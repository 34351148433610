import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { isBrowser } from "react-device-detect";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CustomTextField } from "../../../../common/CustomTextField";
import { TimePicker } from "@material-ui/pickers";
import DialogBox from "../../../../common/DialogBox";
import SubmitCancelBtn from "../../../../common/SubmitCancelBtn";
import MultiSelect from "../../../../common/MultiSelect";
import ApplicationConstants from "../../../../../constants/ApplicationConstants";

const styles = {
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
  },
  fieldCss: {
    border: "1px solid #08456C",
    height: "30px", //"45%",
    maxHeight: "30px",
    minHeight: "30px",
    width: "80px", //isBrowser ? "360px" : "160px",
  },
  fieldErrorCss: {
    border: "1px solid red",
    height: "30px",
    maxHeight: "30px",
    minHeight: "30px",
    width: "80px", // isBrowser ? "360px" : "160px",
  },
  tableList: {
    marginTop: "20px",
    // overflowX: "scroll",
  },
  tableHead: {
    backgroundColor: "#ced4da",
    "& th": {
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
    },
  },

  tableRow: {
    "& td": {
      fontWeight: "bold",
      fontFamily: "Rubik, sans-serif",
    },
  },
};

class CategoriesTable extends Component {
  state = {
    routeDialog: false,
    routeValue: "",
  };
  getRouteDialogComponent = () => {
    const { classes, handleRouteChange } = this.props;
    const {
      row,
      listName,
      activityRow,
      routeDialog,
      routeValue,
      routeValueError,
    } = this.state;
    
    return (
      <div
        style={{
          // width: "80%",

          margin: "0 auto",
          padding: "10px",
        }}
      >
        <CustomTextField
          value={routeValue || ""}
          style={
         
              {
                  border: routeValueError ? "1px solid red" : "1px solid #08456C" ,
                  height: "40px",
                  maxHeight: "40px",
                  minHeight: "40px",
                  width: isBrowser ? "360px" : "160px",
                }
            
          }
          handleChange={(prop) => (e) => {
            this.setState({
              routeValue: e.target.value,
              // eventNameError: false,
            });
          }}
        />
      </div>
    );
  };
  render() {
    const {
      classes,
      listName,
      list,
      activityRow,
      handleCategoryDisplayNameChange,
      handleCategoryFeeChange,
      handleCategoryMaxTicketCountChange,
      isPastEvent,
      handleBibStartNumberChange,
      handleStandardCompletionTimeChange,
      handleRouteChange,
      hasTimingMat,
      handleChallengeGoalChange,
      handleActualDistanceChange
    } = this.props;

    return (
      <>
        <TableContainer component={Paper}>
          <Table aria-label="customized table" style={{ overflowX: "scroll" }}>
            <TableHead className={classes.head}>
              <TableRow className={classes.tableHead}>
                <TableCell component="th">Category Value</TableCell>
                <TableCell component="th">Category Display Name</TableCell>
                <TableCell component="th">Category Fee</TableCell>
                <TableCell component="th">Category Max Ticket Count</TableCell>
                <TableCell component="th">Bib Start Number</TableCell>
                {hasTimingMat && (
                  <TableCell component="th">Standard Completion Time</TableCell>
                )}
                {hasTimingMat && <TableCell component="th">Route</TableCell>}{" "}
                <TableCell component="th">Challenge Goal</TableCell>
                <TableCell component="th">Actual Distance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list?.length > 0 &&
                list.map((row, index) => (
                  <TableRow
                    key={index}
                    component={Paper}
                    className={classes.tableRow}
                  >
                    <TableCell
                      component="td"
                      align="left"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <p>{row?.value}</p>
                    </TableCell>
                    <TableCell
                      component="td"
                      align="left"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <CustomTextField
                        value={row?.categoryDisplayName}
                        defaultValue={
                          row?.categoryDisplayName
                            ? row?.categoryDisplayName
                            : row?.category
                        }
                        className={
                          row?.isDisplayNameError
                            ? classes.fieldErrorCss
                            : classes.fieldCss
                        }
                        handleChange={(prop) => (e) => {
                          handleCategoryDisplayNameChange(
                            e,
                            row,
                            listName,
                            activityRow
                          );
                        }}
                        readOnly={isPastEvent}
                      />
                    </TableCell>
                    <TableCell
                      component="td"
                      align="left"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <CustomTextField
                        type="number"
                        value={row?.categoryFee}
                        defaultValue={row?.categoryFee}
                        className={
                          row?.isFeeError
                            ? classes.fieldErrorCss
                            : classes.fieldCss
                        }
                        handleWheel={(e) => e.target.blur()}
                        handleChange={(prop) => (e) => {
                          handleCategoryFeeChange(
                            e,
                            row,
                            listName,
                            activityRow
                          );
                        }}
                        readOnly={isPastEvent}
                      />
                    </TableCell>
                    <TableCell
                      component="td"
                      align="left"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <CustomTextField
                        type="number"
                        value={row?.categoryMaxTicketCount}
                        defaultValue={
                          row?.categoryMaxTicketCount
                          //   ? row.displayName : row.value
                        }
                        className={
                          row?.isMaxTicketCountError
                            ? classes.fieldErrorCss
                            : classes.fieldCss
                        }
                        handleWheel={(e) => e.target.blur()}
                        handleChange={(prop) => (e) => {
                          handleCategoryMaxTicketCountChange(
                            e,
                            row,
                            listName,
                            activityRow
                          );
                        }}
                        readOnly={isPastEvent}
                      />
                    </TableCell>
                    <TableCell
                      component="td"
                      align="left"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <CustomTextField
                        type="text"
                        value={row?.bibStartNumber}
                        defaultValue={
                          row?.bibStartNumber
                          //   ? row.displayName : row.value
                        }
                        className={
                          row?.isBibStartNumberError
                            ? classes.fieldErrorCss
                            : classes.fieldCss
                        }
                        handleChange={(prop) => (e) => {
                          handleBibStartNumberChange(
                            e,
                            row,
                            listName,
                            activityRow
                          );
                        }}
                        readOnly={isPastEvent}
                      />
                    </TableCell>
                    {hasTimingMat && (
                      <TableCell
                        component="td"
                        align="left"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <CustomTextField
                          type={"time"}
                          placeholder={"HH:MM"}
                          className={
                            // row?.isBibStartNumberError
                            //   ? classes.fieldErrorCss
                            //   :
                            classes.fieldCss
                          }
                          style={{ width: "110px" }}
                          value={row.standardCompletionTime}
                          handleChange={(prop) => (e) => {
                            handleStandardCompletionTimeChange(
                              e.target.value,
                              row,
                              listName,
                              activityRow
                            );
                          }}
                          readOnly={isPastEvent}
                        />
                      </TableCell>
                    )}
                    {hasTimingMat && (
                      <TableCell
                        component="td"
                        align="left"
                        style={{ whiteSpace: "nowrap" }}
                        onClick={() => {
                          this.setState({
                            routeDialog: true,
                            row: row,
                            listName: listName,
                            activityRow: activityRow,
                          });
                        }}
                      >
                        <CustomTextField
                          type={"text"}
                          value={row?.routeCoordinates}
                          defaultValue={row?.routeCoordinates}
                          className={
                            // row?.routeCoordinatesError
                            //   ? classes.fieldErrorCss
                            //   :
                            classes.fieldCss
                          }
                          handleChange={(prop) => (e) => {
                            this.getRouteDialogComponent(
                              row,
                              listName,
                              activityRow
                            );
                          }}
                          readOnly={isPastEvent}
                        />
                      </TableCell>
                    )}
                     <TableCell
                      component="td"
                      align="left"
                      style={{ whiteSpace: "nowrap", width:'180px' }}
                    >
                     <MultiSelect
                      value={row?.challengeGoal || ''}
                      options={ApplicationConstants.challengeGoal}
                      onChange={(e) => {
                        handleChallengeGoalChange(
                          e,
                          row,
                          listName,
                          activityRow
                        )
                      }}
                      isReadOnly={isPastEvent}
                    />
                    </TableCell>
                    <TableCell
                      component="td"
                      align="left"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <CustomTextField
                        // type="number"
                        value={row?.actualDistance}
                        className={
                          classes.fieldCss
                        }
                        handleChange={(prop) => (e) => {
                          handleActualDistanceChange(
                            e,
                            row,
                            listName,
                            activityRow
                          )
                        }}
                        readOnly={isPastEvent}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <DialogBox
          signup={true}
          open={this.state.routeDialog} //{this.state.submitDialog}
          message={this.getRouteDialogComponent()}
          header={"Add Route"}
          onClick={() => {
            handleRouteChange(
              this.state.routeValue,
              this.state.row,
              this.state.listName,
              this.state.activityRow
            );
            this.setState({
              rowValue: "",
              routeDialog: false,
            });
          }}
          // fullWidth
          maxWidth={"xs"}
          closeDisloag={() =>
            this.setState({
              routeDialog: false,
            })
          }
        />
      </>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(CategoriesTable);
