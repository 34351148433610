import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import EventCard from "./detailscards/EventCard";
import EventInfoCard from "./detailscards/EventInfoCard";
import EventDescriptionCard from "./detailscards/EventDescriptionCard";
import EventOrganizer from "./EventOrganizer";
import EventSponsers from "./EventSponsers";
import { withRouter } from "react-router-dom";
import ReactPlayer from "react-player";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import { modifyProgramChallengeParticipant } from "../../../../../apis/programService";
import ListBlock from "./ListBlock";
import Properties from "../../../../../properties/Properties";
import RenderProgramChallenges from "./RenderProgramChallenges";
const styles = (theme) => ({
  details: {
    flex: "0.8",
    // border: "2px solid green",
  },
  videoWrapperClass: {
    width: "100%",
    margin: "auto",
    maxWidth: "850px",

    marginBottom: "10px",
    marginTop: "10px",
    padding: "30px",
    boxSizing: "border-box",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    borderRadius: "10px",
  },
});

class Details extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      isUserRegistered,
      classes,
      data,
      handleVideoViewed,
      isVideoViewed,
      handleBannerLoaded,
      isMainBannerLoaded,
    } = this.props;

    return (
      <div className={classes.details}>
        {data?.challengeType == "TEAM_RELAY" && (
          <EventDescriptionCard data={data} />
        )}
        {/* Main Title block */}
        <EventCard data={data} handleBannerLoaded={handleBannerLoaded} />
        {/* Main Information Desc block */}
        <EventInfoCard data={data} />
        {/* Challenge Video block */}

        {/* Event List block */}
        {data?.eventDTO && data?.eventDTO?.length > 0 && (
          <ListBlock
            isUserRegistered={isUserRegistered}
            title={Properties.Label_EventDetails_Program_events_title}
            items={data?.eventDTO}
            blockFor={"events"}
          />
        )}
        {/* Challenge List block */}
        <RenderProgramChallenges
          programData={data}
          isUserRegistered={isUserRegistered}
          isVideoViewedState={isVideoViewed}
          handleVideoViewed={handleVideoViewed}
          isMainBannerLoaded={isMainBannerLoaded}
        />

        {/* Challenge List block */}
        {data?.programSessions && data?.programSessions?.length > 0 && (
          <ListBlock
            isUserRegistered={isUserRegistered}
            title={Properties.Label_EventDetails_Program_sessions_title}
            items={data?.programSessions}
            blockFor={"sessions"}
          />
        )}
        <EventOrganizer data={data} />
        {data?.eventSponsors?.length > 0 && <EventSponsers data={data} />}
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(Details);
