import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Properties from "./../../../../../properties/Properties";
import { withRouter } from "react-router-dom";
import httpService from "../../../../../apis/httpService";

const styles = (theme) => ({
  name_and_bib: {
    /* position: relative;
  top: 22rem; */
    width: "25%",

    /* margin: auto, */
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",

    " & .outer_circle": {
      backgroundColor: "white",
      border: "5px solid lightblue",
      borderRadius: "50%",
      height: "200px",
      width: "200px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    "& .inner_circle": {
      backgroundColor: "lightblue",
      borderRadius: "50%",
      height: "150px",
      width: "150px",
      margin: "auto",
      /* text-align: center, */
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    "& .inner_circle h1": {
      fontSize: "50px",
    },
    "& .name_and_bib_block, .bib": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      marginTop: "10px",
    },

    "& .name_and_bib_block span": {
      //   fontWeight: 200,
      fontSize: "20px",
      textAlign: "center",
    },

    "& .bib span:nth-child(1)": {
      //   fontWeight: 300,
      borderBottom: "3px solid gray",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "auto",
    },
  },
  profileimg: {
    borderRadius: "50%",
    height: "150px",
    width: "150px",
  },
});

function NameAndBib(props) {
  const API_URL = httpService.API_URL();
  const displayProfileImage = (profileImage) => {
    const { firstName, lastName, classes } = props;
    let splitFirstName = firstName.includes(" ");

    let splitF = "";
    let splitL = "";
    if (splitFirstName) {
      let split = firstName.split(" ");
      splitF = split[0];
      splitL = split[1];
    }
    return profileImage ? (
      <img
        className={classes.profileimg}
        // src={`data:image/png;base64,${profileImage.imageData}`}
        src={
          profileImage.includes("http")
            ? `${profileImage}`
            : `${API_URL}${profileImage}`
        }
        alt={profileImage.fileName}
      />
    ) : (
      <h1>
        {splitFirstName
          ? `${splitF[0]} ${splitL[0] ? splitL[0] : ""}`
          : `${firstName[0]} ${lastName ? lastName[0] : ""}`}
      </h1>
    );
  };

  const { classes, firstName, lastName, bib, profileImage } = props;
  return (
    <Paper className={classes.name_and_bib} square elevation={3}>
      <div className="circle_profile">
        <div className="outer_circle">
          <div className="inner_circle">
            {displayProfileImage(profileImage)}
          </div>
        </div>
      </div>
      <div className="name_and_bib_block">
        <span>
          <b>{`${firstName} ${lastName ? lastName : ""}`}</b>
        </span>
        {bib && (
          <div className="bib">
            <span>{Properties.BIB_label}</span>
            <span>
              <b>{bib}</b>
            </span>
          </div>
        )}
      </div>
    </Paper>
  );
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(NameAndBib);
