import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DialogBox from "../../../../../common/DialogBox";
import ReactPlayer from "react-player";
import AppToolBar from "../../../../../common/AppToolBar";
import Header from "../../../../../common/Header";
import Properties from "../../../../../../properties/Properties";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";
import {
  getVideoGallery,
  getVideoGalleryRunnersList,
} from "../../../../../../apis/eventService";
import httpService from "../../../../../../apis/httpService";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import blackBg from "../../../../../../images/black.jpg";
import Autosuggest from "react-autosuggest";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import SubmitCancelBtn from "../../../../../common/SubmitCancelBtn";
import ListPageLoader from "../../../../../common/ListPageLoader";

const API_URL_FILE = httpService.API_URL();

const styles = (theme) => ({
  card: {
    maxWidth: "1600px",
    width: "100%",
    // marginTop: "70px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "10px",
    "& .MuiCardContent-root": {
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
      },
    },
  },
  thumbnailLayout: {
    width: "360px",
    height: "250px",
    boxShadow: "0px 0px 2px 3px rgba(0,0,0,0.3)",
    borderRadius: "10px",
    position: "relative",
    background: "#000",
  },

  image: {
    width: "360px",
    height: "250px",
    objectFit: "contain",
    margin: "auto",
    borderRadius: "10px",
    marginTop: "auto",
    marginBottom: "auto",
    cursor: "pointer",
  },
  mediaContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: "200px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "300px",
    },
  },
  cardItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "10px",
    [theme.breakpoints.down("sm")]: {
      margin: "7px",
    },
  },
  searchRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  topLayout: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    position: "fixed",
    top: "60px",
    width: "100%",
    maxWidth: "1600px",
    paddingTop: "20px",
    paddingBottom: "20px",
    zIndex: 1,
    background: "white",
  },
  input: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .react-autosuggest__container": {
      position: "relative",
    },

    "& .react-autosuggest__input": {
      width: "500px",
      height: "50px",
      padding: "10px 20px",
      fontFamily: "Helvetica, sans-serif",
      fontWeight: 300,
      fontSize: "16px",
      border: "1px solid #aaa",
      borderRadius: "4px",
      [theme.breakpoints.down("sm")]: {
        width: "255px",
      },
    },
    "& .react-autosuggest__input--focused": {
      outline: "none",
    },

    "& .react-autosuggest__input--open": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },

    "& .react-autosuggest__suggestions-container": {
      display: "none",
    },

    "& .react-autosuggest__suggestions-container--open": {
      display: "block",
      position: "absolute",
      top: "51px",
      width: "500px",

      border: "1px solid #aaa",
      backgroundColor: "#fff",
      fontFamily: "Helvetica, sans-serif",
      fontWeight: 300,
      fontSize: "16px",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      zIndex: 2,
      overflow: "auto",
      maxHeight: "300px",
      [theme.breakpoints.down("sm")]: {
        width: "255px",
      },
    },

    "& .react-autosuggest__suggestions-list": {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },

    "& .react-autosuggest__suggestion": {
      cursor: "pointer",
      padding: "10px 20px",
    },

    "& .react-autosuggest__suggestion--highlighted": {
      backgroundColor: "#ddd",
      color: "#000",
    },
  },
  totalRec: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    fontWeight: 600,
    width: "15%",
    marginLeft: "30px",
    background: "rgba( 149, 213, 178, 0.50 )",
    boxShadow: "0 4px 3px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginLeft: "0px",
      marginTop: "10px",
    },
    // position: "relative",
    // bottom: 20,
  },
  noResults: {
    textAlign: "center",
    width: "100%",
    marginTop: "200px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "250px",
    },
  },
  headline: {
    fontSize: "25px",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
    marginLeft: "50px",
    color: "#780000",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
    },
  },
});
class EventVenue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showVideoDialog: false,
      eventDetails: null,
      galleryList: [],
      selectedVideo: "",
      suggestions: [],
      inputValue: "",
      totalRecords: 0,
      resetPagination: false,
      eventId: "",
      videoSearchList: [],
    };
  }
  componentDidMount() {
    let search = window.location.href;
    let distKey = search.split("distKey=")[1];
    let requestParams = {
      pageCount: 15,
      pageNumber: 0,
      distKey,
    };

    getVideoGallery(requestParams).then((res) => {
      let list = res.data?.videoDetailsDTO;

      this.setState({
        eventDetails: res.data,
        galleryList: list,
        distKey: distKey,
        eventId: res?.data?.id,
        totalRecords: res.data?.totalRecords,
      });
      requestParams.eventId = res?.data?.id;
      getVideoGalleryRunnersList(requestParams).then(({ data }) => {
        this.setState({ suggestions: data, videoSearchList: data });
      });
    });
  }

  fetchVideoGalleryList = (requestParams, addList) => {
    const { galleryList } = this.state;
    getVideoGallery(requestParams).then((res) => {
      let list = res.data?.videoDetailsDTO;

      this.setState({
        galleryList: addList === "DONT_ADD" ? list : galleryList.concat(list),
      });
    });
  };
  getVideoDialogComponent = () => {
    const { selectedVideo } = this.state;
    return (
      <div>
        <ReactPlayer
          width="100%"
          height={window.innerWidth < 1400 ? "400px" : "600px"}
          style={{ background: "#000" }}
          config={{
            file: {
              attributes: {
                autoPlay: true,
              },
              forceVideo: true,
            },
          }}
          controls
          url={`https://${selectedVideo?.videoStreamURL}`}
        />
      </div>
    );
  };
  closeWindow = () => {
    this.setState({
      showVideoDialog: false,
    });
  };
  getSuggestions = (value) => {
    const { videoSearchList } = this.state;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (videoSearchList) {
      let value =
        inputLength === 0
          ? []
          : videoSearchList.filter((val) =>
              val?.fileName?.toLowerCase().includes(inputValue)
            );
      return value;
    }
  };
  getSuggestionValue = (suggestion) => {
    this.setState({
      fileId: suggestion?.fileId,
    });
    this.onHandleSearch(suggestion?.fileId);
    return `${suggestion.fileName}`;
  };
  onSuggestionsFetchRequested = ({ value }) => {
    console.log("time taken", new Date());
    let result = this.getSuggestions(value);
    if (result?.length > 0) {
      this.setState({
        suggestions: result,
      });
    }
    console.log("time taken 1", new Date());
  };
  renderSuggestion = (suggestion) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>
        <DirectionsRunIcon
          style={{
            color: "#09456C",
          }}
        />
      </div>
      <div>{`${suggestion.fileName}`}</div>
    </div>
  );
  onHandleSearch = (id) => {
    const { pageNumber, fileId, distKey, eventId } = this.state;
    this.setState({ resetPagination: true });
    if (fileId || id) {
      let requestParams = {
        pageCount: 15,
        pageNumber: 0,
        fileId: id || fileId,
        distKey,
        eventId: eventId,
      };
      getVideoGalleryRunnersList(requestParams).then(({ data }) => {
        this.setState({ suggestions: data });
      });
      this.fetchVideoGalleryList(requestParams, "DONT_ADD");
    } else {
      this.setState({ galleryList: [] });
    }
  };
  onSuggestionsClearRequested = () => {
    // this.setState({ inputValue: "", fileName: "" });
    // this.setState({
    //   suggestions: [],
    // });
  };

  render() {
    const { classes } = this.props;
    const {
      showVideoDialog,
      eventDetails,
      distKey,
      galleryList,
      selectedVideo,
      fileId,
      suggestions,
      inputValue,
      totalRecords,
      resetPagination,
      eventId,
    } = this.state;
    return (
      <>
        <AppToolBar>
          <Header
            {...this.props}
            hideHomeIcon
            showBack
            backNavigate={() =>
              this.props.history.push(`/event?distKey=${distKey}`)
            }
            label={
              eventDetails?.name
                ? eventDetails?.name
                : Properties.Label_VideoGallery
            }
          />
        </AppToolBar>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.topLayout}>
              <Typography className={classes.headline}>
                {"Largest online video album of people riding bicycles"}
              </Typography>
              <div className={classes.searchRow}>
                <div className={classes.input}>
                  <Autosuggest
                    inputProps={{
                      placeholder: Properties.Label_Search,
                      value: inputValue,
                      type: "search",
                      onChange: (_event, { newValue }) => {
                        this.setState({ inputValue: newValue });
                        if (!newValue) {
                          this.setState({ fileId: "" });
                          let requestParams = {
                            pageCount: 15,
                            pageNumber: 0,
                            distKey,
                            eventId,
                          };
                          getVideoGalleryRunnersList(requestParams).then(
                            ({ data }) => {
                              this.setState({ suggestions: data });
                            }
                          );
                          this.fetchVideoGalleryList(requestParams, "DONT_ADD");
                        }
                      },
                    }}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={
                      this.onSuggestionsFetchRequested
                    }
                    onSuggestionsClearRequested={
                      this.onSuggestionsClearRequested
                    }
                    onSuggestionSelected={(event, { suggestion, method }) => {
                      if (method === "enter") {
                        event.preventDefault();
                      }
                    }}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                  />
                  <SubmitCancelBtn
                    type="submit"
                    label={Properties.Label_Search}
                    style={{ width: "40%", marginLeft: "20px" }}
                    onClick={(e) => {
                      this.onHandleSearch();
                    }}
                  />
                </div>
                <div className={classes.totalRec}>
                  <span>
                    {"Showing"}
                    <span style={{ marginLeft: "10px" }}>
                      {`${galleryList?.length}/${totalRecords}`}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            {galleryList?.length > 0 ? (
              <ListPageLoader
                // style={{width:"100%"}}
                pageParams={{
                  page: 0,
                  start: 0,
                  limit: 15,
                  TotalRecords: totalRecords,
                }}
                totalLoadedLength={galleryList ? galleryList.length : 0}
                resetLoader={resetPagination}
                // divHeight={window.innerHeight - 190}
                loadNextData={(params) => {
                  if (totalRecords > 15 && !fileId) {
                    this.setState({
                      pageNumber: params.page,
                      resetPagination: false,
                    });
                    let requestParams = {
                      pageCount: 15,
                      pageNumber: params.page,
                      fileId: fileId,
                      distKey,
                    };
                    this.fetchVideoGalleryList(requestParams);
                  }
                }}
              >
                <div className={classes.mediaContainer}>
                  {galleryList.map((item, i) => (
                    <div className={classes.cardItem}>
                      <Paper
                        className={classes.thumbnailLayout}
                        onClick={() => {
                          this.setState({
                            showVideoDialog: true,
                            selectedVideo: item,
                          });
                        }}
                      >
                        <img
                          src={
                            item?.videoThumbnail
                              ? `https://${item.videoThumbnail}`
                              : blackBg
                          }
                          className={classes.image}
                        />
                        <div
                          style={{
                            backgroundColor: "#09456C",
                            borderRadius: "50%",
                            position: "absolute",
                            left: "43%",
                            top: "40%",
                          }}
                        >
                          <IconButton style={{ backgroundColor: "#09456C" }}>
                            <PlayCircleFilledIcon
                              onClick={() =>
                                this.setState({ showVideoDialog: true })
                              }
                              style={{ fontSize: "25px", color: "#fff" }}
                            />
                          </IconButton>
                        </div>
                      </Paper>
                      <Tooltip
                        title={item?.videoFileName}
                        placement="top-start"
                      >
                        <Typography
                          style={{
                            marginTop: "5px",
                            height: "50px",
                            // wordWrap:'break-word',
                            maxWidth: "300px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item?.videoFileName ? item?.videoFileName : ""}
                          {item?.videoFileName && item?.runnerName ? "-" : ""}
                          {item?.runnerName ? `${item.runnerName}` : ""}
                        </Typography>
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </ListPageLoader>
            ) : (
              <Typography className={classes.noResults}>
                No Results Found
              </Typography>
            )}
          </CardContent>
        </Card>
        <DialogBox
          fullWidth
          header={selectedVideo?.videoFileName}
          open={showVideoDialog}
          message={this.getVideoDialogComponent()}
          onClick={() => this.closeWindow()}
          closeDisloag={() => this.closeWindow()}
        />
      </>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(EventVenue);
