import React, { Component, createRef } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Details from "./Details";
import Bookings from "./Bookings";
import {
  getEventDetailsWithDistkey,
  getProgramDetailsWithDistkey,
  userRegisteredForEvent,
  userRegisteredForProgram,
} from "./../../../../../apis/eventService";
import AppToolBar from "./../../../../common/AppToolBar";
import EventCard from "./detailscards/EventCard";
import EventInfoCard from "./detailscards/EventInfoCard";
import EventDescriptionCard from "./detailscards/EventDescriptionCard";
import FooterBookings from "./FooterBookings";
import EventOrganizer from "./EventOrganizer";
import Header from "../../../../common/Header";
import EventSponsers from "./EventSponsers";
import { connect } from "react-redux";
import {
  updateUserSelectedEvent,
  updateUserSelectedProgram,
  updateUserPreferences,
  updateCurrentRunningProgramChallenge,
} from "../../../../../redux/user";
import { withRouter } from "react-router-dom";
import RunnerSideBreadcrumbs from "../../../../common/RunnerSideBreadcrumbs";
import ReactPlayer from "react-player";
import { modifyProgramChallengeParticipant } from "../../../../../apis/programService";
import moment from "moment";
import ListBlock from "./ListBlock";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import Properties from "../../../../../properties/Properties";
import RenderProgramChallenges from "./RenderProgramChallenges";

const styles = (theme) => ({
  root: {
    marginTop: "68px",
  },
  header: {
    height: "65px",
    fontFamily: "Lato, sans-serif",
    position: "fixed",
  },
  headerDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  headerTypo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: "0.1",
    cursor: "pointer",
  },
  section: {
    display: "flex",
    height: "100vh",
    maxWidth: "100%",
    padding: "0 10px",
  },
  media: {
    height: "auto",
    width: "60%",
    margin: "auto",
    objectFit: "content",
    boxShadow: "inset 0px 0px 19px 0px rgba(0,0,0,0.75)",
    position: "sticky",
    top: 70,
    zIndex: -1,
  },
  responsive: {
    position: "relative",
    top: "5px",
    margin: "10px",
  },

  selected: {
    color: "#6b46c1",
    borderBottom: "3px solid #6b46c1",
  },

  boxCss: {
    display: "flex",
    alignItems: "center",
  },
  boxPaddingCss: {
    paddingRight: "0px !important",
  },
  videoWrapperClass: {
    width: "100%",
    margin: "auto",
    maxWidth: "850px",
    marginBottom: "10px",
    borderRadius: "10px",
    "& .reactPlayer video": {
      maxHeight: "65vh",
    },
  },
});

class CompleteEventDetailsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileView: 0,
      anchorEl: null,
      userName: "",
      resData: null,
      distKey: "",
      isUserRegistered: false,
      clickedChallengeVideo: "",
      runningProgramChallengeResData: null,
      isDayOneOfCurrentRunningChallenge: false,
      isMainBannerLoaded: false,
      isVideoViewed:
        this.props.currentRunningProgramChallenge?.isVideoViewed || false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    let existEventName = localStorage.getItem("eventName");
    if (existEventName) {
      localStorage.removeItem("eventName");
    }
    // window.addEventListener("load", this.handleLoad);
    window.addEventListener("resize", this.handleresize);
    this.handleresize();
    this.setState({ userName: localStorage.getItem("userName") });
    let search = window.location.href;
    let distKey = search.split("distKey=")[1];
    if (distKey?.includes("&")) {
      distKey = distKey?.split("&")[0];
    }
    if (!distKey || distKey == "") {
      history.push("/completeEventList");
    }
    this.handleDistKeyLoad(distKey);
  }
  componentDidUpdate(prevState, prevProps) {
    if (ApplicationUtil.isuserLoggedIn()) {
      let paramEventId = window.location?.href?.split("eventId=")[1];
      let paramSessionId = window.location?.href?.split("sessionId=")[1];
      let challengeId = window.location?.href?.split("challengeId=")[1];
      if (paramEventId || paramSessionId) {
        this.handleScrollForEvents_Sessions();
      } else {
        if (
          !this.state.isDayOneOfCurrentRunningChallenge &&
          !challengeId &&
          //vishal- Defect_id_776 - Now - Dont scroll back to main banner after challenge video viewed (below condition)
          !this.state.isVideoViewed
        ) {
          window.scrollTo(0, 0);
        }
      }
    }
  }
  handleDistKeyLoad = (distKey) => {
    const { history } = this.props;
    let search = window.location.href;
    let challengeId = search.split("challengeId=")[1];
    const metaDescription = document.querySelector('meta[name="description"]');
    const metaTitle = document.querySelector('meta[name="title"]');
    if (distKey != undefined) {
      if (search.includes("program")) {
        /**
         *getProgramDetailsWithDistkey- Api call for getting all the details of program
         */
        getProgramDetailsWithDistkey(distKey)
          .then(({ data, status }) => {
            if (status === 200) {
              this.props.updateUserSelectedProgram(data);
              document.title = data?.name;
              let stripedHtml = data?.description?.replace(/<[^>]+>/g, "");
              let trimDesc = stripedHtml?.substring(0, 200) + "...";
              let currentRunningChallenge;

              if (challengeId) {
                currentRunningChallenge = ApplicationUtil.getChallengeFromId(
                  data?.programChallenges,
                  challengeId
                );
              } else {
                currentRunningChallenge =
                  ApplicationUtil.getCurrentRunningProgramChallenge(
                    data?.programChallenges
                  );
                if (!currentRunningChallenge) {
                  currentRunningChallenge =
                    ApplicationUtil.getUpcomingProgramChallenge(
                      data?.programChallenges
                    );
                }
              }
              let challengeStartDate =
                currentRunningChallenge?.challengeStartDate?.split(" ")[0];
              const today = moment().format("DD-MM-YYYY");
              const isSameDay = moment(challengeStartDate, "DD-MM-YYYY").isSame(
                moment(today, "DD-MM-YYYY"),
                true
              );
              if (isSameDay) {
                this.setState({ isDayOneOfCurrentRunningChallenge: true });
              }
              let programData = {
                ...data,
                eventLocalEndDate: data?.localEndDate,
                eventLocalStartDate: data?.localStartDate,
                isRegistrationOpen: data?.isProgramRegistrationOpen,
                hideFreeBlockWhenFeeZero: true,
                image: data?.imageDto,
              };

              if (ApplicationUtil.isuserLoggedIn()) {
                /**
                 * If User logged in then
                 * userRegisteredForProgram - Api call for getting details of user registered for that program challenges,etc (only registered)
                 */
                userRegisteredForProgram(data?.id)
                  .then((res) => {
                    if (res?.data?.id && res?.data?.success?.code !== "409") {
                      this.setState({
                        isUserRegistered: true,
                      });
                      let currentRunningRegisteredChallenge;

                      if (challengeId) {
                        currentRunningRegisteredChallenge =
                          ApplicationUtil.getChallengeFromId(
                            res?.data?.programChallenges,
                            challengeId
                          );
                      } else {
                        //check if current ongoing challenge is there, then get details of that challenge
                        currentRunningRegisteredChallenge =
                          ApplicationUtil.getCurrentRunningProgramChallenge(
                            res?.data?.programChallenges
                          );
                        //and if not then get upcoming challenge details
                        if (!currentRunningRegisteredChallenge) {
                          currentRunningRegisteredChallenge =
                            ApplicationUtil.getUpcomingProgramChallenge(
                              res?.data?.programChallenges
                            );
                        }
                      }
                      //isDayOneOfCurrentRunningChallenge - checking if it 1st day of current/upcoming challenge
                      let challengeStartDate =
                        currentRunningRegisteredChallenge?.challengeStartDate?.split(
                          " "
                        )[0];
                      const isSameDay = moment(
                        challengeStartDate,
                        "DD-MM-YYYY"
                      ).isSame(moment(today, "DD-MM-YYYY"), true);
                      this.setState({
                        runningProgramChallengeResData:
                          currentRunningRegisteredChallenge,
                        isDayOneOfCurrentRunningChallenge: isSameDay
                          ? true
                          : false,
                      });
                      this.props.updateCurrentRunningProgramChallenge(
                        currentRunningRegisteredChallenge
                      );
                      this.handleVideoViewed(
                        currentRunningRegisteredChallenge?.isVideoViewed ||
                          false
                      );

                      let openProgramData = {
                        ...res?.data,
                        isRegistrationOpen:
                          res?.data?.isProgramRegistrationOpen,
                        eventLocalEndDate: res?.data?.localEndDate,
                        eventLocalStartDate: res?.data?.localStartDate,
                        hideFreeBlockWhenFeeZero: true,
                        image: res?.data?.imageDto,
                      };

                      if (programData?.isOpenSubscriptionProgram) {
                        this.setState({
                          resData: openProgramData,
                          distKey: distKey,
                          desc: trimDesc,
                        });
                        this.props.updateUserSelectedProgram(openProgramData);
                      }
                    } else {
                      this.setState({
                        resData: programData,
                        distKey: distKey,
                        desc: trimDesc,
                      });
                    }
                  })
                  .catch(() => {
                    this.props.updateCurrentRunningProgramChallenge(
                      currentRunningChallenge
                    );
                    this.handleVideoViewed(
                      currentRunningChallenge?.isVideoViewed || false
                    );
                  });
              }
              if (
                !ApplicationUtil.isuserLoggedIn() ||
                !programData?.isOpenSubscriptionProgram
              ) {
                this.setState({
                  resData: programData,
                  distKey: distKey,
                  desc: trimDesc,
                });
              }
            }
          })
          .catch((error) => {
            if (error) {
              history.push("/");
            }
          });
      } else {
        getEventDetailsWithDistkey(distKey)
          .then((res) => {
            if (res.status === 200) {
              this.props.updateUserSelectedEvent(res.data);
              this.props.updateUserSelectedProgram({});

              document.title = res?.data?.name;
              let stripedHtml = res?.data?.description.replace(/<[^>]+>/g, "");
              let trimDesc = stripedHtml.substring(0, 200) + "...";
              if (metaDescription && metaTitle) {
                metaTitle.content = `Results - ${res?.data?.name}`;
                metaDescription.content = trimDesc;
              }
              if (
                ApplicationUtil.ReactNative(`{"title":"${res?.data?.name}"}`)
              ) {
                this.setState({ nativeView: true });
              }

              if (ApplicationUtil.isuserLoggedIn()) {
                userRegisteredForEvent(res.data?.id).then((res) => {
                  if (res?.data?.success?.code === "200") {
                    this.setState({
                      isUserRegistered: true,
                    });
                  }
                });
              }
              this.setState({
                resData: res.data,
                distKey: distKey,
                desc: trimDesc,
              });
            }
          })
          .catch((error) => {
            if (error) {
              history.push("/completeEventList");
            }
          });
      }
    }
  };

  handleresize = () => {
    this.setState({
      mobileView: window.innerWidth,
    });
  };

  handleBannerLoaded = () => {
    this.setState({ isMainBannerLoaded: true });
  };

  // handleLoad = () => {
  //   const { history } = this.props;
  //   history.push("/completeEventList");
  // };

  componentWillUnmount() {
    // window.removeEventListener("load", this.handleLoad);
    window.removeEventListener("resize", this.handleresize);
  }
  handleVideoViewed = (value) => {
    this.setState({
      isVideoViewed: value,
    });
  };
  handleScrollForEvents_Sessions = () => {
    let paramEventId = window.location?.href?.split("eventId=")[1];
    let paramSessionId = window.location?.href?.split("sessionId=")[1];
    let element;
    if (paramEventId) {
      element = "events_" + paramEventId;
    }
    if (paramSessionId) {
      element = "sessions_" + paramSessionId;
    }

    let bannerElement = document.getElementById("banner");
    let isBannerLoaded = bannerElement ? bannerElement?.complete : true;
    if (element) {
      let ele = document.getElementById(element);
      let bannerElement = document.getElementById("banner");
      let isBannerLoaded = bannerElement ? bannerElement?.complete : true;
      if (ele && isBannerLoaded) {
        ele.scrollIntoView({
          block: "center",
          inline: "start",
          behavior: "instant",
        });
      }
    }
  };

  render() {
    const { classes, history } = this.props;
    const { mobileView, resData, distKey, desc, nativeView, isUserRegistered } =
      this.state;
    const isProgram = window.location.href?.includes("program");
    return (
      <>
        {/* AppBar ---> header part */}
        <AppToolBar
          backNavigate={() => this.props.history.goBack()}
          showBack={true}
        >
          <Header
            {...this.props}
            hideHomeIcon={
              resData?.id === 538 ||
              resData?.programStartDate ||
              resData?.eventDate
            }
            label={resData?.name}
            title={resData?.name}
            description={desc}
          />
          <div style={{ width: 35 }} />
        </AppToolBar>

        <div className={nativeView ? "" : classes.root}>
          <RunnerSideBreadcrumbs />
          {/* body part */}
          {/* Desktop View */}

          {mobileView > 950 ? (
            <section className={classes.section}>
              {/* details */}
              <Details
                isUserRegistered={isUserRegistered}
                isProgram={isProgram}
                data={resData}
                currentRunningProgramChallenge={
                  this.props.currentRunningProgramChallenge
                }
                playVideo={
                  !this.state.runningProgramChallengeResData?.isVideoViewed
                }
                updateCurrentRunningProgramChallenge={
                  this.props.updateCurrentRunningProgramChallenge
                }
                handleVideoViewed={this.handleVideoViewed}
                isVideoViewed={this.state.isVideoViewed}
                runningProgramChallengeResData={
                  this.state.runningProgramChallengeResData
                }
                handleBannerLoaded={this.handleBannerLoaded}
                isMainBannerLoaded={this.state.isMainBannerLoaded}
              />
              {/* bookings dekstop view */}
              <Bookings
                data={resData}
                history={history}
                distKey={distKey}
                isUserRegistered={isUserRegistered}
                isVideoViewed={this.state.isVideoViewed}
                isDayOneOfCurrentRunningChallenge={
                  this.state.isDayOneOfCurrentRunningChallenge
                }
                runningProgramChallengeResData={
                  this.state.runningProgramChallengeResData
                }
              />
            </section>
          ) : (
            // mobile View
            <section className={classes.responsive}>
              {/* <h1>hi this is mihika</h1> */}
              {resData?.challengeType == "TEAM_RELAY" && (
                <EventDescriptionCard data={resData} />
              )}

              <EventCard
                data={resData}
                handleBannerLoaded={this.handleBannerLoaded}
              />
              <EventInfoCard data={resData} />

              {/* Event List block */}
              {resData?.eventDTO && resData?.eventDTO?.length > 0 && (
                <ListBlock
                  isUserRegistered={isUserRegistered}
                  title={Properties.Label_EventDetails_Program_events_title}
                  items={resData?.eventDTO}
                  blockFor={"events"}
                />
              )}
              {/* Challenge List block */}
              <RenderProgramChallenges
                programData={resData}
                isUserRegistered={isUserRegistered}
                isVideoViewedState={this.state.isVideoViewed}
                handleVideoViewed={this.handleVideoViewed}
                isMainBannerLoaded={this.state.isMainBannerLoaded}
              />
              {/* Session List block */}
              {resData?.programSessions &&
                resData?.programSessions?.length > 0 && (
                  <ListBlock
                    isUserRegistered={isUserRegistered}
                    title={Properties.Label_EventDetails_Program_sessions_title}
                    items={resData?.programSessions}
                    blockFor={"sessions"}
                  />
                )}
              <EventOrganizer data={resData} />
              {resData?.eventSponsors?.length > 0 && (
                <EventSponsers data={resData} mobile />
              )}
              {/* Footer booking - mobile view  */}
              <FooterBookings
                data={resData}
                history={history}
                distKey={distKey}
                isUserRegistered={isUserRegistered}
                isVideoViewed={this.state.isVideoViewed}
                isDayOneOfCurrentRunningChallenge={
                  this.state.isDayOneOfCurrentRunningChallenge
                }
                runningProgramChallengeResData={
                  this.state.runningProgramChallengeResData
                }
              />
            </section>
          )}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    userSelectedEvent: state.user.userSelectedEvent,
    updateUserPreferences: state.user.updateUserPreferences,
    currentRunningProgramChallenge: state.user.currentRunningProgramChallenge,
    updateCurrentRunningProgramChallenge:
      state.user.updateCurrentRunningProgramChallenge,
  };
}
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateUserSelectedEvent,
    updateUserSelectedProgram,
    updateUserPreferences,
    updateCurrentRunningProgramChallenge,
  })
)(CompleteEventDetailsView);
