import React, { Component } from "react";
import { Paper, Button, Switch } from "@material-ui/core";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MultiSelect from "../../../../common/MultiSelect";
import { CustomTextField } from "../../../../common/CustomTextField";
import CloudUpload from "@material-ui/icons/CloudUpload";
import ImageDialogBox from "../../../../common/ImageDialogBox";
import ClearIcon from "@material-ui/icons/Clear";

const styles = {
  fieldCss: {
    border: "1px solid #08456C",
    height: "36px", //"45%",
    maxHeight: "36px",
    minHeight: "36px",
    width: "80px", //isBrowser ? "360px" : "160px",
  },
  fieldErrorCss: {
    border: "1px solid red",
    height: "36px",
    maxHeight: "36px",
    minHeight: "36px",
    width: "80px", // isBrowser ? "360px" : "160px",
  },
  tableList: {
    marginTop: "20px",
    // overflowX: "scroll",
  },
  tableHead: {
    backgroundColor: "#ced4da",
    "& th": {
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
    },
  },

  tableRow: {
    // height: "50px",
    "& td": {
      fontWeight: "bold",
      fontFamily: "Rubik, sans-serif",
    },
  },
  parentDivCss: {
    height: "50px",
  },
  childDivCss: {
    position: "absolute",
    // zIndex: "1",
  },
  tableCellTitleCss: {
    maxWidth: "200px",
    minWidth: "200px",
    width: "200px",
  },
  multiSelectCss: {
    position: "absolute",
    zIndex: "1",
    top: "2px",
  },
  imgContainer: {
    position: "relative",
    width: "80px",
    textAlign: "center",
    // padding: "40px 20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  profileImgContainer: {
    height: "50px",
    maxHeight: "50px",
    minHeight: "50px",
    width: "60px",
    // height: "120px",
  },
  profileImg: {
    width: "60px",

    objectFit: "contain",
    margin: "auto",
    cursor: "pointer",
  },
  clearImgContainer: {
    width: "20px",
    position: "absolute",
    top: "1px",
    right: "1px",
  },
};

class EventItemsTable extends Component {
  constructor(props) {
    super(props);
    this.state = { title: "", showImage: "", imagePath: "" };
  }
  closeWindow = () => {
    this.setState({
      title: "",
      showImage: false,
      imagePath: null,
    });
  };

  render() {
    const {
      classes,
      //   row,

      handleFemaleTShirtSizeChange,
      handleMaleTShirtSizeChange,
      handleEventItemTypeChange,
      handleSizeChartUpload,
      handleSizeChartRemove,
      TShirtSizeChart,
      itemTypeList,
      femaleTShirtSizeList,
      eventItems,
      readOnly,
    } = this.props;

    return (
      <div>
        <TableContainer
        // component={Paper}
        >
          <Table aria-label="customized table" style={{ overflowX: "scroll" }}>
            <TableHead className={classes.head}>
              <TableRow className={classes.tableHead}>
                <TableCell component="th">Item</TableCell>

                <TableCell component="th" className={classes.tableCellTitleCss}>
                  Type
                </TableCell>

                <TableCell component="th">ReadOnly</TableCell>
                <TableCell component="th">Price per unit</TableCell>
                <TableCell component="th">Max Quantity</TableCell>
                {/* {row.value == "T-Shirt" && ( */}
                <TableCell component="th" className={classes.tableCellTitleCss}>
                  Male T-Shirt Sizes
                </TableCell>
                <TableCell component="th" className={classes.tableCellTitleCss}>
                  Female T-Shirt Sizes
                </TableCell>
                <TableCell component="th">Size Chart</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventItems?.length > 0 &&
                eventItems.map((row, index) => (
                  <TableRow
                    key={index}
                    component={Paper}
                    className={classes.tableRow}
                  >
                    <TableCell
                      component="td"
                      align="left"
                      style={{
                        // whiteSpace: "nowrap",
                        width: "180px",
                        maxWidth: "180px",
                        minWidth: "80px",
                      }}
                    >
                      <div
                        className={classes.parentDivCss}
                        style={{
                          height:
                            row?.sizeMale?.length > 2 ||
                            row?.sizeFemale?.length > 2
                              ? "100px"
                              : "50px",
                        }}
                      >
                        {/* <div
                          style={{
                            width: "180px",
                            maxWidth: "180px",
                            minWidth: "180px",
                          }}
                          className={classes.childDivCss}
                        > */}
                          <p>{row.value}</p>
                        {/* </div> */}
                      </div>
                    </TableCell>
                    <TableCell
                      component="td"
                      align="left"
                      style={{
                        whiteSpace: "nowrap",
                        width: "170px",
                        maxWidth: "170px",
                        minWidth: "170px",
                      }}
                    >
                      {" "}
                      <div
                        className={classes.parentDivCss}
                        style={{
                          height:
                            row?.sizeMale?.length > 2 ||
                            row?.sizeFemale?.length > 2
                              ? "100px"
                              : "50px",
                        }}
                      >
                        <div
                          style={{
                            width: "180px",
                            maxWidth: "180px",
                            minWidth: "180px",
                          }}
                          className={classes.childDivCss}
                        >
                          <MultiSelect
                            value={row.type}
                            error={row.typeError}
                            options={itemTypeList}
                            onChange={(e) => {
                              handleEventItemTypeChange(e, row);
                            }}
                            isReadOnly={readOnly}
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell
                      component="td"
                      align="center"
                      style={{
                        whiteSpace: "nowrap",
                        width: "80px",
                        maxWidth: "80px",
                        minWidth: "80px",
                      }}
                    >
                      {row?.type?.value === "IMPLICITE" ? (
                        <Switch
                          checked={row.readOnly}
                          color="primary"
                          onChange={(e) => {
                            let newData = [...eventItems];
                            let index = newData.findIndex(
                              (obj) => obj.id === row.id
                            );
                            newData[index].readOnly = e.target.checked;
                            this.setState({
                              eventItems: newData,
                            });
                          }}
                          disabled={
                            readOnly
                              ? readOnly
                              : row?.type?.value === "IMPLICITE"
                              ? false
                              : true
                          }
                        />
                      ) : (
                        <div style={{ textAlign: "center" }}>-</div>
                      )}
                    </TableCell>

                    <TableCell
                      component="td"
                      align="left"
                      style={{
                        whiteSpace: "nowrap",
                        width: "100px",
                        maxWidth: "100px",
                        minWidth: "100px",
                      }}
                      //   className={classes.dropdownCss}
                    >
                      {row?.type?.value === "EXPLICITE" ? (
                        <div
                          className={classes.parentDivCss}
                          style={{
                            height:
                              row?.sizeMale?.length > 2 ||
                              row?.sizeFemale?.length > 2
                                ? "100px"
                                : "50px",
                          }}
                        >
                          <div
                            style={{
                              width: "180px",
                              maxWidth: "180px",
                              minWidth: "180px",
                            }}
                            className={classes.childDivCss}
                          >
                            <CustomTextField
                              value={row.pricePerUnit}
                              className={
                                row.isPricePerUnitError
                                  ? classes.fieldErrorCss
                                  : classes.fieldCss
                              }
                              type="number"
                              handleWheel={(e) => e.target.blur()}
                              handleChange={(prop) => (e) => {
                                let newData = [...eventItems];
                                let index = newData.findIndex(
                                  (obj) => obj.id === row.id
                                );
                                newData[index].isPricePerUnitError =
                                  e.target.value === null ? true : false;
                                newData[index].pricePerUnit = e.target.value;
                                this.setState({
                                  eventItems: newData,
                                });
                              }}
                              readOnly={readOnly}
                            />
                          </div>
                        </div>
                      ) : (
                        <div style={{ textAlign: "center" }}>-</div>
                      )}
                    </TableCell>
                    <TableCell
                      component="td"
                      align="left"
                      style={{
                        whiteSpace: "nowrap",
                        width: "80px",
                        maxWidth: "80px",
                        minWidth: "80px",
                      }}
                    >
                      <div
                        className={classes.parentDivCss}
                        style={{
                          height:
                            row?.sizeMale?.length > 2 ||
                            row?.sizeFemale?.length > 2
                              ? "100px"
                              : "50px",
                        }}
                      >
                        <div
                          style={{
                            width: "180px",
                            maxWidth: "180px",
                            minWidth: "180px",
                          }}
                          className={classes.childDivCss}
                        >
                          <CustomTextField
                            value={row.maxQuantity}
                            className={
                              row.isMaxQuantityError
                                ? classes.fieldErrorCss
                                : classes.fieldCss
                            }
                            type="number"
                            handleWheel={(e) => e.target.blur()}
                            handleChange={(prop) => (e) => {
                              let newData = [...eventItems];
                              let index = newData.findIndex(
                                (obj) => obj.id === row.id
                              );
                              newData[index].isMaxQuantityError =
                                e.target.value === null ? true : false;
                              newData[index].maxQuantity = e.target.value;

                              this.setState({
                                eventItems: newData,
                              });
                            }}
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      component="td"
                      // align="left"
                      style={{
                        whiteSpace: "nowrap",
                        width: "240px",
                        maxWidth: "240px",
                        minWidth: "240px",
                      }}
                    >
                      {row?.value?.toLowerCase().includes("t-shirt") ? (
                        <div
                          className={classes.parentDivCss}
                          style={{
                            height:
                              row?.sizeMale?.length > 2 ||
                              row?.sizeFemale?.length > 2
                                ? "100px"
                                : "50px",
                          }}
                        >
                          <div
                            style={{
                              width: "240px",
                              maxWidth: "240px",
                              minWidth: "240px",
                              maxHeight: "40px",
                              height: "40px",
                              minHeight: "40px",
                            }}
                            className={classes.childDivCss}
                          >
                            <MultiSelect
                              isMulti={true}
                              defaultValue={row.sizeMale}
                              error={row.isSizeMaleError}
                              options={femaleTShirtSizeList}
                              onChange={(e) => {
                                handleMaleTShirtSizeChange(e, row, "MALE");
                              }}
                              isReadOnly={readOnly}
                            />
                          </div>
                        </div>
                      ) : (
                        <div style={{ textAlign: "center" }}>-</div>
                      )}
                    </TableCell>
                    <TableCell
                      component="td"
                      style={{
                        whiteSpace: "nowrap",
                        width: "240px",
                        maxWidth: "240px",
                        minWidth: "240px",
                        right: "10px",
                      }}
                      className={classes.dropdownCss}
                    >
                      {row?.value?.toLowerCase().includes("t-shirt") ? (
                        <div
                          className={classes.parentDivCss}
                          style={{
                            height:
                              row?.sizeMale?.length > 2 ||
                              row?.sizeFemale?.length > 2
                                ? "100px"
                                : "50px",
                          }}
                        >
                          <div
                            style={{
                              width: "240px",
                              maxWidth: "240px",
                              minWidth: "240px",
                            }}
                            className={classes.childDivCss}
                          >
                            <MultiSelect
                              maxHeight="40px"
                              //   className={classes.multiSelectCss}
                              isMulti={true}
                              defaultValue={row.sizeFemale}
                              error={row.isSizeFemaleError}
                              options={femaleTShirtSizeList}
                              onChange={(e) => {
                                handleFemaleTShirtSizeChange(e, row, "FEMALE");
                              }}
                              isReadOnly={readOnly}
                            />
                          </div>
                        </div>
                      ) : (
                        <div style={{ textAlign: "center" }}>-</div>
                      )}
                    </TableCell>
                    <TableCell
                      component="td"
                      align="center"
                      style={{
                        whiteSpace: "nowrap",
                        width: "80px",
                        maxWidth: "80px",
                        minWidth: "80px",
                      }}
                    >
                      {row?.value?.toLowerCase().includes("t-shirt") ? (
                        <div>
                          {TShirtSizeChart ? (
                            <div className={classes.imgContainer}>
                              <div className={classes.clearImgContainer}>
                                {/* <Button disabled={readOnly}> */}
                                <ClearIcon
                                  style={{ color: "red" }}
                                  fontSize="small"
                                  onClick={() =>
                                    readOnly ? null : handleSizeChartRemove()
                                  }
                                />
                                {/* </Button> */}
                              </div>
                              <div className={classes.profileImgContainer}>
                                <img
                                  src={TShirtSizeChart}
                                  style={{
                                    width: "60px",
                                    height: "45px",
                                    maxHeight: "45px",
                                    minHeight: "45px",
                                  }}
                                  alt=""
                                  onClick={() => {
                                    this.setState({
                                      title: "T-Shirt Size Chart",
                                      showImage: true,
                                      imagePath: TShirtSizeChart,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <Button disabled={readOnly}>
                                <label htmlFor="sizechart">
                                  <CloudUpload
                                    style={{
                                      color: "#09456C",
                                      cursor: "pointer",
                                    }}
                                  />
                                </label>{" "}
                              </Button>

                              <input
                                id="sizechart"
                                type="file"
                                style={{ display: "none" }}
                                accept="image/x-png,image/jpeg"
                                onChange={(e) => {
                                  handleSizeChartUpload(e, row);
                                }}
                                disabled={readOnly}
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div style={{ textAlign: "center" }}>-</div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ImageDialogBox
          title={this.state.title}
          open={this.state.showImage}
          imagePath={this.state.imagePath}
          onClick={() => this.closeWindow()}
          closeDisloag={() => this.closeWindow()}
        />
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(EventItemsTable);
