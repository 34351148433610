import React from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  IconButton,
  Divider,
  Box,
  Table,
  TableRow,
  TableBody,
} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Properties from "../../../properties/Properties";
import ApplicationUtil from "../../../utils/ApplicationUtil";
import {
  getSupportEventList,
  getOrganizerEventList,
} from "../../../apis/eventService";
import logo from "../login/ndLogo.png";
import AppToolBar from "./../../common/AppToolBar";
import ListPageLoader from "./../../common/ListPageLoader";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  boxCss: {
    display: "flex",
    alignItems: "center",
  },
  boxPaddingCss: {
    paddingRight: "0px !important",
  },
  root: {
    flexGrow: 1,
    marginLeft: "6.7%",
    marginRight: "6.7%",
    boxShadow: "none !important",
  },
});

class SupportEventList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      userName: "",
      value: 0,
      data: null,
      totalRecords: 0,
    };
  }

  // state = {
  //   anchorEl: null,
  //   userName: "",
  //   value: 0,
  //   data: null,
  //   totalRecords: 0,
  // };

  componentDidMount() {
    document.title = Properties.Page_Title_Event_Details;
    this.setState({ userName: localStorage.getItem("supportUserName") });

    if (!ApplicationUtil.isuserLoggedIn()) {
      this.props.history.push("/");
    } else {
      if (localStorage.getItem("filterData") !== null) {
        localStorage.removeItem("filterData");
      }
      let customParams = {
        pageCount: 20,
        pageNumber: 0,
        sortKey: "eventDate",
        sortOrder: false,
      };

      getSupportEventList(customParams).then((res) => {
        this.setState({
          data: res.data.data,
          totalRecords: res.data.totalRecords,
        });
      });
    }
  }

  getList = (isFristPage, page) => {
    let customeParams = {
      pageCount: 20,
      pageNumber: page ? page : 0,
      sortKey: "eventDate",
      sortOrder: false,
    };
    getSupportEventList(customeParams).then((res) => {
      let newdata = page
        ? this.state.data.concat(res.data.data)
        : res.data.data;

      this.setState({ data: newdata, totalRecords: res.data.totalRecords });
    });
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleLogout = (e) => {
    localStorage.clear();

    ApplicationUtil.go("/support");
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  getHeaderTitleWithIcon = () => {
    const { classes } = this.props;
    return (
      <div style={{ width: "100%", backgroundColor: "#3a6ea5" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
          p={1}
          bgcolor="#3a6ea5"
        >
          <Box
            p={1}
            classes={{
              root: classes.boxCss,
            }}
            flexGrow={1}
          >
            <div style={{ width: "auto", color: "#ffffff" }}>
              {Properties.Label_EventsList}
            </div>
          </Box>
          <Box
            p={2}
            display="flex"
            justifyContent="flex-end"
            classes={{
              root: classes.boxPaddingCss,
            }}
          >
            <span
              onClick={this.handleClick}
              style={{
                color: "#FFFFFF",
                cursor: "pointer",
              }}
            >
              {this.state.userName}
            </span>
          </Box>
        </Box>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
        {/* <div style={{ width: "100%", backgroundColor: "#2C78FF" }}>
          <Box display="flex" p={1} bgcolor="#2C78FF">
            <Box p={1} flexGrow={1}>
              <div style={{ width: "auto" }}>
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    height: "50px",
                    width: "50px",
                  }}
                />
              </div>
            </Box>

            <Box
              p={2}
              display="flex"
              justifyContent="flex-end"
              classes={{
                root: classes.boxPaddingCss,
              }}
            >
              <IconButton>
                <span
                  onClick={(e) => {
                    localStorage.clear();
                   
                      ApplicationUtil.go("/support");
                   
                  }}
                  style={{
                    color: "#FFFFFF",
                    fontSize: "16px",
                    fontFamily: "Ubuntu",
                    cursor: "pointer",
                  }}
                >
                  {this.state.userName}
                </span>
              </IconButton>
            </Box>
          </Box>
          <Divider style={{ height: "5px", backgroundColor: "#08456C" }} />
        </div> */}
        <AppToolBar
          backNavigate={() => this.props.history.goBack()} //goBack()} //
          showBack={true}
        >
          {this.getHeaderTitleWithIcon()}
          <div style={{ width: 35 }} />
        </AppToolBar>
        <div>
          <ListPageLoader
            // style={{width:"100%"}}
            pageParams={{
              page: 0,
              start: 0,
              limit: 20,
              TotalRecords: this.state.totalRecords,
            }}
            totalLoadedLength={this.state.data ? this.state.data.length : 0}
            //divHeight={window.innerHeight - 190}
            loadNextData={(params) => {
              this.getList(false, params.page);
            }}
          >
            <Table aria-label="customized table">
              {this.state.data && this.state.data.length > 0 ? (
                this.state.data.map((row, index) => (
                  <TableBody>
                    <TableRow
                      key={index}
                      onClick={(e) => {
                        localStorage.setItem(
                          "supportEventId",
                          JSON.stringify(row.id)
                        );
                        localStorage.setItem("supportEventName", row.name);

                        ApplicationUtil.go("/support/list");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div style={{ display: "flex", width: "100%" }}>
                        <div
                          style={{
                            width: "80%",
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "#2883B5",
                          }}
                        >
                          {/* FreedomToFitness */}
                          {row.name}
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: "700",
                          color: " #424242",
                        }}
                      >
                        {/* 12/12/2020 */}
                        {ApplicationUtil.FormatDateToUTC(
                          row.eventDate,
                          row.eventTime
                        )}
                      </div>
                    </TableRow>
                    <Divider style={{ height: "3px", color: "#727272" }} />
                  </TableBody>
                ))
              ) : (
                <div style={{ padding: "15px 8px 15px 8px" }}>
                  {"No records to display"}
                </div>
              )}
            </Table>
          </ListPageLoader>
        </div>

        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          style={{ top: "7%" }}
        >
          {/* <MenuItem onClick={this.handleClose}>Profile</MenuItem> */}
          <MenuItem>Change Password</MenuItem>
          <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }),withRouter)(
  SupportEventList
);
