import { Grid, withStyles } from "@material-ui/core";
import React, { useRef } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import Properties from "../../../../properties/Properties";
import AppToolBar from "../../../common/AppToolBar";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import Header from "../../../common/Header";
import { getRegisteredEventDetails } from "../../../../apis/eventService";
import Clamp from "react-multiline-clamp";
import MessageIcon from "@material-ui/icons/Message";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import moment from "moment";
import RunnerSideBreadcrumbs from "../../../common/RunnerSideBreadcrumbs";
const styles = (theme) => ({
  mainPanel: {
    marginTop: "66px",
    marginLeft: "6.7%",
    marginRight: "6.7%",
  },
  titleCss: {
    fontSize: "20px",
    fontWeight: 600,
    paddingBottom: "4px",
    color: "#F79824",
  },
  tableRowCss: {
    border: "1px lightgray solid",
    padding: "12px",
  },
  dateCss: {
    fontWeight: 600,
  },
  msgContainer: {
    width: "100%",
  },
  layout: {
    display: "flex",
    alignItems: "flex-start",
    padding: "10px",

    // backgroundColor: "#e9ecef",
    borderRadius: "5px",
    marginBottom: "3px",
  },
  container: {
    marginTop: "20px",
  },
  content: {
    marginRight: "10px",
    marginLeft: "10px",
    width: "100%",
  },
  message: {
    paddingTop: "10px",
    width: "100%",
    "& .button": {
      color: "blue",
    },
  },
  details: {
    display: "flex",
    justifyContent: "space-between",

    color: "gray",
    "& .name": {
      // fontSize: "20px",
      fontWeight: 600,
      color: "#2883b5",
    },
  },
  icon: {
    color: "#2883b5",
  },
  duration: {
    display: "flex",
    "& >*": {
      marginLeft: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
});
class FeedbackList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comments: null,
    };
  }

  inputRefs = [];

  setRef = (ref) => {
    this.inputRefs.push(ref);
  };

  focusInput = (id) => this.inputRefs[id].focus();

  componentDidMount() {
    let runnerData = ApplicationUtil.getRunnerDetails();
    let eventData = ApplicationUtil.getRunnersEventsDetails();
    let eventId = eventData?.id;

    let runnerId = runnerData?.runner?.id;
    let messagesFromTraining = this.props.history.location?.messages;
    // let parseMessages = localStorage.getItem("trainingUserMessages");
    // let messagesFromTraining = JSON.parse(parseMessages);
    // console.log(messagesFromTraining);
    getRegisteredEventDetails(runnerId, eventId).then((res) => {
      let list = [];
      let userMessages = res.data?.event?.userMessages;
      if (userMessages) {
        for (let i = 0; i < userMessages.length; i++) {
          let ele = userMessages[i];
          if (ele?.activityId === undefined) {
            ele.highlight = false;
            if (messagesFromTraining) {
              let findObj = messagesFromTraining.find(
                (x) => x.messageDate == ele.messageDate
              );
              if (findObj) {
                ele.highlight = true;
              }
            }
            list.push(ele);
          }
        }
        // list = res.data.event.userMessages.filter(
        //   (ele) => ele?.activityId === undefined
        // );
      }
      console.log("list", list);
      this.setState({
        comments: list,

        eventName: eventData?.name,
        eventDate: ApplicationUtil.changeDateFormat(eventData.localStartDate),
        eventEnd: ApplicationUtil.changeDateFormat(eventData.localEndDate),
      });
    });
  }

  isEllipsisActive = (e) => {
    console.log(e);
    return e?.offsetHeight < e?.scrollHeight || e?.offsetWidth < e?.scrollWidth;
  };
  goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  formatTimeStamp = (date) => {
    let splitDate = date.split(" ");
    let formatDate = ApplicationUtil.changeDateFormatDate(splitDate[0]);
    let today = moment().format("YYYY-MM-DD");
    let isToday = moment(formatDate).isSame(today);
    let value = "";
    let time = splitDate[1].split(":");
    if (isToday) {
      // value = moment(date, "DD-MM-YYYY hh:mm:ss")
      //   .fromNow()
      //   .replace("hours", "hrs")
      //   .replace("hour", "hr")
      //   .replace("minutes", "mins")
      //   .replace("ago", "");
      // value = value[0];
      value = `${time[0]}:${time[1]}`;
    } else {
      value = date;
    }
    return value;
  };
  render() {
    const { classes } = this.props;
    const { comments, eventName, eventDate, eventEnd } = this.state;

    return (
      <React.Fragment>
        <AppToolBar
          backNavigate={() => this.props.history.goBack()}
          showBack={true}
        >
          <Header
            {...this.props}
            label={eventName ? eventName : Properties.Label_Feedback}
            fromDate={eventDate}
            toDate={eventDate === eventEnd ? false : eventEnd}
          />
        </AppToolBar>

        <div className={classes.mainPanel}>
          <RunnerSideBreadcrumbs />
          <span className={classes.titleCss}>{"Messages"}</span>

          <div className={classes.container}>
            {comments?.map(
              ({ messageDate, message, sender, highlight }, idx) => (
                <div
                  className={classes.layout}
                  style={{ backgroundColor: highlight ? "#ADE8F4" : "#e9ecef" }}
                  key={idx}
                >
                  <div>
                    <MessageIcon className={classes.icon} />
                  </div>
                  {/* content */}
                  <div className={classes.content}>
                    {/* name, date */}
                    <div className={classes.details}>
                      <span className="name">{sender}</span>
                      {/* <div className={classes.duration}> */}
                      {/* <span className="date">28-03-2022</span> */}
                      <span className="time">
                        {this.formatTimeStamp(messageDate)}
                      </span>
                      {/* </div> */}
                    </div>
                    {/* message */}
                    <div className={classes.message}>
                      <Clamp
                        lines={2}
                        maxLines={1000}
                        withToggle
                        showMoreElement={({ toggle }) => (
                          <span className="button" onClick={toggle}>
                            Read more
                          </span>
                        )}
                        showLessElement={({ toggle }) => (
                          <span className="button" onClick={toggle}>
                            Show Less
                          </span>
                        )}
                      >
                        {/* {message} */}
                        {ReactHtmlParser(message)}
                      </Clamp>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          {/* <div style={{ paddingTop: "10px" }}>
            {comments?.length > 0 ? (
              <div className={classes.msgContainer}>
                {comments?.map((ele, i) => (
                  <Grid
                    key={i}
                    className={classes.tableRowCss}
                    style={{
                      borderTop: i === 0 ? "1px lightgray solid" : "none",
                    }}
                  >
                    {" "}
                    <Clamp
                      texts={{ showMore: "Moreeee", showLess: "Menos" }}
                      lines={3}
                      maxLines={1000}
                      withToggle
                      onShowMore={(show) => console.log(show)}
                    >
                      {ele.message}
                    </Clamp>
                    {ele?.messageDate && (
                      <div className={classes.dateCss}>
                        {ApplicationUtil.changeDateFormat(ele.messageDate)}
                      </div>
                    )}
                  </Grid>
                ))}
              </div>
            ) : (
              <div>No Feedback Recieved</div>
            )}
          </div> */}
        </div>
      </React.Fragment>
    );
  }
}
export default compose(
  withStyles(styles, { withTheme: true }),
  //   connect(mapStateToProps, {
  //     updateUserDetails,
  //   }),
  withRouter
)(FeedbackList);
