import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import ListBlock from "./ListBlock";
import Properties from "../../../../../properties/Properties";
import moment from "moment";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";

function RenderProgramChallenges({
  programData,
  isUserRegistered,
  isVideoViewedState,
  handleVideoViewed,
  isMainBannerLoaded,
}) {
  const [openBlock, setOpenBlock] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(null);
  let search = window.location.href;
  let paramChallengeId = search.split("challengeId=")[1];
  const isProgram = window.location.href?.includes("program");
  const currentRunningChallenge =
    ApplicationUtil.getCurrentRunningProgramChallenge(
      programData?.programChallenges
    );
  const handleBlockOpenByChallengeId = () => {
    if (paramChallengeId) {
      setOpenBlock({ challengeId: paramChallengeId });
    }
    if (
      !paramChallengeId &&
      isProgram &&
      !isVideoViewedState &&
      currentRunningChallenge
    ) {
      let challengeStartDate =
        currentRunningChallenge?.challengeStartDate?.split(" ")[0];
      const today = moment().format("DD-MM-YYYY");
      const isSameDay = moment(challengeStartDate, "DD-MM-YYYY").isSame(
        moment(today, "DD-MM-YYYY"),
        true
      );
      if (isSameDay) {
        setOpenBlock({ challengeId: currentRunningChallenge?.id });
      }
    }
  };

  const handleOpenBlock = (id) => {
    if (openBlock?.challengeId == id) {
      setOpenBlock(null);
      return;
    }
    setOpenBlock({ challengeId: id });
  };

  const handleScroll = () => {
    let ele = document.getElementById("challenges_" + openBlock?.challengeId);
    let bannerElement = document.getElementById("banner");
    let isBannerLoaded = bannerElement ? bannerElement?.complete : true;
    if (ele && isBannerLoaded && videoLoaded) {
      // setTimeout(() => {
      ele.scrollIntoView({
        block: "center",
        inline: "start",
        behavior: "instant",
      });
      // }, 150);
    }
  };

  useEffect(() => {
    if (ApplicationUtil.isuserLoggedIn()) {
      handleBlockOpenByChallengeId();
    }
  }, [currentRunningChallenge, programData]);

  useEffect(() => {
    setTimeout(() => {
      handleScroll();
    }, 100);
  }, [openBlock, isMainBannerLoaded, videoLoaded]);

  return (
    <div>
      {programData?.programChallenges?.length && (
        <ListBlock
          isUserRegistered={isUserRegistered}
          title={Properties.Label_EventDetails_Program_challenges_title}
          items={programData?.programChallenges}
          blockFor={"challenges"}
          currentRunningChallenge={currentRunningChallenge}
          handleOpenBlock={handleOpenBlock}
          openBlock={openBlock}
          isVideoViewedState={isVideoViewedState}
          handleVideoViewed={handleVideoViewed}
          setVideoLoaded={setVideoLoaded}
        />
      )}
    </div>
  );
}

const styles = (theme) => ({});
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(RenderProgramChallenges);
