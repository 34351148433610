import React, { Component } from "react";
import AppToolBar from "../../common/AppToolBar";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  IconButton,
  Box,
  Card,
  CardMedia,
  CardActionArea,
  Grid,
  TextareaAutosize,
} from "@material-ui/core";
import { format } from "date-fns";
import ApplicationConstants from "../../../constants/ApplicationConstants";
import MultiSelect from "../../common/MultiSelect";
import Properties from "../../../properties/Properties";
import { CustomTextField } from "../../common/CustomTextField";
import SubmitCancelBtn from "../../common/SubmitCancelBtn";
import { isMobile } from "react-device-detect";
import { AttachmentIcon, DeleteTrashIcon } from "../../common/SvgIcon";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { IconCalendarBlue } from "../../common/SvgIcon";
import RegistrationUtil from "./RegistrationUtil";
import uploadRunActivity from "../../../apis/uploadRunService";
import Snackbar from "../../common/Snackbar";
import ImageDialogBox from "../../common/ImageDialogBox";
import { updateActivity } from "../../../apis/eventService";
import ApplicationUtil from "../../../utils/ApplicationUtil";
import CancelIcon from "@material-ui/icons/Cancel";
import DialogBox from "../../common/DialogBox";
import ImageSlider from "../../common/ImageSlider";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
const styles = (theme) => ({
  main: {
    // marginTop: "85px",
  },
  container: {
    // width: '95%',
    // margin: 'auto',
    paddingLeft: 20,
    paddingRight: 20,
  },
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
  },

  activityDateContainer: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    maxWidth: "100%",
  },
  timeError: {
    border: "1px solid red",
    height: "38px",
    maxHeight: "38px",
    minHeight: "38px",
    width: "50%",
  },
  time: {
    flex: 1,
    border: "1px solid #08456C",
    height: "38px",
    maxHeight: "38px",
    minHeight: "38px",
    width: "50%",
  },
  datePickerWidth: {
    flex: 1,
    marginRight: "10px",
  },
  inputField: {
    border: "1px solid #08456C",
    height: "38px",
    maxHeight: "38px",
    minHeight: "38px",
    width: "100%",
  },
  otherField: {
    border: "1px solid #08456C",
    height: "38px",
    maxHeight: "38px",
    minHeight: "38px",
    width: "100%",
    marginTop: "23px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
  inputFieldError: {
    border: "1px solid red",
    height: "38px",
    maxHeight: "38px",
    minHeight: "38px",
    width: "100%",
  },
  multiSelect: {
    width: "100%",
    // marginLeft: '10px'
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    // justifyContent: 'flex-end',
    // border: '1px solid gray',
    boxShadow:
      "0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 7px 0 rgba(0, 0, 0, 0.16)",
    padding: 10,
    marginBottom: "5px",
    //  maxWidth: "300px",
    //   minWidth: "300px",
  },
  imageLayout: {
    display: "flex",
    // position: "relative",
    margin: "auto",
    width: "100%",
    // boxShadow:
    // "0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 7px 0 rgba(0, 0, 0, 0.16)",
    // maxHeight: "300px",
    // minHeight: "300px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      overflowX: "hidden",
    },
    "& img": {
      objectFit: "contain",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  textarea: {
    resize: "none",
  },
  carousel: {
    marginTop: "40px",
    width: "200px",
    height: "200px",
    paddingBottom: "25px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      width: "100%",
      height: "max-content",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      width: "60%",
      height: "max-content",
    },
    [theme.breakpoints.up("md")]: {
      width: "100px",
      height: "100px",
    },
  },
  carousel_inner: {
    height: "150px",
    width: "150px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    cursor: "pointer",
    // paddingBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      width: "100px",
      height: "100px",
    },
  },

  carousel_inner_left: {
    flex: "8%",
    height: "100%",
    backgroundColor: " rgba(0, 0, 0, 0.4)",
    display: "grid",
    placeItems: "center",
    color: "white",
    cursor: "pointer",
  },

  carousel_inner_center: {
    flex: "80%",
    height: "100%",
  },

  carousel_inner_right: {
    flex: "8%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    display: "grid",
    placeItems: "center",
    color: "white",
    cursor: "pointer",
  },
  displayFields: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
});
const TextTypography = ({ text, color, fontSize }) => {
  return (
    <Typography
      variant="body2"
      gutterBottom
      style={{ color: color || "", fontSize: fontSize || "" }}
    >
      {text}
    </Typography>
  );
};
const FieldLabel = ({ label, required }) => {
  return (
    <Typography variant="body2" gutterBottom>
      <span style={{ color: "#727272" }}>{label}</span>
      {required && <span style={{ color: "red" }}>*</span>}
    </Typography>
  );
};
const ImageContainer = ({
  classes,
  source,
  fileName,
  fileSize,
  fileType,
  onImageClick,
  onDeleteIconClick,
  widthRequired,
}) => {
  return (
    <div className={classes.imageContainer}>
      {onDeleteIconClick && (
        <div className={classes.cancelIcon}>
          <IconButton onClick={onDeleteIconClick} size="small">
            <CancelIcon />
          </IconButton>
        </div>
      )}
      <div className={classes.imageLayout}>
        <img src={source} alt="post run image" onClick={onImageClick} />
        {/* <div style={{ marginLeft: '10px' }}>
                  <TextTypography text={fileName} />
                  <TextTypography text={`Size: ${fileSize}`} />
                  <TextTypography text={`File Type: ${fileType}`} />

              </div> */}
      </div>
    </div>
  );
};

class UploadForm extends Component {
  state = {
    limit: 3,
    userName: "",
    anchorEl: null,
    hour: "",
    hourError: "",
    minute: "",
    minuteError: "",
    second: "",
    secondError: "",
    activityUrl: "",
    activityUrlError: false,
    image: null,
    selectedFile: null,
    hoursList: ApplicationConstants.Hours,
    uploadedFiles: [],
    postRunPicture: [],
    postRunPreview: null,
    image_src: null,
    preview: null,
    imageDialog: false,
    date: null,
    startTime: "06:00",
    distance: "",
    distanceError: false,
    eventType: "",
    steps: "",
    calories: "",
    elevationGain: "",
    otherActivities: null,
    deletedFiles: [],
    activityName: "",
    activityNameError: false,
    description: "",
    // selectedActivityType:null,
    activityTypeError: false,
    otherActivityText: "",
    screenshotCurImg: 0,
  };
  componentDidMount() {
    this.setDetails();
  }
  componentDidUpdate(previousProps) {
    if (this.props.activityDetails !== previousProps.activityDetails) {
      this.setDetails();
    }
  }

  setDetails = () => {
    const { activityDetails, activityTypeList, selectedActivityType } =
      this.props;
    if (activityDetails != undefined) {
      let time = activityDetails?.completionTime?.split(":");
      let dateTime = activityDetails?.startDateLocal?.split(" ");
      let subActivityType = activityDetails?.subActivityType;
      let hh, mm, ss;
      if (time) {
        ApplicationConstants.Hours.map((e) => {
          if (e.value == time[0]) {
            hh = e;
          }
        });
        ApplicationConstants.valueArray.map((e) => {
          if (e.value == time[1]) {
            mm = e;
          }
          if (e.value == time[2]) {
            ss = e;
          }
        });
      }

      let sel = activityTypeList?.filter((selected) => {
        return selected.type == subActivityType;
      });
      sel = Object.assign({ ...sel}, sel);
      
      let postRunFile,
        postRunPreview,
        activityScreenShots = [];
      if (
        activityDetails?.postRunImageStatus === "AVAILABLE" &&
        activityDetails?.postRunImages?.images[0]
      ) {
        postRunFile = ApplicationUtil.dataURLtoFile(
          `data:image/png;base64,${activityDetails?.postRunImages?.images[0]?.imageData}`,
          activityDetails?.postRunImages?.images[0]?.displayName
        );
        postRunFile.id = activityDetails?.postRunImages?.images[0]?.fileId;

        postRunPreview =
          "data:image/png;base64," +
          activityDetails?.postRunImages?.images[0]?.imageData;
      }
      if (
        activityDetails?.activityScreenshotStatus === "AVAILABLE" &&
        activityDetails?.activityScreenShots?.images?.length > 0
      ) {
        for (
          let i = 0;
          i < activityDetails.activityScreenShots.images.length;
          i++
        ) {
          let file = ApplicationUtil.dataURLtoFile(
            `data:image/png;base64,${activityDetails.activityScreenShots.images[i]?.imageData}`,
            activityDetails.activityScreenShots.images[i]?.displayName
          );
          let key = "uploadedId_" + parseInt(Math.random() * 1000000);

          file.id = activityDetails.activityScreenShots.images[i].fileId;
          file.src = URL.createObjectURL(file);
          activityScreenShots.push(file);
        }
      }

      this.setState({
        distance: activityDetails.distance / 1000,
        hour: hh,
        minute: mm,
        second: ss,
        activityUrl: activityDetails.activityUrl,
        steps: activityDetails?.steps,
        calories: activityDetails?.calories,
        // date: date,
        startTime: dateTime[1],
        selectedActivityType: sel,
        postRunPicture: postRunFile,
        postRunPreview: postRunPreview,

        uploadedFiles: activityScreenShots,
        activityName: activityDetails.name,
        description: activityDetails.description,
      });
    }
  };

  deleteFile = (key) => {
    let uploadedFiles = this.state.uploadedFiles.filter((file) => {
      if (file.key !== undefined && file.key !== key) return true;
      else if (file.id !== undefined && file.id !== key) return true;
    });
    let deletedFiles = this.state.deletedFiles;
    deletedFiles.push(key);
    this.setState({ uploadedFiles: uploadedFiles, deletedFiles: deletedFiles });
  };
  handleImageDelete = (key) => {
    let deletedFiles = this.state.deletedFiles;
    deletedFiles.push(key);
    this.setState({
      postRunPicture: null,
      postRunPreview: null,
      deletedFiles: deletedFiles,
    });
  };
  handleHourChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        hour: selectedValues,
        hourError: false,
        minuteError: false,
        secondError: false,
      });
    } else {
      this.setState({
        hour: selectedValues,
        hourError: true,
      });
    }
  };

  handleMinuteChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        minute: selectedValues,
        hourError: false,
        minuteError: false,
        secondError: false,
      });
    } else {
      this.setState({
        minute: selectedValues,
        minuteError: true,
      });
    }
  };

  handleSecondChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        second: selectedValues,
        hourError: false,
        minuteError: false,
        secondError: false,
      });
    } else {
      this.setState({
        second: selectedValues,
        secondError: true,
      });
    }
  };

  handleCategoryChange = (selectedValues) => {
    var arr = [];

    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        eventCategory: selectedValues,
        eventCategoryError: false,
      });
    } else {
      this.setState({
        eventCategory: selectedValues,
        eventCategoryError: true,
      });
    }
  };

  // single image upload
  clearImage = (e) => {
    e.target.value = null;
  };

  multipleUpload = (e) => {
    if (e.target.id == "contained-button-file") {
      if (
        Array.from(e.target.files)?.every(
          (file) => file?.type === "image/png" || file?.type === "image/jpeg"
        )
      ) {
        if (this.state.uploadedFiles?.length + e.target.files.length > 5) {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Please select five or less than five images .",
          });
          e.preventDefault();
        } else {
          let files = e.target.files;
          this.setState({ activityUrlError: false });
          this.setState({
            uploadedFiles: this.state.uploadedFiles
              .concat(
                Array.from(files).map((file) => {
                  let key = "uploadedId_" + parseInt(Math.random() * 1000000);

                  file.id = key;
                  file.src = URL.createObjectURL(file);
                  return file;
                })
              )
              .reverse(),
          });
        }
      } else {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "only jpeg or png image type is allowed",
        });
      }
    }
    e.target.value = null;
  };

  multipleUploadPost = (e) => {
    if (e.target.id == "postrun-button-file") {
      if (
        Array.from(e.target.files)?.every(
          (file) => file?.type === "image/png" || file?.type === "image/jpeg"
        )
      ) {
        let files = e.target.files[0];
        files.id = "uploadedId_" + parseInt(Math.random() * 1000000);
        let imgsrc = URL.createObjectURL(files);
        this.setState({
          postRunPicture: files,
          postRunPreview: imgsrc,
        });
      } else {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "only jpeg or png image type is allowed",
        });
      }
    }
  };

  handleImageUpload = (e) => {
    let files = e.target.files[0];
    let imgsrc = URL.createObjectURL(files);
    this.setState({
      postRunPicture: files,
      postRunPreview: imgsrc,
    });
  };
  fullImageView = (key) => {
    let Files = this.state.uploadedFiles.filter((file) => {
      if (file.id !== undefined && file.id !== key) return true;
      this.setState({ imageDialog: true, image_src: file.src });
    });
    //this.setState({preview:Files.src});
  };
  fullPostRunPicture() {
    let file = this.state.postRunPicture;
    this.setState({ imageDialog: true, image_src: this.state.postRunPreview });
  }
  goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  validateFields = () => {
    const {
      hour,
      minute,
      second,
      activityUrl,

      startTime,
      distanceError,
      distance,
      steps,
      calories,
      elevationGain,
      uploadedFiles,
      activityName,
    } = this.state;
    const {
      selectedCategory,
      displayColumns,
      timeValidation,
      date,
      dateError,
      handleDateChange,
      activityDetails,
      selectedActivityType,
      showActivityList,
      skipTimeOnUploadActivity,
    } = this.props;
    let displayFields = !showActivityList
      ? selectedActivityType?.displayColumns ||
        this.props.selectedActivityType?.displayColumns
      : displayColumns;

    if (displayFields?.length > 0) {
      if (displayFields.includes("steps") && steps === "") {
        this.setState({ stepsError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.StepsValidationLabel,
        });
        this.goToTop();
        return;
      } else if (displayFields.includes("calories") && calories === "") {
        this.setState({ caloriesError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.CaloriesValidationLabel,
        });
        this.goToTop();
        return;
      } else if (
        displayFields.includes("elevationGain") &&
        elevationGain === ""
      ) {
        this.setState({ elevationGainError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.ElevationValidationLabel,
        });
        this.goToTop();
        return;
      } else if (displayFields.includes("distance") && distance == 0) {
        this.setState({ distanceError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.DistanceNonZeroValidationLabel,
        });
        this.goToTop();
        return;
      } else if (
        (displayFields.includes("completionTime") ||
          displayFields.includes("time")) &&
        (hour == "" || hour?.value == 0) &&
        (minute == "" || minute?.value == 0) &&
        (second == "" || second?.value == 0) &&
        !skipTimeOnUploadActivity
      ) {
        this.setState({
          hourError: hour == "" ? true : false,
          minuteError: minute == "" ? true : false,
          secondError: second == "" ? true : false,
        });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.CompletionTimeValidationLabel,
        });
        this.goToTop();
        return;
      }
      // Defect_ID_407 / ankit  
      else if (activityName === "") {
        this.setState({ activityNameError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please enter activity name",
        });
        this.goToTop();
        return;
      } else if (date == null || date == "" || dateError) {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.Upload_Activity_InvalidDate,
        });
        handleDateChange(date, true);
        // this.setState({ dateError: true });
        this.goToTop();
        return;
      } else if (startTime == "") {
        this.setState({ startTimeError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.InvalidStartTime,
        });
        this.goToTop();
        return;
      } else if (
        timeValidation == "MANDATORY" &&
        this.state.uploadedFiles.length == 0 &&
        this.state.activityUrl == ""
      ) {
        this.setState({ activityUrlError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.Upload_Activity_Validation_label,
        });
        this.goToTop();
        return;
      } else {
        if (activityDetails) {
          this.saveUpdateFields();
        } else {
          this.saveFields();
        }
      }
      // else if (activityUrl === "" && uploadedFiles.length == 0) {
      //   this.setState({ activityUrlError: true });
      //   Snackbar.show({
      //     variant: "error",
      //     isAutoHide: true,
      //     preventDuplicate: true,
      //     message: Properties.Upload_Activity_Validation_label,
      //   });
      //   this.goToTop();
      //   return;
      // }
    } else {
      if (distance === "") {
        this.setState({ distanceError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.DistanceValidationLabel,
        });

        this.goToTop();
        return;
      } else if (
        // hour == "" &&
        // minute == "" &&
        // second == ""
        (hour == "" || hour.value == 0) &&
        (minute == "" || minute.value == 0) &&
        (second == "" || second.value == 0)
      ) {
        this.setState({
          hourError: hour == "" ? true : false,
          minuteError: minute == "" ? true : false,
          secondError: second == "" ? true : false,
        });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.CompletionTimeValidationLabel,
        });
        this.goToTop();
        return;
      } else if (activityName === "") {
        this.setState({ activityNameError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please enter activity name",
        });
        this.goToTop();
        return;
      } else {
        if (date === null || date === "" || dateError) {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Upload_Activity_InvalidDate,
          });
          handleDateChange(date, true);
          this.goToTop();
          return;
        } else if (startTime === "") {
          this.setState({ startTimeError: true });
          this.goToTop();
          return;
        } else if (
          timeValidation == "MANDATORY" &&
          this.state.uploadedFiles.length == 0 &&
          this.state.activityUrl == ""
        ) {
          this.setState({ activityUrlError: true });
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Upload_Activity_Validation_label,
          });
          this.goToTop();
          return;
        }
        // else if (activityUrl === "" && uploadedFiles.length == 0) {
        //   this.setState({ activityUrlError: true });
        //   Snackbar.show({
        //     variant: "error",
        //     isAutoHide: true,
        //     preventDuplicate: true,
        //     message: Properties.Upload_Activity_Validation_label,
        //   });
        //   this.goToTop();
        //   return;
        // }
        else {
          if (activityDetails !== null) {
            //update
            this.saveUpdateFields();
          } else {
            this.saveFields();
          }
        }
      }
    }
  };

  saveFields = () => {
    const {
      selectedCategory,
      date,
      handleNavigationAfterSuccessfulUpload,
      eventId,
      runnerId,
      selectedActivityType,
      trainingActivityData,
      eventType,
      eventCategoryList,
      showActivityList,
      skipTimeOnUploadActivity,
    } = this.props;
    let hr = this.state.hour?.value ? this.state.hour?.value : "00";
    let min = this.state.minute?.value ? this.state.minute?.value : "00";
    let sec = this.state.second?.value ? this.state.second?.value : "00";
    let completionTime = hr + ":" + min + ":" + sec;
    let customParams = {};
    let d = null;
    let startTime = this.state.startTime + ":" + "00";
    if (date) {
      let exactDate = format(new Date(date), "dd-MM-yyyy"); // this.state.date
      //moment(this.state.date).format("DD-MM-YYYY");
      //format(new Date(this.state.date), "dd-MM-yyyy");
      d = exactDate + " " + startTime;
      //customParams.uploadRequest = { startDate: d };
    }
    customParams.uploadRequest = {
      eventId: eventId,
      runnerId: runnerId,
      // activityUrl: this.state.activityUrl,
      category: selectedCategory.value,
      startDateLocal: d,
      name: this.state.activityName,
      activityType: showActivityList
        ? eventCategoryList?.activityType.type
        : selectedActivityType?.type,
    };
    if (this.state.activityUrl) {
      customParams.uploadRequest.activityUrl = this.state.activityUrl;
    }
    if (eventType === "TRAINING") {
      customParams.uploadRequest.subActivityType = showActivityList
        ? eventCategoryList?.activityType.type
        : selectedActivityType?.type;
    }
    if (selectedActivityType?.type === "OTHER") {
      customParams.uploadRequest.subActivityType =
        this.state?.otherActivityText;
    }

    if (!skipTimeOnUploadActivity) {
      customParams.uploadRequest.runCompletionTime = completionTime;
    }
    if (this.state.distance) {
      customParams.uploadRequest.distance =
        parseFloat(this.state.distance.replace("k", "")) * 1000;
    }
    if (this.state.steps) {
      // customParams.uploadRequest.distance = 0;
      customParams.uploadRequest.steps = this.state.steps;
      this.setState({ stepsError: false });
    }
    if (this.state.calories) {
      customParams.uploadRequest.calories = this.state.calories;
    }
    if (this.state.elevationGain) {
      customParams.uploadRequest.elevationGain = this.state.elevationGain;
    }
    if (this.state.description !== "") {
      customParams.uploadRequest.description = this.state.description;
    }
    customParams.file = this.state.uploadedFiles;
    customParams.postRunImages = this.state.postRunPicture;

    uploadRunActivity(customParams).then((res) => {
      handleNavigationAfterSuccessfulUpload();
    });
  };
  getDialogComponent = () => {
    const { uploadedFiles } = this.state;
    return (
      <div>
        <ImageSlider files={uploadedFiles} fileSourceImage />
      </div>
    );
  };
  deletePhoto = () => {
    const { uploadedFiles } = this.state;
    let currentImage = localStorage.getItem("currentImage");
    uploadedFiles.splice(currentImage, 1);
    this.setState({ uploadedFiles: uploadedFiles });
  };
  saveUpdateFields = () => {
    const {
      selectedCategory,
      date,
      handleNavigationAfterSuccessfulUpload,
      eventId,
      runnerId,
      activityDetails,
      selectedActivityType,
      trainingActivityData,
      eventType,
      displayColumns,
      skipTimeOnUploadActivity,
    } = this.props;
    // const { selectedActivityType } = this.state;
    let hr = this.state.hour?.value ? this.state.hour?.value : "00";
    let min = this.state.minute?.value ? this.state.minute?.value : "00";
    let sec = this.state.second?.value ? this.state.second?.value : "00";
    let completionTime = hr + ":" + min + ":" + sec;
    let customParams = {};
    let d = null;
    let startTime = this.state.startTime;
    if (date) {
      let exactDate = format(new Date(date), "dd-MM-yyyy"); // this.state.date
      //moment(this.state.date).format("DD-MM-YYYY");
      //format(new Date(this.state.date), "dd-MM-yyyy");
      d = exactDate + " " + startTime;
      //customParams.uploadRequest = { startDate: d };
    }

    customParams.uploadRequest = {
      eventId: eventId,
      runnerId: runnerId,
      activityUrl: this.state.activityUrl,
      category: selectedCategory.value,
      startDateLocal: d,
      name: this.state.activityName,
      type: selectedActivityType?.type, //activityType
    };
    if (eventType === "TRAINING") {
      customParams.uploadRequest.subActivityType = selectedActivityType?.type;
    }

    if (
      displayColumns?.includes("completionTime") &&
      !skipTimeOnUploadActivity
    ) {
      customParams.uploadRequest.completionTime = completionTime;
    }

    if (displayColumns?.includes("distance") && this.state.distance) {
      customParams.uploadRequest.distance =
        parseFloat(this.state.distance) * 1000;
    }
    if (displayColumns?.includes("steps") && this.state.steps) {
      // customParams.uploadRequest.distance = 0;
      customParams.uploadRequest.steps = this.state.steps;
      this.setState({ stepsError: false });
    }
    if (this.state.calories) {
      customParams.uploadRequest.calories = this.state.calories;
    }
    if (this.state.elevationGain) {
      customParams.uploadRequest.elevationGain = this.state.elevationGain;
    }
    if (this.state.deletedFiles) {
      customParams.uploadRequest.deletedFiles = this.state.deletedFiles;
    }

    let screenshots = this.state.uploadedFiles.filter((ele) => {
      return ele.id.toString().includes("uploadedId_");
      // return ele;
    });

    customParams.file = screenshots;

    if (this.state?.postRunPicture?.id?.toString().includes("uploadedId_")) {
      customParams.postRunImages = this.state.postRunPicture;
    }
    if (this.state.description !== "") {
      customParams.uploadRequest.description = this.state.description;
    }
    let activityId = activityDetails?.id;
    customParams.uploadRequest.approveRequest = false;
    updateActivity(customParams, activityId).then((res) => {
      handleNavigationAfterSuccessfulUpload(res.data);
    });
  };
  handleNumberOnlyInput = (e) => {
    if (e.nativeEvent.code === "KeyE" || e.nativeEvent.code === "Minus") {
      e.preventDefault();
    }
  };
  decreseScreenShotValue = () => {
    this.state.screenshotCurImg > 0 &&
      this.setState({ screenshotCurImg: this.state.screenshotCurImg - 1 });
  };

  increseScreenShotValue = () => {
    this.state.screenshotCurImg < this.state.uploadedFiles.length - 1 &&
      this.setState({ screenshotCurImg: this.state.screenshotCurImg + 1 });
  };
  handleActivityTypeChange = (selectedValues) => {
    this.setState({
      selectedActivityType: selectedValues,
    });
  };
  render() {
    const {
      classes,
      selectedCategory,
      eventCategoryList,
      displayColumns,
      timeValidation,
      showActivityDate,
      handleDateChange,
      handleNavigationAfterSuccessfulUpload,
      date,
      dateError,
      activityTypeList,
      activityDetails,
      selectedActivityType,
      handleActivityTypeChange,
      trainingActivityData,
      eventType,
      showActivityList,
      skipTimeOnUploadActivity,
    } = this.props;

    const {
      eventCategoryError,
      hour,
      hourError = "",
      minute,
      minuteError = "",
      second,
      secondError = "",
      activityUrl,
      activityUrlError = "",

      startTimeError = "",
      uploadedFiles,
      hoursList,
      startTime,
      steps,
      elevationGain,
      distance,
      calories,
      completionTime,
      stepsError,
      caloriesError,
      elevationGainError,
      distanceError,
      otherActivities,
      isAnyActivity,
      activityName,
      activityNameError,
      description,
      postRunPreview,
      activityTypeError,
      limit,
      showScreenshotDialog,
      otherActivityText,
      screenshotCurImg,
      // selectedActivityType,
    } = this.state;
    let displayFields = !showActivityList
      ? selectedActivityType?.primaryDisplayParams
          .split(",")
          .map((x) => x.toLowerCase())
      : displayColumns;
    if (!displayFields) {
      displayFields = displayColumns;
    }
    let runnerEvent = ApplicationUtil.getRunnersEventsDetails();

    let supportEvent = ApplicationUtil.getSupportsEventsDetails();
    let eventData = window.location.href.includes("/organizer")
      ? supportEvent
      : runnerEvent;
    return (
      <div className={classes.main}>
        <Grid container spacing={3} className={classes.container}>
          <Grid container item spacing={3} xs={12} sm={8}>
            {(eventType !== "TRAINING" || eventType !== "CHALLENGE") && (
              <Grid item xs={12}>
                <FieldLabel label={Properties.Category_label} required />
                <MultiSelect
                  value={selectedCategory}
                  error={eventCategoryError}
                  options={eventCategoryList}
                  isReadOnly={true}
                  // classes={{ icon: classes.icon }}
                  //   onChange={this.handleCategoryChange}
                />
              </Grid>
            )}
            {/* {!showActivityList &&  */}
            <>
              <Grid item xs={12} sm={6}>
                <FieldLabel label={Properties.Activity_type_label} required />
                <MultiSelect
                  value={
                    activityDetails?.subActivityType
                      ? selectedActivityType
                      : this.props.selectedActivityType
                  }
                  error={activityTypeError}
                  options={activityTypeList}
                  isReadOnly={showActivityList}
                  // classes()={{ icon: classes.icon }}
                  onChange={(selectedValue) =>
                    activityDetails?.subActivityType
                      ? this.handleActivityTypeChange(selectedValue)
                      : this.props.handleActivityTypeChange(selectedValue)
                  }
                />
              </Grid>
              {selectedActivityType?.type === "OTHER" && (
                <Grid item xs={12} sm={6}>
                  {/* <FieldLabel
                    label={" "}
                  // required={(column === "averagePace" || column === "averageSpeed") ? false : true}
                  /> */}
                  <CustomTextField
                    className={classes.otherField}
                    type={"text"}
                    name={"other"}
                    value={otherActivityText}
                    keyToSet={"other"}
                    handleChange={(prop) => (event) => {
                      this.setState({
                        otherActivityText: event.target.value,
                      });
                    }}
                  />
                </Grid>
              )}
            </>
            {/* } */}
            <Grid item xs={12} sm={6}>
              <FieldLabel label={Properties.Activity_Date_Time} required />
              <Grid item xs={12} className={classes.activityDateContainer}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  disableFuture={true}
                  value={date}
                  error={dateError}
                  className={classes.datePickerWidth}
                  keyboardIcon={
                    <IconCalendarBlue className={classes.calendarIconCss} />
                  }
                  InputProps={{
                    classes: {
                      root: false
                        ? classes.datePickerError
                        : classes.datePicker,
                    },
                  }}
                  onChange={(date) => handleDateChange(date, false)}
                />

                <CustomTextField
                  className={false ? classes.timeError : classes.time}
                  type={"time"}
                  value={startTime}
                  keyToSet={"startTime"}
                  error={startTimeError}
                  placeholder={"HH:MM:SS"}
                  handleChange={(prop) => (event) => {
                    this.setState({
                      startTime: event.target.value,
                      startTimeError: false,
                    });
                  }}
                />
              </Grid>
            </Grid>
            {/* display column */}
            {/* <Grid item xs={12} className={classes.displayFields}> */}
            {displayFields?.length > 0 ? (
              displayFields?.map((column) => (
                <Grid item xs={12} sm={6}>
                  <FieldLabel
                    label={
                      column === "steps"
                        ? "Steps"
                        : column === "calories"
                        ? "Calories"
                        : column === "distance"
                        ? Properties.Actual_distance_label + " (KM)"
                        : (column === "time" || column === "completionTime") &&
                          skipTimeOnUploadActivity == false
                        ? `${Properties.Activity_Duration}`
                        : column === "elevationGain"
                        ? "Elevation Gain"
                        : ""
                    }
                    required={
                      column === "averagePace" || column === "averageSpeed"
                        ? false
                        : (column === "time" || column === "completionTime") &&
                          skipTimeOnUploadActivity
                        ? false
                        : true
                    }
                  />
                  {column === "steps" ||
                  column === "calories" ||
                  column === "distance" ||
                  column === "elevationGain" ? (
                    <CustomTextField
                      className={
                        stepsError ||
                        caloriesError ||
                        elevationGainError ||
                        distanceError
                          ? classes.inputFieldError
                          : classes.inputField
                      }
                      type={"text"}
                      name={column}
                      value={
                        column === "steps"
                          ? steps
                          : column === "calories"
                          ? calories
                          : column === "distance"
                          ? distance
                          : column === "elevationGain"
                          ? elevationGain
                          : ""
                      }
                      keyToSet={column}
                      handleChange={(prop) => (event) => {
                        this.setState({
                          [event.target.name]: event.target.value,

                          [event.target.name + "Error"]:
                            /^(0|[1-9]\d*)(\.\d+)?$/.test(event.target.value)
                              ? false
                              : true,
                        });
                      }}
                      error={
                        stepsError
                          ? stepsError
                          : caloriesError
                          ? caloriesError
                          : elevationGainError
                          ? elevationGainError
                          : distanceError
                          ? distanceError
                          : false
                      }
                    />
                  ) : (
                    (column === "time" || column === "completionTime") &&
                    !skipTimeOnUploadActivity && (
                      // && skipTimeOnUploadActivity==false
                      // <Grid item xs={12} sm={6}>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.activityDateContainer}
                      >
                        <div className={classes.multiSelect}>
                          <MultiSelect
                            value={hour}
                            error={hourError}
                            options={hoursList}
                            onChange={this.handleHourChange}
                          />
                        </div>
                        <div
                          className={classes.multiSelect}
                          style={{ marginLeft: "10px" }}
                        >
                          <MultiSelect
                            value={minute}
                            error={minuteError}
                            options={ApplicationConstants.valueArray}
                            onChange={this.handleMinuteChange}
                          />
                        </div>
                        <div
                          className={classes.multiSelect}
                          style={{ marginLeft: "10px" }}
                        >
                          <MultiSelect
                            value={second}
                            error={secondError}
                            options={ApplicationConstants.valueArray}
                            onChange={this.handleSecondChange}
                          />
                        </div>
                      </Grid>
                      // </Grid>
                    )
                  )}
                </Grid>
              ))
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <FieldLabel
                    label={`${Properties.Actual_distance_label} (KM)`}
                    required
                  />
                  <CustomTextField
                    className={
                      distanceError
                        ? classes.inputFieldError
                        : classes.inputField
                    }
                    // type={"number"}
                    value={distance}
                    keyToSet={"distance"}
                    error={distanceError}
                    handleChange={(prop) => (event) => {
                      this.setState({
                        distance: event.target.value,
                        distanceError: /^(0|[1-9]\d*)(\.\d+)?$/.test(
                          event.target.value
                        )
                          ? false
                          : true,
                      });
                    }}
                  />
                </Grid>
                {skipTimeOnUploadActivity == false && (
                  <Grid item xs={12} sm={6}>
                    {skipTimeOnUploadActivity == false && (
                      <FieldLabel
                        label={Properties.Activity_Duration}
                        required={
                          skipTimeOnUploadActivity === false ? true : false
                        }
                      />
                    )}
                    {skipTimeOnUploadActivity == false && (
                      <Grid
                        item
                        xs={12}
                        className={classes.activityDateContainer}
                      >
                        <div className={classes.multiSelect}>
                          <MultiSelect
                            value={hour}
                            error={hourError}
                            options={hoursList}
                            onChange={this.handleHourChange}
                          />
                        </div>
                        <div
                          className={classes.multiSelect}
                          style={{ marginLeft: "10px" }}
                        >
                          <MultiSelect
                            value={minute}
                            error={minuteError}
                            options={ApplicationConstants.valueArray}
                            onChange={this.handleMinuteChange}
                          />
                        </div>
                        <div
                          className={classes.multiSelect}
                          style={{ marginLeft: "10px" }}
                        >
                          <MultiSelect
                            value={second}
                            error={secondError}
                            options={ApplicationConstants.valueArray}
                            onChange={this.handleSecondChange}
                          />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                )}
              </>
            )}
            {/* </Grid> */}
            {/* activity  anem */}
            <Grid item xs={12}>
              <FieldLabel label={Properties.ActivityNameLabel} required />
              <CustomTextField
                className={
                  activityNameError
                    ? classes.inputFieldError
                    : classes.inputField
                }
                type={"text"}
                value={activityName}
                name={"Activity name"}
                keyToSet={"activityName"}
                error={activityNameError}
                // placeholder={"Morning Run"}
                handleChange={(prop) => (event) => {
                  this.setState({
                    activityName: event.target.value,
                    activityNameError: false,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldLabel label={Properties.Label_Desciption} />
              <TextareaAutosize
                value={description}
                minRows={5}
                maxRows={10}
                // placeholder="Minimum 3 rows"
                // className={classes.inputField}
                style={{
                  width: "98%",
                  fontSize: "20px",
                  font: "inherit",
                  padding: "10px",
                }}
                onChange={(e) => {
                  this.setState({
                    description: e.target.value,
                  });
                }}
                inputProps={{ className: classes.textarea }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldLabel
                label={Properties.ActivityUrl_label}
                required={timeValidation == "MANDATORY"}
              />
              <CustomTextField
                className={
                  activityUrlError
                    ? classes.inputFieldError
                    : classes.inputField
                }
                type={"text"}
                keyToSet={"activityUrl"}
                value={activityUrl}
                handleChange={(prop) => (event) => {
                  this.setState({
                    activityUrl: event.target.value,
                    activityUrlError: false,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <TextTypography text={"OR"} />
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FieldLabel label={"Activity Screenshot"} />
                <label htmlFor="contained-button-file">
                  <AttachmentIcon
                    style={{
                      color: "#296DFA",
                    }}
                  />

                  <div style={{ display: "none" }}>
                    <input
                      className={classes.input}
                      id="contained-button-file"
                      multiple
                      type="file"
                      accept="image/x-png,image/jpeg"
                      max={limit}
                      onChange={this.multipleUpload}
                    />
                  </div>
                </label>
              </div>
              {uploadedFiles.length > 0 && (
                <div>
                  {/* <ImageContainer
                  {...this.props}
                  widthRequired
                  source={uploadedFiles[0]?.src}
                  onImageClick={() => {
                    // this.fullImageView(file.id)
                    this.setState({ showScreenshotDialog: true })
                  }}
                // onDeleteIconClick={() => this.deleteFile(file.id)}
                /> */}
                  <>
                    <div className={classes.carousel}>
                      <div
                        className={classes.carousel_inner}
                        style={{
                          backgroundImage: `url(${uploadedFiles[screenshotCurImg]?.src})`,
                        }}
                      >
                        <>
                          {uploadedFiles?.length > 1 && (
                            <div
                              className={classes.carousel_inner_left}
                              onClick={() => this.decreseScreenShotValue()}
                            >
                              <ArrowBackIos />
                            </div>
                          )}
                          <div
                            className={classes.carousel_inner_center}
                            onClick={() =>
                              this.setState({ showScreenshotDialog: true })
                            }
                          ></div>
                          {uploadedFiles?.length > 1 && (
                            <div
                              className={classes.carousel_inner_right}
                              onClick={() => this.increseScreenShotValue()}
                            >
                              <ArrowForwardIos />
                            </div>
                          )}
                        </>
                      </div>
                    </div>
                  </>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            {postRunPreview ? (
              <>
                <FieldLabel label={"Post Activity Image"} />
                <ImageContainer
                  {...this.props}
                  source={postRunPreview}
                  onImageClick={() => this.fullPostRunPicture()}
                  onDeleteIconClick={() =>
                    this.handleImageDelete(this.state?.postRunPicture?.id)
                  }
                />
              </>
            ) : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FieldLabel label={"Post Activity Image"} />
                <label htmlFor="postrun-button-file">
                  <AttachmentIcon
                    style={{
                      color: "#296DFA",
                    }}
                  />

                  <div style={{ display: "none" }}>
                    <input
                      className={classes.input}
                      id="postrun-button-file"
                      type="file"
                      accept="image/x-png,image/jpeg"
                      //handling for single image selection
                      onClick={this.clearImage}
                      onChange={this.multipleUploadPost}
                      //onInput={(e) => {
                      // let files = e.target.files[0];
                      //let imgsrc = URL.createObjectURL(files);
                      //this.setState({
                      //  postRunPicture: files,
                      //  postRunPreview: imgsrc,
                      // });
                      // }}
                    />
                  </div>
                </label>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12}>
            <TextTypography text={"Note:"} color={"blue"} fontSize={20} />
            <TextTypography
              fontSize={20}
              text={
                "Activity URL or activity screenshot is required. This will be to validate your timing."
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} style={{ textAlign: "center" }}>
            <SubmitCancelBtn
              onClick={() => {
                this.props.history.goBack();
              }}
              label={"Cancel"}
              type="submit"
            />
          </Grid>
          <Grid item xs={6} sm={6} style={{ textAlign: "center" }}>
            <SubmitCancelBtn
              onClick={() => this.validateFields()}
              label="Submit"
              type="submit"
            />
          </Grid>
        </Grid>

        <ImageDialogBox
          imagePath={this.state.image_src}
          fullWidth
          maxWidth={"md"}
          open={this.state.imageDialog}
          onClick={() => this.setState({ imageDialog: false })}
          closeDisloag={() => this.setState({ imageDialog: false })}
        />

        <DialogBox
          fullWidth
          deletePhoto={uploadedFiles.length > 0 ? true : false}
          maxWidth={"md"}
          onDeletePhoto={this.deletePhoto}
          addPhoto={true}
          onAddPhoto={this.multipleUpload}
          open={showScreenshotDialog}
          message={this.getDialogComponent()}
          onClick={() => this.setState({ showScreenshotDialog: false })}
          closeDisloag={() => this.setState({ showScreenshotDialog: false })}
        />
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(UploadForm);
