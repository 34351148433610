import { Typography } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Properties from "../../../../properties/Properties";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import DialogBox from "../../../common/DialogBox";
import "./../../../../styles/organizer.css";
import Breadcrumbs from "./../../../common/Breadcrumbs";
import {
  Analytics,
  Execution,
  JobSync,
  LeaderboardIcon,
  Participants,
  Reports,
  Setup,
  SummaryDashboard,
  Trainer,
  TrainingPlan,
} from "./../../../common/SvgIcon";
import ChallengeExecutionList from "./Challenge/ChallengeExecutionList";
import ChallengeRegistrationList from "./Challenge/ChallengeRegistrationList";
import OrganizerAnalytics from "./OrganizerAnalytics/OrganizerAnalytics";
import OrganizerCommonHeader from "./OrganizerCommonHeader";
import OrganizerExecutionList from "./OrganizerExecutionList";
import OrganizerActivitySyncJob from "./OrganizerJobSync/OrganizerActivitySyncJob";
import OrganizerLeaderBoard from "./OrganizerLeaderBoard";
import CreateChallenge from "./OrganizerProgram/Challenge/CreateChallenge";
import OrganizerProgramChallenge from "./OrganizerProgramChallenge";
import OrganizerRegistrationList from "./OrganizerRegistrationList";
import OrganizerReports from "./OrganizerReports/OrganizerReports";
import CreateEventView from "./OrganizerSetup/CreateEventView";
import OrganizerSummary from "./OrganizerSummary/OrganizerSummary";
import OrganizerTeams from "./OrganizerTeams/OrganizerTeams";
import OrganizerTrainer from "./OrganizerTraining.jsx/OrganizerTrainer";
import OrganizerTrainingPlan from "./OrganizerTraining.jsx/OrganizerTrainingPlan";
import ProgramChallengeParticipantsList from "./Program/ProgramChallengeParticipantsList";
import OrganizerProgramParticipantsList from "./OrganizerProgram/OrganizerProgramParticipantsList";
import OrganizerOpenSubscriptionParticipant from "./Program/OrganizerOpenSubscriptionParticipant";
import OrganizerOpenSubscriptionActivities from "./Program/OrganizerOpenSubscriptionActivities";
//header and sidebar integration

function OrganizerParticipantsList(props) {
  let selected = localStorage.getItem("sidebarSelected");
  let isChallenge = localStorage.getItem("isChallenge");

  let IsOpenSubscriptionProgram = localStorage.getItem(
    "IsOpenSubscriptionProgram"
  );

  let isChallengeEvent = localStorage.getItem("isChallengeEvent");
  let userRole = localStorage.getItem("role");
  let isTraining = localStorage.getItem("isTraining");
  let isCreateOrDraftProgram = localStorage.getItem(
    "selectedProgramCreateOrEdit"
  );
  let isEdit = localStorage.getItem("editView");
  let headerLinkSelected = localStorage.getItem("headerLinkSelected");
  let isProgramChallenge = localStorage.getItem("isProgramChallenge");
  let selectedEvent = ApplicationUtil.getSupportsEventsDetails();
  const selectedChallengeBasedResponse =
    ApplicationUtil.getProgramUnderCurrentChallenge();

  const [selectedMenu, setSelectedMenu] = React.useState(
    selected ? selected : isChallenge ? "Participants" : "Results"
  );
  const [nextSelect, setNextSelect] = React.useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const confirmDialogHeader = "";
  let isCreateOrDraft = localStorage.getItem("isCreateOrDraft");



  
  
  const [menuList, setMenuList] = React.useState(
    userRole === "ORGANIZER_ADMIN" &&
      (selectedEvent?.challengeType === "TEAM" ||
        selectedEvent?.challengeType === "BOTH" ||
        selectedEvent?.challengeType === "TEAM_RELAY")
      ? [
          "Summary",
          "Participants",
          isChallengeEvent ? "Activities" : "Results",
          "Setup",
          "Leaderboard",
          "Teams",
          // "Analytics",
          // "Reports",
        ]
      : userRole === "ORGANIZER_ADMIN"
      ? [
          "Summary",
          "Participants",
          isChallengeEvent || isProgramChallenge ? "Activities" : "Results",
          "Setup",
          "Leaderboard",
          // "Analytics",
          // "Reports",
        ]
      : IsOpenSubscriptionProgram  === "true"
      ? ["Participants", "Activities"]
      : isChallenge
      ? ["Participants", "Activities"]
      : isCreateOrDraft
      ? ["Setup"]
      : isTraining
      ? [
          "Summary",
          "TrainingPlan",
          "Participants",
          "Activities",
          "Leaderboard",
          "Trainer",
          "Setup",
          "JobSync",
          "Analytics",
          "Reports",
        ]
      : selectedEvent?.challengeType === "TEAM" ||
        selectedEvent?.challengeType === "BOTH" ||
        selectedEvent?.challengeType === "TEAM_RELAY"
      ? [
          "Summary",
          "Participants",
          isChallengeEvent ? "Activities" : "Results",
          "Setup",
          "Leaderboard",
          "Teams",
          "JobSync",
          "Analytics",
          "Reports",
        ]
      : selectedEvent?.programChallengeType === "ZERO_SUGAR" ||
        selectedEvent?.programChallengeType === "NO_UNHEALTHY_EATING" ||
        selectedEvent?.programChallengeType === "HYDRATION"
      ? [
          "Summary",
          "Participants",
          isProgramChallenge ? "Activities" : "Results",
          "Setup",
          "Leaderboard",
          "JobSync",
          "Analytics",
          "Reports",
        ]
      : [
          "Summary",
          "Participants",
          isChallengeEvent ? "Activities" : "Results",
          "Setup",
          "Leaderboard",
          "JobSync",
          "Analytics",
          "Reports",
        ]
  );

  const changeBar = () => {
    setSelectedMenu("Setup");
    setMenuList(["Participants", "Results", "Setup"]);
  };

  const closeWindow = () => {
    setShowConfirmDialog(false);
  };

  const handleConfirmAction = () => {
    setShowConfirmDialog(false);

    handleSelectionChangeAction(nextSelect);
  };

  const getConfirmDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.ModifyChallenege_CancelClickWarning}</span>
        </Typography>
      </div>
    );
  };

  const handleSelectionChangeAction = (row) => {
    localStorage.setItem("sidebarSelected", row);
    // localStorage.setItem("supportEventId", organizerEventID);
    setSelectedMenu(row);

    let isChallenge = localStorage.getItem("isChallenge");
    let isChallengeEvent = localStorage.getItem("isChallengeEvent");
    let isCreateOrDraft = localStorage.getItem("isCreateOrDraft");
    let isTraining = localStorage.getItem("isTraining");
    let isProgramChallenge = localStorage.getItem("isProgramChallenge");
    setMenuList(
      userRole === "ORGANIZER_ADMIN" &&
        (selectedEvent?.challengeType === "TEAM" ||
          selectedEvent?.challengeType === "BOTH" ||
          selectedEvent?.challengeType === "TEAM_RELAY")
        ? [
            "Summary",
            "Participants",
            isChallengeEvent ? "Activities" : "Results",
            "Setup",
            "Leaderboard",
            "Teams",
            // "Analytics",
            // "Reports",
          ]
        : userRole === "ORGANIZER_ADMIN"
        ? [
            "Summary",
            "Participants",
            isChallengeEvent || isProgramChallenge ? "Activities" : "Results",
            "Setup",
            "Leaderboard",
            // "Analytics",
            // "Reports",
          ]
        : IsOpenSubscriptionProgram === "true"
        ? ["Participants", "Activities"]
        : isChallenge
        ? ["Participants", "Activities"]
        : isCreateOrDraft
        ? ["Setup"]
        : isTraining
        ? [
            "Summary",
            "TrainingPlan",
            "Participants",
            "Activities",
            "Leaderboard",
            "Trainer",
            "Setup",
            "JobSync",
            "Analytics",
            "Reports",
          ]
        : selectedEvent?.challengeType === "TEAM" ||
          selectedEvent?.challengeType === "BOTH" ||
          selectedEvent?.challengeType === "TEAM_RELAY"
        ? [
            "Summary",
            "Participants",
            isChallengeEvent ? "Activities" : "Results",
            "Setup",
            "Leaderboard",
            "Teams",
            "JobSync",
            "Analytics",
            "Reports",
          ]
        : selectedEvent?.programChallengeType === "ZERO_SUGAR" ||
          selectedEvent?.programChallengeType === "NO_UNHEALTHY_EATING" ||
          selectedEvent?.programChallengeType === "HYDRATION"
        ? [
            "Summary",
            "Participants",
            isProgramChallenge ? "Activities" : "Results",
            "Setup",
            "Leaderboard",
            "JobSync",
            "Analytics",
            "Reports",
          ]
        : [
            "Summary",
            "Participants",
            isChallengeEvent ? "Activities" : "Results",
            "Setup",
            "Leaderboard",
            "JobSync",
            "Analytics",
            "Reports",
          ]
    );
  };


  

  return (
    <>
      <div className="organizer">
        {selected == "Setup" ? (
          <OrganizerCommonHeader title="Configuration" />
        ) : (
          <OrganizerCommonHeader />
        )}
        {/* <MenuIcon style={{ color: "#fff", zIndex: 2 }} /> */}
        <aside className="organizer__sidebar">
          <div className="sidebar__top">
            {/* <KeyboardBackspaceIcon onClick={() => history.goBack()} /> */}
          </div>
          <hr />
          <div className="sidebar__menu" id="organizer_sidebar_menu">
            {menuList.map((row, index) => (
              <div
                className="menu"
                onClick={() => {
                  if (isChallenge && selectedMenu === "Setup") {
                    setNextSelect(row);
                    setShowConfirmDialog(true);
                  } else {
                    handleSelectionChangeAction(row);
                  }
                }}
              >
                {row === "Summary" && (
                  <SummaryDashboard
                    className={`icon ${selectedMenu === row && "selectedIcon"}`}
                  />
                )}
                {row === "Participants" && (
                  <Participants
                    className={`icon ${selectedMenu === row && "selectedIcon"}`}
                  />
                )}
                {(row === "Results" || row === "Activities") && (
                  <Execution
                    className={`icon ${selectedMenu === row && "selectedIcon"}`}
                  />
                )}

                {row === "Setup" && (
                  <Setup
                    className={`icon ${selectedMenu === row && "selectedIcon"}`}
                  />
                )}
                {row === "Leaderboard" && (
                  <LeaderboardIcon
                    className={`icon ${selectedMenu === row && "selectedIcon"}`}
                  />
                )}
                {row === "Teams" && (
                  <GroupIcon
                    className={`icon ${selectedMenu === row && "selectedIcon"}`}
                  />
                )}
                {row === "Analytics" && (
                  <Analytics
                    className={`icon ${selectedMenu === row && "selectedIcon"}`}
                  />
                )}
                {row === "Reports" && (
                  <Reports
                    className={`icon ${selectedMenu === row && "selectedIcon"}`}
                  />
                )}
                {row === "Trainer" && (
                  <Trainer
                    className={`icon ${selectedMenu === row && "selectedIcon"}`}
                  />
                )}
                {row === `TrainingPlan` && (
                  <TrainingPlan
                    className={`icon ${selectedMenu === row && "selectedIcon"}`}
                  />
                )}
                {row === `JobSync` && (
                  <JobSync
                    className={`icon ${selectedMenu === row && "selectedIcon"}`}
                  />
                )}
                <p>{row}</p>
              </div>
            ))}
          </div>
        </aside>
        <div className="organizer__mainBody">
          <Breadcrumbs />
          {selectedMenu === "Summary" && <OrganizerSummary />}
          {selectedMenu === "Participants" &&
            (  isChallenge === "true" ? (
              <ChallengeRegistrationList changeBar={changeBar} />
            ) : IsOpenSubscriptionProgram === "true" ? (
              <OrganizerOpenSubscriptionParticipant />
            ):isProgramChallenge && headerLinkSelected === "Program" ? (
              <ProgramChallengeParticipantsList />
            ) : (
              <OrganizerRegistrationList />
            ))}
          {(selectedMenu === "Activities" || selectedMenu === "Results") &&
            (   isChallenge === "true"  ?  (
              <ChallengeExecutionList />
            )  :IsOpenSubscriptionProgram === "true" ? (
              <OrganizerOpenSubscriptionActivities />
            ) : isProgramChallenge && headerLinkSelected === "Program" ? (
              <OrganizerProgramChallenge />
            ) : (
              <OrganizerExecutionList />
            ))}
          {/* Create Challenge view*/}
          {selectedMenu === "Setup" && selectedChallengeBasedResponse && (
            <CreateChallenge />
          )}
          {/* Create Event view */}
          {selectedMenu === "Setup" &&
            !selectedChallengeBasedResponse &&
            isCreateOrDraftProgram !== "true" && <CreateEventView />}
          {/* Create program view */}
          {headerLinkSelected === "Program" &&
            isCreateOrDraftProgram === "true" &&
            selectedMenu === "Setup" &&
            !selectedChallengeBasedResponse && (
              <CreateChallenge isProgramCreate />
            )}
          {selectedMenu === "Leaderboard" && <OrganizerLeaderBoard />}{" "}
          {selectedMenu === "Teams" && <OrganizerTeams />}
          {selectedMenu === "Analytics" && <OrganizerAnalytics />}
          {selectedMenu === "Reports" && <OrganizerReports />}
          {selectedMenu === "Trainer" && <OrganizerTrainer />}
          {selectedMenu === `TrainingPlan` && <OrganizerTrainingPlan />}
          {selectedMenu === `JobSync` && <OrganizerActivitySyncJob />}
        </div>
      </div>

      <DialogBox
        signup={true}
        open={showConfirmDialog}
        message={getConfirmDialogMsg()}
        header={confirmDialogHeader}
        label={"No"}
        onClick={() => {
          closeWindow();
        }}
        labelTwo={"Yes"}
        onClickTwo={() => {
          handleConfirmAction();
        }}
        closeDisloag={() => closeWindow()}
      />
    </>
  );
}

export default compose(withRouter)(OrganizerParticipantsList);
