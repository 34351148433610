import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import ReactPlayer from "react-player";
import defaultPlay from "./PlayButton.png";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ArrowDropDownCircleOutlined } from "@material-ui/icons";
import {
  downloadVideoFile,
  resendEmails,
} from "./../../../../../apis/eventService";
import Properties from "../../../../../properties/Properties";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
const useStyles = makeStyles({});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth={"md"}
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ position: "relative", height: "60%" }}>
        <ReactPlayer width="100%" height="100%" controls url={props.url} />
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function VideoThumbnail(props) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [selectedValue, setSelectedValue] = React.useState("");
  const { url, src, title } = props;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleDownloadVideo = () => {
    let eventId = ApplicationUtil.getSupportsEventID();
    let runnerId = localStorage.getItem("supportRunnerId");
    downloadVideoFile(runnerId, eventId);
  };
  const handleResend = () => {
    const { title } = props;
    if (title == "Video") resendEmails("EMAIL_VIDEO_LINK");
  };
  return (
    <div style={{ height: "180px", position: "fixed" }}>
      <img
        style={{
          border: "1px solid #ddd",
          borderRadius: "4px",
          padding: "5px",
          width: "150px",
          height: "120px",
        }}
        src={defaultPlay} //{`data:image/png;base64,${src}`}
        onClick={handleClickOpen}
      />{" "}
      <div
        style={{
          color: "rgb(114, 114, 114)",
          fontSize: window.innerWidth > 600 ? "20px" : "16px",
        }}
      >
        {" "}
        <span
          style={{
            position: "absolute",
          }}
        >
          {title}
        </span>
        <span style={{ display: "flex", justifyContent: "flex-end" }}>
          <ArrowDropDownCircleOutlined onClick={handleClick} />
        </span>
      </div>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        url={url}
      />{" "}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDownloadVideo}>
          {Properties.DownloadLabel}
        </MenuItem>
        <MenuItem onClick={handleResend}>{Properties.ResendLabel}</MenuItem>
      </Menu>
    </div>
  );
}
