import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Avatar, IconButton, Typography } from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import GetAppIcon from "@material-ui/icons/GetApp";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
const styles = (theme) => ({
  card: {
    // backgroundRepeat: "no-repeat",
    // backgrounSize: "cover",
    borderRadius: "10px",
    // width: "600px",

    // height: "600px",
    // backgroundSize: "100% 100%",
    objectFit: 'cover',
    // overflowX: "hidden",
   
  },
  container:{
position:'relative'
  },
  cardGradient: {
    background: "linear-gradient(hsl(0 0% 0% / 0.6),hsl(0 0% 0% / 0.09) 10%, hsl(0 0% 0% /0))",
    zIndex: 100,
    // width: "600px",
    // height: "600px",
    position:'absolute',
    top:0,
    borderRadius: "10px",
    display:'flex',
    // alignItems:'center',
    justifyContent:'center'
  },
  icon:{
    color:'#fff',
    padding:'10px',
    cursor:'pointer',
    fontSize:'40px',
    background:'#0072ffa1',
    margin:'10px',
    borderRadius:'50%',
    [theme.breakpoints.down("sm")]: {
      fontSize:'22px',
      margin: '2px 5px 0px 5px',
    padding: '6px'

    },
  }
});
function SummaryCard(props) {
  const { classes, cardImage, data, onShareImage, onDownloadImage, onMultipleUpload, goodieType} = props;

  return (
    <div id="userSummaryCard">
    <div className={classes.container}>

      <img

        src={cardImage}
        className={classes.card}
        style={{ height: window.innerWidth > 600 ? `${data?.height}px` : `${data?.height/2}px`, width: window.innerWidth > 600 ?  `${data?.width}px` : `${data?.width/2}px` }}
        />
      <div className={classes.cardGradient} 
      style={{ height: window.innerWidth > 600 ? `${data?.height}px` : `${data?.height/2}px`, width: window.innerWidth > 600 ?  `${data?.width}px` : `${data?.width/2}px` }}
      >
        {goodieType === "SHARE" && <div>
        <label htmlFor="contained-button-file">

        <AddAPhotoIcon  className={classes.icon}/>
        </label>
        <div style={{ display: "none" }}>
                    <input
                      // multiple
                      className={classes.input}
                      id="contained-button-file"
                      type="file"
                      accept="image/*"
                      onChange={(e) => onMultipleUpload(e)}
                    />
                  </div>
        </div>}
       <ShareIcon
       className={classes.icon}
       onClick={onShareImage}
       />
       <GetAppIcon
       className={classes.icon}
       onClick={onDownloadImage}
       />
      </div>
</div>


    </div>
  );
}

export default compose(withStyles(styles, { withTheme: true }), withRouter)(SummaryCard);
