import React, { useEffect, useState } from "react";
import Header from "../../../common/Header";
import { useDispatch, useSelector } from "react-redux";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import Properties from "../../../../properties/Properties";
import AppToolBar from "../../../common/AppToolBar";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import { CustomTextField } from "../../../common/CustomTextField";
import MultiSelect from "../../../common/MultiSelect";
import HelpIcon from "@material-ui/icons/Help";
import ApplicationConstants from "../../../../constants/ApplicationConstants";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { IconCalendarBlue } from "../../../common/SvgIcon";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import Snackbar from "../../../common/Snackbar";
import {
  getChallengeDurationListOfOpenProgram,
  userCreatedOpenChallengeRegistration,
} from "../../../../apis/challengeService";
import moment from "moment";
import {
  getCaptureDetails,
  getorderDetails,
  userRegisteredForProgram,
} from "../../../../apis/eventService";
import DialogBox from "../../../common/DialogBox";
import { BsCurrencyRupee } from "react-icons/bs";

function UserCreatedChallengeRegistration({ classes, ...props }) {
  const [programDetails, setProgramDetails] = useState(null);
  const [challengeType, setChallengeType] = useState(null);
  const [challengeTypeId, setChallengeTypeId] = useState(null);
  const [selectedChallengeDuration, setSelectedChallengeDuration] =
    useState(null);

  const [challengeDurationOptions, setChallengeDurationOptions] = useState();
  const [challengeDurationError, setChallengeDurationError] = useState(false);
  const [challengeStartDate, setChallengeStartDate] = useState(null);

  const [challengeStartDateError, setChallengeStartDateError] = useState(false);

  const [minAllowDates, setMinAllowDates] = useState(null);
  const [termsCheckboxSelected, setTermsCheckboxSelected] = useState(false);
  const [runnerDetails, setRunnerDetails] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [openDialogOnSuccess, setOpenDialogOnSuccess] = useState(false);
  const [successPaymentDetails, setSuccessPaymentDetails] = useState(null);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let paramChallengeType = urlParams.get("challenge");
    let paramChallengeTypeId = urlParams.get("id");
    let formattedChallengeType =
      ApplicationUtil.capitalizeFirstLetter(paramChallengeType);
    setChallengeType(formattedChallengeType);
    setChallengeTypeId(paramChallengeTypeId);

    //getting the data of openchallenge
    getChallengeDuration(paramChallengeTypeId);

    //get the program details
    getProgramDetails();

    //get Runner details
    getRunnerInfo();
  }, []);

  const getRunnerInfo = () => {
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runner?.id;
    setRunnerDetails(runnerData);
  };

  const getProgramDetails = () => {
    let programDetail = ApplicationUtil.getRunnersProgramDetails();
    setProgramDetails(programDetail);
    const programStartDate = moment(
      programDetail?.programStartDate,
      "DD-MM-YYYY"
    );
    const minDate = programStartDate.isAfter(moment())
      ? programStartDate
      : moment();

    setMinAllowDates(minDate);
    setChallengeStartDate();
  };

  const getChallengeDuration = (typeId) => {
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runner?.id;
    if (typeId) {
      getChallengeDurationListOfOpenProgram(typeId, runnerId).then((res) => {
        let durations = [];
        let data = res?.data;
        if (data) {
          const durations = data.map((item) => ({
            ...item,
            label: item?.displayName,
            value: item?.duration,
            id: item?.id,
            subscriptionFee: item?.subscriptionFee,
            isDisabled: item?.usedFreeSubscription
              ? item?.usedFreeSubscription
              : false,
          }));
          setChallengeDurationOptions(durations);
          // setSelectedChallengeDuration(durations[0]);
        }
      });
    }
  };

  const handleChallengeDurationChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      setSelectedChallengeDuration(selectedValues);
      setChallengeDurationError(false);
    }
    // else {
    //   setSelectedChallengeDuration(selectedValues[0]);
    // }
  };

  const handleDateChange = (date) => {
    setChallengeStartDate(moment(date, "DD-MM-YYYY HH:mm:ss"));
    setChallengeStartDateError(false);
  };

  const handleCheckBoxChange = (event) => {
    setTermsCheckboxSelected(event.target.checked);
  };

  const handleCancelFields = () => {
    setSelectedChallengeDuration(null);
    // [OE_Defect_id_36] : don't want set the date
    setChallengeStartDate(null);
    props.history.push(`program?distKey=${programDetails?.distKey}`)
  };

  const validateFields = () => {
    if (
      selectedChallengeDuration === "" ||
      selectedChallengeDuration === null
    ) {
      setChallengeDurationError(true);
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select challenge duration.",
      });
    } else if (!challengeStartDate) {
      setChallengeStartDateError(true);
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select challenge start date.",
      });
    } else if (
      selectedChallengeDuration &&
      selectedChallengeDuration?.subscriptionFee
    ) {
      //Register flow
      //  After the the payment is successful then registration will happen.
      // 1. call the payment method
      // 2. check payment is successful after i.e. then call capture method to validate signature.
      // 3. payment not done callback function  handlePaymentFailure

      let requestParams = {
        runnerId: runnerDetails?.runner?.id,
        openSubscriptionDurationId: selectedChallengeDuration?.id,
        amount: selectedChallengeDuration?.subscriptionFee,
      };
      //Get the generated order_id and check the payment flow.
      getOrderDetails(requestParams);
    } else {
      //Direct Register and creation of challenge for Program
      registerForChallenge();
    }
  };

  const getOrderDetails = (requestParams) => {
    getorderDetails(requestParams).then((res) => {
      const { data } = res;
      setOrderId(data?.orderId);
      ApplicationUtil.paymentGatewayHandler(
        data,
        captureDetails,
        handlePaymentFailure
      );
    });
  };

  const captureDetails = (requestCaptureParams) => {
    requestCaptureParams.openSubscriptionDurationId =
      selectedChallengeDuration?.id;
    requestCaptureParams.runnerId = runnerDetails?.runner?.id;
    getCaptureDetails(requestCaptureParams)
      .then((res) => {
        registerForChallenge(requestCaptureParams);
        setSuccessPaymentDetails(requestCaptureParams);
      })
      .catch((error) => {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Failed to process the payment.",
        });
      });
  };

  const handlePaymentFailure = (response) => {
    Snackbar.show({
      variant: "error",
      isAutoHide: true,
      preventDuplicate: true,
      message: `Failed to process the payment please note the transactionID
          ${response.error.metadata.payment_id}.`,
    });
  };

  const registerForChallenge = (requestCaptureParams) => {
    const subscriptionChallengeDetails =
      selectedChallengeDuration?.openSubscriptionProgramChallenge;
    const duration = selectedChallengeDuration?.duration;
    const startDate = moment(challengeStartDate, "DD-MM-YYYY").format(
      "DD-MM-YYYY 00:00:01"
    );
    const endDate = moment(startDate, "DD-MM-YYYY HH:mm:ss")
      .add(duration - 1, "days")
      .format("DD-MM-YYYY 23:59:59");
    const programChallengeSettings =
      selectedChallengeDuration?.openSubscriptionProgramChallenge
        ?.openSubscriptionProgramChallengeSettings;
    const videoLink = selectedChallengeDuration
      ?.openSubscriptionProgramChallenge?.videoLink
      ? selectedChallengeDuration?.openSubscriptionProgramChallenge?.videoLink
      : null;
    const description = subscriptionChallengeDetails?.description
      ? subscriptionChallengeDetails?.description
      : null;
    const longDescription = subscriptionChallengeDetails?.longDescription
      ? subscriptionChallengeDetails?.longDescription
      : null;
    const responseSubmissionEndDate = moment(endDate, "DD-MM-YYYY HH:mm:ss")
      .add(2, "days")
      .format("DD-MM-YYYY HH:mm:ss");
    const registrationEndDate = moment(endDate, "DD-MM-YYYY HH:mm:ss")
      .subtract(1, "days")
      .format("DD-MM-YYYY HH:mm:ss");
    let url = "/program?distKey=" + programDetails?.distKey;

    let requestedParams = {
      programId: programDetails?.id,
      name: subscriptionChallengeDetails?.name + " Challenge",
      challengeStartDate: startDate,
      challengeEndDate: endDate,
      registrationEndDate: registrationEndDate,
      videoLink: videoLink,
      programChallengeType: subscriptionChallengeDetails?.challengeType,
      description: description,
      longDescription: longDescription,
      responseSubmissionEndDate: responseSubmissionEndDate,
      runnerId: runnerDetails?.runner?.id,
      openChallengeDurationId: selectedChallengeDuration?.id,
      orderId: requestCaptureParams?.orderId,
      programChallengeSettings: programChallengeSettings,
    };

    if (!requestCaptureParams?.orderId) {
      delete requestedParams.orderId;
    }
    userCreatedOpenChallengeRegistration(requestedParams).then((res) => {
      if (res?.data && res?.data?.code === "200") {
        setOpenDialogOnSuccess(true);
      } else {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Registration Failed",
        });
      }
    });
  };

  const getSuccessDialogComponent = () => {
    return (
      <div style={{ padding: "8px 24px" }}>
        <div style={{ padding: "8px 24px" }}>
          <Typography style={{ display: "grid", fontSize: "16px !important" }}>
            {selectedChallengeDuration?.subscriptionFee > 0 ? (
              <>
                <div>
                  Thank you for registering for <b>{challengeType}</b>{" "}
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>Acknowledgement ID</div>
                    <div
                      style={{
                        display: "flex",
                        wordBreak: "break-all",
                      }}
                    >
                      {" "}
                      {successPaymentDetails?.transactionID}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>
                      Acknowledgement Status
                    </div>
                    <div
                      style={{
                        display: "flex",
                        wordBreak: "break-all",
                      }}
                    >
                      {"Success"}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>Date</div>
                    <div
                      style={{
                        display: "flex",
                        wordBreak: "break-all",
                      }}
                    >
                      {moment(new Date()).format("DD-MM-YYYY HH:mm:ss")}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>
                      {Properties.Total_Amount}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        wordBreak: "break-all",
                      }}
                    >
                      {" "}
                      {successPaymentDetails?.amount}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div>
                Thank you for registering for <b>{challengeType}</b>
              </div>
            )}
          </Typography>
        </div>
      </div>
    );
  };

  const navigateToProgramInfo = () => {
    let url = "/program?distKey=" + programDetails?.distKey;
    props.history.push(url);
  };

  return (
    <div>
      <AppToolBar>
        <Header
          label={
            challengeType
              ? challengeType + " Registration"
              : Properties.Label_RegisterProgram
          }
        />
      </AppToolBar>
      <div className={classes.layout}>
        <Paper className={classes.paper}>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="space-between"
            alignItems="flex-end"
          >
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body2"
                gutterBottom
                className={classes.helpContainerCss}
              >
                <span className={classes.labelCss}>
                  {Properties.ChallengeDuration}
                </span>
                <span className={classes.mandatoryCss}>*</span>{" "}
                <span className={classes.helpCss}>
                  <Tooltip
                    title={Properties.ChallengeDurationHelpTextOpenProgram}
                    leaveTouchDelay={10000}
                    enterTouchDelay={50}
                  >
                    <div>
                      <HelpIcon fontSize="small" />
                    </div>
                  </Tooltip>
                </span>
              </Typography>

              <Grid container spacing={2}>
                <Grid xs={8} item>
                  <MultiSelect
                    value={selectedChallengeDuration}
                    error={challengeDurationError}
                    options={challengeDurationOptions}
                    onChange={handleChallengeDurationChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                variant="body2"
                gutterBottom
                className={classes.helpContainerCss}
              >
                <span className={classes.labelCss}>
                  {Properties.ChallengeStartDate}
                </span>
                <span className={classes.mandatoryCss}>*</span>
                <span className={classes.helpCss}>
                  <Tooltip
                    title={Properties.ChallengeStartDateHelpText}
                    leaveTouchDelay={10000}
                    enterTouchDelay={50}
                  >
                    <div>
                      <HelpIcon fontSize="small" />
                    </div>
                  </Tooltip>
                </span>
              </Typography>

              <KeyboardDatePicker
                // className={!challengeStartDateError && classes.fieldCss}
                autoOk
                clearable
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                value={challengeStartDate ? challengeStartDate : null}
                minDate={minAllowDates ? minAllowDates : undefined}
                minDateMessage={`Challenge Date cannot be before ${moment(
                  programDetails?.programStartDate
                ).format("DD-MM-YYYY")}`}
                maxDate={
                  programDetails?.registrationEndDate
                    ? `${moment(
                        programDetails?.registrationEndDate,
                        "DD-MM-YYYY"
                      )}`
                    : undefined
                }
                maxDateMessage={
                  programDetails?.registrationEndDate
                    ? `Challenge Date cannot be after
                    ${moment(
                      programDetails?.registrationEndDate,
                      "DD-MM-YYYY"
                    )}`
                    : null
                }
                keyboardIcon={
                  <IconCalendarBlue className={classes.calendarIconCss} />
                }
                InputProps={{
                  classes: {
                    root: challengeStartDateError
                      ? classes.datePickerError
                      : classes.datePicker,
                  },
                  placeholder: "--/--/--",
                }}
                onChange={(date) => handleDateChange(date)}
              />
            </Grid>

            <Grid style={{ height: "50px" }} item xs={12}>
              <span style={{ fontSize: "16px" }}>
                <input
                  type="checkbox"
                  onChange={handleCheckBoxChange}
                  value={termsCheckboxSelected}
                  required
                />
                I agree to the
                <a href="https://necessarydevil.com/terms" target="_blank">
                  {" "}
                  Terms and Conditions.
                </a>{" "}
              </span>
            </Grid>

            <Grid item xs={6} className={classes.centerCss}>
              <SubmitCancelBtn
                onClick={(e) => handleCancelFields()}
                label={"Cancel"}
                type="submit"
              />
            </Grid>

            <Grid item xs={6} className={classes.centerCss}>
              <SubmitCancelBtn
                onClick={(e) => validateFields()}
                label={Properties.Label_Register}
                type="submit"
                isDisabled={!termsCheckboxSelected}
              />
            </Grid>
          </Grid>
        </Paper>

        <footer className={classes.footer}>
          <Paper className={classes.footerPaper}>
            {selectedChallengeDuration?.subscriptionFee > 0 ? (
              <span className={classes.highlightedPrice}>
                Payable Amount : <BsCurrencyRupee />{" "}
                {selectedChallengeDuration?.subscriptionFee}
              </span>
            ) : selectedChallengeDuration?.subscriptionFee === 0 ? (
              <span className={classes.highlightedText}>Free</span>
            ) : (
              <span></span>
            )}
          </Paper>
        </footer>
      </div>

      <DialogBox
        signup={true}
        open={openDialogOnSuccess}
        message={getSuccessDialogComponent()}
        header={"Successful"}
        onClick={() => {
          navigateToProgramInfo();
        }}
        closeDisloag={() => openDialogOnSuccess(false)}
      />
    </div>
  );
}
const styles = (theme) => ({
  btnCss: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#296DFA",
    },
    maxWidth: "300px",
    width: "100%",
    height: "38px",
    borderRadius: "16px",
    backgroundColor: "#09456C",
    color: "#fff",
    fontWeight: "bold",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // height: "100vh",
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 700,
      marginLeft: "auto",
      marginRight: "auto",
    },
    marginTop: "68px",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  centerCss: {
    textAlign: "center",
  },
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    width: "100%",
    borderRadius: "5px",
    "&::placeholder": {
      color: "red ", // Change this to your desired color
      opacity: 1,
    },
    border: "1px solid #08456C",
    // border: "1px solid #08456C",
  },
  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },
  innerGridCss: {
    padding: "4px 0px",
  },
  dropdownCss: {
    width: isBrowser ? "360px" : "167.5px",
  },
  labelCss: {
    color: "#727272",
  },
  mandatoryCss: { color: "red" },
  helpContainerCss: {
    display: "inline-flex",
  },
  helpCss: {
    display: "flex",
    color: "#3a6ea5",
    marginLeft: "2px",
  },
  fieldCss: {
    border: "1px solid #08456C",
    height: "37px",
    width: "100%",
    borderRadius: "5px",
  },
  fieldErrorCss: {
    border: "1px solid red",
    height: "37px",
    width: "100%",
  },
  fieldsContainer: {
    marginLeft: "20px",
    marginRight: "20px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px",
    },
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  highlightedText: {
    display: "flex",
    alignItems: "center",
    // color: "#43aa8b",
    color: "#335c67",
    fontSize: "20px",
    fontWeight: 700,
  },
  highlightedPrice: {
    display: "flex",
    alignItems: "center",
    color: "#335c67",
    fontSize: "20px",
    fontWeight: 700,
  },
  footer: {
    width: "100%",
    height: "68px",
    position: "sticky",
    bottom: 0,
    backgroundColor: "#dee2e6",
  },
  footerPaper: {
    height: "68px",
    backgroundColor: "#dee2e6",
    display: "flex",
    justifyContent: "center",
  },
});
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(UserCreatedChallengeRegistration);
