import {
  Typography,
  Grid,
  FormControl,
  NativeSelect,
  Container,
} from "@material-ui/core";
import React from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import StarsSharpIcon from "@material-ui/icons/StarsSharp";
import Properties from "../../../../properties/Properties";
import LineGraph from "../../../common/LineGraph";
import LinearProgressBar from "../../../common/LinearProgressBar";
import moment from "moment";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
const styles = (theme) => ({
  dropdownStyling: {
    marginLeft: "80%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "35% !important",
      marginTop: 0,
    },
  },
  indi: {
    width: "100%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
  totalDetails: {
    color: "#a52a2a",
    fontWeight: "bold",
    fontSize: "20px",
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",
      height: "20%",
      justifyContent: "flex-start",
      margin: "0",
    },
  },
  totalDetailsProg: {
    color: "black",
    fontWeight: "bold",
    fontSize: "28px",
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",
      fontSize: "20px",

      height: "20%",
      justifyContent: "flex-start",
      margin: "0",
    },
    "& .stepsTitle": {
      color: "black",
      fontWeight: "bolder",
      fontSize: "26px",
      margin: "0",
      marginLeft: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
  },
  totalDetails1: {
    color: "#a52a2a",
    fontWeight: "bold",
    fontSize: "20px",
    display: "flex",
    width: "100%",
    //textAlign:'left',
    // justifyContent: "center",
    // alignItems:'center',

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",
      height: "20%",
      justifyContent: "flex-start",
      margin: "0",
    },
  },
  totalDetails2: {
    color: "#a52a2a",
    fontWeight: "bold",
    fontSize: "20px",
    display: "flex",
    width: "100%",
    //textAlign:'left',
    // justifyContent: "center",
    // alignItems:'center',

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",
      height: "20%",
      justifyContent: "flex-start",
      margin: "0",
      marginLeft: "5%",
    },
  },

  totalDetailsWell: {
    color: "#a52a2a",
    fontWeight: "bold",
    fontSize: "20px",
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",
      textAlign: "left",
      margin: "0",
    },
  },
  totalDetails1: {
    color: "#a52a2a",
    fontWeight: "bold",
    fontSize: "20px",
    display: "flex",
    width: "100%",
    //justifyContent: "center",
    // alignItems:'center',

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",

      justifyContent: "start",
      margin: "0",
    },
  },
  graph: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      margin: "0",
      padding: 0,
    },
  },
  graphCar: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      margin: "0",
      padding: 0,
      marginBottom: "20px",
      marginTop: "10px",
    },
  },
  graphs: {
    width: "100%",
    display: "flex",

    [theme.breakpoints.down("sm")]: {
      margin: "0",
      padding: 0,
      width: "100%",
    },
  },

  graph1: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "flex-start",
      marginTop: "0",
    },
  },

  rankStyling: {
    marginLeft: "0%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10%",
    },
  },

  nestedGraph: {
    "& .legendBlockContainer": {
      display: "flex",
      justifyContent: "end",
      marginTop: "12px",
      gap: "10px",
      alignItems: "center",
      [theme.breakpoints.down(1800)]: {
        marginRight: "22px",
      },
      "& .legendBlock": {
        width: "40px",
        height: "18px",
        backgroundColor: "#FE7235",
        [theme.breakpoints.down("sm")]: {
          width: "30px",
          height: "12px",
        },
      },
      "& .legendBlockTitle": {
        fontSize: "20px",
        fontWeight: "500",
        textTransform: "capitalize",
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
          fontWeight: "500",
        },
      },
    },
    [theme.breakpoints.up("sm")]: {
      gridColumn: "span 6",
    },
    [theme.breakpoints.up("xs")]: {
      gridColumn: "span 6",
    },
    [theme.breakpoints.up("md")]: {
      gridColumn: "span 8",
      width: "67%",
    },
    [theme.breakpoints.down("xs")]: {
      gridColumn: "span 12",
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
});
function Individual(props) {
  let {
    navigateToWellnessPointsDetails,
    individualValues,
    dateHandleChange,
    dataX,
    dataY,
    getIndex,
    classes,
    barColor,
    isProgram = false,
  } = props;
  const isOrganizer = window.location.href.includes("/organizer");
  const getDefaultValue = () => {
    if (!dataX && !dataY) {
      const value = individualValues?.dateRangeOptions?.filter((x) =>
        moment().isBetween(
          moment(x?.from, "MM-DD-YYYY"),
          moment(x?.to, "MM-DD-YYYY").add(1, "day") //One day was added to the date because it was returning false in case the 'to' date was the current date.
        )
      );
      let idx = individualValues?.dateRangeOptions?.findIndex((x) =>
        moment().isBetween(
          moment(x?.from, "MM-DD-YYYY"),
          moment(x?.to, "MM-DD-YYYY")
        )
      );
      const X = value[0]?.value?.trackingEstimationByDate[idx];
      const Y = value[0]?.value?.selectedTrackingDates[idx];
      const Z = value[0]?.value?.selectedTooltip[idx];
      // dataX: X, dataY: Y, tooltipDataState: Z
      dataX = X;
      dataY = Y;
      individualValues.tooltipDataState = Z;
      return (
        value[0]?.value?.trackX ||
        individualValues?.dateRangeOptions[0]?.value?.trackX
      );
    }
  };
  return (
    <Grid
      container
      className={classes.indi}
      style={{ height: individualValues?.totalTarget !== 0 ? "100%" : "90%" }}
    >
      {individualValues?.individualGraphRunnerActivities?.length > 0 &&
        !isProgram && (
          <>
            {individualValues?.graphYAxisIndi === "wellnessPoint" ? (
              <Grid item xs={12} sm={4} lg={4} md={4}>
                <Typography variant="div">
                  <span className={classes.totalDetails}>
                    <span
                      style={{
                        color: "#727272",
                        fontWeight: "bold",
                        fontSize: "18px",
                        cursor: "pointer",
                        margin: "0",
                      }}
                      onClick={navigateToWellnessPointsDetails}
                    >
                      {"My Wellness Points"}
                    </span>

                    <StarsSharpIcon
                      style={{
                        color: "#a52a2a",
                        cursor: "pointer",
                        // marginLeft:'5px'  margin:'0'
                      }}
                      onClick={navigateToWellnessPointsDetails}
                    />
                  </span>
                  <div>
                    <span className={classes.totalDetails}>
                      <span style={{ marginTop: "10px" }}>
                        {individualValues?.result?.wellnessDetails
                          ?.totalWellnessPoint
                          ? individualValues?.result?.wellnessDetails
                              ?.totalWellnessPoint
                          : "0"}
                      </span>
                    </span>
                  </div>
                </Typography>
              </Grid>
            ) : individualValues?.graphYAxisIndi === "steps" ? (
              <Grid item xs={4} sm={3} lg={4} md={4}>
                <Typography variant="body2" gutterBottom>
                  <span className={classes.totalDetails}>
                    <span
                      style={{
                        color: "#727272",
                        fontWeight: "bold",
                        fontSize: "18px",
                        margin: "0",
                      }}
                    >
                      {"Total Steps"}
                    </span>
                  </span>
                  {individualValues?.registration && (
                    <div>
                      {" "}
                      <span className={classes.totalDetails}>
                        <span
                          style={{
                            color: "#a52a2a",
                            fontWeight: "bold",
                            fontSize: "20px",
                            margin: "0",
                          }}
                        >
                          {/* Defect_ID_403 / ankit */}
                          {ApplicationUtil.formatNumberWithCommas(
                            individualValues?.result?.totalSteps
                              ? individualValues?.result?.totalSteps
                              : 0
                          )}
                        </span>
                      </span>
                    </div>
                  )}
                </Typography>
              </Grid>
            ) : individualValues?.graphYAxisIndi === "calories" ? (
              <Grid item xs={4} sm={3} lg={4} md={4}>
                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#727272",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {"Calories"}
                  </span>

                  {individualValues?.registration && (
                    <div>
                      <span
                        style={{
                          color: "#a52a2a",
                          fontWeight: "bold",
                          fontSize: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {individualValues?.result?.totalCalories
                            ? individualValues?.result?.totalCalories
                            : 0}
                        </span>
                      </span>
                    </div>
                  )}
                </Typography>
              </Grid>
            ) : individualValues?.graphYAxisIndi === "elevation" ? (
              <Grid item xs={4} sm={3} lg={4} md={4}>
                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#727272",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {"Elevation"}
                  </span>

                  {individualValues?.registration && (
                    <div>
                      <span
                        style={{
                          color: "#a52a2a",
                          fontWeight: "bold",
                          fontSize: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {individualValues?.result?.totalElevationGain
                            ? individualValues?.result?.totalElevationGain
                            : 0}
                        </span>
                      </span>
                    </div>
                  )}
                </Typography>
              </Grid>
            ) : individualValues?.graphYAxisIndi === "distance" ? (
              <Grid item xs={4} sm={3} lg={4} md={4}>
                <Typography variant="body2" gutterBottom>
                  <span className={classes.totalDetailsWell}>
                    {individualValues?.registration && (
                      <span
                        style={{
                          color: "#727272",
                          fontWeight: "bold",
                          fontSize: "18px",
                          margin: "0",
                        }}
                      >
                        {Properties.Total_Activity_Distance_Label}
                      </span>
                    )}
                  </span>
                  {individualValues?.registration && (
                    <div>
                      <span className={classes.totalDetails1}>
                        <span
                          style={{
                            color: "#a52a2a",
                            fontWeight: "bold",
                            fontSize: "20px",
                            margin: "0",
                          }}
                        >
                          {individualValues?.result?.totalActivityDistance &&
                          individualValues?.result?.totalActivityDistance %
                            1000 ===
                            0
                            ? parseFloat(
                                individualValues?.result
                                  ?.totalActivityDistance / 1000
                              ).toFixed(0)
                            : individualValues?.result?.totalActivityDistance &&
                              individualValues?.result?.totalActivityDistance %
                                1000 !==
                                0
                            ? parseFloat(
                                individualValues?.result
                                  ?.totalActivityDistance / 1000
                              ).toFixed(2)
                            : 0}
                          {" Km"}
                        </span>
                      </span>
                    </div>
                  )}
                </Typography>
              </Grid>
            ) : (
              ""
            )}
          </>
        )}
      {!isProgram &&
        individualValues?.totalTarget !== 0 &&
        individualValues?.challengeGoal === "TOTAL_TARGET" && (
          <Grid item className={classes.graphs} style={{ margin: 0 }}>
            <LinearProgressBar
              completedValue={Number(
                individualValues?.completedProgressIndividual
              )}
              targetedValue={individualValues?.targetedProgressIndividual}
              isMaxThanTargeted={
                individualValues?.isProgressMaxThanTargetIndividual
              }
              maximumValue={
                individualValues?.isProgressMaxThanTargetIndividual
                  ? Number(
                      (
                        individualValues?.completedProgressIndividual - 100
                      ).toFixed(2)
                    )
                  : null
              }
              completedActivity={
                individualValues?.completedProgressActivityIndividual
              }
            />
          </Grid>
        )}
      {/* {individualValues?.individualGraphRunnerActivities?.length > 0 &&
        isProgram && (
          <>
            <Typography variant="body2" gutterBottom>
              <span className={classes.totalDetailsProg}>
                <span className={"stepsTitle"}>
                  {"Total Steps"}:&nbsp;
                  {individualValues?.result?.totalSteps
                    ? individualValues?.result?.totalSteps
                    : 0}
                </span>
              </span>
            </Typography>
          </>
        )} */}
      {individualValues?.dateListState?.length > 10 &&
        individualValues?.individualGraphRunnerActivities?.length > 0 && (
          <FormControl
            className={classes.dropdownStyling}
            style={{
              marginLeft: isOrganizer && isProgram ? "1%" : "80%",
            }}
          >
            <NativeSelect
              id="myDropdown"
              onChange={getIndex}
              defaultValue={getDefaultValue()}
            >
              {individualValues?.dateRangeOptions?.map((value, index) => (
                <option value={value.value.trackX} key={index} index={index}>
                  {value.displayDate}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        )}
      {individualValues?.showGraph && individualValues?.showTable && (
        <Container>
          <Grid
            item
            className={classes.graphCar}
            style={{
              justifyContent: isOrganizer && isProgram ? "start" : "center",
              marginInline: isOrganizer && isProgram ? "3%" : 0,
            }}
          >
            <div key="vertical" className="lineGraph" style={{ width: "100%" }}>
              <LineGraph
                eventType={individualValues?.eventType}
                activities={individualValues?.individualGraphRunnerActivities}
                graphShow={individualValues?.graphToShow}
                graphData={individualValues?.individualGraphData}
                graphYAxis={individualValues?.graphYAxisIndi}
                graphXAxis={individualValues?.graphXAxisIndi}
                dataX={dataX}
                dataY={dataY}
                isProgram={isProgram}
                barColor={barColor}
                tooltipDataState={individualValues?.tooltipDataState}
                dateHandleChange={dateHandleChange}
                activityTotal={individualValues?.activityList}
              />
            </div>
            {isProgram && (
              <div
                className={classes.nestedGraph}
                style={{ width: isOrganizer && "90%" }}
              >
                <div className={"legendBlockContainer"}>
                  <div className={"legendBlock"}></div>
                  <span className={"legendBlockTitle"}>
                    {individualValues?.graphYAxisIndi}
                  </span>
                </div>
              </div>
            )}
          </Grid>
        </Container>
      )}
    </Grid>
  );
}
export default compose(
  withStyles(
    styles,

    { withTheme: true }
  )
)(Individual);
