import React, { Component } from "react";
import { Tab, Tabs, Grid, Typography } from "@material-ui/core";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  getExecutionsDetails,
  getPostRunImage,
  displayCertificate,
  displayRunnerCollageFile,
  displayBIBFile,
} from "../../../../apis/eventService";
import Properties from "../../../../properties/Properties";
import ApplicationUtil from "./../../../../utils/ApplicationUtil";

import { isMobile } from "react-device-detect";
import { HorizontalLineGraph } from "./../../../common/HorizontalLineGraph";
import { getGraphDetails, getMultipleGraphDetails, } from "./../../../../apis/graphService";
import VideoThumbnail from "../../registration/events/viewResults/VideoThumbnail";
import ImageDialogBox from "../../../common/ImageDialogBox";

import ActivityList from "./ActivityList";
import ThumbnailViewer from "./ThumbnailViewer";
import ImageSlider from "../../../common/ImageSlider";
import DialogBox from "../../../common/DialogBox";
var moment = require("moment");
const styles = (theme) => ({
  mainBody: {
    marginTop: "0px",
    // height: "100vh",
    overscrollBehavior: "auto contain",
  },
  sliderImageCss: {},
  textTransformCss: {
    textTransform: "none",
    fontSize: "22px",
    fontWeight: "bold",
  },
  displayTitle: {
    color: "rgb(114, 114, 114)",
    fontSize: isMobile ? "16px" : "20px",
  },
  displayInfo: {
    color: "rgb(40, 131, 181)",
    fontSize: isMobile ? "20px" : "24px",
  },
  runnerNameCss: {
    color: "rgb(40, 131, 181)",
    fontWeight: "600",
    fontSize: "34px",
  },
  bottomMargin: {
    marginBottom: "15px",
  },
  picture: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    "& p": {
      marginLeft: "10px",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#2883B5",
      padding: "8px",
      color: "#fff",
      cursor: "pointer",
    },
  },
  profileImgContainer: {
    width: "120px",
    height: "120px",
    // border: "2px solid blue",
    boxShadow: "0px 0px 2px 3px rgba(0,0,0,0.3)",
    display: "flex",
    justifyContent: "center",
  },
  profileImg: {
    width: "100px",
    height: "100px",
    objectFit: "contain",
    margin: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    cursor: "pointer",
  },
});
class ParticipantExecutationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: localStorage.getItem("supportUserName"),
      ActiveTabIndex: 0,
      activeTabTitle: Properties.PersonalDataLabel,
      data: [],
      runnerDetails: null,
      isSupport: null,
      teamGraphData: [],
      graphXAxis:null,
      graphYAxis:null
    };
  }

  componentDidMount() {
    this.onPageLoad();
  }
  pageRefresh = () => {
    this.onPageLoad();
  };
  onPageLoad = () => {
    let runnerId = localStorage.getItem("supportRunnerId");
    let eventId = ApplicationUtil.getSupportsEventID();
let eventDetails = ApplicationUtil.getSupportsEventsDetails()
    document.title = Properties.Label_ParticipantDetails;
    if (!ApplicationUtil.isuserLoggedIn("organizer")) {
      this.props.history.push("/organizer");
    } else {
      let data = ApplicationUtil.getSupportDetails();
      this.setState({
        isSupport: data.role === "SUPPORT" ? true : false,
      });
      let imageList = [];
      // newArray = [];

      getPostRunImage(runnerId, eventId).then((response) => {
        imageList = response.images.reverse();
        this.setState({ imageList: imageList });
        getExecutionsDetails().then((res) => {
          let activityList = null;

          this.setState({
            runnerDetails: res?.data?.runner,
            runnerBib: res?.data?.bibNumber,
            showActivityScreenshot: eventDetails?.activities?.some((x) => x?.allowActivityUpload === true)
          });
          if (res.data.runnerActivity && res.data.runnerActivity.activityList) {
            activityList = res.data.runnerActivity.activityList;
            activityList = activityList.sort((a, b) => {
              return a.startDateLocal - b.startDateLocal;
            });
          }

          if (res.data.bibStatus === "AVAILABLE") {
            this.downloadBIB();
          }
          if (res.data.certificateStatus === "AVAILABLE") {
            this.downloadCertificate();
          }
          if (res.data.runnerCollageStatus === "AVAILABLE") {
            this.downloadCollage();
          }
          if (res.data.videoStatus) {
            if (res.data.runnerActivity?.videoStreamUrl)
              this.setState({
                videoUrl: res.data.runnerActivity.videoStreamUrl,
                // "https://watch.cloudflarestream.com/efebcf09647d945da59b0699c0b4434f",
              });
          }
          this.setState(
            {
              data: res.data,
              activityList: activityList,
              userMessages: res.data?.userMessages,
              displayColumns:
                res?.data?.runnerActivity?.displayColumns?.split(","),
            },
            () => {
              if (
                this.state.activityList != null &&
                this.state.activityList.length > 0
              ) {
                let userMessages = res.data.userMessages;

                this.state.activityList.forEach((element) => {
                  let comments = [];
                  if (element.postRunImageStatus === "AVAILABLE") {
                    imageList.forEach((element1) => {
                      if (element1.objectId === element.id) {
                        element.imageURL = element1.imageData;
                        element.file = element1.fileName;
                        if (element1.fileId) element.fileId = element1.fileId;
                      }
                    });
                  }
                  if (userMessages) {
                    userMessages.forEach((element1) => {
                      if (
                        element1?.activityId &&
                        element.id === element1.activityId
                      ) {
                        comments.push(element1);
                      }
                    });
                  }
                  element.comments = comments;
                });
              }
            }
          );
        });

        // getGraphDetails(runnerId, eventId).then((res) => {
        //   let datesArray = [];
        //   let teamGraphArray = [];

        //   // for horizontal bar ---> team challenge
        //   if (res.data.xAxis) {
        //     this.setState({ graphXAxis: res.data.xAxis });
        //   }
        //   if (res.data.totalTeamDistance) {
        //     this.setState({
        //       totalTeamDistance: res.data.totalTeamDistance,
        //       teamName: res.data.teamName,
        //       teamRank: res.data.teamRank,
        //     });
        //   }
        //   let graphArr = res.data.runnerActivityDetails; //graph API data

        //   if (graphArr != undefined) {
        //     for (let j = 0; j < datesArray.length; j++) {
        //       for (let i = 0; i < graphArr.length; i++) {
        //         if (graphArr[i].startDateLocal) {
        //           let d = graphArr[i].startDateLocal.split(" ");
        //           if (datesArray[j].startDateLocal === d[0]) {
        //             datesArray[j].averagePace = graphArr[i].averagePace;
        //             datesArray[j].averageSpeed = graphArr[i].avgSpeed;
        //             datesArray[j].distance = graphArr[i].distance;
        //             datesArray[j].averagePaceInHHMMSS =
        //               graphArr[i].averagePaceInHHMMSS;
        //             datesArray[j].completionTime = graphArr[i].completionTime; //.split(":");
        //           }
        //         }
        //       }
        //     }
        //     graphArr.map((data) => {
        //       teamGraphArray.push(data);
        //     });
        //   }

        //   if (teamGraphArray && teamGraphArray.length > 0) {
        //     this.setState({
        //       teamGraphData: teamGraphArray,
        //       graphData: datesArray,
        //     });
        //   }
        // });
        getMultipleGraphDetails(runnerId, eventId).then(
          (res) => {
            let datesArray = [];
            let teamGraphArray = [];
            let graphArr = res.data.runnerActivityDetails; //graph API data
            let startEvent=res?.data?.event?.localStartDate;
            this.setState({startEvent:startEvent});
            
            for (
              let index = 0;
              index < this.state.days;
              index++
            ) {
              let date = moment(startEvent)
                .add(index, "day")
                .format("DD-MM-YYYY");
              datesArray.push({
                // startDateLocal: moment(this.state.startEvent)p
                //   .add(index, "day")
                //   .format("DD-MM-YYYY"),
                startDateLocal: date,
                averagePace: 0,
                averageSpeed: 0,
                completionTime:
                  this.state?.result?.totalActivityDistance,
              });
            }
            let axis = res?.data?.xAxis;
console.log('mukaxis',axis);
            let totalTeamProgressTarget =
            axis === "distance" && this.state?.teamTarget % 1000===0
            ? parseFloat(
                this.state?.teamTarget / 1000
              ).toFixed(0) :axis === "distance" && this.state?.totalTarget % 1000!==0?parseFloat(
                this.state?.teamTarget / 1000
              ).toFixed(2)
            : this.state?.teamTarget;
            let selectedCategoryTeamValue =
              axis === "steps"
                ? this.state?.teamDetailsDTO?.teamTotalSteps
                : axis === "calories"
                ? this.state?.teamDetailsDTO?.totalTeamCalories
                : axis === "elevation"
                ? this.state?.teamDetailsDTO
                    ?.totalTeamElevationGain
                : axis === "wellnessPoint"
                ? this.state?.teamDetailsDTO
                    ?.teamTotalWellnessPoint
                :   this.state?.teamDetailsDTO?.teamDistance /
                1000===0? parseFloat(
                    this.state?.teamDetailsDTO?.teamDistance /
                      1000
                  ).toFixed(0):parseFloat(
                    this.state?.teamDetailsDTO?.teamDistance /
                      1000
                  ).toFixed(2);
            let calculateTeamCompletedTarget = (
              (Number(selectedCategoryTeamValue) /
                Number(totalTeamProgressTarget)) *
              100
            ).toFixed(2);
            this.setState({
              showGraph: true,
              graphXAxis: res?.data?.xAxis,
              completedProgress:
                this.state.eventDetails?.event?.graphToShow ===
                  "MULTIPLE" && calculateTeamCompletedTarget,
              targetedProgress:
                this.state.eventDetails?.event?.graphToShow ===
                  "MULTIPLE" && totalTeamProgressTarget,
              isProgressMaxThanTarget:
                this.state.eventDetails?.event?.graphToShow ===
                  "MULTIPLE" &&
                calculateTeamCompletedTarget > 100
                  ? true
                  : false,
              completedProgressActivity:
                this.state.eventDetails?.event?.graphToShow ===
                  "MULTIPLE" && selectedCategoryTeamValue,
            });
           console.log('graogH',this.state.graphXAxis)
            if (res.data.totalTeamDistance !== null) {
              this.setState({
                totalTeamDistance: res.data.totalTeamDistance,
                teamName: res.data.teamName,
                teamRank: res.data.teamRank,
              });
            }
            if (res.data.totalTeamSteps !== null) {
              this.setState({
                totalTeamSteps: res.data.totalTeamSteps,
                teamName: res.data.teamName,
                teamRank: res.data.teamRank,
              });
            }
            if (res.data.totalTeamWellnessPoints !== null) {
              this.setState({
                totalTeamWellnessPoints:
                  res.data.totalTeamWellnessPoints,
                teamName: res.data.teamName,
                teamRank: res.data.teamRank,
              });
            }
            if (graphArr != undefined) {
              for (let j = 0; j < datesArray.length; j++) {
                for (let i = 0; i < graphArr.length; i++) {
                  if (graphArr[i].startDateLocal) {
                    let d =
                      graphArr[i].startDateLocal.split(" ");
                    if (datesArray[j].startDateLocal === d[0]) {
                      datesArray[j] = graphArr[i];
                      datesArray[j].averagePace =
                        graphArr[i].averagePace;
                      datesArray[j].averageSpeed =
                        graphArr[i].avgSpeed;
                      datesArray[j].distance =
                        graphArr[i].distance;
                      datesArray[j].wellnessPoints =
                        graphArr[i].wellnessPoints;
                      datesArray[j].averagePaceInHHMMSS =
                        graphArr[i].averagePaceInHHMMSS;
                      datesArray[j].completionTime =
                        graphArr[i].completionTime; //.split(":");
                    }
                  }
                }
              }
              graphArr.map((data) => {
                teamGraphArray.push(data);
              });
            }
            if (teamGraphArray && teamGraphArray.length > 0) {
              this.setState({ teamGraphData: teamGraphArray ,teamGraphRunnerActivities:graphArr});
            }

          }
        );
      });
    }
  };
  getDialogComponent = () => {
    return (
      <div style={{ padding: "10px" }}>
        <ImageSlider files={this.state.imageList} />
        {/* <ImageSlider files={this.state.imagePath} /> */}
      </div>
    );
  };

  closeWindow = () => {
    this.setState({ showImage: false, showThumbnailImage: false });
  };

  downloadBIB = () => {
    let runnerId = localStorage.getItem("supportRunnerId");
    displayBIBFile(runnerId).then((res) => {
      this.setState({ img: res.imageData });
    });
  };

  downloadCertificate = () => {
    let runnerId = localStorage.getItem("supportRunnerId");
    displayCertificate(runnerId).then((res) => {
      this.setState({ certificateFile: res.imageData });
    });
  };
  downloadCollage = () => {
    let runnerId = localStorage.getItem("supportRunnerId");
    displayRunnerCollageFile(runnerId).then((res) => {
      this.setState({ collageFile: res.imageData });
    });
  };
  showPersonalData = () => {
    const { classes } = this.props;
    const { data } = this.state;

    return (
      <>
        <Grid container>
          <Grid item xs={6} className={classes.bottomMargin}>
            <Typography className={classes.displayTitle}>
              {Properties.Name_label}
            </Typography>
            <Typography className={classes.displayInfo}>
              {data.runner.name}
            </Typography>
          </Grid>
          {data.runner.age > 0 && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_age}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.runner.age}
              </Typography>
            </Grid>
          )}
          {data.runner.gender && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_Gender}
              </Typography>
              <Typography className={classes.displayInfo}>
                {ApplicationUtil.getCamelCasing(data.runner.gender)}
              </Typography>
            </Grid>
          )}
          {data.runner.email && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_Email}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.runner.email}
              </Typography>
            </Grid>
          )}
          {data.runner.city && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_City}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.runner.city}
              </Typography>
            </Grid>
          )}
          {data.runner.runnerAddress && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.address_label}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.runner.runnerAddress}
              </Typography>
            </Grid>
          )}
          {data.runner.country && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_Country}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.runner.country}
              </Typography>
            </Grid>
          )}
          {data.bibNumber && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.BIB_label}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.bibNumber}
              </Typography>
            </Grid>
          )}
          {data.runner.countryCode && data.runner.contactNumber && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_ContatNumber}
              </Typography>
              <Typography className={classes.displayInfo}>
                {"+" +
                  data.runner.countryCode +
                  " " +
                  data.runner.contactNumber}
              </Typography>
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  showTeamData = () => {
    const { classes } = this.props;
    const { data ,graphXAxis,graphYAxis,teamGraphData} = this.state;

    const challengeType = data.event.challengeType;
    const isTeamMember = data.runner.isTeamMember;

    return (
      <>
        <Grid container>
          {data.teamDetailsDTO.teamName && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.TeamNameLabel}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.teamDetailsDTO.teamName}
              </Typography>
            </Grid>
          )}
          {data.teamDetailsDTO.teamLeader && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.TeamLeaderName}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.teamDetailsDTO.teamLeader}
              </Typography>
            </Grid>
          )}
          {data.teamDetailsDTO.teamMemberActivities && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.TeamMembersLabel}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.teamDetailsDTO.teamMemberActivities &&
                  data.teamDetailsDTO.teamMemberActivities.map((ele) => (
                    <div>{ele.firstName + " " + ele.lastName}</div>
                  ))}
              </Typography>
            </Grid>
          )}
          {challengeType !== undefined &&
            (challengeType === "TEAM" || challengeType === "BOTH") &&
            isTeamMember && (
              <Grid
                item
                style={{
                  marginTop: "20px",
                }}
                xs={12}
              >
                <HorizontalLineGraph
                          graphData={teamGraphData}
                          graphXAxis={graphXAxis}
                          graphYAxis={graphYAxis}
                        />
              </Grid>
            )}
        </Grid>
      </>
    );
  };

  showActivityData = () => {
    const { classes } = this.props;
    const {
      data,
      activityList,
      userMessages,
      imageList,
      displayColumns,
      runnerDetails,
      runnerBib,
      isSupport,
      showActivityScreenshot
    } = this.state;
    
    return (
      <>
        <Grid container direction="row" alignItems="center">
          <Grid container item xs={12} md={6} sm={6}>
            {displayColumns?.length > 0 ? (
              displayColumns.map((column) => (
                <Grid item xs={6} className={classes.bottomMargin}>
                  <Typography className={classes.displayTitle}>
                    {column === "totalActivityDistance" &&
                      data?.runnerActivity?.totalActivityDistance &&
                      Properties.Total_Activity_Distance_Label}
                    {column === "totalActivityTime" &&
                      data.runnerActivity.totalActivityTime &&
                      Properties.Total_Activity_Time_Label}
                    {column === "totalSteps" &&
                      data?.runnerActivity?.totalSteps > -1 &&
                      Properties.Total_Activity_Steps_Label}
                    {column === "totalElevationGain" &&
                      Properties.Elevation_label}
                    {column === "totalCalories" &&
                      data?.runnerActivity?.totalCalories > -1 &&
                      Properties.Total_Activity_Calories_Label}
                  </Typography>
                  <Typography className={classes.displayInfo}>
                    {column === "totalActivityDistance" &&
                      data?.runnerActivity?.totalActivityDistance && (
                        <span>
                          {parseFloat(
                            ApplicationUtil.getFormatedDistance(
                              data.runnerActivity.totalActivityDistance
                            ) / 1000
                          ).toFixed(2)}{" "}
                          {"Km"}
                        </span>
                      )}

                    {column === "totalActivityTime" &&
                      data?.runnerActivity?.totalActivityTime &&
                      data?.runnerActivity?.totalActivityTime}
                    {column === "totalSteps" &&
                      data?.runnerActivity?.totalSteps > -1 &&
                      data?.runnerActivity?.totalSteps}
                    {column === "totalElevationGain" &&
                      data?.runnerActivity?.totalElevationGain}
                    {column === "totalCalories" &&
                      data?.runnerActivity?.totalCalories > -1 &&
                      data.runnerActivity.totalCalories}
                  </Typography>
                </Grid>
              ))
            ) : (
              <>
                {" "}
                {data.runnerActivity.totalActivityDistance && (
                  <Grid item xs={6} className={classes.bottomMargin}>
                    <Typography className={classes.displayTitle}>
                      {Properties.Total_Activity_Distance_Label}
                    </Typography>
                    <Typography className={classes.displayInfo}>
                      {parseFloat(
                        ApplicationUtil.getFormatedDistance(
                          data.runnerActivity.totalActivityDistance
                        ) / 1000
                      ).toFixed(2)}
                      {" Km"}
                    </Typography>
                  </Grid>
                )}
                {data.runnerActivity.totalActivityTime && (
                  <Grid item xs={6} className={classes.bottomMargin}>
                    <Typography className={classes.displayTitle}>
                      {Properties.Total_Activity_Time_Label}
                    </Typography>
                    <Typography className={classes.displayInfo}>
                      {data.runnerActivity.totalActivityTime}
                    </Typography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
          {this.state.imageList?.length > 0 && (
            <Grid
              container
              item
              xs={12}
              md={6}
              sm={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div className={classes.picture}>
                <div className={classes.profileImgContainer}>
                  <img
                    className={classes.profileImg}
                    src={
                      this.state.imageList
                        ? "data:image/png;base64," +
                          this.state.imageList[0]?.imageData
                        : null
                    }
                    alt={"pic"}
                    onClick={() => {
                      this.setState({ showImage: true });
                    }}
                  />
                </div>
                {this.state.imageList?.length > 1 && (
                  <p
                    style={{ borderRadius: "50%" }}
                    onClick={() => {
                      this.setState({ showImage: true });
                    }}
                  >
                    +{this.state.imageList.length - 1}
                  </p>
                )}
              </div>
            </Grid>
          )}
        </Grid>
        <Grid container>
          {activityList?.length > 0 ? (
            <ActivityList
              isSupport={isSupport}
              runnerDetails={runnerDetails}
              runnerBib={runnerBib}
              data={activityList}
              userMessages={userMessages}
              updateUserMessages={(data) => {
                this.setState({
                  userMessages: data,
                });
              }}
              updateActivityList={(data) => {
                this.setState({ activityList: data });
              }}
              showActivityScreenshot={showActivityScreenshot}
              challengeType={data?.event?.challengeType}
              onClick={() => this.pageRefresh()}
              displayColumns={this.state.displayColumns}
              type={
                data.eventRunCategories[0] &&
                data.eventRunCategories[0].activityType.type
                  ? data.eventRunCategories[0].activityType.type
                  : "RUN"
              }
            />
          ) : (
            <div
              style={{
                color: "gray",
                margin: "0 auto",
                fontSize: "25px",
              }}
            >
              <p>No Activities available</p>
            </div>
          )}
        </Grid>
      </>
    );
  };

  showEventRegistrationData = () => {
    const { classes } = this.props;
    const { data } = this.state;
    const details = data.eventRunCategories[0];
    let additionalDetails = null;
    if (data.fields && data.fields.customFields) {
      additionalDetails = data.fields.customFields;
    }
    return (
      <>
        <Grid container>
          <Grid item xs={6} className={classes.bottomMargin}>
            <Typography className={classes.displayTitle}>
              {Properties.RegistrationTypeLabel}
            </Typography>
            <Typography className={classes.displayInfo}>
              {data.imported ? Properties.OfflineLabel : Properties.OnlineLabel}
            </Typography>
          </Grid>

          {data.registrationDate && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.RegistrationDateLabel}
              </Typography>
              <Typography className={classes.displayInfo}>
                {ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                  data.registrationDate
                )}
              </Typography>
            </Grid>
          )}

          {details.activityType && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.ActivityType_label}
              </Typography>
              <Typography className={classes.displayInfo}>
                {details.activityType.displayName}
              </Typography>
            </Grid>
          )}
          {details.categoryName && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Category_label}
              </Typography>
              <Typography className={classes.displayInfo}>
                {details.categoryName}
              </Typography>
            </Grid>
          )}
          {additionalDetails && additionalDetails.length > 0 && (
            <Grid item xs={6} className={classes.bottomMargin}>
              {additionalDetails &&
                additionalDetails.length > 0 &&
                additionalDetails.map(
                  (ele) =>
                    ele.fieldValue && (
                      <>
                        <Typography className={classes.displayTitle}>
                          {ele.displayName}
                        </Typography>
                        <Typography className={classes.displayInfo}>
                          {ele.fieldValue}
                          {/* <div>{ele.firstName + " " + ele.lastName}</div> */}
                        </Typography>
                      </>
                    )
                )}
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  showEventItemData = () => {
    const { classes } = this.props;
    const { data  } = this.state;
    console.log('data',data.paymentDetails.items);
    return (
      <>
        {data.paymentDetails && (
          <Grid container>
            {data.paymentDetails.items &&
              data.paymentDetails.items.map((ele) => (
                <>
                  <Grid item xs={6} className={classes.bottomMargin}>
                    <Typography className={classes.displayTitle}>
                      {Properties.itemNameLabel}
                    </Typography>
                    <Typography className={classes.displayInfo}>
                      {ele.itemName}
                    </Typography>
                  </Grid>
                  {ele.size && (
                    <Grid item xs={6} className={classes.bottomMargin}>
                      <Typography className={classes.displayTitle}>
                        {Properties.Label_TShirtSize}
                      </Typography>
                      <Typography className={classes.displayInfo}>
                        {ele.size}
                      </Typography>
                    </Grid>
                  )}
                  {ele.quantity && (
                    <Grid item xs={6} className={classes.bottomMargin}>
                      <Typography className={classes.displayTitle}>
                        {Properties.itemQuantityLabel}
                      </Typography>
                      <Typography className={classes.displayInfo}>
                        {ele.quantity}
                      </Typography>
                    </Grid>
                  )}
                </>
              ))}
            {data.paymentDetails && (
              <Grid item xs={6} className={classes.bottomMargin}>
                <Typography className={classes.displayTitle}>
                  {Properties.itemAmountLabel}
                </Typography>
                <Typography className={classes.displayInfo}>
                  {data.paymentDetails.totalItemPrice}
                </Typography>
              </Grid>
            )}
          {data.paymentDetails.shippingAddress && (
              <Grid item xs={6} className={classes.bottomMargin}>
                <Typography className={classes.displayTitle}>
                  {Properties.shipping_Address_Label}
                </Typography>
                <Typography className={classes.displayInfo}>
                  {data.paymentDetails.shippingAddress}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </>
    );
  };

  showPaymentData = () => {
    const { classes } = this.props;
    const { data } = this.state;

    return (
      <>
        {data.paymentDetails && (
          <Grid container>
            {data.paymentDetails.totalAmount && (
              <Grid item xs={6} className={classes.bottomMargin}>
                <Typography className={classes.displayTitle}>
                  {Properties.Total_Amount}
                </Typography>
                <Typography className={classes.displayInfo}>
                  {data.paymentDetails.totalAmount}
                </Typography>
              </Grid>
            )}
            {data.paymentDetails.paymentStatus && (
              <Grid item xs={6} className={classes.bottomMargin}>
                <Typography className={classes.displayTitle}>
                  {Properties.paymentStatusLabel}
                </Typography>
                <Typography className={classes.displayInfo}>
                  {data.paymentDetails.paymentStatus}
                </Typography>
              </Grid>
            )}
            {data.paymentDetails.orderDetails &&
              data.paymentDetails.orderDetails.paymentId && (
                <Grid item xs={6} className={classes.bottomMargin}>
                  <Typography className={classes.displayTitle}>
                    {Properties.paymentIdLabel}
                  </Typography>
                  <Typography className={classes.displayInfo}>
                    {data.paymentDetails.orderDetails.paymentId}
                  </Typography>
                </Grid>
              )}
          </Grid>
        )}
      </>
    );
  };
  fullImageView = (src) => {
    this.setState({ imageDialog: true, image_src: src });
  };

  showResults = () => {
    const { classes } = this.props;
    const { data } = this.state;
    const { img, certificateFile, collageFile, videoUrl } = this.state;
    return (
      <>
        {data.bibStatus === "AVAILABLE" ||
        data.certificateStatus === "AVAILABLE" ||
        data.runnerCollageStatus === "AVAILABLE" ||
        data.videoStatus === "AVAILABLE" ? (
          <Grid container spacing={3} style={{ margin: "25px 0px" }}>
            {data.bibStatus === "AVAILABLE" && (
              <Grid xs={4}>
                <ThumbnailViewer
                  title={"BIB"}
                  src={img}
                  onClick={(e) => {
                    this.setState({
                      dialogTitle: "BIB",
                      showThumbnailImage: true,
                      imagePath: `data:image/png;base64,${img}`,
                    });
                  }}
                />
              </Grid>
            )}

            {data.certificateStatus === "AVAILABLE" && (
              <Grid xs={4}>
                <ThumbnailViewer
                  title={"Certificate"}
                  src={certificateFile}
                  onClick={(e) => {
                    this.setState({
                      dialogTitle: "Certificate",
                      showThumbnailImage: true,
                      imagePath: `data:image/png;base64,${certificateFile}`,
                    });
                  }}
                />{" "}
              </Grid>
            )}
            {data.runnerCollageStatus === "AVAILABLE" && (
              <Grid xs={4}>
                <ThumbnailViewer
                  title={"Collage"}
                  src={collageFile}
                  onClick={(e) =>
                    this.setState({
                      dialogTitle: "Collage",
                      showThumbnailImage: true,
                      imagePath: `data:image/png;base64,${collageFile}`,
                    })
                  }
                />
              </Grid>
            )}

            {data.videoStatus === "AVAILABLE" && (
              <Grid className={classes.bottomMargin} xs={3}>
                {" "}
                <VideoThumbnail src={img} url={videoUrl} title={"Video"} />
              </Grid>
            )}
          </Grid>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              color: "gray",
              fontSize: "25px",
            }}
          >
            <p>No Goodies available</p>
          </div>
        )}
      </>
    );
  };

  handleTabChange = (event, value) => {
    this.setState({
      ActiveTabIndex: value,
      activeTabTitle: event.target.innerHTML,
    });
  };
  render() {
    const { value } = this.state;
    const { classes } = this.props;

    const { data, ActiveTabIndex, activeTabTitle, isSupport } = this.state;
    return (
      <div className={classes.mainBody}>
        {data.runner && (
          <Grid
            style={{ width: "95%", margin: "auto" }}
            className={classes.mainCss}
          >
            <Grid>
              <Typography className={classes.runnerNameCss}>
                {data.runner.name}
              </Typography>
              {data.bibNumber && (
                <Grid item xs={6}>
                  <Typography className={classes.displayTitle}>
                    {data.bibNumber}
                  </Typography>
                </Grid>
              )}
              {data.eventRunCategories[0] && (
                <Grid item xs={6} className={classes.bottomMargin}>
                  <Typography className={classes.displayTitle}>
                    {/* {data.eventRunCategories[0].activityType.displayName}
                    {"-"} */}
                    {data.eventRunCategories[0].categoryName}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Tabs
              value={this.state.ActiveTabIndex}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.handleTabChange}
              variant={isMobile ? "scrollable" : "fullWidth"}
              scrollButtons={isMobile ? "on" : "off"}
            >
              <Tab
                label={Properties.PersonalDataLabel}
                className={classes.textTransformCss}
              />
              {data && data.eventRunCategories[0] && (
                <Tab
                  label={Properties.RegistrationDataLabel}
                  className={classes.textTransformCss}
                />
              )}

              {data.teamDetailsDTO && (
                <Tab
                  label={Properties.TeamDataLabel}
                  className={classes.textTransformCss}
                />
              )}

              {data.runnerActivity && data.runnerActivity.activityList && (
                <Tab
                  label={Properties.ActivityDataLabel}
                  className={classes.textTransformCss}
                />
              )}

              <Tab
                label={Properties.GoodiesLabel}
                className={classes.textTransformCss}
              />
            </Tabs>

            <div className={classes.root}>
              {activeTabTitle === Properties.PersonalDataLabel &&
                this.showPersonalData()}

              {activeTabTitle === Properties.RegistrationDataLabel &&
                this.showEventRegistrationData()}

              {activeTabTitle === Properties.TeamDataLabel &&
                this.showTeamData()}

              {activeTabTitle === Properties.ActivityDataLabel &&
                this.showActivityData()}

              {activeTabTitle === Properties.GoodiesLabel && this.showResults()}
            </div>

            <DialogBox
              title={this.state.dialogTitle}
              // addPhoto={this.state.isUploadAndAddPhotoAvaialable}
              // onAddPhoto={this.multipleUpload}
              header={"Post Run Picture"}
              open={this.state.showImage}
              message={this.getDialogComponent()}
              onClick={() => this.closeWindow()}
              closeDisloag={() => this.closeWindow()}
            />

            <ImageDialogBox
              title={this.state.dialogTitle}
              open={this.state.showThumbnailImage}
              imagePath={this.state.imagePath}
              closeDisloag={() => this.closeWindow()}
            />
          </Grid>
        )}
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(ParticipantExecutationDetails);
