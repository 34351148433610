import { withStyles } from "@material-ui/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

const ProgramCertificates = () => {
  return <div>Certificate</div>;
};

const styles = (theme) => ({});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ProgramCertificates);
