import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import { compose } from "recompose";
import {
  Checkbox,
  Chip,
  Grid,
  Popover,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import { CustomTextField } from "../../../../../../../common/CustomTextField";

import SubmitCancelBtn from "../../../../../../../common/SubmitCancelBtn";
import ApplicationUtil from "../../../../../../../../utils/ApplicationUtil";

import Snackbar from "../../../../../../../common/Snackbar";
import NewTextEditor from "../../../../../../../common/NewTextEditor";
import DialogBox from "../../../../../../../common/DialogBox";
import Properties from "../../../../../../../../properties/Properties";
import {
  createEmailReminderContent,
  updateEmailReminderContent,
} from "../../../../../../../../apis/programService";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
/**
 * QUESTION CREATION COMPONENT
 */
const CreateEmailReminederContent = ({
  classes,
  InitialLoad,
  editRecord,
  isPastChallenge,
  setListOfContents,
}) => {
  /**
   * Current Challenge Data
   */
  const currentChallenge = ApplicationUtil?.getProgramUnderCurrentChallenge();

  /**
   * QUESTION COMPONENT STATE
   */
  const [contentState, setContentState] = useState({
    Subject: editRecord ? editRecord?.subject : "",
    subjectError: false,

    htmlTextValue: "",
    htmlTextValueError: false,
    openTextEditor: false,
    isEditable: false,
    showCancelDialog: false,
  });

  const {
    Subject,
    subjectError,

    htmlTextValue,
    htmlTextValueError,
    openTextEditor,
    isEditable,
    showCancelDialog,
  } = contentState;

  const getDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.CreateEvent_CancelClickWarning}</span>
        </Typography>
      </div>
    );
  };

  const SubmitQuestion = () => {
    let paramsData = {
      programChallengeType: currentChallenge?.programChallengeType,
      subject: Subject,
      content: htmlTextValue,
    };

    if (editRecord) {
      // udpdate record

      updateEmailReminderContent({ ...paramsData, id: editRecord.id }).then(
        (res) => {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Content updated successfully.",
          });
          setContentState((prev) => ({
            ...prev,
            htmlTextValue: "",
            Subject: "",
          }));
          InitialLoad();
          setListOfContents((prev) => ({ ...prev, editRecord: null }));
        }
      );
    } else {
      //  create api  and fetch data
      createEmailReminderContent(paramsData).then((res) => {
        setContentState((prev) => ({
          ...prev,
          htmlTextValue: "",
          Subject: "",
        }));
        InitialLoad();
      });
    }
  };

  const saveQuestionHandler = () => {
    if (!Subject || subjectError === true) {
      setContentState((prev) => ({ ...prev, subjectError: true }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter subject.",
      });

      return "";
    } else if (!htmlTextValue || htmlTextValueError === true) {
      setContentState((prev) => ({ ...prev, htmlTextValueError: true }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter content.",
      });

      return "";
    } else {
      SubmitQuestion();
    }
  };

  useEffect(() => {
    //    fetching the apis here
    if (editRecord?.id) {
      let { subject, content } = editRecord;
      setContentState((prev) => ({
        ...prev,
        Subject: subject,
        htmlTextValue: content,
        isEditable: true,
      }));
    }
  }, [editRecord]);

  const _getTextEditor = () => {
    return (
      <div
        style={{
          width: "80%",
          height: "auto",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <NewTextEditor
          htmlText={htmlTextValue ? htmlTextValue : ""}
          getEditedValue={(value) => {
            let formattedValue = value === "<p></p>" ? null : value.toString();

            setContentState((prev) => ({
              ...prev,
              htmlTextValue: formattedValue,
              htmlTextValueError: false,
            }));
          }}
          isResponses={(isResponded) => {
            setContentState((prev) => ({
              ...prev,
              textError: !isResponded,
            }));
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <SubmitCancelBtn
            type="submit"
            label={"Submit"}
            onClick={() => {
              setContentState((prev) => ({
                ...prev,
                openTextEditor: false,
              }));
            }}
          />
        </div>
      </div>
    );
  };

  const closeDialogBox = () => {
    setContentState((prev) => ({
      ...prev,

      //reset the previous errors on cancel
      Subject: "",
      subjectError: false,
      htmlTextValue: "",
      htmlTextValueError: false,
      openTextEditor: false,
      isEditable: false,
      showCancelDialog: false,
    }));
  };

  return (
    <div>
      <h3 className={classes.title}>
        {Properties?.email_reminder_section_title}:
      </h3>
      <Grid
        container
        spacing={1}
        style={{
          padding: "10px 5px",
        }}
      >
        <Grid
          item
          xs={12}
          style={{ display: "flex", padding: "6px  0", alignItems: "center" }}
        >
          <Typography style={{ width: "15%" }}>
            <span className={classes.labelCss}>
              {Properties.subject_label}
              <span className={classes.mandatoryCss}>*</span> : &nbsp;
            </span>
          </Typography>
          <CustomTextField
            className={subjectError ? classes.fieldErrorCss : classes.fieldCss}
            type="text"
            placeholder={Properties?.Subject_placeholder}
            value={Subject}
            handleChange={(prop) => (e) => {
              setContentState((prev) => ({
                ...prev,

                Subject: e.target.value,
                subjectError: false,
              }));
            }}
            readOnly={isPastChallenge}
          />
        </Grid>

        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ width: "15%" }}>
            <span className={classes.labelCss}>
              {Properties?.content_label}
              <span style={{ color: "red" }}>*</span> : &nbsp;
            </span>
          </Typography>

          <TextareaAutosize
                value={htmlTextValue}
                className={htmlTextValueError ? classes.textAreaFieldErrorCss : classes.textAreaField}
                minRows={5}
                // placeholder="Minimum 3 rows"
                style={{ width: "29%", borderRadius:"4px", fontWeight:"normal" }}
                onChange={(e) => {
                  setContentState((prev) => ({
                    ...prev,
    
                    htmlTextValue: e.target.value,
                    htmlTextValueError: false,
                  }));
                }}
              />

          {/* <div
            style={{
              width: "440px",
              height: "115px",
              borderRadius: "3px",
              padding: "0 5px",
              border: htmlTextValueError
                ? "1px solid red"
                : "1px solid #08456C",
              overflowY: "auto",
              marginLeft: "-2px",
            }}
            onClick={() => {
              setContentState((prev) => ({
                ...prev,
                openTextEditor: isPastChallenge ? false : true,
              }));
            }}
          >
            {htmlTextValue && (
              <NewTextEditor
                readOnly
                htmlText={htmlTextValue}
                propsText={htmlTextValue}
              />
            )}
          </div> */}
        </Grid>
      </Grid>
      {!isPastChallenge && (
        <Grid
          container
          style={{
            padding: "30px 0 20px 0",
          }}
          spacing={2}
        >
          {" "}
          <Grid
            container
            style={{
              padding: "30px 0 40px 0",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item xs={4}>
              <SubmitCancelBtn
                label={"Cancel"}
                onClick={() => {
                  setContentState((prev) => ({
                    ...prev,

                    showCancelDialog: true,
                  }));
                }}
                type="submit"
                style={{ width: "200px" }}
              />
            </Grid>

            <Grid item xs={4}>
              <SubmitCancelBtn
                onClick={(e) => saveQuestionHandler()}
                label={editRecord?.id ? "Update" : "Submit"}
                type="submit"
                style={{ width: "200px" }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <DialogBox
        signup={true}
        open={showCancelDialog}
        message={getDialogMsg()}
        header={""}
        label={"No"}
        onClick={() => {
          setContentState((prev) => ({
            ...prev,

            showCancelDialog: false,
          }));
        }}
        labelTwo={"Yes"}
        onClickTwo={() => {
          closeDialogBox();
        }}
        closeDisloag={() => {}}
      />

      <DialogBox
        fullWidth
        maxWidth={"md"}
        open={openTextEditor}
        message={_getTextEditor()}
        header={"Content"}
        closeDialog={() =>
          setContentState((prev) => ({
            ...prev,
            openTextEditor: false,
          }))
        }
      />
    </div>
  );
};

const styles = (theme) => ({
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  autocompletefield: {
    border: "1px solid #08456C",
    width: "80% !important",
    padding: "0 4px",
    borderRadius: "3px",
  },

  selectQuestionDialogWrapper: {
    width: "100%",
    padding: "0",
  },

  fieldWrapper: {
    display: "flex",
    width: "100%",
    "& p": {
      width: "30%",
      display: "flex",
      justifyContent: "end",
    },
    // "& div": {
    //   width: "85%",
    // },
  },
  btnGroup: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    padding: "20px 0",
  },

  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
  },
  optionsCss: {
    display: "flex",
    // width: "100%",
    flex: "flex-wrap",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },

  expectedAnwerLabel: {
    color: "#727272",
    width: "fit-Content",
  },
  labelCss: {
    color: "#727272",
  },
  groupLabelCss: {
    fontSize: "20px",
    color: "rgb(8, 69, 108)",
  },
  mandatoryCss: { color: "red" },
  fieldCss: {
    border: "1px solid #08456C",
    height: "40px", //"45%",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  textAreaField:{
    border: "1px solid #08456C",
    fontSize: "18px",
    padding:"10px",
    fontWeight:"400 !important",
    color:"rgba(0, 0, 0, 0.87)",
    fontFamily:"Rubik"
  },
  textAreaFieldErrorCss:{
    border: "1px solid red",
    fontSize: "18px",
    fontWeight:"400 !important",
    color:"rgba(0, 0, 0, 0.87)",
    fontFamily:"Rubik",
    padding:"10px",
  },
  fieldErrorCss: {
    border: "1px solid red",
    height: "40px",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  textAreafieldCss: {
    border: "1px solid #08456C",
    height: "100px",
    maxHeight: "100px",
    minHeight: "100px",
    width: isBrowser ? "360px" : "160px",
  },
  textAreaErrorCss: {
    border: "1px solid red",
    height: "100px",
    maxHeight: "100px",
    minHeight: "100px",
    width: isBrowser ? "360px" : "160px",
  },
  tdropdownCss: { width: "180px" },
  dropdownCss: {
    width: isBrowser ? "360px" : "160px",
  },
  // custom fields
  root: {
    marginBottom: "20px",
    paddingBottom: "20px",
    borderBottom: "1px solid rgb(8, 69, 108)",
  },
  title: {
    color: "rgb(8, 69, 108)",
  },
  tableFieldCss: {
    border: "1px solid #08456C",
    height: "40px", //"45%",
    maxHeight: "40px",
    minHeight: "40px",
  },
  chipCss: {
    border: "1px solid #08456C",
    borderRadius: "4px",
    // height: "40px", //"45%",
    padding: "2px",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  tableHead: {
    backgroundColor: "#ced4da !important",
    "& th:first-child": {
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
      width: "30px",
    },
    "& th": {
      backgroundColor: "#ced4da",
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
      width: "100px",
    },
  },
  tableBody: {
    height: "300px",
    overflowY: "auto",
    "& tr td": {
      width: "100px",
      display: "flex",
      alignItems: "center",

      overflow: "hidden",
      textOverflow: "ellipsis",
      // textAlign: "center",
    },
  },
  fieldsContainer: {
    marginTop: "20px",
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "20px",
  },
  addIcon: {
    fontSize: "30px",
    cursor: "pointer",
    color: "rgb(8, 69, 108)",
  },
  textLink: {
    backgroundColor: "#ade8f4",
    fontWeight: "bold",
    color: "#023e8a",
    cursor: "pointer",
    borderRadius: "5px",
    width: "60px",
    margin: "auto",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&:hover": {
      color: "#03045e",
    },
  },
  moreFieldOptions: {
    padding: "8px 24px",
  },
  addButton: {
    // width: "120px",
    textDecoration: "none",
    backgroundColor: "#09456c",
    color: "white",
    borderRadius: "20px",
    // marginLeft: "auto",
  },
  addBtnText: {
    backgroundColor: "#09456c",
    color: "white",
  },
  saveButton: {
    width: "120px",
  },
  option: {
    border: "1px solid #727272",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "10px",
    paddingBottom: "10px",
    // paddingRight: "",
  },
  optionIcons: {
    display: "flex",
    alignItems: "center",
  },
  optionListContainer: {
    // maxHeight: "300px",
    // overflowY: "auto",
    // overflowX: "hidden",
    marginBottom: "10px",
    paddingLeft: "10px",
  },
  topFieldAdd: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "20px",
  },
  linkText: {
    color: "rgb(8, 69, 108)",
    cursor: "pointer",
    textDecoration: "underline",
  },
  parentDivCss: {
    height: "50px",
  },
  childQuestionDivCss: {
    width: "180px",
    maxWidth: "180px",
    minWidth: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  childDivCss: {
    position: "absolute",
    width: "180px",
    maxWidth: "180px",
    minWidth: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // zIndex: "1",
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(CreateEmailReminederContent);
