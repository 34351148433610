import React, { Component } from "react";
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@material-ui/core";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import ApplicationUtil from "./../../../../../utils/ApplicationUtil";
import Properties from "./../../../../../properties/Properties";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  racedetails: {
    width: "100%",
    "& span": {
      fontSize: "20px",
    },
  },
  racedetails_heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "60px",
    "& h2": {
      fontWeight: 600,
      color: " rgb(40, 131, 181)",
    },
  },

  racedetails_icon: {
    fontSize: "50px !important",
    marginRight: "10px",
    color: "rgb(40, 131, 181)",
  },
  racedetails_table: {
    overflowX: "auto",
  },
});
class RaceDetails extends Component {
  getSuggestedPace = (pace) => {
    if (pace) {
      let paceArr = pace.split(":");
      let first = paceArr[0];
      let second = paceArr[1];
      let val1, val2;
      let parsedVal = parseInt(first);
      let parsedVal2 = parseInt(second);
      if (parsedVal < 10) {
        val1 = "0" + parsedVal;
      } else {
        val1 = first;
      }
      if (parsedVal2 < 10) {
        val2 = "0" + parsedVal2;
      } else {
        val2 = second;
      }
      return val1 + ":" + val2 + " min/km";
    } else {
      return pace + " min/km";
    }
  };
  render() {
    const { classes, details, activityType } = this.props;

    return (
      <>
        <Paper className={classes.racedetails} square elevation={3}>
          <Paper className={classes.racedetails_heading} variant="outlined">
            <AssessmentOutlinedIcon className={classes.racedetails_icon} />
            <h2>{Properties.Label_ResultRaceDetails}</h2>
          </Paper>
          <Paper className={classes.racedetails_table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <span>
                      <b>{Properties.Interval_Label}</b>
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span>
                      <b>{Properties.Time_label}</b>
                    </span>
                  </TableCell>
                  {/*<TableCell>
                  <span>{"Chip Pace(min/km)"}</span>
                </TableCell> */}
                  <TableCell align="center">
                    <span>
                      <b>
                        {activityType === "CYCLE"
                          ? Properties.SpeedAvg
                          : Properties.PaceAvg}
                      </b>
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details ? (
                  details.map((data) => {
                    let splitData = [];
                    if (data.activitySplits) {
                      data.activitySplits.map((val) => {
                        if (val.splitMode === "KM") {
                          splitData.push(val);
                        }
                      });
                    }
                    if (splitData) {
                      return splitData.map((data) => (
                        <TableRow key={data.id}>
                          <TableCell align="center">{`Split @ ${parseFloat(
                            ApplicationUtil.getFormattedKM(
                              data.distance.toString()
                            ) / 1000
                          ).toFixed(2)} Km`}</TableCell>

                          <TableCell align="center">
                            {data.completionTime}
                          </TableCell>

                          <TableCell align="center">
                            {activityType === "CYCLE"
                              ? `${data.averageSpeed} km/hr`
                              : this.getSuggestedPace(data.averagePace)}
                          </TableCell>
                        </TableRow>
                      ));
                    }
                  })
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Paper>
      </>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }),withRouter)(RaceDetails);
