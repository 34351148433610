import http from "./httpService";
import { loading } from "../components/common/Spinner";
import Properties from "../properties/Properties";
import Snackbar from "../components/common/Snackbar";
import ApplicationUtil from "../utils/ApplicationUtil";

const API_URL_FILE = http.API_URL();
export function getIndividualDetails(eventId, runnerId, scanId) {
  let token = ApplicationUtil.getRunnerToken();

  loading(true);
  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    //responseType: "blob",
  };
  let url = scanId
    ? `${API_URL_FILE}api/v1/location/track/data/detail/${eventId}/${runnerId}/${scanId}`
    : `${API_URL_FILE}api/v1/location/track/data/${eventId}/${runnerId}`;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        loading(false);
        resolve(res);
      })
      .catch((error) => {
        console.log(error.response)
        loading(false);
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message:error.response?.data?.error?.code == '404' ?error.response?.data?.error?.verbose : Properties.Something_went_wrong,
        });
        reject(error);
      });
  });
}

export function getAllParticipantsDetails(token, eventId) {
  loading(true);
  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    //responseType: "blob",
  };
  let url = `${API_URL_FILE}api/v1/location/track/data/${eventId}`;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        loading(false);
        resolve(res);
      })
      .catch((error) => {
        loading(false);
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.Something_went_wrong,
        });
        reject(error);
      });
  });
}
