import http from "./httpService";
import { loading } from "../components/common/Spinner";
import ApplicationUtil from "../utils/ApplicationUtil";
import Properties from "../properties/Properties";
import Snackbar from "../components/common/Snackbar";

const API_URL_FILE = http.API_URL();

export default function getUserDetails(token, userId) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/user/profile/" + userId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        reject(error);
      });
  });
}

//user subscribed services
export function getUserSubscribedService() {
  loading(true);
  // let token = localStorage.getItem("token");
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  //userid
  let supportUserId = localStorage.getItem("supportUserId");
  let runnerUserId = localStorage.getItem("userId");
  let userId = window.location.href.includes("/organizer")
    ? supportUserId
    : runnerUserId;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/user/subscribedService/" + userId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        reject(error);
      });
  });
}

// get Consent
export function getConsentUrl(id, userId) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      // Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/user/consentUrl/" + userId + "/" + id;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        reject(error);
      });
  });
}



export function getTokenAuthenticated(params) {
  

  loading(true);
let token=ApplicationUtil.getRunnerToken()
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/link/authenticate";// + params;

  return new Promise(function (resolve, reject) {
    http
      .post(url, params, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
