import React, { Component } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";
import moment from "moment";

const styles = (theme) => ({
  root: {
    margin: "10px 15px",
    // minHeight: "200px",
  },
  tableHead: {
    backgroundColor: "#ced4da",
    "& th": {
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
    },
  },
  tableRow: {
    cursor: "pointer",
    "& td": {
      fontWeight: "bold",
      fontFamily: "Rubik, sans-serif",
    },

    "&.Mui-selected": {
      backgroundColor: "#e9ecef",
      "&.MuiTableRow-hover": {
        backgroundColor: "#e9ecef",
      },
    },
  },
  participantsContainer: {
    padding: "0px 5px",
  },
  name: {
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
});
class ListView extends Component {
  render() {
    const {
      classes,
      data,
      getSelectedParticipant,
      isAllParticipants,
      eventDetailView,
      resultsView,
      scanPointList,
    } = this.props;
    return (
      <div
        className={classes.root}
        style={{ margin: eventDetailView ? "10px 0px" : "10px 15px" }}
      >
        <TableContainer component={Paper} className={classes.tableList}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHead}>
                {isAllParticipants && (
                  <TableCell component="th">Participant Name</TableCell>
                )}{" "}
                {!isAllParticipants && resultsView && (
                  <TableCell component="th">Scan Point</TableCell>
                )}
                {eventDetailView && (
                  <TableCell component="th">
                    Time <span style={{ fontSize: "12px" }}>(HH:MM:SS)</span>
                  </TableCell>
                )}
                {/* {eventDetailView || resultsView && ( */}
                {scanPointList &&
                  scanPointList.length > 1 &&
                  eventDetailView && (
                    <TableCell component="th">
                      Duration{" "}
                      <span style={{ fontSize: "12px" }}>(HH:MM:SS)</span>
                    </TableCell>
                  )}
                {/* // )} */}
                {scanPointList &&
                  scanPointList.length > 1 &&
                  eventDetailView && (
                    <TableCell component="th">Distance</TableCell>
                  )}
              </TableRow>
            </TableHead>{" "}
            <TableBody>
              {data?.map((row, index) => {
                return (
                  <TableRow
                    hover={isAllParticipants && getSelectedParticipant}
                    key={index}
                    component={Paper}
                    className={classes.tableRow}
                    onClick={
                      isAllParticipants && getSelectedParticipant
                        ? () => getSelectedParticipant(row?.runnerId)
                        : undefined
                    }
                    style={{
                      cursor:
                        isAllParticipants && getSelectedParticipant
                          ? "pointer"
                          : "default",
                    }}
                  >
                    {" "}
                    {isAllParticipants && (
                      <TableCell
                        component="td"
                        align="left"
                        style={{ padding: "0px 18px", maxWidth: "90px" }}
                      >
                        <Typography className={classes.name}>
                          {row?.name}
                        </Typography>
                      </TableCell>
                    )}
                    {!isAllParticipants && resultsView && (
                      <TableCell component="td" style={{ padding: "0px 18px" }}>
                        {row?.scanPoint}
                      </TableCell>
                    )}
                    {eventDetailView && (
                      <TableCell component="td" align="center">
                        {row?.lastScannedTime ? row.lastScannedTime : "-"}
                      </TableCell>
                    )}
                    {/* {showDistanceAndDuration && ( */}
                    {scanPointList &&
                      scanPointList.length > 1 &&
                      eventDetailView && (
                        <TableCell component="td" align="center">
                          {row?.activityTime ? row.activityTime : "-"}
                        </TableCell>
                      )}
                    {/* )} */}
                    {scanPointList &&
                      scanPointList.length > 1 &&
                      eventDetailView && (
                        <TableCell component="td" align="center">
                          {row?.distance >= 0
                            ? (row.distance / 1000).toFixed(2) + " Km"
                            : "-"}
                        </TableCell>
                      )}
                    {/* <TableCell component="td" align="left">
                      {row?.recordedBy ? row.recordedBy : "-"}
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <ClickAwayListener onClickAway={this.closeDialog}> */}
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ListView);
