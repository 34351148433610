import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { Card, CardMedia, CardContent, Typography } from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EventIcon from "@material-ui/icons/Event";
import LanguageIcon from "@material-ui/icons/Language";
import { format, isAfter, isBefore } from "date-fns";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";
import Properties from "../../../../../../properties/Properties";
import { withRouter } from "react-router-dom";
import httpService from "../../../../../../apis/httpService";
import run from "../../../../../../images/Run.png";
const API_URL = httpService.API_URL();

const styles = (theme) => ({
  card: {
    maxWidth: "850px",
    width: "100%",
    margin: "auto",
    marginBottom: "10px",
  },
  media: {
    width: "60%",
    maxWidth: "60%",
    minWidth: "100%",
    margin: "auto",
    objectFit: "content",
    borderRadius: "8px",
    boxShadow: "inset 0px 0px 19px 0px rgba(0,0,0,0.75)",
  },
  cardmedia: {
    margin: "0 auto",
    display: "flex",
    position: "relative",
    justifyContent: "center",
  },
  commonHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  left: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    // flexDirection:'column'
    //justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      //  justifyContent: "center",
      flexWrap: "nowrap",
    },
  },
  right: {
    // flex: "0.4",
    display: "flex",
    // justifyContent: "space-around",
    alignItems: "center",
  },
  timeLine: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
  },
  iconOpt: {
    color: "#6b46c1",
    marginLeft: "10px",
    cursor: "pointer",
    transition: "transform .2s",
    "&:hover": {
      transform: "scale(1.5)",
    },
  },
  dateStyling: {
    marginLeft: "5%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "9%",
    },
  },
  headline: {
    fontSize: "25px",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "left",

    color: "#780000",
  },
});
class EventCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventDate: "",
    };
  }

  getHashTags = (data) => {
    let run = "";
    let cycle = "";
    data.eventRunCategories.map((tag) => {
      if (tag.activityType.type === "RUN") {
        run = tag.activityType.type;
      }

      if (tag.activityType.type === "CYCLE") {
        cycle = tag.activityType.type;
      }
    });
    return (
      <>
        {" "}
        {run && (
          <span>
            <b
              style={{ marginLeft: "10px", color: "#718096", fontSize: "15px" }}
            >
              #{run.toLocaleLowerCase()}
            </b>
          </span>
        )}
        {cycle && (
          <span>
            <b
              style={{ marginLeft: "10px", color: "#718096", fontSize: "15px" }}
            >
              #{cycle.toLocaleLowerCase()}
            </b>
          </span>
        )}
      </>
    );
  };

 
  

  render() {
    const { classes, data, handleBannerLoaded } = this.props;
  
    return (
      <>
        <Card className={classes.card}>
          {data?.id === 538 && (
            <Typography className={classes.headline}>
              {"Thank you very much"}
            </Typography>
          )}

          <CardMedia className={classes.cardmedia}>
            <img
              onLoad={() => {
                handleBannerLoaded();
              }}
              id="banner"
              className={classes.media}
              src={data?.image?.url ? `${API_URL}${data?.image?.url}` : run}
              alt={data?.image?.fileName}
            />
          </CardMedia>

          <CardContent>
            <div className={classes.commonHeading}>
              <div className={classes.left}>
                {data && (
                  <Typography variant="body2" gutterBottom>
                    <span
                      style={{
                        color: "#2883B5",
                        fontSize: "23px",
                        fontWeight: "bold",
                      }}
                    >
                      {data.name}
                    </span>
                  </Typography>
                )}
              </div>
            </div>
            <span>
              {data?.eventHashTags && (
                <span>
                  <b
                    style={{
                      marginLeft: "10px",
                      color: "#718096",
                      fontSize: "15px",
                    }}
                  >
                    {data?.eventHashTags}
                  </b>
                </span>
              )}
            </span>
            {!data?.isOpenSubscriptionProgram && data?.id !== 538 && (
              <div className={classes.timeLine}>
                <div className={classes.left}>
                  {data?.eventLocalStartDate &&
                    data?.eventLocalEndDate &&
                    (data?.eventLocalStartDate === data?.eventLocalEndDate ? (
                      <span>
                        <EventIcon style={{ color: "rgb(40, 131, 181)" }} />
                        {ApplicationUtil.changeDateFormat(
                          data.eventLocalStartDate
                        )}
                      </span>
                    ) : (
                      <>
                        <span style={{ display: "flex", alignItems: "center" }}>
                          {/* <b>{Properties.Label_From}</b> */}
                          <EventIcon style={{ color: "rgb(40, 131, 181)" }} />
                          <b>&nbsp;{"Starting from:"}</b>
                          <span style={{ marginLeft: "5px" }}>
                            {ApplicationUtil.changeDateFormat(
                              data.eventLocalStartDate
                            )}
                          </span>
                        </span>
                        <span style={{}} className={classes.dateStyling}>
                          <b>{"Ends on:"}</b>
                          <span style={{ marginLeft: "5px" }}>
                            {ApplicationUtil.changeDateFormat(
                              data.eventLocalEndDate
                            )}
                          </span>
                        </span>{" "}
                      </>
                    ))}
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      </>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(EventCard);
