import React, { Component } from "react";
import {
  Paper,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Switch,
} from "@material-ui/core";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import AppToolBar from "../../../../../common/AppToolBar";
import Header from "../../../../../common/Header";
import Properties from "../../../../../../properties/Properties";
import RelayTable from "./RelayTable";
import {
  getAllSlotData,
  getMapData,
} from "../../../../../../apis/eventService";
import RelayMap from "./RelayMap";
import Countdown, { zeroPad } from "react-countdown";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";
import { withRouter } from "react-router-dom";
var moment = require("moment");

const styles = (theme) => ({
  smallText: {
    fontSize: "16px",
    fontWeight: 600,

    position: "absolute",
    // right: "10px",
    color: "black",

    // marginLeft: "8px",
  },

  counterBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  timerBlock: {
    display: "flex",
    alignItems: "center",
  },
  timerText: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    textTransform: "uppercase",
    position: "relative",
    "& .timeNumber": {
      padding: "12px",
      fontSize: "90px",
      fontWeight: 600,
      width: "110px",
      color: "#FF0000	",
    },
  },
  liveText: {
    fontSize: "50px",
    color: "black",
    fontWeight: 700,
    textTransform: "uppercase",
  },
  noMap:{
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: 600,
  }
});
class RelayLeaderboard extends Component {
 
  
  Completionist() {
    return <span>You are good to go!</span>;
  }
  renderLeaderboard() {
    
    const {data, classes} = this.props
    return (
      <div>
      
      {data ? 
      
      <RelayMap
        flightPlanCoordinates={data?.flightPlanCoordinates}
        center={data?.center}
        defaultCenter={data?.defaultCenter}
        features={data?.features}
        defaultZoom={10}
      /> 
      :
      <div className={classes.noMap}>
                                  {Properties.MapNotAvailable}
                                </div>
    }
         
        
      
      </div>
    );
  }
  renderer = ({ formatted: { hours, minutes, seconds }, completed }) => {
    const { classes, data } = this.props;

    if (completed) {
      return this.renderLeaderboard();
    } else {
      return (
        <div className={classes.counterBlock}>
          <div className={classes.timerBlock}>
            <div className={classes.timerText}>
              <span className={classes.smallText}>Hours</span>
              <span className="timeNumber">
                {zeroPad(hours, 2)}
                <span>:</span>
              </span>
            </div>

            <div className={classes.timerText}>
              <span className={classes.smallText}>Minutes</span>
              <span className="timeNumber">
                {zeroPad(minutes, 2)}
                <span>:</span>
              </span>
            </div>

            <div className={classes.timerText}>
              <span className={classes.smallText}>Seconds</span>
              <span className="timeNumber">{zeroPad(seconds, 2)}</span>
            </div>
          </div>
          <div className={classes.liveText}>
            <span>to go live</span>
          </div>
        </div>
      );
    }
  };
  render() {
    const {
      data
    } = this.props
    return (
      <div>
        {data?.timeRemained > 0 ? (
          <Countdown
            date={Date.now() + data?.timeRemained}
            daysInHours={true}
            renderer={this.renderer}
          />
        ) : (
          this.renderLeaderboard()
        )}
      </div>
    );
  }
}
export default compose(withStyles(styles, { withTheme: true }),withRouter)(
  RelayLeaderboard
);
