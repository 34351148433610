import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { FaCircleCheck } from "react-icons/fa6";
import {
  IoArrowBackCircleOutline,
  IoArrowForwardCircleOutline,
} from "react-icons/io5";

import { FaMinusCircle } from "react-icons/fa";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import moment from "moment";
import { IoMdCloseCircle } from "react-icons/io";
import ApplicationUtil from "../../../../utils/ApplicationUtil";

function NoSugarChallengeTable({
  classes,
  hideTitle = false,
  hideLegends = false,
  challenge,
  viewDate,
}) {
  const [responseData, setResponseData] = useState({});
  const [hideArrow, setHideArrow] = useState(true);

  const isProgramDashboard = window.location.href
    ?.toLowerCase()
    ?.includes("programdashboard");

  const legends = [
    <FaCircleCheck color="#43A047" size={20} />,
    "Positive Response",
    <IoMdCloseCircle color="#CC3333" size={25} />,
    "Negative Response",
    <FaMinusCircle color="#FC6856" size={20} />,
    "Challenge Not Taken",
  ];

  const prevClickHandler = () => {
    if (responseData.currentWeek > 0) {
      setResponseData((prev) => ({
        ...prev,
        currentWeek: responseData.currentWeek - 1,
      }));
    }
  };

  const nextClickHandler = () => {
    if (responseData.currentWeek + 1 < responseData.weekRange.length) {
      setResponseData((prev) => ({
        ...prev,
        currentWeek: responseData.currentWeek + 1,
      }));
    }
  };

  /**
   * Vishal Prod Defect_Id_114
   * date range - if Start and End date is same then shows one time
   * @param {*} startDate
   * @param {*} endDate
   * @returns Range
   */
  const getDateRange = (startDate, endDate) => {
    let range = "";
    if (
      startDate &&
      endDate &&
      ApplicationUtil.formattedDate(startDate) ===
        ApplicationUtil.formattedDate(endDate)
    ) {
      range = `${ApplicationUtil.formattedDate(startDate)}`;
    } else {
      range = `${ApplicationUtil.formattedDate(
        startDate
      )}-${ApplicationUtil.formattedDate(endDate)}`;
    }
    return range;
  };

  const prepareDataForGraph = () => {
    if (challenge?.questionnaires) {
      // Sort questionnaires by viewDate
      const dateSorted = challenge.questionnaires.sort((a, b) => {
        const dateA = moment(
          a?.programChallengeQuestionDto?.viewDate,
          "DD-MM-YYYY HH:mm:ss"
        );
        const dateB = moment(
          b?.programChallengeQuestionDto?.viewDate,
          "DD-MM-YYYY HH:mm:ss"
        );
        return dateA - dateB;
      });

      // Divide sorted questionnaires into weekly chunks
      const weekWise = Array.from(
        { length: Math.ceil(dateSorted.length / 7) },
        (_, i) => dateSorted.slice(i * 7, i * 7 + 7)
      );

      const previousDay = moment().subtract(1, "day").format("DD-MM-YYYY");
      const graphValues = {
        weekRange: [],
        currentWeek: 0,
      };
      // Process each week chunk
      weekWise.forEach((week, i) => {
        // Determine week data considering conditions

        //---------------------------------------------------------
        // logic to show the 'number of days record' in a row  --------------------------------------------
        const weekData = week;
        //commented below logic as it is not compatible to show more than 9 days records in a row for mobile view
        // week.length > 3
        //   ? week
        //   : weekWise[0]?.length >= 7
        //   ? weekWise[i - 1]?.concat(week) || []
        //   : week;
        //---------------------------------------------------------

        // Get the start and end dates of the week
        const startDate = weekData?.[0]?.programChallengeQuestionDto?.viewDate;
        const endDate =
          weekData?.[weekData.length - 1]?.programChallengeQuestionDto
            ?.viewDate;

        if (weekData !== week) {
          // Update previous week's date range if weekData differs from the current week
          graphValues.weekRange[graphValues.weekRange.length - 1] =
            getDateRange(startDate, endDate);
          graphValues[`week${i}`] = weekData;
        } else {
          // Push new date range and week data to graphValues
          graphValues.weekRange.push(getDateRange(startDate, endDate));
          graphValues[`week${i + 1}`] = weekData;
        }
      });

      // Function to determine the current week based on a given date
      const determineCurrentWeek = (date, format) => {
        graphValues.weekRange.forEach((item, weekRangeIndex) => {
          const dateMoment = moment(date, format);
          const [start, end] = item?.split("-");
          const startMoment = moment(start, "Do MMM").year(dateMoment.year());
          const endMoment = moment(end, "Do MMM").year(dateMoment.year());

          if (dateMoment.isBetween(startMoment, endMoment, undefined, "[]")) {
            graphValues.currentWeek = weekRangeIndex;
          }
        });
      };

      // Determine the current week based on viewDate or previous day
      if (viewDate) {
        determineCurrentWeek(viewDate, "YYYY-MM-DD");
      } else {
        determineCurrentWeek(previousDay, "DD-MM-YYYY");
      }

      // Set the response data with graph values
      setResponseData(graphValues);

      // Hide the arrow if there is only one week range
      if (graphValues?.weekRange.length === 1) {
        setHideArrow(false);
      }
    }
  };

  useEffect(() => {
    prepareDataForGraph();
  }, [challenge]);

  return (
    <Box className={classes.graph_container}>
      {!hideTitle && (
        <h2 className={classes.chartTitle}>
          {isProgramDashboard
            ? challenge?.programChallengeType === "HYDRATION"
              ? "Hydration Journey"
              : challenge?.programChallengeType === "ZERO_SUGAR"
              ? "Zero Sugar Journey"
              : challenge?.name
            : challenge?.name}
        </h2>
      )}

      <Box className={classes.subtitle}>
        {/* <Typography className={classes.subTitle}>
          {getChallengeDuration()}
        </Typography> */}
        <Typography className={classes.rangeTitle}>
          {responseData?.weekRange?.[responseData?.currentWeek || 0]}
        </Typography>
      </Box>

      <Box sx={{ width: { lg: "90%", xs: "100%" }, margin: { lg: "auto" } }}>
        <div className={classes.mobile_arrow_container}>
          {hideArrow && (
            <div className="mob_arrow prev">
              <Tooltip title="Previous Week">
                <IconButton
                  style={{ paddingTop: "0" }}
                  onClick={() => {
                    prevClickHandler();
                  }}
                >
                  <IoArrowBackCircleOutline
                    className={
                      responseData?.currentWeek === 0 && classes.disabled
                    }
                  />
                </IconButton>
              </Tooltip>
            </div>
          )}
          {hideArrow && (
            <div className="mob_arrow next">
              <Tooltip title="Next Week">
                <IconButton
                  style={{ paddingTop: "0" }}
                  onClick={() => {
                    nextClickHandler();
                  }}
                >
                  <IoArrowForwardCircleOutline
                    className={
                      responseData?.currentWeek + 1 ===
                        responseData.weekRange?.length && classes.disabled
                    }
                  />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
        <div style={{ position: "relative" }}>
          <div className={classes.chartContainer}>
            {hideArrow && (
              <div className="arrow prev">
                <Tooltip title="Previous Week">
                  <IconButton
                    onClick={() => {
                      prevClickHandler();
                    }}
                    className={
                      responseData?.currentWeek === 0 && classes.disabled
                    }
                  >
                    <IoArrowBackCircleOutline />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <Table
              classes={classes}
              data2={responseData}
              currentWeek={responseData?.currentWeek}
              startDate={moment(
                challenge?.challengeStartDate,
                "DD-MM-YYYY HH:mm:ss"
              )
                .startOf("day")
                .format("YYYY-MM-DD")}
            />
            {hideArrow && (
              <div className="arrow next">
                <Tooltip title="Next Week">
                  <IconButton
                    onClick={() => {
                      nextClickHandler();
                    }}
                  >
                    <IoArrowForwardCircleOutline
                      className={
                        responseData?.currentWeek + 1 ===
                          responseData.weekRange?.length && classes.disabled
                      }
                    />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>

          {!hideLegends && (
            <>
              <br />
              <Table
                classes={classes}
                data={legends}
                style={{ display: "flex", justifyContent: "end" }}
              />
            </>
          )}
        </div>
      </Box>
    </Box>
  );
}

const styles = (theme) => ({
  checkIcon: {
    fontSize: 40,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  closeIcon: {
    fontSize: 45,
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
  },
  subTitle: {
    marginTop: "0px",
    fontWeight: "600",
    fontSize: "18px",
    marginLeft: "10px",
    marginBottom: "10px",
    color: "dimgray",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      marginLeft: "0px",
    },
  },
  rangeTitle: {
    marginTop: "5px",
    fontWeight: "600",
    fontSize: "20px",
    marginLeft: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginLeft: "0px",
    },
  },
  graph_container: {
    width: "100%",
  },
  subtitle: {
    display: "flex",
    justifyContent: "end",
  },
  graphWrapper: {
    // width: "100% ",
  },
  headerContainer: {
    paddingBottom: 15,
  },
  title: {
    color: "#202260",
    fontWeight: "bold",
    fontFamily: "karla, sans serif",
    fontSize: "35px",
    letterSpacing: "-1px",
  },
  desc: {
    color: "black",
    margin: "0",
    fontSize: "26px",
    fontWeight: "bolder",
  },
  selectContainer: {
    padding: "10px",
    marginBottom: "5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
  },
  table: {
    textAlign: "center",
    borderCollapse: "collapse" /* Ensures borders between cells */,
    // width: "100%" /* Adjust width as needed */,
  },
  td: {
    fontWeight: "500",

    border: "1px solid #ddd" /* Light gray border color */,
    padding: "5px" /* Adjust padding as needed */,
    [theme.breakpoints.down("sm")]: {
      fontSize: "small",
    },
  },
  chartTitle: {
    letterSpacing: "-1px",
    marginBottom: 0,
    color: "#202260",
    fontWeight: "bold",
    fontSize: "35px",
    marginLeft: "10px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      marginLeft: "0px",
    },
  },
  chartContainer: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    "& .arrow": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
  mobile_arrow_container: {
    display: "none",
    alignItems: "center",
    justifyContent: "end",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  disabled: {
    color: "#8080807a",
    cursor: "not-allowed",
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(NoSugarChallengeTable);

const Table = ({ currentWeek, startDate, data2, style = {}, classes }) => {
  /**
   * _getToolTipMsg - Based on the challenge type and is challege performed or not
   * get the tooltip msg
   * @param {*} data
   * @returns
   */
  const _getToolTipMsg = (data) => {
    if (data?.isChallengePerformed) {
      if (data?.programChallengeType === "HYDRATION") {
        return (
          <>
            {data?.response ? (
              <>
                <Typography variant="caption" style={{ fontSize: "12px" }}>
                  Submitted Response:-
                </Typography>
                <br></br>
                <Typography
                  variant="caption"
                  style={{ fontSize: "12px" }}
                >{`Quantity Submitted-${
                  data?.response
                }, Status-${ApplicationUtil.capitalize(
                  data?.hydrationStatus
                )}`}</Typography>
              </>
            ) : (
              <Typography variant="caption" style={{ fontSize: "12px" }}>
                Response Not Found
              </Typography>
            )}
          </>
        );
      } else if (data?.programChallengeType === "ZERO_SUGAR") {
        return (
          <>
            {data?.childQuestionnaireDto?.response ? (
              <>
                <Typography variant="caption" style={{ fontSize: "12px" }}>
                  Submitted Response :-
                </Typography>
                <br></br>
                <Typography variant="caption" style={{ fontSize: "12px" }}>
                  {data?.childQuestionnaireDto?.response
                    ?.replace("Any other;", "")
                    ?.replace("$$", "-")}
                </Typography>
              </>
            ) : (
              <Typography variant="caption" style={{ fontSize: "12px" }}>
                Not responded to second question
              </Typography>
            )}
          </>
        );
      }
      return null;
    }
    return (
      <Typography variant="caption" style={{ fontSize: "12px" }}>
        Response Not Submitted
      </Typography>
    );
  };

  /**
   * _getColorCode - Get icon color based on response type like-
   * 1. Challenge not performed / not responded = #a9a9a9 (Dark grey)
   * 2.Positive response = "#43A047 (Green)
   * 3.Negative response = #CC3333 (Red)
   * @param {*} data
   * @returns
   */
  const _getColorCode = (data) => {
    if (data?.isChallengePerformed) {
      return data?.responseStatus === "Positive" ? "#43A047" : "#CC3333";
    }
    return "#a9a9a9";
  };
  return (
    <table className={classes.table}>
      <tbody>
        <tr>
          {data2?.[`week${currentWeek + 1}`]?.map((column) => {
            const msg = _getToolTipMsg(column);
            const colorCode = _getColorCode(column);
            return (
              <td
                className={classes.td}
                style={{
                  width:
                    data2?.[`week${currentWeek + 1}`]?.length < 2
                      ? "200px"
                      : "300px",
                }}
                key={column}
              >
                {column?.isChallengePerformed &&
                column?.responseStatus === "Positive" ? (
                  <>
                    {msg ? (
                      <Tooltip arrow enterTouchDelay={0} title={msg}>
                        <span>
                          <FaCircleCheck
                            color={colorCode}
                            className={classes.checkIcon}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <FaCircleCheck
                        color={colorCode}
                        className={classes.checkIcon}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {msg ? (
                      <Tooltip arrow enterTouchDelay={0} title={msg}>
                        <span>
                          <IoMdCloseCircle
                            color={colorCode}
                            className={classes.closeIcon}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <IoMdCloseCircle
                        color={colorCode}
                        className={classes.closeIcon}
                      />
                    )}
                  </>
                )}
              </td>
            );
          })}
        </tr>
        {data2 && (
          <tr>
            {data2?.[`week${currentWeek + 1}`]?.map((cell, cellIndex) => {
              let date = moment(
                cell?.programChallengeQuestionDto?.viewDate,
                "DD-MM-YYYY HH:mm:ss"
              );
              return (
                <td
                  className={classes.td}
                  style={{
                    width:
                      data2?.[`week${currentWeek + 1}`]?.length < 2
                        ? "200px"
                        : "300px",
                    whiteSpace: "nowrap",
                  }}
                  key={cellIndex}
                >
                  Day {date.diff(startDate, "days") + 1}
                </td>
              );
            })}
          </tr>
        )}
      </tbody>
    </table>
  );
};
