import React from "react";
import { withStyles } from "@material-ui/core/styles";
// import Input from "@material-ui/core/Input";
// import InputLabel from "@material-ui/core/InputLabel";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import FormControl from "@material-ui/core/FormControl";
// import TextField from "@material-ui/core/TextField";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
// import IconButton from "@material-ui/core/IconButton";
import { OutlinedInput } from "@material-ui/core";

const styles = (theme) => ({
  // cssLabel: {
  //   color: "#fff",
  //   "&$cssFocused": {
  //     color: "#fff"
  //   },
  //   fontSize: "18px"
  // },
  // cssFocused: {},
  // cssUnderline: {
  //   "&:before": {
  //     borderBottomColor: "#fff"
  //   },
  //   "&:hover:not($disabled):not($error):not($focused):before": {
  //     borderBottom: "1px solid #fff"
  //   },
  //   "&:after": {
  //     borderBottomColor: " transparent"
  //   }
  // },
  // disabled: {},
  // error: {},
  // focused: {},
  // multilineColor: {
  //   color: "#fff",
  //   height: "inherit"
  // }
  root: {
    //fontSize: "14px",
    "& $notchedOutline": {
      borderWidth: 0,
    },
    "&:hover $notchedOutline": {
      borderWidth: 0,
    },
    "&$focused $notchedOutline": {
      borderWidth: 0,
    },
  },
  input: {
    "&[type=number]": {
      "-moz-appearance": "textfield",
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  focused: {},
  notchedOutline: {},
});

function customTextField(props) {
  const {
    className,
    classes,
    value,
    handleChange,
    handleWheel,
    handleMouseOut,
    error,
    keyToSet,
    style,
    type,
    placeholder,
    defaultValue,
    readOnly,
    name,
  } = props;
  return (
    <OutlinedInput
      className={className ? className : null}
      style={style}
      classes={classes}
      error={error}
      value={value}
      type={type}
      disabled={readOnly}
      defaultValue={defaultValue}
      placeholder={placeholder}
      name={name}
      onChange={handleChange(keyToSet)}
      // onBlur={handleMouseOut ? handleMouseOut(keyToSet) : null}
      onWheel={(e) => console.log(e)}
      onWheel={handleWheel}
      id="outlined-adornment-weight"
      aria-describedby="outlined-weight-helper-text"
      // onInput={(e) => {
        // e.target.value =   

          //  isNumber
          //   ? allowDecimal
          //     ? e.target.value
          //     : Math.max(0, parseInt(e.target.value)).toString().slice(0, 20)
          //   :
          // e.target.value;
      // }}
      // InputProps={
      //   {
      //     classes: { input: classes.input },
          
      //   }
      // }
      labelWidth={0}
    />
  );
}

const CustomTextField = withStyles(styles)(customTextField);

export { CustomTextField };
