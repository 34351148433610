import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { getDecodedUrl } from "../../apis/eventService";
import ApplicationUtil from "../../utils/ApplicationUtil";
import Header from "../common/Header";
import AppToolBar from "../common/AppToolBar";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  textContainer: {
    marginTop: "68px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "& p": {
      fontSize: "40px",
    },
  },
});

class NotFound extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notFound: false,
    };
  }
  componentDidMount() {
    let data = ApplicationUtil.getRunnerDetails();
    let token;
    if (data?.token) {
      token = data.token;
      // this.setState({ token: token });
    }
    let encodedUrl = window.location.pathname;
    // if(encodedUrl.includes("/#/")){
    // encodedUrl = encodedUrl.split("/#/")[1].replace("/", "");
    encodedUrl = encodedUrl.replace("/", "")
    // }else{
      // encodedUrl = encodedUrl.split("/")[3].replace("/", "");
    // }
    getDecodedUrl(encodedUrl)
      .then((res) => {
        let newUrl = ApplicationUtil.getRedirectedRoute(res.data.fullUrl);

        if (newUrl.includes("distKey") || token) {
          ApplicationUtil.go(`${newUrl}`);
        } else {
          ApplicationUtil.routetoLoginWithUrl(`${encodedUrl}`);
        }
      })
      .catch((e) => {
        if (e?.response?.data) {
          this.setState({ notFound: true });
        }
      });
  }
  render() {
    const { classes } = this.props;
    const { notFound } = this.state;
    return (
      <div>
        {notFound && (
          <>
            <AppToolBar
              backNavigate={() => this.props.history.goBack()}
              // showBack={true}
            >
              <Header {...this.props} label={"Not Found"} />
              <div style={{ width: 35 }} />
            </AppToolBar>
            <div className={classes.textContainer}>
              <p> #404 Not Found </p>
            </div>
          </>
        )}
      </div>
    );
  }
}
export default compose(withStyles(styles, { withTheme: true }),withRouter)(NotFound);
