import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { store } from "./store";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    dList: [], ///drawer's eventlist
    userUrl: {},
    userPreferences: {
      drawerSidebar: "",
      eventTabID: 0,
    },
    userSelectedEvent: {},
    userSelectedProgram: {},
    userSelectedChallenge: {},
    queNotAttempted: {},
    selectedQueBlock: {},
    currentRunningProgramChallenge: {},
    resultsSearchValue: "",
  },
  reducers: {
    updateUserDetails: (state, action) => {
      state.user = action.payload;
    },
    updateResultsSearchValue: (state, action) => {
      state.resultsSearchValue = action.payload;
    },
    removeUserDetails: (state) => {
      state.user = null;
      state.dList = null;
      state.userUrl = null;
      state.userSelectedEvent = null;
      state.queNotAttempted = null;
      state.selectedQueBlock = null;
      state.currentRunningProgramChallenge = null;
    },
    updateDrawersEventList: (state, action) => {
      state.dList = action.payload;
    },
    updateUserPreferences: (state, action) => {
      state.userPreferences = { ...state.userPreferences, ...action.payload };
    },
    updateUserSelectedEvent: (state, action) => {
      state.userSelectedEvent = action.payload;
    },
    updateUserSelectedProgram: (state, action) => {
      state.userSelectedProgram = action.payload;
    },
    removeUserSelectedEvent: (state, action) => {
      state.userSelectedEvent = null;
    },
    removeUserSelectedProgram: (state, action) => {
      state.userSelectedProgram = null;
    },
    updateUserSelectedChallenge: (state, action) => {
      state.userSelectedChallenge = action.payload;
    },
    updateCurrentRunningProgramChallenge: (state, action) => {
      state.currentRunningProgramChallenge = action.payload;
    },
    updateSelectedQueBlock: (state, action) => {
      state.selectedQueBlock = action.payload;
    },
    updateUserLink: (state, action) => {
      state.userUrl = action.payload;
    },
    updateQueNotAttempted: (state, action) => {
      state.queNotAttempted = action.payload;
    },
    incrementByAmount: (state, action) => {
      state.count += action.payload;
    },
  },
});

export const {
  updateUserDetails,
  removeUserDetails,
  updateDrawersEventList,
  updateUserPreferences,
  updateUserSelectedEvent,
  updateResultsSearchValue,
  updateUserSelectedChallenge,
  updateSelectedQueBlock,
  updateQueNotAttempted,
  updateUserLink,
  updateCurrentRunningProgramChallenge,
  removeUserSelectedEvent,
  updateUserSelectedProgram,
  removeUserSelectedProgram,
} = userSlice.actions;

export default userSlice.reducer;
