import http from "./httpService";
import { loading } from "../components/common/Spinner";
import Snackbar from "../components/common/Snackbar";
import Properties from "../properties/Properties";

const API_URL_FILE = http.API_URL();

export function forgotPassword(params) {
  //const details={"email":p};

  let url = API_URL_FILE + "api/v1/account/forgotPassword";
  return new Promise(function (resolve, reject) {
    http
      .post(url, params)
      .then((res) => {
        if (res.status === 200) {
          //console.log("Reset Link sent");
          //console.log(res);

          if (res.data.success.code == 404) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Label_AccountNotExists,
            });
          } else {
            resolve(res);
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Email has been sent to reset password",
            });
          }
        } else {
          //console.log("went wrong");
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        if (error !== "") {
          if (error.response.status == 500) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Label_AccountNotActivated,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}

export function resetPassword(params) {
  //const details={"email":p};
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };
  let url = API_URL_FILE + "api/v1/account/resetPassword";

  return new Promise(function (resolve, reject) {
    http
      .post(url, params, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          if (res.data.success.code === "200") {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data.success.verbose,
            });
          } else if (res.data.success.code === "401") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Invalid_password_reset,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error.response.status == 500) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Error_password_reset,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}

//export function resetPassword(p)
