import React, { Component } from "react";
import AppToolBar from "../../common/AppToolBar";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Divider, IconButton, Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import logo from "../login/ndLogo.png";
import { PropTypes } from "prop-types";
import MultiSelect from "../../common/MultiSelect";
import { CustomTextField } from "../../common/CustomTextField";
import SubmitCancelBtn from "../../common/SubmitCancelBtn";
import MenuIcon from "@material-ui/icons/Menu";
import { withRouter } from "react-router-dom";
import ApplicationUtil from "../../../utils/ApplicationUtil";
import { updateActivity } from "../../../apis/eventService";
import ImageDialogBox from "../../common/ImageDialogBox";
import Snackbar from "../../common/Snackbar";
import Properties from "../../../properties/Properties";
import SupportUtil from "./SupportUtil";
import { downloadImage } from "./../../../apis/eventService";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { IconCalendarBlue } from "../../common/SvgIcon";
import { format } from "date-fns";

import ImageSlider from "../../common/ImageSlider";
import DialogBox from "../../common/DialogBox";

const styles = (theme) => ({
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },
  boxPaddingCss: {
    paddingRight: "0px !important",
  },
});
class SupportFormContainer extends Component {
  state = {
    userName: localStorage.getItem("supportUserName"),
    distanceError: false,
    startTimeError: false,
    completionTimeError: false,
    eventName: localStorage.getItem("supportEventName"),
    distance: "",
    activityUrl: "",
    startTime: "",
    completionTime: "",
    averagePace: "",
    averageSpeed: "",
    averageHR: "",
    startLocation: "",
    elevationGain: "",
    loss: "",
    runnerName: localStorage.getItem("runnerName"),
    mannualOptionList: [
      { label: "Yes", value: "YES" },
      { label: "No", value: "No" },
    ],
    mannualOption: { label: "Yes", value: "YES" },
    mannualOptionError: false,
    activityTypeError: false,
    activityType: { label: "Run", value: "Run" }, //localStorage.getItem("activityType"),
    activityTypeList: [
      { label: "Run", value: "Run" },
      { label: "Cycle", value: "Cycle" },
    ],
    data: {},
    imageUrl: "",
    manual: {},
    date: null,
    dateError: false,
    type: null,
    eventType: localStorage.getItem("supportEventType"),
    selectedIndex: -1,
    activityList: [
      {
        id: 185,
        name: "Lunch Run",
        distance: 1009.7,
        elapsedTime: 207,
        completionTime: "00:03:27",
        startDateLocal: "15-08-2020 12:12:16",
        endDateLocal: "15-08-2020 12:15:43",
        averagePace: "03:25",
        suggestedPace: "5:20",
      },
      {
        id: 185,
        name: "Lunch Run",
        distance: 1009.7,
        elapsedTime: 207,
        completionTime: "00:03:27",
        startDateLocal: "15-08-2020 12:12:16",
        endDateLocal: "15-08-2020 12:15:43",
        averagePace: "03:25",
        suggestedPace: "5:20",
      },
      ,
      {
        id: 185,
        name: "Lunch Run",
        distance: 1009.7,
        elapsedTime: 207,
        completionTime: "00:03:27",
        startDateLocal: "15-08-2020 12:12:16",
        endDateLocal: "15-08-2020 12:15:43",
        averagePace: "03:25",
        suggestedPace: "5:20",
      },
      ,
      {
        id: 185,
        name: "Lunch Run",
        distance: 1009.7,
        elapsedTime: 207,
        completionTime: "00:03:27",
        startDateLocal: "15-08-2020 12:12:16",
        endDateLocal: "15-08-2020 12:15:43",
        averagePace: "03:25",
        suggestedPace: "5:20",
      },
      ,
      {
        id: 185,
        name: "Lunch Run",
        distance: 1009.7,
        elapsedTime: 207,
        completionTime: "00:03:27",
        startDateLocal: "15-08-2020 12:12:16",
        endDateLocal: "15-08-2020 12:15:43",
        averagePace: "03:25",
        suggestedPace: "5:20",
      },
    ],
  };

  goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  componentDidMount() {
    document.title = Properties.Page_Title_Event_Support_Form;
    if (!ApplicationUtil.isuserLoggedIn("support")) {
      this.props.history.push("/support");
    } else {
      let row = SupportUtil.getUserRow();

      this.setState({
        data: row,
        type: localStorage.getItem("type"),
        // activityList: localStorage.getItem("activityList"),
        activityType:
          localStorage.getItem("activityTypeId") == 1
            ? { label: "Run", value: "Run" }
            : {
                label: "Cycle",
                value: "Cycle",
              },
        activityUrl: row.activityUrl ? row.activityUrl : "",
        distance: row.distance ? row.distance : "",
        completionTime: row.completionTime ? row.completionTime : "",
        //activityList: row.activityList,
      });
    }
  }

  setIndex = (index) => {
    if (index == this.state.selectedIndex) {
      this.setState({ selectedIndex: -1 });
    } else {
      this.setState({ selectedIndex: index });
    }
  };

  validateFields = () => {
    const {
      distance,
      startTime,
      completionTime,
      startLocation,
      activityType,
      date,
      distanceError,
      completionTimeError,
      startTimeError,
      dateError,
    } = this.state;
    if (distance == "" || distance == undefined || distanceError == true) {
      this.setState({ distanceError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please Enter distance",
      });
      this.goToTop();
    } else if (
      completionTime == "" ||
      completionTime == undefined ||
      completionTimeError == true
    ) {
      this.setState({ completionTimeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please Enter completion time",
      });
      this.goToTop();
    } else if (date == "" || date == undefined || dateError == true) {
      this.setState({ dateError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please Enter start date",
      });
      this.goToTop();
    } else if (
      startTime == "" ||
      startTime == undefined ||
      startTimeError == true
    ) {
      this.setState({ startTimeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please Enter start date",
      });
      this.goToTop();
    } else {
      this.saveFields();
    }
  };

  saveFields = () => {
    let exactDate = format(new Date(this.state.date), "dd-MM-yyyy");
    const {
      distance,
      startTime,
      completionTime,
      startLocation,
      activityType,
      elevationGain,
      type,
      loss,
      averagePace,
      averageSpeed,
      averageHR,
      mannualOption,
      manual,
      activityUrl,
    } = this.state;
    let d = exactDate + " " + startTime;

    let requestParams = {
      id: this.state.data.id,
      distance: distance,
      activityUrl: activityUrl,
      completionTime: completionTime,
      startDate: d,

      manual: this.state.data.selectedMannual
        ? this.state.data.selectedMannual.value == "No"
          ? false
          : true
        : this.state.data.manual
        ? true
        : false,
      averageHeartRate: averageHR,

      elevationGain: elevationGain,
      type: activityType.value,
      elevationLoss: loss,

      // averageHR: averageHR,
    };
    if (type == "RUN")
      requestParams = { ...requestParams, averagePace: averagePace };
    else
      requestParams = {
        ...requestParams,
        averageSpeed: parseFloat(averageSpeed),
      };
    requestParams.approveRequest = true;

    updateActivity(requestParams, this.state.data.id).then((res) => {
      let panelUrl = ApplicationUtil.getMatchUrl();
      ApplicationUtil.go(panelUrl + "/list");
    });
  };

  handleMannualOptionChange = (row, selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        mannualOption: selectedValues,
        mannualOptionError: false,
      });
    } else {
      this.setState({
        mannualOption: selectedValues,
        mannualOptionError: true,
      });
    }
    row.selectedMannual = selectedValues;
  };

  viewImage = (id) => {
    let eventId = localStorage.getItem("supportEventId");
    downloadImage(id, eventId).then((res) => {
      this.setState({
        open: true,
        screenshots: res,
      });
    });
  };

  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  getDialogComponent = () => {
    return (
      <div>
        <ImageSlider files={this.state.screenshots} />
      </div>
    );
  };

  closeWindow = () => {
    this.setState({ open: false });
  };
  render() {
    const { classes } = this.props;
    const {
      distanceError,
      startTimeError,
      completionTimeError,
      averagePaceError,
      averageSpeedError,
      averageHRError,
      startLocationError,
      elevationGainError,
      elevationGain,
      lossError,
      mannualOption,
      mannualOptionError,
      activityType,
      activityTypeError,
      startTime,
      eventName,
      runnerName,
      date,
      dateError,
    } = this.state;

    return (
      <React.Fragment>
        <div style={{ width: "100%", backgroundColor: "#2C78FF" }}>
          <Box display="flex" p={1} bgcolor="#2C78FF">
            <Box p={1} flexGrow={1}>
              <div style={{ width: "auto" }}>
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    height: "50px",
                    width: "50px",
                  }}
                />
              </div>
            </Box>

            <Box
              p={2}
              display="flex"
              justifyContent="flex-end"
              classes={{
                root: classes.boxPaddingCss,
              }}
            >
              <IconButton>
                <span
                  onClick={(e) => {
                    localStorage.clear();
                    ApplicationUtil.go("/support");
                  }}
                  style={{
                    color: "#FFFFFF",
                    fontSize: "16px",
                    fontFamily: "Ubuntu",
                    cursor: "pointer",
                  }}
                >
                  {this.state.userName}
                </span>
              </IconButton>
            </Box>
            {/* <Box p={2} display="flex" justifyContent="flex-end">
              <IconButton>
                <span
                  style={{
                    fontSize: "12px",
                    color: "#2883B5"
                  }}
                >
                  <MenuIcon></MenuIcon>
                </span>
              </IconButton>
            </Box> */}
          </Box>
          <Divider style={{ height: "5px", backgroundColor: "#08456C" }} />
        </div>

        <div
          style={{
            height: "35px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "0 1%",
            }}
          >
            <span
              onClick={(e) => ApplicationUtil.goBack()}
              style={{ color: "blue", marginRight: "5px", cursor: "pointer" }}
            >
              {eventName + "  "}
            </span>
            {"   >>   "}
            <span style={{ marginLeft: "5px" }}>{"  " + runnerName}</span>
          </span>
        </div>

        <Grid
          style={{ width: "100%", height: "50px", margin: "0px" }}
          container
          spacing={1}
        >
          {/* name  */}
          <Grid item style={{ width: "100%", display: "flex" }}>
            {this.state.data.runner && (
              <Grid
                style={{
                  display: "grid",
                  marginLeft: "6.7%",
                  marginRight: "6.7%",
                }}
                item
                xs={6}
              >
                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#727272",
                      fontWeight: "500",
                    }}
                  >
                    {this.state.data.runner.name}
                  </span>
                </Typography>

                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#2883B5",
                      fontWeight: "500",
                    }}
                  >
                    {this.state.data.runner.email}
                  </span>
                </Typography>
              </Grid>
            )}

            <Grid
              style={{
                display: "grid",
                marginLeft: "6.7%",
                marginRight: "6.7%",
              }}
              item
              xs={6}
            >
              {this.state.data.eventRunCategory && (
                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#727272",
                      fontWeight: "500",
                    }}
                  >
                    {this.state.data.eventRunCategory.category}
                  </span>
                </Typography>
              )}

              {this.state.data.bibNumber && (
                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#2883B5",
                      fontWeight: "500",
                    }}
                  >
                    {this.state.data.bibNumber}
                  </span>
                </Typography>
              )}
            </Grid>
          </Grid>

          <div>
            <Grid item style={{ width: "100%", display: "flex" }}>
              <Grid
                style={{
                  display: "grid",
                  marginLeft: "6.7%",
                  marginRight: "6.7%",
                }}
                item
                xs={6}
              >
                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#727272",
                      fontWeight: "500",
                    }}
                  >
                    {Properties.ActivityUrl_label}
                  </span>
                </Typography>

                <CustomTextField
                  style={{
                    border: "1px solid #08456C",
                    height: "60%",
                    //   maxHeight: "30%",
                    //   minHeight: "30%",
                    width: "100%",
                  }}
                  type={"text"}
                  keyToSet={"activity url"}
                  value={this.state.activityUrl}
                  handleChange={(prop) => (event) => {
                    this.setState({
                      activityUrl: event.target.value,
                    });
                  }}
                />
                {/* 
              <Typography variant="body2" gutterBottom>
                {this.state.data.activityUrl ? (
                  <span
                    style={{
                      color: "#F79824",
                      fontWeight: "500",
                      cursor: "pointer"
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      window.open(this.state.data.activityUrl); //sample testing
                    }}
                  >
                    activity url sample
                  </span>
                ) : (
                  <span></span>
                )}
              </Typography> */}
              </Grid>

              <Grid
                style={{
                  display: "grid",
                  marginLeft: "6.7%",
                  marginRight: "6.7%",
                }}
                item
                xs={6}
              >
                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#727272",
                      fontWeight: "500",
                    }}
                  >
                    {Properties.Label_ActivityScreenshot}
                  </span>
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {this.state.data.isActivityScreenshotsAvailable ? (
                    <span
                      style={{
                        color: "#F79824",
                        fontWeight: "800",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.viewImage(this.state.data.runner.id);
                        // this.setState({ imageDialog: true });
                      }}
                    >
                      {Properties.Label_View}
                    </span>
                  ) : (
                    <span></span>
                  )}
                </Typography>
              </Grid>
            </Grid>

            {/* activity type   and distance */}
            <Grid item style={{ width: "100%", display: "flex" }}>
              <Grid
                style={{
                  display: "grid",
                  marginLeft: "6.7%",
                  marginRight: "6.7%",
                }}
                item
                xs={6}
              >
                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#727272",
                      fontWeight: "500",
                    }}
                  >
                    {Properties.Activity_type_label}
                  </span>
                </Typography>

                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#2883B5",
                      fontWeight: "500",
                    }}
                  >
                    <MultiSelect
                      value={activityType}
                      //error={activityTypeError}
                      options={this.state.activityTypeList}
                      //onChange={this.activityTypeChange}
                      isDisabled={true}
                    />
                  </span>
                </Typography>
              </Grid>

              {/* {this.state.data.distance && ( */}
              <Grid
                style={{
                  display: "grid",
                  marginLeft: "6.7%",
                  marginRight: "6.7%",
                }}
                item
                xs={6}
              >
                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#727272",
                      fontWeight: "500",
                    }}
                  >
                    {Properties.Distance_label}
                  </span>
                  <span style={{ color: "red" }}>*</span>
                </Typography>

                <CustomTextField
                  style={
                    distanceError
                      ? {
                          border: "1px solid red",
                          height: "60%",
                          //   maxHeight: "30%",
                          //   minHeight: "30%",
                          width: "100%",
                        }
                      : {
                          border: "1px solid #08456C",
                          height: "60%",
                          //   maxHeight: "30%",
                          //   minHeight: "30%",
                          width: "100%",
                        }
                  }
                  type={"number"}
                  //defaultValue={this.state.distance}
                  error={distanceError}
                  keyToSet={"distance"}
                  value={this.state.distance}
                  handleChange={(prop) => (event) => {
                    this.setState({
                      distance: event.target.value,
                      distanceError: false,
                    });
                  }}
                />
              </Grid>
              {/* )} */}
            </Grid>

            {/* start time completion time  */}
            <Grid item style={{ width: "100%", display: "flex" }}>
              <Grid
                style={{
                  display: "grid",
                  marginLeft: "6.7%",
                  marginRight: "6.7%",
                }}
                item
                xs={6}
              >
                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#727272",
                      fontWeight: "500",
                    }}
                  >
                    {Properties.StartTime_label}
                  </span>
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <Grid style={{ display: "flex" }}>
                  <Grid xs={5}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      disableFuture={true}
                      value={date}
                      error={dateError}
                      className={classes.fieldWidth}
                      InputProps={{
                        classes: {
                          root: dateError
                            ? classes.datePickerError
                            : classes.datePicker,
                        },
                      }}
                      keyboardIcon={
                        <IconCalendarBlue className={classes.calendarIconCss} />
                      }
                      onChange={(date) => {
                        if (date > new Date()) {
                          this.setState({ dateError: true });
                        } else
                          this.setState({
                            date: this.isValidDate(date) ? date : "",
                            dateError: this.isValidDate(date) ? false : true,
                          });
                      }}
                    />
                  </Grid>
                  <Grid xs={5} style={{ marginLeft: "10px" }}>
                    <CustomTextField
                      style={
                        startTimeError
                          ? {
                              border: "1px solid red",
                              height: "38px",
                              maxHeight: "38px",
                              minHeight: "38px",
                              width: "100%",
                            }
                          : {
                              border: "1px solid #08456C",
                              height: "38px",
                              maxHeight: "38px",
                              minHeight: "38px",
                              width: "100%",
                            }
                      }
                      type={"text"}
                      error={startTimeError}
                      keyToSet={"startTime"}
                      placeholder={"HH:MM:SS"}
                      handleChange={(prop) => (event) => {
                        this.setState({
                          startTime: event.target.value,
                          startTimeError: false,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                style={{
                  display: "grid",
                  marginLeft: "6.7%",
                  marginRight: "6.7%",
                }}
                item
                xs={6}
              >
                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#727272",
                      fontWeight: "500",
                    }}
                  >
                    {Properties.CompletionTime_label}
                  </span>
                  <span style={{ color: "red" }}>*</span>
                </Typography>

                <CustomTextField
                  style={
                    completionTimeError
                      ? {
                          border: "1px solid red",
                          height: "30px",
                          maxHeight: "30px",
                          minHeight: "30px",
                          width: "100%",
                        }
                      : {
                          border: "1px solid #08456C",
                          height: "30px",
                          maxHeight: "30px",
                          minHeight: "30px",
                          width: "100%",
                        }
                  }
                  type={"text"}
                  error={completionTimeError}
                  keyToSet={"completionTime"}
                  //defaultValue={this.state.completionTime}
                  placeholder={"HH:MM:SS"}
                  value={this.state.completionTime}
                  handleChange={(prop) => (event) => {
                    this.setState({
                      completionTime: event.target.value,
                      completionTimeError: false,
                    });
                  }}
                />
              </Grid>
            </Grid>

            {/* Avg pace */}
            <Grid item style={{ width: "100%", display: "flex" }}>
              <Grid
                style={{
                  display: "grid",
                  marginLeft: "6.7%",
                  marginRight: "6.7%",
                }}
                item
                xs={6}
              >
                <Typography variant="body2" gutterBottom>
                  {this.state.type == "RUN" ? (
                    <span
                      style={{
                        color: "#727272",
                        fontWeight: "500",
                      }}
                    >
                      {Properties.PaceAvg}
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "#727272",
                        fontWeight: "500",
                      }}
                    >
                      {Properties.SpeedAvg}
                    </span>
                  )}
                </Typography>
                {this.state.type == "RUN" ? (
                  <CustomTextField
                    style={
                      averagePaceError
                        ? {
                            border: "1px solid red",
                            height: "30px",
                            maxHeight: "30px",
                            minHeight: "30px",
                            width: "100%",
                          }
                        : {
                            border: "1px solid #08456C",
                            height: "30px",
                            maxHeight: "30px",
                            minHeight: "30px",
                            width: "100%",
                          }
                    }
                    type={"text"}
                    error={averagePaceError}
                    keyToSet={"averagePace"}
                    placeholder={"MM:SS"}
                    handleChange={(prop) => (event) => {
                      this.setState({
                        averagePace: event.target.value,
                        averagePaceError: false,
                      });
                    }}
                  />
                ) : (
                  <CustomTextField
                    style={
                      averageSpeedError
                        ? {
                            border: "1px solid red",
                            height: "30px",
                            maxHeight: "30px",
                            minHeight: "30px",
                            width: "100%",
                          }
                        : {
                            border: "1px solid #08456C",
                            height: "30px",
                            maxHeight: "30px",
                            minHeight: "30px",
                            width: "100%",
                          }
                    }
                    type={"text"}
                    error={averageSpeedError}
                    keyToSet={"averageSpeed"}
                    placeholder={""}
                    handleChange={(prop) => (event) => {
                      this.setState({
                        averageSpeed: event.target.value,
                        averageSpeedError: false,
                      });
                    }}
                  />
                )}
              </Grid>

              <Grid
                style={{
                  display: "grid",
                  marginLeft: "6.7%",
                  marginRight: "6.7%",
                }}
                item
                xs={6}
              >
                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#727272",
                      fontWeight: "500",
                    }}
                  >
                    {Properties.HRAvg}
                  </span>
                </Typography>

                <CustomTextField
                  style={
                    averageHRError
                      ? {
                          border: "1px solid red",
                          height: "30px",
                          maxHeight: "30px",
                          minHeight: "30px",
                          width: "100%",
                        }
                      : {
                          border: "1px solid #08456C",
                          height: "30px",
                          maxHeight: "30px",
                          minHeight: "30px",
                          width: "100%",
                        }
                  }
                  type={"text"}
                  error={averageHRError}
                  keyToSet={"averageHR"}
                  handleChange={(prop) => (event) => {
                    this.setState({
                      averageHR: event.target.value,
                      averageHRError: false,
                    });
                  }}
                />
              </Grid>
            </Grid>

            {/* start location */}
            <Grid item style={{ width: "100%", display: "flex" }}>
              <Grid
                style={{
                  display: "grid",
                  marginLeft: "6.7%",
                  marginRight: "6.7%",
                }}
                item
                xs={6}
              >
                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#727272",
                      fontWeight: "500",
                    }}
                  >
                    {Properties.StartLocation_label}
                  </span>
                </Typography>

                <CustomTextField
                  style={
                    startLocationError
                      ? {
                          border: "1px solid red",
                          height: "30px",
                          maxHeight: "30px",
                          minHeight: "30px",
                          width: "100%",
                        }
                      : {
                          border: "1px solid #08456C",
                          height: "30px",
                          maxHeight: "30px",
                          minHeight: "30px",
                          width: "100%",
                        }
                  }
                  type={"text"}
                  error={startLocationError}
                  keyToSet={"startLocation"}
                  handleChange={(prop) => (event) => {
                    this.setState({
                      startLocation: event.target.value,
                      startLocationError: false,
                    });
                  }}
                />
              </Grid>
            </Grid>

            {/* elevation and loss */}
            <Grid item style={{ width: "100%", display: "flex" }}>
              <Grid
                style={{
                  display: "grid",
                  marginLeft: "6.7%",
                  marginRight: "6.7%",
                }}
                item
                xs={6}
              >
                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#727272",
                      fontWeight: "500",
                    }}
                  >
                    {Properties.Elevation_label}
                  </span>
                </Typography>

                <Typography variant="body2" gutterBottom>
                  <CustomTextField
                    style={
                      elevationGainError
                        ? {
                            border: "1px solid red",
                            height: "30px",
                            maxHeight: "30px",
                            minHeight: "30px",
                            width: "100%",
                          }
                        : {
                            border: "1px solid #08456C",
                            height: "30px",
                            maxHeight: "30px",
                            minHeight: "30px",
                            width: "100%",
                          }
                    }
                    type={"text"}
                    error={elevationGain}
                    keyToSet={"elevationGain"}
                    handleChange={(prop) => (event) => {
                      this.setState({
                        elevationGain: event.target.value,
                        elevationGainError: false,
                      });
                    }}
                  />
                </Typography>
              </Grid>

              <Grid
                style={{
                  display: "grid",
                  marginLeft: "6.7%",
                  marginRight: "6.7%",
                }}
                item
                xs={6}
              >
                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#727272",
                      fontWeight: "500",
                    }}
                  >
                    {Properties.ElevationLoss_label}
                  </span>
                </Typography>

                <Typography variant="body2" gutterBottom>
                  <CustomTextField
                    style={
                      lossError
                        ? {
                            border: "1px solid red",
                            height: "30px",
                            maxHeight: "30px",
                            minHeight: "30px",
                            width: "100%",
                          }
                        : {
                            border: "1px solid #08456C",
                            height: "30px",
                            maxHeight: "30px",
                            minHeight: "30px",
                            width: "100%",
                          }
                    }
                    type={"text"}
                    error={lossError}
                    keyToSet={"loss"}
                    handleChange={(prop) => (event) => {
                      this.setState({
                        loss: event.target.value,
                        lossError: false,
                      });
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>

            {/* mannual */}
            <Grid item style={{ width: "100%", display: "flex" }}>
              <Grid
                style={{
                  display: "grid",
                  marginLeft: "6.7%",
                  marginRight: "6.7%",
                }}
                item
                xs={6}
              >
                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#727272",
                      fontWeight: "500",
                    }}
                  >
                    {Properties.Mannual_label}
                  </span>
                </Typography>

                <MultiSelect
                  value={
                    this.state.data.selectedMannual
                      ? this.state.data.selectedMannual
                      : this.state.data.manual
                      ? { label: "Yes", value: "Yes" }
                      : { label: "No", value: "No" }
                  }
                  error={mannualOptionError}
                  options={this.state.mannualOptionList}
                  onChange={(e) =>
                    this.handleMannualOptionChange(this.state.data, e)
                  }
                />
              </Grid>
            </Grid>
          </div>

          {/* submit button */}
          {this.state.data.status == "PENDING" &&
            this.state.eventType != "STREAK" && (
              <Grid
                style={{
                  width: "100%",
                  // height: "75px",
                  paddingTop: "2%",
                  paddingBottom: "2%",
                  display: "flex",
                  justifyContent: "center",
                  margin: "0px",
                  textAlign: "center",
                }}
                container
                spacing={1}
              >
                <SubmitCancelBtn
                  onClick={() => this.validateFields()}
                  label={"Submit"}
                  type="submit"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              </Grid>
            )}
        </Grid>

        <ImageDialogBox
          imagePath={this.state.imageUrl}
          open={this.state.imageDialog}
          onClick={() => this.setState({ imageDialog: false })}
          closeDisloag={() => this.setState({ imageDialog: false })}
        />

        <DialogBox
          open={this.state.open}
          message={this.getDialogComponent()}
          onClick={() => this.closeWindow()}
          closeDisloag={() => this.closeWindow()}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(SupportFormContainer);
