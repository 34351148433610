import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import { compose } from "recompose";
import { Checkbox, Chip, Grid, TextField, Typography } from "@material-ui/core";
import { CustomTextField } from "../../../../../../common/CustomTextField";
import MultiSelect from "../../../../../../common/MultiSelect";
import ApplicationConstants from "../../../../../../../constants/ApplicationConstants";
import { Clear, Remove } from "@material-ui/icons";
import SubmitCancelBtn from "../../../../../../common/SubmitCancelBtn";
import ApplicationUtil from "../../../../../../../utils/ApplicationUtil";
import {
  createQuestionInChallenge,
  getQuestionCategoryType,
} from "../../../../../../../apis/eventService";
import Snackbar from "../../../../../../common/Snackbar";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

/**
 * QUESTION CREATION COMPONENT
 */
const QuestionCreationComponent = ({ classes, isPastChallenge }) => {
  /**
   * Current Challenge Data
   */
  const currentChallenge = ApplicationUtil?.getProgramUnderCurrentChallenge();

  /**
   * QUESTION COMPONENT STATE
   */
  const [questionState, setQuestionState] = useState({
    question: {
      Question: "",
      Message: "",
      Options: [],
      questionCategory: "",
      expectedResult: "",
    },
    categoryType: {},
    questionOptionText: "",
    InputView: false,
    minimumRangeNumber: 0,
    maximumRangeNumber: 0,
    expectedResultByText: [],
    expectedResultByRange: 0,
    TipText: "",
    questionFieldError: false,
    categoryTypeList: [],
    categoryTypeError: false,
  });

  const {
    question: { Question, Message, Options, questionCategory, expectedResult },
    categoryType,
    questionOptionText,
    InputView,
    minimumRangeNumber,
    maximumRangeNumber,
    expectedResultByText,
    expectedResultByRange,
    TipText,
    questionFieldError,
    categoryTypeList,
    categoryTypeError,
  } = questionState;

  const onAddOption = () => {
    setQuestionState((prev) => ({ ...prev, InputView: true }));
  };

  const SaveOption = () => {
    if (questionOptionText) {
      let OptionsArray = Options?.map((item) => item.value);
      if (OptionsArray?.includes(questionOptionText)) {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "This option already added.",
        });
      } else {
        setQuestionState((prev) => ({
          ...prev,
          question: {
            ...prev.question,
            Options: [
              ...prev.question.Options,
              {
                id: Options?.length + 1,
                value: questionOptionText,
              },
            ],
          },
          InputView: false,
          questionOptionText: "",
        }));
      }
    } else {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Fill the option text",
      });
    }
  };

  const handleDeleteOption = (id, optionName) => {
    let filterData = Options.filter((item) => {
      return id !== item.id;
    });

    setQuestionState((prev) => ({
      ...prev,
      question: {
        ...prev.question,
        Options: filterData,
      },
      InputView: false,
      questionOptionText: "",
      expectedResultByText: expectedResultByText?.filter((item) => {
        return item !== optionName;
      }),
    }));
  };

  const SaveTipsOption = () => {
    if (TipText) {
      //   createTipsUnderChallenge({ tip: TipText }).then((res) => {
      //     Snackbar.show({
      //       variant: "success",
      //       isAutoHide: true,
      //       preventDuplicate: true,
      //       message: "Tip created successfully",
      //     });
      //   });
      //   setQuestionState((prev) => ({
      //     ...prev,
      //     TipText: "",
      //   }));
    } else {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Fill the Tip text",
      });
    }
  };

  const SubmitQuestion = () => {
    let paramsData = {
      questionResponseType: {
        id: categoryType?.id,
        categoryType: categoryType?.value,
      },
    };

    if (categoryType?.value === "Text") {
      paramsData = {
        ...paramsData,
        id: 1,
        question: Question,
        programChallengeType: currentChallenge?.programChallengeType,
        options: Options?.map((item) => item.value),
        expectedOption: expectedResultByText,
        range: null,
        expectedRange: null,
      };
    } else if (categoryType?.value === "Range") {
      paramsData = {
        ...paramsData,
        id: 1,
        question: Question,
        programChallengeType: currentChallenge?.programChallengeType,
        options: null,
        expectedOption: null,
        range: `${minimumRangeNumber}-${maximumRangeNumber}`,
        expectedRange: `${expectedResultByRange}`,
      };
    } else {
      paramsData = {
        ...paramsData,
        id: 1,
        question: Question,
        programChallengeType: currentChallenge?.programChallengeType,
        options: null,
        expectedOption: null,
        range: null,
        expectedRange: null,
      };
    }
    createQuestionInChallenge(paramsData).then((res) => {
      if (res?.status === 200) {
        if (res.data.code === "400") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Failed to create question",
          });
        } else {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Question created successfully",
          });
          if (currentChallenge) {
            let params = {
              challengeType: currentChallenge?.programChallengeType,
              ProgramChallenegeId: currentChallenge?.id,
            };

            // getAllQuestions(params).then((res) => {
            //   if (res.status === 200) {
            //     setQuestionState((prev) => ({
            //       ...prev,
            //       AllQuestions: res.data,
            //     }));
            //   }
            // });
          }
          setQuestionState((prev) => ({
            ...prev,
            question: {
              Question: "",
              Options: [],
            },
            expectedResultByRange: 0,
            expectedResultByText: [],
            maximumRangeNumber: 0,
            minimumRangeNumber: 0,
          }));
        }
      }
    });
  };

  const clearQuestion = () => {
    setQuestionState((prev) => ({
      ...prev,
      question: { Question: "" },
      minimumRangeNumber: 0,
      maximumRangeNumber: 0,
      expectedResultByRange: "",
      expectedResultByText: [],
      categoryType: {},
    }));
  };

  const saveQuestionHandler = () => {
    if (!Question || questionFieldError === true) {
      setQuestionState((prev) => ({ ...prev, questionFieldError: true }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter question .",
      });
      goToTop();
      return "";
    } else if (!categoryType?.value || categoryTypeError === true) {
      setQuestionState((prev) => ({ ...prev, categoryTypeError: true }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select category.",
      });
      goToTop();
      return "";
    } else if (categoryType?.value === "Text" && !Options?.length) {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please add the options",
      });
      goToTop();
    } else if (
      categoryType?.value === "Text" &&
      !expectedResultByText?.length
    ) {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select expected option",
      });
      goToTop();
    } else if (
      categoryType?.value === "Range" &&
      maximumRangeNumber < minimumRangeNumber
    ) {
      setQuestionState((prev) => ({
        ...prev,
        maxNumberFieldError: true,
      }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message:
          "Maximum range number should be greater than or equal to minimum range number",
      });
    } else if (
      categoryType?.value === "Range" &&
      minimumRangeNumber >= expectedResultByRange &&
      expectedResultByRange <= maximumRangeNumber
    ) {
      setQuestionState((prev) => ({
        ...prev,
        expectedNumberFieldError: true,
      }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message:
          "Expected result should be within the range of minimum and maximum numbers",
      });
    } else {
      SubmitQuestion();
    }
  };

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    getQuestionCategoryType().then((res) => {
      if (res?.status === 200) {
        if (res?.data && res?.data?.length) {
          let formattedData = res?.data?.map((item) => {
            return {
              label: item?.categoryType,
              value: item?.categoryType,
              id: item?.id,
            };
          });
          setQuestionState((prev) => ({
            ...prev,
            categoryTypeList: formattedData,
          }));
        }
      }
    });
  }, []);
  return (
    <div>
      <h3 className={classes.title}>Configure Questions</h3>
      <Grid
        container
        spacing={1}
        style={{
          padding: "10px 5px",
        }}
      >
        <Grid
          item
          xs={12}
          style={{ display: "flex", padding: "6px  0", alignItems: "center" }}
        >
          <Typography style={{ width: "15%" }}>
            <span className={classes.labelCss}>
              {" "}
              Question<span className={classes.mandatoryCss}>*</span> : &nbsp;
            </span>
          </Typography>
          <CustomTextField
            className={
              questionFieldError ? classes.fieldErrorCss : classes.fieldCss
            }
            type="text"
            placeholder="Enter Question"
            value={Question}
            handleChange={(prop) => (e) => {
              setQuestionState((prev) => ({
                ...prev,
                question: {
                  ...prev.question,
                  Question: e.target.value,
                },
                questionFieldError: false,
              }));
            }}
            readOnly={isPastChallenge}
          />
        </Grid>

        <Grid
          item
          xs={12}
          style={{ padding: "6px  0", display: "flex", alignItems: "center" }}
        >
          <Typography style={{ width: "15%" }} gutterBottom>
            <span className={classes.labelCss}>
              Category Type<span className={classes.mandatoryCss}>*</span> :
            </span>
          </Typography>
          <div className={classes.dropdownCss}>
            {" "}
            <MultiSelect
              value={categoryType}
              placeholder="Select..."
              error={categoryTypeError}
              options={categoryTypeList}
              onChange={(e) => {
                setQuestionState((prev) => ({
                  ...prev,
                  categoryType: e,
                  question: { ...prev.question, Options: [] },
                  categoryTypeError: false,
                }));
              }}
              isReadOnly={isPastChallenge}
            />
          </div>
        </Grid>

        {ApplicationConstants?.CategoryListQuestions[0]?.value ===
          categoryType?.value && (
          <Grid
            item
            xs={12}
            style={{
              padding: "6px  0",
              display: "flex",
              // alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Typography style={{ width: "15%" }} gutterBottom>
              <span className={classes.labelCss}>
                Options<span className={classes.mandatoryCss}>*</span> : &nbsp;
              </span>
            </Typography>
            <div
              className={classes.optionsCss}
              style={{ flexWrap: "wrap", width: "85%" }}
            >
              {Options?.map((item) => {
                return (
                  <div style={{ padding: "5px" }}>
                    <Chip
                      label={`${item.value}`}
                      // onClick={handleClick}
                      onDelete={() => handleDeleteOption(item.id, item.value)}
                      deleteIcon={<Clear />}
                    />
                  </div>
                );
              })}
              {InputView ? (
                <>
                  <TextField
                    value={questionOptionText}
                    onChange={(e) =>
                      setQuestionState((prev) => ({
                        ...prev,
                        questionOptionText: e.target.value,
                      }))
                    }
                  />

                  <SubmitCancelBtn
                    onClick={() => SaveOption()}
                    label={"Add"}
                    type="submit"
                    style={{ width: "100px", marginLeft: "8px" }}
                  />
                </>
              ) : (
                <div>
                  <SubmitCancelBtn
                    onClick={() => onAddOption()}
                    label={"Add Options"}
                    type="submit"
                    style={{ width: "121px" }}
                  />
                </div>
              )}
            </div>
          </Grid>
        )}

        {ApplicationConstants?.CategoryListQuestions[1]?.value ===
          categoryType?.value && (
          <Grid
            item
            xs={12}
            style={{
              padding: "6px  0",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography style={{ width: "15%" }} gutterBottom>
              <span className={classes.labelCss}>
                Range<span className={classes.mandatoryCss}>*</span> : &nbsp;
              </span>
            </Typography>
            <div className={classes.optionsCss}>
              <TextField
                value={minimumRangeNumber || 0}
                style={{ margin: "0 10px 0 10px ", width: "140px" }}
                placeholder="Min value"
                onChange={(e) =>
                  setQuestionState((prev) => ({
                    ...prev,
                    minimumRangeNumber: parseInt(e.target.value),
                  }))
                }
              />

              <TextField
                value={maximumRangeNumber || 0}
                // className={
                //   maxNumberFieldError ? classes.fieldErrorCss : classes.fieldCss
                // }
                style={{ margin: "0 10px 0 10px ", width: "140px" }}
                placeholder="Max value"
                onChange={(e) =>
                  setQuestionState((prev) => ({
                    ...prev,
                    maximumRangeNumber: parseInt(e.target.value),
                    maxNumberFieldError: false,
                  }))
                }
              />
            </div>
          </Grid>
        )}

        {ApplicationConstants?.CategoryListQuestions[0]?.value ===
          categoryType?.value && (
          <Grid
            item
            xs={12}
            style={{
              padding: "6px  0",
              display: "flex",
            }}
          >
            <Typography gutterBottom style={{ width: "15%" }}>
              <span className={classes.expectedAnwerLabel}>
                Expected Answer<span className={classes.mandatoryCss}>*</span> :
                &nbsp;
              </span>
            </Typography>
            <div
              className={classes.optionsCss}
              style={{ flexWrap: "wrap", width: "85%" }}
            >
              {Options?.length > 0 ? (
                Options?.map((item) => {
                  return (
                    <div
                      style={{
                        // padding: "4px 0px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        {...label}
                        variant={"primary"}
                        value={item.value}
                        onChange={(e) => {
                          if (
                            e.target.checked &&
                            !expectedResultByText?.includes(e.target.value)
                          ) {
                            setQuestionState((prev) => ({
                              ...prev,
                              expectedResultByText: [
                                ...prev.expectedResultByText,
                                e.target.value,
                              ],
                            }));
                          }
                        }}
                        style={{
                          color: "#09456c",
                          "&.Mui-checked": {
                            color: "#09456c",
                          },
                        }}
                      />
                      <Typography>{item.value}</Typography>
                    </div>
                  );
                })
              ) : (
                <div>
                  <Remove style={{ color: "#727272" }} />
                </div>
              )}
            </div>
          </Grid>
        )}

        {ApplicationConstants?.CategoryListQuestions[1]?.value ===
          categoryType?.value && (
          <Grid
            item
            xs={12}
            style={{
              padding: "6px  0",
              display: "flex",
              // alignItems: "center",
            }}
          >
            <Typography style={{ width: "15%" }} gutterBottom>
              <span className={classes.labelCss}>
                Expected Value<span className={classes.mandatoryCss}>*</span> :
                &nbsp;
              </span>
            </Typography>
            <div className={classes.optionsCss}>
              <TextField
                value={expectedResultByRange || 0}
                // className={
                //   expectedNumberFieldError
                //     ? classes.fieldErrorCss
                //     : classes.fieldCss
                // }
                style={{ margin: "0 10px 0 10px ", width: "140px" }}
                placeholder="Expected Value"
                onChange={(e) =>
                  setQuestionState((prev) => ({
                    ...prev,
                    expectedResultByRange: parseInt(e.target.value),
                    expectedNumberFieldError: false,
                  }))
                }
              />
            </div>
          </Grid>
        )}
      </Grid>
      {!isPastChallenge && (
        <Grid
          container
          style={{
            padding: "30px 0 20px 0",
          }}
          spacing={2}
        >
          {" "}
          <Grid item xs={12}>
            <div
              style={{ display: "flex", justifyContent: "center", gap: "20px" }}
            >
              <SubmitCancelBtn
                onClick={(e) => saveQuestionHandler()}
                label={"Add"}
                type="submit"
                style={{ width: "100px" }}
              />

              <SubmitCancelBtn
                label={"Clear"}
                onClick={(e) => clearQuestion()}
                type="submit"
                style={{ width: "100px" }}
              />
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const styles = (theme) => ({
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  autocompletefield: {
    border: "1px solid #08456C",
    width: "80% !important",
    padding: "0 4px",
    borderRadius: "3px",
  },

  selectQuestionDialogWrapper: {
    width: "100%",
    padding: "0",
  },

  fieldWrapper: {
    display: "flex",
    width: "100%",
    "& p": {
      width: "30%",
      display: "flex",
      justifyContent: "end",
    },
    // "& div": {
    //   width: "85%",
    // },
  },
  btnGroup: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    padding: "20px 0",
  },

  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
  },
  optionsCss: {
    display: "flex",
    // width: "100%",
    flex: "flex-wrap",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },

  expectedAnwerLabel: {
    color: "#727272",
    width: "fit-Content",
  },
  labelCss: {
    color: "#727272",
  },
  groupLabelCss: {
    fontSize: "20px",
    color: "rgb(8, 69, 108)",
  },
  mandatoryCss: { color: "red" },
  fieldCss: {
    border: "1px solid #08456C",
    height: "40px", //"45%",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  fieldErrorCss: {
    border: "1px solid red",
    height: "40px",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  textAreafieldCss: {
    border: "1px solid #08456C",
    height: "100px",
    maxHeight: "100px",
    minHeight: "100px",
    width: isBrowser ? "360px" : "160px",
  },
  textAreaErrorCss: {
    border: "1px solid red",
    height: "100px",
    maxHeight: "100px",
    minHeight: "100px",
    width: isBrowser ? "360px" : "160px",
  },
  tdropdownCss: { width: "180px" },
  dropdownCss: {
    width: isBrowser ? "360px" : "160px",
  },
  // custom fields
  root: {
    marginBottom: "20px",
    paddingBottom: "20px",
    borderBottom: "1px solid rgb(8, 69, 108)",
  },
  title: {
    color: "rgb(8, 69, 108)",
  },
  tableFieldCss: {
    border: "1px solid #08456C",
    height: "40px", //"45%",
    maxHeight: "40px",
    minHeight: "40px",
  },
  chipCss: {
    border: "1px solid #08456C",
    borderRadius: "4px",
    // height: "40px", //"45%",
    padding: "2px",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  tableHead: {
    backgroundColor: "#ced4da !important",
    "& th:first-child": {
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
      width: "30px",
    },
    "& th": {
      backgroundColor: "#ced4da",
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
      width: "100px",
    },
  },
  tableBody: {
    height: "300px",
    overflowY: "auto",
    "& tr td": {
      width: "100px",
      display: "flex",
      alignItems: "center",

      overflow: "hidden",
      textOverflow: "ellipsis",
      // textAlign: "center",
    },
  },
  fieldsContainer: {
    marginTop: "20px",
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "20px",
  },
  addIcon: {
    fontSize: "30px",
    cursor: "pointer",
    color: "rgb(8, 69, 108)",
  },
  textLink: {
    backgroundColor: "#ade8f4",
    fontWeight: "bold",
    color: "#023e8a",
    cursor: "pointer",
    borderRadius: "5px",
    width: "60px",
    margin: "auto",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&:hover": {
      color: "#03045e",
    },
  },
  moreFieldOptions: {
    padding: "8px 24px",
  },
  addButton: {
    // width: "120px",
    textDecoration: "none",
    backgroundColor: "#09456c",
    color: "white",
    borderRadius: "20px",
    // marginLeft: "auto",
  },
  addBtnText: {
    backgroundColor: "#09456c",
    color: "white",
  },
  saveButton: {
    width: "120px",
  },
  option: {
    border: "1px solid #727272",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "10px",
    paddingBottom: "10px",
    // paddingRight: "",
  },
  optionIcons: {
    display: "flex",
    alignItems: "center",
  },
  optionListContainer: {
    // maxHeight: "300px",
    // overflowY: "auto",
    // overflowX: "hidden",
    marginBottom: "10px",
    paddingLeft: "10px",
  },
  topFieldAdd: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "20px",
  },
  linkText: {
    color: "rgb(8, 69, 108)",
    cursor: "pointer",
    textDecoration: "underline",
  },
  parentDivCss: {
    height: "50px",
  },
  childQuestionDivCss: {
    width: "180px",
    maxWidth: "180px",
    minWidth: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  childDivCss: {
    position: "absolute",
    width: "180px",
    maxWidth: "180px",
    minWidth: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // zIndex: "1",
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(QuestionCreationComponent);
