import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Switch,
  OutlinedInput,
  Card,
  CardHeader,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Popover,
} from "@material-ui/core";
import Properties from "../../../../../properties/Properties";
import { isBrowser } from "react-device-detect";
import {
  getDeliverableTemplate,
  uploadInlineImages,
  getTemplatePreview,
  getEventImagesDetails,
  saveDeliverableTemplate,
  getUploadedDeliverableTemplate,
  deleteInlineImage,
  getEventActivityDetails,
} from "./../../../../../apis/eventService";
import { withRouter } from "react-router-dom";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import ImageDialogBox from "../../../../common/ImageDialogBox";
import PublishIcon from "@material-ui/icons/Publish";
import SubmitCancelBtn from "../../../../common/SubmitCancelBtn";
import MultiSelect from "../../../../common/MultiSelect";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import InlineDivider from "../../../../common/InlineDivider";
import { FileUploader } from "./../../../../common/FileUploader";
import Snackbar from "../../../../common/Snackbar";
import { CustomTextField } from "../../../../common/CustomTextField";
import ClearIcon from "@material-ui/icons/Clear";

import DialogBox from "../../../../common/DialogBox";
import Cancel from "@material-ui/icons/Cancel";
import PopupView from "../../../../common/PopupView";
import ImageSlider from "../../../../common/ImageSlider";
const StyledCardMedia = withStyles({
  img: {
    objectFit: "unset",
  },
})(CardMedia);
const styles = (theme) => ({
  typographyCss: {
    paddingBottom: "4px",
    fontSize: "18px",
    color: "#727272",
  },
  fileGridCss: { padding: "5px 0px" },
  actionCss: {
    color: "#F79824",
  },
  imgContainer: {
    position: "relative",
    width: "200px",
    textAlign: "center",
    padding: "40px 20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  smallImgContainer: {
    position: "relative",
    width: "200px",
    height: "120px",
    textAlign: "center",
    padding: "10px 5px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  clearImgContainer: {
    position: "absolute",
    top: "1px",
    right: "1px",
  },
  labelCss: {
    color: "#727272",
    fontSize: "18px",
    textTransform: "capitalize",
  },
  mandatoryCss: { color: "red" },

  fieldCss: {
    border: "1px solid #08456C",
    height: "40px", //"45%",
    maxHeight: "40px",
    minHeight: "40px",
    // width: isBrowser ? "360px" : "160px",
  },
  fieldErrorCss: {
    border: "1px solid red",
    height: "40px",
    maxHeight: "40px",
    minHeight: "40px",
    // width: isBrowser ? "360px" : "160px",
  },
  profileImgContainer: {
    // width: "120px",
    height: "120px",
  },
  smallProfileImgContainer: {
    // width: "120px",
    height: "60px",
  },

  profileImg: {
    width: "200px",
    height: "120px",
    objectFit: "contain",
    margin: "auto",
    cursor: "pointer",
  },
  signImgCss: {
    width: "100px",
    height: "50px",
    objectFit: "contain",
    margin: "auto",
    cursor: "pointer",
    paddingTop: "10px",
  },

  parentDivCss: {
    height: "40px",
  },
  childDivCss: {
    position: "absolute",
    // zIndex: "1",
  },
  checkboxDivCss: {
    border: "1px solid red",
    height: "20px",
    width: "20px",
  },
  checkboxCss: {
    height: "20px",
    width: "20px",
  },
});
class DeliverableTabView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliverableItemList: [
        // { label: "BIB", value: "BIB" },
        { label: "CERTIFICATE", value: "CERTIFICATE" },
        // { label: "COLLAGE", value: "COLLAGE" },
      ],
      certificateSign_1: {
        keyName: "CER_SIGN_1",
        imageURL: null,
        label: "Signature Image ",
        width: "95",
        height: "42", //w*h
        exactSize: true,
      },
      certificateSign_2: {
        keyName: "CER_SIGN_2",
        imageURL: null,
        label: "Signature Image",
        width: "95",
        height: "42",
        exactSize: true,
      },
      certificateLogo: {
        keyName: "CER_ORG_LOGO_1",
        imageURL: null,
        label: "Event Logo",
        width: "100 ",
        height: "80", //w*h
        exactSize: true,
      },
      certSigningAuthority1Name: "",
      certSigningAuthority2Name: "",
      certSigningAuthority1Designation: "",
      certSigningAuthority2Designation: "",
      certificateBackgrounds: [],
      showSaveAll: false,
    };
  }

  componentDidMount() {
    let supportEventId = ApplicationUtil.getSupportsEventID();
    let eventData = ApplicationUtil.getSupportsEventsDetails();
    let type = eventData?.type;
    let pastEvent = localStorage.getItem("pastEvent");
    this.setState({ eventType: type });
    let setPermissions = JSON.parse(localStorage.getItem("supportPermissions"));
    let isChallengeUserPastDate = localStorage.getItem("isChallengePastDate");
    getEventActivityDetails(null, supportEventId).then((response) => {
      response.data.map(
        (r) => (
          (r.label = r.displayName),
          (r.value = r.type),
          (r.id = r.id),
          (r.eventSupportedActivityTypeId = r?.eventSupportedActivityTypeId)
        )
      );
      //duplicates removed
      let activities = response.data;
      activities = activities.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      this.setState({
        activityType: activities,
      });
    });
    if (pastEvent === "true" || isChallengeUserPastDate === "true") {
      this.setState({
        isPastEvent: true, //true,
      });
    } else {
      this.setState({
        isPastEvent: !setPermissions.canModifyEvent,
      });
    }

    localStorage.setItem("ActiveTab", "THREE");
    //for all inline images
    getEventImagesDetails().then((res) => {
      let d = res.data;
      for (const key of Object.keys(d)) {
        let imgsrc = "data:image/png;base64," + d[key]?.imageData;
        this.setImages(key, imgsrc);
      }
    });

    let element = { type: "RUN" };

    //template which was saved prev
    getUploadedDeliverableTemplate().then((res) => {
      let selectedDeliverableItem = [],
        selectedTemplatePreview = null,
        selectedCertificateTemplateId = null;

      let selectedItems = [],
        selectedTemplatesList = [],
        selectedFlags = [];
      if (res.data?.length > 0) {
        if (res?.data[0]?.eventSigningAuthorities?.length > 0) {
          let n1,
            d1,
            n2,
            d2,
            id1,
            id2 = "";
          for (let i = 0; i < res.data[0].eventSigningAuthorities.length; i++) {
            if (i == 0) {
              n1 = res.data[0].eventSigningAuthorities[i].authorityName;
              d1 = res.data[0].eventSigningAuthorities[i].authorityDesignation;
              id1 = res.data[0].eventSigningAuthorities[i].id;
            } else {
              n2 = res.data[0].eventSigningAuthorities[i].authorityName;
              d2 = res.data[0].eventSigningAuthorities[i].authorityDesignation;
              id2 = res.data[0].eventSigningAuthorities[i].id;
            }
          }
          this.setState({
            signId1: id1,
            signId2: id2,
            certSigningAuthority1Name: n1,
            certSigningAuthority2Name: n2,
            certSigningAuthority1Designation: d1,
            certSigningAuthority2Designation: d2,
          });
        }
        // templateAttachment;
        for (let i = 0; i < res.data.length; i++) {
          this.state.deliverableItemList.map((e) => {
            if (e.value === res.data[i].templateAttachment) {
              if (!selectedItems.some((code) => code.value === e.value)) {
                selectedItems.push(e);
              }
            }
          });
          if (res?.data[i]?.templateAttachment === "CERTIFICATE") {
            let obj = {};
            obj.type = res?.data[i]?.attachmentType;
            obj.templateId = res?.data[i]?.parentTemplateId;
            obj.previewtemplateId = res?.data[i]?.templateId;

            obj.selectedFlags = res?.data[i]?.previewFlag;
            obj.templateContent = res?.data[i]?.templateContent;
            selectedTemplatesList.push(obj);
          }
        }
      }
      this.handleDeliverableItemChange(selectedItems, selectedTemplatesList);
    });
  }

  handleUploadedFiles = (arg) => {
    let files = arg.selectedFiles?.[0]; //?.[0].src;
    let row = arg.selectedRow;
    let imgsrc = URL.createObjectURL(files);
    uploadInlineImages(row.keyName, files).then((res) => {
      this.setImages(row.keyName, imgsrc);
    });
  };
  setImages = (value, imgsrc) => {
    const {
      activityType,
      certificateLogo,
      certificateSign_1,
      certificateSign_2,
    } = this.state;
    if (value == "CER_ORG_LOGO_1") {
      let item = certificateLogo;
      item.imageURL = imgsrc;
      this.setState({ certificateLogo: item });
    } else if (value == "CER_SIGN_1") {
      let item = certificateSign_1;
      item.imageURL = imgsrc;
      this.setState({ certificateSign_1: item });
    } else if (value == "CER_SIGN_2") {
      let item = certificateSign_2;
      item.imageURL = imgsrc;
      this.setState({ certificateSign_2: item });
    } else if (
      value == "CER_BACKGROUND" ||
      value == "CYCLING_CER_BGD" ||
      value == "STEP_CER_BGD"
    ) {
      let newActivityType = activityType;
      let certificate = {
        imageURL: null,
        width: "842",
        height: "595",
        exactSize: true,
      };
      let item = null;

      if (value === "CER_BACKGROUND") {
        item = newActivityType.findIndex((obj) => obj?.type === "RUN");

        certificate.keyName = "CER_BACKGROUND";
        certificate.label = "Certificate Background for Run";
      } else if (value === "CYCLING_CER_BGD") {
        item = newActivityType.findIndex((obj) => obj?.type === "CYCLE");
        certificate.keyName = "CYCLING_CER_BGD";
        certificate.label = "Certificate Background for Cycle";
      } else if (value === "STEP_CER_BGD") {
        item = newActivityType.findIndex((obj) => obj?.type === "STEPS");
        certificate.keyName = "STEP_CER_BGD";
        certificate.label = "Certificate Background for Steps";
      }
      if (imgsrc) {
        certificate.imageURL = imgsrc;
      }
      if (item !== -1) {
        newActivityType[item].certificateBackground = certificate;
      }
      this.setState({ activityType: newActivityType });
    }
  };

  handleImageRemove = (key) => {
    const {
      certificateLogo,
      certificateSign_1,
      certificateSign_2,
      activityType,
    } = this.state;
    deleteInlineImage(key).then((res) => {
      if (
        key == "CER_BACKGROUND" ||
        key == "CYCLING_CER_BGD" ||
        key == "STEP_CER_BGD"
      ) {
        let newActivityType = activityType;
        let certificate = {
          imageURL: null,
          width: "800",
          height: "550", //w*h
          maxWidth: "900",
          maxHeight: "650",
        };
        let item = null;
        if (key === "CER_BACKGROUND") {
          item = newActivityType.findIndex((obj) => obj.type === "RUN");
          certificate.keyName = "CER_BACKGROUND";
          certificate.label = "Certificate Background for Run";
        } else if (key === "CYCLING_CER_BGD") {
          item = newActivityType.findIndex((obj) => obj.type === "CYCLE");
          certificate.keyName = "CYCLING_CER_BGD";
          certificate.label = "Certificate Background for Cycle";
        } else if (key === "STEP_CER_BGD") {
          item = newActivityType.findIndex((obj) => obj.type === "STEPS");
          certificate.keyName = "STEP_CER_BGD";
          certificate.label = "Certificate Background for Steps";
        }
        certificate.imageURL = null;
        newActivityType[item].certificateBackground = certificate;
        this.setState({ activityType: newActivityType });
      } else if (key == "CER_ORG_LOGO_1") {
        let item = certificateLogo;
        item.imageURL = null;
        this.setState({ certificateLogo: item });
      } else if (key == "CER_SIGN_1") {
        let item = certificateSign_1;
        item.imageURL = null;
        this.setState({ certificateSign_1: item });
      } else if (key == "CER_SIGN_2") {
        let item = certificateSign_2;
        item.imageURL = null;
        this.setState({ certificateSign_2: item });
      }
    });
  };

  validateFields(activity) {
    const {
      activityType,
      selectedDeliverableItem,
      selectedTemplateFile,
      certSigningAuthority1Name,
      certSigningAuthority1Designation,
      certSigningAuthority2,
      certificateSign_1,
      certificateSign_2,
    } = this.state;

    if (selectedDeliverableItem?.length > 0) {
      selectedDeliverableItem.forEach((element) => {
        if (element.value == "CERTIFICATE") {
          if (
            certSigningAuthority1Name == "" ||
            certSigningAuthority1Name == null
          ) {
            this.setState({
              certSigningAuthority1_authorityNameError: true,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Please enter signing authority name.",
            });
            this.goToTop();
            return;
          } else if (
            certSigningAuthority1Designation == "" ||
            certSigningAuthority1Designation == null
          ) {
            this.setState({
              certSigningAuthority1_authorityDesignationError: true,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Please enter signing authority designation.",
            });
            this.goToTop();
            return;
          } else if (certificateSign_1.imageURL == null) {
            this.setState({
              certificateSign_1Error: true,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Please select signing authority signature.",
            });
            this.goToTop();
            return;
          } else if (
            activity.selectedTemplate === "" ||
            activity.selectedTemplate === undefined
          ) {
            let newActivityType = activityType;
            let index = newActivityType.findIndex(
              (obj) => obj.value == activity.value
            );
            newActivityType[index].selectedTemplateError = true;
            this.setState({
              activityType: newActivityType,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Please select certificate template.",
            });
            this.goToTop();
            return;
          } else {
            this.checkPreview(activity);
          }
        }
      });
    }
  }

  //template preview for certificates
  checkPreview(activity) {
    const {
      eventType,
      activityType,
      certSigningAuthority1Name,
      certSigningAuthority1Designation,

      certSigningAuthority2Name,
      certSigningAuthority2Designation,

      certificateSign_1,
      certificateSign_2,
    } = this.state;
    let newActivity = {
      id: activity.id,
      displayName: activity.displayName,
      type: activity.type,
    };
    let previewFlags = {};
    for (let i = 0; i < activity.flagInputs.length; i++) {
      previewFlags[activity.flagInputs[i].label] = activity.flagInputs[i].value;
    }
    let customParams = {
      eventType: eventType,
      templateId: activity.selectedTemplate,
      activityType: newActivity,
      attachment: "CERTIFICATE",
      eventSigningAuthorityList: [
        {
          authorityName: certSigningAuthority1Name,
          authorityDesignation: certSigningAuthority1Designation,
        },
      ],

      previewFlag: previewFlags,
    };
    if (
      certSigningAuthority2Name !== "" &&
      certSigningAuthority2Designation !== ""
    ) {
      customParams.eventSigningAuthorityList.push({
        authorityName: certSigningAuthority2Name,
        authorityDesignation: certSigningAuthority2Designation,
      });
    }
    getTemplatePreview([customParams]).then((res) => {
      let selectedActivity = activityType.findIndex(
        (obj) => obj.value == activity.value
      );
      let newActivityType = activityType;
      newActivityType[selectedActivity].previewDetails = res.data?.[0];

      //to enable saveAll button
      let enableSaveAll = false;
      newActivityType.forEach((element) => {
        if (!enableSaveAll) {
          if (element.previewDetails) {
            enableSaveAll = true;
          } else {
            enableSaveAll = false;
          }
        }
      });
      this.setState({
        showSaveAll: enableSaveAll,
        activityType: newActivityType,
        certificatePreviewDialog: "Certificate Preview",
        showCertificatePreview: true,
        previewTemplateList: res?.data, // ReactHtmlParser(res.data[0]?.templateContent),
        dialogTitle: "Certificate Preview",
      });
    });
  }

  saveAllDetails(row) {
    const {
      certificateTemplatePreview,
      certSigningAuthority1Name,
      certSigningAuthority1Designation,
      certSigningAuthority2Name,
      certSigningAuthority2Designation,
      certificateSign_1,
      certificateSign_2,
      signId1,
      signId2,
      activityType,
    } = this.state;
    let customParams = [];

    if (certSigningAuthority1Name == "" || certSigningAuthority1Name == null) {
      this.setState({
        certSigningAuthority1_authorityNameError: true,
      });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter signing authority name.",
      });
      this.goToTop();
      return;
    } else if (
      certSigningAuthority1Designation == "" ||
      certSigningAuthority1Designation == null
    ) {
      this.setState({
        certSigningAuthority1_authorityDesignationError: true,
      });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter signing authority designation.",
      });
      this.goToTop();
      return;
    } else if (certificateSign_1.imageURL == null) {
      this.setState({
        certificateSign_1Error: true,
      });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select signing authority signature.",
      });
      this.goToTop();
      return;
    } else {
      activityType.forEach((element) => {
        let item = {};

        if (element?.previewDetails) {
          item.activityType = element?.previewDetails?.activityType;
          item.templateId = element?.previewDetails?.templateId;
          item.templateContent = element?.previewDetails?.templateContent;
          // item.previewFlag = element.previewDetails?.previewFlag;
        } else {
          item.activityType = {
            displayName: element.displayName,
            id: element.id,
            type: element.type,
          };
          item.parentTemplateId = element?.selectedTemplate;
          // let allFlags = element.flagInputs;

          // let tempFlags = {};
          // for (var i = 0; i < allFlags.length; i++) {
          //   tempFlags[allFlags[i].label] = allFlags[i].value;
          // }

          // item.previewFlag = tempFlags;
        }
        let allFlags = element.flagInputs;
        let tempFlags = {};
        for (var i = 0; i < allFlags.length; i++) {
          tempFlags[allFlags[i].label] = allFlags[i].value;
        }

        item.previewFlag = tempFlags;
        // parentTemplateId;
        item.templateAttachment = "CERTIFICATE";
        item.eventSigningAuthorities = [
          {
            authorityName: certSigningAuthority1Name,
            authorityDesignation: certSigningAuthority1Designation,
            id: signId1 ? signId1 : null,
          },
        ];

        if (
          certSigningAuthority2Name !== undefined &&
          certSigningAuthority2Name !== "" &&
          certSigningAuthority2Name !== null &&
          certSigningAuthority2Designation !== undefined &&
          certSigningAuthority2Designation !== "" &&
          certSigningAuthority2Designation !== null
        ) {
          item.eventSigningAuthorities.push({
            authorityName: certSigningAuthority2Name,
            authorityDesignation: certSigningAuthority2Designation,
            id: signId2 ? signId2 : null,
          });
        }
        customParams.push(item);
      });

      saveDeliverableTemplate(customParams).then((res) => {});
    }
  }

  handleDeliverableItemChange = (selectedValues, selectedTemplatesList) => {
    var arr = [];

    const { activityType, certificateBackgrounds } = this.state;
    if (selectedValues != null) {
      selectedValues.forEach((element) => {
        arr.push(element);

        let templteType = element.value; //"COLLAGE";
        let newActivityType = activityType;
        for (let i = 0; i < newActivityType?.length; i++) {
          //template call for certificate
          if (element.value == "CERTIFICATE") {
            let certificate = {
              //keyName: "CER_BACKGROUND",
              imageURL: null,
              // label: "Certificate Background",
              width: "800",
              height: "550", //w*h
              maxWidth: "900",
              maxHeight: "650",
            };
            let flagInputs = [
              { label: "Time", value: false, show: true },
              { label: "Profile", value: false, show: true },
              { label: "Bib", value: false, show: true },
              { label: "Distance", value: false, show: false },
              { label: "Route", value: false, show: false },
              {
                label: "Speed",
                value: false,
                show: false,
              },
              {
                label: "Steps",
                value: false,
                show: false,
              },
              {
                label: "Pace",
                value: false,
                show: false,
              },
            ];
            let selectedTemplate = null;
            if (newActivityType[i].type == "RUN") {
              certificate.keyName = "CER_BACKGROUND";
              certificate.label = "Certificate Background for Run";
              let item = flagInputs.findIndex((obj) => obj.label == "Pace");
              flagInputs[item].show = true;
              item = flagInputs.findIndex((obj) => obj.label == "Route");
              flagInputs[item].show = true;
              item = flagInputs.findIndex((obj) => obj.label == "Distance");
              flagInputs[item].show = true;

              //
              if (selectedTemplatesList) {
                item = selectedTemplatesList.findIndex(
                  (obj) => obj.type === "RUN_CERTIFICATE"
                );
                selectedTemplate = selectedTemplatesList[item]?.templateId;

                let selectedFlags = selectedTemplatesList[item]?.selectedFlags;
                for (let key in selectedFlags) {
                  if (selectedFlags[key]) {
                    let item = flagInputs.findIndex((obj) => obj.label == key);
                    flagInputs[item].value = selectedFlags[key];
                  }
                }
                //setting preview
                let previewDetails = {};
                let activity = activityType.findIndex(
                  (obj) => obj.value === "RUN"
                );
                previewDetails.activityType = {
                  id: activityType[activity]?.id,
                  displayName: activityType[activity]?.displayName,
                  type: activityType[activity]?.type,
                };
                previewDetails.previewFlag =
                  selectedTemplatesList[item]?.selectedFlags;
                previewDetails.templateContent =
                  selectedTemplatesList[item]?.templateContent;
                previewDetails.templateId =
                  selectedTemplatesList[item]?.previewtemplateId;
                newActivityType[i].previewDetails = previewDetails;
              }
            } else if (newActivityType[i].type == "CYCLE") {
              certificate.keyName = "CYCLING_CER_BGD";
              certificate.label = "Certificate Background for Cycle";
              let item = flagInputs.findIndex((obj) => obj.label == "Speed");
              flagInputs[item].show = true;
              item = flagInputs.findIndex((obj) => obj.label == "Route");
              flagInputs[item].show = true;
              item = flagInputs.findIndex((obj) => obj.label == "Distance");
              flagInputs[item].show = true;

              if (selectedTemplatesList) {
                item = selectedTemplatesList.findIndex(
                  (obj) => obj.type === "CYCLE_CERTIFICATE"
                );
                selectedTemplate = selectedTemplatesList[item]?.templateId;

                let selectedFlags = selectedTemplatesList[item]?.selectedFlags;
                for (let key in selectedFlags) {
                  if (selectedFlags[key]) {
                    let item = flagInputs.findIndex((obj) => obj.label == key);
                    flagInputs[item].value = selectedFlags[key];
                  }
                }

                //setting preview
                let previewDetails = {};
                let activity = activityType.findIndex(
                  (obj) => obj.value === "CYCLE"
                );
                previewDetails.activityType = {
                  id: activityType[activity]?.id,
                  displayName: activityType[activity]?.displayName,
                  type: activityType[activity]?.type,
                };
                previewDetails.previewFlag =
                  selectedTemplatesList[item]?.selectedFlags;
                previewDetails.templateContent =
                  selectedTemplatesList[item]?.templateContent;
                previewDetails.templateId =
                  selectedTemplatesList[item]?.previewtemplateId;
                newActivityType[i].previewDetails = previewDetails;
              }
            } else if (newActivityType[i].type == "STEPS") {
              certificate.keyName = "STEP_CER_BGD";
              certificate.label = "Certificate Background for Steps";
              let item = flagInputs.findIndex((obj) => obj.label == "Route");
              flagInputs[item].show = true;

              if (selectedTemplatesList) {
                item = selectedTemplatesList.findIndex(
                  (obj) => obj.type === "STEPS_PREVIEW_CERTIFICATE"
                );
                selectedTemplate = selectedTemplatesList[item]?.templateId;

                let selectedFlags = selectedTemplatesList[item]?.selectedFlags;
                for (let key in selectedFlags) {
                  if (selectedFlags[key]) {
                    let item = flagInputs.findIndex((obj) => obj.label == key);
                    flagInputs[item].value = selectedFlags[key];
                  }
                }

                //setting preview
                let previewDetails = {};
                let activity = activityType.findIndex(
                  (obj) => obj.value === "STEPS"
                );
                previewDetails.activityType = {
                  id: activityType[activity]?.id,
                  displayName: activityType[activity]?.displayName,
                  type: activityType[activity]?.type,
                };
                previewDetails.previewFlag =
                  selectedTemplatesList[item]?.selectedFlags;
                previewDetails.templateContent =
                  selectedTemplatesList[item]?.templateContent;
                previewDetails.templateId =
                  selectedTemplatesList[item]?.previewtemplateId;
                newActivityType[i].previewDetails = previewDetails;
              }
            } else if (element.type == "DUATHLON") {
              // certificate.keyName = "STEP_CER_BGD";
              certificate.label = "Certificate Background for Duathlon";

              //
              if (selectedTemplatesList) {
                let item = selectedTemplatesList.findIndex(
                  (obj) => obj.type === "DUATHLON_CERTIFICATE"
                );
                selectedTemplate = selectedTemplatesList[item]?.templateId;

                let selectedFlags = selectedTemplatesList[item]?.selectedFlags;
                for (let key in selectedFlags) {
                  if (selectedFlags[key]) {
                    let item = flagInputs.findIndex((obj) => obj.label == key);
                    flagInputs[item].value = selectedFlags[key];
                  }
                }
              }
            }

            var requiredCertificate = certificateBackgrounds.filter(function (
              el
            ) {
              return el.keyName == certificate.keyName;
            });

            if (!newActivityType[i]?.certificateBackground?.imageURL) {
              certificate.imageURL = requiredCertificate[0]?.imageURL
                ? requiredCertificate[0]?.imageURL
                : null;
              newActivityType[i].certificateBackground = certificate;
            }
            newActivityType[i].flagInputs = flagInputs;
            newActivityType[i].selectedTemplate = selectedTemplate;
          }
          const getData = async () => {
            let response = await getDeliverableTemplate(
              this.state.eventType,
              newActivityType[i].type, //"RUN", //this.state.activityType,
              templteType
            ).then((res) => {
              if (element.value == "CERTIFICATE") {
                newActivityType[i].templateList = res?.data;

                if (!newActivityType[i].certificateBackground.imageURL) {
                  if (res.data?.length > 0) {
                    if (
                      selectedTemplatesList &&
                      selectedTemplatesList.length > 0
                    ) {
                      let item = res.data.findIndex(
                        (obj) => obj.id === selectedTemplatesList[i]?.templateId
                      );
                      newActivityType[i].customeTheme = false;
                      this.setDefaultCertificateBackground(
                        res?.data[item]?.emailInlineImages[0]
                      );
                    }
                  }
                }

                // default selection of template
                if (!newActivityType[i].selectedTemplate) {
                  newActivityType[i].selectedTemplate =
                    newActivityType[i]?.templateList[0]?.id;

                  if (
                    newActivityType[i]?.templateList[0]?.emailInlineImages[0]
                  ) {
                    this.setDefaultCertificateBackground(
                      newActivityType[i]?.templateList[0]?.emailInlineImages[0]
                    );
                  }
                }
              }
            });
          };
          getData().then(() => {
            this.setState({ activityType: newActivityType });
          });
        }
      });

      this.setState({
        selectedDeliverableItem: arr,
      });
    } else {
      this.setState({
        selectedDeliverableItem: arr,
      });
    }
  };

  handleTemplateSelection = (e, activity, templateId, emailInlineImages) => {
    const { activityType } = this.state;
    let newActivityType = activityType;
    let item = newActivityType.findIndex((obj) => obj.value == activity.value);
    newActivityType[item].selectedTemplate = templateId;

    if (!newActivityType[item].customeTheme) {
      newActivityType[item].customeTheme = false;
      if (emailInlineImages) {
        this.setDefaultCertificateBackground(emailInlineImages[0]);
      }
    }

    this.setState({ activityType: newActivityType });
  };
  handleFlagChange = (e, activity, flag) => {
    const { activityType } = this.state;
    let newActivityType = activityType;
    let item = newActivityType.findIndex(
      (obj) =>
        obj.eventSupportedActivityTypeId ===
        activity.eventSupportedActivityTypeId
    );
    let flagList = newActivityType[item].flagInputs;
    let previewDetails = newActivityType[item].previewDetails;
    let flagItem = flagList.findIndex((obj) => obj.label == flag.label);
    flagList[flagItem].value = e.target?.checked;
    newActivityType[item].flagInputs = flagList;
    if (previewDetails?.previewFlag) {
      previewDetails.previewFlag[flag.label] = flagList[flagItem]?.value;
    }
    this.setState({ activityType: newActivityType });
  };

  setDefaultCertificateBackground(imageDetails) {
    //cert back
    let key = imageDetails?.imageKey;
    // const getImageData = async () => {
    //   const base64Response = await fetch(
    //     `data:image/jpeg;base64,
    //     ${imageDetails?.imageDto?.imageData}`
    //   );
    //   const blob = await base64Response.blob();
    //   return blob;
    // };
    // const blob = new Blob(getImageData, {
    //   type: "image/png",
    // });
    // const url = window.URL.createObjectURL(blob);
    // var a = window.document.createElement("a");
    // a.setAttribute("hidden", "");
    // a.setAttribute("href", url);

    // const file = new File([a], imageDetails?.imageDto?.fileName, {
    //   type: "image/png",
    // });

    if (imageDetails?.imageDto?.imageData) {
      const url = "data:image/jpeg;base64," + imageDetails?.imageDto?.imageData;

      // if (this.state.activityType[0].certificateBackground.imageURL !== url) {
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });

          let hideMsg = true;
          uploadInlineImages(key, file, hideMsg).then((res) => {
            this.setImages(key, url);
          });
        });
      // }
    }
  }
  goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  closeWindow = () => {
    this.setState({
      dialogTitle: "",
      showImage: false,
      imagePath: null,
      dialogTitle: null,
    });
  };

  getCertificatePreviewContent = () => {
    return (
      <div>
        <ImageSlider files={this.state.previewTemplateList} template />
      </div>
    );
  };
  closeCertificatePreviewWindow = () => {
    this.setState({
      showCertificatePreview: false,
      certificatePreviewDialog: "Certificate Preview",
    });
  };
  closeTemplateWindow = () => {
    this.setState({
      templateDialogTitle: "",
      showTemplate: false,
      templateMessage: null,
      dialogTitle: null,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      isPastEvent,
      certSigningAuthority1Name,
      certSigningAuthority2Name,
      certSigningAuthority1Designation,
      certSigningAuthority2Designation,
      certificateSign_1,
      certificateSign_2,
      certSigningAuthority1_authorityDesignationError,
      certSigningAuthority1_authorityNameError,
      certSigningAuthority2_authorityDesignationError,
      certSigningAuthority2_authorityNameError,

      activityType,

      selectedDeliverableItem, //selected items
      deliverableItemError,
      deliverableItemList,
      certificateLogo,
      selectedTemplateFile,
      selectedTemplateFileError,
      certificateTemplatePreview,
    } = this.state;

    return (
      <div>
        <Grid container spacing={1} style={{ padding: "10px" }}>
          <Grid
            item
            // xs={6}
          >
            <Typography variant="body2" gutterBottom>
              <span className={classes.labelCss}>
                {Properties.DeliverableItemLabel}
              </span>
            </Typography>
            <div className={classes.parentDivCss}>
              <div
                style={{
                  width: "360px", // { selectedDeliverableItem.length < 2 && "360px"},
                  maxHeight: "60px",
                  height: "40px",
                  minHeight: "40px",
                }}
                className={classes.childDivCss}
              >
                <MultiSelect
                  isMulti={true}
                  value={selectedDeliverableItem}
                  error={deliverableItemError}
                  options={deliverableItemList}
                  onChange={(e) => {
                    this.handleDeliverableItemChange(e);
                  }}
                  isReadOnly={isPastEvent}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        {selectedDeliverableItem?.length > 0 &&
          selectedDeliverableItem.map((row, index) => (
            <div
              style={{
                padding: "10px",
              }}
              key={index}
            >
              <InlineDivider
                style={{ height: "5px", backgroundColor: "#08456C" }}
              >
                {ApplicationUtil.getCamelCasing(row.value)}{" "}
              </InlineDivider>

              {/* CERTIFICATE */}
              {row.label == "CERTIFICATE" && (
                <div>
                  {/* sign1 */}
                  <Grid
                    container
                    style={{
                      margin: "10px 5px",
                    }}
                  >
                    <Grid item xs={4} className={classes.innerGridCss}>
                      <Typography variant="body2" gutterBottom>
                        <span className={classes.labelCss}>
                          {Properties.signAuthorityNameLabel}
                        </span>
                        <span className={classes.mandatoryCss}>*</span>
                      </Typography>
                      <CustomTextField
                        type={"text"}
                        value={certSigningAuthority1Name}
                        className={
                          certSigningAuthority1_authorityNameError
                            ? classes.fieldErrorCss
                            : classes.fieldCss
                        }
                        handleChange={(prop) => (e) => {
                          this.setState({
                            certSigningAuthority1Name: e.target.value,
                            certSigningAuthority1_authorityNameError: false,
                          });
                        }}
                        readOnly={isPastEvent}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.innerGridCss}>
                      <Typography variant="body2" gutterBottom>
                        <span className={classes.labelCss}>
                          {Properties.signAuthorityDesignationLabel}
                        </span>
                        <span className={classes.mandatoryCss}>*</span>
                      </Typography>
                      <CustomTextField
                        type={"text"}
                        value={certSigningAuthority1Designation}
                        className={
                          certSigningAuthority1_authorityDesignationError
                            ? classes.fieldErrorCss
                            : classes.fieldCss
                        }
                        handleChange={(prop) => (e) => {
                          this.setState({
                            certSigningAuthority1Designation: e.target.value,
                            certSigningAuthority1_authorityDesignationError: false,
                          });
                        }}
                        readOnly={isPastEvent}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.typographyCss}>
                        {" "}
                        <span className={classes.labelCss}>
                          Signature Image
                        </span>
                        <span className={classes.mandatoryCss}>*</span>
                      </Typography>{" "}
                      {certificateSign_1.imageURL ? (
                        <Grid>
                          <div className={classes.smallImgContainer}>
                            {!isPastEvent && (
                              <div className={classes.clearImgContainer}>
                                <ClearIcon
                                  style={{ color: "red", fontSize: "20px" }}
                                  onClick={() =>
                                    this.handleImageRemove("CER_SIGN_1")
                                  }
                                />
                              </div>
                            )}{" "}
                            <div className={classes.smallProfileImgContainer}>
                              <img
                                className={classes.signImgCss}
                                src={certificateSign_1.imageURL}
                                alt={certificateSign_1.label}
                                onClick={() => {
                                  this.setState({
                                    dialogTitle: "Signature",
                                    showImage: true,
                                    imagePath: certificateSign_1.imageURL,
                                    dialogTitle: certificateSign_1.label,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        <Grid>
                          <FileUploader
                            selectedRow={certificateSign_1}
                            maxFiles={1}
                            multiple={false}
                            filesUploaded={
                              (e) => this.handleUploadedFiles(e) //(e, data);
                            }
                            isSmall={true}
                            isReadOnly={isPastEvent}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {/* sign2 */}
                  <Grid
                    container
                    style={{
                      margin: "10px 5px",
                    }}
                  >
                    <Grid item xs={4} className={classes.innerGridCss}>
                      <Typography variant="body2" gutterBottom>
                        <span className={classes.labelCss}>
                          {Properties.signAuthorityNameLabel}
                        </span>
                        {/* <span className={classes.mandatoryCss}>*</span> */}
                      </Typography>
                      <CustomTextField
                        type={"text"}
                        value={certSigningAuthority2Name}
                        className={
                          certSigningAuthority2_authorityNameError
                            ? classes.fieldErrorCss
                            : classes.fieldCss
                        }
                        handleChange={(prop) => (e) => {
                          this.setState({
                            certSigningAuthority2Name: e.target.value,
                            certSigningAuthority2_authorityNameError: false,
                          });
                        }}
                        readOnly={isPastEvent}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.innerGridCss}>
                      <Typography variant="body2" gutterBottom>
                        <span className={classes.labelCss}>
                          {Properties.signAuthorityDesignationLabel}
                        </span>
                        {/* <span className={classes.mandatoryCss}>*</span> */}
                      </Typography>
                      <CustomTextField
                        type={"text"}
                        value={certSigningAuthority2Designation}
                        className={
                          certSigningAuthority2_authorityDesignationError
                            ? classes.fieldErrorCss
                            : classes.fieldCss
                        }
                        handleChange={(prop) => (e) => {
                          this.setState({
                            certSigningAuthority2Designation: e.target.value,
                            certSigningAuthority2_authorityDesignationError: false,
                          });
                        }}
                        readOnly={isPastEvent}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.typographyCss}>
                        {" "}
                        <span className={classes.labelCss}>
                          Signature Image
                        </span>
                        {/* <span className={classes.mandatoryCss}>*</span> */}
                      </Typography>{" "}
                      {certificateSign_2.imageURL ? (
                        <Grid>
                          <div className={classes.smallImgContainer}>
                            {!isPastEvent && (
                              <div className={classes.clearImgContainer}>
                                <ClearIcon
                                  style={{ color: "red" }}
                                  onClick={() =>
                                    this.handleImageRemove("CER_SIGN_2")
                                  }
                                />
                              </div>
                            )}
                            <div className={classes.smallProfileImgContainer}>
                              <img
                                className={classes.signImgCss}
                                src={certificateSign_2.imageURL}
                                alt={certificateSign_2.label}
                                onClick={() => {
                                  this.setState({
                                    dialogTitle: "Signature",
                                    showImage: true,
                                    imagePath: certificateSign_2.imageURL,
                                    dialogTitle: certificateSign_2.label,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        <Grid>
                          <FileUploader
                            selectedRow={certificateSign_2}
                            maxFiles={1}
                            multiple={false}
                            filesUploaded={
                              (e) => this.handleUploadedFiles(e) //(e, data);
                            }
                            isSmall={true}
                            isReadOnly={isPastEvent}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {/* logo */}
                  <Grid
                    container
                    style={{
                      margin: "10px 5px",
                    }}
                  >
                    <Grid item xs={6} className={classes.fileGridCss}>
                      <Typography className={classes.typographyCss}>
                        <span className={classes.labelCss}>
                          Certificate Logo
                        </span>
                        <span className={classes.mandatoryCss}>*</span>
                      </Typography>
                      {certificateLogo?.imageURL ? (
                        <Grid>
                          <div className={classes.imgContainer}>
                            {!isPastEvent && (
                              <div className={classes.clearImgContainer}>
                                <ClearIcon
                                  style={{ color: "red" }}
                                  onClick={() =>
                                    this.handleImageRemove("CER_ORG_LOGO_1")
                                  }
                                />
                              </div>
                            )}
                            <div className={classes.profileImgContainer}>
                              <img
                                className={classes.profileImg}
                                src={certificateLogo?.imageURL}
                                alt={certificateLogo?.label}
                                onClick={() => {
                                  this.setState({
                                    dialogTitle: "Logo",
                                    showImage: true,
                                    imagePath: certificateLogo?.imageURL,
                                    dialogTitle: certificateLogo?.label,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        <Grid>
                          <FileUploader
                            selectedRow={certificateLogo}
                            maxFiles={1}
                            multiple={false}
                            filesUploaded={
                              (e) => this.handleUploadedFiles(e) //(e, data);
                            }
                            isReadOnly={isPastEvent}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {/* activity specific */}
                  {activityType &&
                    activityType?.map((activity, activityIndex) => (
                      <div
                        style={{
                          margin: "10px 0px",
                          padding: "10px",
                          border: "1px solid gray",
                        }}
                        key={activityIndex}
                      >
                        <Typography variant="body2" gutterBottom>
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "600",
                              color: "#727272",
                            }}
                          >
                            {/* {activity?.displayName} */}
                            {ApplicationUtil.getCamelCasing(
                              activity?.value
                            )}{" "}
                          </span>
                        </Typography>

                        <Grid container>
                          {/* templates */}
                          {activity?.templateList?.map(
                            (template, templateIndex) => (
                              <Grid
                                item
                                xs={4}
                                key={templateIndex}
                                spacing={3}
                                style={{ padding: "0px 4px" }}
                              >
                                <Card className={classes.templateCardCss}>
                                  <CardHeader
                                    subheader={
                                      // ApplicationUtil.getCamelCasing(
                                      template?.templateName
                                      // )
                                    }
                                    className={classes.labelCss}
                                    style={{ marginBottom: "4px" }}
                                  />
                                  <CardActionArea>
                                    <StyledCardMedia
                                      component="img"
                                      alt={template?.templateName}
                                      height="200"
                                      image={
                                        "data:image/png;base64," +
                                        template?.templateImageDto?.imageData
                                      }
                                      onClick={() => {
                                        this.setState({
                                          templateDialogTitle:
                                            template?.templateName,
                                          showTemplate: true,
                                          templateMessage: ReactHtmlParser(
                                            template?.templateHtmlContent
                                          ),
                                        });
                                      }}
                                    />
                                  </CardActionArea>
                                  <CardActions>
                                    <FormControl error={true} required>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            onChange={(e) => {
                                              this.handleTemplateSelection(
                                                e,
                                                activity,
                                                template?.id,
                                                template?.emailInlineImages
                                              );
                                            }}
                                            color="primary"
                                            value={
                                              template?.id ==
                                              activity?.selectedTemplate
                                                ? true
                                                : false
                                            }
                                            checked={
                                              template?.id ==
                                              activity?.selectedTemplate
                                                ? true
                                                : false
                                            }
                                          />
                                        }
                                      />
                                    </FormControl>
                                  </CardActions>
                                </Card>
                              </Grid>
                            )
                          )}

                          {/* uploads */}
                          <Grid container style={{ padding: "8px 0px" }}>
                            <Grid item xs={6} className={classes.fileGridCss}>
                              <Typography variant="body2" gutterBottom>
                                <span
                                  className={classes.labelCss}
                                  style={{ fontSize: "20px" }}
                                >
                                  Custom Theme
                                </span>
                              </Typography>

                              {activity?.certificateBackground?.imageURL ? (
                                // ||
                                // activity?.selectedTemplate?.emailInlineImages[0]
                                <div
                                  className={classes.imgContainer}
                                  style={{ marginTop: "4px" }}
                                >
                                  {!isPastEvent && (
                                    <div className={classes.clearImgContainer}>
                                      <ClearIcon
                                        style={{ color: "red" }}
                                        onClick={() =>
                                          this.handleImageRemove(
                                            activity?.certificateBackground
                                              ?.keyName
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                  <div className={classes.profileImgContainer}>
                                    <img
                                      className={classes.profileImg}
                                      src={
                                        activity?.certificateBackground
                                          ?.imageURL
                                      }
                                      alt={
                                        activity?.certificateBackground?.label
                                      }
                                      onClick={() => {
                                        this.setState({
                                          dialogTitle:
                                            activity?.certificateBackground
                                              ?.label,
                                          showImage: true,
                                          imagePath:
                                            activity?.certificateBackground
                                              ?.imageURL,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <Grid>
                                  <FileUploader
                                    selectedRow={
                                      activity?.certificateBackground
                                    }
                                    maxFiles={1}
                                    multiple={false}
                                    filesUploaded={(e) => {
                                      this.handleUploadedFiles(e);
                                      let newActivityType = activityType;
                                      if (
                                        activity?.certificateBackground
                                          ?.keyName === "CER_BACKGROUND"
                                      ) {
                                        let item = newActivityType.findIndex(
                                          (obj) => obj?.type === "RUN"
                                        );
                                        newActivityType[
                                          item
                                        ].customeTheme = true;
                                      } else if (
                                        activity?.certificateBackground
                                          ?.keyName === "CYCLING_CER_BGD"
                                      ) {
                                        let item = newActivityType.findIndex(
                                          (obj) => obj?.type === "CYCLE"
                                        );
                                        newActivityType[
                                          item
                                        ].customeTheme = true;
                                      } else if (
                                        activity?.certificateBackground
                                          ?.keyName === "STEP_CER_BGD"
                                      ) {
                                        let item = newActivityType.findIndex(
                                          (obj) => obj?.type === "STEPS"
                                        );
                                        newActivityType[
                                          item
                                        ].customeTheme = true;
                                      }
                                    }}
                                    isReadOnly={isPastEvent}
                                  />
                                </Grid>
                              )}
                            </Grid>

                            {/* flags */}
                            <Grid
                              item
                              xs={6}
                              container
                              style={{ padding: "5px 0px" }}
                            >
                              <span
                                className={classes.labelCss}
                                style={{ fontSize: "20px" }}
                              >
                                Settings
                              </span>
                              <div
                                style={{
                                  // marginTop: "-50px",
                                  margin: "20px 0px 0px -60px",
                                  display: "grid",
                                  gridTemplateColumns: "140px 140px ",
                                  gridTemplateRows: "40px 40px",
                                }}
                              >
                                {activity?.flagInputs?.map(
                                  (flag, flagIndex) =>
                                    flag?.show && (
                                      <div
                                        item
                                        xs={6}
                                        key={flagIndex}
                                        // className={classes.innerGridCss}
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              onChange={(e) =>
                                                this.handleFlagChange(
                                                  e,
                                                  activity,
                                                  flag
                                                )
                                              }
                                              color="primary"
                                              value={flag?.value}
                                              checked={flag?.value}
                                            />
                                          }
                                        />
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            color: "#727272",
                                          }}
                                        >
                                          {flag?.label}
                                        </span>
                                      </div>
                                    )
                                )}
                              </div>
                            </Grid>

                            {/* flags */}
                            {/* <Grid
                              item
                              xs={6}
                              container
                              style={{ padding: "5px 0px" }}
                            >
                              <span className={classes.labelCss}>Settings</span>
                              <Grid
                                container
                                // style={{ marginTop: "-90px" }}
                              >
                                {activity?.flagInputs?.map(
                                  (flag, flagIndex) =>
                                    flag &&
                                    flag.show && (
                                      <Grid
                                        item
                                        xs={6}
                                        key={flagIndex}
                                        // className={classes.innerGridCss}
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              onChange={(e) =>
                                                this.handleFlagChange(
                                                  e,
                                                  activity,
                                                  flag
                                                )
                                              }
                                              color="primary"
                                              value={flag?.value}
                                              checked={flag?.value}
                                            />
                                          }
                                        />
                                        <span className={classes.labelCss}>
                                          {flag?.label}
                                        </span>
                                      </Grid>
                                    )
                                )}
                              </Grid>
                            </Grid>
                           */}
                          </Grid>

                          <Grid
                            container
                            style={{
                              margin: "10px 5px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Grid
                              item
                              xs={4}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <SubmitCancelBtn
                                onClick={(e) => this.validateFields(activity)}
                                label={"Preview"}
                                type="submit"
                                style={{ width: "200px" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                </div>
              )}

              <Grid
                container
                style={{
                  margin: "10px 5px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <SubmitCancelBtn
                    onClick={(e) => this.saveAllDetails(row)}
                    label={"Save All"}
                    type="submit"
                    style={{ width: "200px" }}
                    // isDisabled={!this.state.showSaveAll}
                  />
                </Grid>
              </Grid>
            </div>
          ))}
        <ImageDialogBox
          title={this.state.dialogTitle}
          open={this.state.showImage}
          imagePath={this.state.imagePath}
          onClick={() => this.closeWindow()}
          closeDisloag={() => this.closeWindow()}
        />

        {/* certificate preview */}
        <DialogBox
          fullWidth
          maxWidth={"lg"}
          classes={{
            paperScrollPaper: {
              maxHeight: "97%",
            },
          }}
          open={this.state.showCertificatePreview}
          header={this.state.certificatePreviewDialog}
          message={this.getCertificatePreviewContent()} //{this.state.templateMessage}
          onClick={() => this.closeCertificatePreviewWindow()}
          closeDisloag={() => this.closeCertificatePreviewWindow()}
        />

        {/* template preview */}
        <PopupView
          popupOpen={this.state.showTemplate}
          popupMsg={this.state.templateMessage}
          popupHeader={this.state.templateDialogTitle}
          popupOnClick={() => this.closeTemplateWindow()}
          popupClose={() => this.closeTemplateWindow()}
        />
        {/*
        <Popover
          // classes={classes}
          open={this.state.showTemplate}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClick={() => this.closeTemplateWindow()}
          closeDisloag={() => this.closeTemplateWindow()}
          PaperProps={{
            style: { height: "97%", maxHeight: "97%" },
          }}
        >
          <PopupView
            popupMsg={this.state.templateMessage}
            popupHeader={this.state.templateDialogTitle}
            popupOnClick={() => this.closeTemplateWindow()}
            popupClose={() => this.closeTemplateWindow()}
            // for list
            templateList={this.state.previewTemplateList}
          />
        </Popover>*/}
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(DeliverableTabView);
