import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import Properties from "../../../../../properties/Properties";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import SubmitCancelBtn from "../../../../common/SubmitCancelBtn";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import ReactPlayer from "react-player";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth={"md"}
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ position: "relative", height: "60%" }}>
        <ReactPlayer width="100%" height="100%" controls url={props.url} />
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo({ url,showIcon }) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      {showIcon ? <PlayCircleOutlineIcon  onClick={handleClickOpen} style={{ color: "#023e8a",cursor:"pointer"}}/>: <SubmitCancelBtn
        type="submit"
        label={"Play Video"}
        onClick={handleClickOpen}
        endIcon={<PlayCircleOutlineIcon />}
      />}

      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        url={url}
      />
    </div>
  );
}
