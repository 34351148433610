import React, { Component } from "react";
import {
  downloadResultCSV,
  generateDeliverables,
  getEventDetails,
} from "../../../../../apis/eventService";
import OrganizerTableList from "./../OrganizerTableList";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import {
  Typography,
  FormControl,
  NativeSelect,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ListPageLoader from "./../../../../common/ListPageLoader";
import { CSVLink, CSVDownload } from "react-csv";
import Papa from "papaparse";
import SubmitCancelBtn from "../../../../common/SubmitCancelBtn";
import ApplicationConstants from "../../../../../constants/ApplicationConstants";
import Snackbar from "../../../../common/Snackbar";
import { getChallengeExecutionList } from "../../../../../apis/challengeService";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import { withRouter } from "react-router-dom";
const styles = (theme) => ({
  mainBody: {
    marginTop: "0px",
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    fontFamily: "Rubik, sans-serif",
  },
  select: {
    marginRight: theme.spacing(1),
  },
  selectActivity: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  searchFilter__row: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    position: "relative",
    bottom: 20,
    "& .search__select": {
      display: "flex",
      alignItems: "flex-end",
    },
    "& .search ": {
      display: "flex",
      alignItems: "center",
      border: "1px solid #bfc0c0",
      borderRadius: "5px",
      height: "35px",
      "& > input": {
        height: "30px",
        outline: "none",
        border: "none",
        fontSize: "16px",
        paddingLeft: "10px",
        borderRight: "2px solid #bfc0c0",
      },
      "& .search__icon": {
        padding: "5px",
        cursor: "pointer",
      },
    },
    "& .filters": {
      display: "flex",
      alignItems: "flex-end",
      fontFamily: "Rubik,sans-serif",
    },
  },
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",

    position: "relative",
    bottom: 20,
    marginRight: theme.spacing(8),
  },
  totalRec: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    fontWeight: 600,
    width: "15%",
    marginRight: "10px",
    background: "rgba( 149, 213, 178, 0.50 )",
    boxShadow: "0 4px 3px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    // position: "relative",
    // bottom: 20,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  editIcon: {
    color: "#09456C",
    cursor: "pointer",
  },
});

class ChallengeExecutionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      participantsList: null,

      validInvalid: "All",
      activityReceived: "All",
      categoryValue: "",
      activityType: "",
      activityOptions: [],
      activityValue: "",
      eventActivityType: "",
      certificateGenerated: false,
      activityCollageGenerated: false,
      videoGenerated: false,
      searchSelectValue: "Name",
      searchValue: "",
      totalRecords: null,
      deliverableOptions: ApplicationConstants.deliverableTypes,
      anchorEl: null,
      checkboxArr: [],
    };
  }
  componentDidMount() {
    let requestParam = {
      pageCount: 20,
      pageNumber: 0,
      activityReceived: true,
      isValid: null,
    };
    getChallengeExecutionList(requestParam)
      .then((res) => {
        if (res.data) {
          let isSteps = false;
          let type = localStorage.getItem("supportOpenChallengeType");
          let data = ApplicationUtil.getSupportDetails();
          this.setState({
            participantsList: res.data.data,
            totalRecords: res.data.totalRecords,
            displayColumns: res?.data?.displayColumns.split(","),
            isSteps: type,
            activityReceived: "Activity Received",
            isSupport: data.role === "SUPPORT" ? true : false,
          });
        }
      })
      .catch((error) => {
        this.setState({ totalRecords: 0, paramObj: requestParam });
      });
  }

  selectHandleValue = (event) => {
    this.setState({ searchSelectValue: event.target.value });
  };
  searchHandleChange = (event) => {
    this.setState({ searchValue: event.target.value });
  };
  searchHandleClick = () => {
    const {
      searchSelectValue,
      searchValue,
      validInvalid,
      activityReceived,
      activityType,
      categoryValue,
    } = this.state;

    let name;
    let bib;
    let email;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    }
    let valid =
      validInvalid === "Valid"
        ? true
        : validInvalid === "Invalid"
        ? false
        : null;
    let received =
      activityReceived === "Activity Received"
        ? true
        : activityReceived === "Activity Not Received"
        ? false
        : null;
    let activityId =
      activityType === "8"
        ? ""
        : activityType === "9"
        ? ""
        : activityType === "10"
        ? ""
        : activityType;
    let requestParams = {
      name: name,
      bib: bib,
      email: email,
      activityReceived: received,
      isValid: valid,
      activityTypeId: activityId,
      categoryId: categoryValue,
      pageNumber: 0,
      pageCount: 20,
    };

    if (
      requestParams.name !== "" ||
      requestParams.email !== "" ||
      requestParams.bib !== ""
    ) {
      getChallengeExecutionList(requestParams).then((res) => {
        if (res.data) {
          this.setState({
            participantsList: res.data.data,
            totalRecords: res.data.totalRecords,
          });
        }
      });
      this.setState({ paramObj: requestParams });
    }
  };

  activityReceivedHandleChange = (event) => {
    const {
      activityType,
      validInvalid,
      certificateGenerated,
      activityCollageGenerated,
      videoGenerated,
      searchSelectValue,
      searchValue,
      categoryValue,
    } = this.state;
    let val = event.target.value;
    this.setState({ activityReceived: event.target.value });
    let activityReceived;
    if (val === "Activity Received") {
      activityReceived = true;
    } else if (val === "Activity Not Received") {
      activityReceived = false;
    } else {
      activityReceived = null;
    }
    let activityId =
      activityType === "8"
        ? ""
        : activityType === "9"
        ? ""
        : activityType === "10"
        ? ""
        : activityType;
    let valid =
      validInvalid === "Valid"
        ? true
        : validInvalid === "Invalid"
        ? false
        : null;
    let name;
    let bib;
    let email;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    }
    let requestParams = {
      activityReceived: activityReceived,
      activityTypeId: activityId,
      isValid: valid,
      certificateGenerated: certificateGenerated,
      activityCollageGenerated: activityCollageGenerated,
      videoGenerated: videoGenerated,
      name: name,
      bib: bib,
      email: email,
      categoryId: categoryValue,
      pageNumber: 0,
      pageCount: 20,
    };
    getChallengeExecutionList(requestParams)
      .then((res) => {
        if (res.data) {
          this.setState({
            participantsList: res.data.data,
            totalRecords: res.data.totalRecords,
          });
        }
      })
      .catch((error) => {
        this.setState({ totalRecords: 0 });
      });
    this.setState({ paramObj: requestParams });
  };
  validHandleChange = (event) => {
    const {
      activityType,
      activityReceived,
      certificateGenerated,
      activityCollageGenerated,
      videoGenerated,
      searchSelectValue,
      searchValue,
      categoryValue,
    } = this.state;
    let val = event.target.value;
    this.setState({ validInvalid: event.target.value });
    let valid;
    if (val === "Valid") {
      valid = true;
    } else if (val === "Invalid") {
      valid = false;
    } else {
      valid = null;
    }

    let activityId =
      activityType === "8"
        ? ""
        : activityType === "9"
        ? ""
        : activityType === "10"
        ? ""
        : activityType;
    let received =
      activityReceived === "Activity Received"
        ? true
        : activityReceived === "Activity Not Received"
        ? false
        : null;
    let name;
    let bib;
    let email;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    }
    let requestParams = {
      activityReceived: received,
      activityTypeId: activityId,
      isValid: valid,
      certificateGenerated: certificateGenerated,
      activityCollageGenerated: activityCollageGenerated,
      videoGenerated: videoGenerated,
      name: name,
      bib: bib,
      email: email,
      categoryId: categoryValue,
      pageNumber: 0,
      pageCount: 20,
    };

    getChallengeExecutionList(requestParams)
      .then((res) => {
        if (res.data) {
          this.setState({
            participantsList: res?.data?.data,
            totalRecords: res?.data?.totalRecords,
            // displayColumns: res?.data?.displayColumns,
          });
        }
      })
      .catch((error) => {
        this.setState({ totalRecords: 0 });
      });
    this.setState({ paramObj: requestParams });
  };
  getList = (isFirstPage, page) => {
    const {
      activityType,
      activityReceived,
      certificateGenerated,
      activityCollageGenerated,
      videoGenerated,
      searchSelectValue,
      searchValue,
      validInvalid,
      categoryValue,
    } = this.state;
    let activityId =
      activityType === "8"
        ? ""
        : activityType === "9"
        ? ""
        : activityType === "10"
        ? ""
        : activityType;
    let received =
      activityReceived === "Activity Received"
        ? true
        : activityReceived === "Activity Not Received"
        ? false
        : null;
    let valid =
      validInvalid === "Valid"
        ? true
        : validInvalid === "Invalid"
        ? false
        : null;
    let name;
    let bib;
    let email;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    }

    let requestParams = {
      pageNumber: page ? page : 0,
      pageCount: 20,
      activityReceived: received,
      activityTypeId: activityId,
      isValid: valid,
      certificateGenerated: certificateGenerated,
      activityCollageGenerated: activityCollageGenerated,
      videoGenerated: videoGenerated,
      name: name,
      bib: bib,
      email: email,
      categoryId: categoryValue,
    };
    getChallengeExecutionList(requestParams)
      .then((res) => {
        if (res.data) {
          let newData = page
            ? this.state.participantsList.concat(res.data.data)
            : res.data.data;
          this.setState({
            participantsList:
              newData.indexOf(undefined) > -1
                ? newData.filter((item) => item !== undefined)
                : newData,
            totalRecords: res.data.totalRecords,
          });
        }
      })
      .catch((error) => {
        this.setState({ totalRecords: 0 });
      });
    this.setState({ paramObj: requestParams });
  };
  getDeliverable = (value) => {
    const { checkboxArr, isAllChecked } = this.state;
    let type = value;

    let arr = [];
    checkboxArr.map((check) => {
      if (check.check) {
        arr.push(check.id);
      }
    });
    if (arr.length > 0 || isAllChecked) {
      let requestParam = {
        runnerIds: isAllChecked ? "" : arr,
        regenerate: true,
      };

      generateDeliverables(type, requestParam);
    } else {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select users",
      });
    }
    this.handleClose();
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  downloadCSVFile = () => {
    const { paramObj } = this.state;
    downloadResultCSV(paramObj);
  };
  render() {
    const {
      participantsList,
      activityReceived,
      validInvalid,
      activityType,
      activityOptions,
      eventActivityType,
      searchSelectValue,
      searchValue,
      totalRecords,
      displayColumns,
      csvData,
      categoryOptions,
      categoryValue,
      deliverableOptions,
      anchorEl,
      isSteps,
      isSupport,
    } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.mainBody}>
        <div className={classes.top}>
          {totalRecords !== null && (
            <div className={classes.totalRec}>
              <span>
                Participants
                <span style={{ marginLeft: "10px" }}>{totalRecords}</span>
              </span>
            </div>
          )}
          {/* <div>
            {totalRecords > 0 && (
              <SubmitCancelBtn
                label="Export"
                type="submit"
                onClick={this.downloadCSVFile}
              />
            )}
          </div> */}
        </div>
        <div className={classes.searchFilter__row}>
          <div className="search__select">
            <FormControl className={classes.select}>
              <NativeSelect
                className={classes.selectActivity}
                value={searchSelectValue}
                onChange={this.selectHandleValue}
                style={{ width: 100 }}
              >
                {["Name", "BIB", "Email"]?.map((value, index) => (
                  <option value={value}>{value}</option>
                ))}
              </NativeSelect>
            </FormControl>
            <form
              className="search"
              onSubmit={(e) => {
                e.preventDefault();
                this.searchHandleClick();
              }}
            >
              <input
                type="text"
                placeholder="Search..."
                value={searchValue}
                onChange={this.searchHandleChange}
              />
              <SearchIcon
                className="search__icon"
                onClick={this.searchHandleClick}
              />
            </form>
          </div>
          <div className="filters">
            <FormControl className={classes.formControl}>
              <NativeSelect
                className={classes.selectActivity}
                value={activityReceived}
                onChange={this.activityReceivedHandleChange}
                style={{ width: 150 }}
              >
                <option value="">All</option>
                {["Activity Received", "Activity Not Received"]?.map(
                  (value, index) => (
                    <option value={value}>{value}</option>
                  )
                )}
              </NativeSelect>
            </FormControl>
            <FormControl className={classes.formControl}>
              <NativeSelect
                className={classes.selectActivity}
                value={validInvalid}
                onChange={this.validHandleChange}
                style={{ width: 80 }}
              >
                <option value="">All </option>
                {["Valid", "Invalid"]?.map((value, index) => (
                  <option value={value}>{value}</option>
                ))}
              </NativeSelect>
            </FormControl>
            {isSupport &&<FormControl className={classes.formControl}>
              <div className={classes.actions}>
                <span>Actions</span>
                <span>
                  <ArrowDropDownCircleOutlinedIcon
                    className={classes.editIcon}
                    onClick={(event) => {
                      this.setState({
                        anchorEl: event.currentTarget,
                      });
                    }}
                  />
                </span>
              </div>
            </FormControl>}
          </div>
        </div>
        <ListPageLoader
          pageParams={{
            page: 0,
            start: 0,
            limit: 8,
            TotalRecords: this.state.totalRecords,
          }}
          totalLoadedLength={participantsList ? participantsList.length : 20}
          loadNextData={(params) => {
            if (!participantsList.includes(undefined)) {
              this.getList(false, params.page);
            }
          }}
        >
          {participantsList?.length > 0 ? (
            <OrganizerTableList
              data={participantsList}
              isSupport={isSupport}
              isChallenge
              startsFrom
              endsOn
              isSteps={isSteps}
              minimumDistance
              totalTarget
              displayColumns={displayColumns}
              bib
              distance
              completionTime
              screenshot
              url
              validInvalid
              cert
              collage
              video
              resultAction
              eventType={eventActivityType}
              approved
              updateCheckboxArr={(arr, isAllChecked) => {
                this.setState({
                  checkboxArr: arr,
                  isAllChecked: isAllChecked,
                });
                let array = [];
                arr.map((check) => {
                  if (check.check) {
                    array.push(check.id);
                  }
                });
                if (array.length > 0) {
                  let requestParam = {
                    runnerIds: isAllChecked ? "" : array,
                    activityReceived: null,
                    isValid: null,
                  };
                  this.setState({ paramObj: requestParam });
                }
              }}
            />
          ) : (
            <p>
              {totalRecords === null
                ? "Loading participants..."
                : totalRecords === 0
                ? "No Results"
                : ""}
            </p>
          )}
        </ListPageLoader>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          // className="header__user--menu"
          style={{ marginTop: "40px" }}
        >
          {deliverableOptions?.map((value, index) => (
            <MenuItem onClick={() => this.getDeliverable(value.value)}>
              {value.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }),withRouter)(
  ChallengeExecutionList
);
