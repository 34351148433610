import * as CryptoJS from "crypto-js";
class LoginUtil {
  constructor() {
    if (!this.instance) {
      this.instance = this;
    }
    return this.instance;
  }
  static encryptPassword = function(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value.toString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
    );

    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  };
}

export default LoginUtil;
