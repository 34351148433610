import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import moment from "moment";
import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import ApplicationUtil from "../../../../utils/ApplicationUtil";

function SingleSelectQue({
  quizResponse,
  setQuizResponse,
  classes,
  history,
  options,
  response,
  question,
}) {

  
  return (
    <FormControl>
      <FormGroup
        onChange={(e) => {
          if (e.target.checked) {
            setQuizResponse(e.target.value);
          }
        }}
      >
        {options?.map((item) => (
          <FormControlLabel
            disabled={ApplicationUtil.handleFieldDisable(question, response)}
            checked={quizResponse === item}
            control={<Checkbox color="#09456C" />}
            label={item}
            value={item}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

const styles = (theme) => ({});

export default compose(withStyles(styles), withRouter)(SingleSelectQue);
