import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import parse from "html-react-parser";
import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import httpService from "../../../../../../../apis/httpService";
const API_URL_FILE = httpService.API_URL();

const TipsTable = ({ classes, list, onTipEditClick = () => {} }) => {
  return (
    <div className="formWrapper">
      <div>
        <div>
          <h3 className={classes.title} style={{ marginBottom: "8px" }}>
            All Tips:
          </h3>
          <div
            style={{
              maxHeight: "300px",

              overflowY: "auto",
              // position: "relative",
            }}
          >
            <Table stickyHeader style={{ minHeight: "300px" }}>
              <TableHead>
                <TableRow className={classes.tableHead}>
                  <TableCell component="th" textAlign="center">
                    Sr.No
                  </TableCell>
                  <TableCell component="th" textAlign="center">
                    Tip
                  </TableCell>
                  <TableCell component="th" textAlign="center">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                  position: "relative",
                }}
              >
                {list &&
                  list?.length > 0 &&
                  list?.map((tip, ind) => {
                    return (
                      <TableRow key={ind + 1}>
                        <TableCell
                          textAlign="center"
                          style={{ textAlign: "center" }}
                        >
                          {ind + 1}
                        </TableCell>
                        <TableCell
                          component="td"
                          textAlign="center"
                          style={{ verticalAlign: "baseline" }}
                        >
                          {parse(`${tip?.tip || ""}`)}
                        </TableCell>
                        <TableCell
                          component="td"
                          textAlign="center"
                          style={{ textAlign: "center" }}
                        >
                          <span
                            style={{ cursor: "pointer", color: "#3a6ea5" }}
                            onClick={() => onTipEditClick(tip)}
                          >
                            Edit
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  title: {
    color: "rgb(8, 69, 108)",
  },
  tableHead: {
    backgroundColor: "#ced4da !important",
    "& th:first-child": {
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
      width: "30px",
    },
    "& th": {
      backgroundColor: "#ced4da",
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
      width: "100px",
    },
  },
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(TipsTable);
