import React, { Component } from "react";
import { connect } from "react-redux";
import { updateSupportUserDetails } from "../../../redux/supportUser";

import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { CustomTextField } from "../../common/CustomTextField";
import { validateEmail, validatePassword } from "./../../../utils/Validator";
import Grid from "@material-ui/core/Grid";
import Properties from "./../../../properties/Properties";
import { Typography } from "@material-ui/core";
import SubmitCancelBtn from "../../common/SubmitCancelBtn";
import logo from "../login/ndLogo.png";
//import logo from '../../../nd.png';
import { loginCheck } from "./../../../apis/loginService";
import DialogBox from "./../../common/DialogBox";
import LoginUtil from "../login/LoginUtil";
import ApplicationUtil from "../../../utils/ApplicationUtil";
import Snackbar from "./../../common/Snackbar";
import { withRouter } from "react-router-dom";
import getUserDetails from "../../../apis/userService";

const styles = (theme) => ({
  mainPanel: {
    paddingRight: theme.rightSpacing,
    paddingLeft: theme.leftSpacing,
  },
});
class SupportLoginScreen extends React.Component {
  state = {
    emailError: false,
    passwordError: false,
    open: false,
    dialogHeader: "",
    dialogMsg: "",
  };
  validateEmailId = (value) => {
    let isValidEmail = validateEmail(value);
    if (isValidEmail) {
      this.setState({ email: value, emailError: false });
    } else {
      this.setState({ emailError: true, email: value });
    }
  };

  validatePasswordField = (key, value) => {
    let isValid = validatePassword(value);
    if (key == "password") {
      if (isValid) {
        this.setState({ password: value, passwordError: false });
      } else {
        this.setState({ passwordError: true, password: value });
      }
    } else {
      if (isValid && value == this.state.password) {
        this.setState({ confirmPassword: value, confirmError: false });
      } else {
        this.setState({ confirmError: true });
      }
    }
  };

  validateFields = () => {
    const {
      email = "",
      password = "",
      emailError = "",
      passwordError = "",
    } = this.state;
    if (email == "" || emailError == true) {
      this.setState({ emailError: true });
    } else if (password == "" || passwordError == true) {
      this.setState({ passwordError: true });
    } else {
      this.saveFields();
    }
  };

  navigationForgotPass = () => {
    return this.props.history.push("/forgotpass");
  };

  navigationSignUp = () => {
    return this.props.history.push("/signup");
  };

  saveFields = () => {
    const { email, password } = this.state;
    const { role } = this.props;
    let time = new Date().getTime();
    time = "123" + time;
    let requestParams = {
      userName: email,
      password: LoginUtil.encryptPassword(time, password),
      // role: "SUPPORT",
    };

    //api
    loginCheck(requestParams, time).then((res) => {
      let profileData = res.data;
      let userId = res.data.id;
      let token = res.data.token;
      // var eventId = 40;
      const getData = async () => {
        await getUserDetails(token, userId).then((res) => {
          profileData.profilePicture = res.data?.profilePicture?.imageData;
          this.props.updateSupportUserDetails(profileData);
        });
      };

      // localStorage.setItem("supportData", JSON.stringify(data));
      localStorage.setItem(
        "supportRunnerId",
        JSON.stringify(res.data.runnerId)
      );
      // localStorage.setItem("supportUserId", JSON.stringify(userId));
      // localStorage.setItem("supportEventId", JSON.stringify(eventId));
      // localStorage.setItem("token", token);
      // localStorage.setItem("supportToken", token);
      // localStorage.setItem(
      //   "supportUserName",
      //   res.data.firstName + " " + res.data.lastName
      // );
      getData().then(() => {
        let panelUrl = ApplicationUtil.getMatchUrl();
        if (role === "support" && res.data.role === "ORGANIZER_ADMIN") {
          ApplicationUtil.go("/organizer");

          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: "you're not authorized to login by support page",
          });
        } else {
          if (res.data.role === "ORGANIZER_ADMIN" && res.data?.isProgramAdmin) {
            localStorage.setItem("headerLinkSelected", "Program");
          }
          // Defect_id_512- laxman
          localStorage.setItem("role", res.data.role);
          ApplicationUtil.go(panelUrl + "/eventlist");
        }
      });
    });
  };

  render() {
    const { classes } = this.props;
    const { emailError, passwordError } = this.state;
    return (
      <React.Fragment>
        <div style={{ width: "100%", position: "absolute" }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.validateFields();
            }}
          >
            <Grid
              style={{
                width: "100%",
                height: "100px",
                marginTop: "10px",
                paddingTop: "5%",
                justifyContent: "center",
                margin: "0px",
                textAlign: "center",
                paddingBottom: "10%",
              }}
              container
              spacing={1}
            >
              <div>
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    height: "70px",
                    width: "70px",
                  }}
                />
              </div>
            </Grid>

            <Grid
              style={{
                width: "100%",
                height: "75px",
                paddingTop: "10px",
                margin: "0px",
                padding: "10px",
              }}
              container
              spacing={1}
            >
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    {Properties.Label_Email}
                  </span>
                </Typography>{" "}
                <CustomTextField
                  style={
                    emailError
                      ? {
                          border: "1px solid red",
                          height: "45%",
                          maxHeight: "45%",
                          minHeight: "45%",
                          width: "100%",
                        }
                      : {
                          border: "1px solid #08456C",
                          height: "45%",
                          maxHeight: "45%",
                          minHeight: "45%",
                          width: "100%",
                        }
                  }
                  type={"email"}
                  keyToSet={"email"}
                  placeholder={"email"}
                  handleChange={(prop) => (event) => {
                    this.validateEmailId(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              style={{
                width: "100%",
                height: "75px",
                margin: "0px",
                padding: "10px",
              }}
              container
              spacing={1}
            >
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    {Properties.Label_Password}
                  </span>
                </Typography>{" "}
                <CustomTextField
                  style={
                    passwordError
                      ? {
                          border: "1px solid red",
                          height: "45%",
                          minHeight: "45%",
                          maxHeight: "45%",
                          width: "100%",
                        }
                      : {
                          border: "1px solid #08456C",
                          height: "45%",
                          width: "100%",
                          minHeight: "45%",
                          maxHeight: "45%",
                        }
                  }
                  type={"password"}
                  keyToSet={"password"}
                  placeholder={"password"}
                  handleChange={(prop) => (event) => {
                    this.validatePasswordField("password", event.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              justify="flex-end"
              style={{
                paddingTop: "6px",
                paddingRight: "15px",
              }}
              container
              spacing={1}
            >
              <span
                onClick={this.navigationForgotPass}
                style={{
                  textAlign: "right",
                  color: "#F79824",
                  fontWeight: "bold",
                  fontSize: "13px",
                  paddingTop: "20px",
                  cursor: "pointer",
                }}
              >
                Forgot Password?
              </span>
            </Grid>
            <Grid
              style={{
                width: "100%",
                height: "75px",
                marginTop: "10px",
                justifyContent: "center",
                margin: "0px",
                textAlign: "center",
              }}
              container
              spacing={1}
            >
              <Grid style={{ marginTop: "5%" }} item xs={5}>
                <SubmitCancelBtn
                  onClick={() => this.validateFields()}
                  label={"Login"}
                  type="submit"
                  style={{ width: "80%" }}
                />
                <button type="submit" style={{ display: "none" }}></button>
              </Grid>
            </Grid>
          </form>
          <DialogBox
            open={this.state.open}
            message={this.state.dialogMsg}
            header={this.state.dialogHeader}
            // onClick={()=>this.han}
            closeDisloag={() => this.closeWindow()}
          />
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    supportUser: state.supportUser,
  };
}
//export default LoginContainer;

export default compose(
  withStyles(styles, { withTheme: true }),

  connect(mapStateToProps, {
    updateSupportUserDetails,
  }),
  withRouter
)(SupportLoginScreen);
