import React, { Component } from "react";
import {
  Paper,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  IconButton,
  Grid,
  Typography,
  Menu,
  MenuItem,
  Checkbox,
  FormControl,
  Badge,
  TextareaAutosize,
  Collapse,
} from "@material-ui/core";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import ImageSlider from "../../../common/ImageSlider";
import Properties from "../../../../properties/Properties";
import DialogBox from "../../../common/DialogBox";
import ApplicationUtil from "./../../../../utils/ApplicationUtil";
import ImageDialogBox from "../../../common/ImageDialogBox";
import Popover from "../../../common/Popover";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  deleteActivity,
  downloadImage,
  getActivityDetails,
  getRegisteredEventDetails,
  updateActivity,
  addActivityFeedback,
  markInvalidActivity,
} from "./../../../../apis/eventService";
import WarningIcon from "@material-ui/icons/Warning";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";

import { CustomTextField } from "../../../common/CustomTextField";
import { FeedbackIcon, IconCalendarBlue } from "../../../common/SvgIcon";

import MultiSelect from "../../../common/MultiSelect";
import ApplicationConstants from "../../../../constants/ApplicationConstants";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import UploadForm from "../../registration/UploadForm";
import Snackbar from "../../../common/Snackbar";
import { AddCircle, Forum } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
const styles = (theme) => ({
  tableList: {
    marginTop: "4px",
  },
  tableHead: {
    backgroundColor: "#ced4da",
    "& th": {
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
    },
    "& div": {
      textAlign: "left",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
      width: "60px",
    },
  },
  top: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "space-between",
    width: "100%",
    position: "relative",
    marginRight: theme.spacing(1),
  },
  actions: {
    display: "flex",
    alignItems: "center",
  },
  editIcon: {
    color: "#09456C",
    cursor: "pointer",
  },
  iconText: {
    display: "flex",
    alignItems: "center",
    "& p": {
      marginLeft: "10px",
    },
  },
  tableRow: {
    "& td": {
      fontWeight: "bold",
      fontFamily: "Rubik, sans-serif",
    },
    "& > *": {
      borderBottom: "unset",
    },
  },
  textLink: {
    color: "#023e8a",
    cursor: "pointer",
    borderRadius: "5px",
    width: "80px",
    margin: "auto",
    "&:hover": {
      color: "#03045e",
    },
  },
  urlLink: {
    textDecoration: "none",
    // backgroundColor: "#e0aaff",
    // color: "#023e8a",
    cursor: "pointer",
    borderRadius: "5px",
    width: "60px",
    margin: "auto",
    "&:hover": {
      color: "#03045e",
    },
  },
  approvalForm: {
    // width: "800px",
    // height: "auto",
    padding: "8px 24px",
  },
  formGrid: {
    marginTop: "15px",
  },
  manual: {
    width: "60px",
  },
  picture: {
    display: "flex",
    alignItems: "center",

    "& .imgCount": {
      // marginLeft: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#2883B5",
      padding: "8px",
      color: "#fff",
      height: "30px",
      width: "30px",
      borderRadius: "50%",
      cursor: "pointer",
    },
  },
  profileImgContainer: {
    width: "120px",
    height: "120px",
    // border: "2px solid blue",
    boxShadow: "0px 0px 2px 3px rgba(0,0,0,0.3)",
    display: "flex",
    justifyContent: "center",
  },
  profileImg: {
    width: "100px",
    height: "100px",
    objectFit: "contain",
    margin: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    cursor: "pointer",
  },
  itemTitle: {
    color: "#727272",
    // fontWeight: "600",
    fontSize: "16px",
  },
  imgTitle: {
    display: "flex",
    alignItems: "flex-start",
    // justifyContent: "flex-end",
    flexDirection: "column",
  },
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },
  labelCss: {
    color: "#727272",
  },
  mandatoryCss: { color: "red" },
  titleCss: {
    // color: "rgb(114, 114, 114)",
    color: "rgba(0, 0, 0, 0.87);",
    // color:'black',
    fontSize: "16px",
    fontWeight: 600,
  },
  infoCss: {
    color: "rgb(9, 69, 108)",
    fontSize: "20px",
    padding: "0px 0px 0px 5px",
    fontWeight: "bolder",
  },
  collapseItem: {
    // padding:10,
    display: "flex",
    alignItems: "center",
  },
});

class ActivityList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      showImage: false,
      imagePath: "",
      userType: "",
      openApproveForm: false,
      openFeedbackForm: false,
      totalStepsError: false,
      totalCaloriesError: false,
      totalElevationGainError: false,
      totalActivityDistanceError: false,
      totalActivityTimeError: false,
      checkboxArr: [],
      expand: false,
      runnerName: null,
      bib: null,
      generalListSelected: [],
      inValidComment: "",
    };
  }
  componentDidMount() {
    let userRole = ApplicationUtil.getSupportDetails();

    this.setState({ userType: userRole.role });
    let runnerId = localStorage.getItem("supportRunnerId");
    let eventId = ApplicationUtil.getSupportsEventID();
    this.setState({
      runnerId: runnerId,
      eventId: eventId,
    });
    // downloadImage(runnerId, eventId).then((res) => {
    //   this.setState({
    //     screenshots: res,
    //   });
    // });
  }

  closeWindow = () => {
    this.setState({
      openApproveForm: false,
      showCancelDialog: false,
      showActivityUploadForm: false,
      anchorEl: null,
      openFeedbackForm: false,
      activityId: null,
    });
  };
  fullImageView = (src) => {
    this.setState({
      imageDialog: true,
      image_src: "data:image/png;base64," + src,
    });
  };

  viewImage = (runnerId, activityId) => {
    let eventId = ApplicationUtil.getSupportsEventID();
    downloadImage(runnerId, eventId, activityId).then((res) => {
      this.setState({
        screenshots: res,
      });
    });
  };
  getDialogComponent = () => {
    return (
      <div>
        <ImageSlider files={this.state.screenshots} />
      </div>
    );
  };

  handleHourChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        hour: selectedValues,
        hourError: false,
      });
    } else {
      this.setState({
        hour: selectedValues,
        hourError: true,
      });
    }
  };

  handleMinuteChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        minute: selectedValues,
        minuteError: false,
      });
    } else {
      this.setState({
        minute: selectedValues,
        minuteError: true,
      });
    }
  };
  handleSecondChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        second: selectedValues,
        secondError: false,
      });
    } else {
      this.setState({
        second: selectedValues,
        secondError: true,
      });
    }
  };
  validateFormFields = () => {
    const { displayColumns } = this.props;
    const { activityDisplayColumn } = this.state;
    let validateField = ["totalActivityDistance", "totalActivityTime"];
    if (activityDisplayColumn?.length > 0) {
      validateField = activityDisplayColumn;
    } else if (displayColumns?.length > 0) {
      validateField = displayColumns;
    }
    let row = ApplicationUtil.objectReorder(this.state, validateField);
    let valid = true;
    Object.entries(row).map(([key, value], index) => {
      if (key !== "averagePace" && key !== "averageSpeed") {
        if (value === "") {
          valid = false;
          if (key === "totalActivityTime") {
            this.setState({
              hourError: true,
              minuteError: true,
              secondError: true,
            });
          } else {
            this.setState({
              [key + "Error"]: true,
            });
          }
        }
      }
    });
    if (valid) {
      this.saveField();
    }
  };
  saveField = () => {
    const { onClick, isGeneral, displayColumns } = this.props;
    const { activityDisplayColumn } = this.state;
    let validateField = ["totalActivityDistance", "totalActivityTime"];
    if (activityDisplayColumn?.length > 0) {
      validateField = activityDisplayColumn;
    } else if (displayColumns?.length > 0) {
      validateField = displayColumns;
    }
    const {
      totalSteps,
      totalCalories,
      totalActivityDistance,
      activityUrl,
      totalElevationGain,
      totalActivityTime,
      activityId,
      generalActivityId,
      hour,
      minute,
      second,
      activityType,
    } = this.state;
    let requestParam = {
      activityId: activityId,
      activityUrl: activityUrl,

      type: activityType,
    };
    if (totalCalories) {
      requestParam.calories = totalCalories;
    }
    if (totalElevationGain) {
      requestParam.elevationGain = totalElevationGain;
    }

    if (
      (validateField?.includes("totalSteps") ||
        validateField?.includes("steps")) &&
      totalSteps
    ) {
      requestParam.steps = totalSteps;
    }
    if (
      (validateField?.includes("totalActivityDistance") ||
        validateField?.includes("distance")) &&
      totalActivityDistance
    ) {
      requestParam.distance = parseFloat(totalActivityDistance) * 1000;
    }
    if (
      validateField?.includes("totalActivityTime") ||
      validateField?.includes("completionTime")
    ) {
      let time = hour.value + ":" + minute.value + ":" + second.value;
      requestParam.completionTime = time;
    }
    requestParam.approveRequest = true;
    updateActivity({ uploadRequest: requestParam }, activityId).then((res) => {
      let newData = this.props.data;
      if (isGeneral) {
        let index = newData.findIndex((o) => o.id === generalActivityId);
        let idx = newData[index]?.activities.findIndex(
          (x) => x.id === activityId
        );
        if (newData[index]?.activities[idx]) {
          newData[index].activities[idx] = res.data;
        }
      } else {
        let index = newData.findIndex((o) => o.id === activityId);
        newData[index] = res.data;
      }
      this.props.updateActivityList(newData);
      this.setState({ openApproveForm: false });
    });
  };
  getApproveForm = () => {
    const { classes, runnerDetails, runnerBib, displayColumns } = this.props;

    const {
      totalSteps,
      totalCalories,
      totalActivityDistance,
      activityUrl,
      totalElevationGain,
      totalStepsError,
      totalCaloriesError,
      totalElevationGainError,
      totalActivityDistanceError,

      hour,
      hourError,
      minute,
      minuteError,
      second,
      secondError,
      runnerName,
      bib,
      activityDisplayColumn,
    } = this.state;
    let fieldValues = ["distance", "completionTime"];
    if (activityDisplayColumn?.length > 0) {
      fieldValues = activityDisplayColumn;
    }

    return (
      <div className={classes.approvalForm}>
        <div
          style={{
            fontWeight: 600,
          }}
        >
          <div>
            <span style={{ color: "rgb(40, 131, 181)", fontSize: "30px" }}>
              {runnerDetails?.name || runnerName}
            </span>
            <br />
            <span>
              <span style={{ color: "rgb(40, 131, 181)" }}>BIB: </span>
              {runnerBib || bib}
            </span>
          </div>
        </div>
        {/* input feilds */}
        <div className="inputFields">
          <Grid container>
            <Grid item xs={12} sm={6} className="leftInputFields">
              {fieldValues.map((field) => (
                <div className={classes.formGrid}>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "#727272" }}>
                      {field === "distance" && Properties.Distance_label}
                      {field === "completionTime" &&
                        Properties.CompletionTime_label}
                      {field === "steps" && Properties.StepsLabel}
                      {field === "calories" && Properties.CaloriesLabel}

                      {field === "elevationGain" && Properties.Elevation_label}
                    </span>

                    {field !== "averagePace" && field !== "averageSpeed" && (
                      <span style={{ color: "red" }}>{"*"}</span>
                    )}
                  </Typography>

                  {field !== "completionTime" &&
                  field !== "averagePace" &&
                  field !== "averageSpeed" ? (
                    <CustomTextField
                      style={
                        totalStepsError ||
                        totalCaloriesError ||
                        totalElevationGainError ||
                        totalActivityDistanceError
                          ? {
                              border: "1px solid red",
                              height: "38px",
                              maxHeight: "38px",
                              minHeight: "38px",
                              width: "60%",
                            }
                          : {
                              border: "1px solid #08456C",
                              height: "38px",
                              maxHeight: "38px",
                              minHeight: "38px",
                              width: "60%",
                            }
                      }
                      type={"number"}
                      value={
                        field === "steps"
                          ? totalSteps
                          : field === "distance"
                          ? totalActivityDistance
                          : field === "calories"
                          ? totalCalories
                          : field === "elevationGain"
                          ? totalElevationGain
                          : ""
                      }
                      error={
                        totalStepsError
                          ? totalStepsError
                          : totalCaloriesError
                          ? totalCaloriesError
                          : totalElevationGainError
                          ? totalElevationGainError
                          : totalActivityDistanceError
                          ? totalActivityDistanceError
                          : false
                      }
                      name={field}
                      keyToSet={field}
                      handleChange={(prop) => (event) => {
                        let name =
                          event.target.name === "steps"
                            ? "totalSteps"
                            : event.target.name === "distance"
                            ? "totalActivityDistance"
                            : event.target.name === "calories"
                            ? "totalCalories"
                            : event.target.name === "elevationGain"
                            ? "totalElevationGain"
                            : "";
                        let errorName =
                          field === "steps"
                            ? "totalSteps"
                            : field === "distance"
                            ? "totalActivityDistance"
                            : field === "calories"
                            ? "totalCalories"
                            : field === "elevationGain"
                            ? "totalElevationGain"
                            : "";

                        this.setState({
                          [name]: event.target.value,
                          [errorName + "Error"]: false,
                        });
                      }}
                    />
                  ) : (
                    field === "completionTime" && (
                      <>
                        <Grid item style={{ display: "flex", width: "60%" }}>
                          <Grid item xs={4} style={{ margin: "2px" }}>
                            <MultiSelect
                              error={hourError}
                              value={hour}
                              options={ApplicationConstants.Hours}
                              onChange={this.handleHourChange}
                            />
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                color: "#2883B5",
                              }}
                            >
                              {Properties.HH_Label}
                            </span>
                          </Grid>

                          <Grid item xs={4} style={{ margin: "2px" }}>
                            <MultiSelect
                              error={minuteError}
                              value={minute}
                              options={ApplicationConstants.valueArray}
                              onChange={this.handleMinuteChange}
                            />
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                color: "#2883B5",
                              }}
                            >
                              {Properties.MM_Label}
                            </span>
                          </Grid>

                          <Grid item xs={4} style={{ margin: "2px" }}>
                            <MultiSelect
                              error={secondError}
                              value={second}
                              options={ApplicationConstants.valueArray}
                              onChange={this.handleSecondChange}
                            />
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                color: "#2883B5",
                              }}
                            >
                              {Properties.SS_Label}
                            </span>
                          </Grid>
                        </Grid>
                      </>
                    )
                  )}
                </div>
              ))}
            </Grid>
            <Grid item xs={12} sm={6} className="rightInputFields">
              {/*  avg pace/avg speed activity screenshots */}
              <div className={classes.formGrid}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    {Properties.ActivityUrl_label}
                  </span>
                </Typography>

                <CustomTextField
                  style={{
                    border: "1px solid #08456C",
                    height: "38px",
                    maxHeight: "38px",
                    minHeight: "38px",
                    width: "60%",
                  }}
                  type={"text"}
                  value={activityUrl}
                  keyToSet={"activityUrl"}
                  handleChange={(prop) => (event) => {
                    this.setState({
                      activityUrl: event.target.value,
                    });
                  }}
                />
              </div>
              {this.state.screenshots?.length > 0 && (
                <div
                  style={{
                    // display: "flex",
                    marginTop: "15px",
                  }}
                >
                  <div className={classes.picture}>
                    <div className={classes.imgTitle}>
                      <Typography variant="body2" gutterBottom>
                        <span style={{ color: "#727272" }}>
                          Activity Screenshots
                        </span>
                      </Typography>

                      <div className={classes.profileImgContainer}>
                        <img
                          className={classes.profileImg}
                          src={
                            this.state.screenshots
                              ? "data:image/png;base64," +
                                this.state.screenshots[0]?.imageData
                              : null
                          }
                          alt={"pic"}
                          onClick={() => {
                            this.setState({
                              // showFormScreenshotPreview: true,
                              // formScreenshotPreview: activityScreenshots,
                              open: true,
                            });
                          }}
                        />
                      </div>
                    </div>

                    {this.state.screenshots?.length > 1 && (
                      <p
                        className="imgCount"
                        onClick={() => {
                          this.setState({
                            open: true,
                            // formScreenshotPreview: activityScreenshots,
                          });
                        }}
                      >
                        +{this.state.screenshots?.length - 1}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
        {/* submit button */}
        <div
          style={{
            marginTop: "20px",

            textAlign: "center",
          }}
        >
          <SubmitCancelBtn
            label={"Submit"}
            type="submit"
            style={{ width: "160px", margin: "auto" }}
            onClick={this.validateFormFields}
          />
        </div>
      </div>
    );
  };
  formatTimeStamp = (date) => {
    let splitDate = date.split(" ");
    let formatDate = ApplicationUtil.changeDateFormatDate(splitDate[0]);
    let today = moment().format("YYYY-MM-DD");
    let isToday = moment(formatDate).isSame(today);
    let value = "";
    let time = splitDate[1].split(":");
    if (isToday) {
      // value = moment(
      //   date,
      //   date.includes(",") ? "DD-MM-YYYY, HH:MM:SS" : "DD-MM-YYYY HH:MM:SS"
      // )
      //   .fromNow()
      //   .replace("hours", "hrs")
      //   .replace("hour", "hr")
      //   .replace("minutes", "mins")
      //   .replace("ago", "");
      // value = value[0];
      value = `${time[0]}:${time[1]}`;
    } else {
      value = splitDate[0];
    }
    return value;
  };
  getFeedbackForm = () => {
    const { classes, userMessages, isGeneral } = this.props;
    const { comment, showGlobalComment, activityDetails } = this.state;

    return (
      <div className={classes.approvalForm}>
        <div
          style={{
            fontWeight: 600,
          }}
        >
          <Typography
            variant="body2"
            gutterBottom
            className={classes.helpContainerCss}
          >
            <span className={classes.labelCss}>Comment</span>
            <span className={classes.mandatoryCss}>*</span>
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextareaAutosize
              value={comment}
              aria-label="feedback"
              minRows={5}
              // placeholder="Minimum 3 rows"
              style={{ width: 600 }}
              onChange={(e) => {
                this.setState({
                  comment: e.target.value,
                });
              }}
            />

            <SubmitCancelBtn
              style={{ width: "auto", marginLeft: "16px" }}
              type="submit"
              // startIcon={
              //   <AddCircle style={{ fontSize: "25px", color: "#fff" }} />
              // }
              label={"Add"}
              onClick={() => this.addComment()}
            />
          </div>
          <div style={{ paddingTop: "10px" }}>
            {showGlobalComment ? (
              <>
                {userMessages &&
                  userMessages.map((ele) => (
                    <>
                      {ele.activityId === undefined && (
                        <div key={ele.id}>
                          {ele?.messageDate && (
                            <span>
                              {/* {ApplicationUtil.changeDateFormat(ele.messageDate)} */}
                              {this.formatTimeStamp(ele.messageDate)}
                            </span>
                          )}
                          <span style={{ fontWeight: 300, marginLeft: "10px" }}>
                            {ele?.message}
                          </span>
                        </div>
                      )}
                    </>
                  ))}
              </>
            ) : (
              <>
                {isGeneral
                  ? activityDetails?.messages?.map((ele) => (
                      <div key={ele.id}>
                        {ele?.messageDate && (
                          <span>
                            {/* {ApplicationUtil.changeDateFormat(ele.messageDate)} */}
                            {this.formatTimeStamp(ele.messageDate)}
                          </span>
                        )}
                        <span style={{ fontWeight: 300, marginLeft: "10px" }}>
                          {ele.message}
                        </span>
                      </div>
                    ))
                  : activityDetails?.comments?.map((ele) => (
                      <div key={ele.id}>
                        {ele?.messageDate && (
                          <span>
                            {/* {ApplicationUtil.changeDateFormat(ele.messageDate)} */}
                            {this.formatTimeStamp(ele.messageDate)}
                          </span>
                        )}
                        <span style={{ fontWeight: 300, marginLeft: "10px" }}>
                          {ele.message}
                        </span>
                      </div>
                    ))}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  addComment = () => {
    const {
      activityId,
      comment,
      eventId,
      runnerId,
      activityDetails,
      showGlobalComment,
      generalActivityId,
    } = this.state;
    const { isGeneral } = this.props;
    let requestParams = {
      message: comment,
      eventId: eventId,
      recipientId: runnerId,
      recipientType: "INDIVIDUAL",
      objectId: runnerId,
      messageType: "ACTIVITY",
    };
    if (activityId) {
      requestParams.activityId = activityId;
      requestParams.objectId = activityId;
      requestParams.recipientType = "ACTIVITY";
    }

    if (comment?.trim() === "" || comment === undefined || comment === null) {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please add a comment",
      });
    } else {
      addActivityFeedback(requestParams).then((res) => {
        if (showGlobalComment) {
          let data = this.props.userMessages ? this.props.userMessages : [];
          data.unshift({
            messageDate: moment().format("DD-MM-YYYY HH:MM:SS"),
            message: comment,
          });
          this.props.updateUserMessages(data);
        } else {
          let newData = this.props.data;
          if (isGeneral) {
            let index = newData.findIndex((o) => o.id === generalActivityId);
            let idx = newData[index]?.activities.findIndex(
              (x) => x.id === activityId
            );
            if (newData[index]?.activities[idx]?.messages) {
              newData[index].activities[idx].messages.unshift({
                messageDate: moment().format("DD-MM-YYYY HH:MM:SS"),
                message: comment,
              });
              newData[index].activities[idx].messages =
                newData[index].activities[idx]?.messages;
            }
          } else {
            let index = newData.findIndex(
              (o) => o.id === this.state.activityId
            );
            let data = newData[index];
            data.comments.unshift({
              messageDate: moment().format("DD-MM-YYYY HH:MM:SS"),
              message: comment,
            });
            newData[index] = data;
          }

          this.props.updateActivityList(newData, "reload");
        }

        this.setState({
          openFeedbackForm: false,
          activityId: null,
          comment: null,
          showGlobalComment: false,
        });
      });
    }
  };
  getDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.DeleteActivityWarning}</span>
        </Typography>
      </div>
    );
  };
  handleActivityDelete = () => {
    const {
      activityId,
      menuAnchorEl,
      checkboxArr,
      generalListSelected,
      runnerId,
    } = this.state;
    const { isGeneral, data } = this.props;
    // let runnerId = localStorage.getItem("supportRunnerId");
    let eventId = ApplicationUtil.getSupportsEventID();
    let activityIds = [];

    if (menuAnchorEl) {
      checkboxArr.forEach((e) => {
        if (e.check) {
          activityIds.push(e.id);
        } else {
          return;
        }
      });
    } else {
      activityIds = [activityId];
    }

    deleteActivity(eventId, runnerId, activityIds)
      .then((res) => {
        this.setState({ anchorEl: null });
        if (isGeneral) {
          let filterActivity = data.map((x) => {
            let idx = generalListSelected.findIndex((a) => a.id == x.id);
            if (idx !== -1) {
              activityIds.forEach((e) => {
                let index = x?.activities.findIndex((o) => o.id == e);
                if (index !== -1) x.activities.splice(index, 1);
              });
            }
            return x;
          });

          this.props.updateActivityList(filterActivity);
          this.closeWindow();
        } else {
          let newData = this.props.data;

          activityIds.forEach((e) => {
            let index = newData.findIndex((o) => o.id === e);
            newData.splice(index, 1);
          });
          this.props.updateActivityList(newData);
          this.closeWindow();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // update activity

  isValidDate = (d) => {
    let days = localStorage.getItem("days");

    if (days) {
      if (d) {
        let newd =
          d?.getDate() + "-" + (d?.getMonth() + 1) + "-" + d?.getFullYear();
        let start = localStorage.getItem("start");
        let end = localStorage.getItem("end");
        if (newd >= start && newd <= end) {
          this.setState({ dateError: false });
        } else {
          this.setState({ dateError: true });
        }
      } else {
        this.setState({ dateError: true });
      }
    }

    return d instanceof Date && !isNaN(d);
  };
  handleDateChange = (date, dateError) => {
    if (dateError) {
      this.setState({ dateError: true });
    } else
      this.setState({
        date: this.isValidDate(date) ? date : "",
        dateError: false,
      });
  };
  handleNavigationAfterSuccessfulUpload = (updatedData) => {
    const { isGeneral, data } = this.props;
    const { activityId } = this.state;
    if (isGeneral) {
      let activity = data.map((x) => {
        let idx = x.activities.findIndex((a) => a.id == activityId);
        if (idx !== -1) x.activities[idx] = updatedData;
        return x;
      });
      this.props.updateActivityList(activity);
      this.closeWindow();
    } else {
      let newData = this.props.data;

      let index = newData.findIndex((o) => o.id === this.state.activityId);
      newData[index] = updatedData;
      this.props.updateActivityList(newData);
      this.closeWindow();
    }
  };
  handleActivityUpdateClick = () => {
    const { activityId, eventId, runnerId } = this.state;
    let data = null;
    let requestParams = {
      eventId: eventId,
      runnerId: runnerId,
      activityId: activityId,
    };
    getActivityDetails(requestParams).then((res) => {
      getRegisteredEventDetails(runnerId, eventId).then((response) => {
        if (response.data?.event?.activities) {
          response.data.event.activities.map(
            (r) => (
              (r.label = r.displayName),
              (r.value = r.type),
              (r.displayColumns = r?.primaryDisplayParams
                ?.toLowerCase()
                .split(","))
            )
          );
          this.setState({
            activityTypeList: response.data?.event?.activities,
            selectedActivityType: response.data?.event?.activities[0],
          });
        }
        this.setState({
          showActivityUploadForm: true,
          activityDetails: res.data,
          eventDetails: response.data,
          date: ApplicationUtil.changeDateFormatDate(res?.data?.startDateLocal),
        });
      });
    });
  };
  getUpdateActivity = () => {
    const {
      eventId,
      runnerId,
      eventDetails,
      activityDetails,
      timeValidation,
      date,
      dateError,
      activityTypeList,
      selectedActivityType,
    } = this.state;

    return (
      <UploadForm
        eventId={eventId}
        runnerId={runnerId}
        selectedCategory={{
          label:
            eventDetails?.registration?.eventRunCategories[0]?.categoryName,
          value:
            eventDetails?.registration?.eventRunCategories[0]?.categoryName,
        }}
        eventCategoryList={eventDetails?.event?.eventRunCategories}
        dateError={dateError}
        date={date}
        handleDateChange={this.handleDateChange}
        activityDetails={activityDetails}
        handleNavigationAfterSuccessfulUpload={
          this.handleNavigationAfterSuccessfulUpload
        }
        displayColumns={
          eventDetails?.event?.displayColumns
            ? eventDetails?.event?.displayColumns.split(",")
            : ["distance", "completionTime"]
        }
        timeValidation={timeValidation}
        showActivityDate={true}
        activityTypeList={activityTypeList}
        selectedActivityType={selectedActivityType}
        handleActivityTypeChange={this.handleActivityTypeChange}
      />
    );
  };
  handleActivityTypeChange = (value) => {
    this.setState({
      selectedActivityType: value,
    });
  };
  handleSelectAll = (e) => {
    const { data } = this.props;

    let val = e.target.checked;
    let arr = [];
    let dataArray = data.map((item) => item.activities).flat(data?.length);

    dataArray &&
      dataArray.map((d) => {
        arr.push({
          check: val,
          id: d.id,
        });
      });

    this.setState({
      isAllChecked: val,
      checkboxArr: arr,
    });
  };
  handleSelect = (e, row) => {
    let arr = this.state.checkboxArr;
    let i = arr.findIndex((x) => x.id === row.id);
    if (i != -1) {
      arr[i].id = row.id;
      arr[i].check = e.target.checked;
      this.setState({
        checkboxArr: arr,
        isAllChecked: false,
      });
    } else {
      let arr = [
        ...this.state.checkboxArr,
        {
          check: e.target.checked,
          id: row.id,
        },
      ];
      this.setState({
        checkboxArr: arr,
        isAllChecked: false,
      });
    }
  };
  isSelected = (id) => {
    let arr = this.state.checkboxArr;
    let i = arr.findIndex((x) => x.id === id);
    if (i !== -1) {
      if (arr[i].check) return true;
    } else {
      return false;
    }
  };
  getInvalidForm = () => {
    const { classes, userMessages, isGeneral } = this.props;
    const { comment, activityDetails, inValidComment } = this.state;

    return (
      <div className={classes.approvalForm}>
        <div
          style={{
            fontWeight: 600,
          }}
        >
          <Typography
            variant="body2"
            gutterBottom
            className={classes.helpContainerCss}
          >
            <span className={classes.labelCss}>Comment</span>
            <span className={classes.mandatoryCss}>*</span>
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextareaAutosize
              value={inValidComment}
              aria-label="feedback"
              minRows={5}
              // placeholder="Minimum 3 rows"
              style={{ width: 600 }}
              onChange={(e) => {
                this.setState({
                  inValidComment: e.target.value,
                });
              }}
            />

            <SubmitCancelBtn
              style={{ width: "auto", marginLeft: "16px" }}
              type="submit"
              // startIcon={
              //   <AddCircle style={{ fontSize: "25px", color: "#fff" }} />
              // }
              label={"Submit"}
              onClick={() => this.submitInvalid()}
            />
          </div>
        </div>
      </div>
    );
  };
  submitInvalid = () => {
    const { activityId, inValidComment, generalActivityId } = this.state;
    const { isGeneral, data } = this.props;
    if (inValidComment) {
      let requestParam = {
        message: inValidComment.replace("\n", " ").trim(),
      };
      markInvalidActivity(requestParam, activityId).then((res) => {
        let newData = data;

        if (isGeneral) {
          let index = newData.findIndex((o) => o.id === generalActivityId);
          let idx = newData[index]?.activities.findIndex(
            (x) => x.id === activityId
          );
          if (newData[index]?.activities[idx]) {
            newData[index].activities[idx] = res.data;
          }
        } else {
          let index = newData.findIndex((o) => o.id === activityId);
          newData[index] = res.data;
        }
        this.props.updateActivityList(newData);
        this.setState({ openInvalidDialog: false });
      });
    } else {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please provide invalid reason",
      });
    }
  };
  render() {
    const {
      data,
      classes,
      type,
      displayColumns,
      isSupport,
      isGeneral,
      challengeType,
      showActivityScreenshot,
    } = this.props;
    const {
      anchorEl,
      menuAnchorEl,
      isAllChecked,
      expand,
      openInvalidDialog,
      openFeedbackForm,
      openApproveForm,
    } = this.state;
    let isTraining = localStorage.getItem("isTraining");

    return (
      <>
        <div
          className={classes.top}
          style={{ justifyContent: !isGeneral ? "space-between" : "flex-end" }}
        >
          {!isGeneral && (
            <div className={classes.totalRec}>
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                }}
              >
                Activities
                {/* <span style={{ marginLeft: "10px" }}>{data}</span> */}
                <span
                  onClick={() => {
                    this.setState({
                      openFeedbackForm: true,
                      showGlobalComment: true,
                    });
                  }}
                  style={{
                    padding: "4px 8px",
                    fontWeight: "20px",
                    borderRadius: "4px",
                    cursor: "pointer",
                    backgroundColor: "#09456C",
                    marginLeft: "8px",
                    color: "white",
                    marginLeft: "8px",
                  }}
                >
                  Add Feedback
                </span>
              </span>
            </div>
          )}
          {isSupport && (
            <FormControl className={classes.formControlAction}>
              <div className={classes.actions}>
                <span>Actions</span>
                <span>
                  <ArrowDropDownCircleOutlinedIcon
                    className={classes.editIcon}
                    onClick={(event) => {
                      this.setState({
                        menuAnchorEl: event.currentTarget,
                      });
                    }}
                  />
                </span>
              </div>
            </FormControl>
          )}
        </div>
        <TableContainer component={Paper} className={classes.tableList}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHead}>
                {isSupport && (
                  <TableCell component="div" align="left">
                    <Checkbox
                      checked={isAllChecked ? isAllChecked : false}
                      onChange={this.handleSelectAll}
                      color="primary"
                    />
                  </TableCell>
                )}
                {/* {isSupport && (
                  <TableCell component="th" align="center"></TableCell>
                )} */}
                <TableCell component="th">
                  {isGeneral ? "Date Time" : "Date"}
                </TableCell>
                {isGeneral && <TableCell component="th">Name</TableCell>}
                {isGeneral && isTraining && (
                  <TableCell component="th">Group</TableCell>
                )}

                {isGeneral && (
                  <TableCell component="th">Activity Name</TableCell>
                )}

                {(challengeType !== "COOPER_TEST" || isGeneral) && (
                  <TableCell component="th">
                    {Properties.ActivityType_label}
                  </TableCell>
                )}
                {!isGeneral && challengeType === "COOPER_TEST" && (
                  <TableCell component="th">
                    {Properties.CooperDistance_label}
                  </TableCell>
                )}
                {!isGeneral && challengeType === "COOPER_TEST" && (
                  <TableCell component="th">
                    {Properties.CooperRemark_label}
                  </TableCell>
                )}
                {displayColumns?.length > 0 && displayColumns[0] !== "" ? (
                  displayColumns.map((column) => (
                    <TableCell component="th">
                      {column === "totalActivityDistance" &&
                        Properties.Distance_label}
                      {column === "totalActivityTime" && Properties.Time_label}
                      {column === "totalCalories" && Properties.CaloriesLabel}
                      {column === "totalSteps" && Properties.StepsLabel}
                      {column === "totalElevationGain" &&
                        Properties.Elevation_label}
                      {column === "averagePace" &&
                        type === "RUN" &&
                        Properties.AveragePace_label}
                      {column === "averageSpeed" &&
                        type !== "RUN" &&
                        Properties.AverageSpeed_label}
                    </TableCell>
                  ))
                ) : (
                  <>
                    <TableCell component="th">
                      {Properties.Distance_label}
                    </TableCell>

                    <TableCell component="th">
                      {Properties.Time_label}
                    </TableCell>
                  </>
                )}
                <TableCell component="th">
                  {" "}
                  {Properties.FetchFromLabel}
                </TableCell>
                <TableCell component="th">
                  {" "}
                  {Properties.ActivityUrl_label}
                </TableCell>
                <TableCell component="th">Valid</TableCell>
                {showActivityScreenshot && (
                  <TableCell component="th">
                    {Properties.Label_ActivityScreenshot}
                  </TableCell>
                )}
                {
                  // data.some((x) => x.approved === false) &&
                  this.state.userType === "SUPPORT" && (
                    <TableCell component="th">Action</TableCell>
                  )
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row) => {
                if (row?.isGeneral) {
                  return (
                    <>
                      <TableRow>
                        <TableCell component="td" align="left" colSpan={15}>
                          <span>
                            {ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                              row?.date
                            )}
                          </span>
                        </TableCell>
                      </TableRow>
                      {row?.activities?.map((a) => {
                        const isItemSelected = this.isSelected(a?.id);
                        return (
                          <>
                            <TableRow
                              // hover
                              key={a.id}
                              // component={Paper}
                              className={classes.tableRow}
                            >
                              {isSupport && (
                                <TableCell component="th" align="center">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Checkbox
                                      checked={
                                        isAllChecked
                                          ? isAllChecked
                                          : isItemSelected
                                      }
                                      onChange={(e) => {
                                        let arr =
                                          this.state.generalListSelected;
                                        let i = arr.findIndex(
                                          (x) => x.id === row.id
                                        );
                                        if (i != -1) {
                                          arr[i].id = row.id;

                                          this.setState({
                                            generalListSelected: arr,
                                          });
                                        } else {
                                          let newArr = [
                                            ...this.state.generalListSelected,
                                            {
                                              id: row.id,
                                            },
                                          ];
                                          this.setState({
                                            generalListSelected: newArr,
                                          });
                                        }
                                        this.handleSelect(e, a);
                                      }}
                                      color="primary"
                                    />
                                    {/* <IconButton> */}
                                    <ArrowDropDownCircleOutlinedIcon
                                      className={classes.editIcon}
                                      onClick={(e) => {
                                        if (isGeneral) {
                                          let arr =
                                            this.state.generalListSelected;
                                          let i = arr.findIndex(
                                            (x) => x.id === row.id
                                          );
                                          if (i != -1) {
                                            arr[i].id = row.id;

                                            this.setState({
                                              generalListSelected: arr,
                                            });
                                          } else {
                                            let newArr = [
                                              ...this.state.generalListSelected,
                                              {
                                                id: row.id,
                                              },
                                            ];
                                            this.setState({
                                              generalListSelected: newArr,
                                            });
                                          }
                                        }
                                        this.setState({
                                          showMenu: true,
                                          anchorEl: e.currentTarget,
                                          activityId: a?.id,
                                          // generalActivityId:row?.id,
                                          runnerId: a?.runnerId,
                                          allowActivityEdit:
                                            a?.allowActivityEdit, // (a?.type === "RUN" || a?.type === "CYCLE") && a?.allowActivityEdit,
                                        });
                                      }}
                                    />
                                    {/* </IconButton> */}
                                  </div>
                                </TableCell>
                              )}

                              <TableCell
                                component="td"
                                align="left"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <span>
                                  {a.startDateLocal ? a.startDateLocal : "-"}
                                </span>
                              </TableCell>
                              <TableCell
                                component="td"
                                align={a?.runnerName ? "left" : "center"}
                              >
                                {a?.runnerName || "-"}
                              </TableCell>
                              {isTraining && (
                                <TableCell
                                  component="td"
                                  align={a?.athleteGroup ? "left" : "center"}
                                >
                                  {a?.athleteGroup || "-"}
                                </TableCell>
                              )}

                              <TableCell
                                component="td"
                                align={a?.name ? "left" : "center"}
                              >
                                {a?.name || "-"}
                              </TableCell>
                              <TableCell
                                component="td"
                                style={{ paddingLeft: "10px" }}
                                align={a?.subActivityType ? "left" : "center"}
                              >
                                <span>
                                  {a.subActivityType ? a.subActivityType : "-"}
                                </span>
                              </TableCell>
                              {displayColumns?.length > 0 &&
                              displayColumns[0] !== "" ? (
                                displayColumns.map((column) => (
                                  <TableCell
                                    component="td"
                                    align="center"
                                    // onClick={() => {
                                    //   this.navigateToDetails(a?.runner?.id);
                                    // }}
                                  >
                                    {column === "totalActivityDistance" && (
                                      <span>
                                        {a.distance
                                          ? parseFloat(
                                              ApplicationUtil.getFormatedDistance(
                                                a.distance
                                              ) / 1000
                                            ).toFixed(2) + " Km"
                                          : "-"}
                                      </span>
                                    )}
                                    {column === "totalActivityTime" && (
                                      <span>
                                        {a.completionTime
                                          ? a.completionTime
                                          : "-"}
                                      </span>
                                    )}
                                    {column === "totalCalories" && (
                                      <p>{a.calories ? a.calories : "-"}</p>
                                    )}
                                    {column === "totalSteps" && (
                                      <p>{a.steps ? a.steps : "-"}</p>
                                    )}
                                    {column === "totalElevationGain" && (
                                      <p>
                                        {a.elevationGain
                                          ? a.elevationGain
                                          : "-"}
                                      </p>
                                    )}
                                    {column === "averagePace" &&
                                      a.type === "RUN" && (
                                        <p>
                                          {a.averagePace ? a.averagePace : "-"}
                                        </p>
                                      )}
                                    {column === "averageSpeed" &&
                                      a.type !== "RUN" && (
                                        <p>
                                          {a.averageSpeed
                                            ? a.averageSpeed
                                            : "-"}
                                        </p>
                                      )}
                                  </TableCell>
                                ))
                              ) : (
                                <>
                                  <TableCell component="td" align="center">
                                    <p>
                                      {a.distance
                                        ? parseFloat(
                                            ApplicationUtil.getFormatedDistance(
                                              a.distance
                                            ) / 1000
                                          ).toFixed(2) + " Km"
                                        : "-"}
                                    </p>
                                  </TableCell>

                                  <TableCell component="td" align="center">
                                    <p>
                                      {a.completionTime
                                        ? a.completionTime
                                        : "-"}
                                    </p>
                                  </TableCell>
                                </>
                              )}
                              <TableCell
                                component="td"
                                align={a?.fetchFrom ? "left" : "center"}
                              >
                                <span>{a.fetchFrom ? a.fetchFrom : "-"}</span>
                              </TableCell>
                              <TableCell component="td" align="center">
                                {a?.activityUrl ? (
                                  <span className={classes.urlLink}>
                                    <a href={a?.activityUrl} target="_blank">
                                      URL
                                    </a>
                                  </span>
                                ) : (
                                  "-"
                                )}
                              </TableCell>
                              <TableCell component="td" align="center">
                                {a.valid && a.valid ? (
                                  <CheckCircleIcon
                                    style={{ color: "#40916c" }}
                                  />
                                ) : a.validationReason ? (
                                  <Popover
                                    title={
                                      <CancelIcon
                                        style={{ color: "#f94144" }}
                                      />
                                    }
                                    message={a && a.validationReason}
                                  />
                                ) : (
                                  <CancelIcon style={{ color: "#f94144" }} />
                                )}
                              </TableCell>
                              {showActivityScreenshot && (
                                <TableCell component="td" align="center">
                                  {a.activityScreenshotStatus ===
                                  "AVAILABLE" ? (
                                    <span
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.viewImage(a?.runnerId, a.id);
                                        this.setState({
                                          openActivityScreenshot: true,
                                        });
                                      }}
                                      style={{
                                        color: "#F79824",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {Properties.Label_View}
                                    </span>
                                  ) : (
                                    "-"
                                  )}
                                </TableCell>
                              )}
                              {this.state.userType === "SUPPORT" && (
                                <TableCell component="td" align="right">
                                  {a?.approved === false && (
                                    <span
                                      className={classes.textLink}
                                      onClick={() => {
                                        let time =
                                          a?.completionTime?.split(":");

                                        this.setState({
                                          openApproveForm: true,
                                          generalActivityId: row?.id,
                                          activityId: a?.id,
                                          activityType: a?.subActivityType,
                                          totalSteps: a?.steps,
                                          totalActivityDistance: parseFloat(
                                            ApplicationUtil.getFormatedDistance(
                                              a?.distance
                                            ) / 1000
                                          ).toFixed(2),
                                          activityUrl: a?.activityUrl,
                                          totalActivityTime: a?.completionTime,
                                          hour: {
                                            label: a?.completionTime
                                              ? time[0]
                                              : "",
                                            value: a?.completionTime
                                              ? time[0]
                                              : "",
                                          },
                                          minute: {
                                            label: a?.completionTime
                                              ? time[1]
                                              : "",
                                            value: a?.completionTime
                                              ? time[1]
                                              : "",
                                          },
                                          second: {
                                            label: a?.completionTime
                                              ? time[2]
                                              : "",
                                            value: a?.completionTime
                                              ? time[2]
                                              : "",
                                          },
                                          averagePace: a?.averagePace,
                                          averageSpeed: a?.averageSpeed,
                                          totalCalories: a?.calories,
                                          totalElevationGain: a?.elevationGain,
                                          runnerName: a?.runnerName,
                                          bib: a?.bibNumber,
                                          activityDisplayColumn:
                                            a?.displayColumns?.split(","),
                                        });
                                        this.viewImage(a?.runnerId, a.id);
                                      }}
                                    >
                                      {/* Approve */}
                                      <IconButton>
                                        <Tooltip title="Approval pending">
                                          <WarningIcon
                                            style={{ color: "red" }}
                                          />
                                        </Tooltip>
                                      </IconButton>
                                    </span>
                                  )}
                                  {a?.valid && (
                                    <IconButton
                                      onClick={() => {
                                        this.setState({
                                          openInvalidDialog: true,
                                          openFeedbackForm: false,
                                          activityId: a.id,
                                          generalActivityId: row?.id,
                                        });
                                      }}
                                    >
                                      <Tooltip title="Mark as invalid">
                                        <AssignmentLateIcon
                                          style={{ color: "red" }}
                                        />
                                      </Tooltip>
                                    </IconButton>
                                  )}
                                  <IconButton
                                    onClick={() => {
                                      this.setState({
                                        openFeedbackForm: true,
                                        activityId: a?.id,
                                        generalActivityId: row?.id,
                                        activityDetails: a,
                                        runnerId: a?.runnerId,
                                        showGlobalComment: false,
                                      });
                                    }}
                                  >
                                    {a?.messages ? (
                                      <Badge
                                        badgeContent={a.messages?.length}
                                        color="primary"
                                      >
                                        <Forum
                                          style={{
                                            color: "#F79824",
                                          }}
                                        />
                                      </Badge>
                                    ) : (
                                      <Forum
                                        style={{
                                          color: "#F79824",
                                        }}
                                      />
                                    )}
                                  </IconButton>
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => {
                                      let parentIdx = data.findIndex(
                                        (x) => x.id == row?.id
                                      );

                                      let idx = data[
                                        parentIdx
                                      ]?.activities?.findIndex(
                                        (x) => x.id == a?.id
                                      );

                                      // if (data[parentIdx]?.activities[idx]?.isExpanded == true || data[parentIdx]?.activities[idx]?.isExpanded == false) {

                                      data[parentIdx].activities[
                                        idx
                                      ].isExpanded =
                                        !data[parentIdx]?.activities[idx]
                                          .isExpanded;
                                      // }

                                      this.props.updateActivityList(data);
                                    }}
                                  >
                                    {a?.isExpanded ? (
                                      <KeyboardArrowUp />
                                    ) : (
                                      <KeyboardArrowDown />
                                    )}
                                    {/* <KeyboardArrowDown/> */}
                                  </IconButton>
                                </TableCell>
                              )}
                            </TableRow>

                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell
                                style={{
                                  paddingBottom: 0,
                                  paddingTop: 0,
                                  paddingBottom: 10,
                                }}
                                colSpan={14}
                              >
                                <Collapse
                                  in={a?.isExpanded}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Grid container>
                                    {a?.averagePace && (
                                      <Grid
                                        item
                                        xs={2}
                                        className={classes.collapseItem}
                                      >
                                        <span className={classes.titleCss}>
                                          {Properties.AveragePace_label}
                                        </span>
                                        <span className={classes.infoCss}>
                                          {a?.averagePace}
                                        </span>
                                      </Grid>
                                    )}
                                    <Grid
                                      item
                                      xs={2}
                                      className={classes.collapseItem}
                                    >
                                      <span className={classes.titleCss}>
                                        {"Type"}
                                      </span>
                                      <span className={classes.infoCss}>
                                        {a?.type}
                                      </span>
                                    </Grid>

                                    {a?.averageSpeed > 0 && (
                                      <Grid
                                        item
                                        xs={2}
                                        className={classes.collapseItem}
                                      >
                                        <span className={classes.titleCss}>
                                          {Properties.AverageSpeed_label}
                                        </span>
                                        <span className={classes.infoCss}>
                                          {a?.averageSpeed}
                                        </span>
                                      </Grid>
                                    )}
                                    {a?.elevationGain > 0 && (
                                      <Grid
                                        item
                                        xs={2}
                                        className={classes.collapseItem}
                                      >
                                        <span className={classes.titleCss}>
                                          {Properties.Elevation_label}
                                        </span>
                                        <span className={classes.infoCss}>
                                          {a?.elevationGain}
                                        </span>
                                      </Grid>
                                    )}
                                    {a?.elevationLoss > 0 && (
                                      <Grid
                                        item
                                        xs={2}
                                        className={classes.collapseItem}
                                      >
                                        <span className={classes.titleCss}>
                                          {Properties.ElevationLoss_label}
                                        </span>
                                        <span className={classes.infoCss}>
                                          {a?.elevationLoss}
                                        </span>
                                      </Grid>
                                    )}
                                    {a?.elevationHigh > 0 && (
                                      <Grid
                                        item
                                        xs={2}
                                        className={classes.collapseItem}
                                      >
                                        <span className={classes.titleCss}>
                                          {Properties.ElevationHigh_label}
                                        </span>
                                        <span className={classes.infoCss}>
                                          {a?.elevationHigh}
                                        </span>
                                      </Grid>
                                    )}
                                    {a?.elevationLow > 0 && (
                                      <Grid
                                        item
                                        xs={2}
                                        className={classes.collapseItem}
                                      >
                                        <span className={classes.titleCss}>
                                          {Properties.ElevationLow_label}
                                        </span>
                                        <span className={classes.infoCss}>
                                          {a?.elevationLow}
                                        </span>
                                      </Grid>
                                    )}
                                    {a?.humidity > 0 && (
                                      <Grid
                                        item
                                        xs={2}
                                        className={classes.collapseItem}
                                      >
                                        <span className={classes.titleCss}>
                                          {Properties.Humidity}
                                        </span>
                                        <span className={classes.infoCss}>
                                          {a?.humidity}
                                        </span>
                                      </Grid>
                                    )}
                                    {a?.averageTemp > 0 && (
                                      <Grid
                                        item
                                        xs={2}
                                        className={classes.collapseItem}
                                      >
                                        <span className={classes.titleCss}>
                                          {Properties.AvgTemp}
                                        </span>
                                        <span className={classes.infoCss}>
                                          {a?.averageTemp}
                                        </span>
                                      </Grid>
                                    )}
                                    {a?.averageHeartRate > 0 && (
                                      <Grid
                                        item
                                        xs={2}
                                        className={classes.collapseItem}
                                      >
                                        <span className={classes.titleCss}>
                                          {Properties.HRAvg}
                                        </span>
                                        <span className={classes.infoCss}>
                                          {a?.averageHeartRate}
                                        </span>
                                      </Grid>
                                    )}
                                    {challengeType === "COOPER_TEST" &&
                                      a?.cooperTestDistance && (
                                        <Grid
                                          item
                                          xs={2}
                                          className={classes.collapseItem}
                                        >
                                          <span className={classes.titleCss}>
                                            {Properties.CooperDistance_label}
                                          </span>
                                          <span className={classes.infoCss}>
                                            {parseFloat(
                                              ApplicationUtil.getFormatedDistance(
                                                a?.cooperTestDistance
                                              ) / 1000
                                            ).toFixed(2) + " Km"}
                                          </span>
                                        </Grid>
                                      )}
                                    {challengeType === "COOPER_TEST" &&
                                      a?.cooperTestRemark && (
                                        <Grid
                                          item
                                          xs={2}
                                          className={classes.collapseItem}
                                        >
                                          <span className={classes.titleCss}>
                                            {"Cooper Remark"}
                                          </span>
                                          <span className={classes.infoCss}>
                                            {a?.cooperTestRemark}
                                          </span>
                                        </Grid>
                                      )}
                                  </Grid>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </>
                  );
                } else {
                  const isItemSelected = this.isSelected(row?.id);
                  return (
                    <TableRow
                      hover
                      key={row.id}
                      component={Paper}
                      className={classes.tableRow}
                    >
                      {isSupport && (
                        <TableCell component="th" align="center">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Checkbox
                              checked={
                                isAllChecked ? isAllChecked : isItemSelected
                              }
                              onChange={(e) => this.handleSelect(e, row)}
                              color="primary"
                            />
                            <IconButton>
                              <ArrowDropDownCircleOutlinedIcon
                                className={classes.editIcon}
                                onClick={(e) => {
                                  this.setState({
                                    showMenu: true,
                                    anchorEl: e.currentTarget,
                                    activityId: row?.id,
                                    runnerId:
                                      localStorage.getItem("supportRunnerId"),
                                    allowActivityEdit: row?.allowActivityEdit,
                                  });
                                }}
                              />
                            </IconButton>
                          </div>
                        </TableCell>
                      )}

                      <TableCell
                        component="td"
                        align="left"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <p>
                          {row.startDateLocal
                            ? ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                                row.startDateLocal
                              )
                            : "-"}
                        </p>
                      </TableCell>
                      {(challengeType !== "COOPER_TEST" || isGeneral) && (
                        <TableCell
                          component="td"
                          align="left"
                          style={{ paddingLeft: "10px" }}
                        >
                          <p>{row.type ? row.type : "-"}</p>
                        </TableCell>
                      )}
                      {!isGeneral && challengeType === "COOPER_TEST" && (
                        <TableCell
                          component="td"
                          align="center"
                          style={{ paddingLeft: "10px" }}
                        >
                          <p>
                            {row?.cooperTestDistance
                              ? parseFloat(
                                  ApplicationUtil.getFormatedDistance(
                                    row.cooperTestDistance
                                  ) / 1000
                                ).toFixed(2) + " Km"
                              : "-"}
                          </p>
                        </TableCell>
                      )}
                      {!isGeneral && challengeType === "COOPER_TEST" && (
                        <TableCell
                          component="td"
                          align="left"
                          style={{ paddingLeft: "10px" }}
                        >
                          <p>
                            {row?.cooperTestRemark ? row.cooperTestRemark : "-"}
                          </p>
                        </TableCell>
                      )}
                      {displayColumns?.length > 0 &&
                      displayColumns[0] !== "" ? (
                        displayColumns.map((column) => (
                          <TableCell
                            component="td"
                            align="center"
                            // onClick={() => {
                            //   this.navigateToDetails(row?.runner?.id);
                            // }}
                          >
                            {column === "totalActivityDistance" && (
                              <p>
                                {row.distance
                                  ? parseFloat(
                                      ApplicationUtil.getFormatedDistance(
                                        row.distance
                                      ) / 1000
                                    ).toFixed(2) + " Km"
                                  : "-"}
                              </p>
                            )}
                            {column === "totalActivityTime" && (
                              <p>
                                {row.completionTime ? row.completionTime : "-"}
                              </p>
                            )}
                            {column === "totalCalories" && (
                              <p>{row.calories ? row.calories : "-"}</p>
                            )}
                            {column === "totalSteps" && (
                              <p>{row.steps ? row.steps : "-"}</p>
                            )}
                            {column === "totalElevationGain" && (
                              <p>
                                {row.elevationGain ? row.elevationGain : "-"}
                              </p>
                            )}
                            {column === "averagePace" && row.type === "RUN" && (
                              <p>{row.averagePace ? row.averagePace : "-"}</p>
                            )}
                            {column === "averageSpeed" &&
                              row.type !== "RUN" && (
                                <p>
                                  {row.averageSpeed ? row.averageSpeed : "-"}
                                </p>
                              )}
                          </TableCell>
                        ))
                      ) : (
                        <>
                          <TableCell component="td" align="center">
                            <p>
                              {row.distance
                                ? parseFloat(
                                    ApplicationUtil.getFormatedDistance(
                                      row.distance
                                    ) / 1000
                                  ).toFixed(2) + " Km"
                                : "-"}
                            </p>
                          </TableCell>

                          <TableCell component="td" align="center">
                            <p>
                              {row.completionTime ? row.completionTime : "-"}
                            </p>
                          </TableCell>
                        </>
                      )}
                      <TableCell component="td" align="center">
                        <p>{row.fetchFrom ? row.fetchFrom : "-"}</p>
                      </TableCell>
                      <TableCell component="td" align="center">
                        {row?.activityUrl ? (
                          <p className={classes.urlLink}>
                            <a href={row?.activityUrl} target="_blank">
                              URL
                            </a>
                          </p>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell component="td" align="center">
                        {row.valid && row.valid ? (
                          <CheckCircleIcon style={{ color: "#40916c" }} />
                        ) : row.validationReason ? (
                          <Popover
                            title={<CancelIcon style={{ color: "#f94144" }} />}
                            message={row && row.validationReason}
                          />
                        ) : (
                          <CancelIcon style={{ color: "#f94144" }} />
                        )}
                      </TableCell>
                      {showActivityScreenshot && (
                        <TableCell component="td" align="center">
                          {row.activityScreenshotStatus === "AVAILABLE" ? (
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                let runnerId =
                                  localStorage.getItem("supportRunnerId");
                                this.viewImage(runnerId, row.id);
                                this.setState({
                                  openActivityScreenshot: true,
                                });
                              }}
                              style={{ color: "#F79824", cursor: "pointer" }}
                            >
                              {Properties.Label_View}
                            </span>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      )}
                      {this.state.userType === "SUPPORT" && (
                        <TableCell component="td" align="right">
                          {row?.approved === false && (
                            <span
                              className={classes.textLink}
                              onClick={() => {
                                let time = row?.completionTime?.split(":");
                                let runnerId =
                                  localStorage.getItem("supportRunnerId");

                                this.setState({
                                  openApproveForm: true,
                                  activityId: row?.id,
                                  activityType: row?.subActivityType,
                                  totalSteps: row?.steps,
                                  totalActivityDistance: parseFloat(
                                    ApplicationUtil.getFormatedDistance(
                                      row?.distance
                                    ) / 1000
                                  ).toFixed(2),
                                  activityUrl: row?.activityUrl,
                                  totalActivityTime: row?.completionTime,
                                  hour: {
                                    label: row?.completionTime ? time[0] : "",
                                    value: row?.completionTime ? time[0] : "",
                                  },
                                  minute: {
                                    label: row?.completionTime ? time[1] : "",
                                    value: row?.completionTime ? time[1] : "",
                                  },
                                  second: {
                                    label: row?.completionTime ? time[2] : "",
                                    value: row?.completionTime ? time[2] : "",
                                  },
                                  averagePace: row?.averagePace,
                                  averageSpeed: row?.averageSpeed,
                                  totalCalories: row?.calories,
                                  totalElevationGain: row?.elevationGain,
                                  activityDisplayColumn:
                                    row?.displayColumns.split(","),
                                });
                                this.viewImage(runnerId, row.id);
                              }}
                            >
                              {/* Approve */}
                              <IconButton>
                                <Tooltip title="Approval pending">
                                  <WarningIcon style={{ color: "red" }} />
                                </Tooltip>
                              </IconButton>
                            </span>
                          )}
                          {row?.valid && (
                            <IconButton
                              onClick={() => {
                                this.setState({
                                  openInvalidDialog: true,
                                  openFeedbackForm: false,
                                  activityId: row.id,
                                  // generalActivityId: row?.id,
                                });
                              }}
                            >
                              <Tooltip title="Mark as invalid">
                                <AssignmentLateIcon style={{ color: "red" }} />
                              </Tooltip>
                            </IconButton>
                          )}
                          <IconButton
                            onClick={() => {
                              this.setState({
                                openFeedbackForm: true,
                                activityId: row?.id,
                                activityDetails: row,
                                showGlobalComment: false,
                              });
                            }}
                          >
                            {row?.comments ? (
                              <Badge
                                badgeContent={row.comments?.length}
                                color="primary"
                              >
                                <Forum
                                  style={{
                                    color: "#F79824",
                                  }}
                                />
                              </Badge>
                            ) : (
                              <Forum
                                style={{
                                  color: "#F79824",
                                }}
                              />
                            )}
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <ImageDialogBox
          imagePath={this.state.image_src}
          open={this.state.imageDialog}
          title={Properties.Label_PostRunPicture}
          onClick={() => this.setState({ imageDialog: false })}
          closeDisloag={() => this.setState({ imageDialog: false })}
        />
        <DialogBox
          open={this.state.open}
          fullWidth
          maxWidth={"md"}
          header={"Screenshot Preview"}
          message={this.getDialogComponent()}
          onClick={() => {
            this.setState({ open: false });
          }}
          closeDisloag={() => {
            this.setState({ open: false });
          }}
        />
        <DialogBox
          open={this.state.openActivityScreenshot}
          fullWidth
          // maxWidth={"md"}
          header={"Screenshot Preview"}
          message={this.getDialogComponent()}
          onClick={() => {
            this.setState({ openActivityScreenshot: false });
          }}
          closeDisloag={() => {
            this.setState({ openActivityScreenshot: false });
          }}
        />
        <DialogBox
          fullWidth
          maxWidth={"lg"}
          header={"Approve Activity"}
          open={this.state.openApproveForm}
          message={this.getApproveForm()}
          onClick={() => {
            this.setState({
              openApproveForm: false,
            });
          }}
          closeDisloag={() => {
            this.setState({
              openApproveForm: false,
            });
          }}
        />

        <DialogBox
          fullWidth
          maxWidth={"md"}
          header={openInvalidDialog ? "Invalid Reason" : "Add Feedback"}
          open={openInvalidDialog || openFeedbackForm}
          message={
            openInvalidDialog ? this.getInvalidForm() : this.getFeedbackForm()
          }
          onClick={() => {
            this.setState({
              openInvalidDialog: false,
              openFeedbackForm: false,
            });
          }}
          closeDisloag={() => {
            this.setState({
              openInvalidDialog: false,
              openFeedbackForm: false,
            });
          }}
        />

        {/* global menu */}
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={() => {
            this.setState({ menuAnchorEl: null });
          }}
          style={{ marginTop: "40px" }}
        >
          <MenuItem
            onClick={() => {
              const { checkboxArr } = this.state;
              let activityIds = [];

              checkboxArr.forEach((e) => {
                if (e.check) {
                  activityIds.push(e.id);
                } else {
                  return;
                }
              });
              if (activityIds.length > 0) {
                this.setState({
                  showCancelDialog: true,
                });
              } else {
                Snackbar.show({
                  variant: "error",
                  isAutoHide: true,
                  preventDuplicate: true,
                  message: Properties.SelectActivityToDeleteLabel,
                });
              }
            }}
          >
            {Properties.DeleteActivityLabel}
          </MenuItem>
        </Menu>
        {/* row wise menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => {
            this.setState({ anchorEl: null });
          }}
          style={{ marginTop: "40px" }}
        >
          <MenuItem
            onClick={() => {
              this.setState({
                showCancelDialog: true,
              });
            }}
          >
            {Properties.DeleteActivityLabel}
          </MenuItem>

          {this.state.allowActivityEdit === true && (
            <MenuItem onClick={() => this.handleActivityUpdateClick()}>
              {Properties.UpdateActivityLabel}
            </MenuItem>
          )}
        </Menu>

        {/* delete activity warning */}
        <DialogBox
          signup={true}
          open={this.state.showCancelDialog}
          message={this.getDialogMsg()}
          header={this.state.dialogHeader}
          label={"No"}
          isSmall={true}
          onClick={() => {
            this.setState({ showCancelDialog: false });
          }}
          labelTwo={"Yes"}
          onClickTwo={() => {
            this.handleActivityDelete();
          }}
          closeDisloag={() => {
            this.setState({ showCancelDialog: false });
          }}
        />

        {/* Update activity */}

        <DialogBox
          fullWidth
          maxWidth={"md"}
          open={this.state.showActivityUploadForm}
          header={"Update Activity"}
          message={this.getUpdateActivity()}
          onClick={() => {
            this.setState({
              showActivityUploadForm: false,
            });
          }}
          closeDisloag={() => {
            this.setState({
              showActivityUploadForm: false,
            });
          }}
        />
      </>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ActivityList);
