import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { Paper } from "@material-ui/core";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      cursor: "pointer",
      // width: theme.spacing(16),
      // height: theme.spacing(16),
    },
  },
});

const reports = [
  { id: 1, name: "Activity Distribution" },
  { id: 2, name: "Program Effectiveness" },
];

export class OrganizerReports extends Component {
  render() {
    const { classes, history } = this.props;
    return (
      <div className={classes.root}>
        {reports.map((report) => {
          return (
            <Paper
              variant="outlined"
              onClick={() => {
                history.push("list/details");
                localStorage.setItem("id", report.id);
              }}
            >
              <p>{report.name}</p>
            </Paper>
          );
        })}

        {}
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(OrganizerReports);
