import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  NativeSelect,
  FormControl,
  Menu,
  MenuItem,
  Typography,
  TextareaAutosize,
} from "@material-ui/core";

import {
  addActivityFeedback,
  deleteEventParticipants,
  downloadBIBMappingTemplate,
  downloadCSV,
  downloadCSVFormat,
  generateDeliverables,
  getEventDetails,
  getOrganizerRegistrationList,
  getParticipantsList,
  uploadEventCSVFile,
  uploadMatToBibMappingFile,
} from "../../../../apis/eventService";
import { KeyboardDatePicker } from "@material-ui/pickers";
import OrganizerTableList from "./OrganizerTableList";
import Snackbar from "./../../../common/Snackbar";
import Radio from "@material-ui/core/Radio";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import ListPageLoader from "../../../common/ListPageLoader";
import { CSVLink, CSVDownload } from "react-csv";
import { FilterSVGIcon, IconCalendarBlue } from "../../../common/SvgIcon";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import ApplicationConstants from "../../../../constants/ApplicationConstants";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import DialogBox from "../../../common/DialogBox";
import Properties from "../../../../properties/Properties";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
// import FilterIcon from "../../../common/FilterIcon";
import DrawerFilter from "../../../common/DrawerFilter";
import {
  updateParticipantSetFilters,
  updateSupportDTODetails,
  updateSupportSelectedEvent,
} from "../../../../redux/supportUser";
import { connect } from "react-redux";
const styles = (theme) => ({
  importContainerCss: {
    margin: "10px",
    padding: "0px 20px",
  },
  mainBody: {
    // marginLeft: 80,
  },
  searchFilter__row: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    marginBottom: "20px",
    "& .search__select": {
      display: "flex",
      alignItems: "flex-end",
    },
    "& .search ": {
      display: "flex",
      alignItems: "center",
      border: "1px solid #bfc0c0",
      borderRadius: "5px",
      height: "35px",
      "& > input": {
        height: "30px",
        outline: "none",
        border: "none",
        fontSize: "16px",
        paddingLeft: "10px",
        borderRight: "2px solid #bfc0c0",
      },
      "& .search__icon": {
        padding: "5px",
        cursor: "pointer",
      },
    },
    "& .filters": {
      display: "flex",
      alignItems: "flex-end",
      fontFamily: "Rubik,sans-serif",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    fontFamily: "Rubik, sans-serif",
  },
  select: {
    marginRight: theme.spacing(1),
  },
  selectActivity: {
    // marginTop: theme.spacing(2),
    textAlign: "center",
    color: "#727272",
    fontSize: 18,
  },
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",

    position: "relative",
    bottom: 20,
    marginRight: theme.spacing(8),
  },
  totalRec: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    fontWeight: 600,
    width: "15%",
    marginRight: "10px",
    background: "rgba( 149, 213, 178, 0.50 )",
    boxShadow: "0 4px 3px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    // position: "relative",
    // bottom: 20,
  },
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },
  customDate: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  actions: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  editIcon: {
    color: "#09456C",
    cursor: "pointer",
  },
  labelCss: {
    color: "#727272",
  },
  mandatoryCss: { color: "red" },
  approvalForm: {
    // width: "800px",
    // height: "auto",
    padding: "8px 24px",
  },
  messageType: {
    marginBottom: theme.spacing(2),
  },
  drawerContainer: {
    margin: 20,
    // padding:20,
    width: "300px",
    "& .title": {
      fontSize: 20,
      padding: 10,
      // borderBottom:'1px solid gray',
      // width:'100%'
    },
  },
  drawerListRow: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  participantNoResult: {
    color: "gray",
    margin: "0 auto",
    fontSize: "25px",
    textAlign: "center",
  },
  filtericonStyling: {
    // width: "20px",
    // height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
    color: "#09456C",
    cursor: "pointer",
    margin: theme.spacing(1),
  },
});

class OrganizerRegistrationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSupport: null,
      eventDetailsData: null,
      participantsList: null,
      activityType: "",
      activityOptions: [],
      categoryValue: "",
      categoryOptions: [],
      runCategoryOptions: [],
      cycleCategoryOptions: [],
      duathlonCategoryOptions: [],
      stepsCategoryOptions: [],
      dateRangeOptions: [],
      totalRecords: "",
      dateRange: "",
      searchSelectValue: "Name",
      searchValue: "",
      modifyCategoryOptions: [],
      totalRecords: null,
      startDate: moment().subtract(7, "day").format("YYYY/MM/DD"),
      endDate: moment().format("YYYY/MM/DD"),
      deliverableOptions: ApplicationConstants.participantDeliverableTypes,
      deliverableType: "",
      checkboxArr: [],
      openFeedbackForm: false,
      comment: "",
      messageType: "General",
      isDrawerOpen: false,
      onlineRegistration: null,
      registrationListFilters: ApplicationConstants.registrationListFilters,
      consentPresent: null,
      bibGenerated: null,
      includeVolunteer: false,
    };
  }

  componentDidMount() {
    this.initialDataLoad();

    //Dates Range
    let dateRange = [];
    //Last 24 hours --
    let last24Hrs = {
      label: "Last 24 hours",
      value: `${moment().subtract(1, "day").format("YYYY-MM-DD")} ${moment()
        .subtract(1, "day")
        .format("HH:mm:ss")}`,
    };
    //Last 3 days --
    let last3days = {
      label: "Last 3 days",
      value: `${moment().subtract(3, "day").format("YYYY-MM-DD")} ${moment()
        .subtract(3, "day")
        .format("HH:mm:ss")}`,
    };
    //Last 7 days --
    let last7days = {
      label: "Last 7 days",
      value: `${moment().subtract(7, "day").format("YYYY-MM-DD")} ${moment()
        .subtract(7, "day")
        .format("HH:mm:ss")}`,
    };
    //Last month --
    let lastMonth = {
      label: "Last month",
      value: `${moment().subtract(1, "months").format("YYYY-MM-DD")} ${moment()
        .subtract(1, "months")
        .format("HH:mm:ss")}`,
    };
    let customDate = {
      label: "Custom Date",
      value: "Custom Date",
    };
    dateRange.push(last24Hrs, last3days, last7days, lastMonth, customDate);
    if (dateRange.length > 0) {
      this.setState({
        dateRangeOptions: dateRange,
        // dateRange: dateRange[0]
      });
    }
  }
  //   componentWillUnmount() {
  //     this.props.updateParticipantSetFilters(null);
  //     alert('hi')
  //  }

  /**
   * vishal
   * Created for getting a previous filter values  when page refresh or else
   * @returns
   */
  getPrevFilters = () => {
    const {
      consentPresent,
      bibGenerated,
      onlineRegistration,
      includeVolunteer,
      registrationListFilters,
    } = this.state;

    const _consentPresent =
      this.props.participantFilters?.consentPresent === true
        ? this.props.participantFilters.consentPresent
        : this.props.participantFilters?.consentPresent === false
        ? this.props.participantFilters?.consentPresent
        : consentPresent;

    const _bibGenerated =
      this.props.participantFilters?.bibGenerated === true
        ? this.props.participantFilters?.bibGenerated
        : this.props.participantFilters?.bibGenerated === false
        ? this.props.participantFilters?.bibGenerated
        : bibGenerated;
    const _onlineRegistration =
      this.props.participantFilters?.onlineRegistration === true
        ? this.props.participantFilters?.onlineRegistration
        : this.props.participantFilters?.onlineRegistration === false
        ? this.props.participantFilters?.onlineRegistration
        : onlineRegistration;
    const _includeVolunteer =
      this.props.participantFilters?.includeVolunteer === true
        ? this.props.participantFilters?.includeVolunteer
        : this.props.participantFilters?.includeVolunteer === false
        ? this.props.participantFilters?.includeVolunteer
        : includeVolunteer;

    return {
      _consentPresent,
      _bibGenerated,
      _onlineRegistration,
      _includeVolunteer,
    };
  };

  initialDataLoad = () => {
    const {
      consentPresent,
      bibGenerated,
      onlineRegistration,
      includeVolunteer,
      registrationListFilters,
    } = this.state;

    let runCatOptions = [];
    let cycleCatOptions = [];
    let actOptions = [];
    let duathlonOptions = [];
    let stepsOptions = [];
    let catOptionsArr = [];
    let eventId = ApplicationUtil.getSupportsEventID();
    let requestparam = "REGISTER_EVENT";
    let participantObj = {
      consentPresent: consentPresent,
      bibGenerated: bibGenerated,
      onlineRegistration: onlineRegistration,
      includeVolunteer: includeVolunteer,
    };
    getEventDetails(requestparam, eventId).then((res) => {
      if (res.data) {
        this.props.updateSupportSelectedEvent(res.data);
        let data = ApplicationUtil.getSupportDetails();
        this.setState({
          eventDetailsData: res.data,
          isSupport: data.role === "SUPPORT" ? true : false,
        });
        if (res.data.eventRunCategories) {
          catOptionsArr = res.data.eventRunCategories.map((r) => ({
            ...r,
            label: r.categoryName,
            value: r.id,
            category: r.category,
          }));

          res.data.eventRunCategories.map(
            ({
              activityType,
              eventSupportedActivityType,
              categoryName,
              id,
            }) => {
              if (eventSupportedActivityType.activityType.type === "RUN") {
                runCatOptions.push({
                  label: categoryName,
                  value: categoryName,
                  id: id,
                  type: eventSupportedActivityType.activityType.type,
                  eventSupportedActivityTypeId: eventSupportedActivityType.id,
                });
              }

              if (eventSupportedActivityType.activityType.type === "CYCLE") {
                cycleCatOptions.push({
                  label: categoryName,
                  value: categoryName,
                  id: id,
                  type: eventSupportedActivityType.activityType.type,
                  eventSupportedActivityTypeId: eventSupportedActivityType.id,
                });
              }
              if (eventSupportedActivityType.activityType.type === "DUATHLON") {
                duathlonOptions.push({
                  label: categoryName,
                  value: categoryName,
                  id: id,
                  type: eventSupportedActivityType.activityType.type,
                  eventSupportedActivityTypeId: eventSupportedActivityType.id,
                });
              }
              if (eventSupportedActivityType.activityType.type === "STEPS") {
                stepsOptions.push({
                  label: categoryName,
                  value: categoryName,
                  id: id,
                  type: eventSupportedActivityType.activityType.type,
                  eventSupportedActivityTypeId: eventSupportedActivityType.id,
                });
              }
            }
          );

          if (runCatOptions.length > 0) {
            // const filteredArr = runCatOptions.reduce((acc, current) => {
            //   const x = acc.find((item) => item.type === current.type);
            //   if (!x) {
            //     return acc.concat([current]);
            //   } else {
            //     return acc;
            //   }
            // }, []);

            this.setState({
              runCategoryOptions: runCatOptions, //filteredArr,
              categoryOptions: runCatOptions, //filteredArr,
              // categoryValue: runCatOptions[0],
            });
          }
          if (cycleCatOptions.length > 0) {
            // const filteredArr = cycleCatOptions.reduce((acc, current) => {
            //   const x = acc.find((item) => item.type === current.type);
            //   if (!x) {
            //     return acc.concat([current]);
            //   } else {
            //     return acc;
            //   }
            // }, []);
            this.setState({
              cycleCategoryOptions: cycleCatOptions, //filteredArr,
            });
          }
          if (duathlonOptions.length > 0) {
            this.setState({
              duathlonCategoryOptions: duathlonOptions,
            });
          }
          if (stepsOptions.length > 0) {
            this.setState({
              stepsCategoryOptions: stepsOptions,
            });
          }
          if (catOptionsArr.length > 0) {
            this.setState({
              modifyCategoryOptions: catOptionsArr,
            });
          }
        }

        if (res.data.activities) {
          res.data.activities.map(
            ({ displayName, type, id, eventSupportedActivityTypeId }) => {
              actOptions.push({
                label: displayName,
                value: displayName,
                type,
                id,
                eventSupportedActivityTypeId,
              });
            }
          );
        }

        if (!res.data.activities && res.data.eventRunCategories) {
          res.data.eventRunCategories.map(({ eventSupportedActivityType }) => {
            actOptions.push({
              label: eventSupportedActivityType.activityType.displayName,
              value: eventSupportedActivityType.activityType.displayName,
              type: eventSupportedActivityType.activityType.type,
              id: eventSupportedActivityType.activityType.id,
              eventSupportedActivityTypeId: eventSupportedActivityType.id,
            });
          });
        }
        if (actOptions.length > 0) {
          const filteredArr = actOptions.reduce((acc, current) => {
            const x = acc.find(
              (item) =>
                item.eventSupportedActivityTypeId ===
                current.eventSupportedActivityTypeId
            );
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
          this.setState({
            activityOptions: filteredArr,
            // activityType: actOptions[0],
          });
        }
      }
    });

    //getting a previous filter values
    const {
      _consentPresent,
      _bibGenerated,
      _onlineRegistration,
      _includeVolunteer,
    } = this.getPrevFilters();

    let _updateFilterDataFromPrevData = registrationListFilters?.map(
      (filter) => {
        const filterType = filter?.filterType;
        const val =
          filterType === "Consent"
            ? _consentPresent === true
              ? "consentProvided"
              : _consentPresent === false
              ? "consentNotProvided"
              : null
            : filterType === "bib"
            ? _bibGenerated === true
              ? "bibGenerated"
              : _bibGenerated === false
              ? "bibNotGenerated"
              : null
            : filterType === "register"
            ? _onlineRegistration === true
              ? "online"
              : _onlineRegistration === false
              ? "offline"
              : null
            : filterType === "group"
            ? _includeVolunteer === true
              ? "volunteer"
              : _includeVolunteer === false
              ? "participants"
              : "all"
            : null;

        return {
          ...filter,
          selectedValue: val,
        };
      }
    );
    let requestParams = {
      pageNumber: 0,
      pageCount: 20,
      categoryId: undefined,
      consentPresent: _consentPresent,
      bibGenerated: _bibGenerated,
      onlineRegistration: _onlineRegistration,
      includeVolunteer: _includeVolunteer,
    };
    if (this.props.participantFilters !== null) {
      this.setState({
        consentPresent: this.props.participantFilters?.consentPresent,
        bibGenerated: this.props.participantFilters?.bibGenerated,
        onlineRegistration: this.props.participantFilters?.onlineRegistration,
        includeVolunteer: this.props.participantFilters?.includeVolunteer,
      });
    } else {
      this.props.updateParticipantSetFilters(participantObj);
    }
    getOrganizerRegistrationList(requestParams).then((res) => {
      if (res.data) {
        this.setState({
          participantsList: res.data.data,
          totalRecords: res.data.totalRecords,
        });
      }
    });
    this.setState({
      paramObj: requestParams,
      registrationListFilters: _updateFilterDataFromPrevData || [],
    });
  };

  getActivityId = (value) => {
    const { activityOptions } = this.state;
    let type = activityOptions.find(
      (x) => x.eventSupportedActivityTypeId === value
    );

    let id;
    if (type) {
      id = Number(type.id);
      return id;
    } else {
      return "";
    }
  };
  activityHandleChange = (event) => {
    const {
      dateRange,
      categoryValue,
      searchSelectValue,
      searchValue,
      startDate,
      endDate,
      eventSupportedActivityTypeId,
      activityOptions,
      runCategoryOptions,
      cycleCategoryOptions,
      duathlonCategoryOptions,
      stepsCategoryOptions,
      consentPresent,
      bibGenerated,
      onlineRegistration,
      includeVolunteer,
    } = this.state;
    let value = event.target.value !== "" ? Number(event.target.value) : "";
    let activityId = this.getActivityId(value);

    this.setState({ activityType: value });

    if (activityId === 1) {
      var arr = [];
      runCategoryOptions.map((category) => {
        if (category.eventSupportedActivityTypeId === value) {
          arr.push(category);
        }
      });
      this.setState({
        categoryOptions: arr,
      });
    }
    if (activityId === 2) {
      var arr = [];
      cycleCategoryOptions.map((category) => {
        if (category.eventSupportedActivityTypeId === value) {
          arr.push(category);
        }
      });
      this.setState({
        categoryOptions: arr,
      });
    }
    if (activityId === 3) {
      var arr = [];
      duathlonCategoryOptions.map((category) => {
        if (category.eventSupportedActivityTypeId === value) {
          arr.push(category);
        }
      });
      this.setState({
        categoryOptions: arr,
      });
    }
    if (activityId === 4) {
      var arr = [];
      stepsCategoryOptions.map((category) => {
        if (category.eventSupportedActivityTypeId === value) {
          arr.push(category);
        }
      });
      this.setState({
        categoryOptions: arr,
      });
    }

    //  ----------x--------- was already commentted
    // if (value === "") {
    //   this.setState({
    //     categoryOptions: this.state.runCategoryOptions,
    //     categoryValue: "",
    //   });
    // }
    // -------x--------
    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }
    let requestParams = {
      startDate:
        dateRange === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : dateRange,
      endDate:
        dateRange === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("HH:mm:ss")}`,
      activityTypeId: value ? activityId : "",
      eventActivityTypeId: value,
      // categoryId: categoryValue,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      consentPresent: consentPresent,
      bibGenerated: bibGenerated,
      includeVolunteer: includeVolunteer,
      onlineRegistration: onlineRegistration,
    };
    getOrganizerRegistrationList(requestParams).then((res) => {
      if (res.data) {
        this.setState({
          participantsList: res.data.data,
          totalRecords: res.data.totalRecords,
        });
      } else {
        this.setState({ participantsList: [] });
        Snackbar.show({
          variant: "info",
          isAutoHide: true,
          preventDuplicate: true,
          message: `No participants are available for this category please select
              another category`,
        });
      }
    });
    this.setState({ paramObj: requestParams });
  };
  categoryHandleChange = (event) => {
    const {
      dateRange,
      activityType,
      searchSelectValue,
      searchValue,
      startDate,
      endDate,
      consentPresent,
      bibGenerated,
      includeVolunteer,
      onlineRegistration,
    } = this.state;
    this.setState({ categoryValue: event.target.value });
    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }
    let requestParams = {
      startDate:
        dateRange === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : dateRange,
      endDate:
        dateRange === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("HH:mm:ss")}`,
      eventActivityTypeId: activityType,
      activityTypeId: this.getActivityId(activityType),
      categoryId: event.target.value,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      consentPresent: consentPresent,
      bibGenerated: bibGenerated,
      includeVolunteer: includeVolunteer,
      onlineRegistration: onlineRegistration,
    };
    getOrganizerRegistrationList(requestParams).then((res) => {
      if (res.data) {
        this.setState({
          participantsList: res.data.data,
          totalRecords: res.data.totalRecords,
        });
      } else {
        this.setState({ participantsList: [] });
        Snackbar.show({
          variant: "info",
          isAutoHide: true,
          preventDuplicate: true,
          message: `No participants are available for this category please select
          another category`,
        });
      }
    });
    this.setState({ paramObj: requestParams });
  };
  dateHandleChange = (event) => {
    const {
      categoryValue,
      activityType,
      searchSelectValue,
      searchValue,
      startDate,
      endDate,
      consentPresent,
      onlineRegistration,
      bibGenerated,
      includeVolunteer,
    } = this.state;
    this.setState({ dateRange: event.target.value });
    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }

    let requestParams = {
      startDate:
        event.target.value === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : event.target.value,
      endDate:
        event.target.value === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("HH:mm:ss")}`,
      eventActivityTypeId: activityType,
      activityTypeId: this.getActivityId(activityType),
      categoryId: categoryValue,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      consentPresent: consentPresent,
      bibGenerated: bibGenerated,
      includeVolunteer: includeVolunteer,
      onlineRegistration: onlineRegistration,
    };
    getOrganizerRegistrationList(requestParams).then((res) => {
      if (res.data) {
        this.setState({
          participantsList: res.data.data,
          totalRecords: res.data.totalRecords,
        });
      } else {
        this.setState({ participantsList: [] });
        Snackbar.show({
          variant: "info",
          isAutoHide: true,
          preventDuplicate: true,
          message: `No participants are available for this category please select
            another category`,
        });
      }
    });
    this.setState({ paramObj: requestParams });
  };
  consentHandleChange = (event) => {
    const {
      categoryValue,
      activityType,
      searchSelectValue,
      searchValue,
      startDate,
      endDate,
      dateRange,
      consentPresent,
      bibGenerated,
      includeVolunteer,
      onlineRegistration,
    } = this.state;
    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }

    let requestParams = {
      startDate:
        dateRange === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : dateRange,
      endDate:
        dateRange === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("HH:mm:ss")}`,
      eventActivityTypeId: activityType,
      activityTypeId: this.getActivityId(activityType),
      categoryId: categoryValue,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      consentPresent: consentPresent,
      bibGenerated: bibGenerated,
      includeVolunteer: includeVolunteer,
      onlineRegistration: onlineRegistration,
    };
    getOrganizerRegistrationList(requestParams).then((res) => {
      if (res.data) {
        this.setState({
          participantsList: res.data.data,
          totalRecords: res.data.totalRecords,
        });
      } else {
        this.setState({ participantsList: [] });
        Snackbar.show({
          variant: "info",
          isAutoHide: true,
          preventDuplicate: true,
          message: `No participants are available for this category please select
          another category`,
        });
      }
    });
    this.setState({ paramObj: requestParams });
  };
  bibGeneratedHandleChange = (event) => {
    const {
      categoryValue,
      activityType,
      searchSelectValue,
      searchValue,
      startDate,
      endDate,
      dateRange,
      consentPresent,
      bibGenerated,
      includeVolunteer,
      onlineRegistration,
    } = this.state;
    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }

    let requestParams = {
      startDate:
        dateRange === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : dateRange,
      endDate:
        dateRange === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("HH:mm:ss")}`,
      eventActivityTypeId: activityType,
      activityTypeId: this.getActivityId(activityType),
      categoryId: categoryValue,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      consentPresent: consentPresent,
      bibGenerated: bibGenerated,
      includeVolunteer: includeVolunteer,
      onlineRegistration: onlineRegistration,
    };
    getOrganizerRegistrationList(requestParams).then((res) => {
      if (res.data) {
        this.setState({
          participantsList: res.data.data,
          totalRecords: res.data.totalRecords,
        });
      } else {
        this.setState({ participantsList: [] });
        Snackbar.show({
          variant: "info",
          isAutoHide: true,
          preventDuplicate: true,
          message: `No participants are available for this category please select
          another category`,
        });
      }
    });
    this.setState({ paramObj: requestParams });
  };
  selectHandleValue = (event) => {
    this.setState({ searchSelectValue: event.target.value });
  };
  searchHandleChange = (event) => {
    this.setState({ searchValue: event.target.value });
    if (!event.target.value) {
      this.initialDataLoad();
    }
  };
  searchHandleClick = () => {
    const {
      searchSelectValue,
      searchValue,
      categoryValue,
      activityType,
      dateRange,
      startDate,
      endDate,
      consentPresent,
      bibGenerated,
      includeVolunteer,
      onlineRegistration,
    } = this.state;

    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }
    let requestParams = {
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      startDate:
        dateRange === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : dateRange,
      endDate:
        dateRange === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("HH:mm:ss")}`,
      eventActivityTypeId: activityType,
      activityTypeId: this.getActivityId(activityType),
      categoryId: categoryValue,
      consentPresent: consentPresent,
      bibGenerated: bibGenerated,
      includeVolunteer: includeVolunteer,
      onlineRegistration: onlineRegistration,
    };

    if (
      requestParams.name !== "" ||
      requestParams.email !== "" ||
      requestParams.bib !== "" ||
      requestParams.contact !== ""
    ) {
      getOrganizerRegistrationList(requestParams).then((res) => {
        if (res.data) {
          this.setState({
            participantsList: res.data.data,
            totalRecords: res.data.totalRecords,
          });
        }
      });
      this.setState({ paramObj: requestParams });
    }
  };
  getList = (isFirstPage, page, updatedFilters = null) => {
    const {
      dateRange,
      activityType,
      searchSelectValue,
      searchValue,
      categoryValue,
      startDate,
      endDate,
      consentPresent,
      bibGenerated,
      includeVolunteer,
      onlineRegistration,
    } = this.state;
    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }
    let requestParams = {
      startDate:
        dateRange === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : dateRange,
      endDate:
        dateRange === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("HH:mm:ss")}`,
      eventActivityTypeId: activityType,
      activityTypeId: this.getActivityId(activityType),
      categoryId: categoryValue,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      pageNumber: page ? page : 0,
      pageCount: 20,
      consentPresent: updatedFilters
        ? updatedFilters?.consentPresent
        : consentPresent,
      bibGenerated: updatedFilters
        ? updatedFilters?.bibGenerated
        : bibGenerated,
      onlineRegistration: updatedFilters
        ? updatedFilters?.onlineRegistration
        : onlineRegistration,
      includeVolunteer: updatedFilters
        ? updatedFilters?.includeVolunteer
        : includeVolunteer,
    };

    getOrganizerRegistrationList(requestParams).then((res) => {
      if (res?.data) {
        let newData = page
          ? this.state.participantsList?.concat(res?.data?.data)
          : res?.data?.data;

        let updatedNewData =
          newData?.indexOf(undefined) > -1
            ? newData?.filter((item) => item !== undefined)
            : newData;

        this.setState({
          participantsList: updatedNewData,
          totalRecords: res?.data?.totalRecords,
        });
      } else {
        this.setState({ participantsList: [] });
        Snackbar.show({
          variant: "info",
          isAutoHide: true,
          preventDuplicate: true,
          message: `No participants are available for this category please select
          another category`,
        });
      }
    });
    this.setState({ paramObj: requestParams });
  };
  registerHandleChange = (page) => {
    const {
      dateRange,
      activityType,
      searchSelectValue,
      searchValue,
      categoryValue,
      startDate,
      endDate,
      consentPresent,
      bibGenerated,
      includeVolunteer,
      onlineRegistration,
    } = this.state;

    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }
    let requestParams = {
      startDate:
        dateRange === "Custom Date"
          ? `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : dateRange,
      endDate:
        dateRange === "Custom Date"
          ? `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("HH:mm:ss")}`,
      eventActivityTypeId: activityType,
      activityTypeId: this.getActivityId(activityType),
      categoryId: categoryValue,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      pageNumber: page ? page : 0,
      pageCount: 20,
      consentPresent: consentPresent,
      bibGenerated: bibGenerated,
      includeVolunteer: includeVolunteer,
      onlineRegistration: onlineRegistration,
    };
    getOrganizerRegistrationList(requestParams).then((res) => {
      if (res?.data) {
        let newData = page
          ? this.state.participantsList.concat(res?.data?.data)
          : res?.data?.data;

        this.setState({
          participantsList:
            newData?.indexOf(undefined) > -1
              ? newData.filter((item) => item !== undefined)
              : newData,
          totalRecords: res?.data?.totalRecords,
        });
      } else {
        this.setState({ participantsList: [] });
        Snackbar.show({
          variant: "info",
          isAutoHide: true,
          preventDuplicate: true,
          message: `No participants are available for this category please select
          another category`,
        });
      }
    });
    this.setState({ paramObj: requestParams });
  };
  customStartDate = (date) => {
    this.setState({ startDate: date });
    const {
      categoryValue,
      activityType,
      searchSelectValue,
      searchValue,
      endDate,
      consentPresent,
      bibGenerated,
      includeVolunteer,
      onlineRegistration,
    } = this.state;

    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }

    let requestParams = {
      startDate: `${moment(date).format("YYYY-MM-DD")} ${moment().format(
        "HH:mm:ss"
      )}`,
      endDate: `${moment(endDate).format("YYYY-MM-DD")} ${moment().format(
        "HH:mm:ss"
      )}`,
      eventActivityTypeId: activityType,
      activityTypeId: this.getActivityId(activityType),
      categoryId: categoryValue,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      consentPresent: consentPresent,
      bibGenerated: bibGenerated,
      includeVolunteer: includeVolunteer,
      onlineRegistration: onlineRegistration,
    };
    getOrganizerRegistrationList(requestParams).then((res) => {
      if (res.data) {
        this.setState({
          participantsList: res.data.data,
          totalRecords: res.data.totalRecords,
        });
      } else {
        this.setState({ participantsList: [] });
        Snackbar.show({
          variant: "info",
          isAutoHide: true,
          preventDuplicate: true,
          message: `No participants are available for this category please select
            another category`,
        });
      }
    });

    this.setState({ paramObj: requestParams });
  };
  customEndDate = (date) => {
    this.setState({ endDate: date });
    const {
      categoryValue,
      activityType,
      searchSelectValue,
      searchValue,
      startDate,
      consentPresent,
      bibGenerated,
      includeVolunteer,
      onlineRegistration,
    } = this.state;

    let name;
    let bib;
    let email;
    let contact;
    if (searchSelectValue === "Name") {
      name = searchValue;
    } else if (searchSelectValue === "BIB") {
      bib = searchValue;
    } else if (searchSelectValue === "Email") {
      email = searchValue;
    } else if (searchSelectValue === "Contact") {
      contact = searchValue;
    }

    let requestParams = {
      startDate: `${moment(startDate).format("YYYY-MM-DD")} ${moment().format(
        "HH:mm:ss"
      )}`,
      endDate: `${moment(date).format("YYYY-MM-DD")} ${moment().format(
        "HH:mm:ss"
      )}`,
      eventActivityTypeId: activityType,
      activityTypeId: this.getActivityId(activityType),
      categoryId: categoryValue,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
      consentPresent: consentPresent,
      bibGenerated: bibGenerated,
      includeVolunteer: includeVolunteer,
      onlineRegistration: onlineRegistration,
    };
    getOrganizerRegistrationList(requestParams).then((res) => {
      if (res.data) {
        this.setState({
          participantsList: res.data.data,
          totalRecords: res.data.totalRecords,
        });
      } else {
        this.setState({ participantsList: [] });
        Snackbar.show({
          variant: "info",
          isAutoHide: true,
          preventDuplicate: true,
          message: `No participants are available for this category please select
            another category`,
        });
      }
    });
    this.setState({ paramObj: requestParams });
  };
  getDeliverable = (value) => {
    const { checkboxArr, isAllChecked } = this.state;
    let type = value;
    this.setState({ deliverableType: type });
    let arr = [];
    checkboxArr.map((check) => {
      if (check.check) {
        arr.push(check.id);
      }
    });
    if (arr.length > 0 || isAllChecked) {
      let requestParam = {
        // runnerIds: isAllChecked ? "" : arr,
        runnerIds: arr,
        regenerate: true,
      };

      generateDeliverables(type, requestParam);
    } else {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select users",
      });
    }
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  downloadCSVFile = () => {
    const { paramObj } = this.state;
    downloadCSV(paramObj);
  };

  handleImportClick = () => {
    this.setState({
      openImportDialog: true,
      anchorEl: null,
    });
  };

  handleImportClose = () => {
    this.setState({
      openImportDialog: false,
    });
  };
  handleCSVFormatDownload = () => {
    downloadCSVFormat().then((res) => {});
  };
  getImportComponent = () => {
    const { classes } = this.props;
    return (
      <div className={classes.importContainerCss}>
        <div>
          <input
            className={classes.input}
            id="csv-file-input"
            type="file"
            accept=".csv"
            // hidden
            onClick={this.clearImage}
            onChange={this.multipleUpload}
          />
          {/* {this.state.importFile && ( */}
          <SubmitCancelBtn
            style={{ width: "130px" }}
            label="Import"
            type="submit"
            onClick={() => {
              this.handleImportFileUpload();
            }}
            isDisabled={!this.state.importFile}
          />
          {/* // )} */}
        </div>
        <div>
          <a
            style={{ fontSize: "14px" }}
            onClick={() => this.handleCSVFormatDownload()}
          >
            Download CSV File Format
          </a>
        </div>
      </div>
    );
  };

  getFailedImportList = () => {
    const { classes } = this.props;
    const { failedImportsList } = this.state;
    return (
      <div className={classes.importContainerCss}>
        {failedImportsList && failedImportsList.map((ele) => <li>{ele}</li>)}
      </div>
    );
  };

  handleBIBMappingFormatDownload = () => {
    let eventId = ApplicationUtil.getSupportsEventID();
    downloadBIBMappingTemplate(eventId).then((res) => {});
  };
  getImportComponentForBIBMapping = () => {
    const { classes } = this.props;
    return (
      <div className={classes.importContainerCss}>
        <div>
          <input
            className={classes.input}
            id="bibmap-file-input"
            type="file"
            accept=".csv"
            onChange={this.handleBibMappingFileSelection}
          />
          {/* {this.state.importFile && ( */}
          <SubmitCancelBtn
            style={{ width: "130px" }}
            label="Import"
            type="submit"
            onClick={() => {
              this.handleBibMappingFileUpload();
            }}
            isDisabled={!this.state.bibMappingFile}
          />
          {/* // )} */}
        </div>
        <div>
          <a
            style={{ fontSize: "14px" }}
            onClick={() => this.handleBIBMappingFormatDownload()}
          >
            Download Import BIB Format
          </a>
        </div>
      </div>
    );
  };

  multipleUpload = (e) => {
    if (e.target.id == "csv-file-input") {
      let file = e.target.files[0];

      if (file.name.split(".").pop() !== "csv") {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "only csv file is allowed",
        });
      } else {
        this.setState({
          importFile: file,
        });
      }
    }
  };

  handleImportFileUpload = () => {
    let file = this.state.importFile;
    let eventId = ApplicationUtil.getSupportsEventID();
    uploadEventCSVFile(file, eventId).then((res) => {
      let openFailedImport = false;
      if (
        res.data &&
        res.data.failedEmails &&
        res.data.failedEmails.length > 0
      ) {
        openFailedImport = true;
      }
      Snackbar.show({
        variant: "success",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Imported successfully.",
      });
      this.setState({
        openImportDialog: false,
        failedImportsList: res.data.failedEmails,
        openFailedImport: openFailedImport,
      });
    });
  };

  // bib mat mapping files
  handleBibMappingFileSelection = (e) => {
    if (e.target.id == "bibmap-file-input") {
      let file = e.target.files[0];

      if (file.name.split(".").pop() !== "csv") {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "only csv file is allowed",
        });
      } else {
        this.setState({
          bibMappingFile: file,
        });
      }
    }
  };
  handleBibMappingFileUpload = () => {
    let file = this.state.bibMappingFile;
    let eventId = ApplicationUtil.getSupportsEventID();
    uploadMatToBibMappingFile(file, eventId).then((res) => {
      this.setState({
        openImportDialogForBIBMapping: false,
      });
    });
  };

  getDeleteSelected = () => {
    const { checkboxArr, isAllChecked } = this.state;
    let arr = [];
    checkboxArr.map((check) => {
      if (check.check) {
        arr.push(check.id);
      }
    });
    if (arr.length > 0 || isAllChecked) {
      let requestParam = {
        runnerIds: isAllChecked ? "" : arr,
        regenerate: true,
      };

      generateDeliverables(requestParam);
    } else {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select users",
      });
    }
  };
  getDeleteParticipantWarning = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.DeleteParticipantWarning}</span>
        </Typography>
      </div>
    );
  };
  handleParticipantDelete = () => {
    const { deleteParams, participantsList } = this.state;
    deleteEventParticipants(deleteParams).then((res) => {
      if (res?.data?.success?.code === "200") {
        this.setState({ deleteParticipantWarningOpen: false, anchorEl: null });
        let newData = participantsList;
        deleteParams.forEach((element) => {
          let index = newData.findIndex((o) => o.runner.id === element);
          newData.splice(index, 1);
        });

        this.setState({
          participantsList: newData,
        });
      } else {
        this.setState({ deleteParticipantWarningOpen: false, anchorEl: null });
      }
    });
  };
  getFeedbackForm = () => {
    const {
      classes,
      // userMessages
    } = this.props;
    const { comment, messageType } = this.state;

    return (
      <div className={classes.approvalForm}>
        <div
          style={{
            fontWeight: 600,
          }}
        >
          {/* <div style={{ display: "flex", alignItems: "flex-start" }}> */}

          <div className={classes.messageType}>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.helpContainerCss}
            >
              <span className={classes.labelCss}>Message Type</span>
              {/* <span className={classes.mandatoryCss}>*</span> */}
            </Typography>
            <FormControl className={classes.select}>
              <NativeSelect
                className={classes.selectActivity}
                value={messageType}
                onChange={(e) => this.setState({ messageType: e.target.value })}
                style={{ width: 100 }}
              >
                {["Training", "General"]?.map((value, index) => (
                  <option value={value}>{value}</option>
                ))}
              </NativeSelect>
            </FormControl>
          </div>
          <div>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.helpContainerCss}
            >
              <span className={classes.labelCss}>Comment</span>
              <span className={classes.mandatoryCss}>*</span>
            </Typography>
            <TextareaAutosize
              value={comment}
              aria-label="feedback"
              minRows={5}
              // placeholder="Minimum 3 rows"
              style={{ width: 600 }}
              onChange={(e) => {
                this.setState({
                  comment: e.target.value,
                });
              }}
            />
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
          >
            <SubmitCancelBtn
              style={{ width: "200px", marginLeft: "16px" }}
              type="submit"
              // startIcon={
              //   <AddCircle style={{ fontSize: "25px", color: "#fff" }} />
              // }
              label={"Add"}
              onClick={() => this.addComment()}
            />
          </div>
        </div>
      </div>
    );
  };
  addComment = () => {
    const {
      // activityId,
      comment,
      // eventId,
      // runnerId,
      // activityDetails,
      // showGlobalComment,
      messageType,
    } = this.state;
    let eventId = ApplicationUtil.getSupportsEventID();
    let requestParams = {
      message: comment,
      eventId: eventId,
      recipientType: "EVENT",
      objectId: eventId,

      // recipientId: runnerId,
    };
    if (messageType) {
      requestParams.messageType = messageType.toUpperCase();
    }

    if (comment?.trim() === "" || comment === undefined || comment === null) {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please add a comment",
      });
    } else {
      addActivityFeedback(requestParams)
        .then((res) => {
          // if (showGlobalComment) {
          //   let data = this.props.userMessages? this.props.userMessages :[];
          //   data.push({
          //     messageDate: moment().format("YYYY-MM-DD"),
          //     message: comment,
          //   });
          //   this.props.updateUserMessages(data);
          // } else {
          //   let newData = this.props.data;

          //   let index = newData.findIndex(
          //     (o) => o.id === this.state.activityId
          //   );
          //   let data = newData[index];
          //   data.comments.push({
          //     messageDate: moment().format("YYYY-MM-DD"),
          //     message: comment,
          //   });
          //   newData[index] = data;
          //   this.props.updateActivityList(newData);
          // }

          this.setState({
            openFeedbackForm: false,
            activityId: null,
            comment: null,
            // showGlobalComment: false,
          });
        })
        .catch((err) => {});
      ///api call
    }
  };
  getFilteredList = () => {
    this.setState({ isFiltered: !this.state.isFiltered });
  };
  updateFilterValues = (data) => {
    if (data) {
      this.setState({ registrationListFilters: data });
      let updateFilters = { ...this.state.registrationListFilters, ...data };
      for (let i = 0; i < data?.length; i++) {
        let ele = data[i];

        if (ele.filterType === "Consent") {
          updateFilters.consentPresent =
            ele.selectedValue === "consentProvided"
              ? true
              : ele.selectedValue === "consentNotProvided"
              ? false
              : null;
          this.setState({
            consentPresent:
              ele.selectedValue === "consentProvided"
                ? true
                : ele.selectedValue === "consentNotProvided"
                ? false
                : null,
          });
        }
        if (ele.filterType === "bib") {
          updateFilters.bibGenerated =
            ele.selectedValue === "bibGenerated"
              ? true
              : ele.selectedValue === "bibNotGenerated"
              ? false
              : null;
          this.setState({
            bibGenerated:
              ele.selectedValue === "bibGenerated"
                ? true
                : ele.selectedValue === "bibNotGenerated"
                ? false
                : null,
          });
        }
        if (ele.filterType === "register") {
          updateFilters.onlineRegistration =
            ele.selectedValue === "online"
              ? true
              : ele.selectedValue === "offline"
              ? false
              : null;
          this.setState({
            onlineRegistration:
              ele.selectedValue === "online"
                ? true
                : ele.selectedValue === "offline"
                ? false
                : null,
          });
        }
        if (ele.filterType === "group") {
          updateFilters.includeVolunteer =
            ele.selectedValue === "volunteer"
              ? true
              : ele.selectedValue === "participants"
              ? false
              : null;
          this.setState({
            includeVolunteer:
              ele.selectedValue === "volunteer"
                ? true
                : ele.selectedValue === "participants"
                ? false
                : null,
          });
        }
      }

      this.props.updateParticipantSetFilters({
        ...this.props.participantFilters,
        consentPresent:
          this.state.registrationListFilters[0].selectedValue ===
          "consentProvided"
            ? true
            : this.state.registrationListFilters[0].selectedValue ===
              "consentNotProvided"
            ? false
            : null,
        bibGenerated:
          this.state.registrationListFilters[1].selectedValue === "bibGenerated"
            ? true
            : this.state.registrationListFilters[1].selectedValue ===
              "bibNotGenerated"
            ? false
            : null,
        onlineRegistration:
          this.state.registrationListFilters[2].selectedValue === "online"
            ? true
            : this.state.registrationListFilters[2].selectedValue === "offline"
            ? false
            : null,
        includeVolunteer:
          this.state.registrationListFilters[3].selectedValue === "volunteer"
            ? true
            : this.state.registrationListFilters[3].selectedValue ===
              "participant"
            ? false
            : null,
      });
      this.getList(true, 0, updateFilters);
    }
  };

  render() {
    const { classes } = this.props;
    const {
      isSupport,
      eventDetailsData,
      participantsList,
      categoryOptions,
      categoryValue,
      activityType,
      activityOptions,
      dateRange,
      dateRangeOptions,
      searchSelectValue,
      searchValue,
      totalRecords,
      modifyCategoryOptions,
      csvData,
      startDate,
      endDate,
      anchorEl,
      deliverableOptions,
      isDrawerOpen,
      isFiltered,
      registrationListFilters,
    } = this.state;
    return (
      <>
        <div className={classes.mainBody}>
          <div className={classes.top}>
            {totalRecords !== null && (
              <div className={classes.totalRec}>
                <span>
                  Participants
                  <span style={{ marginLeft: "10px" }}>{totalRecords}</span>
                </span>
              </div>
            )}

            {totalRecords ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <SubmitCancelBtn
                    label="Export"
                    type="submit"
                    onClick={this.downloadCSVFile}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={classes.searchFilter__row}>
            <div className="search__select">
              <FormControl className={classes.select}>
                <NativeSelect
                  className={classes.selectActivity}
                  value={searchSelectValue}
                  onChange={this.selectHandleValue}
                  style={{ width: 100 }}
                >
                  {["Name", "BIB", "Email", "Contact"]?.map((value, index) => (
                    <option value={value}>{value}</option>
                  ))}
                </NativeSelect>
              </FormControl>
              <form
                className="search"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.searchHandleClick();
                }}
              >
                <input
                  type="search"
                  placeholder="Search..."
                  value={searchValue}
                  onChange={this.searchHandleChange}
                />
                <SearchIcon
                  className="search__icon"
                  onClick={this.searchHandleClick}
                />
              </form>
            </div>
            <div className="filters">
              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectActivity}
                  value={activityType}
                  onChange={this.activityHandleChange}
                  style={{ width: 130 }}
                >
                  <option value="">All activities</option>
                  {activityOptions?.map((value, index) => (
                    <option value={value.eventSupportedActivityTypeId}>
                      {value.label}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
              {activityType && (
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    className={classes.selectActivity}
                    value={categoryValue}
                    onChange={this.categoryHandleChange}
                    style={{ width: "auto" }}
                  >
                    <option value="">All</option>
                    {categoryOptions?.map((value, index) => (
                      <option value={value.id}>{value.label}</option>
                    ))}
                  </NativeSelect>
                </FormControl>
              )}
              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectActivity}
                  value={dateRange}
                  onChange={this.dateHandleChange}
                  style={{ width: "auto" }}
                >
                  <option value="">All </option>
                  {dateRangeOptions?.map((value, index) => (
                    <option value={value.value}>{value.label}</option>
                  ))}
                </NativeSelect>
              </FormControl>
              {dateRange === "Custom Date" && (
                <FormControl className={classes.customDate}>
                  <Typography variant="body2" gutterBottom>
                    <span>Start Date</span>
                  </Typography>
                  <KeyboardDatePicker
                    autoOk
                    clearable
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    // disableFuture={true}
                    value={startDate}
                    //error={dobError}
                    style={{
                      width: "150px",
                    }}
                    maxDate={endDate}
                    InputProps={{
                      classes: {
                        root: classes.datePicker,
                      },
                    }}
                    onChange={(date) => {
                      this.customStartDate(date);
                    }}
                    keyboardIcon={
                      <IconCalendarBlue className={classes.calendarIconCss} />
                    }
                  />
                </FormControl>
              )}
              {dateRange === "Custom Date" && (
                <FormControl className={classes.customDate}>
                  <Typography variant="body2" gutterBottom>
                    <span>End Date</span>
                  </Typography>
                  <KeyboardDatePicker
                    autoOk
                    clearable
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    // disableFuture={true}
                    value={endDate}
                    //error={dobError}
                    style={{
                      width: "150px",
                    }}
                    InputProps={{
                      classes: {
                        root: classes.datePicker,
                      },
                    }}
                    minDate={startDate}
                    onChange={(date) => {
                      this.customEndDate(date);
                    }}
                    keyboardIcon={
                      <IconCalendarBlue className={classes.calendarIconCss} />
                    }
                  />
                </FormControl>
              )}
              {isSupport && (
                <FormControl className={classes.formControl}>
                  <div className={classes.actions}>
                    <span>Actions</span>
                    <span>
                      <ArrowDropDownCircleOutlinedIcon
                        className={classes.editIcon}
                        onClick={(event) => {
                          this.setState({
                            anchorEl: event.currentTarget,
                          });
                        }}
                      />
                    </span>
                  </div>
                </FormControl>
              )}
              {/* <FilterIcon
              filter={this.getFilteredList}
              isFiltered={isFiltered}
              open={() =>
                this.setState({ isDrawerOpen: !this.state.isDrawerOpen })
              }
            /> */}
              <FilterSVGIcon
                onClick={() =>
                  this.setState({
                    isDrawerOpen: true,
                    isFiltered: !this.state.isFiltered,
                  })
                }
                className={classes.filtericonStyling}
              />
              <DrawerFilter
                open={isDrawerOpen}
                onClose={() =>
                  this.setState({ isDrawerOpen: !this.state.isDrawerOpen })
                }
                //  onApplyFilter={this.consentHandleChange && this.bibGeneratedHandleChange && this.registerHandleChange}
                onApplyFilter={this.updateFilterValues}
                filterData={registrationListFilters}
              />
            </div>
          </div>

          <ListPageLoader
            pageParams={{
              page: 0,
              start: 0,
              limit: 8,
              TotalRecords: this.state.totalRecords,
            }}
            totalLoadedLength={participantsList ? participantsList.length : 20}
            loadNextData={(params) => {
              if (!participantsList.includes(undefined)) {
                this.getList(false, params.page);
              }
            }}
          >
            {participantsList?.length > 0 ? (
              <OrganizerTableList
                isSupport={isSupport}
                eventData={eventDetailsData}
                data={participantsList}
                updateParticipantsList={(data, reload) => {
                  if (reload) {
                    this.initialDataLoad();
                  } else {
                    this.setState({ participantsList: data });
                  }
                }}
                email
                activityType
                regType
                editReg
                activityTypeOptions={activityOptions}
                categoryTypeOptions={modifyCategoryOptions}
                updateCheckboxArr={(arr, isAllChecked, reload) => {
                  this.setState({
                    checkboxArr: arr,
                    isAllChecked: isAllChecked,
                  });

                  let array = [];
                  arr.map((check) => {
                    if (check.check) {
                      array.push(check.id);
                    }
                  });
                  if (array.length > 0) {
                    let requestParams = {
                      // runnerIds: isAllChecked ? "" : array,
                      runnerIds: array,
                    };

                    this.setState({ paramObj: requestParams });
                  }
                }}
              />
            ) : (
              <div className={classes.participantNoResult}>
                {totalRecords === 0
                  ? "No participants"
                  : totalRecords === null
                  ? "Loading participants"
                  : ""}
              </div>
            )}
          </ListPageLoader>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            // className="header__user--menu"
            style={{ marginTop: "40px" }}
          >
            {" "}
            <MenuItem
              onClick={() => {
                this.setState({
                  openImportDialogForBIBMapping: true,
                });
              }}
            >
              Import BIB
            </MenuItem>
            {eventDetailsData?.registerOnImport === true && (
              <MenuItem onClick={this.handleImportClick}>Import</MenuItem>
            )}
            {deliverableOptions?.map((value, index) => (
              <MenuItem onClick={() => this.getDeliverable(value.value)}>
                {value.label}
              </MenuItem>
            ))}
            <MenuItem
              onClick={() => {
                this.handleClose();
                this.setState({
                  openFeedbackForm: true,
                });
              }}
            >
              {"Send Message"}
            </MenuItem>
            {/*
            {(eventDetailsData?.freeEvent || isSupport) && (
              // ||
              // ( isChallenge &&
              //   isSupport)
              <MenuItem
                onClick={() => {
                  const { checkboxArr, isAllChecked } = this.state;

                  let arr = [];

                  checkboxArr.map((check) => {
                    if (check.check) {
                      arr.push(check.id);
                    }
                  });
                  if (arr.length > 0 || isAllChecked) {
                    // let deleteParams = {
                    //   runnerIds: isAllChecked ? "" : arr,
                    // };
                    this.setState({
                      deleteParams: arr,
                      deleteParticipantWarningOpen: true,
                    });

                    // generateDeliverables(type, deleteParams);
                  } else {
                    Snackbar.show({
                      variant: "error",
                      isAutoHide: true,
                      preventDuplicate: true,
                      message: "Please select users",
                    });
                  }
                }}
              >
                Delete Participant
              </MenuItem>
            )}
*/}
          </Menu>
        </div>

        <DialogBox
          signup={false}
          maxWidth="xs"
          open={this.state.openFailedImport}
          message={this.getFailedImportList()}
          header={Properties.ImportFailedLabel}
          onClick={() => {
            this.setState({
              openFailedImport: false,
            });
          }}
          closeDisloag={() => {
            this.setState({
              openFailedImport: false,
            });
          }}
        />

        {/* import dialog */}
        <DialogBox
          signup={false}
          open={this.state.openImportDialog}
          message={this.getImportComponent()}
          header={Properties.ImportResultsLabel}
          onClick={() => {
            this.handleImportClose();
          }}
          closeDisloag={() => this.handleImportClose()}
        />

        {/* import dialog for mat to bib mapping*/}
        <DialogBox
          signup={false}
          open={this.state.openImportDialogForBIBMapping}
          message={this.getImportComponentForBIBMapping()}
          header={Properties.ImportBibMappingLabel}
          onClick={() => {
            this.setState({
              openImportDialogForBIBMapping: false,
            });
          }}
          closeDisloag={() => {
            this.setState({
              openImportDialogForBIBMapping: false,
            });
          }}
        />

        {/* delete participant warning */}
        <DialogBox
          signup={true}
          open={this.state.deleteParticipantWarningOpen}
          message={this.getDeleteParticipantWarning()}
          header={this.state.dialogHeader}
          label={"No"}
          isSmall={true}
          onClick={() => {
            this.setState({
              deleteParticipantWarningOpen: false,
              anchorEl: null,
            });
          }}
          labelTwo={"Yes"}
          onClickTwo={() => {
            // this.handleActivityDelete();

            this.handleParticipantDelete();
          }}
          closeDisloag={() => {
            this.setState({
              deleteParticipantWarningOpen: false,
              anchorEl: null,
            });
          }}
        />

        {/* send message */}
        <DialogBox
          fullWidth
          maxWidth={"md"}
          header={"Add Feedback"}
          open={this.state.openFeedbackForm}
          message={this.getFeedbackForm()}
          onClick={() => {
            this.setState({
              openFeedbackForm: false,
            });
          }}
          closeDisloag={() => {
            this.setState({
              openFeedbackForm: false,
            });
          }}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    participantFilters: state.supportUser.filters.participantListFilters,
    supportDTO: state.supportUser.supportDTO,
    supportSelectedEvent: state.supportUser.supportSelectedEvent,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateParticipantSetFilters,
    updateSupportDTODetails,
    updateSupportSelectedEvent,
  })
)(OrganizerRegistrationList);
