import React, { Component } from "react";
import SummaryCard from "./SummaryCard";
import { withStyles } from "@material-ui/core/styles";
import ShareIcon from "@material-ui/icons/Share";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Slider,
} from "@material-ui/core";
import DialogBox from "../../../common/DialogBox";

import AppToolBar from "../../../common/AppToolBar";
import Header from "../../../common/Header";
import {
  downloadImage,
  getGoodiesImage,
  getRegisteredEventDetails,
  getSummaryImage,
  getSummaryImpressions,
  runnerSummary,
  setSummaryBgImage,
} from "../../../../apis/eventService";
// import domtoimage from "dom-to-image"; // package uninstalled
import Properties from "../../../../properties/Properties";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import GetAppIcon from "@material-ui/icons/GetApp";
import Snackbar from "../../../common/Snackbar";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../common/cropImage";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import FlipCameraIosIcon from "@material-ui/icons/FlipCameraIos";
import { NoGoodieGenerated } from "../../../common/SvgIcon";
import RunnerSideBreadcrumbs from "../../../common/RunnerSideBreadcrumbs";
const styles = (theme) => ({
  main: {
    marginTop: "60px",
  },
  card: {
    display: "flex",

    justifyContent: "center",
  },
  buttons: {
    margin: "20px",
    display: "flex",
    justifyContent: "flex-end",
    "& div": {
      margin: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  button: {
    background: "rgba( 58, 110, 165, 0.25 )",
    boxShadow: "0 4px 4px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: " blur( 4px )",
    // -webkit-backdrop-filter: blur( 4px );
    borderRadius: "50%",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    margin: "10px",
  },
  icon: {
    fontSize: "35px",
    color: "#000",
  },
  dialogContent: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    // "& >*": {
    //   marginLeft: "50px",
    // },
  },
  root: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#296DFA",
    },
    // maxWidth: "300px",
    width: "100%",
    height: "38px",
    borderRadius: "16px",
    backgroundColor: "#09456C",
    color: "#fff",
    fontWeight: "bold",
    alignContent: "center",
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      width: "58px",
      height: "58px",
      borderRadius: "50%",
    },
  },
  cropImageDialog: {
    width: "650px",
    margin: "0px",
    height: "650px",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
      height: "400px",
    },
  },
  bgBtn: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      "& .txt": {
        display: "none",
      },
    },
  },
  noGoodieContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& p": {
      fontSize: "20px",
    },
  },
});
class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
      userName: localStorage.getItem("userName"),
      summaryDetails: null,
      cardImage: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 6 / 6,
      openChangeBgDialog: false,
      previewData: null,
    };
  }

  componentDidMount() {
    document.title = Properties.Page_Title_Summary;
    let eventId = ApplicationUtil.getRunnersEventID();
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runner?.id;
    let eventData = ApplicationUtil.getRunnersEventsDetails();
    let goodieType = eventData?.goodiesType ? eventData?.goodiesType : "SHARE";
    this.setState({
      eventDate: ApplicationUtil.changeDateFormat(eventData.localStartDate),
      eventEnd: ApplicationUtil.changeDateFormat(eventData.localEndDate),
      eventName: eventData?.name,
      eventUrl: eventData?.eventLink,
      goodieType,
    });

    getGoodiesImage(runnerId, eventId, goodieType).then((res) => {
      this.setState({
        cardImage: res.data?.imageData
          ? `data:image/png;base64,${res.data?.imageData}`
          : undefined,
        previewData: res.data,
      });
    });
    // setSummaryBgImage(runnerId,eventId).then((res)=>{
    //   console.log(res.data)
    //   this.setState({cardImage:res.data?.imageData ? `data:image/png;base64,${res.data?.imageData}`: null})
    // })
  }
  shareImage = () => {
    const { eventName, eventUrl, shareNDLink, eventType, cardImage, eventDate , organizerName} =
      this.state;
    let eventId = ApplicationUtil.getRunnersEventID();
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runner?.id;
        const shareData = {
          imageSrc: cardImage,
          title: eventName,
        };
    // if (shareNDLink) {
    shareData.url = eventUrl;
            
        ApplicationUtil.navigatorShare(shareData);
        getSummaryImpressions(eventId, runnerId).then((res) => { });
     
  };

  downloadCreatedImage = () => {
    let node = document.getElementById("userSummaryCard");

    const scale = 2;
    // domtoimage
    //   .toPng(a, {
    //     height: node.offsetHeight * scale,
    //     style: {
    //       transform: `scale(${scale}) translate(${node.offsetWidth / 2 / scale
    //         }px, ${node.offsetHeight / 2 / scale}px)`,
    //     },
    //     width: node.offsetWidth * scale,
    //   })
    //   .then(function (dataUrl) {
    ApplicationUtil.downloadImage(this.state.cardImage);
    // })
    // .catch(function (error) {
    //   Snackbar.show({
    //     variant: "error",
    //     isAutoHide: true,
    //     preventDuplicate: true,
    //     message: "Failed to convert to image",
    //   });
    // });
  };
  handleSetToDefault = () => {
    const { goodieType } = this.state;
    let eventId = ApplicationUtil.getRunnersEventID();
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runner?.id;
    let eventData = ApplicationUtil.getRunnersEventsDetails();
    getGoodiesImage(runnerId, eventId, goodieType, {
      defaultBackground: true,
    }).then((res) => {
      this.setState({
        cardImage: res.data?.imageData
          ? `data:image/png;base64,${res.data?.imageData}`
          : undefined,
        previewData: res.data,
      });
    });
  };
  multipleUpload = (e) => {
    if (e.target.id == "contained-button-file") {
      let files = e.target.files;
      if (
        Array.from(e.target.files)?.every(
          (file) => file?.type === "image/png" || file?.type === "image/jpeg"
        )
      ) {
        this.setState({
          uploadedFiles: Array.from(files).map((file) => {
            let key = "uploadedId_" + parseInt(Math.random() * 1000000);
            file.key = key;
            file.id = key;
            file.src = URL.createObjectURL(file);
            return file;
          }),
          openChangeBgDialog: true,
        });
      } else {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please select JPEG or PNG file",
        });
      }
    }
    e.target.value = null;
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, "crop", croppedAreaPixels);
    this.setState({ croppedAreaPixels: croppedAreaPixels });
  };
  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  getCropImagePallete = () => {
    const { classes } = this.props;
    return (
      <div className={classes.cropImageDialog}>
        {this.state?.uploadedFiles && (
          <React.Fragment>
            <div>
              <Cropper
                image={this.state?.uploadedFiles[0].src}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={this.state.aspect}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
              />

              {/* <div className="controls">
            <Slider
              value={this.state.zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => this.onZoomChange(zoom)}
              classes={{ container: "slider" }}
            />
          </div> */}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };
  saveBgImage = async () => {
    const { goodieType } = this.state;
    let eventId = ApplicationUtil.getRunnersEventID();
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runner?.id;
    let eventData = ApplicationUtil.getRunnersEventsDetails();

    let img = await getCroppedImg(
      this.state.uploadedFiles[0].src,
      this.state.croppedAreaPixels
    );

    console.log(img);
    this.setState({ croppedImg: img });
    fetch(img)
      .then((res) => res.blob())
      .then((blob) => {
        let file = new File([blob], "summaryBackground", {
          type: "image/jpeg",
        });
        let key = "imageId_" + parseInt(Math.random() * 1000000);
        file.key = key;
        file.id = key;
        let customParams = {};
        customParams.file = file;
        getGoodiesImage(runnerId, eventId, goodieType, customParams).then(
          (res) => {
            console.log(res.data);
            // getSummaryImage(runnerId, eventId).then((res)=>{
            // console.log(res.data)
            this.setState({
              cardImage: res.data?.imageData
                ? `data:image/png;base64,${res.data?.imageData}`
                : undefined,
              openChangeBgDialog: false,
            });
            // })
          }
        );
      });
  };

  render() {
    const { classes } = this.props;
    const {
      openDialog,
      summaryDetails,
      eventName,
      cardImage,
      eventDate,
      eventEnd,
      openChangeBgDialog,
      previewData,
      goodieType,
    } = this.state;

    return (
      <>
        <AppToolBar>
          <Header
            {...this.props}
            label={eventName ? eventName : Properties.Label_EventDetails}
            fromDate={eventDate}
            toDate={eventDate === eventEnd ? false : eventEnd}
          />
        </AppToolBar>
        <div className={classes.main}>
          <RunnerSideBreadcrumbs />
          {/* buttons */}
          <div className={classes.buttons}>
            {cardImage && goodieType === "SHARE" && (
              <div>
                <SubmitCancelBtn
                  style={{ width: "auto" }}
                  type="submit"
                  startIcon={<FlipCameraIosIcon />}
                  label={"Use default background"}
                  onClick={this.handleSetToDefault}
                />
              </div>
            )}
          </div>
          {/* Summary card */}
          <div className={classes.card}>
            {cardImage ? (
              <SummaryCard
                data={previewData}
                cardImage={cardImage}
                onShareImage={this.shareImage}
                onDownloadImage={this.downloadCreatedImage}
                onMultipleUpload={this.multipleUpload}
                goodieType={goodieType}
              />
            ) : (
              <div className={classes.noGoodieContainer}>
                {cardImage === undefined && (
                  <>
                    <NoGoodieGenerated style={{ width: 100, height: 100 }} />
                    <p>No Goodie Generated</p>
                  </>
                )}
              </div>
            )}
          </div>

          <DialogBox
            fullWidth={true}
            maxWidth={"lg"}
            open={openChangeBgDialog}
            message={this.getCropImagePallete()}
            header={"Edit Background Image"}
            onClick={() => {
              this.setState({ openChangeBgDialog: false });
            }}
            onClickTwo={() => {
              this.saveBgImage();
            }}
            label={"Cancel"}
            labelTwo={"Save"}
            closeDisloag={() => {
              this.setState({ openChangeBgDialog: false });
            }} //{() => this.closeWindow()}
          />
        </div>
      </>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(Summary);
