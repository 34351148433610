import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import httpService from "../../../../../apis/httpService";

import { IoMdCalendar } from "react-icons/io";
import moment from "moment";
import { LuExternalLink } from "react-icons/lu";
import parse from "html-react-parser";
import VideoBlock from "./VideoBlock";

const API_URL = httpService.API_URL();

const ListBlock = ({
  title,
  items,
  isUserRegistered,
  classes,
  blockFor,
  currentRunningChallenge,
  handleOpenBlock,
  openBlock,
  isVideoViewedState,
  handleVideoViewed,
  setVideoLoaded,
}) => {
  return (
    <>
      <Card className={classes.card}>
        <CardContent style={{ paddingBottom: 0 }}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.cardTitle}>{title}</span>
          </Typography>
          <ul style={{ listStyle: "none", margin: "0", padding: "0" }}>
            {items?.map((item, index) => {
              let videoLink, endDate, startDate;
              const { id, name, description } = item;
              if (blockFor === "challenges") {
                videoLink = item?.videoLink;
                endDate = moment(
                  item?.challengeEndDate,
                  "DD-MM-YYYY hh:mm:ss"
                ).format("DD-MM-YYYY");
                startDate = moment(
                  item?.challengeStartDate,
                  "DD-MM-YYYY hh:mm:ss"
                ).format("DD-MM-YYYY");
              }
              if (blockFor === "events") {
                endDate = moment(item.localEndDate).format("DD-MM-YYYY");
                startDate = moment(item.localStartDate).format("DD-MM-YYYY");
              }
              if (blockFor === "sessions") {
                endDate = moment(
                  item?.sessionEndDate,
                  "DD-MM-YYYY hh:mm:ss"
                ).format("DD-MM-YYYY LT");
                startDate = moment(
                  item?.sessionStartDate,
                  "DD-MM-YYYY hh:mm:ss"
                ).format("DD-MM-YYYY LT");
              }
              const isLast = index === items.length - 1;

              return (
                <li
                  key={id}
                  id={blockFor + "_" + id}
                  className={classes?.ListItem}
                  style={{
                    borderBottom: !isLast && "1px solid gray",
                  }}
                >
                  <div>
                    {currentRunningChallenge?.id == id && (
                      <span
                        style={{
                          fontSize: "smaller",
                          color: "#6b7280",
                          fontWeight: "500",
                        }}
                      >
                        On going challenge
                      </span>
                    )}
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "bold",
                        fontSize: "large",
                      }}
                    >
                      {name}
                    </Typography>
                    <div className={classes.challengeDuration}>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <IoMdCalendar
                          style={{
                            color: "rgb(40, 131, 181)",
                            fontSize: "20px",
                          }}
                        />
                        <b>&nbsp;{"Starting from:"}</b>
                        <span style={{ marginLeft: "5px" }}>{startDate}</span>
                      </span>
                      <span className={classes.dateStyling}>
                        <b>{"Ends on:"}</b>
                        <span style={{ marginLeft: "5px" }}>{endDate}</span>
                      </span>
                    </div>
                    {description && (
                      <Typography variant="body3">
                        {parse(description)}
                      </Typography>
                    )}
                    <VideoBlock
                      isUserRegistered={isUserRegistered}
                      videoLink={videoLink}
                      challengeId={id}
                      handleOpenBlock={handleOpenBlock}
                      openBlock={openBlock}
                      setVideoLoaded={setVideoLoaded}
                      currentRunningChallenge={currentRunningChallenge}
                      isVideoViewedState={isVideoViewedState}
                      handleVideoViewed={handleVideoViewed}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </CardContent>
      </Card>
    </>
  );
};

const styles = (theme) => ({
  card: {
    maxWidth: "850px",
    width: "100%",
    margin: "auto",
    marginBottom: "10px",
  },
  cardTitle: {
    color: "#2883B5",
    fontSize: "23px",
    fontWeight: "bold",
  },

  left: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    // flexDirection:'column'
    //justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      //  justifyContent: "center",
      flexWrap: "nowrap",
    },
  },

  dateStyling: {
    marginLeft: "5%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "8%",
    },
  },
  headline: {
    fontSize: "25px",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "left",

    color: "#780000",
  },
  challengeDuration: {
    display: "flex",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  ListItem: {
    marginBottom: "3px",
    padding: "8px 5px",
    borderRadius: "3px",
  },
});
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ListBlock);
