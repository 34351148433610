import React, { Component } from 'react'
import Image from '../../../../../images/coming-soon.png' 

export class OrganizerTrainer extends Component {
  render() {
    return (
      <div>
        <img src={Image} style={{height:200,width:600}} />
        </div>
    )
  }
}

export default OrganizerTrainer