import React, { Component, useEffect } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import EmojiEventsTwoToneIcon from "@material-ui/icons/EmojiEventsTwoTone";
import {
  getTeamLeaderboard,
  getRunnerLeaderboard,
  getRunnerList,
  getTeamList,
  getTeamAndGroupNames,
} from "../../../../../../apis/eventService";
import Autosuggest from "react-autosuggest";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Snackbar from "../../../../../common/Snackbar";

import {
  FormControl,
  NativeSelect,
  Grid,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import Board from "./Board";
import SubmitCancelBtn from "../../../../../common/SubmitCancelBtn";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";
import { withRouter } from "react-router-dom";
import Ticker from "react-ticker";
import { useState } from "react";
import GoldMedal from "../../../../../../images/gold-medal.png";
import SilverMedal from "../../../../../../images/silver-medal.png";
import BronzeMedal from "../../../../../../images/bronze-medal.png";
import BoardList from "./BoardList";
import { Message } from "@material-ui/icons";
import ApplicationConstants from "../../../../../../constants/ApplicationConstants";
// import BoardList from "./BoardList";
const styles = (theme) => ({
  leaderboard_top: {
    width: "90%",
    margin: "auto",
    //marginTop: "20px",
    // backgroundColor:'#caf0f8',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    "& .react-autosuggest__container": {
      position: "relative",
    },

    "& .react-autosuggest__input": {
      width: "100%",
      height: "40px",
      padding: "10px 10px",
      marginTop: "10px",
      fontFamily: "Helvetica, sans-serif",
      fontWeight: 300,
      fontSize: "16px",
      border: "1px solid #aaa",
      borderRadius: "6px",
      [theme.breakpoints.down("sm")]: {
        width: "150%",
      },
    },
    "& .react-autosuggest__input--focused": {
      outline: "none",
    },

    "& .react-autosuggest__input--open": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },

    "& .react-autosuggest__suggestions-container": {
      display: "none",
    },

    "& .react-autosuggest__suggestions-container--open": {
      display: "block",
      position: "absolute",
      top: "51px",
      width: "100%",
      textAlign: "left",
      border: "1px solid #aaa",
      backgroundColor: "#fff",
      fontFamily: "Helvetica, sans-serif",
      fontWeight: 300,
      fontSize: "16px",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      zIndex: 2,
      overflow: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "150%",
        marginRight: "15%",
      },
    },

    "& .react-autosuggest__suggestions-list": {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },

    "& .react-autosuggest__suggestion": {
      cursor: "pointer",
      padding: "10px 20px",
    },

    "& .react-autosuggest__suggestion--highlighted": {
      backgroundColor: "#ddd",
      color: "#000",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "15%",
    },
  },

  container_top: {
    /* width: 20%; */
    display: "flex",
    alignItems: "center",
    gap: "5px",
    /* justify-content: space-around; */
  },
  root: {
    width: "100%",
    "& h3": {
      fontSize: "18px",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  winnerTeamsContainer: {
    marginTop: "30px",
    display: "flex",
    alignItems: "flex-start",
    "& .teamLayout": {
      color: "#ffffff",
      backgroundColor: "#0077b6",
      borderRadius: "0",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "0px",
    },
    "& .teamWinner1": {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "89%",
      },
    },
    "& .teamWinner2": {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "89%",
      },
    },
    "& .teamWinner3": {
      width: "100%",
      marginBottom: "10px",
      [theme.breakpoints.down("sm")]: {
        width: "89%",
      },
    },

    "& .medalIcons": {
      height: 60,
      width: 60,
      objectFit: "contain",
      [theme.breakpoints.down("sm")]: {
        height: 35,
        width: 35,
      },
    },
  },
  winnerTeamsContainer1: {
    marginTop: "30px",
    display: "flex",
    alignItems: "flex-start",
    "& .teamLayout": {
      color: "#ffffff",
      backgroundColor: "#0077b6",
      borderRadius: "0",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "0px",
    },
    "& .teamWinner1": {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "89%",
      },
    },
    "& .teamWinner2": {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "89%",
      },
    },
    "& .teamWinner3": {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "89%",
      },
    },

    "& .medalIcons": {
      height: 60,
      width: 60,
      objectFit: "contain",
      [theme.breakpoints.down("sm")]: {
        height: 35,
        width: 35,
      },
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
      marginRight: "3%",
    },
  },
  dropdownStyling: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      marginBottom: "20px",
    },
  },
  teamListContainer: {
    marginTop: "20px",
    "& .teamList": {
      width: "95%",
      marginLeft: "5%",
      borderRadius: "0",
      backgroundColor: "#0077b6",
      color: "#ffffff",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10%",
        width: "89%",
      },
    },
    "& .accordion": {
      cursor: "none",
      "& .MuiIconButton-root": {
        visibility: "hidden",
      },
      // width: '100%'
    },
  },

  search__select: {
    display: "flex",
    alignItems: "flex-end",
  },
  winnerIconsContainer: {
    display: "flex",
    alignItems: "center",
  },
});

function TeamBoard(props) {
  const [expanded, setExpanded] = useState(false);
  const [challengeParameter, setChallengeParameter] = useState("");
  const [intervalCount, setIntervalCount] = useState(0);
  const [teamTickerText, setTeamTickerText] = useState(null);
  const [teamWinnersData, setTeamWinnersData] = useState(null);
  const [teamData, setTeamData] = useState(null);
  const [teamListData, setTeamListData] = useState(null);
  const [runnerWinnersData, setRunnerWinnersData] = useState(null);
  const [runnersData, setRunnersData] = useState(null);
  const [runnerListData, setRunnerListData] = useState(null);
  const [value, setSearchValue] = useState("");
  const [totalRunners, setTotalRunner] = useState(null);
  const [teamNames, setTeamNames] = useState(null);
  const [totalTeams, setTotalTeam] = useState(null);
  const [runnerGroupNames, setRunnerGroupNames] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (intervalCount > 0) {
      const boardInterval = setInterval(() => {
        setIntervalCount(1);
        initialDataLoad();
      }, 2000000);
      return () => clearInterval(boardInterval);
    } else {
      initialDataLoad();
      setIntervalCount(1);
    }
  }, [intervalCount]);

  const initialDataLoad = () => {
    let runnerEventId = ApplicationUtil.getRunnersEventID();
    let organizerEventId = ApplicationUtil.getSupportsEventID();
    let eventId = window.location.href.includes("/organizer")
      ? organizerEventId
      : runnerEventId;
    if (props.radioValue === "team" || props.radioValue === "individual") {
      getTeamLeaderboard(eventId).then((res) => {
        if (res.data) {
          let data = res.data;
          let winnersData = data?.teamDetails?.filter(
            (x) => x.teamRank == 1 || x.teamRank == 2 || x.teamRank == 3
          );
          let teamData = data?.teamDetails?.filter((x) => x.teamRank > 3);

          getTeamAndGroupNames(eventId, "TEAMS").then(
            ({ data: { teamDetails } }) => {
              setTeamNames(teamDetails);
            }
          );
          setTeamWinnersData(winnersData);
          setTeamData(teamData);
          setTotalTeam(data);
          setTeamTickerText(data.teamTickerText);
          setChallengeParameter(data?.challengeParameter);
        }
      });
    }

    if (props.radioValue === "runnerGroup") {
      getRunnerLeaderboard(eventId).then((res) => {
        if (res.data) {
          let data = res.data;
          let winnersData = data?.runnerGroupDetailsDTO?.filter(
            (x) =>
              x.runnerGroupRank == 1 ||
              x.runnerGroupRank == 2 ||
              x.runnerGroupRank == 3
          );
          let runnerData = data?.runnerGroupDetailsDTO?.filter(
            (x) => x?.runnerGroupRank > 3
          );

          getTeamAndGroupNames(eventId, "RUNNER_GROUPS").then(
            ({ data: { runnerGroupDetailsDTO } }) => {
              setRunnerGroupNames(runnerGroupDetailsDTO);
            }
          );
          setRunnerWinnersData(winnersData);
          setRunnersData(runnerData);
          setTotalRunner(data);
          setChallengeParameter(data?.challengeParameter);
        }
      });
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    if (!isExpanded) {
      setExpanded(false);
      return;
    }

    let runnerEventId = ApplicationUtil.getRunnersEventID();
    let organizerEventId = ApplicationUtil.getSupportsEventID();
    let eventId = window.location.href.includes("/organizer")
      ? organizerEventId
      : runnerEventId;

    if (props.radioValue === "runnerGroup") {
      let requestParams = {
        eventId: eventId,
        runnerGroupId: panel,
      };
      getRunnerList(requestParams).then((res) => {
        if (res.data) {
          let data = res.data;
          setRunnerListData(data?.particiapants[0]?.runnerActivities);
        }
      });
    }
    if (props.radioValue === "team" || props.radioValue === "individual") {
      let requestParams = {
        eventId: eventId,
        teamId: panel,
      };
      getTeamList(requestParams).then((res) => {
        if (res.data) {
          let data = res.data;
          setTeamListData(data?.teamMemberActivities);
        }
      });
    }
    setExpanded(panel ? (isExpanded ? panel : false) : false);
  };
  const editTeamMember = (teamMember, param) => {
    let filterMembers = teamMember.filter((x) =>
      param === "STEPS"
        ? x.totalSteps > 0
        : param === "WELLNESS_POINT"
        ? x.totalWellnessPoint > 0
        : x.totalActivityDistance > 0
    );

    // let sortMembers = filterMembers.sort((a, b) => Number(parseFloat(b.totalActivityDistance / 1000).toFixed(2)) - Number(parseFloat(a.totalActivityDistance / 1000).toFixed(2)))
    return filterMembers;
  };
  const getTitle = () => {
    if (
      ApplicationConstants.isProd &&
      ApplicationConstants?.challengeLabel_Location_Prod?.includes(
        challengeType?.id
      ) &&
      radioValue !== "AgeWise"
    ) {
      return "Cohort Wise Leaderboard";
    }
    if (
      challengeType?.id === 507 ||
      challengeType?.id === 508 ||
      challengeType?.id === 515
    ) {
      return "Corporate Groups";
    }

    if (challengeType?.id === 2473 && radioValue !== "AgeWise") {
      return "Cohort Wise Leaderboard";
    }

    if (radioValue === "AgeWise") {
      return "Age Wise Leaderboard";
    }
    if (ApplicationUtil.isCohortBasedEventId(challengeType?.id)) {
      return "Cohort Based Leaderboard";
    }
    if (radioValue === "runnerGroup") {
      return "Runner Group Leaderboard";
    }
    return "Team Leaderboard";
  };

  const searchHandleClick = (search) => {
    let resultRunner =
      runnerGroupNames &&
      runnerGroupNames.filter((item) => {
        return item?.runnerGroupName?.includes(value);
      });
    let resultTeam =
      teamNames &&
      teamNames.filter((item) => {
        return item?.teamName?.includes(value);
      });

    if (value && radioValue == "runnerGroup") {
      if (resultRunner[0]?.runnerGroupRank <= 3) {
        setRunnerWinnersData(resultRunner);
        setRunnersData(null);
      } else {
        setRunnersData(resultRunner);
        setRunnerWinnersData(null);
      }
    } else if (value && radioValue == "team") {
      if (resultTeam && resultTeam[0]?.teamRank <= 3) {
        setTeamWinnersData(resultTeam);
        setTeamData(null);
      } else {
        setTeamData(resultTeam);
        setTeamWinnersData([]);
      }
    }
    if (value == "") {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message:
          radioValue == "runnerGroup"
            ? "Please enter runner group name"
            : "Please enter team name",
      });
    }
  };
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue?.length;

    if (radioValue == "runnerGroup") {
      return inputLength === 0
        ? []
        : runnerGroupNames.filter((total) =>
            // total?.runnerGroupName.toLowerCase().slice(0, inputLength) === inputValue
            total?.runnerGroupName.toLowerCase().includes(inputValue)
          );
    }
    if (radioValue == "team") {
      return inputLength === 0
        ? []
        : teamNames?.filter((total) =>
            // total?.teamName.toLowerCase().slice(0, inputLength) === inputValue
            total?.teamName.toLowerCase().includes(inputValue)
          );
    }
  };
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const searchHandleChange = (event, { newValue }) => {
    setSearchValue(newValue);
    if (newValue == "" && radioValue == "runnerGroup") {
      let winnersData = totalRunners?.runnerGroupDetailsDTO?.filter(
        (x) =>
          x.runnerGroupRank == 1 ||
          x.runnerGroupRank == 2 ||
          x.runnerGroupRank == 3
      );
      let runnerData = totalRunners?.runnerGroupDetailsDTO?.filter(
        (x) => x?.runnerGroupRank > 3
      );

      setRunnerWinnersData(winnersData);
      setRunnersData(runnerData);
    }
    if (newValue == "" && radioValue == "team") {
      let winnersData = totalTeams?.teamDetails?.filter(
        (x) => x.teamRank == 1 || x.teamRank == 2 || x.teamRank == 3
      );
      let teamData = totalTeams?.teamDetails?.filter((x) => x.teamRank > 3);

      setTeamWinnersData(winnersData);
      setTeamData(teamData);
    }
  };

  const getSuggestionValue =
    props.radioValue == "team"
      ? (suggestion) => suggestion?.teamName
      : props.radioValue == "runnerGroup"
      ? (suggestion) => suggestion?.runnerGroupName
      : "";

  const renderSuggestion = (suggestion) => (
    <div className={classes.divAutocomplete}>
      {radioValue == "team"
        ? suggestion?.teamName
        : radioValue == "runnerGroup"
        ? suggestion?.runnerGroupName
        : ""}
    </div>
  );
  const { classes, radioValue, challengeType, title, ageWiseData } = props;

  return (
    <div className={classes.leaderboard_top}>
      {/* here search bar and go-Button to get the data */}
      {radioValue !== "AgeWise" && (
        <Grid
          spacing={1}
          container
          className={classes.dropdownStyling}
          style={{ marginTop: radioValue == "team" ? "20px" : 0 }}
        >
          <Grid
            item
            xs={4}
            md={2}
            style={{ textAlign: "center" }}
            className={classes.input}
          >
            <Autosuggest
              inputProps={{
                placeholder: "Search...",
                value: value,
                type: "search",
                onChange: searchHandleChange,
              }}
              suggestions={suggestions || []}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              onSuggestionSelected={(event, { suggestion, method }) => {
                if (method === "enter") {
                  event.preventDefault();
                }
              }}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
            />
          </Grid>
          <Grid item xs={4} md={2} style={{ textAlign: "center" }}>
            <SubmitCancelBtn
              type="submit"
              label={"Search"}
              onClick={() => searchHandleClick(value)}
              style={{ width: "60%", margin: "auto", marginTop: "10px" }}
            />
          </Grid>
        </Grid>
      )}

      {teamTickerText && (
        <Ticker>
          {({ index }) => (
            <p
              style={{
                fontSize: "25px",
                fontWeight: 600,
                marginLeft: "75px",
                whiteSpace: "nowrap",
                color: "#780000",
              }}
            >
              {teamTickerText + "."}
            </p>
          )}
        </Ticker>
      )}
      {/* This is title for all radio value excuted by __getTitle__ */}
      <div className={classes.container_top}>
        <EmojiEventsTwoToneIcon style={{ color: "#F79824" }} />

        <span style={{ color: "rgb(40, 131, 181)", fontSize: "20px" }}>
          {getTitle()}
        </span>
      </div>
      <div className={classes.root}>
        {/* winner data for team and runner group as well */}
        {teamWinnersData?.length > 0 && radioValue === "team" ? (
          <div>
            {teamWinnersData?.map((team) => (
              <div
                key={team?.teamRank}
                className={classes.winnerTeamsContainer}
              >
                {!ApplicationUtil.hideMedal_Rank(challengeType?.id) && (
                  <div className={classes.winnerIconsContainer}>
                    <img
                      src={
                        team?.teamRank === 1
                          ? GoldMedal
                          : team?.teamRank === 2
                          ? SilverMedal
                          : BronzeMedal
                      }
                      alt="gold-medal"
                      className="medalIcons"
                    />
                  </div>
                )}
                <Accordion
                  className={`teamLayout teamWinner${team?.teamRank}`}
                  style={{
                    marginBottom:
                      ApplicationUtil.hideMedal_Rank(challengeType?.id) && 0,
                    width:
                      ApplicationUtil.hideMedal_Rank(challengeType?.id) &&
                      "100%",
                  }}
                  expanded={expanded === team?.id}
                  onChange={handleChange(team?.id)}
                >
                  <AccordionSummaryContainer
                    team={team}
                    challengeParameter={challengeParameter}
                    radioValue={"team"}
                    challengeType={challengeType}
                  />
                  <AccordionDetails className={classes.accordionDetails}>
                    {teamListData?.length > 0 && (
                      <BoardList
                        challengeParameter={challengeParameter}
                        distance={
                          challengeParameter === "DISTANCE" ? true : false
                        }
                        time={challengeParameter === "DISTANCE" ? true : false}
                        sort
                        // data={editTeamMember(teamListData, challengeParameter)}
                        data={teamListData}
                        teamLeaderId={team?.teamLeaderId}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        ) : runnerWinnersData?.length > 0 && radioValue === "runnerGroup" ? (
          <div>
            {runnerWinnersData?.map((runner, index) => (
              <div
                key={runner?.runnerGroupId}
                className={classes.winnerTeamsContainer}
              >
                <div className={classes.winnerIconsContainer}>
                  <img
                    src={
                      runner?.runnerGroupRank === 1
                        ? GoldMedal
                        : runner?.runnerGroupRank === 2
                        ? SilverMedal
                        : BronzeMedal
                    }
                    alt="gold-medal"
                    className="medalIcons"
                  />
                </div>
                <Accordion
                  className={`teamLayout teamWinner${runner?.runnerGroupRank}`}
                  style={{
                    marginBottom:
                      ApplicationUtil.hideMedal_Rank(challengeType?.id) && 0,
                    width:
                      ApplicationUtil.hideMedal_Rank(challengeType?.id) &&
                      "100%",
                  }}
                  expanded={expanded === runner?.runnerGroupId}
                  onChange={handleChange(runner?.runnerGroupId)}
                >
                  <AccordionSummaryContainer
                    runner={runner}
                    challengeParameter={challengeParameter}
                    radioValue={"runnerGroup"}
                    challengeType={challengeType}
                  />
                  {runnerListData?.length > 0 && (
                    <AccordionDetails className={classes.accordionDetails}>
                      <BoardList
                        challengeParameter={challengeParameter}
                        distance={
                          challengeParameter === "DISTANCE" ? true : false
                        }
                        time={challengeParameter === "DISTANCE" ? true : false}
                        sort
                        data={editTeamMember(
                          runnerListData,
                          challengeParameter
                        )}
                        teamLeaderId={runner?.runnerGroupId}
                      />
                    </AccordionDetails>
                  )}
                </Accordion>
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}

        {/* If radio value is team */}
        <div className={classes.teamListContainer}>
          {radioValue == "team" &&
            teamData?.map((team, i) => (
              <Accordion
                key={team.id}
                expanded={expanded === team.id}
                onChange={handleChange(team.id)}
                style={{
                  marginLeft:
                    ApplicationUtil.hideMedal_Rank(challengeType?.id) && 0,
                  marginTop:
                    ApplicationUtil.hideMedal_Rank(challengeType?.id) && "30px",
                  width:
                    ApplicationUtil.hideMedal_Rank(challengeType?.id) && "100%",
                }}
                className={`teamList ${
                  team?.teamDistance > 0 || team?.teamTotalSteps > 0
                    ? ""
                    : "accordion"
                }`}
              >
                <AccordionSummaryContainer
                  team={team}
                  challengeParameter={challengeParameter}
                  radioValue={"team"}
                  challengeType={challengeType}
                />
                <AccordionDetails className={classes.accordionDetails}>
                  {teamListData?.length > 0 && (
                    <BoardList
                      challengeParameter={challengeParameter}
                      distance={
                        challengeParameter === "DISTANCE" ? true : false
                      }
                      time={challengeParameter === "DISTANCE" ? true : false}
                      sort
                      data={editTeamMember(teamListData, challengeParameter)}
                      teamLeaderId={team?.teamLeaderId}
                      style={{ width: "100%" }}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
        {/* If radio value is runner group */}
        <div className={classes.teamListContainer}>
          {radioValue === "runnerGroup" &&
            runnersData?.map((runner, i) => (
              <Accordion
                key={runner.runnerGroupId}
                expanded={expanded === runner?.runnerGroupId}
                onChange={handleChange(runner?.runnerGroupId)}
                className={`teamList ${
                  runner?.runnerGroupDistance > 0 ||
                  runner?.runnerGroupTotalSteps > 0
                    ? ""
                    : "accordion"
                }`}
              >
                <AccordionSummaryContainer
                  runner={runner}
                  challengeParameter={challengeParameter}
                  radioValue={"runnerGroup"}
                  challengeType={challengeType}
                />
                <AccordionDetails className={classes.accordionDetails}>
                  {runnerListData?.length > 0 && (
                    <BoardList
                      challengeParameter={challengeParameter}
                      distance={
                        challengeParameter === "DISTANCE" ? true : false
                      }
                      time={challengeParameter === "DISTANCE" ? true : false}
                      sort
                      data={editTeamMember(runnerListData, challengeParameter)}
                      teamLeaderId={runner?.runnerGroupId}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
        </div>

        <div className={classes.teamListContainer}>
          {radioValue === "AgeWise" &&
            ageWiseData?.map((ageGroupItem, i) => (
              <div className={classes.winnerTeamsContainer}>
                <div
                  className={classes.winnerIconsContainer}
                  style={{ visibility: i + 1 > 3 && "hidden" }}
                >
                  <img
                    src={
                      i + 1 === 1
                        ? GoldMedal
                        : i + 1 === 2
                        ? SilverMedal
                        : i + 1 === 3
                        ? BronzeMedal
                        : null
                    }
                    alt="gold-medal"
                    className="medalIcons"
                  />
                </div>

                <Accordion
                  key={ageGroupItem.id}
                  expanded={expanded === ageGroupItem?.id}
                  onChange={handleChange(ageGroupItem?.id)}
                  className={`teamList  ${classes.teamLayout}`}
                  style={{ marginLeft: "0" }}
                >
                  <AccordionSummaryContainer
                    AgeItem={ageGroupItem}
                    ageIndex={i}
                    radioValue={"AgeWise"}
                    // className={`accordion ${classes.teamLayout}`}
                  />
                  <AccordionDetails className={classes.accordionDetails}>
                    {ageGroupItem?.participantsDto?.length > 0 && (
                      <BoardList
                        // challengeParameter={challengeParameter}
                        // distance={
                        //   challengeParameter === "DISTANCE" ? true : false
                        // }
                        // time={challengeParameter === "DISTANCE" ? true : false}
                        // sort
                        radioValue="AgeWise"
                        AgeWiseData={ageGroupItem?.participantsDto}
                        teamLeaderId={ageGroupItem?.id}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
        </div>

        {/* IF data is not present then return this */}
        {!teamWinnersData?.length &&
          !runnerWinnersData?.length &&
          !teamData?.length &&
          !runnersData?.length &&
          !ageWiseData?.length && (
            <div
              style={{
                textAlign: "center",
                marginTop: "10px",
                fontWeight: 600,
                fontSize: "20px",
                color: "gray",
              }}
            >
              <Typography
                style={{
                  textAlign: "center",

                  fontSize: "20px",
                  color: "gray",
                }}
              >
                {value ? (
                  <>Team not found. Please search for another team name.</>
                ) : (
                  <>Teams not found.</>
                )}
              </Typography>
            </div>
          )}
      </div>
    </div>
  );
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(TeamBoard);

const AccordionSummaryData = ({ value, align }, props) => {
  const { classes } = props;
  return (
    <Grid
      item
      style={{
        textAlign: align ? align : "left",
        fontSize: window.innerWidth >= 600 ? "18px" : "16px",
      }}
    >
      <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
        {value}{" "}
      </span>
    </Grid>
  );
};

const AccordionSummaryContainer = (
  {
    team,
    challengeParameter,
    radioValue,
    runner,
    challengeType,
    AgeItem,
    ageIndex,
  },
  props
) => {
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
    >
      {radioValue === "team" || radioValue === "individual" ? (
        <Grid container>
          <Grid item xs={6}>
            {" "}
            <AccordionSummaryData value={team?.teamName} />{" "}
          </Grid>
          {challengeParameter === "DISTANCE" && (
            <Grid item xs={3}>
              <AccordionSummaryData
                value={
                  ApplicationUtil.formatNumberWithCommas(
                    parseFloat(team?.teamDistance / 1000).toFixed(2)
                  ) + " Km"
                }
                align={"center"}
              />
            </Grid>
          )}

          {challengeParameter === "CALORIE" && (
            <Grid item xs={3}>
              <AccordionSummaryData
                value={ApplicationUtil.formatNumberWithCommas(
                  team?.teamTotalCalories
                )}
                align={"center"}
              />
            </Grid>
          )}
          {challengeParameter === "STEPS" && (
            <Grid item xs={3}>
              <AccordionSummaryData
                value={ApplicationUtil.formatNumberWithCommas(
                  team?.teamTotalSteps
                )}
                align={"center"}
              />
            </Grid>
          )}
          {challengeParameter === "ELEVATION" && (
            <Grid item xs={3}>
              <AccordionSummaryData
                value={ApplicationUtil.formatNumberWithCommas(
                  team?.teamTotalElevationGain
                )}
                align={"center"}
              />
            </Grid>
          )}
          {challengeParameter === "WELLNESS_POINT" && (
            <Grid item xs={3}>
              <AccordionSummaryData
                value={ApplicationUtil.formatNumberWithCommas(
                  team?.teamTotalWellnessPoint
                )}
                align={"center"}
              />
            </Grid>
          )}

          {!ApplicationUtil.hideMedal_Rank(challengeType?.id) && (
            <Grid item xs={3}>
              <AccordionSummaryData
                value={ApplicationUtil.formatNumberWithCommas(team?.teamRank)}
                align={window.innerWidth >= 600 ? "center" : "right"}
              />
            </Grid>
          )}
        </Grid>
      ) : radioValue === "AgeWise" ? (
        <Grid container>
          <Grid item xs={6}>
            <AccordionSummaryData value={`Age Group ${AgeItem?.groupName}`} />
          </Grid>

          <Grid item xs={3}>
            <AccordionSummaryData
              value={AgeItem?.teamTotalDistance}
              align={"center"}
            />
          </Grid>

          <Grid item xs={3} style={{ textAlign: "center" }}>
            {ageIndex + 1}
          </Grid>
        </Grid>
      ) : (
        radioValue === "runnerGroup" && (
          <Grid container>
            <Grid item xs={6}>
              <AccordionSummaryData value={runner?.runnerGroupName} />
            </Grid>
            {challengeParameter === "DISTANCE" && (
              <Grid item xs={3}>
                <AccordionSummaryData
                  value={
                    ApplicationUtil.formatNumberWithCommas(
                      parseFloat(runner?.runnerGroupDistance / 1000).toFixed(2)
                    ) + " Km"
                  }
                  align={"center"}
                />
              </Grid>
            )}

            {challengeParameter === "CALORIE" && (
              <Grid item xs={3}>
                <AccordionSummaryData
                  value={ApplicationUtil.formatNumberWithCommas(
                    runner?.runnerGroupTotalCalories
                  )}
                  align={"center"}
                />
              </Grid>
            )}
            {challengeParameter === "STEPS" && (
              <Grid item xs={3}>
                <AccordionSummaryData
                  value={ApplicationUtil.formatNumberWithCommas(
                    runner?.runnerGroupTotalSteps
                  )}
                  align={"center"}
                />
              </Grid>
            )}
            {challengeParameter === "ELEVATION" && (
              <Grid item xs={3}>
                <AccordionSummaryData
                  value={ApplicationUtil.formatNumberWithCommas(
                    runner?.runnerGroupTotalElevationGain
                  )}
                  align={"center"}
                />
              </Grid>
            )}
            {challengeParameter === "WELLNESS_POINT" && (
              <Grid item xs={3}>
                <AccordionSummaryData
                  value={ApplicationUtil.formatNumberWithCommas(
                    runner?.runnerGroupTotalWellnessPoint
                  )}
                  align={"center"}
                />
              </Grid>
            )}

            <Grid item xs={3}>
              <AccordionSummaryData
                value={ApplicationUtil.formatNumberWithCommas(
                  runner?.runnerGroupRank
                )}
                align={"right"}
              />
            </Grid>
          </Grid>
        )
      )}
    </AccordionSummary>
  );
};
