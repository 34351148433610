import React, { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { Avatar, Typography } from "@material-ui/core";
import { EmojiEventsTwoTone } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Snackbar from "../../../../../common/Snackbar";
import SubmitCancelBtn from "../../../../../common/SubmitCancelBtn";
import { getChallengeLeaderboard } from "../../../../../../apis/eventService";
import httpService from "../../../../../../apis/httpService";

function BinaryResponseBoard({ classes, challengeType, isProgram }) {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [participantsWithResponse, setParticipantsWithResponse] = useState([]);
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(false);
    let programChallengeId = challengeType?.id;
    getChallengeLeaderboard(programChallengeId).then((res) => {
      let data = res?.data || [];
      if (data) {
        const outerDescendingSort = data?.sort(
          (a, b) => b?.positiveResponseCount - a?.positiveResponseCount
        );

        const innerDescendingSort = outerDescendingSort?.map((obj) => {
          return {
            ...obj,
            programChallengeParticipantDTOs:
              obj?.programChallengeParticipantDTOs?.sort(
                (aa, bb) => bb?.totalPoints - aa?.totalPoints
              ),
          };
        });

        setParticipantsWithResponse(innerDescendingSort);
        setFilteredParticipants(innerDescendingSort);
      }
    });
  }, [challengeType]);

  const handleChange = (panel) => (event, isExpanded) => {
    if (!isExpanded) {
      setExpanded(false);
      return;
    }
    setExpanded(panel ? (isExpanded ? panel : false) : false);
  };
  const searchHandleClick = () => {
    const accordionSummary = value;
    const match = accordionSummary.match(/\d+/);

    if (value) {
      if (match) {
        const number = parseInt(match[0]);
        let filterAccordion = participantsWithResponse.filter((response) => {
          return response?.positiveResponseCount == number && response;
        });
        setFilteredParticipants(filterAccordion);
      } else {
        setFilteredParticipants([]);
      }
    } else {
      if (value == "") {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Enter number of days participants continued",
        });
      }
      setFilteredParticipants(participantsWithResponse);
    }
  };

  const inputProps = {
    placeholder: "Search...",
    value: value,
    onChange: (event, { newValue }) => {
      if (newValue == "") {
        setFilteredParticipants(participantsWithResponse);
      }
      setValue(newValue);
    },
    type: "search",
    variant: "outlined",
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    let compare = participantsWithResponse?.map((response) => {
      const { positiveResponseCount } = response;
      return `Participants continued for ${positiveResponseCount} day`;
    });
    return compare.filter((summary) =>
      summary.toLowerCase().includes(inputValue)
    );
  };

  const getSuggestionValue = (suggestion) => suggestion;

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;
  const API_URL = httpService.API_URL();
  return (
    <div className={classes.container}>
      <Grid spacing={1} container className={classes.dropdownStyling}>
        <Grid item xs={4} md={2} className={classes.input}>
          <Autosuggest
            inputProps={inputProps}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={() => setSuggestions([])}
            getSuggestionValue={(suggestion) => suggestion}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={(event, { suggestion, method }) => {
              if (method === "enter") {
                event.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={4} md={2} className={classes.searchBtn}>
          <SubmitCancelBtn
            type="submit"
            label={"Search"}
            style={{ width: "60%" }}
            onClick={() => searchHandleClick(value)}
          />
        </Grid>
      </Grid>

      <Grid item>
        <div className={classes.leaderBoardTitle}>
          <EmojiEventsTwoTone className={classes.leaderBoardIcon} />
          <span className={classes.leaderBoardText}>
            Days Continued Based Leaderboard
          </span>
        </div>

        {filteredParticipants &&
          filteredParticipants?.map((response) => {
            const { positiveResponseCount, programChallengeParticipantDTOs } =
              response;
            const accordionSummary = `Participants continued for ${positiveResponseCount} day`;
            return (
              <div className={classes.binaryLayoutContainer}>
                <Accordion
                  className={classes.binaryLayout}
                  expanded={expanded === accordionSummary}
                  onChange={handleChange(accordionSummary)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Grid xs={6}>{accordionSummary}</Grid>
                    <Grid xs={3}>{programChallengeParticipantDTOs.length}</Grid>
                  </AccordionSummary>

                  <AccordionDetails
                    className="accordionDetails"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {programChallengeParticipantDTOs.length > 0 &&
                      programChallengeParticipantDTOs.map(
                        (participant, idx) => (
                          <div className={classes.listContainer}>
                            <div className={classes.listStyling}>
                              <div
                                key={idx}
                                className={classes.individualListStyling}
                              >
                                <Avatar
                                  src={
                                    participant?.runnerProfileLink
                                      ? participant?.runnerProfileLink.includes(
                                          "https"
                                        )
                                        ? `${participant?.runnerProfileLink}`
                                        : `${API_URL}${participant?.runnerProfileLink}`
                                      : ""
                                  }
                                  className={classes.profileImage}
                                >
                                  {participant.firstName
                                    .charAt(0)
                                    .toUpperCase()}
                                </Avatar>
                                <span className={classes.nameStyling}>
                                  {participant.firstName}{" "}
                                  {participant.lastName
                                    ? participant.lastName
                                    : ""}
                                </span>
                                <span className={classes.individualListStyling}>
                                  {participant?.totalPoints > 0
                                    ? participant?.totalPoints
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}

        {!filteredParticipants?.length && (
          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
              fontWeight: 600,
              fontSize: "20px",
              color: "gray",
            }}
          >
            <Typography
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "gray",
              }}
            >
              {value ? <>Day not Present.</> : <>Participant not continued.</>}
            </Typography>
          </div>
        )}
      </Grid>
    </div>
  );
}

const styles = (theme) => ({
  container: {
    width: "90%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dropdownStyling: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      //   justifyContent: "space-between",
      marginBottom: "20px",
    },
  },
  searchBtn: {
    display: "flex",
    justifyContent: "center",
  },
  accordionDetails: {
    display: "flex",
    flexDirection: "column",
  },
  listContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    width: "100%",
    margin: "10px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
      display: "flex",
      width: "97%",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  listStyling: {
    backgroundColor: "white",
    color: "grey",
    textAlign: "left",
    padding: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "20px",
    height: "100%",
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      height: "100%",
      width: "100%",
      margin: "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  individualListStyling: {
    fontWeight: "bold",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    flex: "1",
    [theme.breakpoints.down("sm")]: {},
  },
  nameStyling: {
    fontWeight: "bold",
    textAlign: "left",
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    paddingLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10px",
    },
  },
  leaderBoardTitle: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    marginTop: "5px",
  },
  leaderBoardIcon: {
    color: "#F79824",
  },
  leaderBoardText: {
    color: "rgb(40, 131, 181)",
    fontSize: "20px",
  },
  binaryLayoutContainer: {
    marginTop: "30px",
    alignItems: "flex-start",

    "& .MuiAccordionSummary-content": {
      justifyContent: "space-between",
    },
  },
  binaryLayout: {
    color: "#ffffff",
    backgroundColor: "#0077b6",
    borderRadius: "0 !important",
  },
  input: {
    "& .react-autosuggest__container": {
      position: "relative",
    },

    "& .react-autosuggest__input": {
      border: "1px solid #08456C",
      padding: "10px",
      borderRadius: 5,
      height: "40px",
      font: "inherit",
      width: "100%",
    },
    "& .react-autosuggest__input--focused": {
      outline: "none",
    },

    "& .react-autosuggest__input--open": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },

    "& .react-autosuggest__suggestions-container": {
      display: "none",
    },

    "& .react-autosuggest__suggestions-container--open": {
      display: "block",
      position: "absolute",
      top: "40px",
      width: "100%",
      border: "1px solid #aaa",
      backgroundColor: "#fff",
      fontFamily: "Helvetica, sans-serif",
      fontWeight: 300,
      fontSize: "16px",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      zIndex: 2,
      overflow: "auto",
      maxHeight: "300px",
    },

    "& .react-autosuggest__suggestions-list": {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },

    "& .react-autosuggest__suggestion": {
      cursor: "pointer",
      padding: "10px 20px",
    },

    "& .react-autosuggest__suggestion--highlighted": {
      backgroundColor: "#ddd",
      color: "#000",
    },
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(BinaryResponseBoard);
