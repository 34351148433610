
import React, { Component } from "react";
import { connect } from "react-redux";
import { updateUserDetails } from "../../../redux/user";
import { updateSupportUserDetails } from "../../../redux/supportUser";
import AppToolBar from "../../common/AppToolBar";
import Properties from "../../../properties/Properties";
import { CustomTextField } from "../../common/CustomTextField";
import { IconCalendarBlue } from "../../common/SvgIcon";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import MultiSelect from "../../common/MultiSelect";
import SubmitCancelBtn from "../../common/SubmitCancelBtn";
import getUserDetails from "./../../../apis/userService";
import editUserProfile from "./../../../apis/editProfile";
import ApplicationConstants from "../../../constants/ApplicationConstants";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Snackbar from "../../common/Snackbar";
import { format } from "date-fns";
import getRunnerGroupList from "./../../../apis/runnerGroupService";
import Header from "./../../common/Header";
import OrganizerCommonHeader from "../support/organizer/OrganizerCommonHeader";
import ApplicationUtil from "../../../utils/ApplicationUtil";
import { countryList } from "../../../constants/CountryList";
import { isBrowser, isMobile } from "react-device-detect";
import { validateEmail } from "../../../utils/Validator";
import { withRouter } from "react-router-dom";
const styles = (theme) => ({
  mainPanel: {
    marginTop: "68px",
    marginLeft: isMobile ? "10px" :"6.7%",
    marginRight: isMobile ? "10px" :"6.7%",
    width:'100%'
  },
  boxCss: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    flexGrow: 1,
    marginLeft: "6.7%",
    marginRight: "6.7%",
    boxShadow: "none !important",
  },

  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },
  boxPaddingCss: {
    paddingRight: "0px !important",
  },
});

class EditProfileContainer extends Component {
  state = {
    genderList: [
      { label: "Male", value: "MALE" },
      { label: "Female", value: "FEMALE" },
      { label: "Other", value: "OTHER" },
    ],
    countryCodeList: countryList, //ApplicationConstants.CountryCodes,
    countryList: ApplicationConstants.countries,
    contactCode: { label: "India", value: " 91", code: "IN" },
    userName: "",
    firstName: "",
    firstNameError: false,
    lastName: "",
    lastNameError:false,
    contactNumber: "",
    city: "",
    country: "",
    pincode: "",
    genderError: false,
    gender:"",
     bloodGroup:"",
    bloodGroupError:false,
    dob: null,
    dobError: false,
    countryError: false,
    runnerGroupList: null,
    otherGroup: false,
    groupDetails: null,
    selectedRunnerGroup: null,
    runnerGroup: "",
    eventType: null,
    staticDataList: null,
    selectedStaticData: "",
    eventTypeList: [
      { label: "Run", value: "RUN" },
      { label: "Cycle", value: "CYCLE" },
    ],
    bloodGroupList:ApplicationConstants.bloodGroupList,
    runnerStats: {},
    runPbDistance: "",
    runPbDistanceError: false,
    cyclePbDistanceError: false,
    cyclePbDistance: "",
    runPbTime: "",
    runPbTimeError: false,
    cyclePbTime: "",
    cyclePbTimeError: false,
    selectedRunStaticData: {},
    selectedCycleStaticData: {},
    isRun: true,
    staticRunList: [
      { label: 5, value: "5K" },
      { label: 10, value: "10K" },
      { label: 21.1, value: "21.1K" },
      { label: 42.2, value: "42.2K" },
    ],
    staticCycleList: [
      { label: 10, value: "10K" },
      { label: 20, value: "20K" },
      { label: 30, value: "30K" },
      { label: 40, value: "40K" },
    ],
    runHour: "",
    runSecond: "",
    runMinute: "",
    cycleHour: "",
    cycleMinute: "",
    cycleSecond: "",
    contactReadOnly: false,
    emailReadOnly:false
  };

  componentDidMount() {
    document.title = Properties.Page_Title_Edit_Profile;
    let runnerDetails = ApplicationUtil.getRunnerDetails();
    let supportData = ApplicationUtil.getSupportDetails();
    let runnerUserId = runnerDetails?.id;
    let supportUserId = supportData?.id;

    let runner_token = runnerDetails?.token;
    let supportOrganizer_token = supportData?.token;

    let token =
      this.props.location?.state?.view === "organizer"
        ? supportOrganizer_token
        : runner_token;
    let userId =
      this.props.location?.state?.view === "organizer"
        ? supportUserId
        : runnerUserId;

        getRunnerGroupList().then((groupData) => {

          let runnerData = groupData.data?.data?.map((x) => {
            if(x?.city){
            x.city =   x.city.toLowerCase()
            }
            if(x?.groupVisibility){
  
              return x
            }
          })
  
          // logic for grouping runner groups based on city
  
          let runnerGroupWithCity = runnerData.filter((x) => x?.city).map((a)=>{
            a.label = a?.name
            a.value = `${a?.name} ${a?.city.toUpperCase()}`
            return a
          })
          let runnerGroupWithOther = runnerData.filter((x) => !x?.city).map((a)=>{
  
            a.label = a?.name
            a.value = a?.name
            return a
          })
  
          let cityList = []
          let city;
          runnerGroupWithCity.forEach((item)=>{
            if(city !== item.city && !cityList.includes(item.city)){
              cityList.push(item.city)
              city = item.city
            }
  
          })
  
          if(runnerGroupWithOther?.length > 0){
            cityList.push('Others')
          }
          cityList.push('Custom')
  
          let list = []
           cityList.forEach((item, index)=>{
            let obj = {
              id:index+1,
              // label : `${item.charAt(0).toUpperCase()}${item.substring(1)}`,
              label:item,
              options :item === 'Custom' ? [{label:'Other'}]: item === 'Others' ? runnerGroupWithOther : runnerGroupWithCity.filter((x) => x.city === item)
            }
            list.push(obj)
  
           })
  
    
          this.setState({ runnerGroupList: list });
          getUserDetails(token, userId).then((res) => {
            let user = res.data?.user
            if(user?.groupDetails){
              let details = {...user?.groupDetails}
              details.label = details?.name
              details.value = details?.city ?  `${details?.name} ${details?.city.toUpperCase()}` : details?.name
             
              this.setState({selectedRunnerGroup:details})
             }
      
            let isUser = true;
            if (this.props.location?.state?.view === "organizer") {
              isUser = false;
            }
            let contactReadOnly = false;
            let emailReadOnly = false
            if (user?.email) {
              emailReadOnly = true;
            }  
              if (user?.contactNumber) {
                contactReadOnly = true;
              }  
             
            this.setState({
              isUser: isUser,
              userName: localStorage.getItem("userName"),
              firstName: user?.firstName,
              lastName: user?.lastName,
              email: user?.email,
              dob: user?.dateOfBirth ? user?.dateOfBirth : "",
              city: user?.city,
              pincode: user?.pincode,
              contactNumber: user?.contactNumber
                ? user?.contactNumber
                : "",
              height: user?.height,
              weight: user?.weight,
              role: user?.role,
              contactReadOnly: contactReadOnly,
              emailReadOnly:emailReadOnly,
              contactMandatory:runnerDetails?.skipManadatorySignupFields ? false : true,
              emailMandatory:runnerDetails?.skipManadatorySignupFields ? false : true

            });
            this.state.genderList.map((g) => {
              if (g.value === user?.gender) {
                this.setState({ gender: g });
              }
            });
            this.state.bloodGroupList.map((b) => {
              if (b.value === user?.bloodGroup) {
                this.setState({ bloodGroup: b });
              }
            });
            if (user?.countryCode) {
              let contactCode = countryList.find(
                (x) => x.value === user?.countryCode
              );
              this.setState({
                contactCode: contactCode,
              });
            }
      
            this.state.countryList.map((c) => {
              if (c.value === user?.country) this.setState({ country: c });
            });
      
            if (res.data.runnerStats) {
              if (res.data.runnerStats.runPbDistance) {
                this.setState({ runPbDistance: res.data.runnerStats.runPbDistance });
                this.state.staticRunList.map((e) => {
                  if (e.value === res.data.runnerStats.runPbDistance) {
                    this.setState({ selectedRunStaticData: e });
                  }
                });
              }
              if (res.data.runnerStats.cyclePbDistance) {
                this.setState({
                  cyclePbDistance: res.data.runnerStats.cyclePbDistance,
                });
                this.state.staticCycleList.map((e) => {
                  if (e.value === res.data.runnerStats.cyclePbDistance) {
                    this.setState({ selectedCycleStaticData: e });
                  }
                });
              }
      
              if (res.data.runnerStats.cyclePbDistance) {
                this.setState({
                  cyclePbDistance: res.data.runnerStats.cyclePbDistance,
                });
              }
      
              if (
                res.data.runnerStats.runPbDistance &&
                res.data.runnerStats.runPbTime
              ) {
                this.state.eventTypeList.map((e) => {
                  if (e.value === "RUN") this.setState({ eventType: e });
                });
                let time = res.data.runnerStats.runPbTime;
      
                let hr = time.split(":");
                ApplicationConstants.Hours.map((e) => {
                  if (e.value === hr[0]) this.setState({ runHour: e });
                });
                ApplicationConstants.valueArray.map((e) => {
                  if (e.value === hr[1]) this.setState({ runMinute: e });
                });
                ApplicationConstants.valueArray.map((e) => {
                  if (e.value === hr[2]) this.setState({ runSecond: e });
                });
                this.setState({
                  runPbTime:
                    this.state.runHour.value +
                    ":" +
                    this.state.runMinute.value +
                    ":" +
                    this.state.runSecond.value,
                });
              }
      
              if (res.data.runnerStats.runPbTime) {
                this.setState({ runPbTime: res.data.runnerStats.runPbTime });
              }
      
              if (res.data.runnerStats.cyclePbTime) {
                this.setState({ cyclePbTime: res.data.runnerStats.cyclePbTime });
              }
      
              if (
                res.data.runnerStats.cyclePbDistance &&
                res.data.runnerStats.cyclePbTime
              ) {
                let time = res.data.runnerStats.cyclePbTime;
                let hr = time.split(":");
                ApplicationConstants.Hours.map((e) => {
                  if (e.value === hr[0]) this.setState({ cycleHour: e });
                });
                ApplicationConstants.valueArray.map((e) => {
                  if (e.value === hr[1]) this.setState({ cycleMinute: e });
                });
                ApplicationConstants.valueArray.map((e) => {
                  if (e.value === hr[2]) this.setState({ cycleSecond: e });
                });
                this.setState({
                  cyclePbTime:
                    this.state.cycleHour.value +
                    ":" +
                    this.state.cycleMinute.value +
                    ":" +
                    this.state.cycleSecond.value,
                });
              }
            }
          });
        });

  }
  navigateToProfile = () => {
    this.props.history.push({
      pathname: "/profile",
      state: {
        view:
          // this.props.location?.state?.view
          this.state.role === "RUNNER" ? "user" : "organizer",
      },
    });
  };
  handleGenderChange = (selectedValues) => {
    var arr = [];
    console.log('selec',selectedValues )
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        gender: selectedValues,
        genderError: false,
      });
      console.log('arr',this.state.gender);
    } else {
      this.setState({
        gender: selectedValues,
        genderError: true,
      });
    }
  };
  handleBloodGroupChange=(selectedValues)=>{
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        bloodGroup: selectedValues,
        // bloodGroupError: false,
      });
    } 
  }
  handleRunnerGroupChange = (selectedValues) => {
    this.setState({selectedRunnerGroup:selectedValues, groupDetails:selectedValues})
      
    if (selectedValues?.label == "Other") this.setState({ otherGroup: true });
    else this.setState({ otherGroup: false });
  };
  handleCodeChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      this.setState({
        contactCode: selectedValues,
      });
    } else {
      this.setState({
        contactCode: selectedValues,
      });
    }
  };
  handleTypeChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      if (selectedValues.value === "RUN") this.setState({ isRun: true });
      else this.setState({ isRun: false });
      arr.push(selectedValues.value);

      this.setState({
        activityType: selectedValues.label,
        eventType: selectedValues,
        // eventTypeError: false,
        eventCategory: "",
      });
      this.SeperateEventTypes(this.state.eventTotalList, selectedValues.value);
    } else {
      this.setState({
        eventType: selectedValues,
        // eventTypeError: true,
      });
    }
  };
  SeperateEventTypes = (list, type) => {
    let staticRunList = [
      { label: 5, value: "5K" },
      { label: 10, value: "10K" },
      { label: 21.1, value: "21.1K" },
      { label: 42.2, value: "42.2K" },
    ];
    let staticCycleList = [
      { label: 10, value: "10K" },
      { label: 20, value: "20K" },
      { label: 30, value: "30K" },
      { label: 40, value: "40K" },
    ];
    this.setState({
      staticDataList: type === "RUN" ? staticRunList : staticCycleList,
    });
  };

  //cycle type
  handleStaticCycleDataChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        selectedCycleStaticData: selectedValues,
        cyclePbDistanceError: false,
      });
    }
  };
  handleCycleHourChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        cycleHour: selectedValues,
        //hourError: false,
      });
    } else {
      this.setState({
        cycleHour: selectedValues,
        //hourError: true,
      });
    }
  };
  handleCycleMinuteChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        cycleMinute: selectedValues,
        //minuteError: false,
      });
    } else {
      this.setState({
        cycleMinute: selectedValues,
        //minuteError: true,
      });
    }
  };
  handleCycleSecondChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        cycleSecond: selectedValues,
        //secondError: false,
      });
    } else {
      this.setState({
        cycleSecond: selectedValues,
        //secondError: true,
      });
    }
  };

  //run type
  handleStaticRunDataChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        selectedRunStaticData: selectedValues,
        runPbDistanceError: false,
      });
    }
  };
  handleRunHourChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        runHour: selectedValues,
        hourError: false,
      });
    } else {
      this.setState({
        runHour: selectedValues,
        //hourError: true,
      });
    }
  };
  handleRunMinuteChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        runMinute: selectedValues,
        //minuteError: false,
      });
    } else {
      this.setState({
        runMinute: selectedValues,
        //minuteError: true,
      });
    }
  };
  handleRunSecondChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        runSecond: selectedValues,
        //secondError: false,
      });
    } else {
      this.setState({
        runSecond: selectedValues,
        //secondError: true,
      });
    }
  };

  goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  validateEmailId = (value) => {
    let isValidEmail = validateEmail(value);

    if (isValidEmail || value === "") {
      this.setState({ email: value, emailError: false });
    } else {
      this.setState({ emailError: true, email: value });
    }
  };
  ValidateFields = () => {
    const {
      firstName,
      // lastName,
      gender,
       bloodGroup,
      dob,
      contactNumber,
      city,
      pincode,
      country,
      email,
      emailError,
      runHour,
      runMinute,
      runSecond,
      cycleHour,
      cycleMinute,
      cycleSecond,
      eventType,
      firstNameError,
      lastNameError,
      lastName,
      contactMandatory
    } = this.state;
    if (firstName === "" || firstNameError) {
      this.setState({ firstNameError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: (firstName && firstNameError) ? "Invalid first name, no special characters allowed except (-, ') " : "Please enter first name.",
      });
      this.goToTop();
    } else  if (lastName && lastNameError) {
      this.setState({ lastNameError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Invalid last name, no special characters allowed except (-, ') "        ,
      });
      this.goToTop();
    } else if (gender === "") {
      this.setState({ genderError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select gender.",
      });
      this.goToTop();
    } else if (dob === null || dob === "") {
      this.setState({ dobError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select valid date of birth.",
      });
      this.goToTop();
    }  else if( !validateEmail(email) && email!==""){
    this.setState({ emailError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
         message: "Please enter valid email address.",
      });
      this.goToTop();
    
    }
    else if (contactMandatory && contactNumber === "") {
      this.setState({ contactNumberError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter contact number.",
      });
      this.goToTop();
    }
     else if (city === "") {
      this.setState({ cityError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter city.",
      });
      this.goToTop();
    } else if (pincode === "") {
      this.setState({ zipError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter pincode.",
      });
      this.goToTop();
    } else if (country === "") {
      this.setState({ countryError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select country.",
      });
      this.goToTop();
    } else if (
      eventType != null &&
      eventType.value === "RUN" &&
      this.state.selectedRunStaticData.value === undefined
    ) {
      this.setState({ runPbDistanceError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select Distance for Run type.",
      });
    } else if (
      eventType != null &&
      eventType.value === "CYCLE" &&
      this.state.selectedCycleStaticData.value === undefined
    ) {
      this.setState({ cyclePbDistanceError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select Distance for Cycle type.",
      });
    } else if (
      eventType &&
      eventType.value === "RUN" &&
      runHour === "" &&
      runMinute === "" &&
      runSecond === ""
    ) {
      this.setState({ runPbTimeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select all fields of Time for Run type.",
      });
    } else if (
      eventType &&
      eventType.value === "CYCLE" &&
      cycleHour === "" &&
      cycleMinute === "" &&
      cycleSecond === ""
    ) {
      this.setState({ cyclePbTimeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select all fields of Time for Cycle type.",
      });
    } else if (
      eventType &&
      eventType.value === "RUN" &&
      (runHour !== "" || runMinute !== "" || runSecond !== "")
    ) {
      if (runHour === "") {
        this.setState({ runPbTimeError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please select all fields of Time for Run type.",
        });
        this.goToTop();
      } else if (runMinute === "") {
        this.setState({ runPbTimeError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please select all fields of Time for Run type.",
        });
        this.goToTop();
      } else if (runSecond === "") {
        this.setState({ runPbTimeError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please select all fields of Time for Run type.",
        });
        this.goToTop();
      } else {
        this.saveFields();
      }
    } else if (
      (eventType && eventType.value === "CYCLE" && cycleHour !== "") ||
      cycleMinute !== "" ||
      cycleSecond !== ""
    ) {
      if (cycleHour === "") {
        this.setState({ cyclePbTimeError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please select all fields of Time.",
        });
        this.goToTop();
      } else if (cycleMinute === "") {
        this.setState({ cyclePbTimeError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please select all fields of Time.",
        });
        this.goToTop();
      } else if (cycleSecond === "") {
        this.setState({ cyclePbTimeError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please select all fields of Time.",
        });
        this.goToTop();
      } else {
        this.saveFields();
      }
    } else {
      this.saveFields();
    }
  };
  saveFields = () => {
    let exactDate = format(new Date(this.state.dob), "dd-MM-yyyy");
    const {
      firstName,
      lastName,
      gender,
       bloodGroup,
      //dob,
      contactCode,
      contactNumber,
      city,
      pincode,
      country,
      runnerGroup,
      email,

      height,
      weight,
      groupDetails,
      runHour,
      runMinute,
      runSecond,
      cycleHour,
      cycleMinute,
      cycleSecond,
      runnerGroupCity
    } = this.state;
    let supportData = ApplicationUtil.getSupportDetails();
    let supportUserId = supportData?.id;
    let supportOrganizer_token = supportData?.token;

    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerUserId = runnerData?.id;
    let runner_token = runnerData?.token;
    let token =
      this.props.location?.state?.view === "organizer"
        ? supportOrganizer_token
        : runner_token;
    let userId =
      this.props.location?.state?.view === "organizer"
        ? supportUserId
        : runnerUserId;
    let customParams = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      countryCode: contactCode ? contactCode.value : "91",
      contactNumber: contactNumber,
      gender: gender?.value,
      bloodGroup:bloodGroup?.value,
      dob: exactDate,
      city: city,
      pincode: pincode,
      country: country.value,
      //runnerGroup: runnerGroup,
      height: height,
      weight: weight,
    };
    if (!this.state.otherGroup) {
      //runnerGroup.map(r => ((r.id = r.id), (r.name = r.name)));
      if (groupDetails){
        
        customParams.groupDetails={name:groupDetails?.name, id:groupDetails?.id, city:groupDetails?.city, groupVisibility:groupDetails?.groupVisibility}
        customParams.runnerGroup = groupDetails?.name
        customParams.runnerGroupCity = groupDetails?.city
      }
    } else {
      customParams.groupDetails = {name:runnerGroup,groupVisibility:false,city:runnerGroupCity}
        customParams.runnerGroup= runnerGroup
customParams.runnerGroupCity = runnerGroupCity
    }

    if (this.state.eventType) {
      let statData = {};
      if (this.state.eventType.value === "RUN") {
        statData.runPbDistance = this.state.selectedRunStaticData.value;
        statData.runPbTime =
          this.state.runHour.value +
          ":" +
          this.state.runMinute.value +
          ":" +
          this.state.runSecond.value;
      }

      if (this.state.eventType.value === "CYCLE") {
        statData.cyclePbDistance = this.state.selectedCycleStaticData.value;
        statData.cyclePbTime =
          this.state.cycleHour.value +
          ":" +
          this.state.cycleMinute.value +
          ":" +
          this.state.cycleSecond.value;
      }

      customParams.runnerStats = statData;
    }
    editUserProfile(customParams, token, userId).then((res) => {
      localStorage.removeItem("userName");

      getUserDetails(token, userId).then((res) => {
        let profileData =
          this.props.location?.state?.view === "organizer"
            ? ApplicationUtil.getSupportDetails()
            : ApplicationUtil.getRunnerDetails();
        let newData = res.data.user;
        profileData = { ...profileData, ...newData };
        profileData.profilePicture = res.data?.profilePicture?.imageData;
        if (this.props.location?.state?.view === "organizer") {
          this.props.updateSupportUserDetails(profileData);
        } else {
          this.props.updateUserDetails(profileData);
        }
        localStorage.setItem(
          "userName",
          profileData?.firstName + " " + profileData?.lastName
        );
      });
      this.props.history.replace({
        pathname: "/profile",
        state: {
          editProfile: this.props.location?.state?.view ? false : true,
          view: this.state.role === "RUNNER" ? "user" : "organizer",
        },
      });
    });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  handleCountryChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      this.setState({
        country: selectedValues,
        countryError: false,
      });
    } else {
      this.setState({
        country: selectedValues,
        countryError: true,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      isUser,
      firstNameError,
      lastNameError,
      countryCodeList,
      contactNumber,
      contactNumberError,
      contactCode,
      dob,
      dobError,
      cityError,
      zipError,
      emailError,
      genderError,
      // bloodGroupError,
      countryError,
      selectedRunnerGroup,
      eventType,
      eventTypeList,
      bloodGroupList,
      selectedCycleStaticData,
      selectedRunStaticData,
      staticDataList,
      isRun,
      runHour,
      runMinute,
      runSecond,
      cycleHour,
      cycleMinute,
      cycleSecond,
      contactReadOnly,
      anchorEl,
      email,
      runnerGroupList,
      runnerGroup,
      runnerGroupCity,
      contactMandatory,
      emailMandatory,
      emailReadOnly
    } = this.state;
    return (
      <React.Fragment>
        {!isUser ? (
          <OrganizerCommonHeader
            title={"Edit Profile"}
            isProfile={true}
            backNavigate={() =>
              this.props.history.push({
                pathname: "/profile",
                state: {
                  view: this.state.role === "RUNNER" ? "user" : "organizer",
                },
              })
            }
            showBack={true}
          />
        ) : (
          <AppToolBar>
            <Header
              {...this.props}
              label={Properties.Label_EditProfile}
              showSupport={this.props.location?.state?.view}
            />
          </AppToolBar>
        )}
        <div className={classes.mainPanel}>
          <Grid
            style={{
              margin: "0px",
            }}
            container
            spacing={1}
          >
            {/* firstName and lastName */}
            <Grid
              style={{
                height: "75px",
                margin: "0px",
              }}
              container
              spacing={1}
            >
              <Grid item xs={6}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    {Properties.Label_FirstName}
                  </span>
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <CustomTextField
                  style={
                    firstNameError
                      ? {
                          border: "1px solid red",
                          height: "45%",
                          maxHeight: "45%",
                          minHeight: "45%",
                          width: isBrowser ? "360px" : "100%",
                        }
                      : {
                          border: "1px solid #08456C",
                          height: "45%",
                          maxHeight: "45%",
                          minHeight: "45%",
                          width: isBrowser ? "360px" : "100%",
                        }
                  }
                  type={"text"}
                  error={firstNameError}
                  keyToSet={"firstName"}
                  value={this.state.firstName}
                  handleChange={(prop) => (event) => {
                    this.setState({
                      firstName: event.target.value,
                      firstNameError: /^([a-zA-Z0-9- ' .]*)$/.test(event.target.value) ? false : true,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    {Properties.Label_LastName}
                  </span>
                </Typography>
                <CustomTextField
                  style={{
                    border:lastNameError ? "1px solid red" : "1px solid #08456C",
                    height: "45%",
                    maxHeight: "45%",
                    minHeight: "45%",
                    width: isBrowser ? "360px" : "100%",
                  }}
                  type={"text"}
                  keyToSet={"lastName"}
                  value={this.state.lastName}
                  handleChange={(prop) => (event) => {
                    this.setState({ lastName: event.target.value, lastNameError: /^([a-zA-Z0-9- ' .]*)$/.test(event.target.value) ? false : true});
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              style={{ width: "100%", height: "75px", margin: "0px" }}
              container
              spacing={1}
            >
              <Grid item xs={6}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    {Properties.Label_Gender}
                  </span>
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <div
                  style={{
                    width: isBrowser ? "360px" : "100%",
                  }}
                >
                  <MultiSelect
                    value={this.state.gender}
                    error={genderError}
                    options={this.state.genderList}
                    onChange={this.handleGenderChange}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    {Properties.Label_DOB}
                  </span>
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  disableFuture={true}
                  value={dob}
                  error={dobError}
                  style={{
                    width: isBrowser ? "360px" : "100%",
                  }}
                  className={classes.fieldWidth}
                  InputProps={{
                    classes: {
                      root: dobError
                        ? classes.datePickerError
                        : classes.datePicker,
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      margin: "-3px 0px",
                    },
                  }}
                  keyboardIcon={
                    <IconCalendarBlue className={classes.calendarIconCss} />
                  }
                  onChange={(date) => {
                    if (date > new Date()) {
                      this.setState({ dobError: true });
                    } else
                      this.setState({
                        dob: this.isValidDate(date) ? date : "",
                        dobError: this.isValidDate(date) ? false : true,
                      });
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              style={{
                height: "75px",
                margin: "0px",
              }}
              container
              spacing={1}
            >
              <Grid item xs={9}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    {Properties.Label_Email}
                  </span>
                {  emailMandatory && <span style={{ color: "red" }}>*</span>} 
                </Typography>{" "}
                <CustomTextField
                  style={
                     {
                          border: "1px solid #08456C",
                          height: "45%",
                          maxHeight: "45%",
                          minHeight: "45%",
                          width: "100%",
                        }
                  }
                  type={"email"}
                  keyToSet={"email"}
                  value={this.state.email}
                  handleChange={(prop) => (event) => {
                    this.validateEmailId(event.target.value);
                  }}
                  readOnly={emailReadOnly}
                />
              </Grid>
            </Grid>

            <Grid container>
              {!isMobile ? (
                <Grid
                  style={{
                    height: "75px",
                    margin: "0px",
                  }}
                  container
                  xs={6}
                >
                  <Grid item style={{ width: "97%" }}>
                    <Typography variant="body2" gutterBottom>
                      <span style={{ color: "#727272" }}>
                        {Properties.Label_ContatNumber}
                      </span>{" "}
                      {contactMandatory && <span style={{ color: "red" }}>*</span>}
                    </Typography>
                    <Grid
                      item
                     
                      style={{
                        display: "flex",
                         width: isBrowser ? "360px" : "100%",
                      }}
                    >
                      <Grid item  xs={5}>
                        <div style={{ width: "100px" }}>
                          <MultiSelect
                            value={contactCode}
                            options={this.state.countryCodeList}
                            onChange={this.handleCodeChange}
                            // isReadOnly={contactNumber!==""? contactReadOnly:""}
                            isReadOnly={contactReadOnly}
                            showIcon={true}
                          />
                        </div>
                      </Grid>
                      <Grid
                        item
                          xs={12}
                        style={{
                         width: "100%", //: "40px",
                        }}
                      >
                        <CustomTextField
                          style={
                            {
                                  border:contactNumberError ? '1px solid red' : "1px solid #08456C",
                                  height: "63%",
                                  maxHeight: "63%",
                                  minHeight: "63%",
                                  marginLeft: "2px",
                                }
                          }
                          type={"number"}
                          isNumber={true}
                          value={this.state.contactNumber}
                          error={contactNumberError}
                          keyToSet={"contactNumber"}
                          handleChange={(prop) => (event) => {
                            
                            this.setState({
                              contactNumber: event.target.value,
                              contactNumberError:
                                event.target.value.length > 10 ? true : false,
                            });
                          }}
                          // readOnly={contactNumber!==""? contactReadOnly:""}
                          readOnly={contactReadOnly}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  style={{
                    height: "75px",
                    margin: "0px",
                    paddingRight: "12px",
                  }}
                  container
                  spacing={1}
                  xs={12}
                >
                  <Grid item style={{ width: "97%" }}>
                    <Typography variant="body2" gutterBottom>
                      <span style={{ color: "#727272" }}>
                        {Properties.Label_ContatNumber}
                      </span>
                      {contactMandatory && <span style={{ color: "red" }}>*</span>}
                    </Typography>
                    <Grid
                       
                      item
                      style={{
                        display: "flex",
                         width: isBrowser ? "360px" : "100%",
                      }}
                    >
                      <Grid item   xs={5}>
                        {" "}
                        <div style={{ width: "80px" }}>
                          <MultiSelect
                            value={contactCode}
                            options={this.state.countryCodeList}
                            onChange={this.handleCodeChange}
                            // isReadOnly={contactNumber!==""? contactReadOnly:""}
                            isReadOnly={contactReadOnly}
                            showIcon={true}
                          />
                        </div>
                      </Grid>
                      <Grid item    xs={12}
                        style={{
                         width: "150px", //: "40px",
                        }}>
                        <CustomTextField
                          style={
                            contactNumberError
                              ? {
                                  border: "1px solid red",
                                  height: "63%",
                                  maxHeight: "63%",
                                  minHeight: "63%",
                                  marginLeft: "2px",
                                }
                              : {
                                  border: "1px solid #08456C",
                                  height: "63%",
                                  maxHeight: "63%",
                                  minHeight: "63%",
                                  marginLeft: "2px",
                                }
                          }
                          type={"number"}
                          isNumber={true}
                          value={this.state.contactNumber}
                          error={contactNumberError}
                          keyToSet={"contactNumber"}
                          handleChange={(prop) => (event) => {
                            
                            this.setState({

                              contactNumber: event.target.value,
                              contactNumberError:
                                event.target.value.length > 10 ? true : false,
                            });
                          }}
                          // readOnly={contactNumber!==""? contactReadOnly:""}
                          readOnly={contactReadOnly}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid
              style={{
                height: "75px",
                margin: "0px",
              }}
              container
              spacing={1}
            >
              <Grid item xs={6} sm={6}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    {Properties.Label_City}
                  </span>
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <CustomTextField
                  style={
                    cityError
                      ? {
                          border: "1px solid red",
                          height: "45%",
                          maxHeight: "45%",
                          minHeight: "45%",
                          width: isBrowser ? "360px" : "100%",
                        }
                      : {
                          border: "1px solid #08456C",
                          height: "45%",
                          maxHeight: "45%",
                          minHeight: "45%",
                          width: isBrowser ? "360px" : "100%",
                        }
                  }
                  type={"text"}
                  error={true}
                  keyToSet={"city"}
                  value={this.state.city}
                  handleChange={(prop) => (event) => {
                    this.setState({
                      city: event.target.value,
                      cityError: false,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6}  >
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272"  }}>
                    {Properties.Label_Postal}<span style={{ color: "red" }}>*</span>
                  </span>{" "}
                 
                </Typography>
                <CustomTextField
                  style={
                    zipError
                      ? {
                          border: "1px solid red",
                          height: "45%",
                          maxHeight: "45%",
                          minHeight: "45%",
                          width: isBrowser ? "360px" : "100%",
                        }
                      : {
                          border: "1px solid #08456C",
                          height: "45%",
                          maxHeight: "45%",
                          minHeight: "45%",
                          width: isBrowser ? "360px" : "100%",
                        }
                  }
                  type={"text"}
                  keyToSet={"postaclCode"}
                  value={this.state.pincode}
                  handleChange={(prop) => (event) => {
                    this.setState({
                      pincode: event.target.value,
                      zipError: false,
                    });
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              style={{
                height: "75px",
                margin: "0px",
              }}
              container
              spacing={1}
            >
              <Grid item xs={11} sm={6} md={6} lg={6} xl={6}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    {Properties.Label_Country}
                  </span>
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <MultiSelect
                  value={this.state.country}
                  error={countryError}
                  options={this.state.countryList}
                  onChange={this.handleCountryChange}
                />
              </Grid>
            </Grid>

            <Grid
              style={{ width: "100%", height: "75px", margin: "0px" }}
              container
              spacing={1}
            >
              <Grid item xs={11}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    {Properties.Label_FitnessGroup}
                  </span>
                </Typography>{" "}
                <MultiSelect
                      isGroup
                      groupedOptions={runnerGroupList}
                      isClearable
                      formatOptionLabel={(option,{context}) =>{
                         return context === 'value' ? option?.city ? `${option.label} (${option.city.toUpperCase()})` : option.label : option.label
                      }
                        }
                        // options={this.state.runnerGroupList}
                        value={selectedRunnerGroup}
                        onChange={this.handleRunnerGroupChange}
                      />
              </Grid>
            </Grid>

            {this.state.otherGroup && (
              <Grid
                style={{
                  height: "55px",
                  margin: "0px",
                }}
                container
                spacing={1}
              >
                 <Grid item xs={6}>
                    <Typography variant="body2" gutterBottom>
                      <span style={{ color: "#727272" }}>
                        {'Group Name'}
                      </span>
                    </Typography>
                      <CustomTextField
                        style={{
                          border: "1px solid #08456C",
                          height: "48%",
                          maxHeight: "48%",
                          minHeight: "48%",
                          // width:'100%'
                          // marginTop: "21px",
                          width: isBrowser ? "360px" : "160px",
                        }}
                        type={"text"}
                        keyToSet={"runnerGroup"}
                        value={runnerGroup}
                        handleChange={(prop) => (event) => {
                          this.setState({ runnerGroup: event.target.value });
                        }}
                      />
                      
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="body2" gutterBottom>
                      <span style={{ color: "#727272" }}>
                        {Properties.Label_City}
                      </span>
                    </Typography>
                      <CustomTextField
                        style={{
                          border: "1px solid #08456C",
                          height: "48%",
                          maxHeight: "48%",
                          minHeight: "48%",
                          // marginTop: "21px",
                          width: isBrowser ? "360px" : "160px",
                        }}
                        type={"text"}
                        keyToSet={"runnerGroup"}
                        value={runnerGroupCity}
                        handleChange={(prop) => (event) => {
                          this.setState({ runnerGroupCity: event.target.value });
                        }}
                      />
                      
                    </Grid>
              </Grid>
            )}

            <div
              style={{
                borderTop: "1px solid rgb(13 108 169)",
                width: "100%",
                marginTop:'20px'
              }}
            />

            <Grid
              style={{
                width: "100%",
                margin: "5px",
                paddingTop: "5px",
              }}
              container
              spacing={1}
            >
              <div>
                <Typography variant="body2" gutterBottom>
                  <span
                    style={{
                      color: "#2883B5",
                      fontSize: "23px",
                      fontWeight: "bold",
                      padding: "2px 0px",
                    }}
                  >
                    {Properties.PersonalData_label}
                  </span>
                </Typography>
              </div>
            </Grid>

            <Grid
              style={{
                height: "75px",
                margin: "0px",
              }}
              container
              spacing={1}
            >
              <Grid item xs={6}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    {Properties.Height_label} {Properties.Label_unit_cm}
                  </span>
                </Typography>
                <CustomTextField
                  style={{
                    border: "1px solid #08456C",
                    height: "45%",
                    maxHeight: "45%",
                    minHeight: "45%",
                    width: isBrowser ? "360px" : "100%",
                  }}
                  placeholder={"00"}
                  type={"number"}
                  error={true}
                  keyToSet={"height"}
                  value={this.state.height}
                  handleChange={(prop) => (event) => {
                    this.setState({ height: event.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    {Properties.Weight_label} {Properties.Label_Unit_kg}
                  </span>
                </Typography>
                <CustomTextField
                  style={{
                    border: "1px solid #08456C",
                    height: "45%",
                    maxHeight: "45%",
                    minHeight: "45%",
                    width: isBrowser ? "360px" : "100%",
                  }}
                  placeholder={"00"}
                  type={"number"}
                  keyToSet={"weight"}
                  value={this.state.weight}
                  handleChange={(prop) => (event) => {
                    this.setState({ weight: event.target.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    {Properties.Blood_Group_Label}
                  </span>
                </Typography>
                <div
                  style={{
                    width: isBrowser ? "360px" : "160px",
                  }}
                >
                  <MultiSelect
                    value={this.state.bloodGroup}
                    // error={bloodGroupError}
                    options={this.state.bloodGroupList}
                    onChange={this.handleBloodGroupChange}
                  />
                </div>
              </Grid>
            <Grid
              style={{
                // height: "75px",
                margin: "0px",
              }}
              container
              spacing={1}
            >
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    Please enter personal best
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    {Properties.Activity_type_label}
                  </span>
                </Typography>
                <div
                  style={{
                    width: isBrowser ? "360px" : "160px",
                  }}
                >
                  <MultiSelect
                    value={this.state.eventType}
                    options={eventTypeList}
                    onChange={this.handleTypeChange}
                  />
                </div>
              </Grid>
              {eventType != null && (
                <Grid
                  style={{
                    margin: "0px",
                  }}
                  container
                  spacing={1}
                >
                  {this.state.isRun ? (
                    <>
                      <Grid item xs={11}>
                        <Typography variant="body2" gutterBottom>
                          <span style={{ color: "#727272" }}>
                            {Properties.Distance_label}
                          </span>
                        </Typography>
                        <Grid item xs={12}>
                          <MultiSelect
                            error={this.state.runPbDistanceError}
                            value={this.state.selectedRunStaticData}
                            options={this.state.staticRunList}
                            onChange={this.handleStaticRunDataChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography variant="body2" gutterBottom>
                          <span style={{ color: "#727272" }}>
                            {Properties.Time_label}
                          </span>
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <MultiSelect
                              value={runHour}
                              options={ApplicationConstants.Hours}
                              onChange={this.handleRunHourChange}
                              classes={{ icon: classes.icon }}
                            />
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                color: "#2883B5",
                              }}
                            >
                              {Properties.HH_Label}
                            </span>
                          </Grid>
                          <Grid item xs={4}>
                            <MultiSelect
                              value={runMinute}
                              options={ApplicationConstants.valueArray}
                              onChange={this.handleRunMinuteChange}
                              classes={{ icon: classes.icon }}
                            />{" "}
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                color: "#2883B5",
                              }}
                            >
                              {Properties.MM_Label}
                            </span>
                          </Grid>
                          <Grid item xs={4}>
                            <MultiSelect
                              value={runSecond}
                              options={ApplicationConstants.valueArray}
                              onChange={this.handleRunSecondChange}
                              classes={{ icon: classes.icon }}
                            />{" "}
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                color: "#2883B5",
                              }}
                            >
                              {Properties.SS_Label}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={11}>
                        <Typography variant="body2" gutterBottom>
                          <span style={{ color: "#727272" }}>
                            {Properties.Distance_label}
                          </span>
                        </Typography>
                        <Grid item xs={12}>
                          <MultiSelect
                            value={this.state.selectedCycleStaticData}
                            error={this.state.cyclePbDistanceError}
                            options={this.state.staticCycleList}
                            onChange={this.handleStaticCycleDataChange}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={11}>
                        <Typography variant="body2" gutterBottom>
                          <span style={{ color: "#727272" }}>
                            {Properties.Time_label}
                          </span>
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <MultiSelect
                              value={cycleHour}
                              options={ApplicationConstants.Hours}
                              onChange={this.handleCycleHourChange}
                              classes={{ icon: classes.icon }}
                            />
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                color: "#2883B5",
                              }}
                            >
                              {Properties.HH_Label}
                            </span>
                          </Grid>
                          <Grid item xs={4}>
                            <MultiSelect
                              //error={minuteError}
                              value={cycleMinute}
                              options={ApplicationConstants.valueArray}
                              onChange={this.handleCycleMinuteChange}
                              classes={{ icon: classes.icon }}
                            />{" "}
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                color: "#2883B5",
                              }}
                            >
                              {Properties.MM_Label}
                            </span>
                          </Grid>
                          <Grid item xs={4}>
                            <MultiSelect
                              value={cycleSecond}
                              options={ApplicationConstants.valueArray}
                              onChange={this.handleCycleSecondChange}
                              classes={{ icon: classes.icon }}
                            />{" "}
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                color: "#2883B5",
                              }}
                            >
                              {Properties.SS_Label}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
            </Grid>

            {/* submit buttons */}
            <Grid
              container
              style={{
                width: "100%",
                marginTop: "30px",
                display: "flex",
                justifyContent: "center",
              }}
              spacing={2}
            >
              <Grid item xs={3}>
                <SubmitCancelBtn
                  onClick={() => {
                    this.navigateToProfile();
                  }}
                  label={"Cancel"}
                  type="submit"
                />
              </Grid>
              <Grid item xs={3}>
                <SubmitCancelBtn
                  onClick={() => this.ValidateFields()}
                  label={"Submit"}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
    supportUser: state.supportUser,
  };
}
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateUserDetails,
    updateSupportUserDetails,
  })
)(EditProfileContainer);
