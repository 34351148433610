import { Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import ProductCard from "./ProductCard";
import httpService from "../../../../apis/httpService";

function ProductBlock({ classes, productDetails, programChallengeId }) {
  const API_URL_FILE = httpService.API_URL();
  return (
    <div className={classes.container}>
      <div className="title">Suggested Products</div>
      <div>
        {[
          `${API_URL_FILE.replace(/\/$/, "")}${productDetails?.imageUrl}`,
          //"https://cdn1.vectorstock.com/i/1000x1000/10/65/vitamins-and-supplements-design-vector-8751065.jpg",
          //   "https://cdn1.vectorstock.com/i/1000x1000/10/65/vitamins-and-supplements-design-vector-8751065.jpg",
          //   "https://cdn1.vectorstock.com/i/1000x1000/10/65/vitamins-and-supplements-design-vector-8751065.jpg",
        ].map((item) => {
          return (
            <ProductCard
              item={item}
              productDetails={productDetails}
              programChallengeId={programChallengeId}
            />
          );
        })}
      </div>
    </div>
  );
}

const styles = (theme) => ({
  container: {
    padding: "10px",
    borderRadius: "10px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 132px)",
      overflowY: "scroll",
    },
    "& .title": {
      fontWeight: "600",
      fontSize: "18px",
      marginBottom: "10px",
    },
  },
});

export default compose(withStyles(styles), withRouter)(ProductBlock);
