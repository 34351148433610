import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { CustomTextField } from "../../../../common/CustomTextField";
import ChipInput from "material-ui-chip-input";
import { isBrowser } from "react-device-detect";
import MultiSelect from "../../../../common/MultiSelect";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import DialogBox from "../../../../common/DialogBox";
import SubmitCancelBtn from "../../../../common/SubmitCancelBtn";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import {
  customFieldSave,
  customFieldUpdate,
  getPostRegistrationFields,
} from "../../../../../apis/eventService";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
const styles = {
  root: {
    marginBottom: "20px",
    paddingBottom: "20px",
    borderBottom: "1px solid rgb(8, 69, 108)",
  },
  title: {
    color: "rgb(8, 69, 108)",
  },
  labelCss: {
    color: "#727272",
  },
  fieldCss: {
    border: "1px solid #08456C",
    height: "40px", //"45%",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  fieldErrorCss: {
    border: "1px solid red",
    height: "40px",
    maxHeight: "40px",
    minHeight: "40px",
  },
  tableFieldCss: {
    border: "1px solid #08456C",
    height: "40px", //"45%",
    maxHeight: "40px",
    minHeight: "40px",
  },
  chipCss: {
    border: "1px solid #08456C",
    borderRadius: "4px",
    // height: "40px", //"45%",
    padding: "2px",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  tableHead: {
    backgroundColor: "#ced4da",
    "& th": {
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
      width: "100px",
    },
  },
  tableBody: {
    "& td": {
      width: "100px",
      // textAlign: "center",
    },
  },
  fieldsContainer: {
    marginTop: "20px",
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "20px",
  },
  addIcon: {
    fontSize: "30px",
    cursor: "pointer",
    color: "rgb(8, 69, 108)",
  },
  textLink: {
    backgroundColor: "#ade8f4",
    fontWeight: "bold",
    color: "#023e8a",
    cursor: "pointer",
    borderRadius: "5px",
    width: "60px",
    margin: "auto",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&:hover": {
      color: "#03045e",
    },
  },
  textAreafieldCss: {
    border: "1px solid #08456C",
    height: "100px",
    maxHeight: "100px",
    minHeight: "100px",
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },
  moreFieldOptions: {
    padding: "8px 24px",
  },
  addButton: {
    width: "120px",
    marginLeft: "auto",
  },
  saveButton: {
    width: "120px",
  },
  option: {
    border: "1px solid #727272",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "10px",
    paddingBottom: "10px",
    // paddingRight: "",
  },
  optionIcons: {
    display: "flex",
    alignItems: "center",
  },
  optionListContainer: {
    // maxHeight: "300px",
    // overflowY: "auto",
    // overflowX: "hidden",
    marginBottom: "10px",
    paddingLeft: "10px",
  },
  topFieldAdd: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "20px",
  },
  linkText: {
    color: "rgb(8, 69, 108)",
    cursor: "pointer",
    textDecoration: "underline",
  },
  parentDivCss: {
    height: "50px",
  },
  childDivCss: {
    position: "absolute",
    width: "180px",
    maxWidth: "180px",
    minWidth: "180px",
    // zIndex: "1",
  },
};

const CustomFields = React.forwardRef((props, forwardedRef, pastEvent) => {
  const { classes } = props;
  const [sectionTitle, setSectionTitle] = useState("");

  const [fieldTypes] = useState([
    { label: "Text", value: "TEXT" },
    { label: "Single Select", value: "SINGLE_SELECT" },
    { label: "Multi Select", value: "MULTI_SELECT" },
  ]);
  const [optionField, setOptionField] = useState("");
  const [openOptionsDialog, setOpenOptionsDialog] = useState(false);
  const [groupNames, setGroupNames] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [assignIndex, setAssignIndex] = useState(0);
  const [getCustomResData, setGetCustomResData] = useState(null);
  const [isEditValue, setIsEditValue] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [openAssignGroupDialog, setOpenAssignGroupDialog] = useState(false);
  const [openManageGroupDialog, setOpenManageGroupDialog] = useState(false);
  const [editValueIndex, setEditValueIndex] = useState(null);
  const [isPastEvent, setIsPastEvent] = useState(null);

  const [fieldRequestList, setFieldRequestList] = useState([
    {
      displayName: "",
      fieldType: {},
      formName: "REGISTER_EVENT",
      fieldOptionRequestList: [],
      requiredField: false,
      displayOrder: 1,
      fieldGroupRequest: {},
    },
  ]);

  useEffect(() => {
    onPageLoad();
  }, []);
  const onPageLoad = () => {
    let getArr = [];

    let eventId = ApplicationUtil.getSupportsEventID();
    let pastEvent = localStorage.getItem("pastEvent");
    let setPermissions = JSON.parse(localStorage.getItem("supportPermissions"));
    let isChallengeUserPastDate = localStorage.getItem("isChallengePastDate");
    if (pastEvent === "true" || isChallengeUserPastDate === "true") {
      setIsPastEvent(true);
    } else {
      setIsPastEvent(!setPermissions.canModifyEvent);
    }
    getPostRegistrationFields(eventId).then((res) => {
      setGetCustomResData(res.data);
      setSectionTitle(res?.data?.customSectionLabel);
      if (res?.data?.customFields) {
        res.data.customFields.map((field, index) => {
          getArr.push({
            id: field?.customFieldId,
            displayName: field?.displayName,
            formName: field?.formName,

            fieldType: {
              label:
                field?.fieldType?.fieldTypeAttribute === "TEXT"
                  ? "Text"
                  : field?.fieldType?.fieldTypeAttribute === "SINGLE_SELECT"
                  ? "Single Select"
                  : field?.fieldType?.fieldTypeAttribute === "MULTI_SELECT"
                  ? "Multi Select"
                  : "Text",
              value: field?.fieldType?.fieldTypeAttribute,
              id: field?.fieldType?.id,
            },
            fieldOptionRequestList: field?.fieldOptions?.map((option) => {
              return {
                id: option?.id,
                optionValue: option?.optionValue,
                displayOrder: option?.displayOrder,
              };
            }),
            requiredField: field?.requiredField ? field.requiredField : false,

            displayOrder: field?.displayOrder,
            fieldGroupRequest: {
              groupName: field?.fieldGroup?.groupName,
            },
          });
        });
      }

      if (res?.data?.fieldGroups) {
        let arr = [];
        res.data.fieldGroups.map((field) => {
          arr.push(field?.fieldGroup);
        });
        setGroupNames(arr);
      }

      if (getArr.length > 0) {
        getArr.sort((a, b) => (a.displayOrder < b.displayOrder ? -1 : 1));
        setFieldRequestList(getArr);
      }
    });
  };
  const handleChangeField = (event, index) => {
    const values = [...fieldRequestList];
    let fieldName = event.target.name;

    if (fieldName === "requiredField") {
      values[index][fieldName] = event.target.checked;
    } else {
      values[index][fieldName] = event.target.value;
    }
    setFieldRequestList(values);
    setFieldError(false);
  };
  const handleChangeSelect = (selectedValue, index) => {
    let values = [...fieldRequestList];
    values[index]["fieldType"] = selectedValue;
    setFieldRequestList(values);
    setFieldError(false);
  };

  const handleEditChange = (value) => {
    let values = [...fieldRequestList];

    values[currentIndex]["fieldOptionRequestList"].splice(editValueIndex, 1, {
      ...values[currentIndex]["fieldOptionRequestList"][editValueIndex],
      optionValue: value,
    });
    setFieldRequestList(values);
  };
  const saveEditValue = () => {
    setEditValueIndex(null);
  };
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    let values = [...fieldRequestList];
    const [reorderedItem] = values[currentIndex][
      "fieldOptionRequestList"
    ]?.splice(result.source.index, 1);
    values[currentIndex]["fieldOptionRequestList"].splice(
      result.destination.index,
      0,
      reorderedItem
    );

    setFieldRequestList(values);
  };
  const handleTableOnDragEnd = (result) => {
    if (!result.destination) return;
    let values = [...fieldRequestList];
    const [reorderedItem] = values.splice(result.source.index, 1);
    values.splice(result.destination.index, 0, reorderedItem);

    setFieldRequestList(values);
  };
  const moreFieldOptions = () => {
    let arr = [];
    return (
      <div className={classes.moreFieldOptions}>
        <div className={classes.topFieldAdd}>
          <div style={{ marginLeft: "10px" }}>
            <Typography>
              <span className={classes.labelCss}>Add Options:</span>
            </Typography>
            <CustomTextField
              value={optionField}
              className={classes.fieldCss}
              handleChange={(prop) => (e) => {
                let val = e.target.value; //.trim();
                setOptionField(val);
              }}
            />
          </div>
          <div className={classes.addButton}>
            <SubmitCancelBtn
              type="submit"
              label="Add"
              onClick={() => {
                if (optionField) {
                  let values = [...fieldRequestList];

                  values[currentIndex]["fieldOptionRequestList"] = [
                    ...values[currentIndex]["fieldOptionRequestList"],
                    {
                      optionValue: optionField,
                    },
                  ];

                  setFieldRequestList(values);
                }

                setOptionField("");
              }}
            />
          </div>
        </div>
        <>
          <div style={{ marginLeft: "10px" }}>
            <Typography>
              <span className={classes.labelCss}>Current Options:</span>
            </Typography>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              saveEditValue();
            }}
          >
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="options">
                {(provided) => (
                  <div
                    className={classes.optionListContainer}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {fieldRequestList.length > 0 &&
                      fieldRequestList[
                        currentIndex
                      ].fieldOptionRequestList?.map((list, index) => (
                        <Draggable
                          key={list.optionValue}
                          draggableId={list.optionValue}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className={classes.option}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <div
                                style={{
                                  marginLeft: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ marginRight: "10px" }}>
                                  <DragIndicatorIcon
                                    style={{ color: "#727272" }}
                                  />
                                </span>
                                {editValueIndex !== index && (
                                  <span
                                    style={{ fontWeight: 600, color: "#000" }}
                                  >
                                    {list.optionValue}
                                  </span>
                                )}
                                {index === editValueIndex && (
                                  <input
                                    type="text"
                                    style={{
                                      height: "20px",
                                      fontSize: "18px",
                                      // marginLeft: "20px",
                                      outlineWidth: 0,
                                      outline: "none",
                                      border: "none",
                                    }}
                                    autoFocus
                                    value={list.optionValue}
                                    onChange={(e) => {
                                      let val = e.target.value;
                                      // val = val.replace(/\D/g, "");
                                      handleEditChange(val);
                                    }}
                                  />
                                )}
                              </div>
                              <div className={classes.optionIcons}>
                                <EditIcon
                                  style={{
                                    color: "rgb(8, 69, 108)",
                                    border: "1px solid #727272",
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setEditValueIndex(index);
                                    // setIsEditValue(true);
                                  }}
                                />
                                <ClearIcon
                                  style={{
                                    color: "rgb(8, 69, 108)",
                                    border: "1px solid #727272",
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    let values = [...fieldRequestList];
                                    values[currentIndex][
                                      "fieldOptionRequestList"
                                    ].splice(index, 1);
                                    setFieldRequestList(values);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </form>
        </>
        {fieldRequestList.length > 0 &&
          fieldRequestList[currentIndex].fieldOptionRequestList?.length > 0 && (
            <div className={classes.addButton}>
              <SubmitCancelBtn
                type="submit"
                label="Save"
                onClick={() => {
                  let values = [...fieldRequestList];

                  values[currentIndex]["fieldOptionRequestList"].map(
                    (val, index) => {
                      val.displayOrder = index + 1;
                    }
                  );

                  setFieldRequestList(values);
                  setEditValueIndex(null);
                  closeWindow();
                }}
              />
            </div>
          )}
      </div>
    );
  };
  const closeWindow = () => {
    setOpenOptionsDialog(false);
    setOpenManageGroupDialog(false);
    setOpenAssignGroupDialog(false);
  };
  const addRow = (index) => {
    fieldRequestList.map((field, index) => {
      return (field.fieldType = {
        id: field.fieldType.id,
        label: field.fieldType.fieldTypeAttribute
          ? field.fieldType.fieldTypeAttribute === "MULTI_SELECT"
            ? "Multi Select"
            : field.fieldType.fieldTypeAttribute === "TEXT"
            ? "Text"
            : "Single Select"
          : field.fieldType.label,
        value: field.fieldType.fieldTypeAttribute
          ? field.fieldType.fieldTypeAttribute
          : field.fieldType.value,
      });
    });

    if (
      fieldRequestList[index].displayName === "" ||
      fieldRequestList.some((x) => x.displayName === "")
    ) {
      setFieldError(true);
    } else if (
      Object.keys(fieldRequestList[index].fieldType).length === 0 ||
      fieldRequestList.some((x) => Object.keys(x.fieldType).length === 0)
    ) {
      setFieldError(true);
    } else {
      setFieldRequestList([
        ...fieldRequestList,
        {
          displayName: "",
          fieldType: "",
          formName: "REGISTER_EVENT",

          fieldOptionRequestList: [],
          requiredField: false,

          displayOrder: fieldRequestList.length + 1,
          fieldGroupRequest: {},
        },
      ]);
    }
  };
  const saveFields = () => {
    let groupRequestList = [];

    groupNames.map((name, index) => {
      name?.id
        ? groupRequestList.push({
            id: name?.id,
            displayOrder: name?.displayOrder ? name?.displayOrder : index + 1,
            groupName: name?.groupName,
          })
        : groupRequestList.push({
            displayOrder: index + 1,
            groupName: name?.groupName,
          });
    });

    let jsonObj = {
      customSectionLabel: sectionTitle,
      fieldRequestsList: fieldRequestList,
      fieldGroupRequestsList: groupRequestList,
    };
    if (fieldRequestList.length === 1) {
      if (fieldRequestList[0].displayName === "") {
        jsonObj.fieldRequestsList = [];
        return jsonObj;
      } else {
        return jsonObj;
      }
    } else if (
      fieldRequestList.every(
        (x) => x.displayName !== "" && Object.keys(x.fieldType).length !== 0
      )
    ) {
      return jsonObj;
    } else {
      setFieldError(true);

      fieldRequestList.map((field, index) => {
        return (field.fieldType = {
          id: field.fieldType.id,
          label: field.fieldType.fieldTypeAttribute
            ? field.fieldType.fieldTypeAttribute === "MULTI_SELECT"
              ? "Multi Select"
              : field.fieldType.fieldTypeAttribute === "TEXT"
              ? "Text"
              : "Single Select"
            : field.fieldType.label,
          value: field.fieldType.fieldTypeAttribute
            ? field.fieldType.fieldTypeAttribute
            : field.fieldType.value,
        });
      });
      return "Please add data in empty fields";
    }
  };
  const assignGroupNames = () => {
    return (
      <div style={{ marginTop: "10px" }}>
        {groupNames?.length > 0 ? (
          groupNames.map((list, index) => (
            <div
              style={{
                border: "1px solid #727272",
                padding: "10px",
                width: "60%",
                margin: "auto",
                cursor: "pointer",
                fontWeight: 600,
              }}
              onClick={() => {
                let values = [...fieldRequestList];
                values[assignIndex]["fieldGroupRequest"] = {
                  groupName: list?.groupName,
                };
                setFieldRequestList(values);
                closeWindow();
              }}
            >
              <span
                style={{ marginLeft: "20px", fontWeight: 600, color: "#000" }}
              >
                {list?.groupName}
              </span>
            </div>
          ))
        ) : (
          <div
            style={{
              textAlign: "center",
              color: "#727272",
              fontWeight: 600,
            }}
          >
            <p>{"Groups not available"}</p>
          </div>
        )}
      </div>
    );
  };
  const manageGroupNames = () => {
    return (
      <div className={classes.moreFieldOptions}>
        <div className={classes.topFieldAdd}>
          <div style={{ marginLeft: "10px" }}>
            <Typography>
              <span className={classes.labelCss}>Add Options:</span>
            </Typography>
            <CustomTextField
              value={optionField}
              className={classes.fieldCss}
              handleChange={(prop) => (e) => {
                let val = e.target.value; //.trim();
                setOptionField(val);
              }}
            />
          </div>
          <div className={classes.addButton}>
            <SubmitCancelBtn
              type="submit"
              label="Add"
              onClick={() => {
                if (optionField) {
                  setGroupNames([
                    ...groupNames,

                    {
                      groupName: optionField,
                    },
                  ]);
                }

                setOptionField("");
              }}
            />
          </div>
        </div>
        <>
          <div style={{ marginLeft: "10px" }}>
            <Typography>
              <span className={classes.labelCss}>Current Options:</span>
            </Typography>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              saveEditValue();
            }}
          >
            <div className={classes.optionListContainer}>
              {groupNames?.length > 0 &&
                groupNames.map((list, index) => (
                  <div className={classes.option}>
                    {editValueIndex !== index && (
                      <span
                        style={{
                          marginLeft: "20px",
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        {list?.groupName}
                      </span>
                    )}
                    {index === editValueIndex && (
                      <input
                        type="text"
                        style={{
                          height: "20px",
                          fontSize: "18px",
                          marginLeft: "20px",
                          outlineWidth: 0,
                          outline: "none",
                          border: "none",
                        }}
                        autoFocus
                        value={list?.groupName}
                        onChange={(e) => {
                          let values = [...groupNames];
                          let fieldValues = [...fieldRequestList];

                          fieldValues.map((field) => {
                            if (
                              field?.fieldGroupRequest?.groupName ===
                              values[index]?.groupName
                            ) {
                              field.fieldGroupRequest.groupName =
                                e.target.value;
                            }
                          });
                          setFieldRequestList(fieldValues);
                          values[index].groupName = e.target.value;
                          setGroupNames(values);
                        }}
                      />
                    )}
                    <div className={classes.optionIcons}>
                      <EditIcon
                        style={{
                          color: "rgb(8, 69, 108)",
                          border: "1px solid #727272",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEditValueIndex(index);
                          // setIsEditValue(true);
                        }}
                      />
                      <ClearIcon
                        style={{
                          color: "rgb(8, 69, 108)",
                          border: "1px solid #727272",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          let values = [...groupNames];
                          let fieldValues = [...fieldRequestList];

                          fieldValues.map((field) => {
                            if (
                              field?.fieldGroupRequest?.groupName ===
                              values[index]?.groupName
                            ) {
                              field.fieldGroupRequest = {};
                            }
                          });
                          setFieldRequestList(fieldValues);
                          values.splice(index, 1);
                          setGroupNames(values);
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </form>
        </>
        {groupNames?.length > 0 && (
          <div className={classes.addButton}>
            <SubmitCancelBtn
              type="submit"
              label="Save"
              onClick={() => {
                let values = [...groupNames];
                values.map((val, index) => {
                  val.displayOrder = index + 1;
                });
                setGroupNames(values);
                setEditValueIndex(null);
                closeWindow();
              }}
            />
          </div>
        )}
      </div>
    );
  };

  React.useImperativeHandle(forwardedRef, () => {
    return {
      updateCustomFields: (param) => {
        if (param === "refresh") {
          onPageLoad();
        } else {
          return saveFields();
        }
      },
    };
  });
  // React.useImperativeHandle(forwardedRef, () => {
  //   return {
  //     popup: () => {
  //       alert("i am child, call my function");
  //     },
  //   };
  // });
  return (
    <div className={classes.root}>
      <h3 className={classes.title}>Custom Section</h3>
      <Grid container>
        <Grid item xs={6}>
          <Typography>
            <span className={classes.labelCss}>Section Title</span>
          </Typography>
          <CustomTextField
            className={classes.fieldCss}
            type="text"
            value={sectionTitle}
            handleChange={(prop) => (e) => setSectionTitle(e.target.value)}
            readOnly={isPastEvent}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <h3
              className={classes.linkText}
              onClick={() => {
                if (!isPastEvent) setOpenManageGroupDialog(true);
              }}
            >
              Manage Field Group
            </h3>
          </Typography>
        </Grid>
      </Grid>
      {/* table + add row */}
      <div className={classes.fieldsContainer}>
        {/* fields table */}
        {fieldRequestList?.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow className={classes.tableHead}>
                <TableCell component="th">Field Name</TableCell>
                <TableCell
                  component="th"
                  style={
                    {
                      // maxWidth: "180px",
                      // minWidth: "180px",
                      // width: "180px",
                    }
                  }
                >
                  Field Type
                </TableCell>
                <TableCell component="th">Field Options</TableCell>
                <TableCell component="th">Required</TableCell>

                <TableCell component="th">Assign Group</TableCell>
                {/* <TableCell component="th"></TableCell> */}
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={handleTableOnDragEnd}>
              <Droppable droppableId="fields">
                {(provided) => (
                  <TableBody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {fieldRequestList?.map((list, index) => (
                      <Draggable
                        key={list?.displayOrder.toString()}
                        draggableId={list?.displayOrder.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <TableRow
                            className={classes.tableBody}
                            key={list.displayOrder}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <TableCell component="td">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span style={{ marginRight: "10px" }}>
                                  <DragIndicatorIcon
                                    style={{ color: "#727272" }}
                                  />
                                </span>
                                <CustomTextField
                                  type="text"
                                  name="displayName"
                                  className={
                                    list.displayName === "" && fieldError
                                      ? classes.fieldErrorCss
                                      : classes.tableFieldCss
                                  }
                                  value={list?.displayName}
                                  handleChange={(prop) => (e) => {
                                    handleChangeField(e, index);
                                  }}
                                  readOnly={isPastEvent}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              component="td"
                              style={{
                                whiteSpace: "nowrap",

                                // right: "10px",
                              }}
                            >
                              <div
                                className={classes.parentDivCss}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className={classes.childDivCss}>
                                  <MultiSelect
                                    error={
                                      Object.keys(list.fieldType).length ===
                                        0 && fieldError
                                    }
                                    options={fieldTypes}
                                    value={list.fieldType}
                                    onChange={(e) =>
                                      handleChangeSelect(e, index)
                                    }
                                    isReadOnly={isPastEvent}
                                  />
                                </div>
                              </div>
                            </TableCell>
                            <TableCell component="td" align="center">
                              {list?.fieldType?.label === "Single Select" ||
                              list?.fieldType?.label === "Multi Select" ? (
                                <span
                                  className={classes.textLink}
                                  onClick={() => {
                                    if (!isPastEvent) {
                                      setOpenOptionsDialog(true);
                                      setCurrentIndex(index);
                                    }
                                  }}
                                >
                                  {list?.fieldOptionRequestList?.length > 0
                                    ? "Edit"
                                    : "Add"}
                                </span>
                              ) : (
                                <span>{"-"}</span>
                              )}
                            </TableCell>
                            <TableCell component="td" align="center">
                              <Checkbox
                                name="requiredField"
                                checked={list?.requiredField}
                                onChange={(e) => handleChangeField(e, index)}
                                disabled={isPastEvent}
                              />
                            </TableCell>

                            <TableCell component="td" align="center">
                              <Typography variant="body2">
                                {list?.fieldGroupRequest?.groupName ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                      justifyContent: "space-between",
                                      backgroundColor: "#D3D3D3	",
                                      borderRadius: "10px",
                                      width: "80%",
                                      margin: "auto",
                                      padding: "5px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: 600,
                                        flex: 1,
                                        textAlign: "center",
                                      }}
                                    >
                                      {list.fieldGroupRequest.groupName}
                                    </span>

                                    <ClearIcon
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "25px",
                                      }}
                                      onClick={() => {
                                        let values = [...fieldRequestList];
                                        values[index]["fieldGroupRequest"] = {};

                                        setFieldRequestList(values);
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <span
                                    className={classes.linkText}
                                    onClick={() => {
                                      if (!isPastEvent) {
                                        setOpenAssignGroupDialog(true);
                                        setAssignIndex(index);
                                      }
                                    }}
                                  >
                                    <b>Assign Group</b>
                                  </span>
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="td"
                              align="center"
                              style={{ width: "50px" }}
                            >
                              <div>
                                {index === fieldRequestList?.length - 1 && (
                                  <Tooltip title="Add field">
                                    <AddCircleIcon
                                      onClick={() => {
                                        if (!isPastEvent) {
                                          addRow(index);
                                        }
                                      }}
                                      className={classes.addIcon}
                                    />
                                  </Tooltip>
                                )}
                                <Tooltip title="Delete field">
                                  <DeleteForeverIcon
                                    onClick={() => {
                                      if (!isPastEvent) {
                                        let values = [...fieldRequestList];
                                        values.splice(index, 1);
                                        setFieldRequestList(values);
                                      }
                                    }}
                                    className={classes.addIcon}
                                  />
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        ) : (
          <div style={{ width: "30%" }}>
            <SubmitCancelBtn
              type="submit"
              label="Add Field"
              onClick={() => {
                setFieldRequestList([
                  {
                    displayName: "",
                    fieldType: "",
                    formName: "REGISTER_EVENT",

                    fieldOptionRequestList: [],
                    requiredField: false,

                    displayOrder: fieldRequestList.length + 1,
                    fieldGroupRequest: {},
                  },
                ]);
              }}
            />
          </div>
        )}
      </div>

      {/* field option dialog */}
      <DialogBox
        fullWidth
        maxWidth={"sm"}
        open={openOptionsDialog}
        header={"Field Options"}
        message={moreFieldOptions()}
        onClick={() => closeWindow()}
        closeDisloag={() => closeWindow()}
      />
      {/* manage group dialog */}
      <DialogBox
        fullWidth
        maxWidth={"sm"}
        open={openManageGroupDialog}
        header={"Manage Field Group"}
        message={manageGroupNames()}
        onClick={() => closeWindow()}
        closeDisloag={() => closeWindow()}
      />
      {/* assign group dialog */}
      <DialogBox
        fullWidth
        maxWidth={"sm"}
        open={openAssignGroupDialog}
        header={"Assign Group"}
        message={assignGroupNames()}
        onClick={() => closeWindow()}
        closeDisloag={() => closeWindow()}
      />
    </div>
  );
});

export default compose(withStyles(styles), withRouter)(CustomFields);
