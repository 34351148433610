import React, { Component, Fragment, useEffect, useState } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
//import { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Typography, Grid, Divider, Box, Tooltip } from "@material-ui/core";
import MultiSelect from "./MultiSelect";
import { CustomTextField } from "./CustomTextField";
import { isBrowser } from "react-device-detect";
import Properties from "./../../properties/Properties";
import CustomFields from "./CustomFields";
const styles = (theme) => ({
  updateForm: {
    padding: "8px 10px",
    "& > div": {
      display: "flex",
      flexDirection: "column",
      padding: "10px",
      // width: "60%",
      "& .label": {
        color: "#000",
      },
    },
  },
});
const ModifyForm = ({
  classes,
  modifyValues,
  updatedDetailsData,
  updateChildStates
}) => { 
   const onChangeAgeGroup = (value) => {
    let updatedData = { ...modifyValues, selectedAgeGroup: value }
    updateChildStates(updatedData) 
} 
const  handleCorporateChange=(value)=>{
  if (value?.label === "Other") {
    let updatedData = { ...modifyValues, selectedCorporate: value,otherCorporate:true  }
    updateChildStates(updatedData);
  } else {
    let updatedData = { ...modifyValues, selectedCorporate: value ,otherCorporate:false }
    updateChildStates(updatedData);
  } 
} 
const handleRunnerGroupChange=(value)=>{
  if (value?.label == "Other") {
    let updatedData = { ...modifyValues, selectedRunnerGroup: value,otherGroup: true  }  
  updateChildStates(updatedData);
  }
  else{
    let updatedData = { ...modifyValues, selectedRunnerGroup: value,otherGroup: false  }  
  updateChildStates(updatedData);
  }  
} 
const onChangeCategory=(value)=>{
  let updatedData = { ...modifyValues, categoryType: value }
  updateChildStates(updatedData)
}
  return (
    <div className={classes.updateForm}>
     {modifyValues?.allowEditProfOnEveDetails  && <Grid container > <div>
        <span className="label">First Name</span>
        <CustomTextField
          placeholder="John"
          value={modifyValues?.firstName}
          variant="outlined"
          size="small"
          //error={firstName ? false : true}
          handleChange={(prop) => (event) => {
            let updatedData = {
                ...modifyValues,
                firstName: event.target.value,
                 
            }
            updateChildStates(updatedData)

        }}
        />
      </div>
      {/* {lastName && ( */}
      <div>
        <span className="label"> Last Name</span>
        <CustomTextField
          placeholder="Doe"
          value={modifyValues?.lastName}
          variant="outlined"
          size="small"
         
          handleChange={(prop) => (event) => {
            let updatedData = {
                ...modifyValues,
                lastName: event.target.value,
                 
            }
            updateChildStates(updatedData)

        }}
        />
      </div>
      {/* )} */}
      <div>
        <span className="label">Email</span>

        <CustomTextField
          placeholder="abc@gmail.com"
          variant="outlined"
          size="small"
          value={modifyValues?.email}
         
          handleChange={(prop) => (event) => {
            let updatedData = {
                ...modifyValues,
                email: event.target.value,
                 
            }
            updateChildStates(updatedData)

        }}
        />
      </div></Grid>}
       {modifyValues?.allowEditRegOnEveDetails && modifyValues?.showCategoryOnRegistration ?  <div>
        <span className="label">Category</span>
        <MultiSelect
          value={modifyValues?.categoryType}
          options={modifyValues?.modifyCategoryOptions}
          onChange={onChangeCategory}
        />
      </div>:""} 
     
{modifyValues?.allowEditRegOnEveDetails  && <Grid container >
      {modifyValues?.showRunnerGroup && (
        <div>
          <div>
            <span style={{ color: "#727272" }}>
              {Properties.Label_FitnessGroup}
            </span>

            <div>
              <MultiSelect
                isGroup
                groupedOptions={modifyValues?.runnerGroupList}
                isClearable
                formatOptionLabel={(option, { context }) => {
                  return context === "value"
                    ? option?.city
                      ? `${option.label} (${option.city.toUpperCase()})`
                      : option.label
                    : option.label;
                }}
                // options={this.state.runnerGroupList}
                value={ modifyValues?.selectedRunnerGroup}
                onChange={handleRunnerGroupChange}
              />
            </div>
          </div>

          {modifyValues?.otherGroup && (
            <Grid container spacing={1} style={{ margin: "0px"  }}>
              <Grid item xs={12} md={6} lg={6} style={{padding:'0'}}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" ,fontSize:'18.28px'}}>{"Group Name"}</span>
                </Typography>

                <CustomTextField
                  style={{
                    border: "1px solid #08456C",
                    height: "48%",
                    maxHeight: "48%",
                    minHeight: "48%",
                    // width:'100%'
                    // marginTop: "21px",
                    width: isBrowser && "360px"  ,
                  }}
                  keyToSet={"runnerGroup"}
                  value={modifyValues?.runnerGroup1}
                  handleChange={(prop) => (event) => {
                    let updatedData = {
                        ...modifyValues,
                        runnerGroup: event.target.value,
                         
                    }
                    updateChildStates(updatedData)
  
                }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{padding:'0'}}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272",fontSize:'18.28px' }}>
                    {Properties.Label_City}
                  </span>
                </Typography>

                <CustomTextField
                  style={{
                    border: "1px solid #08456C",
                    height: "48%",
                    maxHeight: "48%",
                    minHeight: "48%",
                    // width:'100%'
                    // marginTop: "21px",
                    width: isBrowser && "360px"  ,
                  }}
                  keyToSet={"runnerGroup"}
                  value={modifyValues?.groupCity}
                  handleChange={(prop) => (event) => {
                    let updatedData = {
                        ...modifyValues,
                        runnerGroupCity: event.target.value,
                         
                    }
                    updateChildStates(updatedData)
  
                }}
                />
              </Grid>
            </Grid>
           
          )}
        </div>
      )}
      {modifyValues?.showAgeGroup && (
        <div style={{marginTop:'20px'}}>
          <span style={{ color: "#727272" }}>{Properties.Label_AgeGroup}</span>
          <span style={{ color: 'red' }} >*</span>
          <MultiSelect
            options={modifyValues?.ageGroupList}
            value={ modifyValues?.selectedAgeGroup}
            
            onChange={onChangeAgeGroup}
          />
        </div>
      )}

      {modifyValues?.showCorporateOnReg && <div>
        <div style={{marginTop:'20px'}}>
          <span style={{ color: "#727272" }}>{Properties.Label_Corporate}</span>

          <div
            style={{
              width: isBrowser ? "360px" : "100%",
            }}
          >
            <MultiSelect
              isClearable
              options={modifyValues?.corporateList}
              value={modifyValues?.selectedCorporate}
              onChange={handleCorporateChange}
            />
          </div>
        </div>
        {modifyValues?.otherCorporate && (
          <Grid container spacing={1} xs={12} style={{ margin: "0%" }}>
            <Grid item   xs={12} md={6} lg={6}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" ,fontSize:'18.28px'}}>{"Other"}</span>
              </Typography>
           
              <CustomTextField
                style={{
                  border: "1px solid #08456C",
                  height: "48%",
                  maxHeight: "48%",
                  minHeight: "48%",
                 
                  width: isBrowser && "360px"  ,
                }}
                value={modifyValues?.corporateName}
                handleChange={(prop) => (event) => {
                  let updatedData = {
                      ...modifyValues,
                      corporateName: event.target.value,
                       
                  }
                  updateChildStates(updatedData)

              }}
              />
            </Grid>
          </Grid>
        )}
   
      </div>}
      {
                  modifyValues?.additionalDetails?.length > 0 && (
                    <div >

                      <CustomFields
                        detailsData={modifyValues?.additionalDetails}
                        editRegistration
                        hideCustomSectionLabel={modifyValues?.hideCustomSectionLabel}
                        customSectionLabel={modifyValues?.customSectionLabel}
                        updateDetailsData={updatedDetailsData}
                      />
                    </div>

                  )}
           </Grid>  }     
    </div>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(ModifyForm);
