import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Button, Grid, Tooltip, Typography } from "@material-ui/core";
import { CustomTextField } from "../../../../../common/CustomTextField";
import Properties from "../../../../../../properties/Properties";
import MultiSelect from "../../../../../common/MultiSelect";
import { isBrowser } from "react-device-detect";
import ApplicationConstants from "../../../../../../constants/ApplicationConstants";
import { updateSupportSelectedProgram } from "../../../../../../redux/supportUser";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import HelpIcon from "@material-ui/icons/Help";
import SubmitCancelBtn from "../../../../../common/SubmitCancelBtn";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { IconCalendarBlue } from "../../../../../common/SvgIcon";
import DialogBox from "../../../../../common/DialogBox";
import NewTextEditor from "../../../../../common/NewTextEditor";
import Snackbar from "../../../../../common/Snackbar";
import {
  createProgram,
  getEventOrganiserList,
  getProgramDetail,
  publishDraftedProgram,
  updateProgram,
} from "../../../../../../apis/eventService";
import { Clear } from "@material-ui/icons";
import moment from "moment";
import { useDispatch } from "react-redux";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";

const ProgramBasicTab = ({ classes, onSuccess, ...props }) => {
  const [programCreateState, setProgramCreateState] = useState({
    name: "",
    nameError: "",
    description: "",
    descriptionError: "",
    longDescription: "",
    longDescriptionError: "",
    programStartDate: null,
    programStartDateError: "",
    programEndDate: null,
    programEndDateError: "",
    departmentsName: "",
    programVisibility: "",
    programVisibilityError: "",
    programVisibilityList: ApplicationConstants.eventScopeList,
    programRegistrationEndDate: null,
    programRegistrationEndDateError: "",
    departmentsNameError: "",
    BibStartNumber: 0,
    showCancelDialog: false,
    longDescriptionDialog: false,
    descriptionDialog: false,
    htmlDescription: "",
    htmlLongDescription: "",
    programOrganizerList: [],

    departmentName: "",
    departmentsList: [],
    organizer: "",
    organizerError: "",
    programData: "",
    programLink: "",
    isPastProgram: false,
  });
  const dispatch = useDispatch();
  const currentProgram = ApplicationUtil.getSupportsProgramDetails();
  const {
    name,
    nameError,
    description,
    descriptionError,
    longDescription,
    longDescriptionError,
    programStartDate,
    programStartDateError,
    programEndDate,
    programEndDateError,
    departmentsName,
    programVisibility,
    programVisibilityError,
    programVisibilityList,

    BibStartNumber,
    programRegistrationEndDate,
    programRegistrationEndDateError,
    showCancelDialog,
    longDescriptionDialog,
    descriptionDialog,
    htmlDescription,
    htmlLongDescription,
    programOrganizerList,

    departmentName,
    departmentsNameError,
    departmentsList,
    organizer,
    organizerError,
    programData,
    programLink,
    isPastProgram,
  } = programCreateState;

  // select box value handler
  const handleVisibilityChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      setProgramCreateState((prev) => ({
        ...prev,
        programVisibility: selectedValues,
        programVisibilityError: false,
      }));
    } else {
      setProgramCreateState((prev) => ({
        ...prev,
        programVisibility: selectedValues,
        programVisibilityError: false,
      }));
    }
  };

  const handleChangeOrganizer = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      setProgramCreateState((prev) => ({
        ...prev,
        organizer: selectedValues,
        organizerError: false,
      }));
    } else {
      setProgramCreateState((prev) => ({
        ...prev,
        organizer: selectedValues,
        organizerError: false,
      }));
    }
  };

  const closeDialogBox = () => {
    setProgramCreateState((prev) => ({
      ...prev,
      showCancelDialog: false,
      name: null,
      programStartDate: null,
      programVisibility: null,
      programEndDate: null,
      description: "",
      longDescription: "",
      programRegistrationEndDate: null,
      BibStartNumber: "",
      departmentsList: [],
      departmentName: "",
      organizer: null,
    }));
    // handlerFilledData();

    props.history.push("/organizer/eventlist");
  };

  const getDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.CreateEvent_CancelClickWarning}</span>
        </Typography>
      </div>
    );
  };

  const editDescription = () => {
    return (
      <div
        style={{
          width: "80%",
          height: "auto",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <NewTextEditor
          htmlText={description ? description : ""}
          getEditedValue={(value) => {
            let formattedValue = value === "<p></p>" ? null : value.toString();

            setProgramCreateState((prev) => ({
              ...prev,

              description: formattedValue,
            }));
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <SubmitCancelBtn
            type="submit"
            label={"Submit"}
            onClick={() => {
              setProgramCreateState((prev) => ({
                ...prev,
                descriptionDialog: false,
                htmlDescription: description,
              }));
            }}
          />
        </div>
      </div>
    );
  };

  const editLongDescription = () => {
    return (
      <div
        style={{
          width: "80%",
          height: "auto",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <NewTextEditor
          htmlText={longDescription ? longDescription : ""}
          getEditedValue={(value) => {
            let formattedValue = value === "<p></p>" ? null : value.toString();

            setProgramCreateState((prev) => ({
              ...prev,

              longDescription: formattedValue,
            }));
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <SubmitCancelBtn
            type="submit"
            label={"Submit"}
            onClick={() => {
              setProgramCreateState((prev) => ({
                ...prev,
                longDescriptionDialog: false,
                htmlLongDescription: longDescription,
              }));
            }}
          />
        </div>
      </div>
    );
  };

  const validateFieldsForDraft = (btnClicked) => {
    if (name === "" || nameError === true) {
      setProgramCreateState((prev) => ({ ...prev, nameError: true }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter program name.",
      });
      goToTop();
    } else if (!programVisibility?.value || programVisibilityError === true) {
      setProgramCreateState((prev) => ({
        ...prev,
        programVisibilityError: true,
      }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter program visibility.",
      });
      goToTop();
    } else if (!programStartDate || programStartDateError === true) {
      setProgramCreateState((prev) => ({
        ...prev,
        programStartDateError: true,
      }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter program start date time.",
      });
      goToTop();
    } else if (!programEndDate || programEndDateError === true) {
      setProgramCreateState((prev) => ({
        ...prev,
        programEndDateError: true,
      }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter program end date time.",
      });
      goToTop();
    } else if (
      !programRegistrationEndDate ||
      programRegistrationEndDateError === true
    ) {
      setProgramCreateState((prev) => ({
        ...prev,
        programRegistrationEndDateError: true,
      }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter program registration end date time.",
      });
      goToTop();
    } else if (!organizer?.value || organizerError === true) {
      setProgramCreateState((prev) => ({
        ...prev,
        organizerError: true,
      }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select organizer.",
      });
      goToTop();
    } else {
      const paramsData = {
        name: name,
        programStartDate: moment(programStartDate).format(
          "DD-MM-YYYY HH:mm:ss"
        ),
        programEndDate: moment(programEndDate).format("DD-MM-YYYY 23:59:59"),
        registrationEndDate: moment(programRegistrationEndDate).format(
          "DD-MM-YYYY HH:mm:ss"
        ),
        departments: departmentsList,
        organizers: organizer,
        description: description,
        longDescription: longDescription,
        programScope: programVisibility?.value,
      };

      if (btnClicked === "publish") {
        publishDraftedProgram(currentProgram?.id).then((res) => {
          handlerFilledData();
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Program challenge published successfully",
          });
        });
      }
      if (!currentProgram) {
        createProgram(paramsData).then((res) => {
          dispatch(updateSupportSelectedProgram(res.data));

          onSuccess();
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Program challenge created successfully",
          });
        });
      } else if (btnClicked !== "publish") {
        if(!isPastProgram){

          updateProgram({
            id: currentProgram?.id,
            data: paramsData,
          }).then((res) => {
            handlerFilledData();
            onSuccess();
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Program challenge updated successfully",
            });
          });
        }
      }
    }
  };

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    getEventOrganiserList({ id: 1 })
      .then((res) => {
        res.data.map(
          (r) => ((r.label = r.name), (r.value = r.name), (r.id = r.id))
        );
        setProgramCreateState((prev) => ({
          ...prev,
          programOrganizerList: res.data,
        }));
      })
      .catch((error) => {
        console.log(error);
      });

    handlerFilledData();
  }, []);


  

  const handlerFilledData = () => {
    if (currentProgram) {
      getProgramDetail(currentProgram?.id).then((res) => {
        dispatch(updateSupportSelectedProgram(res.data));
        
        let programData = res.data;
      

        setProgramCreateState((prev) => ({
          ...prev,
          name: programData?.name,
          programEndDate: moment(
            programData?.programEndDate,
            "DD-MM-YYYY HH:mm:ss"
          ),
          programStartDate: moment(
            programData?.programStartDate,
            "DD-MM-YYYY HH:mm:ss"
          ).isValid()
            ? moment(programData?.programStartDate, "DD-MM-YYYY HH:mm:ss")
            : null,
          programRegistrationEndDate: moment(
            programData?.registrationEndDate,
            "DD-MM-YYYY HH:mm:ss"
          ).isValid()
            ? moment(programData?.registrationEndDate, "DD-MM-YYYY HH:mm:ss")
            : null,
          description: programData?.description,
          longDescription: programData?.longDescription,
          organizer: {
            ...programData?.organizers?.[0],
            label: programData?.organizers?.[0]?.name,
            value: programData?.organizers?.[0]?.name,
          },
          departmentsList: programData?.departments,
          programVisibility: {
            label: programData?.programScope,
            value: programData?.programScope,
          },
          programLink: programData?.programLink,
          programData: programData,
          isPastProgram: moment(
            programData?.programEndDate,
            "DD-MM-YYYY HH:mm:ss"
          ).isSameOrBefore(moment()),
          htmlDescription: programData?.description,
          htmlLongDescription: programData?.longDescription,
        }));

        // setProgramCreateState((prev) => ({
        //   ...prev,
        //   programData: res.data,
        // }));
      });
    }
  };

  const handlerDepartmentList = () => {
    if (!isPastProgram) {
    if (departmentName) {
      if (departmentsList.includes(departmentName)) {
        setProgramCreateState((prev) => ({
          ...prev,
          departmentsNameError: true,
        }));
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Department already exist.",
        });
      } else {
        setProgramCreateState((prev) => ({
          ...prev,

          departmentsList: [...prev.departmentsList, departmentName],
          departmentName: "",
        }));
      }
    }else{
     setProgramCreateState((prev)=>({
      ...prev,
      departmentsNameError:true
     })) 
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter department name.",
      });
    }
  }
  };

  const handleRemoveFromList = (index) => {
    if (!isPastProgram) {
      let updatedList = departmentsList.filter((item, i) => i !== index);
      setProgramCreateState((prev) => ({
        ...prev,
        departmentsList: updatedList,
      }));
    }
  };

  const copyLink = () => {
    if(programLink){
      Snackbar.show({
        variant: "success",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Link Copied",
      });
    }
  };

  return (
    <div>
      <Grid container spacing={1} style={{ padding: "10px 0px" }}>
        {/* 1 */}
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>
              {Properties.ProgramNameLabel}
            </span>
            <span className={classes.mandatoryCss}>*</span>
          </Typography>
          <CustomTextField
            value={name || ""}
            placeholder="Enter program Name"
            className={nameError ? classes.fieldErrorCss : classes.fieldCss}
            handleChange={(prop) => (e) => {
              setProgramCreateState((prev) => ({
                ...prev,
                name: e.target.value,
                nameError: false,
              }));
            }}
            readOnly={isPastProgram}
          />
        </Grid>

        {/* 2 */}
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>
              {Properties.Program_visibility_label}
            </span>
            <span className={classes.mandatoryCss}>*</span>
          </Typography>
          <div className={classes.dropdownCss}>
            <MultiSelect
              value={programVisibility || ""}
              error={programVisibilityError}
              options={programVisibilityList}
              onChange={handleVisibilityChange}
              isReadOnly={isPastProgram}
            />
          </div>
        </Grid>

        {/* 3 */}
        <Grid item xs={6}>
          <Typography
            variant="body2"
            gutterBottom
            className={classes.helpContainerCss}
          >
            <span className={classes.labelCss}>
              {Properties.Program_start_date_label}
            </span>
            <span className={classes.mandatoryCss}>*</span>
            <span className={classes.helpCss}>
              <Tooltip title={Properties.EventStartsFromHelpText}>
                <HelpIcon fontSize="small" />
              </Tooltip>
            </span>
          </Typography>

          <div>
            <KeyboardDateTimePicker
              style={{
                width: isBrowser ? "360px" : "160px",
              }}
              autoOk={true}
              variant="inline"
              inputVariant="outlined"
              // ampm={false}
              format="dd/MM/yyyy hh:mm a" //:ss-hh" //"dd/MM/yyyy"
              // disableFuture={true}
              value={programStartDate}
              minDate={currentProgram ? undefined : moment()}
              //   maxDate={localEndDate ? localEndDate : undefined}
              maxDateMessage={Properties.EventStartDateValidationMsg}
              FormHelperTextProps={{
                style: {
                  margin: "0px",
                },
              }}
              className={classes.fieldWidth}
              InputProps={{
                classes: {
                  root: programStartDateError
                    ? classes.datePickerError
                    : classes.datePicker,
                },
              }}
              keyboardIcon={
                <IconCalendarBlue className={classes.calendarIconCss} />
              }
              onChange={(date) => {
                setProgramCreateState((prev) => ({
                  ...prev,
                  programStartDate: date ? date : null,
                  programStartDateError: false,
                }));
              }}
              disabled={isPastProgram}
            />
          </div>
        </Grid>

        {/* 4 */}
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>
              {Properties.Program_end_date_label}
            </span>
            <span className={classes.mandatoryCss}>*</span>
          </Typography>
          <div>
            <KeyboardDatePicker
              autoOk={true}
              // clearable
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy "
              // disableFuture={true}
              value={programEndDate}
              minDate={programStartDate ? programStartDate : undefined}
              //   minDateMessage={Properties.EventEndDateValidationMsg}
              FormHelperTextProps={{
                style: {
                  margin: "0px",
                },
              }}
              style={{
                width: isBrowser ? "360px" : "160px",
              }}
              //error={dobError}
              className={classes.fieldWidth}
              InputProps={{
                classes: {
                  root: programEndDateError
                    ? classes.datePickerError
                    : classes.datePicker,
                },
              }}
              keyboardIcon={
                <IconCalendarBlue className={classes.calendarIconCss} />
              }
              onChange={(date) => {
                setProgramCreateState((prev) => ({
                  ...prev,
                  programEndDate: date ? date : null,
                  programEndDateError: false,
                }));
              }}
              disabled={isPastProgram}
            />
          </div>
        </Grid>

        {/* 5 */}
        <Grid item xs={6}>
          <Typography
            variant="body2"
            gutterBottom
            className={classes.helpContainerCss}
          >
            <span className={classes.labelCss}>
              {Properties.Registration_end_date_label}
            </span>
            <span className={classes.mandatoryCss}>*</span>
            <span className={classes.helpCss}>
              <Tooltip title={Properties.RegistrationEndDateHelpText}>
                <HelpIcon fontSize="small" />
              </Tooltip>
            </span>
          </Typography>

          <div>
            <KeyboardDateTimePicker
              style={{
                width: isBrowser ? "360px" : "160px",
              }}
              autoOk={true}
              clearable
              variant="inline"
              inputVariant="outlined"
              // ampm={false}
              format="dd/MM/yyyy hh:mm a" //:ss-hh" //"dd/MM/yyyy"
              // disableFuture={true}
              value={programRegistrationEndDate}
              minDate={programStartDate ? programStartDate : undefined}
              maxDate={programEndDate ? programEndDate : undefined}
              //   maxDateMessage={Properties.RegistrationEndDateValidationMsg}
              FormHelperTextProps={{
                style: {
                  margin: "0px",
                },
              }}
              className={classes.fieldWidth}
              InputProps={{
                classes: {
                  root: programRegistrationEndDateError
                    ? classes.datePickerError
                    : classes.datePicker,
                },
              }}
              keyboardIcon={
                <IconCalendarBlue className={classes.calendarIconCss} />
              }
              onChange={(date) => {
                setProgramCreateState((prev) => ({
                  ...prev,
                  programRegistrationEndDate: date,
                  programRegistrationEndDateError: false,
                }));
              }}
              disabled={isPastProgram}
            />
          </div>
        </Grid>

        {/* 6 */}

        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>
              {Properties.Program_link_label} :
            </span>
          </Typography>
          <div
            style={{
              width: isBrowser ? "360px" : "160px",
              height: "30px",
              border: "1px solid #08456C",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "2px",
              color: "#08456C",
            }}
          >
            <CustomTextField
              type="text"
              className={classes.fieldLinkCss}
              value={programLink}
              handleChange={(prop) => () => {}}
              readOnly
            />
            <CopyToClipboard text={programLink} onCopy={() => copyLink()}>
              <button
                style={{ cursor: "pointer", height: "30px", border: "none" }}
              >
                Copy
              </button>
            </CopyToClipboard>
          </div>
        </Grid>
        {/* <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>
              {Properties.Program_link_label} :
            </span>
          </Typography>
          <div
            style={{
              width: isBrowser ? "360px" : "160px",
              height: "30px",
              // border: "1px solid #08456C",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "2px",
              color: "#08456C",
            }}
          >
            <CustomTextField
              type="text"
              className={classes.fieldCss}
                value={programLink}
              placeholder="Enter URL"
              handleChange={(prop) => (e) => {
                setProgramCreateState((prev) => ({
                  ...prev,
                  programLink: e.target.value,
                }));
              }}
              //   readOnly={isPastChallenge}
            />
          </div>
        </Grid> */}

        {/* /bib */}
        {/* <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>
              {Properties?.Bib_start_number_label}
            </span>
          </Typography>
          <div
            style={{
              width: isBrowser ? "360px" : "160px",
              height: "30px",
              // border: "1px solid #08456C",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "2px",
              color: "#08456C",
            }}
          >
            <CustomTextField
              type="text"
              className={classes.fieldCss}
              //   value={videoLink}
              placeholder="Enter URL"
              handleChange={(prop) => (e) => {
                // setProgramCreateState((prev) => ({
                //   ...prev,
                //   videoLink: e.target.value,
                // }));
              }}
              //   readOnly={isPastChallenge}
            />
          </div>
        </Grid> */}

        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>
              {Properties.Departments_label}
            </span>
          </Typography>
          <div className="" style={{display:"flex"}}>
            <CustomTextField
              value={departmentName || ""}
              placeholder="Enter Department"
              className={
                departmentsNameError ? classes.fieldErrorCss : classes.fieldCss
              }
              handleChange={(prop) => (e) => {
                setProgramCreateState((prev) => ({
                  ...prev,
                  departmentName: e.target.value,
                  departmentsNameError: false,
                }));
              }}
              readOnly={isPastProgram}
            />
        
              <SubmitCancelBtn
                label={"Add"}
                onClick={() => handlerDepartmentList()}
                 type="submit"
                style={{ width: "fit-content", background: "#09456C" , marginLeft:"10px" }}
              />
          
          </div>
          <div style={{ padding: "10px 0" }}>
            {departmentsList?.length > 0 && (
              <ul className={classes.listOfDepartmentsWrapper}>
                {departmentsList?.map((department, index) => {
                  return (
                    <li className={classes.listItemOfDepartment}>
                      {department}
                      {!isPastProgram && <span
                        className={classes.deleteListItem}
                        onClick={() => handleRemoveFromList(index)}
                      >
                        <Tooltip title={"Delete"}>
                          <Clear style={{ fontSize: "12px", padding: "5px" }} />
                        </Tooltip>
                      </span> }
                     
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>
              Program Organizer
              {/* {Properties.Departments_label} */}
            </span>
            <span className={classes.mandatoryCss}>*</span> :
          </Typography>
          <div className={classes.dropdownCss}>
            <MultiSelect
              value={organizer || ""}
              error={organizerError}
              options={programOrganizerList}
              onChange={handleChangeOrganizer}
              isReadOnly={isPastProgram}
            />
          </div>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>
              {Properties?.Program_description_label}
            </span>
          </Typography>

          <div
            style={{
              width: "440px",
              height: "100px",
              padding: "0 4px",
              border: "1px solid #08456C",
              overflowY: "auto",
            }}
            onClick={() => {
              setProgramCreateState((prev) => ({
                ...prev,
                descriptionDialog: isPastProgram ? false :  true,
                htmlDescription: isPastProgram ? htmlDescription : "",
              }));
            }}
          >
            {htmlDescription && (
              <NewTextEditor readOnly htmlText={htmlDescription} />
            )}
          </div>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>
              {Properties?.Program_long_description_label}
            </span>
          </Typography>

          <div
            style={{
              width: "440px",
              height: "100px",
              padding: "0 4px",
              border: "1px solid #08456C",
              overflowY: "auto",
            }}
            onClick={() => {
              setProgramCreateState((prev) => ({
                ...prev,
                longDescriptionDialog: isPastProgram ? false : true,
                htmlLongDescription: isPastProgram ? htmlLongDescription : "",
              }));
            }}
          >
            {htmlLongDescription && (
              <NewTextEditor readOnly htmlText={htmlLongDescription} />
            )}
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          margin: "20px 5px 10px 5px",
          paddingBottom: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item xs={4}>
          <SubmitCancelBtn
            label={"Cancel"}
            onClick={() => {
              setProgramCreateState((prev) => ({
                ...prev,
                showCancelDialog: true,
              }));
            }}
            type="submit"
            style={{ width: "200px" }}
          />
        </Grid>

        <Grid item xs={4}>
          {currentProgram ? (
            <SubmitCancelBtn
              onClick={() => validateFieldsForDraft()}
              disabled={isPastProgram}
              label={"Save"}
              type="submit"
              style={{ width: "200px" }}
            />
          ) : (
            <SubmitCancelBtn
              onClick={() => validateFieldsForDraft()}
              disabled={currentProgram ? true : false}
              label={"Draft"}
              type="submit"
              style={{ width: "200px" }}
            />
          )}
        </Grid>
        {currentProgram &&
          currentProgram?.programState !== "PUBLISHED" &&
          programData && (
            <Grid item xs={4}>
              <SubmitCancelBtn
                onClick={() => validateFieldsForDraft("publish")}
                disabled={currentProgram ? true : false}
                label={"Publish"}
                type="submit"
                style={{ width: "200px" }}
              />
            </Grid>
          )}
      </Grid>

      <DialogBox
        signup={true}
        open={showCancelDialog}
        message={getDialogMsg()}
        header={""}
        label={"No"}
        onClick={() => {
          setProgramCreateState((prev) => ({
            ...prev,
            showCancelDialog: false,
          }));
        }}
        labelTwo={"Yes"}
        onClickTwo={() => {
          closeDialogBox();
        }}
        closeDisloag={() => {
          setProgramCreateState((prev) => ({
            ...prev,
            showCancelDialog: false,
          }));
        }}
      />

      {/* description dialog */}
      <DialogBox
        fullWidth
        maxWidth={"md"}
        open={descriptionDialog}
        message={editDescription()}
        header={Properties?.Program_description_label}
        onClick={() =>
          setProgramCreateState((prev) => ({
            ...prev,
            descriptionDialog: false,
            htmlDescription: description,
          }))
        }
        closeDisloag={() =>
          setProgramCreateState((prev) => ({
            ...prev,
            descriptionDialog: false,
            htmlDescription: description,
          }))
        }
      />
      <DialogBox
        fullWidth
        maxWidth={"md"}
        open={longDescriptionDialog}
        message={editLongDescription()}
        header={Properties?.Program_long_description_label}
        onClick={() =>
          setProgramCreateState((prev) => ({
            ...prev,
            longDescriptionDialog: false,
            htmlLongDescription: longDescription,
          }))
        }
        closeDisloag={() => {
          setProgramCreateState((prev) => ({
            ...prev,
            longDescriptionDialog: false,
            htmlLongDescription: longDescription,
          }));
        }}
      />
    </div>
  );
};

const styles = (theme) => ({
  fieldErrorCss: {
    border: "1px solid red",
    height: "40px",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  labelCss: {
    color: "#727272",
  },
  fieldCss: {
    border: "1px solid #08456C",
    height: "40px", //"45%",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
    // focused: {},
    // notchedOutline: {},
  },
  dropdownCss: {
    width: isBrowser ? "360px" : "160px",
  },
  helpContainerCss: {
    display: "inline-flex",
  },
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },
  mandatoryCss: { color: "red" },

  listOfDepartmentsWrapper: {
    listStyle: "none",
    margin: "0",
    padding: "0",
    height: "70px",
    display: "flex",
    gap: "10px",
    padding: "10px 5px",
    width: "70%",
    border: "1px solid gray",
    flexWrap: "wrap",
    overflowY: "auto",
    borderRadius: "5px",
  },
  listItemOfDepartment: {
    background: "#c4d4e4",
    color: "#0c1621",
    width: "fit-content",
    padding: "5px 8px",
    borderRadius: "6px",
    cursor: "pointer",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteListItem: {
    color: "red",
    fontWeight: "bold",
    fontSize: "10px",
    background: "#eeeeee",
    display: "flex",
    justifyContent: "center",
    borderRadius: "50%",
    margin: "0 0 0 3px",
  },
  fieldLinkCss: {
    width: "340px",
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ProgramBasicTab);
