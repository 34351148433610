import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@material-ui/core";
import TimerIcon from "@material-ui/icons/Timer";
import ApplicationUtil from "./../../../../../utils/ApplicationUtil";
import Properties from "./../../../../../properties/Properties";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  timing: {
    width: "100%",

    "& span": {
      fontSize: "18px",
    },
    "& .timing_heading": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "60px",
    },
    "& .timing_heading h2": {
      fontWeight: 600,
      color: "rgb(40, 131, 181)",
    },
    "& .time_icon": {
      fontSize: "30px !important",
      marginRight: "10px",
      color: "rgb(40, 131, 181)",
    },
  },
});

class Timing extends Component {
  render() {
    const { classes, data } = this.props;
    const displayColumns = data?.displayColumns?.split(",");
    return (
      <Paper className={classes.timing} square elevation={3}>
        <Paper className="timing_heading" variant="outlined">
          <TimerIcon className="time_icon" />
          <h2>{"Result"}</h2>
        </Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                style={{
                  width: displayColumns?.length === 1 ? "100px" : "auto",
                }}
              >
                <span>
                  <b>{Properties.ActivityType_label}</b>
                </span>
              </TableCell>

              {displayColumns?.length > 0 && displayColumns[0] !== "" ? (
                displayColumns?.map((column) => (
                  <TableCell
                    component="th"
                    align="center"
                    style={{
                      width: displayColumns.length === 1 ? "100px" : "auto",
                    }}
                  >
                    <span>
                      <b>
                        {column === "totalActivityDistance" &&
                          Properties.Distance_label}
                        {column === "totalActivityTime" &&
                          Properties.Time_label}

                        {column === "totalCalories" && Properties.CaloriesLabel}
                        {column === "totalSteps" && Properties.StepsLabel}
                        {column === "totalElevationGain" &&
                          Properties.Elevation_label}
                      </b>
                    </span>
                  </TableCell>
                ))
              ) : (
                <>
                  <TableCell component="th" align="center">
                    <span>
                      <b>{data?.activityType!=="STEPS" ? Properties.Distance_label :""}</b>
                    </span>
                  </TableCell>
                  <TableCell component="th" align="center">
                    <span>
                      <b>{data?.activityType!=="STEPS" ? Properties.Time_label:""}</b>
                    </span>
                  </TableCell>
                  <TableCell component="th" align="center">
                    <span>
                      <b>{data?.activityType==="STEPS" ?  Properties.StepsLabel :""}</b>
                    </span>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                {data?.activityTypeDisplayName}
              </TableCell>

              {displayColumns?.length > 0 && displayColumns[0] !== "" ? (
                displayColumns?.map((column) => (
                  <TableCell align="center">
                    <span>
                      {column === "totalActivityDistance" &&
                        (Number(data?.totalActivityDistance) >= 0
                          ? parseFloat(
                              ApplicationUtil.getFormattedKM(
                                data?.totalActivityDistance
                              ) / 1000
                            ).toFixed(2) + " Km"
                          : "-")}
                      {column === "totalActivityTime" &&
                        (data?.completionTime ? data?.completionTime : "-")}
                        {/* Defect_ID_403 / ankit */}
                      {column === "totalSteps" &&
                        ApplicationUtil.formatNumberWithCommas((data?.totalSteps >= 0 ? data?.totalSteps : "-"))
                        }
                      {column === "totalCalories" &&
                        (data?.totalCalories ? data?.totalCalories : "-")}

                      {column === "totalElevationGain" &&
                        (data?.totalElevationGain
                          ? data?.totalElevationGain
                          : "-")}
                    </span>
                  </TableCell>
                ))
              ) : (
                <>
                  <TableCell align="center">
                    <span>
                      {Number(data?.totalActivityDistance) >= 0   
                        ? parseFloat(
                            ApplicationUtil.getFormattedKM(
                              data?.totalActivityDistance
                            ) / 1000
                          ).toFixed(2) + " Km"
                         : data?.activityType==="STEPS" ? "":'-'}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span>
                      {   data?.completionTime   ? data?.completionTime :data?.activityType==="STEPS" ?  "":'-'}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span>
                      {   data?.totalSteps    ? data?.totalSteps :  data?.activityType==="STEPS"  ? "-":''}
                    </span>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }),withRouter)(Timing);
