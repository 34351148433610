import { Typography, Grid } from "@material-ui/core";
import React from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import StarsSharpIcon from "@material-ui/icons/StarsSharp";
import HorizontalLineGraph from "./../../../common/HorizontalLineGraph";
import Properties from "../../../../properties/Properties";
const styles = (theme) => ({
  totalDetails: {
    color: "#a52a2a",
    fontWeight: "bold",
    fontSize: "20px",
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",
      height: "20%",
      justifyContent: "flex-start",
      margin: "0",
    },
  },
  totalDetails1: {
    color: "#a52a2a",
    fontWeight: "bold",
    fontSize: "20px",
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",
      height: "20%",
      justifyContent: "flex-start",
      margin: "0",
    },
  },
  totalDetails2: {
    color: "#a52a2a",
    fontWeight: "bold",
    fontSize: "20px",
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",
      height: "20%",
      justifyContent: "flex-start",
      margin: "0",
      marginLeft: "5%",
    },
  },
  totalDetailsRunner: {
    color: "#a52a2a",
    fontWeight: "bold",
    fontSize: "20px",
    display: "flex",
    width: "100%",
    textAlign: "left",
    // justifyContent: "center",
    // alignItems:'center',

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",
      height: "20%",
      justifyContent: "flex-start",
      margin: "0",
    },
  },
  totalDetailsRunner1: {
    color: "#a52a2a",
    fontWeight: "bold",
    fontSize: "20px",
    display: "flex",
    width: "100%",
    textAlign: "left",
    // justifyContent: "center",
    // alignItems:'center',

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",
      height: "20%",
      justifyContent: "flex-start",
      margin: "0",
      marginLeft: "10%",
    },
  },
  runnerActivityStyling: {
    color: "#a52a2a",
    fontWeight: "bold",
    fontSize: "20px",
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",
      height: "20%",
      margin: "0",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  totalDetailsWell: {
    color: "#a52a2a",
    fontWeight: "bold",
    fontSize: "20px",
    display: "flex",
    width: "100%",
    //justifyContent: "center",
    // alignItems:'center',

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",
      textAlign: "left",
      margin: "0",
    },
  },
  totalDetails1: {
    color: "#a52a2a",
    fontWeight: "bold",
    fontSize: "20px",
    display: "flex",
    width: "100%",
    //justifyContent: "center",
    // alignItems:'center',

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",

      justifyContent: "start",
      margin: "0",
    },
  },
  graph: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      margin: "0",
      padding: 0,
    },
  },
  graphCar: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      margin: "0",
      padding: 0,
      marginBottom: "20px",
      marginTop: "10px",
    },
  },
  graphs: {
    width: "100%",
    display: "flex",

    [theme.breakpoints.down("sm")]: {
      margin: "0",
      padding: 0,
      width: "100%",
    },
  },

  graph1: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "flex-start",
      marginTop: "0",
    },
  },
  rankStyling: {
    marginLeft: "0%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10%",
    },
  },
  nestedGrid: {
    [theme.breakpoints.up("sm")]: {
      gridColumn: "span 6",
    },
    [theme.breakpoints.up("xs")]: {
      gridColumn: "span 6",
    },
    [theme.breakpoints.up("md")]: {
      gridColumn: "span 8",
    },
    [theme.breakpoints.down("xs")]: {
      gridColumn: "span 12",
    },
  },
});
function RunnerGroup(props) {
  const { classes, runnerValues, navigateToWellnessPointsDetails } = props;
  return (
    <Grid
      container
      style={{
        width: "100%",
        display: "flex",
        height: "90%",
        //borderTop: "1px solid rgb(8, 69, 108)",
      }}
    >
      {runnerValues?.showTable && (
        <>
          {runnerValues?.runnerGroupName && (
            <Grid item xs={4} sm={3} lg={4} md={4}>
              <Typography variant="body2" gutterBottom>
                <span className={classes.totalDetailsRunner}>
                  <span
                    style={{
                      color: "#727272",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {Properties.Label_RunnerGroup}
                  </span>
                </span>
                <div>
                  <span className={classes.totalDetailsRunner}>
                    <span>{runnerValues?.runnerGroupName}</span>
                  </span>
                </div>
              </Typography>
            </Grid>
          )}

          <Grid item xs={4} sm={3} lg={4} md={4}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.totalDetailsRunner1}>
                {runnerValues?.runnerGraphRunnerActivities?.length > 0 &&
                  (runnerValues?.totalRunnerDistance > 0 ||
                    runnerValues?.totalRunnerSteps > 0 ||
                    runnerValues?.totalRunnerWellnessPoints > 0) && (
                    <span
                      style={{
                        color: "#727272",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {runnerValues?.graphXAxis === "steps" ? (
                        <>{Properties.Label_Steps}</>
                      ) : runnerValues?.graphXAxis === "wellnessPoint" ? (
                        <>
                          <span className={classes.totalDetailsWell}>
                            <span
                              style={{
                                color: "#727272",
                                fontWeight: "bold",
                                fontSize: "18px",
                                cursor: "pointer",
                              }}
                              onClick={navigateToWellnessPointsDetails}
                            >
                              {Properties.Label_WellnessPoints}
                            </span>
                            <StarsSharpIcon
                              style={{
                                color: "#a52a2a",
                                cursor: "pointer",
                                fontSize: "25px",
                                //  marginLeft:'5px'
                              }}
                              onClick={navigateToWellnessPointsDetails}
                            />
                          </span>{" "}
                        </>
                      ) : runnerValues?.graphXAxis === "distance" ? (
                        <>
                          <span
                            style={{
                              color: "#727272",
                              fontWeight: "bold",
                              fontSize: "18px",
                              cursor: "pointer",
                            }}
                          >
                            {Properties.Distance_label}
                          </span>{" "}
                        </>
                      ) : (
                        ""
                      )}
                    </span>
                  )}
              </span>
              {runnerValues?.registration &&
                (runnerValues?.totalRunnerDistance > 0 ||
                  runnerValues?.totalRunnerSteps > 0 ||
                  runnerValues?.totalRunnerWellnessPoints > 0) && (
                  <div>
                    <span className={classes.totalDetails2}>
                      {runnerValues?.graphXAxis === "steps"
                        ? runnerValues?.totalRunnerSteps !== undefined && (
                            <span>{runnerValues?.totalRunnerSteps}</span>
                          )
                        : runnerValues?.graphXAxis === "wellnessPoint"
                        ? runnerValues?.totalRunnerWellnessPoints !==
                            undefined && (
                            <span className={classes.runnerActivityStyling}>
                              {runnerValues?.totalRunnerWellnessPoints
                                ? runnerValues?.totalRunnerWellnessPoints
                                : "0"}
                            </span>
                          )
                        : runnerValues?.graphXAxis === "distance"
                        ? runnerValues?.totalRunnerDistance !== null && (
                            <span className={classes.totalDetails2}>
                              {runnerValues?.totalRunnerDistance % 1000 === 0
                                ? parseFloat(
                                    runnerValues?.totalRunnerDistance / 1000
                                  ).toFixed(0)
                                : parseFloat(
                                    runnerValues?.totalRunnerDistance / 1000
                                  ).toFixed(2)}
                              {" Km"}
                            </span>
                          )
                        : ""}
                    </span>
                  </div>
                )}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3} lg={4} md={4}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.totalDetailsRunner}>
                {runnerValues?.runnerGraphRunnerActivities?.length > 0 &&
                  (runnerValues?.totalRunnerDistance > 0 ||
                    runnerValues?.totalRunnerSteps > 0 ||
                    runnerValues?.totalRunnerWellnessPoints > 0) && (
                    <span
                      style={{
                        color: "#727272",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                      //  className={classes.rankStyling}
                    >
                      {Properties.Label_ResultRank}
                    </span>
                  )}
              </span>
              {runnerValues?.registration &&
                (runnerValues?.totalRunnerDistance > 0 ||
                  runnerValues?.totalRunnerSteps > 0 ||
                  runnerValues?.totalRunnerWellnessPoints > 0) && (
                  <div>
                    <span className={classes.totalDetails}>
                      {runnerValues?.runnerGroupRank && (
                        <span>
                          {runnerValues?.totalRunnerDistance !== 0 ||
                          runnerValues?.totalRunnerWellnessPoints !== 0 ||
                          runnerValues?.totalRunnerSteps !== 0
                            ? runnerValues?.runnerGroupRank
                            : "---"}
                        </span>
                      )}
                    </span>
                  </div>
                )}
            </Typography>
          </Grid>
        </>
      )}

      {runnerValues?.showGraph &&
        runnerValues?.showTable &&
        (runnerValues?.totalRunnerDistance > 0 ||
          runnerValues?.totalRunnerSteps > 0 ||
          runnerValues?.totalRunnerWellnessPoints > 0) && (
          <Grid item className={classes.graph1}>
            <Grid
              item
              style={{ marginBottom: "20px" }}
              xs={12}
              lg={8}
              md={8}
              className={classes.nestedGrid}
            >
              <div key="horizontals">
                <HorizontalLineGraph
                  teamSteps={runnerValues?.totalRunnerSteps}
                  teamWellness={runnerValues?.totalRunnerWellnessPoints}
                  teamDistance={runnerValues?.totalRunnerDistance}
                  graphData={runnerValues?.runnerGraphRunnerActivities}
                  graphXAxis={runnerValues?.graphXAxis}
                  graphYAxis={runnerValues?.graphYAxis}
                />
              </div>{" "}
            </Grid>
          </Grid>
        )}
    </Grid>
  );
}
export default compose(
  withStyles(
    styles,

    { withTheme: true }
  )
)(RunnerGroup);
