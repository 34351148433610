import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import ApplicationConstants from "../../../constants/ApplicationConstants";

const GoogleMap = (props) => {
  const { defaultCenter, apiIsLoaded, markers, defaultZoom } = props;

  const [zoomLevel, setZoomLevel] = useState(7);

  const calculateZoom = () => {
    let allPoints = markers;
    if (allPoints) {
      let lastPoint = allPoints[allPoints?.length - 1]?.longDiscription;
      let totalDistance = 0;
      if (lastPoint) {
        Object.keys(lastPoint).forEach((key, value) => {
          if (lastPoint[key].key === "Total Distance") {
            totalDistance = lastPoint[key]?.value;
          }
        });

        // console.log(totalDistance);
        if (totalDistance > 9244649) {
          setZoomLevel(4);
        } else if (totalDistance > 4622324) {
          setZoomLevel(6);
        } else if (totalDistance > 2311162) {
          setZoomLevel(6);
        } else if (totalDistance > 1155581) {
          setZoomLevel(6);
        } else if (totalDistance > 577790) {
          //till 1100km
          setZoomLevel(6);
        } else if (totalDistance > 328895) {
          setZoomLevel(6);
        } else if (totalDistance > 248895) {
          //till 320km
          setZoomLevel(6);
        } else if (totalDistance > 118000) {
          setZoomLevel(7);
        } else if (totalDistance > 90000) {
          setZoomLevel(8);
        } else if (totalDistance > 59000) {
          //till 120-59Km
          setZoomLevel(9);
        } else if (totalDistance > 33000) {
          //64-33Km
          setZoomLevel(10);
        } else if (totalDistance > 16000) {
          //18Km
          setZoomLevel(11);
        } else if (totalDistance > 8000) {
          setZoomLevel(11);
        } else if (totalDistance > 4513) {
          setZoomLevel(13);
        } else if (totalDistance > 2256) {
          setZoomLevel(14);
        } else if (totalDistance > 1128) {
          //1Km
          setZoomLevel(15);
        } else {
          //default
          setZoomLevel(15);
        }
      }
    }
  };
  useEffect(() => {
    calculateZoom();
  }, []);
  return (
    <div
      style={{
        height: "83vh", // "850px", //
        width: "100%",
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: ApplicationConstants.NewGoogleMapKey,
          language: "en",
        }}
        onTilesLoaded={() => {
          // console.log('google map loaded', e)
          let isScroll = localStorage.getItem("scrollToMap");

          if (
            (!isScroll || !isScroll === "dont scroll") &&
            (window.location.href?.includes("#map") ||
              window.location.href?.includes("#participants"))
          ) {
            let ele = document.getElementById("map");
            ele.scrollIntoView({ behavior: "smooth" });
            localStorage.setItem("scrollToMap", "dont scroll");
          }
        }}
        defaultCenter={defaultCenter} //{{ lat: 40.7306, lng: 34.471 }} //
        defaultZoom={defaultZoom >= 0 ? defaultZoom : zoomLevel}
        center={defaultCenter} //{{ lat: 40.7306, lng: 34.471 }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          apiIsLoaded(map, maps);
        }}
      ></GoogleMapReact>
    </div>
  );
};

export default GoogleMap;
