import React from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Properties from "../../../../properties/Properties";
import AppToolBar from "../../../common/AppToolBar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import Snackbar from "../../../common/Snackbar";
import {
  deleteEventParticipants,
  getCaptureDetails,
  getInvitationList,
  getorderDetails,getEventDetails
} from "../../../../apis/eventService";
import CommonMenu from "./../../../common/SimpleMenu";
import { Box } from "@material-ui/core";
import { format, isAfter, isBefore } from "date-fns";
import { InviteIcon, RuppeeIcon } from "../../../common/SvgIcon";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import Header from "./../../../common/Header";
import DialogBox from "../../../common/DialogBox";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";

var moment = require("moment");
const { zonedTimeToUtc, utcToZonedTime } = require("date-fns-tz");

const styles = (theme) => ({
  mainPanel: {
    paddingRight: theme.rightSpacing,
    paddingLeft: theme.leftSpacing,
    marginTop: "68px",
  },
  centerPanel: {
    backgroundColor: "red",
    color: "white",
    opacity: "0.8",
    padding: "10px 0px",
    // width: "80%",
    margin: "10px 20px",
  },
  noteCss: {},
  price: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px",
  },
  priceText: {
    fontWeight: 600,
    fontSize: "30px",
    marginRight: "20px",
  },
  paid: {
    fontWeight: 600,
    fontSize: "20px",
    color: "#43aa8b",
  },
});

class InvitationListContainer extends React.Component {
  state = {
    userMail: localStorage.getItem("userEMail"),
    userName: "",
    email: "",
    emailError: false,
    contactNumber:"",
    inviteList: [],
    isInviteShow: false,
    showText: false,
    maxCount: 0,
    allowShareBtn: false,

  };

  componentDidMount() {
    this.onPageLoad();
    let eventId = ApplicationUtil.getRunnersEventID();
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runner?.id;
    let requestparam = null; //"REGISTER_EVENT";

   
    getEventDetails(requestparam, eventId).then((res) => {
      console.log('response',res)
      this.setState(
        {
          eventId: eventId,
          runnerId:runnerId,
          showCategoryOnRegistration:res?.data?.showCategoryOnRegistration
        }   
      );
    
      
    });
  }

  onPageLoad = () => {
    document.title = Properties.Page_Title_Invitation_List;
    this.setState({ userName: localStorage.getItem("userName") });
    let isHide = localStorage.getItem("hideInviteOnList");
    // getInvitationList().then((res) => {
    //   if (res && res.data) {
    //     this.setState({ inviteList: res.data });

    // const utcDate = zonedTimeToUtc(
    //   new Date(),
    //   Intl.DateTimeFormat().resolvedOptions().timeZone
    // );

    // let val = res.data[0].event.registrationEndDate;
    // let evntEndtime = new Date(
    //   ApplicationUtil.changeDateFormatDateMMDDWithTime(val)
    // );
    // const finalEventDate = utcToZonedTime(
    //   evntEndtime,
    //   Intl.DateTimeFormat().resolvedOptions().timeZone
    // );

    // let isshow = isAfter(utcDate, finalEventDate);

    // let isshow = localStorage.getItem("showInviteOption");
    // console.log(isshow);
    //  if (isshow) {
    // this.setState({ isInviteShow: isshow });
    // }
    // } else {
    // let isshow = localStorage.getItem("showInviteOption");
    //     let isshow = localStorage.getItem("showInviteOption");

    //     this.setState({ isInviteShow: isshow });

    //     this.setState({ showText: true });
    //   }
    // });
    let shareBtn = JSON.parse(localStorage.getItem("allowShare"));
    let isshow = JSON.parse(localStorage.getItem("showInviteOption"));

    this.setState({
      isInviteShow: isshow,
      showText: true,
      userName: localStorage.getItem("userName"),
      allowShareBtn: shareBtn,
    });
    getInvitationList().then((res) => {
      let totalFee = 0;
      let pendingRunnersFee = [];
      let runnerIds = [];
      let pendingPayment = false;
      if (res?.data?.length > 0) {
        this.setState({ inviteList: res.data, showText: false });
      } else {
        this.setState({ inviteList: [], showText: true });
      }

      if (res?.data[0]?.event.maxInvites)
        this.setState({
          maxCount: res.data[0].event.maxInvites,
        });
      if (res?.data?.length > 0) {
        res.data.map((val) => {
          if (
            val?.event?.showSecondaryActivityType == "SHOW_ON_REG" ||
            val?.event?.showSecondaryActivityType == "SHOW_ON_REG_INVITEE" ||
            val?.showSecondaryActivityType == "SHOW_ON_INVITEE"
          ) {
            let list = "";
            let activityList = val?.participantSecondaryActivities;

            if (activityList) {
              activityList.forEach((element) => {
                list = list.concat(element.displayName, ",");
              });
              list = list.replace(/,\s*$/, "");
            }
            val.secondaryActivityList = list;
          }

          if (val?.paymentStatus === "PENDING") {
            totalFee += val.totalFee;
            pendingRunnersFee.push({
              runnerId: val?.runnerId,
              regFee: val?.eventFee,
              otherCharges: val?.itemsPrice,
            });
            runnerIds.push(val?.runnerId);
            pendingPayment = true;
          }
        });
      }
      this.setState({
        pendingPayment: pendingPayment,
        totalFee: totalFee,
        categoryId: res?.data[0]?.category?.id,
        runnersDetails: pendingRunnersFee,
        runnerIds: runnerIds,
      });
    });
  };
  naviagte = () => {
    this.props.history.push("/invitationForm");
  };

  // naviagteToShareEvent = () => {
  //   this.props.history.push("/shareEventForm");
  // };

  handlePayment = () => {
    const { totalFee, categoryId, runnersDetails } = this.state;

    let requestParams = {
      runnerId: localStorage.getItem("runnerId"),
      categoryId: categoryId,
      amount: totalFee,
      runners: runnersDetails,
    };
    let eventId = ApplicationUtil.getRunnersEventID();
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runner?.id;
    getorderDetails(requestParams).then((res) => {
      const { data } = res;
      this.setState({
        orderId: data.orderId,
        amount: res.data.amount,
      });
      data.contact =
        this.state.contactNumber != ""
          ? this.state.contactNumber
          : data.participantPhone;
      //   this.setState({ orderId: data.orderId });

      ApplicationUtil.paymentGatewayHandler(
        data,
        this.captureDetails,
        this.handlePaymentFailure
        // this.dismissHandler
      );
    });
   
  };
  captureDetails = (requestCaptureParams) => {
    delete requestCaptureParams.runnerId;

    const { history } = this.props;
    const { runnerIds } = this.state;
    requestCaptureParams.runnerIds = runnerIds;
    getCaptureDetails(requestCaptureParams)
      .then((res) => {
        Snackbar.show({
          variant: "success",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Payment Success!", //Capture API error
        });
        this.onPageLoad();
        // this.setState({
        //   transactionID: requestCaptureParams.razorpay_payment_id,
        //   transactionStatus: "Success",
        // });
      })
      .catch((error) => {
        this.setState({
          transactionStatus: "Failed",
        });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Failed to process the payment.", //Capture API error
        });
      });
  };
  handlePaymentFailure = (response) => {
    // Snackbar.show({
    //   variant: "error",
    //   isAutoHide: true,
    //   preventDuplicate: true,
    //   message: `Failed to process the payment please note the transactionID
    //       ${response.error.metadata.payment_id}.`, //Capture API error
    // });
  };
  dismissHandler = () => {
    this.setState({
      open: true,
      dialogMsg: "",
    });
  };

  confirmRemove = (id, userName) => {
    this.setState({
      open: true,
      selectedId: id,
      dialogMsg: `Do you want to delete ` + userName + ` registration?`,
    });
  };

  handleRemoveInvitations = (id) => {
    deleteEventParticipants(id).then((res) => {
      this.onPageLoad();
      this.setState({ open: false });
    });
  };

  closeWindow = () => {
    this.setState({ open: false });
  };
  render() {
    const { classes } = this.props;
    const { totalFee, pendingPayment ,showCategoryOnRegistration} = this.state;
    return (
      <React.Fragment>
        <AppToolBar
          backNavigate={() => this.props.history.replace("/eventDetails")}
          showBack={true}
        >
          <Header {...this.props} label={Properties.Label_InvitationListPage} />
          <div style={{ width: 35 }} />
        </AppToolBar>

        <div className={classes.mainPanel}>
          {pendingPayment && (
            <div
              className={classes.centerPanel}
              style={{ textAlign: "center" }}
            >
              <span className={classes.noteCss}>
                {Properties.Label_PaymentWarning}{" "}
              </span>
            </div>
          )}

          <Grid
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            container
            spacing={1}
          >
            {this.state.inviteList.map((rec, index) => (
              <Grid
                style={{
                  borderBottom: "1px solid rgb(8, 69, 108)",
                  margin: "1% 6.7%",
                  width: "100%",
                  paddingBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                key={index}
              >
                <div>
                  <div style={{ padding: "2px" }}>
                    <b
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: 20,
                      }}
                    >
                      {rec.name}
                    </b>
                  </div>
                  <div style={{ padding: "2px" }}>
                    {rec.gender == "MALE" && (
                      <span>{Properties.Label_Male}</span>
                    )}
                    {rec.gender == "FEMALE" && (
                      <span>{Properties.Label_Female}</span>
                    )}
                    {rec.gender == "OTHER" && (
                      <span>{Properties.Label_Other}</span>
                    )}
                    <span style={{ paddingLeft: "15px" }}>{rec.age}</span>
                  </div>

                  <div style={{ padding: "2px" }}>
                    <span>{rec.email}</span>
                  </div>
                  <div style={{ padding: "2px" }}>
                    <span>{rec.contactNumber?rec.contactNumber:""}</span>
                  </div>
                  {rec.secondaryActivityList ? (
                    <div style={{ padding: "2px", width: "100%" }}>
                      <div>
                        <span className={classes.secondaryLabel}>
                          {Properties.Label_PrimaryActivity}:{" "}
                        </span>
                        <span>
                          {rec.category?.activityType?.displayName
                            ? rec.category?.activityType?.displayName
                            : rec.category?.activityType?.type}
                          {"-"}
                          {rec.category.categoryName}
                        </span>
                      </div>
                      <div>
                        <span className={classes.secondaryLabel}>
                          {Properties.Label_SecondaryActivity}:{" "}
                        </span>
                        <span className={classes.itemValue}>
                          {rec.secondaryActivityList}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{ padding: "2px", width: "100%", display: "flex" }}
                    >
                     {showCategoryOnRegistration ? <><div>
                          <b>{Properties.Category_label}</b>
                          {": "}
                        </div><div style={{ marginLeft: "10px" }}>
                            <span>
                              {rec.category?.activityType?.displayName
      ? rec.category?.activityType?.displayName
      : rec.category?.activityType?.type}
    {"-"}
    {rec.category.categoryName}
                            </span>
                          </div></>:""}
                    </div>
                  )}
                  {/* </div> */}

                  {rec.tShirtSize && (
                    <div
                      style={{ padding: "2px", width: "100%", display: "flex" }}
                    >
                      <div>
                        <b>{Properties.Label_TShirtSize}</b>
                        {": "}
                      </div>
                      <div style={{ marginLeft: "10px" }}>
                        {<span>{rec.tShirtSize}</span>}
                      </div>
                    </div>
                  )}
                </div>
                {rec?.paymentStatus === "PENDING" ? (
                  <div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {rec?.event?.freeEvent === true && (
                        <DeleteIcon
                          style={{
                            width: "auto",
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.confirmRemove(rec.runnerId, rec.name);
                          }}
                        />
                      )}
                    </div>
                    {rec?.eventFee !== 0 && (
                      <p style={{ margin: "2px" }}>
                        <b>
                          {Properties.Label_EventPrice}:
                          <span style={{ color: "red" }}>
                            {" " + rec?.eventFee + "/-"}
                          </span>
                        </b>
                      </p>
                    )}

                    {rec?.itemsPrice > 0 && (
                      <p style={{ margin: "2px" }}>
                        <b>
                          {Properties.Label_ItemPrice} :
                          <span style={{ color: "red" }}>
                            {" " + rec?.itemsPrice + "/-"}
                          </span>
                        </b>
                      </p>
                    )}
                  </div>
                ) : (
                  rec?.paymentStatus === "SUCCESS" && (
                    <div>
                      {rec?.event?.freeEvent === true || rec?.totalFee === 0 ? (
                        <p className={classes.paid}>Registered</p>
                      ) : (
                        <p className={classes.paid}>Paid</p>
                      )}
                    </div>
                  )
                )}
              </Grid>
            ))}

            {this.state.showText && (
              <div
                style={{ margin: "0px 20px" }}
                // className={classes.centerPanel}
              >
                {Properties.Show_no_invitation_message}
              </div>
            )}
          </Grid>

          {this.state.isInviteShow && (
            <Grid
              style={{
                width: "100%",
                height: "75px",
                marginTop: "20px",
                justifyContent: "center",
              }}
              container
              spacing={1}
            >
              {(this.state.maxCount === 0 ||
                (this.state.inviteList.length !== this.state.maxCount &&
                  this.state.inviteList.length < this.state.maxCount)) && (
                <>
                  <SubmitCancelBtn
                    style={{ width: "auto", marginRight: "10px" }}
                    onClick={() => this.naviagte()}
                    label={Properties.Label_InvitePeople}
                    type="submit"
                  />
                  {/* {this.state.allowShareBtn && (
                    <SubmitCancelBtn
                      style={{ width: "auto" }}
                      // onClick={() => this.naviagteToShareEvent()}
                      label={Properties.Label_ShareEvent}
                      type="submit"
                    />
                  )} */}
                </>
              )}
            </Grid>
          )}

          {!this.state.showText && (
            <div style={{ textAlign: "center" }}>
              <span style={{ color: "red" }}>*</span>
              <span>Max {this.state.maxCount} invites are allowed </span>
              {/* {Properties.Max_invitation_message} */}
            </div>
          )}

          {this.state.inviteList.some((x) => x.paymentStatus === "PENDING") && (
            <div className={classes.price}>
              <p className={classes.priceText}>
                <span>
                  <RuppeeIcon style={{ fontSize: "20px" }} />
                </span>
                <span>{totalFee ? totalFee : ""}</span>
              </p>
              <div>
                <SubmitCancelBtn
                  type="submit"
                  label={Properties.Label_PayNow}
                  onClick={this.handlePayment}
                  style={{ width: "auto" }}
                />
              </div>
            </div>
          )}
        </div>

        <DialogBox
          signup={true}
          open={this.state.open}
          message={this.state.dialogMsg}
          header={this.state.dialogHeader}
          onClick={() => {
            this.handleRemoveInvitations(this.state.selectedId);
          }}
          label={Properties.DeleteLabel}
          onClickTwo={() => {
            this.closeWindow();
          }}
          isInvitationScreen={true}
          labelTwo={Properties.CancelLabel}
          closeDisloag={() => this.closeWindow()}
        />
      </React.Fragment>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }),withRouter)(
  InvitationListContainer
);
