import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  root: {
    textTransform: "none",
    backgroundColor: "#09456C",
    "&:hover": {
      backgroundColor: "#296DFA",
    },
    "&.active": {
      backgroundColor: "#296DFA",
    },
    maxWidth: "300px",
    width: "100%",
    height: "38px",
    borderRadius: "16px",
    color: "#fff",
    fontWeight: "bold",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "small",
    },
  },
  cancelBtn: {
    textTransform: "none",
    margin: "0 auto",
    minWidth: "100px",
    maxWidth: "100px",
    height: "38px",
    borderRadius: "4px",
    backgroundColor: "#BABABA",
    color: "#fff",
    fontWeight: "bold",
    fontFamily: "nunito sans",
    "&:hover": {
      backgroundColor: "#BABABA",
    },
    "&.active": { backgroundColor: "#BABABA" },
  },
  rootResetCss: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#296DFA",
    },
    "&.active": { backgroundColor: "#296DFA" },

    maxWidth: "300px",
    //width: "100%",
    height: "38px",
    borderRadius: "4px",
    backgroundColor: "#296DFA",
    color: "#fff",
    fontWeight: "bold",
    fontFamily: "nunito sans",
  },
  cancelBtnResetCss: {
    textTransform: "none",
    margin: "0 auto",
    //minWidth: "100px",
    maxWidth: "100px",
    height: "38px",
    borderRadius: "4px",
    backgroundColor: "#BABABA",
    color: "#fff",
    fontWeight: "bold",
    fontFamily: "nunito sans",
    "&:hover": {
      backgroundColor: "#BABABA",
    },
    "&.active": {
      backgroundColor: "#BABABA",
    },
  },
});

class SubmitCancelBtn extends React.Component {
  render() {
    const {
      classes,
      label,
      type,
      style,
      onClick,
      isDisabled,
      btnIcon,
      isWidthReset,
      endIcon,
      startIcon,
      fullWidth = false,
    } = this.props;

    return (
      <React.Fragment>
        {type === "submit" ? (
          <Button
            color="primary"
            disabled={isDisabled}
            fullWidth={fullWidth}
            variant="contained"
            // className={isWidthReset ? classes.rootResetCss : classes.root}
            className={classes.root}
            style={style}
            onClick={(e) => (onClick ? onClick(e) : null)}
            endIcon={endIcon}
            startIcon={startIcon}
          >
            {btnIcon}
            {label}
          </Button>
        ) : (
          <Button
            color="primary"
            fullWidth={fullWidth}
            className={
              isWidthReset ? classes.cancelBtnResetCss : classes.cancelBtn
            }
            style={style}
            onClick={(e) => (onClick ? onClick(e) : null)}
          >
            {label}
          </Button>
        )}
      </React.Fragment>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(SubmitCancelBtn);
