import React, { Component } from "react";
import compose from "recompose/compose";
import { Switch, Route, withRouter } from "react-router-dom";
import ApplicationUtil from "../../../utils/ApplicationUtil";
import SupportListContainer from "./SupportListContainer";
import SupportFormContainer from "./SupportFormContainer";
import SupportLoginScreen from "./SupportLoginScreen";
import SupportEventList from "./SupportEventList";

class SupportPanel extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { match } = this.props;
    console.log(match);
    ApplicationUtil.saveMatchUrl(match.url);
    return (
      <Switch>
        <Route
          strict
          path={match.url + "/form"}
          render={() => this.getSupportListFormPanel()}
        />
        <Route
          strict
          exact
          path={match.url}
          //  component={SupportLoginScreen}
          render={() => <SupportLoginScreen role="support" />}
        />
        <Route
          strict
          exact
          path={match.url + "/list"}
          render={() => this.getSupportListPanel()}
        />
        <Route
          strict
          exact
          path={match.url + "/eventlist"}
          // component={SupportEventList}
          render={() => this.getSupportEventListPanel()}
        />
      </Switch>
    );
  }
  getSupportListFormPanel = () => {
    return <SupportFormContainer />;
  };

  getSupportListPanel = () => {
    return <SupportListContainer />;
  };

  getSupportEventListPanel = () => {
    return <SupportEventList />;
  };
}

export default compose(withRouter)(SupportPanel);
