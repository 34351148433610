import React, { Component, useEffect } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  updateParticipantSetFilters,
  updateSupportDTODetails,
  updateSupportSelectedEvent,
} from "../../../../../redux/supportUser";
import { connect } from "react-redux";
import OrganizerCommonHeader from "../OrganizerCommonHeader";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import {
  Participants,
  Setup,
  Execution,
  SummaryDashboard,
  LeaderboardIcon,
  Analytics,
  Reports,
  Trainer,
  TrainingPlan,
  JobSync,
} from "../../../../common/SvgIcon";
import GroupIcon from "@material-ui/icons/Group";
import { Typography } from "@material-ui/core";
import Breadcrumbs from "../../../../common/Breadcrumbs";
import OrganizerSummary from "../OrganizerSummary/OrganizerSummary";
import CreateChallenge from "./Challenge/CreateChallenge";

// const OrganizerProgramCreateChallenge = () => {
//   return <div>Hellos</div>;
// };

function OrganizerProgramCreateChallenge(props) {
  let selected = localStorage.getItem("sidebarSelected");
  let isChallenge = localStorage.getItem("isChallenge");
  let isChallengeEvent = localStorage.getItem("isChallengeEvent");
  let userRole = localStorage.getItem("role");
  let isTraining = localStorage.getItem("isTraining");
  let isEdit = localStorage.getItem("editView");
  let selectedEvent = ApplicationUtil.getSupportsEventsDetails();
  const [selectedMenu, setSelectedMenu] = React.useState(
    selected ? selected : isChallenge ? "Participants" : "Setup"
  );
  const [nextSelect, setNextSelect] = React.useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const confirmDialogHeader = "";
  let isCreateOrDraft = localStorage.getItem("isCreateOrDraft");
  const [menuList, setMenuList] = React.useState(["Summary"]);

  const changeBar = () => {
    setSelectedMenu("Setup");
    setMenuList(["Participants", "Results", "Setup"]);
  };

  // const closeWindow = () => {
  //   setShowConfirmDialog(false);
  // };

  // const handleConfirmAction = () => {
  //   setShowConfirmDialog(false);

  //   handleSelectionChangeAction(nextSelect);
  // };

  const handleSelectionChangeAction = (row) => {
    localStorage.setItem("sidebarSelected", row);
    // localStorage.setItem("supportEventId", organizerEventID);
    setSelectedMenu(row);

    let isChallenge = localStorage.getItem("isChallenge");
    let isChallengeEvent = localStorage.getItem("isChallengeEvent");
    let isCreateOrDraft = localStorage.getItem("isCreateOrDraft");
    let isTraining = localStorage.getItem("isTraining");
    setMenuList(["Summary"]);
  };

  useEffect(() => {
    if (localStorage.getItem("sidebarSelected")) {
      setSelectedMenu("Summary");
    }
  }, []);

  return (
    <>
      <div className="organizer">
        {/* {selected == "Setup" ? (
          <OrganizerCommonHeader title="Configuration" />
        ) : ( */}
        <OrganizerCommonHeader />
        {/* )} */}
        {/* <MenuIcon style={{ color: "#fff", zIndex: 2 }} /> */}
        <aside className="organizer__sidebar">
          <div className="sidebar__top">
            {/* <KeyboardBackspaceIcon onClick={() => history.goBack()} /> */}
          </div>
          <hr />
          <div className="sidebar__menu" id="organizer_sidebar_menu">
            {menuList.map((row, index) => (
              <div
                className="menu"
                onClick={() => {
                  if (isChallenge && selectedMenu === "Setup") {
                    setNextSelect(row);
                    setShowConfirmDialog(true);
                  } else {
                    handleSelectionChangeAction(row);
                  }
                }}
              >
                {row === "Summary" && (
                  <SummaryDashboard
                    className={`icon ${selectedMenu === row && "selectedIcon"}`}
                  />
                )}

                <p>{row}</p>
              </div>
            ))}
          </div>
        </aside>
        <div className="organizer__mainBody">
          <Breadcrumbs />
          {selectedMenu === "Summary" && <CreateChallenge />}
        </div>
      </div>
    </>
  );
}

const styles = (theme) => {};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(OrganizerProgramCreateChallenge);
