import { Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Properties from "../../../../../../properties/Properties";
import BasicView from "./TabsView/BasicView";
import SettingsView from "./TabsView/SettingsView";
import ImagesView from "./TabsView/ImagesView";
import CertificatesView from "./TabsView/CertificatesView";
import { compose } from "recompose";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";
import ProductConfigurationView from "./TabsView/ProductConfigurationView";
import ProgramBasicTab from "../../OrganizerSetup/CreateProgram/ProgramBasicTab";
import ProgramSetting from "../../OrganizerSetup/CreateProgram/ProgramSetting";
import ProgramCertificates from "../../OrganizerSetup/CreateProgram/ProgramCertificates";
import ProgramImageTab from "../../OrganizerSetup/CreateProgram/ProgramImageTab";

const CreateChallenge = ({ classes, isProgramCreate }) => {
  let activeTab = localStorage.getItem("ActiveTab");
  let isEdit = localStorage.getItem("editView");
  const currentChallenge = ApplicationUtil?.getProgramUnderCurrentChallenge();

  const currentProgram = ApplicationUtil.getSupportsProgramDetails();

  let tabDisabled = false;

  let activeTabIndex = 0;
  if (activeTab === "ZERO") {
    activeTabIndex = 0;
  } else if (activeTab === "ONE") {
    activeTabIndex = 1;
  }
  if (activeTab === "TWO") {
    activeTabIndex = 2;
  } else if (activeTab === "THREE") {
    activeTabIndex = 3;
  } else if (activeTab === "FOUR") {
    activeTabIndex = 4;
  }

  const [createState, setCreateState] = useState({
    ActiveTabIndex: activeTabIndex,
    isEdit: false,
    tabsDisabled: tabDisabled,
  });

  useEffect(() => {
    if (isProgramCreate) {
      if (!currentProgram) {
        setCreateState((prev) => ({
          ...prev,
          tabsDisabled: true,
        }));
      }
    } else {
      if (!currentChallenge) {
        setCreateState((prev) => ({
          ...prev,
          tabsDisabled: true,
        }));
      }
    }
  }, []);

  const { ActiveTabIndex, tabsDisabled } = createState;

  const handleTabChange = (event, newValue) => {
    let isEdit = localStorage.getItem("editView");
    setCreateState((prev) => ({
      ...prev,
      ActiveTabIndex: newValue,
      isEdit: isEdit,
    }));
  };

  return (
    <div>
      <Tabs
        value={ActiveTabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        variant={isMobile ? "scrollable" : "fullWidth"}
        scrollButtons={isMobile ? "on" : "off"}
      >
        <Tab
          value={0}
          label={Properties.BasicTabLabel}
          className={classes.textTransformCss}
        />
        <Tab
          value={1}
          label={Properties.SettingsTabLabel}
          className={classes.textTransformCss}
          disabled={tabsDisabled}
        />
        <Tab
          value={2}
          label={"Images"}
          className={classes.textTransformCss}
          disabled={tabsDisabled}
        />
        {/* {role == "SUPPORT" && ( */}

        {/* Certificates tab  */}
        {/* <Tab
          value={3}
          label={Properties.DeliverableTabLabel}
          className={classes.textTransformCss}
          disabled={tabsDisabled}
        /> */}
        {isProgramCreate ? (
          <Tab
            value={4}
            label={"Certificates"}
            className={classes.textTransformCss}
            disabled={tabsDisabled}
          />
        ) : (
          <Tab
            value={4}
            label={"Product Configuration"}
            className={classes.textTransformCss}
            disabled={tabsDisabled}
          />
        )}

        {/* )} */}
      </Tabs>

      {/*---------------- 
      *  Challenge Creation tab content
      *
      -------------------------------*/}

      {/*---------------- 
      *  Program tab content
      *
      -------------------------------*/}

      {isProgramCreate ? (
        <div
        //   className={classes.root}
        >
          {ActiveTabIndex === 0 && (
            <>
              <ProgramBasicTab
                onSuccess={(e) => {
                  setCreateState((prev) => ({
                    ...prev,
                    tabsDisabled: false,
                  }));
                  handleTabChange(e, 1);
                }}
              />
            </>
          )}

          {ActiveTabIndex === 1 && <ProgramSetting />}

          {ActiveTabIndex === 2 && <ProgramImageTab />}
          {ActiveTabIndex === 3 && <ProgramCertificates />}
          {ActiveTabIndex === 4 && <ProgramCertificates />}
        </div>
      ) : (
        <div
        //   className={classes.root}
        >
          {ActiveTabIndex === 0 && (
            <>
              <BasicView
                onSuccess={(e) => {
                  setCreateState((prev) => ({
                    ...prev,
                    tabsDisabled: false,
                  }));
                  handleTabChange(e, 1);
                }}
              />
            </>
          )}

          {ActiveTabIndex === 1 && <SettingsView />}

          {ActiveTabIndex === 2 && <ImagesView />}
          {ActiveTabIndex === 3 && <CertificatesView />}
          {ActiveTabIndex === 4 && <ProductConfigurationView />}
        </div>
      )}
    </div>
  );
};

const styles = (theme) => ({
  textTransformCss: {
    textTransform: "none",
    fontSize: "22px",
    fontWeight: "bold",
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(CreateChallenge);
