import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import {
  deleteInlineImage,
  getEventImagesDetails,
  uploadInlineImages,
} from "./../../../../../apis/eventService";
import { withRouter } from "react-router-dom";
import ImageDialogBox from "../../../../common/ImageDialogBox";
import { FileUploader } from "./../../../../common/FileUploader";
import ClearIcon from "@material-ui/icons/Clear";
import DialogBox from "../../../../common/DialogBox";
import Properties from "../../../../../properties/Properties";
import { Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import InlineDivider from "../../../../common/InlineDivider";
const styles = (theme) => ({
  actionCss: {
    color: "#F79824",
  },
  imgContainer: {
    position: "relative",
    width: "200px",
    textAlign: "center",
    padding: "40px 20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  clearImgContainer: {
    position: "absolute",
    top: "1px",
    right: "1px",
  },
  labelCss: {
    color: "#727272",
  },
  profileImgContainer: {
    // width: "120px",
    height: "120px",
  },
  profileImg: {
    width: "200px",
    height: "120px",
    objectFit: "contain",
    margin: "auto",
    cursor: "pointer",
  },
  imgTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
});
class UpdateImagesTabView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allImages: [
        {
          label: "Banners",
          keyList: [
            {
              keyName: "EMAIL_BANNER_IMG",
              imageURL: null,
              label: "Email Banner",
              width: "600",
              height: "256", //w*h
              exactSize: true,
            },
            {
              keyName: "EVENT_BANNER_IMG",
              imageURL: null,
              label: "Event Banner",
              width: "1280",
              height: "885", //w*h
              exactSize: true,
            },
            {
              keyName: "UPCOMING_EVENT_BANNER_IMG",
              imageURL: null,
              label: "Upcoming Event Banner",
              width: "1280",
              height: "885", //w*h
              exactSize: true,
            },
            {
              keyName: "RESULT_BANNER_IMG",
              imageURL: null,
              label: "Result Banner",
              width: "1584",
              height: "300", //w*h
              exactSize: true,
            },
            {
              keyName: "SUMMARY_BACKGROUND",
              imageURL: null,
              label: "SUMMARY BACKGROUND",
              width: "600",
              height: "600", //w*h
              exactSize: true,
            }
            
          ],
        },
        {
          label: "BIB",
          keyList: [
            {
              keyName: "BIB_BANNER_TOP",
              imageURL: null,
              label: "BIB Header",
              width: "794",
              height: "104", //w*h 794*135
              // maxWidth: "796",
              // maxHeight: "140",
              exactSize: true,
            },
            {
              keyName: "BIB_BANNER_1",
              imageURL: null,
              label: "BIB Footer",
              width: "794",
              height: "104", //w*h 794*135
              // maxWidth: "796",
              // maxHeight: "140",
              exactSize: true,
            },
          ],
        },
        {
          label: "VIDEO",
          keyList: [
            {
              keyName: "VIDEO_BANNER",
              imageURL: null,
              label: "Video Banner",
              width: "700",
              height: "270", //w*h
              exactSize: true,
            },
            {
              keyName: "VIDEO_HEADER",
              imageURL: null,
              label: "Video Header",
              width: "1280",
              height: "100",
              exactSize: true,
            },
            {
              keyName: "VIDEO_FOOTER",
              imageURL: null,
              label: "Video Footer",
              width: "1280",
              height: "100",
              exactSize: true,
            },
          ],
        },
      ],
    };
  }
  componentDidMount() {
    getEventImagesDetails().then((res) => {
      let pastEvent = localStorage.getItem("pastEvent");
      let setPermissions = JSON.parse(
        localStorage.getItem("supportPermissions")
      );
      let isChallengeUserPastDate = localStorage.getItem("isChallengePastDate");
      if (pastEvent === "true" || isChallengeUserPastDate === "true") {
        this.setState({
          isPastEvent: true,
        });
      } else {
        this.setState({
          isPastEvent: setPermissions ? !setPermissions.canModifyEvent : false,
        });
      }

      localStorage.setItem("ActiveTab", "TWO");
      let d = res.data;
      let allImages = this.state.allImages;

      for (let i = 0; i < allImages.length; i++) {
        allImages[i].keyList.forEach((element) => {
          for (const key of Object.keys(d)) {
            if (element.keyName == key) {
              element.imageURL = "data:image/png;base64," + d[key]?.imageData;
            }
          }
        });
      }
      this.setState({ allImages: allImages });
    });
  }
  closeWindow = () => {
    this.setState({
      dialogTitle: "",
      showImage: false,
      imagePath: null,
      deleteImageDialog: false,
      deleteWarningMsg: null,
    });
  };

  handleUploadedFiles = (arg, itemRow) => {
    let files = arg.selectedFiles?.[0]; //?.[0].src;
    let row = arg.selectedRow;
    let imgsrc = URL.createObjectURL(files);

    uploadInlineImages(row.keyName, files).then((res) => {
      let newData = this.state.allImages;
      let currentItemRow = newData.findIndex(
        (obj) => obj.label === itemRow.label
      );
      let index = newData[currentItemRow].keyList.findIndex(
        (obj) => obj.keyName == row.keyName
      );

      newData[currentItemRow].keyList[index].imageURL = imgsrc; //files.preview;
      this.setState({ keyList: newData });
    });
  };

  handleImageRemove = (row, itemRow) => {
    this.setState({
      deleteImageDialog: true,
      deleteWarningMsg: `Are you sure you want to delete ` + row.label + ` ?`,
    });
    let newData = this.state.allImages;
    let currentItemRow = newData.findIndex(
      (obj) => obj.label === itemRow.label
    );

    let index = newData[currentItemRow].keyList.findIndex(
      (obj) => obj.keyName === row.keyName
    );
    let key = newData[currentItemRow].keyList[index].keyName;

    // newData[index].imageURL = null; //files.preview;
    this.setState({
      allImages: newData,
      deleteKey: key,
      itemRow: itemRow,
    });
  };
  closeDialogBox = () => {
    const { deleteKey, itemRow, allImages } = this.state;
    let newData = allImages;
    let currentItemRow = newData.findIndex(
      (obj) => obj.label === itemRow.label
    );

    let index = newData[currentItemRow].keyList.findIndex(
      (obj) => obj.keyName === deleteKey
    );
    newData[currentItemRow].keyList[index].imageURL = null; //files.preview;
    this.setState({ allImages: newData });
    deleteInlineImage(deleteKey).then((res) => {
      console.log(res);
    });

    this.closeWindow();
    return true;
  };
  getDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.CreateEvent_CancelClickWarning}</span>
        </Typography>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const { allImages, isPastEvent } = this.state;
    return (
      <div>
        {allImages.map((item, itemIndex) => (
          <div
            key={itemIndex}
            style={{
              margin: "10px 0px",
              padding: "10px",
              border: "1px solid gray",
            }}
          >
            {/* 
            <InlineDivider               style={{ height: "5px", backgroundColor: "#08456C" }}
            >*/}

            <Typography variant="body2" gutterBottom>
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#727272",
                }}
              >
                {ApplicationUtil.getCamelCasing(item.label)}
              </span>
            </Typography>
            {/* </InlineDivider> */}
            <Grid container spacing={1} style={{ padding: "10px" }}>
              {item.keyList.map((row, index) => (
                <Grid item xs={6} key={index}>
                  {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                  <Typography style={{ paddingRight: "10px" }}>
                    {row.label}
                    <Tooltip
                      title={
                        row?.exactSize
                          ? `Image size should should be ${row?.width}px * ${row?.height}px`
                          : `Image size should be within ${row?.width}px * ${row?.height}px -
         ${row?.maxWidth}px * ${row?.maxHeight}px`
                      }
                    >
                      <HelpIcon
                        style={{ color: "#3a6ea5", marginLeft: "10px" }}
                        fontSize="small"
                      />
                    </Tooltip>
                  </Typography>{" "}
                  {row.imageURL ? (
                    <Grid>
                      <div className={classes.imgContainer}>
                        {!isPastEvent && (
                          <div className={classes.clearImgContainer}>
                            <ClearIcon
                              style={{ color: "red" }}
                              onClick={() => this.handleImageRemove(row, item)}
                            />
                          </div>
                        )}

                        <div className={classes.profileImgContainer}>
                          <img
                            className={classes.profileImg}
                            src={row.imageURL}
                            alt={row.label}
                            onClick={() => {
                              this.setState({
                                showImage: true,
                                imagePath: row.imageURL,
                                dialogTitle: row.label,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                  ) : (
                    <Grid>
                      <FileUploader
                        selectedRow={row}
                        maxFiles={1}
                        multiple={false}
                        filesUploaded={
                          (e) => this.handleUploadedFiles(e, item) //(e, data);
                        }
                        isReadOnly={isPastEvent}
                      />
                    </Grid>
                  )}
                  {/* </div> */}
                </Grid>
              ))}{" "}
            </Grid>
          </div>
        ))}

        <ImageDialogBox
          title={this.state.dialogTitle}
          open={this.state.showImage}
          imagePath={this.state.imagePath}
          onClick={() => this.closeWindow()}
          closeDisloag={() => this.closeWindow()}
        />
        <DialogBox
          isSmall
          signup={true}
          open={this.state.deleteImageDialog}
          message={this.state.deleteWarningMsg}
          header={this.state.dialogHeader}
          label={"No"}
          onClick={() => {
            this.closeWindow();
          }}
          labelTwo={"Yes"}
          onClickTwo={() => {
            this.closeDialogBox();
          }}
          closeDisloag={() => this.closeWindow()}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(UpdateImagesTabView);
