import { Grid, Tooltip, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import HelpIcon from "@material-ui/icons/Help";
import { compose } from "recompose";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import ImageDialogBox from "../../../../../../common/ImageDialogBox";
import DialogBox from "../../../../../../common/DialogBox";
import { FileUploader } from "../../../../../../common/FileUploader";
import ApplicationUtil from "../../../../../../../utils/ApplicationUtil";

const ImagesView = ({ classes }) => {
  const [imageViewState, setImageViewState] = useState({
    allImages: [
      {
        label: "Banners",
        keyList: [
          {
            keyName: "EMAIL_BANNER_IMG",
            imageURL: null,
            label: "Email Banner",
            width: "600",
            height: "256", //w*h
            exactSize: true,
          },
          {
            keyName: "EVENT_BANNER_IMG",
            imageURL: null,
            label: "Event Banner",
            width: "1280",
            height: "885", //w*h
            exactSize: true,
          },
          {
            keyName: "UPCOMING_EVENT_BANNER_IMG",
            imageURL: null,
            label: "Upcoming Event Banner",
            width: "1280",
            height: "885", //w*h
            exactSize: true,
          },
          {
            keyName: "RESULT_BANNER_IMG",
            imageURL: null,
            label: "Result Banner",
            width: "1584",
            height: "300", //w*h
            exactSize: true,
          },
          {
            keyName: "SUMMARY_BACKGROUND",
            imageURL: null,
            label: "SUMMARY BACKGROUND",
            width: "600",
            height: "600", //w*h
            exactSize: true,
          },
        ],
      },
      {
        label: "BIB",
        keyList: [
          {
            keyName: "BIB_BANNER_TOP",
            imageURL: null,
            label: "BIB Header",
            width: "794",
            height: "104", //w*h 794*135
            // maxWidth: "796",
            // maxHeight: "140",
            exactSize: true,
          },
          {
            keyName: "BIB_BANNER_1",
            imageURL: null,
            label: "BIB Footer",
            width: "794",
            height: "104", //w*h 794*135
            // maxWidth: "796",
            // maxHeight: "140",
            exactSize: true,
          },
        ],
      },
      {
        label: "VIDEO",
        keyList: [
          {
            keyName: "VIDEO_BANNER",
            imageURL: null,
            label: "Video Banner",
            width: "700",
            height: "270", //w*h
            exactSize: true,
          },
          {
            keyName: "VIDEO_HEADER",
            imageURL: null,
            label: "Video Header",
            width: "1280",
            height: "100",
            exactSize: true,
          },
          {
            keyName: "VIDEO_FOOTER",
            imageURL: null,
            label: "Video Footer",
            width: "1280",
            height: "100",
            exactSize: true,
          },
        ],
      },
    ],
    dialogTitle: "",
    showImage: "",
    imagePath: "",
    deleteImageDialog: "",
    deleteWarningMsg: "",
    dialogHeader: "",
  });

  const {
    allImages,
    dialogTitle,
    showImage,
    imagePath,
    deleteImageDialog,
    deleteWarningMsg,
    dialogHeader,
  } = imageViewState;
  return (
    <div>
      {allImages.map((item, itemIndex) => (
        <div
          key={itemIndex}
          style={{
            margin: "10px 0px",
            padding: "10px",
            border: "1px solid gray",
          }}
        >
          {/* 
          <InlineDivider               style={{ height: "5px", backgroundColor: "#08456C" }}
          >*/}

          <Typography variant="body2" gutterBottom>
            <span
              style={{
                fontSize: "20px",
                fontWeight: "600",
                color: "#727272",
              }}
            >
              {ApplicationUtil.getCamelCasing(item.label)}
            </span>
          </Typography>
          {/* </InlineDivider> */}
          <Grid container spacing={1} style={{ padding: "10px" }}>
            {item.keyList.map((row, index) => (
              <Grid item xs={6} key={index}>
                {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                <Typography style={{ paddingRight: "10px" }}>
                  {row.label}
                  <Tooltip
                    title={
                      row?.exactSize
                        ? `Image size should should be ${row?.width}px * ${row?.height}px`
                        : `Image size should be within ${row?.width}px * ${row?.height}px -
       ${row?.maxWidth}px * ${row?.maxHeight}px`
                    }
                  >
                    <HelpIcon
                      style={{ color: "#3a6ea5", marginLeft: "10px" }}
                      fontSize="small"
                    />
                  </Tooltip>
                </Typography>{" "}
                {row.imageURL ? (
                  <Grid>
                    <div className={classes.imgContainer}>
                      {/* {!isPastEvent && ( */}
                      <div className={classes.clearImgContainer}>
                        <ClearIcon
                          style={{ color: "red" }}
                          onClick={() => this.handleImageRemove(row, item)}
                        />
                      </div>
                      {/* )} */}

                      <div className={classes.profileImgContainer}>
                        <img
                          className={classes.profileImg}
                          src={row.imageURL}
                          alt={row.label}
                          onClick={() => {
                            this.setState({
                              showImage: true,
                              imagePath: row.imageURL,
                              dialogTitle: row.label,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                ) : (
                  <Grid>
                    <FileUploader
                      selectedRow={row}
                      maxFiles={1}
                      multiple={false}
                      filesUploaded={
                        (e) => this.handleUploadedFiles(e, item) //(e, data);
                      }
                      // isReadOnly={isPastEvent}
                    />
                  </Grid>
                )}
                {/* </div> */}
              </Grid>
            ))}{" "}
          </Grid>
        </div>
      ))}

      <ImageDialogBox
        title={dialogTitle}
        open={showImage}
        imagePath={imagePath}
        // onClick={() => this.closeWindow()}
        // closeDisloag={() => this.closeWindow()}
      />
      <DialogBox
        isSmall
        signup={true}
        open={deleteImageDialog}
        message={deleteWarningMsg}
        header={dialogHeader}
        label={"No"}
        // onClick={() => {
        //   this.closeWindow();
        // }}
        labelTwo={"Yes"}
        // onClickTwo={() => {
        //   this.closeDialogBox();
        // }}
        // closeDisloag={() => this.closeWindow()}
      />
    </div>
  );
};

const styles = (theme) => ({
  actionCss: {
    color: "#F79824",
  },
  imgContainer: {
    position: "relative",
    width: "200px",
    textAlign: "center",
    padding: "40px 20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  clearImgContainer: {
    position: "absolute",
    top: "1px",
    right: "1px",
  },
  labelCss: {
    color: "#727272",
  },
  profileImgContainer: {
    // width: "120px",
    height: "120px",
  },
  profileImg: {
    width: "200px",
    height: "120px",
    objectFit: "contain",
    margin: "auto",
    cursor: "pointer",
  },
  imgTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ImagesView);
