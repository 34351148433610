import * as React from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";

const ProgressBar = ({ classes, history, percent, fillingProgressColor }) => {
  return (
    <div className={classes.mainProgressBarDiv}>
      <div className={"emptyProgressBar"} style={{ width: "100%" }}>
        <div
          className={"fillingProgressBar"}
          style={{
            left: percent - 100 + "%",
            transition: "3s",
            backgroundColor: fillingProgressColor || "green",
          }}
        />
      </div>
    </div>
  );
};
const styles = (theme) => ({
  mainProgressBarDiv: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    flexDirection: "column",
    "& .emptyProgressBar": {
      backgroundColor: "#e7e4da",
      borderRadius: "100px",
      height: "19px",
      overflow: "hidden",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        height: "15px",
      },
      "& .fillingProgressBar": {
        borderRadius: "100px",
        height: "100%",
        width: "100%",
        position: "absolute",
      },
    },
  },
});
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ProgressBar);
