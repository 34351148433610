export function validateEmail(email) {
  var customEmail = /^(")?(?:[^\."])(?:(?:[\.])?(?:[\w\-!#$%&'*+/=?^_`{|}~]))*\1@(\w[\-\w]*\.){1,5}([A-Za-z]){2,6}$/;

  if (customEmail.test(email)) {
    return true;
  } else {
    return false;
  }
}

export function validateDomainName(domain) {
  var customDomain =/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;


  if (customDomain.test(domain)) {
    return true;
  } else {
    return false;
  }
}

export function validateEmpCode(code) {
  var customCode = /^[a-zA-Z0-9]{6}$/;

  if (customCode.test(code)) {
    return true;
  } else {
    return false;
  }
}

export function validatePassword(password) {
  //var customPassword = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$&+,:;=?@#|'<>.^*()%!_\"\"~-])\S{8}/;

  //if (customPassword.test(password)) {
  if (password.length >= 6) {
    return true;
  } else {
    return false;
  }
}

export function isEmpty(value) {
  if (
    value !== undefined && value !== null && typeof value === "string"
      ? value.trim() !== ""
      : value !== ""
  ) {
    return false;
  }
  return true;
}
