import React from "react";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import compose from "recompose/compose";

import Properties from "../../properties/Properties";
import {
  SortSVGIcon,
  SortUpDownSVGIcon,
  SortUpBlueIcon,
  SortDownBlueIcon,
  SortDownGreyIcon,
  FilterSVGIcon
} from "../common/SvgIcon";
import { CustomTextField } from "./CustomTextField";
import MultiSelect from "./MultiSelect";
import ApplicationConstants from "../../constants/ApplicationConstants";

const styles = theme => ({
  drawerPaper: theme.drawer.drawerRight,

  listStyle: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#222222",
    paddingLeft: "10px"
  },
  listnameStyle: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#222222",
    padding: "10px",
    background: "#D0DFFF",
    backgroundClip: "ontent-box"
  },
  scrollabelDiv: {
    height: "100%",
    overflow: "scroll"
  },
  buttonDiv: {
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "10px"
  },
  listSortIcon: {
    height: 27,
    width: 27
  },
  headerTitle: {
    borderBottom: "solid 1px #ccc",
    height: "60px"
  },
  applyBtn: {
    width: "80%",
    background: "#296dfa",
    textTransform: "none",
    height: "38px"
  }
});

class FilterDrawer extends React.Component {
  state = { selectedSort: null,BIBValue:"",BIBError:false,participantError:false,participantValue: "",participantsIds:""};

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isOpen, selectedSortColumn } = nextProps;

    if (isOpen) {
      if (selectedSortColumn) {
        this.setState({ selectedSort: selectedSortColumn });
      }
    }
  }

  render() {
    const {
      classes,
      isOpen,
      onClose,
      sortingLabel,
      columnList,
      handleApplySort
    } = this.props;

    const { selectedSort,BIBValue ,participantValue,participantsIds} = this.state;
    return (
      <Drawer
        anchor="right"
        variant="temporary"
        className="Drawer"
        open={isOpen}
        classes={{
          paper: classes.drawerPaper
        }}
        onClose={() => {
          onClose();
        }}
      >
        <ListItem className={classes.headerTitle}>
          <FilterSVGIcon
            style={{
              color: "#F79824",
              width: "26px",
              height: "20px"
            }}
          />
          <ListItemText
            classes={{ primary: classes.listStyle }}
            style={{ paddingLeft: "0px" }}
          >
            <b>{"Filter"}</b>
          </ListItemText>
        </ListItem>
        <div className={classes.scrollabelDiv}>
          <List>
            {columnList.map((item, i) => (
              <ListItem
                key={i}
                style={{ borderBottom: "solid 1px #ccc" }}
                onClick={e => this.handleColumnSort(item)}
              >
                {this.getListItem(item)}
              </ListItem>
            ))}
          </List>
        </div>
        <div className={classes.buttonDiv}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (selectedSort.sortColumn == "BIB") {
                if (BIBValue == "") {
                  this.setState({BIBError:true})
                } else { 
                  onClose();

                handleApplySort({
                  sortDirection: selectedSort.sortDirection,
                  sortColumn: selectedSort.sortColumn,
                  columnName: selectedSort.columnName,
                  BIBValue:BIBValue
                });
                }
                
              } else if (selectedSort.sortColumn == "PARTICIPANTS") {                 
                if (participantValue == "") {
                  this.setState({participantError:true})
                } else { 
                  onClose();

                handleApplySort({
                  sortDirection: selectedSort.sortDirection,
                  sortColumn: selectedSort.sortColumn,
                  columnName: selectedSort.columnName,
                  participantValue:participantsIds
                });
                }
                
              
              } else {
                onClose();

                handleApplySort({
                  sortDirection: selectedSort.sortDirection,
                  sortColumn: selectedSort.sortColumn,
                  columnName: selectedSort.columnName,
                });
              }
            }}
            className={classes.applyBtn}
          >
            {Properties.Aapply_btnLabel}
          </Button>
        </div>
      </Drawer>
    );
  }

  handleColumnSort = item => {
    const { selectedSort } = this.state;
    item.sortColumn = item.dataIndex;

    if (
      selectedSort &&
      selectedSort.sortColumn === item.sortColumn &&
      selectedSort.sortDirection === "ASC"
    ) {
      item.sortDirection = "DESC";
      this.setState({ selectedSort: item });
    } else if (
      selectedSort &&
      selectedSort.sortColumn === item.sortColumn &&
      selectedSort.sortDirection === "DESC"
    ) {
      item.sortDirection = "ASC";
      this.setState({ selectedSort: item });
    } else {
      item.sortDirection = "ASC";
      this.setState({ selectedSort: item });
    }
  };

  handleParticipantChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
     
      selectedValues.forEach(element => {
        arr.push(element.value)
      });
      

      this.setState({
        participantValue: selectedValues,
        participantsIds:arr.toString()
      });
    } else {
      this.setState({
        participantValue: selectedValues,
        participantError: true,
      });
    }
  };

  getListItem = item => {
    const { classes } = this.props;
    const { selectedSort,BIBError,participantError,participantValue } = this.state;

    if (selectedSort && selectedSort.sortColumn === item.dataIndex) {
      return (
        <div style={{display:"column",width:"100%"}}>
          <ListItemText classes={{ primary: classes.listnameStyle }}>
            <b>{item.columnName}</b>
          </ListItemText>
          {item.dataIndex == "BIB"&&<CustomTextField
            style={
              BIBError ? { border: "1px solid red",                                      
                height: "40px",
                width: "100%",}:{
                 border: "1px solid #08456C",                                      
                height: "40px",
                width: "100%",
              }
            }
            error={BIBError}
            type={"text"}
            handleChange={(prop) => (event) => {              
              this.setState({BIBValue:event.target.value,BIBError:false})
                              }}                  
          />
          }
          {item.dataIndex == "PARTICIPANTS" &&            
                <MultiSelect
                      isMulti
                      isClearable={false}
                      error={participantError}
                      value={participantValue}
                      options={this.props.participantList}
                      onChange={this.handleParticipantChange}
                    />
          }
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <ListItemText classes={{ primary: classes.listStyle }}>
            <b>{item.columnName}</b>
          </ListItemText>
        </React.Fragment>
      );
    }
  };
}

export default compose(withStyles(styles, { withTheme: true }))(FilterDrawer);
