import {
    Typography,
    Grid,
  } from "@material-ui/core";
  import React from "react";
  import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import StarsSharpIcon from "@material-ui/icons/StarsSharp";
import HorizontalLineGraph from "./../../../common/HorizontalLineGraph";
import LinearProgressBar from "../../../common/LinearProgressBar";
import Properties from "../../../../properties/Properties";
const styles = (theme) => ({
 
    totalDetails: {
      color: "#a52a2a",
      fontWeight: "bold",
      fontSize: "20px",
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexWrap: "nowrap",
        height: "20%",
        justifyContent: "flex-start",
        margin: "0",
      },
    },
   
    totalDetails1: {
      color: "#a52a2a",
      fontWeight: "bold",
      fontSize: "20px",
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexWrap: "nowrap",
  
        justifyContent: "start",
        margin: "0",
      },
    },
    totalDetails2: {
      color: "#a52a2a",
      fontWeight: "bold",
      fontSize: "20px",
      display: "flex",
      width: "100%",
      //textAlign:'left',
      // justifyContent: "center",
      // alignItems:'center',
  
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexWrap: "nowrap",
        height: "20%",
        justifyContent: "flex-start",
        margin: "0",
        marginLeft:'5%'
      },
    },
    totalDetailsRunner: {
      color: "#a52a2a",
      fontWeight: "bold",
      fontSize: "20px",
      display: "flex",
      width: "100%",
      textAlign: 'left',
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexWrap: "nowrap",
        height: "20%",
        justifyContent: "flex-start",
        margin: "0",
      },
    },
    totalDetailsRunner1: {
      color: "#a52a2a",
      fontWeight: "bold",
      fontSize: "20px",
      display: "flex",
      width: "100%",
      textAlign:'left',
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexWrap: "nowrap",
        height: "20%",
        justifyContent: "flex-start",
        margin: "0",
        marginLeft:'10%'
      },
    },
    
    graph: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
  
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
        margin: "0",
        padding: 0,
      },
    },
   
    graphs: {
      width: "100%",
      display: "flex",
  
      [theme.breakpoints.down("sm")]: {
        margin: "0",
        padding: 0,
        width: "100%",
      },
    },
  
    graph1: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "flex-start",
        marginTop: "0",
      },
    },   
    rankStyling: {
      marginLeft: "0%",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10%",
      },
    },
    nestedGrid:{
      [theme.breakpoints.up("sm")]: {
       gridColumn:'span 6',
      },
      [theme.breakpoints.up("xs")]: {
        gridColumn:'span 6',
       },
       [theme.breakpoints.up("md")]: {
        gridColumn:'span 8',
       },
      [theme.breakpoints.down("xs")]: {
        gridColumn:'span 12',
       },
      }
  });
function Team(props){
  const {classes,teamValues}=props;
  console.log('teamdis',teamValues?.totalTeamDistance)
    return(
        <Grid
        container
        style={{
          width: "100%",
          display: "flex",
          height:teamValues?.teamTarget !== 0? "100%":'90%',
          //borderTop: "1px solid rgb(8, 69, 108)",
        }}
      >
        {teamValues?.graphToShow === "MULTIPLE" && teamValues?.showTable && (
          <>
            {teamValues?.challengeType !== undefined &&
             (teamValues?.challengeType === "TEAM" || teamValues?.challengeType === "BOTH" ||
             teamValues?.challengeType==="TEAM_RELAY") &&
             teamValues?.isTeamMember &&
             teamValues?.teamName && (
                <Grid item xs={6} sm={3} lg={4} md={4}>
                  <Typography variant="body2" gutterBottom>
                    <span className={classes.totalDetailsRunner}>
                      <span
                        style={{
                          color: "#727272",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {Properties.TeamNameLabel}
                      </span>
                    </span>
                    <div>
                      <span className={classes.totalDetailsRunner}>
                        <span>{teamValues?.teamName}</span>
                      </span>
                    </div>
                  </Typography>
                </Grid>
              )}

            <Grid item xs={4} sm={3} lg={4} md={4}>
              <Typography variant="body2" gutterBottom>
                <span className={classes.totalDetails}>
                  {teamValues?.challengeType !== undefined &&
                   (teamValues?.challengeType === "TEAM" || teamValues?.challengeType === "BOTH" ||
                   teamValues?.challengeType==="TEAM_RELAY") &&
                   teamValues?.isTeamMember &&
                   teamValues?.teamGraphRunnerActivities?.length > 0 &&
                    (teamValues?.totalTeamDistance > 0 ||
                      teamValues?.totalTeamSteps > 0 ||
                      teamValues?.totalTeamWellnessPoints > 0) && (
                      <span
                        style={{
                          color: "#727272",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {teamValues?.graphXAxis === "steps" ? (
                          <>{Properties.Label_Steps}</>
                        ) : teamValues?.graphXAxis === "wellnessPoint" ? (
                          <>
                            <span
                              className={classes.totalDetailsWell}
                            >
                              <span
                                style={{
                                  color: "#727272",
                                  fontWeight: "bold",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                }}
                                onClick={
                                  props
                                    ?.navigateToWellnessPointsDetails
                                }
                              >
                                {Properties.Label_WellnessPoints}
                              </span>
                              <StarsSharpIcon
                                style={{
                                  color: "#a52a2a",
                                  cursor: "pointer",
                                  fontSize: "25px",
                                  //  marginLeft:'5px'
                                }}
                                onClick={
                                  props
                                    ?.navigateToWellnessPointsDetails
                                }
                              />
                            </span>{" "}
                          </>
                        ) : teamValues?.graphXAxis === "distance" ? (
                          <>
                            <span
                              className={classes.totalDetailsWell}
                            >
                              <span
                                style={{
                                  color: "#727272",
                                  fontWeight: "bold",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                }}
                                
                              >
                               {Properties.Distance_label}
                              </span>
                            </span>{" "}
                          </>
                        ) : (
                          ""
                        )}
                      </span>
                    )}
                </span>
                {teamValues?.registration &&
                  (teamValues?.totalTeamDistance > 0 ||
                    teamValues?.totalTeamSteps > 0 ||
                    teamValues?.totalTeamWellnessPoints > 0) && (
                    <div>
                      <span className={classes.totalDetails}>
                        {teamValues?.challengeType !== undefined &&
                          (teamValues?.challengeType === "TEAM" || teamValues?.challengeType === "BOTH" ||
                          teamValues?.challengeType==="TEAM_RELAY") &&
                          teamValues?.isTeamMember &&
                          (teamValues?.graphXAxis === "steps"
                            ? teamValues?.totalTeamSteps !== undefined && (
                                <span>{teamValues?.totalTeamSteps}</span>
                              )
                            : teamValues?.graphXAxis === "wellnessPoint"
                            ? teamValues?.totalTeamWellnessPoints !==
                                undefined && (
                                <span
                                  className={classes.totalDetails1}
                                >
                                  {teamValues?.totalTeamWellnessPoints
                                    ? teamValues?.totalTeamWellnessPoints
                                    : "0"}
                                </span>
                              )
                            : teamValues?.graphXAxis === "distance"
                            ? teamValues?.totalTeamDistance !== null && (
                                <span
                                  className={classes.totalDetails1}
                                >
                                  {teamValues?.totalTeamDistance % 1000 === 0
                                    ? parseFloat(
                                      teamValues?.totalTeamDistance / 1000
                                      ).toFixed(0)
                                    : parseFloat(
                                      teamValues?.totalTeamDistance / 1000
                                      ).toFixed(2)}
                                  {" Km"}
                                </span>
                              )
                            : "")}
                      </span>
                    </div>
                  )}
              </Typography>
            </Grid>

            <Grid item xs={2} sm={3} lg={4} md={4}>
              <Typography variant="body2" gutterBottom>
                <span className={classes.totalDetailsRunner}>
                  {teamValues?.challengeType !== undefined &&
                    (teamValues?.challengeType === "TEAM" ||
                    teamValues?.challengeType === "BOTH" ||  
                    teamValues?.challengeType==="TEAM_RELAY") &&
                    teamValues?.isTeamMember && teamValues?.teamRank &&
                    ( 
                      teamValues?.totalTeamDistance > 0 ||
                      teamValues?.totalTeamSteps > 0 ||
                      teamValues?.totalTeamWellnessPoints > 0) && (
                      <span
                        style={{
                          color: "#727272",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                        className={classes.rankStyling}
                      >
                        {Properties.Label_ResultRank}
                      </span>
                    )}
                </span>
                {teamValues?.registration &&
                  (teamValues?.totalTeamDistance > 0 ||
                    teamValues?.totalTeamSteps > 0 ||
                    teamValues?.totalTeamWellnessPoints > 0) && (
                    <div>
                      <span className={classes.totalDetails}>
                        {teamValues?.challengeType !== undefined &&
                          (teamValues?.challengeType === "TEAM" || teamValues?.challengeType === "BOTH" ||
                          teamValues?.challengeType==="TEAM_RELAY") &&
                          teamValues?.isTeamMember &&
                          teamValues?.teamRank   &&
                          ( 
                            teamValues?.totalTeamDistance > 0 ||
                            teamValues?.totalTeamSteps > 0 ||
                            teamValues?.totalTeamWellnessPoints > 0)&& (
                            <span className={classes.rankStyling}>
                              {teamValues?.totalTeamWellnessPoints !== 0 ||
                              teamValues?.totalTeamDistance !== 0 ||
                              teamValues?.totalTeamSteps !== 0
                                ? teamValues?.teamRank
                                : "---"}
                            </span>
                          )}
                      </span>
                    </div>
                  )}
              </Typography>
            </Grid>
          </>
        )}
        { teamValues?.teamTarget !== 0 &&
           teamValues?.challengeGoal === "TOTAL_TARGET" &&
           teamValues?.isTeamMember &&
           teamValues?.graphToShow === "MULTIPLE" && (
            <Grid item className={classes.graphs}>
              <LinearProgressBar
                completedValue={Number( teamValues?.completedProgress)}
                targetedValue={ teamValues?.targetedProgress}
                isMaxThanTargeted={ teamValues?.isProgressMaxThanTarget}
                maximumValue={
                  teamValues?.isProgressMaxThanTarget
                    ? Number(( teamValues?.completedProgress - 100).toFixed(2))
                    : null
                }
                completedActivity={ teamValues?.completedProgressActivity}
              />
            </Grid>
          )}
        { teamValues?.showGraph &&
           teamValues?.showTable &&
           teamValues?.teamGraphRunnerActivities?.length > 0 &&
          ( teamValues?.totalTeamDistance > 0 ||
            teamValues?.totalTeamSteps > 0 ||
            teamValues?.totalTeamWellnessPoints > 0) && (
            <Grid item className={classes.graph1}>
              <Grid
                item
                xs={12}
                lg={8}
                md={8}
                className={classes.nestedGrid}
              >
                <div key="horizontal">
                <HorizontalLineGraph
                  teamSteps={ teamValues?.totalTeamSteps}
                  teamWellness={ teamValues?.totalTeamWellnessPoints}
                  teamDistance={ teamValues?.totalTeamDistance}
                  graphData={ teamValues?.teamGraphData}
                  graphXAxis={ teamValues?.graphXAxis}
                  graphYAxis={ teamValues?.graphYAxis}
                />
             </div> </Grid>
            </Grid>
          )}
      </Grid>
    )
   
}
export default compose(
    withStyles(
      styles,
  
      { withTheme: true }
    ),
  
  )(Team);
  