import React from "react";
import screenshot from "../../../../../images/Cycle.png";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import AppToolBar from "../../../../common/AppToolBar";
import Header from "../../../../common/Header";
import {
  getActivityDetailsSummary,
  getPostRunImage,
} from "../../../../../apis/eventService";

import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { mergeClasses } from "@material-ui/styles";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import DialogBox from "../../../../common/DialogBox";
import ImageSlider from "../../../../common/ImageSlider";
import { scaleService } from "chart.js";
import RunnerSideBreadcrumbs from "../../../../common/RunnerSideBreadcrumbs";

const styles = (theme) => ({
  tableBlock: {
    display: "flex",
    // marginTop: "60px",
    justifyContent: "center",
  },

  tablebody: {
    border: "1px solid gray",
    width: "60vw",
    padding: "10px",
    marginTop: "30px",
    [theme.breakpoints.down("xs")]: {
      margin: "0 10px 0 10px",
      width: "90vw",
    },
  },

  tableHeader: {
    backgroundColor: "rgb(245, 240, 245)",
    "& h3": {
      color: "rgb(40, 131, 181)",
      fontWeight: "bold",
      fontSize: "20px",
      margin: "10px 0 10px 0",
    },
    "& span": {
      color: "rgb(114, 114, 114)",
      fontSize: "18px",
      fontWeight: "700",
    },
  },

  bodyRow: {
    marginTop: "20px",
    "& span": {
      color: "gray",
      fontWeight: "bold",
      fontSize: "12px",
    },
  },

  rowdata1: {
    "& span>p": {
      color: "rgb(114, 114, 114)",
      fontSize: "15px",
    },
    "& span>p span": {
      color: "rgb(40, 131, 181)",
      fontSize: "17px",
      fontWeight: "normal",
    },
  },

  rowImage1: {
    "& h3": {
      color: "rgb(114, 114, 114)",
      fontSize: "20px",
      fontWeight: "600",
    },
  },

  row: {
    width: "50%",
    display: "inline-block",

    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "100%",
    },
  },

  floatParent: {
    width: "100%",
    display: "grid",
    borderTop: "1px solid rgb(245, 240, 245)",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "20px",
    paddingBottom: "30px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      paddingBottom: "0",
    },
  },

  imageDataClass: {
    padding: "5px",
    width: "50%",
    justifyContent: "center",
  },

  imageRowDataClass: {
    float: "left",
    width: "50%",
    justifyContent: "center",
  },

  rowdata2: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: {
      borderBottom: "none",
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
    },
  },

  floatContainer: {
    padding: "20px",
  },

  float_Child: {
    width: "50%",
    float: "left",
    padding: "20px",
    border: "1px solid black",
  },

  flexContainer: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      paddingBottom: "0",
    },
  },
  flexChild: {
    flex: "1",
    "&:first-child": {
      marginRight: "20px",
    },
    "&:last-child": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      justifyContent: "space-evenly",
      [theme.breakpoints.down("xs")]: {
        borderBottom: "none",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
      },
    },
    "& span>p": {
      marginTop: "0",
      marginBottom: "2px",
      color: "rgb(114, 114, 114)",
      fontSize: "18px",
      fontWeight: "bold",
    },
    "& span span": {
      color: "rgb(40, 131, 181)",
      fontSize: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px",
    },
  },

  tableDescription: {
    marginTop: "10px",
    "& h3": {
      color: "rgb(114, 114, 114)",
      fontSize: "20px",
      fontWeight: "600",
      margin: "0",
    },
  },

  hrLine: {
    border: "1px solid rgb(245, 240, 245)",
  },

  date_time_row: {
    display: "flex",
    flexWrap: "wrap",
  },

  date_time_row: {
    "& span": {
      display: "inline-flex",
      justofyContent: "center",
      textAlign: "center",
      marginRight: "20px",
      placeItems: "center",
    },
  },

  title_row: {
    display: "flex",
    fontSize: "1.1rem",
    boxSizing: "border-box",
  },

  title_row: {
    "& h3": {
      color: "rgb(40, 131, 181)",
      fontWeight: "bold",
      fontSize: "20px",
      margin: "10px 0 10px 0",
    },
    "& span": {
      color: "rgb(114, 114, 114)",
      fontSize: "20px",
      fontWeight: "600",
    },
  },

  description: {
    "& p": {
      display: "block",
      textAlign: "left",
      margin: 0,
      padding: 0,
      fontSize: "15px",
    },
  },

  calculation_row: {
    display: "inline-flex",
    marginTop: "30px",
    textAlign: "center",
  },

  calculation_inner_div: {
    display: "flex",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
    },
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexWrap: "wrap",
      justofyContent: "space-between",
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justofyContent: "space-between",
    },
  },

  calculation_div: {
    "& .map_item": {
      margin: "2px 2px 5px 2px",
    },
  },

  calculation_div: {
    "& .map_item > div": {
      margin: "0 50px 20px 0",
      display: "block",
    },
  },

  calculation_name: {
    color: "rgb(114, 114, 114)",
    fontSize: "18px",
    fontWeight: "600",
  },

  calculation_value: {
    color: "rgb(40, 131, 181)",
    fontSize: "20px",
    marginBottom: "20px",
  },

  source: {
    margin: "20px 0px",
    "& h3": {
      color: "rgb(114, 114, 114)",
      fontSize: "18px",
      margin: "0",
    },
    "& p": {
      color: "rgb(40, 131, 181)",
      textAlign: "left",
      fontSize: "16px",
      margin: "0",
      padding: "0",
    },
  },

  carousel: {
    marginTop: "40px",
    width: "200px",
    height: "200px",
    paddingBottom: "25px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      width: "100%",
      height: "max-content",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      width: "60%",
      height: "max-content",
    },
    [theme.breakpoints.up("md")]: {
      width: "100px",
      height: "100px",
    },
  },

  carousel_inner: {
    height: "150px",
    width: "150px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    cursor: "pointer",
    // paddingBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      width: "100px",
      height: "100px",
    },
  },

  carousel_inner_left: {
    flex: "8%",
    height: "100%",
    backgroundColor: " rgba(0, 0, 0, 0.4)",
    display: "grid",
    placeItems: "center",
    color: "white",
    cursor: "pointer",
  },

  carousel_inner_center: {
    flex: "80%",
    height: "100%",
  },

  carousel_inner_right: {
    flex: "8%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    display: "grid",
    placeItems: "center",
    color: "white",
    cursor: "pointer",
  },

  one_inner_image: {
    height: "100px",
    width: "100px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    display: "flex",
    marginBottom: "10px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "100px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100px",
      height: "100px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100px",
      height: "100px",
    },
    [theme.breakpoints.up("md")]: {
      width: "150px",
      height: "150px",
    },
  },

  activity_url: {
    margin: "30px 0 0 0",
  },

  activity_url_a: {
    textDecoration: "none",
    color: "rgb(40, 131, 181)",
    fontSize: "18px",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  urlDiv: {
    marginBottom: "10px",
    display: "flex",
    flexWrap: "wrap",
    "& span": {
      color: "rgb(114, 114, 114)",
      fontSize: "20px",
      fontWeight: "600",
    },
    "& span>a": {
      fontWeight: "300",
    },
  },

  screenshot_div: {
    width: "40%",
    height: "150px",
  },

  screenshot: {
    height: "100%",
    width: "100%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      width: "40%",
      height: "250px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      width: "60%",
      height: "250px",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
      height: "400px",
    },
  },

  demodiv: {
    display: "block",
    height: "100px",
  },
  buttonClick: {
    marginTop: "100px",
  },
});

const events = [
  { title: "My Calender", start: "2022-06-22", end: "2022-06-25" },
  { title: `My Calender2 : ${50}`, date: "2022-06-22" },
];

class EventActivityDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      postRunCurImg: 0,
      // events: [],
      screensshotCurImg: 0,
      activityDetails: null,
      title: null,
      activityScreenshotStatus: null,
      activityScreenshot: null,
      postRunImageStatus: null,
      activityUrl: null,
      fetchFrom: null,
      imageList: null,
      length: null,
      screenShotLength: null,
      showDialog: false,
      showScreenShotDialog: false,
      headerTitle: null,
      eventDate: null,
      eventTime: null,
      localStartDate: null,
      localEndDate: null,
      subActivityTypeName: null,
      click: false,
    };
  }

  changeState = () => {
    this.setState({ showDialog: true });
  };

  changeScreenShotState = () => {
    this.setState({ showScreenShotDialog: true });
  };

  getDialogComponent = () => {
    return (
      <div>
        <ImageSlider files={this.state.imageList} />
      </div>
    );
  };

  getScreenShotDialogComponent = () => {
    return (
      <div>
        <ImageSlider files={this.state.activityScreenshot} />
      </div>
    );
  };

  closeScreenShotWindow = () => {
    this.setState({ showScreenShotDialog: false });
  };

  closeWindow = () => {
    this.setState({ showDialog: false });
  };

  decreseValue = () => {
    this.state.postRunCurImg > 0 &&
      this.setState({ postRunCurImg: this.state.curImg - 1 });
  };

  increseValue = () => {
    this.state.postRunCurImg < this.state.imageList.length - 1 &&
      this.setState({ postRunCurImg: this.state.postRunCurImg + 1 });
  };

  decreseScreenShotValue = () => {
    this.state.screensshotCurImg > 0 &&
      this.setState({ screensshotCurImg: this.state.screensshotCurImg - 1 });
  };

  increseScreenShotValue = () => {
    this.state.screensshotCurImg < this.state.activityScreenshot.length - 1 &&
      this.setState({ screensshotCurImg: this.state.screensshotCurImg + 1 });
  };

  componentDidMount() {
    let eventId = ApplicationUtil.getRunnersEventID();
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runner?.id;
    let activityId = localStorage.getItem("activityId");
    getActivityDetailsSummary(eventId, runnerId, activityId).then(
      ({ data }) => {
        let distanceInKm = {
          id: 1,
          name: "Distance",
          value: parseFloat(data?.distance / 1000).toFixed(2) + " Km",
        };
        let steps = { id: 2, name: "Steps", value: data?.steps };
        let completionTime = {
          id: 3,
          name: "Time",
          value: data?.completionTime,
        };
        let averagePace = {
          id: 4,
          name: "Pace",
          value: data?.averagePace + "/km",
        };
        let elevationGain = {
          id: 5,
          name: "Elevation Gain",
          value: data?.elevationGain,
        };
        let elevationLoss = {
          id: 6,
          name: "Elevation Loss",
          value: data?.elevationLoss,
        };
        let calories = { id: 7, name: "Calories", value: data?.calories };
        let heartRate = {
          id: 8,
          name: "Heart Rate",
          value: data?.averageHeartRate,
        };
        let temperature = {
          id: 9,
          name: "Temperature",
          value: data?.temperature,
        };
        let humidity = {
          id: 10,
          name: "Humidity",
          value: data?.humidity,
        };

        const arr = [];

        if (data?.displayColumns) {
          if (data?.displayColumns.includes("distance")) {
            arr.push(distanceInKm);
          }
          if (data?.displayColumns.includes("completionTime")) {
            arr.push(completionTime);
          }
          if (data?.displayColumns.includes("averagePace")) {
            arr.push(averagePace);
          }
          if (data?.displayColumns.includes("steps")) {
            arr.push(steps);
          }
          if (data?.displayColumns.includes("elevationGain")) {
            arr.push(elevationGain);
          }
          if (data?.displayColumns.includes("elevationLoss")) {
            arr.push(elevationLoss);
          }
          if (data?.displayColumns.includes("calories")) {
            arr.push(calories);
          }
          if (data?.displayColumns.includes("averageHeartRate")) {
            arr.push(heartRate);
          }
          if (data?.displayColumns.includes("temperature")) {
            arr.push(temperature);
          }
          if (data?.displayColumns.includes("humidity")) {
            arr.push(humidity);
          }
        } else {
          if (data.distance > 0) {
            arr.push(distanceInKm);
          }
          if (data.steps !== undefined) {
            arr.push(steps);
          }
          if (data.completionTime !== undefined) {
            arr.push(completionTime);
          }
          if (data.averagePace !== undefined) {
            arr.push(averagePace);
          }
          if (data.elevationGain > 0) {
            arr.push(elevationGain);
          }
          if (data.elevationLoss > 0) {
            arr.push(elevationLoss);
          }

          if (data.calories > 0) {
            arr.push(calories);
          }

          if (data.averageHeartRate > 0) {
            arr.push(heartRate);
          }
          if (data.temperature > 0) {
            arr.push(temperature);
          }
          if (data.humidity > 0) {
            arr.push(humidity);
          }
        }

        if (data?.cooperTestDistance)
          arr.push({
            id: 11,
            name: "Cooper Distance",
            value:
              parseFloat(data?.cooperTestDistance / 1000).toFixed(2) + " Km",
          });
        if (data?.cooperTestRemark)
          arr.push({ name: "Remark", value: data?.cooperTestRemark });

        this.setState({
          activityDetails: arr,
          title: data.name,
          activityScreenshotStatus: data.activityScreenshotStatus,
          postRunImageStatus: data.postRunImageStatus,
          activityUrl: data.activityUrl,
          fetchFrom: data.fetchFrom,
          activityScreenshot: data.activityScreenShots?.images,
          screenShotLength: data.activityScreenShots?.images?.length,
          headerTitle: data.eventDTO.name,
          eventDate: data.startDateLocal,
          // eventTime: data.eventDTO.eventTime,
          localStartDate: data.eventDTO.localStartDate,
          localEndDate: data.eventDTO.localEndDate,
          description: data?.description,
          imageList: data.postRunImages?.images,
          length: data.postRunImages?.images?.length,
          subActivityTypeName: data.subActivityType,
        });
      }
    );
  }
  render() {
    const { classes } = this.props;
    const { activityDetails, description } = this.state;
    const { imageList } = this.state;
    return (
      <React.Fragment>
        <AppToolBar>
          <Header
            // backNavigate={() => this.props.history.push("/eventlist")} //goBack()} //
            // showBack={true}
            {...this.props}
            label={this.state.headerTitle ? this.state.headerTitle : ""}
            fromDate={this.state.localStartDate}
            toDate={
              this.state.localStartDate === this.state.localEndDate
                ? false
                : this.state.localEndDate
            }
            // reloadDetails={this.pageRefresh}
          />
        </AppToolBar>
        <div style={{ marginTop: "60px" }}>
          <RunnerSideBreadcrumbs />
        </div>
        <div className={classes.tableBlock}>
          <div className={classes.tablebody}>
            <div className={classes.tableHeader}>
              {this.state.subActivityType ? (
                <h3>Sub Activity Type</h3>
              ) : (
                <h3>
                  <span>{this.state.subActivityTypeName} -</span>{" "}
                  {this.state.title}
                </h3>
              )}
            </div>
            <hr className={classes.hrLine} />
            <div className={classes.flexContainer}>
              <div className={classes.flexChild}>
                <span>
                  <p>
                    Activity performed on
                    {/*at <span>{this.state.eventTime}</span>*/}
                  </p>
                  <span>{this.state.eventDate}</span>{" "}
                </span>
              </div>

              <div className={classes.flexChild}>
                {activityDetails?.map((item) => {
                  return (
                    <div className="map_item">
                      <div>
                        <div className={classes.calculation_name} key={item.id}>
                          {item.name}
                        </div>
                        <div className={classes.calculation_value}>
                          {item.value}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <hr className={classes.hrLine} />
            <div className={classes.tableDescription}>
              <div>
                {description && (
                  <div>
                    <div className={classes.description}>
                      <h3>Description :</h3>
                      <p>{description}</p>
                    </div>
                  </div>
                )}
              </div>
              <div className={classes.source}>
                <div>
                  <h3>{this.state.fetchFrom === "MANUAL" ? "" : "Source"}</h3>
                  <p>
                    {this.state.fetchFrom === "MANUAL"
                      ? "Manually Uploaded"
                      : this.state.fetchFrom}
                  </p>
                </div>
              </div>
            </div>
            <div>
              {this.state.activityUrl && (
                <div className={classes.activity_url}>
                  <div className={classes.urlDiv}>
                    <span>
                      Activity link :
                      <a
                        href={this.state.activityUrl}
                        target="new"
                        className={classes.activity_url_a}
                      >
                        &nbsp;Click here
                      </a>
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className={classes.floatParent}>
              <div className={classes.floatChild}>
                <div className={classes.rowImage1}>
                  {this.state.postRunImageStatus === "AVAILABLE" ? (
                    this.state.length <= 1 ? (
                      <>
                        <h3>Post Activity Image</h3>
                        <div
                          className={classes.one_inner_image}
                          style={{
                            backgroundImage: `url(${
                              imageList
                                ? "data:image/png;base64," +
                                  this.state.imageList[this.state.postRunCurImg]
                                    ?.imageData
                                : null
                            })`,
                          }}
                          onClick={() => this.changeState()}
                        />
                      </>
                    ) : (
                      <>
                        <h3>Post Run Images</h3>
                        <div className={classes.carousel}>
                          <div
                            className={classes.carousel_inner}
                            style={{
                              backgroundImage: `url(${
                                this.state.imageList
                                  ? "data:image/png;base64," +
                                    this.state.imageList[
                                      this.state.postRunCurImg
                                    ]?.imageData
                                  : null
                              })`,
                            }}
                            onClick={() => this.changeState()}
                          >
                            <div
                              className={classes.carousel_inner_left}
                              onClick={() => this.decreseValue()}
                            >
                              <ArrowBackIos />
                            </div>
                            <div
                              className={classes.carousel_inner_center}
                              onClick={() => this.changeState()}
                            ></div>
                            <div
                              className={classes.carousel_inner_right}
                              onClick={() => this.increseValue()}
                            >
                              <ArrowForwardIos />
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className={classes.floatChild}>
                <div className={classes.rowImage1}>
                  {this.state.activityScreenshotStatus === "AVAILABLE" ? (
                    this.state.activityScreenshot?.length <= 1 ? (
                      <>
                        <h3>Screenshot</h3>
                        <div className={classes.screenshot_div}>
                          <div className={classes.screenshot}>
                            <img
                              className={classes.one_inner_image}
                              src={
                                this.state.activityScreenshot
                                  ? "data:image/png;base64," +
                                    this.state.activityScreenshot[
                                      this.state.screensshotCurImg
                                    ]?.imageData
                                  : null
                              }
                              onClick={() => this.changeScreenShotState()}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <h3>Screenshots</h3>
                        <div className={classes.carousel}>
                          <div
                            className={classes.carousel_inner}
                            style={{
                              backgroundImage: `url(${
                                this.state.activityScreenshot
                                  ? "data:image/png;base64," +
                                    this.state.activityScreenshot[
                                      this.state.screensshotCurImg
                                    ]?.imageData
                                  : null
                              })`,
                            }}
                          >
                            <div
                              className={classes.carousel_inner_left}
                              onClick={() => this.decreseScreenShotValue()}
                            >
                              <ArrowBackIos />
                            </div>
                            <div
                              className={classes.carousel_inner_center}
                              onClick={() => this.changeScreenShotState()}
                            ></div>
                            <div
                              className={classes.carousel_inner_right}
                              onClick={() => this.increseScreenShotValue()}
                            >
                              <ArrowForwardIos />
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {/* <div className={classes.floatContainer}>
              <div className={classes.float_Child}>
                <div>Float Column 1</div>
              </div>

              <div className={classes.float_Child}>
                <div>Float Column 2</div>
              </div>
            </div> */}
          </div>
          {this.state.length && (
            <DialogBox
              fullWidth
              maxWidth={"md"}
              // deletePhoto={
              //   showDeleteButton
              //     ? this.state.imageList != 0
              //       ? true
              //       : false
              //     : false //0 ? true : false
              // }
              // onDeletePhoto={this.deletePhoto}
              // addPhoto={showAddButton}
              // onAddPhoto={this.multipleUpload}
              open={this.state.showDialog}
              message={this.getDialogComponent()}
              // onClick={() => this.closeWindow()}
              closeDisloag={() => this.closeWindow()}
            />
          )}
          {this.state.screenShotLength && (
            <DialogBox
              fullWidth
              maxWidth={"md"}
              // deletePhoto={
              //   showDeleteButton
              //     ? this.state.imageList != 0
              //       ? true
              //       : false
              //     : false //0 ? true : false
              // }
              // onDeletePhoto={this.deletePhoto}
              // addPhoto={showAddButton}
              // onAddPhoto={this.multipleUpload}
              open={this.state.showScreenShotDialog}
              message={this.getScreenShotDialogComponent()}
              // onClick={() => this.closeWindow()}
              closeDisloag={() => this.closeScreenShotWindow()}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

// export default EventDetailsSummaryPage;
export default compose(
  withStyles(
    styles,

    { withTheme: true }
  ),
  withRouter
)(EventActivityDetails);
