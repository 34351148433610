import React from "react";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { useState } from "react";

function LinearProgressBar(props) {
  const {
    classes,
    completedValue,
    targetedValue,
    isMaxThanTargeted,
    maximumValue,
    completedActivity,
    showKm,
    kmsCompleted,
    challengeParameter,
  } = props;
  // const [style, setStyle] = useState({})
  // const [maxBarStyle,setMaxBarStyle] = useState({})
  // setTimeout(() => {
  const style = {
    opacity: 1,
    width: isMaxThanTargeted
      ? "100%"
      : completedValue
      ? completedValue + "%"
      : "0%",
    background:
      completedValue < 10
        ? "linear-gradient(to right, #ff4d6d,#ff4d6d)"
        : completedValue < 20
        ? "linear-gradient(to right, #ff4d6d, #ef233c)"
        : completedValue < 50
        ? "linear-gradient(to right, #ff4d6d,#ef233c, #f49f0a)"
        : completedValue < 70
        ? "linear-gradient(to right, #ff4d6d,#ef233c, #f49f0a,#52b788)"
        : "linear-gradient(to right, #ff4d6d,#ef233c, #f49f0a,#52b788,#40916c)",
    borderRadius: "20px 0px 0px 20px",
  };
  const maxBarStyle = {
    opacity: 1,
    width: maximumValue ? maximumValue + "%" : "0%",
    background: "linear-gradient(to right,#40916c,#40916c)",
    //    marginLeft:'-4px',
    borderRadius: "0px 20px 20px 0px",
  };
  //format the completed and target value by adding commas between the number
  function formatProgressbarValue(number) {
    const numberString = String(number);
    const parts = numberString.split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1] ? `.${parts[1]}` : "";

    const formattedIntegerPart = integerPart.replace(
      /(\d)(?=(\d\d)+\d$)/g,
      "$1,"
    );

    return formattedIntegerPart + decimalPart;
  }
  return (
    <div className={classes.progressBar}>
      <div className={classes.progress}>
        {completedValue !== NaN && (
          <div className={classes.progressDone} style={style}>
            {/* {'Completed ' + completedValue + "%"} */}
          </div>
        )}
        <div className={classes.seperator}></div>
        {isMaxThanTargeted && (
          <div className={classes.progressDone} style={maxBarStyle}></div>
        )}
      </div>
      <div className={classes.valueLayout}>
        {!isMaxThanTargeted && (
          <div
            style={{
              width: completedValue ? completedValue + "%" : "0%",
              fontSize: "20px",
            }}
          >
            <span>
              {showKm
                ? kmsCompleted && kmsCompleted >= 10000
                  ? formatProgressbarValue(kmsCompleted) +
                    `${
                      challengeParameter === "DISTANCE"
                        ? " Km Completed"
                        : " Steps Completed"
                    }`
                  : showKm && kmsCompleted && kmsCompleted <= 10000
                  ? kmsCompleted +
                    `${
                      challengeParameter === "DISTANCE"
                        ? " Km Completed"
                        : " Steps Completed"
                    }`
                  : "0 Km Completed"
                : completedValue
                ? completedValue + "%" + " Completed"
                : "0% Completed"}
            </span>
          </div>
        )}
        <div
          className={classes.targetedValue}
          style={{
            width: 1 < maximumValue <= 15 ? maximumValue + "%" : "100%",
            paddingLeft: 1 < maximumValue <= 15 ? "" : "30px",
            // marginTop: maximumValue ? 0 : "5px",
            textAlign: 1 < maximumValue <= 15 && "left",
          }}
        >
          <span style={{ marginLeft: 1 < maximumValue <= 15 && "10px" }}>
            {targetedValue && targetedValue >= 10000
              ? formatProgressbarValue(targetedValue)
              : targetedValue && targetedValue < 10000
              ? targetedValue
              : "0"}
          </span>
          <br />
          {maximumValue && (
            <p
              style={{
                margin: 0,
                fontSize: "15px",
                width: "120px",
                textAlign: "left",
              }}
            >
              Total target
            </p>
          )}
        </div>
        {isMaxThanTargeted && (
          <div
            className={classes.targetedValue}
            style={{
              width:
                1 < maximumValue <= 15
                  ? "100%"
                  : maximumValue
                  ? maximumValue + "%"
                  : "0%",
              marginTop: maximumValue ? 0 : "5px",
            }}
          >
            <span>
              {completedActivity && completedActivity >= 10000
                ? formatProgressbarValue(completedActivity)
                : completedActivity &&
                  completedActivity <= 10000 &&
                  completedActivity}
            </span>
            {maximumValue && (
              <p style={{ margin: 0, fontSize: "15px" }}>Achieved</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const styles = (theme) => ({
  progressBar: {
    width: "100%",
    marginTop: "30px",
    marginBottom: "30px",
  },
  progress: {
    backgroundColor: "#d8d8d8",
    borderRadius: "20px",
    height: "30px",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  progressDone: {
    boxShadow: "0 3px 3px -5px #f2709c, 0 2px 5px #6c757d",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    opacity: 0,
    transition: "1s ease-in",
  },
  valueLayout: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    color: "#a52a2a",
    fontWeight: "bold",
    marginTop: "5px",
  },
  targetedValue: {
    fontSize: "20px",
    textAlign: "end",
  },
  seperator: {
    border: "2px solid #585151",
    backgroundColor: "#edf754",
    borderRadius: "4px",
    height: "35px",
    zIndex: 0,
    width: "10px",
  },
});
export default compose(withStyles(styles, { withTheme: true }))(
  LinearProgressBar
);
