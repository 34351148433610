import { Card, CardContent, Paper, Typography } from "@material-ui/core";
import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Properties from "../../../../../properties/Properties";
import { withRouter } from "react-router-dom";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import httpService from "../../../../../apis/httpService";
const styles = (theme) => ({
  eventOrganizer: {
    width: "100%",
    maxWidth: "850px",
    margin: "auto",
    marginBottom: "10px",
  },
  organizerTitle: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  },
});

class EventOrganizer extends Component {
  render() {
    const { data, classes } = this.props;
    if (data?.organizers && data?.organizers?.length) {
      return (
        <Card className={classes.eventOrganizer}>
          <CardContent>
            <Typography variant="body2" gutterBottom>
              <span
                style={{
                  color: "#2883B5",
                  fontSize: "23px",
                  fontWeight: "bold",
                }}
              >
               {data?.id === 597 ? 'IMPLEMENTATION AGENCY' : Properties.Label_EventDetails_Organizer }
              </span>
            </Typography>
            {data?.organizers &&
              data?.organizers.map((organizer) => {
                return (
                  <div
                    className={
                      organizer?.organizerLogo ? classes.organizerTitle : ""
                    }
                  >
                    {organizer?.organizerLogo && (
                      <img
                        src={
                          organizer?.organizerLogo.includes("http")
                            ? `${organizer?.organizerLogo}`
                            : `${
                                httpService.API_URL() + organizer?.organizerLogo
                              }`
                        }
                        alt={organizer?.name}
                        style={{
                          width: "80px",
                          // height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    )}

                    <b style={{ fontSize: "20px", marginLeft: "10px" }}>
                      {ReactHtmlParser(organizer?.name)}
                    </b>
                  </div>
                );
              })}
          </CardContent>
        </Card>
      );
    }
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(EventOrganizer);
