import React, { Component } from "react";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";


import draftToHtml from "draftjs-to-html";





import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./../../styles/NewTextEditor.css";



class EditorConvertToHTML extends Component {
  constructor(props) {
    super(props);

    let htmlState;
    if (this.props?.htmlText !== "") {
      const blocksFromHTML = convertFromHTML(this.props.htmlText );
    
      console.log(blocksFromHTML.contentBlocks 
        , "effect not");
      htmlState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
    }
    this.state = {
      editorState:
        this.props?.htmlText !== ""
          ? EditorState.createWithContent(htmlState)
          : EditorState.createEmpty(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.htmlText !== this.props.htmlText && this.props.propsText) {
      const blocksFromHTML = convertFromHTML(this.props.htmlText);
      const contentBlocks = blocksFromHTML.contentBlocks.reverse();
      // console.log(JSON.stringify(convertFromHTML(this.props.htmlText , {
      //   inlineStyles: true, // extract inline styles
      // }), null, 2), "effect");
      const htmlState = ContentState.createFromBlockArray(
        contentBlocks,
        blocksFromHTML.entityMap
      );

      this.setState({
        editorState: EditorState.createWithContent(htmlState),
      });
    }
  }

  onEditorStateChange = (editorState) => {

    console.log("hello setting new value" ,editorState , convertToRaw(editorState.getCurrentContent()));
    this.setState({
      editorState,
      editorContentHtml: draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ),
    });

    if (this.props?.getEditedValue)
      console.log("getEditValue" ,editorState);
      this.props.getEditedValue(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      );

    if (this.props?.isResponses) {
      console.log("isresponse" ,editorState);
      //added this props for to know that user is entered or not any text/response except the HTML tag - for validation purpose
      let val = convertToRaw(editorState.getCurrentContent())?.blocks || [];
      let isResponded = val?.some((obj) => obj?.text && obj?.text !== "");
      this.props.isResponses(isResponded);
    }
  };

  

  render() {
    const { editorState } = this.state;
    const { readOnly } = this.props;

    // console.log("hello 1" , editorState );
    

    return (
      <div className="root">
        <Editor
          editorState={editorState}
          readOnly={readOnly}
          toolbarHidden={readOnly}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          toolbarClassName="toolbar"
          forceSelection={true}
          onEditorStateChange={this.onEditorStateChange}
          handlePastedText={() => false}
          toolbar={{
            options: ["inline", "blockType", "list", "history", "link" , "colorPicker"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
            blockType: {
              options: ["Normal", "H1", "H2", "H3", "H4"],
              className: "blockType",
            },
            colorPicker: {
              // icon: color,
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
            },
          }}
        />
      </div>
    );
  }
}

export default EditorConvertToHTML;




