import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Collapse,
  Typography,
  IconButton,
  CardActions,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import clsx from "clsx";
import Properties from "../../../../../../properties/Properties";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  card: {
    maxWidth: "850px",
    width: "100%",
    margin: "auto",
    marginBottom: "10px",
  },
  expand: {
    cursor: "pointer",
    textAlign: "center",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  categoryDisplay: {
    marginTop: "15px",
  },
});
class EventInfoCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }
  handleExpandClick = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  getCategories = (data) => {
    const { classes } = this.props;
    let activities = data.activities;
    let activityTypeCategoriesArray = [];
    let categoriesArr = data.eventRunCategories;
    for (let i = 0; i < activities.length; i++) {
      let activityType = categoriesArr.filter((x) => {
        return (
          x.eventSupportedActivityType.id ===
          activities[i].eventSupportedActivityTypeId
        );
      });
      activityTypeCategoriesArray.push(activityType);
    }
    if (data.type !== "Challenge") {
      return activityTypeCategoriesArray.map((arr) => {
        let displayName = arr[0].eventSupportedActivityType.displayName;
        return (
          <>
            <div className={classes.categoryDisplay}>
              <b>{displayName + " " + "Categories"}</b>
            </div>

            {arr.map((km) => {
              if (arr[arr.length - 1].category === km.category) {
                return <span>{km.categoryName} </span>;
              } else {
                return <span>{km.categoryName} | </span>;
              }
            })}
          </>
        );
      });
    }
  };
  render() {
    const { classes, data } = this.props;
    const { expanded } = this.state;
    const isProgram = window.location.href?.includes("program");

    return (
      <>
        <Card elevation={2} className={classes.card}>
          <CardContent>
            <Typography variant="body2" gutterBottom>
              <span
                style={{
                  color: "#2883B5",
                  fontSize: "23px",
                  fontWeight: "bold",
                }}
              >
                {isProgram
                  ? Properties.Label_ProgramDetails_ProgramInfo_Title
                  : Properties.Label_EventDetails_EventInfo_Title}
              </span>
            </Typography>
            <br />
            <Typography variant="body2" component="div">
              {data && (
                <span>
                  <b>{data.name}</b>
                </span>
              )}
              <br />
              {data && <div>{ReactHtmlParser(data.description)}</div>}
            </Typography>
            <br />
            {data?.showCategoryOnRegistration && data?.eventRunCategories && (
              <Typography variant="body2" component="div">
                {this.getCategories(data)}
              </Typography>
            )}

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <br />
              <Typography variant="body2" component="div">
                <span>
                  <b>{Properties.Label_EventDetails_EventInfo_Guide}</b>
                </span>
                <br />
                <span>
                  {data && data.longDescription && (
                    <span style={{ textAlign: "left" }}>
                      {ReactHtmlParser(data.longDescription)}
                    </span>
                  )}
                </span>
              </Typography>
            </Collapse>
          </CardContent>
          {data && data.longDescription && (
            <CardActions style={{ textAlign: "center" }}>
              <Typography
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={this.handleExpandClick}
                aria-expanded={expanded}
              >
                {expanded ? (
                  <IconButton>
                    <ExpandLessIcon />{" "}
                  </IconButton>
                ) : (
                  <span>{Properties.Label_EventDetails_EventInfo_Read}</span>
                )}
              </Typography>
            </CardActions>
          )}
        </Card>
      </>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(EventInfoCard);
