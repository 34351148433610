import React, { Component } from "react";
import {
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Grid,
  Typography,
} from "@material-ui/core";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import "./../../../../../styles/common.css";
import EventListTiles from "./../../../../common/EventListTiles";
import ListPageLoader from "./../../../../common/ListPageLoader";
import { withRouter } from "react-router-dom";
import OrganizerCommonHeader from "./../OrganizerCommonHeader";
import { EmptyEvents, RuppeeIcon } from "../../../../common/SvgIcon";
import Properties from "../../../../../properties/Properties";
import Snackbar from "../../../../common/Snackbar";
import DialogBox from "../../../../common/DialogBox";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { openEventChallenges } from "../../../../../apis/challengeService";
import Breadcrumbs from "../../../../common/Breadcrumbs";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import ChallengeDetailsTile from "../../../registration/challenges/ChallengeDetailsTile";
const styles = (theme) => ({
  main: {
    width: "95%",
    margin: "auto",
    marginTop: "70px",
  },
  eventBlock: {
    background: "rgba( 58, 110, 165, 0.25 )",
    boxShadow: "0 4px 4px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: " blur( 4px )",
    // -webkit-backdrop-filter: blur( 4px );
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    // width: "30%",
    padding: "10px",
    marginBottom: "15px",
    marginLeft: "15px",
    cursor: "pointer",
  },
  title: {
    fontWeight: 600,
    fontSize: "20px",
  },
  desc: {
    fontWeight: 600,
    fontSize: "14px",
  },
  bottomText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  trial: {
    backgroundColor: "#f3722c",
    borderRadius: "10px",
    color: "#fff",
    padding: "10px",
    width: "50%",
    fontWeight: 600,
  },
  container: {
    display: "flex",
  },
  media: {
    width: "200px",
    height: "200px",
    // maxWidth: "60%",
    // minWidth: "100%",
    marginLeft: "auto",
    objectFit: "content",
    borderRadius: "8px",
    boxShadow: "inset 0px 0px 19px 0px rgba(0,0,0,0.75)",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
      height: "150px",
      marginTop: "auto",
      marginBottom: "auto",
    },
  },
  cardmedia: {
    width: "50%",
    marginLeft: "auto",
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  textContainer: {
    width: "50%",
  },
  fee: {
    position: "relative",
    bottom: 0,
  },
  gridContainer: {
    marginTop: "10px",
  },
});

class OpenChallengesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      userName: localStorage.getItem("supportUserName"),
      value: 0,
      data: null,
      totalRecords: 0,
    };
  }

  componentDidMount() {
    this.loadDetails();
  }
  loadDetails = () => {
    localStorage.removeItem("pastEvent");
    let organizer = JSON.parse(localStorage.getItem("organizer"));

    openEventChallenges(organizer?.id ? organizer?.id : null).then((res) => {
      if (res?.data) {
        this.setState({
          responseData: res.data,
        });
      }
    });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleChange = (event, newValue) => {
    const { canModifyEvent } = this.state;
    let showMenu = true;
    if (newValue === 0) {
      showMenu = canModifyEvent;
    } else if (newValue === 1) {
      //past
      showMenu = false;
    } else {
      showMenu = true;
    }
    this.setState({
      showMenu: showMenu,
      value: newValue,
      resetParams: newValue, //fix for reseting page since it was carrying between states
    });
    let eventTab = "CURRENT";
    if (newValue === 0) {
      eventTab = "CURRENT";
      localStorage.removeItem("pastEvent");
    } else if (newValue === 1) {
      eventTab = "PAST";
      localStorage.setItem("pastEvent", true);
    } else if (newValue === 2) {
      eventTab = "DRAFT";
      localStorage.removeItem("pastEvent");
    }
  };

  render() {
    const { anchorEl, value, canCreateEvent, canModifyEvent } = this.state;
    const open = Boolean(anchorEl);
    const { classes } = this.props;
    const { responseData } = this.state;

    return (
      <>
        <OrganizerCommonHeader title={"Challenges"} logo />

        <div className={classes.main}>
          <Breadcrumbs />
          {responseData && responseData?.length > 0 ? (
            <Grid container spacing={4} className={classes.gridContainer}>
              {responseData?.map((data, index) => (
                <ChallengeDetailsTile
                  row={data}
                  startDate={ApplicationUtil.FormatDateToUTC(
                    data?.eventDate,
                    data?.eventTime
                  )}
                  endDate={ApplicationUtil.changeDateFormat(data?.localEndDate)}
                  key={index}
                  displayRegistration
                  onClick={() => {
                    this.props.history.push("/organizer/challenges/list");
                    localStorage.setItem("supportOpenChallengeId", data?.id);
                    localStorage.setItem("supportEventName", data?.name);
                    let side = "Participants";
                    localStorage.setItem("sidebarSelected", side);
                    let isChallenge = true;
                    localStorage.setItem("isChallenge", isChallenge);
                    localStorage.setItem(
                      "supportOpenChallengeType",
                      data?.activityType
                    );
                    localStorage.removeItem("isCreateOrDraft");
                  }}
                  onResultsClick={() => {
                    this.props.history.push("/organizer/challenges/list");
                    localStorage.setItem("supportOpenChallengeId", data?.id);
                    localStorage.setItem("supportEventName", data?.name);
                    let side = "Results";
                    localStorage.setItem("sidebarSelected", side);
                    let isChallenge = true;
                    localStorage.setItem("isChallenge", isChallenge);
                    localStorage.setItem(
                      "supportOpenChallengeType",
                      data?.activityType
                    );
                    localStorage.removeItem("isCreateOrDraft");
                  }}
                />
              ))}
            </Grid>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                // display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "80px",
              }}
            >
              <EmptyEvents />
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  marginLeft: "35px",
                  color: "gray",
                }}
              >
                No Events Available
              </p>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(OpenChallengesList);
