import React, { Component } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Properties from "../../../../../properties/Properties";
import SubmitCancelBtn from "../../../../common/SubmitCancelBtn";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import {
  userRegisteredForEvent,
  userRegisteredForProgram,
} from "../../../../../apis/eventService";
import { connect } from "react-redux";
import {
  updateUserSelectedEvent,
  updateUserPreferences,
  updateUserSelectedProgram,
} from "../../../../../redux/user";
import { withRouter } from "react-router-dom";
import MultiSelect from "../../../../common/MultiSelect";
var moment = require("moment");
const styles = (theme) => ({
  bookingFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    // border: "1px solid #718096",
    margin: "auto",
    position: "sticky",
    bottom: 0,
  },
  feeFooter: {
    width: "100%",
    margin: "auto",
    //  marginBottom:'150px',
    //  marginLeft:'50%',
    textAlign: "center",
  },
  footerClose: {
    fontSize: "18px",
    textAlign: "center",
    width: "90%",
    height: "40px",
    // display: "flex",
    // alignItems: "center",
  },
  loginContainer: {
    width: "100%",
    paddingTop: "5%",
    justifyContent: "center",
    textAlign: "center",
  },
  loginText: {
    color: "#2883B5",
    fontWeight: "bold",
    cursor: "pointer",
  },
});
class FooterBookings extends Component {
  constructor(props) {
    super(props);

    // Initializing the state
    this.state = { challengeType: null };
  }

  getRegistrationFee = (data) => {
    let feeArr = [];
    let fee;
    data.eventRunCategories.map((fee) => {
      feeArr.push(fee.registrationFee);
    });
    if (feeArr.length > 0) {
      if (feeArr.every((x) => x === 0)) {
        // console.log("free event");
        return <h2>{Properties.Label_EventDetails_Free}</h2>;
      } else {
        return (
          <>
            <p>
              <b>
                Rs.
                {Math.min(
                  ...feeArr.filter(function (x) {
                    return x > 0;
                  })
                )}
                /-{" "}
              </b>
              {feeArr?.length > 1 ? (
                <span style={{ color: "#718096" }}>
                  {Properties.Label_EventDetails_Bookings_Fee}
                </span>
              ) : (
                <span style={{ color: "#718096" }}>
                  {Properties.Label_Only}
                </span>
              )}
            </p>
            <p>{Properties.Label_EventDetails_Bookings_Inclu}</p>
          </>
        );
      }
    }
  };

  navigateToEventRegistration = (data) => {
    const { history, distKey } = this.props;
    if (data?.programStartDate) {
      this.props.updateUserSelectedProgram(data);
    } else {
      this.props.updateUserSelectedProgram({});
      this.props.updateUserSelectedEvent(data);
    }
    if (!ApplicationUtil.isuserLoggedIn()) {
      history.push(`/login?distKey=${distKey}`);
    } else {
      if (distKey !== "") {
        if (data?.programStartDate) {
          userRegisteredForProgram(data?.id).then((res) => {
            if (res?.data && res?.data?.success?.code !== "409") {
              this.props.history.push("/calender");
            } else this.props.history.push("/programRegistration");
          });
        } else {
          userRegisteredForEvent(data?.id).then((res) => {
            if (res.data.success.code === "200")
              this.props.history.push("/eventDetails");
            else this.props.history.push("/eventRegistration");
          });
        }
      }
    }
  };
  navigateToVideoGallery = (data) => {
    this.props.history.push(`/videoGallery?distKey=${data?.distKey}`);
  };

  disableGotoChallengeBtn = () => {
    /**
     * Checking if is it a program & program contains challenge & if current day is the start day of challenge & if intro video is viewed or not
     */
    if (
      window.location.href?.includes("program") &&
      this.props.isDayOneOfCurrentRunningChallenge &&
      !this.props.isVideoViewed &&
      this.props.runningProgramChallengeResData &&
      this.props.runningProgramChallengeResData?.videoLink
    ) {
      return true;
    } else return false;
  };

  getLabelForSubmitButton = () => {
    /**
     * Checking if user is registered & if is it a program & program contains challenge
     */
    if (this.props.isUserRegistered) {
      if (
        window.location.href?.includes("program")
        // &&this.props.runningProgramChallengeResData
      ) {
        return Properties.Label_Go_To_Challenge;
      } else return "View Dashboard";
    } else return Properties.Label_EventDetails_Bookings_BtnOpen;
  };

  getOptionValue = (data) => {
    if (data && data?.openSubscriptionProgramChallengeDto) {
      let formattedData = data?.openSubscriptionProgramChallengeDto?.map(
        (item) => {
          let isSameChallengeTypeOrOnGoing =
            data?.programChallenges &&
            data?.programChallenges?.some((challenge) => {
              const startDate = moment(
                challenge.challengeStartDate,
                "DD-MM-YYYY HH:mm:ss"
              );
              const endDate = moment(
                challenge.challengeEndDate,
                "DD-MM-YYYY HH:mm:ss"
              );
              // checking start date and end data should be in between current date and  same type of challenge present
              return (
                data?.programChallenges?.length &&
                moment().isSameOrBefore(endDate) &&
                item.challengeType === challenge.programChallengeType
              );
            });

          let formattedChallengeType = ApplicationUtil.capitalizeFirstLetter(
            item?.challengeType
          );
          return {
            label: `${formattedChallengeType} Challenge`,
            value: item?.challengeType,
            id: item?.id,
            isDisabled: isSameChallengeTypeOrOnGoing,
            tooltip: isSameChallengeTypeOrOnGoing
              ? "You cannot start the same challenge till the previous one ends. Please select another challenge."
              : "",
          };
        }
      );

      return formattedData;
    }
  };

  handleChallengeType = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        challengeType: selectedValues,
        // challengeTypeError: false,
      });
    } else {
      this.setState({
        challengeType: selectedValues,
        // challengeTypeError: true,
      });
    }
  };

  navigateToOpenChallenge = (data) => {
    const { history } = this.props;
    history.push(
      `/createChallenge&register?challenge=${this.state.challengeType?.label}&id=${this.state.challengeType?.id}`
    );
  };

  render() {
    const { classes, data, history, isUserRegistered } = this.props;
    const isProgram = window.location.href?.includes("program");

    return (
      <Paper className={classes.bookingFooter} elevation={5}>
        {!data?.hideFreeBlockWhenFeeZero && data?.isRegistrationOpen && (
          <div className={classes.feeFooter}>
            {this.getRegistrationFee(data)}
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            // alignItems: "center",
            width: "100%",
            padding: "20px 0",
          }}
        >
          {/* if program is openProgram then user selection of challengeType */}

          {data &&
            data?.isOpenSubscriptionProgram &&
            moment().isSameOrBefore(
              moment(data?.localEndDate, "YYYY-MM-DD")
            ) && (
              <>
                <div
                  style={{
                    display: "flex",
                    padding: "10px",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    className={classes.dropdownCss}
                    style={{ width: "175px" }}
                  >
                    <MultiSelect
                      // styles={{
                      //   placeholder: (provided) => ({
                      //     ...provided,
                      //     margin: 0,
                      //   }),
                      // }}
                      value={this.state.challengeType || ""}
                      // error={challengeTypeError}
                      placeholder={
                        <div className="mobile-footer-placeholder">
                          Select Challenge
                        </div>
                      }
                      isReadOnly={
                        this.getLabelForSubmitButton() ===
                        Properties.Label_EventDetails_Bookings_BtnOpen
                          ? true
                          : false
                      }
                      isNotAllowedCreateSameChallenge
                      isMobileNotAllowCreateChallenge
                      options={this.getOptionValue(data)}
                      onChange={this.handleChallengeType}
                    />
                  </div>
                  <div>
                    <SubmitCancelBtn
                      label={Properties.Label_Add_Challenge}
                      type="submit"
                      style={{
                        width: window?.innerWidth < 1400 ? "120px" : "160px",
                        marginRight: window?.innerWidth < 1400 ? "3px" : "10px",
                        fontSize: window?.innerWidth < 1400 ? "13px" : "16px",
                      }}
                      isDisabled={this.state.challengeType ? false : true}
                      onClick={() => {
                        this.navigateToOpenChallenge(data);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {data && data.isRegistrationOpen ? (
              <>
                {this.disableGotoChallengeBtn() ? (
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      marginTop: "5px",
                    }}
                  >
                    {Properties.video_finish_label}
                  </Typography>
                ) : (
                  <SubmitCancelBtn
                    label={this.getLabelForSubmitButton()}
                    // label={Properties.Label_Go_To_Challenge}
                    type="submit"
                    style={{
                      display:
                        data?.programChallenges?.length ||
                        data?.eventDTO?.length > 0 ||
                        data?.programSessions?.length > 0 ||
                        !this.props.isUserRegistered ||
                        !window.location.href?.includes("program")
                          ? ""
                          : "none",
                      width: window?.innerWidth < 1400 ? "140px" : "160px",
                      marginRight: window?.innerWidth < 1400 ? "3px" : "10px",
                      fontSize: window?.innerWidth < 1400 ? "13px" : "16px",
                    }}
                    isDisabled={this.disableGotoChallengeBtn()}
                    onClick={() => {
                      this.navigateToEventRegistration(data);
                    }}
                  />
                )}
                {!isUserRegistered && !ApplicationUtil.isuserLoggedIn() && (
                  <Grid
                    className={classes.loginContainer}
                    container
                    spacing={1}
                  >
                    <span style={{ color: "#999999" }}>Already Register? </span>
                    &nbsp;
                    <span
                      className={classes.loginText}
                      onClick={() => {
                        this.navigateToEventRegistration(data);
                      }}
                    >
                      Login
                    </span>
                  </Grid>
                )}
              </>
            ) : (
              isUserRegistered && (
                <>
                  {this.disableGotoChallengeBtn() ? (
                    <Typography
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        marginTop: "5px",
                      }}
                    >
                      {Properties.video_finish_label}
                    </Typography>
                  ) : (
                    <SubmitCancelBtn
                      label={this.getLabelForSubmitButton()}
                      type="submit"
                      isDisabled={this.disableGotoChallengeBtn()}
                      style={{
                        width: window?.innerWidth < 1400 ? "140px" : "160px",
                        marginRight: window?.innerWidth < 1400 ? "3px" : "10px",
                        fontSize: window?.innerWidth < 1400 ? "12px" : "16px",
                      }}
                      onClick={() => {
                        this.navigateToEventRegistration(data);
                      }}
                    />
                  )}
                </>
              )
            )}

            {data?.summaryGenerated &&
              !isProgram &&
              ApplicationUtil.isuserLoggedIn() && (
                <div
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <SubmitCancelBtn
                    label={"View Results"}
                    type="submit"
                    style={{
                      width: window?.innerWidth < 1400 ? "120px" : "160px",
                      marginRight: window?.innerWidth < 1400 ? "3px" : "10px",
                      marginBottom: "10px",
                      fontSize: window?.innerWidth < 1400 ? "13px" : "16px",
                    }}
                    onClick={() => {
                      let newData = { ...data };
                      newData.distKey = this.props?.distKey;
                      if (newData?.programStartDate) {
                        this.props.updateUserSelectedProgram(newData);
                      } else {
                        this.props.updateUserSelectedEvent(newData);
                      }

                      localStorage.removeItem("navigatedFromEventDetails");
                      localStorage.removeItem("defaultRunnerActivityType");
                      localStorage.removeItem("defaultRunnerCategoryName");
                      // history.push({ pathname: "/results", eventId: eventId });
                      // history.push("/programResults");
                      history.push(
                        newData?.programStartDate
                          ? "/programResults"
                          : "/results"
                      );
                    }}
                  />
                </div>
              )}

            {data && !data?.isRegistrationOpen && (
              <>
                {!ApplicationUtil.isuserLoggedIn() && (
                  <SubmitCancelBtn
                    label={Properties.login_label}
                    type="submit"
                    style={{ width: "160px", marginTop: "10px" }}
                    onClick={() => {
                      this.navigateToEventRegistration(data);
                    }}
                  />
                )}

                <div className={classes.footerClose}>
                  <p>
                    <b
                      style={{
                        color: "#FF0000",
                      }}
                    >
                      {Properties.Label_EventDetails_Bookings_BtnClosed}
                    </b>
                  </p>
                </div>
              </>
            )}

            {data?.allowVideoUpload && (
              <SubmitCancelBtn
                label={Properties.Label_VideoGallery}
                type="submit"
                style={{
                  marginRight: "10px",
                  padding: "10px",
                  lineHeight: "18px",
                }}
                onClick={() => {
                  this.navigateToVideoGallery(data);
                }}
              />
            )}
          </div>
        </div>
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return {
    userSelectedEvent: state.user.userSelectedEvent,
    updateUserPreferences: state.user.updateUserPreferences,
  };
}
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateUserSelectedEvent,
    updateUserSelectedProgram,
  })
)(FooterBookings);
