import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  Switch,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
  Tooltip,
  Typography,
  OutlinedInput,
} from "@material-ui/core";
import Properties from "../../../../../properties/Properties";
import { isBrowser } from "react-device-detect";
import {
  customFieldUpdate,
  getEventSettingsDetails,
  updateEventSettingsDetails,
  getPostRegistrationFields,
  getSubscribedServices,
} from "./../../../../../apis/eventService";
import SubmitCancelBtn from "../../../../common/SubmitCancelBtn";
import { withRouter } from "react-router-dom";
import MultiSelect from "../../../../common/MultiSelect";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import { CustomTextField } from "../../../../common/CustomTextField";
import DialogBox from "../../../../common/DialogBox";
import CustomFields from "./CustomFields";
import { IconCalendarBlue } from "../../../../common/SvgIcon";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import ApplicationConstants from "../../../../../constants/ApplicationConstants";
import Snackbar from "../../../../common/Snackbar";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { add } from "date-fns/esm";
import { TimerSharp } from "@material-ui/icons";
var moment = require("moment");

const styles = (theme) => ({
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },
  labelCss: {
    color: "#727272",
  },
  groupLabelCss: {
    fontSize: "20px",
    color: "rgb(8, 69, 108)",
  },
  mandatoryCss: { color: "red" },
  fieldCss: {
    border: "1px solid #08456C",
    height: "40px", //"45%",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  fieldErrorCss: {
    border: "1px solid red",
    height: "40px",
    maxHeight: "40px",
    minHeight: "40px",
    // width: isBrowser ? "360px" : "160px",
  },
  textAreafieldCss: {
    border: "1px solid #08456C",
    height: "100px",
    maxHeight: "100px",
    minHeight: "100px",
    width: isBrowser ? "360px" : "160px",
  },
  textAreaErrorCss: {
    border: "1px solid red",
    height: "100px",
    maxHeight: "100px",
    minHeight: "100px",
    width: isBrowser ? "360px" : "160px",
  },
  dropdownCss: {
    width: isBrowser ? "360px" : "160px",
  },
  // custom fields
  root: {
    marginBottom: "20px",
    paddingBottom: "20px",
    borderBottom: "1px solid rgb(8, 69, 108)",
  },
  title: {
    color: "rgb(8, 69, 108)",
  },
  tableFieldCss: {
    border: "1px solid #08456C",
    height: "40px", //"45%",
    maxHeight: "40px",
    minHeight: "40px",
  },
  chipCss: {
    border: "1px solid #08456C",
    borderRadius: "4px",
    // height: "40px", //"45%",
    padding: "2px",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  tableHead: {
    backgroundColor: "#ced4da",
    "& th": {
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
      width: "100px",
    },
  },
  tableBody: {
    "& td": {
      width: "100px",
      // textAlign: "center",
    },
  },
  fieldsContainer: {
    marginTop: "20px",
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "20px",
  },
  addIcon: {
    fontSize: "30px",
    cursor: "pointer",
    color: "rgb(8, 69, 108)",
  },
  textLink: {
    backgroundColor: "#ade8f4",
    fontWeight: "bold",
    color: "#023e8a",
    cursor: "pointer",
    borderRadius: "5px",
    width: "60px",
    margin: "auto",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&:hover": {
      color: "#03045e",
    },
  },
  moreFieldOptions: {
    padding: "8px 24px",
  },
  addButton: {
    width: "120px",
    marginLeft: "auto",
  },
  saveButton: {
    width: "120px",
  },
  option: {
    border: "1px solid #727272",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "10px",
    paddingBottom: "10px",
    // paddingRight: "",
  },
  optionIcons: {
    display: "flex",
    alignItems: "center",
  },
  optionListContainer: {
    // maxHeight: "300px",
    // overflowY: "auto",
    // overflowX: "hidden",
    marginBottom: "10px",
    paddingLeft: "10px",
  },
  topFieldAdd: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "20px",
  },
  linkText: {
    color: "rgb(8, 69, 108)",
    cursor: "pointer",
    textDecoration: "underline",
  },
  parentDivCss: {
    height: "50px",
  },
  childDivCss: {
    position: "absolute",
    width: "180px",
    maxWidth: "180px",
    minWidth: "180px",
    // zIndex: "1",
  },
});
class SettingsTabView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendInvitation: false,
      cutOffTime: null,
      cutOffTimeError: false,
      maxInvite: false,
      validateActivityDistance: false,
      eventSettings: null,
      timeValidation: false,
      activityAutoApprove: false,
      timeValidationOptionList: ApplicationConstants.timeValidationOptionList,
      computeTimeFieldList: ApplicationConstants.computeTimeFieldList,
      distanceFieldList: ApplicationConstants.distanceFieldList,
      invalidActivityCertificateTypeList:
        ApplicationConstants.invalidActivityCertificateTypeList,
      timeValidation: false,
      showDOB: false,
      showRunnerGroup: false,
      showPinCode: false,
      showPhone: false,
      showActivityDateOnForm: false,
      allowPhotoDelete: false,
      registerOnImport: false,
      registerOnFailure: false,
      computeAdvisedPace: false,
      allowShare: false,
      isPastEvent: false, ///

      autoDeliverGoodies: false,
      shippingRequired: false,
      eventSettings: null,
      additionalSettingsLabel: null,
      // custom field states
      sectionTitle: "",
      fieldTypes: [
        { label: "Text", value: "TEXT" },
        { label: "Single Select", value: "SINGLE_SELECT" },
        { label: "Multi Select", value: "MULTI_SELECT" },
        { label: "Number", value: "NUMBER" },
      ],
      optionField: "",
      openOptionsDialog: false,
      groupNames: [],
      currentIndex: 0,
      assignIndex: 0,
      fieldError: false,
      openAssignGroupDialog: false,
      openManageGroupDialog: false,
      editValueIndex: null,
      // isPastEvent,
      fieldRequestList: [
        {
          displayName: "",
          fieldType: {},
          formName: "REGISTER_EVENT",
          fieldOptionRequestList: [],
          requiredField: false,
          displayOrder: 1,
          fieldGroupRequest: {},
        },
      ],

      // activity service
      activityServices: [],
      eventActivityServices: null,
    };
    this.customFieldRef = React.createRef();
  }
  componentDidMount() {
    this.loadDetails();
  }

  loadDetails = () => {
    let getArr = [];

    let eventId = ApplicationUtil.getSupportsEventID();

    let data = ApplicationUtil.getSupportDetails();
    let pastEvent = localStorage.getItem("pastEvent");
    let setPermissions = JSON.parse(localStorage.getItem("supportPermissions"));
    let isChallengeUserPastDate = localStorage.getItem("isChallengePastDate");
    // if (pastEvent === "true" || isChallengeUserPastDate === "true") {
    //   this.setState({
    //     isPastEvent: true,
    //   });
    // } else {
    //   this.setState({
    //     isPastEvent: !setPermissions?.canModifyEvent,
    //   });
    // }

    localStorage.setItem("ActiveTab", "ONE");
    this.setState({ role: data?.role });
    getEventSettingsDetails().then((res) => {
      let timeValidation = null,
        computeTimeField = null,
        distanceField = null,
        invalidActivityCertificateType = null;

      this.state.timeValidationOptionList.map((e) => {
        if (e.value == res.data?.timeValidation) {
          timeValidation = e;
        }
      });
      this.state.computeTimeFieldList.map((e) => {
        if (e.value == res.data?.computeTimeField) {
          computeTimeField = e;
        }
      });
      this.state.distanceFieldList.map((e) => {
        if (e.value == res.data?.distanceField) {
          distanceField = e;
        }
      });
      this.state.invalidActivityCertificateTypeList.map((e) => {
        if (e.value == res.data?.invalidActivityCertificateType) {
          invalidActivityCertificateType = e;
        }
      });
      getPostRegistrationFields(eventId).then((res) => {
        this.setState({ sectionTitle: res?.data?.customSectionLabel });
        if (res?.data?.customFields) {
          res.data.customFields.map((field, index) => {
            getArr.push({
              id: field?.customFieldId,
              displayName: field?.displayName,
              formName: field?.formName,

              fieldType: {
                label:
                  field?.fieldType?.fieldTypeAttribute === "TEXT"
                    ? "Text"
                    : field?.fieldType?.fieldTypeAttribute === "SINGLE_SELECT"
                    ? "Single Select"
                    : field?.fieldType?.fieldTypeAttribute === "MULTI_SELECT"
                    ? "Multi Select"
                    : field?.fieldType?.fieldTypeAttribute === "NUMBER"
                    ? "Number"
                    : "Text",
                value: field?.fieldType?.fieldTypeAttribute,
                id: field?.fieldType?.id,
              },
              fieldOptionRequestList: field?.fieldOptions?.map((option) => {
                return {
                  id: option?.id,
                  optionValue: option?.optionValue,
                  displayOrder: option?.displayOrder,
                };
              }),
              requiredField: field?.requiredField ? field.requiredField : false,

              displayOrder: field?.displayOrder,
              fieldGroupRequest: {
                groupName: field?.fieldGroup?.groupName,
              },
            });
          });
        }

        if (res?.data?.fieldGroups) {
          let arr = [];
          res.data.fieldGroups.map((field) => {
            arr.push(field?.fieldGroup);
          });
          this.setState({ groupNames: arr });
        }

        if (getArr.length > 0) {
          getArr.sort((a, b) => (a.displayOrder < b.displayOrder ? -1 : 1));
          this.setState({ fieldRequestList: getArr });
        }
      });
      // Activity services
      getSubscribedServices().then((response) => {
        if (response.data && response.data.length > 0) {
          let activityServicesData = response.data?.map((item) => ({
            ...item,
            value: item.name,
            label: item.name,
          }));

          if (res.data?.activityServices) {
            // checking present Data
            const activityServicesArray = res.data.activityServices.split(",");
            const filterData = response.data.filter((item) =>
              activityServicesArray.includes(item.name)
            );
            // making multiselect suitable data
            const formattedData = filterData.map((item) => ({
              ...item,
              value: item.name,
              label: item.name,
            }));
            // set the predefined values to activity services
            this.setState({
              eventActivityServices: formattedData,
            });
          }

          if (activityServicesData) {
            this.setState({
              activityServices: activityServicesData,
            });
          }
        }
      });
      let eventSettingsKeys = Object.keys(res?.data?.eventSettings);
      let eventSettingsData = [];
      for (let i = 0; i < eventSettingsKeys.length; i++) {
        let obj = {
          id: i + 1,
          label: res.data?.eventSettingsLabel[eventSettingsKeys[i]],
          value: JSON.parse(res.data?.eventSettings[eventSettingsKeys[i]]),
          name: eventSettingsKeys[i],
        };
        eventSettingsData.push(obj);
      }

      this.setState({
        cutOffTime: res?.data?.cutOffTime
          ? ApplicationUtil.changeDateToLocalFormat(res?.data?.cutOffTime)
          : null,
        sendInvitation: res.data?.sendInvitation,
        maxInvites: res.data?.maxInvites,
        validateActivityDistance: res.data?.validateActivityDistance,
        eventSettings: eventSettingsData,

        activityAutoApprove: res.data?.autoApproveActivity,
        timeValidation: timeValidation,
        autoApproveActivity: res.data?.activityAutoApprove,
        showActivityDateOnForm: res.data?.showActivityDateOnForm,
        invalidActivityMaxCount: res.data?.invalidActivityMaxCount,
        computeTimeField: computeTimeField,
        distanceField: distanceField,
        invalidActivityCertificateType: invalidActivityCertificateType,
        allowPhotoDelete: res.data?.allowPhotoDelete,
        computeAdvisedPace: res.data?.computeAdvisedPace,
        showDOB: res.data?.showDOB,
        showPhone: res.data?.showPhone,
        showRunnerGroup: res.data?.showRunnerGroup,
        showPinCode: res.data?.showPinCode,
        registerOnFailure: res.data?.registerOnFailure,
        registerOnImport: res.data?.registerOnImport,
        allowShare: res.data?.allowShare,
        maxShareCount: res.data?.maxShareCount,
      });
    });
  };
  // custom fields function
  handleChangeField = (event, index) => {
    const { fieldRequestList } = this.state;
    const values = [...fieldRequestList];
    let fieldName = event.target.name;

    if (fieldName === "requiredField") {
      values[index][fieldName] = event.target.checked;
    } else {
      values[index][fieldName] = event.target.value;
    }
    this.setState({ fieldRequestList: values, fieldError: false });
  };
  handleChangeSelect = (selectedValue, index) => {
    const { fieldRequestList } = this.state;
    let values = [...fieldRequestList];
    values[index]["fieldType"] = selectedValue;
    this.setState({ fieldRequestList: values, fieldError: false });
  };
  handleEditChange = (value) => {
    const { fieldRequestList, currentIndex, editValueIndex } = this.state;
    let values = [...fieldRequestList];

    values[currentIndex]["fieldOptionRequestList"].splice(editValueIndex, 1, {
      ...values[currentIndex]["fieldOptionRequestList"][editValueIndex],
      optionValue: value,
    });
    this.setState({ fieldRequestList: values });
  };
  saveEditValue = () => {
    this.setState({ editValueIndex: null });
  };
  handleOnDragEnd = (result) => {
    const { fieldRequestList, editValueIndex, currentIndex } = this.state;
    if (!result.destination) return;
    let values = [...fieldRequestList];
    const [reorderedItem] = values[currentIndex][
      "fieldOptionRequestList"
    ]?.splice(result.source.index, 1);
    values[currentIndex]["fieldOptionRequestList"].splice(
      result.destination.index,
      0,
      reorderedItem
    );

    this.setState({ fieldRequestList: values });
  };
  handleTableOnDragEnd = (result) => {
    const { fieldRequestList } = this.state;
    if (!result.destination) return;
    let values = [...fieldRequestList];
    const [reorderedItem] = values.splice(result.source.index, 1);
    values.splice(result.destination.index, 0, reorderedItem);

    this.setState({ fieldRequestList: values });
  };
  moreFieldOptions = () => {
    const { classes } = this.props;
    const { optionField, currentIndex, editValueIndex, fieldRequestList } =
      this.state;
    let arr = [];
    return (
      <div className={classes.moreFieldOptions}>
        <div className={classes.topFieldAdd}>
          <div style={{ marginLeft: "10px" }}>
            <Typography>
              <span className={classes.labelCss}>Add Options:</span>
            </Typography>
            <CustomTextField
              value={optionField}
              className={classes.fieldCss}
              handleChange={(prop) => (e) => {
                let val = e.target.value; //.trim();
                this.setState({ optionField: val });
              }}
            />
          </div>
          <div className={classes.addButton}>
            <SubmitCancelBtn
              type="submit"
              label="Add"
              onClick={() => {
                if (optionField) {
                  let values = [...fieldRequestList];

                  values[currentIndex]["fieldOptionRequestList"] = [
                    ...values[currentIndex]["fieldOptionRequestList"],
                    {
                      optionValue: optionField,
                    },
                  ];

                  this.setState({ fieldRequestList: values });
                }

                this.setState({ optionField: "" });
              }}
            />
          </div>
        </div>
        <>
          <div style={{ marginLeft: "10px" }}>
            <Typography>
              <span className={classes.labelCss}>Current Options:</span>
            </Typography>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.saveEditValue();
            }}
          >
            <DragDropContext onDragEnd={this.handleOnDragEnd}>
              <Droppable droppableId="options">
                {(provided) => (
                  <div
                    className={classes.optionListContainer}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {fieldRequestList.length > 0 &&
                      fieldRequestList[
                        currentIndex
                      ].fieldOptionRequestList?.map((list, index) => (
                        <Draggable
                          key={list.optionValue}
                          draggableId={list.optionValue}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className={classes.option}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <div
                                style={{
                                  marginLeft: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ marginRight: "10px" }}>
                                  <DragIndicatorIcon
                                    style={{ color: "#727272" }}
                                  />
                                </span>
                                {editValueIndex !== index && (
                                  <span
                                    style={{ fontWeight: 600, color: "#000" }}
                                  >
                                    {list.optionValue}
                                  </span>
                                )}
                                {index === editValueIndex && (
                                  <input
                                    type="text"
                                    style={{
                                      height: "20px",
                                      fontSize: "18px",
                                      // marginLeft: "20px",
                                      outlineWidth: 0,
                                      outline: "none",
                                      border: "none",
                                    }}
                                    autoFocus
                                    value={list.optionValue}
                                    onChange={(e) => {
                                      let val = e.target.value;
                                      // val = val.replace(/\D/g, "");
                                      this.handleEditChange(val);
                                    }}
                                  />
                                )}
                              </div>
                              <div className={classes.optionIcons}>
                                <EditIcon
                                  style={{
                                    color: "rgb(8, 69, 108)",
                                    border: "1px solid #727272",
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    this.setState({ editValueIndex: index });
                                    // setIsEditValue(true);
                                  }}
                                />
                                <ClearIcon
                                  style={{
                                    color: "rgb(8, 69, 108)",
                                    border: "1px solid #727272",
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    let values = [...fieldRequestList];
                                    values[currentIndex][
                                      "fieldOptionRequestList"
                                    ].splice(index, 1);
                                    this.setState({ fieldRequestList: values });
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </form>
        </>
        {fieldRequestList.length > 0 &&
          fieldRequestList[currentIndex].fieldOptionRequestList?.length > 0 && (
            <div className={classes.addButton}>
              <SubmitCancelBtn
                type="submit"
                label="Save"
                onClick={() => {
                  let values = [...fieldRequestList];

                  values[currentIndex]["fieldOptionRequestList"].map(
                    (val, index) => {
                      val.displayOrder = index + 1;
                    }
                  );

                  this.setState({
                    fieldRequestList: values,
                    editValueIndex: null,
                    openOptionsDialog: false,
                  });

                  this.closeWindow();
                }}
              />
            </div>
          )}
      </div>
    );
  };
  addRow = (index) => {
    const { fieldRequestList } = this.state;
    fieldRequestList.map((field, index) => {
      return (field.fieldType = {
        id: field.fieldType.id,
        label: field.fieldType.fieldTypeAttribute
          ? field.fieldType.fieldTypeAttribute === "MULTI_SELECT"
            ? "Multi Select"
            : field.fieldType.fieldTypeAttribute === "SINGLE_SELECT"
            ? "Single Select"
            : field.fieldType.fieldTypeAttribute === "TEXT"
            ? "Text"
            : field.fieldType.fieldTypeAttribute === "NUMBER"
            ? "Number"
            : "Single Select"
          : field.fieldType.label,
        value: field.fieldType.fieldTypeAttribute
          ? field.fieldType.fieldTypeAttribute
          : field.fieldType.value,
      });
    });

    if (
      fieldRequestList[index].displayName === "" ||
      fieldRequestList.some((x) => x.displayName === "")
    ) {
      this.setState({ fieldError: true });
    } else if (
      Object.keys(fieldRequestList[index].fieldType).length === 0 ||
      fieldRequestList.some((x) => Object.keys(x.fieldType).length === 0)
    ) {
      this.setState({ fieldError: true });
    } else {
      this.setState({
        fieldRequestList: [
          ...fieldRequestList,
          {
            displayName: "",
            fieldType: "",
            formName: "REGISTER_EVENT",

            fieldOptionRequestList: [],
            requiredField: false,

            displayOrder: fieldRequestList.length + 1,
            fieldGroupRequest: {},
          },
        ],
      });
    }
  };
  saveCustomFields = () => {
    const { groupNames, sectionTitle, fieldRequestList } = this.state;
    let groupRequestList = [];

    groupNames.map((name, index) => {
      name?.id
        ? groupRequestList.push({
            id: name?.id,
            displayOrder: name?.displayOrder ? name?.displayOrder : index + 1,
            groupName: name?.groupName,
          })
        : groupRequestList.push({
            displayOrder: index + 1,
            groupName: name?.groupName,
          });
    });

    let jsonObj = {
      customSectionLabel: sectionTitle,
      fieldRequestsList: fieldRequestList,
      fieldGroupRequestsList: groupRequestList,
    };
    if (fieldRequestList.length === 1) {
      if (fieldRequestList[0].displayName === "") {
        jsonObj.fieldRequestsList = [];
        return jsonObj;
      } else {
        return jsonObj;
      }
    } else if (
      fieldRequestList.every(
        (x) => x.displayName !== "" && Object.keys(x.fieldType).length !== 0
      )
    ) {
      return jsonObj;
    } else {
      this.setState({ fieldError: true });

      fieldRequestList.map((field, index) => {
        return (field.fieldType = {
          id: field.fieldType.id,
          label: field.fieldType.fieldTypeAttribute
            ? field.fieldType.fieldTypeAttribute === "MULTI_SELECT"
              ? "Multi Select"
              : field.fieldType.fieldTypeAttribute === "SINGLE_SELECT"
              ? "Single Select"
              : field.fieldType.fieldTypeAttribute === "TEXT"
              ? "Text"
              : field.fieldType.fieldTypeAttribute === "NUMBER"
              ? "Number"
              : "Single Select"
            : field.fieldType.label,
          value: field.fieldType.fieldTypeAttribute
            ? field.fieldType.fieldTypeAttribute
            : field.fieldType.value,
        });
      });
      return "Please add data in empty fields";
    }
  };
  assignGroupNames = () => {
    const { groupNames, assignIndex, fieldRequestList } = this.state;
    return (
      <div style={{ marginTop: "10px" }}>
        {groupNames?.length > 0 ? (
          groupNames.map((list, index) => (
            <div
              style={{
                border: "1px solid #727272",
                padding: "10px",
                width: "60%",
                margin: "auto",
                cursor: "pointer",
                fontWeight: 600,
              }}
              onClick={() => {
                let values = [...fieldRequestList];
                values[assignIndex]["fieldGroupRequest"] = {
                  groupName: list?.groupName,
                };
                this.setState({ fieldRequestList: values });
                this.closeWindow();
              }}
            >
              <span
                style={{ marginLeft: "20px", fontWeight: 600, color: "#000" }}
              >
                {list?.groupName}
              </span>
            </div>
          ))
        ) : (
          <div
            style={{
              textAlign: "center",
              color: "#727272",
              fontWeight: 600,
            }}
          >
            <p>{"Groups not available"}</p>
          </div>
        )}
      </div>
    );
  };
  manageGroupNames = () => {
    const { classes } = this.props;
    const { fieldRequestList, optionField, groupNames, editValueIndex } =
      this.state;
    return (
      <div className={classes.moreFieldOptions}>
        <div className={classes.topFieldAdd}>
          <div style={{ marginLeft: "10px" }}>
            <Typography>
              <span className={classes.labelCss}>Add Options:</span>
            </Typography>
            <CustomTextField
              value={optionField}
              className={classes.fieldCss}
              handleChange={(prop) => (e) => {
                let val = e.target.value; //.trim();
                this.setState({ optionField: val });
              }}
            />
          </div>
          <div className={classes.addButton}>
            <SubmitCancelBtn
              type="submit"
              label="Add"
              onClick={() => {
                if (optionField) {
                  this.setState({
                    groupNames: [
                      ...groupNames,

                      {
                        groupName: optionField,
                      },
                    ],
                  });
                }

                this.setState({ optionField: "" });
              }}
            />
          </div>
        </div>
        <>
          <div style={{ marginLeft: "10px" }}>
            <Typography>
              <span className={classes.labelCss}>Current Options:</span>
            </Typography>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.saveEditValue();
            }}
          >
            <div className={classes.optionListContainer}>
              {groupNames?.length > 0 &&
                groupNames.map((list, index) => (
                  <div className={classes.option}>
                    {editValueIndex !== index && (
                      <span
                        style={{
                          marginLeft: "20px",
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        {list?.groupName}
                      </span>
                    )}
                    {index === editValueIndex && (
                      <input
                        type="text"
                        style={{
                          height: "20px",
                          fontSize: "18px",
                          marginLeft: "20px",
                          outlineWidth: 0,
                          outline: "none",
                          border: "none",
                        }}
                        autoFocus
                        value={list?.groupName}
                        onChange={(e) => {
                          let values = [...groupNames];
                          let fieldValues = [...fieldRequestList];

                          fieldValues.map((field) => {
                            if (
                              field?.fieldGroupRequest?.groupName ===
                              values[index]?.groupName
                            ) {
                              field.fieldGroupRequest.groupName =
                                e.target.value;
                            }
                          });

                          values[index].groupName = e.target.value;
                          this.setState({
                            fieldRequestList: fieldValues,
                            groupNames: values,
                          });
                        }}
                      />
                    )}
                    <div className={classes.optionIcons}>
                      <EditIcon
                        style={{
                          color: "rgb(8, 69, 108)",
                          border: "1px solid #727272",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.setState({ editValueIndex: index });
                          // setIsEditValue(true);
                        }}
                      />
                      <ClearIcon
                        style={{
                          color: "rgb(8, 69, 108)",
                          border: "1px solid #727272",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          let values = [...groupNames];
                          let fieldValues = [...fieldRequestList];

                          fieldValues.map((field) => {
                            if (
                              field?.fieldGroupRequest?.groupName ===
                              values[index]?.groupName
                            ) {
                              field.fieldGroupRequest = {};
                            }
                          });
                          values.splice(index, 1);
                          this.setState({
                            fieldRequestList: fieldValues,
                            groupNames: values,
                          });
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </form>
        </>
        {groupNames?.length > 0 && (
          <div className={classes.addButton}>
            <SubmitCancelBtn
              type="submit"
              label="Save"
              onClick={() => {
                let values = [...groupNames];
                values.map((val, index) => {
                  val.displayOrder = index + 1;
                });
                this.setState({
                  groupNames: values,
                  editValueIndex: null,
                  openManageGroupDialog: false,
                });

                this.closeWindow();
              }}
            />
          </div>
        )}
      </div>
    );
  };
  updateCustomFields = (param) => {
    if (param === "refresh") {
      this.loadDetails();
    } else {
      return this.saveCustomFields();
    }
  };
  // -----------x---------
  handleCancel() {
    this.setState({ showCancelDialog: true });
  }
  closeWindow = () => {
    this.setState({
      showCancelDialog: false,
      openOptionsDialog: false,
      openManageGroupDialog: false,
      openAssignGroupDialog: false,
    });
  };
  getDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.CreateEvent_CancelClickWarning}</span>
        </Typography>
      </div>
    );
  };
  closeWindow = () => {
    this.setState({
      showCancelDialog: false,
    });
  };

  handleActivityDelete = () => {
    //api for delete activity
  };
  closeDialogBox = () => {
    this.setState({ showCancelDialog: false });
    this.loadDetails();
    // this.customFieldRef.current.updateCustomFields("refresh");
    this.updateCustomFields("refresh");
  };
  validateFields() {
    this.saveFields();
  }

  saveFields() {
    const {
      sendInvitation,
      maxInvites,
      validateActivityDistance,
      eventSettings,

      timeValidation,
      activityAutoApprove,
      timeValidationOptionList,
      showDOB,
      showRunnerGroup,
      showPinCode,
      showPhone,
      showActivityDateOnForm,
      allowPhotoDelete,
      registerOnImport,
      registerOnFailure,
      computeAdvisedPace,
      allowShare,
      maxShareCount,
      computeTimeField,
      invalidActivityCertificateType,
      distanceField,
      invalidActivityMaxCount,
      cutOffTime,
      eventActivityServices,
      shippingRequired,
      allowReferral,
      allowVolunteerParticipation,
    } = this.state;

    let additionalSettingsObject = {};
    for (let i = 0; i < this.state?.eventSettings.length; i++) {
      additionalSettingsObject[
        this.state?.eventSettings[i].name
      ] = `${this.state?.eventSettings[i].value}`;
    }



    let customeParams = {
      cutOffTime:
        moment(cutOffTime).format("DD-MM-YYYY") +
        " " +
        moment(cutOffTime).format("HH:mm:ss"),
      computeTimeField: computeTimeField?.value,
      invalidActivityCertificateType: invalidActivityCertificateType?.value,
      distanceField: distanceField?.value,
      invalidActivityMaxCount: invalidActivityMaxCount,
      autoApproveActivity: activityAutoApprove,
      sendInvitation: sendInvitation,
      timeValidation: timeValidation?.value,
      maxInvites: maxInvites,
      eventSettings: additionalSettingsObject,
      validateActivityDistance: validateActivityDistance,

      showDOB: showDOB,
      showRunnerGroup: showRunnerGroup,
      showPinCode: showPinCode,
      showPhone: showPhone,
      showActivityDateOnForm: showActivityDateOnForm,
      allowPhotoDelete: allowPhotoDelete,
      registerOnImport: registerOnImport,
      registerOnFailure: registerOnFailure,
      computeAdvisedPace: computeAdvisedPace,
      allowShare: allowShare,
      maxShareCount: maxShareCount,
      activityServices: eventActivityServices
        ?.map((item) => item.label)
        .join(","),
      shippingRequired: shippingRequired,
    };

   if(eventActivityServices?.length <= 0){
    delete customeParams.activityServices
   }
    

    // if (isCustomSuccess === "success") {
    updateEventSettingsDetails(customeParams).then((res) => {
      this.props.onSuccess();
      Snackbar.show({
        variant: "success",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Event settings updated successfully",
      });

      //this.props.history.push("/organizer/eventlist");
      //  Snackbar.show({
      //   variant: "success",
      //   isAutoHide: true,
      //   preventDuplicate: true,
      //   message: "Event settings updated succefully",
      // });
    });
    // } else {
    //   Snackbar.show({
    //     variant: "error",
    //     isAutoHide: true,
    //     preventDuplicate: true,
    //     message: "Custom Fields not saved properly",
    //   });
    // }
  }

  handleTimeValidationChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        timeValidation: selectedValues,
      });
    }
  };
  handleTimeValidationChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        timeValidation: selectedValues,
      });
    }
  };
  handleComputeTimeFieldChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        computeTimeField: selectedValues,
      });
    }
  };
  handleActivityCertificateTypeChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        invalidActivityCertificateType: selectedValues,
      });
    }
  };

  // activity services
  handleActivityServices = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      selectedValues.forEach((element) => {
        arr.push(element);
      });
      this.setState({
        eventActivityServices: arr,
      });
    } else {
      this.setState({
        eventActivityServices: arr,
      });
    }
  };

  handleDistanceFieldChange = (selectedValues) => {
    this.setState({ distanceField: selectedValues });
  };
  handleChecked = (val, item) => {
    let findIndex = this.state.eventSettings.findIndex((x) => {
      return x.id === item.id;
    });

    let array = this.state.eventSettings;
    array[findIndex].value = val.target.checked;
    this.setState({ eventSettings: array });
  };
  render() {
    const { classes } = this.props;
    const {
      role,
      cutOffTime,
      cutOffTimeError,
      sendInvitation,
      distanceField,
      distanceFieldList,
      maxInvites,
      validateActivityDistance,
      eventSettings,
      additionalSettingsLabel,
      activityAutoApprove,
      timeValidation,
      timeValidationOptionList,
      showDOB,
      showRunnerGroup,
      showPinCode,
      showPhone,
      showActivityDateOnForm,
      allowPhotoDelete,
      registerOnImport,
      registerOnFailure,
      computeAdvisedPace,
      allowShare,
      maxShareCount,
      computeTimeField,
      computeTimeFieldList,
      invalidActivityCertificateType,
      invalidActivityCertificateTypeList,
      invalidActivityMaxCount,
      isPastEvent,
      analyticsGenerated,
      autoDeliverGoodies,
      shippingRequired,
      sectionTitle,
      fieldRequestList,
      fieldTypes,
      openOptionsDialog,
      openManageGroupDialog,
      openAssignGroupDialog,
      fieldError,
      activityServices,
      eventActivityServices,
    } = this.state;
    return (
      <div>
        <Grid
          container
          spacing={1}
          style={{
            padding: "10px 5px",
            borderBottom: "1px solid rgb(8,69,108)",
          }}
        >
          <Grid item xs={6}>
            <span className={classes.labelCss}>
              {"Register Friends/Family"}
            </span>
            <Switch
              color="primary"
              checked={sendInvitation}
              onChange={(e) => {
                this.setState({
                  sendInvitation: e.target.checked,
                });
              }}
              disabled={isPastEvent}
            />
          </Grid>
          {sendInvitation && (
            <Grid item xs={6}>
              <Typography variant="body2" gutterBottom>
                <span className={classes.labelCss}>
                  {Properties.MaxInviteLabel}
                </span>
              </Typography>
              <CustomTextField
                value={maxInvites}
                type="number"
                className={classes.fieldCss}
                handleChange={(prop) => (e) => {
                  this.setState({
                    maxInvites: e.target.value,
                  });
                }}
                readOnly={isPastEvent}
              />
            </Grid>
          )}

          <Grid item xs={6} style={{ padding: "4px 0px" }}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.labelCss}>Proof of Activity</span>
            </Typography>
            <div className={classes.dropdownCss}>
              <MultiSelect
                value={timeValidation}
                // error={eventOrganiserError}
                options={timeValidationOptionList}
                onChange={this.handleTimeValidationChange}
                isReadOnly={isPastEvent}
              />
            </div>
          </Grid>
        </Grid>
        {/* Custom Section */}
        {/* <CustomFields
          ref={this.customFieldRef}
          pastEvent={this.state.isPastEvent}
        /> */}
        <div className={classes.root}>
          <h3 className={classes.title}>Custom Section</h3>
          <Grid container>
            <Grid item xs={6}>
              <Typography>
                <span className={classes.labelCss}>Section Title</span>
              </Typography>
              <CustomTextField
                className={classes.fieldCss}
                type="text"
                value={sectionTitle}
                handleChange={(prop) => (e) =>
                  this.setState({ sectionTitle: e.target.value })}
                readOnly={isPastEvent}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <h3
                  className={classes.linkText}
                  onClick={() => {
                    if (!isPastEvent)
                      this.setState({ openManageGroupDialog: true });
                  }}
                >
                  Manage Field Group
                </h3>
              </Typography>
            </Grid>
          </Grid>
          {/* table + add row */}
          <div className={classes.fieldsContainer}>
            {/* fields table */}
            {fieldRequestList?.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow className={classes.tableHead}>
                    <TableCell component="th">Field Name</TableCell>
                    <TableCell
                      component="th"
                      style={
                        {
                          // maxWidth: "180px",
                          // minWidth: "180px",
                          // width: "180px",
                        }
                      }
                    >
                      Field Type
                    </TableCell>
                    <TableCell component="th">Field Options</TableCell>
                    <TableCell component="th">Required</TableCell>

                    <TableCell component="th">Assign Group</TableCell>
                    {/* <TableCell component="th"></TableCell> */}
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={this.handleTableOnDragEnd}>
                  <Droppable droppableId="fields">
                    {(provided) => (
                      <TableBody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {fieldRequestList?.map((list, index) => (
                          <Draggable
                            key={list?.displayOrder.toString()}
                            draggableId={list?.displayOrder.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <TableRow
                                className={classes.tableBody}
                                key={list.displayOrder}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <TableCell component="td">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span style={{ marginRight: "10px" }}>
                                      <DragIndicatorIcon
                                        style={{ color: "#727272" }}
                                      />
                                    </span>
                                    <CustomTextField
                                      type="text"
                                      name="displayName"
                                      className={
                                        list.displayName === "" && fieldError
                                          ? classes.fieldErrorCss
                                          : classes.tableFieldCss
                                      }
                                      value={list?.displayName}
                                      handleChange={(prop) => (e) => {
                                        this.handleChangeField(e, index);
                                      }}
                                      readOnly={isPastEvent}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  component="td"
                                  style={{
                                    whiteSpace: "nowrap",

                                    // right: "10px",
                                  }}
                                >
                                  <div
                                    className={classes.parentDivCss}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className={classes.childDivCss}>
                                      <MultiSelect
                                        error={
                                          Object.keys(list.fieldType).length ===
                                            0 && fieldError
                                        }
                                        options={fieldTypes}
                                        value={list.fieldType}
                                        onChange={(e) =>
                                          this.handleChangeSelect(e, index)
                                        }
                                        isReadOnly={isPastEvent}
                                      />
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell component="td" align="center">
                                  {list?.fieldType?.label === "Single Select" ||
                                  list?.fieldType?.label === "Multi Select" ? (
                                    <span
                                      className={classes.textLink}
                                      onClick={() => {
                                        if (!isPastEvent) {
                                          this.setState({
                                            openOptionsDialog: true,
                                            currentIndex: index,
                                          });
                                        }
                                      }}
                                    >
                                      {list?.fieldOptionRequestList?.length > 0
                                        ? "Edit"
                                        : "Add"}
                                    </span>
                                  ) : (
                                    <span>{"-"}</span>
                                  )}
                                </TableCell>
                                <TableCell component="td" align="center">
                                  <Checkbox
                                    name="requiredField"
                                    checked={list?.requiredField}
                                    onChange={(e) =>
                                      this.handleChangeField(e, index)
                                    }
                                    disabled={isPastEvent}
                                  />
                                </TableCell>

                                <TableCell component="td" align="center">
                                  <Typography variant="body2">
                                    {list?.fieldGroupRequest?.groupName ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-end",
                                          justifyContent: "space-between",
                                          backgroundColor: "#D3D3D3	",
                                          borderRadius: "10px",
                                          width: "80%",
                                          margin: "auto",
                                          padding: "5px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontWeight: 600,
                                            flex: 1,
                                            textAlign: "center",
                                          }}
                                        >
                                          {list.fieldGroupRequest.groupName}
                                        </span>

                                        <ClearIcon
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "25px",
                                          }}
                                          onClick={() => {
                                            let values = [...fieldRequestList];
                                            values[index]["fieldGroupRequest"] =
                                              {};

                                            this.setState({
                                              fieldRequestList: values,
                                            });
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <span
                                        className={classes.linkText}
                                        onClick={() => {
                                          if (!isPastEvent) {
                                            this.setState({
                                              openAssignGroupDialog: true,
                                              assignIndex: index,
                                            });
                                          }
                                        }}
                                      >
                                        <b>Assign Group</b>
                                      </span>
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="td"
                                  align="center"
                                  style={{ width: "50px" }}
                                >
                                  <div>
                                    {index === fieldRequestList?.length - 1 && (
                                      <Tooltip title="Add field">
                                        <AddCircleIcon
                                          onClick={() => {
                                            if (!isPastEvent) {
                                              this.addRow(index);
                                            }
                                          }}
                                          className={classes.addIcon}
                                        />
                                      </Tooltip>
                                    )}
                                    <Tooltip title="Delete field">
                                      <DeleteForeverIcon
                                        onClick={() => {
                                          if (!isPastEvent) {
                                            let values = [...fieldRequestList];
                                            values.splice(index, 1);
                                            this.setState({
                                              fieldRequestList: values,
                                            });
                                          }
                                        }}
                                        className={classes.addIcon}
                                      />
                                    </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            ) : (
              <div style={{ width: "30%" }}>
                <SubmitCancelBtn
                  type="submit"
                  label="Add Field"
                  onClick={() => {
                    this.setState({
                      fieldRequestList: [
                        {
                          displayName: "",
                          fieldType: "",
                          formName: "REGISTER_EVENT",

                          fieldOptionRequestList: [],
                          requiredField: false,

                          displayOrder: fieldRequestList.length + 1,
                          fieldGroupRequest: {},
                        },
                      ],
                    });
                  }}
                />
              </div>
            )}
          </div>

          {/* field option dialog */}
          <DialogBox
            fullWidth
            maxWidth={"sm"}
            open={openOptionsDialog}
            header={"Field Options"}
            message={this.moreFieldOptions()}
            onClick={() => this.setState({ openOptionsDialog: false })}
            closeDisloag={() => this.setState({ openOptionsDialog: false })}
          />
          {/* manage group dialog */}
          <DialogBox
            fullWidth
            maxWidth={"sm"}
            open={openManageGroupDialog}
            header={"Manage Field Group"}
            message={this.manageGroupNames()}
            onClick={() => this.setState({ openManageGroupDialog: false })}
            closeDisloag={() => this.setState({ openManageGroupDialog: false })}
          />
          {/* assign group dialog */}
          <DialogBox
            fullWidth
            maxWidth={"sm"}
            open={openAssignGroupDialog}
            header={"Assign Group"}
            message={this.assignGroupNames()}
            onClick={() => this.setState({ openAssignGroupDialog: false })}
            closeDisloag={() => this.closeWindow()}
          />
        </div>

        {/* support fields */}
        {role == "SUPPORT" && (
          <Grid
            container
            spacing={1}
            style={{
              padding: "10px 5px",
              borderBottom: "1px solid rgb(8,69,108)",
            }}
          >
            <Grid container style={{ paddingBottom: "5px" }}>
              <Grid item xs={6}>
                <span className={classes.labelCss}>
                  {Properties.ValidateActivityDistanceLabel}
                </span>

                <Switch
                  color="primary"
                  checked={validateActivityDistance}
                  onChange={(e) => {
                    this.setState({
                      validateActivityDistance: e.target.checked,
                    });
                  }}
                  disabled={isPastEvent}
                />
              </Grid>

              <Grid item xs={6}>
                <span className={classes.labelCss}>
                  {Properties.ActivityAutoApproveLabel}
                </span>
                <Switch
                  color="primary"
                  checked={activityAutoApprove}
                  onChange={(e) => {
                    this.setState({
                      activityAutoApprove: e.target.checked,
                    });
                  }}
                  disabled={isPastEvent}
                />
              </Grid>

              <Grid item xs={6}>
                <span className={classes.labelCss}>Allow Photo Delete </span>

                <Switch
                  checked={allowPhotoDelete}
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      allowPhotoDelete: e.target.checked,
                    });
                  }}
                  disabled={isPastEvent}
                />
              </Grid>

              <Grid item xs={6}>
                <span className={classes.labelCss}>Compute Advised Pace </span>

                <Switch
                  checked={computeAdvisedPace}
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      computeAdvisedPace: e.target.checked,
                    });
                  }}
                  disabled={isPastEvent}
                />
              </Grid>

              <Grid item xs={6}>
                <span className={classes.labelCss}>
                  Register on Failure{" "}
                  {/* {Properties.AllowActivityUploadLabel} */}
                </span>
                <Switch
                  checked={registerOnFailure}
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      registerOnFailure: e.target.checked,
                    });
                  }}
                  disabled={isPastEvent}
                />
              </Grid>

              <Grid item xs={6}>
                <span className={classes.labelCss}>
                  Register on import{" "}
                  {/* {Properties.AllowActivityUploadLabel} */}
                </span>
                <Switch
                  checked={registerOnImport}
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      registerOnImport: e.target.checked,
                    });
                  }}
                  disabled={isPastEvent}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" gutterBottom>
                  <span className={classes.labelCss}>
                    Cut off Time {/* {Properties.EventDateTimeLabel} */}
                  </span>
                  {/* <span className={classes.mandatoryCss}>*</span> */}
                </Typography>

                <div>
                  <KeyboardDateTimePicker
                    style={{
                      width: isBrowser ? "360px" : "160px",
                    }}
                    autoOk={false}
                    variant="inline"
                    inputVariant="outlined"
                    // ampm={false}
                    format="dd/MM/yyyy hh:mm a" //:ss-hh" //"dd/MM/yyyy"
                    // disableFuture={true}
                    value={cutOffTime}
                    className={classes.fieldWidth}
                    InputProps={{
                      classes: {
                        root: cutOffTimeError
                          ? classes.datePickerError
                          : classes.datePicker,
                      },
                    }}
                    keyboardIcon={
                      <IconCalendarBlue className={classes.calendarIconCss} />
                    }
                    onChange={(date) => {
                      this.setState({
                        cutOffTime: date ? date : null,
                        cutOffTimeError: false,
                      });
                    }}
                    readOnly={isPastEvent}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" gutterBottom>
                  <span className={classes.labelCss}>
                    Invalid Activity Max Count{" "}
                    {/* {Properties.MaxInviteLabel} */}
                  </span>
                </Typography>
                <CustomTextField
                  value={invalidActivityMaxCount}
                  type="number"
                  className={
                    // max ? classes.fieldErrorCss :
                    classes.fieldCss
                  }
                  handleChange={(prop) => (e) => {
                    this.setState({
                      invalidActivityMaxCount: e.target.value,
                      // maxShareCountError: false,
                    });
                  }}
                  readOnly={isPastEvent}
                />
              </Grid>

              <Grid item xs={6} style={{ padding: "4px 0px" }}>
                <Typography variant="body2" gutterBottom>
                  <span className={classes.labelCss}>Compute Time Field</span>
                </Typography>
                <div className={classes.dropdownCss}>
                  <MultiSelect
                    value={computeTimeField}
                    // error={eventOrganiserError}
                    options={computeTimeFieldList}
                    onChange={this.handleComputeTimeFieldChange}
                    isReadOnly={isPastEvent}
                  />
                </div>
              </Grid>

              <Grid item xs={6} style={{ padding: "4px 0px" }}>
                <Typography variant="body2" gutterBottom>
                  <span className={classes.labelCss}>Distance Field</span>
                </Typography>
                <div className={classes.dropdownCss}>
                  <MultiSelect
                    value={distanceField}
                    // error={eventOrganiserError}
                    options={distanceFieldList}
                    onChange={this.handleDistanceFieldChange}
                    isReadOnly={isPastEvent}
                  />
                </div>
              </Grid>

              <Grid item xs={6} style={{ padding: "4px 0px" }}>
                <Typography variant="body2" gutterBottom>
                  <span className={classes.labelCss}>
                    Invalid Activity Certificate Type
                  </span>
                </Typography>
                <div className={classes.dropdownCss}>
                  <MultiSelect
                    value={invalidActivityCertificateType}
                    // error={eventOrganiserError}
                    options={invalidActivityCertificateTypeList}
                    onChange={this.handleActivityCertificateTypeChange}
                    isReadOnly={isPastEvent}
                  />
                </div>
              </Grid>
              {/* concent code added here */}
              <Grid item xs={6} style={{ padding: "4px 0px" }}>
                <Typography variant="body2" gutterBottom>
                  <span className={classes.labelCss}>Activity Services</span>
                </Typography>
                <div className={classes.dropdownCss}>
                  <MultiSelect
                    isMulti={true}
                    value={eventActivityServices}
                    // error={eventOrganiserError}
                    options={activityServices}
                    onChange={this.handleActivityServices}
                    isReadOnly={isPastEvent}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container style={{ paddingBottom: "5px" }}>
              <Grid item xs={6} style={{ padding: "4px 0px" }}>
                <span className={classes.labelCss}>
                  Shipping Address Required
                </span>

                <Switch
                  checked={shippingRequired}
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      shippingRequired: e.target.checked,
                    });
                  }}
                  disabled={isPastEvent}
                />
              </Grid>
              <Grid item xs={6} style={{ padding: "4px 0px" }}>
                <span className={classes.labelCss}>Generate Goodies Auto </span>

                <Switch
                  checked={autoDeliverGoodies}
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      autoDeliverGoodies: e.target.checked,
                    });
                  }}
                  disabled={isPastEvent}
                />
              </Grid>
            </Grid>
            <Grid container style={{ paddingBottom: "5px" }}>
              <Typography variant="body2" gutterBottom>
                <span className={classes.groupLabelCss}>Signup Form</span>
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  <span className={classes.labelCss}>
                    Show Phone {/* {Properties.AllowActivityUploadLabel} */}
                  </span>
                  <Switch
                    checked={showPhone}
                    color="primary"
                    onChange={(e) => {
                      this.setState({
                        showPhone: e.target.checked,
                      });
                    }}
                    disabled={isPastEvent}
                  />
                </Grid>

                <Grid item xs={6}>
                  <span className={classes.labelCss}>Show Date of Birth </span>

                  <Switch
                    checked={showDOB}
                    color="primary"
                    onChange={(e) => {
                      this.setState({
                        showDOB: e.target.checked,
                      });
                    }}
                    disabled={isPastEvent}
                  />
                </Grid>
                <Grid item xs={6}>
                  <span className={classes.labelCss}>
                    Show Runner Group{" "}
                    {/* {Properties.AllowActivityUploadLabel} */}
                  </span>
                  <Switch
                    checked={showRunnerGroup}
                    color="primary"
                    onChange={(e) => {
                      this.setState({
                        showRunnerGroup: e.target.checked,
                      });
                    }}
                    disabled={isPastEvent}
                  />
                </Grid>

                <Grid item xs={6}>
                  <span className={classes.labelCss}>
                    Show PinCode
                    {/* {Properties.AllowActivityUploadLabel} */}
                  </span>
                  <Switch
                    checked={showPinCode}
                    color="primary"
                    onChange={(e) => {
                      this.setState({
                        showPinCode: e.target.checked,
                      });
                    }}
                    disabled={isPastEvent}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Typography variant="body2" gutterBottom>
              <span className={classes.groupLabelCss}>
                Upload Activity Form
              </span>
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <span className={classes.labelCss}>
                  Show Activity Date on Form{" "}
                  {/* {Properties.AllowActivityUploadLabel} */}
                </span>
                <Switch
                  checked={showActivityDateOnForm}
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      showActivityDateOnForm: e.target.checked,
                    });
                  }}
                  disabled={isPastEvent}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <div className={classes.root}>
          <h3 className={classes.title}>Additional Settings</h3>

          <Grid container>
            {eventSettings?.map((item, key) => {
              return (
                <>
                  {" "}
                  <Grid item xs={6}>
                    <span className={classes.labelCss}>{item.label}</span>
                    <Switch
                      color="primary"
                      checked={item.value}
                      onChange={(value) => this.handleChecked(value, item)}
                      disabled={isPastEvent}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
        </div>
        {!isPastEvent && (
          <Grid
            container
            style={{
              margin: "10px 5px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Grid item xs={4}>
              <SubmitCancelBtn
                onClick={(e) => this.handleCancel()}
                label={"Cancel"}
                type="submit"
                style={{ width: "200px" }}
              />
            </Grid>
            <Grid item xs={4}>
              <SubmitCancelBtn
                onClick={(e) => {
                  let customJsonObj = this.updateCustomFields();
                  // this.customFieldRef.current.updateCustomFields();

                  if (typeof customJsonObj === "object") {
                    customJsonObj.fieldRequestsList.map((field, index) => {
                      if (field.fieldType.label) {
                        return (
                          (field.fieldType = {
                            id:
                              field.fieldType.label === "Text"
                                ? 1
                                : field.fieldType.label === "Single Select"
                                ? 2
                                : field.fieldType.label === "Multi Select"
                                ? 3
                                : field.fieldType.label === "Number"
                                ? 4
                                : "",
                            fieldTypeAttribute: field.fieldType.value,
                          }),
                          (field.displayOrder = index + 1)
                        );
                      }
                    });

                    console.log(customJsonObj, "cutom");

                    customFieldUpdate(customJsonObj)
                      .then((res) => {
                        this.validateFields();
                        customJsonObj.fieldRequestsList.map((field, index) => {
                          return (field.fieldType = {
                            id: field.fieldType.id,
                            label: field.fieldType.fieldTypeAttribute
                              ? field.fieldType.fieldTypeAttribute ===
                                "MULTI_SELECT"
                                ? "Multi Select"
                                : field.fieldType.fieldTypeAttribute ===
                                  "SINGLE_SELECT"
                                ? "Single Select"
                                : field.fieldType.fieldTypeAttribute === "TEXT"
                                ? "Text"
                                : field.fieldType.fieldTypeAttribute ===
                                  "NUMBER"
                                ? "Number"
                                : "Single Select"
                              : field.fieldType.label,
                            value: field.fieldType.fieldTypeAttribute
                              ? field.fieldType.fieldTypeAttribute
                              : field.fieldType.value,
                          });
                        });
                      })
                      .catch((err) => {
                        customJsonObj.fieldRequestsList.map((field, index) => {
                          return (field.fieldType = {
                            id: field.fieldType.id,
                            label: field.fieldType.fieldTypeAttribute
                              ? field.fieldType.fieldTypeAttribute ===
                                "MULTI_SELECT"
                                ? "Multi Select"
                                : field.fieldType.fieldTypeAttribute ===
                                  "SINGLE_SELECT"
                                ? "Single Select"
                                : field.fieldType.fieldTypeAttribute === "TEXT"
                                ? "Text"
                                : field.fieldType.fieldTypeAttribute ===
                                  "NUMBER"
                                ? "Number"
                                : "Single Select"
                              : field.fieldType.label,
                            value: field.fieldType.fieldTypeAttribute
                              ? field.fieldType.fieldTypeAttribute
                              : field.fieldType.value,
                          });
                        });
                      });
                  } else {
                    Snackbar.show({
                      variant: "error",
                      isAutoHide: true,
                      preventDuplicate: true,
                      message: customJsonObj,
                    });
                  }
                }}
                label={"Update"}
                type="submit"
                style={{ width: "200px" }}
              />
            </Grid>
          </Grid>
        )}

        {/* cancel dialog */}
        <DialogBox
          // signup={true}
          open={this.state.showCancelDialog}
          message={this.getDialogMsg()}
          header={this.state.dialogHeader}
          label={"No"}
          onClick={() => {
            this.closeWindow();
          }}
          labelTwo={"Yes"}
          onClickTwo={() => {
            this.handleActivityDelete();
          }}
          closeDisloag={() => this.closeWindow()}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(SettingsTabView);
