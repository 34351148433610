import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Tabs } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import Properties from "../../../../../properties/Properties";
import BasicTabView from "./BasicTabView";
import SettingsTabView from "./SettingsTabView";
import UpdateImagesTabView from "./UpdateImagesTabView";
import DeliverableTabView from "./DeliverableTabView";
import BasicEditView from "./BasicEditView";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";

const styles = (theme) => ({
  textTransformCss: {
    textTransform: "none",
    fontSize: "22px",
    fontWeight: "bold",
  },
});
class CreateEventView extends Component {
  constructor(props) {
    super(props);
    let activeTab = localStorage.getItem("ActiveTab");
    let activeTabIndex = 0;
    if (activeTab === "ZERO") {
      activeTabIndex = 0;
    } else if (activeTab === "ONE") {
      activeTabIndex = 1;
    }
    if (activeTab === "TWO") {
      activeTabIndex = 2;
    } else if (activeTab === "THREE") {
      activeTabIndex = 3;
    }
    this.state = {
      ActiveTabIndex: activeTabIndex,
    };
  }
  componentDidMount() {
    let data = ApplicationUtil.getSupportDetails();
    let eventId = ApplicationUtil.getSupportsEventID();
    let isEdit = localStorage.getItem("editView");
    let tabsDisabled = false;
    if (eventId == null || eventId == undefined) tabsDisabled = true;

    let activeTab = localStorage.getItem("ActiveTab");
    let activeTabIndex = 0;
    if (activeTab === "ZERO") {
      activeTabIndex = 0;
    } else if (activeTab === "ONE") {
      activeTabIndex = 1;
    }
    if (activeTab === "TWO") {
      activeTabIndex = 2;
    } else if (activeTab === "THREE") {
      activeTabIndex = 3;
    }
    this.setState({
      ActiveTabIndex: activeTabIndex,
      isEdit: isEdit,
      role: data?.role,
      tabsDisabled: tabsDisabled,
    });
  }

  handleTabChange = (event, newValue) => {
    let isEdit = localStorage.getItem("editView");

    this.setState({ ActiveTabIndex: newValue, isEdit: isEdit });
  };
  render() {
    const { classes } = this.props;
    const { ActiveTabIndex, role, tabsDisabled, isEdit } = this.state;

    return (
      <div className={classes.summary}>
        <Tabs
          value={ActiveTabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleTabChange}
          variant={isMobile ? "scrollable" : "fullWidth"}
          scrollButtons={isMobile ? "on" : "off"}
        >
          <Tab
            value={0}
            label={Properties.BasicTabLabel}
            className={classes.textTransformCss}
          />
          <Tab
            value={1}
            label={Properties.SettingsTabLabel}
            className={classes.textTransformCss}
            disabled={tabsDisabled}
          />
          <Tab
            value={2}
            label={"Images"}
            className={classes.textTransformCss}
            disabled={tabsDisabled}
          />
          {role == "SUPPORT" && (
            <Tab
              value={3}
              label={Properties.DeliverableTabLabel}
              className={classes.textTransformCss}
              disabled={tabsDisabled}
            />
          )}
        </Tabs>

        <div className={classes.root}>
          {ActiveTabIndex == 0 && (
            <>
              {isEdit === "true" && (
                <BasicEditView
                  onSuccess={(e) => {
                    this.handleTabChange(e, 1);
                  }}
                />
              )}
              {isEdit === "false" && (
                <BasicTabView
                  onSuccess={(e) => {
                    this.handleTabChange(e, 1);
                  }}
                  changeTabStatus={() => {
                    this.setState({ tabsDisabled: false });
                  }}
                />
              )}
            </>
          )}

          {ActiveTabIndex == 1 && (
            <SettingsTabView
              onSuccess={(e) => {
                this.handleTabChange(e, 2);
              }}
            />
          )}

          {ActiveTabIndex == 2 && (
            <UpdateImagesTabView
              onSuccess={(e) => {
                this.handleTabChange(e, 3);
              }}
            />
          )}
          {role == "SUPPORT" && ActiveTabIndex == 3 && (
            <DeliverableTabView
              onSuccess={(e) => {
                this.handleTabChange(e, 4);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(CreateEventView);
