/*global google*/

import React, { Component } from "react";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import GoogleMap from "../../../../../common/GoogleMap/GoogleMap";
import MenuIcon from "@material-ui/icons/Menu";
import StarIcon from "../../../../../../images/star.gif";
import StartIcon from "../../../../../../images/start.jpg";
import markerIcon from "../../../../../../images/marker.gif";
import markerImgIcon from "../../../../../../images/marker.jpg";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";

const styles = (theme) => ({});
class RouteMap extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  componentDidMount() {
    this.setState({
      flightPlanCoordinates: this.props.flightPlanCoordinates,
      categoryDTO: this.props.categoryDTO,
      features: this.props.features,
      defaultCenter: this.props.defaultCenter,
      zoomLevel: this.props.zoomLevel,
      eventType: this.props.eventType,
      zoom: this.props.zoom,
      center: this.props.center,
      isAllParticipants: this.props.isAllParticipants,
      // mapToShow:this.props.mapToShow
    });
  }

  componentDidUpdate(previousProps) {
    if (
      this.props.flightPlanCoordinates !==
        previousProps.flightPlanCoordinates ||
      this.props.categoryDTO?.id !== previousProps.categoryDTO?.id ||
      this.props.features !== previousProps.features ||
      this.props.defaultCenter?.lat !== previousProps.defaultCenter?.lat ||
      this.props.defaultCenter?.lng !== previousProps.defaultCenter?.lng ||
      this.props.zoomLevel !== previousProps.zoomLevel ||
      this.props.eventType !== previousProps.eventType ||
      this.props.isAllParticipants !== previousProps.isAllParticipants
      // this.props.maptoShow !== previousProps.mapToShow
    ) {
      this.setState({
        flightPlanCoordinates: this.props.flightPlanCoordinates,
        categoryDTO: this.props.categoryDTO,
        features: this.props.features,
        defaultCenter: this.props.defaultCenter,
        zoomLevel: this.props.zoomLevel,
        eventType: this.props.eventType,
        isAllParticipants: this.props.isAllParticipants,
        // mapToShow:this.props.mapToShow
      });
    }
  }
  apiIsLoaded = (map, maps) => {
    const {
      flightPlanCoordinates,
      features,
      categoryDTO,
      eventType,
      isAllParticipants,
    } = this.state;
    const flightPath = new google.maps.Polyline({
      path: flightPlanCoordinates,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    let marker = {};
    // if (
    //   flightPlanCoordinates &&
    //   flightPlanCoordinates.length > 0 &&
    //   flightPlanCoordinates[0].lat
    // ) {
    //   const firstPoint = {
    //     lat: flightPlanCoordinates[0].lat,
    //     lng: flightPlanCoordinates[0].lng,
    //   };
    //   const image = {
    //     url: StarIcon,
    //     // anchor: new google.maps.Point(14, 25),
    //   };
      
    //   marker = new google.maps.Marker({
    //     position: firstPoint,
    //     icon: StartIcon, //image,
    //     map: map,
    //     title: "start",
    //     size: 1,
    //   });
    // }
    let color = ApplicationUtil.dynamicColors();

    let allPoints = features;
    var infoWindow = new google.maps.InfoWindow();
    for (var i = 0; i < allPoints?.length; i++) {
      let points = allPoints[i]?.coordinate ? allPoints[i].coordinate :
      allPoints[i]?.currentPosition
        ? JSON.parse(allPoints[i].currentPosition)
        : null;
      // let points=flightPlanCoordinates[i] ? flightPlanCoordinates[i]:null
      var latLng = {};
      if (points && points.lat) {
        latLng = {
          //new google.maps.LatLng(
          lat: points.lat, // allPoints[i]?.coOrdinatePoint?.lat,
          lng: points.lng, //allPoints[i]?.coOrdinatePoint?.lng,
        };
      }

      // );
      var data = null;

      let feat = allPoints[i];
      // ?.runnerActivityDTO
      //   ? allPoints[i]?.runnerActivityDTO
      //   : null;

      if (feat) {
        if (this.props?.mapToShow === "TRACKER") {
          // if (isAllParticipants) {
          //   data = {
          //     nameList: feat.nameList.split(",").filter((x) => x),
          //     scanPointName:feat.scanPointName
          //   };
          // } else {
            data = {
              recordedBy: feat?.recordedBy ? feat.recordedBy : "-",
              lastScannedTime: feat?.lastScannedTime || '-',
              participants: feat?.name,
              distance: feat?.distance,
              activityTime: feat?.activityTime,
              scanPoint:feat?.timingMatIdentifier || feat?.scanPoint || '-' 
            };
          // }
        } else {
          data = {
            totalDistance: feat.runnerActivityDTO?.totalActivityDistance
              ? feat.runnerActivityDTO.totalActivityDistance
              : 0,

            participants: feat?.runner?.name, //feat.activityList[0]?.name,
          };
        }

        color = categoryDTO?.mapIconColor;
      }
      if (data.totalDistance === undefined) {
        data.totalDistance = 0;
      }
      
      marker = new google.maps.Marker({
        position: latLng,
        icon:(!isAllParticipants && i == 0) ? StartIcon :  color
          ? ApplicationUtil.pinSymbol(color)
          : ApplicationUtil.pinSymbol("red"),
        // anchor: new google.maps.Point(14, 25),
        map: map,
        title:'',
        size: 1,
      });


      (function (marker, data) {
        google.maps.event.addListener(marker, "click", function (e) {
          infoWindow.setContent(
            `<div style='max-height:160px'>
              <div style = 'margin-bottom:4px;  font-size: 16px; font-weight: 500; color: black; '>
              <div style = 'font-size: 16px; font-weight: 500; color: black;text-transform:capitalize'>
                
                <div>${data?.participants ? data?.participants : ''}</div>
              
              </div> 
            
                ${
                  data?.recordedBy || data?.lastScannedTime
                    ? 
                    isAllParticipants ? 
                    `<div><div style=' font-size: 14px;font-weight: 500;color: #727272;'>Scan Point</div>
                    <div style = 'font-size: 16px; font-weight: 500; color: black;text-transform:capitalize'>
                    ${data?.scanPoint}
                    </div>
                    <div><div style=' font-size: 14px;font-weight: 500;color: #727272;'>Time (HH:MM:SS)</div>
                    <div style = 'font-size: 16px; font-weight: 500; color: black;text-transform:capitalize'>
                    ${data?.lastScannedTime}
                    </div>
                    <div style=' font-size: 14px;font-weight: 500;color: #727272;'> Scanned By</div>
                      <div style = 'font-size: 16px; font-weight: 500; color: black;text-transform:capitalize'>
                      ${data?.recordedBy}
                      </div>`
                       : 
                       `<div><div style=' font-size: 14px;font-weight: 500;color: #727272;'>Time (HH:MM:SS)</div>
                      <div style = 'font-size: 16px; font-weight: 500; color: black;text-transform:capitalize'>
                      ${data?.lastScannedTime}
                      </div>
                    <div style=' font-size: 14px;font-weight: 500;color: #727272;'> Distance (Km)</div>
                      <div style = 'font-size: 16px; font-weight: 500; color: black;text-transform:capitalize'>
                     ${parseFloat(Number(data?.distance) / 1000).toFixed(2)}
                      </div>
                      <div style=' font-size: 14px;font-weight: 500;color: #727272;'> Duration (HH:MM:SS)</div>
                      <div style = 'font-size: 16px; font-weight: 500; color: black;text-transform:capitalize'>
                      ${data?.activityTime ? data?.activityTime : "-"}
                      </div>
                      <div style=' font-size: 14px;font-weight: 500;color: #727272;'> Scanned By</div>
                      <div style = 'font-size: 16px; font-weight: 500; color: black;text-transform:capitalize'>
                      ${data?.recordedBy}
                      </div>
                      </div>`
                    : data?.totalDistance == 0
                    ? "<div></div>"
                    : `<div><div style=' font-size: 14px;font-weight: 500;color: #727272;'>Distance Covered</div>
                      <div style = 'font-size: 16px; font-weight: 500; color: black;text-transform:capitalize'>
                      ${parseFloat(Number(data?.totalDistance) / 1000).toFixed(2)} Km
                      </div>
                      <div>
                      </div>`
                }`
              
          );
          infoWindow.open(map, marker);
        });
      })(marker, data);
    }
    flightPath.setMap(map);
  };

  render() {
    const {
      flightPlanCoordinates,
      features,
      zoomLevel,
      defaultCenter,
      zoom,
      center,
    } = this.state;
    return (
      <Grid container>
        <Grid
          item
          style={{
            width: "100%",
            position: "relative",
            margin: "0px 4px",
          }}
        >
          <GoogleMap
            defaultCenter={defaultCenter}
            defaultZoom={zoomLevel}
            apiIsLoaded={this.apiIsLoaded}
            markers={features}
          />
        </Grid>
      </Grid>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(RouteMap);
