import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
const styles = (theme) => ({
  root: {
    maxWidth: "auto",
    maxHeight: "auto",
    position: "static",
    margin: "auto",
    //flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 0,
    //paddingLeft: theme.spacing.unit * 4,
    backgroundColor: theme.palette.background.default,
  },

  mobileStepper: { padding: "4px 8px" },
  imgContainer: {
    width: "100%", //width: "500px"
    height: "70vh",
    // margin: "auto",
    objectFit: "inherit",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
      height: "200px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "200px",
    },
  },
  img: {
    width: "95%",
    height: "100%",
    margin: "auto",
    overflow: "hidden",
    display: "block",
    objectFit: "contain",
    // marginBottom: "2px",
    // border: "2px solid gray",
  },
});

class ImageSlider extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    activeStep: 0,
    files: null,
  };

  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };
  componentDidMount() {
    this.setState({
      files: this.props.imageData,
    });
  }
  componentDidUpdate() {
    localStorage.setItem("currentImage", this.state.activeStep);
    console.log(this.state.activeStep)
  }

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };
  renderImage = (file) => {
    const { classes, applyCss, onClick, template, files, fileSourceImage } = this.props;
    const { activeStep } = this.state;
    if (file === undefined) {
      this.setState({
        activeStep: this.state.activeStep - 1,
      });
    } else if (file?.src || file?.imageData) {
      return (
        <div className={classes.imgContainer}>
          <img
            onClick={onClick ? onClick : null}
            className={applyCss ? applyCss : classes.img}
            //src={files[activeStep].imageData}
            src={fileSourceImage ? file?.src : "data:image/png;base64," + file?.imageData}
            //   alt={files[activeStep].name}
          />
        </div>
      );
    }
  };
  render() {
    const { classes, theme, applyCss, onClick, template, files, } = this.props;
    const { activeStep } = this.state;
    const {} = this.props;
    const maxSteps = this.props.files?.length;

    return (
      <div className={classes.root}>
        <Paper square elevation={0} className={classes.header}>
          {/* <Typography>{files[activeStep].name}</Typography> */}
        </Paper>
        {template ? (
          <div>{ReactHtmlParser(files[activeStep]?.templateContent)}</div>
        ) : (
          <div>
            {files && files.length > 0 ? (
              this.renderImage(files[activeStep])
            ) : (
              <div className={classes.imgContainer}>
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: "40px",
                    fontWeight: 600,
                  }}
                >
                  No images Available
                </div>
              </div>
            )}
          </div>
        )}

        {maxSteps > 1 && (
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            className={classes.mobileStepper}
            nextButton={
              <Button
                size="small"
                onClick={this.handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={this.handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        )}
      </div>
    );
  }
}

ImageSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ImageSlider);
