import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Divider,
  ListItem,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MuiDrawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import getUserDetails from "./../../apis/userService";
import "./../../styles/common.css";
import { withRouter, useRouteMatch } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ApplicationUtil from "../../utils/ApplicationUtil";
import { BackArrowSVGIcon } from "./SvgIcon";
import { getEventList, logoutUser } from "../../apis/eventService";
import { HomeIcon, CalendarIcon } from "../../images/Drawer";
import { isMobile } from "react-device-detect";
import Snackbar from "./Snackbar";
import logo from "../views/login/ndLogo.png";
import { HomeIcon as HomeIconSvg } from "../common/SvgIcon";
import { useSelector, useDispatch } from "react-redux";
import {
  updateDrawersEventList,
  updateUserDetails,
  updateUserPreferences,
  updateUserSelectedEvent,
} from "../../redux/user";
import { compose } from "recompose";
import httpService from "../../apis/httpService";

const styles = (theme) => ({
  boxCss: {
    display: "flex",
    alignItems: "center",
  },
  boxPaddingCss: {
    paddingRight: "0px !important",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  drawerCss: {
    padding: "0px 10px",
    // marginTop: "70px",
    position: "absolute",
    top: "60px",
    bottom: "1px",
    zIndex: 1,
  },
  drawerMenuCss: {
    color: "#fff",
    display: "inline-flex",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: 600,
    cursor: "pointer",
    margin: "4px 0px",
    opacity: 0.8,
    padding: "5px",
    borderBottom: "2px solid #f5f1f1",
  },
  drawerMenuCssActive: {
    color: "#f79824",
    display: "inline-flex",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: 600,
    cursor: "pointer",
    margin: "4px 0px",
    opacity: 1,
    padding: "5px",
    // background: "#4bb99f40",
    // backdropFilter: "blur(21.3px)",
    // borderRadius: "25px",
    borderBottom: "2px solid #f79824",
    textTransform: "capitalize",
    background: "#88b0c070",
    borderRadius: "12px",
  },
  menuLabelCss: {
    paddingLeft: "8px",
    paddingRight: "8px",
    // color: "white",
    "&:hover": {
      transform: "scale(1.05)",
    },
    textTransform: "capitalize",
    // textShadow:
    //   "-1px 1px 0 #000,1px 1px 0 #000,1px -1px 0 #000,-1px -1px 0 #000",
  },
  logos: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
  },
  logo: {
    width: "50px",
    paddingLeft: 0,
    // marginTop: "10px",
    alignItems: "center",
  },
  header__labelName: {
    color: "white",
    alignItems: "center",
    fontSize: "22px",
    width: "100%",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
});
const Drawer = withStyles({
  root: {
    "& .MuiDrawer-paper": {
      // backgroundColor: "#001f54",
      background:
        // "#d4e6f1", //
        "linear-gradient(21deg, #000428, #5092f1)",
      // "linear-gradient(45deg, #d33c3c, transparent)",
      color: "#000",
      // boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
      // backdropFilter: "blur(11.3px)",
      // minWidth: "200px",
      borderTop: "1px solid #f1ecec",
      padding: "0px 10px",
      height: "auto",
    },
  },
})(MuiDrawer);

function Header(props) {
  const { history, hideHomeIcon, title } = props; //useHistory();
  // const { count } = useSelector((state) => state.counter);

  const { user } = useSelector((state) => state.user);
  const dList = useSelector((state) => state.user.dList);
  const userPreferences = useSelector((state) => state.user.userPreferences);
  const userSelectedEvent = useSelector(
    (state) => state.user.userSelectedEvent
  );
  const userSelectedProgram = useSelector(
    (state) => state.user.userSelectedProgram
  );
  const dispatch = useDispatch();

  const { classes, showBack, backNavigate } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  let isProgramDashboard = window.location.href
    .toLowerCase()
    .includes("programdashboard");

  const [events, setEvents] = React.useState([]);

  const [userProfile, setUserProfile] = React.useState(user);
  const data = ApplicationUtil.getRunnerDetails();
  const [active, setActive] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [orgLogosWidth, setOrgLogosWidth] = useState(0);

  const [drawerAnchorEl, setDrawerAnchorEl] = React.useState(null);
  useEffect(() => {
    if (!props.signup) {
      if (ApplicationUtil.isuserLoggedIn("runner")) {
        let profileData = { ...user };
        let eventsList = dList;

        if (
          profileData === null ||
          profileData === undefined ||
          eventsList === null ||
          eventsList === undefined ||
          eventsList.length === 0
        ) {
          const getData = async () => {
            if (profileData === null || profileData === undefined) {
              await getUserDetails(data?.token, data?.userId).then((res) => {
                profileData = { ...profileData, ...res.data.user };
                // profileData.profilePicture =
                //   res.data?.profilePicture?.imageData;
                dispatch(updateUserDetails(profileData));
              });
            }
            if (
              eventsList === null ||
              eventsList === undefined ||
              eventsList.length === 0
            ) {
              let customParams = {
                pageCount: 10,
                pageNumber: 0,
                sortKey: "",
                sortOrder: true,
                eventState: "REGISTERED",
              };
              await getEventList(customParams).then((response) => {
                dispatch(updateDrawersEventList(response.data.data));
                eventsList = response.data.data;
              });
            }
          };

          getData().then(() => {
            setUserProfile(profileData);
            setEvents(eventsList ? eventsList : []);
            setShowDrawer(props.hideDrawer ? false : true);
          });
        } else {
          // profileData.profilePicture = profileData?.profileLink;
          // add profile picture
          setUserProfile(profileData);
          setEvents(eventsList ? eventsList : []);

          setShowDrawer(props.hideDrawer ? false : true);
        }

        let eventId = userSelectedEvent?.id;
        setCurrentEvent(eventId);

        let side = userPreferences.drawerSidebar;
        let endPoint = window.location;
        // let encodedUrl = endPoint.href.split("#")[1];
        let encodedUrl = endPoint.href;

        if (
          encodedUrl.includes("/profile") ||
          encodedUrl.includes("/editProfile") ||
          encodedUrl.includes("/consent")
        ) {
          setActive(null);
        } else if (
          encodedUrl === "/" ||
          encodedUrl.includes("/eventlist") ||
          encodedUrl.includes("/completeEventList") ||
          encodedUrl.includes("/calender") ||
          encodedUrl.includes("/low2highmap")
        ) {
          setActive("/eventlist");
        } else {
          setActive(side);
        }
      } else {
        let endPoint = window.location;
        let encodedUrl = endPoint.href;
        if (encodedUrl.includes("/low2highmap")) {
          setActive("/eventlist");
        }
      }
    }
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfile = () => {
    props.history.push({
      pathname: "/profile",
      state: { view: "user" },
    });
  };

  /**
   * Vishal
   */
  const handleLogIn = () => {
    //vishal- Defect_id_565 - as user click on header Login so clear previously selected event details (For remove the org logo and show default ND logo)
    dispatch(updateUserSelectedEvent(null));

    //redirect to the login component
    props.history.push("/login");
  };
  const handleLogout = (text) => {
    // if (text !== "login") {

    logoutUser().then((res) => {
      ApplicationUtil.handleRunnerLogout().then((data) => {
        if (props?.handleIsuserLogIn) {
          props.handleIsuserLogIn(false);
        }
        localStorage.clear();
        setAnchorEl(null);
        props.history.push("/");
        Snackbar.show({
          variant: "success",
          isAutoHide: true,
          preventDuplicate: true,
          message: res.data.verbose,
        });
      });
    });
    // getData().then(() => {
    //   ApplicationUtil.handleRunnerLogout();
    //   setAnchorEl(null);
    // });
    // } else if (props.homeScreen) {
    //   // ApplicationUtil.handleRunnerLogout("/login");
    //   console.log('login button clicked in if else')

    //   ApplicationUtil.handleRunnerLogout().then(()=>{

    //     props.history.push("/login");
    //   })
    // } else {
    //   console.log('login button clicked in else')
    //   props.history.push("/login");
    // }
  };

  const handleDrawerOpen = () => {
    setDrawerAnchorEl(true);
  };

  const handleDrawerClose = () => {
    setDrawerAnchorEl(null);
  };

  useEffect(() => {
    if (isProgramDashboard) {
      let _ww = document.getElementById("logosId")?.clientWidth;
      setOrgLogosWidth(_ww);
    }
  }, []);

  const userName = userProfile?.firstName + " " + userProfile?.lastName;
  return (
    <>
      {!props.signup && (
        <div className="headerCss">
          <div className="header__box" p={1}>
            {showBack && (
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={(e) => backNavigate()}
                // className={classNames(classes.menuButton, open && classes.hide)}
              >
                <BackArrowSVGIcon
                  color="inherit"
                  style={{ paddingLeft: 0, height: 22, width: 22 }}
                />
              </IconButton>
            )}
            {showDrawer ? (
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={handleDrawerOpen}
                // className={classNames(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <>
                {active !== "/eventlist" ? (
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      props.history.push("/");
                    }}
                    style={{
                      margin: "10px",
                      padding: "6px",
                      height: "60px",
                      width: "60px",
                      backgroundColor: "#08456c", //"#4791db"
                      cursor: "pointer",
                      borderRadius: "initial",
                    }}
                  >
                    <HomeIconSvg width="200px" height="200px" color="#F79824" />
                  </IconButton>
                ) : (
                  <img src={logo} alt="logo" />
                )}
              </>
            )}

            <Box p={0} className="header__boxCss" flexGrow={1}>
              {!isProgramDashboard && (
                <>
                  {" "}
                  {props.fromDate ? (
                    <div>
                      <span className="overflowed_label">
                        <Tooltip enterTouchDelay={0} title={props.label}>
                          <div className="header__label">{props.label}</div>
                        </Tooltip>
                      </span>
                      <div
                        className="header__date"
                        style={{ marginTop: "5px" }}
                      >
                        {props.fromDate}{" "}
                        {props.toDate && <span>to {props.toDate}</span>}
                      </div>
                    </div>
                  ) : (
                    <span className="overflowed_label">
                      <Tooltip enterTouchDelay={0} title={props.label}>
                        <div className="header__label">{props.label}</div>
                      </Tooltip>
                    </span>
                  )}
                </>
              )}
              {isProgramDashboard && (
                // <div
                //   style={{ display: "flex", gap: "10px", alignItems: "center" }}
                // >
                <>
                  <div id="logosId" className={classes.logos}>
                    {userSelectedProgram?.organizers?.map((organizer) => {
                      return (
                        <img
                          src={`${httpService.API_URL()}${
                            organizer?.organizerLogo
                          }`}
                          alt="organizer-logo"
                          height="35"
                          // width="70"
                          // style={{ objectFit: "cover" }}
                          className={classes.logo}
                        />
                      );
                    })}
                  </div>
                  {/* <div className="header__label">{props.label}</div> */}
                  {/* <span style={{ width: "calc(100% - 105px)" }}> */}
                  <span
                    style={{
                      width:
                        orgLogosWidth >= 0
                          ? `calc(100% - ${orgLogosWidth}px)`
                          : "100%",
                      paddingLeft:
                        userSelectedProgram?.organizers?.length > 0
                          ? "5px"
                          : "0px",
                    }}
                  >
                    <Tooltip enterTouchDelay={0} title={props.label}>
                      <div className={classes.header__labelName}>
                        {props.label}
                      </div>
                    </Tooltip>
                  </span>
                </>
                // </div>
              )}
            </Box>

            <div className="header--user">
              {ApplicationUtil.isuserLoggedIn("runner") ? (
                <>
                  <Avatar
                    alt={userName !== "undefined undefined" ? userName : ""}
                    src={
                      userProfile?.profileLink
                        ? userProfile?.profileLink?.includes("http")
                          ? `${userProfile.profileLink}`
                          : `${httpService.API_URL()}${userProfile.profileLink}`
                        : ""
                    }
                  >
                    {userName !== "undefined undefined"
                      ? userName.substring(0, 1)
                      : ""}
                  </Avatar>
                  {window.innerWidth > 600 && (
                    <span>
                      {userName !== "undefined undefined" ? userName : ""}
                    </span>
                  )}
                </>
              ) : (
                <>
                  {!hideHomeIcon && (
                    <span
                      style={{
                        cursor: "pointer",
                        marginRight: "20px",
                        fontSize: "20px",
                        fontWeight: "600",
                        borderRadius: "10px",
                        padding: " 6px 16px",
                        border: "1px solid white",
                        background: "rgb(8, 69, 108)",
                      }}
                      onClick={handleLogIn}
                    >
                      Login
                    </span>
                  )}
                </>
              )}

              {ApplicationUtil.isuserLoggedIn("runner") && (
                <ArrowDropDownIcon
                  onClick={handleClick}
                  className="user__icon"
                  style={{ color: "#fff", cursor: "pointer" }}
                />
              )}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                // keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="header__user--menu"
              >
                {window.innerWidth <= 600 && (
                  <MenuItem>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        alt={userName}
                        src={
                          userProfile?.profileLink
                            ? userProfile?.profileLink?.includes("http")
                              ? `${userProfile.profileLink}`
                              : `${httpService.API_URL()}${
                                  userProfile.profileLink
                                }`
                            : ""
                        }
                        // className={classes.large}
                      />
                      <div
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        <span style={{ fontWeight: 600 }}>{userName}</span>
                        <br />
                        <span
                          style={{
                            fontSize: "14px",
                            overflowWrap: "break-word",
                          }}
                        >
                          {userProfile?.email}
                        </span>
                      </div>
                    </div>
                  </MenuItem>
                )}
                {!props?.hideProfile && (
                  <MenuItem onClick={handleProfile}>Profile</MenuItem>
                )}
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      )}
      {props.signup && (
        <div className="headerCss">
          {" "}
          <div className="header__box" p={1} style={{ marginLeft: "20px" }}>
            <Typography className="header__typography" noWrap>
              {props.label}
            </Typography>
          </div>
        </div>
      )}

      {/* sidebar drawer */}
      {showDrawer && (
        <Drawer
          style={{
            maxWidth: isMobile ? "60%" : "375px",
            // maxHeight: window.innerHeight - "200px",
          }}
          // classes={classes.}
          variant="temporary"
          anchor="left"
          open={drawerAnchorEl}
          classes={{
            paper: classes.drawerCss,
          }}
          onClose={() => {
            handleDrawerClose();
          }}
          docked={false}
          // ModalProps={{ onBackdropClick: handleDrawerClose }}
          // onBackdropClick={handleDrawerClose}
          BackdropProps={{ invisible: true }}
        >
          <div
            className={
              active === "/"
                ? classes.drawerMenuCssActive
                : classes.drawerMenuCss
            }
            onClick={() => {
              // if (ApplicationUtil.isuserLoggedIn("runner")) {
              //   ApplicationUtil.handleNavigateHome().then(()=>{

              //     props.history.push("/eventlist");
              //   })

              // } else {
              props.history.push("/");
              // }
            }}
          >
            <div>
              <img src={HomeIcon} alt="" style={{ height: "30px" }} />
            </div>
            <div className={classes.menuLabelCss}>Home</div>
          </div>

          {events.map((event, index) => (
            <div
              className={
                active === "/eventDetails" && currentEvent == event.id
                  ? classes.drawerMenuCssActive
                  : classes.drawerMenuCss
              }
              onClick={() => {
                dispatch(updateUserSelectedEvent(event));
                dispatch(
                  updateUserPreferences({
                    drawerSidebar: "/eventDetails",
                  })
                );
                setActive("/eventDetails");
                setCurrentEvent(event.id);
                if (props.reloadDetails) {
                  props.reloadDetails();
                }
                setDrawerAnchorEl(false);
                props.history.push("/eventDetails");
              }}
            >
              <div>
                <img src={CalendarIcon} alt="" style={{ height: "30px" }} />
              </div>
              <div className={classes.menuLabelCss}>{event.name}</div>
            </div>
          ))}
        </Drawer>
      )}
    </>
  );
}

export default compose(withStyles(styles), withRouter)(Header);
