import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import OrganizerCommonHeader from "../OrganizerCommonHeader";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import ListPageLoader from "../../../../common/ListPageLoader";
import {
  downloadProgramCSV,
  generateProgrmParticipantCertificates,
  getProgramEventList,
  getProgramParticipantsList,
} from "../../../../../apis/eventService";
import OrganizerTableList from "../OrganizerTableList";
import Breadcrumbs from "../../../../common/Breadcrumbs";
import {
  FormControl,
  Menu,
  MenuItem,
  NativeSelect,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Snackbar from "../../../../common/Snackbar";
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import ViewListIcon from "@material-ui/icons/ViewList";
import { FilterSVGIcon, IconCalendarBlue } from "../../../../common/SvgIcon";
import DrawerFilter from "../../../../common/DrawerFilter";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import ApplicationConstants from "../../../../../constants/ApplicationConstants";
import SubmitCancelBtn from "../../../../common/SubmitCancelBtn";
import Properties from "../../../../../properties/Properties";

function OrganizerOpenSubscriptionParticipant({
  classes,
  history,
  hideBreadcrumb,
}) {
  const [participantsList, setParticipantsList] = useState(null);
  const [totalParticipants, setTotalParticipants] = useState(null);
  const [listResetLoader, setListResetLoader] = useState(false);
  const [requestParams, setRequestParams] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(null);
  const [filters, setFilters] = useState({});
  const [dateRangeOptions, setDateRangeOptions] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [eventList, setEventList] = useState(null);
  const [programChallengesList, setProgramChallengesList] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [programSessionsList, seProgramSessionsList] = useState(null);
  const [pageNumberLoad, setPageNumberLoad] = useState(null);

  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkboxArr, setCheckboxArr] = useState([]);
  const [listTypeAnchorEl, setListTypeAnchorEl] = useState(null);

  /**
   * get the latest selected list type
   * @returns
   */
  const getListTypeFromLocal = () => {
    return localStorage.getItem("selectedProgramParticpantTypeList") ===
      "ACTIVE"
      ? true
      : false;
  };

  /**
   * isActiveParticipants : check initially that last seected filter is for active participant or not
   * multiCheckbox : same for multiCheckbox too
   *
   * basically we are remove "selectedProgramParticpantTypeList" from calendar page as we have to show All participant type by default
   * and if click/change list type filter and go in participant info page and again comeback this page so my filter will remains
   */
  const [programActionOptions, setProgramActionOptions] = useState({
    isActiveParticipants: getListTypeFromLocal(),
    multiCheckbox: getListTypeFromLocal(),
    certificateGeneration: true,
    resetCheckBoxesState: false,
  });

  //Removed the Group filter for program participants.
  const filteredFilters = ApplicationConstants?.registrationListFilters?.filter(
    (item) => item?.id !== 4
  );
  const [registrationListFilters, setRegistrationListFilters] =
    useState(filteredFilters);

  const [anchorEl, setAnchorEl] = useState(null);
  const selectedProgramDetails = ApplicationUtil.getSupportsProgramDetails();
  let supportDetails = ApplicationUtil.getSupportDetails();

  /**
   * for store the current selected list type
   * @param {*} type
   */
  const handleParticpantTypeList = (type) => {
    localStorage.setItem("selectedProgramParticpantTypeList", type);
  };

  /**
   * For closing the anchor element of actions on top right- in filters line
   */
  const handleClose = () => {
    setAnchorEl(null);
  };
  /**
   * for closing the anchor ele of fltering the users type = All || Active - top right above export btn
   */
  const handleCloseListType = () => {
    setListTypeAnchorEl(null);
  };

  /**
   * For generating the certificate of project participant
   */
  const generateCertificates = (singleRunnerId) => {
    let _body;
    if (singleRunnerId) {
      _body = {
        runnerIds: [singleRunnerId],
      };
      generateProgrmParticipantCertificates(_body).then((_res) => {
        if (_res?.data?.code === "200") {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Certificate Generated successfully !!",
          });
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: _res?.data?.verbose ?? Properties.Something_went_wrong,
          });
        }
      });
    } else {
      let arr = [];
      checkboxArr.map((check) => {
        if (check.check) {
          arr.push(check.id);
        }
      });
      if (arr.length > 0 || isAllChecked) {
        // let _ids = arr?.toString();
        _body = {
          runnerIds: arr,
        };
        generateProgrmParticipantCertificates(_body).then((_res) => {
          if (_res?.data?.code === "200") {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Certificate Generated successfully !!",
            });

            setProgramActionOptions({
              ...programActionOptions,
              resetCheckBoxesState: true,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: _res?.data?.verbose ?? Properties.Something_went_wrong,
            });
          }
        });
      } else {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please select users",
        });
      }
    }
  };

  /**
   * for get the active participant list
   */
  const handleActiveParticipantList = () => {
    handleParticpantTypeList("ACTIVE");
    getList({ _activeParticipantRequired: "true" });
    setProgramActionOptions({
      ...programActionOptions,
      multiCheckbox: true,
      isActiveParticipants: true,
    });
    // setOption;
  };

  const initialLoad = (params) => {
    //Dates Range
    let dateRange = [];
    //Last 24 hours --
    let last24Hrs = {
      label: "Last 24 hours",
      value: `${moment().subtract(1, "day").format("YYYY-MM-DD")} ${moment()
        .subtract(1, "day")
        .format("HH:mm:ss")}`,
    };
    //Last 3 days --
    let last3days = {
      label: "Last 3 days",
      value: `${moment().subtract(3, "day").format("YYYY-MM-DD")} ${moment()
        .subtract(3, "day")
        .format("HH:mm:ss")}`,
    };
    //Last 7 days --
    let last7days = {
      label: "Last 7 days",
      value: `${moment().subtract(7, "day").format("YYYY-MM-DD")} ${moment()
        .subtract(7, "day")
        .format("HH:mm:ss")}`,
    };
    //Last month --
    let lastMonth = {
      label: "Last month",
      value: `${moment().subtract(1, "months").format("YYYY-MM-DD")} ${moment()
        .subtract(1, "months")
        .format("HH:mm:ss")}`,
    };
    let customDate = {
      label: "Custom Date",
      value: "Custom Date",
    };
    dateRange.push(last24Hrs, last3days, last7days, lastMonth, customDate);
    if (dateRange.length > 0) {
      setDateRangeOptions(dateRange);
    }

    /**
     * check if previous filter of cosent is there or not
     * if it is there then get the data according to them
     *
     * defect_id_783 - Consent filter destroy after page refresh - fixed
     */
    const consentSeleted = localStorage.getItem("consentSeleted");
    if (consentSeleted && filteredFilters) {
      const index = filteredFilters?.findIndex(
        (item) => item?.filterType === "Consent"
      );
      const updatedFilter = filteredFilters;
      if (index != -1) {
        updatedFilter[index].selectedValue = consentSeleted;
      }
      // pass the final filter array
      applyFilters(updatedFilter);
    } else {
      //Getting the last selected list type participant - like Active/All
      getList({
        _activeParticipantRequired: `${programActionOptions?.isActiveParticipants}`,
      });

      //if pre filter is not there then by default selected null
      setRegistrationListFilters(
        filteredFilters?.map((data) => {
          return {
            ...data,
            selectedValue: null,
          };
        })
      );
    }
    // getList(params || requestParams, 0);

    setFilters({ ...filters, searchSelectValue: "Name", searchValue: "" });
    getProgramEventList({ programId: selectedProgramDetails?.id }).then(
      ({ data }) => {
        let eventDTO = data?.eventDTO;
        let sessions = data?.programSessions;
        let _programChallenges = data?.programChallenges ?? [];

        
        
        setProgramData(data);
        setEventList(eventDTO);
        setProgramChallengesList(_programChallenges);
        seProgramSessionsList(sessions);
      }
    );
  };

  async function dateHandleChange(event) {
    setDateRange(event.target.value);
    setListResetLoader(true);
    let params = {
      ...requestParams,
      startDate:
        event.target.value === "Custom Date"
          ? `${moment(filters?.startDate).format(
              "YYYY-MM-DD"
            )} ${moment().format("HH:mm:ss")}`
          : event.target.value,
      endDate:
        event.target.value === "Custom Date"
          ? `${moment(filters?.endDate).format("YYYY-MM-DD")} ${moment().format(
              "HH:mm:ss"
            )}`
          : `${moment().format("YYYY-MM-DD")} ${moment().format("HH:mm:ss")}`,
    };
    await getList(params);
    setFilters({ ...filters, ...params });
    setRequestParams(params);
  }

  async function getList(params, page, isAdd = false) {
    let activeParticipantRequired =
      params?._activeParticipantRequired === "true"
        ? true
        : params?._activeParticipantRequired === "false"
        ? false
        : programActionOptions?.isActiveParticipants;

    const consentSeleted = localStorage.getItem("consentSeleted");
    if (consentSeleted && !params.consentPresent && consentSeleted !== "all") {
      params.consentPresent =
        consentSeleted === "consentProvided" ? true : false;
    }

    await getProgramParticipantsList({
      ...params,
      activeParticipantRequired: "false",
      programId: selectedProgramDetails?.id,
      pageNumber: page ? page : 0,
      pageCount: 20,
    }).then(({ data }) => {
      if (data?.programParticipant) {
        let list = data?.programParticipant?.filter((item) => !item.testUser);
        if (isAdd) {
          list = participantsList.concat(list);
          setListResetLoader(false);
        }
        setParticipantsList(list || []);
        setTotalParticipants(data?.totalCounts || 0);

        if (
          activeParticipantRequired !==
          programActionOptions?.isActiveParticipants
        ) {
          setProgramActionOptions({
            ...programActionOptions,
            multiCheckbox: activeParticipantRequired,
            isActiveParticipants: activeParticipantRequired,
          });
          setCheckboxArr([]);
          setIsAllChecked(false);
        }
      }
    });
  }
  const searchHandleChange = (event) => {
    setFilters({ ...filters, searchValue: event.target.value || "" });
    setListResetLoader(true);
    if (!event.target.value) {
      let params = {
        ...requestParams,
        name: null,
        bib: null,
        email: null,
        contact: null,
      };
      setRequestParams(params);
      initialLoad(params);
    }
  };

  const selectHandleValue = (event) => {
    setFilters({ ...filters, searchSelectValue: event.target.value });
    setListResetLoader(true);
  };

  const searchHandleClick = async () => {
    let name;
    let bib;
    let email;
    let contact;
    if (filters?.searchSelectValue === "Name") {
      name = filters?.searchValue;
    } else if (filters?.searchSelectValue === "BIB") {
      bib = filters?.searchValue;
    } else if (filters?.searchSelectValue === "Email") {
      email = filters?.searchValue;
    } else if (filters?.searchSelectValue === "Contact") {
      contact = filters?.searchValue;
    }
    let params = {
      ...requestParams,
      name: name,
      bib: bib,
      email: email,
      contact: contact,
    };

    if (
      params.name !== "" ||
      params.email !== "" ||
      params.bib !== "" ||
      params.contact !== ""
    ) {
      await getList(params);
      setListResetLoader(true);
      setRequestParams(params);
    }
  };

  const customStartDate = async (date) => {
    setFilters({ ...filters, startDate: date });
    let params = {
      ...requestParams,
      startDate: `${moment(date).format("YYYY-MM-DD")} ${moment().format(
        "HH:mm:ss"
      )}`,
      endDate: `${moment(filters?.endDate).format(
        "YYYY-MM-DD"
      )} ${moment().format("HH:mm:ss")}`,
    };
    await getList(params);
    setListResetLoader(true);
    setRequestParams(params);
  };
  const customEndDate = async (date) => {
    setFilters({ ...filters, endDate: date });

    let params = {
      ...requestParams,
      startDate: `${moment(filters?.startDate).format(
        "YYYY-MM-DD"
      )} ${moment().format("HH:mm:ss")}`,
      endDate: `${moment(date).format("YYYY-MM-DD")} ${moment().format(
        "HH:mm:ss"
      )}`,
    };
    await getList(params);
    setListResetLoader(true);
    setRequestParams(params);
  };

  const addOrRemoveConsentToLocal = (type, consent) => {
    type === "Add"
      ? localStorage.setItem("consentSeleted", consent)
      : localStorage.removeItem("consentSeleted");
  };

  const applyFilters = (drawerFilters) => {
    setRegistrationListFilters(drawerFilters);
    let updateFilters = { ...filters };
    for (let i = 0; i < drawerFilters?.length; i++) {
      let ele = drawerFilters[i];

      if (ele.filterType === "Consent") {
        addOrRemoveConsentToLocal("Add", ele.selectedValue);
        updateFilters.consentPresent =
          ele.selectedValue === "consentProvided"
            ? true
            : ele.selectedValue === "consentNotProvided"
            ? false
            : null;
      }
      if (ele.filterType === "bib") {
        updateFilters.bibGenerated =
          ele.selectedValue === "bibGenerated"
            ? true
            : ele.selectedValue === "bibNotGenerated"
            ? false
            : null;
      }
      if (ele.filterType === "register") {
        updateFilters.onlineRegistration =
          ele.selectedValue === "online"
            ? true
            : ele.selectedValue === "offline"
            ? false
            : null;
      }
    }

    setFilters({ ...updateFilters });
    const params = { ...requestParams, ...updateFilters };

    setRequestParams(params);
    setListResetLoader(true);
    getList(params);
  };

  const eventHandleChange = (event) => {
    setRequestParams({ ...requestParams, eventId: event.target.value });
    setListResetLoader(true);
    getList({ ...requestParams, eventId: event.target.value });
  };
  /**
   * Vishal - for get challenge specific records
   */
  const challengeHandleChange = (event) => {
    setRequestParams({
      ...requestParams,
      programChallengeId: event.target.value,
    });
    setListResetLoader(true);
    getList({ ...requestParams, programChallengeId: event.target.value });
  };
  const sessionHandleChange = (event) => {
    setRequestParams({
      ...requestParams,
      programSessionId: event.target.value,
    });
    setListResetLoader(true);
    getList({ ...requestParams, programSessionId: event.target.value });
  };
  const downloadCSVFile = () => {
    // Defect_Id_287 - Laxman
    let Params = {
      pageNumber: 0,
      ...requestParams,
    };
    downloadProgramCSV(Params);
  };

  useEffect(() => {
    initialLoad();
  }, []);

  useEffect(() => {
    return () => {
      addOrRemoveConsentToLocal("RemoveConsent");
    };
  }, []);

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.top}>
          {totalParticipants !== null && (
            <div className={classes.totalRec}>
              <span>
                Participants
                <span style={{ marginLeft: "10px" }}>{totalParticipants}</span>
              </span>
            </div>
          )}
          {totalParticipants !== 0 && totalParticipants !== null && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <SubmitCancelBtn
                  label="Export"
                  type="submit"
                  onClick={downloadCSVFile}
                />
              </div>
            </div>
          )}
          <Tooltip title="Change Participants Type">
            {/* <ViewQuiltIcon */}
            <ViewListIcon
              className={classes.listTypeIcon}
              onClick={(event) => {
                setListTypeAnchorEl(event.currentTarget);
              }}
            />
          </Tooltip>
        </div>
        <div className={classes.tableContainer}>
          <div className={classes.searchFilter__row}>
            <div className="search__select">
              <FormControl className={classes.select}>
                <NativeSelect
                  className={classes.selectActivity}
                  value={filters?.searchSelectValue}
                  onChange={selectHandleValue}
                  style={{ width: 100 }}
                >
                  {["Name", "BIB", "Email", "Contact"]?.map((value, index) => (
                    <option value={value}>{value}</option>
                  ))}
                </NativeSelect>
              </FormControl>
              <form
                className="search"
                onSubmit={(e) => {
                  e.preventDefault();
                  searchHandleClick();
                }}
              >
                <input
                  type="search"
                  placeholder="Search..."
                  value={filters?.searchValue}
                  onChange={searchHandleChange}
                />
                <SearchIcon
                  className="search__icon"
                  onClick={searchHandleClick}
                />
              </form>
            </div>
            <div className="filters">
              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectActivity}
                  value={filters?.activityType}
                  onChange={eventHandleChange}
                  style={{ width: 130 }}
                >
                  <option value="">All Events</option>
                  {eventList?.map((value, index) => (
                    <option value={value.id}>{value.name}</option>
                  ))}
                </NativeSelect>
              </FormControl>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectActivity}
                  value={filters?.activityType}
                  onChange={challengeHandleChange}
                  style={{ width: 130 }}
                >
                  <option value="">All Challenges</option>
                  {programChallengesList?.map((value, index) => (
                    <option value={value?.id}>{value?.name}</option>
                  ))}
                </NativeSelect>
              </FormControl>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectActivity}
                  value={filters?.activityType}
                  onChange={sessionHandleChange}
                  style={{ width: 130 }}
                >
                  <option value="">All Sessions</option>
                  {programSessionsList?.map((value, index) => (
                    <option value={value.id}>{value.name}</option>
                  ))}
                </NativeSelect>
              </FormControl>

              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectActivity}
                  value={filters?.dateRange}
                  onChange={dateHandleChange}
                  style={{ width: "auto" }}
                >
                  <option value="">All </option>
                  {dateRangeOptions?.map((value, index) => (
                    <option value={value.value}>{value.label}</option>
                  ))}
                </NativeSelect>
              </FormControl>
              {dateRange === "Custom Date" && (
                <FormControl className={classes.customDate}>
                  <Typography variant="body2" gutterBottom>
                    <span>Start Date</span>
                  </Typography>
                  <KeyboardDatePicker
                    autoOk
                    clearable
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    // disableFuture={true}
                    value={filters?.startDate}
                    //error={dobError}
                    style={{
                      width: "150px",
                    }}
                    maxDate={filters?.endDate}
                    InputProps={{
                      classes: {
                        root: classes.datePicker,
                      },
                    }}
                    onChange={(date) => {
                      customStartDate(date);
                    }}
                    keyboardIcon={
                      <IconCalendarBlue className={classes.calendarIconCss} />
                    }
                  />
                </FormControl>
              )}
              {dateRange === "Custom Date" && (
                <FormControl className={classes.customDate}>
                  <Typography variant="body2" gutterBottom>
                    <span>End Date</span>
                  </Typography>
                  <KeyboardDatePicker
                    autoOk
                    clearable
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    // disableFuture={true}
                    value={filters?.endDate}
                    //error={dobError}
                    style={{
                      width: "150px",
                    }}
                    InputProps={{
                      classes: {
                        root: classes.datePicker,
                      },
                    }}
                    minDate={filters?.startDate}
                    onChange={(date) => {
                      customEndDate(date);
                    }}
                    keyboardIcon={
                      <IconCalendarBlue className={classes.calendarIconCss} />
                    }
                  />
                </FormControl>
              )}

              {programActionOptions?.isActiveParticipants && (
                <FormControl className={classes.formControl}>
                  <div className={classes.actions}>
                    <span>Actions</span>
                    <span>
                      <ArrowDropDownCircleOutlinedIcon
                        className={classes.editIcon}
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                        }}
                      />
                    </span>
                  </div>
                </FormControl>
              )}

              <FilterSVGIcon
                onClick={() => setIsDrawerOpen(true)}
                className={classes.filtericonStyling}
              />
              <DrawerFilter
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(!isDrawerOpen)}
                onApplyFilter={applyFilters}
                filterData={registrationListFilters}
              />
            </div>
          </div>
          <ListPageLoader
            pageParams={{
              page: 0,
              start: 0,
              limit: 8,
              TotalRecords: totalParticipants,
              pageNumberLoad: pageNumberLoad,
            }}
            resetLoader={listResetLoader}
            totalLoadedLength={participantsList ? participantsList.length : 20}
            loadNextData={(params) => {
              if (!participantsList.includes(undefined)) {
                getList(requestParams, params.page, true);
                setPageNumberLoad(null);
              }
            }}
          >
            {participantsList?.length > 0 ? (
              <OrganizerTableList
                isSupport={supportDetails.role === "SUPPORT" ? true : false}
                isProgram={true}
                eventData={{
                  ...selectedProgramDetails,
                  programData: programData,
                }}
                isOpenSubscriptionProgram={true}
                data={participantsList}
                updateParticipantsList={(data, reload) => {
                  if (reload) {
                    getList();
                    setPageNumberLoad(0);
                  }
                }}
                programActionOptions={programActionOptions}
                updateCheckboxArr={(arr, isAllChecked) => {
                  setCheckboxArr(arr ?? []);
                  setIsAllChecked(isAllChecked);
                  setProgramActionOptions({
                    ...programActionOptions,
                    resetCheckBoxesState: false,
                  });
                }}
                generateCertificateofProgram={(runnerId) => {
                  if (runnerId) {
                    generateCertificates(runnerId);
                  }
                }}
                email
                // regType
                editReg
              />
            ) : (
              <div className={classes.participantNoResult}>
                {participantsList && participantsList?.length === 0
                  ? "No participants"
                  : participantsList?.length === null
                  ? "Loading participants"
                  : ""}
              </div>
            )}
          </ListPageLoader>
          <Menu
            anchorEl={listTypeAnchorEl}
            keepMounted
            open={Boolean(listTypeAnchorEl)}
            onClose={handleCloseListType}
            style={{ marginTop: "40px" }}
          >
            <MenuItem
              onClick={() => {
                // handleViewChange("ATHELETE");
                handleCloseListType();
                handleParticpantTypeList("ALL");
                getList({ _activeParticipantRequired: "false" });
                setProgramActionOptions({
                  ...programActionOptions,
                  multiCheckbox: false,
                  isActiveParticipants: false,
                });
              }}
            >
              All Participants
            </MenuItem>
            <MenuItem
              onClick={() => {
                // handleViewChange("ACTIVITY");
                handleActiveParticipantList();
                handleCloseListType();
              }}
            >
              Active Participants
            </MenuItem>
          </Menu>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            // className="header__user--menu"
            style={{ marginTop: "40px" }}
          >
            <MenuItem
              onClick={() => {
                generateCertificates();
                handleClose();
              }}
            >
              {"Generate Certificate"}
            </MenuItem>
          </Menu>
        </div>
      </div>
    </>
  );
}
const styles = (theme) => ({
  mainContainer: {
    marginTop: "0px",
    paddingInline: "10px",
  },
  importContainerCss: {
    margin: "10px",
    padding: "0px 20px",
  },
  mainBody: {
    // marginLeft: 80,
  },
  searchFilter__row: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    // paddingLeft: theme.spacing(6),
    // paddingRight: theme.spacing(6),
    marginBottom: "20px",
    "& .search__select": {
      display: "flex",
      alignItems: "flex-end",
    },
    "& .search ": {
      display: "flex",
      alignItems: "center",
      border: "1px solid #bfc0c0",
      borderRadius: "5px",
      height: "35px",
      "& > input": {
        height: "30px",
        outline: "none",
        border: "none",
        fontSize: "16px",
        paddingLeft: "10px",
        borderRight: "2px solid #bfc0c0",
      },
      "& .search__icon": {
        padding: "5px",
        cursor: "pointer",
      },
    },
    "& .filters": {
      display: "flex",
      alignItems: "flex-end",
      fontFamily: "Rubik,sans-serif",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    fontFamily: "Rubik, sans-serif",
  },
  select: {
    marginRight: theme.spacing(1),
  },
  selectActivity: {
    // marginTop: theme.spacing(2),
    textAlign: "center",
    color: "#727272",
    fontSize: 18,
  },
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",

    position: "relative",
    bottom: 20,
    marginRight: theme.spacing(8),
  },
  totalRec: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    fontWeight: 600,
    width: "15%",
    marginRight: "10px",
    background: "rgba( 149, 213, 178, 0.50 )",
    boxShadow: "0 4px 3px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    // position: "relative",
    // bottom: 20,
  },
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },
  customDate: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  actions: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  editIcon: {
    color: "#09456C",
    cursor: "pointer",
  },
  listTypeIcon: {
    color: "#09456C",
    cursor: "pointer",
    fontSize: "50px",
  },
  labelCss: {
    color: "#727272",
  },
  mandatoryCss: { color: "red" },
  approvalForm: {
    // width: "800px",
    // height: "auto",
    padding: "8px 24px",
  },
  messageType: {
    marginBottom: theme.spacing(2),
  },
  drawerContainer: {
    margin: 20,
    // padding:20,
    width: "300px",
    "& .title": {
      fontSize: 20,
      padding: 10,
      // borderBottom:'1px solid gray',
      // width:'100%'
    },
  },
  drawerListRow: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  participantNoResult: {
    color: "gray",
    margin: "0 auto",
    fontSize: "25px",
    textAlign: "center",
  },
  filtericonStyling: {
    // width: "20px",
    // height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
    color: "#09456C",
    cursor: "pointer",
    margin: theme.spacing(1),
  },
  tableContainer: {
    marginTop: "30px",
  },
  participantNoResult: {
    color: "gray",
    margin: "0 auto",
    fontSize: "25px",
    textAlign: "center",
  },
});
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(OrganizerOpenSubscriptionParticipant);
