import React from "react";
import Select, { components } from "react-select";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import CancelIcon from "@material-ui/icons/Cancel";
import CreatableSelect from "react-select/creatable";
import clsx from "clsx";
import { LeaderboardIcon } from "./SvgIcon";
import ApplicationUtil from "../../utils/ApplicationUtil";
import { Tooltip } from "@material-ui/core";

const { SingleValue, Option } = components;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
    border: "1px solid #08456C",
  },

  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  flagContainerCss: {
    display: "flex",
    alignItems: "center",
  },
  flagCss: {
    height: "28px",
    width: "28px",
  },
  flagLabelCss: {
    marginLeft: "4px",
  },
 
}));

const IconOptionSelcted = (props) => {
  const classes = useStyles();

  return (
    <SingleValue {...props}>
      {/* <ReactCountryFlag
      countryCode={props.data.code}
      svg
      style={{
        width: "2em",
        height: "2em",
      }}
      title={props.showIcon ? +props.data.value : props.data.value}
    /> */}
      <div className={classes.flagContainerCss}>
        <img
          className={classes.flagCss}
          src={ApplicationUtil.getCountryFlag(props.data.code)}
        />
        <span className={classes.flagLabelCss}>
          {props.showIcon ? +props.data.code : props.data.code}
        </span>
      </div>
    </SingleValue>
  );
};
const IconOption = (props) => {
  const classes = useStyles();
  return (
    <Option {...props}>
      <div className={classes.flagContainerCss}>
        <img
          className={classes.flagCss}
          src={ApplicationUtil.getCountryFlag(props.data.code)}
        />

        <span className={classes.flagLabelCss}>
          {props.showIcon ? +props.data.code : props.data.code}
        </span>
      </div>
    </Option>
  );
};

const CustomOption = (props) => {
  const classes = useStyles();
  return (
    <Option {...props}>
      <div className={classes.flagContainerCss}>
        <Tooltip title={props?.data?.tooltip} enterTouchDelay={0}>
           <span className={classes.flagLabelCss}>{props?.data?.label} </span>
        </Tooltip>
      </div>
    </Option>
  );
};
function MultiValue(props) {
  const { selectProps, data } = props;
  const { securityChecks } = selectProps;
  let showDeleteIcon = (
    <Chip
      tabIndex={-1}
      label={props.children}
      variant="outlined"
      className={clsx(selectProps.classes.chip, {
        [selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
      icon={<img src="" />}
    />
  );
  return showDeleteIcon;
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  // removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function MultiSelect(props) {
  const classes = useStyles();
  const {
    isCreatableSelect,
    isTag,
    isMulti,
    isGroup,
    groupedOptions,
    fixedHeight,
    height,
    isMinimiseHeight,
    isFixedMaxHeight,
    isClearable,
    isReadOnly,
    showIcon,
    isMenuMaxHeight,
    isNotAllowedCreateSameChallenge,
    isMobileNotAllowCreateChallenge,
    isSearchable = true,
  } = props;

  if (isCreatableSelect) {
    return (
      <CreatableSelect
        classes={classes}
        styles={{
          valueContainer: (base) => ({
            ...base,
            //minHeight: "140px",
            //height: "140px",
            maxHeight: "110px",
            overflowY: "auto",
            overflow: "auto",
          }),
          control: (base) => ({
            ...base,
            minHeight: "110px",
            borderColor: props.error ? "#f44336" : "#C0C0C0",
          }),

          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected ? "#F0F0F0" : null,
              backgroundColor: isFocused ? "#dbd7d7" : null,

              color: isDisabled ? "#D0D0D0" : "black",
              cursor: isDisabled ? "not-allowed" : "#A9A9A9",
            };
          },
        }}
        {...props}
        blurInputOnSelect={isMulti ? false : true}
        closeMenuOnSelect={isMulti ? false : true}
        components={{ MultiValue }}
      />
    );
  } else if (isTag) {
    return (
      <Select
        classes={classes}
        styles={{
          valueContainer: (base) => ({
            ...base,
            minHeight: "110px",
            overflowY: "auto",
            overflow: "auto",
          }),
          control: (base) => ({
            ...base,
            minHeight: "110px",
            borderColor: props.error ? "#f44336" : "#C0C0C0",
            cursor: "pointer",
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected ? "#F0F0F0" : null,
              backgroundColor: isFocused ? "#dbd7d7" : null,

              color: isDisabled ? "#D0D0D0" : "black",
              cursor: isDisabled ? "not-allowed" : "#A9A9A9",
            };
          },
        }}
        {...props}
        blurInputOnSelect={isMulti ? false : true}
        closeMenuOnSelect={isMulti ? false : true}
        components={{ MultiValue }}
        isClearable={isClearable ? isClearable : false}
        isDisabled={isReadOnly}
      />
    );
  } else if (isGroup) {
    const groupStyles = {
      border: "2px #000",
      borderRadius: "5px",
      background: "#fff",
    };

    const GroupHeading = (props) => (
      <div style={groupStyles}>
        <components.GroupHeading {...props} />
      </div>
    );

    return (
      <Select
        classes={classes}
        styles={{
          valueContainer: (base) => ({
            ...base,
            // height: height ? height : "110px",
            // overflowY: "scroll",
            // overflow: "scroll",
          }),
          control: (base) => ({
            ...base,
            // height: height ? height : "110px",
            cursor: "pointer",

            // overflowY: "scroll",
            // overflow: "scroll",
            borderColor: props.error ? "#f44336" : "#C0C0C0",
            border: "1px solid #08456C",
          }),
          multiValueRemove: (base, state) => {
            return state.data.security !== 1
              ? { ...base, display: "none" }
              : base;
          },
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected ? "#F0F0F0" : null,
              backgroundColor: isFocused ? "#dbd7d7" : null,
              marginLeft: "10px",
              color: isDisabled ? "#D0D0D0" : "black",
              cursor: isDisabled ? "not-allowed" : "#A9A9A9",
            };
          },
          groupHeading: (base) => ({
            ...base,
            color: "#296dfa",
            // color:'#09456C',
            fontSize: "15px",
            fontWeight: "bold",
          }),
        }}
        options={groupedOptions}
        components={{ GroupHeading, MultiValue }}
        {...props}
        blurInputOnSelect={isMulti ? false : true}
        closeMenuOnSelect={isMulti ? false : false}
        isClearable={isClearable ? isClearable : false}
        isDisabled={isReadOnly}
      />
    );
  } else if (fixedHeight) {
    const styles = {
      valueContainer: (base) => ({
        ...base,
        height: fixedHeight ? fixedHeight : "84px",
        overflowY: "auto",
        overflow: "auto",
      }),
      control: (base) => ({
        ...base,
        height: fixedHeight ? fixedHeight : "84px",
        overflowY: "auto",
        overflow: "auto",
        borderColor: props.error ? "#f44336" : "#C0C0C0",
      }),
      multiValueRemove: (base, state) => {
        return state.data.security !== 1 ? { ...base, display: "none" } : base;
      },
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? "#F0F0F0" : null,
          backgroundColor: isFocused ? "#dbd7d7" : null,
          color: isDisabled ? "#D0D0D0" : "black",
          cursor: isDisabled ? "not-allowed" : "#A9A9A9",
        };
      },
    };

    return (
      <Select
        classes={classes}
        styles={styles}
        {...props}
        blurInputOnSelect={isMulti ? false : true}
        closeMenuOnSelect={isMulti ? false : true}
        components={{ MultiValue }}
        isClearable={isClearable ? isClearable : false}
        isDisabled={isReadOnly}
      />
    );
  } else if (isMinimiseHeight) {
    return (
      <CreatableSelect
        classes={classes}
        styles={{
          valueContainer: (base) => ({
            ...base,
            //minHeight: "140px",
            //height: "55px",
            maxHeight: "85px",
            overflowY: "auto",
            overflow: "auto",
          }),
          control: (base) => ({
            ...base,
            minHeight: "85px",
            borderColor: props.error ? "#f44336" : "#C0C0C0",
          }),

          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected ? "#F0F0F0" : null,
              backgroundColor: isFocused ? "#dbd7d7" : null,

              color: isDisabled ? "#D0D0D0" : "black",
              cursor: isDisabled ? "not-allowed" : "#A9A9A9",
            };
          },
        }}
        {...props}
        blurInputOnSelect={isMulti ? false : true}
        closeMenuOnSelect={isMulti ? false : true}
        components={{ MultiValue }}
      />
    );
  } else if (isFixedMaxHeight) {
    return (
      <Select
        classes={classes}
        styles={{
          valueContainer: (base) => ({
            ...base,
            maxHeight: "90px",
            overflowY: "auto",
            overflow: "auto",
          }),
          control: (base) => ({
            ...base,
            borderColor: props.error ? "#f44336" : "#C0C0C0",
          }),
          //QA-5913
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected ? "#F0F0F0" : null,
              backgroundColor: isFocused ? "#dbd7d7" : null,

              color: isDisabled ? "#D0D0D0" : "black",
              cursor: isDisabled ? "not-allowed" : "#A9A9A9",
            };
          },
          multiValueRemove: (base, state) => {
            return state.data.security !== 1
              ? { ...base, display: "none" }
              : base;
          },
        }}
        {...props}
        blurInputOnSelect={isMulti ? false : true}
        closeMenuOnSelect={isMulti ? false : true}
        components={{ MultiValue }}
        isClearable={isClearable ? isClearable : false}
        isDisabled={isReadOnly}
      />
    );
  } else if (showIcon) {
    return (
      <Select
        classes={classes}
        styles={{
          valueContainer: (base) => ({
            ...base,
            // maxHeight: "84px",
            overflowY: "auto",
            overflow: "auto",
            // position: "absolute",
          }),
          control: (base) =>
            props.error
              ? {
                  ...base,
                  borderColor: props.error ? "#f44336" : "#C0C0C0",
                }
              : {
                  ...base,
                  borderColor: props.error ? "#f44336" : "#C0C0C0",
                  border: "1px solid #08456C",
                },
          //QA-5913
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected ? "#F0F0F0" : null,
              backgroundColor: isFocused ? "#dbd7d7" : null,

              color: isDisabled ? "#D0D0D0" : "black",
              cursor: isDisabled ? "not-allowed" : "#A9A9A9",
            };
          },
          multiValueRemove: (base, state) => {
            return state.data.security !== 1
              ? { ...base, display: "none" }
              : base;
          },
        }}
        {...props}
        blurInputOnSelect={isMulti ? false : true}
        closeMenuOnSelect={isMulti ? false : true}
        components={{
          SingleValue: IconOptionSelcted,
          Option: IconOption,
        }}
        isClearable={isClearable ? isClearable : false}
        isDisabled={isReadOnly}
      />
    );
  } else if (isNotAllowedCreateSameChallenge) {

    return (
      <Select
        isSearchable={false}
        menuPlacement={isMobileNotAllowCreateChallenge && "top"}
        classes={classes}
        styles={{
          valueContainer: (base) => ({
            ...base,
            maxHeight: "64px",
            overflowY: "auto",
            overflow: "auto",
            // position: "absolute",
          }),
          singleValue: (provided) => ({
            ...provided,
            overflow: "visible",
          }),

          menuList: (provided) => {
            if (isMenuMaxHeight) {
              return {
                ...provided,
                // height: "170px",
                // minHeight:"30px",
                overflowY: "scroll",
              };
            } else {
              return {
                ...provided,
               
              };
            }
          },
          control: (base) =>
            props.error
              ? {
                  ...base,
                  borderColor: props.error ? "#f44336" : "#C0C0C0",
                }
              : {
                  ...base,
                  borderColor: props.error ? "#f44336" : "#C0C0C0",
                  border: "1px solid #08456C",
                },
          //QA-5913
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected ? "#F0F0F0" : null,
              // backgroundColor: isDisabled && isFocused  ? "#ffffff" : isFocused ? "#dbd7d7" : null,
              backgroundColor: isDisabled && isFocused ? "#fff" : isFocused  && !isDisabled ? "#dbd7d7" : null,
              // color: isDisabled ? "#D0D0D0" : "black",
              color: isDisabled ? "#999999" : "black",
              cursor: isDisabled ? "not-allowed" : "#A9A9A9",
            };
          },
          multiValueRemove: (base, state) => {
            return state.data.security !== 1
              ? { ...base, display: "none" }
              : base;
          },
        }}
        {...props}
        blurInputOnSelect={isMulti ? false : true}
        closeMenuOnSelect={isMulti ? false : true}
        components={{
          Option: CustomOption,
        }}
        isClearable={isClearable ? isClearable : false}
        isDisabled={isReadOnly}
        // type={'number'}
      />
    );
  } else {
    return (
      <Select
        isSearchable={isSearchable}
        classes={classes}
        styles={{
          valueContainer: (base) => ({
            ...base,
            maxHeight: "64px",
            overflowY: "auto",
            overflow: "auto",
           
            // position: "absolute",
          }),
          singleValue: (provided) => ({
            ...provided,
            overflow: "visible",
          }),
        
         

          menuList: (provided) => {
            if (isMenuMaxHeight) {
              return {
                ...provided,
                height: "170px",
                overflowY: "scroll",
              };
            } else {
              return {
                ...provided,
              };
            }
          },
          control: (base) =>
            props.error
              ? {
                  ...base,
                  borderColor: props.error ? "#f44336" : "#C0C0C0",
                }
              : {
                  ...base,
                  borderColor: props.error ? "#f44336" : "#C0C0C0",
                  border: "1px solid #08456C",
                },
          //QA-5913
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              // backgroundColor: isSelected ? "#F0F0F0" : null,
              backgroundColor: isFocused ? "#dbd7d7" : null,
              // color: isDisabled ? "#D0D0D0" : "black",
              color: isDisabled ? "#999999" : "black",
              cursor: isDisabled ? "not-allowed" : "#A9A9A9",
            };
          },
          multiValueRemove: (base, state) => {
            return state.data.security !== 1
              ? { ...base, display: "none" }
              : base;
          },
        }}
        {...props}
        blurInputOnSelect={isMulti ? false : true}
        closeMenuOnSelect={isMulti ? false : true}
        components={{
          MultiValue,
        }}
        isClearable={isClearable ? isClearable : false}
        isDisabled={isReadOnly}
        // type={'number'}
      />
    );
  }
}
export default MultiSelect;
