import React, { useEffect, useState } from "react";
import NewTeamBoard from "./NewTeamBoard";
import { Grid, NativeSelect, Typography } from "@material-ui/core";
import SubmitCancelBtn from "../../../../../common/SubmitCancelBtn";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { loading } from "../../../../../../../src/components/common/Spinner";
import { withStyles } from "@material-ui/styles";

const styles = (theme) => ({
  individualBoard: {
    marginTop: "20px",
  },
  myDropdown_select: {
    fontSize: "20px !important",

    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
    },
  },
  myDropdown_option: {
    fontSize: "16px", // Default option font size
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Font size for small devices
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px !important", // Font size for extra small devices
    },
  },
  label_category: {
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  leaderboard_top: {
    width: "90%",
    margin: "auto",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  container_top: {
    /* width: 20%; */
    display: "flex",
    alignItems: "center",
    //  justifyContent:'space-around'
  },
  leaderboard_maleFemale: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: "20px 10px",

    [theme.breakpoints.down("sm")]: {
      //  width: '100%',
      //     display: "flex",
      //     justifyContent: "center",
      //     alignItems:'center',
      //    flexWrap: "wrap",
      //    marginTop: "20px"
      //    ,margin:"0px",
      flexDirection: "column",
    },
  },
  container_title: {
    display: "flex",
    alignItems: "center",
    //  justifyContent: 'space-between',
    /* border: 3px solid green, */
    textAlign: "center",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
  leaderboard_container: {
    flex: 0.48,
    //width: "45%",
    margin: "0 10px",
    // marginTop: "20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      width: "100%",
      margin: "0px",
    },
  },
  graphs: {
    width: "90%",
    display: "flex",
    marginLeft: "5%",

    [theme.breakpoints.down("sm")]: {
      margin: "0",
      padding: 0,
      width: "100%",
    },
  },
});

const LocationAndAgeWiseLeaderBoard = ({ radioValue, classes }) => {
  const [dropDownListData, setDropdownListData] = useState([
    { label: "Monthly Challenge", value: "Monthly Challenge" },
  ]);
  const [selectedDropdownMenu, setSelectedDropdownMenu] = useState({});
  const [render, setRender] = useState(false);

  const [selectDate , setSelectedDate] = useState();

  const [category, setCategoryData] = useState();

  const [customDatesOptions, setCustomDateOptions] = useState([
    {
      label: "17th Oct-16th Nov",
      value: "2024-10-17 2024-11-16",
      fromDate: "2024-10-17",
      toDate: "2024-11-16",
    },
    {
      label: "17th Nov-16th Dec",
      value: "2024-11-17 2024-12-16",
      fromDate: "2024-11-17",
      toDate: "2024-12-16",
    },
    {
      label: "17th Dec-18th Jan",
      value: "2024-12-17 2025-01-18",
      fromDate: "2024-12-17",
      toDate: "2025-01-18",
    },
  ]);

  const [selectedCustomDate, setSelectedCustomDate] = useState(
    customDatesOptions?.[0]
  );
  useEffect(() => {
    let timer;
    
    if (radioValue === "location") {
      loading(true);
      setCategoryData([]);
      timer = setTimeout(() => {
        setCategoryData([
          {
            label: "Max steps - Stores (Male)",
            id: 1,
            gender: "MALE",
            Location: "Store",
          },
          {
            label: "Max steps - Stores (Female)",
            id: 2,
            gender: "FEMALE",
            Location: "Store",
          },
          {
            label: "Max steps - DCs (Male)",
            id: 3,
            gender: "MALE",
            Location: "Distribution Centre",
          },
          {
            label: "Max steps - DCs (Female)",
            id: 4,
            gender: "FEMALE",
            Location: "Distribution Centre",
          },
          {
            label: "Max steps - Office (Male)",
            id: 5,
            gender: "MALE",
            Location: "Office",
          },
          {
            label: "Max steps - Office (Female)",
            id: 6,
            gender: "FEMALE",
            Location: "Office",
          },
        ]);
        setSelectedDate(customDatesOptions?.[0]);
        setSelectedCustomDate(customDatesOptions?.[0])

        loading(false);
      }, 300);
    } else {
      loading(true);
      setCategoryData([]);
      timer = setTimeout(() => {
        setCategoryData([
          {
            label: "Max steps (below 30 Years - Male)",
            id: 1,
            gender: "MALE",
            AgeGroup: "Below 30 years",
          },
          {
            label: "Max steps (below 30 Years - Female)",
            id: 2,
            gender: "FEMALE",
            AgeGroup: "Below 30 years",
          },
          {
            label: "Max steps (30 years 1 day to 42 years - Male)",
            id: 3,
            gender: "MALE",
            AgeGroup: "30 Years and 1 day to 42 years",
          },
          {
            label: "Max steps (30 years 1 day to 42 years - Female)",
            id: 4,
            gender: "FEMALE",
            AgeGroup: "30 Years and 1 day to 42 years",
          },
          {
            label: "Max steps (42 years 1 day to 55 years - Male)",
            id: 5,
            gender: "MALE",
            AgeGroup: "42 Years and 1 day to 55 years",
          },
          {
            label: "Max steps (42 years 1 day to 55 years - Female)",
            id: 6,
            gender: "FEMALE",
            AgeGroup: "42 Years and 1 day to 55 years",
          },
          {
            label: "Max steps (55 years 1 day and above - Male)",
            id: 7,
            gender: "MALE",
            AgeGroup: "55 years 1 day and above",
          },
          {
            label: "Max steps (55 years 1 day and above - Female)",
            id: 8,
            gender: "FEMALE",
            AgeGroup: "55 years 1 day and above",
          },
        ]);
        setSelectedDate(customDatesOptions?.[0]);
        setSelectedCustomDate(customDatesOptions?.[0])

        loading(false);
      }, 300);
    }

    return () => clearTimeout(timer); // Cleanup function to clear timeout
  }, [radioValue]);


  

  useEffect(() => {
    let timer;
    if (render) {
      loading(true);
      timer = setTimeout(() => {
        loading(false);
        setRender(false);
      }, 300);
    }
    return () => clearTimeout(timer);
  }, [render]);

  const onHandleDateChange = (event) => {
    let dateObj = customDatesOptions.find(
      (x) => x.value === event.target.value
    );
    setSelectedDate(event.target.value ? dateObj : null)
   
  };

  const handleClick = () => {
    setSelectedCustomDate(selectDate ? selectDate : null);
    setRender(true);
  };

  const onHandleDropDownSelectMenu = (event) => {
    let SelectedObj = dropDownListData?.find(
      (x) => x.value === event.target.value
    );
    setSelectedDropdownMenu(event.target.value ? SelectedObj : null);
  };

  return (
    <div>
      <Grid
        spacing={1}
        container
        direction="row"
        alignItems="center"
        wrap="wrap"
        justifyContent="center"
        // md={12}
        // className={classes.dropdownStyling}
        style={{ marginBottom: "10px", margin: "0 5px" }}
      >
        {/* select category */}

        <Grid
          item
          xs={4}
          sm={4}
          md={2}
          justifyContent="center"
          alignItems="center"
        >
          <Typography className={classes?.label_category || ""} gutterBottom>
            <span style={{ color: "#727272" }}>{"Select Category"}</span>
            <span style={{ color: "red" }}>*</span>
          </Typography>

          <NativeSelect
            id="myDropdown"
            value={selectedDropdownMenu?.value}
            onChange={(e) => onHandleDropDownSelectMenu(e)}
            className={classes.myDropdown_select || ""}
          >
            {dropDownListData?.map((value, index) => (
              <option
                value={value.value}
                key={index}
                className={classes.myDropdown_option || ""}
                style={{ padding: "0 2px" }}
              >
                {value.label}
              </option>
            ))}
          </NativeSelect>
        </Grid>

        {/* dependent on others */}

        <Grid
          item
          xs={4}
          sm={4}
          md={2}
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="body2"
            className={classes?.label_category}
            gutterBottom
          >
            <span style={{ color: "#727272" }}>{"Duration"}</span>
            <span style={{ color: "red" }}>*</span>
          </Typography>

          <NativeSelect
            id="myDropdown"
            value={selectDate?.value}
            onChange={(e) => onHandleDateChange(e)}
            className={classes?.myDropdown_select || ""}
          >
            {customDatesOptions?.map((value, index) => (
              <option
                value={value.value}
                key={index}
                className={classes?.myDropdown_option || ""}
                style={{ padding: "0 2px" }}
              >
                {value.label}
              </option>
            ))}
          </NativeSelect>
        </Grid>

        <Grid item xs={2} sm={4} md={2} style={{ textAlign: "center" }}>
          <SubmitCancelBtn
            type="submit"
            label={"Go"}
            onClick={() => handleClick()}
            style={{ width: "60%", margin: "auto", marginTop: "20px" }}
          />
        </Grid>
      </Grid>
      <div>
        <NewTeamBoard
          radioValue={radioValue}
          data={category}
          render={render}
          selectedCustomDate={selectedCustomDate}
        />
      </div>
    </div>
  );
};

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(LocationAndAgeWiseLeaderBoard);
