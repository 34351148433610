import http from "./httpService";
import { loading } from "../components/common/Spinner";
import Snackbar from "../components/common/Snackbar";
import Properties from "../properties/Properties";
var momentTimeZone = require("moment-timezone");
const API_URL_FILE = http.API_URL();

export function loginCheck(requestParams, time) {
  loading(true);
  let axiosConfig = {
    headers: {
      "time-stamp": time,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  let url = API_URL_FILE + "api/v1/login";

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status == 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);

        if (error.response && error.response.status == 401) {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response.data.error.verbose, // Properties.Label_Unauthorized,
          });
        } else if (error.response && error.response.status == 404) {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Label_InvalidCredentials,
          });
        } else if (error.response && error.response.status == 500) {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Label_OperationFailed,
          });
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }

        reject(error);
      });
  });
}

export function removeDuplicateAccounts(requestParams, time) {
  loading(true);
  let axiosConfig = {
    headers: {
      "time-stamp": time,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  let url = API_URL_FILE + "api/v1/update/contact";
  let arr = requestParams.userIds;
  arr = arr.toString();
  var formData = new FormData();
  formData.append("userIds", arr);
  formData.append("userName", requestParams.userName);
  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        loading(false);

        if (res.status == 200) {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: res.data.success.verbose,
          });
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error.response && error.response.status == 401) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: error.response.data.error.verbose, // Properties.Label_Unauthorized,
            });
          } else if (error.response && error.response.status == 404) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Label_InvalidCredentials,
            });
          } else if (error.response && error.response.status == 500) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Label_OperationFailed,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: error.response
                ? error.response.data
                  ? error.response.data.error.verbose
                  : Properties.Something_went_wrong
                : Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}

export function loginWithOTP(requestParams) {
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  let url = API_URL_FILE + "api/v1/login/otp";
  // requestParams.byEmail = true;
  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status == 200) {
          loading(false);
          if (res.data.success.code === "200") {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data.success.verbose,
            });
            resolve(res);
          } else if (res.data.success.code === "401") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data.success.verbose,
            });
            resolve(res);
          } else if (res.data.success.code === "404") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data.success.verbose,
            });
            resolve(res);
          } else if (res.data.success.code === "409") {
            resolve(res);
          } else if (res.data.success.code === "500") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data.success.verbose,
            });
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error.response && error.response.status == 401) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Label_InvalidCredentials,
            });
          } else if (error.response && error.response.status == 500) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Label_AccountNotActivated,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: error.response
                ? error.response.data
                  ? error.response.data.error.verbose
                  : Properties.Something_went_wrong
                : Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}

export function verifyOTP(requestParams) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  let url = API_URL_FILE + "api/v1/login/verify/otp";

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status == 200) {
          loading(false);

          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error.response && error.response.status === 400) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: error?.response?.data
                ? error?.response?.data
                : Properties.Label_InvalidCredentials,
            });
          } else if (error.response && error.response.status === 401) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Label_InvalidCredentials,
            });
          } else if (error.response && error.response.status === 500) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Label_AccountNotActivated,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: error?.response
                ? error?.response?.data
                  ? error?.response?.data?.error?.verbose
                  : Properties.Something_went_wrong
                : Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}
export function getOrganizerLogo(eventId) {
  loading(true);
  const timeZone = momentTimeZone.tz.guess();
  let axiosConfig = {
    headers: {
      timeZone: timeZone,
      userAgent: window?.clientInformation?.userAgent,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  let url = API_URL_FILE + "api/v1/p/event/" + eventId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status == 200) {
          loading(false);

          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error?.response?.data
                ? error?.response?.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
