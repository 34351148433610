import React, { Component } from "react";
import ReportImage1 from "../../../../../images/Report (1).png";
import ReportImage2 from "../../../../../images/Report (2).png";
const images = [
  { id: 1, image: ReportImage1 },
  { id: 2, image: ReportImage2 },
];
export class ReportDetails extends Component {
  render() {
    let id = localStorage.getItem("id");
    // console.log(images);
    return (
      <>
        {images.map((ele) => {
          if (id == ele.id) {
            return (
              <div>
                <img src={ele.image} width="100%"/>
              </div>
            );
          }
        })}
      </>
    );
  }
}

export default ReportDetails;
