import React from "react";

class RegistrationUtil {
  static userEmail = "";
  static userEventKm = "";
  static token = "";

  static setUserEmail = function(email) {
    this.userEmail = email;
  };

  static getUserEmail = function() {
    return this.userEmail;
  };

  static setToken = function(token) {
    this.token = token;
  };

  static getToken = function() {
    return this.token;
  };
  static getFileSize = fileSize => {
    let fileSizeInBytes = fileSize;
    let fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    let size = parseFloat(fileSizeInMB).toFixed(2);
    if (fileSizeInMB > 0 && !(size > 0)) {
      size = 0.01;
    }
    return size + " MB";
  };
}

export default RegistrationUtil;
