import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Divider } from "@material-ui/core";
import GaugeGraph from "../../../../common/Graphs/GaugeGraph";

import ThreeBarVerticalGraph from "../../../../common/Graphs/ThreeBarVerticalGraph";
import PieChartGraph from "../../../../common/Graphs/PieChartGraph";

function NoSugarChallenge({
  title,
  classes,
  GaugeGraphData,
  graphData,
  responseBasedChallenges,
  index,
}) {
  let filterData = GaugeGraphData?.find(
    (item) => item.programChallengeName === graphData?.programChallengeName
  );

  return (
    <div>
      <h2 className={classes.chartTitle}>{title} :</h2>
      <div className={classes.graphContainer}>
        <div className={"barGraphContainer"}>
          <PieChartGraph graphData={graphData}  responseBasedChallenges = {responseBasedChallenges}/>
        </div>

        <Divider
          orientation="vertical"
          flexItem
          style={{
            background: "#2C3539",
          }}
        />
        <div className={"engagementContainer"}>
          <GaugeGraph
            gapBetweenDateAndGraph={
              document.documentElement.clientWidth > 1400 ? 100 : 0
            }
            graphData={filterData?.engagementMeter || []}
            isSession={false}
            responseBasedChallenges = {responseBasedChallenges}
          />
        </div>
      </div>
    </div>
  );
}

const styles = (theme) => ({
  mainContainer: {
    fontFamily: '"Karla", sans-serif',
    margin: "5px",
    marginInline: "20px",
    [theme.breakpoints.down("sm")]: { marginInline: "5px" },
  },
  dashBoardTitle: {
    letterSpacing: "-1px",
    fontWeight: "bold",
    padding: 0,
    fontSize: "40px",
    marginTop: 0,
    marginBottom: "0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  chartTitle: {
    letterSpacing: "-1px",
    marginBottom: 0,
    color: "#202260",
    fontWeight: "bold",
    fontSize: "35px",
    marginLeft: "10px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      marginLeft: "0px",
    },
  },
  titleContainer: {
    // border: "1px solid",
    color: "#202260",
    borderColor: "#d1d1d1",
    textAlign: "center",
  },
  datesContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginInline: "10px",
    "& .date2": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    "& .scoreTitle": {
      marginTop: "5px",
      fontWeight: "600",
      fontSize: "26px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
  },
  graphContainer: {
    display: "flex",
    width: "100%",

    alignItems: "end",
    justifyContent: "space-between",
    "& .barGraphContainer": {
      width: "50%",
    },
    "& .engagementContainer": {
      width: "50%",
    },
  },

  childGraphContainer: {
    width: "86%",
  },
  arrowContainer: {
    width: "7%",
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(NoSugarChallenge);
