import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  Checkbox,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  deleteJobSyncedJob,
  getJobSyncedActivity,
  getJobSyncedHistoryOfProgramChallengeOrEvent,
} from "../../../../../apis/eventService";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import moment from "moment";
import ArrowDropDownCircleOutlined from "@material-ui/icons/ArrowDropDownCircleOutlined";
import Properties from "../../../../../properties/Properties";
import Snackbar from "../../../../common/Snackbar";
import DialogBox from "../../../../common/DialogBox";
import { useSelector } from "react-redux";

function OrganizerJobHistoryTab({ classes }) {
  const [data, setData] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkboxArr, setCheckboxArr] = useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState("");
  const [anchorEl, setAnchorEl] = useState("");
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [jobId, setJobId] = useState("");
  const [isRender, setIsRender] = useState(false);

  const supportSelectedEvent = useSelector(
    (state) => state?.supportUser?.supportSelectedEvent
  );
  let eventId = ApplicationUtil.getSupportsEventID();

  useEffect(() => {
    const isProgramChallenge = localStorage.getItem("isProgramChallenge");
    let params = {};

    if (isProgramChallenge) {
      //for program || program challenge
      const { id: challengeId } = supportSelectedEvent;
      params.programChallengeId = challengeId;
    } else {
      params.eventId = eventId;
    }

    //new API replaced with getJobSyncedActivity (eventService)
    getJobSyncedHistoryOfProgramChallengeOrEvent(params).then((res) => {
      if (res.data && res.data.length > 0) {
        let eventData = res.data;
        setData(eventData);
      } else {
        setData([]);
      }
    });
    setIsRender(false);
  }, [isRender]);

  const closeWindow = () => {
    setShowCancelDialog(false);
    setAnchorEl(null);
    setJobId(null);
  };

  const handleActivityDelete = () => {
    let jobIds = [];
    if (menuAnchorEl) {
      checkboxArr.forEach((e) => {
        if (e.check) {
          jobIds.push(e.id);
        } else {
          return;
        }
      });
    } else {
      jobIds = [jobId];
    }

    deleteJobSyncedJob(jobIds)
      .then((res) => {
        setIsRender(true);
        closeWindow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isSelected = (id) => {
    let i = checkboxArr.findIndex((x) => x.id === id);
    if (i !== -1) {
      return checkboxArr[i].check;
    }
    return false;
  };

  const handleSelectAll = (e) => {
    let val = e.target.checked;
    let arr = val
      ? data?.map((job) => ({
          id: job.id,
          check: job.status === "PENDING" ? true : false,
        }))
      : [];

    setCheckboxArr(arr);
    setIsAllChecked(val);
  };

  const handleSelect = (e, row) => {
    let arr = [...checkboxArr];
    let i = arr.findIndex((x) => x.id === row.id);
    if (i !== -1) {
      arr[i].id = row.id;
      arr[i].check = e.target.checked;
      setCheckboxArr(arr);
    } else {
      setCheckboxArr([
        ...checkboxArr,
        {
          check: e.target.checked,
          id: row.id,
        },
      ]);
    }
    setIsAllChecked(false);
  };

  const getDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.DeleteActivityWarning}</span>
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.main}>
      <FormControl className={classes.formControlAction}>
        <div className={classes.actions}>
          <span>Actions</span>
          <span>
            <ArrowDropDownCircleOutlined
              className={classes.editIcon}
              onClick={(event) => {
                setMenuAnchorEl(event.currentTarget);
              }}
            />
          </span>
          <Tooltip title="Refresh" placement="top-start">
            <RefreshIcon
              onClick={() => {
                setIsRender(true);
              }}
              className={classes.refreshTable}
            />
          </Tooltip>
        </div>
      </FormControl>

      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell component="th">
                <Checkbox
                  checked={isAllChecked}
                  onChange={handleSelectAll}
                  color="primary"
                />
                <IconButton className={classes.hideArrowDropdown}>
                  <ArrowDropDownCircleOutlined className={classes.editIcon} />
                </IconButton>
              </TableCell>
              <TableCell component="th">Job Name</TableCell>
              <TableCell component="th" align="center">
                From Date
              </TableCell>
              <TableCell component="th">To Date</TableCell>
              <TableCell component="th">Status</TableCell>
              <TableCell component="th">User</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length > 0 ? (
              data.map((row, index) => {
                const isItemSelected = isSelected(row?.id);
                return (
                  <TableRow key={index} hover>
                    <TableCell
                      component="td"
                      align="center"
                      style={{ height: "50px" }}
                    >
                      {row?.status && row?.status === "PENDING" ? (
                        <>
                          <Checkbox
                            checked={
                              isAllChecked ? isAllChecked : isItemSelected
                            }
                            onChange={(e) => handleSelect(e, row)}
                            color="primary"
                          />
                          <IconButton>
                            <ArrowDropDownCircleOutlined
                              className={classes.editIcon}
                              onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setJobId(row?.id);
                              }}
                            />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <Checkbox disabled />
                          <IconButton disabled>
                            <ArrowDropDownCircleOutlined />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                    <TableCell component="td" align="left">
                      {row?.jobName}
                    </TableCell>
                    <TableCell
                      component="td"
                      align={row?.startTime ? "left" : "center"}
                    >
                      {row?.startTime
                        ? moment
                            .utc(row.startTime, "DD-MM-YYYY HH:mm:ss")
                            .tz("Asia/Kolkata")
                            .format("DD-MM-YYYY HH:mm:ss")
                        : "-"}
                    </TableCell>
                    <TableCell
                      component="td"
                      align={row?.endTime ? "left" : "center"}
                    >
                      {row?.endTime
                        ? moment
                            .utc(row.endTime, "DD-MM-YYYY HH:mm:ss")
                            .tz("Asia/Kolkata")
                            .format("DD-MM-YYYY HH:mm:ss")
                        : "-"}
                    </TableCell>
                    <TableCell component="td" align="left">
                      {row?.status}
                    </TableCell>
                    <TableCell component="td" align="left">
                      {row?.userName}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No data found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* global menu */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{ marginTop: "45px", position: "absolute", zIndex: 0 }}
      >
        <MenuItem
          onClick={() => {
            let jobIds = [];
            checkboxArr.forEach((e) => {
              if (e.check) {
                jobIds.push(e.id);
              } else {
                return;
              }
            });
            if (jobIds.length > 0) {
              setShowCancelDialog(true);
            } else {
              Snackbar.show({
                variant: "error",
                isAutoHide: true,
                preventDuplicate: true,
                message: Properties.SelectActivityToDeleteLabel,
              });
            }
          }}
        >
          {Properties.DeleteActivityLabel}
        </MenuItem>
      </Menu>

      {/* row wise menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{ marginTop: "45px", position: "absolute", zIndex: 0 }}
      >
        <MenuItem
          onClick={() => {
            setShowCancelDialog(true);
          }}
        >
          {Properties.DeleteActivityLabel}
        </MenuItem>
      </Menu>

      {/* delete activity warning */}
      <DialogBox
        signup={true}
        open={showCancelDialog}
        message={getDialogMsg()}
        // header={dialogHeader}
        label={"No"}
        isSmall={true}
        onClick={() => {
          setShowCancelDialog(false);
        }}
        labelTwo={"Yes"}
        onClickTwo={() => {
          handleActivityDelete();
        }}
        closeDisloag={() => {
          setShowCancelDialog(false);
        }}
      />
    </div>
  );
}

const styles = (theme) => ({
  main: {
    padding: "10px",
    margin: "10px",
  },
  editIcon: {
    color: "#09456C",
    cursor: "pointer",
    marginLeft: "1px",
  },
  formControlAction: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  actions: {
    display: "flex",
    alignItems: "center",
  },
  hideArrowDropdown: {
    visibility: "hidden",
  },
  refreshTable: {
    color: "#2883B5",
    cursor: "pointer",
    margin: "10px",
  },
  tableHead: {
    backgroundColor: "#ced4da",
    "& th": {
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
    },
  },
});

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(OrganizerJobHistoryTab);
