//require("typeface-ubuntu");
import lightBlue from "@material-ui/core/colors/lightBlue";

export default function applicationTheme() {
  return {
    palette: {},
      breakpoints: {
        values: {
          xs: 300,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
    
    overrides: {
      MuiTableCell: {
        root: {
          padding: "5px"
        }
      },
      MuiTabs: {
        root: {
          borderBottom: "2px solid #444444 !important"
        }
      },
      PrivateTabIndicator: {
        root: {
          height: "3px !important",
          backgroundColor: "#F79824 !important"
        }
      },
      MuiOutlinedInput: {
        input: {
          padding:"18.5px 8px !important"
        }
      },
      MuiTypography: {
        gutterBottom: {
          marginBottom:"0px"
        }
      },
      MuiOutlinedInput: {
        root: {
    //fontSize: "14px",
    "& $notchedOutline": {
      borderWidth: 0,
    },
    "&:hover $notchedOutline": {
      borderWidth: 0,
    },
    "&$focused $notchedOutline": {
      borderWidth: 0,
    },
        },
      },
      MuiPickersToolbar: {
        Typography: {
          fontSize:"12px"
        },
        toolbar: {
//  padding:"12px",

        // backgroundColor: lightBlue.A200,
      },
      },
    //     MuiInputBase: {
    //     "&:focused": {
    //   borderWidth: 0,
    // }, "&:hover": {
    //   borderWidth: 0,
    // },
      //  {
      //   '&:hover': {
      //     backgroundColor: 'red',
      //   },
      // },
    // },
    // MuiPickersCalendarHeader: {
    //   switchHeader: {
    //     backgroundColor: lightBlue.A200,
    //     color: "white",
    //   },
    //   },
      MuiPickerDTToolbar: {
        toolbar: {
          paddingTop: "16px",
          paddingBottom: "16px",
        }
    }
    // MuiPickersDay: {
    //   day: {
    //     color: lightBlue.A700,
    //   },
    //   daySelected: {
    //     backgroundColor: lightBlue["400"],
    //   },
    //   dayDisabled: {
    //     color: lightBlue["100"],
    //   },
    //   current: {
    //     color: lightBlue["900"],
    //   },
    // },
    //   MuiPickersModal: {
    //     // position: "absolute",
    //   dialogAction: {
    //     color: lightBlue["400"],
    //   },
    // },
      // ...
      //   MuiOutlinedInput: {
      //     focused: {
      //       border: "1px solid red"
      //     },
      //     "& $notchedOutline": {
      //       border: "1px solid red"
      //     }
      //   }
      // ...
    },
    drawer: {
      drawerLeft: {
        width: "80%",
        height: "95%",
        maxWidth: "316px",
        borderTopRightRadius: "6px",
        bottom: 0,
        top: "auto"
      },
      drawerRight: {
        width: "80%",
        height: "95%",
        maxWidth: "316px",
        borderTopLeftRadius: "6px",
        bottom: 0,
        top: "auto"
      }
    },
    snackbar: {
      success: "#43a047",
      error: "#d32f2f",
      warning: "#ffa000",
      info: "#384769"
    },
    typography: {
      useNextVariants: true,
      fontFamily: ["Rubik"].join(","),
      fontSize: 14,
      htmlFontSize: 14,
      fontWeight: "normal"
    },
    // leftSpacing: 12,
    // rightSpacing: 12,
   
  };
}
