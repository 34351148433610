import React from "react";
import compose from "recompose/compose";
import { Grid, Typography, Avatar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { SpeakerNotes } from "@material-ui/icons";
const styles = (theme) => ({
  divStyling: {
    backgroundColor: "white",
    color: "black",
    borderRadius: "50px",
    height: "100%",
    paddingTop: "0px",
    paddingBottom: "0",
    paddingLeft: "20px",
    paddingRight: "0",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      paddingLeft: "10px",
    },
  },
  header: {
    color: "#3a6ea5",
    textAlign: "center",
    marginTop: "0",
    marginBottom: "5px",
  },
  profileImageStyling: {
    borderRadius: "50%",
    width: "100px",
    height: "100px",
  },
  nameStyling: {
    fontSize: "1rem",
    fontWeight: "bold",
    marginLeft: "5%",
    color: "#3a6ea5",
  },
  fieldsStyling: {
    fontSize: "1rem",
    color: "#424242",
  },

  listContainer: {
    display: "flex",
    alignItems: "center",
  },
  listStyling: {
    display: "flex",
    alignItems: "center",
  },
  list1Styling: {
    listStyle: "none",
    fontSize: "1rem",
    fontWeight: "normal",
    marginTop: "10px",
    marginBottom: "0px",
    color: "#424242",
    display: "flex",
    alignItems: "center",
    flex: "1",
  },
  list2Styling: {
    listStyle: "none",
    fontSize: "1rem",
    fontWeight: "normal",
    color: "#3a6ea5",
    marginTop: "10px",
    marginBottom: "0px",
    display: "flex",
    alignItems: "center",
    flex: "1",
  },
});
function ParticipantDetailsScanned({ details, classes }) {
  const g =
    details.gender?.charAt(0)?.toUpperCase() +
    details.gender?.slice(1)?.toLowerCase();
  const n = details.name?.charAt(0).toUpperCase();
  return (
    <div style={{}} className={classes.divStyling}>
      <Grid container direction="column" item xs={12} md={12} lg={12}>
        <Grid item style={{ display: "flex", alignItems: "center" }}>
          <Typography>
            <Avatar
              src={
                details.profileImage
                  ? `data:image/png;base64,${details.profileImage.imageData}`
                  : "/broken-image.jpg"
              }
              className={classes.profileImageStyling}
            >
              {n}
            </Avatar>
          </Typography>
          <Typography style={{}} className={classes.nameStyling}>
            <span>{details.name} </span>
            <br></br>
            <span style={{ color: "#424242" }}>{g}</span>{" "}
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: "7px" }}>
          <Typography className={classes.fieldsStyling}>
            {" "}
            {details.contact}
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: "7px" }}>
          <Typography className={classes.fieldsStyling}>
            {details.email}
          </Typography>
        </Grid>

        <Grid item style={{ marginTop: "7px" }}>
          <Typography className={classes.fieldsStyling}>
            {details.activity} - {details.category}
          </Typography>
        </Grid>
        <h4 style={{ color: "#3a6ea5", marginTop: "7px", marginBottom: "0" }}>
          {details?.additionalFields ? details.additionalFields : ""}
        </h4>
      </Grid>
      {details?.customFieldsValues &&
        details?.customFieldsValues?.customFields?.map((fieldsVal) => (
          <div className={classes.listContainer}>
            <p className={classes.list1Styling}>{fieldsVal?.displayName}</p>
            <p className={classes.list2Styling}>{fieldsVal?.fieldValue}</p>
          </div>
        ))}
    </div>
  );
}
export default compose(withStyles(styles, { withTheme: true }))(
  ParticipantDetailsScanned
);
