import http from "./httpService";
//import qs from "qs";
import { loading } from "../components/common/Spinner";
import RegistrationUtil from "../components/views/registration/RegistrationUtil";
import Snackbar from "../components/common/Snackbar";
import Properties from "../properties/Properties";
import ApplicationUtil from "../utils/ApplicationUtil";
const API_URL_FILE = http.API_URL();

export function getGraphDetails(runnerId, eventId) {
  loading(true);
  const isOrganizer_or_Support = window.location.href.includes("/organizer");

  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = isOrganizer_or_Support ? supportOrganizer_token : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url =
    API_URL_FILE + "api/v1/runner/activity/graph/" + eventId + "/" + runnerId; //eventId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });

          loading(false);
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);

        console.log(error.response);
      });
  });
}
export function getMultipleGraphDetails(runnerId, eventId) {
  loading(true);
  const isOrganizer_or_Support = window.location.href.includes("/organizer");

  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = isOrganizer_or_Support ? supportOrganizer_token : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url =
    API_URL_FILE + "api/v1/runner/activity/team/graph/" + eventId + "/" + runnerId; //eventId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });

          loading(false);
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);

        console.log(error.response);
      });
  });
}
export function getIndividualGraphDetails(runnerId, eventId) {
  loading(true);
  const isOrganizer_or_Support = window.location.href.includes("/organizer");

  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = isOrganizer_or_Support ? supportOrganizer_token : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url =
    API_URL_FILE + "api/v1/runner/activity/individual/graph/" + eventId + "/" + runnerId; //eventId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });

          loading(false);
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);

        console.log(error.response);
      });
  });
}
export function getRunnerGraphDetails(runnerId, eventId) {
  loading(true);
  const isOrganizer_or_Support = window.location.href.includes("/organizer");

  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = isOrganizer_or_Support ? supportOrganizer_token : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url =
    API_URL_FILE + "api/v1/runner/activity/runner/graph/" + eventId + "/" + runnerId; //eventId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });

          loading(false);
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);

        console.log(error.response);
      });
  });
}