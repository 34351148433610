import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";

import Leaderboard from "../../registration/events/viewResults/Leaderboard/Leaderboard";
import { withRouter, useHistory } from "react-router-dom";

import {
  getEventDetailsWithDistkey,
  getMapData,
  getMapRoute,
} from "../../../../apis/eventService";
import { NoLeaderboard } from "../../../common/SvgIcon";
import RelayLeaderboard from "../../registration/events/viewResults/Relay/RelayLeaderboard";
import Properties from "../../../../properties/Properties";
import RouteMap from "../../registration/events/viewResults/RouteMap/RouteMap";
import RunnerMap from "../../registration/events/viewResults/RouteMap/RunnerMap";
import { CircularProgress, Paper } from "@material-ui/core";
import Searchbar from "../../registration/events/viewResults/Searchbar";
import EventMap from "../../registration/events/viewResults/RouteMap/EventMap";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import { connect } from "react-redux";
import {
  updateSupportDTODetails,
  updateSupportSelectedEvent,
} from "../../../../redux/supportUser";
import moment from "moment";
import ChallengeSearchBar from "../../registration/events/viewResults/ChallengeResponseResult/ChallengeSearchBar";
const styles = (theme) => ({
  viewresults: {
    // overflow: "hidden",
    height: "100vh",
  },
  mapContainer: {
    width: "90%",
    margin: "auto",
    paddingBottom: "10px",
  },
  noLeaderboard: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "100px",
  },
  viewMapLabel: {
    color: "rgb(40, 131, 181)",
    textAlign: "center",
    marginTop: "20px",
  },
  noMap: {
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: 600,
  },
  loadingContainer: {
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: 600,
  },
});
class OrganizerLeaderBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventId: null,
      eventName: "",
      eventResData: null,
      firstLoad: true,
      programChallengeResData: null,
      showChallengeResults: true,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    let data = ApplicationUtil.getSupportsEventsDetails();
    let eventId = data?.id;
    let sidebar = localStorage.getItem("sidebarSelected");

    let eventName = data?.name;
    document.title = `Results - ${eventName}`;
    if (eventId) {
      this.setState({
        eventId: eventId,
        eventName: eventName,
      });
    } else {
      history.push("/organizer/eventlist");
    }
    {
      /* set the eventResData based on programChallenge and event */
    }
    const isProgramChallenge =
      data?.programChallengeType === "HYDRATION" ||
      data?.programChallengeType === "NO_UNHEALTHY_EATING" ||
      data?.programChallengeType === "ZERO_SUGAR";

    if (isProgramChallenge) {
      this.setState({
        eventResData: data,
        programChallengeResData: isProgramChallenge,
      });
    }

    let distKey = data?.distKey;
    if (distKey) {
      getEventDetailsWithDistkey(distKey)
        .then((res) => {
          if (res.status === 200) {
            let isLive = moment().isAfter(res.data?.eventLocalEndDate);
            let eventDateTime = moment(
              res.data?.eventDate + " " + res.data?.eventTime
            );
            let current = moment();
            let timeRemained = eventDateTime.diff(current);
            this.props.updateSupportSelectedEvent(res.data);
            getMapData(eventId).then((relayRes) => {
              this.setState({
                relayMapData: {
                  live: isLive,
                  flightPlanCoordinates: relayRes?.data?.coOrdinatePointsJson
                    ? JSON.parse(relayRes?.data?.coOrdinatePointsJson)
                    : null,
                  center: relayRes?.data?.centerPoint?.coOrdinatePoint
                    ? relayRes?.data?.centerPoint?.coOrdinatePoint
                    : null,
                  defaultCenter: relayRes?.data?.centerPoint?.coOrdinatePoint,
                  features: relayRes?.data?.markerPointDTOs
                    ? relayRes?.data?.markerPointDTOs
                    : null,
                  timeRemained: timeRemained,
                },
              });
            });
            this.setState({
              eventResData: res.data,
              distKey: distKey,
            });
          }
        })
        .catch((error) => {
          if (error) {
            history.push("/organizer/eventlist");
          }
        });
    }
  }
  updateLeaderboardCategory = (activityType, catId) => {
    this.setState({
      updatedCat: {
        activityType: activityType,
        catId: catId,
      },
    });
  };
  getEventLevelMap = (type, id) => {
    const { eventResData, firstLoad, hideSearchBar, runnerId } = this.state;

    if (eventResData && eventResData.mapGenerated && firstLoad) {
      let requestParams = {
        eventId: this.state.eventId,
      };
      if (hideSearchBar === "true") {
        requestParams.runnerId = runnerId;
      }
      getMapRoute(requestParams).then((res) => {
        if (res?.data?.runnerRoutePositionDetails) {
          this.setState({
            flightPlanCoordinates: res?.data?.runnerRoutePositionDetails
              ? res?.data?.runnerRoutePositionDetails
              : null,

            features: res?.data?.runnerRoutePositionDetails,
            categoryDTOs: res?.data?.categoryDTOs
              ? res?.data?.categoryDTOs
              : null,

            //for runner/category level map
            categoryDTO: res?.data?.categoryDTO,

            mapType: type,
            zoomLevel: res.data?.zoomLevel >= 0 ? res.data?.zoomLevel : null,
            defaultCenter: res.data?.startPoint ? res.data?.startPoint : null,
            counter:
              // this.state.counter === 0 ? 0 :
              this.state.counter + 1,
            firstLoad: false,
            loading: false,
          });
        }
      });
    }
  };
  getSearchedMapResults = (type, id) => {
    const { eventResData, showRunnerMap, counter, firstLoad } = this.state;
    this.setState({ loading: true });
    if (eventResData && eventResData.mapGenerated && !firstLoad) {
      let requestParams = {
        eventId: this.state.eventId, //eventId,
        // runnerId: 1273,
        // catergoryId: 2028,
      };
      let isTrue = counter > 0 ? true : showRunnerMap ? false : true;

      if (isTrue && type === "CATEGORY") {
        requestParams.categoryId = id;
      } else if (type === "RUNNER" && id) {
        requestParams.runnerId = id;
      } else if (type === "BIB" && id) {
        requestParams.bibNumber = id;
      }
      if (
        requestParams.runnerId ||
        requestParams.bibNumber ||
        requestParams.categoryId ||
        type === "EVENT"
      ) {
        getMapRoute(requestParams).then((res) => {
          if (res?.data?.runnerRoutePositionDetails) {
            this.setState({
              flightPlanCoordinates: res?.data?.runnerRoutePositionDetails
                ? res?.data?.runnerRoutePositionDetails
                : null,

              features: res?.data?.runnerRoutePositionDetails,
              categoryDTOs: res?.data?.categoryDTOs
                ? res?.data?.categoryDTOs
                : null,
              categoryDTO: res?.data?.categoryDTO,

              mapType: type,
              zoomLevel: res.data?.zoomLevel >= 0 ? res.data?.zoomLevel : null,
              defaultCenter: res.data?.startPoint ? res.data?.startPoint : null,
              counter:
                // this.state.counter === 0 ? 0 :
                this.state.counter + 1,
              // showRunnerMap: false,
              loading: false,
            });
          } else {
            this.setState({
              defaultCenter: null,
              loading: false,
            });
          }
        });
      }
    } else if (eventResData?.mapGenerated) {
      this.getEventLevelMap("EVENT");
    }
  };

  render() {
    const { classes } = this.props;
    const {
      eventName,
      programChallengeResData,
      eventId,
      eventResData,
      flightPlanCoordinates,
      features,
      categoryDTOs,
      categoryDTO,
      zoomLevel,
      defaultCenter,
      counter,
      mapType,
      loading,
      relayMapData,
      showChallengeResults,
    } = this.state;

    return (
      <div>
        {/* {eventResData?.summaryGenerated ? ( */}
        <>
          {eventResData?.challengeType === "RELAY" ? (
            <RelayLeaderboard data={relayMapData} />
          ) : (
            <>
              {!programChallengeResData && (
                <Searchbar
                  eventId={eventId}
                  showResults={eventResData?.showSearchResultsOnResultsView}
                  eventType={eventResData?.type}
                  getSearchedMapResults={this.getSearchedMapResults}
                  updateLeaderboardCategory={this.updateLeaderboardCategory}
                />
              )}
              {programChallengeResData && (
                <ChallengeSearchBar
                  challengeId={eventId}
                  showChallengeResults={
                    eventResData?.programChallengeSettings
                      ?.showSearchBarOnResultsView === "true" &&
                    showChallengeResults
                  }
                  clearResult={(data) => {
                    if (data && Object.keys(data).length > 0) {
                      this.setState({ showChallengeResults: false });
                    } else {
                      this.setState({ showChallengeResults: true });
                    }
                  }}
                />
              )}
              {/* {eventResData?.summaryGenerated   && ( */}
              <Leaderboard
                challengeType={eventResData}
                mapType={mapType}
                getSearchedMapResults={this.getSearchedMapResults}
                categoryDTO={this.state.updatedCat}
              />

              {/* <Map /> */}
              {eventResData && eventResData.mapGenerated && (
                <>
                  <Paper elevation={3} className={classes.mapContainer}>
                    {(this.state.mapType === "RUNNER" ||
                      this.state.mapType === "BIB") && (
                      <h1 className={classes.viewMapLabel}>
                        {Properties.MapRouteLabel}
                      </h1>
                    )}
                    {mapType === "CATEGORY" && (
                      <h1 className={classes.viewMapLabel}>
                        {Properties.ParticipantLocationLabel}
                      </h1>
                    )}
                    {mapType === "EVENT" && (
                      <h1 className={classes.viewMapLabel}>
                        {Properties.ParticipantLocationLabel}
                      </h1>
                    )}
                    {loading ? (
                      <div className={classes.loadingContainer}>
                        {/* loading... */}
                        <CircularProgress />
                      </div>
                    ) : (
                      <>
                        {(this.state.mapType === "RUNNER" ||
                          this.state.mapType === "BIB") && (
                          <div key={counter}>
                            {(flightPlanCoordinates !== undefined ||
                              features !== undefined) &&
                            defaultCenter !== null ? (
                              <div className={classes.map}>
                                <RunnerMap
                                  flightPlanCoordinates={
                                    flightPlanCoordinates[0].routeJSON
                                      ? JSON.parse(
                                          flightPlanCoordinates[0].routeJSON
                                        )
                                      : null
                                  }
                                  categoryDTO={categoryDTO}
                                  features={features}
                                  defaultCenter={JSON.parse(defaultCenter)}
                                  zoomLevel={zoomLevel}
                                  activity={
                                    flightPlanCoordinates[0].runnerActivityDTO
                                  }
                                />
                              </div>
                            ) : (
                              <div className={classes.noMap}>
                                {Properties.MapNotAvailable}
                              </div>
                            )}
                          </div>
                        )}
                        {mapType === "CATEGORY" && (
                          <div key={counter}>
                            {defaultCenter !== null ? (
                              <div className={classes.map}>
                                <RouteMap
                                  flightPlanCoordinates={
                                    categoryDTOs?.routeCoordinates
                                      ? JSON.parse(
                                          categoryDTOs.routeCoordinates
                                        )
                                      : null
                                  }
                                  categoryDTO={categoryDTO}
                                  features={features}
                                  defaultCenter={
                                    defaultCenter
                                      ? JSON.parse(defaultCenter)
                                      : null
                                  }
                                  zoomLevel={zoomLevel}
                                />
                              </div>
                            ) : (
                              <div className={classes.noMap}>
                                {Properties.MapNotAvailable}
                              </div>
                            )}
                          </div>
                        )}

                        {mapType === "EVENT" && (
                          <div key={counter}>
                            {defaultCenter !== null ? (
                              <div className={classes.map}>
                                <EventMap
                                  flightPlanCoordinates={
                                    categoryDTOs?.routeCoordinates
                                      ? JSON.parse(
                                          categoryDTOs.routeCoordinates
                                        )
                                      : null
                                  }
                                  categoryDTOs={categoryDTOs}
                                  features={features}
                                  defaultCenter={
                                    defaultCenter
                                      ? JSON.parse(defaultCenter)
                                      : null
                                  }
                                  zoomLevel={zoomLevel}
                                />
                              </div>
                            ) : (
                              <div className={classes.noMap}>
                                {Properties.MapNotAvailable}
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </Paper>
                </>
              )}
            </>
          )}
        </>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    supportDTO: state.supportUser.supportDTO,
    supportSelectedEvent: state.supportUser.supportSelectedEvent,
  };
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    updateSupportDTODetails,
    updateSupportSelectedEvent,
  }),
  withStyles(styles, { withTheme: true })
)(OrganizerLeaderBoard);
