import React, { Component, useEffect } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardMedia, CardContent } from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Grid } from "@material-ui/core";
import SubmitCancelBtn from "../../../../../common/SubmitCancelBtn";
import { updateUserSelectedEvent } from "../../../../../../redux/user";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
var moment = require("moment");
const styles = (theme) => ({
  card: {
    maxWidth: "850px",
    width: "100%",
    padding: 0,
    margin: "auto",
    marginBottom: "10px",
  },
  media: {
    width: "60%",
    maxWidth: "60%",
    minWidth: "100%",
    margin: "auto",
    height: "230px",
    padding: "5px",
    objectFit: "cover",
    borderRadius: "8px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      padding: "7px",
      height: "220px",
    },
  },

  activityStyling: {
    height: "0",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
    },
  },
  activityStyling2: {
    height: "0",
    fontWeight: "bold",
    marginTop:'8px',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
    },
  },

  activityStylingBottom: {
    fontSize: "1.4rem",
    textAlign: "left",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      fontSize: "1.0rem",
      height: "30%",
      margin: 0,
    },
  },
  activityStylingBottom2: {
    fontSize: "1.4rem",
    textAlign: "left",
    fontWeight: "600",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      fontSize: "1.0rem",
      height: 0,
      marginTop: "0px",
    },
  },

  headStyle: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
  },

  thumbswrapper: {
    // height: "100%",
    "& .thumbs-wrapper": {
      margin: 0,
    },
  },
  arrowStyles: {
    position: "absolute",
    zIndex: 2,
    top: "calc(45% - 15px)",
    backgroundColor: "white",

    width: 30,
    height: 30,
    fontSize: 40,
    cursor: "pointer",
    borderRadius: "50%",
    border: "1px solid grey",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
    [theme.breakpoints.down("sm")]: {
      width: 0,
      height: 0,
      display: "none",
      top: "calc(12% - 15px)",
    },
  },

  btn: {
    [theme.breakpoints.down("sm")]: {
      height: "20px",
    },
  },
  btnContainer: {
  marginLeft:0,
    flexDirection:'column',
    justifyContent: "center", alignItems:'center',
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginTop: "20px",
       
      alignItems:'center',
    },
  },
  card1: {
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "left",
    margin: "1% 0 0 2%",
    fontSize: "1.4rem",
    color: "white",
  },
  card2: {
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "left",
    margin: "1% 0 0 10%",
    fontSize: "1.4rem",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      margin: "1% 0 0 2%",
    },
  },
});
function EventDescriptionCard(props) {
  const { classes, data } = props;
  const challengeParam =
    data?.activities[0]?.challengeParams?.charAt(0) +
    data?.activities[0]?.challengeParams
      ?.toLowerCase()
      .slice(1, data?.activities[0]?.challengeParams?.length);

  const date = new Date();
  let diff = moment(date, "YYYY-MM--DD").diff(
    moment(data?.eventLocalEndDate, "YYYY-MM--DD"),
    "day"
  );
  let totalDaysPast = moment(data?.eventLocalEndDate, "YYYY-MM--DD").diff(
    moment(data?.eventLocalStartDate, "YYYY-MM--DD"),
    "day"
  );
  let totalDaysCurr = moment(date, "YYYY-MM--DD").diff(
    moment(data?.eventLocalStartDate, "YYYY-MM--DD"),
    "day"
  );
  function formatRelayValues(number) {
    const numberString = String(number);
    const parts = numberString.split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1] ? `.${parts[1]}` : "";

    const formattedIntegerPart = integerPart.replace(
      /(\d)(?=(\d\d)+\d$)/g,
      "$1,"
    );

    return formattedIntegerPart + decimalPart;
  }
  const navigateToLiveRelayMap = () => {
    const { history } = props;
    props.updateUserSelectedEvent(data);
    history.push("/low2highmap");
  };
  const todaysDistance = parseFloat(data?.todaysTotalDistance / 1000).toFixed(
    2
  );
  const totalDistance = parseFloat(data?.totalDistanceCovered / 1000).toFixed(
    2
  );
  const todaysRelayDistance = parseFloat(
    data?.todaysRelayDistance / 1000
  ).toFixed(2);
  const totalRelayDistance = parseFloat(
    data?.totalRelayDistance / 1000
  ).toFixed(2);
  
  const slides = [
    {
      id: 1,
      content: (
        <CardMedia className={classes.cardmedia}>
          {" "}
          <div
            className={classes.media}
            style={{
              background:
                "linear-gradient(0.215turn, #023e8a, #0077b6, #00b4d8)",
            }}
          >
            <Grid spacing={1} container className={classes.card1}>
              <Grid container className={classes.activityStyling}>
                {data?.todaysTotalDistance > 0 && (
                  <Grid item xs={4} md={4} lg={4}>
              
                      <>
                        <span>Today's {challengeParam}</span><br/>
                         
                        <span>
                          { (challengeParam == "Distance" &&
                          data?.todaysTotalDistance &&
                          todaysDistance >= 10000)
                            ? formatRelayValues(todaysDistance)
                            :
                              challengeParam == "Distance" &&
                              data?.todaysTotalDistance &&
                              todaysDistance <= 10000
                            ? todaysDistance
                            : 0
                            }
                          <span style={{ marginLeft: "2px" }}>km</span>
                        </span>
                      </>
                   
                  </Grid>
                )}
                <Grid item xs={4} md={12} lg={6}>
                  <span>
                    Total  {challengeParam} 
                  
                  </span><br/>
                  <span>
                    {(challengeParam == "Distance" &&
                    data?.totalDistanceCovered &&
                    totalDistance >= 10000)
                      ? formatRelayValues(totalDistance)
                      : challengeParam == "Distance" &&
                        data?.totalDistanceCovered &&
                        totalDistance < 10000
                      ? totalDistance
                      : 0}
                    <span style={{ marginLeft: "2px" }}>km</span>
                  </span>
                </Grid>
              </Grid>
              
              <Grid container className={classes.activityStyling}>
                <Grid item xs={4} md={3} lg={4}>
                  <span>Total Days</span>
                  <br />
                  {diff >= 0 ? (
                    <span>{totalDaysPast + 1}</span>
                  ) : (
                    <>
                      <span>{totalDaysCurr + 1}</span>/
                      <span>{totalDaysPast + 1}</span>
                    </>
                  )}
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <span>Total Registrations</span><br/>
                  <span>
                    {data?.totalRegistrations ? data?.totalRegistrations : 0}
                  </span>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <span className={classes.activities}>Total </span>
                  <span className={classes.activities}>Activities</span><br/>
                  <span>
                    {data?.totalActivityRecieved
                      ? data?.totalActivityRecieved
                      : 0}
                  </span>
                </Grid>
              </Grid>
           
            </Grid>
          </div>
        </CardMedia>
      ),
    },
    {
      id: 2,
      content: (
        <CardMedia className={classes.cardmedia}>
          {" "}
          <div
            className={classes.media}
            style={{
              background:
                "linear-gradient(0.215turn, #007f5f, #55a630, #aacc00)",
            }}
          >
            <Grid
              spacing={1}
              container
              className={classes.card2}
              style={{ height: !data?.todaysRelayDistance ? "60%" : "" }}
            >
              {data?.todaysRelayDistance &&
               
                challengeParam == "Distance" && (
                  <Grid item xs={6} lg={6} className={classes.activityStyling}>
                  
                      <>
                        <span>Ashish Kasodekar's Today's {challengeParam}</span>
                        <br />

                        <span
                          style={{ margin: "1% 0 0 0", marginBottom: "2%" }}
                        >
                          {data?.todaysRelayDistance &&
                          todaysRelayDistance >= 10000
                            ? formatRelayValues(todaysRelayDistance)
                            :
                              challengeParam == "Distance" &&
                              data?.todaysRelayDistance &&
                              todaysRelayDistance < 10000
                            ? todaysRelayDistance
                            : 0}
                        </span>
                        <span style={{ marginLeft: "2px" }}>km</span>
                      </>
                    
                  </Grid>
                )}

              <Grid
                item
                xs={data?.todaysRelayDistance ? 5 : 8}
                lg={data?.todaysRelayDistance ? 6 : 12}
                style={{ height: 0 }}
              >
                <SubmitCancelBtn
                  type="submit"
                  label={"Ashish's Live Map"}
                  onClick={navigateToLiveRelayMap}
                  className={classes.btn}
                  style={{
                    backgroundColor: "white",
                    color: "grey",

                    textAlign: "center",
                    fontSize: window.innerWidth < 600 ? "0.8rem" : "1.2rem",
                    letterSpacing: "2px",
                    height:
                      data?.todaysRelayDistance && window.innerWidth < 600
                        ? "53px"
                        : window.innerWidth < 600
                        ? "40px"
                        : "32px",
                    marginLeft:
                      data?.todaysRelayDistance && window.innerWidth > 600
                        ? "15%"
                        : data?.todaysRelayDistance && window.innerWidth < 600
                        ? "20px"
                        : window.innerWidth > 600
                        ? "53%"
                        : "49%",
                  }}
                />
              </Grid>
              <Grid container className={classes.activityStyling2}>
              { data?.totalRelayDistance && (
              <Grid item xs={6} lg={6}>              
                    <>
                      <span>Ashish Kasodekar's Total {challengeParam}</span>    <br/>    
                      <span>
                    {challengeParam == "Distance" &&
                    data?.totalRelayDistance &&
                    totalRelayDistance >= 10000
                      ? formatRelayValues(totalRelayDistance)
                      : challengeParam == "Distance" &&
                        data?.totalRelayDistance &&
                        totalRelayDistance < 10000
                      ? totalRelayDistance
                      : 0}
                    <span style={{ marginLeft: "2px" }}>km</span>
                  </span>            
                    </>
             
                </Grid>
      )}
                <Grid item xs={6} lg={6}>
                  <span>Relay Days</span>
                  <br />
                  {diff >= 0 ? (
                    <span className={classes.headStyle}>
                      {totalDaysPast + 1}
                    </span>
                  ) : (
                    <>
                      <span className={classes.headStyle}>
                        {totalDaysCurr + 1}
                      </span>
                      /
                      <span className={classes.headStyle}>
                        {totalDaysPast + 1}
                      </span>
                    </>
                  )}
                </Grid>
              </Grid>
        
             
            </Grid>
            {/*
           
            */}
          </div>
        </CardMedia>
      ),
    },
  ];
  const visibleSlides = slides.filter((slide) => {
    return slide.id == 1 || (slide.id == 2 ? data?.totalRelayDistance : null);
  });

  return (
    <Card className={classes.card}>
      <CardContent style={{ padding: "2px" }}>      
          <Carousel
            className={classes.thumbswrapper}
            showStatus={false}
            showThumbs={false}
            swipeable={visibleSlides?.length > 1}
            showArrows={visibleSlides?.length > 1}
            showIndicators={visibleSlides?.length > 1}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  className={classes.arrowStyles}
                  title={label}
                  style={{ left: 15 }}
                >
                  <ArrowBackIosIcon
                    style={{ color: "grey" }}
                    className={classes.leftArrow}
                  />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  className={classes.arrowStyles}
                  style={{ right: 15 }}
                >
                  <ArrowForwardIosIcon style={{ color: "grey" }} />
                </button>
              )
            }
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              const defStyle = {
                color: "lightgrey",
                cursor: "pointer",
                marginTop: "0",
              };
              const style = isSelected
                ? { ...defStyle, color: "#495057" }
                : { ...defStyle };
              return (
                <span
                  style={style}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  aria-label={`${label} ${index + 1}`}
                >
                  <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                </span>
              );
            }}
          >
            {visibleSlides.map((slide) => (
              <div key={slide.id}>{slide.content}</div>
            ))}
          </Carousel>       
      </CardContent>
    </Card>
  );
}
function mapStateToProps(state) {
  return {
    userSelectedEvent: state.user.userSelectedEvent,
    updateUserPreferences: state.user.updateUserPreferences,
  };
}
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateUserSelectedEvent,
  })
)(EventDescriptionCard);
