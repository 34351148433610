import http from "./httpService";
import { loading } from "../components/common/Spinner";

import Snackbar from "../components/common/Snackbar";
import Properties from "../properties/Properties";
import ApplicationUtil from "../utils/ApplicationUtil";
const API_URL_FILE = http.API_URL();

//
export function challengeRegister(requestParams, eventId) {
  loading(true);
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let obj = JSON.stringify(requestParams.openEventRequest);
  let url = API_URL_FILE + "api/v1/openChallenge/register/" + eventId;
  var formData = new FormData();
  formData.append("openEventRequest", obj);

  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          resolve(res);
          if (res.data?.error?.code === "200") {
            let str = res.data?.error?.verbose.split(",");
            let eventId = str[1];
            localStorage.setItem("eventId", eventId);
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: str[0],
            });
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error?.response?.status == 600) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: false,
              message: error?.response?.data
                ? error?.response?.data
                : Properties.Something_went_wrong,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}

export function programChallengeRegister(runnerId, programId) {
  loading(true);
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/program/challenge/register";
  var formData = new FormData();
  formData.append("runnerId", runnerId);
  formData.append("programId", programId);

  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          resolve(res);
          if (res.data?.error?.code === "200") {
            //logic
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error?.response?.status == 600) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: false,
              message: error?.response?.data
                ? error?.response?.data
                : Properties.Something_went_wrong,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}

export function openEventChallenges(orgId, state) {
  let token = null;
  if (ApplicationUtil.getRunnerToken() != null) {
    token = ApplicationUtil.getRunnerToken();
  } else if (ApplicationUtil.getSupportToken()) {
    token = ApplicationUtil.getSupportToken();
  }
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
  let url = "";

  if (orgId) {
    if (state) {
      url =
        API_URL_FILE +
        "api/v1/openChallenges?organizerId=" +
        orgId +
        "&state=" +
        state;
    }
    url = API_URL_FILE + "api/v1/openChallenges?organizerId=" + orgId;
  } else {
    if (state) {
      url = API_URL_FILE + "api/v1/openChallenges?state=" + state;
    } else {
      url = API_URL_FILE + "api/v1/openChallenges";
    }
  }

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

// sent otp api
export function openEventChallengeUserCheck(generateOTP, email, contactNumber) {
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      // Authorization:""
    },
  };

  let url = API_URL_FILE + "api/v1/openChallenge/userCheck";
  var formData = new FormData();

  if (email) {
    formData.append("email", email);
  }

  if (contactNumber) {
    formData.append("mobile", contactNumber);
  }
  formData.append("generateOTP", generateOTP);

  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//for getting data from orgKey
export function getChallengeCustomFee(eventId, requestParam) {
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  let url =
    API_URL_FILE +
    "api/v1/openChallenges/fees/" +
    eventId +
    "?challengeDuration=" +
    requestParam.challengeDuration;
  if (requestParam.organizerId) {
    url =
      API_URL_FILE +
      "api/v1/openChallenges/fees/" +
      eventId +
      "?organizerId=" +
      requestParam.organizerId +
      "?challengeDuration=" +
      requestParam.challengeDuration;
  }

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
//for getting data from orgKey
export function getChallengeDetailsWithOrgkey(orgKey) {
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      // distKey: distkey,
    },
  };

  let url = API_URL_FILE + "api/v1/nd/organizer/?organizerKey=" + orgKey;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//get list of participants for openChallenge
export function getRegistrationsForOpenChallenge(requestParams) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };
  let challengeId = localStorage.getItem("supportOpenChallengeId");
  let url;
  if (requestParams) {
    url =
      `${API_URL_FILE}api/v1/openChallenge/participants?openSubscriptionEventId=${challengeId}` +
      "&pageNumber=" +
      requestParams.pageNumber +
      "&pageCount=" +
      requestParams.pageCount;
  }
  if (requestParams) {
    url = `${API_URL_FILE}api/v1/openChallenge/participants?openSubscriptionEventId=${challengeId}${
      requestParams.pageCount ? "&pageCount=" + requestParams.pageCount : ""
    }${
      requestParams.pageNumber >= 0
        ? "&pageNumber=" + requestParams.pageNumber
        : ""
    }${
      requestParams.startDate
        ? "&startDate=" +
          requestParams.startDate +
          "&endDate=" +
          requestParams.endDate
        : ""
    }${
      requestParams.activityTypeId
        ? "&activityTypeId=" + requestParams.activityTypeId
        : ""
    }${
      requestParams.categoryId
        ? "&eventCategoryId=" + requestParams.categoryId
        : ""
    }${requestParams.bib ? "&bib=" + requestParams.bib : ""}${
      requestParams.name ? "&runnerName=" + requestParams.name : ""
    }${requestParams.email ? "&email=" + requestParams.email : ""}`;
  } else {
    url =
      API_URL_FILE +
      "api/v1/openChallenge/participants?openSubscriptionEventId=" +
      challengeId;
  }

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//get list of participants for openChallenge
export function getChallengeExecutionList(requestParams) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };
  let challengeId = localStorage.getItem("supportOpenChallengeId");
  let url;
  // http://localhost:88/api/v1/openChallengeEvent/excecution/eventParticipants?openSubscriptionEventId=1&pageCount=20&pageNumber=0

  if (requestParams) {
    url = `${API_URL_FILE}api/v1/openChallengeEvent/excecution/eventParticipants?openSubscriptionEventId=${challengeId}${
      requestParams.pageCount ? "&pageCount=" + requestParams.pageCount : ""
    }${
      requestParams.pageNumber >= 0
        ? "&pageNumber=" + requestParams.pageNumber
        : ""
    }${
      requestParams.activityReceived !== null
        ? "&activityReceived=" + requestParams.activityReceived
        : ""
    }${
      requestParams.activityTypeId
        ? "&activityTypeId=" + requestParams.activityTypeId
        : ""
    }${
      requestParams.categoryId
        ? "&eventCategoryId=" + requestParams.categoryId
        : ""
    }${
      requestParams.isValid !== null ? "&isValid=" + requestParams.isValid : ""
    }${
      requestParams.certificateGenerated
        ? "&certificateGenerated=" + requestParams.certificateGenerated
        : ""
    }${
      requestParams.activityCollageGenerated
        ? "&activityCollageGenerated=" + requestParams.activityCollageGenerated
        : ""
    }${
      requestParams.videoGenerated
        ? "&videoGenerated=" + requestParams.videoGenerated
        : ""
    }${requestParams.bib ? "&bib=" + requestParams.bib : ""}${
      requestParams.name ? "&runnerName=" + requestParams.name : ""
    }${requestParams.email ? "&email=" + requestParams.email : ""} `;
  } else {
    url =
      API_URL_FILE +
      "api/v1/openChallengeEvent/excecution/eventParticipants?openSubscriptionEventId=" +
      challengeId;
  }
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

// Vishal - get list of program challenge participants
export function getProgramChallengeParticipants(requestParams) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();

  let programChallengeId = ApplicationUtil.getSupportsEventID();
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };
  let url;
  if (requestParams) {
    url = `${API_URL_FILE}api/v1/program/challenge/list?programChallengeId=${programChallengeId}${
      requestParams.pageCount ? "&pageCount=" + requestParams.pageCount : ""
    }${
      requestParams.pageNumber >= 0
        ? "&pageNumber=" + requestParams.pageNumber
        : ""
    }${
      requestParams.startDate
        ? "&startDate=" +
          requestParams.startDate +
          "&endDate=" +
          requestParams.endDate
        : ""
    }${
      requestParams.activityTypeId
        ? "&activityTypeId=" + requestParams.activityTypeId
        : ""
    }${
      requestParams.categoryId
        ? "&eventCategoryId=" + requestParams.categoryId
        : ""
    }${
      requestParams.eventActivityTypeId
        ? "&eventActivityTypeId=" + requestParams.eventActivityTypeId
        : ""
    }${requestParams.bib ? "&bib=" + requestParams.bib : ""}${
      requestParams.name ? "&runnerName=" + requestParams.name : ""
    }${requestParams.email ? "&email=" + requestParams.email : ""}${
      requestParams.contact ? "&contactNumber=" + requestParams.contact : ""
    }${
      requestParams.consentPresent === true
        ? "&consentPresent=" + requestParams.consentPresent
        : requestParams.consentPresent === false
        ? "&consentPresent=" + requestParams.consentPresent
        : ""
    }${
      requestParams.bibGenerated === true
        ? "&bibGenerated=" + requestParams.bibGenerated
        : requestParams.bibGenerated === false
        ? "&bibGenerated=" + requestParams.bibGenerated
        : ""
    }${
      requestParams.onlineRegistration === true
        ? "&onlineRegistration=" + requestParams.onlineRegistration
        : requestParams.onlineRegistration === false
        ? "&onlineRegistration=" + requestParams.onlineRegistration
        : ""
    }${
      requestParams.activityReceived === true
        ? "&activityReceived=" + requestParams.activityReceived
        : requestParams.activityReceived === false
        ? "&activityReceived=" + requestParams.activityReceived
        : ""
    } `;
  } else {
    url =
      API_URL_FILE +
      "api/v1/program/challenge/list?" +
      "programChallengeId=" +
      programChallengeId;
  }
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function openChallengeDetails(orgId) {
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "",
    },
  };
  let challengeId = localStorage.getItem("challengeId");
  let url = "";
  if (orgId) {
    url =
      API_URL_FILE +
      "api/v1/openChallenges/" +
      challengeId +
      "?organizerId=" +
      orgId;
  } else {
    url =
      API_URL_FILE + "api/v1/openChallenges/" + challengeId + "?organizerId=";
  }

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            // message: error.response
            //   ? error.response.data
            //     ? error.response.data.error.verbose
            //     : Properties.Something_went_wrong
            //   : Properties.Something_went_wrong,
            message: "Please select the event or enter proper distkey",
          });
        }
        reject(error);
      });
  });
}

export function openChallengeDetailsWithDistKey(distKey) {
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      distKey: encodeURIComponent(distKey),
    },
  };
  let url = API_URL_FILE + "api/v1/openChallengeEvent";

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//get list of activity list based response of program challenge participants
export function getActivityListOfProgramChallengeParticipants(params) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();
  let programChallengeId = ApplicationUtil.getSupportsEventID();
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = `${API_URL_FILE}api/v1/participant/response?programChallengeId=${programChallengeId}`;

  if (params.pageCount) {
    url += `&pageCount=${params.pageCount}`;
  }
  if (params.pageNumber >= 0) {
    url += `&pageNumber=${params.pageNumber}`;
  }
  if (params.startDate) {
    url += `&startDate=${params.startDate}&endDate=${params.endDate}`;
  }
  if (params.bib) {
    url += `&bib=${params.bib}`;
  }
  if (params.name) {
    url += `&runnerName=${params.name}`;
  }
  if (params.email) {
    url += `&email=${params.email}`;
  }
  if (params.contact) {
    url += `&contactNumber=${params.contact}`;
  }
  if (params.activityReceived) {
    url += `&activityReceived=true`;
  }

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//delete Activity Challenge Response participants
export function deleteChallengeActivity(challengeResponseIds) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();

  let url =
    API_URL_FILE + "api/v1/delete/program/challenge/participants/response";
  let arr = challengeResponseIds;
  arr = arr.toString();
  var formData = new FormData();
  formData.append("participantsResponseIds", arr);

  return new Promise(function (resolve, reject) {
    http
      .delete(url, {
        headers: { Authorization: "Bearer " + token },
        data: formData,
      })
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Deleted Succesfully",
          });
          resolve(res.data);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//get participant details of program Challenge
export function getChallengeParticipantsDetails() {
  loading(true);

  let token = ApplicationUtil.getSupportToken();
  let runnerId = localStorage.getItem("supportRunnerId");

 
  
  let challengeId = localStorage.getItem("programChallengeId");

 
  
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };
  let url =
    API_URL_FILE +
    "api/v1/program/challenge/participant/details/" +
    challengeId +
    "/" +
    runnerId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//get leaderboard response details of the particular challenge
export function getChallengeResponseResults(requestParams) {
  loading(true);
  let token = null;
  if (ApplicationUtil.getRunnerToken() != null) {
    token = ApplicationUtil.getRunnerToken();
  } else if (ApplicationUtil.getSupportToken()) {
    token = ApplicationUtil.getSupportToken();
  }
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let challengeId = requestParams.eventId;
  let runnerId = requestParams.runnerId;

  let url =
    API_URL_FILE +
    `api/v1/program/challenge/response/result/${challengeId}/${runnerId}`;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//Get Challenge Duration List of Open Program
export function getChallengeDurationListOfOpenProgram(challengeTypeid , runnerId) {
  loading(true);
  let runner_token = ApplicationUtil.getRunnerToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + runner_token,
      "Access-Control-Allow-Origin": "*",
    },
  };
  let url =
    API_URL_FILE +
    `api/v1/open/subscription/program/challange/duration/${challengeTypeid}/${runnerId} `;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//get user Created OpenChallenge Registration under program
export function userCreatedOpenChallengeRegistration(requestParams) {
  loading(true);
  let runner_token = ApplicationUtil.getRunnerToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + runner_token,
      "Access-Control-Allow-Origin": "*",
    },
  };
  let url = API_URL_FILE + "api/v1/open/subscription/challenge/creation/registration";
  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.status === 200) {
            loading(false);
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Program Challenge Registration Successful",
            });
            resolve(res);
          } else {
            loading(false);
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
            reject(res);
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
