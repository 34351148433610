import React, { Component } from "react";
import {
  Paper,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Popover,
  Dialog,
  DialogContent,
  Popper,
  ClickAwayListener,
  Typography,
} from "@material-ui/core";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";
import CancelIcon from "@material-ui/icons/Cancel";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  root: {
    margin: "0px 5px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
     
    },
  },
  tableHead: {
    backgroundColor: "#ced4da",
    "& th": {
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
    },
  },
  tableRow: {
    cursor: "pointer",
    "& td": {
      fontWeight: "bold",
      fontFamily: "Rubik, sans-serif",
    },

    "&.Mui-selected": {
      backgroundColor: "#e9ecef",
      "&.MuiTableRow-hover": {
        backgroundColor: "#e9ecef",
      },
    },
  },
  participantsContainer: {
    padding: "0px 5px",
  },
});
class RelayTable extends Component {
  state = {
    open: false,
    anchorEl: null,
    participants: null,
  };
  componentDidMount() {}

  showMore = (participants) => {
    
    let show =
      window.innerWidth < 600
        ? participants.length > 1
          ? true
          : false
        : participants.length > 4
        ? true
        : false;
    return show;
  };
  renderParticipants = (participants, param) => {

    return(
          <span>
            {
              participants?.map((value, index) => { 
                if (window.innerWidth < 600 ? index == 0 : index <= 3) {
                  return (`${value?.runner?.firstName} ${value?.runner?.lastName}${participants.length - 1 !== index ? ", " : ""}`);
                }
              })}
          </span>
       

    );


  };
 

  renderAllParticipants = (participants) => {
    const { classes } = this.props;
  
    return (
      <div className={classes.participantsContainer}>
        {participants &&
          Object.entries(participants).map(([key, value], index) => {
            return (
              <div>
                {value?.runner?.firstName + " " + value?.runner?.lastName}
              </div>
            );
          })}
      </div>
    );
  
  };
  
  displaySlot = (slot) => {
    let trimmed = slot.split(":");

    return trimmed[0] + ":" + trimmed[1];
  };
  closeDialog = () => {
    this.setState({
      open: false,
      anchorEl: null,
      participants: null,
    });
  };
  render() {
    const { classes, data } = this.props;
    const { open, anchorEl, participants } = this.state;
    return (
      <div className={classes.root}>
        <TableContainer component={Paper} className={classes.tableList}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHead}>
                <TableCell
                  component="th"
                  style={{
                    width: window.innerWidth < 600 ? "84px" : "auto",
                    minWidth: window.innerWidth < 600 ? "84px" : "auto",
                    maxWidth: window.innerWidth < 600 ? "84px" : "auto",
                  }}
                >
                  Date
                </TableCell>{" "}
                {/* <TableCell component="th">Time Slot</TableCell> */}
                <TableCell component="th">Slot</TableCell>
                <TableCell component="th">Participants</TableCell>
                <TableCell component="th">Covered Distance</TableCell>
              </TableRow>
            </TableHead>{" "}
            <TableBody>
              {data?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    key={index}
                    component={Paper}
                    className={classes.tableRow}
                  >
                    {" "}
                    <TableCell component="td" align="center">
                      {ApplicationUtil.changeDateFormat(
                        row?.highestRelaySlotResult?.date
                      )}
                    </TableCell>
                    <TableCell component="td" align="center">
                      {row?.relaySlotParticipants?.length > 0 ? (
                        <span>
                       {row?.relaySlotParticipants[0]?.relaySlot?.slotName}
                        </span>
                      
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                    <TableCell component="td" align="center">
                      {row?.relaySlotParticipants ? (
                        <>
                          {row?.relaySlotParticipants && (
                            // <Tooltip
                            //   title={this.renderAllParticipants(
                            //     row?.relaySlotParticipants
                            //   )}
                            //   enterTouchDelay={50}
                            // >
                            <span>
                              {row?.relaySlotParticipants?.length > 0 && this.renderParticipants(
                                row?.relaySlotParticipants
                              )}{" "}
                              {this.showMore(row?.relaySlotParticipants) && (
                                <a
                                  onClick={(e) => {
                                    this.setState({
                                      open: true,
                                      anchorEl: e.currentTarget,
                                      participants: row?.relaySlotParticipants,
                                      isSlots:false
                                    });
                                  }}
                                >
                                  more
                                </a>
                              )}
                            </span>
                            // </Tooltip>
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    
                    <TableCell component="td" align="center">
                      {row?.highestRelaySlotResult?.totalDistance ? (
                        <span>
                          {parseFloat(
                            Number(row?.highestRelaySlotResult?.totalDistance) /
                              1000
                          ).toFixed(2) + " Km"}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                    
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <ClickAwayListener onClickAway={this.closeDialog}> */}
        <Popper
          open={open}
          anchorEl={anchorEl}
          // placement="bottom"
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: false,
              bounariesElement: "scrollParent",
            },
          }}
          onClose={() => this.closeDialog()}
        >
          <Paper
            style={{
              padding: "10px 5px",
            }}
            elevation={4}
          >
            <div
              style={{
                color: "#08456C",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "0px 0px 0px 6px",
                zIndex:9999 
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: "20px",

                  textTransform: "capitalize",
                }}
              >
                {'Participants'}
              </span>
              <span>
                <CancelIcon
                  style={{ marginRight: "10px", cursor: "pointer"}}
                  onClick={(e) => this.closeDialog()}
                />
              </span>
            </div>
            <div style={{ overflowY: "auto" }}>
              <Typography style={{ color: "#0000008a" }}>
                {this.renderAllParticipants(participants)}
              </Typography>
            </div>
          </Paper>
        </Popper>
        {/* </ClickAwayListener> */}
        {/* <DialogBox
          header="Participants"
          open={open}
          anchorEl={anchorEl}
          message={this.renderAllParticipants(participants)}
          closeDisloag={() => {
            this.setState({ open: false, anchorEl: null, participants: null });
          }}
          // fullWidth
        /> */}
      </div>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }),withRouter)(RelayTable);
