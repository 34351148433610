import http from "./httpService";
import { loading } from "../components/common/Spinner";
import Snackbar from "../components/common/Snackbar";
import Properties from "../properties/Properties";

const API_URL_FILE = http.API_URL();

//for runnerGroup List
export default function getRunnerGroupList() {
  loading(true);
  let token = localStorage.getItem("token");
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/runnerGroups";

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
