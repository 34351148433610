import React, { Component, createRef, useEffect, useState } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import banner from "./banner.jpg";
import Searchbar from "./Searchbar";
import Leaderboard from "./Leaderboard/Leaderboard";
import { withRouter } from "react-router-dom";
import Properties from "./../../../../../properties/Properties";
import {
  getProgramDetailsWithDistkey,
  getViewResults,
} from "./../../../../../apis/eventService";
import Results from "./Results";
import Header from "../../../../common/Header";
import AppToolBar from "../../../../common/AppToolBar";
import moment from "moment";

import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import { connect } from "react-redux";
import {
  updateUserSelectedProgram,
  updateUserSelectedEvent,
  updateUserPreferences,
  updateResultsSearchValue,
} from "../../../../../redux/user";

import httpService from "../../../../../apis/httpService";
import RunnerSideBreadcrumbs from "../../../../common/RunnerSideBreadcrumbs";
import { getChallengeResponseResults } from "../../../../../apis/challengeService";
import ChallengeSearchBar from "./ChallengeResponseResult/ChallengeSearchBar";
import ChallengeResponseResult from "./ChallengeResponseResult/ChallengeResponseResult";
import Snackbar from "../../../../common/Snackbar";
const API_URL = httpService.API_URL();
const styles = (theme) => ({
  root: {
    marginTop: "68px",
  },
  viewresults: {
    // overflow: "hidden",
    height: "100vh",
  },
  eventName_heading: {
    textAlign: "center",
    color: "rgb(40, 131, 181)",
    fontWeight: 600,
    fontSize: "150%",
  },
  event_banner: {
    marginBottom: "10px",
    padding: "0px 4px",
    // marginLeft: "4px",
    // marginRight: "4px",
    // width: "100%",
    // height: window.innerWidth > 600 ? "75%" : "auto",
    overflow: "hidden",
    "& .banner_img": {
      width: "100%",
      // height: "100%",
      // maxHeight: "100%",
      // display: "block",
      margin: "auto",

      // marginTop: 0,
      // objectFit: "cover",
      // top: 0,
      // zIndex: -1,
      // maskImage: "linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))",
    },
  },
  mapContainer: {
    width: "95%",
    margin: "auto",
    paddingBottom: "10px",
    position: "relative",
  },

  viewMapLabel: {
    color: "rgb(40, 131, 181)",
    textAlign: "center",
    marginTop: "20px",
  },
  noMap: {
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: 600,
  },
  loadingContainer: {
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: 600,
  },
  publicHeaderLayout: {
    display: "flex",
  },
  homeIcon: {
    margin: "10px",
    padding: "6px 6px 6px 8px",
    height: "30px",
    width: "30px",
    backgroundColor: "#08456c",
    cursor: "pointer",
  },
  eventName: {
    margin: "0 auto",
  },
  trackerMap_LabelLayout: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px",
  },
  trackerMap_Label: {
    fontSize: "24px",
    color: "#727272",
    fontWeight: 600,
  },
  trackerMap_Toggle: {
    fontSize: "18px",
    color: "#727272",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  trackerMap_Individual: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // margin: "20px",
    width: "100%",
  },
  trackerMap_RunnerName: {
    marginRight: "20px",
    marginLeft: "20px",
    fontSize: "20px",
    color: "#727272",
    fontWeight: 600,
  },
  trackerMap_SeeAllRunners: {
    cursor: "pointer",
    marginRight: "20px",
    marginLeft: "20px",
    fontSize: "20px",
    color: "blue",
    fontWeight: 600,
  },
  relayMapActionLayout: {
    position: "relative",
    display: "flex",
    alignItems: "flex-end",
    padding: "10px 20px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px",
      // marginBottom:'40px',

      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  relayMapLabel: {
    fontSize: "24px",
    color: "#727272",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginTop: "50px",
    },
  },
  relayParticipantsMap: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
    },
  },
});
const initialState = {
  eventId: null,
  eventName: "",
  eventResData: null,
  counter: 0,
  firstLoad: true,
  listView: false,
  isAllParticipants: true,
  relayMapToggleValue: "map",
  scrollToMap: false,
  features: [],
};
function ProgramResults({
  history,
  classes,
  updateUserSelectedProgram,
  updateUserSelectedEvent,
}) {
  const [programDetails, setProgramDetails] = useState(null);
  const [runnerName, setRunnerName] = useState("");
  const [programDate, setProgramDate] = useState("");
  const [programEnd, setProgramEnd] = useState("");
  const [results, setResults] = useState(null);
  const [showResults, setShowResults] = useState(true);
  const [updatedCategory, setUpdatedCategory] = useState(null);
  const [showChallengeResults, setShowChallengeResults] = useState(true);
  const [updatedEventIdx, setUpdatedEventIdx] = useState(0);
  const [summeryGeneratedEvents, setSummeryGeneratedEvents] = useState([]);

  useEffect(() => {
    initialLoad();
  }, []);

  const initialLoad = (id) => {
    let search = window.location.href;
    let distKey = search.split("distKey=")[1];
    let programData = ApplicationUtil.getRunnersProgramDetails();
    if (!distKey) {
      distKey = programData?.distKey;
    }
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runnerId;
    let idx;
    let summeryGenerated;
    let challengeGenerated;
    let isStairRecord;

    getProgramDetailsWithDistkey(distKey)
      .then(({ data, status }) => {
        if (status === 200) {
          document.title = data?.name;

          if (data?.eventDTO) {
            data.eventDTO.map((x) => {
              x.eventLocalStartDate = moment(
                x?.localStartDate,
                // "DD-MM-YYYY"
                "YYYY-MM-DD"
              ).format("DD-MM-YYYY");
              x.eventLocalEndDate = moment(
                x?.localEndDate,
                // "DD-MM-YYYY"
                "YYYY-MM-DD"
              ).format("DD-MM-YYYY");
              return x;
            });
            summeryGenerated =
              data?.eventDTO.filter((item) => item.summaryGenerated) || [];
          } else {
            summeryGenerated = [];
          }

          if (data?.programChallenges) {
            data.programChallenges.map((x) => {
              x.eventLocalStartDate = moment(
                x?.challengeStartDate,
                "DD-MM-YYYY"
              ).format("DD-MM-YYYY");
              x.eventLocalEndDate = moment(
                x?.challengeEndDate,
                "DD-MM-YYYY"
              ).format("DD-MM-YYYY");
              return x;
            });
            challengeGenerated =
              data?.programChallenges.filter(
                (item) =>
                  item.programChallengeSettings?.summaryGenerated === "true"
              ) || [];
          } else {
            challengeGenerated = [];
          }

          let mergedGenerated = [...summeryGenerated, ...challengeGenerated];

          const defaultSelection = isEventOngoing(mergedGenerated);
          mergedGenerated = mergedGenerated.filter(
            (event) => event.id !== defaultSelection.id
          );
          mergedGenerated = [defaultSelection].concat(mergedGenerated);
          setSummeryGeneratedEvents(mergedGenerated);

          if (id) {
            idx = mergedGenerated.findIndex((x) => x.id === id);
            setUpdatedEventIdx(idx >= 0 ? idx : updatedEventIdx);
            setShowResults(false);
            setShowChallengeResults(false);
            updateResultsSearchValue("");
          }

          updateUserSelectedEvent(
            idx >= 0 ? mergedGenerated[idx] : mergedGenerated[updatedEventIdx]
          );

          setProgramDetails(data);
          updateUserSelectedProgram(data);

          let requestParams = {
            eventId:
              idx >= 0
                ? mergedGenerated[idx]?.id
                : mergedGenerated[updatedEventIdx]?.id,
            challengeType:
              idx >= 0
                ? mergedGenerated[idx]?.programChallengeType
                : mergedGenerated[updatedEventIdx]?.programChallengeType,
            runnerId,
          };

          if (
            requestParams?.challengeType === "HYDRATION" ||
            requestParams?.challengeType === "ZERO_SUGAR" ||
            requestParams?.challengeType === "NO_UNHEALTHY_EATING"
          ) {
            getProgramChallengeData(requestParams, runnerId);
          } else {
            getProgramEventData(requestParams, runnerId);
          }

          setRunnerName(
            `${runnerData?.firstName} ${
              runnerData?.lastName ? runnerData?.lastName : ""
            }`
          );
          setProgramDate(
            moment(data?.localStartDate, "YYYY-MM-DD").format("DD-MM-YYYY")
          );
          setProgramEnd(
            moment(data?.localEndDate, "YYYY-MM-DD").format("DD-MM-YYYY")
          );
        }
      })
      .catch((error) => {
        if (error) {
          history.push("/");
        }
      });
  };

  const isEventOngoing = (events) => {
    //if event is ongoing
    const onGoingEvents = events?.filter((event) =>
      moment().isBetween(
        moment(event?.eventLocalStartDate, "DD-MM-YYYY"),
        moment(event?.eventLocalEndDate, "DD-MM-YYYY").add(1, "day")
      )
    );
    if (onGoingEvents.length === 1) {
      return onGoingEvents[0];
    } else if (onGoingEvents.length == 0) {
      //if there's no ongoing event & events are ended then return latest end date.
      var mostRecentDate = new Date(
        Math.max.apply(
          null,
          events.map((e) => {
            return new Date(e?.eventLocalEndDate);
          })
        )
      );
      var mostRecentObject = events.filter((e) => {
        var d = new Date(e?.eventLocalEndDate);
        return d.getTime() == mostRecentDate.getTime();
      })[0];
      return mostRecentObject ? mostRecentObject : events[0];
    } else {
      return events[0];
    }
  };

  const getProgramEventData = (params, runnerId) => {
    if (params?.eventId && runnerId) {
      getViewResults(params)
        .then((response) => {
          setUpdatedCategory({
            activityType: response?.data?.activityType,
            catId: response?.data?.categoryDTO?.id,
          });
          setResults(response?.data || null);
        })
        .catch((error) => {
          console.log("error:", error);
        });
    }
  };

  const getProgramChallengeData = (params, runnerId) => {
    if (params?.eventId && runnerId) {
      getChallengeResponseResults(params)
        .then((response) => {
          if (response?.data?.response?.code == "406") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.ChallengeParticipantNotRegister,
            });
            setResults(response?.data || null);
          } else {
            setResults(response?.data || null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const updateLeaderboardCategory = (activityType, catId) => {
    setUpdatedCategory({
      activityType: activityType,
      catId: catId,
    });
    setShowResults(true);
  };

  return (
    <>
      <div className={classes.viewresults}>
        <AppToolBar
          backNavigate={() => history.push("/eventDetails")}
          showBack={true}
        >
          <Header
            label={`${programDetails?.name || ""} ${Properties.Label_Results}`}
            fromDate={programDate}
            toDate={programDate !== programEnd ? programEnd : false}
          />
        </AppToolBar>

        <div className={classes.root}>
          <RunnerSideBreadcrumbs />
          {programDetails && (
            <div className={classes.event_banner}>
              <img
                src={
                  programDetails?.programResultBanner?.url
                    ? API_URL + programDetails?.programResultBanner?.url
                    : programDetails?.imageDto?.url
                    ? API_URL + programDetails?.imageDto?.url
                    : banner
                }
                alt="marathon_banner"
                className="banner_img"
              />
            </div>
          )}

          {/* ankit Defect_id_488 - id passed of summeryGeneratedEvents as it shows only that data on screen*/}
          {programDetails &&
            summeryGeneratedEvents[updatedEventIdx]?.eventSettings
              ?.showSearchBarOnResultsView === "true" &&
            programDetails?.eventDTO && (
              <Searchbar
                eventId={summeryGeneratedEvents[updatedEventIdx]?.id}
                updateLeaderboardCategory={updateLeaderboardCategory}
                showResults={
                  summeryGeneratedEvents[updatedEventIdx]?.eventSettings
                    ?.showSearchBarOnResultsView === "true" && showResults
                }
                isProgram
                clearResult={(data) => {
                  if (data && Object.keys(data).length) {
                    setShowResults(false);
                    setResults(data);
                  } else {
                    setShowResults(true);
                    setResults(null);
                  }
                }}
              />
            )}

          {programDetails &&
            summeryGeneratedEvents[updatedEventIdx]?.programChallengeSettings
              ?.showSearchBarOnResultsView === "true" &&
            programDetails?.programChallenges && (
              <ChallengeSearchBar
                challengeId={summeryGeneratedEvents[updatedEventIdx]?.id}
                showChallengeResults={
                  summeryGeneratedEvents[updatedEventIdx]
                    ?.programChallengeSettings?.showSearchBarOnResultsView ===
                    "true" && showChallengeResults
                }
                isProgram
                clearResult={(data) => {
                  if (data && Object.keys(data).length) {
                    setShowChallengeResults(false);
                    setResults(data);
                  } else {
                    setShowChallengeResults(true);
                    setResults(null);
                  }
                }}
              />
            )}

          {programDetails &&
            summeryGeneratedEvents[updatedEventIdx]?.eventSettings
              ?.showSearchResultOnResults === "true" &&
            results && <Results data={results} isProgram />}

          {programDetails &&
            summeryGeneratedEvents[updatedEventIdx]?.programChallengeSettings
              ?.showSearchResultOnResults === "true" &&
            programDetails?.programChallenges &&
            results && <ChallengeResponseResult data={results} isProgram />}

          {programDetails &&
            (programDetails?.eventDTO || programDetails?.programChallenges) && (
              <Leaderboard
                defaultStairStepper={programDetails?.eventDTO}
                challengeType={summeryGeneratedEvents[updatedEventIdx]}
                isProgram
                reload={(data) => {
                  initialLoad(data?.value);
                }}
                categoryDTO={updatedCategory}
              />
            )}
        </div>
      </div>
    </>
  );
}
function mapStateToProps(state) {
  return {
    userSelectedProgram: state.user.userSelectedProgram,
    userSelectedEvent: state.user.userSelectedEvent,
    updateResultsSearchValue: state.user.updateResultsSearchValue,
    updateUserPreferences: state.user.updateUserPreferences,
  };
}
export default compose(
  withRouter,
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    updateUserSelectedProgram,
    updateUserSelectedEvent,
    updateResultsSearchValue,
    updateUserPreferences,
  })
)(ProgramResults);
