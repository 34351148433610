import axios from "axios";
import { reject } from "lodash";
import Snackbar from "../components/common/Snackbar";
import { loading } from "../components/common/Spinner";
import Properties from "../properties/Properties";
import ApplicationUtil from "../utils/ApplicationUtil";
var isExpired = 0;
// function setRefreshToken(refresh_token) {
//   var accountName = getAccountName();
//   localStorage.setItem(accountName + "_" + "refresh_token", refresh_token);
// }

// function getAccessToken() {
//   var accountName = getAccountName();
//   let access_token = localStorage.getItem(accountName + "_" + "access_token");

//   if (access_token) {
//     return access_token;
//   } else {
//     backToLoginScreen();
//   }
// }

// function setAccessToken(access_token) {
//   var accountName = getAccountName();
//   localStorage.setItem(accountName + "_" + "access_token", access_token);
// }

// function addRefreshRequestQueue(request) {
//   refreshRequestQueue.push(request);
// }
// function setRequestHeaders(config, url) {
//   config.headers["Content-Type"] = "application/x-www-form-urlencoded";
//   config.headers.Authorization = "Bearer " + getAccessToken();

//   return config;
// }

// axios.interceptors.request.use(
//   config => {
//     config = setRequestHeaders(config, config.url);
//     return config;
//   },
//   error => {
//     console.log(error);
//     return Promise.reject(error);
//   }
// );
// axios.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     const { config, response } = error;
//     const originalRequest = config;
//     let { status, data } = {};
//     if (response && response.status) {
//       //let { status, data } = response;
//       status = response.status;
//       data = response.data;
//     }
//   }
// );

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error) {
      let URL = error.request.responseURL.split("/v1/");
      URL = URL[1];
      if (URL == "login") {
        return Promise.reject(error);
      } else {
        if (error?.response?.status === 401) {
          loading(false);

          if (isExpired === 0) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Session_Expired, // "Session Expired",
            });
            ApplicationUtil.handleRunnerLogout().then(()=>{

              ApplicationUtil.go("/");
              isExpired = 1;
            });
          }

          return Promise.reject("");
        } else {
          return Promise.reject(error);
        }
      }
    }
  }
);
function API_URL() {
  //QA
  //return "http://192.168.1.49:8080/";

  //prod
    return "https://events.necessarydevil.com/";
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  all:axios.all,
  API_URL,
};
