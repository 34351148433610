import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { CustomTextField } from "../../../../common/CustomTextField";
import SubmitCancelBtn from "../../../../common/SubmitCancelBtn";
import Autosuggest from "react-autosuggest";
import {
  getSearchResults,
  getViewResults,
} from "./../../../../../apis/eventService";
import Results from "./Results";
import Properties from "./../../../../../properties/Properties";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import Snackbar from "../../../../common/Snackbar";
import { debounce } from "lodash";
import { withRouter } from "react-router-dom";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import { updateResultsSearchValue } from "../../../../../redux/user";
import { connect } from "react-redux";

const styles = (theme) => ({
  input: {
    "& .react-autosuggest__container": {
      position: "relative",
    },

    "& .react-autosuggest__input": {
      width: "100%",
      height: "50px",
      padding: "10px 20px",
      fontFamily: "Helvetica, sans-serif",
      fontWeight: 300,
      fontSize: "16px",
      border: "1px solid #aaa",
      borderRadius: "4px",
    },
    "& .react-autosuggest__input--focused": {
      outline: "none",
    },

    "& .react-autosuggest__input--open": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },

    "& .react-autosuggest__suggestions-container": {
      display: "none",
    },

    "& .react-autosuggest__suggestions-container--open": {
      display: "block",
      position: "absolute",
      top: "51px",
      width: "300px",

      border: "1px solid #aaa",
      backgroundColor: "#fff",
      fontFamily: "Helvetica, sans-serif",
      fontWeight: 300,
      fontSize: "16px",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      zIndex: 2,
      overflow: "auto",
      maxHeight: "300px",
    },

    "& .react-autosuggest__suggestions-list": {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },

    "& .react-autosuggest__suggestion": {
      cursor: "pointer",
      padding: "10px 20px",
    },

    "& .react-autosuggest__suggestion--highlighted": {
      backgroundColor: "#ddd",
      color: "#000",
    },
  },
  suggestion_container: {},
});

class Searchbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bib: "",
      searchValues: [],
      resData: null,
      suggestions: [],
      runnerId: null,
      results: null,
    };
  }

  componentDidMount() {
    this.props.updateResultsSearchValue("");
    const { eventId, classes } = this.props;
    let id;
    if (eventId) {
      id = eventId;
    }
    let requestParams = {
      eventId: id,
      searchString: "",
    };
    getSearchResults(requestParams).then((res) => {
      if (res.data) {
        let autoValues = [];

        res.data.map((value) => {
          autoValues.push({
            firstName: value.firstName,
            lastName: value.lastName,
            bibnumber: value.bibNumber,
            runnerId: value.runnerId,
          });
        });

        this.setState({ searchValues: autoValues, resData: res.data });
      }
    });
  }

  componentDidUpdate(previousProps) {
    let id;
    if (this.props.eventId !== previousProps.eventId) {
      const { eventId } = this.props;
      if (eventId) {
        id = eventId;
      }
      let requestParams = {
        eventId: id,
        searchString: "", //this.state.inputValue,
      };

      getSearchResults(requestParams).then((res) => {
        if (res.data) {
          let autoValues = [];

          res.data.map((value) => {
            autoValues.push({
              firstName: value.firstName,
              lastName: value.lastName,
              bibnumber: value.bibNumber,
              runnerId: value.runnerId,
            });
          });

          this.setState({
            searchValues: autoValues,
            resData: res.data,
          });
          this.props.updateResultsSearchValue("");
        }
      });
    }
  }
  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (this.state.searchValues) {
      return inputLength === 0
        ? []
        : this.state.searchValues.filter(
            (val) =>
              val?.firstName?.toLowerCase().slice(0, inputLength) ===
                inputValue ||
              val?.lastName?.toLowerCase().slice(0, inputLength) ===
                inputValue ||
              val?.bibnumber?.toString().slice(0, inputLength) === inputValue
          );
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    const { eventId } = this.props;
    this.setState({
      suggestions: [],
    });
  };

  getSuggestionValue = (suggestion) => {
    this.setState({
      bib: suggestion.bibnumber,
      runnerId: suggestion.runnerId,
      runnerName: `${suggestion.firstName} ${suggestion?.lastName || ""}`,
    });
    return `${suggestion.firstName} ${
      suggestion.lastName ? suggestion.lastName : ""
    } (BIB-${suggestion.bibnumber})`;
  };

  renderSuggestion = (suggestion) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>
        <DirectionsRunIcon
          style={{
            color: "#09456C",
          }}
        />
      </div>
      <div>
        {`${suggestion.firstName} ${
          suggestion.lastName ? suggestion.lastName : ""
        } (BIB - ${suggestion.bibnumber})`}
      </div>
    </div>
  );

  getSearchResults = (BIB, runnerId) => {
    const { eventId } = this.props;
    const { resData, results } = this.state;
    let runnerIdValue;
    const searchValue = ApplicationUtil.getResultsSearchValue();
    if (!runnerId && searchValue) {
      resData.map((id) => {
        if (Number(searchValue) === id.bibNumber) {
          runnerIdValue = id.runnerId;
        }
      });
    }

    if (runnerId || runnerIdValue) {
      let requestParams = {
        eventId: eventId,
        runnerId: runnerId ? runnerId : runnerIdValue,
      };
      if (requestParams.runnerId) {
        getViewResults(requestParams).then((res) => {
          if (res.data) {
            this.setState({ results: res.data, runnerId: null });
            if (this.props.updateLeaderboardCategory) {
              this.props.updateLeaderboardCategory(
                res.data.activityType,
                res.data.categoryDTO.id
              );
            }
          }
        });
      }
    } else {
      if (searchValue === "") {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: false,
          message: Properties.SelectParticipantMsgForLeaderboard,
        });
      }
    }
  };

  render() {
    let {
      classes,
      eventId,
      eventType,
      getSearchedParticipants,
      showResults,
      isProgram,
    } = this.props;
    let { suggestions, results, runnerId, bib, runnerName } = this.state;
    const searchValue = ApplicationUtil.getResultsSearchValue();

    return (
      <div>
        <Grid
          style={{
            width: "90%",
            margin: "auto",

            //   border: "2px solid gray",
          }}
          container
          spacing={1}
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid
            item
            xs={6}
            className={classes.input}
            // style={{ border: "2px solid gray" }}
          >
            <Autosuggest
              inputProps={{
                placeholder: Properties.Label_Searchbar,
                value: searchValue,
                type: "search",
                onChange: (_event, { newValue }) => {
                  this.props.updateResultsSearchValue(newValue);
                  if (!newValue) {
                    if (this.props.getEventLevelMap) {
                      this.props.getEventLevelMap("EVENT", true);
                    }
                    if (ApplicationUtil.isuserLoggedIn()) {
                      let runnerData = ApplicationUtil.getRunnerDetails();
                      let runnerId = runnerData?.runner?.id;
                      getViewResults({
                        eventId: this.props.eventId,
                        runnerId,
                      }).then(({ data }) => {
                        this.props.clearResult(data);
                        this.setState({ results: data });
                      });
                    } else {
                      this.setState({ results: null });
                    }
                  }
                },
              }}
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              onSuggestionSelected={(event, { suggestion, method }) => {
                if (method === "enter") {
                  event.preventDefault();
                }
              }}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.renderSuggestion}
            />
          </Grid>
          <Grid item xs={6} style={{ textAlign: "center" }}>
            <SubmitCancelBtn
              type="submit"
              label={Properties.Lable_Go}
              style={{ width: "30%", margin: "auto" }}
              onClick={(e) => {
                e.preventDefault();
                if (getSearchedParticipants) {
                  getSearchedParticipants(runnerId, runnerName);
                }

                this.getSearchResults(bib, runnerId);
                if (this.props && this.props.getSearchedMapResults && bib) {
                  this.props.getSearchedMapResults("BIB", bib);
                }

                if (this.props?.clearResult && bib) {
                  this.props.clearResult();
                }

                // }, 1000);
                // if (this.props && this.props.getSearchedMapResults && bib) {
                //   this.props.getSearchedMapResults("BIB", bib);
                // }
              }}
            />
          </Grid>
        </Grid>
        {showResults && results && (
          <Results data={results} type={eventType} isProgram={isProgram} />
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
    userSelectedEvent: state.user.userSelectedEvent,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateResultsSearchValue,
  })
)(Searchbar);
