import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Card, Grid } from "@material-ui/core";
import { RuppeeIcon } from "../../../common/SvgIcon";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import group from "./../../../../images/Group.png";

const styles = (theme) => ({
  eventBlock: {
    background: "rgba( 58, 110, 165, 0.25 )",
    boxShadow: "0 4px 4px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: " blur( 4px )",
    // -webkit-backdrop-filter: blur( 4px );
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    // width: "30%",
    padding: "10px",
    marginBottom: "15px",
    marginLeft: "15px",
    cursor: "pointer",
  },

  title: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  desc: {
    fontWeight: 600,
    fontSize: "14px",
  },
  bottomText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  trial: {
    backgroundColor: "#f3722c",
    borderRadius: "10px",
    color: "#fff",
    padding: "10px",
    width: "100px",
    fontWeight: 600,
  },
  container: {
    display: "flex",
  },
  media: {
    width: "200px",
    height: "200px",
    // maxWidth: "60%",
    // minWidth: "100%",
    marginLeft: "auto",
    objectFit: "content",
    borderRadius: "8px",
    boxShadow: "inset 0px 0px 19px 0px rgba(0,0,0,0.75)",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
      height: "150px",
      marginTop: "auto",
      marginBottom: "auto",
    },
  },
  cardmedia: {
    width: "50%",
    marginLeft: "auto",
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  textContainer: {
    // width: "50%",

    width: "80%",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#2883B5",
  },
  fee: {
    position: "relative",
    bottom: 0,
  },
  reviewImgSmall: {
    width: "60%",
    maxWidth: "60%",
    minWidth: "100%",
  },
  rowContainer: {
    paddingBottom: "10px",
    borderRadius: "10px",
    cursor: "pointer",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  dataContainer: {
    padding: "10px",
  },
  imageCont: {
    position: "relative",
    margin: "0 auto",
    justifyContent: "center",
    display: "flex",
    position: "relative",
  },
});

class ChallengesEventList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trial: false,
    };
  }

  freeTrialCheck = (data, trialDays) => {
    const { classes, trial } = this.props;

    return (
      <>
        {trial && (
          <>
            {trialDays !== undefined && trialDays !== 0 && (
              <p className={classes.trial}>{trialDays + " Days Free Trial"}</p>
            )}
          </>
        )}{" "}
      </>
    );
  };
  render() {
    const { classes, history, challengesData } = this.props;

    return (
      <div>
        <Grid
          style={{
            padding: "5px",
            overflow: "hidden",
          }}
          container
          spacing={4}
        >
          {challengesData?.map((data, index) => (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={data?.id}>
              <Card
                className={classes.rowContainer}
                onClick={() => {
                  history.push("/challenge");
                  localStorage.setItem("challengeId", data?.id);
                }}
              >
                {data?.bannerImagePath ? (
                  <div className={classes.imageCont}>
                    <img
                      src={data?.bannerImagePath}
                      className={classes.reviewImgSmall}
                      alt="rev-itm-img"
                    />
                  </div>
                ) : (
                  <div className={classes.imageCont}>
                    <img
                      src={group}
                      className={classes.reviewImgSmall}
                      alt="rev-itm-img"
                    />
                  </div>
                )}
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "5px 10px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className={classes.textContainer}
                      style={{ width: "40%" }}
                    >
                      <span className={classes.title}>
                        {data?.challengeParamDisplayName}
                      </span>
                      <br />
                    </div>
                    <div>
                      {data?.registrationEndDate &&
                      data?.isEventClosed === true ? (
                        <div>
                          <span
                            style={{
                              borderRadius: "25px",
                              border: "1px solid lightgray",
                              padding: "3px",
                            }}
                          >
                            <span
                              style={{
                                borderRadius: "50%",
                                display: "inline-block",
                                backgroundColor: "red",
                                height: "10px",
                                width: "10px",
                              }}
                            ></span>{" "}
                            <span>Registrations Closed</span>
                          </span>
                        </div>
                      ) : (
                        <div>
                          <span
                            style={{
                              borderRadius: "25px",
                              border: "1px solid lightgray",
                              padding: "3px",
                            }}
                          >
                            <span
                              style={{
                                borderRadius: "50%",
                                display: "inline-block",
                                backgroundColor: "green",
                                height: "10px",
                                width: "10px",
                              }}
                            ></span>
                            <span>Register Now</span>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>{" "}
                  <div style={{ padding: "0px 10px" }}>
                    {this.freeTrialCheck(data, data?.trialDays)}
                  </div>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ChallengesEventList);
