import React, { Component } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  head: {
    "& p": {
      fontSize: "20px",
    },
  },
  table: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
    },
  },
  rowCss: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  teamLeadRow: {
    backgroundColor: "#f1a60e52",
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 18,
  },
}))(TableCell);

class Board extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortingKM: true,
    };
  }

  tableDataBody = (data, rank, distance, center) => {
    // console.log(data);
    return (
      <TableBody style={{ overflowX: "scroll" }}>
        {data?.map((member) => (
          <StyledTableRow isLeader={true}>
            {rank && (
              <StyledTableCell align={center ? "center" : "left"}>
                <p>{member.rank}</p>
              </StyledTableCell>
            )}
            <StyledTableCell align={center ? "center" : "left"}>
              <p>{`${member.firstName} ${
                member.lastName ? member.lastName : ""
              }`}</p>
            </StyledTableCell>
            {distance && (
              <StyledTableCell align={center ? "center" : "left"}>
                <p>
                  {parseFloat(member.totalActivityDistance / 1000).toFixed(2) +
                    " Km"}
                </p>
              </StyledTableCell>
            )}
            <StyledTableCell align={center ? "center" : "left"}>
              {member.completionTime}
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    );
  };

  aescKmTable = (data, rank, distance, center) => {
    let sortData = [...data];
    sortData.sort((a, b) =>
      a.totalActivityDistance < b.totalActivityDistance ? -1 : 1
    );
    return this.tableDataBody(sortData, rank, distance, center);
  };

  descKmTable = (data, rank, distance, center) => {
    let sortData = [...data];
    sortData.sort((a, b) =>
      a.totalActivityDistance > b.totalActivityDistance ? -1 : 1
    );
    return this.tableDataBody(sortData, rank, distance, center);
  };

  render() {
    const {
      classes,
      rank,
      distance,
      center,
      data,
      sort,
      time,
      challengeParameter,
      teamLeaderId,
    } = this.props;

    return (
      <Paper className={classes.table}>
        <Table>
          <TableHead>
            <TableRow className={classes.head}>
              {rank && (
                <StyledTableCell align={center ? "center" : "left"}>
                  <span>Rank</span>
                </StyledTableCell>
              )}
              <StyledTableCell
                align={center ? "center" : "left"}
                sortDirection="desc"
              >
                <span>Name</span>
              </StyledTableCell>
              {(challengeParameter === "DISTANCE" || distance) && (
                <StyledTableCell align={center ? "center" : "left"}>
                  {/* {sort ? (
                    <span
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={() => {
                        this.setState({
                          sortingKM: !this.state.sortingKM,
                        });
                      }}
                    >
                      <span>Distance</span>
                      {this.state.sortingKM ? (
                        <ArrowDropUpIcon style={{ cursor: "pointer" }} />
                      ) : (
                        <ArrowDropDownIcon style={{ cursor: "pointer" }} />
                      )}
                    </span> */}
                  {/* // ) : ( */}
                  <span>Distance</span>
                  {/* // ) */}
                  {/* } */}
                </StyledTableCell>
              )}
              {challengeParameter === "STEPS" && (
                <StyledTableCell align={center ? "center" : "left"}>
                  <span>Steps</span>
                </StyledTableCell>
              )}
              {challengeParameter === "CALORIE" && (
                <StyledTableCell align={center ? "center" : "left"}>
                  <span>Calorie</span>
                </StyledTableCell>
              )}
              {challengeParameter === "ELEVATION" && (
                <StyledTableCell align={center ? "center" : "left"}>
                  <span>Elevation</span>
                </StyledTableCell>
              )}
              {(challengeParameter === "TIME" ||
                challengeParameter === undefined ||
                time) && (
                <StyledTableCell align={center ? "center" : "left"}>
                  <span>Time</span>
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>

          {/* {sort && this.state.sortingKM
            ? this.descKmTable(data, rank, distance, center)
            : this.aescKmTable(data, rank, distance, center)}
          {!sort && this.tableDataBody(data, rank, distance, center)} */}
          <TableBody style={{ overflowX: "scroll" }}>
            {data?.map((member, index) => (
              <TableRow
                className={
                  teamLeaderId === member.runnerId
                    ? classes.teamLeadRow
                    : classes.rowCss
                }
                key={index}
              >
                {rank && (
                  <StyledTableCell align={center ? "center" : "left"}>
                    <p>{member.rank}</p>
                  </StyledTableCell>
                )}
                <StyledTableCell align={"left"}>
                  <p style={{ marginLeft: "20px" }}>{`${member.firstName} ${
                    member.lastName ? member.lastName : ""
                  }`}</p>
                </StyledTableCell>
                {(challengeParameter === "DISTANCE" || distance) && (
                  <StyledTableCell align={center ? "center" : "left"}>
                    <p>
                      {parseFloat(member.totalActivityDistance / 1000).toFixed(
                        2
                      ) + " Km"}
                    </p>
                  </StyledTableCell>
                )}
                {challengeParameter === "STEPS" && (
                  <StyledTableCell align={center ? "center" : "left"}>
                    <span>{member.totalSteps}</span>
                  </StyledTableCell>
                )}
                {challengeParameter === "CALORIE" && (
                  <StyledTableCell align={center ? "center" : "left"}>
                    <span>{member.totalCalories}</span>
                  </StyledTableCell>
                )}
                {challengeParameter === "ELEVATION" && (
                  <StyledTableCell align={center ? "center" : "left"}>
                    <span>{member.totalElevationGain}</span>
                  </StyledTableCell>
                )}
                {(challengeParameter === "TIME" ||
                  challengeParameter === undefined ||
                  time) && (
                  <StyledTableCell align={center ? "center" : "left"}>
                    {member.completionTime}
                  </StyledTableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }),withRouter)(Board);
