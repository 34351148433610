import React, { Component } from "react";
import Chart from "chart.js";
import Properties from "../../properties/Properties";
//Chart.defaults.global.defaultFontSize = 18;
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import ApplicationUtil from "../../utils/ApplicationUtil";
const styles = (theme) => ({
  canvasStyling: {
    height: "300px",
    position: "relative",
    overflow: "hidden",
    display: "inline",
  },
  canvasStylin1: {
    height: "500px",
    position: "relative",
    overflow: "hidden",
  },
});
class LineGraph extends Component {
  chartRef = React.createRef();

  state = {
    data: this.props.graphData,
    eventType: this.props.eventType,
    graphYAxis: this.props.graphYAxis,
    graphXAxis: this.props.graphXAxis,
  };
  componentDidMount() {
    this.makeChart();
  }

  componentDidUpdate(previousProps, previousState) {
    if (this.props.graphData !== previousProps.graphData) {
      this.setState({
        data: this.props.graphData,
      });
    }
    if (this.props.eventType !== previousProps.eventType) {
      this.setState({
        eventType: this.props.eventType,
      });
    }
    if (this.props.graphYAxis !== previousProps.graphYAxis) {
      this.setState({
        graphYAxis: this.props.graphYAxis,
      });
    }
    if (this.props.graphXAxis !== previousProps.graphXAxis) {
      this.setState({
        graphXAxis: this.props.graphXAxis,
      });
    }
    this.makeChart();
  }

  makeChart = () => {
    const { graphData, graphYAxis, graphXAxis, isProgram } = this.props;
    const myChartRef = this.chartRef.current.getContext("2d");
    if (
      window.newLineChart != null &&
      // this.props.graphShow === "INDIVIDUAL" &&
      this.props.activities?.length > 0
    ) {
      window.newLineChart.destroy();
    }
    let labelName = "";
    let dataXX = [],
      dataY2 = [],
      average = [],
      tooltipData = [],
      completionTime = [],
      calories = [],
      legendLabel = "",
      totalDistance = [],
      totalSteps = [],
      totalWellnessPoint = [];
    let toolTipUpdatedData = [];
    toolTipUpdatedData = this.state?.tooltipDataState;
    if (graphXAxis) {
      if (graphXAxis == "startDateLocal") {
        labelName = "date";
        dataXX = graphData?.map(function (e) {
          let d = e.startDateLocal.split("-");
          d = d[0] + "-" + d[1];
          return d; //e.startDateLocal;
        });
      } else if (graphXAxis === "wellnessPoint") {
        labelName = "WellnessPoint";
        dataXX = graphData?.map(function (e) {
          return e.wellnessPoints;
        });
      } else {
        labelName = graphXAxis;
        dataXX = graphData?.map(function (e) {
          let d = e[graphXAxis];
          return d;
        });
      }
    } else {
      labelName = "date";
      dataXX = graphData?.map(function (e) {
        let d = e.startDateLocal.split("-");
        d = d[0] + "-" + d[1];
        return d; //e.startDateLocal+++;
      });
    }

    if (graphYAxis) {
      if (graphYAxis == "distance") {
        dataY2 = graphData?.map(function (e) {
          let dis = e.distance;
          if (dis % 1000 === 0) {
            return parseFloat(dis / 1000).toFixed(0);
          } else {
            return parseFloat(dis / 1000).toFixed(2);
          }
        });
        legendLabel = Properties.Distance_label + " (Km)";
      } else if (graphYAxis === "wellnessPoint") {
        dataY2 = graphData?.map(function (e) {
          return e.wellnessPoints;
        });
        legendLabel = Properties.Label_WellnessPoints;
      } else if (graphYAxis === "steps") {
        dataY2 = graphData?.map(function (e) {
          return e.steps;
        });
        legendLabel = Properties.Label_Steps;
      } else {
        dataY2 = graphData?.map(function (e) {
          return e[graphYAxis];
        });

        legendLabel = graphYAxis?.charAt(0).toUpperCase() + graphYAxis.slice(1);
      }
    } else {
      dataY2 = graphData?.map(function (e) {
        return e.distance;
      });

      legendLabel = Properties.Distance_label + " (Km)";
    }
    tooltipData = graphData?.map(function (e) {
      return e[graphYAxis];
    });

    average = graphData?.map(function (e) {
      return e.averagePaceInHHMMSS;
    });

    completionTime = graphData?.map(function (e) {
      return e.completionTime;
    });
    calories = graphData
      ?.map(function (e) {
        if (e.calories !== undefined) {
          return { startDateLocal: e.startDateLocal, calories: e.calories };
        }
      })
      .filter((x) => x !== undefined);
    totalDistance = graphData?.map(function (e) {
      return parseFloat(e.distance / 1000).toFixed(2);
    });
    totalWellnessPoint = graphData?.map(function (e) {
      return e.wellnessPoints;
    });
    totalSteps = graphData?.map(function (e) {
      return e.steps;
    });
    window.newLineChart = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels:
          this.props.dataX ||
          (this.props.dateHandleChange(dataXX) &&
            this.props.dateHandleChange(dataXX)[0]),
        datasets: [
          {
            label: legendLabel,
            data:
              this.props.dataY ||
              (this.props.dateHandleChange(dataY2) &&
                this.props.dateHandleChange(dataY2)[0]),
            backgroundColor: this.props.barColor || "#a52a2a",
          },
        ],
      },
      options: {
        layout: {
          padding: {
            top: 20, //fix for cutoff issue
          },
        },

        scales: {
          yAxes: [
            {
              type: "linear",
              gridLines: {
                drawOnChartArea: false,
              },
              ticks: {
                //display: false,
                beginAtZero: true,
                fontSize: this.props.isProgram
                  ? document.documentElement.clientWidth < 1200
                    ? 12
                    : 15
                  : 10,
                fontWeight: this.props.isProgram && "700",
                fontColor: this.props.isProgram && "black",
                userCallback: function (label, index, labels) {
                  // when the floored value is the same as the value we have a whole number

                  if (
                    (graphYAxis === "wellnessPoint" ||
                      graphYAxis === "steps") &&
                    Math.floor(label) === label
                  ) {
                    return ApplicationUtil.formatNumberWithCommas(label);
                  }

                  if (
                    Math.round(label).toFixed(2) === label ||
                    graphYAxis === "distance"
                  ) {
                    return ApplicationUtil.formatNumberWithCommas(label);
                  }
                },
                // paddingBottom: 30,
              },
            },
          ],
          xAxes: [
            {
              barPercentage: this.props.isProgram ? 0.25 : 0.2,
              categoryPercentage: 1,
              gridLines: {
                drawOnChartArea: this.props.isProgram ? false : true,
              },
              //categoryPercentage: 2,
              ticks: {
                fontWeight: this.props.isProgram && "700",
                fontSize: this.props.isProgram
                  ? document.documentElement.clientWidth < 1200
                    ? 12
                    : 15
                  : 10,
                autoSkip: false,
                autoSkipPadding: 15,
              },
            },
          ],
        },

        tooltips: {
          enabled: isProgram ? false : true,
          responsive: true,
          callbacks: {
            label: function (tooltipItem) {
              // var avgPace = labelName + ": " + average[tooltipItem.index];

              let calorieValue = calories.filter((calorieItem) =>
                calorieItem.startDateLocal?.includes(tooltipItem?.label)
              )[0]?.calories;

              var newTooltip =
                graphYAxis !== undefined
                  ? graphYAxis === "distance"
                    ? distance
                    : graphYAxis === "steps"
                    ? step
                    : graphYAxis === "wellnessPoints"
                    ? wellnessPoint
                    : graphYAxis?.charAt(0).toUpperCase() +
                      graphYAxis?.slice(1) +
                      ":" +
                      tooltipData[tooltipItem.index]
                  : "";

              var distance =
                Properties.Distance_label + ": " + tooltipItem.yLabel + " km";
              var step =
                Properties.Label_Steps +
                ": " +
                //    toolTipUpdatedData.length>0 ?  toolTipUpdatedData[tooltipItem?.index] :  totalSteps[tooltipItem.index]
                tooltipItem.yLabel;
              var calorie = "Calories" + ": " + calorieValue || 0;
              var wellnessPoint =
                Properties.Label_WellnessPoints + ": " + tooltipItem.yLabel;
              var time =
                Properties.CompletionTime_label +
                ": " +
                completionTime[tooltipItem.index];

              // if (avgPace && distance && time) return [avgPace, distance, time];
              if (graphYAxis === "steps") {
                if (calorie[tooltipItem.index] && isProgram) {
                  return [step, calorie];
                } else return [step];
              } else if (graphYAxis === "wellnessPoint") {
                return [wellnessPoint];
              } else if (newTooltip) {
                return [distance];
              } else if (distance) {
                return [distance];
              } else return null;
            },
          },
        },

        //events:['hover'],
        onHover: false,
        legend: {
          // display: true,
          display: this.props.isProgram ? false : true,
          position: "bottom",
          align: "end",
          padding: 5,
          onClick: () => {},

          labels: {
            fontSize: 12,
            //fontWeight: "20px",
            boxWidth: 15,
            fontWeight: "700",
            fontColor: "black",
            fontFamily: "'Rubik', sans-serif",
            //fontColor: "purple",    // to change color of text on bar
            //usePointStyle: true
          },
          //legendText: "Km",
        },
        responsive: true,
        zoom: {
          enabled: false,
        },
        hover: {
          animationDuration: 0,
          // mode: "null",
          //  intersect:false
        },
        animation: {
          duration: 0,
          onComplete: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;

            //ctx.textAlign = "center";
            //ctx.textBaseline = "bottom";

            // ctx.fontSize = 8;
            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                var data = dataset.data[index];
                ctx.font = "15px !important";
                var height = chartInstance.controller.boxes[0].bottom;

                ctx.textAlign = "center";
                ctx.fillText(
                  ApplicationUtil.formatNumberWithCommas(data || 0),
                  bar._model.x,
                  bar._model.y - 5
                );
              });
            });
          },
        },

        maintainAspectRatio: false,
      },
    });
  };

  render() {
    const { classes, graphData, graphXAxis, graphYAxis, isProgram } =
      this.props;
    // let {dataX,dataY2}=this.state;

    return (
      <>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {window.innerWidth > 480 ? (
            <canvas
              id="LineGraph"
              ref={this.chartRef}
              style={{
                width: "800px",
                maxWidth: "800px",
                height: "300px",
                position: "relative",
                overflow: "hidden",
              }}
            />
          ) : (
            <canvas
              id="LineGraph"
              ref={this.chartRef}
              style={{
                width: "100%",
                height: "300px",
                position: "relative",
                overflow: "hidden",
              }}
            />
          )}
        </div>
      </>
    );
  }
}
export default compose(withStyles(styles, { withTheme: true }))(LineGraph);
