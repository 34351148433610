import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { isBrowser } from "react-device-detect";
import { Grid, Switch, Tooltip, Typography } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

import { CustomTextField } from "../../../../../../common/CustomTextField";
import MultiSelect from "../../../../../../common/MultiSelect";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import ChipInput from "material-ui-chip-input"; // uncontrolled input

import { IconCalendarBlue } from "../../../../../../common/SvgIcon";
import Properties from "../../../../../../../properties/Properties";
import moment from "moment";
import SubmitCancelBtn from "../../../../../../common/SubmitCancelBtn";
import InlineDivider from "../../../../../../common/InlineDivider";
import NewTextEditor from "../../../../../../common/NewTextEditor";
import DialogBox from "../../../../../../common/DialogBox";
import ApplicationConstants from "../../../../../../../constants/ApplicationConstants";
import Snackbar from "../../../../../../common/Snackbar";
import ApplicationUtil from "../../../../../../../utils/ApplicationUtil";
import {
  createProgramChallenge,
  getProgramChallenge,
  getProgramChallengeType,
  publicProgramChallenge,
  updateProgramChallenge,
} from "../../../../../../../apis/eventService";
import { IsCurrentDraftChallengeData } from "../../../../../../../redux/supportUser";
import { useDispatch } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";

const BasicView = ({ classes, history, onSuccess }) => {
  const selectedProgramDetails = ApplicationUtil.getSupportsProgramDetails();

  const currentChallenge = ApplicationUtil?.getProgramUnderCurrentChallenge();

  const dispatch = useDispatch();
  const [basicViewState, seTBAsicViewTab] = useState({
    name: "",
    nameError: "",
    programId: "",
    challengeStartDate: null,
    challengeStartTimeError: false,
    challengeResponseBasedEndOnDate: null,
    challengeResponseBasedEndOnDateError: false,
    challengeEndDateError: false,
    challengeEndDate: null,
    registrationEndDate: null,
    programChallengeState: null,
    programChallengeTypeList: [],
    description: "",
    longDescription: "",
    programChallengeSettings: "",
    videoLink: "",
    eventNameError: "",
    descriptionDialog: false,
    localEndDate: null,
    eventDateTime: null,
    htmlDescription: "",
    longDescription: "",
    registrationEndDateTimeError: "",
    challengeType: "",
    challengeTypeError: "",
    challengeTypeList: [],
    longDescriptionDialog: false,
    videoLink: "",
    showCancelDialog: false,
    isRender: false,
    htmlLongDescription: "",
    challengeData: {},
  });
  const [isPastChallenge, setIsPastChallenge] = useState(false);

  let {
    name,
    nameError,
    challengeStartDate,
    challengeEndDate,
    registrationEndDate,
    descriptionDialog,
    description,
    longDescription,

    eventNameError,
    programChallengeTypeList,
    localEndDate,

    challengeStartTimeError,
    challengeEndDateError,

    registrationEndDateTimeError,
    eventType,
    eventTypeError,
    htmlDescription,
    videoLink,
    challengeType,
    challengeTypeError,
    challengeTypeList,
    longDescriptionDialog,
    showCancelDialog,
    htmlLongDescription,
    isRender,
    challengeData,
    challengeResponseBasedEndOnDate,
    challengeResponseBasedEndOnDateError,
  } = basicViewState;

  const handleChallengeTypeChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      seTBAsicViewTab((prev) => ({
        ...prev,
        challengeType: selectedValues,
        challengeTypeError: false,
      }));
    } else {
      seTBAsicViewTab((prev) => ({
        ...prev,
        challengeType: selectedValues,
        challengeTypeError: false,
      }));
    }
  };

  const editDescription = () => {
    return (
      <div
        style={{
          width: "80%",
          height: "auto",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <NewTextEditor
          htmlText={description ? description : ""}
          getEditedValue={(value) => {
            let formattedValue = value === "<p></p>" ? null : value.toString();

            seTBAsicViewTab((prev) => ({
              ...prev,

              description: formattedValue,
            }));
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <SubmitCancelBtn
            type="submit"
            label={"Submit"}
            onClick={() => {
              seTBAsicViewTab((prev) => ({
                ...prev,
                descriptionDialog: false,
                htmlDescription: description,
              }));
            }}
          />
        </div>
      </div>
    );
  };

  const editLongDescription = () => {
    return (
      <div
        style={{
          width: "80%",
          height: "auto",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <NewTextEditor
          htmlText={longDescription ? longDescription : ""}
          getEditedValue={(value) => {
            let formattedValue = value === "<p></p>" ? null : value.toString();

            seTBAsicViewTab((prev) => ({
              ...prev,

              longDescription: formattedValue,
            }));
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <SubmitCancelBtn
            type="submit"
            label={"Submit"}
            onClick={() => {
              seTBAsicViewTab((prev) => ({
                ...prev,
                longDescriptionDialog: false,
                htmlLongDescription: longDescription,
              }));
            }}
          />
        </div>
      </div>
    );
  };

  const copyLink = () => {
    Snackbar.show({
      variant: "success",
      isAutoHide: true,
      preventDuplicate: true,
      message: "Link Copied",
    });
  };

  const validateFieldsForDraft = (btnClicked) => {
    if (name === "" || nameError === true) {
      seTBAsicViewTab((prev) => ({ ...prev, nameError: true }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter Challenge name.",
      });
      goToTop();
    } else if (!challengeType?.value || challengeTypeError === true) {
      seTBAsicViewTab((prev) => ({
        ...prev,
        challengeTypeError: true,
      }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter challenge type.",
      });
      goToTop();
    } else if (!challengeStartDate || challengeStartTimeError === true) {
      seTBAsicViewTab((prev) => ({ ...prev, challengeStartTimeError: true }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter challenge start time.",
      });
      goToTop();
    } else if (!challengeEndDate || challengeEndDateError === true) {
      seTBAsicViewTab((prev) => ({ ...prev, challengeEndDateError: true }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter challenge end time.",
      });
      goToTop();
    } else if (!registrationEndDate || registrationEndDateTimeError === true) {
      seTBAsicViewTab((prev) => ({
        ...prev,
        registrationEndDateTimeError: true,
      }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter challenge registration end date time.",
      });
      goToTop();
    } else if (
      !challengeResponseBasedEndOnDate ||
      challengeResponseBasedEndOnDate === true
    ) {
      seTBAsicViewTab((prev) => ({
        ...prev,
        challengeResponseBasedEndOnDate: true,
      }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter response submission end date.",
      });
      goToTop();
    } else {
      const paramsData = {
        programId: selectedProgramDetails?.id,
        name: name,
        challengeStartDate: moment(challengeStartDate).format(
          "DD-MM-YYYY HH:mm:ss"
        ),
        challengeEndDate: moment(challengeEndDate).format(
          "DD-MM-YYYY 23:59:59"
        ),
        registrationEndDate: moment(registrationEndDate).format(
          "DD-MM-YYYY HH:mm:ss"
        ),
        videoLink: videoLink || "",
        programChallengeType: challengeType?.value,
        description: description,
        longDescription: longDescription,
        responseSubmissionEndDate: moment(
          challengeResponseBasedEndOnDate
        ).format("DD-MM-YYYY HH:mm:ss"),
      };

      if (btnClicked === "publish") {
        publicProgramChallenge(currentChallenge?.id).then((res) => {
          getProgramChallenge(currentChallenge?.id).then((res) => {
            dispatch(IsCurrentDraftChallengeData(res.data));
            seTBAsicViewTab((prev) => ({
              ...prev,
              challengeData: res.data,
            }));
          });
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Program challenge published successfully",
          });
        });
      }

      if (!currentChallenge) {
        createProgramChallenge(paramsData).then((res) => {
          dispatch(IsCurrentDraftChallengeData(res.data));
          onSuccess();
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Program challenge created successfully",
          });
        });
      } else if (btnClicked !== "publish") {
        updateProgramChallenge({
          id: currentChallenge?.id,
          data: paramsData,
        }).then((res) => {
          getProgramChallenge(currentChallenge?.id).then((res) => {
            dispatch(IsCurrentDraftChallengeData(res.data));
            seTBAsicViewTab((prev) => ({
              ...prev,
              challengeData: res.data,
            }));
          });
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Program challenge updated successfully",
          });
        });
      }
    }
  };

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    let setPermissions = JSON.parse(localStorage.getItem("supportPermissions"));
    setIsPastChallenge(!setPermissions?.canModifyProgram)
    getProgramChallengeType().then((res) => {
      if (res.status === 200) {
        let formattedData = res.data?.map((item) => {
          return { label: item, value: item };
        });
        seTBAsicViewTab((prev) => ({
          ...prev,
          programChallengeTypeList: formattedData,
        }));
      }
    });
  }, []);

  useEffect(() => {
    if (currentChallenge) {
      seTBAsicViewTab((prev) => ({
        ...prev,

        name: currentChallenge?.name,
        // isRender: true,
        challengeEndDate: moment(
          currentChallenge?.challengeEndDate,
          "DD-MM-YYYY HH:mm:ss"
        ),
        challengeStartDate: moment(
          currentChallenge?.challengeStartDate,
          "DD-MM-YYYY HH:mm:ss"
        ).isValid()
          ? moment(currentChallenge?.challengeStartDate, "DD-MM-YYYY HH:mm:ss")
          : null,
        registrationEndDate: moment(
          currentChallenge?.registrationEndDate,
          "DD-MM-YYYY HH:mm:ss"
        ).isValid()
          ? moment(currentChallenge?.registrationEndDate, "DD-MM-YYYY HH:mm:ss")
          : null,
        challengeResponseBasedEndOnDate: moment(
          currentChallenge?.responseSubmissionEndDate,
          "DD-MM-YYYY HH:mm:ss"
        ).isValid()
          ? moment(
              currentChallenge?.responseSubmissionEndDate,
              "DD-MM-YYYY HH:mm:ss"
            )
          : null,
        description: currentChallenge?.description,
        longDescription: currentChallenge?.longDescription,
        htmlDescription: currentChallenge?.description,
        htmlLongDescription: currentChallenge?.longDescription,
        videoLink: currentChallenge.videoLink
          ? currentChallenge.videoLink || ""
          : "",

        challengeType: {
          label: currentChallenge?.programChallengeType,
          value: currentChallenge?.programChallengeType,
        },
      }));
    }
  }, []);

  const getDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.CreateEvent_CancelClickWarning}</span>
        </Typography>
      </div>
    );
  };

  const closeDialogBox = () => {
    seTBAsicViewTab((prev) => ({ ...prev, showCancelDialog: false }));
    history.push("/organizer/eventlist");
  };

  // useEffect(() => {

  // }, [basicViewState]);

  return (
    <div>
      {/* initial section */}
      <Grid container spacing={1} style={{ padding: "10px 0px" }}>
        {/* 1 */}
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>
              {Properties.ChallengeNameLabel}
            </span>
            <span className={classes.mandatoryCss}>*</span>
          </Typography>
          <CustomTextField
            value={name || ""}
            placeholder="Enter Challenge Name"
            className={nameError ? classes.fieldErrorCss : classes.fieldCss}
            handleChange={(prop) => (e) => {
              seTBAsicViewTab((prev) => ({
                ...prev,
                name: e.target.value,
                nameError: false,
              }));
            }}
            readOnly={isPastChallenge}
          />
        </Grid>

        {/* 2 */}
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>
              {Properties.ChallengeTypeLabel}
            </span>
            <span className={classes.mandatoryCss}>*</span>
          </Typography>
          <div className={classes.dropdownCss}>
            <MultiSelect
              value={challengeType || ""}
              error={challengeTypeError}
              options={programChallengeTypeList}
              onChange={handleChallengeTypeChange}
              isReadOnly={isPastChallenge}
            />
          </div>
        </Grid>

        {/* 3 */}
        <Grid item xs={6}>
          <Typography
            variant="body2"
            gutterBottom
            className={classes.helpContainerCss}
          >
            <span className={classes.labelCss}>
              {Properties.challengeStartDateLabel}
            </span>
            <span className={classes.mandatoryCss}>*</span>
            <span className={classes.helpCss}>
              <Tooltip title={Properties.EventStartsFromHelpText}>
                <HelpIcon fontSize="small" />
              </Tooltip>
            </span>
          </Typography>

          <div>
            <KeyboardDateTimePicker
              style={{
                width: isBrowser ? "360px" : "160px",
              }}
              autoOk={true}
              variant="inline"
              inputVariant="outlined"
              // ampm={false}
              format="dd/MM/yyyy hh:mm a" //:ss-hh" //"dd/MM/yyyy"
              // disableFuture={true}
              value={challengeStartDate}
              minDate={currentChallenge ? undefined : moment()}
              maxDate={localEndDate ? localEndDate : undefined}
              maxDateMessage={Properties.EventStartDateValidationMsg}
              FormHelperTextProps={{
                style: {
                  margin: "0px",
                },
              }}
              className={classes.fieldWidth}
              InputProps={{
                classes: {
                  root: challengeStartTimeError
                    ? classes.datePickerError
                    : classes.datePicker,
                },
              }}
              keyboardIcon={
                <IconCalendarBlue className={classes.calendarIconCss} />
              }
              onChange={(date) => {
                seTBAsicViewTab((prev) => ({
                  ...prev,
                  challengeStartDate: date ? date : null,
                  challengeStartTimeError: false,
                }));
              }}
              disabled={isPastChallenge}
            />
          </div>
        </Grid>

        {/* 4 */}
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>
              {Properties.challengeEndDateLabel}
            </span>
            <span className={classes.mandatoryCss}>*</span>
          </Typography>
          <div>
            <KeyboardDatePicker
              autoOk={true}
              // clearable
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy "
              // disableFuture={true}
              value={challengeEndDate}
              minDate={challengeStartDate ? challengeStartDate : undefined}
              minDateMessage={Properties.EventEndDateValidationMsg}
              // FormHelperTextProps={{
              //   style: {
              //     margin: "0px",
              //   },
              // }}
              style={{
                width: isBrowser ? "360px" : "160px",
              }}
              //error={dobError}
              className={classes.fieldWidth}
              InputProps={{
                classes: {
                  root: challengeEndDateError
                    ? classes.datePickerError
                    : classes.datePicker,
                },
              }}
              keyboardIcon={
                <IconCalendarBlue className={classes.calendarIconCss} />
              }
              onChange={(date) => {
                seTBAsicViewTab((prev) => ({
                  ...prev,
                  challengeEndDate: date ? date : null,
                  challengeEndDateError: false,
                }));
              }}
              disabled={isPastChallenge}
            />
          </div>
        </Grid>

        {/* 5 */}
        <Grid item xs={6}>
          <Typography
            variant="body2"
            gutterBottom
            className={classes.helpContainerCss}
          >
            <span className={classes.labelCss}>
              {Properties.RegistrationEndDateLabel}
            </span>
            <span className={classes.mandatoryCss}>*</span>
            <span className={classes.helpCss}>
              <Tooltip title={Properties.RegistrationEndDateHelpText}>
                <HelpIcon fontSize="small" />
              </Tooltip>
            </span>
          </Typography>

          <div>
            <KeyboardDateTimePicker
              style={{
                width: isBrowser ? "360px" : "160px",
              }}
              autoOk = {true}
              clearable
              variant="inline"
              inputVariant="outlined"
              // ampm={false}
              format="dd/MM/yyyy hh:mm a" //:ss-hh" //"dd/MM/yyyy"
              // disableFuture={true}
              value={registrationEndDate}
              // minDate={challengeStartDate ? challengeStartDate : undefined}
              maxDate={challengeEndDate ? challengeEndDate : undefined}
              maxDateMessage={Properties.RegistrationEndDateValidationMsg}
              FormHelperTextProps={{
                style: {
                  margin: "0px",
                },
              }}
              className={classes.fieldWidth}
              InputProps={{
                classes: {
                  root: registrationEndDateTimeError
                    ? classes.datePickerError
                    : classes.datePicker,
                },
              }}
              keyboardIcon={
                <IconCalendarBlue className={classes.calendarIconCss} />
              }
              onChange={(date) => {
                seTBAsicViewTab((prev) => ({
                  ...prev,
                  registrationEndDate: date,
                  registrationEndDateTimeError: false,
                }));
              }}
              disabled={isPastChallenge}
            />
          </div>
        </Grid>

        {/* 6 */}

        <Grid item xs={6}>
          <Typography
            variant="body2"
            gutterBottom
            className={classes.helpContainerCss}
          >
            <span className={classes.labelCss}>
              Response submission ends on
            </span>
            <span className={classes.mandatoryCss}>*</span>
            <span className={classes.helpCss}>
              <Tooltip title={Properties.RegistrationEndDateHelpText}>
                <HelpIcon fontSize="small" />
              </Tooltip>
            </span>
          </Typography>

          <div>
            <KeyboardDateTimePicker
              style={{
                width: isBrowser ? "360px" : "160px",
              }}
              autoOk = {true}
              clearable
              variant="inline"
              inputVariant="outlined"
              // ampm={false}
              format="dd/MM/yyyy hh:mm a" //:ss-hh" //"dd/MM/yyyy"
              // disableFuture={true}
              value={challengeResponseBasedEndOnDate}
              minDate={challengeEndDate ? challengeEndDate : undefined}
              maxDateMessage={Properties.RegistrationEndDateValidationMsg}
              FormHelperTextProps={{
                style: {
                  margin: "0px",
                },
              }}
              className={classes.fieldWidth}
              InputProps={{
                classes: {
                  root: challengeResponseBasedEndOnDateError
                    ? classes.datePickerError
                    : classes.datePicker,
                },
              }}
              keyboardIcon={
                <IconCalendarBlue className={classes.calendarIconCss} />
              }
              onChange={(date) => {
                seTBAsicViewTab((prev) => ({
                  ...prev,
                  challengeResponseBasedEndOnDate: date,
                  challengeResponseBasedEndOnDateError: false,
                }));
              }}
              disabled={isPastChallenge}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>{"Video Link"}</span>
          </Typography>
          <div
            style={{
              width: isBrowser ? "360px" : "160px",
              height: "30px",
              // border: "1px solid #08456C",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "2px",
              color: "#08456C",
            }}
          >
            <CustomTextField
              type="text"
              className={classes.fieldCss}
              value={videoLink}
              placeholder="Enter URL"
              handleChange={(prop) => (e) => {
                seTBAsicViewTab((prev) => ({
                  ...prev,
                  videoLink: e.target.value,
                }));
              }}
              readOnly={isPastChallenge}
            />
          </div>
        </Grid>

        <Grid item xs={6}></Grid>

        {/* 7 */}
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>
              {Properties.ChallengeSummaryLabel}
            </span>
          </Typography>

          <div
            style={{
              width: "440px",
              height: "100px",
              padding: "0 4px",
              border: "1px solid #08456C",
              overflowY: "auto",
            }}
            onClick={() => {
              seTBAsicViewTab((prev) => ({
                ...prev,
                descriptionDialog: isPastChallenge ? false : true,
                htmlDescription: isPastChallenge ? htmlDescription : "",
              }));
            }}
          >
            {htmlDescription && (
              <NewTextEditor readOnly htmlText={htmlDescription} />
            )}
          </div>
        </Grid>

        {/* 8 */}
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>
              {Properties.ChallengeLongDescriptionLabel}
            </span>
          </Typography>

          <div
            style={{
              width: "440px",
              height: "100px",
              padding: "0 4px",
              border: "1px solid #08456C",
              overflowY: "auto",
            }}
            onClick={() => {
              seTBAsicViewTab((prev) => ({
                ...prev,
                longDescriptionDialog: isPastChallenge ? false :  true,
                htmlLongDescription: isPastChallenge ? htmlLongDescription : "",
              }));
            }}
          >
            {htmlLongDescription && (
              <NewTextEditor readOnly htmlText={htmlLongDescription} />
            )}
          </div>
        </Grid>
      </Grid>

      {!isPastChallenge && (
      <Grid
        container
        style={{
          margin: "20px 5px 10px 5px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item xs={4}>
          <SubmitCancelBtn
            label={"Cancel"}
            onClick={() => {
              seTBAsicViewTab((prev) => ({ ...prev, showCancelDialog: true }));
            }}
            type="submit"
            style={{ width: "200px" }}
          />
        </Grid>

        <Grid item xs={4}>
          {currentChallenge ? (
            <SubmitCancelBtn
              onClick={() => validateFieldsForDraft()}
              disabled={currentChallenge ? true : false}
              label={"Save"}
              type="submit"
              style={{ width: "200px" }}
            />
          ) : (
            <SubmitCancelBtn
              onClick={() => validateFieldsForDraft()}
              disabled={currentChallenge ? true : false}
              label={"Draft"}
              type="submit"
              style={{ width: "200px" }}
            />
          )}
        </Grid>
        {currentChallenge &&
          currentChallenge?.programChallengeState !== "PUBLISHED" &&
          challengeData && (
            <Grid item xs={4}>
              <SubmitCancelBtn
                onClick={() => validateFieldsForDraft("publish")}
                disabled={currentChallenge ? true : false}
                label={"Publish"}
                type="submit"
                style={{ width: "200px" }}
              />
            </Grid>
          )}
      </Grid>
       )}
      {/* cancel dialog */}
      <DialogBox
        signup={true}
        open={showCancelDialog}
        message={getDialogMsg()}
        header={""}
        label={"No"}
        onClick={() => {
          seTBAsicViewTab((prev) => ({ ...prev, showCancelDialog: false }));
        }}
        labelTwo={"Yes"}
        onClickTwo={() => {
          closeDialogBox();
        }}
        closeDisloag={() => {
          seTBAsicViewTab((prev) => ({ ...prev, showCancelDialog: false }));
        }}
      />

      {/* description dialog */}
      <DialogBox
        fullWidth
        maxWidth={"md"}
        open={descriptionDialog}
        message={editDescription()}
        header={"Challenge Summary"}
        onClick={() =>
          seTBAsicViewTab((prev) => ({
            ...prev,
            descriptionDialog: false,
            htmlDescription: description,
          }))
        }
        closeDisloag={() =>
          seTBAsicViewTab((prev) => ({
            ...prev,
            descriptionDialog: false,
            htmlDescription: description,
          }))
        }
      />
      <DialogBox
        fullWidth
        maxWidth={"md"}
        open={longDescriptionDialog}
        message={editLongDescription()}
        header={"Challenge Guidelines"}
        onClick={() =>
          seTBAsicViewTab((prev) => ({
            ...prev,
            longDescriptionDialog: false,
            htmlLongDescription: longDescription,
          }))
        }
        closeDisloag={() => {
          seTBAsicViewTab((prev) => ({
            ...prev,
            longDescriptionDialog: false,
            htmlLongDescription: longDescription,
          }));
        }}
      />
    </div>
  );
};

const styles = (theme) => ({
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },
  helpContainerCss: {
    display: "inline-flex",
  },
  helpCss: {
    display: "flex",
    color: "#3a6ea5",
  },
  labelCss: {
    color: "#727272",
  },
  mandatoryCss: { color: "red" },
  fieldCss: {
    border: "1px solid #08456C",
    height: "40px", //"45%",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
    // focused: {},
    // notchedOutline: {},
  },
  fieldErrorCss: {
    border: "1px solid red",
    height: "40px",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  chipCss: {
    border: "1px solid #08456C",
    borderRadius: "4px",
    // height: "40px", //"45%",
    padding: "2px",
    // maxHeight: "40px",
    // minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  chipErrorCss: {
    border: "1px solid red",
    borderRadius: "4px",
    // height: "40px",
    padding: "2px",
    // maxHeight: "40px",
    // minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  textAreafieldCss: {
    border: "1px solid #08456C",
    height: "100px",
    maxHeight: "100px",
    minHeight: "100px",
    width: isBrowser ? "360px" : "160px",
  },
  textAreaErrorCss: {
    border: "1px solid red",
    height: "100px",
    maxHeight: "100px",
    minHeight: "100px",
    width: isBrowser ? "360px" : "160px",
  },
  dropdownCss: {
    width: isBrowser ? "360px" : "160px",
  },
  multiSelectGridCss: {
    margin: "4px 0px",
  },
  innerGridCss: {
    padding: "4px 0px",
  },
  supportedDomainsCss: {
    underline: {
      "&:hover": {
        "&:before": {
          borderBottom: ["rgba(0, 188, 212, 0.7)", "!important"],
        },
      },
      "&:before": {
        borderBottom: "rgba(0, 188, 212, 0.7)",
      },
    },
  },
  // },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(BasicView);
