import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import parse from "html-react-parser";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import Properties from "../../../../../../../../properties/Properties";
import ApplicationUtil from "../../../../../../../../utils/ApplicationUtil";

const ListingOfEmailContent = ({
  classes,
  list,
  parentTopScrollView,
  setListOfContents,
  ...props
}) => {
  const [selectedEle, setSelectedEle] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event, data) => {
    console.log(event.currentTarget, event, "current target");

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div
      style={{
        maxHeight: "300px",
        overflowY: "auto",
        // position: "relative",
      }}
    >
      <h3 className={classes.title}>
        {Properties?.email_reminder_section_list}:
      </h3>
      <Table stickyHeader style={{ minHeight: "300px" }}>
        <TableHead>
          <TableRow className={classes.tableHead}>
            {/* {!isPastChallenge && (
                    )} */}
            <TableCell
              component="th"
              style={{ width: 50 }}
              textAlign="center"
            ></TableCell>
            <TableCell component="th" textAlign="center">
              {Properties?.subject_label}
            </TableCell>
            <TableCell component="th" textAlign="center">
              {Properties?.program_challenge_type}
            </TableCell>{" "}
            <TableCell component="th" textAlign="center">
              {Properties?.content_label}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody
          style={{
            maxHeight: "300px",
            overflowY: "auto",
            position: "relative",
          }}
        >
          {list?.length > 0 &&
            list?.map((Content, index) => {
              let { content, id, programChallengeType, subject } = Content;

              return (
                <TableRow key={id}>
                  <TableCell
                    textAlign="center"
                    style={{ verticalAlign: "baseline", width: 50 }}
                  >
                    <IconButton>
                      <ArrowDropDownCircleOutlinedIcon
                        className={classes.editIcon}
                        onClick={(event) => {
                          handleClick(event);
                          setSelectedEle(Content);
                        }}
                      />
                    </IconButton>
                  </TableCell>

                  <TableCell
                    textAlign="center"
                    style={{ verticalAlign: "baseline", width: "200px" }}
                  >
                    {subject}
                  </TableCell>
                  <TableCell
                    component="td"
                    textAlign="center"
                    style={{ verticalAlign: "baseline" }}
                  >
                    {programChallengeType
                      ? ApplicationUtil.capitalize(
                          programChallengeType.replace(/_/g, " ")
                        )
                      : ""}
                  </TableCell>
                  <TableCell
                    component="td"
                    textAlign="center"
                    style={{ verticalAlign: "baseline", width: "300px" }}
                  >
                    {content ? parse(content) : ""}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>

      <Menu
        id="popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ marginTop: "40px" }}
      >
        <MenuItem
          onClick={() => {
            parentTopScrollView.current.scrollIntoView();
            handleClose();
            setListOfContents((prev) => ({
              ...prev,
              editRecord: selectedEle,
            }));
          }}
        >
          Modify Content
        </MenuItem>
        {/* <MenuItem
        // onClick={() => {
        //   setProductState((prev) => ({
        //     ...prev,
        //     anchorEl: null,
        //     deleteProductWarningOpen: true,
        //   }));
        // }}
        >
          Delete Content
        </MenuItem> */}
      </Menu>
    </div>
  );
};

const styles = {
  tableHead: {
    backgroundColor: "#ced4da !important",
    "& th:first-child": {
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
      width: "30px",
    },
    "& th": {
      backgroundColor: "#ced4da",
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
      width: "100px",
    },
  },
  tableBody: {
    height: "300px",
    overflowY: "auto",
    "& tr td": {
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      // textAlign: "center",
    },
  },
};
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ListingOfEmailContent);
