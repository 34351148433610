import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import ProgressBar from "../../../common/ProgressBar";
import { Typography } from "@material-ui/core";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import Individual from "../events/Individual";
import { useDispatch } from "react-redux";
import { updateUserSelectedEvent } from "../../../../redux/user";
import Properties from "../../../../properties/Properties";

function StepsChallengeComponent({
  classes,
  history,
  individualValues,
  verticalBarGraphData,
  dateHandleChange,

  dateRangeOptions,
}) {
  const [individualGraphDetails, setIndividualGraphDetails] = useState();
  const [scrollToGraphView, setScrollToGraphView] = useState();
  const dispatch = useDispatch();
  const getProgressTarget = () => {
    return ApplicationUtil.formatNumberWithCommas(
      individualValues?.targetedProgressIndividual || 0
    );
  };
  function getIndex(val) {
    let idx = dateRangeOptions.findIndex(
      (x) => x.displayDate == val.target.value
    );
    const X = dateRangeOptions[idx]?.value?.trackingEstimationByDate[idx];
    const Y = dateRangeOptions[idx]?.value?.selectedTrackingDates[idx];
    const Z = dateRangeOptions[idx]?.value?.selectedTooltip[idx];
    setIndividualGraphDetails({ dataX: X, dataY: Y, tooltipDataState: Z });
    setScrollToGraphView(val.target.value);
  }
  const getProgressPercentage = () => {
    let percentage =
      (individualValues?.result?.totalSteps /
        individualValues?.targetedProgressIndividual) *
      100;
    return percentage > 100 ? 100 : percentage;
  };

  function navigateToWellnessPointsDetails() {
    let programDetails = ApplicationUtil.getRunnersProgramDetails();
    dispatch(updateUserSelectedEvent(programDetails?.eventDTO[0]));
    history.push("/wellnessDetails");
  }

  useEffect(() => {
    if (scrollToGraphView) {
      const element = document.getElementById("stepChallengeRef");

      if (element) {
        element.scrollIntoView({
          block: "end",
          inline: "end",
          behavior: "instant",
        });
      }
    }
  }, [scrollToGraphView]);

  return (
    <div id="stepChallengeRef" className={classes.stepsChallengeContainer}>
      <div
        className={classes.stepsChallengeProgressContainer}
        style={{
          display:
            individualValues?.totalTarget !== 0 &&
            individualValues?.challengeGoal === "TOTAL_TARGET"
              ? "flex"
              : "block",
        }}
      >
        <h2 className={classes.chartTitle}>
          {verticalBarGraphData?.eventName?.toLowerCase() || "Steps Challenge"}:
        </h2>

        {individualValues?.totalTarget !== 0 &&
        individualValues?.challengeGoal === "TOTAL_TARGET" ? (
          <div className="progressBarContainer">
            <ProgressBar
              percent={getProgressPercentage()}
              fillingProgressColor={"#FE7235"}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginInline: "20px",
              }}
            >
              <Typography variant="body2" gutterBottom>
                <span className={classes.totalDetailsProg}>
                  <span className={"stepsTitle"}>
                    {"Total Steps"}:&nbsp;
                    {ApplicationUtil.formatNumberWithCommas(
                      individualValues?.result?.totalSteps
                        ? individualValues?.result?.totalSteps
                        : 0
                    )}
                  </span>
                </span>
              </Typography>
              <Typography variant="body2" gutterBottom>
                <span className={classes.totalDetailsProg}>
                  <span className={"stepsTitle"}>
                    {"Target"}:&nbsp;
                    {getProgressTarget()}
                  </span>
                </span>
              </Typography>
            </div>
          </div>
        ) : (
          <Typography
            variant="body2"
            gutterBottom
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <span className={classes.totalDetailsProg}>
              <span className={"stepsTitle"}>
                {"Total Steps"}:&nbsp;
                {ApplicationUtil.formatNumberWithCommas(
                  individualValues?.result?.totalSteps
                    ? individualValues?.result?.totalSteps
                    : 0
                )}
              </span>
            </span>
          </Typography>
        )}
      </div>
      <div className={classes.individualGraphContainer}>
        {individualValues && Object.keys(individualValues).length > 0 && (
          <>
            <Individual
              individualValues={{
                ...individualValues,
                tooltipDataState: individualGraphDetails?.tooltipDataState,
              }}
              navigateToWellnessPointsDetails={navigateToWellnessPointsDetails}
              dateHandleChange={dateHandleChange}
              dataX={individualGraphDetails?.dataX}
              dataY={individualGraphDetails?.dataY}
              getIndex={getIndex}
              barColor={"#FE7235"}
              isProgram={true}
              graphData={individualValues?.individualGraphData}
            />
          </>
        )}
      </div>
    </div>
  );
}

const styles = (theme) => ({
  mainContainer: {
    fontFamily: '"Karla", sans-serif',
    margin: "5px",
    marginInline: "20px",
    [theme.breakpoints.down("sm")]: { marginInline: "5px" },
  },
  dashBoardTitle: {
    letterSpacing: "-1px",
    fontWeight: "bold",
    padding: 0,
    fontSize: "40px",
    marginTop: 0,
    marginBottom: "0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  chartTitle: {
    letterSpacing: "-1px",
    marginBottom: 0,
    color: "#202260",
    fontWeight: "bold",
    fontSize: "35px",
    marginLeft: "10px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      marginLeft: "0px",
    },
  },
  titleContainer: {
    // border: "1px solid",
    color: "#202260",
    borderColor: "#d1d1d1",
    textAlign: "center",
  },
  datesContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginInline: "10px",
    "& .date2": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    "& .scoreTitle": {
      marginTop: "5px",
      fontWeight: "600",
      fontSize: "26px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
  },

  stepsChallengeContainer: {
    marginTop: "20px",
  },
  individualGraphContainer: {
    marginTop: "20px",
    "& .cooperLegendBlock": {
      display: "flex",
      justifyContent: "end",
      marginRight: "44px",
      gap: "10px",
      alignItems: "center",
      "& .legendBlock": {
        width: "40px",
        height: "18px",
        backgroundColor: "#FE7235",
        [theme.breakpoints.down("sm")]: {
          width: "30px",
          height: "12px",
        },
      },
      "& .legendBlockTitle": {
        fontSize: "20px",
        fontWeight: "500",
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
          fontWeight: "500",
        },
      },
    },
  },

  stepsChallengeProgressContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "baseline",
    gap: "80px",
    [theme.breakpoints.down("sm")]: {
      gap: "20px",
      flexDirection: "column",
    },
    "& .progressBarContainer": {
      width: "60%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  totalDetailsProg: {
    color: "black",
    fontWeight: "bold",
    fontSize: "28px",
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",
      fontSize: "20px",
      height: "20%",
      justifyContent: "flex-start",
      margin: "0",
    },
    "& .stepsTitle": {
      color: "black",
      fontWeight: "bolder",
      fontSize: "26px",
      margin: "0",
      // marginLeft: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
  },
  breadcrumbs_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    "& .breadcrumbs": {
      [theme.breakpoints.down("sm")]: {
        alignSelf: "start",
      },
    },
    "& .dashboard_logo": {
      [theme.breakpoints.down("sm")]: {
        alignSelf: "end",
      },
    },
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(StepsChallengeComponent);
