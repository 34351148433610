import { Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { withRouter } from "react-router-dom";
import Properties from "../../../../properties/Properties";
import moment from "moment";
import { compose } from "recompose";

function CooperTest({ classes, history, cooperTestData, cooperTest }) {
  return (
    <>
      <h2 className={classes.chartTitle}>
        {Properties.program_dashboard.chart_titles.cooper_test}:
      </h2>
      <div className={classes.datesContainer}>
        <p className={"scoreTitle"}>
          {Properties.Date_label}:&nbsp;
          {cooperTestData &&
          cooperTestData?.length > 0 &&
          cooperTestData[0]?.eventStartDate
            ? moment(cooperTestData[0]?.eventStartDate).format("DD/MM/YYYY")
            : "-/-/-"}
        </p>
        {cooperTest.length > 1 && (
          <p className={"scoreTitle date2"}>
            {Properties.Date_label}:&nbsp;
            {cooperTestData &&
            cooperTestData?.length > 0 &&
            cooperTestData[1]?.eventStartDate
              ? moment(cooperTestData[1]?.eventStartDate).format("DD/MM/YYYY")
              : "-/-/-"}
          </p>
        )}
      </div>
      <div className={classes.doughnutChartContainer}>
        <div className={"doughnutItem"}>
          <div className={"doughnutContainer"}>
            <CircularProgressbar
              value={
                cooperTest.length > 0
                  ? cooperTest[0]?.cooperTestCurrentScore || 0
                  : 0
              }
              text={`${
                cooperTest.length > 0
                  ? Number(cooperTest[0]?.cooperTestCurrentScore || 0)
                  : 0
              }/100`}
              styles={{
                path: {
                  // Path color
                  stroke: `#FE7235`,
                },
                text: {
                  // Text color
                  fill: "#000000",
                  // Text size
                  fontSize: "15px",
                  fontWeight: "bold",
                },
              }}
            />
          </div>
          <div className={"cooperLegendBlock"}>
            <div className={"legendBlock"}></div>
            <span className={"legendBlockTitle"}>
              {Properties.program_dashboard.legends.cooper_test}
            </span>
          </div>
        </div>
        {cooperTest.length > 1 && (
          <Divider
            orientation="vertical"
            flexItem
            style={{ background: "#000000" }}
          />
        )}

        {cooperTest.length > 1 && (
          <div className={"doughnutItem"}>
            <p className={"cooperDate2"}>
              {Properties.Date_label} :&nbsp;
              {cooperTestData &&
              cooperTestData?.length > 0 &&
              cooperTestData[1]?.eventStartDate
                ? moment(cooperTestData[1]?.eventStartDate).format("DD/MM/YYYY")
                : "-/-/-"}
            </p>

            <div className={"doughnutContainer"}>
              <CircularProgressbar
                value={
                  cooperTest.length > 1
                    ? cooperTest[1]?.cooperTestCurrentScore || 0
                    : 0
                }
                text={`${
                  cooperTest.length > 1
                    ? cooperTest[1]?.cooperTestCurrentScore || 0
                    : 0
                }/100`}
                styles={{
                  path: {
                    // Path color
                    stroke: `#FE7235`,
                  },
                  text: {
                    // Text color
                    fill: "#000000",
                    // Text size
                    fontSize: "15px",
                    fontWeight: "bold",
                  },
                }}
              />
            </div>
            <div className={"cooperLegendBlock"}>
              <div className={"legendBlock"}></div>
              <span className={"legendBlockTitle"}>
                {Properties.program_dashboard.legends.cooper_test}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

const styles = (theme) => ({
  chartTitle: {
    letterSpacing: "-1px",
    marginBottom: 0,
    color: "#202260",
    fontWeight: "bold",
    fontSize: "35px",
    marginLeft: "10px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      marginLeft: "0px",
    },
  },
  datesContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginInline: "10px",
    "& .date2": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    "& .scoreTitle": {
      marginTop: "5px",
      fontWeight: "600",
      fontSize: "26px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
  },
  doughnutChartContainer: {
    width: "100%",
    display: "flex",

    marginTop: "5px",
    justifyContent: "space-between",

    "& .doughnutItem": {
      // border: "1px solid",
      position: "relative",
      width: "100%",
      paddingBottom: "20px",
      borderColor: "#b3caf6",
      "& .cooperDate2": {
        display: "none",

        [theme.breakpoints.down("sm")]: {
          display: "block",
          fontSize: "18px",
          marginTop: "5px",
          marginLeft: "10px",
          fontWeight: "600",
        },
      },

      "& .doughnutLabel": {
        position: "absolute",
        left: "131px",
        top: "91px",
        width: "50px",
        fontSize: "small",
        fontWeight: "bold",
        [theme.breakpoints.down("sm")]: {
          left: "131px",
          top: "91px",
          width: "50px",
          fontSize: "small",
        },
      },
      "& .doughnutContainer": {
        margin: "0 auto",
        height: "200px",
        width: "200px",
        [theme.breakpoints.down("sm")]: {
          height: "150px",
          width: "150px",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },

    "& .cooperLegendBlock": {
      display: "flex",
      justifyContent: "end",
      marginRight: "44px",
      gap: "10px",
      alignItems: "center",
      "& .legendBlock": {
        width: "40px",
        height: "18px",
        backgroundColor: "#FE7235",
        [theme.breakpoints.down("sm")]: {
          width: "30px",
          height: "12px",
        },
      },
      "& .legendBlockTitle": {
        fontSize: "20px",
        fontWeight: "500",
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
          fontWeight: "500",
        },
      },
    },
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(CooperTest);
