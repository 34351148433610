import React, { Component, useEffect } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import EmojiEventsTwoToneIcon from "@material-ui/icons/EmojiEventsTwoTone";
import {
  getTeamLeaderboard,
  getRunnerLeaderboard,
  getRunnerList,
  getTeamList,
  getTeamAndGroupNames,
  getOneTimeParticipantList,
  getCohortParticipantList,
  getIndividualLeaderboard,
  getCohortLocationWiseData,
  getRadioLocationWiseData,
  getAgeGroupData,
  getCohortTeamWiseData,
} from "../../../../../../apis/eventService";
import Autosuggest from "react-autosuggest";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Snackbar from "../../../../../common/Snackbar";

import {
  FormControl,
  NativeSelect,
  Grid,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import Board from "./Board";
import SubmitCancelBtn from "../../../../../common/SubmitCancelBtn";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";
import { withRouter } from "react-router-dom";
import Ticker from "react-ticker";
import { useState } from "react";
import GoldMedal from "../../../../../../images/gold-medal.png";
import SilverMedal from "../../../../../../images/silver-medal.png";
import BronzeMedal from "../../../../../../images/bronze-medal.png";
import BoardList from "./BoardList";
import { Message } from "@material-ui/icons";
import ApplicationConstants from "../../../../../../constants/ApplicationConstants";
// import BoardList from "./BoardList";
const styles = (theme) => ({
  leaderboard_top: {
    width: "90%",
    margin: "auto",
    // marginTop: "20px",
    // backgroundColor:'#caf0f8',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    "& .react-autosuggest__container": {
      position: "relative",
    },

    "& .react-autosuggest__input": {
      width: "100%",
      height: "40px",
      padding: "10px 10px",
      marginTop: "10px",
      fontFamily: "Helvetica, sans-serif",
      fontWeight: 300,
      fontSize: "16px",
      border: "1px solid #aaa",
      borderRadius: "6px",
      [theme.breakpoints.down("sm")]: {
        width: "150%",
      },
    },
    "& .react-autosuggest__input--focused": {
      outline: "none",
    },

    "& .react-autosuggest__input--open": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },

    "& .react-autosuggest__suggestions-container": {
      display: "none",
    },

    "& .react-autosuggest__suggestions-container--open": {
      display: "block",
      position: "absolute",
      top: "51px",
      width: "100%",
      textAlign: "left",
      border: "1px solid #aaa",
      backgroundColor: "#fff",
      fontFamily: "Helvetica, sans-serif",
      fontWeight: 300,
      fontSize: "16px",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      zIndex: 2,
      overflow: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "150%",
        marginRight: "15%",
      },
    },

    "& .react-autosuggest__suggestions-list": {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },

    "& .react-autosuggest__suggestion": {
      cursor: "pointer",
      padding: "10px 20px",
    },

    "& .react-autosuggest__suggestion--highlighted": {
      backgroundColor: "#ddd",
      color: "#000",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "15%",
    },
  },

  container_top: {
    /* width: 20%; */
    display: "flex",
    alignItems: "center",
    gap: "5px",
    /* justify-content: space-around; */
  },
  root: {
    width: "100%",
    "& h3": {
      fontSize: "18px",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  winnerTeamsContainer: {
    marginTop: "30px",
    display: "flex",
    alignItems: "flex-start",
    "& .teamLayout": {
      color: "#ffffff",
      backgroundColor: "#0077b6",
      borderRadius: "0",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "0px",
    },
    "& .teamWinner1": {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "89%",
      },
    },
    "& .teamWinner2": {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "89%",
      },
    },
    "& .teamWinner3": {
      width: "100%",
      marginBottom: "10px",
      [theme.breakpoints.down("sm")]: {
        width: "89%",
      },
    },

    "& .medalIcons": {
      height: 60,
      width: 60,
      objectFit: "contain",
      [theme.breakpoints.down("sm")]: {
        height: 35,
        width: 35,
      },
    },
  },
  winnerTeamsContainer1: {
    marginTop: "30px",
    display: "flex",
    alignItems: "flex-start",
    "& .teamLayout": {
      color: "#ffffff",
      backgroundColor: "#0077b6",
      borderRadius: "0",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "0px",
    },
    "& .teamWinner1": {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "89%",
      },
    },
    "& .teamWinner2": {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "89%",
      },
    },
    "& .teamWinner3": {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "89%",
      },
    },

    "& .medalIcons": {
      height: 60,
      width: 60,
      objectFit: "contain",
      [theme.breakpoints.down("sm")]: {
        height: 35,
        width: 35,
      },
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
      marginRight: "3%",
    },
  },
  dropdownStyling: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      marginBottom: "20px",
    },
  },
  teamListContainer: {
    marginTop: "20px",
    "& .teamList": {
      width: "95%",
      marginLeft: "5%",
      borderRadius: "0",
      backgroundColor: "#0077b6",
      color: "#ffffff",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10%",
        width: "89%",
      },
    },
    "& .accordion": {
      cursor: "none",
      "& .MuiIconButton-root": {
        visibility: "hidden",
      },
      // width: '100%'
    },
  },
  teamListContainer1: {
    // marginTop: "30px",
    "& .teamList": {
      width: "94%",
      marginLeft: "5.2%",
      borderRadius: "0",
      backgroundColor: "#0077b6",
      color: "#ffffff",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "12.2%",
        width: "85%",
      },
    },
    "& .accordion": {
      cursor: "none",
      "& .MuiIconButton-root": {
        visibility: "hidden",
      },
      // width: '100%'
    },
  },
  search__select: {
    display: "flex",
    alignItems: "flex-end",
  },
  winnerIconsContainer: {
    display: "flex",
    alignItems: "center",
  },
});

function NewTeamBoard(props) {
  const [expanded, setExpanded] = useState(false);
  const [oneTimeData, setOneTimeData] = useState();
  const [challengeParameter, setChallengeParameter] = useState("");
  const [intervalCount, setIntervalCount] = useState(0);
  const [teamTickerText, setTeamTickerText] = useState(null);
  const [teamWinnersData, setTeamWinnersData] = useState(null);
  const [teamData, setTeamData] = useState(null);
  const [teamListData, setTeamListData] = useState(null);
  const [runnerWinnersData, setRunnerWinnersData] = useState(null);
  const [runnersData, setRunnersData] = useState(null);
  const [runnerListData, setRunnerListData] = useState(null);
  const [value, setSearchValue] = useState("");
  const [totalRunners, setTotalRunner] = useState(null);
  const [totalTeams, setTotalTeam] = useState(null);
  const [teamNames, setTeamNames] = useState(null);
  const [runnerGroupNames, setRunnerGroupNames] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  const [dataLoading, setDataLoading]=useState(false) 

  const [maleFemaleData, setMaleFemaleData] = useState();

  const { classes, radioValue, challengeType, title, ageWiseData } = props;

  useEffect(() => {
    if (intervalCount > 0) {
      const boardInterval = setInterval(() => {
        setIntervalCount(1);
        initialDataLoad();
      }, 2000000);
      return () => clearInterval(boardInterval);
    } else {
      initialDataLoad();
      setIntervalCount(1);
    }
  }, [intervalCount]);

  useEffect(() => {
    setExpanded(null);
  }, [props.selectedCustomDate , props.radioValue]);

  const initialDataLoad = () => {
    let runnerEventId = ApplicationUtil.getRunnersEventID();
    let organizerEventId = ApplicationUtil.getSupportsEventID();
    let eventId = window.location.href.includes("/organizer")
      ? organizerEventId
      : runnerEventId;


    let requestParams = {
      eventId: eventId,
    };

    let dataParam = {
      activity: "STEPS",
      activityPriority: "PRIMARY",
    };

    getIndividualLeaderboard(dataParam, requestParams?.eventId).then((res) => {
     
      let { particiapants } = res.data;

      setTeamTickerText(res.data?.teamTickerText)
      

      setMaleFemaleData(particiapants);
      // setTeamListData(data);
    });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    if (!isExpanded) {
      setExpanded(false);
      return;
    }

    setTeamListData([]);
    let runnerEventId = ApplicationUtil.getRunnersEventID();
    let organizerEventId = ApplicationUtil.getSupportsEventID();
    let eventId = window.location.href.includes("/organizer")
      ? organizerEventId
      : runnerEventId;

    let requestParams;

    requestParams = {
      eventId: eventId,
      stepSize: panel,
    };

    if (props?.CohortLocationWiseData?.length) {
      let dataParam = {};
      if (props?.selectedCustomDate?.value) {
        dataParam.fromDate = `${props?.selectedCustomDate?.fromDate} 00:00:00`;
        dataParam.toDate = `${props?.selectedCustomDate?.toDate} 23:59:59`;
        dataParam.Location = panel === "all" ? null : panel;
      }
      setDataLoading(true);
      getCohortLocationWiseData(dataParam, requestParams?.eventId)
        .then((res) => {
          setTeamListData(res.data);
          setDataLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching cohort location wise data:", error);
        });
    } else if (panel === "Happy Male") {
      setTeamListData([maleFemaleData?.[0]?.maleActivities?.[0]]);
    } else if (panel === "Happy Female") {
      setTeamListData([maleFemaleData?.[0]?.femaleActivities?.[0]]);
    } else {
      if (radioValue === "Cohort") {
        setDataLoading(true);
        getCohortParticipantList(requestParams).then((res) => {
          if (res.data) {
            let data = res.data;
            setTeamListData(data);
            setDataLoading(false);
          }
        });
      } else {
        setDataLoading(true);
        getOneTimeParticipantList(requestParams).then((res) => {
          if (res.data) {
            let data = res.data;
            setTeamListData(data);
            setDataLoading(false);
          }
        });
      }
    }

    setExpanded(panel ? (isExpanded ? panel : false) : false);
  };

  const handleChangeForLocationAndAgeWise = (panel) => (event, isExpanded) => {
    if (!isExpanded) {
      setExpanded(false);
      return;
    }

    setTeamListData([]);
    let runnerEventId = ApplicationUtil.getRunnersEventID();
    let organizerEventId = ApplicationUtil.getSupportsEventID();
    let eventId = window.location.href.includes("/organizer")
      ? organizerEventId
      : runnerEventId;

    let dataParam = {};
    if (props?.selectedCustomDate?.value) {
      dataParam.fromDate = `${props?.selectedCustomDate?.fromDate} 00:00:00`;
      dataParam.toDate = `${props?.selectedCustomDate?.toDate} 23:59:59`;
      dataParam.category = panel 
    }

    if(panel?.Location){
      setDataLoading(true);
      getRadioLocationWiseData(dataParam , eventId).then((res)=>{
        setTeamListData(res.data)
        setDataLoading(false);
      }).catch((error)=>{
        console.log(error)
      })
    }else{
      setDataLoading(true);
      getAgeGroupData(dataParam , eventId).then((res)=>{
     
        setTeamListData(res.data)
        setDataLoading(false);
      }).catch((error)=>{
        console.log(error)
      })
    }

    

    setExpanded(panel);
  };

  const handleChangeCohortForSelectTeamGroup =  (panel) => (event, isExpanded) => {
    if (!isExpanded) {
      setExpanded(false);
      return;
    }

    setTeamListData([]);
    let runnerEventId = ApplicationUtil.getRunnersEventID();
    let organizerEventId = ApplicationUtil.getSupportsEventID();
    let eventId = window.location.href.includes("/organizer")
      ? organizerEventId
      : runnerEventId;

    let dataParam = {};
    if (props?.selectedCustomDate?.value) {
      dataParam.fromDate = `${props?.selectedCustomDate?.fromDate} 00:00:00`;
      dataParam.toDate = `${props?.selectedCustomDate?.toDate} 23:59:59`;
      dataParam.category = panel 
    }

    // http://localhost:81/api/v1/cohort/member/leaderboard/2473?startDate=2024-11-07%2000:00:00&endDate=2024-11-13%2023:59:59&teamId=137

    

      setDataLoading(true);
      getCohortTeamWiseData(dataParam , eventId).then((res)=>{
        setTeamListData(res.data)
        setDataLoading(false);
      }).catch((error)=>{
        console.log(error)
      })
    

    

    setExpanded(panel);
  }

  const getTitle = () => {
    if (
      ApplicationConstants.isProd &&
      ApplicationConstants?.challengeLabel_Location_Prod?.includes(
        challengeType?.id
      ) &&
      radioValue !== "AgeWise"
    ) {
      return "Cohort Wise Leaderboard";
    }
    if (
      challengeType?.id === 507 ||
      challengeType?.id === 508 ||
      challengeType?.id === 515
    ) {
      return "Corporate Groups";
    }

    if (challengeType?.id === 2473 && radioValue !== "AgeWise") {
      return "Cohort Wise Leaderboard";
    }

    if (radioValue === "AgeWise") {
      return "Age Wise Leaderboard";
    }
    if (radioValue === "location") {
      return "Location Based Leaderboard";
    }
    if (radioValue === "Cohort") {
      return "Cohort Leaderboard";
    }
    if (radioValue === "individual") {
      return "Individual Leaderboard";
    }
    return "Team Leaderboard";
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue?.length;

    if (radioValue == "runnerGroup") {
      return inputLength === 0
        ? []
        : runnerGroupNames.filter((total) =>
            // total?.runnerGroupName.toLowerCase().slice(0, inputLength) === inputValue
            total?.runnerGroupName.toLowerCase().includes(inputValue)
          );
    }
    if (radioValue == "team") {
      return inputLength === 0
        ? []
        : teamNames?.filter((total) =>
            // total?.teamName.toLowerCase().slice(0, inputLength) === inputValue
            total?.teamName.toLowerCase().includes(inputValue)
          );
    }
  };
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  useEffect(() => {
    if (radioValue === "Cohort") {
      setOneTimeData([
        { categoryName: "50 Lakh", id: 1, steps: 5000000 },
        { categoryName: "100 Lakh", id: 2, steps: 10000000 },
      ]);
    } 
     if (radioValue === "individual") {
      setOneTimeData([
        { categoryName: "1 Lakh", id: 1, steps: 100000 },
        { categoryName: "5 Lakh", id: 2, steps: 500000 },
        { categoryName: "10 Lakh", id: 3, steps: 1000000 },
        { categoryName: "Mr. Happy Feet", id: 3, steps: "Happy Male" },
        { categoryName: "Ms. Happy Feet", id: 3, steps: "Happy Female" },
      ]);
    }
  }, [radioValue]);

  let { data } = props;

  return (
    <div className={classes.leaderboard_top}>
      {/* This is title for all radio value excuted by __getTitle__ */}
      {teamTickerText && (
        <Ticker>
          {({ index }) => (
            <p
              style={{
                fontSize: "25px",
                fontWeight: 600,
                marginLeft: "75px",
                whiteSpace: "nowrap",
                color: "#780000",
              }}
            >
              {teamTickerText + "."}
            </p>
          )}
        </Ticker>
      )}

      <div className={classes.container_top}>
        <EmojiEventsTwoToneIcon style={{ color: "#F79824" }} />

        <span style={{ color: "rgb(40, 131, 181)", fontSize: "20px" }}>
          {getTitle()}
        </span>
      </div>

      
      <div className={classes.root}>
        <div className={classes.teamListContainer}>
          {radioValue === "location" || radioValue === "AgeWise"
            ? data &&
              data?.map((category, i) => (
                <div className={classes.winnerTeamsContainer}>
                  <Accordion
                    key={category.id}
                    expanded={expanded?.id === category?.id}
                    onChange={handleChangeForLocationAndAgeWise(category)}
                    className={`teamList  ${classes.teamLayout}`}
                    style={{ marginLeft: "5%" }}
                  >
                    <AccordionSummaryContainer
                      category={category}
                      // ageIndex={i}
                      radioValue={"AgeWise"}
                      // className={`accordion ${classes.teamLayout}`}
                    />
                    <AccordionDetails className={classes.accordionDetails}>
                      {teamListData?.length > 0 ? (
                        <BoardList
                          oneTimeTeamListData={teamListData}
                          teamLeaderId={category?.id}
                        />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "Center",
                            color: "#cbd5e1",
                          }}
                        >
                          {dataLoading ? "Loading..." : "Records Not Found."}
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))
            : props.CohortLocationWiseData?.length
            ? props?.CohortLocationWiseData?.map((category, i) => (
                <div className={classes.winnerTeamsContainer}>
                  <Accordion
                    key={category.id}
                    expanded={expanded === category?.payload}
                    onChange={handleChange(category?.payload)}
                    className={`teamList  ${classes.teamLayout}`}
                    style={{ marginLeft: "5%" }}
                  >
                    <AccordionSummaryContainer
                      category={category}
                      // ageIndex={i}
                      radioValue={"AgeWise"}
                      // className={`accordion ${classes.teamLayout}`}
                    />
                    <AccordionDetails className={classes.accordionDetails}>
                      {teamListData?.length > 0 ? (
                        <BoardList
                          oneTimeTeamListData={teamListData}
                          teamLeaderId={category?.id}
                        />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "Center",
                            color: "#cbd5e1",
                          }}
                        >
                            {dataLoading ? "Loading..." : "Records Not Found."}
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))
            : props.cohortData
            ? props.cohortData?.slice(0, 2)?.map((category, i) => (
                <div className={classes.winnerTeamsContainer}>
                  <div
                    className={classes.winnerIconsContainer}
                    style={{ visibility: i + 1 > 3 && "hidden" }}
                  >
                    <img
                      src={
                        i + 1 === 1
                          ? GoldMedal
                          : i + 1 === 2
                          ? SilverMedal
                          : i + 1 === 3
                          ? BronzeMedal
                          : null
                      }
                      alt="gold-medal"
                      className="medalIcons"
                    />
                  </div>
                 
                  <Accordion
                    key={category.id}
                    expanded={expanded?.teamId === category?.teamId}
                    onChange={handleChangeCohortForSelectTeamGroup(category)}
                    className={`teamList  ${classes.teamLayout}`}
                    style={{ marginLeft: "0" }}
                  >
                    <AccordionSummaryContainer
                      category={category}
                      ageIndex={i}
                      radioValue={"AgeWise"}
                      // className={`accordion ${classes.teamLayout}`}
                    />
                    <AccordionDetails className={classes.accordionDetails}>
                      {teamListData?.length > 0 ? (
                        <BoardList
                          oneTimeTeamListData={teamListData}
                          teamLeaderId={category?.id}
                        />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "Center",
                            color: "#cbd5e1",
                          }}
                        >
                            {dataLoading ? "Loading..." : "Records Not Found."}
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))
            : oneTimeData?.map((category, i) => (
                <div className={classes.winnerTeamsContainer}>
                  <Accordion
                    key={category.id}
                    expanded={expanded === category?.steps}
                    onChange={handleChange(category?.steps)}
                    className={`teamList  ${classes.teamLayout}`}
                    style={{ marginLeft: "5%" }}
                  >
                    <AccordionSummaryContainer
                      category={category}
                      // ageIndex={i}
                      radioValue={"AgeWise"}
                      // className={`accordion ${classes.teamLayout}`}
                    />
                    <AccordionDetails className={classes.accordionDetails}>
                      {teamListData?.length > 0 ? (
                        <BoardList
                          oneTimeTeamListData={teamListData}
                          teamLeaderId={category?.id}
                        />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "Center",
                            color: "#cbd5e1",
                          }}
                        >
                            {dataLoading ? "Loading..." : "Records Not Found."}
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
        </div>

      
      </div>
    </div>
  );
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(NewTeamBoard);

const AccordionSummaryData = ({ value, align }, props) => {
  const { classes } = props;
  return (
    <Grid
      item
      style={{
        textAlign: align ? align : "left",
        fontSize: window.innerWidth >= 600 ? "18px" : "16px",
      }}
    >
      <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
        {value}{" "}
      </span>
    </Grid>
  );
};

const AccordionSummaryContainer = (
  {
    team,
    challengeParameter,
    radioValue,
    runner,
    challengeType,
    category,
    ageIndex,
  },
  props
) => {
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
    >
      <Grid container>
        <Grid item xs={(!category?.totalTeamSteps  && !category?.teamRank ) ? 9 : 6}>
          <AccordionSummaryData
            value={
              category?.categoryName || category?.teamName || category?.label
            }
          />
        </Grid>

        {category?.totalTeamSteps ? (
          <Grid item xs={3} style={{ textAlign: "center" }}>
            {ApplicationUtil.formatNumberWithCommas(category?.totalTeamSteps)}
          </Grid>
        ) : (
          <Grid item xs={3} style={{ textAlign: "center" }}>
            {ageIndex >= 0 ? ageIndex + 1 : null}
          </Grid>
        )}

        <Grid item xs={3} style={{ textAlign: "center" }}>
          {category?.teamRank}
        </Grid>
      </Grid>
    </AccordionSummary>
  );
};
