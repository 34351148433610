import { withStyles } from "@material-ui/styles";
import { HorizontalBar } from "@reactchartjs/react-chart.js";
import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Properties from "../../../../properties/Properties";

function KnowledgeSessionComponent({
  classes,
  knowledgeSessionData,
  knowledgeSessions,
}) {
  const HorizontalBarOptions = {
    maintainAspectRatio: false,
    borderRadius: 100,
    legend: {
      display: false,
      position: "bottom",
      align: "end",
      fillStyle: "black",

      labels: {
        fontSize: document.documentElement.clientWidth > 1200 ? 20 : 13,
        fontWeight: "700",
        fontColor: "black",
      },
      onClick: function (event, legendItem) {},
    },

    gridLines: {
      color: "rgba(0, 0, 0, 0)",
    },

    scales: {
      yAxes: [
        {
          ticks: {
            fontSize: document.documentElement.clientWidth > 1200 ? 20 : 13,
            fontWeight: "700",
            fontColor: "black",
          },
          gridLines: {
            display: false,
          },
        },
      ],

      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
          },
          display: false,
          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {},
  };

  return (
    <div className={classes.knowledgeSessionContainer}>
      <div className={classes.knowledgeSessionTitleContainer}>
        <h2 className={"knowledgeSessionTitle"}>
          {Properties.program_dashboard.chart_titles.knowledge_sessions}:
        </h2>
      </div>
      <div className={classes.horizontalBarContainer}>
        <div style={{ width: "90%" }}>
          <HorizontalBar
            height={"100%"}
            options={HorizontalBarOptions}
            data={knowledgeSessionData}
          />
        </div>
        <div
          className={classes.sessionIndicatorContainer}
          style={{
            gap:
              knowledgeSessions.length == 4
                ? "10px"
                : knowledgeSessions.length == 3
                ? "20px"
                : "32px",
          }}
        >
          {knowledgeSessions?.map((sessionType) => {
            return (
              <p className={"sessionText"}>
                {sessionType?.totalAttendedProgramSession}/
                {sessionType?.totalProgramSession}
              </p>
            );
          })}
        </div>
      </div>
      <div className="legends">
        <div className="attended_session">
          <span className="legend_block"></span>
          <span className="text">
            {Properties.program_dashboard.legends.sessions.attended}
          </span>
        </div>
        <div className="total_session">
          <span className="legend_block"></span>
          <span className="text">
            {Properties.program_dashboard.legends.sessions.total}
          </span>
        </div>
      </div>
    </div>
  );
}

const styles = (theme) => ({
  horizontalBarContainer: {
    height: "30vh",
    width: "auto",
    display: "flex",
    gap: "20px",
    width: "100%",
    justifyContent: "center",
    // position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "20vh",
      width: "auto",
    },
  },
  knowledgeSessionTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    "& .knowledgeSessionTitle": {
      marginBottom: 0,
      color: "#202260",
      fontWeight: "bold",
      fontSize: "35px",
      marginLeft: "10px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
        marginLeft: "0px",
      },
    },
    "& .legendBlock": {
      width: "40px",
      height: "18px",
      backgroundColor: "#FE7235",
      [theme.breakpoints.down("sm")]: {
        width: "30px",
        height: "12px",
      },
    },
    "& .legendBlockTitle": {
      fontSize: "20px",
      fontWeight: "500",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        fontWeight: "500",
      },
    },
    "& .attendanceContainer": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      marginRight: "120px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "0px",
      },
    },
  },
  sessionIndicatorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    // gap: "32px",
    "& .sessionText": {
      fontSize: "20px",
      fontWeight: "bold",
      padding: 0,
      margin: 0,
      [theme.breakpoints.down(1400)]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
      },
    },
  },
  stepsChallengeProgressContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "baseline",
    gap: "80px",
    [theme.breakpoints.down("sm")]: {
      gap: "20px",
      flexDirection: "column",
    },
    "& .progressBarContainer": {
      width: "60%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  totalDetailsProg: {
    color: "black",
    fontWeight: "bold",
    fontSize: "28px",
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",
      fontSize: "20px",
      height: "20%",
      justifyContent: "flex-start",
      margin: "0",
    },
    "& .stepsTitle": {
      color: "black",
      fontWeight: "bolder",
      fontSize: "26px",
      margin: "0",
      // marginLeft: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
  },
  breadcrumbs_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    "& .breadcrumbs": {
      [theme.breakpoints.down("sm")]: {
        alignSelf: "start",
      },
    },
    "& .dashboard_logo": {
      [theme.breakpoints.down("sm")]: {
        alignSelf: "end",
      },
    },
  },
  knowledgeSessionContainer: {
    "& .legends": {
      display: "flex",
      gap: "20px",
      marginRight: "6%",
      justifyContent: "end",
      alignItems: "center",
      fontSize: "22px",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        gap: "10px",
        fontSize: "14px",
        marginRight: "0",
      },
      "& .attended_session": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        "& .legend_block": {
          height: "18px",
          width: "45px",
          backgroundColor: "#FE7235",
          [theme.breakpoints.down("sm")]: {
            height: "11px",
            width: "37px",
          },
        },
      },
      "& .total_session": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        "& .legend_block": {
          height: "10px",
          width: "45px",
          backgroundColor: "#9396E3",
          [theme.breakpoints.down("sm")]: {
            height: "5px",
            width: "37px",
          },
        },
      },
    },
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(KnowledgeSessionComponent);
