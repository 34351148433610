/*global google*/

import React, { Component } from "react";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import GoogleMap from "../../../../../common/GoogleMap/GoogleMap";
import MenuIcon from "@material-ui/icons/Menu";
import StarIcon from "../../../../../../images/star.gif";
import StartIcon from "../../../../../../images/start.jpg";
import markerIcon from "../../../../../../images/marker.gif";
import markerImgIcon from "../../../../../../images/marker.jpg";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";

const styles = (theme) => ({});
class EventMap extends Component {
  constructor(props) {
    super(props);
  }
  state = {

  };
  componentDidMount() {
    this.setState({
      flightPlanCoordinates: this.props.flightPlanCoordinates,
      categoryDTOs: this.props.categoryDTOs,
      features: this.props.features,
      defaultCenter: this.props.defaultCenter,
      zoomLevel: this.props.zoomLevel,
      eventType:this.props.eventType

    });
  }
  componentDidUpdate(previousProps) {
    if (
      this.props.flightPlanCoordinates !==
        previousProps.flightPlanCoordinates ||
      this.props.categoryDTOs !== previousProps.categoryDTOs ||
      this.props.features !== previousProps.features ||
      this.props.defaultCenter !== previousProps.defaultCenter ||
      this.props.zoomLevel !== previousProps.zoomLevel ||
      this.props.eventType != previousProps.eventType

    ) {
      this.setState({
        flightPlanCoordinates: this.props.flightPlanCoordinates,
        categoryDTOs: this.props.categoryDTOs,
        features: this.props.features,
        defaultCenter: this.props.defaultCenter,
        zoomLevel: this.props.zoomLevel,
        eventType: this.props.eventType

      });
    }
  }
  apiIsLoaded = (map, maps) => {
    const { flightPlanCoordinates, features, categoryDTOs, eventType } = this.state;

    const flightPath = new google.maps.Polyline({
      // path: flightPlanCoordinates,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    let marker = {};
    if (
      flightPlanCoordinates &&
      flightPlanCoordinates.length > 0 &&
      flightPlanCoordinates[0].lat
    ) {
      const firstPoint = {
        lat: flightPlanCoordinates[0].lat,
        lng: flightPlanCoordinates[0].lng,
      };

      marker = new google.maps.Marker({
        position: firstPoint,
        icon: StartIcon,
        map: map,
        title: "start",
        size: 1,
      });
    }
    let color = ApplicationUtil.dynamicColors();

    let allPoints = features;
    var infoWindow = new google.maps.InfoWindow();
    for (var i = 0; i < allPoints?.length; i++) {
      

      let points = allPoints[i].currentPosition
        ? JSON.parse(allPoints[i].currentPosition)
        : null;

      var latLng = {};
      if (points && points.lat) {
        latLng = {
          lat: points.lat,
          lng: points.lng,
        };
      }
      var data = null;

      let feat = allPoints[i];

      if (feat) {
       
          data = {
            totalDistance:
             
              feat.runnerActivityDTO?.totalActivityDistance
                ? feat.runnerActivityDTO.totalActivityDistance
                : 0, 
            
            participants:feat.runner.name, //feat.activityList[0]?.name,
          };
        
        // if (categoryDTOs) {
        //   let index = categoryDTOs.findIndex(
        //     (e) => e.id === feat.categoryDTO.id
        //   );
        //   color = categoryDTOs[index].mapIconColor;
        // }
      }

      if (data.totalDistance === undefined) {
        data.totalDistance = 0;
      }
      marker = new google.maps.Marker({
        position: latLng,
        icon: ApplicationUtil.pinSymbol("red"),
        // color
        //   ? ApplicationUtil.pinSymbol(color)
        //   : ApplicationUtil.pinSymbol("red"),

        anchor: new google.maps.Point(14, 25),
        map: map,
        size: 1,
      });

      (function (marker, data) {
        google.maps.event.addListener(marker, "click", function (e) {
          infoWindow.setContent(
            "<div style='max-height:160px'>" +
              "<div style = 'margin-bottom:4px;  font-size: 16px; font-weight: 500; color: black; '>" +
              `<div style = 'font-size: 16px; font-weight: 500; color: black;text-transform:capitalize'> ${
                data?.participants && `<div>${data?.participants}</div>`
              }</div>` +
              `${ 
                  data?.totalDistance == 0
                ? "<div></div>"
                : "<div><div style=' font-size: 14px;font-weight: 500;color: #727272;'>Distance Covered</div>" +
                  "<div style = 'font-size: 16px; font-weight: 500; color: black;text-transform:capitalize'>" +
                  parseFloat(Number(data?.totalDistance) / 1000).toFixed(2) +
                  " Km" +
                  "</div>" +
                  "<div>" +
                  "</div>"


            }`
          );
          infoWindow.open(map, marker);
        });
      })(marker, data);
    }

    flightPath.setMap(map);
  };

  render() {
    const { flightPlanCoordinates, features, zoomLevel, defaultCenter } =
      this.state;

    return (
      <Grid container>
        <Grid
          item
          style={{
            width: "100%",
            position: "relative",
            margin: "0px 4px",
          }}
        >
          <GoogleMap
            defaultCenter={defaultCenter}
            defaultZoom={zoomLevel}
            apiIsLoaded={this.apiIsLoaded}
            markers={features}
          />
        </Grid>
      </Grid>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(EventMap);
