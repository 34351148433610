import React from "react";
import Radio from "@material-ui/core/Radio";
import { AccordionDetails } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  updateParticipantSetFilters,
  updateActivitySetFilters,
} from "../../redux/supportUser";
import { useSelector, useDispatch } from "react-redux";

import { CheckBox, Label } from "@material-ui/icons";
import { useEffect } from "react";
import { useState } from "react";
const styles = () => ({
  accordionStyling: {
    margin: "0",
    padding: "0",
    width: "100%",
  },
  filterStyling: {
    margin: "0 0 0 5px",
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
const AccordionFilter = ({
  classes,
  data,
  id,
  label,
  val,
  onChange,
  selectedValue,
}) => {
  const count = useSelector(
    (state) => state.supportUser.filters.participantListFilters
  );
  const count1 = useSelector((state) => state.supportUser.filters.activityList);
  console.log("count", count?.consentPresent);
  return (
    <>
      <AccordionDetails className={classes.accordionStyling} key={id}>
        <div style={{ width: "100%" }}>
          <span className={classes.filterStyling}>
            {label}
            <span>
              {data.btnType === "RADIO" ? (
                <Radio
                  checked={
                    selectedValue === null && data.label === "Activity recieved"
                      ? label === data?.items[1]?.label
                      : selectedValue === null
                      ? label === data?.items[0]?.label
                      : selectedValue === val
                  }
                  onChange={(e) => {
                    onChange(e.target.value, data.id, id);
                  }}
                  value={val}
                />
              ) : (
                <CheckBox />
              )}
              {/* selectedValue===null && data.label==="Activity recieved" ? label === data?.items[1]?.label: selectedValue===null ?label === data?.items[0]?.label  : selectedValue === val         */}
              {/* selectedValue===null?label === data?.items[0]?.label :selectedValue === val  */}
            </span>
          </span>
        </div>
      </AccordionDetails>
    </>
  );
};

function mapStateToProps(state) {
  return {
  participantFilters:state.supportUser.filters.participantListFilters,
  activityList1: state.supportUser.filters.activityList,
};
}
// export default  ,withRouter,
export default compose(withStyles(styles, { withTheme: true }) , withRouter,
connect(mapStateToProps, {
  updateParticipantSetFilters,updateActivitySetFilters
}  ) )(AccordionFilter);

