import React, { Component } from "react";
import { useHistory, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";

import { NoLeaderboard } from "../../../../common/SvgIcon";
import { TabPanel } from "@material-ui/lab";
import { Tab, Tabs } from "@material-ui/core";
import Properties from "../../../../../properties/Properties";
import { isMobile } from "react-device-detect";
import { CompanyWide } from "../../../../../images/Analytics";
const styles = (theme) => ({
  textTransformCss: {
    textTransform: "none",
    fontSize: "22px",
    fontWeight: "bold",
  },

  noLeaderboard: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "100px",
  },
});

class CompanyAnalytics extends Component {
  constructor(props) {
    super(props);

    this.state = { ActiveTabIndex: 0 };
  }

  componentDidMount() {
    const { history } = this.props;
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      ActiveTabIndex: newValue,
    });
  };
  render() {
    const { classes } = this.props;
    const { ActiveTabIndex } = this.state;

    return (
      <div
        style={{
          height: "500px",
          width: "600px",
          margin: "0 auto",
          paddingTop: "4vh",
        }}
      >
        <img
          src={CompanyWide}
          alt="Company-wide-img"
          style={{ width: "100%" }}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(CompanyAnalytics);
