import React, { Component } from "react";
import { Badge, IconButton, Paper, Tooltip } from "@material-ui/core";

import GetAppIcon from "@material-ui/icons/GetApp";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import ImageSlider from "../../../common/ImageSlider";
import Properties from "../../../../properties/Properties";

import ApplicationUtil from "./../../../../utils/ApplicationUtil";
import ImageDialogBox from "../../../common/ImageDialogBox";
import CancelIcon from "@material-ui/icons/Cancel";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import WarningIcon from "@material-ui/icons/Warning";
import uploadPhoto, {
  deleteActivity,
  getActivityDetails,
} from "../../../../apis/eventService";

import clsx from "clsx";
import { Grid, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { downloadImage } from "./../../../../apis/eventService";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import { Forum } from "@material-ui/icons";
import { isMobile } from "react-device-detect";
import Clamp from "react-multiline-clamp";
import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";
import MessageIcon from "@material-ui/icons/Message";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import { VideoPlayerIcon } from "../../../common/SvgIcon";
import DialogBox from "../../../common/DialogBox";

const styles = (theme) => ({
  expand: {
    cursor: "pointer",
    // textAlign: "center",
    // marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  tableList: {
    marginTop: "10px",
    // overflowX: "scroll",
  },

  tableRowCss: {
    textAlign: "left",
    border: "1px lightgray solid",
    padding: "2px 4px",
    // borderTop: "none",
    marginBottom: "6px",
  },
  firstRowCss: {
    textAlign: "left",
    border: "1px lightgray solid",
    padding: "2px 4px",
    marginBottom: "6px",
    // display: "flex",
    // borderTop: "none",
  },
  titleCss: {
    color: "rgb(114, 114, 114)",
    // color: "#727272",
    fontSize: "14px",
    fontWeight: 600,
  },
  infoCss: {
    color: "rgb(40, 131, 181)",
    fontSize: "20px",
    padding: "0px 0px 0px 5px",
  },
  nameTitle: {
    color: "rgb(40, 131, 181)",
    fontSize: "20px",
    margin: "5px 0px 5px 0px",
    "&:hover": {
      borderBottom: "1px solid rgb(40, 131, 181)",
    },
  },
  readMoreCss: {
    fontSize: "12px",
  },
  dateCss: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  bottomMargin: {
    display: "flex",
    alignItems: "center",
  },
  trainingCss: {
    fontSize: "18px",
    fontWeight: "600",
    color: "darkorange",
  },
  trainingDetails: {
    // backgroundColor: "#ff99007a",
    border: "2px solid #ff99007a",
    borderRadius: "8px",
    padding: "4px 12px",
    marginBottom: "4px",
  },
  trainingDetailsPast: {
    // backgroundColor: "#d3d3d34f",
    border: "2px solid #d3d3d34f",
    borderRadius: "8px",
    padding: "4px 12px",
  },
  trainingDetailsTitle: {
    fontWeight: 600,
    paddingBottom: "4px",
    marginTop: "6px",
  },
  trainingListCss: {
    margin: 0,
    paddingInlineStart: "20px",
    listStyleType: "square",
  },
  card: {
    // width: 400px;
    height: "auto",
    padding: "2rem",
    borderRadius: "1rem",
    background: "rgba(255, 255, 255, .7)",
    "-webkit-backdrop-filter": "blur(10px)",
    backdropFilter: "blur(10px)",
  },
  trainingUploadCss: {
    textAlign: "center",
    // marginBottom:"10px"
  },
  detailsRowCss: {
    marginBottom: "8px",
    // display: "flex",
    // justifyContent: "flex-end",
  },
  layout: {
    display: "flex",
    alignItems: "flex-start",
    padding: "10px",

    backgroundColor: "#e9ecef",
    borderRadius: "5px",
    margin: "3px",
    // marginRight: "3px",
  },
  container: {
    marginTop: "20px",
  },
  content: {
    marginRight: "10px",
    marginLeft: "10px",
    width: "100%",
  },
  message: {
    paddingTop: "10px",
    width: "100%",
    "& .button": {
      color: "blue",
    },
  },
  details: {
    display: "flex",
    justifyContent: "space-between",

    color: "gray",
    "& .name": {
      // fontSize: "20px",
      fontWeight: 600,
      color: "#2883b5",
    },
    "& .time": {
      fontSize: "14px",
    },
  },
  icon: {
    color: "#2883b5",
  },
  playerIcon: {
    padding: "0px 2px",
    "&:hover": {
      transform: "scale(1.3)", //padding:"0px 4px"
    },
  },
});

class ActivityView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      showImage: false,
      imagePath: "",
      expanded: false,
      uploadedFiles: [],
      showDeleteActivityDialog: false,
      dialogHeader: "",
    };
  }

  closeWindow = () => {
    this.setState({ showDialog: false, showImage: false });
  };
  fullImageView = (src) => {
    this.setState({
      imageDialog: true,
      image_src: "data:image/png;base64," + src,
    });
  };

  viewImage = () => {
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runner?.id;
    let eventId = ApplicationUtil.getRunnersEventID();
    downloadImage(runnerId, eventId).then((res) => {
      this.setState({
        open: true,
        screenshots: res,
      });
    });
  };
  getDialogComponent = () => {
    return (
      <div>
        <ImageSlider files={this.state.screenshots} />
      </div>
    );
  };

  closeWindow = () => {
    this.setState({ open: false });
  };
  multipleUpload = (e, rec, id) => {
    if (e.target.id === "contained-button-file") {
      let files = e.target.files;
      this.setState(
        {
          uploadedFiles: Array.from(files).map((file) => {
            let key = "uploadedId_" + parseInt(Math.random() * 1000000);
            file.key = key;
            file.id = key;
            file.src = URL.createObjectURL(file);
            return file;
          }),
        },
        () => {
          let customParams = {};
          customParams.id = id;
          customParams.file = this.state.uploadedFiles[0];
          uploadPhoto(customParams).then((res) => {
            this.setState({ imageId: "" });
            this.props.pageRefresh();
          });
        }
      );
    }
    e.target.value = null;
  };
  renderRow = (key, value, subActivityType) => {
    const { classes } = this.props;

    let row = null;
    const mapColumn = {
      distance: Properties.Distance_label,
      calories: Properties.CaloriesLabel,
      averagePace: Properties.AveragePace_label,
      suggestedPace: Properties.SuggestedPace_Label,
      averageSpeed: Properties.AverageSpeed_label,
      steps: Properties.StepsLabel,
      completionTime: Properties.Time_label,
      cooperTestDistance: "Cooper Distance",
      cooperTestRemark: "Cooper Remark",
    };
    if (key === "distance" || key === "cooperTestDistance") {
      row = (
        <Grid item xs={isMobile ? 12 : 4} className={classes.bottomMargin}>
          <span className={classes.titleCss}>
            {key === "cooperTestDistance"
              ? "Cooper Distance"
              : Properties.Distance_label}
          </span>
          <span className={classes.infoCss}>
            {parseFloat(value / 1000).toFixed(2)}
            {" Km"}
          </span>
        </Grid>
      );
    } else if (key === "averageSpeed") {
      row = (
        <Grid item xs={isMobile ? 12 : 4} className={classes.bottomMargin}>
          <span className={classes.titleCss}>
            {Properties.AverageSpeed_label}
          </span>
          <span className={classes.infoCss}>
            {value}
            {" Km/Hr"}
          </span>
        </Grid>
      );
    } else {
      row = (
        <Grid item xs={isMobile ? 12 : 4} className={classes.bottomMargin}>
          <span className={classes.titleCss}>{mapColumn[key]}</span>
          <span className={classes.infoCss}>{value}</span>
        </Grid>
      );
    }
    return row;
  };
  getShortDetails = (row) => {
    const { displayColumns, classes } = this.props;

    let newDisplayColumns = [];
    if (row.displayColumns) {
      newDisplayColumns = row.displayColumns.split(",");
    } else {
      newDisplayColumns = ["distance", "completionTime"];
    }

    if (row?.cooperTestDistance) newDisplayColumns.push("cooperTestDistance");
    if (row?.cooperTestRemark) newDisplayColumns.push("cooperTestRemark");
    let newRow = ApplicationUtil.objectReorder(row, newDisplayColumns);
    return (
      <Grid container>
        {row.type === "STRENGTH_TRAINING" ||
        row.type === "Strength Training" ||
        row.type === "Cross Training" ? (
          <>
            <Grid item xs={isMobile ? 12 : 4} className={classes.bottomMargin}>
              <span className={classes.infoCss} style={{ marginLeft: "-4px" }}>
                {/* {row.name ? row.name : row.displayName}{" "} */}
                {row.type === "Cross Training" && (
                  <span>{row.subActivityType}</span>
                )}
                {row.type === "Strength Training" && <span>{row.name}</span>}
              </span>
            </Grid>
            {newRow &&
              Object.entries(newRow).map(([key, value], index) => {
                if (index < 2) {
                  return this.renderRow(key.trim(), value);
                }
                //  return   <h1>index</h1>
              })}
            {/* <Grid item xs={isMobile ? 12 : 6} className={classes.bottomMargin}>
              <span className={classes.titleCss}>{Properties.Time_label}</span>
              <span className={classes.infoCss}>{row.completionTime}</span>
            </Grid> */}
          </>
        ) : (
          <>
            {newRow &&
              Object.entries(newRow).map(([key, value], index) => {
                if (index < 2) {
                  return this.renderRow(key.trim(), value);
                }
              })}
          </>
        )}
      </Grid>
    );
  };
  formatTimeStamp = (date) => {
    let splitDate = date.split(" ");
    let formatDate = ApplicationUtil.changeDateFormatDate(splitDate[0]);
    let today = moment().format("YYYY-MM-DD");
    let isToday = moment(formatDate).isSame(today);
    let value = "";
    let time = splitDate[1].split(":");
    if (isToday) {
      // value = moment(date, "DD-MM-YYYY hh:mm:ss")
      //   .fromNow()
      //   .replace("hours", "hrs")
      //   .replace("hour", "hr")
      //   .replace("minutes", "mins")
      //   .replace("ago", "");
      // value = value[0];
      value = `${time[0]}:${time[1]}`;
    } else {
      value = date;
    }
    return value;
  };
  getLongDetails = (row) => {
    const { displayColumns, classes } = this.props;

    let newDisplayColumns = [];
    if (row.displayColumns && row.subActivityType == "CYCLE") {
      newDisplayColumns = row.displayColumns.split(",");
    } else if (displayColumns) {
      newDisplayColumns = displayColumns.split(",");
    } else {
      newDisplayColumns = [
        "distance",
        "completionTime",
        "averagePace",
        "suggestedPace",
        "averageSpeed",
        "steps",
        "calories",
      ];
    }

    if (row?.cooperTestDistance) newDisplayColumns.push("cooperTestDistance");
    if (row?.cooperTestRemark) newDisplayColumns.push("cooperTestRemark");

    let newRow = ApplicationUtil.objectReorder(row, newDisplayColumns);

    return (
      <>
        <Grid container>
          {row.type === "Strength Training" ? (
            <Grid container>
              {newRow &&
                Object.entries(newRow).map(([key, value], index) => {
                  if (index > 1) {
                    return this.renderRow(key.trim(), value);
                  }
                })}
            </Grid>
          ) : (
            <>
              {newRow &&
                Object.entries(newRow).map(([key, value], index) => {
                  if (index > 1) {
                    return this.renderRow(key.trim(), value);
                  }
                })}
            </>
          )}
        </Grid>

        {row?.comments?.length > 0 && (
          <div>
            <span className={classes.titleCss} style={{ color: "#F79824" }}>
              {"Feedback Recieved"}
            </span>
          </div>
        )}
        {row?.comments?.length > 0 && (
          <Grid container xs={12}>
            {row?.comments?.map((ele, idx) => (
              // <Grid
              //   // className={classes.infoCss}
              //   item
              //   xs={isMobile ? 12 : 6}
              //   key={ele.id}
              //   style={{ display: "flex" }}
              // >
              //   {ele?.messageDate && (
              //     <span style={{ minWidth: "100px" }}>
              //       {/* {ApplicationUtil.changeDateFormat(ele.messageDate)} */}
              //       {this.formatTimeStamp(ele.messageDate)}
              //       {":"}
              //     </span>
              //   )}
              //   <span item style={{ fontWeight: 300 }}>
              //     {ele.message}
              //   </span>
              // </Grid>
              <Grid item xs={isMobile ? 12 : 6} key={idx}>
                <div className={classes.layout}>
                  <MessageIcon className={classes.icon} />

                  {/* content */}
                  <div className={classes.content}>
                    {/* name, date */}
                    <div className={classes.details}>
                      <span className="name">{ele.sender}</span>
                      {/* <div className={classes.duration}> */}
                      {/* <span className="date">28-03-2022</span> */}
                      <span className="time">
                        {this.formatTimeStamp(ele.messageDate)}
                      </span>
                      {/* </div> */}
                    </div>
                    {/* message */}
                    <div className={classes.message}>
                      <Clamp
                        lines={2}
                        maxLines={1000}
                        withToggle
                        showMoreElement={({ toggle }) => (
                          <span className="button" onClick={toggle}>
                            Read more
                          </span>
                        )}
                        showLessElement={({ toggle }) => (
                          <span className="button" onClick={toggle}>
                            Show Less
                          </span>
                        )}
                      >
                        {/* {message} */}
                        {ReactHtmlParser(ele.message)}
                      </Clamp>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        )}
        {/* </Grid> */}
      </>
    );
  };
  handleExpandClick = (id) => {
    const { expanded } = this.state;
    this.setState({ expanded: expanded !== id ? id : !expanded });
  };
  handleActivityUpdateClick = (id) => {
    let { runnerId } = ApplicationUtil.getRunnerDetails();
    let eventId = ApplicationUtil.getRunnersEventID();
    const { timeValidation } = this.props;
    let data = null;
    let requestParams = {
      eventId: eventId,
      runnerId: runnerId,
      activityId: id,
    };
    getActivityDetails(requestParams).then((res) => {
      localStorage.setItem("fromEventDetails", true);
      localStorage.setItem(
        "uploadDate",
        // ApplicationUtil.changeDateFormatDate(
        res?.data?.startDateLocal
        // )
      );

      this.props.history.push({
        pathname: "/uploadrun",
        timeValidation: timeValidation,
        activityDetails: res.data,
      });
    });
  };

  showTrainingUpload = (activityType) => {
    // if (
    //   activityType?.type === "RUN" ||
    //   activityType?.type === "STRENGTH_TRAINING" ||
    //   activityType?.type === "CROSS_TRAINING"
    // ) {
    //   return true;
    // } else {
    //   return false;
    // }
    if (activityType?.type === "REST") {
      return false;
    } else {
      return true;
    }
  };
  uploadRun = (d) => {
    const { timeValidation } = this.props;
    localStorage.setItem("uploadDate", d.toString());
    localStorage.setItem("displayColumns", this.props.displayColumns);
    localStorage.setItem("fromEventDetails", true);

    return this.props.history.push({
      pathname: "/uploadrun",
      timeValidation: timeValidation,
    });
  };
  uploadTrainingActivity = (startDateLocal) => {
    localStorage.setItem("fromEventDetails", true);
    localStorage.setItem("uploadDate", startDateLocal.toString());

    return this.props.history.push({
      pathname: "/uploadrun",
      // trainingActivityData: details,
    });
  };

  // getRequiredRow = (details, list, startDate) => {
  getRequiredRow = (list, startDate, showLabel) => {
    const { classes, displayColumns, history } = this.props;

    const columns = displayColumns.split(",");
    const { expanded } = this.state;

    let newlist = list;

    // .filter(function (el) {
    //   return (
    //     el?.type?.toLowerCase() === details?.plannedActivityType?.toLowerCase()
    //     || el?.type?.toLowerCase() === details?.plannedActivityType?.toLowerCase()
    //     || details?.activityType?.type.toLowerCase() === "cross_training"
    //   );
    // });

    return (
      <>
        {newlist.length > 0 && (
          <>
            {showLabel && (
              <div className={classes.trainingDetailsTitle}>
                {Properties.Label_Actual}
              </div>
            )}
            {newlist?.map((ele, i) => (
              <div
                className={i === 0 ? classes.firstRowCss : classes.tableRowCss}
              >
                <Grid
                  container
                  direction="row"
                  // className={
                  //   i === 0 ? classes.firstRowCss : classes.tableRowCss
                  // }
                >
                  <Grid
                    item
                    xs={10}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      localStorage.setItem("activityId", ele.id);
                      history.push("/activitydetailsview");
                    }}
                  >
                    <span className={classes.nameTitle}>
                      {ele?.subActivityType ? ele?.subActivityType : ele?.type}
                      {ele?.name ? " - " + ele?.name : ""}
                    </span>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={10}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      localStorage.setItem("activityId", ele.id);
                      history.push("/activitydetailsview");
                    }}
                  >
                    {/* {expanded === ele.id ? (
                      <>
                        {this.getShortDetails(ele)}
                        {this.getLongDetails(ele)}
                      </>
                    ) : ( */}
                    <>{this.getShortDetails(ele)} </>
                    {/* )} */}
                  </Grid>

                  <Grid
                    container
                    item
                    xs={2}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      alignContent: "flex-start",
                    }}
                  >
                    {ele?.comments?.length > 0 && (
                      <Badge
                        badgeContent={ele.comments?.length}
                        color="primary"
                        onClick={() => {
                          this.handleExpandClick(ele.id);
                        }}
                      >
                        <Forum
                          style={{
                            color: "#F79824",
                          }}
                        />
                      </Badge>
                    )}

                    {ele?.valid === false && (
                      <>
                        {/* {this.showIcon(ele?.type) && ( */}
                        <span>
                          <Tooltip
                            enterTouchDelay={0}
                            title={ele?.validationReason || "Invalid Activity"}
                          >
                            <CancelIcon style={{ color: "#f94144" }} />
                          </Tooltip>
                        </span>
                        {/* )} */}
                      </>
                    )}
                    {ele?.approved !== true && (
                      <>
                        {/* {this.showIcon(ele?.type) && ( */}
                        <span>
                          <Tooltip enterTouchDelay={0} title="Approval pending">
                            <WarningIcon style={{ color: "red" }} />
                          </Tooltip>
                        </span>
                        {/* )} */}
                      </>
                    )}
                    {ele?.allowActivityEdit &&
                      ele?.allowActivityEdit === true && (
                        <>
                          {/* {this.showIcon(ele?.type) && ( */}
                          <>
                            {ele?.fetchFrom === "MANUAL" && (
                              <span
                                onClick={() =>
                                  this.handleActivityUpdateClick(ele.id)
                                }
                              >
                                <Tooltip
                                  enterTouchDelay={0}
                                  title="Edit Activity"
                                >
                                  <EditIcon style={{ color: "gray" }} />
                                </Tooltip>
                              </span>
                            )}
                            <span
                              onClick={() =>
                                this.setState({
                                  activityId: ele.id,
                                  showDeleteActivityDialog: true,
                                })
                              }
                            >
                              <Tooltip
                                enterTouchDelay={0}
                                title="Delete Activity"
                              >
                                <DeleteIcon style={{ color: "gray" }} />
                              </Tooltip>
                            </span>
                          </>
                          {/* )} */}
                        </>
                      )}

                    {columns.length > 2 && (
                      <Typography
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded,
                        })}
                        onClick={() => this.handleExpandClick(ele.id)}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {expanded === ele.id ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                {expanded === ele.id && (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      localStorage.setItem("activityId", ele.id);
                      history.push("/activitydetailsview");
                    }}
                  >
                    {" "}
                    {this.getLongDetails(ele)}
                  </div>
                )}
              </div>
            ))}
          </>
        )}
      </>
    );
  };

  getRequiredRowForCombinedType = (details, list, startDate) => {
    const { classes, displayColumns, history } = this.props;

    const columns = displayColumns.split(",");
    const { expanded } = this.state;
    let newlist = list;
    // .filter(function (el) {
    //   return (
    //     el?.type?.toLowerCase() ===
    //       details?.activityType?.displayName.toLowerCase() ||
    //     el?.type?.toLowerCase() === details?.activityType?.type.toLowerCase()
    //   );
    // });

    return (
      <>
        {newlist.length > 0 && (
          <>
            <div className={classes.trainingDetailsTitle}>
              {Properties.Label_Actual}
            </div>
            {newlist?.map((ele, i) => (
              <div
                className={i === 0 ? classes.firstRowCss : classes.tableRowCss}
              >
                <Grid container direction="row">
                  <Grid
                    item
                    xs={10}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      localStorage.setItem("activityId", ele.id);
                      history.push("/activitydetailsview");
                    }}
                  >
                    <span className={classes.nameTitle}>
                      {ele?.subActivityType ? ele?.subActivityType : ele?.type}
                      {ele?.name ? " - " + ele?.name : ""}
                    </span>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={10}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      localStorage.setItem("activityId", ele.id);
                      history.push("/activitydetailsview");
                    }}
                  >
                    {/* {expanded === ele.id ? (
                    <>
                      {this.getShortDetails(ele)}
                      {this.getLongDetails(ele)}
                    </>
                  ) : ( */}
                    <>{this.getShortDetails(ele)} </>
                    {/* // )} */}
                  </Grid>

                  <Grid
                    container
                    item
                    xs={2}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      alignContent: "flex-start",
                    }}
                  >
                    {ele?.comments?.length > 0 && (
                      <Badge
                        badgeContent={ele.comments?.length}
                        color="primary"
                        onClick={() => {
                          this.handleExpandClick(ele.id);
                        }}
                      >
                        <Forum
                          style={{
                            color: "#F79824",
                          }}
                        />
                      </Badge>
                    )}
                    {ele?.valid === false && (
                      <>
                        {this.showIcon(ele?.type) && (
                          <span>
                            <Tooltip
                              enterTouchDelay={0}
                              title={
                                ele?.validationReason || "Invalid Activity"
                              }
                            >
                              <CancelIcon style={{ color: "#f94144" }} />
                            </Tooltip>
                          </span>
                        )}
                      </>
                    )}
                    {ele?.approved !== true && (
                      <>
                        {this.showIcon(ele?.type) && (
                          <span>
                            <Tooltip
                              enterTouchDelay={0}
                              title="Approval pending"
                            >
                              <WarningIcon style={{ color: "red" }} />
                            </Tooltip>
                          </span>
                        )}
                      </>
                    )}
                    {ele?.allowActivityEdit &&
                      ele?.allowActivityEdit === true && (
                        <>
                          {this.showIcon(ele?.type) &&
                            ele?.fetchFrom === "MANUAL" && (
                              <span
                                onClick={() =>
                                  this.handleActivityUpdateClick(ele.id)
                                }
                              >
                                <Tooltip
                                  enterTouchDelay={0}
                                  title="Edit Activity"
                                >
                                  <EditIcon style={{ color: "gray" }} />
                                </Tooltip>
                              </span>
                            )}
                        </>
                      )}

                    {columns.length > 2 && (
                      <Typography
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded,
                        })}
                        onClick={() => this.handleExpandClick(ele.id)}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {expanded === ele.id ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                {expanded === ele.id && (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      localStorage.setItem("activityId", ele.id);
                      history.push("/activitydetailsview");
                    }}
                  >
                    {" "}
                    {this.getLongDetails(ele)}
                  </div>
                )}
              </div>
            ))}
          </>
        )}
      </>
    );
  };

  showIcon = (type) => {
    if (type === "Strength Training" || type === "STRENGTH_TRAINING") {
      return false;
    } else if (type === "Cross Training" || type === "CROSS_TRAINING") {
      return false;
    } else {
      return true;
    }
  };

  formatDesc = (desc, url, source, filename, shortLinkName) => {
    const { classes } = this.props;
    let parts = desc?.split("$$");
    let msg = "";
    parts.map((part) => {
      if (part === "videoLink") {
        if (url !== undefined) {
          msg =
            msg +
            `<a id="link" >${shortLinkName ? shortLinkName : "see video"}
             </a>`;
          //${url}
        }
      } else {
        msg = msg + `<span>${part}</span>`;
      }
    });

    return parse(msg, {
      replace: ({ attribs }) => {
        if (attribs && attribs.id === "link") {
          return (
            <a
              onClick={() => this.handleURLClick(url, source, filename)}
              style={{ verticalAlign: "middle" }}
            >
              {/* {shortLinkName ? shortLinkName : "see video"} */}
              <VideoPlayerIcon className={classes.playerIcon} />
            </a>
          );
        }
      },
    });
  };

  handleURLClick = (url, source, filename) => {
    this.props.history.push({
      pathname: "/player",
      link: {
        link: url,
        source: source,
        filename: filename,
      },
    });
  };
  handleActivityDelete = () => {
    let { runnerId } = ApplicationUtil.getRunnerDetails();
    let eventId = ApplicationUtil.getRunnersEventID();
    const { activityId } = this.state;
    deleteActivity(eventId, runnerId, activityId).then((res) => {
      this.props.pageRefresh();
      this.setState({ showDeleteActivityDialog: false });
    });
  };
  getDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.DeleteActivityWarning}</span>
        </Typography>
      </div>
    );
  };
  render() {
    const {
      data,
      classes,
      challengeType,
      multipleActivityUpload,
      eventType,

      displayColumns,
      showNextDayActivityPlan,
      enableTraining,
      history,
    } = this.props;
    const columns = displayColumns.split(",");
    const { expanded, showDeleteActivityDialog, dialogHeader } = this.state;

    return (
      <>
        <div className={classes.tableList}>
          {/* for streak and single type */}
          {eventType === "SINGLE" && (
            <>
              {data?.map((row, i) => (
                <div key={i} style={{ marginBottom: "30px" }}>
                  <span className={classes.dateCss}>
                    {row?.startDateLocal &&
                      ApplicationUtil.changeDateFormatDateDDMM(
                        row?.startDateLocal
                      )}
                  </span>

                  {/* training */}
                  {row?.startDateLocal &&
                    row?.trainingDetails?.length > 0 &&
                    moment(
                      ApplicationUtil.changeDateFormatDate(row.startDateLocal)
                    ).isAfter(moment().format("YYYY-MM-DD")) === true && (
                      <div className={classes.trainingDetails}>
                        <div className={classes.trainingDetailsTitle}>
                          {Properties.Label_NextDayPlan}
                        </div>
                        <ul className={classes.trainingListCss}>
                          {row?.trainingDetails?.map((details) => (
                            <li>
                              {details?.description && (
                                <Clamp
                                  texts={{
                                    showMore: "Moreeee",
                                    showLess: "Menos",
                                  }}
                                  lines={2}
                                  maxLines={1000}
                                  withToggle
                                >
                                  {this.formatDesc(
                                    details?.description,
                                    details?.videoRecordLink,
                                    details?.videoSource,
                                    details?.videoFileName,
                                    details?.shortLinkName
                                  )}
                                </Clamp>
                              )}
                              {details?.additionalText && (
                                <Clamp
                                  texts={{
                                    showMore: "Moreeee",
                                    showLess: "Menos",
                                  }}
                                  lines={2}
                                  maxLines={1000}
                                  withToggle
                                >
                                  {ReactHtmlParser(details?.additionalText)}
                                </Clamp>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  <div key={row?.id} component={Paper}>
                    {row?.showResult && (
                      <Grid
                        container
                        direction="row"
                        className={classes.firstRowCss}
                      >
                        <Grid
                          item
                          xs={10}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            localStorage.setItem("activityId", row.id);
                            history.push("/activitydetailsview");
                          }}
                        >
                          <span className={classes.nameTitle}>
                            {row?.subActivityType
                              ? row?.subActivityType
                              : row?.type}
                            {row?.name ? " - " + row?.name : ""}
                          </span>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={10}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            localStorage.setItem("activityId", row.id);
                            history.push("/activitydetailsview");
                          }}
                        >
                          {challengeType === "COOPER_TEST" ? (
                            <>
                              {this.getShortDetails(row)}
                              {this.getLongDetails(row)}
                            </>
                          ) : expanded === row?.id ? (
                            <>
                              {this.getShortDetails(row)}
                              {this.getLongDetails(row)}
                            </>
                          ) : (
                            <>{this.getShortDetails(row)}</>
                          )}
                        </Grid>

                        <Grid
                          container
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            cursor: "pointer",
                            alignContent: "flex-start",
                          }}
                          onClick={() => {
                            this.handleExpandClick(row?.id);
                          }}
                        >
                          {row?.comments?.length > 0 && (
                            <Badge
                              badgeContent={row.comments?.length}
                              color="primary"
                              onClick={() => {
                                this.handleExpandClick(row?.id);
                              }}
                            >
                              <Forum
                                style={{
                                  color: "#F79824",
                                }}
                              />
                            </Badge>
                          )}
                          {row?.valid === false && (
                            <span>
                              <Tooltip
                                enterTouchDelay={0}
                                title={
                                  row?.validationReason || "Invalid Activity"
                                }
                              >
                                <CancelIcon style={{ color: "#f94144" }} />
                              </Tooltip>
                            </span>
                          )}
                          {row?.approved !== true && (
                            <span>
                              <Tooltip
                                enterTouchDelay={0}
                                title="Approval pending"
                              >
                                <WarningIcon style={{ color: "red" }} />
                              </Tooltip>
                            </span>
                          )}
                          {row?.allowActivityEdit &&
                            row?.allowActivityEdit === true &&
                            row?.fetchFrom === "MANUAL" && (
                              <span
                                onClick={() =>
                                  this.handleActivityUpdateClick(row?.id)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <Tooltip
                                  enterTouchDelay={0}
                                  title="Edit Activity"
                                >
                                  <EditIcon style={{ color: "gray" }} />
                                </Tooltip>
                              </span>
                            )}
                          {columns.length > 2 && (
                            <Typography
                              className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                              })}
                              onClick={() => this.handleExpandClick(row?.id)}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {expanded === row.id ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    )}

                    {row?.showButton && (
                      <div style={{ textAlign: "center" }}>
                        <SubmitCancelBtn
                          onClick={() => this.uploadRun(row?.startDateLocal)}
                          label={Properties.Upload_Activity_label}
                          type="submit"
                          style={{
                            width: "auto",
                            marginBottom: "5px",
                            marginTop: "5px",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}

          {(eventType === "CHALLENGE" ||
            eventType === "STREAK" ||
            eventType === "TRAINING") && (
            // multipleActivityUpload && (
            <>
              {enableTraining ? (
                <>
                  {data?.map((row) => (
                    <>
                      {row?.startDateLocal && (
                        <div style={{ marginBottom: "30px" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "5px",
                            }}
                          >
                            <span className={classes.dateCss}>
                              {row?.startDateLocal &&
                                ApplicationUtil.changeDateFormatDateDDMM(
                                  row?.startDateLocal
                                )}
                            </span>

                            {row?.showButton && (
                              <span className={classes.trainingUploadCss}>
                                {window.innerWidth > 600 ? (
                                  <SubmitCancelBtn
                                    onClick={() => {
                                      this.uploadTrainingActivity(
                                        row?.startDateLocal
                                      );
                                    }}
                                    label={Properties.Upload_Activity_label}
                                    type="submit"
                                    style={{
                                      width: "150px",

                                      alignContent: "center",
                                      alignItems: "center",
                                    }}
                                  />
                                ) : (
                                  <IconButton
                                    style={{
                                      backgroundColor: "#09456C",
                                      transform: "rotate(180deg)",
                                    }}
                                  >
                                    <GetAppIcon
                                      onClick={() => {
                                        this.uploadTrainingActivity(
                                          row?.startDateLocal
                                        );
                                      }}
                                      style={{
                                        fontSize: "25px",
                                        color: "#fff",
                                      }}
                                    />
                                  </IconButton>
                                )}
                              </span>
                            )}
                          </div>

                          {/* training */}
                          {row?.startDateLocal &&
                          row?.trainingDetails?.length > 0 ? (
                            <div className={classes.trainingDetailsPast}>
                              {row?.trainingDetails?.map((details) => (
                                <Grid
                                  container
                                  className={classes.detailsRowCss}
                                >
                                  {(details?.description ||
                                    details?.additionalText) && (
                                    <Grid item>
                                      <div
                                        className={classes.trainingDetailsTitle}
                                      >
                                        {Properties.Label_NextDayPlan}
                                      </div>
                                      {details?.description && (
                                        <Clamp
                                          texts={{
                                            showMore: "Moreeee",
                                            showLess: "Menos",
                                          }}
                                          lines={2}
                                          maxLines={1000}
                                          withToggle
                                        >
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            {this.formatDesc(
                                              details?.description,
                                              details?.videoRecordLink,
                                              details?.videoSource,
                                              details?.videoFileName,
                                              details?.shortLinkName
                                            )}
                                          </span>
                                        </Clamp>
                                      )}
                                      {details?.additionalText && (
                                        <Clamp
                                          texts={{
                                            showMore: "Moreeee",
                                            showLess: "Menos",
                                          }}
                                          lines={2}
                                          maxLines={1000}
                                          withToggle
                                        >
                                          {ReactHtmlParser(
                                            details?.additionalText
                                          )}
                                        </Clamp>
                                      )}
                                    </Grid>
                                  )}
                                </Grid>
                              ))}
                              {/* </ul> */}
                              <Grid xs={12}>
                                {row.list.length > 0 && (
                                  <>
                                    {this.getRequiredRow(
                                      row.list,
                                      row.startDateLocal,
                                      true
                                    )}
                                  </>
                                )}
                              </Grid>
                            </div>
                          ) : (
                            row.list.length > 0 && (
                              <>
                                {this.getRequiredRow(
                                  row.list,
                                  row.startDateLocal,
                                  false
                                )}
                              </>
                            )
                          )}
                        </div>
                      )}
                      {/* </>
                      )} */}
                    </>
                  ))}
                </>
              ) : (
                <>
                  {/* without training */}
                  {data?.map((row) => (
                    <div style={{ marginBottom: "30px" }}>
                      <span className={classes.dateCss}>
                        {row?.startDateLocal &&
                          ApplicationUtil.changeDateFormatDateDDMM(
                            row?.startDateLocal
                          )}
                      </span>

                      <div key={row?.id} align="center" component={Paper}>
                        {row?.list?.length > 0 ? (
                          <>
                            {row?.list?.map((ele, i) => (
                              <Grid
                                container
                                direction="row"
                                className={
                                  i === 0
                                    ? classes.firstRowCss
                                    : classes.tableRowCss
                                }
                              >
                                <Grid
                                  item
                                  xs={10}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    localStorage.setItem("activityId", ele.id);
                                    history.push("/activitydetailsview");
                                  }}
                                >
                                  <span className={classes.nameTitle}>
                                    {ele?.subActivityType
                                      ? ele?.subActivityType
                                      : ele?.type}
                                    {ele?.name ? " - " + ele?.name : ""}
                                  </span>
                                </Grid>
                                <Grid
                                  container
                                  item
                                  xs={10}
                                  onClick={() => {
                                    localStorage.setItem("activityId", ele.id);
                                    history.push("/activitydetailsview");
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {challengeType === "COOPER_TEST" ? (
                                    <>
                                      {this.getShortDetails(ele)}
                                      {this.getLongDetails(ele)}
                                    </>
                                  ) : expanded === ele?.id ? (
                                    <>
                                      {this.getShortDetails(ele)}
                                      {this.getLongDetails(ele)}
                                    </>
                                  ) : (
                                    <>{this.getShortDetails(ele)}</>
                                  )}
                                </Grid>

                                <Grid
                                  container
                                  item
                                  xs={2}
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    alignContent: "flex-start",
                                  }}
                                >
                                  {ele?.comments?.length > 0 && (
                                    <Badge
                                      badgeContent={ele.comments?.length}
                                      color="primary"
                                      onClick={() => {
                                        this.handleExpandClick(ele.id);
                                      }}
                                    >
                                      <Forum
                                        style={{
                                          color: "#F79824",
                                        }}
                                      />
                                    </Badge>
                                  )}
                                  {ele?.valid === false && (
                                    <span>
                                      <Tooltip
                                        enterTouchDelay={0}
                                        title={
                                          ele?.validationReason ||
                                          "Invalid Activity"
                                        }
                                      >
                                        <CancelIcon
                                          style={{ color: "#f94144" }}
                                        />
                                      </Tooltip>
                                    </span>
                                  )}
                                  {ele?.approved !== true && (
                                    <span>
                                      <Tooltip
                                        enterTouchDelay={0}
                                        title="Approval pending"
                                      >
                                        <WarningIcon style={{ color: "red" }} />
                                      </Tooltip>
                                    </span>
                                  )}

                                  {ele?.allowActivityEdit &&
                                    ele?.allowActivityEdit === true && (
                                      <>
                                        {ele?.fetchFrom === "MANUAL" && (
                                          <span
                                            onClick={() =>
                                              this.handleActivityUpdateClick(
                                                ele.id
                                              )
                                            }
                                          >
                                            <Tooltip
                                              enterTouchDelay={0}
                                              title="Edit Activity"
                                            >
                                              <EditIcon
                                                style={{ color: "gray" }}
                                              />
                                            </Tooltip>
                                          </span>
                                        )}
                                        <span
                                          onClick={() =>
                                            this.setState({
                                              activityId: ele.id,
                                              showDeleteActivityDialog: true,
                                            })
                                          }
                                        >
                                          <Tooltip
                                            enterTouchDelay={0}
                                            title="Delete Activity"
                                          >
                                            <DeleteIcon
                                              style={{ color: "gray" }}
                                            />
                                          </Tooltip>
                                        </span>
                                      </>
                                    )}

                                  {columns.length > 2 &&
                                    !challengeType === "COOPER_TEST" && (
                                      <Typography
                                        className={clsx(classes.expand, {
                                          [classes.expandOpen]: expanded,
                                        })}
                                        onClick={() =>
                                          this.handleExpandClick(ele.id)
                                        }
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        {expanded === ele.id ? (
                                          <ExpandLessIcon />
                                        ) : (
                                          <ExpandMoreIcon />
                                        )}
                                      </Typography>
                                    )}
                                </Grid>
                              </Grid>
                            ))}
                            {row.showButton && multipleActivityUpload && (
                              <SubmitCancelBtn
                                onClick={() =>
                                  this.uploadRun(row.startDateLocal)
                                }
                                label={Properties.Upload_Activity_label}
                                type="submit"
                                style={{
                                  width: "auto",
                                  marginBottom: "5px",
                                  marginTop: "5px",
                                  alignContent: "center",
                                  alignItems: "center",
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            {row?.showButton && (
                              <SubmitCancelBtn
                                onClick={() =>
                                  this.uploadRun(row?.startDateLocal)
                                }
                                label={Properties.Upload_Activity_label}
                                type="submit"
                                style={{
                                  width: "auto",
                                  marginBottom: "5px",
                                  marginTop: "5px",
                                  alignContent: "center",
                                  alignItems: "center",
                                }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
          {/* </Table> */}
        </div>
        <ImageDialogBox
          title={""}
          imagePath={this.state.image_src}
          open={this.state.imageDialog}
          onClick={() => this.setState({ imageDialog: false })}
          closeDisloag={() => this.setState({ imageDialog: false })}
        />
        <DialogBox
          signup={true}
          open={showDeleteActivityDialog}
          message={this.getDialogMsg()}
          header={dialogHeader}
          label={"No"}
          isSmall={true}
          onClick={() => {
            this.setState({ showDeleteActivityDialog: false });
          }}
          labelTwo={"Yes"}
          onClickTwo={() => {
            this.handleActivityDelete();
          }}
          closeDisloag={() => {
            this.setState({ showDeleteActivityDialog: false });
          }}
        />
      </>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ActivityView);
