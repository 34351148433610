import React from "react";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  makeStyles,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import ApplicationUtil from "../../utils/ApplicationUtil";
import { useDispatch, useSelector } from "react-redux";
import { updateQueNotAttempted } from "../../redux/user";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  active: {
    margin: "4px",
    fontSize: "22px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  nonActive: {
    fontSize: "16px",
    margin: "4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
}));

function RunnerSideBreadcrumbs(props) {
  const classes = useStyles();
  const {
    history,
    location: { pathname },
  } = props;
  const dispatch = useDispatch();
  let pathnames = ApplicationUtil.getPathnamesArray(pathname);
  const urlParams = new URLSearchParams(window.location.search);
  let isQueBlock = Boolean(urlParams.get("queBlock"));
  let program = ApplicationUtil.getRunnersProgramDetails();
  let paramChallengeId = urlParams.get("id");
  let paramDate = urlParams.get("viewDate");
  const currentRunningProgramChallenge = useSelector(
    (state) => state.user.currentRunningProgramChallenge
  );
  const NavigateRoute = (route) => {
    if (route === "/program") {
      history.push(route + "?distKey=" + program?.distKey);
      return;
    }
    if (route === "/calender" && !isQueBlock) {
      let search = window.location.href;
      if (search?.includes("distkey") || search?.includes("id")) {
        dispatch(
          updateQueNotAttempted({
            challenge: { id: Number(paramChallengeId) },
            date: moment(paramDate).format("DD-MM-YYYY"),
          })
        );
      } else {
        dispatch(
          updateQueNotAttempted({
            challenge: currentRunningProgramChallenge,
            date: moment().subtract(1, "day").format("DD-MM-YYYY"),
          })
        );
      }
    }
    history.push(route);
  };
  let isChallengeEvent = localStorage.getItem("isChallengeEvent");
  let isTraining = localStorage.getItem("isTraining");
  let selectedProgramDetails = ApplicationUtil.getRunnersProgramDetails();

  const mapBreadcrumbs = {
    "/": isChallengeEvent
      ? "Challenges"
      : isTraining
      ? "Trainings"
      : selectedProgramDetails?.programStartDate
      ? "Programs"
      : "Events",
    "/programDashboard": "Dashboard",
    "/calender": "Calendar",
    "/eventDetails": "Event Details",
    "/eventRegistration": "Event Registration",
    "/programRegistration": "Program Registration",
    "/eventlist": "Event List",
    "/completeEventList": "Event List",
    "/videoGallery": "Video Gallery",
    "/uploadrun": "Upload Activity",
    "/profile": "Profile",
    "/editProfile": "Edit Profile",
    "/editProfilePicture": "Edit Profile Picture",
    "/consent": "Consent",
    "/postRegister": "Additional Details",
    "/invitationList": "Invitation List",
    "/invitationForm": "Invitation Form",
    "/refer": "Refer",
    "/training": "Training",
    "/feedback": "Feedback",
    "/results": "Leaderboard",
    "/programResults": "Leaderboard",
    "/wellnessDetails": "Wellness Details",
    "/challenge": "Challenge",
    "/challengeRegistration": "Challenge Registration",
    "/summary": "Summary",
    "/activitydetailsview": "Activity Details",
    "/event": "Event Information",
    "/program": "Program Information",
    "/record-response": "Record Response",
    "/challengeView": "Challenge Details",
  };

  return (
    <MUIBreadcrumbs aria-label="breadcrumb" separator=">">
      {pathnames?.map((name, index, ele) => {
        const isLast = index === pathnames.length - 1;

        return isLast ? (
          <div
            className={classes.active}
            key={ele}
            style={{ fontWeight: "bold" }}
          >
            <span key={name}>{mapBreadcrumbs[name]}</span>
          </div>
        ) : (
          <div
            className={classes.nonActive}
            key={ele}
            style={{ fontWeight: "bold" }}
            onClick={() => NavigateRoute(name)}
          >
            <Link key={name}>{mapBreadcrumbs[name]}</Link>
          </div>
        );
      })}
    </MUIBreadcrumbs>
  );
}
export default withRouter(RunnerSideBreadcrumbs);
