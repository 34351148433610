import { withStyles } from "@material-ui/core";
import React from "react";
import { isBrowser } from "react-device-detect";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import Properties from "../../../../properties/Properties";
import AppToolBar from "../../../common/AppToolBar";
import { CustomTextField } from "../../../common/CustomTextField";
import MultiSelect from "../../../common/MultiSelect";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import { validateEmail } from "../../../../utils/Validator";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import ApplicationConstants from "../../../../constants/ApplicationConstants";
import Snackbar from "../../../common/Snackbar";
import Header from "../../../common/Header";
import { Typography, Grid, Box, Tooltip, Paper } from "@material-ui/core";
import { referSave } from "../../../../apis/eventService";

const styles = (theme) => ({
  mainPanel: {
    marginTop: "60px",
    // paddingRight: theme.rightSpacing,
    // paddingLeft: theme.leftSpacing,
    marginLeft: "6.7%",
    marginRight: "6.7%",
  },
  innerPanel: {
    marginLeft: "6.7%",
    marginRight: "6.7%",
  },
  helpCss: {
    display: "flex",
    color: "#3a6ea5",
  },
  helpContainerCss: {
    display: "inline-flex",
  },
  innerGrid: {
    height: "70px",
  },
});
class ReferralForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      runnerCountryCode: { label: "+91(India)", value: "91" },
      runnerContactNumber: "",
      countryCodeList: ApplicationConstants.CountryCodes,
    };
  }
  componentDidMount() {}

  ValidateFields = () => {
    const {
      firstName,
      firstNameError,
      lastName,
      email,
      emailError,
      runnerCountryCode,
      runnerContactNumber,
    } = this.state;
    if (firstName == "") {
      this.setState({ firstNameError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter first name.",
      });
      this.goToTop();
    } else if (
      (email === "" || emailError === true || !validateEmail) &&
      (runnerContactNumber === "" ||
        !ApplicationUtil.validateContactNumber(runnerContactNumber))
    ) {
      if (
        runnerContactNumber !== "" &&
        !ApplicationUtil.validateContactNumber(runnerContactNumber)
      ) {
        this.setState({
          runnerContactNumberError: true,
          emailError: validateEmail ? false : true,
        });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please enter valid contact number.",
        });
      } else {
        this.setState({ emailError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please enter valid email address.",
        });
      }

      this.goToTop();
    } else {
      this.saveFields();
    }
  };

  saveFields = () => {
    const {
      firstName,
      lastName,
      email,
      runnerCountryCode,
      runnerContactNumber,
    } = this.state;

    let customeParams = {
      firstName,
      lastName,
    };
    if (email) {
      customeParams.email = email;
    }
    if (runnerContactNumber) {
      customeParams.contactNumber = runnerContactNumber;
      customeParams.countryCode = runnerCountryCode.value;
    }
    customeParams.eventId = ApplicationUtil.getRunnersEventID();
    customeParams.referredBy = ApplicationUtil.getRunnerDetails().runner.id;

    //api call

    referSave(customeParams).then((res) => {
      this.props.history.push("/eventDetails");
    });
  };
  goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  render() {
    const { classes } = this.props;
    const {
      firstName,
      firstNameError,
      lastName,
      email,
      emailError,
      runnerCountryCode,
      runnerContactNumber,
      runnerContactNumberError,
      countryCodeList,
    } = this.state;
    return (
      <React.Fragment>
        <AppToolBar
          backNavigate={() => this.props.history.goBack()}
          showBack={true}
        >
          <Header {...this.props} label={Properties.Label_InvitationFormPage} />
        </AppToolBar>

        <div className={classes.mainPanel}>
          <Grid
            style={{
              width: "100%",
              margin: "0px",
            }}
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.innerGrid}
            >
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>
                  {Properties.Label_FirstName}
                </span>
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <CustomTextField
                style={
                  firstNameError
                    ? {
                        border: "1px solid red",
                        height: "40px",
                        maxHeight: "40px",
                        minHeight: "40px",
                        // width: "80%",
                        width: isBrowser ? "80%" : "100%",
                      }
                    : {
                        border: "1px solid #08456C",
                        height: "40px",
                        maxHeight: "40px",
                        minHeight: "40px",
                        width: isBrowser ? "80%" : "100%",
                      }
                }
                type={"text"}
                error={firstNameError}
                keyToSet={"firstName"}
                value={this.state.firstName}
                handleChange={(prop) => (event) => {
                  this.setState({
                    firstName: event.target.value,
                    firstNameError: false,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>
                  {Properties.Label_LastName}
                </span>
              </Typography>
              <CustomTextField
                style={{
                  border: "1px solid #08456C",
                  height: "40px",
                  maxHeight: "40px",
                  minHeight: "40px",
                  width: isBrowser ? "80%" : "100%",
                }}
                type={"text"}
                keyToSet={"lastName"}
                value={this.state.lastName}
                handleChange={(prop) => (event) => {
                  this.setState({ lastName: event.target.value });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>
                  {Properties.Label_Email}
                </span>
                <span style={{ color: "red" }}>*</span>
              </Typography>{" "}
              <CustomTextField
                style={
                  emailError
                    ? {
                        border: "1px solid red",
                        height: "40px",
                        maxHeight: "40px",
                        minHeight: "40px",
                        width: isBrowser ? "80%" : "100%",
                      }
                    : {
                        border: "1px solid #08456C",
                        height: "40px",
                        maxHeight: "40px",
                        minHeight: "40px",
                        width: isBrowser ? "80%" : "100%",
                      }
                }
                type={"email"}
                keyToSet={"email"}
                value={this.state.email}
                handleChange={(prop) => (event) => {
                  this.setState({
                    email: event.target.value,
                    emailError: validateEmail(event.target.value)
                      ? false
                      : true,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>
                  {Properties.Label_ContatNumber}
                </span>
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <div
                item
                style={{ display: "flex", width: isBrowser ? "80%" : "100%" }}
              >
                <div
                  style={{
                    width: "30%",
                    // isBrowser ? "200px" : "160px"
                  }}
                >
                  <MultiSelect
                    value={runnerCountryCode}
                    options={countryCodeList}
                    onChange={this.handleRunnerCountryCodeChange}
                  />
                </div>

                <div style={{ width: "70%" }}>
                  <CustomTextField
                    style={
                      runnerContactNumberError
                        ? {
                            border: "1px solid red",
                            height: "40px",
                            maxHeight: "40px",
                            minHeight: "40px",
                            marginLeft: "2px",
                            width: "100%",
                          }
                        : {
                            border: "1px solid #08456C",
                            height: "40px",
                            maxHeight: "40px",
                            minHeight: "40px",
                            marginLeft: "2px",
                            width: "100%",
                          }
                    }
                    type={"number"}
                    isNumber={true}
                    value={runnerContactNumber}
                    error={runnerContactNumberError}
                    keyToSet={"runnerContactNumber"}
                    handleChange={(prop) => (event) => {
                      this.setState({
                        runnerContactNumber: event.target.value,
                        runnerContactNumberError:
                          ApplicationUtil.validateContactNumber(
                            event.target.value
                          )
                            ? false
                            : true,
                      });
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid
            style={{
              width: "100%",
              height: "75px",
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
            container
            spacing={1}
          >
            <Grid
              style={{
                marginTop: "5%",
                display: "flex",
                justifyContent: "center",
              }}
              item
              xs={5}
            >
              <SubmitCancelBtn
                onClick={() => this.ValidateFields()}
                label={"Refer"}
                type="submit"
              />
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(
  withStyles(styles, { withTheme: true }),
  //   connect(mapStateToProps, {
  //     updateUserDetails,
  //   }),
  withRouter
)(ReferralForm);
