import React, { Component, useEffect, useState } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import { withRouter } from "react-router-dom";
import { getTeamAndGroupNames, getTeamList } from "../../../../../apis/eventService";
import GoldMedal from "../../../../../images/gold-medal.png";
import SilverMedal from "../../../../../images/silver-medal.png";
import BronzeMedal from "../../../../../images/bronze-medal.png";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BoardList from "../../../registration/events/viewResults/Leaderboard/BoardList";
import GroupIcon from '@material-ui/icons/Group';

const styles = (theme) => ({
  winnerTeamsContainer: {
    marginTop: "30px",
    display: "flex",
    alignItems: "flex-start",
    "& .teamLayout": {
      color: "#ffffff",
      backgroundColor: "#0077b6",
      borderRadius: "0",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "0px",
    },
    "& .teamWinner1": {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "89%",
      },
    },
    "& .teamWinner2": {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "89%",
      },
    },
    "& .teamWinner3": {
      width: "100%",
      marginBottom: "10px",
      [theme.breakpoints.down("sm")]: {
        width: "89%",
      },
    },

    "& .medalIcons": {
      height: 60,
      width: 60,
      objectFit: "contain",
      [theme.breakpoints.down("sm")]: {
        height: 35,
        width: 35,
      },
    },
   
  },
  teamListContainer: {
    marginTop: "20px",
    "& .teamList": {
      width: "95%",
      marginLeft: "5%",
      borderRadius: "0",
      backgroundColor: "#0077b6",
      color: "#ffffff",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10%",
        width: "89%",
      },
    },
    "& .accordion": {
      cursor: "none",
      "& .MuiIconButton-root": {
        visibility: "hidden",
      },
      // width: '100%'
    },
  },
  winnerIconsContainer: {
    display: "flex",
    alignItems: "center",
  },
  noTeam: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "100px",
  },
});
function OrganizerTeams(props) {
  const { classes, history } = props;
  const [teamWinnersData, setTeamWinnersData] = useState(null);
  const [teamData, setTeamData] = useState(null);
  const [teamListData, setTeamListData] = useState([]);
const [expanded, setExpanded] = useState(false)
const [challengeParameter, setChallengeParameter] = useState('DISTANCE')
  useEffect(() => {
    let eventId = ApplicationUtil.getSupportsEventID();
    getTeamAndGroupNames(eventId, "TEAMS").then((res) => {
      if (res.data) {
        let data = res.data;
        let winnersData = data?.teamDetails?.filter(
          (x) => x.teamRank == 1 || x.teamRank == 2 || x.teamRank == 3
        );
        let teamData = data?.teamDetails?.filter((x) => x.teamRank > 3);

        setTeamWinnersData(data?.teamDetails?.length > 0 ? winnersData : []);
        setTeamData(teamData);
        if(data?.challengeParameter) setChallengeParameter(data?.challengeParameter)
      }
    });
  }, []);
 
  const handleChange = (panel) => (event, isExpanded) => {
    if (!isExpanded) {
      setExpanded(false)
      return;
    }
    
    let eventId = ApplicationUtil.getSupportsEventID();
      let requestParams = {
        eventId: eventId,
        teamId:panel,
         leaderboard:false
      };
      getTeamList(requestParams).then((res) => {
        if (res.data) {
          let data = res.data;
          setTeamListData(data?.teamMemberActivities);
        }
      });
  
    setExpanded(panel ? (isExpanded ? panel : false) : false);
  };
 const navigateToDetails = (id) => {
    localStorage.setItem("supportRunnerId", id);

  history.push("list/details");
  };
  return (
    <div>
      {teamWinnersData?.length > 0 && (
        <div>
          {teamWinnersData?.map((team) => (
            <div key={team?.teamRank} className={classes.winnerTeamsContainer}>
              <div className={classes.winnerIconsContainer}>
                <img
                  src={
                    team?.teamRank === 1
                      ? GoldMedal
                      : team?.teamRank === 2
                      ? SilverMedal
                      : BronzeMedal
                  }
                  alt="gold-medal"
                  className="medalIcons"
                />
              </div>
              <Accordion
                className={`teamLayout teamWinner${team?.teamRank}`}
                expanded={expanded === team?.id}
                onChange={handleChange(team?.id)}
              >
                <AccordionSummaryContainer
                  team={team}
                  challengeParameter={challengeParameter}
                 
                />
                <AccordionDetails className={classes.accordionDetails}>
                  {teamListData?.length > 0 && (
                    <BoardList
                      challengeParameter={challengeParameter}
                      distance={
                        challengeParameter === "DISTANCE" ? true : false
                      }
                      time={challengeParameter === "DISTANCE" ? true : false}
                      sort
                      data={teamListData}
                      onClick={navigateToDetails}
                      teamLeaderId={team?.teamLeaderId}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </div>
      )}
      {teamData?.length > 0 && 
      <div className={classes.teamListContainer}>
          {teamData?.map((team, i) => (
              <Accordion
                key={team.id}
                expanded={expanded === team.id}
                onChange={handleChange(team.id)}
                className={`teamList ${team?.teamDistance > 0 || team?.teamTotalSteps > 0
                    ? ""
                    : "accordion"
                  }`}
              >
                <AccordionSummaryContainer
                  team={team}
                  challengeParameter={challengeParameter}
                  radioValue={"team"}
                />
                <AccordionDetails className={classes.accordionDetails}>
                  {teamListData?.length > 0 && (
                    <BoardList
                      challengeParameter={challengeParameter}
                      distance={
                        challengeParameter === "DISTANCE" ? true : false
                      }
                      time={challengeParameter === "DISTANCE" ? true : false}
                      sort
                      data={teamListData}
                      onClick={navigateToDetails}
                      teamLeaderId={team?.teamLeaderId}
                      style={{ width: "100%" }}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            ))

          }
        </div>
        }
{teamWinnersData?.length === 0 && 
    <div className={classes.noTeam}>
             <GroupIcon style={{ width: "200px", height: "200px",color: "gray", }} />
             <span
              style={{
                fontSize: "18px",
                fontWeight: 600,
                color: "gray",
              }}
            >
              Teams not found.
            </span>
          </div>
}
    </div>
  );
}
const AccordionSummaryData = ({ value, align }) => {
  return (
    <Grid
      item
      style={{
        textAlign: align ? align : "left",
        fontSize: window.innerWidth >= 600 ? "18px" : "16px",
      }}
    >
      <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
        {value}{" "}
      </span>
    </Grid>
  );
};
const AccordionSummaryContainer = ({ team, challengeParameter }) => {
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
    >
      <Grid container>
        <Grid item xs={6}>
          {" "}
          <AccordionSummaryData value={team?.teamName} />{" "}
        </Grid>
        {challengeParameter === "DISTANCE" && (
          <Grid item xs={3}>
            <AccordionSummaryData
              value={parseFloat(team?.teamDistance / 1000).toFixed(2) + " Km"}
              align={"center"}
            />
          </Grid>
        )}

        {challengeParameter === "CALORIE" && (
          <Grid item xs={3}>
            <AccordionSummaryData
              value={team?.teamTotalCalories}
              align={"center"}
            />
          </Grid>
        )}
        {challengeParameter === "STEPS" && (
          <Grid item xs={3}>
            <AccordionSummaryData
              value={team?.teamTotalSteps}
              align={"center"}
            />
          </Grid>
        )}
        {challengeParameter === "ELEVATION" && (
          <Grid item xs={3}>
            <AccordionSummaryData
              value={team?.teamTotalElevationGain}
              align={"center"}
            />
          </Grid>
        )}
        {challengeParameter === "WELLNESS_POINT" && (
          <Grid item xs={3}>
            <AccordionSummaryData
              value={team?.teamTotalWellnessPoint}
              align={"center"}
            />
          </Grid>
        )}

        <Grid item xs={3}>
          <AccordionSummaryData
            value={team?.teamRank}
            align={"center"}
          />
        </Grid>
      </Grid>
    </AccordionSummary>
  );
};
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(OrganizerTeams);
