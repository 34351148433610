import React from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Divider,
  Box,
  Table,
  TableRow,
  TableBody,
} from "@material-ui/core";
import Properties from "../../../../properties/Properties";
import AppToolBar from "../../../common/AppToolBar";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import {
  getEventList,
  getProgramList,
  getUpcomingEventList,
} from "../../../../apis/eventService";
import DialogBox from "./../../../common/DialogBox";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Header from "./../../../common/Header";
import ListPageLoader from "./../../../common/ListPageLoader";
import ChallengesEventList from "../challenges/ChallengesEventList";
import {
  openEventChallenges,
  openEventChallengeUserCheck,
} from "../../../../apis/challengeService";
import ChallengeDetailsTile from "../challenges/ChallengeDetailsTile";
import { connect } from "react-redux";
import {
  updateUserPreferences,
  updateUserSelectedEvent,
  updateUserSelectedProgram,
} from "../../../../redux/user";
import EventListTiles from "../../../common/EventListTiles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import InlineDivider from "../../../common/InlineDivider";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: "6.7%",
    marginRight: "6.7%",
    boxShadow: "none !important",
    marginTop: "68px",
  },
  rootInner: {
    flexGrow: 1,
    marginLeft: "6.7%",
    marginRight: "6.7%",
    marginTop: "10px",
    boxShadow: "none !important",
  },
  selectedTab: {
    backgroundColor: "#4791db",
    color: "#fff",
    borderRadius: "10px",
    textTransform: "none",
    minWidth: 72,
    // fontWeight: theme.typography.fontWeightRegular,
    fontWeight: 600,
  },
  tab: {
    // color: "#fff",
    textTransform: "none",
    minWidth: 72,
    // fontWeight: theme.typography.fontWeightRegular,
    // marginRight: theme.spacing(4),
    fontWeight: 600,
  },
  labels: {
    fontWeight: 600,
    textDecoration: "underline",
  },
});

class EventListView extends React.Component {
  state = {
    anchorEl: null,
    userName: "",
    value: 1,
    data: null,
    totalRecords: 0,
    pastData: null,
    pastTotalRecords: 0,
    open: false,
    challengesData: null,
    dialogHeader: Properties.ActivitySync_label,
    dialogMsg: Properties.ActivitySync_Instruction,
    newMessagesPresent: false,
    programList: [],
  };

  componentDidMount() {
    document.title = Properties.Page_Title_Event_List;
    localStorage.removeItem("navigateToCalender");
    localStorage.removeItem("start");
    localStorage.removeItem("end");
    localStorage.removeItem("days");
    localStorage.removeItem("uploadDate");
    localStorage.removeItem("fromEventDetails");
    this.setState({ userName: localStorage.getItem("userName") });

    if (!ApplicationUtil.isuserLoggedIn()) {
      this.props.history.push("/");
    } else {
      //challenges

      // let organizer
      let organizer = JSON.parse(localStorage.getItem("organizer"));

      // let isOpenEventAvailable = false;
      openEventChallenges(organizer?.id ? organizer?.id : null)
        .then((res) => {
          if (res.data?.length > 0) {
            // isOpenEventAvailable = true;
            this.setState({
              isEventChallengesAvailable: true,
              challengesData: res.data,
            });
          } else if (res.data?.success.code === "404") {
            this.setState({
              isEventChallengesAvailable: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            isEventChallengesAvailable: false,
          });
        });

      let customParamsUpcomingEvents = {
        pageCount: 20,
        pageNumber: 0,
      };
      let index = this.props.userPreferences?.eventTabID;

      getUpcomingEventList(customParamsUpcomingEvents).then((res) => {
        let data = res.data.data;
        let length = data.totalRecords;
        let registrationOpen = data.every(function (e) {
          return e.isRegistrationOpen === false;
        });

        // !isOpenEventAvailable add condition to change breadcrumb behaviour
        let tabValue = length === 0 || registrationOpen ? 1 : 0;

        if (index) {
          tabValue = index ? (index === "0" ? 0 : index) : tabValue;
        }
        this.setState({
          data: data,
          totalRecords: length,
          value: tabValue,
        });
      });
      // if (index == 1) {
      //past events
      let customParams = {
        // pageCount: 20,
        // pageNumber: 0,
        sortKey: "",
        sortOrder: true,
      };
      getEventList(customParams).then((res) => {
        this.setState({
          pastData: res.data.data,
          pastTotalRecords: res.data.totalRecords,
        });
      });
      // }
      getProgramList(customParams).then(({ data }) => {
        let list = data?.programDto?.map((x) => {
          x.image = x?.imageDto;
          return x;
        });
        this.setState({
          programList: list,
          programTotalRecords: data?.totalRecords,
        });
      });

      let generateOTP = false;
      let profileData = ApplicationUtil.getRunnerDetails();

      openEventChallengeUserCheck(
        generateOTP,
        profileData?.email,
        profileData?.contactNumber
      ).then((res) => {
        this.setState({ trial: res.data.trialApplicable });
      });

      this.setState({
        open: this.props.location?.openActivitySync,
        newMessagesPresent:
          this.props.location?.newMessageEvents &&
          this.props.location?.newMessagesPresent,
        // newMessageEvents:this.props.location.newMessageEvents
      });
    }
  }
  navigateToFeedback = () => {
    if (this.props.location?.newMessageEvents) {
      let keys = Object.keys(this.props.location.newMessageEvents);
      let obj = { id: keys[0] };
      this.props.updateUserSelectedEvent(obj);
      this.props.history.push("/feedback");
    }
  };
  closeWindow = () => {
    this.setState({ open: false, newMessagesPresent: false });
  };
  getList = (isFristPage, page) => {
    const { appliedFilter, appliedSort } = this.state;
    let customeParams = {
      filterString: appliedFilter.sortColumn,
      pageCount: 20,
      pageNumber: page ? page : 0,
      sortKey: appliedSort.sortColumn,
      sortOrder: appliedSort.sortDirection === "ASC" ? true : false,
    };
    getUpcomingEventList(customeParams).then((res) => {
      let newdata = page
        ? this.state.data.concat(res.data.data)
        : res.data.data;
      this.setState({ data: newdata, totalRecords: res.data.totalRecords });
    });
  };

  getPastList = (isFirstPage, page) => {
    const { appliedFilter, appliedSort } = this.state;
    let customeParams = {
      pageCount: 20,
      pageNumber: page ? page : 0,
      sortKey: "", // appliedSort.sortColumn,
      sortOrder: true, // appliedSort.sortDirection === "ASC" ? true : false,
    };

    getEventList(customeParams).then((res) => {
      let newdata = page
        ? this.state.pastData.concat(res.data.data)
        : res.data.data;
      this.setState({
        pastData: newdata,
        pastTotalRecords: res.data.totalRecords,
      });
    });
  };

  // Remove the support of pagination, as duplicate programs gets concat Defect_id_953

  // getProgramList = (isFirstPage, page) => {
  //   let customParams = {
  //     pageCount: 20,
  //     pageNumber: page ? page : 0,
  //   };
  //   getProgramList(customParams).then((res) => {
  //     let newdata = page
  //       ? this.state.programList.concat(res?.data?.programDto)
  //       : res?.data?.programDto;

  //     newdata = newdata.filter(function (value) {
  //       return value !== undefined;
  //     });

  //     let list = newdata?.map((x) => {
  //       x.image = x?.imageDto;
  //       return x;
  //     });

  //     this.setState({
  //       programList: list,
  //       programTotalRecords: res.data.totalRecords,
  //     });
  //   });
  // };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });

    let userPreferences = this.props.userPreferences;
    this.props.updateUserPreferences({
      eventTabID: newValue,
    });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  getPastEvents = () => {
    const { classes } = this.props;

    return (
      <div index={0}>
        <ListPageLoader
          // style={{width:"100%"}}
          pageParams={{
            page: 0,
            start: 0,
            limit: 20,
            TotalRecords: this.state.pastTotalRecords,
          }}
          totalLoadedLength={
            this.state.pastData ? this.state.pastData.length : 0
          }
          // divHeight={window.innerHeight - 190}
          loadNextData={(params) => {
            this.getPastList(false, params.page);
          }}
        >
          <Grid container spacing={4} style={{ marginBottom: "10px" }}>
            {this.state.pastData && this.state.pastData.length > 0 ? (
              this.state.pastData.map((row, index) => (
                <>
                  {row.openSubscriptionEvents ? (
                    <ChallengeDetailsTile
                      row={row?.openSubscriptionEvents}
                      startDate={ApplicationUtil.FormatDateToUTC(
                        row?.eventDate,
                        row?.eventTime
                      )}
                      endDate={ApplicationUtil.changeDateFormat(
                        row?.localEndDate
                      )}
                      key={index}
                      onClick={() => {
                        this.props.updateUserPreferences({
                          drawerSidebar: "/eventDetails",
                        });
                        this.props.updateUserSelectedEvent(row);
                        this.props.updateUserSelectedProgram({});

                        this.props.history.push("/eventDetails");
                      }}
                    />
                  ) : (
                    <EventListTiles
                      {...this.props}
                      row={row}
                      index={index}
                      key={index}
                      navigateToDetails={(e) => {
                        this.props.updateUserSelectedEvent(row);
                        this.props.updateUserSelectedProgram({});

                        this.props.history.push("/eventDetails");
                      }}
                      displayRegistration={false}
                      hideResultsForAdmin
                    />
                  )}
                </>
              ))
            ) : this.state.pastData === null ? (
              <div style={{ padding: "15px 8px 15px 8px" }}>
                {/* {loading(true)} */}
              </div>
            ) : (
              <div style={{ margin: "20px" }}>{"No events available"}</div>
            )}
          </Grid>
        </ListPageLoader>
      </div>
    );
  };

  getProgramEvents = () => {
    const { classes } = this.props;
    const { programList, programTotalRecords } = this.state;
    // vishal - seprating the current, upcoming and past program from programlist
    let currProg = [];
    let upcomingProg = [];
    let pastProg = [];
    if (programList && programList?.length > 0) {
      programList.forEach((obj) => {
        const { localStartDate, localEndDate } = obj;
        const today = new Date();
        if (
          // check for current program
          moment(today, "YYYY-MM--DD").isBetween(
            moment(localStartDate, "YYYY-MM--DD"),
            moment(localEndDate, "YYYY-MM--DD")
          ) &&
          obj?.isRegistered
        ) {
          currProg.push(obj);
        } else if (
          // check for upcoming program
          moment(localStartDate, "YYYY-MM--DD").isAfter(
            moment(today, "YYYY-MM--DD")
          ) &&
          obj?.isRegistered
        ) {
          upcomingProg.push(obj);
        } else if (
          // check for past program
          moment(localEndDate, "YYYY-MM--DD").isBefore(
            moment(today, "YYYY-MM--DD")
          ) &&
          obj?.isRegistered
        ) {
          pastProg.push(obj);
        }
      });
    }
    // vishal - combine current and upcoming program in one - as per req
    const currProg_UpProg = [...currProg, ...upcomingProg];
    return (
      <div index={0}>
        {/* Remove the support of pagination, as duplicate programs gets concat Defect_id_953 */}
        {/* <ListPageLoader
          // style={{width:"100%"}}
          pageParams={{
            page: 0,
            start: 0,
            limit: 20,
            TotalRecords: programTotalRecords,
          }}
          totalLoadedLength={programList ? programList?.length : 0}
          // divHeight={window.innerHeight - 190}
          loadNextData={(params) => {
            this.getProgramList(false, params.page);
          }}
        > */}
        {/* current + upcoming */}
        <Grid container spacing={4} style={{ marginBottom: "10px" }}>
          {currProg_UpProg && currProg_UpProg?.length > 0 ? (
            currProg_UpProg?.map((row, index) => (
              <>
                <EventListTiles
                  {...this.props}
                  row={row}
                  index={index}
                  key={index}
                  navigateToDetails={(e) => {
                    this.props.updateUserSelectedProgram(row);
                    this.props.history.push(`/program?distKey=${row.distKey}`);
                    // this.props.history.push("/calender");
                  }}
                  displayRegistration={false}
                  hideResultsForAdmin
                />
              </>
            ))
          ) : currProg_UpProg === null ? (
            <div style={{ padding: "15px 8px 15px 8px" }}></div>
          ) : (
            <div style={{ margin: "20px", marginTop: "30px" }}>
              {"No current / upcoming programs available"}
            </div>
          )}
        </Grid>
        {/* seprator*/}
        <div style={{ marginBottom: "10px" }}>
          <InlineDivider
            style={{ height: "2px", backgroundColor: "#444444" }}
            childrenStyle={{
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Past Programs
          </InlineDivider>
        </div>
        {/* past */}
        <Grid container spacing={4} style={{ marginBottom: "10px" }}>
          {pastProg && pastProg?.length > 0 ? (
            pastProg?.map((row, index) => (
              <>
                <EventListTiles
                  {...this.props}
                  row={row}
                  index={index}
                  key={index}
                  navigateToDetails={(e) => {
                    this.props.updateUserSelectedProgram(row);
                    // this.props.history.push("/calender");
                    this.props.history.push(`/program?distKey=${row.distKey}`);
                  }}
                  displayRegistration={false}
                  hideResultsForAdmin
                />
              </>
            ))
          ) : pastProg === null ? (
            <div style={{ padding: "15px 8px 15px 8px" }}></div>
          ) : (
            <div style={{ margin: "20px" }}>{"No past programs available"}</div>
          )}
        </Grid>
        {/* </ListPageLoader> */}
      </div>
    );
  };

  //upcoming events
  getEventView = () => {
    const { classes } = this.props;
    const { value, data, challengesData, trial, programList } = this.state;
    let upcomingProg = [];

    if (programList && programList?.length > 0) {
      programList.forEach((obj) => {
        if (obj && !obj?.isRegistered) {
          upcomingProg.push(obj);
        }
      });
    }
    return (
      <div value={value} index={0}>
        {this.state.isEventChallengesAvailable && (
          <>
            <Typography variant="h6">
              <span className={classes.labels}>Challenges</span>
            </Typography>

            <ChallengesEventList
              challengesData={challengesData}
              trial={trial}
            />

            <Typography variant="h6" style={{ paddingTop: "15px" }}>
              <span className={classes.labels}>Events</span>
            </Typography>
          </>
        )}
        <ListPageLoader
          // style={{width:"100%"}}
          pageParams={{
            page: 0,
            start: 0,
            limit: 20,
            TotalRecords: this.state.totalRecords,
          }}
          totalLoadedLength={this.state.data ? this.state.data.length : 0}
          // divHeight={window.innerHeight - 190}
          loadNextData={(params) => {
            this.getList(false, params.page);
          }}
        >
          <Grid container spacing={4} style={{ marginBottom: "10px" }}>
            {this.state.data?.length > 0 ? (
              this.state.data.map((row, index) => (
                <EventListTiles
                  key={index}
                  {...this.props}
                  row={row}
                  index={index}
                  navigateToRegistration={(e) => {
                    this.props.updateUserSelectedEvent(row);
                    this.props.updateUserSelectedProgram({});
                    this.props.history.push("/eventRegistration");
                  }}
                  displayRegistration
                  hideResultsForAdmin
                />
              ))
            ) : this.state.data === null ? (
              <div style={{ padding: "15px 8px 15px 8px" }}>
                {/* {loading(true)} */}
              </div>
            ) : (
              <div style={{ margin: "20px" }}>{"No events available"}</div>
            )}
          </Grid>
        </ListPageLoader>
        {/* seprator*/}
        <div style={{ marginBottom: "10px" }}>
          <InlineDivider
            style={{ height: "2px", backgroundColor: "#444444" }}
            childrenStyle={{
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Programs
          </InlineDivider>
        </div>
        {/* Upcoming Program */}
        <Grid container spacing={4} style={{ marginBottom: "10px" }}>
          {upcomingProg && upcomingProg?.length > 0 ? (
            upcomingProg?.map((row, index) => (
              <>
                <EventListTiles
                  {...this.props}
                  row={row}
                  index={index}
                  key={index}
                  navigateToDetails={(e) => {
                    this.props.updateUserSelectedProgram(row);
                    this.props.history.push(`/program?distKey=${row.distKey}`);
                  }}
                  displayRegistration={false}
                  hideResultsForAdmin
                />
              </>
            ))
          ) : upcomingProg === null ? (
            <div style={{ padding: "15px 8px 15px 8px" }}></div>
          ) : (
            <div style={{ margin: "20px" }}>
              {"No upcoming programs available"}
            </div>
          )}
        </Grid>
      </div>
    );
  };

  /**
   * if user logout then hide the program tab and
   * set the selected tab value as 1 (My Events Tab)
   * @param {*} flag
   */
  handleIsuserLogIn = (flag) => {
    if (flag === false) {
      this.setState({ value: 1 });
    }
  };

  render() {
    const { classes } = this.props;
    const { value, nativeView } = this.state;

    return (
      <React.Fragment>
        <AppToolBar
          backNavigate={() => this.props.history.goBack()}
          // showBack={true}
        >
          <Header
            {...this.props}
            label={Properties.Label_EventsList}
            handleIsuserLogIn={this.handleIsuserLogIn}
          />
          <div style={{ width: 35 }} />
        </AppToolBar>

        <Paper className={classes.root}>
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={this.handleChange}
            centered
          >
            <Tab
              value={0}
              fullWidth
              className={value === 0 ? classes.selectedTab : classes.tab}
              label="Upcoming"
            />
            <Tab
              value={1}
              fullWidth
              className={value === 1 ? classes.selectedTab : classes.tab}
              label="My Events"
            />
            {this.state.programList?.length > 0 &&
              ApplicationUtil.isuserLoggedIn() && (
                <Tab
                  value={2}
                  fullWidth
                  className={value === 2 ? classes.selectedTab : classes.tab}
                  label="Programs"
                />
              )}
          </Tabs>
        </Paper>
        {/* <TabPanel></TabPanel> */}
        <div className={classes.rootInner}>
          {value == 0 && this.getEventView()}
          {value == 1 && this.getPastEvents()}
          {this.state.programList?.length > 0 &&
            value == 2 &&
            this.getProgramEvents()}
        </div>

        <DialogBox
          open={this.state.open}
          message={this.state.dialogMsg}
          header={this.state.dialogHeader}
          label={"Skip"}
          onClick={() => {
            this.closeWindow();
          }}
          labelTwo={"Continue"}
          onClickTwo={() => {
            this.props.history.push("/consent");
          }}
          closeDisloag={null} //{() => this.closeWindow()}
        />
        <DialogBox
          open={this.state.newMessagesPresent}
          message={"You got new messages"}
          header={"New Messages"}
          singleButton
          label={"View"}
          onClick={this.closeWindow}
          onSingleButtonClick={this.navigateToFeedback}
          // labelTwo={"View"}
          // onClickTwo={() => {
          //   this.props.history.push("/feedback");
          // }}
          closeDisloag={null} //{() => this.closeWindow()}
        />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    userSelectedEvent: state.user.userSelectedEvent,
    userPreferences: state.user.userPreferences,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateUserPreferences,
    updateUserSelectedEvent,
    updateUserSelectedProgram,
  })
)(EventListView);
// export default connect(mapStateToProps)(EventListView);
