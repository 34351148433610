import { withStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import AppToolBar from "../../../common/AppToolBar";
import Header from "../../../common/Header";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import { useEffect } from "react";
import Properties from "../../../../properties/Properties";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  getProgramParticipantDashboardData,
  getRegisteredEventDetails,
} from "../../../../apis/eventService";
import httpService from "../../../../apis/httpService";
import { Typography } from "@material-ui/core";
import ApplicationConstants from "../../../../constants/ApplicationConstants";
import RunnerSideBreadcrumbs from "../../../common/RunnerSideBreadcrumbs";
import CooperTest from "./CooperTestComponent";
import StairStepperGraphComponent from "./StairStepperGraphComponent";
import StepsChallengeComponent from "./StepsChallengeComponent";
import KnowledgeSessionComponent from "./KnowledgeSessionComponent";
import NoSugarChallengeTable from "./NoSugarChallengeTable";

function ParticipantProgramDashboard({ classes, history }) {
  const [programDetails, setProgramDetails] = useState();
  const [dateRangeOptions, setDateRangeOptions] = useState();
  const [individualValues, setIndividualValues] = useState();
  const [cooperTestData, setCooperTestData] = useState([]);
  const [cooperTest, setCooperTest] = useState(null);
  const [knowledgeSessionData, setKnowledgeSessionData] = useState();
  const [knowledgeSessions, setKnowledgeSessions] = useState();
  const [verticalBarGraphData, setVerticalBarGraphData] = useState();
  const [loading, setLoading] = useState(true);

  // newgraphdata

  const [stepperGraphData, setStepperGraphData] = useState({});

  const [programChallenges, setProgramChallenges] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!ApplicationUtil.isuserLoggedIn("runner")) {
      let search = window.location.href;
      let distKey = search.split("distkey=")[1];
      if (!distKey) distKey = search.split("distKey=")[1];
      let loginRoute = "/login";
      if (distKey !== undefined) {
        loginRoute =
          loginRoute +
          "?distKey=" +
          distKey +
          "&isprogram=true&isDashboard=true";
      }
      history.push(loginRoute);
    } else {
      getParticipantData();
    }
  }, []);

  function getParticipantData() {
    let programDetails = ApplicationUtil.getRunnersProgramDetails();
    setProgramDetails(programDetails);
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runner?.id;

    if (programDetails && programDetails?.eventDTO) {
      const getStepsChallenge = programDetails?.eventDTO.find(
        (event) =>
          event?.eventRunCategories[0]?.activityType?.type === "STEPS" &&
          event.challengeType !== "STAIR_STEPPER"
      );

      let startD = getStepsChallenge?.localStartDate;
      let endD = getStepsChallenge?.localEndDate;
      let diff =
        moment(endD, "YYYY-MM-DD").diff(moment(startD, "YYYY-MM-DD"), "day") +
        1;

      if (getStepsChallenge && Object.keys(getStepsChallenge).length > 0) {
        getRegisteredEventDetails(runnerId, getStepsChallenge.id)
          .then((registeredEventRes) => {
            const registration = registeredEventRes?.data?.registration;
            const result = registeredEventRes?.data?.result;
            const event = registeredEventRes?.data?.event;
            getProgramParticipantDashboardData(runnerId, programDetails?.id)
              .then((res) => {
                if (res?.data) {
                  const {
                    cooperTestResult,
                    graphDTO,
                    programSessionResultDTOs,
                  } = res?.data;

                  if (res?.data?.programChallenges) {
                    setProgramChallenges(res.data.programChallenges);
                    setLoading(false);
                  }

                  if (graphDTO?.length > 0) {
                    const graphData = graphDTO.filter(
                      (graph) =>
                        graph.challengeType !==
                        ApplicationConstants.challengeTypeList[6].value
                    );
                    let stairStepperData = graphDTO?.filter(
                      (graph) =>
                        graph?.challengeType ===
                        ApplicationConstants.challengeTypeList[6].value
                    );

                    setStepperGraphData(() => {
                      const dateSorted =
                        stairStepperData?.[0]?.runnerActivityDetails.sort(
                          (a, b) => {
                            const dateA = moment(
                              a.startDateLocal,
                              "DD-MM-YYYY HH:mm:ss"
                            );
                            const dateB = moment(
                              b.startDateLocal,
                              "DD-MM-YYYY HH:mm:ss"
                            );
                            return dateA - dateB;
                          }
                        );
                      let weekWise = [];
                      for (let i = 0; i < dateSorted?.length; i += 7) {
                        weekWise.push(dateSorted?.slice(i, i + 7));
                      }

                      function formattedDate(date) {
                        return moment(date, "DD-MM-YYYY HH:mm:ss").format(
                          "Do MMM"
                        );
                      }

                      let currentDate = moment().format("DD-MM-YYYY");
                      let graphValues = {
                        title: stairStepperData?.[0]?.eventName,
                        eventId: stairStepperData?.[0]?.eventId,
                        eventStartDate: stairStepperData?.[0]?.eventStartDate,
                        weekRange: [],
                        currentWeek: 0,
                      };

                      for (let i = 0; i < weekWise?.length; i++) {
                        let weekData =
                          weekWise[i]?.length > 3
                            ? weekWise[i]
                            : weekWise[i - 1]?.concat(weekWise[i]) || [];

                        let startDate =
                          weekData?.length > 0
                            ? weekData[0]?.startDateLocal
                            : null;
                        let endDate =
                          weekData?.length > 0
                            ? weekData[weekData?.length - 1]?.startDateLocal
                            : null;

                        if (weekData !== weekWise[i]) {
                          graphValues.weekRange[
                            graphValues.weekRange.length - 1
                          ] = `${formattedDate(startDate)}-${formattedDate(
                            endDate
                          )}`;

                          graphValues[`week${i}`] = weekData;
                        } else {
                          graphValues.weekRange.push(
                            startDate && endDate
                              ? `${formattedDate(startDate)}-${formattedDate(
                                  endDate
                                )}`
                              : null
                          );
                          graphValues[`week${i + 1}`] = weekData;

                          let calculatedDates = graphValues[
                            `week${i + 1}`
                          ].filter((item, index, array) => {
                            if (
                              moment(item.startDateLocal, "DD-MM-YYYY HH:mm:ss")
                                .format("DD-MM-YYYY")
                                .includes(currentDate)
                            ) {
                              graphValues.currentWeek = i;

                              return `week${i}`;
                            }
                          });
                        }
                      }

                      return graphValues;
                    });

                    setVerticalBarGraphData(graphData[0]);
                    const { runnerActivityDetails, yAxis, xAxis } =
                      graphData[0];
                    const stepsChallengeGraphData = prepareIndividualValues({
                      runnerActivityDetails,
                      xAxis,
                      yAxis,
                      registration,
                      result,
                      diff,
                      event,
                      startD,
                      endD,
                    });
                    setIndividualValues(
                      stepsChallengeGraphData?.values || null
                    );
                    setDateRangeOptions(stepsChallengeGraphData?.list || []);
                  } else {
                    setIndividualValues(null);
                  }

                  if (cooperTestResult && cooperTestResult?.length) {
                    setCooperTest(cooperTestResult);
                    prepareCooperTestDataForParticipant({ cooperTestResult });
                  } else {
                    setCooperTest(null);
                  }
                  prepareKnowledgeSessionData({ programSessionResultDTOs });
                }
                setLoading(false);
              })
              .catch(() => {
                setLoading(false);
              });
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        getProgramParticipantDashboardData(runnerId, programDetails?.id)
          .then((res) => {
            if (res?.data) {
              const { cooperTestResult, graphDTO, programSessionResultDTOs } =
                res?.data;

              if (res?.data?.programChallenges) {
                setProgramChallenges(res?.data?.programChallenges);
              }

              if (graphDTO.length && graphDTO[0]) {
                const graphData = graphDTO.filter(
                  (graph) =>
                    graph.challengeType !==
                    ApplicationConstants.challengeTypeList[6].value
                );
                let stairStepperData = graphDTO?.filter(
                  (graph) =>
                    graph?.challengeType ===
                    ApplicationConstants.challengeTypeList[6].value
                );

                setStepperGraphData(() => {
                  const dateSorted =
                    stairStepperData?.[0]?.runnerActivityDetails.sort(
                      (a, b) => {
                        const dateA = moment(
                          a.startDateLocal,
                          "DD-MM-YYYY HH:mm:ss"
                        );
                        const dateB = moment(
                          b.startDateLocal,
                          "DD-MM-YYYY HH:mm:ss"
                        );
                        return dateA - dateB;
                      }
                    );
                  let weekWise = [];
                  for (let i = 0; i < dateSorted?.length; i += 7) {
                    weekWise.push(dateSorted?.slice(i, i + 7));
                  }

                  function formattedDate(date) {
                    return moment(date, "DD-MM-YYYY HH:mm:ss").format("Do MMM");
                  }

                  let currentDate = moment().format("DD-MM-YYYY");
                  let graphValues = {
                    title: stairStepperData?.[0]?.eventName,
                    eventId: stairStepperData?.[0]?.eventId,
                    weekRange: [],
                    currentWeek: 0,
                  };

                  for (let i = 0; i < weekWise?.length; i++) {
                    let weekData =
                      weekWise[i]?.length > 3
                        ? weekWise[i]
                        : weekWise[i - 1]?.concat(weekWise[i]) || [];

                    let startDate =
                      weekData?.length > 0 ? weekData[0]?.startDateLocal : null;
                    let endDate =
                      weekData?.length > 0
                        ? weekData[weekData?.length - 1]?.startDateLocal
                        : null;

                    if (weekData !== weekWise[i]) {
                      graphValues.weekRange[
                        graphValues.weekRange.length - 1
                      ] = `${formattedDate(startDate)}-${formattedDate(
                        endDate
                      )}`;

                      graphValues[`week${i}`] = weekData;
                    } else {
                      graphValues.weekRange.push(
                        startDate && endDate
                          ? `${formattedDate(startDate)}-${formattedDate(
                              endDate
                            )}`
                          : null
                      );
                      graphValues[`week${i + 1}`] = weekData;

                      let calculatedDates = graphValues[`week${i + 1}`].filter(
                        (item, index, array) => {
                          if (
                            moment(item.startDateLocal, "DD-MM-YYYY HH:mm:ss")
                              .format("DD-MM-YYYY")
                              .includes(currentDate)
                          ) {
                            graphValues.currentWeek = i;

                            return `week${i}`;
                          }
                        }
                      );
                    }
                  }

                  return graphValues;
                });

                setVerticalBarGraphData(graphData[0]);
                const { runnerActivityDetails, yAxis, xAxis } = graphData[0];
                const stepsChallengeGraphData = prepareIndividualValues({
                  runnerActivityDetails,
                  xAxis,
                  yAxis,
                  registration: {},
                  result: { totalSteps: 0 },
                  diff,
                  event: {},
                  startD,
                  endD,
                });
                setIndividualValues(stepsChallengeGraphData?.values || {});
                setDateRangeOptions(stepsChallengeGraphData?.list || []);
              } else {
                setIndividualValues(null);
              }

              if (cooperTestResult && cooperTestResult?.length) {
                setCooperTest(cooperTestResult);
                prepareCooperTestDataForParticipant({ cooperTestResult });
              } else {
                setCooperTest(null);
              }
              prepareKnowledgeSessionData({ programSessionResultDTOs });
              setLoading(false);
            }
          })
          .catch(() => {
            setLoading(false);
          });
      }
    } else if (programDetails) {
      getProgramParticipantDashboardData(runnerId, programDetails?.id)
        .then((res) => {
          if (res?.data) {
            setProgramChallenges(res.data?.programChallenges);
            if (res?.data?.programSessionResultDTOs) {
              prepareKnowledgeSessionData({
                programSessionResultDTOs: res?.data?.programSessionResultDTOs,
              });
            }

            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }

  function prepareIndividualValues({
    runnerActivityDetails,
    xAxis,
    yAxis,
    registration,
    result,
    diff,
    event,
    startD,
    totalSteps,
  }) {
    let datesArray = [];
    for (let index = 0; index < diff; index++) {
      let date = moment(startD).add(index, "day").format("DD-MM-YYYY");
      datesArray.push({
        startDateLocal: date,
        averagePace: 0,
        averageSpeed: 0,
      });
    }

    if (runnerActivityDetails !== undefined) {
      for (let j = 0; j < datesArray?.length; j++) {
        for (let i = 0; i < runnerActivityDetails?.length; i++) {
          if (runnerActivityDetails[i].startDateLocal) {
            let d = runnerActivityDetails[i].startDateLocal.split(" ");
            if (datesArray[j].startDateLocal === d[0]) {
              datesArray[j] = runnerActivityDetails[i];
              datesArray[j].distance = runnerActivityDetails[i].distance;
              datesArray[j].wellnessPoints =
                runnerActivityDetails[i].wellnessPoints;
              datesArray[j].completionTime =
                runnerActivityDetails[i].completionTime; //.split(":");
            }
          }
        }
      }
    }
    let labelName = "";
    let dateList = [];
    let dataY2 = [];
    let dateList1 = [];
    let tooltipData = [];
    let legendLabel = "";

    if (xAxis) {
      if (xAxis == "startDateLocal") {
        labelName = "date";
        dateList = datesArray.map(function (e) {
          let d = e.startDateLocal.split("-");
          d = d[0] + "-" + d[1];
          return d; //e.startDateLocal;
        });
      } else if (xAxis === "wellnessPoint") {
        labelName = "WellnessPoint";
        dateList = datesArray.map(function (e) {
          return e.wellnessPoints;
        });
      } else {
        labelName = xAxis;
        dateList = datesArray.map(function (e) {
          let d = e[xAxis];
          return d;
        });
      }
    } else {
      labelName = "date";
      dateList = datesArray.map(function (e) {
        let d = e.startDateLocal.split("-");
        d = d[1] + "-" + d[0];
        return d; //e.startDateLocal;
      });
    }
    if (xAxis) {
      if (xAxis == "startDateLocal") {
        labelName = "date";
        dateList1 = datesArray.map(function (e) {
          let d = e.startDateLocal.split("-");
          d = d[1] + "-" + d[0];
          return d; //e.startDateLocal;
        });
      } else if (xAxis === "wellnessPoint") {
        labelName = "WellnessPoint";
        dateList1 = datesArray.map(function (e) {
          return e.wellnessPoints;
        });
      } else {
        labelName = xAxis;
        dateList1 = datesArray.map(function (e) {
          let d = e[xAxis];
          return d;
        });
      }
    } else {
      labelName = "date";
      dateList1 = datesArray.map(function (e) {
        let d = e.startDateLocal.split("-");
        d = d[1] + "-" + d[0];
        return d; //e.startDateLocal;
      });
    }
    if (yAxis) {
      if (yAxis == "distance") {
        dataY2 = datesArray.map(function (e) {
          let dis = e.distance;
          if (dis % 1000 === 0) {
            return parseFloat(dis / 1000).toFixed(0);
          } else {
            return parseFloat(dis / 1000).toFixed(2);
          }
        });
        legendLabel = Properties.Distance_label + " (Km)";
      } else if (yAxis === "wellnessPoint") {
        dataY2 = datesArray.map(function (e) {
          return e.wellnessPoints;
        });
        legendLabel = Properties.Label_WellnessPoints;
      } else if (yAxis === "steps") {
        dataY2 = datesArray.map(function (e) {
          return e.steps;
        });
        legendLabel = Properties.Label_Steps;
      } else {
        dataY2 = datesArray.map(function (e) {
          return e[yAxis];
        });

        legendLabel = yAxis?.charAt(0).toUpperCase() + yAxis.slice(1);
      }
    } else {
      dataY2 = datesArray.map(function (e) {
        return parseFloat(e.distance / 1000).toFixed(2);
      });
      legendLabel = Properties.Distance_label + " (Km)";
    }
    tooltipData = datesArray.map(function (e) {
      return e[yAxis];
    });

    let list = [];

    if (dateList?.length >= 10 && runnerActivityDetails?.length > 0) {
      for (let i = 0; i < dateList.length; i += 6) {
        if (
          dateList?.length > 6 &&
          dateList?.length >= i + 6 &&
          dateList?.length - i >= 11
        ) {
          list.push({
            displayDate:
              moment(dateList1[i], "MM-DD").format("Do MMM") +
              "-" +
              moment(dateList1[i + 6], "MM-DD").format("Do MMM"),
            from: dateList1[i],
            to: dateList1[i + 6],
            value: {
              trackX:
                moment(dateList1[i], "MM-DD").format("Do MMM") +
                "-" +
                moment(dateList1[i + 6], "MM-DD").format("Do MMM"),
              trackingEstimationByDate: dateHandleChange(dateList),
              selectedTrackingDates: dateHandleChange(dataY2),
              selectedTooltip: dateHandleChange(tooltipData),
            },
          });
          i++;
        } else {
          list.push({
            displayDate:
              moment(dateList1[i], "MM-DD").format("Do MMM") +
              "-" +
              moment(dateList1[dateList1?.length - 1], "MM-DD").format(
                "Do MMM"
              ),
            from: dateList1[i],
            to: dateList1[dateList1?.length - 1],
            value: {
              trackX:
                moment(dateList1[i], "MM-DD").format("Do MMM") +
                "-" +
                moment(dateList1[dateList1?.length - 1], "MM-DD").format(
                  "Do MMM"
                ),
              trackingEstimationByDate: dateHandleChange(dateList),
              selectedTrackingDates: dateHandleChange(dataY2),
              selectedTooltip: dateHandleChange(tooltipData),
            },
          });
          i = dateList?.length;
        }
      }
    } else {
      list.push({
        value: {
          trackingEstimationByDate: dateHandleChange(dateList),
          selectedTrackingDates: dateHandleChange(dataY2),
          selectedTooltip: dateHandleChange(tooltipData),
        },
      });
    }
    let calculateCompletedTarget;
    let totalProgressTarget;
    let selectedCategoryValue;
    if (registration && result) {
      totalProgressTarget =
        yAxis === "distance" && registration?.totalTarget % 1000 === 0
          ? parseFloat(registration?.totalTarget / 1000).toFixed(0)
          : yAxis === "distance" && registration?.totalTarget % 1000 !== 0
          ? parseFloat(registration?.totalTarget / 1000).toFixed(2)
          : registration?.totalTarget;
      selectedCategoryValue =
        yAxis === "steps"
          ? result?.totalSteps
          : yAxis === "calories"
          ? result?.totalCalories
          : yAxis === "elevation"
          ? result?.totalElevationGain
          : yAxis === "wellnessPoint"
          ? result?.wellnessDetails?.totalWellnessPoint
          : result?.totalActivityDistance % 1000 === 0
          ? parseFloat(result?.totalActivityDistance / 1000).toFixed(0)
          : parseFloat(result?.totalActivityDistance / 1000).toFixed(2);

      calculateCompletedTarget = (
        (Number(selectedCategoryValue) / Number(totalProgressTarget)) *
        100
      ).toFixed(2);
    }
    let values = {
      showGraph: true,
      eventName: event?.name || "",
      showTable: true,
      eventType: event?.type || "CHALLENGE",
      individualGraphRunnerActivities: runnerActivityDetails,
      graphToShow: event?.graphToShow,
      graphYAxisIndi: yAxis,
      graphXAxisIndi: xAxis,
      individualGraphData: datesArray,
      dateRangeOptions: list,
      dateListState: dateList,
      dateListY2State: dataY2,
      registration: registration,
      totalTarget: registration?.totalTarget,
      challengeGoal: registration?.eventRunCategories[0]?.challengeGoal,
      result: result,
      completedProgressIndividual: calculateCompletedTarget,
      targetedProgressIndividual: totalProgressTarget,
      isProgressMaxThanTargetIndividual:
        calculateCompletedTarget > 100 ? true : false,
      completedProgressActivityIndividual: selectedCategoryValue,
    };
    return { list, values };
  }

  function prepareKnowledgeSessionData({ programSessionResultDTOs }) {
    let labels = [];
    let totalSessionData = [];
    let attendedSessionData = [];
    if (
      programSessionResultDTOs &&
      programSessionResultDTOs[0] &&
      Object.keys(programSessionResultDTOs[0]).length > 0
    ) {
      programSessionResultDTOs.forEach((session) => {
        if (Object.keys(session).length > 0) {
          const name =
            session?.sessionType?.charAt(0).toUpperCase() +
            session?.sessionType?.slice(1).toLowerCase();
          labels.push(name?.replaceAll("_", " "));
        }
      });

      setKnowledgeSessions(programSessionResultDTOs);
      programSessionResultDTOs.forEach((session) => {
        if (Object.keys(session).length > 0) {
          totalSessionData.push(session?.totalProgramSession);
          attendedSessionData.push(session?.totalAttendedProgramSession);
        }
      });
      let datasets = [
        {
          label: "Total Session",
          data: totalSessionData,
          backgroundColor: "#9396E3",
          barThickness: 4,
        },
        {
          label: "Attended Session",
          data: attendedSessionData,
          backgroundColor: "#FE7235",
          barThickness: 10,
        },
      ];
      setKnowledgeSessionData({ labels, datasets });
    }
  }

  function prepareCooperTestDataForParticipant({ cooperTestResult }) {
    let graphData = [];

    let runnerData = ApplicationUtil.getRunnerDetails();
    const fullScore = ApplicationUtil.getCooperTestFullScore({
      gender: runnerData?.gender,
      age: runnerData?.age,
    });
    if (cooperTestResult && cooperTestResult.length > 0) {
      cooperTestResult.forEach((test) => {
        graphData.push({
          labels: ["My Score", ""],
          datasets: [
            {
              label: "My Score",
              data: [
                Number(
                  parseFloat(test?.cooperTestCurrentScore || 0).toFixed(2)
                ),
                (100 - parseFloat(test?.cooperTestCurrentScore || 0)).toFixed(
                  2
                ),
              ],
              backgroundColor: ["#4472c4", "#f29e66"],
              borderWidth: 3,
            },
          ],
          activityDate: test?.activityDate,
          eventStartDate: test?.eventStartDate,
          cooperTestRemark: test?.cooperTestRemark,
          cooperTestCurrentScore: test?.cooperTestCurrentScore,
        });
      });
    } else {
      graphData = [
        {
          labels: ["My Score", ""],
          datasets: [
            {
              label: "My Score",
              data: [0, 100],
              backgroundColor: ["#4472c4", "#f29e66"],
              borderWidth: 3,
            },
          ],
        },
        {
          labels: ["My Score", ""],
          datasets: [
            {
              label: "My Score",
              data: [0, 100],
              backgroundColor: ["#4472c4", "#f29e66"],
              borderWidth: 3,
            },
          ],
        },
      ];
    }

    if (graphData.length === 1) {
      graphData.push({
        labels: ["My Score", ""],
        datasets: [
          {
            label: "My Score",
            data: [0, 100],
            backgroundColor: ["#4472c4", "#f29e66"],
            borderWidth: 3,
          },
        ],
      });
    }

    setCooperTestData(graphData);
  }

  function dateHandleChange(data) {
    if (!data || data?.length === 0) return;
    let subDates = [];
    for (let i = 0; i < data.length; i += 7) {
      let sub = data.slice(i, i + 7);
      if (
        subDates.length > 0 &&
        subDates[subDates.length - 1].length + sub.length <= 10
      ) {
        subDates[subDates.length - 1] =
          subDates[subDates.length - 1].concat(sub);
      } else {
        subDates.push(sub);
      }
    }

    return subDates;
  }

  const isChallengesNotHaveQues = () => {
    let temp = programChallenges?.filter(
      (challenge) => challenge?.questionnaires
    );

    if (!temp?.length) {
      return true;
    }
  };
  let isDataNotPresent =
    !knowledgeSessionData &&
    !cooperTest &&
    !individualValues &&
    !Object.keys(stepperGraphData || {}).length > 0 &&
    !stepperGraphData?.weekRange?.length &&
    isChallengesNotHaveQues();

  return (
    <div>
      <AppToolBar>
        <Header label={programDetails?.programName || programDetails?.name} />
        <div style={{ width: 35 }} />
      </AppToolBar>

      <div className={classes.mainContainer} style={{ marginTop: "60px" }}>
        <div className={classes.breadcrumbs_container}>
          <div className="breadcrumbs">
            <RunnerSideBreadcrumbs />
          </div>

          {programDetails?.programDashboardLogo?.url && (
            <img
              className="dashboard_logo"
              src={`${httpService.API_URL()}${
                programDetails?.programDashboardLogo?.url
              }`}
              alt="program-logo"
              height="60"
            />
          )}
        </div>
        <div className={classes.titleContainer}>
          <h2 className={classes.dashBoardTitle}>
            {Properties.program_dashboard.title.personal}
          </h2>
        </div>
        {cooperTest && (
          <CooperTest cooperTest={cooperTest} cooperTestData={cooperTestData} />
        )}
        {stepperGraphData &&
          stepperGraphData?.weekRange?.length > 0 &&
          Object.keys(stepperGraphData).length > 0 &&
          moment(moment().format("YYYY-MM-DD")).isSameOrAfter(
            stepperGraphData?.eventStartDate
          ) && (
            <StairStepperGraphComponent stepperGraphData={stepperGraphData} />
          )}
        {individualValues && (
          <StepsChallengeComponent
            individualValues={individualValues}
            verticalBarGraphData={verticalBarGraphData}
            dateHandleChange={dateHandleChange}
            dateRangeOptions={dateRangeOptions}
          />
        )}

        {programChallenges?.length > 0 &&
          programChallenges
            ?.filter((challenge) => challenge?.questionnaires)
            ?.map((challenge) => {
              return (
                <div key={challenge?.id}>
                  <NoSugarChallengeTable challenge={challenge} hideLegends />
                </div>
              );
            })}
        {knowledgeSessionData &&
          Object.keys(knowledgeSessionData).length > 0 && (
            <KnowledgeSessionComponent
              knowledgeSessionData={knowledgeSessionData}
              knowledgeSessions={knowledgeSessions}
            />
          )}

        {isDataNotPresent && (
          <div
            style={{
              margin: "0 auto",
              textAlign: "center",
              marginTop: "10%",
            }}
          >
            {!loading && (
              <Typography style={{ fontSize: "28px", fontWeight: "bold" }}>
                No data found
              </Typography>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const styles = (theme) => ({
  mainContainer: {
    fontFamily: '"Karla", sans-serif',
    margin: "5px",
    marginInline: "20px",
    [theme.breakpoints.down("sm")]: { marginInline: "5px" },
  },
  dashBoardTitle: {
    letterSpacing: "-1px",
    fontWeight: "bold",
    padding: 0,
    fontSize: "40px",
    marginTop: 0,
    marginBottom: "0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  chartTitle: {
    letterSpacing: "-1px",
    marginBottom: 0,
    color: "#202260",
    fontWeight: "bold",
    fontSize: "35px",
    marginLeft: "10px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      marginLeft: "0px",
    },
  },
  titleContainer: {
    // border: "1px solid",
    color: "#202260",
    borderColor: "#d1d1d1",
    textAlign: "center",
  },
  datesContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginInline: "10px",
    "& .date2": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    "& .scoreTitle": {
      marginTop: "5px",
      fontWeight: "600",
      fontSize: "26px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
  },
  doughnutChartContainer: {
    width: "100%",
    display: "flex",

    marginTop: "5px",
    justifyContent: "space-between",

    "& .doughnutItem": {
      // border: "1px solid",
      position: "relative",
      width: "100%",
      paddingBottom: "20px",
      borderColor: "#b3caf6",
      "& .cooperDate2": {
        display: "none",

        [theme.breakpoints.down("sm")]: {
          display: "block",
          fontSize: "18px",
          marginTop: "5px",
          marginLeft: "10px",
          fontWeight: "600",
        },
      },

      "& .doughnutLabel": {
        position: "absolute",
        left: "131px",
        top: "91px",
        width: "50px",
        fontSize: "small",
        fontWeight: "bold",
        [theme.breakpoints.down("sm")]: {
          left: "131px",
          top: "91px",
          width: "50px",
          fontSize: "small",
        },
      },
      "& .doughnutContainer": {
        margin: "0 auto",
        height: "200px",
        width: "200px",
        [theme.breakpoints.down("sm")]: {
          height: "150px",
          width: "150px",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },

    "& .cooperLegendBlock": {
      display: "flex",
      justifyContent: "end",
      marginRight: "44px",
      gap: "10px",
      alignItems: "center",
      "& .legendBlock": {
        width: "40px",
        height: "18px",
        backgroundColor: "#FE7235",
        [theme.breakpoints.down("sm")]: {
          width: "30px",
          height: "12px",
        },
      },
      "& .legendBlockTitle": {
        fontSize: "20px",
        fontWeight: "500",
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
          fontWeight: "500",
        },
      },
    },
  },
  stepsChallengeContainer: {
    marginTop: "20px",
  },
  individualGraphContainer: {
    marginTop: "20px",
  },
  horizontalBarContainer: {
    height: "30vh",
    width: "auto",
    display: "flex",
    gap: "20px",
    width: "100%",
    justifyContent: "center",

    // position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "20vh",
      width: "auto",
    },
  },
  knowledgeSessionTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    "& .knowledgeSessionTitle": {
      marginBottom: 0,
      color: "#202260",
      fontWeight: "bold",
      fontSize: "35px",
      marginLeft: "10px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
        marginLeft: "0px",
      },
    },
    "& .legendBlock": {
      width: "40px",
      height: "18px",
      backgroundColor: "#FE7235",
      [theme.breakpoints.down("sm")]: {
        width: "30px",
        height: "12px",
      },
    },
    "& .legendBlockTitle": {
      fontSize: "20px",
      fontWeight: "500",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        fontWeight: "500",
      },
    },
    "& .attendanceContainer": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      marginRight: "120px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "0px",
      },
    },
  },
  sessionIndicatorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    gap: "32px",

    "& .sessionText": {
      fontSize: "20px",
      fontWeight: "bold",
      padding: 0,
      margin: 0,
      [theme.breakpoints.down(1400)]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
      },
    },
  },
  stepsChallengeProgressContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "baseline",
    gap: "80px",
    [theme.breakpoints.down("sm")]: {
      gap: "20px",
      flexDirection: "column",
    },
    "& .progressBarContainer": {
      width: "60%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  totalDetailsProg: {
    color: "black",
    fontWeight: "bold",
    fontSize: "28px",
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "nowrap",
      fontSize: "20px",
      height: "20%",
      justifyContent: "flex-start",
      margin: "0",
    },
    "& .stepsTitle": {
      color: "black",
      fontWeight: "bolder",
      fontSize: "26px",
      margin: "0",
      // marginLeft: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
  },
  breadcrumbs_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    "& .breadcrumbs": {
      [theme.breakpoints.down("sm")]: {
        alignSelf: "start",
      },
    },
    "& .dashboard_logo": {
      [theme.breakpoints.down("sm")]: {
        alignSelf: "end",
      },
    },
  },
  knowledgeSessionContainer: {
    "& .legends": {
      display: "flex",
      gap: "20px",
      marginRight: "6%",
      justifyContent: "end",
      alignItems: "center",
      fontSize: "22px",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        gap: "10px",
        fontSize: "14px",
        marginRight: "0",
      },
      "& .attended_session": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        "& .legend_block": {
          height: "18px",
          width: "45px",
          backgroundColor: "#FE7235",
          [theme.breakpoints.down("sm")]: {
            height: "11px",
            width: "37px",
          },
        },
      },
      "& .total_session": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        "& .legend_block": {
          height: "10px",
          width: "45px",
          backgroundColor: "#9396E3",
          [theme.breakpoints.down("sm")]: {
            height: "5px",
            width: "37px",
          },
        },
      },
    },
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ParticipantProgramDashboard);
