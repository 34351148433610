import React from "react";
import { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import {
  Box,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { ArrowDropDownCircleOutlined } from "@material-ui/icons";
import Properties from "../../../../../properties/Properties";
import Snackbar from "../../../../common/Snackbar";
import DialogBox from "../../../../common/DialogBox";
import { deleteChallengeActivity } from "../../../../../apis/challengeService";
import useScrollBlock from "../../../../../hooks/useScrollBlock";
function ChallengeActivityList({
  classes,
  data,
  setIsProgramChallenge,
  programChallengeType,
  isPastChallenge,  isOpenSubscriptionProgram
}) {
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkboxArr, setCheckboxArr] = useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [challengeResponseId, setChallengeResponseId] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});

  /**
   * Vishal- Defect_Id_689
   * reusable hook for disabled the page scroll behaviour while open and close the the anchorEl menu
   * call allowScroll() at the time of component unmount
   */
  const [blockScroll, allowScroll] = useScrollBlock();

  const groupedData = data?.reduce((acc, curr) => {
    const viewDate = curr?.questionnaireDto?.viewDate;
    if (!acc[viewDate]) {
      acc[viewDate] = [];
    }
    acc[viewDate].push(curr);
    return acc;
  }, {});

  const formattedData = Object.entries(groupedData).map(([viewDate, data]) => ({
    viewDate,
    data,
  }));

  const handleRowToggle = (challengeResponseId) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [challengeResponseId]: !prevState[challengeResponseId],
    }));
  };

  const closeWindow = () => {
    setShowCancelDialog(false);
    setAnchorEl(null);
    setChallengeResponseId(null);
  };

  const handleActivityDelete = () => {
    let challengeResponseIds = [];
    if (menuAnchorEl) {
      checkboxArr.forEach((e) => {
        if (e.check) {
          challengeResponseIds.push(e.id);
        } else {
          return;
        }
      });
    } else {
      challengeResponseIds = [challengeResponseId];
    }

    deleteChallengeActivity(challengeResponseIds)
      .then((res) => {
        setIsProgramChallenge(true);
        setMenuAnchorEl(null);
        setIsAllChecked(false);
        setCheckboxArr([]);
        closeWindow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isSelected = (id) => {
    let i = checkboxArr.findIndex((x) => x.id === id);
    if (i !== -1) {
      return checkboxArr[i].check;
    }
    return false;
  };

  const handleSelectAll = (e) => {
    let val = e.target.checked;
    let arr = val
      ? data?.map((challenge) => ({
          id: challenge?.challengeResponseId,
          check: true,
        }))
      : [];

    setCheckboxArr(arr);
    setIsAllChecked(val);
  };

  const handleSelect = (e, row) => {
    let arr = [...checkboxArr];
    let i = arr.findIndex((x) => x.id === row?.challengeResponseId);
    if (i !== -1) {
      arr[i].id = row?.challengeResponseId;
      arr[i].check = e.target.checked;
      setCheckboxArr(arr);
    } else {
      setCheckboxArr([
        ...checkboxArr,
        {
          check: e.target.checked,
          id: row.challengeResponseId,
        },
      ]);
    }
    setIsAllChecked(false);
  };

  const getDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.DeleteActivityWarning}</span>
        </Typography>
      </div>
    );
  };

  useEffect(() => {
    if (anchorEl) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [anchorEl]);
  useEffect(() => {
    return () => {
      //Allow page scroll whenever component unmount
      allowScroll();
    };
  }, []);
  return (
    <div className={classes.main}>
      <FormControl className={classes.formControlAction}>
        <div className={classes.actions}>
          <span>Actions</span>
          <span>
            <ArrowDropDownCircleOutlined
              className={classes.editIcon}
              onClick={(event) => {
                setMenuAnchorEl(event.currentTarget);
              }}
            />
          </span>
        </div>
      </FormControl>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {!isPastChallenge && (
                <TableCell component="th">
                  <Checkbox
                    checked={isAllChecked}
                    onChange={handleSelectAll}
                    color="primary"
                  />
                  <IconButton className={classes.hideArrowDropdown}>
                    <ArrowDropDownCircleOutlined className={classes.editIcon} />
                  </IconButton>
                </TableCell>
              )}
              <TableCell component="th">View Date</TableCell>
              <TableCell component="th" align="center">
                Name
              </TableCell>
              <TableCell component="th">Sustain</TableCell>
              <TableCell component="th">Response</TableCell>
              {programChallengeType && programChallengeType === "HYDRATION" && (
                <TableCell component="th">Hydration Status</TableCell>
              )}
              <TableCell component="th">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formattedData.map((group, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell component="td" align="left" colSpan={7}>
                    {moment(group?.viewDate, "DD-MM-YYYY").format("DD-MM-YYYY")}
                  </TableCell>
                </TableRow>
                {group?.data?.map((row) => (
                  <React.Fragment key={row?.challengeResponseId}>
                    <TableRow className={classes.tableRow}>
                      {!isPastChallenge && (
                        <TableCell
                          component="td"
                          align="center"
                          style={{ height: "50px" }}
                        >
                          <Checkbox
                            checked={
                              isAllChecked
                                ? isAllChecked
                                : isSelected(row?.challengeResponseId)
                            }
                            onChange={(e) => handleSelect(e, row)}
                            color="primary"
                          />
                          <IconButton>
                            <ArrowDropDownCircleOutlined
                              className={classes.editIcon}
                              onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setChallengeResponseId(
                                  row?.challengeResponseId
                                );
                              }}
                            />
                          </IconButton>
                        </TableCell>
                      )}
                      <TableCell component="td" align="left">
                        {moment(
                          row?.questionnaireDto?.viewDate,
                          "DD-MM-YYYY"
                        ).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell component="td" align="left">
                        {row?.runnerName}
                      </TableCell>
                      <TableCell component="td" align="center">
                        {row?.questionnaireDto?.responseStatus ===
                        "Positive" ? (
                          <img
                            src="https://upload.wikimedia.org/wikipedia/commons/8/8b/Eo_circle_green_white_checkmark.svg"
                            style={{ width: "30px", height: "30px" }}
                          />
                        ) : (
                          <img
                            src="https://upload.wikimedia.org/wikipedia/commons/2/28/White_X_in_red_background.svg"
                            style={{ width: "30px", height: "30px" }}
                          />
                        )}
                      </TableCell>
                      <TableCell component="td" align="left">
                        {row?.questionnaireDto?.response}
                      </TableCell>

                      {row &&
                        row?.questionnaireDto?.programChallengeType ===
                          "HYDRATION" && (
                          <TableCell component="td" align="left">
                            {row?.questionnaireDto?.responseStatus ===
                            "Positive"
                              ? "Hydrated"
                              : "Unhydrated"}
                          </TableCell>
                        )}

                      <TableCell component="td" align="right">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() =>
                            handleRowToggle(row?.challengeResponseId)
                          }
                        >
                          {expandedRows[row?.challengeResponseId] ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell style={{ maxWidth: "300px" }} colSpan={7}>
                        <Collapse
                          in={expandedRows[row?.challengeResponseId]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {programChallengeType &&
                          programChallengeType === "HYDRATION" ? (
                            <Grid container>
                              <Grid
                                item
                                xs={8}
                                className={classes.collapseItem}
                              >
                                <span className={classes.titleCss}>
                                  Activity:
                                </span>
                                <span className={classes.infoCssForHydration}>
                                  {row?.lifeStyle?.replace("_", " ") || "-"}
                                </span>
                              </Grid>
                              <Grid
                                item
                                xs={8}
                                className={classes.collapseItem}
                              >
                                <span className={classes.titleCss}>
                                  Climate:
                                </span>
                                <span className={classes.infoCssForHydration}>
                                  {row?.currentClimate || "-"}
                                </span>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container>
                              <Grid
                                item
                                xs={8}
                                className={classes.collapseItem}
                              >
                                <span className={classes.titleCss}>
                                  Response:
                                </span>
                                <span className={classes.infoCss}>
                                  {row?.questionnaireDto?.childQuestionnaireDto?.response
                                    ?.replace("Any other;", "")
                                    ?.replace("$$", "-") || "No Response"}
                                </span>
                              </Grid>
                            </Grid>
                          )}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* global menu */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{ marginTop: "45px", position: "absolute", zIndex: 0 }}
      >
        <MenuItem
          onClick={() => {
            let challengeIds = [];
            checkboxArr.forEach((e) => {
              if (e.check) {
                challengeIds.push(e.challengeResponseId);
              } else {
                return;
              }
            });
            if (challengeIds.length > 0) {
              setShowCancelDialog(true);
            } else {
              Snackbar.show({
                variant: "error",
                isAutoHide: true,
                preventDuplicate: true,
                message: Properties.SelectActivityToDeleteLabel,
              });
            }
          }}
        >
          {Properties.DeleteActivityLabel}
        </MenuItem>
      </Menu>
      {/* row wise menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{ marginTop: "40px" }}
        // style={{ marginTop: "45px", position: "absolute", zIndex: 0 }}
      >
        <MenuItem
          onClick={() => {
            setShowCancelDialog(true);
          }}
        >
          {Properties.DeleteActivityLabel}
        </MenuItem>
      </Menu>
      {/* delete activity warning */}
      <DialogBox
        signup={true}
        open={showCancelDialog}
        message={getDialogMsg()}
        // header={dialogHeader}
        label={"No"}
        isSmall={true}
        onClick={() => {
          setShowCancelDialog(false);
        }}
        labelTwo={"Yes"}
        onClickTwo={() => {
          handleActivityDelete();
        }}
        closeDisloag={() => {
          setShowCancelDialog(false);
        }}
      />
    </div>
  );
}

const styles = (theme) => ({
  main: {
    padding: "10px",
    margin: "10px",
  },
  editIcon: {
    color: "#09456C",
    cursor: "pointer",
    marginLeft: "1px",
  },
  formControlAction: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  actions: {
    display: "flex",
    alignItems: "center",
  },
  hideArrowDropdown: {
    visibility: "hidden",
  },
  tableHead: {
    backgroundColor: "#ced4da",
    "& th": {
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
    },
  },
  tableRow: {
    "& td": {
      fontWeight: "bold",
      fontFamily: "Rubik, sans-serif",
    },
    "& > *": {
      borderBottom: "unset",
    },
  },
  collapseItem: {
    // padding:10,
    display: "flex",
    // alignItems: "center",
    alignItems: "start",
  },
  titleCss: {
    // color: "rgb(114, 114, 114)",
    color: "rgba(0, 0, 0, 0.87);",
    // color:'black',
    fontSize: "16px",
    fontWeight: 600,
  },
  infoCss: {
    color: "rgb(9, 69, 108)",
    fontSize: "18px",
    padding: "0px 0px 0px 5px",
    fontWeight: "bolder",
  },
  infoCssForHydration: {
    color: "rgb(9, 69, 108)",
    fontSize: "16px",
    padding: "0px 0px 0px 5px",
    fontWeight: "bolder",
  },
  textValue: {
    textTransform: "capitalize",
  },
});

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(ChallengeActivityList);
