import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";

function FullCalenderComponent({
  handleDateSelect,
  renderEventContent,
  handleEventClick,
  handleEvents,
  calenderRef,
  events,
  handleCalenderViewDate,
  handleEventHover,
  handleEventMouseLeave,
  handleEventMount,
  views,
}) {
  return (
    <FullCalendar
      ref={calenderRef}
      plugins={[
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        multiMonthPlugin,
      ]}
      initialView={"weekProgram"}
      height={window.innerHeight - 130}
      editable={true}
      selectable={true}
      selectMirror={true}
      dayMaxEvents={2}
      weekends={true}
      eventOrder={[]}
      dayMaxEventRows
      datesSet={handleCalenderViewDate}
      headerToolbar={{
        start: "prev,next",
        center: "title",
        end: "weekProgram,monthProgram,wholeProgram",
        // right:''
      }}
      views={views}
      themeSystem="standard"
      // initialEvents={INITIAL_EVENTS}
      events={events}
      dragScroll={false}
      droppable={false}
      select={handleDateSelect}
      // eventContent={renderEventContent} // custom render function
      eventClick={handleEventClick}
      eventChange={() => console.log("on event chnage")}
      eventsSet={handleEvents} // called after events are initialized/added/changed/removed
      eventDidMount={handleEventMount}
      // eventMouseEnter={handleEventHover}
      // eventMouseLeave={handleEventMouseLeave}
    />
  );
}
const labels = {
  distance: "Distance",
  steps: "Steps",
  completionTime: "Duration",
  target: "Target",
  achieved: "Achieved",
};
export default FullCalenderComponent;
