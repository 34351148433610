/*
 should have 2 div separately, 
First one for center items 
Second one for last, ie. right alight items.
*/

import React from "react";
import AppBar from "@material-ui/core/AppBar";
import compose from "recompose/compose";
//import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import { BackArrowSVGIcon } from "./SvgIcon";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import getUserDetails from "../../apis/userService";
import ApplicationUtil from "../../utils/ApplicationUtil";
//const img_path = "";
const styles = (theme) => ({
  grow: {
    //flexGrow: 1
    //display: "flex",
    //justifyContent: "center"
  },
  appBar: {
    color: "#F79824",
    background: "#3a6ea5",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)",
    zIndex: 100,
    position: "fixed",
  },

  btnSize: {
    width: "23px",
    height: "23px",
  },
});

class AppToolBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hideMobileHeader: false,
    };
  }

  componentDidMount() {
    if (ApplicationUtil.ReactNative()) {
      const userData = ApplicationUtil.getUserDetails();
      const userId = userData?.id;
      const token = userData?.token;
      getUserDetails(token, userId).then((res) => {
        let data = res.data.user;
        ApplicationUtil.ReactNative(
          `{"userName":"${data.firstName} ${data.lastName}","profileImage":"${res?.data?.profilePicture?.imageData}"}`
        );
      });
      this.setState({ hideMobileHeader: true });
    }
  }
  render() {
    const { classes, children, menu, openMenu, className } = this.props;

    return (
      <React.Fragment>
        {!this.state.hideMobileHeader && (
          <AppBar
            position="sticky"
            className={className ? className : classes.appBar}
          >
            {/* <Toolbar> */}

            {/* {showBack && ( */}

            {/*</span><IconButton
                color="inherit"
                onClick={e => backNavigate()}
                style={{ paddingLeft: 0 }}
              >
                <img
                  src={img_path + "assets/svg-icons/icon_back_arrow_blue.svg"}
                  className={classes.btnSize}
                  alt="BackBtn"
                />
              </IconButton>
              */}
            {/* )} */}

            {/* <div className={classes.grow} /> */}
            {children}

            {/* {children[0] ? children[0] : ""} */}
            {/* <div className={classes.grow} /> */}
            {/* {children[1] ? children[1] : ""} */}
            {/* </Toolbar> */}

            {/* <Divider style={{ height: "5px", backgroundColor: "#08456C" }} /> */}
          </AppBar>
        )}
      </React.Fragment>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(AppToolBar);
