import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import SummaryTotalCount from "./SummaryTotalCount";
import SummaryCharts from "./SummaryCharts";

import { getEventDetails } from "./../../../../../apis/eventService";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  summary: {},
});
class OrganizerSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventResData: null,
    };
  }
  componentDidMount() {
    let eventId = ApplicationUtil.getSupportsEventID();
    let requestparam = "";
    getEventDetails(requestparam, eventId).then((res) => {
      this.setState({ eventResData: res.data });
    });
  }

  render() {
    const { classes } = this.props;
    const { eventResData } = this.state;
    return (
      <div className={classes.summary}>
        {/* summary total cards */}
        {eventResData && <SummaryTotalCount data={eventResData} />}
        {/* summary dhoughnut charts */}
        {eventResData && <SummaryCharts data={eventResData} />}
      </div>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }),withRouter)(
  OrganizerSummary
);
