import http from "./httpService";
import { loading } from "../components/common/Spinner";
import Snackbar from "../components/common/Snackbar";
import Properties from "../properties/Properties";

const API_URL_FILE = http.API_URL();

export default function editUserProfile(requestParams, token, userId) {
  loading(true);
  // let token = localStorage.getItem("token");
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  // let userId = localStorage.getItem("userId");

  let url = API_URL_FILE + "api/v1/user/" + userId;

  return new Promise(function (resolve, reject) {
    http
      .put(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          if (res?.data?.success?.code === "200") {
            Snackbar.show({
              variant: "success",
              message: res.data.success.verbose,
            });
            resolve(res);
          } else if (res?.data?.success?.code === "409") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.success?.verbose
                ? res?.data?.success?.verbose
                : Properties.Something_went_wrong,
            });
            reject(res);
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
            reject(res);
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error?.response?.data?.error?.code === "400") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
        console.log(error.response);
      });
  });
}

export function updateProfilePicture(requestParams, token, userId) {
  loading(true);
  // let token = localStorage.getItem("token");
  // let userId = localStorage.getItem("userId");
  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/user/profilePicture/upload/" + userId;
  var formData = new FormData();

  formData.append("profilePicture", requestParams.file);

  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        loading(false);
        if (res.status === 200) {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Photo added successfully.",
          });
          resolve(res);
        } else {
          console.log("API failed");
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
