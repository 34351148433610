import React from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SubmitCancelBtn from "./SubmitCancelBtn";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
const styles = (theme) => ({});

class ImageDialogBox extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    open: this.props.open,
  };

  closeSuccessDialog = () => {
    this.setState({ open: false });
    this.props.closeDisloag();
  };

  render() {
    const {
      classes,
      open,
      message,
      closeSuccessDialog,
      header,
      imagePath,
      onClick,
      title,
      fullWidth,
      maxWidth
    } = this.props;
    return (
      <Dialog
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={() => this.closeSuccessDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            color: "#08456C",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            //  width:"65%",
            //  margin:"auto",
            //  marginLeft:"35%",
            //  border:"1px solid black"
          }}
        >
          <span
            style={{
              textAlign: "center",
              fontWeight: 600,
              fontSize: "20px",
              paddingLeft: "10px",
              flex: 1,
            }}
          >
            {title ? title : "Screenshot View"}
          </span>
          <IconButton>
            <CancelIcon
              onClick={(e) =>
                onClick ? onClick(e) : this.closeSuccessDialog()
              }
            />
          </IconButton>
        </div>

        <DialogContent>
          <img
            style={{ width: "100%", objectFit: "contain" }}
            src={imagePath}
          />
        </DialogContent>
        {/* <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <SubmitCancelBtn
            style={{ width: "40%" }}
            //onClick={() => this.closeSuccessDialog()}
            onClick={e => (onClick ? onClick(e) : this.closeSuccessDialog())}
            label={"OK"}
            type="submit"
          />
        </DialogActions> */}
      </Dialog>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(ImageDialogBox);
