import React, { useState, useEffect } from "react";
import OrganizerCommonHeader from "./OrganizerCommonHeader";
import { Grid, Typography, withStyles } from "@material-ui/core";
import { CustomTextField } from "../../../common/CustomTextField";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Properties from "../../../../properties/Properties";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { IconCalendarBlue } from "../../../common/SvgIcon";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import Snackbar from "../../../common/Snackbar";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import {
  addProgramSession,
  registerProgramSession,
  getProgramSessionType,
} from "../../../../apis/eventService";
import moment from "moment";
import Breadcrumbs from "../../../common/Breadcrumbs";
import MultiSelect from "../../../common/MultiSelect";

function OrganizerProgramSessionForm({ classes, history }) {
  const [textFieldValues, setTextFieldValues] = useState({
    sessionName: null,
    startDate: null,
    endDate: null,
    facultyName: null,
    meetingLink: null,
    startDateError: false,
    endDateError: false,
  });

  const [programSessionType, setProgramSessionType] = useState({});
  const [sessionTypeOptions, setSessionTypeOptions] = useState([]);

  let programDetails = ApplicationUtil.getSupportsProgramDetails();
  useEffect(() => {
    getProgramSessionType(programDetails?.id)
      .then(({ data }) => {
        const options = data?.listOfProgramSessionType.map((item, index) => ({
          label: item,
          value: item,
        }));
        setSessionTypeOptions(options);
      })
      .catch((error) => {
        console.error("Error fetching session type options:", error);
      });
  }, []);

  const [errorField, setErrorField] = useState(false);
  const onSubmit = () => {
    // let userDetails = ApplicationUtil.getRunnerDetails();
    // const API_URL_FILE = httpService.API_URL();
    const today = new Date();
    const _isStartDateInvalid =
      textFieldValues?.startDate &&
      moment(textFieldValues?.startDate, "DD-MM-YYYY hh:mm:ss").isBefore(
        moment(today, "DD-MM-YYYY hh:mm:ss")
      );
    const _isEndDateInvalid =
      textFieldValues?.startDate &&
      textFieldValues?.endDate &&
      moment(textFieldValues?.endDate, "DD-MM-YYYY hh:mm:ss").isBefore(
        moment(textFieldValues?.startDate, "DD-MM-YYYY hh:mm:ss")
      );
    const Optionkeys = Object.keys(programSessionType || {});
    let allFieldValues = { ...textFieldValues };
    let isFieldNotProvided =
      !textFieldValues?.sessionName ||
      !textFieldValues?.startDate ||
      !textFieldValues?.endDate ||
      !textFieldValues?.facultyName ||
      !Optionkeys.length;

    if (isFieldNotProvided) {
      setTextFieldValues({
        ...textFieldValues,
        startDateError: !textFieldValues?.startDate ? true : false,
        endDateError: !textFieldValues?.endDate ? true : false,
      });
      setErrorField(true);
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: `Please provide required fields`,
      });
    } else if (_isStartDateInvalid && _isEndDateInvalid) {
      setErrorField(true);
      setTextFieldValues({
        ...textFieldValues,
        startDateError: true,
        endDateError: true,
      });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Session start and date should not be past date !!",
      });
    } else if (_isStartDateInvalid) {
      setErrorField(true);
      setTextFieldValues({ ...textFieldValues, startDateError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Session start date should not be past date !!",
      });
    } else if (_isEndDateInvalid) {
      setErrorField(true);
      setTextFieldValues({ ...textFieldValues, endDateError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Session end date should not be past date !!",
      });
    } else {
      setErrorField(false);
      let params = {
        programId: programDetails?.id,
        //   userId: userDetails?.id,
        name: allFieldValues?.sessionName,
        sessionStartDate: moment(allFieldValues?.startDate).format(
          "DD-MM-YYYY HH:mm:ss"
        ),
        sessionEndDate: moment(allFieldValues?.endDate).format(
          "DD-MM-YYYY HH:mm:ss"
        ),
        facultyName: allFieldValues?.facultyName,
        meetingLink: allFieldValues?.meetingLink,
        programSessionType: programSessionType.value,
      };

      addProgramSession(params).then(({ data }) => {
        if (data?.success?.code === "200") {
          setTextFieldValues({
            sessionName: "",
            startDate: null,
            endDate: null,
            facultyName: "",
            meetingLink: "",
            startDateError: false,
            endDateError: false,
          });
          setProgramSessionType({});
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: data?.success?.verbose,
          });
        }
      });
    }
  };

  const onTextHandleChange = (event) => {
    setTextFieldValues({
      ...textFieldValues,
      [event.target.name]: event.target.value,
    });
  };
  const onDateHandleChange = (date, name) => {
    if (name === "startDate") {
      setTextFieldValues({
        ...textFieldValues,
        [name]: date,
        startDateError: false,
      });
    } else if (name === "endDate") {
      setTextFieldValues({
        ...textFieldValues,
        [name]: date,
        endDateError: false,
      });
    }
  };
  const onOptionValueChange = (selectedOptions) => {
    setProgramSessionType(selectedOptions);
  };
  return (
    <div>
      <OrganizerCommonHeader
        title={programDetails?.name || "Create Session"}
        logo
      />
      <div className={classes.main}>
        <Breadcrumbs />
        <div className="mainContainer">
          <Grid container xs={12}>
            <TextFieldContainer
              label={Properties.Label_SessionName}
              value={textFieldValues.sessionName}
              required
              handleChange={onTextHandleChange}
              style={classes.textFieldContainer}
              type="text"
              name={"sessionName"}
              error={errorField && !textFieldValues.sessionName}
              xs={12}
            />
          </Grid>
          <Grid container xs={12}>
            <DateFieldContainer
              label={Properties.Label_StartDate}
              value={textFieldValues.startDate}
              required
              handleChange={onDateHandleChange}
              style={classes.textFieldContainer}
              type="text"
              name={"startDate"}
              error={errorField && textFieldValues.startDateError}
              disablePast={true}
            />
            <DateFieldContainer
              label={Properties.Label_EndDate}
              value={textFieldValues.endDate}
              required
              handleChange={onDateHandleChange}
              style={classes.textFieldContainer}
              type="text"
              name={"endDate"}
              error={errorField && textFieldValues.endDateError}
              disablePast={true}
            />
          </Grid>
          <Grid container xs={12}>
            <TextFieldContainer
              label={Properties.Label_FacultyName}
              value={textFieldValues.facultyName}
              required
              handleChange={onTextHandleChange}
              style={classes.textFieldContainer}
              type="text"
              name={"facultyName"}
              error={errorField && !textFieldValues.facultyName}
            />

            <TextFieldContainer
              label={Properties.Label_MeetingLink}
              value={textFieldValues.meetingLink}
              handleChange={onTextHandleChange}
              style={classes.textFieldContainer}
              type="text"
              name={"meetingLink"}
            />
          </Grid>
          {sessionTypeOptions && (
            <Grid container xs={12}>
              <MultiSelectContainer
                label={Properties.Label_SessionType}
                required
                option={sessionTypeOptions}
                value={programSessionType}
                style={classes.multiSelectFieldContainer}
                handleChange={onOptionValueChange}
                error={
                  errorField && Object.keys(programSessionType).length === 0
                }
              />
            </Grid>
          )}

          <Grid container xs={12} style={{ marginTop: "10px" }}>
            <Grid item className="btnItem" xs={12}>
              <SubmitCancelBtn
                onClick={onSubmit}
                //   isDisabled={!termsCheckboxSelected}
                label={Properties.CreateSessionLabel}
                type="submit"
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
const styles = (theme) => ({
  main: {
    marginTop: "60px",

    "& .mainContainer": {
      width: "55%",
      margin: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      //    "& .MuiGrid-container":{
      //     display:'flex',
      //     alignItems:'center',
      //     justifyContent:'center'
      //    },
      "& .btnItem": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .MuiGrid-item": {
        // flex:0.5,
        padding: "10px",
      },
    },
  },
  textFieldContainer: {
    "& .MuiOutlinedInput-input": {
      padding: "6.5px 13px",
    },
  },
  multiSelectFieldContainer: {
    "& .multi_select": {
      "& .css-9pj6i7-control": {
        border: "1px solid red",
      },
    },
  },
});
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
  //   connect(mapStateToProps, {
  //     updateDrawersEventList,
  //     updateUserDetails,
  //   })
)(OrganizerProgramSessionForm);

const MultiSelectContainer = ({
  label,
  required,
  option,
  disabled = false,
  value,
  handleChange,
  style,
  type,
  name,
  error,
  xs = 12,
}) => {
  return (
    <Grid item className={style} xs={xs}>
      <Typography variant="body2" gutterBottom>
        <span style={{ color: "#727272" }}>{label}</span>
        {required && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <MultiSelect
        options={option}
        error={error}
        onChange={handleChange}
        value={value}
      />
      {error && (
        <Typography variant="body2" color="error">
          Please select an option
        </Typography>
      )}
    </Grid>
  );
};

const TextFieldContainer = ({
  label,
  required,
  disabled = false,
  value,
  handleChange,
  style,
  type,
  name,
  error,
  xs = 6,
}) => {
  return (
    <Grid item className={style} xs={xs}>
      <Typography variant="body2" gutterBottom>
        <span style={{ color: "#727272" }}>{label}</span>
        {required && <span style={{ color: "red" }}>*</span>}
      </Typography>

      <CustomTextField
        style={{
          border: error ? "1px solid red" : "1px solid #08456C",
          width: "100%",
        }}
        name={name}
        readOnly={disabled}
        type={type === "number" ? type : "text"}
        keyToSet={label}
        value={value}
        handleChange={(prop) => (event) => {
          handleChange(event);
        }}
      />
    </Grid>
  );
};
const DateFieldContainer = ({
  label,
  required,
  disabled = false,
  disablePast,
  value,
  handleChange,
  style,
  type,
  name,
  error,
}) => {
  return (
    <Grid item className={style} xs={6}>
      <Typography variant="body2" gutterBottom>
        <span style={{ color: "#727272" }}>{label}</span>
        {required && <span style={{ color: "red" }}>*</span>}
      </Typography>

      <KeyboardDateTimePicker
        style={{
          border: error ? "1px solid red" : "1px solid #08456C",
          width: "100%",
          borderRadius: "5px",
        }}
        autoOk
        clearable
        variant="inline"
        inputVariant="outlined"
        // ampm={false}
        format="dd/MM/yyyy hh:mm a" //:ss-hh" //"dd/MM/yyyy"
        // disableFuture={true}
        disablePast={!!disablePast}
        value={value}
        // maxDate={localEndDate ? localEndDate : undefined}
        // maxDateMessage={Properties.RegistrationEndDateValidationMsg}
        FormHelperTextProps={{
          style: {
            margin: "0px",
          },
        }}
        InputProps={{
          style: {
            paddingRight: 0,
          },
        }}
        keyboardIcon={
          <IconCalendarBlue className={{ height: 22, width: 22 }} />
        }
        onChange={(date) => {
          handleChange(date, name);
        }}
      />
    </Grid>
  );
};
