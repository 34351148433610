/*global google*/

import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import GoogleMap from "../../../../../common/GoogleMap/GoogleMap";
import MenuIcon from "@material-ui/icons/Menu";
import StarIcon from "../../../../../../images/star.gif";
import StartIcon from "../../../../../../images/start.jpg";
import markerIcon from "../../../../../../images/marker.gif";
import markerImgIcon from "../../../../../../images/marker.jpg";
import { IconCalendarBlue, markerPointer } from "../../../../../common/SvgIcon";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({});
class RunnerMap extends Component {
  state = {
    FullScreen: true,
  };
  componentDidMount() {
    this.setState({
      flightPlanCoordinates: this.props.flightPlanCoordinates,
      categoryDTO: this.props.categoryDTO,
      features: this.props.features,
      defaultCenter: this.props.defaultCenter,
      zoomLevel: this.props.zoomLevel,
      mapToShow:this.props.mapToShow,
      activity: this.props.activity,
    });
  }
  componentDidUpdate(previousProps) {
    if (
      this.props.flightPlanCoordinates !==
        previousProps.flightPlanCoordinates ||
      this.props.categoryDTO !== previousProps.categoryDTO ||
      this.props.features !== previousProps.features ||
      this.props.defaultCenter !== previousProps.defaultCenter ||
      this.props.zoomLevel !== previousProps.zoomLevel ||
      this.props.activity != previousProps.activity ||
      this.props.mapToShow != previousProps.mapToShow

    ) {
      this.setState({
        flightPlanCoordinates: this.props.flightPlanCoordinates,
        categoryDTO: this.props.categoryDTO,
        features: this.props.features,
        defaultCenter: this.props.defaultCenter,
        zoomLevel: this.props.zoomLevel,
        activity: this.props.activity,
        mapToShow:this.props.mapToShow,
      });
    }
  }
  apiIsLoaded = (map, maps) => {
    const { flightPlanCoordinates, features, activity, categoryDTO, mapToShow } =
      this.state;

    const flightPath = new google.maps.Polyline({
      path: flightPlanCoordinates,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    let marker;
    if (flightPlanCoordinates && flightPlanCoordinates.length > 0) {
      const firstPoint = {
        lat: flightPlanCoordinates[0].lat,
        lng: flightPlanCoordinates[0].lng,
      };
      const image = {
        url: StarIcon,
      };
      new google.maps.Marker({
        position: firstPoint,
        icon: StartIcon, //image,
        map: map,
        title: "start",
        size: 1,
      });
      let allPoints = [
        flightPlanCoordinates[0],
        flightPlanCoordinates[flightPlanCoordinates.length - 1],
      ];
      var infoWindow = new google.maps.InfoWindow();
      for (var i = 1; i < allPoints?.length; i++) {
      

        var latLng = {
          lat: allPoints[i]?.lat,
          lng: allPoints[i]?.lng,
        };
        let color;

        var data = null;
        let feat = activity;

        if (feat) {
          data = {
            totalDistance: feat.totalActivityDistance, //feat.totalActivityDistance,
            participants: features[0].runner?.name, //feat.activityList[0]?.name,
          };
        }
        if (data.totalDistance === undefined) {
          data.totalDistance = 0;
        }
        color = categoryDTO?.mapIconColor ? categoryDTO?.mapIconColor : "red";

        marker = new google.maps.Marker({
          position: latLng, //{ lat: allPoints[i].lat, lng: allPoints[i].lng },
          icon: ApplicationUtil.pinSymbol(color), // i === allPoints.length - 1 ? image : StartIcon,
          map: map,
          size: 1,
        });
        (function (marker, data) {
          google.maps.event.addListener(marker, "click", function (e) {
            infoWindow.setContent(
              "<div style='max-height:160px'>" +
                "<div style = 'margin-bottom:4px;  font-size: 16px; font-weight: 500; color: black; '>" +
                `<div style = 'font-size: 16px; font-weight: 500; color: black;text-transform:capitalize'> ${
                  data?.participants &&
                  // data.participants.map((e) =>
                  `<div>${data?.participants}</div>`
                  // ).join("")
                }</div>` +
                `${
                  data?.totalDistance == 0
                    ? "<div></div>"
                    : "<div><div style=' font-size: 14px;font-weight: 500;color: #727272;'>Distance Covered</div>" +
                      "<div style = 'font-size: 16px; font-weight: 500; color: black;text-transform:capitalize'>" +
                      parseFloat(Number(data?.totalDistance) / 1000).toFixed(
                        2
                      ) +
                      " Km" +
                      "</div>" +
                      "<div>" +
                      "</div>"
                }`
            );
            infoWindow.open(map, marker);
          });
        })(marker, data);
      }
    } else {
      let color;
      let allPoints = features;
      var infoWindow = new google.maps.InfoWindow();
      for (var i = 0; i < allPoints.length; i++) {
        let totalDistance = 0;
        let participants = [];

        let points = allPoints[i].currentPosition
          ? JSON.parse(allPoints[i].currentPosition)
          : null;

        var latLng = {};
        if (points && points.lat) {
          latLng = {
            //new google.maps.LatLng(
            lat: points.lat, // allPoints[i]?.coOrdinatePoint?.lat,
            lng: points.lng, //allPoints[i]?.coOrdinatePoint?.lng,
          };
        }
        // );
        var data = null;
        let feat = allPoints[i]; //?.runnerActivityDTO;
        // ? allPoints[i]?.runnerActivityDTO
        // : null;

        if (feat) {
          if(mapToShow === "TRACKER"){

            data = {
             recordedBy:feat.recordedBy,
              lastScannedTime:feat.lastScannedTime,
              participants: feat.name 
            };
          }
          
          if(mapToShow === "DEFAULT" || mapToShow === "RELAY"){
            data = {
              totalDistance:
               
                feat.runnerActivityDTO?.totalActivityDistance
                  ? feat.runnerActivityDTO.totalActivityDistance
                  : 0, 
              
              participants:feat.runner.name, //feat.activityList[0]?.name,
            };
          }
         
        }
        if (data.totalDistance === undefined) {
          data.totalDistance = 0;
        }
        const image = {
          url: ApplicationUtil.pinSymbol(color),
          anchor: new google.maps.Point(14, 25),
        };

        marker = new google.maps.Marker({
          position: latLng, //{ lat: allPoints[i].lat, lng: allPoints[i].lng },
          icon: color
            ? ApplicationUtil.pinSymbol(color)
            : ApplicationUtil.pinSymbol("red"),
          //   i === allPoints.length - 1 ? image : markerImgIcon,
          anchor: new google.maps.Point(14, 25),
          map: map,
          size: 1,
        });
        (function (marker, data) {
          google.maps.event.addListener(marker, "click", function (e) {
            infoWindow.setContent(
              "<div style='max-height:160px'>" +
                "<div style = 'margin-bottom:4px;  font-size: 16px; font-weight: 500; color: black; '>" +
                `<div style = 'font-size: 16px; font-weight: 500; color: black;text-transform:capitalize'> ${
                  data?.participants && `<div>${data?.participants}</div>`
                }</div>` +
                `${ mapToShow === "TRACKER" ? 
                "<div><div style=' font-size: 14px;font-weight: 500;color: #727272;'>Last Scanned Time</div>" +
                    "<div style = 'font-size: 16px; font-weight: 500; color: black;text-transform:capitalize'>" +
                    data?.lastScannedTime +
                   
                    "</div>" +
                    "<div style=' font-size: 14px;font-weight: 500;color: #727272;'> Scanned By</div>" +
                      "<div style = 'font-size: 16px; font-weight: 500; color: black;text-transform:capitalize'>" +
                      data?.recordedBy +
                      "</div>" +
                    "</div>" 
                   
                    :
                    data?.totalDistance == 0
                  ? "<div></div>"
                  : "<div><div style=' font-size: 14px;font-weight: 500;color: #727272;'>Distance Covered</div>" +
                    "<div style = 'font-size: 16px; font-weight: 500; color: black;text-transform:capitalize'>" +
                    parseFloat(Number(data?.totalDistance) / 1000).toFixed(2) +
                    " Km" +
                    "</div>" +
                    "<div>" +
                    "</div>"
  
  
              }`
            );
            infoWindow.open(map, marker);
          });
        })(marker, data);
      }
    }

    flightPath.setMap(map);
  };

  render() {
    const { FullScreen } = this.state;
    const { flightPlanCoordinates, defaultCenter, features, zoomLevel } =
      this.state;
    const featuresData = features ? features[0] : null;
    const startPoint = featuresData?.startPoint
      ? JSON.parse(featuresData.startPoint)
      : defaultCenter;
    return (
      <Grid container>
        <Grid
          item
          style={{ width: "100%", position: "relative", margin: "0px 4px" }}
        >
          <div>
            <GoogleMap
              defaultCenter={startPoint}
              defaultZoom={zoomLevel}
              apiIsLoaded={this.apiIsLoaded}
              markers={features}
              handleItemClick={(item) => {}}
              flightPlanCoordinates={flightPlanCoordinates}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}
export default compose(withStyles(styles, { withTheme: true }),withRouter)(RunnerMap);
