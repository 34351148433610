import React, { Component } from "react";
import { Link, Redirect, Route, Router, Switch } from "react-router-dom";
import compose from "recompose/compose";
import RegistrationContainer from "./components/views/registration/RegistrationContainer";
import RegisterEvent from "./components/views/registration/RegisterEvent";
import EventDetails from "./components/views/registration/events/EventDetails";
import WellnessPoints from "./components/views/registration/events/WellnessPoints";
import LoginContainer from "./components/views/login/LoginContainer";
import ForgotpassContainer from "./components/views/login/ForgotpassContainer";
import ResetpassContainer from "./components/views/login/ResetpassContainer";
import UploadRunContainer from "./components/views/registration/UploadRunContainer";
import ProfileContainer from "./components/views/dashboard/ProfileContainer";
import SupportPanel from "./components/views/support/SupportPanel";
import EventListView from "./components/views/registration/events/EventListView";
import CompleteEventListView from "./components/views/registration/events/CompleteEventListView";
import EditProfileContainer from "./components/views/dashboard/EditProfilecontainer";
import PostRegistrationContainer from "./components/views/registration/PostRegistrationContainer";
import InviteListContainer from "./components/views/registration/invites/InviteListContainer";
import InvitationFormContainer from "./components/views/registration/invites/InvitationFormContainer";
import EditProfilePictureContainer from "./components/views/dashboard/EditProfilePictureContainer";
import CompleteEventDetailsView from "./components/views/registration/events/CompleteEventDetailsView/CompleteEventDetailsView";
import DataPermissionsContainer from "./components/views/dashboard/DataPermissionsContainer";
import ViewResults from "./components/views/registration/events/viewResults/ViewResults";
import OrganizerPanel from "./components/views/support/organizer/OrganizerPanel";
import "./App.css";
import ChallengeDetails from "./components/views/registration/challenges/ChallengeDetails";
import ChallengeRegistration from "./components/views/registration/challenges/ChallengeRegistration";
import NotFound from "./components/views/NotFound";
import { getDecodedUrl } from "./apis/eventService";
import ApplicationUtil from "./utils/ApplicationUtil";
import Spinner from "./components/common/Spinner";
import Snackbar from "./components/common/Snackbar";
import Summary from "./components/views/registration/summary/Summary";
import ReferralForm from "./components/views/registration/refer/ReferralForm";
import FeedbackList from "./components/views/registration/Feedback/FeedbackList";
import TrainingPlan from "./components/views/registration/Training/TrainingPlan";

import VideoPlayerContainer from "./components/views/videoplayer/VideoPlayerContainer";
import { createBrowserHistory, createHashHistory } from "history";
import { history } from "./History";
import EventActivityDetails from "./components/views/registration/events/eventdetails/EventActivityDetails";

import HomeScreen from "./components/views/HomeScreen";
import LiveMap from "./components/views/liveMap";
import EventVenue from "./components/views/registration/events/CompleteEventDetailsView/detailscards/EventVenue";
import CalenderHomeView from "./components/views/calenderHome/CalenderHomeView";
import RegisterProgram from "./components/views/registration/program/RegisterProgram";
import ProgramResults from "./components/views/registration/events/viewResults/ProgramResults";
import ProgramDashboard from "./components/views/program/ProgramDashboard";
import RecordResponse from "./components/views/program/recordResponseView/RecordResponse";
import FrameCreation from "./components/common/FrameCreation";
import UserCreatedChallengeRegistration from "./components/views/program/openChallengeSubscription/UserCreatedChallengeRegistration";

class App extends Component {
  state = {
    token: null,
  };
  componentDidMount() {
    let encodedUrl = window.location.pathname;
    // if (encodedUrl.includes("/#/")[1]){
    // encodedUrl = encodedUrl.split("/")[1];
    // }
    //  else {
    //   encodedUrl = encodedUrl.split("/")[3].replace("/", "");
    // }
    let data = ApplicationUtil.getRunnerDetails();

    let token;
    if (data?.token) {
      token = data.token;
      this.setState({ token: token });
    }

    if (encodedUrl && encodedUrl.startsWith("/Z")) {
      // if (token) {

      getDecodedUrl(encodedUrl).then((res) => {
        if (res.data.fullUrl) {
          if (res.data.fullUrl.includes("#")) {
            let newUrl = ApplicationUtil.getRedirectedRoute(res.data.fullUrl);

            if (token) {
              ApplicationUtil.go(`${newUrl}`);
            } else if (
              newUrl === "/summary" ||
              newUrl === false ||
              newUrl === "/consent"
            ) {
              if (newUrl) {
                ApplicationUtil.go(`${newUrl}`);
              } else {
                ApplicationUtil.routetoLoginWithUrl(`${encodedUrl}`);
              }
            } else {
              ApplicationUtil.routetoLoginWithUrl(`${encodedUrl}`);
            }
          } else {
            ApplicationUtil.go(`${res.data.fullUrl}`);
          }
        }
      });
      // } else {
      //   ApplicationUtil.routetoLoginWithUrl(`${encodedUrl}`);
      // }
    }
    // else{
    //   ApplicationUtil.go("/"+encodedUrl)

    // }
  }
  render() {
    const { token } = this.state;
    return (
      <React.Fragment>
        <Snackbar />
        <Spinner />
        <main>
          <Router
            basename="/"
            history={history}
            //{ApplicationUtil.getHistoryObject()}//{customHistory}
          >
            <Switch>
              <Route exact path="/signup" component={RegistrationContainer} />
              <Route
                exact
                path="/eventDetails"
                component={EventDetails}
                key={"event-details"}
              />
              <Route
                exact
                path="/eventRegistration"
                component={RegisterEvent}
                key={"event-registration"}
              />
              <Route
                exact
                path="/programRegistration"
                component={RegisterProgram}
              />
              <Route exact path="/event" component={CompleteEventDetailsView} />
              <Route
                exact
                path="/programDashboard"
                component={ProgramDashboard}
              />
              <Route
                exact
                path="/program"
                component={CompleteEventDetailsView}
              />
              <Route
                exact
                path="/createChallenge&register"
                component={UserCreatedChallengeRegistration}
              />
              <Route exact path="/eventList" component={EventListView} />
              <Route exact path="/calender" component={CalenderHomeView} />
              <Route
                exact
                path="/completeEventList"
                component={CompleteEventListView}
              />
              <Route exact path="/videoGallery" component={EventVenue} />

              <Route exact path="/frameCreation" component={FrameCreation} />
              <Route
                exact
                path="/login"
                // render={(props) =>
                //   token ? (
                //     <Redirect to="/" {...props} />
                //   ) : (
                //     <LoginContainer {...props} />
                //   )
                // }
                component={LoginContainer}
              />

              <Route exact path="/forgotpass" component={ForgotpassContainer} />
              <Route exact path="/resetpass" component={ResetpassContainer} />
              <Route exact path="/uploadRun" component={UploadRunContainer} />
              <Route exact path="/profile" component={ProfileContainer} />
              <Route
                exact
                path="/editProfile"
                component={EditProfileContainer}
              />
              <Route
                exact
                path="/editProfilePicture"
                component={EditProfilePictureContainer}
              />
              <Route
                exact
                path="/consent"
                component={DataPermissionsContainer}
              />
              <Route exact path="/record-response" component={RecordResponse} />
              <Route
                exact
                path="/postRegister"
                component={PostRegistrationContainer}
              />
              <Route
                exact
                path="/invitationList"
                component={InviteListContainer}
              />
              <Route
                exact
                path="/invitationForm"
                component={InvitationFormContainer}
              />
              <Route exact path="/refer" component={ReferralForm} />
              <Route exact path="/feedback" component={FeedbackList} />
              <Route exact path="/training" component={TrainingPlan} />

              <Route path="/support" component={SupportPanel} />
              <Route path="/organizer" component={OrganizerPanel} />
              <Route
                path="/results"
                // component={ViewResults}
                render={(props) => <ViewResults {...props} />}
              />
              <Route
                path="/programResults"
                // component={ViewResults}
                render={(props) => <ProgramResults {...props} />}
              />
              <Route path="/wellnessDetails" component={WellnessPoints} />
              <Route path="/challenge" component={ChallengeDetails} />
              <Route
                path="/challengeRegistration"
                component={ChallengeRegistration}
              />
              <Route path="/summary" component={Summary} />

              <Route path="/player" component={VideoPlayerContainer} />
              <Route
                exact
                path="/"
                component={HomeScreen}

                // component={CompleteEventListView}
              />

              <Route
                exact
                path="/activitydetailsview"
                component={EventActivityDetails}
              />
              <Route
                exact
                path="/low2highmap"
                render={(props) => <LiveMap {...props} />}
              />
              <Route component={NotFound} />
              {/* <Route
                path="/shareEventForm"
                component={ShareEventFormContainer}
              /> */}
            </Switch>
          </Router>
        </main>
      </React.Fragment>
    );
  }
}
export default App;
// compose(withRouter)(App);
