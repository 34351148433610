import React, { Component } from "react";
import { useHistory, withRouter } from "react-router-dom";
import compose from "recompose/compose";

import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { NoLeaderboard } from "../../../../common/SvgIcon";
import { TabPanel } from "@material-ui/lab";
import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Properties from "../../../../../properties/Properties";
import { isMobile } from "react-device-detect";
import {
  csk_grap_chart,
  extreame_east_grap_chart,
  _Furious_grap_chart,
  mpcg_tigers_grap_chart,
  north_royals_grap_chart,
} from "../../../../../images/Analytics";

const styles = (theme) => ({
  textTransformCss: {
    textTransform: "none",
    fontSize: "22px",
    fontWeight: "bold",
  },

  accordionMain: { border: "1px solid rgba(0, 0, 0, .125)" },
  noLeaderboard: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "100px",
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  expanded: {},
  content: {
    "&$expanded": {
      marginBottom: 0,
    },
  },
  root: {
    ".MuiAccordionSummary-content": {
      margin: "0px",
    },
  },
});

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {
    backgroundColor: "#e9ecef",
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    // borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
    "&$expanded": {
      backgroundColor: "rgba(0, 0, 0, .03)",
    },
  },
  content: {
    // "&$expanded": {
    //   margin: "12px 0",
    // },
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: "white",
  },
}))(MuiAccordionDetails);
class TeamAnalytics extends Component {
  constructor(props) {
    super(props);

    this.state = { ActiveTabIndex: 0, teams: [] };
  }

  componentDidMount() {
    const { history } = this.props;
    let data = [
      { label: "Chennai Super Kings", src: csk_grap_chart },
      { label: "Fast and Furious", src: _Furious_grap_chart },
      { label: "MPCG Tigers", src: mpcg_tigers_grap_chart },
      { label: "North Royals", src: north_royals_grap_chart },
      { label: "Extreme East", src: extreame_east_grap_chart },
    ];
    this.setState({
      teams: data,
    });
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      ActiveTabIndex: newValue,
    });
  };
  render() {
    const { classes } = this.props;
    const { ActiveTabIndex, teams } = this.state;

    return (
      <div style={{ marginTop: "15px" }} className={classes.root}>
        {teams.map((team, i) => (
          <Accordion
            square
            defaultExpanded={i === 0 ? true : false}
            className={classes.accordionMain}
          >
            <AccordionSummary
              // classes={{ content: classes.content, expanded: classes.expanded }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: window.innerWidth > 600 ? "23px" : "20px",
                  fontWeight: 600,
                }}
              >
                {team.label}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.root}>
              <div style={{ margin: "0 auto" }}>
                <img src={team.src} style={{ width: "100%" }} />
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(TeamAnalytics);
