import React, { Component } from "react";
import { useHistory, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";

import { NoLeaderboard } from "../../../../common/SvgIcon";
import AnalyticsView from "./AnalyticsView";
import { getEventDetails } from "../../../../../apis/eventService";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
const styles = (theme) => ({
  viewresults: {
    // overflow: "hidden",
    height: "100vh",
  },

  noLeaderboard: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "100px",
  },
});

class OrganizerAnalytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventId: null,
      eventName: "",
      eventResData: null,
    };
  }

  componentDidMount() {
    const { history } = this.props;

    let supportEvent = ApplicationUtil.getSupportsEventsDetails();
    let eventId = supportEvent?.id;
    let requestparam = "REGISTER_EVENT";
    let eventName = supportEvent?.name;
    getEventDetails(requestparam, eventId).then((res) => {
      this.setState({ eventResData: res.data });
    });
    document.title = `Analytics - ${eventName}`;
  }
  render() {
    const { classes } = this.props;
    const { eventName, eventId, eventResData } = this.state;

    return (
      <div
        style={{
          height: eventResData?.showAnalytics ? "100vh" : "",
        }}
      >
        {eventResData?.showAnalytics ? (
          <AnalyticsView />
        ) : (
          <div className={classes.noLeaderboard}>
            <NoLeaderboard style={{ width: "200px", height: "200px" }} />
            <span
              style={{
                fontSize: "18px",
                fontWeight: 600,

                color: "gray",
              }}
            >
              No Analytics Available.
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(OrganizerAnalytics);
