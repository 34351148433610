import { Box, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import StepperBarGraph from "../../../common/Graphs/StepperBarGraph";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

function StairStepperGraphComponent({ history, classes, stepperGraphData }) {
  const [scrollToGraphViewStair, setScrollToGraphViewStair] = useState();
  const [selectWeek, setSelectedWeek] = useState(null); // This should be week number

  const handleValueChange = (e) => {
    setSelectedWeek(parseInt(e.target.value) + 1);
    setScrollToGraphViewStair(e.target.value);
  };
  useEffect(() => {
    if (scrollToGraphViewStair) {
      const setgraphview = document.getElementById("stairChallengeRef");
      if (setgraphview) {
        setgraphview.scrollIntoView({
          block: "end",
          inline: "end",
          behavior: "instant",
        });
      }
    }
  }, [scrollToGraphViewStair]);
  return (
    <Box py={4} id="stairChallengeRef">
      <h2 className={classes.chartTitle}>{stepperGraphData?.title} :</h2>
      <StepperBarGraph
        setSelectedWeek={setSelectedWeek}
        selectWeek={selectWeek}
        stepperGraphData={stepperGraphData}
        selectedWeekData={
          selectWeek
            ? stepperGraphData?.[`week ${selectWeek}`]
            : stepperGraphData?.[`week ${stepperGraphData?.firstWeek}`]
        }
        stairStepperValueChange={handleValueChange}
      />
    </Box>
  );
}

const styles = (theme) => ({
  chartTitle: {
    letterSpacing: "-1px",
    marginBottom: 0,
    color: "#202260",
    fontWeight: "bold",
    fontSize: "35px",
    marginLeft: "10px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      marginLeft: "0px",
    },
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(StairStepperGraphComponent);
