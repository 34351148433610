import React from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { Button, MobileStepper, Popover, Popper } from "@material-ui/core";
import Cancel from "@material-ui/icons/Cancel";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
const styles = (theme) => ({
  popperDisablePortal: {
    position: "relative",
  },
  mobileStepper: { padding: "4px 8px" },

  ".MuiPopover": {
    paper: {
      maxHeight: 0,
      overflowY: "hidden",
    },
  },
});

class PopupView extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    activeStep: 0,
    files: null,
  };
  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };
  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  render() {
    const {
      classes,
      theme,
      popupOpen,
      popupMsg,
      popupHeader,
      popupOnClick,
      popupClose,
    } = this.props;
    const { activeStep } = this.state;
    const maxSteps = 0; //this.props.files?.length;

    return (
      <Popover
        // classes={classes}
        open={popupOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClick={popupOnClick}
        closeDisloag={popupClose}
        PaperProps={{
          style: { maxHeight: "97%", paddingBottom: "10px" },
        }}
      >
        <div
          style={{
            color: "#08456C",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <span
            style={{
              textAlign: "center",
              fontWeight: 600,
              fontSize: "20px",
              flex: 1,
              textTransform: "capitalize",
            }}
          >
            {popupHeader}
          </span>
          <span>
            <Cancel
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => popupClose}
            />
          </span>
        </div>
        <div style={{ padding: "0px 8px" }}>
          <div>{popupMsg}</div>
        </div>
      </Popover>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }),withRouter)(PopupView);
