import { Grid, withStyles } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Properties from "../../../../properties/Properties";
import AppToolBar from "../../../common/AppToolBar";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import Header from "../../../common/Header";
import { getTrainingPlan } from "../../../../apis/eventService";
import Clamp from "react-multiline-clamp";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import parse from "html-react-parser";
import { VideoPlayerIcon } from "../../../common/SvgIcon";
import MessageIcon from "@material-ui/icons/Message";
import RunnerSideBreadcrumbs from "../../../common/RunnerSideBreadcrumbs";
const styles = (theme) => ({
  mainPanel: {
    marginTop: "65px",
    marginLeft: "6.7%",
    marginRight: "6.7%",
    marginBottom: "10px",
  },
  titleCss: {
    fontSize: "20px",
    fontWeight: 600,
    paddingBottom: "4px",
    color: "#F79824",
  },
  tableRowCss: {
    border: "1px lightgray solid",
    padding: "6px 12px",
  },
  dateCss: {
    fontWeight: 600,
    paddingBottom: "2px",
  },
  trainingDetails: {
    // backgroundColor: "#ff99007a",
    border: "2px solid #ff99007a",
    borderRadius: "8px",
    padding: "4px 12px",
    marginBottom: "4px",
  },
  trainingDetailsPast: {
    backgroundColor: "#d3d3d34f",
    border: "2px solid #d3d3d34f",
    borderRadius: "8px",
    padding: "4px 12px",
    marginBottom: "4px",
  },
  trainingDetailsTitle: {
    fontWeight: 600,
    paddingBottom: "4px",
  },
  trainingListCss: {
    margin: 0,
    paddingInlineStart: "20px",
    listStyleType: "square",
    "& li": {
      marginTop: "7px",
    },
    "& .button": {
      color: "blue",
      cursor: "pointer",
    },
  },
  playerIcon: {
    padding: "0px 2px",
    "&:hover": {
      transform: "scale(1.3)", //padding:"0px 4px"
    },
  },
  icon: {
    color: "#2883b5",
    cursor: "pointer",
  },
  upperLayout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
class TrainingPlan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      loading: false,
    };
  }
  componentDidMount() {
    let runnerData = ApplicationUtil.getRunnerDetails();
    let eventData = ApplicationUtil.getRunnersEventsDetails();
    let eventId = eventData?.id;

    let runnerId = runnerData?.runner?.id;
    let token = runnerData?.token;
    this.setState({ loading: true });
    getTrainingPlan(token, eventId, runnerId).then((res) => {
      let list = res.data;
      let formattedList = [];
      for (let i = 0; i < list.length; i++) {
        let trainingIndex = formattedList.findIndex(
          (e) => e.challengeDate === list[i].challengeDate
        );
        if (trainingIndex === -1) {
          formattedList.push({
            challengeDate: list[i].challengeDate,
            userMessages: list[i].userMessages,
            trainingDetails: [list[i]],
          });
        } else {
          formattedList[trainingIndex].trainingDetails.push(list[i]);
          // formattedList[trainingIndex].userMessages.push(list[i].userMessages);
        }
      }

      let sorted = formattedList.sort((a, b) => {
        let first = moment(
          ApplicationUtil.getDateParts(
            ApplicationUtil.changeDateFormatDate(a.challengeDate)
          )
        );
        let second = moment(
          ApplicationUtil.getDateParts(
            ApplicationUtil.changeDateFormatDate(b.challengeDate)
          )
        );

        return second.diff(first, "days");
      });
      console.log(sorted);
      this.setState({
        list: sorted, //formattedList,//res.data,
        eventName: eventData?.name,
        eventDate: ApplicationUtil.changeDateFormat(eventData.localStartDate),
        eventEnd: ApplicationUtil.changeDateFormat(eventData.localEndDate),
        loading: false,
      });
    });
  }

  goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  formatDesc = (desc, url, source, filename, shortLinkName) => {
    const { classes } = this.props;
    let parts = desc.split("$$");
    let msg = "";
    parts.map((part) => {
      if (part === "videoLink") {
        if (url !== undefined) {
          msg =
            msg +
            `<a id="link" >${shortLinkName ? shortLinkName : "see video"}</a>`;
          //${url}
        }
      } else {
        msg = msg + `<span>${part}</span>`;
      }
    });

    return parse(msg, {
      replace: ({ attribs }) => {
        if (attribs && attribs.id === "link") {
          return (
            <a
              onClick={() => this.handleURLClick(url, source, filename)}
              style={{ verticalAlign: "middle" }}
            >
              {/* {shortLinkName ? shortLinkName : "see video"} */}
              <VideoPlayerIcon className={classes.playerIcon} />
            </a>
          );
        }
      },
    });
  };

  handleURLClick = (url, source, filename) => {
    this.props.history.push({
      pathname: "/player",
      link: {
        link: url,
        source: source,
        filename: filename,
      },
    });
  };
  render() {
    const { classes, history } = this.props;
    const { list, eventName, eventDate, eventEnd, loading } = this.state;
    return (
      <React.Fragment>
        <AppToolBar
          backNavigate={() => this.props.history.goBack()}
          showBack={true}
        >
          <Header
            {...this.props}
            label={eventName ? eventName : Properties.Label_Training}
            fromDate={eventDate}
            toDate={eventDate === eventEnd ? false : eventEnd}
          />
        </AppToolBar>
        <div className={classes.mainPanel}>
          <RunnerSideBreadcrumbs />
          <div className={classes.titleCss}>{"Training Plan"}</div>
          <div style={{ paddingTop: "10px" }}>
            {list?.length > 0 ? (
              <div className={classes.msgContainer}>
                {list.length > 0 &&
                  list.map((row, i) => (
                    <Grid
                      key={i}
                      className={classes.tableRowCss}
                      style={{
                        borderTop: i === 0 ? "1px lightgray solid" : "none",
                      }}
                    >
                      <div className={classes.upperLayout}>
                        <div className={classes.dateCss}>
                          {ApplicationUtil.changeDateFormatDate(
                            row.challengeDate
                          )}
                        </div>
                        {row?.userMessages?.length > 0 && (
                          <div>
                            <MessageIcon
                              className={classes.icon}
                              onClick={() => {
                                // localStorage.setItem(
                                //   "trainingUserMessages",
                                //   row.userMessages
                                // );
                                history.push({
                                  pathname: "/feedback",
                                  messages: row.userMessages,
                                });
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div>
                        <ul className={classes.trainingListCss}>
                          {row?.trainingDetails?.map((details) => (
                            <li>
                              {details?.description && (
                                <Clamp
                                  // texts={{
                                  //   showMore: "Moreeee",
                                  //   showLess: "Menos",
                                  // }}
                                  showMoreElement={({ toggle }) => (
                                    <span className="button" onClick={toggle}>
                                      Read more
                                    </span>
                                  )}
                                  showLessElement={({ toggle }) => (
                                    <span className="button" onClick={toggle}>
                                      Show Less
                                    </span>
                                  )}
                                  lines={1}
                                  maxLines={1000}
                                  withToggle
                                  // onShowMore={(show) => console.log(show)}
                                >
                                  {this.formatDesc(
                                    details?.description,
                                    details?.videoRecordLink,
                                    details?.videoSource,
                                    details?.videoFileName,
                                    details?.shortLinkName
                                  )}
                                </Clamp>
                              )}
                              {details?.additionalText && (
                                <Clamp
                                  texts={{
                                    showMore: "Moreeee",
                                    showLess: "Menos",
                                  }}
                                  lines={2}
                                  maxLines={1000}
                                  withToggle
                                >
                                  {ReactHtmlParser(details?.additionalText)}
                                </Clamp>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Grid>
                  ))}
              </div>
            ) : (
              <>
                {loading ? (
                  <div>{Properties.TrainingPlanLoading}</div>
                ) : (
                  <div>{Properties.NoTrainingPlanAvailable}</div>
                )}
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(
  withStyles(styles, { withTheme: true }),
  //   connect(mapStateToProps, {
  //     updateUserDetails,
  //   }),
  withRouter
)(TrainingPlan);
