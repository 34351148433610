import React from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SubmitCancelBtn from "./SubmitCancelBtn";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import { AddCircleOutline, Share } from "@material-ui/icons";
import { Grid, Button } from "@material-ui/core";
import Properties from "../../properties/Properties";
import { DeleteTrashIcon } from "./SvgIcon";
import ShareIcon from "@material-ui/icons/Share";

const styles = (theme) => ({
  root: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#296DFA",
    },
    width: "150px",
    alignContent: "center",
    padding: "6px 16px",
    maxWidth: "300px",
    height: "38px",
    borderRadius: "10px",
    backgroundColor: "#09456C",
    color: "#fff",
    fontWeight: "bold",
    "&.MuiDialog-paperScrollPaper": {
      // height: "97%",
      maxHeight: "97%",
    },
  },
  editButtonText: {
    fontSize: "13px !important",
    [theme.breakpoints.down("sm")]: {
      // justifyContent: "space-between",
      fontSize: "12px !important",
    },
  },
});

class DialogBox extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    open: this.props.open,
  };

  closeSuccessDialog = () => {
    // return null;
    this.setState({ open: false });
    if (this.props?.closeDialog) {
      this.props.closeDialog();
    }
  };

  render() {
    const {
      classes,
      open,
      message,
      closeSuccessDialog,
      header,
      onClick,
      onClickTwo,
      labelTwo,
      label,
      fullWidth,
      signup,
      onAddPhoto,
      addPhoto,
      deletePhoto,
      onDeletePhoto,
      deleteFalse,
      maxWidth,
      isInvitationScreen, //temporary
      isSmall,
      singleButton,
      onSingleButtonClick,
      disableCloseBtn,
      dialogContentTextStyle,
      isImagePresent,
      share,
       badgeAchieved,
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={() => this.closeSuccessDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle
          style={{
            color: "#08456C",
            display: "flex",
            justifyContent: "center",
          }}
          id="alert-dialog-title"
        >
          {header}


        </DialogTitle> */}
        {/* this header only used for badge Dialog box */}
        {badgeAchieved ? (
          <div
            style={{
              color: "#08456C",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              //  width:"65%",
              // margin: "0px 10px",
              // marginTop: "10px",
              position: "relative",
              //  marginLeft:"35%",
            }}
          >
            <span
              style={{
                textAlign: "center",
                fontWeight: 600,
                fontSize: "20px",
                flex: 1,
                textTransform: "capitalize",
                padding: "15px",
              }}
            >
              {header}
            </span>
            <span
              style={{
                position: "absolute",
                right: "7px",
                top: "0px",
              }}
            >
              <IconButton disabled={disableCloseBtn}>
                <CancelIcon
                  style={{
                    margin: 0,
                    cursor: "pointer",
                    color: disableCloseBtn
                      ? "rgba(0, 0, 0, 0.54)"
                      : "rgb(8, 69, 108)",
                  }}
                  onClick={(e) =>
                    onClick ? onClick(e) : this.closeSuccessDialog()
                  }
                />
              </IconButton>
            </span>
          </div>
        ) : (
          <div
            style={{
              color: "#08456C",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              //  width:"65%",
              margin: "0px 10px",
              marginTop: "10px",
              // position:"relative"
              //  marginLeft:"35%",
            }}
          >
            <span
              style={{
                textAlign: "center",
                fontWeight: 600,
                fontSize: "20px",
                flex: 1,
                textTransform: "capitalize",
                // padding:"10px"
              }}
            >
              {header}
            </span>

            {addPhoto && (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ marginLeft: "15%" }}
              >
                <Button
                  color="primary"
                  fullWidth={false}
                  variant="contained"
                  className={classes.root}
                >
                  <label htmlFor="contained-button-file">
                    <div style={{ cursor: "pointer" }}>
                      {isImagePresent ? (
                        <div
                          className={classes.editButtonText}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {deleteFalse ? "Change Photo" : "Edit"}
                          <EditIcon
                            fontSize="small"
                            style={{
                              // color: "#F79824",
                              cursor: "pointer",
                              marginLeft: "8px",
                              fontSize: "19px",
                            }}
                          ></EditIcon>
                        </div>
                      ) : (
                        Properties.Add_photo_label
                      )}
                    </div>
                  </label>
                  <Grid item xs style={{ display: "none" }}>
                    <input
                      multiple
                      className={classes.input}
                      id="contained-button-file"
                      type="file"
                      accept="image/x-png,image/jpeg"
                      onChange={(e) => onAddPhoto(e)}
                    />
                  </Grid>
                </Button>
              </Grid>
            )}

            {!signup && (
              <>
                {deletePhoto && !deleteFalse && (
                  <DeleteTrashIcon
                    style={{ margin: "0", cursor: "pointer" }}
                    onClick={onDeletePhoto}
                  />
                )}
                {!disableCloseBtn && (
                  <IconButton disabled={disableCloseBtn}>
                    <CancelIcon
                      style={{
                        margin: 0,
                        cursor: "pointer",
                        color: disableCloseBtn
                          ? "rgba(0, 0, 0, 0.54)"
                          : "rgb(8, 69, 108)",
                      }}
                      onClick={(e) =>
                        onClick ? onClick(e) : this.closeSuccessDialog()
                      }
                    />
                  </IconButton>
                )}
              </>
            )}
          </div>
        )}

        <DialogContent
          style={{
            // overflowY: "hidden",
            padding: typeof message === "string" ? "8px 24px" : "0px",
            // margin: 0,
          }}
        >
          <DialogContentText
            style={{ ...dialogContentTextStyle }}
            id="alert-dialog-description"
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          {singleButton && (
            <SubmitCancelBtn
              style={{ width: isSmall ? "auto" : "100%" }}
              //onClick={() => this.closeSuccessDialog()}
              onClick={(e) => onSingleButtonClick(e)}
              label={label ? label : "OK"}
              type="submit"
            />
          )}
          {onClickTwo ? (
            <>
              <SubmitCancelBtn
                // style={{ width: isSmall ? "auto" : "40%" }}
                style={{
                  width: isInvitationScreen ? "40%" : isSmall ? "auto" : "100%",
                  fontSize: "14px",
                }}
                //onClick={() => this.closeSuccessDialog()}
                onClick={(e) =>
                  onClick ? onClick(e) : this.closeSuccessDialog()
                }
                label={label ? label : "OK"}
                type="submit"
              />
              <SubmitCancelBtn
                style={{
                  width: isInvitationScreen ? "40%" : isSmall ? "auto" : "100%",
                  fontSize: "14px",
                }}
                onClick={onClickTwo}
                label={labelTwo}
                type="submit"
              />
            </>
          ) : (
            <>
              {signup && (
                <SubmitCancelBtn
                  style={{ width: "40%" }}
                  //onClick={() => this.closeSuccessDialog()}
                  onClick={(e) =>
                    onClick ? onClick(e) : this.closeSuccessDialog()
                  }
                  label={"OK"}
                  type="submit"
                />
              )}

              {share && (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  // style={{ marginLeft: "15%"}}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.root}
                    onClick={onAddPhoto}
                  >
                    Share
                    <ShareIcon fontSize="small" style={{ marginLeft: "8px" }} />
                  </Button>
                </Grid>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(DialogBox);
