import React, { Component } from "react";
import { Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Properties from "../../../../../properties/Properties";
import SubmitCancelBtn from "../../../../common/SubmitCancelBtn";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import {
  userRegisteredForEvent,
  userRegisteredForProgram,
} from "../../../../../apis/eventService";
import { RuppeeIcon } from "../../../../common/SvgIcon";
import { connect } from "react-redux";
import {
  updateUserSelectedEvent,
  updateUserPreferences,
  updateUserSelectedProgram,
} from "../../../../../redux/user";
import { withRouter } from "react-router-dom";
import MultiSelect from "../../../../common/MultiSelect";
var moment = require("moment");

const styles = (theme) => ({
  bookings: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    right: 30,
    width: window.innerWidth < 1050 ? "15%" : "20%",
    marginLeft: "20px",
    alignItems: "center",
    padding: "15px",
  },
  fee: {
    width: "100%",
    margin: "auto",
    textAlign: "center",
    marginTop: "10px",
    border: "1px solid #718096",
  },
  bookBtn: {
    width: "100%",
    margin: "auto",
    textAlign: "center",
    marginTop: "10px",
  },
  regClose: {
    color: "#000",
    marginTop: "15px",
    border: "1px solid #718096",
  },
  loginContainer: {
    width: "100%",
    paddingTop: "5%",
    justifyContent: "center",
    textAlign: "center",
  },
  loginText: {
    color: "#2883B5",
    fontWeight: "bold",
    cursor: "pointer",
  },
  dropdownCss: {
    width: "175px",
  },
});
class Bookings extends Component {
  constructor(props) {
    super(props);

    // Initializing the state
    this.state = { challengeType: null };
  }
  getRegistrationFee = (data) => {
    let feeArr = [];
    let fee;
    if (data?.eventRunCategories && data?.eventRunCategories?.length > 0) {
      data.eventRunCategories.map((fee) => {
        feeArr.push(fee.registrationFee);
      });
    }
    if (feeArr.length > 0) {
      if (feeArr.every((x) => x === 0)) {
        return <h2>{Properties.Label_EventDetails_Free}</h2>;
      } else {
        return (
          <>
            <p>
              <b>
                <RuppeeIcon style={{ fontSize: "18px" }} />
                {Math.min(
                  ...feeArr.filter(function (x) {
                    return x > 0;
                  })
                )}
                /-{" "}
              </b>
              {feeArr?.length > 1 ? (
                <span style={{ color: "#718096" }}>
                  {Properties.Label_EventDetails_Bookings_Fee}
                </span>
              ) : (
                <span style={{ color: "#718096" }}>
                  {Properties.Label_Only}
                </span>
              )}
            </p>
            <p>{Properties.Label_EventDetails_Bookings_Inclu}</p>
          </>
        );
      }
    }
  };

  navigateToEventRegistration = (data) => {
    const { history, distKey } = this.props;
    if (data?.programStartDate) {
      this.props.updateUserSelectedProgram(data);
    } else {
      this.props.updateUserSelectedProgram({});
      this.props.updateUserSelectedEvent(data);
    }
    if (!ApplicationUtil.isuserLoggedIn()) {
      history.push(`/login?distKey=${distKey}`);
    } else {
      if (distKey !== "") {
        if (data?.programStartDate) {
          userRegisteredForProgram(data?.id).then((res) => {
            if (res?.data?.id && res?.data?.success?.code !== "409")
              this.props.history.push("/calender");
            else this.props.history.push("/programRegistration");
          });
        } else {
          userRegisteredForEvent(data?.id).then((res) => {
            if (res.data.success.code === "200")
              this.props.history.push("/eventDetails");
            else this.props.history.push("/eventRegistration");
          });
        }
      }
    }
  };

  navigateToVideoGallery = (data) => {
    this.props.history.push(`/videoGallery?distKey=${data?.distKey}`);
  };

  disableGotoChallengeBtn = () => {
    /**
     * Checking if is it a program & program contains challenge & if current day is the start day of challenge & if intro video is viewed or not
     */
    if (
      window.location.href?.includes("program") &&
      this.props.isDayOneOfCurrentRunningChallenge &&
      !this.props.isVideoViewed &&
      this.props.runningProgramChallengeResData &&
      this.props.runningProgramChallengeResData?.videoLink
    ) {
      return true;
    } else return false;
  };

  getLabelForSubmitButton = () => {
    
    /**
     * Checking if user is registered & if is it a program & program contains challenge
     */
    if (this.props.isUserRegistered) {
      if (
        window.location.href?.includes("program")
        // && this.props.runningProgramChallengeResData
      ) {
        return Properties.Label_Go_To_Challenge;
      } else return "View Dashboard";
    } else return Properties.Label_EventDetails_Bookings_BtnOpen;
  };

  getOptionValue = (data) => {
    if (data && data?.openSubscriptionProgramChallengeDto) {
      let formattedData = data?.openSubscriptionProgramChallengeDto?.map(
        (item) => {
          let isSameChallengeTypeOrOnGoing =
            data?.programChallenges &&
            data?.programChallenges?.some((challenge) => {
              const startDate = moment(
                challenge.challengeStartDate,
                "DD-MM-YYYY HH:mm:ss"
              );
              const endDate = moment(
                challenge.challengeEndDate,
                "DD-MM-YYYY HH:mm:ss"
              );
              // checking start date and end data should be in between current date and  same type of challenge present
              return (
                data?.programChallenges?.length &&
                moment().isSameOrBefore(endDate) &&
                item.challengeType === challenge.programChallengeType
              );
            });

          let formattedChallengeType = ApplicationUtil.capitalizeFirstLetter(
            item?.challengeType
          );

          return {
            label: `${formattedChallengeType} Challenge`,
            value: item?.challengeType,
            id: item?.id,
            isDisabled: isSameChallengeTypeOrOnGoing,
            tooltip: isSameChallengeTypeOrOnGoing
              ? "You cannot start the same challenge till the previous one ends. Please select another challenge."
              : "",
          };
        }
      );

      return formattedData;
    }
  };

  handleChallengeType = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      this.setState({
        challengeType: selectedValues,
        // challengeTypeError: false,
      });
    } else {
      this.setState({
        challengeType: selectedValues,
        // challengeTypeError: true,
      });
    }
  };

  navigateToOpenChallenge = (data) => {
    const { history } = this.props;
    history.push(
      `/createChallenge&register?challenge=${this.state.challengeType?.label}&id=${this.state.challengeType?.id}`
    );
  };

  render() {
    const {
      isVideoViewed,
      classes,
      data,
      history,
      isUserRegistered,
      isDayOneOfCurrentRunningChallenge,
      runningProgramChallengeResData,
    } = this.props;
    const isProgram = window.location.href?.includes("program");

    return (
      <div style={{ flex: 0.2 }}>
        <Paper className={classes.bookings} elevation={2}>
          {!data?.hideFreeBlockWhenFeeZero && data?.isRegistrationOpen && (
            <div className={classes.fee}>{this.getRegistrationFee(data)}</div>
          )}

          {/* if program is openProgram then user selection of challengeType */}

          {data &&
            data?.isOpenSubscriptionProgram &&
            moment().isSameOrBefore(
              moment(data?.localEndDate, "YYYY-MM-DD")
            ) && (
              <>
                <div className={classes.dropdownCss}>
                  <MultiSelect
                    value={this.state.challengeType || ""}
                    // error={challengeTypeError}
                    // styles={{
                    //   placeholder: (provided) => ({
                    //     ...provided,
                    //     margin: 0,
                    //   }),
                    // }}
                    placeholder="Select Challenge"
                    isReadOnly={
                      this.getLabelForSubmitButton() ===
                      Properties.Label_EventDetails_Bookings_BtnOpen
                        ? true
                        : false
                    }
                    options={this.getOptionValue(data)}
                    isNotAllowedCreateSameChallenge
                    onChange={this.handleChallengeType}
                  />
                </div>

                <div className={classes.bookBtn}>
                  <SubmitCancelBtn
                    label={Properties.Label_Add_Challenge}
                    type="submit"
                    style={{ width: "150px", marginRight: "10px" }}
                    isDisabled={this.state.challengeType ? false : true}
                    onClick={() => {
                      this.navigateToOpenChallenge(data);
                    }}
                  />
                </div>
              </>
            )}


          <div className={classes.bookBtn}>
            {data && data.isRegistrationOpen ? (
              <>
                {this.disableGotoChallengeBtn() ? (
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginTop: "5px",
                    }}
                  >
                    {Properties.video_finish_label}
                  </Typography>
                ) : (
                  <SubmitCancelBtn
                    label={this.getLabelForSubmitButton()}
                    // label={Properties.Label_Go_To_Challenge}
                    type="submit"
                    style={{
                      display:
                        data?.programChallenges?.length > 0 ||
                        data?.eventDTO?.length > 0 ||
                        data?.programSessions?.length > 0 ||
                        !this.props.isUserRegistered ||
                        !window.location.href?.includes("program")
                          ? ""
                          : "none",
                      width: "160px",
                      marginRight: "10px",
                    }}
                    isDisabled={this.disableGotoChallengeBtn()}
                    onClick={() => {
                      this.navigateToEventRegistration(data);
                    }}
                  />
                )}
                {!isUserRegistered && !ApplicationUtil.isuserLoggedIn() && (
                  <Grid
                    className={classes.loginContainer}
                    container
                    spacing={1}
                  >
                    <span style={{ color: "#999999" }}>Already Register? </span>
                    &nbsp;
                    <span
                      className={classes.loginText}
                      onClick={() => {
                        this.navigateToEventRegistration(data);
                      }}
                    >
                      Login
                    </span>
                  </Grid>
                )}
              </>
            ) : (
              isUserRegistered && (
                <>
                  {this.disableGotoChallengeBtn() ? (
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginTop: "5px",
                      }}
                    >
                      {Properties.video_finish_label}
                    </Typography>
                  ) : (
                    <SubmitCancelBtn
                      label={this.getLabelForSubmitButton()}
                      type="submit"
                      style={{ width: "160px", marginRight: "10px" }}
                      isDisabled={this.disableGotoChallengeBtn()}
                      onClick={() => {
                        this.navigateToEventRegistration(data);
                      }}
                    />
                  )}
                </>
              )
            )}

            {data?.summaryGenerated &&
              !isProgram &&
              ApplicationUtil.isuserLoggedIn() && (
                <div style={{ marginTop: "10px" }}>
                  <SubmitCancelBtn
                    label={"View Results"}
                    type="submit"
                    style={{
                      width: "160px",
                      marginRight: "10px",
                      marginBottom: "10px",
                    }}
                    onClick={() => {
                      let newData = { ...data };
                      newData.distKey = this.props?.distKey;
                      if (newData?.programStartDate) {
                        this.props.updateUserSelectedProgram(newData);
                      } else {
                        this.props.updateUserSelectedEvent(newData);
                      }
                      localStorage.removeItem("navigatedFromEventDetails");
                      localStorage.removeItem("defaultRunnerActivityType");
                      localStorage.removeItem("defaultRunnerCategoryName");

                      // history.push({ pathname: "/results", eventId: eventId });
                      history.push(
                        newData?.programStartDate
                          ? "/programResults"
                          : "/results"
                      );
                    }}
                  />
                </div>
              )}

            {data && !data?.isRegistrationOpen && (
              <>
                {!ApplicationUtil.isuserLoggedIn() && (
                  <SubmitCancelBtn
                    label={Properties.login_label}
                    type="submit"
                    style={{ width: "160px", marginTop: "10px" }}
                    onClick={() => {
                      this.navigateToEventRegistration(data);
                    }}
                  />
                )}
                <div className={classes.regClose}>
                  <p>
                    <b style={{ color: "#FF0000" }}>
                      {!data?.isRegistrationOpen &&
                        Properties.Label_EventDetails_Bookings_BtnClosed}
                    </b>
                  </p>
                </div>
              </>
            )}

            {data?.allowVideoUpload && (
              <div style={{ marginTop: "10px" }}>
                <SubmitCancelBtn
                  label={Properties.Label_VideoGallery}
                  type="submit"
                  style={{
                    marginRight: "10px",
                    padding: "10px",
                    lineHeight: "18px",
                  }}
                  onClick={() => {
                    this.navigateToVideoGallery(data);
                  }}
                />
              </div>
            )}
          </div>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userSelectedEvent: state.user.userSelectedEvent,
    updateUserPreferences: state.user.updateUserPreferences,
  };
}
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateUserSelectedEvent,
    updateUserSelectedProgram,
  })
)(Bookings);
