import React from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid, Divider, Box, Tooltip } from "@material-ui/core";
import { CustomTextField } from "./CustomTextField";
import MultiSelect from "./MultiSelect";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { IconCalendarBlue } from "./SvgIcon";
const styles = (theme) => ({
  mainPanel: {
    marginTop: "60px",
    // overflowX: "hidden",
  },
  customInputCss: {
    border: "1px solid #08456C",
    height: "38px", //"55%",
    maxHeight: "38px", //"55%",
    minHeight: "38px", //"55%",
    width: window.innerWidth >= 600 ? "360px" : "220px",
    minWidth: window.innerWidth >= 600 ? "360px" : "220px",
    maxWidth: window.innerWidth >= 600 ? "360px" : "220px",
  },
  customInputErrorCss: {
    border: "1px solid red",
    height: "38px", //"55%",
    maxHeight: "38px", //"55%",
    minHeight: "38px", //"55%",
    width: window.innerWidth >= 600 ? "360px" : "220px",
    minWidth: window.innerWidth >= 600 ? "360px" : "220px",
    maxWidth: window.innerWidth >= 600 ? "360px" : "220px",
  },
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
    width: window.innerWidth >= 600 ? "360px" : "220px",
    minWidth: window.innerWidth >= 600 ? "360px" : "220px",
    maxWidth: window.innerWidth >= 600 ? "360px" : "220px",
  },
  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
    width: window.innerWidth >= 600 ? "360px" : "220px",
    minWidth: window.innerWidth >= 600 ? "360px" : "220px",
    maxWidth: window.innerWidth >= 600 ? "360px" : "220px",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },
  dropdownCss: {
    // width: window.innerWidth >= 600 ? "200px" : "180px",
    // minWidth: window.innerWidth >= 600 ? "200px" : "180px",
    // maxWidth: window.innerWidth >= 600 ? "200px" : "180px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "96%",
      marginLeft: "2%",
    },
  },
  helpCss: {
    display: "flex",
    color: "#3a6ea5",
  },
  helpContainerCss: {
    display: "inline-flex",
  },
  incrementDistanceBlock: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  incrementDistanceMenu: {
    marginLeft: "10px",
    width: "100px",
  },
  checkBox: {
    "&.Mui-checked": {
      color: "#296dfa",
    },
  },
  customFieldTopBar: {
    // marginLeft: "5%",
    width: "137%",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  customFieldTopBarEdit: {
    width: "87%",
  },
  customFieldContainer: {
    // marginLeft: "6.7%",
    width: "100%",
    marginTop: "1px",

    "&.MuiGrid-container": {
      display: "block",
      width: "100%",
      flexWrap: "wrap",
      boxSizing: "borderBox",
    },
  },
  customSectionTitle: {
    color: "#2883B5",
    fontSize: "23px",
    fontWeight: "bold",
    padding: "2px 0px",
  },
  customGroupBox: {
    border: "1px solid  rgb(160,160,160)",
    padding: "1% 1%",
    margin: "1% 0%",

    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  customGroupTitle: {
    padding: "3px 0px 6px 0px",
    fontWeight: "600",
    fontSize: "18px",
  },
  customTextField: {
    marginTop: "4px",
  },
  customFieldTitle: {
    color: "#727272",
  },
  customFieldRequired: {
    color: "red",
  },
  customMultiSelectField: {
    marginTop: "7px",
  },
});
function CustomFields(props) {
  const {
    classes,
    hideCustomSectionLabel,
    customSectionLabel,
    detailsData,
    updateDetailsData,
    editRegistration,
  } = props;

  const handleSelectChange = (selectedValues, row) => {
    var arr = [];
    if (selectedValues !== null) {
      if (Array.isArray(selectedValues)) {
        arr.push(selectedValues);
        row.fieldValue = selectedValues;
        row.isError = false;
      } else {
        arr.push(selectedValues.value);
        row.fieldValue = selectedValues?.optionValue;
        row.fieldOptionId = selectedValues?.id;

        row.isError = false;
      }
    } else {
      row.fieldValue = selectedValues?.optionValue;
      row.fieldOptionId = selectedValues?.id;
      row.isError = false;
    }
    // if(inputField==="MULTI_SELECT"){
    //     alert('hi')
    // }
    let newData = [...detailsData];
    let index = newData.findIndex(
      (obj) => obj.customFieldId == row.customFieldId
    );
    newData[index].isError = row.isError ? true : false;
    if (row.dependentField.length > 0) {
      row.dependentField.forEach((ele) => {
        for (let i = 0; i < newData.length; i++) {
          if (newData[i].customFieldId == ele.dependentFieldId) {
            if (row.fieldValue == "") newData[i].hidden = true;
            else newData[i].hidden = false;
          }
        }
      });
    }
    updateDetailsData(newData);
  };
  const handleGroupedSelectChange = (selectedValues, row, rowIndex) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      row.fieldValue = selectedValues.optionValue;
      row.fieldOptionId = selectedValues.id;

      row.isError = false;
    } else {
      row.fieldValue = selectedValues.optionValue;
      row.fieldOptionId = selectedValues.id;
      row.isError = false;
    }
    let newData = [...detailsData];
    if (newData[rowIndex].field) {
      let index = newData[rowIndex].field.findIndex(
        (obj) => obj.customFieldId == row.customFieldId
      );
      newData[rowIndex].field[index].isError = row.isError ? true : false;
    }
    if (row.dependentField.length > 0) {
      row.dependentField.forEach((ele) => {
        for (let i = 0; i < newData.length; i++) {
          for (
            let j = 0;
            newData[i].field && j < newData[i].field.length;
            j++
          ) {
            if (newData[i].field[j].customFieldId == ele.dependentFieldId) {
              if (row.fieldValue == "") newData[i].field[j].hidden = true;
              else newData[i].field[j].hidden = false;
            }
          }
          // if (
          //   newData[i].field.customFieldId == ele.dependentFieldId
          // ) {
          //   if (row.fieldValue == "") newData[i].hidden = true;
          //   else newData[i].hidden = false;
          // }
        }
      });
    }
    updateDetailsData(newData);
  };

  const handleGroupedMultiSelectChange = (selectedValues, row, rowIndex) => {
    var arr = [];
    if (selectedValues != null) {
      selectedValues.forEach((element) => {
        arr.push(element);
      });
      row.fieldValue = selectedValues;
      row.isError = false;
    } else {
      row.fieldValue = selectedValues;
      row.isError = false;
    }
    let newData = [...detailsData];
    if (newData[rowIndex].field) {
      let index = newData[rowIndex].field.findIndex(
        (obj) => obj.customFieldId == row.customFieldId
      );
      newData[rowIndex].field[index].isError = row.isError ? true : false;
    }
    if (row.dependentField.length > 0) {
      row.dependentField.forEach((ele) => {
        for (let i = 0; i < newData.length; i++) {
          for (
            let j = 0;
            newData[i].field && j < newData[i].field.length;
            j++
          ) {
            if (newData[i].field[j].customFieldId == ele.dependentFieldId) {
              if (row.fieldValue == "") newData[i].field[j].hidden = true;
              else newData[i].field[j].hidden = false;
            }
          }
          // if (
          //   newData[i].field.customFieldId == ele.dependentFieldId
          // ) {
          //   if (row.fieldValue == "") newData[i].hidden = true;
          //   else newData[i].hidden = false;
          // }
        }
      });
    }
    updateDetailsData(newData);
  };
  const renderRequiredField = (inputField, row, index) => {
    if (inputField === "TEXT" || inputField === "NUMBER") {
      return (
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          className={classes.customTextField}
        >
          <Typography
            variant="body2"
            gutterBottom
            style={{ fontSize: "18px", marginTop: "15px" }}
          >
            <span
              className={classes.customFieldTitle}
              style={{ fontSize: "18px", marginTop: "30px" }}
            >
              {row.displayName}
            </span>
            {row.requiredField && (
              <span className={classes.customFieldRequired}>*</span>
            )}
          </Typography>
          <CustomTextField
            className={
              row.isError ? classes.customInputErrorCss : classes.customInputCss
            }
            type={inputField === "NUMBER" ? "number" : "text"}
            error={row.isError}
            keyToSet={row.displayName}
            defaultValue={row.fieldValue}
            handleChange={(prop) => (event) => {
              row.fieldValue = event.target.value;
              row.isError = false;
              let newData = [...detailsData];
              let index = newData.findIndex(
                (obj) => obj.customFieldId == row.customFieldId
              );
              newData[index].isError = row.isError ? true : false;
              if (row.dependentField.length > 0) {
                row.dependentField.forEach((ele) => {
                  for (let i = 0; i < newData.length; i++) {
                    if (newData[i].customFieldId == ele.dependentFieldId) {
                      if (row.fieldValue == "") newData[i].hidden = true;
                      else newData[i].hidden = false;
                    }
                  }
                });
              }

              updateDetailsData(newData);
            }}
          />
        </Grid>
      );
    } else if (inputField === "SINGLE_SELECT") {
      let optionsList = row.fieldOptions;
      optionsList.map((r) => ((r.label = r.optionValue), (r.value = r.id)));
      let val = null;

      optionsList.forEach((element) => {
        if (element.label == row.fieldValue) {
          val = element;
        }
      });

      return (
        <Grid
          item
          sm={6}
          xs={12}
          md={8}
          lg={8}
          xl={8}
          className={classes.customTextField}
        >
          <Typography
            variant="body2"
            gutterBottom
            style={{ fontSize: "18px", marginTop: "15px" }}
          >
            <span
              className={classes.customFieldTitle}
              style={{ fontSize: "18px" }}
            >
              {row.displayName}
            </span>
            {row.requiredField && (
              <span className={classes.customFieldRequired}>*</span>
            )}
          </Typography>
          <div className={classes.dropdownCss}>
            <MultiSelect
              value={val}
              error={row.isError}
              options={optionsList}
              onChange={(e) => {
                handleSelectChange(e, row);
              }} //{this.handleSelectChange(e, i)}
            />
          </div>
        </Grid>
      );
    } else if (inputField === "MULTI_SELECT") {
      let optionsList = row.fieldOptions;
      optionsList.map((r) => ((r.label = r.optionValue), (r.value = r.id)));
      let val = null;

      optionsList.forEach((element) => {
        if (element.label == row.fieldValue) {
          val = element;
        }
      });

      return (
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          className={classes.customTextField}
        >
          <Typography
            variant="body2"
            gutterBottom
            style={{ fontSize: "18px", marginTop: "15px" }}
          >
            <span
              className={classes.customFieldTitle}
              style={{ fontSize: "18px" }}
            >
              {row.displayName}
            </span>
            {row.requiredField && (
              <span className={classes.customFieldRequired}>*</span>
            )}
          </Typography>
          <div className={classes.dropdownCss}>
            <MultiSelect
              isMulti={true}
              value={row.fieldValue}
              error={row.isError}
              options={optionsList}
              onChange={(e) => {
                handleSelectChange(e, row);
              }}
            />
          </div>
        </Grid>
      );
    } else if (inputField === "DATE") {
      return (
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
          <Typography
            variant="body2"
            gutterBottom
            style={{ fontSize: "18px", marginTop: "15px" }}
          >
            <span
              className={classes.customFieldTitle}
              style={{ fontSize: "18px" }}
            >
              {row.displayName}
            </span>
            {row.requiredField && (
              <span className={classes.customFieldRequired}>*</span>
            )}
          </Typography>
          <div>
            <KeyboardDatePicker
              autoOk
              clearable
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              // disableFuture={true}
              style={{
                width: "360px",
                minWidth: "360px",
                maxWidth: "360px",
              }}
              defaultValue={row.fieldValue}
              value={row.fieldValue}
              error={row.isError}
              className={classes.fieldWidth}
              InputProps={{
                classes: {
                  root: row.isError
                    ? classes.datePickerError
                    : classes.datePicker,
                },
              }}
              keyboardIcon={
                <IconCalendarBlue className={classes.calendarIconCss} />
              }
              onChange={(date) => {
                row.fieldValue = date;
                row.isError = false;

                let newData = [...detailsData];
                let index = newData.findIndex(
                  (obj) => obj.customFieldId == row.customFieldId
                );
                newData[index].isError = row.isError ? true : false;
                if (row.dependentField.length > 0) {
                  row.dependentField.forEach((ele) => {
                    for (let i = 0; i < newData.length; i++) {
                      if (newData[i].customFieldId == ele.dependentFieldId) {
                        if (row.fieldValue == "") newData[i].hidden = true;
                        else newData[i].hidden = false;
                      }
                    }
                  });
                }
                updateDetailsData(newData);
              }}
            />
          </div>
        </Grid>
      );
    }
  };

  const renderRequiredGroupedField = (inputField, row, index, rowIndex) => {
    if (inputField == "TEXT" || inputField == "NUMBER") {
      return (
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          className={classes.customTextField}
        >
          <Typography variant="body2" gutterBottom>
            <span
              className={classes.customFieldTitle}
              style={{ fontSize: "18px" }}
            >
              {row.displayName}
            </span>
            {row.requiredField && (
              <span className={classes.customFieldRequired}>*</span>
            )}
          </Typography>
          <CustomTextField
            className={
              row.isError ? classes.customInputErrorCss : classes.customInputCss
            }
            type={inputField === "NUMBER" ? "number" : "text"}
            error={row.isError}
            keyToSet={row.displayName}
            defaultValue={row.fieldValue}
            handleChange={(prop) => (event) => {
              row.fieldValue = event.target.value;
              row.isError = false;
              //let newData = [...detailsDataGroupedList];
              let newData = [...detailsData];
              if (newData[rowIndex].field) {
                let index = newData[rowIndex].field.findIndex(
                  (obj) => obj.customFieldId == row.customFieldId
                );
                newData[rowIndex].field[index].isError = row.isError
                  ? true
                  : false;
              }
              if (row.dependentField.length > 0) {
                row.dependentField.forEach((ele) => {
                  for (let i = 0; i < newData.length; i++) {
                    for (
                      let j = 0;
                      newData[i].field && j < newData[i].field.length;
                      j++
                    ) {
                      if (
                        newData[i].field[j].customFieldId ==
                        ele.dependentFieldId
                      ) {
                        if (row.fieldValue == "")
                          newData[i].field[j].hidden = true;
                        else newData[i].field[j].hidden = false;
                      }
                    }
                    // if (
                    //   newData[i].field.customFieldId == ele.dependentFieldId
                    // ) {
                    //   if (row.fieldValue == "") newData[i].hidden = true;
                    //   else newData[i].hidden = false;
                    // }
                  }
                });
              }
              updateDetailsData(newData);

              //dependent fields
            }}
          />
        </Grid>
      );
    } else if (inputField == "SINGLE_SELECT") {
      let optionsList = row.fieldOptions;
      optionsList.map((r) => ((r.label = r.optionValue), (r.value = r.id)));
      let val = null;

      optionsList.forEach((element) => {
        if (element.label == row.fieldValue) {
          val = element;
        }
      });

      return (
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          className={classes.customTextField}
        >
          <Typography variant="body2" gutterBottom>
            <span
              className={classes.customFieldTitle}
              style={{ fontSize: "18px" }}
            >
              {row.displayName}
            </span>
            {row.requiredField && (
              <span className={classes.customFieldRequired}>*</span>
            )}
          </Typography>{" "}
          <div className={classes.dropdownCss}>
            <MultiSelect
              style={{}}
              value={val}
              error={row.isError}
              options={optionsList}
              onChange={(e) => {
                handleGroupedSelectChange(e, row, rowIndex);
              }} //{this.handleSelectChange(e, i)}
            />
          </div>
        </Grid>
      );
    } else if (inputField === "MULTI_SELECT") {
      let optionsList = row.fieldOptions;
      optionsList.map((r) => ((r.label = r.optionValue), (r.value = r.id)));
      let val = null;

      optionsList.forEach((element) => {
        if (element.label == row.fieldValue) {
          val = element;
        }
      });

      return (
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          className={classes.customTextField}
        >
          <Typography variant="body2" gutterBottom>
            <span className={classes.customFieldTitle}>{row.displayName}</span>
            {row.requiredField && (
              <span className={classes.customFieldRequired}>*</span>
            )}
          </Typography>
          <div className={classes.dropdownCss}>
            <MultiSelect
              isMulti={true}
              value={row.fieldValue}
              error={row.isError}
              options={optionsList}
              onChange={(e) => {
                handleGroupedMultiSelectChange(e, row, rowIndex);
              }}
            />
          </div>
        </Grid>
      );
    } else if (inputField === "DATE") {
      return (
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.customFieldTitle}>{row.displayName}</span>
            {row.requiredField && (
              <span className={classes.customFieldRequired}>*</span>
            )}
          </Typography>
          <div>
            <KeyboardDatePicker
              autoOk
              clearable
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              // disableFuture={true}
              defaultValue={row.fieldValue}
              value={row.fieldValue}
              error={row.isError}
              className={classes.fieldWidth}
              InputProps={{
                classes: {
                  root: row.isError
                    ? classes.datePickerError
                    : classes.datePicker,
                },
              }}
              keyboardIcon={
                <IconCalendarBlue className={classes.calendarIconCss} />
              }
              onChange={(date) => {
                row.fieldValue = date;
                row.isError = false;

                let newData = [...detailsData];
                let index = newData.findIndex(
                  (obj) => obj.customFieldId == row.customFieldId
                );
                newData[index].isError = row.isError ? true : false;
                if (row.dependentField.length > 0) {
                  row.dependentField.forEach((ele) => {
                    for (let i = 0; i < newData.length; i++) {
                      if (newData[i].customFieldId == ele.dependentFieldId) {
                        if (row.fieldValue == "") newData[i].hidden = true;
                        else newData[i].hidden = false;
                      }
                    }
                  });
                }
                updateDetailsData(newData);
              }}
            />
          </div>
        </Grid>
      );
    }
  };

  return (
    <>
      <div
        className={
          editRegistration
            ? classes.customFieldTopBarEdit
            : classes.customFieldTopBar
        }
        style={{
          marginTop: hideCustomSectionLabel ? "0px" : "20px",
          borderTop: hideCustomSectionLabel
            ? "none"
            : "1px solid rgb(13 108 169)",
        }}
      />
      <Grid
        className={classes.customFieldContainer}
        // xs={10}
        container
        spacing={1}
      >
        {!hideCustomSectionLabel && customSectionLabel && (
          <Typography variant="body2" gutterBottom>
            <span className={classes.customSectionTitle}>
              {customSectionLabel}
            </span>
          </Typography>
        )}
        {detailsData.map((row, index) => (
          <>
            {!row.field && !row.hidden && (
              <Grid style={{ width: "100%" }} container spacing={1}>
                {renderRequiredField(row.fieldType.name, row, index)}
              </Grid>
            )}
            {row.field && (
              <Box className={classes.customGroupBox} spacing={1} boxShadow={3}>
                <span className={classes.customGroupTitle}>
                  {row.groupName}
                </span>
                {row.field.map((r, i) => (
                  <>
                    {!r.hidden && (
                      <Grid style={{ width: "100%" }} container spacing={1}>
                        {renderRequiredGroupedField(
                          r.fieldType.name,
                          r,
                          i,
                          index //rowIndex
                        )}
                      </Grid>
                    )}
                  </>
                ))}
              </Box>
            )}
          </>
        ))}
      </Grid>
    </>
  );
}

export default compose(withStyles(styles, { withTheme: true }))(CustomFields);
