import React from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Properties from "../../../../properties/Properties";
import AppToolBar from "../../../common/AppToolBar";
import { CustomTextField } from "../../../common/CustomTextField";
import MultiSelect from "../../../common/MultiSelect";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import { validateEmail } from "../../../../utils/Validator";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import Snackbar from "../../../common/Snackbar";
import { Typography, Grid, Box, Tooltip, Paper } from "@material-ui/core";
import CommonMenu from "./../../../common/SimpleMenu";
import {
  getEventActivityDetails,
  getEventDetails,
  getSecondaryActivity,
  invitationRegistration,
} from "../../../../apis/eventService";
import DialogBox from "../../../common/DialogBox";
import sizechart from "../../../../images/size_chart.jpg";
import Header from "../../../common/Header";
import InfoIcon from "@material-ui/icons/Info";
import { isBrowser, isMobile } from "react-device-detect";
import ApplicationConstants from "../../../../constants/ApplicationConstants";
import { withRouter } from "react-router-dom";
import CustomFields from "../../../common/CustomFields";
import moment from "moment";

const styles = (theme) => ({
  mainPanel: {
    marginTop: "60px",
    // paddingRight: theme.rightSpacing,
    // paddingLeft: theme.leftSpacing,
    marginLeft: "6.7%",
    marginRight: "6.7%",
  },
  innerPanel: {
    marginLeft: "6.7%",
    marginRight: "6.7%",
  },
  helpCss: {
    display: "flex",
    color: "#3a6ea5",
  },
  helpContainerCss: {
    display: "inline-flex",
  },
  innerGrid: {
    height: "70px",
  },
  customFieldContainer: {
    marginLeft: "10px",
    marginTop: "10px",
  },
});

class InvitationFormContainer extends React.Component {
  state = {
    userName: "",
    genderList: [
      { label: "Male", value: "MALE" },
      { label: "Female", value: "FEMALE" },
      { label: "Other", value: "OTHER" },
    ],
    relationList: [
      { label: "Friend", value: "Friend" },
      { label: "Family", value: "Family" },
    ],
    eventId: 0,
    // FirstName: "",
    lastName: "",
    gender: "",
    email: "",
    emailError: false,
    runnerContactNumber: "",
    runnerContactNumberError: false,
    keepSame: true,
    runnerCountryCode: { label: "+91(India)", value: "91" },
    height: "",
    heightError: false,
    weight: "",
    weightError: false,
    // age: "",
    relation: "",
    changeActivity: "",
    firstName:"",
    firstNameError: false,
    activityType: "",
    eventCategoryList: [],
    eventType: "",
    eventTypeList: [],
    description: [],
    newEventTypeList: [],
    CheckboxSelected: false,
    price: 0,
    showChartOpen: false,
    dailogMsg: "",
    tshirt: false,
    medal: false,
    shippingRequired: false,
bloodGroup:"",
bloodGroupError:false,
    city: "",
    cityError: false,
    country: { label: "India", value: "India" },
    countryError: false,
    address: "",
    addressError: false,
    pincode: "",
    pincodeError: false,
    state: "",
    stateError: false,
    countryCodeList: ApplicationConstants.CountryCodes,
    countryList: ApplicationConstants.countries,
    contactNumber: "",
    contactNumberError: false,
    contactCode: { label: "+91(India)", value: "91" },
    participationType: { label: "Physical", value: "REGULAR_PHYSICAL" },
    participationTypeError: false,
    participationTypeList: ApplicationConstants.participationTypeList,
    showParticipationType: false,
  };

  componentDidMount() {
    document.title = Properties.Page_Title_Invitation_Form;
    this.setState({ userName: localStorage.getItem("userName") });
    let inviteopen = JSON.parse(localStorage.getItem("showInviteOption"));
    if (!ApplicationUtil.isuserLoggedIn()) {
      this.props.history.push("/");
    } else if (!inviteopen) {
      this.props.history.push("/eventDetails");
    } else {
      let eventId = ApplicationUtil.getRunnersEventID();
      let activities = [];
      let requestparam = null; //"INVITATION";
      getEventActivityDetails(requestparam, eventId).then((response) => {
        response.data.map(
          (r) => (
            (r.label = r.displayName),
            (r.value = r.type),
            (r.id = r.id),
            (r.eventSupportedActivityTypeId = r?.eventSupportedActivityTypeId),
            (r.participantType = r.participantType)
          )
        );
        activities = response.data;
      });

      getEventDetails(requestparam, eventId).then((res) => {
        res.data.eventRunCategories.map(
          (r) => (
            (r.label = r.categoryName),
            (r.value = r.id),
            (r.category = r.category),
            (r.eventSupportedActivityType = r?.eventSupportedActivityType)
          )
        );

        if (
          res.data.eventParticipantType === "ALL" &&
          res.data.showParticipationType
        ) {
          activities = activities;
        } else {
          let newAct = activities.filter(
            (e) => e.participantType === "INVITEE"
          );
          if (newAct.length === 0) {
            activities = activities.filter(
              (e) => e.participantType === "REGULAR"
            );
          } else {
            activities = newAct;
          }
        }

        //multiple activity change
        if (res.data.eventRunCategories) {
          let catList = [];
          res.data.eventRunCategories.forEach((element) => {
            if (element?.eventSupportedActivityType?.id) {
              if (
                res.data.eventParticipantType === "ALL" &&
                res.data.showParticipationType &&
                this.state?.eventType?.eventSupportedActivityTypeId &&
                this.state?.eventType?.eventSupportedActivityTypeId ===
                  element?.eventSupportedActivityType?.id
              ) {
                if (!element.hidden) {
                  catList.push(element);
                } else {
                  return;
                }
              } else if (
                this.state?.eventType?.eventSupportedActivityTypeId &&
                this.state?.eventType?.eventSupportedActivityTypeId ===
                  element?.eventSupportedActivityType?.id
              ) {
                if (!element.hidden) {
                  catList.push(element);
                } else {
                  return;
                }
              }
            } else {
              if (this.state.eventType.type === element?.activityType?.type) {
                catList.push(element);
              }
            }
          });

          if (
            res.data.eventParticipantType === "ALL" &&
            res.data.showParticipationType
          ) {
            let newEventsTypeList = [];
            activities.forEach((element) => {
              if (
                element.participantType === this.state.participationType.value
              ) {
                newEventsTypeList.push(element);
              }
            });
          }
          this.setState(
            {
              allEventTypeList: activities,
              eventTypeList: activities,
              eventType: activities[0],
              eventCategoryList: catList,
              eventCategory: catList[0],
              completeEventCategories: res.data.eventRunCategories,
              showActivity:res.data.showActivityTypeOnRegistration,
              showCategory:res.data.showCategoryOnRegistration,
              showSecondaryActivity:res.data?.showSecondaryActivity,
              showParticipationType: res.data.showParticipationType,
              eventParticipantType: res.data.eventParticipantType
                ? res.data.eventParticipantType
                : "REGULAR",
              isPhysical:
                res.data.eventParticipantType === "PHYSICAL"
                  ? true
                  : res.data.eventParticipantType === "ALL"
                  ? true
                  : false,
            },
            () => {
              if (
                res.data.eventParticipantType === "ALL" &&
                res.data.showParticipationType
              ) {
                this.handleParticipationTypeChange(
                  this.state.participationTypeList[0]
                );
              } else {
                this.handleCategoryChange(catList[0]);
                this.handleTypeChange(this.state.eventType);
              }
            }
          );
        }
        if (res?.data?.eventItems) {
          res.data.eventItems.map((item) => {
            if (item.itemName === "T-Shirt") {
              this.setState({ tShirt: true });
            }
            if (item.itemName === "Medal") {
              this.setState({ medal: true });
            }
          });
        }

        //description
        let descArr = [];
        res.data.eventRunCategories.map((desc) => {
          if (desc.description) {
            descArr.push({
              label: desc.activityType.type,
              desc: desc.description,
              category: desc.categoryName,
              maxTickets: desc.maxTickets,
              ticketsSold: desc.ticketsSold,
              ticketSoldOut: desc.ticketSoldOut,
            });
          }
        });
        if (descArr) {
          this.setState({
            description: descArr,
          });
        }
        if (res.data?.showRunnerGroup) {


          let runnerGroupData = res.data?.runnerGroupListDto?.data?.map((x) => {
            if (x?.city) {
              x.city = x.city.toLowerCase()
            }
            if (x?.groupVisibility) {

              return x
            }
          })

          // logic for grouping runner groups based on city

          let runnerGroupWithCity = runnerGroupData.filter((x) => x?.city).map((a) => {
            a.label = a?.name
            a.value = `${a?.name} ${a?.city.toUpperCase()}`
            return a
          })
          let runnerGroupWithOther = runnerGroupData.filter((x) => !x?.city).map((a) => {

            a.label = a?.name
            a.value = a?.name
            return a
          })

          let cityList = ['Custom']
          let city;
          runnerGroupWithCity.forEach((item) => {
            if (city !== item.city && !cityList.includes(item.city)) {
              cityList.push(item.city)
              city = item.city
            }

          })

          if (runnerGroupWithOther?.length > 0) {
            cityList.push('Others')
          }
          // cityList.push('Custom')

          let list = []
          cityList.forEach((item, index) => {
            let obj = {
              id: index + 1,
              // label : `${item.charAt(0).toUpperCase()}${item.substring(1)}`,
              label: item,
              options: item === 'Custom' ? [{ label: 'Other' }] : item === 'Others' ? runnerGroupWithOther : runnerGroupWithCity.filter((x) => x.city === item)
            }
            list.push(obj)

          })

       
          this.setState({ runnerGroupList: list });

        }
        if (res.data?.showAgeGroup) {
          let ageGroupData = res.data?.ageGroupDTOList
          if (ageGroupData?.length > 0) {

            ageGroupData.map((x) => {
              x.label = x?.groupName
              x.value = x?.id
              return x
            })
            this.setState({ ageGroupList: ageGroupData })
          }
        }
        if (res.data?.showCorporateOnReg) {
          let corporateData = res.data?.corporateListDto?.data
          corporateData.map((x) => {
            x.label = x?.displayName
            x.value = x?.id
            return x
          })
          corporateData.unshift({ label: 'Other' })
          this.setState({ corporateList: corporateData })
        }
        //show secondary activity
        let showSecondaryActivity = false;
        if (
          res.data.showSecondaryActivityType == "SHOW_ON_INVITEE" ||
          res.data.showSecondaryActivityType == "SHOW_ON_REG_INVITEE"
        ) {
          showSecondaryActivity = true;
        }
        if (showSecondaryActivity) {
          getSecondaryActivity(eventId,this.state.eventType?.id).then((response) => {
            let secondaryActivityList = response.data.secondaryActivityType;
            secondaryActivityList.map(
              (r) => (
                (r.label = r.displayName), (r.value = r.type), (r.id = r.id)
              )
            );
            this.setState({
              secondaryActivityList: secondaryActivityList,
            });
          });
        }

        this.setState(
          {
            showRunnerGroup: res.data?.showRunnerGroup,
            showAgeGroup: res.data?.showAgeGroup,
            showCorporateOnReg: res.data?.showCorporateOnReg,
            showSecondaryActivity: showSecondaryActivity,
            supportedDomainList:res.data?.supportedDomains,
            // eventCategoryList: runList,
            // eventCategory: runList.length == 1 ? runList[0] : "",
            eventItems: res.data.eventItems,
            shippingRequired: res.data?.shippingRequired,
          },
          () => {}
        );
        if (res.data && res.data.eventItems)
          res.data.eventItems.forEach((element) => {
            if (element.itemName == this.state.tShirtLabel)
              this.setState({ price: element.pricePerUnit });
          });
          if (
            res.data.fields &&
            res.data.fields.customFields &&
            res.data.fields.customFields.length > 0
          ) {
            let d = res.data.fields.customFields;
            d.sort((a, b) => {
              return a.displayOrder - b.displayOrder;
            });

            d = d.filter(function (ele) {
              if (ele.formName == "REGISTER_EVENT") return true;
            });

            d.forEach((row) => {
              if (row.inputField == "SINGLE_SELECT") {
                let optionsList = row.fieldOptions;
                optionsList.map(
                  (r) => ((r.label = r.optionValue), (r.value = r.id))
                );
              }
            });

            
            if (res.data.fields && res.data.fields.fieldGroups) {
              let groupFieldList = res.data.fields.fieldGroups;
              if (groupFieldList.length > 0) {
               

                groupFieldList.forEach((element) => {
                  let list = [];
                  if (element.fieldId && element.fieldId.length > 0) {
                    for (let i = 0; i < element.fieldId.length; i++) {
                      // d.forEach((el) => {
                      for (let j = 0; j < d.length; j++) {
                        if (element.fieldId[i] == d[j].customFieldId) {
                          list.push(d[j]);
                          d.splice(j, 1);
                        }
                      }
                      
                    }
                   
                    d.push({
                      field: list,
                      displayOrder: element.displayOrder,
                      groupName: element.fieldGroup.groupName,
                    });
                  }
                });

                
              }
            }
         
            this.setState({
              additionalDetails: d.sort((a, b) =>
                a.displayOrder > b.displayOrder ? 1 : -1
              ),
              hideCustomSectionLabel: false,
            });
            if (res.data?.fields?.customSectionLabel) {
              this.setState({
                customSectionLabel: res.data?.fields?.customSectionLabel,
              });
            }
          }
      });
    }
  }

  viewChart = () => {
    this.setState({
      showChartOpen: true,
      dailogMsg: this.getShirtSizeChart(),
    });
  };
  hideChart = () => {
    this.setState({ showChartOpen: false });
  };
  getShirtSizeChart = () => {
    return (
      <div>
        <img src={sizechart} alt="sizechart" width="100%" />
      </div>
    );
  };
  handleRunnerGroupChange = (selectedValues) => {


    this.setState({ selectedRunnerGroup: selectedValues, groupDetails: selectedValues })

    if (selectedValues?.label == "Other") this.setState({ otherGroup: true });
    else this.setState({ otherGroup: false });


  };
  handleCorporateChange = (selectedValues) => {
    this.setState({ selectedCorporate: selectedValues })
    if (selectedValues?.label === "Other") {
      this.setState({ otherCorporate: true })
    } else {
      this.setState({ otherCorporate: false })
    }
  }
  
  handleTypeChange = (selectedValues) => {
    let eventId = ApplicationUtil.getRunnersEventID();

    const {
      eventType,
      completeEventCategories,
      participationType,
      eventParticipantType,
      showParticipationType,
    } = this.state;
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      let catList = [];
      completeEventCategories.forEach((element) => {
        //having supportedActivityType
        if (element?.eventSupportedActivityType?.id) {
          if (
            eventParticipantType === "ALL" &&
            showParticipationType &&
            selectedValues.eventSupportedActivityTypeId &&
            selectedValues.eventSupportedActivityTypeId ===
              element.eventSupportedActivityType.id &&
            element.participantType === participationType?.value
          ) {
            if (!element.hidden) {
              catList.push(element);
            } else {
              return;
            }
          } else if (
            // eventParticipantType !== "ALL" &&
            !showParticipationType &&
            selectedValues.eventSupportedActivityTypeId &&
            selectedValues.eventSupportedActivityTypeId ===
              element.eventSupportedActivityType.id
          ) {
            if (!element.hidden) {
              catList.push(element);
            } else {
              return;
            }
          }
        } else {
          //not having supportedActivityType
          if (this.state.eventType.type === element.activityType.type) {
            catList.push(element);
          }
        }
      });

      this.setState(
        {
          activityType: selectedValues.label,
          eventType: selectedValues,
          eventTypeError: false,
          eventCategoryList: catList,
          eventCategory: catList[0] ? catList[0] : "",
        },
        () => {
          this.handleCategoryChange(this.state.eventCategory);
        }
      );
      // this.SeperateEventTypes(this.state.eventTotalList, selectedValues.value);

      //secondary activity
      if (this.state.showSecondaryActivity) {
        getSecondaryActivity(eventId,selectedValues.id).then((response) => {
          let secondaryActivityList = response.data.secondaryActivityType;
          secondaryActivityList.map(
            (r) => (
              (r.label = r.displayName), (r.value = r.type), (r.id = r.id)
            )
          );
          this.setState({
            secondaryActivityList: secondaryActivityList,
          });
        });
      }
    } else {
      this.setState({
        eventType: selectedValues,
        eventTypeError: true,
      });
    }
  };

  // secondary
  handleSecondaryActivityChange = (selectedValues) => {
    var arr = [];

    if (selectedValues != null) {
      selectedValues.forEach((element) => {
        arr.push(element);
      });

      this.setState({
        selectedSecondaryActivity: arr,
        // activityTypeError: false,
      });
    } else {
      this.setState({
        selectedSecondaryActivity: arr,
        // activityTypeError: true,
      });
    }
  };

  // shipping

  handleCountryChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      this.setState({
        country: selectedValues,
        countryError: false,
      });
    } else {
      this.setState({
        country: selectedValues,
        countryError: true,
      });
    }
  };

  handleCodeChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      this.setState({
        contactCode: selectedValues,
      });
    } else {
      this.setState({
        contactCode: selectedValues,
      });
    }
  };
  handleRunnerCountryCodeChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      this.setState({
        runnerCountryCode: selectedValues,
      });
    } else {
      this.setState({
        runnerCountryCode: selectedValues,
      });
    }
  };
  saveFields = () => {
    const {
      firstName,
      lastName,
      gender,
      bloodGroup,
      email,
      runnerContactNumber,
      runnerCountryCode,
      // age,
      selectedAgeGroup,
      height,
      weight,
      relation,
      medalSelected,
      TShirtSelected,
      shippingRequired,
      city,
      pincode,
      contactCode,
      country,
      state,
      address,
      contactNumber,
      customFieldsParam,
      additionalDetails
    } = this.state;
    let eventId = ApplicationUtil.getRunnersEventID();
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runner?.id;
    let customParams = {
      eventId: eventId,
      runnerId: runnerId,
      firstName: firstName,
      lastName: lastName,
      // email: email,
      // age: age,
      relationWithParticipant: relation.value,
      gender: gender.value,
      //selectedAgeGroup:selectedAgeGroup.id,
      // bloodGroup:bloodGroup.value,
      // height:height,
      // weight:weight,
      categoryId: this.state.eventCategory.value,
      // contactNumber: runnerContactNumber,
      countryCode: runnerCountryCode?.value,
    };
    if(email) customParams.email = email
    if(runnerContactNumber) customParams.contactNumber = runnerContactNumber
    if (this.state.showSecondaryActivity) {
      let secondaryActivityTypes = "";
      if (this.state?.selectedSecondaryActivity) {
        this.state.selectedSecondaryActivity.forEach((i) => {
          secondaryActivityTypes = secondaryActivityTypes?.concat(i.id + ",");
        });
        secondaryActivityTypes = secondaryActivityTypes.replace(/,\s*$/, "");
        customParams.secondaryActivityTypes = secondaryActivityTypes;
      }
    }

    let items = [];
    this.state.eventItems.forEach((element) => {
      if (TShirtSelected && element.itemName == "T-Shirt") {
        let object = {
          id: element.id,
          itemName: element.itemName,
          pricePerUnit: element.pricePerUnit,
          quantity: 1, //element.itemQuantity;
        };

        if (gender.value === "MALE" || gender.value === "OTHER") {
          object.sizeMale = this.state.selectedSize.value;
        }

        if (gender.value === "FEMALE") {
          object.sizeFemale = this.state.selectedSize.value;
        }
        items.push(object);
      } else if (medalSelected) {
        let object = {
          id: element.id,
          itemName: element.itemName,
          pricePerUnit: element.pricePerUnit,
          quantity: 1,
        };
        items.push(object);
      }
    });
    if (medalSelected || TShirtSelected) {
      customParams.eventItems = items;
    }

    if (TShirtSelected) {
      customParams.tshirtSize = this.state.selectedSize.value;
    }
    if (medalSelected) {
      customParams.emedal = true;
    }

    if (medalSelected || TShirtSelected || shippingRequired) {
      customParams.shippingContactNumber = contactNumber;
      customParams.shippingCountryCode = contactCode?.value;
      customParams.city = city;
      customParams.pincode = pincode;
      customParams.state = state;
      customParams.country = country.value;
      customParams.shippingAddress = address;

    }
    if (this.state.showRunnerGroup) {

      if (!this.state.otherGroup) {
        //runnerGroup.map(r => ((r.id = r.id), (r.name = r.name)));
        if (this.state.groupDetails) {
          let details = this.state.groupDetails
          customParams.groupDetails = { name: details?.name, id: details?.id, city: details?.city, groupVisibility: details?.groupVisibility }
          customParams.runnerGroup = details?.name
          customParams.runnerGroupCity = details?.city
        }
      } else if (this.state.runnerGroup || this.state.runnerGroupCity) {


        customParams.groupDetails = { name: this.state.runnerGroup, city: this.state.runnerGroupCity, groupVisibility: false }
        customParams.runnerGroup = this.state.runnerGroup
        customParams.runnerGroupCity = this.state.runnerGroupCity

      }
    }
    if (this.state?.showCorporateOnReg && this.state?.selectedCorporate) {
      if (!this.state?.otherCorporate) {
        customParams.corporateDetails = { id: this.state.selectedCorporate?.id, name: this.state.selectedCorporate?.label }
      } else if (this.state.corporateName) {
        customParams.corporateDetails = { name: this.state.corporateName }
      }
    }
    if (this.state.showAgeGroup && this.state?.selectedAgeGroup) {
      customParams.ageGroupId = this.state?.selectedAgeGroup?.id
    }
   if(additionalDetails?.length > 0){
      let submitFlag = true;

      let fields = [];
      // additional details check
      if (
        additionalDetails &&
        additionalDetails.length > 0
      ) {
        additionalDetails.forEach((element) => {
          if (submitFlag) {
            if (element.groupName) {
              for (let i = 0; i < element.field.length; i++) {
                if (
                  (element.field[i].fieldType.name === "SINGLE_SELECT" ||
                    element.field[i].fieldType.name === "MULTI_SELECT") &&
                  element.field[i].requiredField &&
                  !element.field[i].hidden &&
                  (element?.field[i]?.fieldValue == "" ||
                    element?.field[i]?.fieldValue == undefined)
                ) {
                  element.field[i].isError = true;
                  Snackbar.show({
                    variant: "error",
                    isAutoHide: true,
                    preventDuplicate: true,
                    message: element.field[i].displayName + " is Required",
                  });
                  submitFlag = false;

                  let newData = [...additionalDetails];
                  let index = newData.findIndex(function (obj) {
                    if (obj.field && obj.field.length > 0)
                      return (
                        obj.field[i].customFieldId ==
                        element.field[i].customFieldId
                      );
                  });
                  newData[index].isError = element.field[i].isError
                    ? true
                    : false;
                  this.setState({ additionalDetails: newData });
                  // this.setState({ data: [...data, element] });
                  this.goToTop();
                  submitFlag = false;
                  return;
                } else if (
                  (element.field[i].fieldType.name === "TEXT" ||
                    element.field[i].fieldType.name === "DATE") &&
                  element.field[i].requiredField &&
                  !element.field[i].hidden &&
                  (element?.field[i]?.fieldValue == "" ||
                    element?.field[i]?.fieldValue == undefined ||
                    element?.field[i]?.fieldValue.trim()?.length === 0)
                ) {
                  element.field[i].isError = true;
                  Snackbar.show({
                    variant: "error",
                    isAutoHide: true,
                    preventDuplicate: true,
                    message: element.field[i].displayName + " is Required",
                  });
                  submitFlag = false;

                  let newData = [...additionalDetails];
                  let index = newData.findIndex(function (obj) {
                    if (obj.field && obj.field.length > 0)
                      return (
                        obj.field[i].customFieldId ==
                        element.field[i].customFieldId
                      );
                  });
                  newData[index].isError = element.field[i].isError
                    ? true
                    : false;
                  this.setState({ additionalDetails: newData });
                  // this.setState({ data: [...data, element] });
                  this.goToTop();
                  submitFlag = false;
                  return;
                } else {
                  let obj = {};

                  element.field[i].isError = false;
                  if (element.field[i].fieldType.name == "SINGLE_SELECT" && element.field[i]?.fieldValue) {
                    obj.fieldId = element?.field[i]?.customFieldId;
                   obj.fieldOptionId = element?.field[i]?.fieldOptionId;
                    element.field[i] &&
                      element.field[i]?.fieldValue 
                      ? (obj.fieldValue =
                        element.field[i]?.fieldValue)
                      : (obj.fieldValue = null);
                    fields.push(obj);
                  } else if (
                    element.field[i].fieldType.name == "MULTI_SELECT"
                  ) {
                    if (
                      element.field[i]?.fieldValue &&
                      element.field[i]?.fieldValue?.length > 0
                    ) {
                      element.field[i].fieldValue.forEach((e) => {
                        let newObj = {};
                        newObj.fieldId = element?.field[i]?.customFieldId;
                        newObj.fieldOptionId = e?.id;
                        e && e?.optionValue
                          ? (newObj.fieldValue = e?.optionValue)
                          : (newObj.fieldValue = null);
                        fields.push(newObj);
                      });
                    }
                  } else if (element?.field[i]?.fieldType?.name == "DATE") {
                    obj.fieldId = element?.customFieldId;
                    element.fieldValue
                      ? (obj.dateValue = moment(
                        element?.field[i]?.fieldValue
                      ).format("DD-MM-YYYY"))
                      : (obj.dateValue = null);
                    fields.push(obj);
                  } else {
                    obj.fieldId = element?.field[i]?.customFieldId;
                    element?.field[i]?.fieldValue
                      ? (obj.fieldValue = element?.field[i]?.fieldValue)
                      : (obj.fieldValue = "");
                    fields.push(obj);
                  }

                  if (element.field.length - 1 > i) {
                    submitFlag = false;
                  } else {
                    submitFlag = true;
                    return;
                  }
                }
              }
            } else {
              if (
                (element.fieldType.name === "SINGLE_SELECT" ||
                  element.fieldType.name === "MULTI_SELECT") &&
                element.requiredField &&
                !element?.hidden &&
                (element?.fieldValue === "" ||
                  element?.fieldValue === undefined)
              ) {
                element.isError = true;
                Snackbar.show({
                  variant: "error",
                  isAutoHide: true,
                  preventDuplicate: true,
                  message: element.displayName + " is Required",
                });
                submitFlag = false;
                let newData = [...additionalDetails];
                let index = newData.findIndex(
                  (obj) => obj.customFieldId == element.customFieldId
                );
                newData[index].isError = element.isError ? true : false;
                this.setState({ additionalDetails: newData });
                // this.setState({ data: [...this.state.data, element] });
                this.goToTop();
              } else if (
                (element.fieldType.name === "TEXT" ||
                  element.fieldType.name === "DATE") &&
                element.requiredField &&
                !element?.hidden &&
                (element?.fieldValue === "" ||
                  element?.fieldValue === undefined ||
                  element?.fieldValue?.trim()?.length === 0)
              ) {
                element.isError = true;
                Snackbar.show({
                  variant: "error",
                  isAutoHide: true,
                  preventDuplicate: true,
                  message: element.displayName + " is Required",
                });
                submitFlag = false;
                let newData = [...this.state.additionalDetails];
                let index = newData.findIndex(
                  (obj) => obj.customFieldId == element.customFieldId
                );
                newData[index].isError = element.isError ? true : false;
                this.setState({ additionalDetails: newData });
                // this.setState({ data: [...this.state.data, element] });
                this.goToTop();
              } else {
                let obj = {};
                element.isError = false;
                if (element.fieldType?.name == "SINGLE_SELECT" && element?.fieldValue) {
                  obj.fieldId = element?.customFieldId;
                  obj.fieldOptionId = element?.fieldOptionId;
                  element.fieldValue  
                    ? (obj.fieldValue = element?.fieldValue )
                    : (obj.fieldValue = null);
                  fields.push(obj);
                } else if (element.fieldType?.name == "MULTI_SELECT") {
                  if (element.fieldValue && element?.fieldValue?.length > 0) {
                    element.fieldValue.forEach((e) => {
                      let newObj = {};
                      newObj.fieldId = element?.customFieldId;
                      newObj.fieldOptionId = e?.id;
                      e && e.optionValue
                        ? (newObj.fieldValue = e?.optionValue)
                        : (newObj.fieldValue = null);
                      fields.push(newObj);
                    });
                  }
                } else if (element.fieldType.name == "DATE") {
                  obj.fieldId = element.customFieldId;
                  element.fieldValue
                    ? (obj.dateValue = moment(element?.fieldValue).format(
                      "DD-MM-YYYY"
                    ))
                    : (obj.dateValue = null);
                  fields.push(obj);
                } else {
                  obj.fieldId = element.customFieldId;
                  element?.fieldValue
                    ? (obj.fieldValue = element?.fieldValue)
                    : (obj.fieldValue = "");
                  fields.push(obj);
                }

                submitFlag = true;
                return;
              }
            }
          }
        });
        if (submitFlag) {
          let fieldValues = {
            runnerId: runnerId,
            eventId: eventId,
          };
          fieldValues.fields = fields;
          customParams.fieldValues = fieldValues;
          invitationRegistration(customParams).then((res) => {
            this.props.history.push("/invitationList");
          });
        }
      }

    }else{

      invitationRegistration(customParams).then((res) => {
        this.props.history.push("/invitationList");
      });
    }
    
   
  };

  handleCheckBoxChange = (event) => {
    this.setState({ CheckboxSelected: event.target.checked });
  };

  handleTShirtSelectionChange = (event) => {
    this.setState({
      TShirtSelected: event.target.checked,
    });
  };

  handleClose = () => {
    this.setState({ open: false, openWarning: false });
  };
  handleAgeGroupChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
       
      this.setState({
        selectedAgeGroup: selectedValues,
        ageError: false,
      });
    } else {
      this.setState({
        selectedAgeGroup: selectedValues,
        ageError: true,
      });
    }
  }
  handleGenderChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState(
        {
          gender: selectedValues,
          genderError: false,
        },
        () => {
          let items = this.state.eventItems;
          items.forEach((item) => {
            if ((items.itemName = this.state.tShirtLabel)) {
              if (
                this.state.gender.value == "MALE" ||
                this.state.gender.value == "OTHER"
              ) {
                if (item.sizeMale) {
                  let sizes = item.sizeMale.split(",");
                  let newSizes = [];
                  sizes.forEach((element) => {
                    let object = {};
                    object.label = element;
                    object.value = element;
                    newSizes.push(object);
                  });
                  this.setState({ sizeArray: newSizes });
                }
              } else if (this.state.gender.value == "FEMALE") {
                if (item.sizeFemale) {
                  let sizes = item.sizeFemale.split(",");
                  let newSizes = [];
                  sizes.forEach((element) => {
                    let object = {};
                    object.label = element;
                    object.value = element;
                    newSizes.push(object);
                  });
                  this.setState({ sizeArray: newSizes });
                }
              }
            }
          });
        }
      );
    } else {
      this.setState({
        gender: selectedValues,
        genderError: true,
      });
    }
  };

  handleRelationChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        relation: selectedValues,
        relationError: false,
      });
    } else {
      this.setState({
        relation: selectedValues,
        relationError: true,
      });
    }
  };

  handleCategoryChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        eventCategory: selectedValues,
        eventCategoryError: false,
      });
    } else {
      this.setState({
        eventCategory: selectedValues,
        eventCategoryError: true,
      });
    }
  };
  handleBloodGroupChange=(selectedValues)=>{
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        bloodGroup: selectedValues,
       
      });
    
    }
  }
  handleParticipationTypeChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      let newEventsTypeList = [];
      this.state.allEventTypeList.forEach((element) => {
        if (element.participantType === selectedValues.value) {
          newEventsTypeList.push(element);
        }
      });
      this.setState(
        {
          eventType: newEventsTypeList[0],
          eventTypeList: newEventsTypeList,
          participationType: selectedValues,
          participationTypeError: false,
          isPhysical:
            selectedValues?.value === "REGULAR"
              ? false
              : this.state.eventParticipantType === "ALL"
              ? true
              : false,
        },
        () => {
          this.handleTypeChange(this.state.eventType);
        }
      );
    } else {
      this.setState({
        participationType: selectedValues,
        participationTypeError: true,
      });
    }
  };
  handleSizeChange = (selectedValues) => {
    var arr = [];

    if (selectedValues != null) {
      arr.push(selectedValues.value);
      this.setState({
        selectedSize: selectedValues,
        selectedSizeError: false,
      });
    } else {
      this.setState({
        selectedSizeError: true,
      });
    }
  };

  goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  ValidateFields = () => {
    const {
      firstName,
      gender,
      bloodGroup,
      selectedAgeGroup,
      bloodGroupError,
      email,
      emailError,
      height,
      weight,
      heightError,
      weightError,
      runnerContactNumber,
      runnerContactNumberError,
      activityType,
      // age,
      // relation,
      TShirtSelected,
      medalSelected,
      shippingRequired,
      keepSame,
      additionalDetails
    } = this.state;

    // if (relation == "") {
    //   this.setState({ relationError: true });
    //   Snackbar.show({
    //     variant: "error",
    //     isAutoHide: true,
    //     preventDuplicate: true,
    //     message: "Please select relation with participant.",
    //   });
    //   this.goToTop();
    // } else
    if (firstName == "") {
      this.setState({ firstNameError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter first name.",
      });
      this.goToTop();
    } else if (
      this.state.supportedDomainList?.length > 0 && email &&
      !this.validateEmailDomain(email)
    ) {
      this.setState({ emailError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message:  "Email domain is not supported for this event." 
      });
      this.goToTop();
    } else if (runnerContactNumber == "" && (email == "" || emailError == true || !this.validateEmailDomain(email))) {
      this.setState({ emailError: true,runnerContactNumberError: true  });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: !this.validateEmailDomain(email) ? "Email domain is not supported for this event." : "Please enter valid email address or valid contact number.",
      });
      this.goToTop();
    } else if (
     ( email=="" || !this.validateEmailDomain(email))&&
    (  runnerContactNumber == "" ||
      // runnerContactNumber?.length !== 10
      !ApplicationUtil.validateContactNumber(runnerContactNumber))
    ) {
      this.setState({ runnerContactNumberError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message:!this.validateEmailDomain(email) ? "Email domain is not supported for this event." : "Please enter valid email address or valid contact number.",
      });
      this.goToTop();
    }  
     else if (gender == "") {
      this.setState({ genderError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select gender.",
      });
      this.goToTop();
    }
    
    else if (this.state?.showAgeGroup && selectedAgeGroup == undefined) {
      this.setState({ ageError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select Age Group.",
      });
      this.goToTop();
    }
    // if (age == "") {
    //   this.setState({ ageError: true });
    //   Snackbar.show({
    //     variant: "error",
    //     isAutoHide: true,
    //     preventDuplicate: true,
    //     message: "Please enter age.",
    //   });
    //   this.goToTop();
    // } else
    else if (this.state.eventCategory == "") {
      if (this.state.eventType == "") {
        this.setState({ eventTypeError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please select Activity type.",
        });
      } else {
        this.setState({ eventCategoryError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please select Event Category.",
        });
        this.goToTop();
      }
    } else if (
      this.state.TShirtSelected &&
      (this.state.selectedSize == "" || this.state.selectedSize == null)
    ) {
      this.setState({ selectedSizeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select T-Shirt Size.",
      });
      this.goToTop();
    } else if (
      (TShirtSelected || medalSelected || shippingRequired) &&
      (this.state.contactNumber == "" ||
        // this.state.contactNumber?.length !== 10
        !ApplicationUtil.validateContactNumber(this.state.contactNumber))
    ) {
      this.setState({ contactNumberError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter valid contact number for shipping.",
      });
      this.goToTop();
    } else if (
      (TShirtSelected || medalSelected || shippingRequired) &&
      (this.state?.address == "" || this.state?.address?.trim()?.length === 0)
    ) {
      this.setState({ addressError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter shipping address.",
      });
      this.goToTop();
    } else if (
      (TShirtSelected || medalSelected || shippingRequired) &&
      (this.state?.city == "" || this.state?.city?.trim()?.length === 0)
    ) {
      this.setState({ cityError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter city.",
      });
      this.goToTop();
    } else if (
      (TShirtSelected || medalSelected || shippingRequired) &&
      (this.state?.pincode == "" || this.state?.pincode?.trim()?.length === 0)
    ) {
      this.setState({ pincodeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter pincode.",
      });
      this.goToTop();
    } else if (
      (TShirtSelected || medalSelected || shippingRequired) &&
      (this.state?.state == "" || this.state?.state?.trim()?.length === 0)
    ) {
      this.setState({ stateError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter State.",
      });
      this.goToTop();
    } else if (
      (TShirtSelected || medalSelected || shippingRequired) &&
      this.state?.country == ""
    ) {
      this.setState({ countryError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select country.",
      });
      this.goToTop();
    } else {
      this.saveFields();
    }
  };

  validateEmailDomain = (value) => {
    let domainValid = false;
    let domain = value.split("@");
    domain = domain[1].toLowerCase();
    debugger
    if (
      this.state.supportedDomainList &&
      this.state.supportedDomainList.length > 0
    ) {
      this.state.supportedDomainList.map((e) => {
        if (e.validationDomain.toLowerCase() == domain) {
          domainValid = true;
        }
      });
      return domainValid;
    } else {
      return true;
    }
  };

  validateEmailId = (value) => {
    let isValidEmail = validateEmail(value);
    if (isValidEmail) {
      this.setState({ email: value, emailError: false });
    } else {
      this.setState({ emailError: true, email: value });
    }
  };
  handleMedalChange = (event) => {
    this.setState({
      medalSelected: event.target.checked,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      firstNameError,
      firstName,
      heightError,
      weightError,
      genderError,
      ageError,
      emailError,
      runnerContactNumberError,
      runnerContactNumber,
      keepSame,
      description,
      showSecondaryActivity,
      selectedSecondaryActivity,
      secondaryActivityList,
      contactNumber,
      contactNumberError,
      eventParticipantType,
      showParticipationType,
      participationType,
      participationTypeError,
      participationTypeList,
      additionalDetails,
      hideCustomSectionLabel,
      customSectionLabel,
      showActivity,
      showCategory
    } = this.state;
    let eventId = ApplicationUtil.getRunnersEventID();
    return (
      <React.Fragment>
        <AppToolBar
          backNavigate={() => this.props.history.goBack()}
          showBack={true}
        >
          <Header {...this.props} label={Properties.Label_InvitationFormPage} />
        </AppToolBar>

        <div className={classes.mainPanel}>
          <Grid
            style={{
              width: "100%",
              margin: "0px",
            }}
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.innerGrid}
            >
              {/* <Paper> */}
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>
                  {Properties.Label_FirstName}
                </span>
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <CustomTextField
                style={
                  firstNameError
                    ? {
                        border: "1px solid red",
                        height: "40px",
                        maxHeight: "40px",
                        minHeight: "40px",
                        // width: "80%",
                        width: isBrowser ? "80%" : "100%",
                      }
                    : {
                        border: "1px solid #08456C",
                        height: "40px",
                        maxHeight: "40px",
                        minHeight: "40px",
                        width: isBrowser ? "80%" : "100%",
                      }
                }
                type={"text"}
                error={firstNameError}
                keyToSet={"firstName"}
                value={this.state.firstName}
                handleChange={(prop) => (event) => {
                  this.setState({
                    firstName: event.target.value,
                    firstNameError: false,
                  });
                }}
              />
              {/* </Paper> */}
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>
                  {Properties.Label_LastName}
                </span>
              </Typography>
              <CustomTextField
                style={{
                  border: "1px solid #08456C",
                  height: "40px",
                  maxHeight: "40px",
                  minHeight: "40px",
                  width: isBrowser ? "80%" : "100%",
                }}
                type={"text"}
                keyToSet={"lastName"}
                value={this.state.lastName}
                handleChange={(prop) => (event) => {
                  this.setState({ lastName: event.target.value });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>
                  {Properties.Label_Email}
                </span>
                <span style={{ color: "red" }}>*</span>
              </Typography>{" "}
              <CustomTextField
                style={
                  emailError
                    ? {
                        border: "1px solid red",
                        height: "40px",
                        maxHeight: "40px",
                        minHeight: "40px",
                        width: isBrowser ? "80%" : "100%",
                      }
                    : {
                        border: "1px solid #08456C",
                        height: "40px",
                        maxHeight: "40px",
                        minHeight: "40px",
                        width: isBrowser ? "80%" : "100%",
                      }
                }
                type={"email"}
                keyToSet={"email"}
                value={this.state.email}
                handleChange={(prop) => (event) => {
                  this.validateEmailId(event.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>
                  {Properties.Label_ContatNumber}
                </span>
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <div
                item
                style={{ display: "flex", width: isBrowser ? "80%" : "100%" }}
              >
                <div
                  style={{
                    width: "30%",
                    // isBrowser ? "200px" : "160px"
                  }}
                >
                  <MultiSelect
                    value={this.state.runnerCountryCode}
                    options={this.state.countryCodeList}
                    onChange={this.handleRunnerCountryCodeChange}
                  />
                </div>

                <div style={{ width: "70%" }}>
                  <CustomTextField
                    style={
                      runnerContactNumberError
                        ? {
                            border: "1px solid red",
                            height: "40px",
                            maxHeight: "40px",
                            minHeight: "40px",
                            marginLeft: "2px",
                            width: "100%",
                          }
                        : {
                            border: "1px solid #08456C",
                            height: "40px",
                            maxHeight: "40px",
                            minHeight: "40px",
                            marginLeft: "2px",
                            width: "100%",
                          }
                    }
                    type={"number"}
                    isNumber={true}
                    value={runnerContactNumber}
                    error={runnerContactNumberError}
                    keyToSet={"runnerContactNumber"}
                    handleChange={(prop) => (event) => {
                      if (keepSame) {
                        this.setState({
                          runnerContactNumber: event.target.value,
                          runnerContactNumberError:
                            ApplicationUtil.validateContactNumber(
                              event.target.value
                            )
                              ? false
                              : true,
                          contactNumber: event.target.value,
                        });
                      } else {
                        this.setState({
                          runnerContactNumber: event.target.value,
                          runnerContactNumberError:
                            ApplicationUtil.validateContactNumber(
                              event.target.value
                            )
                              ? false
                              : true,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>
                  {Properties.Label_Gender}
                </span>
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <div style={{ width: isBrowser ? "80%" : "100%" }}>
                <MultiSelect
                  xs={4}
                  value={this.state.gender}
                  error={this.state.genderError}
                  options={this.state.genderList}
                  onChange={this.handleGenderChange}
                />
              </div>
            </Grid>
          
            {eventParticipantType === "ALL" && showParticipationType && (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography variant="body2" gutterBottom>
                  <span style={{ color: "#727272" }}>
                    {Properties.ParticipationLabel}
                  </span>
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <div style={{ width: isBrowser ? "80%" : "100%" }}>
                  <MultiSelect
                    value={participationType}
                    error={participationTypeError}
                    options={participationTypeList}
                    onChange={this.handleParticipationTypeChange}
                  />
                </div>
              </Grid>
            )}

           {showActivity && <> <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>
                  {Properties.Activity_type_label}
                </span>
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <div style={{ width: isBrowser ? "80%" : "100%" }}>
                <MultiSelect
                  value={this.state.eventType}
                  error={this.state.eventTypeError}
                  options={
                    this.state.newEventTypeList.length > 0
                      ? this.state.newEventTypeList
                      : this.state.eventTypeList
                  }
                  onChange={this.handleTypeChange}
                />
              </div>
            </Grid>

            {/* secondary activity */}
            {showSecondaryActivity && (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.helpContainerCss}
                >
                  <span style={{ color: "#727272" }}>
                    {Properties.Secondary_Activity_type_label}
                  </span>
                  <span className={classes.helpCss}>
                    <Tooltip title={Properties.SecondaryActivityInfoText}>
                      <InfoIcon fontSize="small" />
                    </Tooltip>
                  </span>
                </Typography>
                <div style={{ width: isBrowser ? "80%" : "100%" }}>
                  <MultiSelect
                    isMulti={true}
                    value={selectedSecondaryActivity || ""}
                    // error={activityTypeError}
                    options={secondaryActivityList}
                    onChange={(e) => {
                      this.handleSecondaryActivityChange(e);
                    }}
                  />
                </div>
              </Grid>
            )}</> }
            {/* </Grid> */}
            {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.innerGrid}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>
                {Properties.Height_label} {Properties.Label_unit_cm}
                </span>
              </Typography>
               
              <CustomTextField
                  style= {{
                        border: "1px solid #08456C",
                        height: "40px",
                        maxHeight: "40px",
                        minHeight: "40px",
                        width: isBrowser ? "80%" : "100%",
                      }}
                  placeholder={"00"}
                  type={"number"}
                  keyToSet={"height"}
                  value={this.state.height}
                  handleChange={(prop) => (event) => {
                    this.setState({ height: event.target.value });
                  }}
                />
              
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.innerGrid}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>
                {Properties.Weight_label} {Properties.Label_Unit_kg}
                </span>
              </Typography>
              <CustomTextField
                  style={{
                        border: "1px solid #08456C",
                        height: "40px",
                        maxHeight: "40px",
                        minHeight: "40px",
                        width: isBrowser ? "80%" : "100%",
                      }}
                  placeholder={"00"}
                  type={"number"}
                  keyToSet={"weight"}
                  value={this.state.weight}
                  handleChange={(prop) => (event) => {
                    this.setState({ weight: event.target.value});
                  }}
                />
            </Grid> */}
          
                {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>
                {Properties.Blood_Group_Label}
                </span>
              
              </Typography>
              <div style={{ width: isBrowser ? "80%" : "100%" }}>
              <MultiSelect 
               value={this.state.bloodGroup}
                      options={ApplicationConstants.bloodGroupList}
                     onChange={this.handleBloodGroupChange}
                    />
              </div>
            </Grid> */}
            {showCategory && <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>
                  {Properties.Label_EventCategory}
                </span>
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <div style={{ width: isBrowser ? "80%" : "100%" }}>
                <MultiSelect
                  value={this.state.eventCategory}
                  error={this.state.eventCategoryError}
                  options={this.state.eventCategoryList}
                  onChange={this.handleCategoryChange}
                />
              </div>{" "}
            </Grid>}
          </Grid>
        
          {this.state.showRunnerGroup && (
                  <Grid container
                     
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{marginTop:'5px'}}>
                      <Typography variant="body2" gutterBottom>
                        <span style={{ color: "#727272" }}>
                          {Properties.Label_FitnessGroup}
                        </span>
                      </Typography>{" "}
                      <div
                        style={{ width: isBrowser ? "80%" : "100%" }}
                      >
                        <MultiSelect
                          isGroup
                          groupedOptions={this.state.runnerGroupList}
                          isClearable
                          formatOptionLabel={(option, { context }) => {
                            return context === 'value' ? option?.city ? `${option.label} (${option.city.toUpperCase()})` : option.label : option.label
                          }
                          }
                          // options={this.state.runnerGroupList}
                          value={this.state.selectedRunnerGroup}
                          onChange={this.handleRunnerGroupChange}
                        />
                      </div>
                    </Grid>
                    {this.state.otherGroup && (
                      <Grid container spacing={1} style={{ margin: '0px' }} >

                        <Grid item xs={6}>
                          <Typography variant="body2" gutterBottom>
                            <span style={{ color: "#727272" }}>
                              {'Group Name'}
                            </span>
                          </Typography>
                          <CustomTextField
                            style={{
                              border: "1px solid #08456C",
                              height: "48%",
                              maxHeight: "48%",
                              minHeight: "48%",
                              // width:'100%'
                              // marginTop: "21px",
                              width: isBrowser ? "360px" : "160px",
                            }}
                            type={"text"}
                            keyToSet={"runnerGroup"}
                            value={this.state.runnerGroup}
                            handleChange={(prop) => (event) => {
                              this.setState({ runnerGroup: event.target.value });
                            }}
                          />

                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" gutterBottom>
                            <span style={{ color: "#727272" }}>
                              {Properties.Label_City}
                            </span>
                          </Typography>
                          <CustomTextField
                            style={{
                              border: "1px solid #08456C",
                              height: "48%",
                              maxHeight: "48%",
                              minHeight: "48%",
                              // marginTop: "21px",
                              width: isBrowser ? "360px" : "160px",
                            }}
                            type={"text"}
                            keyToSet={"runnerGroup"}
                            value={this.state.runnerGroupCity}
                            handleChange={(prop) => (event) => {
                              this.setState({ runnerGroupCity: event.target.value });
                            }}
                          />

                        </Grid>
                      </Grid>

                    )}
                
                     {this.state?.showCorporateOnReg && (
                       <>
                         <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  style={{marginTop:'5px'}}>
                           <Typography variant="body2" gutterBottom>
                             <span style={{ color: "#727272" }}>
                               {Properties.Label_Corporate}
                             </span>
     
                           </Typography>{" "}
                           <div
                             style={{ width: isBrowser ? "80%" : "100%" }}
                           >
                             <MultiSelect
                               isClearable
                               options={this.state.corporateList}
                               value={this.state.selectedCorporate}
                               onChange={this.handleCorporateChange}
                             />
                           </div>
                         </Grid>
                        
                         {this.state?.otherCorporate && (
                           <Grid container spacing={1} xs={12} style={{ margin: "0%" }} >
     
                             <Grid item xs={6} lg={12}>
                               <Typography variant="body2" gutterBottom>
                                 <span style={{ color: "#727272" }}>
                                   {'Other'}
                                 </span>
                               </Typography>
                               <CustomTextField
                                 style={{
                                   border: "1px solid #08456C",
                                   height: "48%",
                                   maxHeight: "48%",
                                   minHeight: "48%",
                                   // width:'100%'
                                   // marginTop: "21px",
                                   width: isBrowser ? "360px" : "160px",
                                 }}
                                 type={"text"}
                                 keyToSet={"corporate"}
                                 value={this.state.corporateName}
                                 handleChange={(prop) => (event) => {
                                   this.setState({ corporateName: event.target.value });
                                 }}
                               />
     
                             </Grid>
     
                           </Grid>
     
                         )}
                      </>
                     )}
                       </Grid>
                )}
             
             {this.state.showAgeGroup && (
                  
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  style={{marginTop:'5px'}}>
                    <Typography variant="body2" gutterBottom>
                      <span style={{ color: "#727272" }}>
                        {Properties.Label_AgeGroup}
                      </span>
                      <span style={{ color: 'red' }} >*</span>
                    </Typography>{" "}
                    <div
                      style={{ width: isBrowser ? "80%" : "100%" }}
                    >
                      <MultiSelect
                        options={this.state.ageGroupList}
                        error={this.state.ageError}
                        value={this.state.selectedAgeGroup}
                        onChange={this.handleAgeGroupChange}
                      />
                    </div>
                  </Grid>

              )}
          {description && (
            <>
              {description.map(
                (desc) =>
                  this.state.eventType &&
                  this.state.eventCategory &&
                  (desc.label === this.state.eventType.value &&
                  desc.category === this.state.eventCategory.categoryName ? (
                    <Grid
                      container
                      style={{
                        width: "100%",
                        // height: "75px",
                        margin: "0px",
                        marginLeft: "5px",
                      }}
                      xs={10}
                    >
                      <Typography variant="body2" gutterBottom>
                        <span>
                          <b style={{ color: "rgb(40, 131, 181)" }}>
                            {desc.label}
                          </b>
                        </span>
                        <br />
                        {desc.ticketSoldOut ? (
                          <span style={{ color: "#ff0000" }}>{"SOLD OUT"}</span>
                        ) : (
                          <span>{desc.desc}</span>
                        )}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  ))
              )}
            </>
          )}

          {/* {this.state.tShirt && (
            <Grid
              style={{ width: "100%", margin: "0px" }}
              container
              spacing={1}
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ display: "inline" }}
                >
                  <span style={{ fontSize: "16px", color: "#727272" }}>
                    <input
                      type="checkbox"
                      onChange={this.handleTShirtSelectionChange}
                      value={this.state.TShirtSelected}
                    />
                    {"T-Shirt"}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          )} */}
          {/* {this.state.TShirtSelected && (
            <Grid
              style={{ width: "100%", margin: "0px" }}
              container
              spacing={1}
            >
            
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ display: "inline" }}
                >
                  <span style={{ color: "#727272" }}>
                    {Properties.Label_Select_Size}
                  </span>
                  <span style={{ color: "red" }}>*</span>{" "}
                  {!this.state.sizeArray?.length > 0 && (
                    <span style={{ fontSize: "12px", color: "#727272" }}>
                      (Please select gender)
                    </span>
                  )}
                </Typography>
                <Grid item style={{ display: "flex" }}>
                  <div style={{ width: isBrowser ? "360px" : "160px" }}>
                    <MultiSelect
                      value={this.state.selectedSize}
                      error={this.state.selectedSizeError}
                      options={this.state.sizeArray}
                      onChange={this.handleSizeChange}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "8px",
                    }}
                  >
                    <a onClick={this.viewChart}>Size Chart</a>
                  </div>
                </Grid>
              </Grid>
              
            </Grid>
          )} */}

          {/* {(this.state.TShirtSelected &&  (eventId==1879 ||  eventId==507 ||  eventId==508)) ? (
            <Grid
              style={{ width: "100%", margin: "0px" }}
              container
              spacing={1}
            >
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ display: "inline" }}
                >
                  <span style={{ fontSize: "16px", color: "#727272" }}>
                    <input
                      type="checkbox"
                      onChange={this.handleMedalChange}
                      value={this.state.medalSelected}
                    />
                    {Properties.Label_Medal}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          ):(this.state.medal &&  (eventId!=1879 || eventId==507 ||  eventId==508)) ? 
           <Grid
          style={{ width: "100%", margin: "0px" }}
          container
          spacing={1}
        >
          <Grid item xs={12}>
            <Typography
              variant="body2"
              gutterBottom
              style={{ display: "inline" }}
            >
              <span style={{ fontSize: "16px", color: "#727272" }}>
                <input
                  type="checkbox"
                  onChange={this.handleMedalChange}
                  value={this.state.medalSelected}
                />
                   {this.state?.medalLabel ? this.state.medalLabel : Properties.Label_Medal}
              </span>
            </Typography>
          </Grid>
        </Grid>:"" } */}
        </div>
        <div className={classes.innerPanel}>
          {/* {(this.state.shippingRequired ||
            this.state.TShirtSelected ||
            this.state.medalSelected) && (
            <Grid
              style={{
                borderTop: "1px solid rgb(13 108 169",
                marginTop: "10px",
              }}
            >
              <Typography variant="body2" gutterBottom>
                <span
                  style={{
                    color: "#2883B5",
                    fontSize: "23px",
                    fontWeight: "bold",
                    padding: "2px 0px",
                  }}
                >
                  Shipping Address
                </span>
              </Typography>
              <Grid
                style={{
                  width: "100%",
                  margin: "0px",
                }}
                container
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "#727272" }}>
                      {Properties.Label_ContatNumber}
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <div
                    item
                    style={{
                      display: "flex",
                      width: isBrowser ? "80%" : "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                        // isBrowser ? "200px" : "160px"
                      }}
                    >
                      <MultiSelect
                        value={this.state.contactCode}
                        options={this.state.countryCodeList}
                        onChange={this.handleCodeChange}
                      />
                    </div>

                    <div style={{ width: "70%" }}>
                      <CustomTextField
                        style={
                          this.state.contactNumberError
                            ? {
                                border: "1px solid red",
                                height: "40px",
                                maxHeight: "40px",
                                minHeight: "40px",
                                marginLeft: "2px",
                                width: "100%",
                              }
                            : {
                                border: "1px solid #08456C",
                                height: "40px",
                                maxHeight: "40px",
                                minHeight: "40px",
                                marginLeft: "2px",
                                width: "100%",
                              }
                        }
                        type={"number"}
                        isNumber={true}
                        defaultValue={runnerContactNumber}
                        value={this.state.contactNumber}
                        error={this.state.contactNumberError}
                        keyToSet={"contactNumber"}
                        handleChange={(prop) => (event) => {
                          this.setState({
                            contactNumber: event.target.value,
                            contactNumberError:
                              ApplicationUtil.validateContactNumber(
                                event.target.value
                              )
                                ? false
                                : true,
                            keepSame: false,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="body2" gutterBottom>
                    <span
                      style={{
                        color: "#727272",
                        marginBottom: "4px",
                        marginTop: "5px",
                      }}
                    >
                      {Properties.shipping_Address_Label}
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <CustomTextField
                    style={
                      this.state.addressError
                        ? {
                            border: "1px solid red",
                            height: "40px",
                            maxHeight: "40px",
                            minHeight: "40px",
                            width: isBrowser ? "80%" : "100%",
                          }
                        : {
                            border: "1px solid #08456C",
                            height: "40px",
                            maxHeight: "40px",
                            minHeight: "40px",
                            width: isBrowser ? "80%" : "100%",
                          }
                    }
                    type={"text"}
                    error={true}
                    keyToSet={"city"}
                    readOnly={this.state.isReadOnly}
                    value={this.state.address}
                    handleChange={(prop) => (event) => {
                      this.setState({
                        address: event.target.value,
                        addressError: false,
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "#727272" }}>
                      {Properties.Label_City}
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <CustomTextField
                    style={
                      this.state.cityError
                        ? {
                            border: "1px solid red",
                            height: "40px",
                            maxHeight: "40px",
                            minHeight: "40px",
                            width: isBrowser ? "80%" : "100%",
                          }
                        : {
                            border: "1px solid #08456C",
                            height: "40px",
                            maxHeight: "40px",
                            minHeight: "40px",
                            width: isBrowser ? "80%" : "100%",
                          }
                    }
                    type={"text"}
                    error={true}
                    keyToSet={"city"}
                    readOnly={this.state.isReadOnly}
                    value={this.state.city}
                    handleChange={(prop) => (event) => {
                      this.setState({
                        city: event.target.value,
                        cityError: false,
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "#727272" }}>
                      {Properties.Label_Postal}
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <CustomTextField
                    style={
                      this.state.pincodeError
                        ? {
                            border: "1px solid red",
                            height: "40px",
                            maxHeight: "40px",
                            minHeight: "40px",
                            width: isBrowser ? "80%" : "100%",
                          }
                        : {
                            border: "1px solid #08456C",
                            height: "40px",
                            maxHeight: "40px",
                            minHeight: "40px",
                            width: isBrowser ? "80%" : "100%",
                          }
                    }
                    type={"text"}
                    keyToSet={"postaclCode"}
                    readOnly={this.state.isReadOnly}
                    error={this.state.pincodeError}
                    value={this.state.pincode}
                    handleChange={(prop) => (event) => {
                      this.setState({
                        pincode: event.target.value,
                        pincodeError: false,
                      });
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ height: "75px" }}
                >
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "#727272" }}>
                      {Properties.Label_State}
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Typography>{" "}
                  <CustomTextField
                    style={
                      this.state.stateError
                        ? {
                            border: "1px solid red",
                            height: "40px",
                            maxHeight: "40px",
                            minHeight: "40px",
                            width: isBrowser ? "80%" : "100%",
                          }
                        : {
                            border: "1px solid #08456C",
                            height: "40px",
                            maxHeight: "40px",
                            minHeight: "40px",
                            width: isBrowser ? "80%" : "100%",
                          }
                    }
                    type={"text"}
                    keyToSet={"state"}
                    value={this.state.state}
                    error={this.state.stateError}
                    handleChange={(prop) => (event) => {
                      this.setState({
                        state: event.target.value,
                        stateError: false,
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "#727272" }}>
                      {Properties.Label_Country}
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <div style={{ width: isBrowser ? "80%" : "100%" }}>
                    <MultiSelect
                      value={this.state.country}
                      error={this.state.countryError}
                      options={this.state.countryList}
                      onChange={this.handleCountryChange}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )} */}

{additionalDetails?.length > 0 &&
<div className={classes.customFieldContainer}>

<CustomFields
detailsData={additionalDetails}
hideCustomSectionLabel={hideCustomSectionLabel}
customSectionLabel={customSectionLabel}
updateDetailsData={(updatedData)=>this.setState({additionalDetails:updatedData})}
/>
</div>
}

          <Grid
            style={{
              width: "100%",
              height: "75px",
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
            container
            spacing={1}
          >
            <Grid
              style={{
                marginTop: "5%",
                display: "flex",
                justifyContent: "center",
              }}
              item
              xs={5}
            >
              <SubmitCancelBtn
                onClick={() => this.ValidateFields()}
                label={"Invite"}
                type="submit"
                isDisabled={
                  this.state.eventCategory?.ticketSoldOut ? true : false
                }
              />
            </Grid>
          </Grid>
       
          <DialogBox
            open={this.state.showChartOpen}
            message={this.state.dailogMsg}
            header="T-Shirt Size Chart"
            onClick={() => this.hideChart()}
            closeDisloag={() => {
              this.hideChart();
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }),withRouter)(
  InvitationFormContainer
);

/* <Grid
            style={{ width: "100%", height: "75px", margin: "0px" }}
            container
            spacing={1}
          > */
{
  /*<Grid item xs={12}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>
                  {Properties.label_RelationWithParticipant}
                </span>
                <span style={{ color: "red" }}>*</span>
              </Typography>

              <MultiSelect
                value={this.state.relation}
                error={this.state.relationError}
                options={this.state.relationList}
                onChange={this.handleRelationChange}
              />
            </Grid> */
}
{
  /* </Grid> */
}
{
  /* <Grid item xs={6}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>{Properties.Label_age}</span>
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <CustomTextField
                style={
                  this.state.ageError
                    ? {
                        border: "1px solid red",
                        height: "45%",
                        maxHeight: "45%",
                        minHeight: "45%",
                        //width: "50%"
                      }
                    : {
                        border: "1px solid #08456C",
                        height: "45%",
                        maxHeight: "45%",
                        minHeight: "45%",
                        width: "100%",
                      }
                }
                type={"number"}
                isNumber={true}
                keyToSet={"age"}
                value={this.state.age}
                error={this.state.ageError}
                handleChange={(prop) => (event) => {
                  this.setState({ age: event.target.value, ageError: false });
                }}
              />
            </Grid> */
}
