import React, { useEffect, useState, useMemo } from "react";
import PublishIcon from "@material-ui/icons/Publish";
import { useDropzone } from "react-dropzone";
import ApplicationUtil from "../../utils/ApplicationUtil";

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export function FileUploader(props) {
  const [files, setFiles] = useState([]);
  const specifiedWidth = props?.selectedRow?.width,
    specifiedHeight = props?.selectedRow?.height,
    specifiedMaxWidth = props?.selectedRow?.maxWidth,
    specifiedMaxHeight = props?.selectedRow?.maxHeight,
    exactSize = props?.selectedRow?.exactSize
      ? props?.selectedRow?.exactSize
      : false;

  // const specifiedAspectRatio = Math.ceil(specifiedWidth / specifiedHeight);
  const specifiedAspectRatio = ApplicationUtil.getAspectRatio(
    specifiedWidth,
    specifiedHeight
  );

  function fileSizeValidator(file) {
    let imgAspectRatio = ApplicationUtil.getAspectRatio(
      file.width,
      file.height
    );

    if (props.selectedRow?.acceptAllSizes) {
      return null;
    } else if (
      exactSize === true &&
      (file.width != specifiedWidth || file.height != specifiedHeight)
    ) {
      return {
        code: "file too small",
        message: `Image size should should be ${specifiedWidth}px * ${specifiedHeight}px`,
      };
    } else if (
      file.height < specifiedHeight ||
      file.height > specifiedMaxHeight ||
      file.width < specifiedWidth ||
      file.width > specifiedMaxWidth
    ) {
      return {
        code: "file too small",
        message: `Image size should be within ${specifiedWidth}px * ${specifiedHeight}px -
         ${specifiedMaxWidth}px * ${specifiedMaxHeight}px`,
        // // ``Please select image with approx dimension*  px`,
      };
    }
    // else if (specifiedAspectRatio != imgAspectRatio) {
    //   return {
    //     code: "file too small",
    //     message: `Please select image with approx aspect ratio ${specifiedAspectRatio}`,
    //   };
    // }

    return null;
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    noDragEventsBubbling: props?.isReadOnly ? props.isReadOnly : false,
    noClick: props?.isReadOnly ? props.isReadOnly : false,
    accept: "image/*", //".jpeg,.png,.jpg", ///x-png,image/jpeg",
    maxFiles: props?.maxFiles ? props.maxFiles : null,
    multiple: props?.multiple,
    getFilesFromEvent: async (event) => {
      const files = event?.target?.files;
      const promises = [];
      for (let index = 0; index < files?.length; index++) {
        const file = files[index];
        const promise = new Promise((resolve, reject) => {
          const image = new Image();
          let url = "";
          image.onload = function () {
            file.width = image.width;
            file.height = image.height;
            resolve(file);
          };
          url = URL.createObjectURL(file);
          image.src = url;
        });
        promises.push(promise);
      }
      return await Promise.all(promises);
    },
    validator: fileSizeValidator,
    onDrop: (acceptedFiles) => {
      let newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles(newFiles);
      let data = {};
      data = { selectedRow: props.selectedRow, selectedFiles: newFiles };
      // if (fileRejections?.length > 0) {

      // } else
      if (acceptedFiles.length > 0) {
        props.filesUploaded(data);
      }
    },
  });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <>
      {" "}
      {errors.map((e) => (
        <span key={e.code}>{e.message}</span>
      ))}
    </>
  ));

  let baseStyle = {
    position: "relative",
    width: props.isSmall ? "200px" : "200px",
    height: props.isSmall ? "120px" : "120px",
    textAlign: "center",
    padding: props.isSmall ? "10px 5px" : "40px 20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };
  let style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  // }

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div>
      <section className="container">
        <div {...getRootProps({ style })}>
          <div>
            <input {...getInputProps()} />
            <div>
              <PublishIcon />
            </div>
            <div>Drag image or click to select from device</div>
          </div>{" "}
          {fileRejectionItems?.length > 0 && (
            <div style={{ color: "red" }}>{fileRejectionItems}</div>
          )}
        </div>
        {/* <aside style={thumbsContainer}>{thumbs}</aside> */}
      </section>
    </div>
  );
}
