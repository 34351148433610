import { withStyles } from "@material-ui/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import ParticipantProgramDashboard from "../registration/program/ParticipantProgramDashboard";
import OrganizerProgramDashboard from "../support/organizer/OrganizerProgram/OrganizerProgramDashboard";

function ProgramDashboard({ classes, history, isOrganizer }) {
  if (isOrganizer) {
    return <OrganizerProgramDashboard />;
  } else {
    return <ParticipantProgramDashboard />;
  }
}

const styles = (theme) => ({});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ProgramDashboard);
