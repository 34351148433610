import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import Autosuggest from "react-autosuggest";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import SubmitCancelBtn from "../../../../../common/SubmitCancelBtn";
import Properties from "../../../../../../properties/Properties";
import { programChallengeRunnerListForJobSync } from "../../../../../../apis/eventService";
import { getChallengeResponseResults } from "../../../../../../apis/challengeService";
import Snackbar from "../../../../../common/Snackbar";
import ChallengeResponseResult from "./ChallengeResponseResult";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";

function ChallengeSearchBar({
  classes,
  challengeId,
  showChallengeResults,
  isProgram,
  clearResult,
}) {
  const [runnerId, setRunnerId] = useState("");
  const [runnerName, setRunnerName] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");
  const [searchvalue, setSearchValue] = useState("");
  const [responseData, setResponseData] = useState();

  useEffect(() => {
    // getting the registered runner list for the particular challenge of program
    programChallengeRunnerListForJobSync({ challengeId }).then((res) => {
      if (res?.data) {
        let autoValues = [];
        res.data.map((value) => {
          autoValues.push({
            firstName: value.firstName,
            lastName: value.lastName,
            runnerId: value.runnerId,
          });
        });
        setSearchValue(autoValues);
      }
    });
    setValue("");
  }, [challengeId]);

  const renderSuggestion = (suggestion) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>
        <DirectionsRunIcon
          style={{
            color: "#09456C",
          }}
        />
      </div>
      <div>
        {`${suggestion.firstName} ${
          suggestion.lastName ? suggestion.lastName : ""
        } (RunnerId- ${suggestion.runnerId})`}
      </div>
    </div>
  );

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (searchvalue) {
      return inputLength === 0
        ? []
        : searchvalue.filter(
            (val) =>
              val?.firstName?.toLowerCase().slice(0, inputLength) ===
                inputValue ||
              val?.runnerId?.toString().slice(0, inputLength) === inputValue
          );
    }
  };

  const getSuggestionValue = (suggestion) => {
    setRunnerId(suggestion.runnerId);
    setRunnerName(`${suggestion.firstName} ${suggestion?.lastName || ""}`);
    return `${suggestion.firstName} ${
      suggestion.lastName ? suggestion.lastName : ""
    }(RunnerId-${suggestion.runnerId})`;
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onRunnerInputChange = (event, { newValue }) => {
    if (!newValue) {
      setRunnerId("");
      setRunnerName("");
      if (ApplicationUtil.isuserLoggedIn()) {
        let runnerData = ApplicationUtil.getRunnerDetails();
        let runnerId = runnerData?.runner?.id;
        getChallengeResponseResults({
          eventId: challengeId,
          runnerId,
        }).then(({ data }) => {
          if (data?.response?.code == "406") {
            // Snackbar.show({
            //   variant: "error",
            //   isAutoHide: true,
            //   preventDuplicate: true,
            //   message: Properties.ChallengeParticipantNotRegister,
            // });
            clearResult();
            setResponseData(data);
          } else {
            clearResult(data);
            setResponseData(data);
          }
        });
      } else {
        setResponseData(null);
      }
    }
    setValue(newValue);
  };

  const inputProps = {
    placeholder: "Name/Runner ID",
    value,
    onChange: onRunnerInputChange,
    type: "search",
    variant: "outlined",
  };
  const handleSearchedClick = (runnerId) => {
    if (runnerId) {
      getSearchResults(runnerId);
      clearResult();
    } else {
      if (value === "") {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: false,
          message: Properties.SelectParticipantMsgForLeaderboard,
        });
      }
    }
  };
  const getSearchResults = (runnerId) => {
    if (challengeId && runnerId) {
      let params = {
        eventId: challengeId,
        runnerId: runnerId,
      };

      getChallengeResponseResults(params)
        .then((response) => {
          if (response?.data?.response?.code == "406") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.ChallengeParticipantNotRegister,
            });
            setResponseData(response?.data);
          } else {
            setResponseData(response?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (value === "") {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: false,
          message: Properties.SelectParticipantMsgForLeaderboard,
        });
      }
    }
  };
  return (
    <>
      <Grid
        style={{
          width: "90%",
          margin: "auto",
        }}
        container
        spacing={1}
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item xs={6} className={classes.input}>
          <Autosuggest
            inputProps={inputProps}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={(event, { suggestion, method }) => {
              if (method === "enter") {
                event.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ textAlign: "center" }}>
          <SubmitCancelBtn
            type="submit"
            label={Properties.Lable_Go}
            style={{ width: "30%", margin: "auto" }}
            onClick={(e) => {
              e.preventDefault();
              handleSearchedClick(runnerId);
            }}
          />
        </Grid>
      </Grid>

      {showChallengeResults && responseData && (
        <div>
          <ChallengeResponseResult data={responseData} isProgram={isProgram} />
        </div>
      )}
    </>
  );
}
const styles = (theme) => ({
  input: {
    "& .react-autosuggest__container": {
      position: "relative",
    },

    "& .react-autosuggest__input": {
      width: "100%",
      height: "50px",
      padding: "10px 20px",
      fontFamily: "Helvetica, sans-serif",
      fontWeight: 300,
      fontSize: "16px",
      border: "1px solid #aaa",
      borderRadius: "4px",
    },
    "& .react-autosuggest__input--focused": {
      outline: "none",
    },

    "& .react-autosuggest__input--open": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },

    "& .react-autosuggest__suggestions-container": {
      display: "none",
    },

    "& .react-autosuggest__suggestions-container--open": {
      display: "block",
      position: "absolute",
      top: "51px",
      width: "300px",

      border: "1px solid #aaa",
      backgroundColor: "#fff",
      fontFamily: "Helvetica, sans-serif",
      fontWeight: 300,
      fontSize: "16px",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      zIndex: 2,
      overflow: "auto",
      maxHeight: "300px",
    },

    "& .react-autosuggest__suggestions-list": {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },

    "& .react-autosuggest__suggestion": {
      cursor: "pointer",
      padding: "10px 20px",
    },

    "& .react-autosuggest__suggestion--highlighted": {
      backgroundColor: "#ddd",
      color: "#000",
    },
  },
  results: {
    margin: "20px 0px",
  },
  results_block1: {
    width: "90%",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
});

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(ChallengeSearchBar);
