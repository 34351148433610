// import { createHashHistory, createBrowserHistory } from "history";
// export default createBrowserHistory({
//   // hashType: "slash"
//   //alwaysEnableState: true
//   createHref: (location) =>
//     "#" +
//     this.basename + this.createPath(location),
// });

import { createBrowserHistory, createHashHistory } from "history";
// export default createHashHistory({
//   hashType: "slash",
//   //alwaysEnableState: true
//   createHref: (location) =>  this.basename + this.createPath(location),
// });


// export default createBrowserHistory({

// })
export const history = createBrowserHistory()

// export default createHashHistory({
//     // hashType: "slash"
//     //alwaysEnableState: true
//     // createHref: (location) => "#" + this.basename + this.createPath(location),
//     createHref: (location) => this.basename + this.createPath(location)
// });