import React, { useEffect } from "react";
import { Box, FormControl, NativeSelect } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import { compose } from "recompose";
import { useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import moment from "moment";

const GaugeGraph = ({
  classes,
  gapBetweenDateAndGraph = 0,
  graphData,
  isSession = false,
  responseBasedChallenges,
}) => {
  const [graphValue, setGraphValue] = useState(null);
  const handleChangeValue = (e) => {
    const selectedValue = e.target.value;
    let selectedObject;
    if (isSession) {
      selectedObject = graphData?.find(
        (value) =>
          value?.sessionName === selectedValue ||
          value?.overall === selectedValue
      );
    } else {
      selectedObject = graphData?.find(
        (value) =>
          value?.date === selectedValue || value?.overall === selectedValue
      );
    }

    setGraphValue(selectedObject);
  };

  const getDefaultValue = () => {
    let defaultValue = getDefaultSelection();
    return defaultValue?.label;
  };

  const getGaugeValue = () => {
    let value = 0;
    if (graphValue) {
      value = graphValue?.engagementPercentage;
    } else if (graphData?.length) {
      let defaultValue = getDefaultSelection();
      value = defaultValue?.engagementPercentage;
    }
    return parseFloat(value || 0);
  };

  const getDefaultSelection = () => {
    let value = {};
    if (isSession) {
      value = {
        engagementPercentage: graphData[0]?.engagementPercentage,
        label: graphData[0]?.overall,
      };
    } else {
      let isSameDate;

      if (responseBasedChallenges) {
        isSameDate = graphData.filter((element) => {
          if (
            element?.date &&
            moment().subtract(1, "day").isSame(element?.date, "date")
          )
            return element;
        });
      } else {
        isSameDate = graphData.filter((element) => {
          if (element?.date && moment().isSame(element?.date, "date")) {
            return element;
          }
        });
      }

      if (isSameDate.length) {
        value = {
          engagementPercentage: isSameDate[0]?.engagementPercentage,
          label: isSameDate[0]?.date,
        };
      } else {
        value = {
          engagementPercentage: graphData[0]?.engagementPercentage,
          label: graphData[0]?.overall,
        };
      }
    }
    return value;
  };

  return (
    <div
      className={classes.gaugeGraphConatiner}
      style={{ gap: gapBetweenDateAndGraph + "px" }}
    >
      <Box className={classes.selectView}>
        <span></span>{" "}
        <span>
          {graphData?.length > 0 && (
            <FormControl className={classes.dropdownStyling}>
              <NativeSelect
                id="myDropdown"
                onChange={handleChangeValue}
                // MenuProps={MenuProps}

                defaultValue={getDefaultValue()}
              >
                {graphData?.map((value, index) => {
                  return (
                    <option
                      value={
                        isSession
                          ? value?.sessionName || value?.overall
                          : value?.date || value?.overall
                      }
                      key={index}
                      index={index}
                    >
                      {isSession
                        ? value?.sessionName || value?.overall
                        : value?.date || value?.overall}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>
          )}
        </span>
      </Box>
      <ReactSpeedometer
        needleHeightRatio={0.7}
        maxValue={100}
        segments={200}
        height={200}
        startColor="red"
        endColor="green"
        needleColor="black"
        value={getGaugeValue()}
        currentValueText={`${parseFloat(getGaugeValue()).toFixed(2)}%`}
        valueTextFontSize={"20px"}
        ringWidth={document.documentElement.clientWidth > 1000 ? 40 : 20}
        maxSegmentLabels={0}
      />
    </div>
  );
};

const styles = (theme) => ({
  gaugeGraphConatiner: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  selectView: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  gaugChart: {
    "& .text-group": {
      fill: "#000",
      fontWeight: "bold !important",
      fontSize: "24px",
    },
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(GaugeGraph);
