import React from "react";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import Snackbar from "./Snackbar";
import Properties from "../../properties/Properties";
import ApplicationUtil from "../../utils/ApplicationUtil";
import MultiSelect from "./MultiSelect";
import { CustomTextField } from "./CustomTextField";

const styles = (theme) => ({
  tableRow: {
    marginLeft: "20px ",
    marginBottom: "100px",
  },
  tableCell: {
    width: "36%",
    border: 0,
    fontSize: "1.1rem",
  },
  icon: {
    fontSize: "20px",
    padding: "10px",
    color: "#683594",
  },
  quantity: {
    fontSize: "1.1rem",
  },
  selectedSizeField: {
    display: "flex",
    alignItems: "center",
  },
  sizeChartLink: {
    paddingLeft: "5px",
    margin: "auto",
  },
  block: {
    marginTop: "3%",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
});

const Label = ({ label, required }) => (
  <Typography variant="body2" gutterBottom style={{ display: "inline" }}>
    <span style={{ color: "#727272" }}>{label}</span>
    {required && <span style={{ color: "red" }}>*</span>}
  </Typography>
);

function EventItems(props) {
  let eventId = ApplicationUtil.getRunnersEventID();

  const { classes, data, updateData, openSizeChart } = props;

  const decreaseCounter = (row) => {
    const { eventItems } = data;

    let arr = eventItems.map((x) => {
      if (x.id === row.id) {
        if (x.itemQuantity > 0) {
          x.itemQuantity--;
          x.rowTotal = x.pricePerUnit * x.itemQuantity;
          x.totalRowPrice = x.pricePerUnit * x.itemQuantity;
        }
      }
      return x;
    });
    if (eventId == 1879 || eventId == 508 || eventId == 507 || eventId == 513) {
      let item = arr.find((x) =>
        x?.itemName?.toLowerCase().includes("t-shirt")
      );
      if (item && item?.itemQuantity === 0) {
        arr = arr.map((x) => {
          if (x?.itemName?.toLowerCase().includes("medal")) {
            x.hideItem = true;
            x.itemQuantity = 0;
            x.totalRowPrice = 0;
            x.rowTotal = 0;
          }
          return x;
        });
      }
    }
    let isItemSelected = arr.some((x) => x?.itemQuantity > 0);

    let updatedData = {
      ...data,
      eventItems: arr,
      showShippingDetails: isItemSelected,
    };
    updateData(updatedData);
  };
  const increaseQuantity = (row) => {
    let eventId = ApplicationUtil.getRunnersEventID();

    const { eventItems } = data;
    let arr = eventItems.map((x) => {
      if (x.id === row.id) {
        if (x.maxQuantity === x.itemQuantity) {
          // x.itemQuantity--;
          // x.rowTotal =
          //   x.itemQuantity > 0 ? x.pricePerUnit * x.itemQuantity : 0;

          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: "You reached max quantity.",
          });
        } else {
          x.itemQuantity++;
          x.rowTotal = x.itemQuantity > 0 ? x.pricePerUnit * x.itemQuantity : 0;
          x.totalRowPrice =
            x.itemQuantity > 0 ? x.pricePerUnit * x.itemQuantity : 0;
        }
      }
      return x;
    });

    if (
      eventId == 1879 ||
      eventId == 508 ||
      eventId == 507 ||
      eventId === 513
    ) {
      let item = arr.find((x) =>
        x?.itemName?.toLowerCase().includes("t-shirt")
      );
      if (item && item?.itemQuantity > 0) {
        arr = arr.map((x) => {
          x.hideItem = false;
          return x;
        });
      }
    }

    let isItemSelected = arr.some((x) => x?.itemQuantity > 0);

    let updatedData = {
      ...data,
      eventItems: arr,
      showShippingDetails: isItemSelected,
    };
    updateData(updatedData);
  };
  const handleCodeChange = (value) => {
    let updatedData = { ...data, countryCode: value };
    updateData(updatedData);
  };

  return (
    <div>
      {data?.eventItems?.map((row, index) => (
        <>
          {row?.hideItem === false && (
            <>
              <Table aria-label="customized table">
                <TableBody>
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      {row.itemName}
                    </TableCell>
                    {/* 20% */}
                    <TableCell
                      className={classes.tableCell}
                      style={{ width: "25%" }}
                    >
                      &#8377; {row.pricePerUnit ? row.pricePerUnit : 0}
                    </TableCell>
                    {/* 20% */}
                    <TableCell
                      className={classes.tableCell}
                      style={{ width: "20%" }}
                    >
                      <span
                        className={classes.icon}
                        style={{
                          cursor: row.readOnly === true ? "unset" : "pointer",
                        }}
                        onClick={() =>
                          row.readOnly === true ? null : decreaseCounter(row)
                        }
                      >
                        -
                      </span>

                      <span className={classes.quantity}>
                        {row.itemQuantity ? row.itemQuantity : 0}
                      </span>

                      <span
                        className={classes.icon}
                        style={{
                          cursor: row.readOnly === true ? "unset" : "pointer",
                        }}
                        onClick={() =>
                          row.readOnly === true ? null : increaseQuantity(row)
                        }
                      >
                        +
                      </span>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ width: "20%" }}
                    >
                      &#8377; {row.rowTotal ? row.rowTotal : 0}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {(row?.sizeMale || row?.sizeFemale) && row.itemQuantity > 0 && (
                <div>
                  <Label label={Properties.Label_Select_Size} required={true} />
                  <div className={classes.selectedSizeField}>
                    <div style={{ flex: 0.6 }}>
                      <MultiSelect
                        value={row?.selectedSize}
                        error={row?.selectedSizeError}
                        options={row?.sizeArray}
                        onChange={(value) => {
                          let arr = data?.eventItems?.map((x) => {
                            if (x.id === row.id) {
                              x.selectedSize = value;
                              x.selectedSizeError = false;
                            }
                            return x;
                          });

                          let updatedData = {
                            ...data,
                            eventItems: arr,
                            selectedTshirtSize: value,
                            selectedTshirtSizeError: false,
                          };
                          updateData(updatedData);
                        }}
                      />
                    </div>

                    {data?.tShirtSizeChartFile && (
                      <div className={classes.sizeChartLink}>
                        {/* <a href="">Size Chart</a> */}
                        <a onClick={openSizeChart}>Size Chart</a>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </>
      ))}

      {data?.showShippingDetails && (
        <>
          <div className={classes.block}>
            <Label label={Properties.Label_ContatNumber} required={true} />
            <div className={classes.flexContainer}>
              <div style={{ flex: 0.4 }}>
                <MultiSelect
                  value={data?.countryCode}
                  options={data?.countryCodeList}
                  onChange={handleCodeChange}
                />
              </div>

              <div style={{ flex: 1, height: "42px" }}>
                <CustomTextField
                  style={{
                    border: data?.contactNumberError
                      ? "1px solid red"
                      : "1px solid #08456C",
                    height: "100%",

                    marginLeft: "2px",
                  }}
                  type={"number"}
                  isNumber={true}
                  value={data?.contactNumber}
                  error={data?.contactNumberError}
                  keyToSet={"contactNumber"}
                  handleChange={(prop) => (event) => {
                    let updatedData = {
                      ...data,
                      contactNumber: event.target.value,
                      contactNumberError: ApplicationUtil.validateContactNumber(
                        event.target.value
                      )
                        ? false
                        : true,
                    };
                    updateData(updatedData);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={classes.block}>
            <Label label={Properties.shipping_Address_Label} required={true} />
            <CustomTextField
              style={{
                border: data?.addressError
                  ? "1px solid red"
                  : "1px solid #08456C",

                height: "40px",
                maxHeight: "40px",
                minHeight: "40px",
                width: "100%",
              }}
              type={"text"}
              error={data?.addressError}
              keyToSet={"address"}
              // readOnly={this.state.isReadOnly}
              value={data?.address}
              handleChange={(prop) => (event) => {
                let updatedData = {
                  ...data,
                  address: event.target.value,
                  addressError: false,
                };
                updateData(updatedData);
              }}
            />
          </div>
          <div className={classes.flexContainer} style={{ marginTop: "3%" }}>
            <div>
              <Label label={Properties.Label_City} required />
              <div style={{ height: "40px" }}>
                <CustomTextField
                  style={{
                    border: data?.cityError
                      ? "1px solid red"
                      : "1px solid #08456C",
                    height: "100%",

                    marginLeft: "2px",
                  }}
                  type={"text"}
                  isNumber={true}
                  value={data?.city}
                  error={data?.cityError}
                  keyToSet={"city"}
                  handleChange={(prop) => (event) => {
                    let updatedData = {
                      ...data,
                      city: event.target.value,
                      cityError: false,
                    };
                    updateData(updatedData);
                  }}
                />
              </div>
            </div>
            <div>
              <Label label={Properties.Label_Postal} required />
              <div style={{ height: "40px" }}>
                <CustomTextField
                  style={{
                    border: data?.pincodeError
                      ? "1px solid red"
                      : "1px solid #08456C",
                    height: "100%",

                    marginLeft: "2px",
                  }}
                  type={"number"}
                  isNumber={true}
                  value={data?.pincode}
                  error={data?.pincodeError}
                  keyToSet={"pincode"}
                  handleChange={(prop) => (event) => {
                    let updatedData = {
                      ...data,
                      pincode: event.target.value,
                      pincodeError: false,
                    };
                    updateData(updatedData);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={classes.block}>
            <Label label={Properties.Label_State} required />
            <div style={{ height: "40px" }}>
              <CustomTextField
                style={{
                  border: data?.stateError
                    ? "1px solid red"
                    : "1px solid #08456C",
                  height: "100%",

                  width: "100%",
                }}
                type={"text"}
                isNumber={true}
                value={data?.state}
                error={data?.stateError}
                keyToSet={"state"}
                handleChange={(prop) => (event) => {
                  let updatedData = {
                    ...data,
                    state: event.target.value,
                    stateError: false,
                  };
                  updateData(updatedData);
                }}
              />
            </div>
          </div>
          <div className={classes.block}>
            <Label label={Properties.Label_Country} required />
            <div style={{ height: "40px" }}>
              <MultiSelect
                value={data?.country}
                options={data?.countryCodeList}
                onChange={(value) => {
                  let updatedData = {
                    ...data,
                    country: value,
                    countryError: false,
                  };
                  updateData(updatedData);
                }}
              />
              {/* <CustomTextField
                        style={

                            {
                                border: data?.countryError ? "1px solid red" : "1px solid #08456C",
                                height: "100%",
                                width: '100%'

                            }

                        }
                        type={"text"}
                        isNumber={true}
                        value={data?.country}
                        error={data?.countryError}
                        keyToSet={"country"}
                        handleChange={(prop) => (event) => {
                            let updatedData = {
                                ...data,
                                state: event.target.value,
                                stateError:  false,
                            }
                            updateData(updatedData)
                        }}
                    /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default compose(withStyles(styles, { withTheme: true }))(EventItems);
