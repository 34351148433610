import { Box, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import SubmitCancelBtn from "../../../../common/SubmitCancelBtn";
import Snackbar from "../../../../common/Snackbar";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import Autosuggest from "react-autosuggest";
import {
  getJobTypes,
  getSearchResults,
  getSubscribedServices,
  jobSyncActivity,
  programChallengeRunnerListForJobSync,
} from "../../../../../apis/eventService";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import { IconCalendarBlue } from "../../../../common/SvgIcon";
import moment from "moment";
import MultiSelect from "../../../../common/MultiSelect";
import Properties from "../../../../../properties/Properties";
import { useSelector } from "react-redux";

function OrganizerActivitySyncTab({ classes }) {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [fetchDate, setFetchDate] = useState(null);
  const [fetchDateTo, setFetchDateTo] = useState(null);
  const [errorField, setErrorField] = useState(false);
  const [fetchedError, setFetchedError] = useState(false);

  const [runnerId, setRunnerId] = useState("");
  const [runnerName, setRunnerName] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");
  const [searchvalue, setSearchValue] = useState("");
  const [runnerError, setRunnerError] = useState("");

  const [serviceId, setServiceId] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [serviceSuggestions, setServiceSuggestions] = useState([]);
  const [serviceValue, setServiceValue] = useState("");
  const [serviceSearchValue, setServiceSearchValue] = useState("");
  const [serviceError, setServiceError] = useState("");

  const [customJobId, setCustomJobId] = useState("");
  const [selectValue, setSelectValue] = useState(null);
  const [optionsList, setOptionsList] = useState([]);
  const [intervalValue, setIntervalValue] = useState("");
  const [count, setCount] = useState("");

  let eventId = ApplicationUtil.getSupportsEventID();

  const [programChallengeDetails, setProgramChallengeDetails] = useState(null);
  const supportSelectedEvent = useSelector(
    (state) => state?.supportUser?.supportSelectedEvent
  );

  const handleSelectChange = (selectedOptions) => {
    setSelectValue(selectedOptions);
  };

  const syncJob = (params) => {
    setErrorField(false);
    setRunnerError(false);
    setServiceError(false);
    setFetchedError(false);
    jobSyncActivity(params).then(({ data }) => {
      if (data?.success?.code === "200") {
        setFromDate(null);
        setToDate(null);
        setFetchDate(null);
        setFetchDateTo(null);
        setRunnerId("");
        setRunnerName("");
        setServiceId("");
        setServiceName("");
        setValue("");
        setServiceValue("");
        setCustomJobId("");
        setIntervalValue("");
        setSelectValue(optionsList[0]);
        setCount("");
      }
    });
  };

  const handleSubmit = () => {
    let isFieldNotProvided =
      !fromDate || !toDate || !intervalValue || !customJobId;
    let checkDate = fromDate && toDate && toDate < fromDate;
    let checkIntervalValue = intervalValue && parseInt(intervalValue) <= 0;
    if (isFieldNotProvided || checkDate || checkIntervalValue) {
      setErrorField(true);
      let errorMessage = isFieldNotProvided
        ? `Please provide required fields`
        : checkDate
        ? `To date cannot be less than From date`
        : `Interval value should be positive`;
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: errorMessage,
      });
    } else {
      setErrorField(false);

      if (value || serviceValue || fetchDateTo) {
        function isRunnerPresent(runnerId, runnerName) {
          for (const key in searchvalue) {
            if (
              searchvalue[key].runnerId === runnerId &&
              `${searchvalue[key].firstName} ${searchvalue[key].lastName}` ===
                runnerName
            ) {
              return true;
            }
          }
          return false;
        }

        function isServicePresent(serviceId, serviceName) {
          for (const key in serviceSearchValue) {
            if (
              serviceSearchValue[key].serviceId === serviceId &&
              `${serviceSearchValue[key].serviceName}` === serviceName
            ) {
              return true;
            }
          }
          return false;
        }

        function compareDate(date1, date2) {
          let checkDate = date1 && date2 && date2 < date1;
          return checkDate ? false : true;
        }

        if (value && !isRunnerPresent(runnerId, runnerName)) {
          setRunnerError(true);
          let msg = programChallengeDetails?.isProgramChallenge
            ? `Participant Not Present for the Challenge`
            : `Participant Not Present for the Event`;
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: msg,
          });
        } else if (serviceValue && !isServicePresent(serviceId, serviceName)) {
          setServiceError(true);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: `Provide Service Properly`,
          });
        } else if (
          fetchDate &&
          fetchDateTo &&
          !compareDate(fetchDate, fetchDateTo)
        ) {
          setFetchedError(true);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: `FetchDateTo cannot be less than FetchDate`,
          });
        } else {
          const params = {
            jobType: selectValue?.value,
            eventId: eventId,
            startDateTime: moment.utc(fromDate).format("DD-MM-YYYY HH:mm:ss"),
            endDateTime: moment.utc(toDate).format("DD-MM-YYYY HH:mm:ss"),
            activityFetchDate: fetchDate
              ? moment.utc(fetchDate).format("DD-MM-YYYY HH:mm:ss")
              : null,
            activityFetchDateTo: fetchDateTo
              ? moment.utc(fetchDateTo).format("DD-MM-YYYY HH:mm:ss")
              : null,
            runnerIds: runnerId ? [runnerId] : [],
            interval: intervalValue ? parseInt(intervalValue) : 1,
            customJobId: parseInt(customJobId),
            serviceId: serviceId ? serviceId : "",
            count: count
              ? parseInt(count)
              : selectValue === optionsList[0]
              ? null
              : 0,
          };
          if (
            selectValue?.label === "PROGRAM_CHALLENGE_REMAINDER" &&
            programChallengeDetails?.id
          ) {
            params.programChallengeId = programChallengeDetails?.id;
          }
          syncJob(params);
        }
      } else {
        const params = {
          jobType: selectValue?.value,
          eventId: eventId,
          startDateTime: moment.utc(fromDate).format("DD-MM-YYYY HH:mm:ss"),
          endDateTime: moment.utc(toDate).format("DD-MM-YYYY HH:mm:ss"),
          activityFetchDate: fetchDate
            ? moment.utc(fetchDate).format("DD-MM-YYYY HH:mm:ss")
            : null,
          activityFetchDateTo: fetchDateTo
            ? moment.utc(fetchDateTo).format("DD-MM-YYYY HH:mm:ss")
            : null,
          runnerIds: runnerId ? [runnerId] : [],
          interval: intervalValue ? parseInt(intervalValue) : 1,
          customJobId: parseInt(customJobId),
          serviceId: serviceId ? serviceId : "",
          count: count
            ? parseInt(count)
            : selectValue === optionsList[0]
            ? null
            : 0,
        };
        if (
          selectValue?.label === "PROGRAM_CHALLENGE_REMAINDER" &&
          programChallengeDetails?.id
        ) {
          params.programChallengeId = programChallengeDetails?.id;
        }
        syncJob(params);
      }
    }
  };

  const renderSuggestion = (suggestion) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>
        <DirectionsRunIcon
          style={{
            color: "#09456C",
          }}
        />
      </div>
      <div>
        {`${suggestion.firstName} ${
          suggestion.lastName ? suggestion.lastName : ""
        } (RunnerId- ${suggestion.runnerId})`}
      </div>
    </div>
  );

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (searchvalue) {
      return inputLength === 0
        ? []
        : searchvalue.filter(
            (val) =>
              val?.firstName?.toLowerCase().slice(0, inputLength) ===
                inputValue ||
              val?.runnerId?.toString().slice(0, inputLength) === inputValue
          );
    }
  };

  const getSuggestionValue = (suggestion) => {
    setRunnerId(suggestion.runnerId);
    setRunnerName(`${suggestion.firstName} ${suggestion?.lastName || ""}`);
    return `${suggestion.firstName} ${
      suggestion.lastName ? suggestion.lastName : ""
    }(RunnerId-${suggestion.runnerId})`;
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onRunnerInputChange = (event, { newValue }) => {
    if (!newValue) {
      setRunnerId("");
      setRunnerName("");
    }
    setValue(newValue);
  };

  const inputProps = {
    placeholder: "Runner Name/Id",
    value,
    onChange: onRunnerInputChange,
    type: "search",
    variant: "outlined",
  };

  const renderServiceSuggestion = (serviceValue) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>
        <DirectionsRunIcon
          style={{
            color: "#09456C",
          }}
        />
      </div>
      <div>
        {`${serviceValue.serviceName} (ServiceId- ${serviceValue.serviceId})`}
      </div>
    </div>
  );

  const getServiceSuggestions = (serviceValue) => {
    const inputValue = serviceValue.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (serviceSearchValue) {
      return inputLength === 0
        ? []
        : serviceSearchValue.filter(
            (service) =>
              service.serviceName.toLowerCase().slice(0, inputLength) ===
                inputValue ||
              service.serviceId.toString().slice(0, inputLength) === inputValue
          );
    }
    return [];
  };

  const getServiceSuggestionValue = (serviceSuggestions) => {
    setServiceId(serviceSuggestions.serviceId);
    setServiceName(serviceSuggestions.serviceName);
    return `${serviceSuggestions.serviceName} (ServiceId-${serviceSuggestions.serviceId})`;
  };

  const onServiceSuggestionsFetchRequested = ({ value }) => {
    setServiceSuggestions(getServiceSuggestions(value));
  };

  const onServiceSuggestionsClearRequested = () => {
    setServiceSuggestions([]);
  };

  const onServiceInputChange = (event, { newValue }) => {
    if (!newValue) {
      setServiceId("");
      setServiceName("");
    }
    setServiceValue(newValue);
  };

  const serviceInputProps = {
    placeholder: "Service Name/Id",
    value: serviceValue,
    onChange: onServiceInputChange,
    type: "search",
    variant: "outlined",
  };

  useEffect(() => {
    const isProgramChallenge = localStorage.getItem("isProgramChallenge");
    const headerLinkSelected = localStorage.getItem("headerLinkSelected");
    if (isProgramChallenge && supportSelectedEvent) {
      setProgramChallengeDetails({
        ...supportSelectedEvent,
        isProgramChallenge,
      });
    }

    if (headerLinkSelected === "Program") {
      //for program || program challenge
      const { id: challengeId } = supportSelectedEvent;
      // getting the registered runner list for the particular challenge of program for job sync
      programChallengeRunnerListForJobSync({ challengeId }).then((res) => {
        if (res?.data) {
          let autoValues = [];
          res.data.map((value) => {
            autoValues.push({
              firstName: value.firstName,
              lastName: value.lastName,
              runnerId: value.runnerId,
            });
          });
          setSearchValue(autoValues);
        }
      });
    } else {
      let requestParams = {
        eventId: eventId,
        searchString: "",
      };
      // getting the registered runner list for the particular event for job sync
      getSearchResults(requestParams).then((res) => {
        if (res.data) {
          let autoValues = [];
          res.data.map((value) => {
            autoValues.push({
              firstName: value.firstName,
              lastName: value.lastName,
              runnerId: value.runnerId,
            });
          });
          setSearchValue(autoValues);
        }
      });
    }

    getSubscribedServices().then((res) => {
      if (res.data && res.data.length > 0) {
        let services = res.data;
        let serviceList = [];
        services.map((value) => {
          serviceList.push({
            serviceId: value.id,
            serviceName: value.name,
          });
        });
        setServiceSearchValue(serviceList);
      }
    });

    getJobTypes().then((res) => {
      if (res.data && res.data.length > 0) {
        let jobTypes = res.data;
        const options = jobTypes?.map((item, index) => ({
          label: item,
          value: item,
        }));
        setOptionsList(options);
        if (options.length > 0) {
          let defaultValue = options[0];
          setSelectValue(defaultValue);
        }
      }
    });
  }, []);

  return (
    <div className={classes.main}>
      <Grid
        container
        item
        xs={6}
        alignItems="center"
        className={classes.innerGridCss}
      >
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>Job Type:</span>
            <span className={classes.mandatoryCss}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={8} className={classes.dropdownCss}>
          <MultiSelect
            value={selectValue}
            options={optionsList}
            onChange={handleSelectChange}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          container
          item
          xs={6}
          alignItems="center"
          className={classes.innerGridCss}
        >
          <Grid item xs={3}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.labelCss}>Runner Id/Name:</span>
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            className={runnerError ? classes.inputerror : classes.input}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Autosuggest
                inputProps={inputProps}
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={(event, { suggestion, method }) => {
                  if (method === "enter") {
                    event.preventDefault();
                  }
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={6}
          alignItems="center"
          className={classes.innerGridCss}
        >
          <Grid item xs={3}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.labelCss}>Interval: </span>
              <span className={classes.mandatoryCss}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                type={"number"}
                required
                variant="outlined"
                className={
                  errorField && !intervalValue
                    ? classes.fieldErrorCss
                    : classes.fieldCss
                }
                InputProps={{ disableUnderline: true, inputProps: { min: 0 } }}
                value={intervalValue || ""}
                onChange={(e) => {
                  setIntervalValue(e.target.value);
                }}
                onWheel={(e) => e.target.blur()}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          container
          item
          xs={6}
          alignItems="center"
          className={classes.innerGridCss}
        >
          <Grid item xs={3}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.labelCss}>Service Id:</span>
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            className={serviceError ? classes.inputerror : classes.input}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Autosuggest
                inputProps={serviceInputProps}
                suggestions={serviceSuggestions}
                onSuggestionsFetchRequested={onServiceSuggestionsFetchRequested}
                onSuggestionsClearRequested={onServiceSuggestionsClearRequested}
                getSuggestionValue={getServiceSuggestionValue}
                renderSuggestion={renderServiceSuggestion}
                onSuggestionSelected={(event, { suggestion, method }) => {
                  if (method === "enter") {
                    event.preventDefault();
                  }
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={6}
          alignItems="center"
          className={classes.innerGridCss}
        >
          <Grid item xs={3}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.labelCss}>Count: </span>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                type={"number"}
                required
                variant="outlined"
                className={classes.fieldCss}
                InputProps={{ disableUnderline: true, inputProps: { min: 0 } }}
                value={count || ""}
                onChange={(e) => {
                  setCount(e.target.value);
                }}
                onWheel={(e) => e.target.blur()}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          container
          item
          xs={6}
          alignItems="center"
          className={classes.innerGridCss}
        >
          <Grid item xs={3}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.labelCss}>Custom Job Id:</span>
              <span className={classes.mandatoryCss}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.input}>
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                type={"text"}
                variant="outlined"
                className={
                  errorField && !customJobId
                    ? classes.fieldErrorCss
                    : classes.fieldCss
                }
                InputProps={{ disableUnderline: true }}
                value={customJobId || ""}
                onChange={(e) => {
                  setCustomJobId(e.target.value);
                }}
              />
            </Box>
          </Grid>
        </Grid>
        {selectValue?.label === "PROGRAM_CHALLENGE_REMAINDER" &&
          programChallengeDetails !== null && (
            <Grid
              container
              item
              xs={6}
              alignItems="center"
              className={classes.innerGridCss}
            >
              <Grid item xs={3}>
                <Typography variant="body2" gutterBottom>
                  <span className={classes.labelCss}>
                    {Properties?.Label_Program_Challenge_Id}
                  </span>
                  <span className={classes.mandatoryCss}>*</span>
                </Typography>
              </Grid>

              <Grid item xs={8} className={classes.input}>
                <Box sx={{ flexGrow: 1 }}>
                  <TextField
                    type={"text"}
                    variant="outlined"
                    disabled
                    className={classes.fieldCss}
                    InputProps={{ disableUnderline: true }}
                    value={`${programChallengeDetails?.name}(Id-${programChallengeDetails?.id})`}
                    onChange={(e) => {}}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
      </Grid>
      <Grid container>
        <Grid
          container
          item
          xs={6}
          alignItems="center"
          className={classes.innerGridCss}
        >
          <Grid item xs={3}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.labelCss}>From:</span>
              <span className={classes.mandatoryCss}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <DateFieldContainer
              value={fromDate}
              required
              handleChange={(date) => {
                setFromDate(date);
              }}
              type="text"
              name={"fromDate"}
              error={errorField && !fromDate}
              disablePast
            />
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={6}
          alignItems="center"
          className={classes.innerGridCss}
        >
          <Grid item xs={3}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.labelCss}>To:</span>
              <span className={classes.mandatoryCss}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Box sx={{ flexGrow: 1 }}>
              <DateFieldContainer
                value={toDate}
                required
                handleChange={(date) => {
                  setToDate(date);
                }}
                type="text"
                name={"toDate"}
                error={errorField && !toDate}
                disablePast
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {selectValue && selectValue === optionsList[0] && (
        <Grid container>
          <Grid
            container
            item
            xs={6}
            alignItems="center"
            className={classes.innerGridCss}
          >
            <Grid item xs={3}>
              <Typography variant="body2" gutterBottom>
                <span className={classes.labelCss}>Fetch Date:</span>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <DateFieldContainer
                value={fetchDate}
                required
                handleChange={(date) => {
                  setFetchDate(date);
                }}
                type="text"
                name={"fetchDate"}
                error={fetchedError}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={6}
            alignItems="center"
            className={classes.innerGridCss}
          >
            <Grid item xs={3}>
              <Typography variant="body2" gutterBottom>
                <span className={classes.labelCss}>Fetch Date To:</span>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Box sx={{ flexGrow: 1 }}>
                <DateFieldContainer
                  value={fetchDateTo}
                  required
                  handleChange={(date) => {
                    setFetchDateTo(date);
                  }}
                  type="text"
                  name={"fetchDateTo"}
                  error={fetchedError}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid container className={classes.syncActivity}>
        <SubmitCancelBtn
          type="submit"
          label="Sync Activity"
          onClick={() => {
            handleSubmit();
          }}
        />
      </Grid>
    </div>
  );
}

const styles = (theme) => ({
  main: {
    padding: "10px",
  },
  innerGridCss: {
    padding: "4px 0px",
  },
  labelCss: {
    color: "#727272",
  },
  mandatoryCss: { color: "red" },
  dropdownCss: {
    "& .css-2b097c-container": {
      width: isBrowser ? "360px" : "160px",
    },
  },
  fieldCss: {
    border: "1px solid #08456C",
    height: "40px",
    width: isBrowser ? "360px" : "160px",
    borderRadius: 5,
    "& .MuiInputBase-input": {
      height: "100%",
      padding: "10px",
    },
  },
  syncActivity: {
    justifyContent: "center",
    marginTop: "50px",
  },
  fieldErrorCss: {
    border: "1px solid red",
    borderRadius: 5,
    height: "40px",
    width: isBrowser ? "360px" : "160px",
    "& .MuiInputBase-input": {
      height: "100%",
      padding: "10px",
    },
  },

  input: {
    "& .react-autosuggest__container": {
      position: "relative",
    },

    "& .react-autosuggest__input": {
      border: "1px solid #08456C",
      padding: "10px",
      borderRadius: 5,
      height: "40px",
      font: "inherit",
      width: isBrowser ? "360px" : "160px",
    },
    "& .react-autosuggest__input--focused": {
      outline: "none",
    },

    "& .react-autosuggest__input--open": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },

    "& .react-autosuggest__suggestions-container": {
      display: "none",
    },

    "& .react-autosuggest__suggestions-container--open": {
      display: "block",
      position: "absolute",
      top: "40px",
      width: isBrowser ? "360px" : "160px",
      border: "1px solid #aaa",
      backgroundColor: "#fff",
      fontFamily: "Helvetica, sans-serif",
      fontWeight: 300,
      fontSize: "16px",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      zIndex: 2,
      overflow: "auto",
      maxHeight: "300px",
    },

    "& .react-autosuggest__suggestions-list": {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },

    "& .react-autosuggest__suggestion": {
      cursor: "pointer",
      padding: "10px 20px",
    },

    "& .react-autosuggest__suggestion--highlighted": {
      backgroundColor: "#ddd",
      color: "#000",
    },
  },

  inputerror: {
    "& .react-autosuggest__container": {
      position: "relative",
    },
    "& .react-autosuggest__input": {
      border: "1px solid red",
      padding: "10px",
      borderRadius: 5,
      height: "40px",
      font: "inherit",
      width: isBrowser ? "360px" : "160px",
    },
    "& .react-autosuggest__input--focused": {
      outline: "none",
    },

    "& .react-autosuggest__input--open": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },

    "& .react-autosuggest__suggestions-container": {
      display: "none",
    },

    "& .react-autosuggest__suggestions-container--open": {
      display: "block",
      position: "absolute",
      top: "40px",
      width: isBrowser ? "360px" : "160px",
      border: "1px solid #aaa",
      backgroundColor: "#fff",
      fontFamily: "Helvetica, sans-serif",
      fontWeight: 300,
      fontSize: "16px",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      zIndex: 2,
      overflow: "auto",
      maxHeight: "300px",
    },

    "& .react-autosuggest__suggestions-list": {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },

    "& .react-autosuggest__suggestion": {
      cursor: "pointer",
      padding: "10px 20px",
    },

    "& .react-autosuggest__suggestion--highlighted": {
      backgroundColor: "#ddd",
      color: "#000",
    },
  },
});

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(OrganizerActivitySyncTab);

const DateFieldContainer = ({
  value,
  handleChange,
  style,
  name,
  error,
  required,
  disablePast,
}) => {
  return (
    <Grid item xs={12}>
      <KeyboardDateTimePicker
        style={{
          border: error ? "1px solid red" : "1px solid #08456C",
          borderRadius: "5px",
        }}
        required
        autoOk
        disablePast={disablePast}
        clearable
        variant="inline"
        inputVariant="outlined"
        format="dd/MM/yyyy hh:mm:ss a"
        value={value}
        // FormHelperTextProps={{
        //   style: {
        //     margin: "0px",
        //   },
        // }} 'Invalid Date Format' Ankit / Defect_id_521 remove the style.
        InputProps={{
          style: {
            paddingRight: 0,
            height: "40px",
            width: isBrowser ? "360px" : "160px",
          },
        }}
        keyboardIcon={
          <IconCalendarBlue className={{ height: 22, width: 22 }} />
        }
        onChange={(date) => {
          handleChange(date, name);
        }}
      />
    </Grid>
  );
};
