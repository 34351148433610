import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Typography,
  Switch,
  Tooltip,
  IconButton,
  Button,
} from "@material-ui/core";
import ChipInput from "material-ui-chip-input"; // uncontrolled input
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import Properties from "../../../../../properties/Properties";
import { CustomTextField } from "../../../../common/CustomTextField";
import { isBrowser } from "react-device-detect";
import MultiSelect from "../../../../common/MultiSelect";
import {
  getCategoryList,
  getEventActivityDetails,
  getEventDetails,
  getEventOrganiserList,
  publishEvent,
  updateEventDetails,
} from "../../../../../apis/eventService";
import SubmitCancelBtn from "../../../../common/SubmitCancelBtn";
import Snackbar from "../../../../common/Snackbar";
import CategoriesTable from "./CategoriesTable";
import EventItemsTable from "./EventItemsTable";
import InlineDivider from "../../../../common/InlineDivider";
import DialogBox from "./../../../../common/DialogBox";
import NewTextEditor from "./../../../../common/NewTextEditor";
import HelpIcon from "@material-ui/icons/Help";
import { IconCalendarBlue } from "../../../../common/SvgIcon";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import ApplicationConstants from "../../../../../constants/ApplicationConstants";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import {
  updateSupportDTODetails,
  updateSupportSelectedEvent,
} from "../../../../../redux/supportUser";
import { loading } from "../../../../common/Spinner";
var moment = require("moment");
var momentTimeZone = require("moment-timezone");

const styles = (theme) => ({
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },
  helpContainerCss: {
    display: "inline-flex",
  },
  helpCss: {
    display: "flex",
    color: "#3a6ea5",
  },
  labelCss: {
    color: "#727272",
  },
  mandatoryCss: { color: "red" },
  fieldCss: {
    border: "1px solid #08456C",
    height: "40px", //"45%",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
    // focused: {},
    // notchedOutline: {},
  },
  fieldLinkCss: {
    width: "340px",
  },
  fieldErrorCss: {
    border: "1px solid red",
    height: "40px",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  chipCss: {
    border: "1px solid #08456C",
    borderRadius: "4px",
    // height: "40px", //"45%",
    padding: "2px",
    // maxHeight: "40px",
    // minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  chipErrorCss: {
    border: "1px solid red",
    borderRadius: "4px",
    // height: "40px",
    padding: "2px",
    // maxHeight: "40px",
    // minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  textAreafieldCss: {
    border: "1px solid #08456C",
    height: "100px",
    maxHeight: "100px",
    minHeight: "100px",
    width: isBrowser ? "360px" : "160px",
  },
  textAreaErrorCss: {
    border: "1px solid red",
    height: "100px",
    maxHeight: "100px",
    minHeight: "100px",
    width: isBrowser ? "360px" : "160px",
  },
  dropdownCss: {
    width: isBrowser ? "360px" : "160px",
  },
  multiSelectGridCss: {
    margin: "4px 0px",
  },
  innerGridCss: {
    padding: "4px 0px",
  },
  supportedDomainsCss: {
    underline: {
      "&:hover": {
        "&:before": {
          borderBottom: ["rgba(0, 188, 212, 0.7)", "!important"],
        },
      },
      "&:before": {
        borderBottom: "rgba(0, 188, 212, 0.7)",
      },
    },
  },
  // },
});

class BasicEditView extends Component {
  state = {
    supportedDomains: [],
    supportedDomainsError: false,
    role: "",
    eventName: "",
    eventNameError: false,
    eventScope: "",
    eventScopeError: false,
    eventScopeList: ApplicationConstants.eventScopeList,
    eventDateTime: null,
    eventDateTimeError: false,
    eventEndDateTime: null,
    eventEndDateTimeError: false,
    description: "",
    descriptionError: false,
    longDescription: "",
    longDescriptionError: false,
    registrationEndDateTime: null,
    registrationEndDateTimeError: false,
    eventType: null,
    eventTypeList: ApplicationConstants.eventTypeList,
    eventTypeError: false,
    eventParticipationType: null,
    eventParticipationTypeError: false,
    eventParticipationTypeList: ApplicationConstants.eventParticipationTypeList,
    participantTypeList: ApplicationConstants.participantTypeList,

    challengeType: null,
    challengeTypeError: false,
    challengeTypeList: ApplicationConstants.challengeTypeList,
    challengeParameterList: ApplicationConstants.challengeParameterList,
    stepChallengeParamterList: ApplicationConstants.StepChallengeParamterList,
    runCycleChallengeParamterList:
      ApplicationConstants.RunCycleChallengeParamterList,
    activityTypeList: ApplicationConstants.activityTypeList,
    eventItemsList: [
      {
        id: 1,
        label: "T-Shirt",
        value: "T-Shirt",
        title: "T-Shirt",
        maxQuantity: 1,
      },
      {
        id: 2,
        label: "Medal",
        value: "Medal",
        title: "Medal",
        maxQuantity: 1,
      },
    ],
    femaleTShirtSizeList: ApplicationConstants.femaleTShirtSizeList,
    itemTypeList: ApplicationConstants.itemTypeList,
    localEndDate: null,
    localEndDateError: false,
    activityType: null,
    activityTypeError: false,
    eventCategory: null,
    eventCategoryError: false,
    eventCategoryList: null,
    selectedNotificationMode: [
      ApplicationConstants.notificationModeOptionList?.[0],
    ],
    notificationModeOptionList: ApplicationConstants.notificationModeOptionList,
    isPastEvent: false,
    hasTimingMat: false,
    allowActivityUpload: true,
  };

  componentDidMount() {
    loading(true);
    let data = ApplicationUtil.getSupportDetails();
    let pastEvent = localStorage.getItem("pastEvent");
    let setPermissions = JSON.parse(localStorage.getItem("supportPermissions"));
    let isChallengeUserPastDate = localStorage.getItem("isChallengePastDate");
    if (pastEvent === "true" || isChallengeUserPastDate === "true") {
      this.setState({
        isPastEvent: true, //true,
      });
    } else {
      this.setState({
        isPastEvent: !setPermissions?.canModifyEvent,
      });
    }

    localStorage.setItem("ActiveTab", "ZERO");
    this.setState({ role: data?.role });

    this.loadDetails();
  }

  loadDetails = () => {
    let requestparam = null; //"REGISTER_EVENT";

    let eventId = ApplicationUtil.getSupportsEventID();
    getEventOrganiserList({ customLoading: false }).then((res) => {
      res.data.map(
        (r) => ((r.label = r.name), (r.value = r.name), (r.id = r.id))
      );
      this.setState({ eventOrganiserList: res.data });
    });
    getEventDetails(requestparam, eventId, false).then((res) => {
      const data = res?.data;
      let selectedEventScope = null,
        selectedChallengeType = null,
        selectedEventType = null,
        selectedEventParticipantType = null,
        addChallengeType = {},
        selectedSupportedDomains = [];

      if (data) {
        this.props.updateSupportSelectedEvent(data);
        this.state.eventScopeList.map((e) => {
          if (e.value === data?.eventScope) {
            selectedEventScope = e;
          }
        });

        this.state.eventTypeList.map((e) => {
          if (e.value === data?.type) {
            selectedEventType = e;
          }
        });

        this.state.challengeTypeList.map((e) => {
          if (e.value === data?.challengeType) {
            selectedChallengeType = e;
          }
        });

        this.state.eventParticipationTypeList.map((e) => {
          if (e.value === data?.eventParticipantType) {
            selectedEventParticipantType = e;
          }
        });
        // if (selectedChallengeType === null && data?.challengeType) {
        //   addChallengeType = {
        //     label:
        //       data?.challengeType?.slice(0, 1).toUpperCase() +
        //       data?.challengeType?.slice(1).toLowerCase(),
        //     value: data?.challengeType,
        //   };
        //   this.setState({
        //     challengeTypeList: [
        //       ...this.state.challengeTypeList,
        //       addChallengeType,
        //     ],
        //   });
        //   selectedChallengeType = addChallengeType;
        // }

        if (data?.supportedDomains) {
          data.supportedDomains.forEach((ele) => {
            selectedSupportedDomains.push(ele.validationDomain);
          });
        }

        getEventActivityDetails(null, eventId, false).then((response) => {
          response.data.map(
            (r) => (
              (r.label = r.displayName),
              (r.value = r.type),
              (r.id = r.id),
              (r.eventSupportedActivityTypeId = r?.eventSupportedActivityTypeId)
            )
          );
          if (response.data?.length > 0) {
            let newData = response.data;
            newData = Object.values(
              newData.reduce(
                (acc, cur) => Object.assign(acc, { [cur.id]: cur }),
                {}
              )
            );
            this.state.activityTypeList.map((e) => {
              newData.forEach((element) => {
                if (e.value === element.type) {
                  element.label = e.label;
                  element.value = e.value;
                }
              });
            });

            for (let i = 0; i < newData.length; i++) {
              data.eventRunCategories.forEach((ele) => {
                let arr = [];
                if (
                  newData[i].id === ele.activityType.id &&
                  ele.eventSupportedActivityType.participantType ===
                    ele.participantType
                ) {
                  let a = {};
                  let time = null;
                  if (ele && ele.standardCompletionTime) {
                    time = ele.standardCompletionTime.split(":");
                    time = time[0] + ":" + time[1];
                  }
                  a.title = ele.category;
                  a.id = ele.id;
                  a.label = ele.categoryName;
                  a.value = ele.category;
                  a.categoryDisplayName = ele.categoryName;
                  a.categoryFee = ele.registrationFee;
                  a.categoryMaxTicketCount = ele.maxTickets;
                  a.bibStartNumber = ele.bibStartNumber;
                  a.standardCompletionTime = time;
                  a.routeCoordinates = ele?.routeCoordinates
                    ? ele?.routeCoordinates
                    : null;
                  a.challengeGoal = ApplicationConstants.challengeGoal.find(
                    (x) => x.value === ele?.challengeGoal
                  );
                  a.actualDistance = ele?.actualDistance
                    ? Number(parseFloat(ele?.actualDistance / 1000).toFixed(1))
                    : 0;
                  arr.push(a);
                }
                let selectedParticipationType = null;
                this.state.participantTypeList.map((e) => {
                  if (e.value === ele.participantType) {
                    selectedParticipationType = e;
                  }
                });
                let listItem = {
                  id: ele.eventSupportedActivityType.id,
                  value: ele.activityType.type,
                  displayName: ele?.eventSupportedActivityType?.displayName,
                  allowActivityUpload:
                    ele?.eventSupportedActivityType?.allowActivityUpload,
                  minChallengeValue:
                    ele?.eventSupportedActivityType?.activityType
                      ?.minChallengeValue,
                  maxTolerance: ele?.eventSupportedActivityType?.maxTolerance
                    ? ele?.eventSupportedActivityType?.activityType
                        ?.maxTolerance
                    : 0,
                  selectedValues: arr,
                  supportedActivityType: ele?.eventSupportedActivityType,
                  participantType: selectedParticipationType,
                  challengeParams:
                    ele?.eventSupportedActivityType?.activityType
                      ?.primaryDisplayParams,
                };

                let selectedChallengeParams = [];

                if (this.state?.challengeParameterList?.length > 0) {
                  this.state.challengeParameterList.map((e) => {
                    if (e.value === newData[i].challengeParams) {
                      selectedChallengeParams = e;
                    }
                  });
                  listItem.challengeParams = selectedChallengeParams;
                }
                if (
                  newData[i].type ===
                  ele.eventSupportedActivityType.activityType.type
                ) {
                  if (
                    newData[i].ActivityList &&
                    newData[i].ActivityList?.length > 0
                  ) {
                    let found = newData[i].ActivityList.findIndex(
                      (obj) =>
                        obj.supportedActivityType?.id ===
                        ele?.eventSupportedActivityType.id
                    );

                    if (found !== -1) {
                      newData[i].ActivityList[found].selectedValues.push(
                        listItem.selectedValues[0]
                      );
                    } else {
                      //   newData[i].ActivityList[newData[i].ActivityList.length + 0] = [
                      //     listItem,
                      //   ];
                      //   // }
                      newData[i].ActivityList.push(listItem);
                    }
                  } else {
                    newData[i].ActivityList = [listItem];
                  }
                }
              });
            }

            this.setState({ activityType: newData }, () => {
              this.handleActivityTypeChange({
                selectedValues: this.state.activityType,
                loading: false,
              });
            });
          }
        });

        if (data?.eventItems?.length > 0) {
          let fetchedItems = data?.eventItems;
          let existingItems = [
            {
              id: 1,
              label: "T-Shirt",
              value: "T-Shirt",
              title: "T-Shirt",
              maxQuantity: 1,
            },
            {
              id: 2,
              label: "Medal",
              value: "Medal",
              title: "Medal",
              maxQuantity: 1,
            },
          ];
          let Items = [];
          for (let i = 0; i < fetchedItems.length; i++) {
            let e = existingItems?.find((x) =>
              fetchedItems[i]?.itemName
                ?.toLowerCase()
                .includes(x.value.toLowerCase())
            );
            let item;
            if (
              fetchedItems[i].itemName
                ?.toLowerCase()
                .includes(e?.value.toLowerCase())
            ) {
              item = e;
              item.label = fetchedItems[i]?.itemName;
              item.value = fetchedItems[i]?.itemName;
              this.state.itemTypeList.map((ele) => {
                if (ele.value === fetchedItems[i].type) {
                  item.id = fetchedItems[i]?.id;
                  item.type = {
                    id: fetchedItems[i]?.id,
                    label: ele.label,
                    value: ele.value,
                  };
                }
              });

              if (fetchedItems[i].itemName.toLowerCase().includes("t-shirt")) {
                let arr = [],
                  arr2 = [];
                if (fetchedItems[i]?.eventItemSizeChartImage?.imageData) {
                  this.setState({
                    TShirtSizeChart:
                      "data:image/png;base64," +
                      fetchedItems[i]?.eventItemSizeChartImage?.imageData,
                    TShirtSizeChartFile:
                      fetchedItems[i]?.eventItemSizeChartImage?.imageData,
                  });
                }
                this.state.femaleTShirtSizeList.map((ele) => {
                  if (fetchedItems[i].sizeFemale) {
                    let fetchedFemaleSizes =
                      fetchedItems[i].sizeFemale.split(",");
                    fetchedFemaleSizes.forEach((size) => {
                      if (ele.value === size) {
                        let obj = {};
                        obj.id = ele.id;
                        obj.label = ele.label;
                        obj.value = ele.value;
                        arr.push(obj);
                      }
                    });
                    item.sizeFemale = arr;
                  }

                  //male tshirt sizes
                  if (fetchedItems[i].sizeMale) {
                    let fetchedMaleSizes = fetchedItems[i].sizeMale.split(",");
                    fetchedMaleSizes.forEach((size) => {
                      if (ele.value === size) {
                        let obj = {};
                        obj.id = ele.id;
                        obj.label = ele.label;
                        obj.value = ele.value;
                        arr2.push(obj);
                      }
                    });
                    item.sizeMale = arr2;
                  }
                });
              }

              item.maxQuantity = fetchedItems[i].maxQuantity
                ? fetchedItems[i].maxQuantity
                : 0;
              item.pricePerUnit = fetchedItems[i].pricePerUnit
                ? fetchedItems[i].pricePerUnit
                : 0;

              item.readOnly = fetchedItems[i].readOnly
                ? fetchedItems[i].readOnly
                : false;

              Items.push(item);
            }
          }

          this.setState({ eventItems: Items });
        }

        let eventOrganiser = null;
        if (
          this.state.eventOrganiserList &&
          this.state.eventOrganiserList.length > 0
        ) {
          this.state.eventOrganiserList.map((e) => {
            if (e.value === data?.organizers[0]?.name) {
              eventOrganiser = e;
            }
          });
        }

        let newNotificationList = this.state.notificationModeOptionList;
        if (data.eventNotificationModes?.length > 0) {
          let list = data.eventNotificationModes;
          for (let i = 0; i < list.length; i++) {
            newNotificationList.map((e) => {
              if (e.value === list[i].notificationMode) {
                e.id = list[i]?.id ? list[i].id : null;
              }
            });
          }
          this.setState(
            { notificationModeOptionList: newNotificationList },
            () => {
              let list = data.eventNotificationModes;
              let selectedList = [];
              for (let i = 0; i < list.length; i++) {
                let item = {};

                this.state.notificationModeOptionList.map((e) => {
                  if (e.value === list[i].notificationMode) {
                    item = e;
                  }
                });
                selectedList.push(item);
              }
              this.setState({ selectedNotificationMode: selectedList });
            }
          );
        }

        // let activities = res?.data?.activities;
        // localStorage.setItem("supportActivities", JSON.stringify(activities));

        let isPublished = false;
        if (data.eventState == "PUBLISHED") {
          isPublished = true;
        }

        this.setState({
          isPublished: isPublished,
          // activities: activities,
          //   activityType: this.state.activityTypeList[0], //data.activities,
          eventName: data?.name,
          eventScope: selectedEventScope,
          eventOrganiser: eventOrganiser,
          eventType: selectedEventType,
          eventDateTime: moment(data?.eventDate + " " + data?.eventTime).format(
            "YYYY-MM-DDTHH:mm"
          ),
          eventEndDateTime: ApplicationUtil.changeDateToLocalFormat(
            data?.eventEndDate
          ),
          registrationEndDateTime: data?.registrationEndDate
            ? ApplicationUtil.changeDateToLocalFormat(data?.registrationEndDate)
            : null,
          localEndDate: moment(data?.localEndDate).format("YYYY-MM-DD"),
          description: data?.description,
          longDescription: data?.longDescription,
          htmlDescription: data?.description,
          htmlLongDescription: data?.longDescription,
          challengeType: selectedChallengeType,
          eventParticipationType: selectedEventParticipantType,
          eventLink: data?.eventLink,
          supportedDomains: selectedSupportedDomains,
          hasTimingMat:
            data?.eventSettings?.hasTimingMat === "true" ? true : false,
        });
      }
    });
  };
  handleCancel() {
    this.setState({ showCancelDialog: true });
  }

  closeWindow = () => {
    this.setState({ showCancelDialog: false });
  };
  getDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.CreateEvent_CancelClickWarning}</span>
        </Typography>
      </div>
    );
  };

  closeDialogBox = () => {
    this.setState({ showCancelDialog: false });
    this.loadDetails();
  };

  handleNotificationMode = (selectedValues) => {
    var arr = [];

    if (selectedValues != null) {
      selectedValues.forEach((element) => {
        arr.push(element);
      });
      this.setState({ selectedNotificationMode: arr });
    }
  };
  setDefaultParticipantType = (element) => {
    let defaultType = ApplicationConstants.participantTypeList[0];
    if (element.participantType.value === "REGULAR") {
      this.state.participantTypeList.map((e) => {
        if (e.value === "REGULAR_PHYSICAL") {
          defaultType = e;
        }
      });
    } else if (element.participantType.value === "REGULAR_PHYSICAL") {
      this.state.participantTypeList.map((e) => {
        if (e.value === "INVITEE") {
          defaultType = e;
        }
      });
    } else if (element.participantType.value === "INVITEE") {
      this.state.participantTypeList.map((e) => {
        if (e.value === "REGULAR") {
          defaultType = e;
        }
      });
    }
    return defaultType;
  };
  handleAddActivity = (activityRow, row, rowIndex) => {
    const { classes } = this.props;
    const { activityType, participantTypeList, eventParticipationType } =
      this.state;
    let newData = activityType;
    let currentActivityType = newData.findIndex(
      (obj) => obj.id === activityRow.id
    );

    /* participatioType */
    let defaultType = ApplicationConstants.participantTypeList[0];

    if (eventParticipationType?.value === "ALL") {
      newData[currentActivityType].ActivityList.forEach((element) => {
        defaultType = this.setDefaultParticipantType(element);
      });
    }
    /* */

    newData[currentActivityType].ActivityList = [
      ...newData[currentActivityType].ActivityList,
      {
        value: activityRow.value,
        id:
          activityRow.value + newData[currentActivityType].ActivityList.length,
        label: activityRow.label,
        allowActivityUpload: true,
        participantType: defaultType,
      },
    ];

    this.setState({
      activityType: newData,
    });
  };
  // handleAddActivity = (activityRow, row, rowIndex) => {
  //   const { classes } = this.props;
  //   const { activityType, participantTypeList, eventParticipationType } =
  //     this.state;
  //   let newData = activityType;
  //   let currentActivityType = newData.findIndex(
  //     (obj) => obj.id === activityRow.id
  //   );

  //   /* participatioType */
  //   let defaultType = ApplicationConstants.participantTypeList[0];

  //   if (eventParticipationType.value === "ALL") {
  //     newData[currentActivityType].ActivityList.forEach((element) => {
  //       defaultType = this.setDefaultParticipantType(element);
  //     });
  //   }
  //   /* */

  //   newData[currentActivityType].ActivityList = [
  //     ...newData[currentActivityType].ActivityList,
  //     {
  //       value: activityRow.value,
  //       id:
  //         activityRow.value + newData[currentActivityType].ActivityList.length,
  //       label: activityRow.label,
  //       allowActivityUpload: true,
  //       participantType: defaultType,
  //     },
  //   ];
  //   this.setState({
  //     activityType: newData,
  //   });
  // };
  handleRemoveActivity = (activityRow, row, rowIndex) => {
    const { classes } = this.props;
    const { activityType } = this.state;
    let newData = activityType;
    let currentActivityType = newData.findIndex(
      (obj) => obj.id === activityRow.id
    );

    newData[currentActivityType].ActivityList.splice(rowIndex, 1);
    if (newData[currentActivityType].ActivityList.length === 0) {
      newData.splice(currentActivityType, 1);
    }
    this.setState({
      activityType: newData,
    });
  };
  handleActivityTypeChange = ({ selectedValues, customLoading = true }) => {
    if (selectedValues != null) {
      var arr = [];
      selectedValues.forEach((element) => {
        // element.allowActivityUpload = true;

        /* participatioType */
        let defaultType = ApplicationConstants.participantTypeList[0];
        /* */
        if (!element?.ActivityList?.length > 0) {
          element.ActivityList = [
            {
              value: element.value,
              id: element.value + "0",
              label: element.label,
              allowActivityUpload: true,
              participantType: defaultType,
            },
          ];
        }
        arr.push(element);
        let response = [];
        const getData = async () => {
          let returnedRes = await getCategoryList(
            element.id,
            customLoading
          ).then((res) => {
            res.data.forEach((ele) => {
              let a = { ...ele };
              a.title = ele.category;
              a.id = ele.id;
              a.label = ele.categoryDisplayName;
              a.value = ele.category;
              a.categoryDisplayName = ele.categoryDisplayName;
              a.categoryFee = ele.defaultFee;
              a.categoryMaxTicketCount = ele.defaultMaxTicket;
              response.push(a);
            });
          });
        };
        getData().then(() => {
          let index = arr.findIndex((o) => o.id === element.id);
          arr[index] = {
            ...arr[index],
            list: response,
          };

          this.setState({
            activityType: arr,
            activityTypeError: false,
          });
          if (!customLoading) {
            loading(false);
          }
        });
      });
    } else {
      this.setState({
        activityType: arr,
        activityTypeError: true,
      });
      if (!customLoading) {
        loading(false);
      }
    }
  };

  handleChallengeParameterChange = (selectedValues, row, activityRow) => {
    var arr = [];
    let newData = [...this.state.activityType];
    let selectedAct = newData.findIndex((obj) => obj.id === activityRow.id);
    let index = newData[selectedAct].ActivityList.findIndex(
      (obj) => obj.id === row.id
    );
    if (selectedValues != null) {
      newData[selectedAct].ActivityList[index].challengeParams = selectedValues;
      newData[selectedAct].ActivityList[index].ChallenegParamsError = false;
    }
    this.setState({
      activityType: newData,
    });
  };

  handleOrganiserChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        eventOrganiser: selectedValues,
        eventOrganiserError: false,
      });
    } else {
      this.setState({
        eventOrganiser: selectedValues,
        eventOrganiserError: true,
      });
    }
  };

  handleEventScopeChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        eventScope: selectedValues,
        eventScopeError: false,
      });
    } else {
      this.setState({
        eventScope: selectedValues,
        eventScopeError: true,
      });
    }
  };
  handleEventTypeChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      this.setState({
        eventType: selectedValues,
        eventTypeError: false,
      });
    } else {
      this.setState({
        eventType: selectedValues,
        eventTypeError: true,
      });
    }
  };
  handleChallengeType = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        challengeType: selectedValues,
        challengeTypeError: false,
      });
    } else {
      this.setState({
        challengeType: selectedValues,
        challengeTypeError: true,
      });
    }
  };
  handleEventCategoryChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        eventCategory: selectedValues,
        eventCategoryError: false,
      });
    } else {
      this.setState({
        eventCategory: selectedValues,
        eventCategoryError: true,
      });
    }
  };
  getSuccessDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>
            {this.state.eventName}
            {" updated successfully"}
          </span>
        </Typography>
      </div>
    );
  };

  goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  renderRequiredRow = (activityRow, row, rowIndex) => {
    const { classes } = this.props;
    const {
      activityType,
      eventType,
      challengeParameterList,
      role,
      isPastEvent,
      participantTypeList,
      eventParticipationType,
      hasTimingMat,
      stepChallengeParamterList,
       runCycleChallengeParamterList
    } = this.state;
    return (
      <Grid container>
        <Grid item xs={11}>
          <div
            style={{
              margin: "10px 0px",
              padding: "10px",
              border: "1px solid gray",
            }}
          >
            <Typography variant="body2" gutterBottom>
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#727272",
                }}
              >
                {ApplicationUtil.getCamelCasing(row.value)}
              </span>
            </Typography>
            <Grid container>
              <Grid item xs={6} className={classes.innerGridCss}>
                <Typography variant="body2" gutterBottom>
                  <span className={classes.labelCss}>
                    {Properties.ActivityNameLabel}
                  </span>
                  <span className={classes.mandatoryCss}>*</span>
                </Typography>
                <CustomTextField
                  type={"text"}
                  value={row.displayName || ""}
                  defaultValue={row.displayName}
                  className={
                    row.isError ? classes.fieldErrorCss : classes.fieldCss
                  }
                  handleChange={(prop) => (e) => {
                    let newData = [...activityType];
                    let selectedAct = newData.findIndex(
                      (obj) => obj.id === activityRow.id
                    );
                    let index = newData[selectedAct].ActivityList.findIndex(
                      (obj) => obj.id === row.id
                    );
                    newData[selectedAct].ActivityList[index].isError =
                      e.target.value === null ? true : false;
                    newData[selectedAct].ActivityList[index].displayName =
                      e.target.value;

                    this.setState({
                      activityType: newData, // activityType: newData,
                    });
                  }}
                  readOnly={isPastEvent}
                />
              </Grid>

              {eventParticipationType?.value === "ALL" && (
                <Grid item xs={6}>
                  <Typography variant="body2" gutterBottom>
                    <span className={classes.labelCss}>
                      {/* {Properties.Activity_type_label} */}
                      Participation
                    </span>
                    <span className={classes.mandatoryCss}>*</span>
                  </Typography>
                  <div className={classes.dropdownCss}>
                    <MultiSelect
                      // isMulti={true}
                      value={row.participantType || ""}
                      error={row.participantTypeError}
                      options={participantTypeList}
                      onChange={(e) => {
                        this.handleSelectedParticipantTypeChange(
                          e,
                          row,
                          activityRow
                        );
                      }}
                      isReadOnly={isPastEvent}
                      //{rowIndex === 0 ? false : true}
                    />
                  </div>
                </Grid>
              )}

              {activityRow?.list?.length > 0 && (
                <Grid item xs={6} className={classes.innerGridCss}>
                  <Typography variant="body2" gutterBottom>
                    <span className={classes.labelCss}>
                      {Properties.CategoriesLabel}
                    </span>
                    <span className={classes.mandatoryCss}>*</span>
                  </Typography>
                  <div className={classes.dropdownCss}>
                    <MultiSelect
                      isMulti={true}
                      defaultValue={row.selectedValues}
                      error={row.isSelectedValueError}
                      options={activityRow.list}
                      onChange={(e) => {
                        this.handleSelectedRowChange(
                          e,
                          activityRow.list,
                          row,
                          activityRow
                        );
                      }}
                      isReadOnly={isPastEvent}
                    />
                  </div>
                </Grid>
              )}

              {role === "SUPPORT" && (
                <Grid item xs={6} className={classes.innerGridCss}>
                  <Typography variant="body2" gutterBottom>
                    <span className={classes.labelCss}>
                      {Properties.ToleranceDistanceLabel} for{" "}
                      {" " + ApplicationUtil.getCamelCasing(row.value)}{" "}
                    </span>
                  </Typography>
                  <CustomTextField
                    value={row.maxTolerance || ""}
                    className={classes.fieldCss}
                    type="number"
                    handleChange={(prop) => (e) => {
                      let newData = [...activityType];
                      let selectedAct = newData.findIndex(
                        (obj) => obj.id === activityRow.id
                      );
                      let index = newData[selectedAct].ActivityList.findIndex(
                        (obj) => obj.id === row.id
                      );
                      newData[selectedAct].ActivityList[index].maxTolerance =
                        e.target.value;

                      this.setState({
                        activityType: newData,
                      });
                    }}
                    readOnly={isPastEvent}
                  />
                </Grid>
              )}

              <Grid item xs={6} className={classes.innerGridCss}>
                <span className={classes.labelCss}>
                  {Properties.AllowActivityUploadLabel}
                </span>
                <Switch
                  checked={row.allowActivityUpload}
                  color="primary"
                  onChange={(e) => {
                    let newData = [...activityType];
                    let selectedAct = newData.findIndex(
                      (obj) => obj.id === activityRow.id
                    );
                    let index = newData[selectedAct].ActivityList.findIndex(
                      (obj) => obj.id === row.id
                    );
                    newData[selectedAct].ActivityList[
                      index
                    ].allowActivityUpload = e.target.checked;

                    this.setState({
                      activityType: newData,
                    });
                  }}
                  disabled={isPastEvent}
                />
              </Grid>

              {eventType?.value === "CHALLENGE" && (
                <Grid item xs={6} className={classes.innerGridCss}>
                  <Typography variant="body2" gutterBottom>
                    <span className={classes.labelCss}>
                      Challenge on for{" "}
                      {" " + ApplicationUtil.getCamelCasing(row.value)}{" "}
                      {/* {Properties.Activity_type_label} */}
                    </span>
                    {/* <span className={classes.mandatoryCss}>*</span> */}
                  </Typography>

                  <div className={classes.dropdownCss}>
                    <MultiSelect
                      value={row.challengeParams || ""}
                      error={row.ChallenegParamsError}
                      options={
                        row.value === "STEPS"
                          ? stepChallengeParamterList
                          : runCycleChallengeParamterList
                      }
                      onChange={(e) => {
                        this.handleChallengeParameterChange(
                          e,
                          row,
                          activityRow
                        );
                      }}
                      isReadOnly={isPastEvent}
                    />
                  </div>
                </Grid>
              )}

              {eventType?.value === "CHALLENGE" && (
                <Grid item xs={6} className={classes.innerGridCss}>
                  <Typography variant="body2" gutterBottom>
                    <span className={classes.labelCss}>
                      Min Challenge{" "}
                      {(row?.type === "RUN" || row?.type === "CYCLE") && (
                        <> Distance</>
                      )}
                      {row?.type === "STEPS" && <> Steps</>}
                    </span>
                    {/* <span className={classes.mandatoryCss}>*</span> */}
                  </Typography>
                  <CustomTextField
                    value={row.minChallengeValue || ""}
                    defaultValue={row.minChallengeValue}
                    className={
                      row.minChallengeError
                        ? classes.fieldErrorCss
                        : classes.fieldCss
                    }
                    handleChange={(prop) => (e) => {
                      let newData = [...activityType];
                      let selectedAct = newData.findIndex(
                        (obj) => obj.id === activityRow.id
                      );
                      let index = newData[selectedAct].ActivityList.findIndex(
                        (obj) => obj.id === row.id
                      );
                      newData[selectedAct].ActivityList[
                        index
                      ].minChallengeError =
                        e.target.value === null ? true : false;
                      newData[selectedAct].ActivityList[
                        index
                      ].minChallengeValue = e.target.value;

                      this.setState({
                        activityType: newData, // activityType: newData,
                      });
                    }}
                    readOnly={isPastEvent}
                  />
                </Grid>
              )}

              <Grid item xs={12} style={{ padding: "5px" }}>
                {row.selectedValues?.length > 0 && (
                  <CategoriesTable
                    isPastEvent={isPastEvent}
                    listName={row.id}
                    list={row.selectedValues}
                    activityRow={activityRow}
                    handleCategoryDisplayNameChange={
                      this.handleCategoryDisplayNameChange
                    }
                    handleActualDistanceChange={this.handleActualDistanceChange}
                    handleChallengeGoalChange={this.handleChallengeGoalChange}
                    handleBibStartNumberChange={this.handleBibStartNumberChange}
                    handleCategoryFeeChange={this.handleCategoryFeeChange}
                    handleCategoryMaxTicketCountChange={
                      this.handleCategoryMaxTicketCountChange
                    }
                    handleStandardCompletionTimeChange={
                      this.handleStandardCompletionTimeChange
                    }
                    handleRouteChange={this.handleRouteChange}
                    hasTimingMat={hasTimingMat}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid
          item
          xs={1}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ curosr: "pointer" }}>
            {activityRow?.ActivityList[activityRow?.ActivityList?.length - 1]
              .id === row.id && (
              <Button disabled={isPastEvent}>
                <AddCircleIcon
                  onClick={() =>
                    this.handleAddActivity(activityRow, row, rowIndex)
                  }
                />
              </Button>
            )}

            {!(rowIndex === 0) && (
              <Button disabled={isPastEvent}>
                <RemoveCircleIcon
                  onClick={() =>
                    this.handleRemoveActivity(activityRow, row, rowIndex)
                  }
                />
              </Button>
            )}
          </span>
        </Grid>
      </Grid>
    );
  };
  handleSelectedRowChange = (selectedValues, list, row, activityRow) => {
    var arr = [];

    let newData = [...this.state.activityType];
    let selectedAct = newData.findIndex((obj) => obj.id === activityRow.id);
    let index = newData[selectedAct].ActivityList.findIndex(
      (obj) => obj.id === row.id
    );

    if (selectedValues != null) {
      selectedValues.forEach((element) => {
        element.challengeGoal = element?.challengeGoal?.label
          ? element.challengeGoal
          : undefined;
        element.actualDistance =
          element?.actualDistance > 999
            ? Number(parseFloat(element.actualDistance / 1000).toFixed(1))
            : element?.actualDistance;
        arr.push(element);
      });
      newData[selectedAct].ActivityList[index].selectedValues = arr;
      newData[selectedAct].ActivityList[index].isSelectedValueError = false;
    } else {
      newData[selectedAct].ActivityList[index].selectedValues = selectedValues;
      newData[selectedAct].ActivityList[index].isSelectedValueError = true;
    }

    this.setState({
      activityType: newData,
    });
  };

  handleSelectedParticipantTypeChange = (selectedValues, row, activityRow) => {
    var arr = [];

    let newData = [...this.state.activityType];
    let selectedAct = newData.findIndex((obj) => obj.id === activityRow.id);
    let index = newData[selectedAct].ActivityList.findIndex(
      (obj) => obj.id === row.id
    );

    if (selectedValues != null) {
      newData[selectedAct].ActivityList[index].participantType = selectedValues;
      newData[selectedAct].ActivityList[index].participantTypeError = false;
    } else {
      newData[selectedAct].ActivityList[index].participantType = selectedValues;
      newData[selectedAct].ActivityList[index].participantTypeError = true;
    }
    this.setState({
      activityType: newData,
    });
  };

  handleCategoryDisplayNameChange = (e, row, listName, activityRow) => {
    const { activityType } = this.state;

    let newData = [...activityType];
    let selectedAct = newData.findIndex((obj) => obj.id === activityRow.id);
    for (let i = 0; i < newData[selectedAct].ActivityList.length; i++) {
      if (newData[selectedAct].ActivityList[i].id === listName) {
        newData[selectedAct].ActivityList[i].selectedValues.forEach(
          (element) => {
            if (element.value === row.value) {
              element.isDisplayNameError =
                e.target.value === null ? true : false;
              element.categoryDisplayName = e.target.value;
            }
          }
        );
      }
    }
    this.setState({
      activityType: newData,
    });
  };
  handleChallengeGoalChange = (e, row, listName, activityRow) => {
    const { activityType } = this.state;

    let newData = [...activityType];
    let selectedAct = newData.findIndex((obj) => obj.id === activityRow.id);
    for (let i = 0; i < newData[selectedAct].ActivityList.length; i++) {
      if (newData[selectedAct].ActivityList[i].id === listName) {
        newData[selectedAct].ActivityList[i].selectedValues.forEach(
          (element) => {
            if (element.value === row.value) {
              element.challengeGoal = e;
            }
          }
        );
      }
    }

    this.setState({
      activityType: newData,
    });
  };
  handleActualDistanceChange = (e, row, listName, activityRow) => {
    const { activityType } = this.state;

    let newData = [...activityType];
    let selectedAct = newData.findIndex((obj) => obj.id === activityRow.id);
    for (let i = 0; i < newData[selectedAct].ActivityList.length; i++) {
      if (newData[selectedAct].ActivityList[i].id === listName) {
        newData[selectedAct].ActivityList[i].selectedValues.forEach(
          (element) => {
            if (element.value === row.value) {
              element.actualDistance = e.target.value;
            }
          }
        );
      }
    }

    this.setState({
      activityType: newData,
    });
  };
  handleCategoryFeeChange = (e, row, listName, activityRow) => {
    const { activityType } = this.state;
    let newData = [...activityType];

    let selectedAct = newData.findIndex((obj) => obj.id === activityRow.id);
    for (let i = 0; i < newData[selectedAct].ActivityList.length; i++) {
      if (newData[selectedAct].ActivityList[i].id === listName) {
        newData[selectedAct].ActivityList[i].selectedValues.forEach(
          (element) => {
            if (element.value === row.value) {
              element.isFeeError = e.target.value === null ? true : false;
              element.categoryFee = e.target.value;
            }
          }
        );
      }
    }
    this.setState({
      activityType: newData,
    });
  };

  handleCategoryMaxTicketCountChange = (e, row, listName, activityRow) => {
    const { activityType } = this.state;

    let newData = [...activityType];
    let selectedAct = newData.findIndex((obj) => obj.id === activityRow.id);
    for (let i = 0; i < newData[selectedAct].ActivityList.length; i++) {
      if (newData[selectedAct].ActivityList[i].id === listName) {
        newData[selectedAct].ActivityList[i].selectedValues.forEach(
          (element) => {
            if (element.value === row?.value) {
              element.isMaxTicketCountError =
                e.target.value === null ? true : false;
              element.categoryMaxTicketCount = e.target.value;
            }
          }
        );
      }
    }
    this.setState({
      activityType: newData,
    });
  };
  handleStandardCompletionTimeChange = (value, row, listName, activityRow) => {
    const { activityType } = this.state;

    let newData = [...activityType];
    let selectedAct = newData.findIndex((obj) => obj.id === activityRow.id);
    for (let i = 0; i < newData[selectedAct].ActivityList.length; i++) {
      if (newData[selectedAct].ActivityList[i].id === listName) {
        newData[selectedAct].ActivityList[i].selectedValues.forEach(
          (element) => {
            if (element.value === row?.value) {
              // element.standardCompletionTimeError = value ? false : true;
              element.standardCompletionTime = value;
            }
          }
        );
      }
    }

    this.setState({
      activityType: newData,
    });
  };
  handleRouteChange = (value, row, listName, activityRow) => {
    const { activityType } = this.state;

    let newData = [...activityType];
    let selectedAct = newData.findIndex((obj) => obj.id === activityRow.id);
    for (let i = 0; i < newData[selectedAct].ActivityList.length; i++) {
      if (newData[selectedAct].ActivityList[i].id === listName) {
        newData[selectedAct].ActivityList[i].selectedValues.forEach(
          (element) => {
            if (element.value === row?.value) {
              element.routeCoordinatesError = value ? false : true;
              element.routeCoordinates = value;
            }
          }
        );
      }
    }

    this.setState({
      activityType: newData,
    });
  };
  handleEventItemsChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      selectedValues.forEach((element) => {
        arr.push(element);
      });
      this.setState({
        eventItems: arr,
        // eventItemsError: false,
      });
    } else {
      this.setState({
        eventItems: arr,
        // eventItemsError: false,
      });
    }
  };

  handleFemaleTShirtSizeChange = (selectedValues, row) => {
    var arr = [];

    let newData = [...this.state.eventItems];
    if (selectedValues != null) {
      selectedValues.forEach((element) => {
        arr.push(element);
      });

      for (let i = 0; i < newData.length; i++) {
        if (newData[i].id === row.id) {
          // let index = newData[row.id].findIndex((obj) => obj.id === row.id);
          newData[i].sizeFemale = arr;
          newData[i].isSizeFemaleError = false;
        }
      }

      this.setState({
        eventItems: newData,
      });
    } else {
      for (let i = 0; i < newData.length; i++) {
        if (newData[i].id === row.id) {
          newData[i].sizeFemale = null;
          newData[i].isSizeFemaleError = null;
        }
      }
      this.setState({
        eventItems: newData,
      });
    }
  };

  handleMaleTShirtSizeChange = (selectedValues, row) => {
    var arr = [];
    let newData = [...this.state.eventItems];
    if (selectedValues != null) {
      selectedValues.forEach((element) => {
        arr.push(element);
      });
      for (let i = 0; i < newData.length; i++) {
        if (newData[i].id === row.id) {
          newData[i].sizeMale = arr;
          newData[i].isSizeMaleError = false;
        }
      }
      this.setState({
        eventItems: newData,
      });
    } else {
      for (let i = 0; i < newData.length; i++) {
        if (newData[i].id === row.id) {
          newData[i].sizeMale = null;
          newData[i].isSizeMaleError = null;
        }
      }
      this.setState({
        eventItems: newData,
      });
    }
  };

  handleEventItemTypeChange = (selectedValues, row) => {
    var arr = [];

    let newData = [...this.state.eventItems];
    if (selectedValues != null) {
      for (let i = 0; i < newData.length; i++) {
        if (newData[i].id === row.id) {
          newData[i].type = selectedValues;
          newData[i].typeError = false;
          if (selectedValues.value === "IMPLICITE") {
            newData[i].pricePerUnit = 0;
          }
        }
      }
      this.setState({
        eventItems: newData,
      });
    }
  };

  handleSizeChartUpload = (e, row) => {
    let files = e.target.files[0];

    let imgsrc = URL.createObjectURL(files);
    this.setState({ TShirtSizeChart: imgsrc, TShirtSizeChartFile: files });
  };
  handleSizeChartRemove = () => {
    this.setState({ TShirtSizeChart: null, TShirtSizeChartFile: null });
  };
  handleBibStartNumberChange = (e, row, listName, activityRow) => {
    const { activityType } = this.state;

    let newData = [...activityType];
    let selectedAct = newData.findIndex((obj) => obj.id === activityRow.id);
    for (let i = 0; i < newData[selectedAct].ActivityList.length; i++) {
      if (newData[selectedAct].ActivityList[i].id === listName) {
        newData[selectedAct].ActivityList[i].selectedValues.forEach(
          (element) => {
            if (element.value === row?.value) {
              element.isBibStartNumberError = e.target.value ? false : true;
              element.bibStartNumber = e.target.value;
            }
          }
        );
      }
    }
    this.setState({
      activityType: newData,
      [e.target.name]: e.target.value,

      [e.target.name + "Error"]: /^\d+$/.test(e.target.value) ? false : true,
    });
  };
  //supportedDomains
  handleAddChip = (chip) => {
    let chips = chip.split(",");
    // let isChipValid = validateDomainName(chip);
    // if (isChipValid) {
    let supportedDomains = this.state.supportedDomains;
    chips.forEach((element) => {
      supportedDomains.push(element);
    });
    this.setState({ supportedDomains: supportedDomains });
    // } else {
    //   Snackbar.show({
    //     variant: "error",
    //     isAutoHide: true,
    //     preventDuplicate: true,
    //     message: "Invalid Domian name.",
    //   });
    // }
  };
  handleDeleteChips = (chip, index) => {
    let supportedDomains = this.state.supportedDomains;
    supportedDomains.splice(index, 1);
    this.setState({ supportedDomains: supportedDomains });
  };

  handleEventParticipationTypeChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        eventParticipationType: selectedValues,
        eventParticipationTypeError: false,
      });
    } else {
      this.setState({
        eventParticipationType: selectedValues,
        eventParticipationTypeError: true,
      });
    }
  };
  handleCancelClick = () => {
    this.props.history.push("/organizer/eventlist");
  };

  validateFieldsForDraft = () => {
    const {
      eventName,
      eventNameError,
      eventScope,
      eventDateTime,
      eventEndDateTime,
      registrationEndDateTime,
      eventType,
      challengeType,
      localEndDate,
      activityType,
      role,
      eventOrganiser,
      eventItems,
      isPublished,
    } = this.state;

    this.setState({ publishEvent: false });
    if (eventName === "" || eventNameError === true) {
      this.setState({ eventNameError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter Event name.",
      });
      this.goToTop();
      return;
    } else if (eventDateTime === null || eventDateTime === "") {
      this.setState({ eventDateTimeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select " + Properties.EventDateTimeLabel + ".",
      });
      this.goToTop();
      return;
    } else if (localEndDate === null || localEndDate === "") {
      this.setState({ localEndDateError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select " + Properties.EventEndDateTimeLabel + ".",
      });
      this.goToTop();
      return;
    } else if (
      registrationEndDateTime === null ||
      registrationEndDateTime === ""
    ) {
      this.setState({ registrationEndDateTimeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select " + Properties.RegistrationEndDateLabel + ".",
      });
      this.goToTop();
      return;
    } else if (eventEndDateTime === null || eventEndDateTime === "") {
      this.setState({ eventEndDateTimeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select " + Properties.EventCloseDateLabel + ".",
      });
      this.goToTop();
      return;
    } else if ((isPublished && eventType === null) || eventType === "") {
      this.setState({ eventTypeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select " + Properties.EventTypeLabel + ".",
      });
      this.goToTop();
      return;
    } else if (
      (isPublished &&
        eventType.value === "CHALLENGE" &&
        challengeType === null) ||
      challengeType === ""
    ) {
      this.setState({ challengeTypeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select " + Properties.ChallengeTypeLabel + ".",
      });
      this.goToTop();
      return;
    } else if (
      isPublished &&
      role === "SUPPORT" &&
      (eventOrganiser === "" ||
        eventNameError === null ||
        eventOrganiser === undefined)
    ) {
      this.setState({ eventOrganiserError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select " + Properties.EventOrganiserLabel + ".",
      });
      this.goToTop();
      return;
    } else if (
      (isPublished && activityType === null) ||
      activityType === undefined ||
      activityType.length === 0
    ) {
      this.setState({ activityTypeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select Activity type.",
      });
      this.goToTop();
      return;
    } else if (isPublished && activityType?.length > 0) {
      for (let j = 0; j < activityType.length; j++) {
        for (let i = 0; i < activityType[j].ActivityList.length; i++) {
          if (
            activityType[j].ActivityList[i].displayName === "" ||
            activityType[j].ActivityList[i].displayName === undefined
          ) {
            let newData = [...activityType];

            newData[j].ActivityList[i].isError = true;
            this.setState({
              activityType: newData,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: newData[j].ActivityList[i].displayName
                ? "Please enter activity name for " +
                  newData[j].ActivityList[i].displayName
                : "Please enter activity name for " +
                  ApplicationUtil.getCamelCasing(
                    newData[j].ActivityList[i].value
                  ),
            });
            this.goToTop();
            return;
          } else if (
            activityType[j].ActivityList[i].selectedValues === "" ||
            activityType[j].ActivityList[i].selectedValues === undefined ||
            activityType[j].ActivityList[i].selectedValues === null
          ) {
            let newData = [...activityType];
            newData[j].ActivityList[i].isSelectedValueError = true;
            this.setState({
              activityType: newData,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: newData[j].ActivityList[i].displayName
                ? "Please select categories for " +
                  newData[j].ActivityList[i].displayName
                : "Please select categories for " +
                  ApplicationUtil.getCamelCasing(
                    newData[j].ActivityList[i].value
                  ),
            });
            this.goToTop();
            return;
          } else if (
            activityType[j].ActivityList[i]?.selectedValues?.length > 0
          ) {
            let newData = [...activityType];
            for (
              let k = 0;
              k < activityType[j].ActivityList[i].selectedValues.length;
              k++
            ) {
              if (
                activityType[j].ActivityList[i].selectedValues[k]
                  .categoryDisplayName === "" ||
                activityType[j].ActivityList[i].selectedValues[k]
                  .categoryDisplayName === undefined
              ) {
                activityType[j].ActivityList[i].selectedValues[
                  k
                ].isDisplayNameError = true;
                Snackbar.show({
                  variant: "error",
                  isAutoHide: true,
                  preventDuplicate: true,
                  message:
                    "Please select category name for " +
                    activityType[j].ActivityList[i].selectedValues[k].title,
                });
                this.setState({
                  activityType: newData,
                });
                this.goToTop();
                return;
              } else if (
                (!activityType[j].ActivityList[i].selectedValues[k]
                  .categoryFee &&
                  activityType[j].ActivityList[i].selectedValues[k]
                    .categoryFee !== 0) ||
                // newData[i].selectedValues[j].categoryFee === "" ||
                activityType[j].ActivityList[i].selectedValues[k]
                  .categoryFee === undefined
              ) {
                activityType[j].ActivityList[i].selectedValues[
                  k
                ].isFeeError = true;
                Snackbar.show({
                  variant: "error",
                  isAutoHide: true,
                  preventDuplicate: true,
                  message:
                    "Please enter category fee for " +
                    activityType[j].ActivityList[i].title +
                    activityType[j].ActivityList[i].selectedValues[k].title,
                });
                this.setState({
                  activityType: newData,
                });
                this.goToTop();
                return;
              } else if (
                // newData[i].selectedValues[j].categoryMaxTicketCount === "" ||
                (!activityType[j].ActivityList[i].selectedValues[k]
                  .categoryMaxTicketCount &&
                  activityType[j].ActivityList[i].selectedValues[k]
                    .categoryMaxTicketCount !== 0) ||
                activityType[j].ActivityList[i].selectedValues[k]
                  .categoryMaxTicketCount === undefined
              ) {
                activityType[j].ActivityList[i].selectedValues[
                  k
                ].isMaxTicketCountError = true;
                Snackbar.show({
                  variant: "error",
                  isAutoHide: true,
                  preventDuplicate: true,
                  message:
                    "Please select max ticket count name for " +
                    activityType[j].ActivityList[i].selectedValues[k].title,
                });
                this.setState({
                  activityType: newData,
                });
                this.goToTop();
                return;
              } else if (
                // newData[i].selectedValues[j].categoryMaxTicketCount === "" ||
                !activityType[j].ActivityList[i].selectedValues[k]
                  .bibStartNumber ||
                activityType[j].ActivityList[i].selectedValues[k]
                  .bibStartNumber === undefined
              ) {
                activityType[j].ActivityList[i].selectedValues[
                  k
                ].isBibStartNumberError = true;
                Snackbar.show({
                  variant: "error",
                  isAutoHide: true,
                  preventDuplicate: true,
                  message:
                    "Please select BIB start number for " +
                    activityType[j].ActivityList[i].selectedValues[k].title,
                });
                this.setState({
                  activityType: newData,
                });
                this.goToTop();
                return;
              }
            }
          }
        }
      }
      if (isPublished && eventItems?.length > 0) {
        let submitFlag = false;

        for (let i = 0; i < eventItems.length; i++) {
          if (i == eventItems.length - 1) {
            submitFlag = true;
          }
          if (
            eventItems[i].type == "" ||
            eventItems[i].type == undefined ||
            eventItems[i].type == null
          ) {
            let newData = [...eventItems];
            newData[i].typeError = true;
            this.setState({
              eventItems: newData,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message:
                "Please select type for " +
                ApplicationUtil.getCamelCasing(eventItems[i].value),
            });
            this.goToTop();
            return;
          } else if (
            (!eventItems[i].pricePerUnit && eventItems[i].pricePerUnit !== 0) ||
            eventItems[i].pricePerUnit == undefined
          ) {
            let newData = [...eventItems];
            newData[i].isPricePerUnitError = true;
            this.setState({
              eventItems: newData,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message:
                "Please select pricer per unit for " +
                ApplicationUtil.getCamelCasing(eventItems[i].value),
            });
            this.goToTop();
            return;
          } else if (
            (!eventItems[i].maxQuantity && eventItems[i].maxQuantity !== 0) ||
            eventItems[i].maxQuantity == undefined
          ) {
            let newData = [...eventItems];
            newData[i].isMaxQuantityError = true;
            this.setState({
              eventItems: newData,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message:
                "Please select maximum quantity for " +
                ApplicationUtil.getCamelCasing(eventItems[i].value),
            });
            this.goToTop();
            return;
          } else if (
            eventItems[i].value == "T-Shirt" &&
            (eventItems[i].sizeMale == "" ||
              eventItems[i].sizeMale == undefined)
          ) {
            let newData = [...eventItems];
            newData[i].isSizeMaleError = true;
            this.setState({
              eventItems: newData,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message:
                "Please select " +
                ApplicationUtil.getCamelCasing(eventItems[i].value) +
                " sizes for male",
            });
            this.goToTop();
            return;
          } else if (
            eventItems[i].value == "T-Shirt" &&
            (eventItems[i].sizeFemale == "" ||
              eventItems[i].sizeFemale == undefined)
          ) {
            let newData = [...eventItems];
            newData[i].isSizeFemaleError = true;
            this.setState({
              eventItems: newData,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message:
                "Please select " +
                ApplicationUtil.getCamelCasing(eventItems[i].value) +
                " sizes for female",
            });
            this.goToTop();
            return;
          }
        }
        this.saveFields();
      } else {
        this.saveFields();
      }
    } else {
      this.saveFields();
    }
  };

  validateFieldsForPublish = () => {
    const {
      eventName,
      eventNameError,
      eventScope,
      eventDateTime,
      eventEndDateTime,
      registrationEndDateTime,
      eventType,
      challengeType,
      localEndDate,
      activityType,
      role,
      eventOrganiser,
      eventItems,
      eventParticipationType
    } = this.state;
    this.setState({
      publishClicked: true,
    });
    if (eventName === "" || eventNameError === true) {
      this.setState({ eventNameError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter Event name.",
      });
      this.goToTop();
      return;
    } else if (eventScope?.value === "" || eventScope?.value === undefined) {
      this.setState({ eventScopeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select Event Visibility.",
      });
      this.goToTop();
      return;
    } else if (eventDateTime === null || eventDateTime === "") {
      this.setState({ eventDateTimeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select " + Properties.EventDateTimeLabel + ".",
      });
      this.goToTop();
      return;
    } else if (localEndDate === null || localEndDate === "") {
      this.setState({ localEndDateError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select " + Properties.EventEndDateTimeLabel + ".",
      });
      this.goToTop();
      return;
    } else if (
      registrationEndDateTime === null ||
      registrationEndDateTime === ""
    ) {
      this.setState({ registrationEndDateTimeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select " + Properties.RegistrationEndDateLabel + ".",
      });
      this.goToTop();
      return;
    } else if (eventEndDateTime === null || eventEndDateTime === "") {
      this.setState({ eventEndDateTimeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select " + Properties.EventCloseDateLabel + ".",
      });
      this.goToTop();
      return;
    } else if (eventType === null || eventType === "") {
      this.setState({ eventTypeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select " + Properties.EventTypeLabel + ".",
      });
      this.goToTop();
      return;
    } else if (
      (eventType.value === "CHALLENGE" && challengeType === null) ||
      challengeType === ""
    ) {
      this.setState({ challengeTypeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select " + Properties.ChallengeTypeLabel + ".",
      });
      this.goToTop();
      return;
    } else if (
      eventParticipationType === null ||
      eventParticipationType === ""
    ) {
      this.setState({ eventParticipationTypeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select " + Properties.EventParticipationLabel + ".",
      });
      this.goToTop();
      return;
    } else if (
      role === "SUPPORT" &&
      (eventOrganiser === "" ||
        eventNameError === null ||
        eventOrganiser === undefined)
    ) {
      this.setState({ eventOrganiserError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select " + Properties.EventOrganiserLabel + ".",
      });
      this.goToTop();
      return;
    } else if (
      activityType === null ||
      activityType === undefined ||
      activityType.length === 0
    ) {
      this.setState({ activityTypeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select Activity type.",
      });
      this.goToTop();
      return;
    } else if (activityType?.length > 0) {
      for (let j = 0; j < activityType.length; j++) {
        for (let i = 0; i < activityType[j].ActivityList.length; i++) {
          if (
            activityType[j].ActivityList[i].displayName === "" ||
            activityType[j].ActivityList[i].displayName === undefined
          ) {
            let newData = [...activityType];

            newData[j].ActivityList[i].isError = true;
            this.setState({
              activityType: newData,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: newData[j].ActivityList[i].displayName
                ? "Please enter activity name for " +
                  newData[j].ActivityList[i].displayName
                : "Please enter activity name for " +
                  ApplicationUtil.getCamelCasing(
                    newData[j].ActivityList[i].value
                  ),
            });
            this.goToTop();
            return;
          } else if (
            activityType[j].ActivityList[i].selectedValues === "" ||
            activityType[j].ActivityList[i].selectedValues === undefined ||
            activityType[j].ActivityList[i].selectedValues === null
          ) {
            let newData = [...activityType];
            newData[j].ActivityList[i].isSelectedValueError = true;
            this.setState({
              activityType: newData,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: newData[j].ActivityList[i].displayName
                ? "Please select categories for " +
                  newData[j].ActivityList[i].displayName
                : "Please select categories for " +
                  ApplicationUtil.getCamelCasing(
                    newData[j].ActivityList[i].value
                  ),
            });
            this.goToTop();
            return;
          } else if (
            activityType[j].ActivityList[i]?.selectedValues?.length > 0
          ) {
            let newData = [...activityType];
            for (
              let k = 0;
              k < activityType[j].ActivityList[i].selectedValues.length;
              k++
            ) {
              if (
                activityType[j].ActivityList[i].selectedValues[k]
                  .categoryDisplayName === "" ||
                activityType[j].ActivityList[i].selectedValues[k]
                  .categoryDisplayName === undefined
              ) {
                activityType[j].ActivityList[i].selectedValues[
                  k
                ].isDisplayNameError = true;
                Snackbar.show({
                  variant: "error",
                  isAutoHide: true,
                  preventDuplicate: true,
                  message:
                    "Please select category name for " +
                    activityType[j].ActivityList[i].selectedValues[k].title,
                });
                this.setState({
                  activityType: newData,
                });
                this.goToTop();
                return;
              } else if (
                (!activityType[j].ActivityList[i].selectedValues[k]
                  .categoryFee &&
                  activityType[j].ActivityList[i].selectedValues[k]
                    .categoryFee !== 0) ||
                // newData[i].selectedValues[j].categoryFee === "" ||
                activityType[j].ActivityList[i].selectedValues[k]
                  .categoryFee === undefined
              ) {
                activityType[j].ActivityList[i].selectedValues[
                  k
                ].isFeeError = true;
                Snackbar.show({
                  variant: "error",
                  isAutoHide: true,
                  preventDuplicate: true,
                  message:
                    "Please enter category fee for " +
                    activityType[j].ActivityList[i].title +
                    activityType[j].ActivityList[i].selectedValues[k].title,
                });
                this.setState({
                  activityType: newData,
                });
                this.goToTop();
                return;
              } else if (
                // newData[i].selectedValues[j].categoryMaxTicketCount === "" ||
                (!activityType[j].ActivityList[i].selectedValues[k]
                  .categoryMaxTicketCount &&
                  activityType[j].ActivityList[i].selectedValues[k]
                    .categoryMaxTicketCount !== 0) ||
                activityType[j].ActivityList[i].selectedValues[k]
                  .categoryMaxTicketCount === undefined
              ) {
                activityType[j].ActivityList[i].selectedValues[
                  k
                ].isMaxTicketCountError = true;
                Snackbar.show({
                  variant: "error",
                  isAutoHide: true,
                  preventDuplicate: true,
                  message:
                    "Please select max ticket count name for " +
                    activityType[j].ActivityList[i].selectedValues[k].title,
                });
                this.setState({
                  activityType: newData,
                });
                this.goToTop();
                return;
              } else if (
                // newData[i].selectedValues[j].categoryMaxTicketCount === "" ||
                !activityType[j].ActivityList[i].selectedValues[k]
                  .bibStartNumber ||
                activityType[j].ActivityList[i].selectedValues[k]
                  .bibStartNumber === undefined
              ) {
                activityType[j].ActivityList[i].selectedValues[
                  k
                ].isBibStartNumberError = true;
                Snackbar.show({
                  variant: "error",
                  isAutoHide: true,
                  preventDuplicate: true,
                  message:
                    "Please select BIB start number for " +
                    activityType[j].ActivityList[i].selectedValues[k].title,
                });
                this.setState({
                  activityType: newData,
                });
                this.goToTop();
                return;
              }
            }
          }
        }
      }

      //this.saveFields();

      if (eventItems?.length > 0) {
        let submitFlag = false;

        for (let i = 0; i < eventItems.length; i++) {
          if (i == eventItems.length - 1) {
            submitFlag = true;
          }
          if (
            eventItems[i].type == "" ||
            eventItems[i].type == undefined ||
            eventItems[i].type == null
          ) {
            let newData = [...eventItems];
            newData[i].typeError = true;
            this.setState({
              eventItems: newData,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message:
                "Please select type for " +
                ApplicationUtil.getCamelCasing(eventItems[i].value),
            });
            this.goToTop();
            return;
          } else if (
            (!eventItems[i].pricePerUnit && eventItems[i].pricePerUnit !== 0) ||
            eventItems[i].pricePerUnit == undefined
          ) {
            let newData = [...eventItems];
            newData[i].isPricePerUnitError = true;
            this.setState({
              eventItems: newData,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message:
                "Please select pricer per unit for " +
                ApplicationUtil.getCamelCasing(eventItems[i].value),
            });
            this.goToTop();
            return;
          } else if (
            (!eventItems[i].maxQuantity && eventItems[i].maxQuantity !== 0) ||
            eventItems[i].maxQuantity == undefined
          ) {
            let newData = [...eventItems];
            newData[i].isMaxQuantityError = true;
            this.setState({
              eventItems: newData,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message:
                "Please select maximum quantity for " +
                ApplicationUtil.getCamelCasing(eventItems[i].value),
            });
            this.goToTop();
            return;
          } else if (
            eventItems[i].value == "T-Shirt" &&
            (eventItems[i].sizeMale == "" ||
              eventItems[i].sizeMale == undefined)
          ) {
            let newData = [...eventItems];
            newData[i].isSizeMaleError = true;
            this.setState({
              eventItems: newData,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message:
                "Please select " +
                ApplicationUtil.getCamelCasing(eventItems[i].value) +
                " sizes for male",
            });
            this.goToTop();
            return;
          } else if (
            eventItems[i].value == "T-Shirt" &&
            (eventItems[i].sizeFemale == "" ||
              eventItems[i].sizeFemale == undefined)
          ) {
            let newData = [...eventItems];
            newData[i].isSizeFemaleError = true;
            this.setState({
              eventItems: newData,
            });
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message:
                "Please select " +
                ApplicationUtil.getCamelCasing(eventItems[i].value) +
                " sizes for female",
            });
            this.goToTop();
            return;
          } else {
            if (submitFlag) {
              this.getPublishCall();
            }
          }
        }
      } else {
        this.getPublishCall();
      }
    }
  };

  getPublishCall = () => {
    let eventId = ApplicationUtil.getSupportsEventID();
    publishEvent(eventId).then((res) => {
      if (res.data.success.code === "200") {
        Snackbar.show({
          variant: "success",
          message: res.data.success.verbose,
        });
      } else if (res.data.success.code === "400") {
        let msg = res.data.success.verbose;
        //msg = "hello \n hi \n dcdsc \n";
        // msg = msg.split("\n");
        // let showMsg;
        // msg.map((e) => <ul>{e}</ul>);

        msg.replace("\n", "");
        Snackbar.show({
          variant: "error",
          message: msg,
        });
      }
    });
  };

  saveFields = () => {
    const {
      eventName,
      eventScope,
      eventDateTime,
      eventEndDateTime,
      description,
      longDescription,
      registrationEndDateTime,
      eventType,
      challengeType,
      eventParticipationType,
      localEndDate,
      activityType,
      eventOrganiser,
      toleranceDistance,
      role,
      eventItems,
      supportedDomains,
      selectedNotificationMode,
      TShirtSizeChartFile,
      TShirtSizeChart,
      hasTimingMat,
    } = this.state;

    //timezone
    const timeZone = momentTimeZone.tz.guess();
    // const offset = new Date().getTimezoneOffset();
    // const userTimeZone = moment.tz.zone(timezone).abbr(offset);

    let customeParams = {
      timeZone: timeZone,
      name: eventName,
      eventScope: eventScope?.value,
      eventStartDate: moment(eventDateTime).format("DD-MM-YYYY HH:mm:ss"),
      // eventTime: moment(eventDateTime).format("HH:mm:ss"),
      eventEndDate:
        moment(eventEndDateTime).format("DD-MM-YYYY") +
        " " +
        moment(eventEndDateTime).format("HH:mm:ss"),
      description: description,
      longDescription: longDescription,
      registrationEndDate:
        moment(registrationEndDateTime).format("DD-MM-YYYY") +
        " " +
        moment(registrationEndDateTime).format("HH:mm:ss"),
      type: eventType?.value,
      eventParticipantType: eventParticipationType?.value,

      localStartDate: moment(eventDateTime).format("YYYY-MM-DD"),
      localEndDate: localEndDate,
      eventSettings: {
        hasTimingMat: hasTimingMat ? true : false,
      },
    };

    //activity and runCats
    let categoryList = [];
    if (activityType?.length > 0) {
      activityType.forEach((ele) => {
        ele.ActivityList.forEach((element) => {
          let item = {};
          item.id = ele.id; //activity id
          //multiple activities
          item.displayName = element.displayName;
          if (eventType && eventType.value === "CHALLENGE") {
            item.challengeParams = element?.challengeParams?.value;
            item.minChallengeValue = element?.minChallengeValue;
          }
          item.maxTolerance = element?.maxTolerance ? element.maxTolerance : 0;
          item.allowActivityUpload = element?.allowActivityUpload;
          item.participantType = element?.participantType?.value;
          item.eventSupportedActivityTypeId =
            element?.supportedActivityType?.id;
          let category = [];
          for (let i = 0; i < element?.selectedValues?.length; i++) {
            let newItem = {};

            newItem.id = element.selectedValues[i].id;
            newItem.category = element?.selectedValues[i]?.value;
            newItem.categoryDisplayName =
              element.selectedValues[i].categoryDisplayName;
            newItem.categoryFee = element?.selectedValues[i]?.categoryFee;
            newItem.categoryMaxTicketCount =
              element?.selectedValues[i]?.categoryMaxTicketCount;
            newItem.eventSupportedActivityTypeId =
              element?.supportedActivityType?.id;
            newItem.bibStartNumber = element?.selectedValues[i]?.bibStartNumber;
            newItem.participantType =
              eventParticipationType?.value === "ALL"
                ? element?.participantType?.value
                : "REGULAR";
            newItem.standardCompletionTime = hasTimingMat
              ? element?.selectedValues[i]?.standardCompletionTime
                ? element?.selectedValues[i]?.standardCompletionTime +
                  ":" +
                  "00"
                : null
              : null;

            newItem.routeCoordinates = hasTimingMat
              ? element?.selectedValues[i]?.routeCoordinates
              : null;
            newItem.actualDistance =
              element?.selectedValues[i]?.actualDistance * 1000;
            newItem.challengeGoal =
              element?.selectedValues[i]?.challengeGoal?.value;
            category.push(newItem);
          }

          item.category = category;
          categoryList.push(item);
        });
      });
      customeParams.activityTypeWithCategory = categoryList;
    }
    //eventItems
    let eventItem = [];
    if (eventItems?.length > 0) {
      eventItems.forEach((element) => {
        let item = {};

        item.id = element?.id;
        item.type = element?.type?.value;
        item.itemName = element?.value;
        item.pricePerUnit = element?.pricePerUnit;
        item.maxQuantity = element?.maxQuantity;
        item.readOnly =
          element?.type?.value === "EXPLICITE" ? false : element.readOnly;

        if (element.value == "T-Shirt") {
          let maleSize = "",
            femaleSize = "";

          {
            element &&
              element.sizeFemale &&
              element.sizeFemale.forEach((i) => {
                femaleSize = femaleSize.concat(i.value + ",");
              });
          }

          {
            element &&
              element.sizeMale &&
              element.sizeMale.forEach((i) => {
                maleSize = maleSize.concat(i.value + ",");
              });
          }

          femaleSize = femaleSize?.replace(/,\s*$/, "");
          maleSize = maleSize?.replace(/,\s*$/, "");

          if (maleSize) item.sizeMale = maleSize;
          if (femaleSize) item.sizeFemale = femaleSize;
        }
        eventItem.push(item);
      });
    }
    if (eventItem?.length > 0) {
      customeParams.eventItems = eventItem;
    }

    if (selectedNotificationMode?.length > 0) {
      let list = [];

      selectedNotificationMode.forEach((ele) => {
        let item = {};
        item.id = ele.id;
        item.notificationMode = ele.value;
        list.push(item);
      });
      customeParams.notificationModes = list;
    }

    if (challengeType?.value) {
      customeParams.challengeType = challengeType.value;
    }

    if (eventOrganiser?.value) {
      customeParams.organizers = [
        {
          id: eventOrganiser.id,
          name: eventOrganiser.value,
          // primaryEmail: null,
        },
      ];
    }

    if (supportedDomains?.length > 0) {
      customeParams.eventSupportedDomains = supportedDomains;
    }

    updateEventDetails(customeParams, TShirtSizeChartFile).then((res) => {
      let type = eventType?.value;
      let eventId = ApplicationUtil.getSupportsEventID();

      localStorage.setItem(
        "supportActivities",
        JSON.stringify(this.state.activities)
      );
      // if (this.state.publishClicked) {
      //   publishEvent(eventId).then((res) => {
      //     if (res.data.success.code === "200") {
      //       Snackbar.show({
      //         variant: "success",
      //         message: res.data.success.verbose,
      //       });
      //     } else if (res.data.success.code === "400") {
      //       let msg = res.data.success.verbose;
      //       //msg = "hello \n hi \n dcdsc \n";
      //       // msg = msg.split("\n");
      //       // let showMsg;
      //       // msg.map((e) => <ul>{e}</ul>);

      //       msg.replace("\n", "");
      //       Snackbar.show({
      //         variant: "error",
      //         message: msg,
      //       });
      //     }
      //   });
      //   this.setState({ publishEvent: false });
      // } else {
      //   this.setState({ submitDialog: true });
      // }
      this.setState({ submitDialog: true });
      //   // this.props.onSuccess();
      //   // this.props.history.push("/organizer/eventlist");
    });
  };

  editDescription = () => {
    return (
      <div
        style={{
          width: "80%",
          height: "auto",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <NewTextEditor
          htmlText={this.state.description ? this.state.description : ""}
          getEditedValue={(value) => {
            let formattedValue = value === "<p></p>" ? null : value.toString();

            this.setState({ description: formattedValue });
          }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <SubmitCancelBtn
            type="submit"
            label={"Submit"}
            onClick={() => {
              this.setState({
                descriptionDialog: false,
                htmlDescription: this.state.description,
              });
            }}
          />
        </div>
      </div>
    );
  };

  editLongDescription = () => {
    return (
      <div
        style={{
          width: "80%",
          height: "auto",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <NewTextEditor
          htmlText={
            this.state.longDescription ? this.state.longDescription : ""
          }
          getEditedValue={(value) => {
            let formattedValue = value === "<p></p>" ? null : value.toString();

            this.setState({ longDescription: formattedValue });
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <SubmitCancelBtn
            type="submit"
            label={"Submit"}
            onClick={() => {
              this.setState({
                longDescriptionDialog: false,
                htmlLongDescription: this.state.longDescription,
              });
            }}
          />
        </div>
      </div>
    );
  };
  copyLink = () => {
    Snackbar.show({
      variant: "success",
      isAutoHide: true,
      preventDuplicate: true,
      message: "Link Copied",
    });
  };
  render() {
    const { classes } = this.props;
    const {
      isPublished,
      role,
      eventName,
      eventNameError,
      eventOrganiser,
      eventOrganiserError,
      eventOrganiserList,
      eventScope,
      eventScopeError,
      eventScopeList,
      eventDateTime,
      eventDateTimeError,
      eventEndDateTime,
      eventEndDateTimeError,

      htmlDescription,
      htmlLongDescription,
      registrationEndDateTime,
      registrationEndDateTimeError,
      eventType,
      eventTypeError,
      eventTypeList,
      challengeType,
      challengeTypeError,
      challengeTypeList,
      eventParticipationType,
      eventParticipationTypeError,
      eventParticipationTypeList,

      participantTypeList,
      localEndDate,
      localEndDateError,
      activityType,
      activityTypeError,
      activityTypeList,
      eventItems,
      eventItemsList,
      femaleTShirtSizeList,
      itemTypeList,
      supportedDomains,
      supportedDomainsError,
      notificationModeOptionList,
      selectedNotificationMode,
      isPastEvent,
      eventLink,
      hasTimingMat,
    } = this.state;
    return (
      <div>
        <Grid container spacing={1} style={{ padding: "10px 0px" }}>
          {/* 1 */}
          <Grid item xs={6}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.labelCss}>
                {Properties.EventNameLabel}
              </span>
              <span className={classes.mandatoryCss}>*</span>
            </Typography>
            <CustomTextField
              value={eventName || ""}
              className={
                eventNameError ? classes.fieldErrorCss : classes.fieldCss
              }
              handleChange={(prop) => (e) => {
                this.setState({
                  eventName: e.target.value,
                  eventNameError: false,
                });
              }}
              readOnly={isPastEvent}
            />
          </Grid>

          {/* 2 */}
          <Grid item xs={6}>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.helpContainerCss}
            >
              <span className={classes.labelCss}>
                {Properties.EventScopeLabel}
              </span>
              <span className={classes.mandatoryCss}>*</span>
              <span className={classes.helpCss}>
                <Tooltip title={Properties.EventScopeHelpText}>
                  <HelpIcon fontSize="small" />
                </Tooltip>
              </span>
            </Typography>
            <div className={classes.dropdownCss}>
              <MultiSelect
                value={eventScope || ""}
                error={eventScopeError}
                options={eventScopeList}
                onChange={this.handleEventScopeChange}
                isReadOnly={isPastEvent}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.labelCss}>
                {Properties.EventTypeLabel}
              </span>
              <span className={classes.mandatoryCss}>*</span>
            </Typography>
            <div className={classes.dropdownCss}>
              <MultiSelect
                value={eventType || ""}
                error={eventTypeError}
                options={eventTypeList}
                onChange={this.handleEventTypeChange}
                isReadOnly={isPastEvent}
              />
            </div>
          </Grid>
          {/* event link */}
          <Grid item xs={6}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.labelCss}>{"Event Link"}</span>
            </Typography>
            <div
              style={{
                width: isBrowser ? "360px" : "160px",
                height: "30px",
                border: "1px solid #08456C",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "2px",
                color: "#08456C",
              }}
            >
              <CustomTextField
                type="text"
                className={classes.fieldLinkCss}
                value={eventLink}
                handleChange={(prop) => () => {}}
                readOnly
              />
              <CopyToClipboard text={eventLink} onCopy={this.copyLink}>
                <button
                  style={{ cursor: "pointer", height: "30px", border: "none" }}
                >
                  Copy
                </button>
              </CopyToClipboard>
            </div>
          </Grid>
          {/* 3 */}
          <Grid item xs={6}>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.helpContainerCss}
            >
              <span className={classes.labelCss}>
                {Properties.EventDateTimeLabel}
              </span>
              <span className={classes.mandatoryCss}>*</span>
              <span className={classes.helpCss}>
                <Tooltip title={Properties.EventStartsFromHelpText}>
                  <HelpIcon fontSize="small" />
                </Tooltip>
              </span>
            </Typography>
            <div>
              <KeyboardDateTimePicker
                style={{
                  width: isBrowser ? "360px" : "160px",
                }}
                autoOk
                clearable
                variant="inline"
                inputVariant="outlined"
                // ampm={false}
                format="dd/MM/yyyy hh:mm a" //:ss-hh" //"dd/MM/yyyy"
                // disableFuture={true}
                value={eventDateTime}
                maxDate={localEndDate}
                maxDateMessage={Properties.EventStartDateValidationMsg}
                FormHelperTextProps={{
                  style: {
                    margin: "0px",
                  },
                }}
                className={classes.fieldWidth}
                InputProps={{
                  classes: {
                    root: eventDateTimeError
                      ? classes.datePickerError
                      : classes.datePicker,
                  },
                  readOnly: { isPastEvent },
                }}
                readOnly={isPastEvent}
                keyboardIcon={
                  <IconCalendarBlue className={classes.calendarIconCss} />
                }
                onChange={(date) => {
                  this.setState({
                    eventDateTime: date ? date : null,
                    eventDateTimeError: false,
                  });
                }}
              />
            </div>
          </Grid>

          {/* 4 */}
          <Grid item xs={6}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.labelCss}>
                {Properties.EventEndDateTimeLabel}
              </span>
              <span className={classes.mandatoryCss}>*</span>
            </Typography>
            <div>
              <KeyboardDatePicker
                autoOk
                clearable
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                // disableFuture={true}
                value={localEndDate}
                style={{
                  width: isBrowser ? "360px" : "160px",
                }}
                minDate={moment(eventDateTime).format("YYYY/MM/DD")}
                minDateMessage={Properties.EventEndDateValidationMsg}
                FormHelperTextProps={{
                  style: {
                    margin: "0px",
                  },
                }}
                //error={dobError}
                className={classes.fieldWidth}
                InputProps={{
                  classes: {
                    root: localEndDateError
                      ? classes.datePickerError
                      : classes.datePicker,
                  },
                  readOnly: { isPastEvent },
                }}
                readOnly={isPastEvent}
                keyboardIcon={
                  <IconCalendarBlue className={classes.calendarIconCss} />
                }
                onChange={(date) => {
                  this.setState({
                    localEndDate: date,
                    localEndDateError: false,
                  });
                }}
              />
            </div>
          </Grid>

          {/* 5 */}
          <Grid item xs={6}>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.helpContainerCss}
            >
              <span className={classes.labelCss}>
                {Properties.RegistrationEndDateLabel}
              </span>
              <span className={classes.mandatoryCss}>*</span>
              <span className={classes.helpCss}>
                <Tooltip title={Properties.RegistrationEndDateHelpText}>
                  <HelpIcon fontSize="small" />
                </Tooltip>
              </span>
            </Typography>
            <div>
              <KeyboardDateTimePicker
                style={{
                  width: isBrowser ? "360px" : "160px",
                }}
                autoOk
                clearable
                variant="inline"
                inputVariant="outlined"
                // ampm={false}
                format="dd/MM/yyyy hh:mm a" //:ss-hh" //"dd/MM/yyyy"
                // disableFuture={true}
                value={registrationEndDateTime}
                maxDate={localEndDate}
                maxDateMessage={Properties.RegistrationEndDateValidationMsg}
                FormHelperTextProps={{
                  style: {
                    margin: "0px",
                  },
                }}
                className={classes.fieldWidth}
                InputProps={{
                  classes: {
                    root: registrationEndDateTimeError
                      ? classes.datePickerError
                      : classes.datePicker,
                  },
                }}
                readOnly={isPastEvent}
                keyboardIcon={
                  <IconCalendarBlue className={classes.calendarIconCss} />
                }
                onChange={(date) => {
                  this.setState({
                    registrationEndDateTime: date,
                    registrationEndDateTimeError: false,
                  });
                }}
              />
            </div>
          </Grid>

          {/* 6 */}
          <Grid item xs={6}>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.helpContainerCss}
            >
              <span className={classes.labelCss}>
                {Properties.EventCloseDateLabel}
              </span>
              <span className={classes.mandatoryCss}>*</span>
              <span className={classes.helpCss}>
                <Tooltip title={Properties.EventCloseDateHelpText}>
                  <HelpIcon fontSize="small" />
                </Tooltip>
              </span>{" "}
            </Typography>
            <div>
              <KeyboardDateTimePicker
                style={{
                  width: isBrowser ? "360px" : "160px",
                }}
                autoOk
                clearable
                variant="inline"
                inputVariant="outlined"
                // ampm={false}
                format="dd/MM/yyyy hh:mm a" //:ss-hh" //"dd/MM/yyyy"
                // disableFuture={true}
                value={eventEndDateTime}
                minDate={moment(localEndDate).format("YYYY/MM/DD")}
                minDateMessage={Properties.ActivityUploadDateValidationMsg}
                FormHelperTextProps={{
                  style: {
                    margin: "0px",
                  },
                }}
                className={classes.fieldWidth}
                InputProps={{
                  classes: {
                    root: eventEndDateTimeError
                      ? classes.datePickerError
                      : classes.datePicker,
                  },
                  readOnly: { isPastEvent },
                }}
                readOnly={isPastEvent}
                keyboardIcon={
                  <IconCalendarBlue className={classes.calendarIconCss} />
                }
                onChange={(date) => {
                  this.setState({
                    eventEndDateTime: date ? date : null,
                    eventEndDateTimeError: false,
                  });
                }}
              />
            </div>
          </Grid>

          {/* 7 */}
          <Grid item xs={6}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.labelCss}>
                {Properties.DescriptionLabel}
              </span>
            </Typography>

            <div
              style={{
                width: "440px",
                height: "100px",
                border: "1px solid #08456C",
                overflowY: "auto",
                padding: "5px",
              }}
              onClick={() => {
                if (!isPastEvent) {
                  this.setState({
                    descriptionDialog: true,
                    htmlDescription: "",
                  });
                }
              }}
            >
              {htmlDescription && (
                <NewTextEditor readOnly htmlText={htmlDescription} />
              )}
            </div>
          </Grid>

          {/* 8 */}
          <Grid item xs={6}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.labelCss}>
                {Properties.LongDescriptionLabel}
              </span>
            </Typography>

            <div
              style={{
                width: "440px",
                height: "100px",
                border: "1px solid #08456C",
                overflowY: "auto",
                padding: "5px",
              }}
              onClick={() => {
                if (!isPastEvent) {
                  this.setState({
                    longDescriptionDialog: true,
                    htmlLongDescription: "",
                  });
                }
              }}
            >
              {htmlLongDescription && (
                <NewTextEditor readOnly htmlText={htmlLongDescription} />
              )}
            </div>
          </Grid>

          {/* 9 */}

          {eventType?.value == "CHALLENGE" && (
            <Grid item xs={6}>
              <Typography variant="body2" gutterBottom>
                <span className={classes.labelCss}>
                  {Properties.ChallengeTypeLabel}
                </span>
                <span className={classes.mandatoryCss}>*</span>
              </Typography>
              <div className={classes.dropdownCss}>
                <MultiSelect
                  value={challengeType || ""}
                  error={challengeTypeError}
                  options={challengeTypeList}
                  onChange={this.handleChallengeType}
                  isReadOnly={isPastEvent}
                />
              </div>
            </Grid>
          )}

          {/* 10 */}

          {role == "SUPPORT" && (
            <Grid item xs={6}>
              <Typography variant="body2" gutterBottom>
                <span className={classes.labelCss}>
                  {Properties.EventOrganiserLabel}
                </span>
                <span className={classes.mandatoryCss}>*</span>
              </Typography>
              <div className={classes.dropdownCss}>
                <MultiSelect
                  value={eventOrganiser || ""}
                  error={eventOrganiserError}
                  options={eventOrganiserList}
                  onChange={this.handleOrganiserChange}
                  isReadOnly={isPastEvent}
                />
              </div>
            </Grid>
          )}
          <Grid item xs={6}>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.helpContainerCss}
            >
              <span className={classes.labelCss}>
                Supported Domains {/* {Properties.EventNameLabel} */}
              </span>
              <span className={classes.helpCss}>
                <Tooltip title={Properties.SupportedDomianHelpText}>
                  <HelpIcon fontSize="small" />
                </Tooltip>
              </span>
            </Typography>
            <div>
              <ChipInput
                className={
                  supportedDomainsError ? classes.chipErrorCss : classes.chipCss
                }
                fullWidth={false}
                newChipKeyCodes={[13, 32]}
                blurBehavior="add"
                value={supportedDomains || ""}
                onAdd={(chip) => this.handleAddChip(chip)}
                onDelete={(chip, index) => this.handleDeleteChips(chip, index)}
                readOnly={isPastEvent}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.labelCss}>
                {Properties.EventParticipationLabel}
              </span>
              <span className={classes.mandatoryCss}>*</span>
            </Typography>
            <div className={classes.dropdownCss}>
              <MultiSelect
                value={eventParticipationType || ""}
                error={eventParticipationTypeError}
                options={eventParticipationTypeList}
                onChange={this.handleEventParticipationTypeChange}
                isReadOnly={isPastEvent}
              />
            </div>
          </Grid>

          {/* 11 */}
          <Grid item xs={6}>
            <Typography variant="body2" gutterBottom>
              <span className={classes.labelCss}>
                {Properties.Notification_Mode_label}
              </span>
            </Typography>
            <div className={classes.dropdownCss}>
              <MultiSelect
                isMulti={true}
                value={selectedNotificationMode || ""}
                // error={notificationModeError}
                options={notificationModeOptionList}
                onChange={(e) => {
                  this.handleNotificationMode(e);
                }}
                isReadOnly={isPastEvent}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <span className={classes.labelCss}>Timing Mat</span>

            <Switch
              checked={hasTimingMat}
              color="primary"
              onChange={(e) => {
                this.setState({
                  hasTimingMat: e.target.checked,
                });
              }}
              disabled={isPastEvent}
            />
          </Grid>
        </Grid>

        <div
          style={{
            padding: "10px",
          }}
        >
          <InlineDivider style={{ height: "5px", backgroundColor: "#08456C" }}>
            {Properties.EventCategoriesLabel}
          </InlineDivider>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body2" gutterBottom>
                <span className={classes.labelCss}>
                  {Properties.Activity_type_label}
                </span>
                <span className={classes.mandatoryCss}>*</span>
              </Typography>
              <div className={classes.dropdownCss}>
                <MultiSelect
                  isMulti={true}
                  value={activityType || ""}
                  error={activityTypeError}
                  options={activityTypeList}
                  onChange={(e) => {
                    this.handleActivityTypeChange({ selectedValues: e });
                  }}
                  isReadOnly={isPastEvent}
                />
              </div>
            </Grid>

            {/* display Name for activities */}
            {/* {activityType?.length > 0 &&
              activityType.map((row, index) =>
                row.value ? (
                  <Grid container key={index}>
                    {this.renderRequiredRow(row)}
                  </Grid>
                ) : null
              )} */}

            {activityType?.length > 0 &&
              activityType.map((row, index) => (
                <div key={index}>
                  {row?.ActivityList?.map((innerRow, innerIndex) => (
                    <Grid container key={innerIndex}>
                      {this.renderRequiredRow(row, innerRow, innerIndex)}
                    </Grid>
                  ))}
                </div>
              ))}
          </Grid>
        </div>

        {/* items section */}
        <div
          style={{
            padding: "10px",
          }}
        >
          <InlineDivider style={{ height: "5px", backgroundColor: "#08456C" }}>
            {Properties.EventItemsLabel}
          </InlineDivider>
          <Grid container>
            <Grid item xs={6} className={classes.multiSelectGridCss}>
              <Typography variant="body2" gutterBottom>
                <span className={classes.labelCss}>
                  {Properties.ItemsLabel}
                </span>
              </Typography>
              <div className={classes.dropdownCss}>
                <MultiSelect
                  isMulti={true}
                  value={eventItems || ""}
                  // error={eventItemsError}
                  options={eventItemsList}
                  onChange={(e) => {
                    this.handleEventItemsChange(e);
                  }}
                  isReadOnly={isPastEvent}
                />
              </div>
            </Grid>

            <Grid item xs={12} style={{ padding: "5px" }}>
              {eventItems?.length > 0 && (
                <EventItemsTable
                  eventItems={eventItems}
                  // row={row}
                  itemTypeList={itemTypeList}
                  handleEventItemTypeChange={this.handleEventItemTypeChange}
                  femaleTShirtSizeList={femaleTShirtSizeList}
                  handleMaleTShirtSizeChange={this.handleMaleTShirtSizeChange}
                  handleFemaleTShirtSizeChange={
                    this.handleFemaleTShirtSizeChange
                  }
                  handleSizeChartUpload={this.handleSizeChartUpload}
                  handleSizeChartRemove={this.handleSizeChartRemove}
                  TShirtSizeChart={this.state.TShirtSizeChart}
                  readOnly={isPastEvent}
                />
              )}
            </Grid>
          </Grid>
        </div>

        {!isPastEvent && (
          <Grid
            container
            style={{
              margin: "20px 5px 10px 5px",
              // display: "flex",
              // justifyContent: "center",
            }}
          >
            <Grid item xs style={{ display: "flex", justifyContent: "center" }}>
              <SubmitCancelBtn
                onClick={(e) => this.handleCancel()}
                label={"Cancel"}
                type="submit"
                style={{ width: "200px" }}
              />
            </Grid>

            <Grid item xs style={{ display: "flex", justifyContent: "center" }}>
              <SubmitCancelBtn
                onClick={(e) => this.validateFieldsForDraft(e)}
                label={"Save"}
                type="submit"
                style={{ width: "200px" }}
              />
            </Grid>

            {!isPublished && role == "SUPPORT" && (
              <Grid
                item
                xs
                style={{ display: "flex", justifyContent: "center" }}
              >
                <SubmitCancelBtn
                  onClick={(e) => this.validateFieldsForPublish(e)}
                  label={"Publish"}
                  type="submit"
                  style={{ width: "200px" }}
                />
              </Grid>
            )}
          </Grid>
        )}
        {/* submit on success popup */}
        <DialogBox
          signup={true}
          open={this.state.submitDialog}
          message={this.getSuccessDialogMsg()}
          header={this.state.dialogHeader}
          onClick={() => {
            this.props.onSuccess();
          }}
          // fullWidth
          // maxWidth={"xs"}
          closeDisloag={() => this.closeWindow()}
        />

        {/* cancel dialog */}
        <DialogBox
          signup={true}
          open={this.state.showCancelDialog}
          message={this.getDialogMsg()}
          header={this.state.dialogHeader}
          label={"No"}
          onClick={() => {
            this.closeWindow();
          }}
          labelTwo={"Yes"}
          onClickTwo={() => {
            this.closeDialogBox();
          }}
          closeDisloag={() => this.closeWindow()}
        />

        <DialogBox
          fullWidth
          maxWidth={"md"}
          open={this.state.descriptionDialog}
          message={this.editDescription()}
          header={"Event Summary"}
          onClick={() =>
            this.setState({
              descriptionDialog: false,
              htmlDescription: this.state.description,
            })
          }
          closeDisloag={() =>
            this.setState({
              descriptionDialog: false,
              htmlDescription: this.state.description,
            })
          }
        />
        <DialogBox
          fullWidth
          maxWidth={"md"}
          open={this.state.longDescriptionDialog}
          message={this.editLongDescription()}
          header={"Event Guidelines"}
          onClick={() =>
            this.setState({
              longDescriptionDialog: false,
              htmlLongDescription: this.state.longDescription,
            })
          }
          closeDisloag={() =>
            this.setState({
              longDescriptionDialog: false,
              htmlLongDescription: this.state.longDescription,
            })
          }
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    supportDTO: state.supportUser.supportDTO,
    supportSelectedEvent: state.supportUser.supportSelectedEvent,
  };
}
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateSupportDTODetails,
    updateSupportSelectedEvent,
  })
)(BasicEditView);
