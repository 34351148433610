import React, { Component } from "react";
import { useHistory, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";

import { NoLeaderboard } from "../../../../common/SvgIcon";
import { TabPanel } from "@material-ui/lab";
import { Tab, Tabs } from "@material-ui/core";
import Properties from "../../../../../properties/Properties";
import { isMobile } from "react-device-detect";
import TeamAnalytics from "./TeamAnalytics";
import CompanyAnalytics from "./CompanyAnalytics";

const styles = (theme) => ({
  textTransformCss: {
    textTransform: "none",
    fontSize: "22px",
    fontWeight: "bold",
  },

  noLeaderboard: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "100px",
  },
});

class AnalyticsView extends Component {
  constructor(props) {
    super(props);

    this.state = { ActiveTabIndex: 0 };
  }

  componentDidMount() {
    const { history } = this.props;
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      ActiveTabIndex: newValue,
    });
  };
  render() {
    const { classes } = this.props;
    const { ActiveTabIndex } = this.state;

    return (
      <div>
        <div className={classes.summary}>
          <Tabs
            value={ActiveTabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleTabChange}
            variant={isMobile ? "scrollable" : "fullWidth"}
            scrollButtons={isMobile ? "on" : "off"}
          >
            <Tab
              value={0}
              label={Properties.CompanyAnalyticsLabel}
              className={classes.textTransformCss}
            />
            <Tab
              value={1}
              label={Properties.TeamAnalyticsLabel}
              className={classes.textTransformCss}
            />
          </Tabs>
          {ActiveTabIndex === 0 && (
            <CompanyAnalytics
            //   onSuccess={(e) => {
            //     this.handleTabChange(e, 2);
            //   }}
            />
          )}
          {ActiveTabIndex === 1 && (
            <TeamAnalytics
              onSuccess={(e) => {
                this.handleTabChange(e, 2);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(AnalyticsView);
