import React, { Component } from "react";
import AppToolBar from "../../common/AppToolBar";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Properties from "./../../../properties/Properties";
import {
  getEventActivityDetails,
  getEventDetails,
  getRegisteredEventDetails,
  getRunnerActivityList,
} from "./../../../apis/eventService";
import ApplicationConstants from "../../../constants/ApplicationConstants";
import ApplicationUtil from "../../../utils/ApplicationUtil";
import ImageDialogBox from "../../common/ImageDialogBox";
import { format } from "date-fns";
import Header from "../../common/Header";
import UploadForm from "./UploadForm";

import RunnerSideBreadcrumbs from "../../common/RunnerSideBreadcrumbs";
const styles = (theme) => ({
  root: {
    marginTop: "68px",
  },
  input: {
    display: "none",
  },
  boxCss: {
    display: "flex",
    alignItems: "center",
  },
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
  },
  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
  },
  fieldWidth: {
    width: "100%",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },
  boxPaddingCss: {
    paddingRight: "0px !important",
  },

  //  input:{display:"none"}
});

class UploadRunContainer extends Component {
  state = {
    limit: 3,
    userName: "",
    anchorEl: null,
    eventCategoryList: [],
    hour: "",
    hourError: "",
    minute: "",
    minuteError: "",
    second: "",
    secondError: "",
    activityUrl: "",
    activityUrlError: false,
    image: null,
    selectedFile: null,
    hoursList: ApplicationConstants.Hours,
    uploadedFiles: [],
    postRunPicture: [],
    postRunPreview: null,
    image_src: null,
    preview: null,
    selectedCategory: {},
    imageDialog: false,
    date: null,
    startTime: "06:00",
    distance: "",
    distanceError: false,
    eventType: "",
    showActivityDate: false,
    timeValidation: "",
    displayColumns: [],
    steps: "",
    calories: "",
    elevationGain: "",
    otherActivities: null,
    activityDetails: null,
    selectedType: null,
    skipTimeFlag: true,
  };

  componentDidMount() {
    document.title = Properties.Page_Title_Event_UploadRun;
    let checkFromEventDetails = JSON.parse(
      localStorage.getItem("fromEventDetails")
    );
    if (!ApplicationUtil.isuserLoggedIn()) {
      this.props.history.push("/");
    } else if (
      checkFromEventDetails == false ||
      checkFromEventDetails == null
    ) {
      //(!checkFromEventDetails) {
      this.props.history.push("/eventDetails");
    } else {
      let eventId = ApplicationUtil.getRunnersEventID();
      let runnerData = ApplicationUtil.getRunnerDetails();

      let runnerId = runnerData?.runner?.id;

      getRegisteredEventDetails(runnerId, eventId).then((res) => {
        let eventData = res.data;
        // let activityTypeData=res.data?.registration?.eventRunCategories[0]?.eventSupportedActivityType ;
        //console.log('activityTypeData',activityTypeData);
        let otherActivities = res.data?.event?.eventOtherActivityTypes;
        if (otherActivities && otherActivities.length > 0) {
          otherActivities.map(
            (r) => (
              (r.label = r?.displayName),
              (r.value = r?.eventRunCategory?.categoryName
                ? r?.eventRunCategory?.categoryName
                : r?.activityType?.type),
              (r.id = r?.id),
              (r.category = r?.eventRunCategory?.category),
              (r.eventSupportedActivityType = r?.activityType)
            )
          );
        }

        // edit act
        let activityDetails = null;

        if (this.props?.location?.activityDetails !== undefined) {
          activityDetails = this.props?.location?.activityDetails;
        }

        // train actiivty upload
        let trainingActivityData = null;
        if (this.props?.location?.trainingActivityData !== undefined) {
          trainingActivityData = this.props.location.trainingActivityData;
        }

        this.setState(
          {
            eventId: eventId,
            runnerId: runnerId,
            showActivityDate: eventData?.event?.showActivityDateOnForm,
            timeValidation: eventData?.event?.timeValidation,
            skipTimeFlag: eventData.event?.skipTimeOnUploadActivity,
            isAnyActivity:
              eventData?.registration?.eventRunCategories[0]?.activityType
                ?.type === "ANY"
                ? true
                : false,
            otherActivities: otherActivities,
            activityDetails: activityDetails,
            trainingActivityData: trainingActivityData,
            eventType: eventData?.event?.type,
            // selectedType:activityTypeData ,
            uploadRegisteredActType: eventData?.event?.uploadRegisteredActType,
          },

          () => {
            let selected = eventData.registration?.eventRunCategories[0];
            let d = localStorage.getItem("uploadDate");

            if (d) {
              let newD = d.split("-");
              // d = d[1] + ":" + d[0] + ":" + d[2];
              d = newD[1] + "/" + newD[0] + "/" + newD[2];
              d = format(new Date(d), "MM/dd/yyyy");
              this.setState({
                date: d,
              });
            }

            let columns = eventData?.event?.displayColumns
              .split(",")
              .filter((x) => x !== "averagePace" && x !== "averageSpeed");

            let data = selected.category.replace("K", "");
            this.setState({
              eventCategoryList: selected,
              selectedCategory: {
                label: selected.categoryName,
                value: selected.categoryName,
              },
              eventId: eventId,
              displayColumns: columns,
              // parseFloat(selected.category) * 1000
            });
            if (eventData?.event?.type != "CHALLENGE") {
              this.setState({
                distance: ApplicationUtil.getFormattedKM(
                  parseFloat(data).toFixed(2)
                ),
              });
            }
            // }
          }
        );

        //console.log('selectedType',activityTypeData)
        getEventActivityDetails(
          { requestView: "UPLOAD_FORM", runnerId },
          eventId
        ).then((list) => {
          let data = list.data;
          if (data?.length > 0) {
            data.map(
              (r) => (
                (r.label = r.displayName),
                (r.value = r.type),
                (r.displayColumns = r?.primaryDisplayParams
                  ?.toLowerCase()
                  .split(","))
              )
            );
            let otherActivity = {
              label: "Other",
              value: "OTHER",
              type: "OTHER",
              displayColumns: ["time"],
            };
            if (data?.showOtherOnUploadActivity) {
              data.push(otherActivity);
            }

            this.setState({
              activityTypeList: data,
              selectedActivityType: data.find(
                (x) =>
                  x?.eventSupportedActivityTypeId ===
                  res.data?.registration?.eventRunCategories[0]
                    ?.eventSupportedActivityType?.id
              ),
            });
          }

          let id = null;
          id = data.find((item) => item.id);
        });
      });

      localStorage.setItem("fromEventDetails", false);
    }
  }

  componentWillUnmount() {
    localStorage.removeItem("start");
    localStorage.removeItem("end");
    localStorage.removeItem("days");
    localStorage.removeItem("uploadDate");
    localStorage.removeItem("fromEventDetails");
  }

  selectImageHandler = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
    this.fileUploadHandler();
  };
  fileUploadHandler = () => {};
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleLogout = (e) => {
    localStorage.clear();
    ApplicationUtil.go("/");
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  fullPostRunPicture() {
    let file = this.state.postRunPicture;
    this.setState({ imageDialog: true, image_src: this.state.postRunPreview });
  }
  handleImageDelete = (event) => {
    this.setState({ postRunPicture: null, postRunPreview: null });
  };

  isValidDate = (d) => {
    let days = localStorage.getItem("days");

    if (days) {
      if (d) {
        let newd =
          d?.getDate() + "-" + (d?.getMonth() + 1) + "-" + d?.getFullYear();
        let start = localStorage.getItem("start");
        let end = localStorage.getItem("end");
        if (newd >= start && newd <= end) {
          this.setState({ dateError: false });
        } else {
          this.setState({ dateError: true });
        }
      } else {
        this.setState({ dateError: true });
      }
    }

    return d instanceof Date && !isNaN(d);
  };

  handleDateChange = (date, dateError) => {
    if (date > new Date()) {
      this.setState({ dateError: true });
    } else
      this.setState({
        date: this.isValidDate(date) ? date : "",
      });
  };
  handleNavigationAfterSuccessfulUpload = () => {
    this.props.history.goBack();
  };
  handleActivityTypeChange = (selectedValues) => {
    this.setState({
      selectedActivityType: selectedValues,
    });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const {
      timeValidation,
      eventCategoryError,
      eventCategoryList,
      showActivityDate,
      date,
      dateError,
      displayColumns,
      selectedCategory,
      otherActivities,
      isAnyActivity,
      eventId,
      runnerId,
      activityDetails,
      trainingActivityData,
      activityTypeList,
      selectedActivityType,
      selectedType,
      eventType,
      uploadRegisteredActType,
      userRegisteredActivityType,
      skipTimeFlag,
    } = this.state;

    return (
      <React.Fragment>
        <AppToolBar
          backNavigate={() => this.props.history.goBack()}
          showBack={true}
        >
          <Header {...this.props} label={"Manual Entry"} />
          <div style={{ width: 35 }} />
        </AppToolBar>

        <div className={classes.root}>
          <>
            <RunnerSideBreadcrumbs />

            {/* {isAnyActivity ? (
                <ActivityUpload
                  timeValidation={this.state.timeValidation}
                  otherActivities={otherActivities}
                />
              ) : ( */}
            <UploadForm
              eventId={eventId}
              runnerId={runnerId}
              selectedCategory={selectedCategory}
              eventCategoryList={eventCategoryList}
              dateError={dateError}
              date={date}
              handleDateChange={this.handleDateChange}
              handleActivityTypeChange={this.handleActivityTypeChange}
              activityDetails={activityDetails}
              handleNavigationAfterSuccessfulUpload={
                this.handleNavigationAfterSuccessfulUpload
              }
              trainingActivityData={
                trainingActivityData ? trainingActivityData : false
              }
              displayColumns={displayColumns}
              timeValidation={timeValidation}
              showActivityDate={showActivityDate}
              activityTypeList={activityTypeList}
              selectedActivityType={selectedActivityType}
              eventType={eventType}
              showActivityList={uploadRegisteredActType}
              skipTimeOnUploadActivity={skipTimeFlag}
            />
            {/* )} */}
          </>
        </div>
        <ImageDialogBox
          imagePath={this.state.image_src}
          open={this.state.imageDialog}
          onClick={() => this.setState({ imageDialog: false })}
          closeDisloag={() => this.setState({ imageDialog: false })}
        />
      </React.Fragment>
    );
  }
}

// function mapStateToProps(state) {
//   return {
//     user: state.user,
//   };
// }
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(UploadRunContainer);
