class SupportUtil {
  static userRow = "";

  static setUserRow = function(row) {
    this.userRow = row;
  };

  static getUserRow = function() {
    return this.userRow;
  };
}

export default SupportUtil;
