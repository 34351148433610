import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
} from "@material-ui/core";
import { Participants } from "./../../../../common/SvgIcon";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { withRouter } from "react-router-dom";
const styles = (theme) => ({
  summaryTotalCount: {
    marginBottom: "8px",
    display: "flex",
    justifyContent: "center",
  },
  root: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#faedcd",
  },
  cover: {
    fontSize: "50px !important",
    padding: "10px",
    color: "#bc6c25",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // borderLeft: "2px solid gray",

    borderRadius: "5px",
    "& span:nth-child(1)": {
      // marginLeft: "10px",
      // background: "rgba( 149, 213, 178, 0.50 )",
      // boxShadow: "0 4px 3px 0 rgba( 31, 38, 135, 0.37 )",
      // backdropFilter: "blur( 4px )",
      // borderRadius: "10px",
      // border: "1px solid rgba( 255, 255, 255, 0.18 )",
      fontWeight: 600,
      fontSize: "40px",
      // padding: "10px",
    },
    "& span:nth-child(2)": {
      fontWeight: 600,
    },
  },
});
class SummaryTotalCount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalCounts: [
        {
          title: "Total Registrations",
          count: this.props.data?.eventStatistics?.totalRegistrations,
        },
        {
          title: "Total Amount Received",
          count: this.props.data?.eventStatistics?.totalAmountReceived,
        },
      ],
      resData: this.props.data,
    };
  }

  render() {
    const { classes } = this.props;
    const { totalCounts, resData } = this.state;

    return (
      <Grid container spacing={2} className={classes.summaryTotalCount}>
        {totalCounts.map((row, index) => (
          <Grid item xs={4}>
            <Card className={classes.root}>
              {index === 0 && <Participants className={classes.cover} />}
              {index === 1 && resData?.eventStatistics?.paidEvent && (
                <AccountBalanceWalletIcon className={classes.cover} />
              )}
              {/* <div className="details"> */}
              {index === 1 ? (
                resData?.eventStatistics?.paidEvent && (
                  <CardContent className={classes.content}>
                    <span>{row.count}</span>
                    <span>{row.title}</span>
                  </CardContent>
                )
              ) : (
                <CardContent className={classes.content}>
                  <span>{row.count}</span>
                  <span>{row.title}</span>
                </CardContent>
              )}
              {/* </div> */}
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }),withRouter)(
  SummaryTotalCount
);
