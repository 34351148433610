import React, { Component } from "react";
import AppToolBar from "../../common/AppToolBar";
import Properties from "../../../properties/Properties";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import Header from "./../../common/Header";
import { getSubscribedServices } from "../../../apis/eventService";
import { getConsentUrl } from "../../../apis/userService";
import ApplicationUtil from "./../../../utils/ApplicationUtil";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import garminLogo from "./garmin_logo.png";
import stravaLogo from "./strava_logo.png";
import fitbitLogo from "./fitbit_logo.png";
import mapmyrunLogo from "./mapmyrun_logo.png";
import googlefit from "./google_fit.png";
import {
  GarminLogoIcon,
  StravaLogoIcon,
  FitBitIcon,
  MapMyRun,
  GoogleFitIcon,
  GoogleFitText,
  YoutubeIcon,
} from "../../common/SvgIcon";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  mainPanel: {
    paddingRight: theme.rightSpacing,
    paddingLeft: theme.leftSpacing,
  },
  boxCss: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    flexGrow: 1,
    marginLeft: "6.7%",
    marginRight: "6.7%",
    boxShadow: "none !important",
    marginTop: "68px",
  },
  boxPaddingCss: {
    paddingRight: "0px !important",
  },

  reviewImgSmall: {
    position: "relative",
    height: "40px",
    maxHeight: "40px",
    minHeight: "40px",
    width: "220px",
    maxWidth: "220px",
    minWidth: "220px",
  },
  rowGrid: { margin: "0 auto", padding: "5px 20px 0px 20px" },
  rowContainer: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  imageCont: { flex: 1 },
  note: {
    fontSize: "28px",
    color: "red",
    fontWeight: "bold",
  },
});

class DataPermissionsContainer extends Component {
  state = {
    runCycleList: [],
    stepsList: [],
  };

  componentDidMount() {
    document.title = Properties.Page_Title_Activity_Sync;
    // if (!ApplicationUtil.isuserLoggedIn()) {
    //   this.props.history.push("/");
    // } else
    // {
    this.setState({
      userName: localStorage.getItem("userName"),
    });

    let userId = sessionStorage.getItem("consentUserId");
    let runnerId ;
    if (!userId) {
      let userData = ApplicationUtil.getRunnerDetails();
      runnerId = userData?.runnerId
      userId = userData?.id;
    }else{
      let userData = ApplicationUtil.getRunnerDetails();
      runnerId = userData?.runnerId
    }

    
    let eventId = sessionStorage.getItem("consentEventId");
    getSubscribedServices(runnerId ,eventId).then((res) => {
      if (res.data && res.data.length > 0) {
        let d = res.data;
        d.forEach((element) => {
          if (element.name == "STRAVA") {
            element.image = stravaLogo;
          }
          if (element.name == "GARMIN") {
            element.image = garminLogo;
          }
          if (element.name == "FITBIT") {
            element.image = fitbitLogo;
          }
          if (element.name == "MAPMYRUN") {
            element.image = mapmyrunLogo;
          }
          if (element.name == "GOOGLEFIT") {
            element.image = googlefit;
          }
        });
        let runCycleList = [],
          stepsList = [];
        d.map((e) => {
          if (
            e.supportedActivities.includes("RUN") ||
            e.supportedActivities.includes("CYCLE")
          ) {
            runCycleList.push(e);
          }
          if (e.supportedActivities.includes("STEPS")) {
            stepsList.push(e);
          }
          // if (e.supportedActivities.includes("DUATHLON")) {
          //   duathlonList.push(e);

          // }
        });
        this.setState({
          runCycleList: runCycleList,
          stepsList: stepsList,
          // duathlonList: duathlonList,
          eventId: eventId,
          userId: userId,
        });
      }
    });
    // }
  }

  componentWillUnmount() {
    sessionStorage.removeItem("consentUserId");
    sessionStorage.removeItem("consentEventId");
  }

  navigateToProfile = () => {
    this.props.history.push("/profile");
  };

  validateFields = (row) => {
    const { userId } = this.state;
    const programDetails = ApplicationUtil.getRunnersProgramDetails();
    getConsentUrl(row.id, userId).then((res) => {
      if (res.data && res.data.consentLink)
        window.location.href = res.data.consentLink;
      localStorage.setItem("consentLink", res.data.consentLink);
      if (programDetails?.programStartDate) {
        localStorage.setItem("navigateToCalender", true);
      }
    });
  };
  displayRow = (row) => {
    const { classes } = this.props;
    return (
      <div className={classes.rowGrid}>
        <Card
          className={classes.rowContainer}
          style={{ cursor: "pointer", padding: "15px" }}
          // onClick={() => this.validateFields(row)}
        >
          {row.image && (
            <>
              {" "}
              <div
                className={classes.imageCont}
                onClick={() => this.validateFields(row)}
              >
                {row.name == "STRAVA" ? (
                  <StravaLogoIcon style={{ width: "100px" }} />
                ) : row.name == "FITBIT" ? (
                  <FitBitIcon style={{ width: "100px" }} />
                ) : row.name == "GARMIN" ? (
                  <GarminLogoIcon style={{ width: "100px" }} />
                ) : row.name == "MAPMYRUN" ? (
                  <MapMyRun
                    style={{
                      width: "180px",
                      marginTop: "10px",
                    }}
                  />
                ) : row.name == "GOOGLEFIT" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <GoogleFitIcon />
                    <GoogleFitText
                      style={{
                        width: "80px",
                        marginLeft: "5px",
                      }}
                    />
                    <span>FIT</span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
          <div>
            <a
              href={
                row.name == "GARMIN"
                  ? "https://youtu.be/WqDyqULpG-M"
                  : row.name == "STRAVA"
                  ? "https://youtu.be/fY0XJ4-AZYg"
                  : row.name == "GOOGLEFIT"
                  ? "https://youtu.be/3UGq6P9lGSY"
                  : row.name == "FITBIT"
                  ? "https://youtu.be/ASA4KXQJvmU"
                  : row.name == "MAPMYRUN"
                  ? "https://youtu.be/YJKuLlBnTKY"
                  : "https://youtu.be/fY0XJ4-AZYg"
              }
              target="_blank"
            >
              <YoutubeIcon />
            </a>
          </div>
        </Card>
      </div>
    );
  };
  goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    const {
      anchorEl,
      runCycleList,
      stepsList,
      // duathlonList
    } = this.state;
    const open = Boolean(anchorEl);
    const { classes } = this.props;
    return (
      <React.Fragment>
        <AppToolBar
          backNavigate={() => {
            this.props.history.goBack();
          }}
          showBack={true}
        >
          <Header {...this.props} label={Properties.ActivitySync_label} />
        </AppToolBar>
        <div className={classes.root}>
          <p className={classes.note}>
            Note: Make sure App you are syncing is installed in your mobile
            device.
          </p>
          {runCycleList && runCycleList.length > 0 && (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Run/Walk/Cycle/Hike
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid xs={12} container>
                  {runCycleList.map((row, index) => (
                    <Grid item xs={12} key={index}>
                      {this.displayRow(row)}
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
          {stepsList && stepsList.length > 0 && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Steps</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid xs={12} container>
                  {stepsList.map((row, index) => (
                    <Grid item xs={12} key={index}>
                      {this.displayRow(row)}
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
          {/* <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Duathlon</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {" "}
              <Grid xs={12} container>
                {duathlonList &&
                  duathlonList.length > 0 &&
                  duathlonList.map((row, index) => (
                    <Grid item xs={12} key={index}>
                      {this.displayRow(row)}
                    </Grid>
                  ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        */}
        </div>

        {/* </Grid> */}
      </React.Fragment>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(DataPermissionsContainer);
