import { Grid, Tab, Tabs, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import "../../../../../styles/organizer.css";
import ThumbnailViewer from "../ThumbnailViewer";
import ImageDialogBox from "../../../../common/ImageDialogBox";

/**
 * -Vishal
 * Created for reusable purposes to show participant details in dynamic manner
 * @param {*} param0
 * @returns
 */
const OrganizerProgramParticipantInfo = ({
  tabsArr = [],
  tabsDetails = [],
  commonData = null,
  classes,
}) => {
  const [ActiveTabIndex, setActiveTabIndex] = useState(0);
  const [activeLabelKey, setActiveLabelKey] = useState(tabsArr[0]);
  const [dialogueDetails, setDialogueDetails] = useState({
    title: null,
    open: false,
    imagePath: null,
  });

  /**
   * Handle the Active labelKey for showing the correspond data
   */
  const handleTabChange = (event, value) => {
    setActiveTabIndex(value);
    setActiveLabelKey(event.target.innerText);
    // setActiveLabelKey(event.target.innerHTML);
  };

  //close certificte dialogue close
  const closeWindow = () => {
    setDialogueDetails({ title: null, open: false, imagePath: null });
  };

  //disply Label inn dynamic manner
  const displayTitle = (label) => {
    return <Typography className={classes.displayTitle}>{label}</Typography>;
  };

  //display label values in dynamic manner
  const displayTitleValue = (labelVal) => {
    return (
      <Typography className={classes.displayInfo}>
        {labelVal && typeof labelVal !== "object" ? labelVal : "-"}
      </Typography>
    );
  };

  /**
   * -Vishal
   * Function to render the UI based on tab selection
   * @param {*} activeLabelKey
   * @returns
   */
  const showData = (activeLabelKey) => {
    let _data =
      tabsDetails &&
      tabsDetails?.find((obj) => obj?.labelKey === activeLabelKey);
    let _dataKeysArr = _data && Object.keys(_data?.details);

    return (
      <>
        {_data ? (
          <Grid container>
            {_dataKeysArr?.map((key, ind) => (
              <Grid key={ind + 1} item xs={6} className={classes.bottomMargin}>
                {displayTitle(key)}
                {key === "consent"
                  ? _data?.details[key] && _data?.details[key]?.length > 0
                    ? _data?.details[key]?.map((val) =>
                        displayTitleValue(
                          `${val?.name} ${
                            val?.status === "ACTIVE" ? "(Active)" : ""
                          }`
                        )
                      )
                    : displayTitleValue("")
                  : displayTitleValue(_data?.details[key])}
              </Grid>
            ))}
          </Grid>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              color: "gray",
              fontSize: "25px",
            }}
          >
            <p>No Data Available</p>
          </div>
        )}
      </>
    );
  };

  /**
   * To show goodies tab data - for certificate
   * @param {*} activeLabelKey
   * @returns
   */
  const showGoodies = (activeLabelKey) => {
    let _data =
      tabsDetails &&
      tabsDetails?.find((obj) => obj?.labelKey === activeLabelKey);
    return (
      <>
        {_data ? (
          <Grid container spacing={3} style={{ margin: "25px 0px" }}>
            <Grid xs={4}>
              <ThumbnailViewer
                title={"Certificate"}
                isProgram={true}
                src={_data?.details?.details?.imageData}
                onClick={(e) => {
                  setDialogueDetails({
                    dialogTitle: "Certificate",
                    open: true,
                    imagePath: `data:image/png;base64,${_data?.details?.details?.imageData}`,
                  });
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              color: "gray",
              fontSize: "25px",
            }}
          >
            <p>No Data Available</p>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={classes.mainBody}>
      <Grid className={classes.mainCss}>
        <Grid>
          <Typography className={classes.runnerNameCss}>
            {commonData?.name}
          </Typography>
        </Grid>

        <Tabs
          value={ActiveTabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          variant={isMobile ? "scrollable" : "fullWidth"}
          scrollButtons={isMobile ? "on" : "off"}
        >
          {/* Show Dynamic Tabs based on props */}
          {tabsArr?.map((tab) => (
            <Tab label={tab} className={classes.textTransformCss} />
          ))}
        </Tabs>
        {/* Show Dynamic Data based on tab selection (labelKey) */}
        <div className={classes.root}>
          {activeLabelKey === "Goodies"
            ? showGoodies("Goodies")
            : showData(activeLabelKey)}
        </div>

        {/* Dialogue box for open the certificate */}
        <ImageDialogBox
          title={dialogueDetails?.dialogTitle}
          open={dialogueDetails?.open}
          imagePath={dialogueDetails?.imagePath}
          closeDisloag={() => closeWindow()}
        />
      </Grid>
    </div>
  );
};

const styles = (theme) => ({
  mainBody: {
    marginTop: "0px",
    overscrollBehavior: "auto contain",
  },
  textTransformCss: {
    textTransform: "none",
    fontSize: "22px",
    fontWeight: "bold",
  },
  displayTitle: {
    color: "rgb(114, 114, 114)",
    textTransform: "capitalize",
    fontSize: isMobile ? "16px" : "20px",
  },
  displayInfo: {
    color: "rgb(40, 131, 181)",
    fontSize: isMobile ? "20px" : "24px",
  },
  mainCss: {
    width: "100%",
    // marginTop: "65px",
    padding: "15px",
  },
  runnerNameCss: {
    color: "rgb(40, 131, 181)",
    fontWeight: "600",
    fontSize: "34px",
  },
  bottomMargin: {
    marginBottom: "15px",
  },
});
export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(OrganizerProgramParticipantInfo);
