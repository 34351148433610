import { withStyles } from "@material-ui/styles";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { getAllTipsUnderChallenge } from "../../../../../../../apis/eventService";
import TipCreationComponent from "./TipCreationComponent";
import TipsTable from "./TipsTable";

/**
 * Vishal- Sepration of tips related operation
 * @param {*} param
 * @returns
 */
const TipsSection = ({ classes, isPastChallenge }) => {
  /**
   * States
   */
  const [tipsSesctionState, setTipsSectionState] = useState({
    allTipsList: [],
    editTipData: null,
  });
  const myRef = useRef(null);

  let { allTipsList, editTipData } = tipsSesctionState;

  /**
   * when click on a edit action button
   * @param {*} tipData
   */
  const onTipEditClick = (tipData) => {
    setTipsSectionState((prev) => ({
      ...prev,
      editTipData: tipData,
    }));
    myRef.current.scrollIntoView({
      block: "center",
      inline: "center",
      behavior: "instant",
    });
  };

  const onTipEditClear = () => {
    setTipsSectionState((prev) => ({
      ...prev,
      editTipData: null,
    }));
  };

  /**
   * Fetch all tips
   */
  const getAllTips = () => {
    getAllTipsUnderChallenge().then((res) => {
      if (res?.data && res?.data?.length) {
        setTipsSectionState((prev) => ({
          ...prev,
          allTipsList: res?.data || [],
        }));
      }
    });
  };

  useEffect(() => {
    getAllTips();
  }, []);

  return (
    <div>
      <div
        style={{
          height: "1px",
          backgroundColor: "#9cb5c4",
        }}
        ref={myRef} // user click on edit tip then user scroll into this view
      ></div>
      <TipCreationComponent
        isPastChallenge={isPastChallenge}
        editTipData={editTipData}
        onTipEditClear={onTipEditClear}
        getAllTips={getAllTips}
      />
      <TipsTable list={allTipsList} onTipEditClick={onTipEditClick} />
    </div>
  );
};

const styles = (theme) => ({});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(TipsSection);
