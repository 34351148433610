import React, { Component } from "react";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import AppToolBar from "../../common/AppToolBar";
import { getTokenAuthenticated } from "../../../apis/userService";
import Header from "../../common/Header";
import {
  updateUserLink,
} from "../../../redux/user";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import ApplicationUtil from "../../../utils/ApplicationUtil";
import { loading } from "../../common/Spinner";
import Properties from "../../../properties/Properties";

const styles = (theme) => ({
  input: {
    display: "none",
  },
  root: {
    marginTop: "68px",
  },
  mainPanel: {
    width: "100%",
    height: "90vh",
    margin: "auto",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      height: "60vh",
      overflowX: "hidden",
    },
  },
});

class VideoPlayerContainer extends Component {
  state = {
    URL: "",
  };
  componentDidMount() {
    document.title = Properties.Page_Title_VideoPlayer;
    loading(true);
    let userUrl = this.props?.location?.link
      ? this.props?.location?.link
      : ApplicationUtil.getRunnerVideoURL(); ;
    
      this.validateTokenAndURL(userUrl);
    loading(false)
  }

  validateTokenAndURL = (userUrl) => {
    var formData = new FormData();
    formData.set("link", userUrl?.link);
    getTokenAuthenticated(formData)
      .then((res) => {
        this.setState({ URL: userUrl });
        this.props.updateUserLink({
          link: userUrl?.link,

          source: userUrl?.source,
          filename: userUrl?.filename,

        });
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  navigateToURL = () => {
    //  window.location=(this.state.URL, "_blank");
    //  window.href=this.state.URL;//, "_blank").focus();
    let a = document.createElement("a");
    a.target = "_blank";
    a.href = this.state.URL;
    a.click();
  };

  render() {
    const { classes } = this.props;
    const { URL } = this.state;

    return (
      <React.Fragment>
        <AppToolBar>
          <Header
            {...this.props}
            label={URL?.filename ? URL.filename : Properties.Label_NDVideo}
          />
        </AppToolBar>
        <div className={classes.root}>
          <div className={classes.mainPanel}>
            <iframe
              src={URL?.link}
              width="100%"
              height="90%"
              frameborder="0"
              scrolling="no"
              allow="autoplay"
              oallowfullscreen="true"
              msallowfullscreen="true"
              allowFullScreen="true"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
            ></iframe>

            {/* <div style={{ height: "90vh" }}>
              <ReactPlayer
                width="100%"
                height="100%"
                controls
                url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" //"https://iframe.dacast.com/vod/8e6ee0a7-d121-f2ae-cb53-c15793d677cb/ff817a5c-0476-ca91-2880-977893371c7b"//{URL}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
              />
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    updateUserLink: state.user.userUrl,
  };
}
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateUserLink,
  })
)(VideoPlayerContainer);
