import { Grid, Switch, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import SubmitCancelBtn from "../../../../../common/SubmitCancelBtn";
import Properties from "../../../../../../properties/Properties";
import DialogBox from "../../../../../common/DialogBox";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";
import {
  getAllProgramSettingsFlags,
  updateProgramFlags,
} from "../../../../../../apis/eventService";
import Snackbar from "../../../../../common/Snackbar";

const ProgramSetting = ({ classes }) => {
  const [programSettingState, setProgramSettingState] = useState({
    programSettings: [],
    showCancelDialog: false,
    flag: false,
  });
  const currentProgram = ApplicationUtil.getSupportsProgramDetails();
  const { programSettings, showCancelDialog } = programSettingState;
  useEffect(() => {
    if (currentProgram) {
      additionalFlagLoad();
    }
  }, []);

  //   Fetching flag values from backend
  const additionalFlagLoad = () => {
    getAllProgramSettingsFlags(currentProgram?.id).then((res) => {
      let eventSettingsKeys = Object.keys(res?.data?.programSettings || {});
      let eventSettingsData = [];
      for (let i = 0; i < eventSettingsKeys.length; i++) {
        let obj = {
          id: i + 1,
          label: res.data?.programSettingsLabel[eventSettingsKeys[i]],
          value: JSON.parse((res.data?.programSettings[eventSettingsKeys[i]]).toLowerCase()),
          name: eventSettingsKeys[i],
        };
        eventSettingsData.push(obj);
      }

      setProgramSettingState((prev) => ({
        ...prev,
        programSettings: eventSettingsData,
        showCancelDialog: false,
      }));
    });
  };

  //   onchange Event on the flag switch
  const handleChecked = (val, item) => {
    let findIndex = programSettings?.findIndex((x) => {
      return x.id === item.id;
    });
    let array = programSettings;
    array[findIndex].value = val.target.checked;
    setProgramSettingState((prev) => ({ ...prev, programSettings: array }));
  };

  // ---- flag changes cancel ----
  const cancelFlagsChanges = () => {
    setProgramSettingState((prev) => ({
      ...prev,
      showCancelDialog: true,
      flag: true,
    }));
  };

  //  ----- Popup msg ----
  const getDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.CreateEvent_CancelClickWarning}</span>
        </Typography>
      </div>
    );
  };

  //   ---- Close popup and reload the settings again  ----
  const closeDialogBox = () => {
    if (!programSettingState?.flag) {
      setProgramSettingState((prev) => ({
        ...prev,
        showCancelDialog: false,
      }));
    } else {
      additionalFlagLoad();
    }
  };

  //   -------  Submit app flags updates   --------
  const handleSubmitFlags = () => {
    let additionalSettingsObject = {};
    for (let i = 0; i < programSettings.length; i++) {
      additionalSettingsObject[
        programSettings[i].name
      ] = `${programSettings[i].value}`;
    }

    updateProgramFlags({
      data: additionalSettingsObject,
      id: currentProgram?.id,
    }).then((res) => {
      Snackbar.show({
        variant: "success",
        isAutoHide: true,
        preventDuplicate: true,
        message: res.data.verbose,
      });
    });
  };
  return (
    <div className={classes.root}>
      <h3 className={classes.title}>Additional Settings</h3>

      <Grid container>
        {programSettings?.map((item, key) => {
          return (
            <>
              {" "}
              <Grid item xs={6}>
                <span className={classes.labelCss}>{item.label}</span>
                <Switch
                  color="primary"
                  checked={item.value}
                  onChange={(value) => handleChecked(value, item)}
                  //   disabled={isPastChallenge}
                />
              </Grid>
            </>
          );
        })}

        {/* Buttons */}
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              padding: "30px 0",
            }}
          >
            <SubmitCancelBtn
              onClick={(e) => cancelFlagsChanges()}
              label={"Cancel"}
              type="submit"
              style={{ width: "150px" }}
            />

            <SubmitCancelBtn
              onClick={(e) => handleSubmitFlags()}
              label={"Update"}
              type="submit"
              style={{ width: "150px" }}
            />
          </div>
        </Grid>
      </Grid>

      <DialogBox
        signup={true}
        open={showCancelDialog}
        message={getDialogMsg()}
        header={""}
        label={"No"}
        onClick={() => {
          setProgramSettingState((prev) => ({
            ...prev,
            showCancelDialog: false,
          }));
        }}
        labelTwo={"Yes"}
        onClickTwo={() => {
          closeDialogBox();
        }}
        closeDisloag={() =>
          setProgramSettingState((prev) => ({
            ...prev,
            showCancelDialog: false,
          }))
        }
      />
    </div>
  );
};
const styles = (theme) => ({});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ProgramSetting);
