import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Properties from "./../../../properties/Properties";
import AppToolBar from "../../common/AppToolBar";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import Grid from "@material-ui/core/Grid";
import { CustomTextField } from "../../common/CustomTextField";
import { validateEmail } from "./../../../utils/Validator";
import SubmitCancelBtn from "../../common/SubmitCancelBtn";
import { forgotPassword } from "./../../../apis/passwordService";
import DialogBox from "./../../common/DialogBox";
import Header from "../../common/Header";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  mainPanel: {
    paddingRight: theme.rightSpacing,
    paddingLeft: theme.leftSpacing,
    marginTop: "60px",
  },
});

class ForgotpassContainer extends Component {
  state = {
    open: false, //for dialogbox
    emailError: false,
    email: "",
    dialogMsg: "",
    dialogHeader: "",
  };
  validateEmailId = (value) => {
    let isValidEmail = validateEmail(value);
    if (isValidEmail) {
      //RegistrationUtil.setUserEmail(value);
      localStorage.setItem("userEMail", value);
      this.setState({ email: value, emailError: false });
    } else {
      this.setState({ emailError: true, email: value });
    }
  };

  validateFields = () => {
    const { email = "", emailError = "" } = this.state;

    if (email === "" || emailError === true) {
      this.setState({ emailError: true });
    } else {
      this.saveFields();
    }
  };

  saveFields = () => {
    const { email } = this.state;
    let params = {
      email: email,
    };
    var data = email;

    forgotPassword(params).then((res) => {
      localStorage.setItem("data", JSON.stringify(data));
      //this.props.history.push("/resetpass");
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { emailError } = this.state;
    return (
      <React.Fragment>
        <AppToolBar
          backNavigate={() => this.props.history.goBack()}
          showBack={true}
        >
          <Header signup label={Properties.Label_Forgotpass} />
        </AppToolBar>
        <div className={classes.mainPanel}>
          <Grid
            style={{
              width: "100%",
              margin: "0px",
              padding: "0 20px",
            }}
            container
            spacing={1}
          >
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272", fontSize: "20px" }}>
                  By clicking button below, you will get mail at mail id you
                  entered below, with the link to reset password for your
                  account
                </span>
              </Typography>
            </Grid>
            <Grid
              style={{
                width: "100%",
                height: "75px",
                margin: "0px",
                padding: "10px",
              }}
              container
              spacing={1}
            >
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>Email Id</span>
                <span style={{ color: "red" }}>*</span>
              </Typography>{" "}
              <CustomTextField
                style={
                  emailError
                    ? {
                        border: "1px solid red",
                        height: "60%",
                        maxHeight: "60%",
                        minHeight: "60%",
                        width: "100%",
                      }
                    : {
                        border: "1px solid #08456C",
                        height: "60%",
                        maxHeight: "60%",
                        minHeight: "60%",
                        width: "100%",
                      }
                }
                type={"email"}
                keyToSet={"email"}
                handleChange={(prop) => (event) => {
                  this.validateEmailId(event.target.value);
                }}
              />
            </Grid>

            <Grid
              style={{
                width: "100%",
                //   height: "100%",
                marginTop: "10px",
                justifyContent: "center",
                margin: "0px",
                textAlign: "center",
              }}
              container
              spacing={1}
            >
              <Grid
                style={{
                  marginTop: "5%",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <SubmitCancelBtn
                  onClick={() => this.validateFields()}
                  label={"Reset Password"}
                  type="submit"
                  style={{
                    width: "200px",
                    textAlign: "center",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <DialogBox
            open={this.state.open}
            message={this.state.dialogMsg}
            header={this.state.dialogHeader}
            onClick={() => this.handleClose()}
          />
        </div>
      </React.Fragment>
    );
  }
}

//export default ForgotpassContainer;
export default compose(withStyles(styles, { withTheme: true }),withRouter)(
  ForgotpassContainer
);
