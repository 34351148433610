import React from "react";
import compose from "recompose/compose";
import Team from "./Team";
import { withStyles } from "@material-ui/core/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import {
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Collapse,
} from "@material-ui/core";

import MultiSelect from "./../../../common/MultiSelect";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  uploadPostRunImages,
  getRegisteredEventDetails,
  downloadCertificateFile,
  downloadFitnessReportFile,
  downloadRunnersFile,
  downloadRoutesFile,
  getPostRunImage,
  downloadRunnerCollageFile,
  getorderDetails,
  getCaptureDetails,
  downloadVideoFile,
  getTrainingPlan,
  postBarScannerData,
  getGoodiesImage,
  getSummaryImpressions,
  getDetailsScanned,
  OrganizerUpdateForm,
  uploadPostRunVideo,
  deletePostRunVideo,
  getScanPointsList,
  postSelectedScanPoint,
} from "../../../../apis/eventService";
import Properties from "../../../../properties/Properties";

import AppToolBar from "../../../common/AppToolBar";
import ShareIcon from "@material-ui/icons/Share";

import SubmitCancelBtn from "../../../common/SubmitCancelBtn";

import RefreshIcon from "@material-ui/icons/Refresh";
import {
  InviteIcon,
  LeaderboardIcon,
  NoActivityAvailable,
  SummaryDashboard,
} from "../../../common/SvgIcon";
import EditIcon from "@material-ui/icons/Edit";
import ApplicationUtil from "../../../../utils/ApplicationUtil";

import {
  getMultipleGraphDetails,
  getIndividualGraphDetails,
  getRunnerGraphDetails,
} from "./../../../../apis/graphService";
import { deletePostRunImage } from "./../../../../apis/eventService";
import Header from "./../../../common/Header";
import ActivityView from "./ActivityView";

import DialogBox from "../../../common/DialogBox";
import ImageSlider from "../../../common/ImageSlider";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import clsx from "clsx";
import Snackbar from "../../../common/Snackbar";

import { connect } from "react-redux";
import {
  updateUserSelectedEvent,
  updateUserPreferences,
} from "../../../../redux/user";
import { FitnessCenter, Forum } from "@material-ui/icons";
import CropFreeIcon from "@material-ui/icons/CropFree";
import { withRouter } from "react-router-dom";

import { QrScanner } from "@yudiel/react-qr-scanner";
import ParticipantDetailsScanned from "../../../common/ParticipantDetailsScanned";
import ModifyForm from "../../../common/ModifyForm";
import { countryList } from "../../../../constants/CountryList";
import EventItems from "../../../common/EventItems";

import Individual from "./Individual";
import RunnerGroup from "./RunnerGroup";
import ReactPlayer from "react-player";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import ListView from "./viewResults/RouteMap/ListView";
import { getIndividualDetails } from "../../../../apis/mapService";
import { loading } from "../../../common/Spinner";
import RunnerSideBreadcrumbs from "../../../common/RunnerSideBreadcrumbs";
var moment = require("moment");

const styles = (theme) => ({
  actionBtn: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  actionIcon: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  thumbswrapper: {
    height: "100%",
    "& .thumbs-wrapper": {
      margin: 0,
    },
    "& .control-dots": {
      margin: 0,
    },
  },
  arrowStyles: {
    position: "absolute",
    zIndex: 2,
    top: "calc(45% - 15px)",
    backgroundColor: "white",
    width: 50,
    height: 50,
    fontSize: 40,
    cursor: "pointer",
    borderRadius: "50%",
    border: "2px solid grey",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
    [theme.breakpoints.down("sm")]: {
      width: 0,
      height: 0,
      display: "none",
      top: "calc(12% - 15px)",
    },
  },
  indi: {
    width: "100%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },

  mainPanel: {
    width: "90%",
    margin: "auto",
    marginTop: "68px",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      overflowX: "hidden",
    },
  },

  actionSection: {
    display: "flex",

    "& > *": {
      marginLeft: "10px",
    },
  },
  downloadRefresh: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& > *": {
      marginLeft: "10px",
    },
  },
  download: {
    display: "flex",
    alignItems: "center",
    color: "#2883B5",
    marginRight: "1.7%",
    cursor: "pointer",
  },
  resultContainer: {
    display: "flex",
    alignItems: "center",
    color: "#2883B5",
    cursor: "pointer",
  },
  textButton: {
    display: "block",
    marginRight: "10px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  IconButton: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      backgroundColor: "#09456C",
      borderRadius: "50%",
    },
  },

  firstBlock: {
    display: "flex",
    justifyContent: "space-between",

    borderBottom: "1px solid rgb(8, 69, 108)",
  },
  itemTitle: {
    color: "#727272",
    fontWeight: "600",
    fontSize: "16px",
  },
  itemValue: {
    color: "#2883B5",
    fontWeight: "500",
    fontSize: "20px",
  },
  secondaryLabel: {
    color: "#727272",
    // fontWeight: "500",
    // fontSize: "20px",
  },
  item: {
    marginBottom: "20px",
  },
  itemsBlock: {
    // width: "80%",
    flex: 0.8,
  },
  mediaItem: {
    margin: "2px",
    // paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
      paddingRight: "0px",
    },
  },
  qrScan: {
    marginTop: "20px",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "0px",
    },
  },
  scanPointsSelect: {
    width: "150px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
  },

  picture: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    "& p": {
      // marginLeft: "10px",
      display: "flex",
      alignItems: "center",

      backgroundColor: "#2883B5",
      padding: "8px",
      color: "#fff",
      cursor: "pointer",
      "& span": {
        display: "block",
        marginLeft: "5px",
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
  },

  profileImgContainer: {
    width: "120px",
    height: "120px",
    // border: "2px solid blue",
    boxShadow: "0px 0px 2px 3px rgba(0,0,0,0.3)",
    display: "flex",
    justifyContent: "center",
    marginTop: "5px",
    marginRight: "5px",
    marginBottom: "15px",
  },
  profileImg: {
    // maxWidth: "80px",
    // minWidth: "60px",

    width: "100px",
    height: "100px",
    objectFit: "contain",
    margin: "auto",

    marginTop: "auto",
    marginBottom: "auto",
    cursor: "pointer",
  },
  imgTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  root: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#296DFA",
    },
    maxWidth: "300px",
    width: "100%",
    height: "38px",
    borderRadius: "16px",
    backgroundColor: "#09456C",
    color: "#fff",
    fontWeight: "bold",
  },
  expand: {
    cursor: "pointer",
    textAlign: "center",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    // marginTop: "30px",totalDetails
    marginRight: "50px",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },

  goodieMenu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& span": {
      flex: 1,
      width: "200px",
      paddingLeft: "5px",
    },
  },
  shareIconContainer: {
    // marginLeft: 'auto',
    // paddingLeft:50,
  },
  shareIcon: {
    width: 22,
    height: 22,
    padding: "5px",
    borderRadius: "50%",
    backgroundColor: "#3a6ea529",
  },

  purchaseLinkContainer: {
    textAlign: "center",
  },
  purchaseLink: {
    fontSize: "20px",
    color: "blue",
    borderBottom: "1px solid blue",
    cursor: "pointer",
    width: "160px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      width: "140px",
    },
  },
  merchandiseContainer: {
    padding: "8px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 5px",
    },
  },

  mediaContainer: {
    flex: 0.2,
    display: "flex",
    alignItems: "flex-start",
    // justifyContent:'space-between',
    // [theme.breakpoints.down("sm")]: {
    //   flexDirection: "column",
    // },
  },
  mediaThumbnail: {
    // width:'150px',
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
});
const initialState = {
  // a
  anchorEl: null,
  activityList: [],
  activityTypeOptions: [],
  allowMultipeActiviy: false,
  allowPhotoDelete: false,
  allowShare: false,
  // allowReferral: false,
  acknowledgementID: null,
  activityType: "",
  // b
  buyMerchandiseDialog: false,
  // c
  categoryType: "",
  customFields: null,
  computeAdvisedPace: false,
  challengeType: "",
  challengeGoal: null,
  completedProgress: null,
  completedProgressActivity: null,
  customFieldsExpanded: false,
  // d
  days: [],
  dateRangeOptions: [],
  dataX: null,
  dataY: null,
  details: [],
  displayColumns: null,
  dateListState: [],
  dateListY2State: null,
  detailsScanned: null,
  displayQRScanner: true,
  displayBibField: false,
  // e
  firstName: "",
  lastName: "",
  email: "",
  eventDetails: {},
  eventDate: "",
  eventEnd: "",
  eventItemsFormData: null,
  endTime: "",
  expanded: false,
  eventState: "",
  eventType: null,
  // enableTraining: false,
  //f
  fields: null,
  // g
  graphData: [],
  graphXAxisIndi: null,
  graphYAxisIndi: null,
  graphXAxis: null,
  graphYAxis: null,
  goodiesList: null,

  // h
  hideFeebackMessages: false,
  hideShareSummary: false,
  // r
  result: null,
  teamDetailsDTO: null,
  // i
  imageId: "",
  individualGraphData: [],
  individualValues: null,
  individualGraphRunnerActivities: [],
  isUploadAndAddPhotoAvaialable: true,
  invitesCount: 0,
  isRegistrationOpen: false,
  isTeamMember: false,
  hideTeamGraph: false,
  hideCustomSectionLabel: false,
  imageList: null,
  isProgressMaxThanTarget: null,
  isGoodiesAvailable: false,
  // u
  optionsList: null,
  userName: null,
  userMessages: null,
  // m

  modifyValues: null,
  modifyCategoryOptions: [],
  // o
  openScannerDialog: false,
  openParticipantDialog: false,
  // p

  participantDetail: false,
  participantName: null,
  participantGender: null,
  participantContact: null,
  participantEmail: null,
  participantProfileImage: null,
  participantActivityType: null,
  participantCategory: null,
  postRunImages: [],
  // q
  qrScanned: false,
  // s
  streakType: false,
  showArray: [],
  showForm: false,
  scanProcessed: false,
  showGraph: false,
  showInviteOption: false,
  sendInvite: false,
  showRunnerGroup: false,
  showRunnerGroupGraph: false,
  showAgeGroup: false,
  showCorporateOnReg: false,
  snapPhoto: false,
  showSecondaryActivity: false,
  shippingAddress: null,
  showDialog: false,
  selfChallenge: false,
  selectedSpec: null,
  showAddButton: false,
  showDeleteButton: false,
  showNextDayActivityPlan: false,
  showVideoDialog: false,
  selectedScanPoint: null,
  // t
  teamName: "",
  totalTeamDistance: null,
  teamRank: null,
  teamValues: null,
  totalTeamSteps: null,
  teamTarget: null,
  totalTeamWellnessPoints: null,
  timeValidation: null,
  type: null,
  totalTarget: null,
  targetedProgress: null,
  TShirtSize: null,
  tooltipDataState: null,
  teamGraphData: [],
  teamGraphRunnerActivities: [],
  //r
  runnerGraphData: [],
  runnerGroupRank: null,
  rewardPoints: null,
  runnerValues: null,
  // u
  uploadedFiles: [],
};

class EventDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidUpdate() {
    window.addEventListener(
      "captureCameraImage",
      this.handleCapturedCameraChanges
    );
  }
  handleCapturedCameraChanges = (e) => {
    e.preventDefault();
    let image = e.detail.imageUri;
    fetch(image)
      .then((res) => res.blob())
      .then((blob) => {
        let files = new File([blob], "snap", {
          type: "image/jpeg",
        });

        this.setState(
          {
            uploadedFiles: Array.from(files).map((file) => {
              let key = "snapId_" + parseInt(Math.random() * 1000000);
              file.key = key;
              file.id = key;
              file.src = URL.createObjectURL(file);
              return file;
            }),
          },
          () => {
            let customParams = {};

            customParams.file = this.state.uploadedFiles;

            if (
              customParams.file?.every(
                (file) =>
                  file?.type === "image/png" || file?.type === "image/jpeg"
              )
            ) {
              uploadPostRunImages(customParams).then((res) => {
                this.setState({ imageId: "" });
                this.pageRefresh();
              });
            } else {
              Snackbar.show({
                variant: "error",
                isAutoHide: true,
                preventDuplicate: true,
                message: "only jpeg or png image type is allowed",
              });
            }
          }
        );
      });
  };
  componentDidMount() {
    this.pageRefresh();
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  multipleUpload = (e) => {
    if (e.target.id === "contained-button-file") {
      let files = e.target.files;
      this.setState(
        {
          uploadedFiles: Array.from(files).map((file) => {
            let key = "uploadedId_" + parseInt(Math.random() * 1000000);
            file.key = key;
            file.id = key;
            file.src = URL.createObjectURL(file);
            return file;
          }),
        },
        () => {
          let customParams = {};

          customParams.file = this.state.uploadedFiles;

          if (
            customParams.file?.every(
              (file) =>
                file?.type === "image/png" || file?.type === "image/jpeg"
            )
          ) {
            uploadPostRunImages(customParams).then((res) => {
              this.setState({ imageId: "" });
              this.pageRefresh();
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: "only jpeg or png image type is allowed",
            });
          }
        }
      );
    }
    e.target.value = null;
  };
  uploadVideo = (event) => {
    let runnerData = ApplicationUtil.getRunnerDetails();
    let eventId = ApplicationUtil.getRunnersEventID();
    let runnerId = runnerData?.runner?.id;
    if (event?.target?.files) {
      let files = event.target.files;
      let videoFiles = Array.from(files).map((file) => {
        let key = "uploadedId_" + parseInt(Math.random() * 1000000);
        file.key = key;
        file.id = key;
        file.src = URL.createObjectURL(file);
        return file;
      });
      uploadPostRunVideo({ file: videoFiles }).then(() => {
        getRegisteredEventDetails(runnerId, eventId).then((res) => {
          this.setState({ videoFiles: res.data?.videoDetailsDTO });
        });
      });
    }
  };
  deletePhoto = () => {
    let currentImage = localStorage.getItem("currentImage");
    let requestParams = [];

    requestParams.fileName = this.state.imageList[currentImage]?.fileName;
    requestParams.fileType = "POST_RUN_IMAGE";
    requestParams.fileId = this.state.imageList[currentImage]?.fileId;
    deletePostRunImage(requestParams).then((res) => {
      this.pageRefresh();
    });
  };

  pageRefresh = () => {
    document.title = Properties.Page_Title_Event_Details;
    let runnerData = ApplicationUtil.getRunnerDetails();
    let eventId = ApplicationUtil.getRunnersEventID();
    let runnerId = runnerData?.runner?.id;
    let token = ApplicationUtil.getRunnerToken();
    const { history, updateUserPreferences } = this.props;
    let catOptionsArr = [];
    let actOptions = [];
    let imageList = [];
    if (!ApplicationUtil.isuserLoggedIn()) {
      history.push("/");
    } else if (!eventId || eventId === null) {
      history.push("/eventlist");
    } else {
      getPostRunImage(runnerId, eventId).then(({ images }) => {
        imageList = images.reverse();
        this.setState({ imageList: imageList });

        if (eventId) {
          getRegisteredEventDetails(runnerId, eventId).then(
            (registeredEventDetailsRes) => {
              let data = registeredEventDetailsRes?.data;
              let event = data?.event;
              let registration = data?.registration;
              let result = data?.result;

              let trainingPlanData = null;
              if (event?.enableTraining) {
                getTrainingPlan(token, eventId, runnerId).then(({ data }) => {
                  trainingPlanData = data;
                });
              }
              updateUserPreferences({
                drawerSidebar: "/eventDetails",
              });
              if (event?.eventRunCategories) {
                catOptionsArr = event?.eventRunCategories.map((r) => ({
                  ...r,
                  label: r.categoryName,
                  value: r.id,
                  category: r.category,
                }));

                let idAct =
                  registration?.eventRunCategories[0]?.activityType?.id;
                let catOptioArr = catOptionsArr.filter(function (freelancer) {
                  return freelancer.activityType.id == idAct;
                });

                if (catOptionsArr?.length > 0) {
                  this.setState({
                    modifyCategoryOptions: catOptioArr,
                    categoryType: catOptionsArr[0],
                  });
                }
                // if (catOptionsArr?.length > 0) {
                //   this.setState((prevState) => ({
                //     modifyValues: {
                //       ...prevState.modifyValues,
                //       modifyCategoryOptions: this.state?.modifyCategoryOptions,
                //       categoryType: this.state?.categoryType,
                //     },
                //   }));
                // }
              }
              if (event?.activities?.length > 0) {
                event.activities.map(
                  ({ displayName, type, id, eventSupportedActivityTypeId }) => {
                    actOptions.push({
                      label: displayName,
                      value: displayName,
                      type,
                      id,
                      eventSupportedActivityTypeId,
                    });
                  }
                );
              }
              let eventRun = event?.eventRunCategories;
              if (!event?.activities && event?.eventRunCategories) {
                eventRun.map(({ eventSupportedActivityType }) => {
                  actOptions.push({
                    label: eventSupportedActivityType.activityType.displayName,
                    value: eventSupportedActivityType.activityType.displayName,
                    type: eventSupportedActivityType.activityType.type,
                    id: eventSupportedActivityType.activityType.id,
                    eventSupportedActivityTypeId: eventSupportedActivityType.id,
                  });
                });
              }
              if (actOptions?.length > 0) {
                const filteredArr = actOptions.reduce((acc, current) => {
                  const x = acc.find(
                    (item) =>
                      item.eventSupportedActivityTypeId ===
                      current.eventSupportedActivityTypeId
                  );
                  if (!x) {
                    return acc.concat([current]);
                  } else {
                    return acc;
                  }
                }, []);
                this.setState({
                  activityTypeOptions: filteredArr,
                  activityType: actOptions[0],
                });
              }

              if (event?.showRunnerGroup) {
                let runnerGroupData = event?.runnerGroupListDto?.data?.map(
                  (x) => {
                    if (x?.city) {
                      x.city = x.city.toLowerCase();
                    }
                    if (x?.groupVisibility) {
                      return x;
                    }
                  }
                );

                // logic for grouping runner groups based on city

                let runnerGroupWithCity = runnerGroupData
                  .filter((x) => x?.city)
                  .map((a) => {
                    a.label = a?.name;
                    a.value = `${a?.name} ${a?.city.toUpperCase()}`;
                    return a;
                  });
                let runnerGroupWithOther = runnerGroupData
                  .filter((x) => !x?.city)
                  .map((a) => {
                    a.label = a?.name;
                    a.value = a?.name;
                    return a;
                  });

                let cityList = ["Custom"];
                let city;
                runnerGroupWithCity.forEach((item) => {
                  if (city !== item.city && !cityList.includes(item.city)) {
                    cityList.push(item.city);
                    city = item.city;
                  }
                });

                if (runnerGroupWithOther?.length > 0) {
                  cityList.push("Others");
                }
                // cityList.push('Custom')

                let list = [];
                cityList.forEach((item, index) => {
                  let obj = {
                    id: index + 1,
                    // label : `${item.charAt(0).toUpperCase()}${item.substring(1)}`,
                    label: item,
                    options:
                      item === "Custom"
                        ? [{ label: "Other" }]
                        : item === "Others"
                        ? runnerGroupWithOther
                        : runnerGroupWithCity.filter((x) => x.city === item),
                  };
                  list.push(obj);
                });
                this.setState((prevState) => ({
                  runnerGroupList: list,
                  modifyValues: {
                    ...prevState.modifyValues,

                    runnerGroupList: list,
                  },
                }));

                if (registration?.runner) {
                  let runner = registration.runner.runnerGroup;
                  let runnerGroupData = event?.runnerGroupListDto.data;
                  let runnerSelectName = runnerGroupData.filter(function (
                    runnerGroup
                  ) {
                    return runnerGroup.name == runner;
                  });
                  let details = { ...runnerSelectName };
                  if (runnerSelectName?.length > 0) {
                    details.label = details[0]?.name;
                    details.value = details[0]?.city
                      ? `${details[0]?.name} ${details[0]?.city.toUpperCase()}`
                      : details[0]?.name;
                  } else if (Object.keys(details)?.length == 0) {
                    details = {
                      label: runner,
                      value: runner,
                      city: registration?.runner?.runnerGroupCity
                        ? `${registration?.runner?.runnerGroupCity.toUpperCase()}`
                        : "",
                    };
                  }
                  this.setState((prevState) => ({
                    selectedRunnerGroup: details,
                    groupDetails: details,
                    modifyValues: {
                      ...prevState.modifyValues,
                      selectedRunnerGroup: details,
                      groupDetails: details,
                    },
                  }));
                } else if (runnerData?.groupDetails) {
                  let details = { ...runnerData?.groupDetails };
                  details.label = details?.name;
                  details.value = details?.city
                    ? `${details?.name} ${details?.city.toUpperCase()}`
                    : details?.name;

                  this.setState((prevState) => ({
                    selectedRunnerGroup: details,
                    groupDetails: details,
                    modifyValues: {
                      ...prevState.modifyValues,
                      selectedRunnerGroup: details,
                      groupDetails: details,
                    },
                  }));
                }
                // this.setState((prevState) => ({
                //   modifyValues: {
                //     ...prevState.modifyValues,
                //     selectedRunnerGroup: this.state?.selectedRunnerGroup,
                //     runnerGroupList: list,
                //     groupDetails: this.state.groupDetails,
                //   },
                // }));
              }

              if (event?.showAgeGroup) {
                let ageGroupData = event?.ageGroupDTOList;
                if (ageGroupData?.length > 0) {
                  ageGroupData.map((x) => {
                    x.label = x?.groupName;
                    x.value = x?.id;
                    return x;
                  });
                  if (registration?.ageGroup) {
                    let age = registration.ageGroup;

                    let ageGroupData = event?.ageGroupDTOList;

                    let ageSelectName = ageGroupData.filter(function (
                      ageGroup
                    ) {
                      return ageGroup.groupName == age;
                    });

                    let details = { ...ageSelectName };
                    details.id = details[0]?.id;
                    details.label = details[0]?.groupName;
                    details.value = details[0]?.groupName;
                    this.setState({
                      selectedAgeGroup: details,
                    });
                  }
                  this.setState({ ageGroupList: ageGroupData });
                  // this.setState((prevState) => ({
                  //   modifyValues: {
                  //     ...prevState.modifyValues,
                  //     selectedAgeGroup: this.state?.selectedAgeGroup,
                  //     ageGroupList: this.state?.ageGroupList,
                  //   },
                  // }));
                }
              }

              if (event?.showCorporateOnReg) {
                let corporateData = event?.corporateListDto?.data;
                if (corporateData?.length > 0) {
                  corporateData.map((x) => {
                    x.label = x?.displayName;
                    x.value = x?.id;
                    return x;
                  });

                  corporateData.unshift({ label: "Other" });
                  if (registration?.corporateGroup) {
                    let corporate = registration?.corporateGroup;
                    let corporateGropData = event?.corporateListDto.data;

                    let corporateSelectName = corporateGropData.filter(
                      function (runnerGroup) {
                        return runnerGroup.displayName == corporate;
                      }
                    );

                    let details = { ...corporateSelectName };
                    if (corporateSelectName?.length > 0) {
                      details.id = details[0]?.id;
                      details.label = details[0]?.displayName;
                      details.value = details[0]?.displayName;
                    } else if (Object.keys(details).length == 0) {
                      details = {
                        name: corporate,
                        label: corporate,
                        value: corporate,
                      };
                    }
                    this.setState((prevState) => ({
                      modifyValues: {
                        ...prevState.modifyValues,
                        selectedCorporate: details,
                      },
                      selectedCorporate: details,
                    }));
                  } else if (runnerData?.corporateListDto) {
                    let details = { ...runnerData?.corporateListDto };
                    details.label = details?.displayName;
                    details.value = details?.id;

                    this.setState((prevState) => ({
                      modifyValues: {
                        ...prevState.modifyValues,
                        selectedCorporate: details,
                      },
                      selectedCorporate: details,
                    }));
                  }
                  this.setState((prevState) => ({
                    modifyValues: {
                      ...prevState.modifyValues,
                      corporateList: corporateData,
                      showCorporateOnReg: event?.showCorporateOnReg,
                    },
                    corporateList: corporateData,
                    showCorporateOnReg: event?.showCorporateOnReg,
                  }));
                  // this.setState((prevState) => ({
                  //   modifyValues: {
                  //     ...prevState.modifyValues,
                  //     selectedCorporate: this.state?.selectedCorporate,
                  //     corporateList: this.state?.corporateList,
                  //   },
                  // }));
                }
              }

              if (registration?.fields?.customFields?.length > 0) {
                let d = registration?.fields?.customFields;
                d.sort((a, b) => {
                  return a.displayOrder - b.displayOrder;
                });

                d = d.filter((ele) => ele.formName == "REGISTER_EVENT");

                d.forEach((row) => {
                  if (row?.fieldType?.name == "SINGLE_SELECT") {
                    row.fieldOptions.map(
                      (r) => ((r.label = r.optionValue), (r.value = r.id))
                    );
                  }
                  if (row?.fieldType?.name == "MULTI_SELECT") {
                    row.fieldOptions.map(
                      (r) => ((r.label = r.optionValue), (r.value = r.id))
                    );
                    if (row?.fieldValue) {
                      row.fieldValue = row.fieldValue.split(",").map((x) => ({
                        label: x,
                        optionValue: x,
                        value: row.fieldOptions?.find(
                          (y) => y.optionValue === x
                        )?.id,
                        id: row.fieldOptions?.find((y) => y.optionValue === x)
                          ?.id,
                      }));
                    }
                  }
                });

                if (registration?.fields?.fieldGroups) {
                  let groupFieldList = registration.fields.fieldGroups;
                  if (groupFieldList?.length > 0) {
                    let List = [];

                    groupFieldList.forEach((element) => {
                      let list = [];
                      if (element.fieldId && element.fieldId?.length > 0) {
                        for (let i = 0; i < element.fieldId?.length; i++) {
                          // d.forEach((el) => {
                          for (let j = 0; j < d?.length; j++) {
                            if (element.fieldId[i] == d[j].customFieldId) {
                              list.push(d[j]);
                              d.splice(j, 1);
                            }
                          }
                        }

                        d.push({
                          field: list,
                          displayOrder: element.displayOrder,
                          groupName: element.fieldGroup.groupName,
                        });
                      }
                    });
                  }
                }
                // d = { ...d, List };

                this.setState({
                  additionalDetails: d.sort((a, b) =>
                    a.displayOrder > b.displayOrder ? 1 : -1
                  ),
                });
                if (registration.fields?.customSectionLabel) {
                  this.setState({
                    customSectionLabel: registration.fields?.customSectionLabel,
                  });
                }
                this.setState((prevState) => ({
                  modifyValues: {
                    ...prevState.modifyValues,
                    additionalDetails: d.sort((a, b) =>
                      a.displayOrder > b.displayOrder ? 1 : -1
                    ),
                    customSectionLabel:
                      registration?.fields?.customSectionLabel,
                    hideCustomSectionLabel: this.state.hideCustomSectionLabel,
                  },
                }));
              }

              if (event?.showQRScanner) {
                loading(true);
                getScanPointsList(
                  registeredEventDetailsRes?.data?.event?.id
                ).then((listData) => {
                  let list = listData?.data.map((x) => {
                    x.label = x.timingMatIdentifier;
                    x.value = x.id;
                    return x;
                  });
                  if (list && list.length === 1) {
                    {
                      this.handleScanPointChange(list[0]);
                    }
                    this.setState({ scanPointList: list });
                  } else {
                    this.setState({ scanPointList: list });
                  }

                  loading(false);
                });
              }
              //}
              let dailyMin = "",
                totalTarget = "";

              //open Challenge
              if (
                registeredEventDetailsRes?.data?.event?.openSubscriptionEvents
              ) {
                dailyMin =
                  registeredEventDetailsRes?.data?.event?.userMinChallengeValue;
                totalTarget =
                  registeredEventDetailsRes?.data?.event?.totalTarget;
                this.setState({
                  dailyMin: dailyMin,
                  totalTarget: totalTarget,
                  selfChallenge:
                    registeredEventDetailsRes?.data?.event
                      ?.openSubscriptionEvents?.activityType,
                });
              }

              //showSecondaryActivity
              let showSecondaryActivity = false;
              if (
                registeredEventDetailsRes?.data?.event
                  ?.showSecondaryActivityType == "SHOW_ON_REG" ||
                registeredEventDetailsRes?.data?.event
                  ?.showSecondaryActivityType == "SHOW_ON_REG_INVITEE" ||
                registeredEventDetailsRes?.data.showSecondaryActivityType ==
                  "SHOW_ON_INVITEE"
              ) {
                showSecondaryActivity = true;
                let list = "";
                let activityList =
                  registeredEventDetailsRes?.data?.registration
                    ?.participantSecondaryActivities;

                if (activityList) {
                  activityList.forEach((element) => {
                    list = list.concat(element.displayName, ",");
                  });
                  list = list.replace(/,\s*$/, "");
                  this.setState({
                    showSecondaryActivity: showSecondaryActivity,
                    secondaryActivityList: list,
                  });
                }
              }

              this.setState({
                type: registeredEventDetailsRes?.data?.event?.type,
                displayColumns:
                  registeredEventDetailsRes?.data?.event?.displayColumns,
                showRunnerGroup:
                  registeredEventDetailsRes?.data?.event?.showRunnerGroup,
                showRunnerGroupGraph:
                  registeredEventDetailsRes?.data?.event?.showRunnerGroupGraph,
                showAgeGroup:
                  registeredEventDetailsRes?.data?.event?.showAgeGroup,
                showCorporateOnReg:
                  registeredEventDetailsRes?.data?.event?.showCorporateOnReg,
                // enableTraining: registeredEventDetailsRes?.data?.event?.enableTraining,
                runnerRegistered:
                  registeredEventDetailsRes?.data?.registration?.runner
                    ?.runnerGroup,
                videoFiles: registeredEventDetailsRes?.data?.videoDetailsDTO,
              });

              this.setState((prevState) => ({
                modifyValues: {
                  ...prevState.modifyValues,
                  showRunnerGroup: this.state?.showRunnerGroup,
                  showAgeGroup: this.state?.showAgeGroup,
                  showCorporateOnReg: this.state?.showCorporateOnReg,
                },
              }));
              if (registeredEventDetailsRes.data?.event?.eventEndDate) {
                this.setState({
                  isUploadAndAddPhotoAvaialable:
                    registeredEventDetailsRes.data.event.isUploadActivityOpen,
                  eventState:
                    registeredEventDetailsRes?.data?.event?.eventState,
                  eventEndDate:
                    registeredEventDetailsRes?.data?.event?.eventEndDate,
                });
              }

              if (
                registeredEventDetailsRes.data?.registration === undefined ||
                registeredEventDetailsRes.data?.registration === null
              ) {
                localStorage.setItem(
                  "eventItems",
                  JSON.stringify(registeredEventDetailsRes.data)
                );
                this.props.history.push("/eventRegistration");
              } else {
                let convertedDate =
                  registeredEventDetailsRes?.data?.event?.eventRunCategories
                    ?.length > 0
                    ? ApplicationUtil.changeDateFormatDate(
                        registeredEventDetailsRes?.data?.event
                          ?.eventRunCategories[
                          registeredEventDetailsRes?.data?.event
                            ?.eventRunCategories?.length - 1
                        ].endTime
                      )
                    : ApplicationUtil.changeDateFormatDate(
                        registeredEventDetailsRes?.data?.event?.eventEndDate
                      );
                let eventEndTimeForRedirection =
                  ApplicationUtil.FormatDateToUTC(convertedDate);
                let isLogin = localStorage.getItem("isFromLogin");
                if (
                  moment().format("DD-MM-YYYY") > eventEndTimeForRedirection &&
                  isLogin !== "true" &&
                  isLogin !== "" &&
                  isLogin !== null
                ) {
                  this.props.history.push("/eventlist");
                } else {
                  let exactDate = ApplicationUtil.FormatDateToUTC(
                    registeredEventDetailsRes.data.event.eventDate,
                    registeredEventDetailsRes.data.event.eventTime
                  );

                  let startEvent =
                    registeredEventDetailsRes?.data?.event?.localStartDate;

                  let addButton_startDate = moment(
                    moment().format("YYYY-MM-DD")
                  ).isSameOrAfter(
                    moment(
                      registeredEventDetailsRes?.data?.event?.localStartDate
                    ).format("YYYY-MM-DD")
                  );

                  let addButton_endDate = moment(
                    moment().format("YYYY-MM-DD")
                  ).isSameOrBefore(
                    moment(
                      ApplicationUtil.changeDateFormatDate(
                        registeredEventDetailsRes?.data?.event?.eventEndDate
                      )
                      // "2021-07-23"
                    ).format("YYYY-MM-DD")
                  );

                  let isGoodiesAvailable =
                    registeredEventDetailsRes?.data.registration?.bibStatus ===
                      "AVAILABLE" ||
                    registeredEventDetailsRes?.data?.registration
                      ?.runnerCollageStatus === "AVAILABLE" ||
                    registeredEventDetailsRes?.data?.registration
                      ?.certificateStatus === "AVAILABLE" ||
                    registeredEventDetailsRes?.data?.registration
                      ?.fitnessReportStatus === "AVAILABLE" ||
                    registeredEventDetailsRes?.data?.registration
                      ?.videoStatus === "AVAILABLE" ||
                    (!registeredEventDetailsRes?.data?.event
                      ?.hideShareSummary &&
                      registeredEventDetailsRes?.data?.event?.allowShare);
                  let goodies = [];
                  if (isGoodiesAvailable) {
                    if (
                      registeredEventDetailsRes?.data.registration
                        ?.bibStatus === "AVAILABLE"
                    ) {
                      goodies.push({
                        id: 1,
                        label: Properties.BIB_label,
                      });
                    }
                    if (
                      registeredEventDetailsRes?.data?.registration
                        ?.runnerCollageStatus === "AVAILABLE"
                    ) {
                      goodies.push({
                        id: 1,
                        label: Properties.RunnerCollage_label,
                      });
                    }
                    if (
                      registeredEventDetailsRes?.data?.registration
                        ?.certificateStatus === "AVAILABLE" ||
                      registeredEventDetailsRes?.data?.registration
                        ?.fitnessReportStatus === "AVAILABLE"
                    ) {
                      goodies.push({
                        id: 3,
                        label: Properties.Certificate_label.toUpperCase(),
                      });
                    }
                    if (
                      registeredEventDetailsRes?.data?.registration
                        ?.videoStatus === "AVAILABLE"
                    ) {
                      goodies.push({
                        id: 3,
                        label: Properties.Video_label,
                      });
                    }
                    if (
                      !registeredEventDetailsRes?.data?.event
                        ?.hideShareSummary &&
                      registeredEventDetailsRes?.data?.event?.allowShare
                    ) {
                      goodies.push({
                        id: 4,
                        label: "SHARE",
                      });
                    }
                  }

                  this.setState({
                    eventId: eventId,
                    runnerId: runnerId,
                    invitesCount: registeredEventDetailsRes?.data?.noOfInvites
                      ? registeredEventDetailsRes?.data?.noOfInvites
                      : 0,
                    showInviteOption:
                      registeredEventDetailsRes.data.event.sendInvitation &&
                      registeredEventDetailsRes.data.event.isRegistrationOpen,
                    sendInvite:
                      registeredEventDetailsRes.data.event.sendInvitation &&
                      registeredEventDetailsRes.data.event.inviteByInvitee,
                    isRegistrationOpen:
                      registeredEventDetailsRes.data.event.isRegistrationOpen,
                    eventDate: ApplicationUtil.changeDateFormat(
                      registeredEventDetailsRes?.data?.event?.localStartDate
                    ),
                    eventEnd: ApplicationUtil.changeDateFormat(
                      registeredEventDetailsRes?.data?.event?.localEndDate
                    ),
                    allowMultipeActiviy:
                      registeredEventDetailsRes.data.event
                        .allowMultipleActivityUpload,
                    allowPhotoDelete:
                      registeredEventDetailsRes.data.event.allowPhotoDelete,
                    computeAdvisedPace:
                      registeredEventDetailsRes.data.event.computeAdvisedPace,
                    startEvent: startEvent,
                    eventDetails: registeredEventDetailsRes.data,
                    userName: registeredEventDetailsRes.data.registration
                      ? registeredEventDetailsRes.data.registration.runner.name
                      : `${runnerData?.firstName} ${runnerData?.lastName}`,
                    firstName: registeredEventDetailsRes.data?.registration
                      ?.runner?.firstName
                      ? registeredEventDetailsRes.data?.registration?.runner
                          ?.firstName
                      : runnerData?.firstName
                      ? runnerData?.firstName
                      : "",
                    lastName: registeredEventDetailsRes.data?.registration
                      ?.runner?.lastName
                      ? registeredEventDetailsRes.data?.registration?.runner
                          ?.lastName
                      : runnerData?.lastName
                      ? runnerData?.lastName
                      : "",
                    email: registeredEventDetailsRes.data?.registration?.runner
                      ?.email
                      ? registeredEventDetailsRes.data?.registration?.runner
                          ?.email
                      : runnerData?.email,
                    result: registeredEventDetailsRes.data?.result,
                    allowEditRegOnEveDetails:
                      registeredEventDetailsRes.data?.event
                        ?.allowEditRegOnEveDetails,
                    allowEditProfOnEveDetails:
                      registeredEventDetailsRes.data?.event
                        ?.allowEditProfOnEveDetails,
                    showCategoryOnRegistration:
                      registeredEventDetailsRes.data?.event
                        ?.showCategoryOnRegistration,
                    teamDetailsDTO:
                      registeredEventDetailsRes?.data?.registration
                        ?.teamDetailsDTO,
                    timeValidation:
                      registeredEventDetailsRes.data.event.timeValidation,
                    teamTarget:
                      registeredEventDetailsRes?.data?.registration
                        ?.teamDetailsDTO?.teamTarget,
                    totalTarget:
                      registeredEventDetailsRes?.data?.registration
                        ?.totalTarget,
                    allowShare: registeredEventDetailsRes.data.event.allowShare,
                    // allowReferral: registeredEventDetailsRes.data.event.allowReferral,
                    challengeType:
                      registeredEventDetailsRes.data.event.challengeType,
                    isTeamMember:
                      registeredEventDetailsRes.data?.registration.runner
                        .isTeamMember,
                    hideTeamGraph:
                      registeredEventDetailsRes.data?.eventSettings
                        ?.hideTeamGraph,
                    showAddButton: addButton_startDate && addButton_endDate,
                    showDeleteButton: addButton_endDate,

                    hideFeebackMessages:
                      !registeredEventDetailsRes.data?.event
                        ?.hideFeebackMessages &&
                      registeredEventDetailsRes.data?.event?.userMessages &&
                      registeredEventDetailsRes.data?.event?.userMessages
                        ?.length > 0
                        ? true
                        : false,
                    hideShareSummary:
                      registeredEventDetailsRes.data?.event?.hideShareSummary,
                    showNextDayActivityPlan:
                      registeredEventDetailsRes.data.event
                        ?.showNextDayActivityPlan,
                    challengeGoal:
                      registeredEventDetailsRes.data?.registration
                        ?.eventRunCategories[0]?.challengeGoal,
                    isGoodiesAvailable,
                    goodiesList: isGoodiesAvailable ? goodies : null,
                    //  modifyCategoryOptions:registeredEventDetailsRes.data?.event?.eventRunCategories
                  });
                  this.setState((prevState) => ({
                    modifyValues: {
                      ...prevState.modifyValues,
                      allowEditRegOnEveDetails:
                        this.state?.allowEditRegOnEveDetails,
                      allowEditProfOnEveDetails:
                        this.state?.allowEditProfOnEveDetails,
                      showCategoryOnRegistration:
                        this.state?.showCategoryOnRegistration,
                      firstName: this.state?.firstName,
                      lastName: this.state?.lastName,
                      email: this.state?.email,
                    },
                  }));
                  if (
                    registeredEventDetailsRes.data?.registration?.paymentDetails
                  ) {
                    if (
                      registeredEventDetailsRes.data.registration.paymentDetails
                        ?.orderDetails?.paymentId
                    ) {
                      this.setState({
                        acknowledgementID:
                          registeredEventDetailsRes.data.registration
                            .paymentDetails.orderDetails.paymentId,
                      });
                    }

                    if (registeredEventDetailsRes.data.registration?.items) {
                      let items = [];
                      let boughtItems =
                        registeredEventDetailsRes.data.registration.items;
                      boughtItems.forEach((item) => {
                        if (item?.size) {
                          let obj = {
                            label: item.itemName,
                            value: item.size,
                          };
                          items.push(obj);
                        }
                      });
                      this.setState({ TShirtSize: items });

                      if (
                        registeredEventDetailsRes.data.registration
                          .shippingAddress
                      ) {
                        this.setState({
                          shippingAddress:
                            registeredEventDetailsRes.data.registration
                              .shippingAddress,
                        });
                      }
                    }
                  }

                  let selectedCat =
                    this.state.eventDetails?.registration
                      ?.eventRunCategories[0];

                  this.setState({
                    eventType:
                      this.state.eventDetails?.registration
                        ?.eventRunCategories[0].activityType.type,
                  });
                  if (registeredEventDetailsRes?.data.result != undefined) {
                    this.setState({
                      activityList:
                        registeredEventDetailsRes?.data?.result?.activityList,
                      userMessages:
                        registeredEventDetailsRes?.data?.event?.userMessages,
                    });
                  }
                  // if (res.data.event.showActivityDateOnForm)

                  if (
                    event.type === "STREAK" ||
                    event.type === "CHALLENGE" ||
                    event.type === "TRAINING"
                  ) {
                    let startD = event.localStartDate.split("-");
                    let endD = event.localEndDate.split("-");
                    let diff =
                      moment(endD, "YYYY-MM-DD").diff(
                        moment(startD, "YYYY-MM-DD"),
                        "day"
                      ) + 1;
                    this.setState(
                      {
                        isAnyActivity:
                          registration?.eventRunCategories[0]?.activityType
                            ?.type === "ANY"
                            ? true
                            : false,
                        streakType: true,
                        days: diff,
                      },
                      () => {
                        if (event?.graphToShow === "MULTIPLE") {
                          getMultipleGraphDetails(runnerId, eventId).then(
                            (multipleGraphDetailsRes) => {
                              let datesArray = [];
                              let teamGraphArray = [];
                              let graphArr =
                                multipleGraphDetailsRes.data
                                  .runnerActivityDetails; //graph API data
                              for (
                                let index = 0;
                                index < this.state.days;
                                index++
                              ) {
                                let date = moment(startEvent)
                                  .add(index, "day")
                                  .format("DD-MM-YYYY");
                                datesArray.push({
                                  // startDateLocal: moment(this.state.startEvent)p
                                  //   .add(index, "day")
                                  //   .format("DD-MM-YYYY"),
                                  startDateLocal: date,
                                  averagePace: 0,
                                  averageSpeed: 0,
                                  completionTime: result?.totalActivityDistance,
                                });
                              }
                              let axis = multipleGraphDetailsRes?.data?.xAxis;
                              let totalTeamProgressTarget =
                                axis === "distance" &&
                                this.state?.teamTarget % 1000 === 0
                                  ? parseFloat(
                                      this.state?.teamTarget / 1000
                                    ).toFixed(0)
                                  : axis === "distance" &&
                                    this.state?.totalTarget % 1000 !== 0
                                  ? parseFloat(
                                      this.state?.teamTarget / 1000
                                    ).toFixed(2)
                                  : this.state?.teamTarget;
                              let selectedCategoryTeamValue =
                                axis === "steps"
                                  ? this.state?.teamDetailsDTO?.teamTotalSteps
                                  : axis === "calories"
                                  ? this.state?.teamDetailsDTO
                                      ?.totalTeamCalories
                                  : axis === "elevation"
                                  ? this.state?.teamDetailsDTO
                                      ?.totalTeamElevationGain
                                  : axis === "wellnessPoint"
                                  ? this.state?.teamDetailsDTO
                                      ?.teamTotalWellnessPoint
                                  : this.state?.teamDetailsDTO?.teamDistance /
                                      1000 ===
                                    0
                                  ? parseFloat(
                                      this.state?.teamDetailsDTO?.teamDistance /
                                        1000
                                    ).toFixed(0)
                                  : parseFloat(
                                      this.state?.teamDetailsDTO?.teamDistance /
                                        1000
                                    ).toFixed(2);
                              let calculateTeamCompletedTarget = (
                                (Number(selectedCategoryTeamValue) /
                                  Number(totalTeamProgressTarget)) *
                                100
                              ).toFixed(2);
                              this.setState({
                                showGraph: true,
                                graphXAxis:
                                  multipleGraphDetailsRes?.data?.xAxis,
                                completedProgress:
                                  event?.graphToShow === "MULTIPLE" &&
                                  calculateTeamCompletedTarget,
                                targetedProgress:
                                  event?.graphToShow === "MULTIPLE" &&
                                  totalTeamProgressTarget,
                                isProgressMaxThanTarget:
                                  event?.graphToShow === "MULTIPLE" &&
                                  calculateTeamCompletedTarget > 100
                                    ? true
                                    : false,
                                completedProgressActivity:
                                  event?.graphToShow === "MULTIPLE" &&
                                  selectedCategoryTeamValue,
                              });
                              if (
                                multipleGraphDetailsRes.data
                                  .totalTeamDistance !== null
                              ) {
                                this.setState({
                                  totalTeamDistance:
                                    multipleGraphDetailsRes.data
                                      .totalTeamDistance,
                                  teamName:
                                    multipleGraphDetailsRes.data.teamName,
                                  teamRank:
                                    multipleGraphDetailsRes.data.teamRank,
                                });
                              }
                              if (
                                multipleGraphDetailsRes.data.totalTeamSteps !==
                                null
                              ) {
                                this.setState({
                                  totalTeamSteps:
                                    multipleGraphDetailsRes.data.totalTeamSteps,
                                  teamName:
                                    multipleGraphDetailsRes.data.teamName,
                                  teamRank:
                                    multipleGraphDetailsRes.data.teamRank,
                                });
                              }
                              if (
                                multipleGraphDetailsRes.data
                                  .totalTeamWellnessPoints !== null
                              ) {
                                this.setState({
                                  totalTeamWellnessPoints:
                                    multipleGraphDetailsRes.data
                                      .totalTeamWellnessPoints,
                                  teamName:
                                    multipleGraphDetailsRes.data.teamName,
                                  teamRank:
                                    multipleGraphDetailsRes.data.teamRank,
                                });
                              }
                              if (graphArr != undefined) {
                                for (let j = 0; j < datesArray?.length; j++) {
                                  for (let i = 0; i < graphArr?.length; i++) {
                                    if (graphArr[i].startDateLocal) {
                                      let d =
                                        graphArr[i].startDateLocal.split(" ");
                                      if (
                                        datesArray[j].startDateLocal === d[0]
                                      ) {
                                        datesArray[j] = graphArr[i];
                                        datesArray[j].averagePace =
                                          graphArr[i].averagePace;
                                        datesArray[j].averageSpeed =
                                          graphArr[i].avgSpeed;
                                        datesArray[j].distance =
                                          graphArr[i].distance;
                                        datesArray[j].wellnessPoints =
                                          graphArr[i].wellnessPoints;
                                        datesArray[j].averagePaceInHHMMSS =
                                          graphArr[i].averagePaceInHHMMSS;
                                        datesArray[j].completionTime =
                                          graphArr[i].completionTime; //.split(":");
                                      }
                                    }
                                  }
                                }
                                graphArr.map((data) => {
                                  teamGraphArray.push(data);
                                });
                              }
                              if (
                                teamGraphArray &&
                                teamGraphArray?.length > 0
                              ) {
                                this.setState({
                                  teamGraphData: teamGraphArray,
                                  teamGraphRunnerActivities: graphArr,
                                });
                              }
                              this.setState((prevState) => ({
                                teamValues: {
                                  ...prevState.teamValues,
                                  showGraph: true,
                                  completedProgress:
                                    this.state?.completedProgress,
                                  targetedProgress:
                                    this.state?.targetedProgress,
                                  isProgressMaxThanTarget:
                                    this.state?.isProgressMaxThanTarget,
                                  completedProgressActivity:
                                    this.state?.completedProgressActivity,
                                  registration:
                                    this.state?.eventDetails?.registration,
                                  challengeGoal: this.state?.challengeGoal,
                                  teamTarget: this.state?.teamTarget,
                                  showTable: moment(
                                    moment().format("YYYY-MM-DD")
                                  ).isSameOrAfter(
                                    ApplicationUtil.changeDateFormatDate(
                                      this.state.eventDate
                                    )
                                  ),
                                  graphXAxis:
                                    multipleGraphDetailsRes.data?.xAxis,
                                  teamGraphData: teamGraphArray,
                                  teamGraphRunnerActivities: graphArr,
                                  teamName:
                                    multipleGraphDetailsRes.data?.teamName,
                                  teamRank:
                                    multipleGraphDetailsRes.data?.teamRank,
                                  totalTeamSteps:
                                    multipleGraphDetailsRes.data
                                      ?.totalTeamSteps,
                                  totalTeamDistance:
                                    multipleGraphDetailsRes.data
                                      ?.totalTeamDistance,
                                  isTeamMember:
                                    registration.runner.isTeamMember,
                                  totalTeamWellnessPoints:
                                    multipleGraphDetailsRes.data
                                      ?.totalTeamWellnessPoints,
                                  challengeType: event?.challengeType,
                                  graphToShow: event?.graphToShow,
                                },
                              }));
                            }
                          );
                        }
                        if (
                          event?.graphToShow === "INDIVIDUAL" ||
                          event?.graphToShow === "MULTIPLE"
                        ) {
                          getIndividualGraphDetails(runnerId, eventId).then(
                            (individualGraphDetailsRes) => {
                              let axis = individualGraphDetailsRes?.data?.yAxis;
                              let individualGraphArray = [];
                              let datesArray = [];
                              let graphArr =
                                individualGraphDetailsRes?.data
                                  ?.runnerActivityDetails; //graph API data

                              for (let index = 0; index < diff; index++) {
                                let date = moment(startEvent)
                                  .add(index, "day")
                                  .format("DD-MM-YYYY");
                                datesArray.push({
                                  startDateLocal: date,
                                  averagePace: 0,
                                  averageSpeed: 0,
                                  completionTime: result?.totalActivityDistance,
                                });
                              }

                              let totalProgressTarget =
                                axis === "distance" &&
                                registration?.totalTarget % 1000 === 0
                                  ? parseFloat(
                                      registration?.totalTarget / 1000
                                    ).toFixed(0)
                                  : axis === "distance" &&
                                    registration?.totalTarget % 1000 !== 0
                                  ? parseFloat(
                                      registration?.totalTarget / 1000
                                    ).toFixed(2)
                                  : registration?.totalTarget;
                              let selectedCategoryValue =
                                axis === "steps"
                                  ? result?.totalSteps
                                  : axis === "calories"
                                  ? result?.totalCalories
                                  : axis === "elevation"
                                  ? result?.totalElevationGain
                                  : axis === "wellnessPoint"
                                  ? result?.wellnessDetails?.totalWellnessPoint
                                  : result?.totalActivityDistance % 1000 === 0
                                  ? parseFloat(
                                      result?.totalActivityDistance / 1000
                                    ).toFixed(0)
                                  : parseFloat(
                                      result?.totalActivityDistance / 1000
                                    ).toFixed(2);
                              let calculateCompletedTarget = (
                                (Number(selectedCategoryValue) /
                                  Number(totalProgressTarget)) *
                                100
                              ).toFixed(2);

                              this.setState({
                                showGraph: true,
                                graphYAxisIndi:
                                  individualGraphDetailsRes.data.yAxis,
                                graphXAxisIndi:
                                  individualGraphDetailsRes.data.xAxis,
                                completedProgressIndividual:
                                  calculateCompletedTarget,
                                targetedProgressIndividual: totalProgressTarget,
                                isProgressMaxThanTargetIndividual:
                                  calculateCompletedTarget > 100 ? true : false,
                                completedProgressActivityIndividual:
                                  selectedCategoryValue,
                              });

                              if (graphArr != undefined) {
                                for (let j = 0; j < datesArray?.length; j++) {
                                  for (let i = 0; i < graphArr?.length; i++) {
                                    if (graphArr[i].startDateLocal) {
                                      let d =
                                        graphArr[i].startDateLocal.split(" ");
                                      if (
                                        datesArray[j].startDateLocal === d[0]
                                      ) {
                                        datesArray[j] = graphArr[i];
                                        datesArray[j].averagePace =
                                          graphArr[i].averagePace;
                                        datesArray[j].averageSpeed =
                                          graphArr[i].avgSpeed;
                                        datesArray[j].distance =
                                          graphArr[i].distance;
                                        datesArray[j].wellnessPoints =
                                          graphArr[i].wellnessPoints;
                                        datesArray[j].averagePaceInHHMMSS =
                                          graphArr[i].averagePaceInHHMMSS;
                                        datesArray[j].completionTime =
                                          graphArr[i].completionTime; //.split(":");
                                      }
                                    }
                                  }
                                }
                              }

                              this.setState({
                                individualGraphData: datesArray,
                                individualGraphRunnerActivities: graphArr,
                              });
                              let labelName = "";
                              let dateList = [],
                                dataY2 = [],
                                dateList1 = [],
                                average = [],
                                tooltipData = [],
                                completionTime = [],
                                legendLabel = "",
                                totalDistance = [],
                                totalWellnessPoint = [];

                              if (individualGraphDetailsRes.data.xAxis) {
                                if (
                                  individualGraphDetailsRes.data.xAxis ==
                                  "startDateLocal"
                                ) {
                                  labelName = "date";
                                  dateList = datesArray.map(function (e) {
                                    let d = e.startDateLocal.split("-");
                                    d = d[0] + "-" + d[1];
                                    return d; //e.startDateLocal;
                                  });
                                } else if (
                                  individualGraphDetailsRes.data.xAxis ===
                                  "wellnessPoint"
                                ) {
                                  labelName = "WellnessPoint";
                                  dateList = datesArray.map(function (e) {
                                    return e.wellnessPoints;
                                  });
                                } else {
                                  labelName =
                                    individualGraphDetailsRes.data.xAxis;
                                  dateList = datesArray.map(function (e) {
                                    let d =
                                      e[individualGraphDetailsRes.data.xAxis];
                                    return d;
                                  });
                                }
                              } else {
                                labelName = "date";
                                dateList = datesArray.map(function (e) {
                                  let d = e.startDateLocal.split("-");
                                  d = d[1] + "-" + d[0];
                                  return d; //e.startDateLocal;
                                });
                              }
                              if (individualGraphDetailsRes?.data.xAxis) {
                                if (
                                  individualGraphDetailsRes?.data.xAxis ==
                                  "startDateLocal"
                                ) {
                                  labelName = "date";
                                  dateList1 = datesArray.map(function (e) {
                                    let d = e.startDateLocal.split("-");
                                    d = d[1] + "-" + d[0];
                                    return d; //e.startDateLocal;
                                  });
                                } else if (
                                  individualGraphDetailsRes?.data.xAxis ===
                                  "wellnessPoint"
                                ) {
                                  labelName = "WellnessPoint";
                                  dateList1 = datesArray.map(function (e) {
                                    return e.wellnessPoints;
                                  });
                                } else {
                                  labelName =
                                    individualGraphDetailsRes?.data.xAxis;
                                  dateList1 = datesArray.map(function (e) {
                                    let d =
                                      e[individualGraphDetailsRes?.data.xAxis];
                                    return d;
                                  });
                                }
                              } else {
                                labelName = "date";
                                dateList1 = datesArray.map(function (e) {
                                  let d = e.startDateLocal.split("-");
                                  d = d[1] + "-" + d[0];
                                  return d; //e.startDateLocal;
                                });
                              }

                              if (individualGraphDetailsRes.data.yAxis) {
                                if (
                                  individualGraphDetailsRes.data.yAxis ==
                                  "distance"
                                ) {
                                  dataY2 = datesArray.map(function (e) {
                                    let dis = e.distance;
                                    if (dis % 1000 === 0) {
                                      return parseFloat(dis / 1000).toFixed(0);
                                    } else {
                                      return parseFloat(dis / 1000).toFixed(2);
                                    }
                                  });
                                  legendLabel =
                                    Properties.Distance_label + " (Km)";
                                } else if (
                                  individualGraphDetailsRes.data.yAxis ===
                                  "wellnessPoint"
                                ) {
                                  dataY2 = datesArray.map(function (e) {
                                    return e.wellnessPoints;
                                  });
                                  legendLabel = Properties.Label_WellnessPoints;
                                } else if (
                                  individualGraphDetailsRes.data.yAxis ===
                                  "steps"
                                ) {
                                  dataY2 = datesArray.map(function (e) {
                                    return e.steps;
                                  });
                                  legendLabel = Properties.Label_Steps;
                                } else {
                                  dataY2 = datesArray.map(function (e) {
                                    return e[
                                      individualGraphDetailsRes.data.yAxis
                                    ];
                                  });

                                  legendLabel =
                                    individualGraphDetailsRes.data.yAxis
                                      ?.charAt(0)
                                      .toUpperCase() +
                                    individualGraphDetailsRes.data.yAxis.slice(
                                      1
                                    );
                                }
                              } else {
                                dataY2 = datesArray.map(function (e) {
                                  return parseFloat(e.distance / 1000).toFixed(
                                    2
                                  );
                                });
                                legendLabel =
                                  Properties.Distance_label + " (Km)";
                              }
                              tooltipData = datesArray.map(function (e) {
                                return e[individualGraphDetailsRes.data.yAxis];
                              });
                              this.setState({
                                dateListState: dateList,
                                dateListY2State: dataY2,
                              });
                              let list = [];

                              if (
                                dateList?.length >= 10 &&
                                graphArr?.length > 0
                              ) {
                                for (let i = 0; i < dateList.length; i += 6) {
                                  if (
                                    dateList?.length > 6 &&
                                    dateList?.length >= i + 6 &&
                                    dateList?.length - i >= 11
                                  ) {
                                    list.push({
                                      displayDate:
                                        moment(dateList1[i], "MM-DD").format(
                                          "Do MMM"
                                        ) +
                                        "-" +
                                        moment(
                                          dateList1[i + 6],
                                          "MM-DD"
                                        ).format("Do MMM"),
                                      from: dateList1[i],
                                      to: dateList1[i + 6],
                                      value: {
                                        // trackX:
                                        //   moment(
                                        //     dateList[i]
                                        //   ).format("MMM DD") +
                                        //   "," +
                                        //   moment(
                                        //     dateList[i + 6]
                                        //   ).format("MMM DD"),
                                        trackX:
                                          moment(dateList1[i], "MM-DD").format(
                                            "Do MMM"
                                          ) +
                                          "-" +
                                          moment(
                                            dateList1[i + 6],
                                            "MM-DD"
                                          ).format("Do MMM"),
                                        trackingEstimationByDate:
                                          this.dateHandleChange(dateList),
                                        selectedTrackingDates:
                                          this.dateHandleChange(dataY2),
                                        selectedTooltip:
                                          this.dateHandleChange(tooltipData),
                                      },
                                    });
                                    i++;
                                  } else {
                                    list.push({
                                      displayDate:
                                        moment(dateList1[i], "MM-DD").format(
                                          "Do MMM"
                                        ) +
                                        "-" +
                                        moment(
                                          dateList1[dateList1?.length - 1],
                                          "MM-DD"
                                        ).format("Do MMM"),
                                      from: dateList1[i],
                                      to: dateList1[dateList1?.length - 1],
                                      value: {
                                        trackX:
                                          moment(dateList1[i], "MM-DD").format(
                                            "Do MMM"
                                          ) +
                                          "-" +
                                          moment(
                                            dateList1[dateList1?.length - 1],
                                            "MM-DD"
                                          ).format("Do MMM"),
                                        trackingEstimationByDate:
                                          this.dateHandleChange(dateList),
                                        selectedTrackingDates:
                                          this.dateHandleChange(dataY2),
                                        selectedTooltip:
                                          this.dateHandleChange(tooltipData),
                                      },
                                    });
                                    i = dateList?.length;
                                  }
                                }
                              } else {
                                list.push({
                                  value: {
                                    trackingEstimationByDate:
                                      this.dateHandleChange(dateList),
                                    selectedTrackingDates:
                                      this.dateHandleChange(dataY2),
                                    selectedTooltip:
                                      this.dateHandleChange(tooltipData),
                                  },
                                });
                              }
                              // this.setState({
                              //   dateRangeOptions: list,
                              //   dateListState: dateList,
                              // });
                              this.setState((prevState) => ({
                                dateRangeOptions: list,
                                dateListState: dateList,
                                individualValues: {
                                  ...prevState.individualValues,
                                  individualGraphRunnerActivities: graphArr,
                                  graphYAxisIndi:
                                    individualGraphDetailsRes.data?.yAxis,
                                  result: this.state?.result,
                                  totalTarget: this.state?.totalTarget,
                                  registration:
                                    this.state?.eventDetails?.registration,
                                  challengeGoal: this.state?.challengeGoal,
                                  completedProgressIndividual:
                                    calculateCompletedTarget,
                                  targetedProgressIndividual:
                                    totalProgressTarget,
                                  isTeamMember: this.state?.isTeamMember,
                                  isProgressMaxThanTargetIndividual:
                                    calculateCompletedTarget > 100
                                      ? true
                                      : false,
                                  completedProgressActivityIndividual:
                                    selectedCategoryValue,
                                  dateListState: dateList,
                                  dateRangeOptions: list,
                                  showGraph: true,
                                  showTable: moment(
                                    moment().format("YYYY-MM-DD")
                                  ).isSameOrAfter(
                                    ApplicationUtil.changeDateFormatDate(
                                      this.state.eventDate
                                    )
                                  ),
                                  eventType: this.state?.eventType,
                                  individualGraphData: datesArray,
                                  graphXAxisIndi: this.state?.graphXAxisIndi,
                                  dataX: this.state?.dataX,
                                  dataY: this.state?.dataY,
                                  tooltipDataState:
                                    this.state?.tooltipDataState,
                                  challengeType: this.state?.challengeType,
                                  activityList:
                                    registeredEventDetailsRes?.data.result
                                      ?.activityList,
                                  graphToShow:
                                    this.state?.eventDetails?.event
                                      ?.graphToShow,
                                },
                              }));
                            }
                          );
                        }
                        if (
                          this.state?.showRunnerGroupGraph &&
                          this.state?.runnerRegistered
                        ) {
                          getRunnerGraphDetails(runnerId, eventId).then(
                            (runnerGraphDetailsRes) => {
                              let datesArray = [];
                              let runnerGraphArray = [];
                              let runnerGraphArr =
                                runnerGraphDetailsRes?.data
                                  .runnerActivityDetails; //graph API data
                              for (
                                let index = 0;
                                index < this.state.days;
                                index++
                              ) {
                                let date = moment(startEvent)
                                  .add(index, "day")
                                  .format("DD-MM-YYYY");
                                datesArray.push({
                                  startDateLocal: date,
                                  averagePace: 0,
                                  averageSpeed: 0,
                                  completionTime:
                                    this.state?.result?.totalActivityDistance,
                                });
                              }

                              this.setState({
                                showGraph: true,
                                graphXAxis: runnerGraphDetailsRes?.data?.xAxis,
                              });
                              if (
                                runnerGraphDetailsRes.data.totalTeamDistance !==
                                null
                              ) {
                                this.setState({
                                  totalRunnerDistance:
                                    runnerGraphDetailsRes.data
                                      .totalTeamDistance,
                                  runnerGroupName:
                                    runnerGraphDetailsRes.data.runnerGroup,
                                  runnerGroupRank:
                                    runnerGraphDetailsRes.data.runnerGroupRank,
                                });
                              }
                              if (
                                runnerGraphDetailsRes.data.totalTeamSteps !==
                                null
                              ) {
                                this.setState({
                                  totalRunnerSteps:
                                    runnerGraphDetailsRes.data.totalTeamSteps,
                                  runnerGroupName:
                                    runnerGraphDetailsRes.data.runnerGroup,
                                  runnerGroupRank:
                                    runnerGraphDetailsRes.data.runnerGroupRank,
                                });
                              }
                              if (
                                runnerGraphDetailsRes.data
                                  .totalTeamWellnessPoints !== null
                              ) {
                                this.setState({
                                  totalRunnerWellnessPoints:
                                    runnerGraphDetailsRes.data
                                      .totalTeamWellnessPoints,
                                  runnerGroupName:
                                    runnerGraphDetailsRes.data.runnerGroup,
                                  runnerGroupRank:
                                    runnerGraphDetailsRes.data.runnerGroupRank,
                                });
                              }
                              if (runnerGraphArr != undefined) {
                                for (let j = 0; j < datesArray.length; j++) {
                                  for (
                                    let i = 0;
                                    i < runnerGraphArr.length;
                                    i++
                                  ) {
                                    if (runnerGraphArr[i].startDateLocal) {
                                      let d =
                                        runnerGraphArr[i].startDateLocal.split(
                                          " "
                                        );
                                      if (
                                        datesArray[j].startDateLocal === d[0]
                                      ) {
                                        datesArray[j] = runnerGraphArr[i];
                                        datesArray[j].averagePace =
                                          runnerGraphArr[i].averagePace;
                                        datesArray[j].averageSpeed =
                                          runnerGraphArr[i].avgSpeed;
                                        datesArray[j].distance =
                                          runnerGraphArr[i].distance;
                                        datesArray[j].wellnessPoints =
                                          runnerGraphArr[i].wellnessPoints;
                                        datesArray[j].averagePaceInHHMMSS =
                                          runnerGraphArr[i].averagePaceInHHMMSS;
                                        datesArray[j].completionTime =
                                          runnerGraphArr[i].completionTime; //.split(":");
                                      }
                                    }
                                  }
                                }
                                runnerGraphArr.map((data) => {
                                  runnerGraphArray.push(data);
                                });
                              }
                              if (
                                runnerGraphArray &&
                                runnerGraphArray.length > 0
                              ) {
                                this.setState({
                                  runnerGraphData: runnerGraphArray,
                                  runnerGraphRunnerActivities: runnerGraphArr,
                                });
                              }

                              this.setState((prevState) => ({
                                runnerValues: {
                                  ...prevState.runnerValues,
                                  showRunnerGroupGraph:
                                    this.state?.showRunnerGroupGraph,
                                  totalRunnerDistance:
                                    runnerGraphDetailsRes?.data
                                      ?.totalTeamDistance,
                                  // this.state?.totalRunnerDistance,
                                  totalRunnerSteps:
                                    runnerGraphDetailsRes?.data?.totalTeamSteps,
                                  // this.state?.totalRunnerSteps,
                                  totalRunnerWellnessPoints:
                                    runnerGraphDetailsRes?.data
                                      ?.totalTeamWellnessPoints,
                                  showGraph: true,
                                  showTable: moment(
                                    moment().format("YYYY-MM-DD")
                                  ).isSameOrAfter(
                                    ApplicationUtil.changeDateFormatDate(
                                      this.state.eventDate
                                    )
                                  ),
                                  runnerGroupRank:
                                    runnerGraphDetailsRes?.data
                                      ?.runnerGroupRank,
                                  // this.state?.runnerGroupRank

                                  registration:
                                    this.state?.eventDetails?.registration,
                                  graphXAxis:
                                    runnerGraphDetailsRes?.data?.xAxis,
                                  // this.state?.graphXAxis,
                                  challengeType: this.state?.challengeType,
                                  runnerGroupName:
                                    runnerGraphDetailsRes?.data?.runnerGroup,
                                  //  this.state?.runnerGroupName,
                                  runnerRegistered:
                                    this.state?.runnerRegistered,
                                  runnerGraphRunnerActivities: runnerGraphArr,
                                  // this.state?.runnerGraphRunnerActivities,
                                  graphToShow: event?.graphToShow,
                                },
                              }));
                            }
                          );
                        }
                      }
                    );
                    localStorage.setItem("days", this.state.days);

                    let arr = [];
                    for (let index = 0; index < diff; index++) {
                      let date = moment(startEvent)
                        .add(index, "day")
                        .format("DD-MM-YYYY");
                      // let trainingIndex = trainingPlanData.findIndex(
                      //   (e) => e.challengeDate === ApplicationUtil.changeDateFormatDate(date)
                      // );

                      let currentTrainingData = trainingPlanData?.filter(
                        (e) =>
                          e.challengeDate.indexOf(
                            ApplicationUtil.changeDateFormatDate(date)
                          ) !== -1
                      );

                      arr.push({
                        trainingDetails: currentTrainingData, //trainingPlanData[trainingIndex],
                        startDateLocal: currentTrainingData
                          ? moment(
                              ApplicationUtil.changeDateFormatDate(date),
                              "YYYY-MM-DD"
                            ).isSameOrBefore(
                              moment(moment(), "DD-MM-YYYY")
                                .add(1, "days")
                                .format("YYYY-MM-DD")
                            ) && date
                          : moment(
                              ApplicationUtil.changeDateFormatDate(date),
                              "YYYY-MM-DD"
                            ).isSameOrBefore(moment(moment(), "DD-MM-YYYY")) &&
                            date,
                        suggestedPace: registration.nextSuggestedPace,
                        showButton:
                          moment(
                            ApplicationUtil.changeDateFormatDate(date),
                            "YYYY-MM-DD"
                          ).isSameOrBefore(moment().format("YYYY-MM-DD")) &&
                          event.isUploadActivityOpen,

                        list: [],
                      });
                    }

                    if (arr && arr.length > 0) {
                      localStorage.setItem("start", arr[0].startDateLocal);
                      localStorage.setItem(
                        "end",
                        arr[arr.length - 1].startDateLocal
                      );
                    }

                    this.setState({ details: arr });
                    let newArray = arr;
                    // if (this.state.allowMultipeActiviy) {
                    for (let j = 0; j < arr.length; j++) {
                      for (let i = 0; i < result?.activityList?.length; i++) {
                        let d =
                          result?.activityList[i].startDateLocal.split(" ");

                        if (arr[j].startDateLocal === d[0]) {
                          newArray[j].startDateLocal = d[0];
                          newArray[j].list = [
                            ...newArray[j].list,
                            result?.activityList[i],
                          ];
                        }
                      }
                    }

                    let userMessages = this.state.userMessages;

                    // if (this.state.allowMultipeActiviy) {
                    newArray.forEach((element) => {
                      for (let i = 0; i < element.list.length; i++) {
                        if (
                          element.list[i].postRunImageStatus === "AVAILABLE"
                        ) {
                          imageList.forEach((element1) => {
                            if (element1.objectId === element.list[i].id) {
                              element.list[i].imageURL = element1.imageData;
                              element.list[i].file = element1.fileName;
                              if (element1.fileId)
                                element.list[i].fileId = element1.fileId;
                            }
                          });
                        }

                        // for comments
                        let comments = [];
                        if (userMessages) {
                          userMessages.forEach((element1) => {
                            if (
                              element1?.activityId &&
                              element.list[i].id === element1.activityId
                            ) {
                              comments.push(element1);
                            }
                          });
                        }
                        element.list[i].comments = comments;
                      }
                    });
                    if (
                      event?.isUploadActivityOpen === false &&
                      newArray.every((x) => x?.list?.length === 0)
                    ) {
                      this.setState({ details: [] });
                    } else {
                      let sorted = newArray.sort((a, b) => {
                        let first = moment(
                          ApplicationUtil.getDateParts(a.startDateLocal)
                        );
                        let second = moment(
                          ApplicationUtil.getDateParts(b.startDateLocal)
                        );
                        return second.diff(first, "days");
                      });

                      if (
                        moment(
                          ApplicationUtil.changeDateFormatDate(
                            event?.eventEndDate
                          ),
                          "YYYY-MM-DD"
                        ).isBefore(moment().format("YYYY-MM-DD"))
                      ) {
                        sorted = sorted.filter((x) => x?.list?.length > 0);
                      }
                      this.setState({
                        details: sorted,
                      });
                    }
                  } else {
                    this.setState({ streakType: false });
                    localStorage.removeItem("days");
                    localStorage.removeItem("start");
                    localStorage.removeItem("end");

                    let exactDate = ApplicationUtil.FormatDateToUTC(
                      registeredEventDetailsRes.data.event.eventDate,
                      registeredEventDetailsRes.data.event.eventTime
                    );
                    // let startEvent = moment(
                    //   moment(exactDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                    // );
                    this.setState({ startDate: exactDate });
                    if (registeredEventDetailsRes.data.event.eventEndDate) {
                      this.setState({
                        isUploadAndAddPhotoAvaialable:
                          registeredEventDetailsRes.data.event
                            .isUploadActivityOpen,
                      });
                    }
                    let startD =
                      registeredEventDetailsRes.data.event.localStartDate.split(
                        "-"
                      );
                    let endD =
                      registeredEventDetailsRes.data.event.localEndDate.split(
                        "-"
                      );

                    let diff =
                      moment(endD, "YYYY-MM-DD").diff(
                        moment(startD, "YYYY-MM-DD"),
                        "day"
                      ) + 1;
                    let arr = [];
                    for (let index = 0; index < diff; index++) {
                      let date = moment(startEvent)
                        .add(index, "day")
                        .format("DD-MM-YYYY");
                      let currentTrainingData = trainingPlanData?.filter(
                        (e) =>
                          e.challengeDate.indexOf(
                            ApplicationUtil.changeDateFormatDate(date)
                          ) !== -1
                      );

                      arr.push({
                        trainingDetails: currentTrainingData,
                        startDateLocal:
                          moment(
                            ApplicationUtil.changeDateFormatDate(date),
                            "YYYY-MM-DD"
                          ).isSameOrBefore(moment().format("YYYY-MM-DD")) &&
                          date,
                        // .isSameOrBefore(moment(), "DD-MM-YYYY")
                        // .add(1, "days")
                        // .format("YYYY-MM-DD") && date,
                        // moment().format("YYYY-MM-DD")
                        showButton:
                          moment(
                            ApplicationUtil.changeDateFormatDate(date),
                            "YYYY-MM-DD"
                          ).isSameOrBefore(moment().format("YYYY-MM-DD")) &&
                          registeredEventDetailsRes.data.event
                            .isUploadActivityOpen,
                      });
                    }

                    if (arr && arr.length > 0) {
                      localStorage.setItem("start", arr[0].startDateLocal);
                      localStorage.setItem(
                        "end",
                        arr[arr.length - 1].startDateLocal
                      );
                    }
                    let sorted = arr.sort((a, b) => {
                      let first = moment(
                        ApplicationUtil.getDateParts(a.startDateLocal)
                      );
                      let second = moment(
                        ApplicationUtil.getDateParts(b.startDateLocal)
                      );
                      return second.diff(first, "days");
                    });

                    if (
                      registeredEventDetailsRes?.data?.event
                        ?.isUploadActivityOpen === false
                      // endDatePast
                    ) {
                      if (
                        registeredEventDetailsRes?.data?.event
                          ?.eventActivityType !== "SINGLE"
                      ) {
                        this.setState({ details: [] });
                      }
                    } else {
                      this.setState({ details: sorted });
                    }

                    if (
                      registeredEventDetailsRes?.data?.result?.activityList
                        ?.length > 0
                    ) {
                      let newArray =
                        registeredEventDetailsRes?.data?.result?.activityList;
                      newArray.forEach((element) => {
                        element.showButton =
                          registeredEventDetailsRes?.data?.event?.isUploadActivityOpen;
                        element.showResult = true;
                        if (element.postRunImageStatus === "AVAILABLE") {
                          imageList.forEach((element1) => {
                            if (element1.objectId === element.id) {
                              element.imageURL = element1.imageData;
                              element.file = element1.fileName;
                              if (element1.fileId)
                                element.fileId = element1.fileId;
                            }
                          });
                        }

                        // for comments
                        let userMessages = this.state.userMessages;
                        let comments = [];
                        if (userMessages) {
                          userMessages.forEach((element1) => {
                            if (
                              element1?.activityId &&
                              element.id === element1.activityId
                            ) {
                              comments.push(element1);
                            }
                          });
                        }
                        element.comments = comments;
                      });
                      this.setState({
                        details: newArray,
                      });
                    }
                  }
                }
              }

              if (registeredEventDetailsRes?.data?.event?.eventItems) {
                let eventItems =
                  registeredEventDetailsRes.data?.event?.eventItems;
                let eventItemsArr = [];
                let singleEventItem = [];
                let item = eventItems.find((x) =>
                  x?.itemName?.toLowerCase().includes("t-shirt")
                );
                for (let i = 0; i < eventItems.length; i++) {
                  let obj = {
                    ...eventItems[i],
                    itemQuantity: 0,
                    totalRowPrice: 0,
                    hideItem:
                      (eventId == 1879 ||
                        eventId == 508 ||
                        eventId == 507 ||
                        eventId === 513) &&
                      item &&
                      eventItems[i]?.itemName?.toLowerCase().includes("medal")
                        ? true
                        : false,
                  };
                  if (eventItems[i]?.sizeMale || eventItems[i]?.sizeFemale) {
                    obj.sizeArray =
                      runnerData?.gender === "FEMALE"
                        ? eventItems[i]?.sizeFemale
                            .split(",")
                            .map((x) => ({ label: x, value: x }))
                        : eventItems[i]?.sizeMale
                            .split(",")
                            .map((x) => ({ label: x, value: x }));
                    obj.selectedSizeError = false;
                  }
                  eventItemsArr.push(obj);
                }

                let eventItemsData = {
                  eventItems: eventItemsArr,
                  showShippingDetails: false,
                  countryCode: runnerData?.countryCode
                    ? {
                        label: runnerData?.country,
                        value: ` ${runnerData?.countryCode}`,
                      }
                    : countryList.find((x) => x.value === " 91"),
                  countryCodeList: countryList,
                  contactNumber: runnerData?.contactNumber,
                  contactNumberError: false,
                  address: runnerData?.address,
                  addressError: false,
                  city: runnerData?.city,
                  cityError: false,
                  pincode: runnerData?.pincode,
                  pincodeError: false,
                  state: runnerData?.state,
                  stateError: false,
                  country: runnerData?.country
                    ? {
                        label: runnerData?.country,
                        value: countryList?.find((x) =>
                          x.label.includes(runnerData?.country)
                        )?.value,
                      }
                    : countryList.find((x) => x.value === " 91"),
                  countryError: false,
                };
                if (item) {
                  if (item?.eventItemSizeChartImage?.imageData) {
                    eventItemsData.tShirtSizeChartFile =
                      "data:image/png;base64," +
                      item?.eventItemSizeChartImage?.imageData;
                  }
                }

                this.setState({
                  eventItemsFormData: eventItemsData,
                });
              }
            }
          );
        } else {
          history.push("/eventlist");
        }
      });
    }
  };
  getIndex = (val) => {
    const { dateRangeOptions } = this.state;

    let idx = dateRangeOptions.findIndex(
      (x) => x.displayDate == val.target.value
    );
    const X = dateRangeOptions[idx]?.value?.trackingEstimationByDate[idx];
    const Y = dateRangeOptions[idx]?.value?.selectedTrackingDates[idx];
    const Z = dateRangeOptions[idx]?.value?.selectedTooltip[idx];
    this.setState({ dataX: X, dataY: Y, tooltipDataState: Z });
  };
  dateHandleChange(data) {
    if (!data || data?.length === 0) return;
    let subDates = [];
    for (let i = 0; i < data.length; i += 7) {
      let sub = data.slice(i, i + 7);
      if (
        subDates.length > 0 &&
        subDates[subDates.length - 1].length + sub.length <= 10
      ) {
        subDates[subDates.length - 1] =
          subDates[subDates.length - 1].concat(sub);
      } else {
        subDates.push(sub);
      }
    }

    return subDates;
  }
  handleCategoryChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        eventCategory: selectedValues,
        eventCategoryError: false,
      });
    } else {
      this.setState({
        eventCategory: selectedValues,
        eventCategoryError: true,
      });
    }
  };

  naviagteToInvite = () => {
    localStorage.setItem("allowShare", this.state.allowShare);
    localStorage.setItem("showInviteOption", this.state.showInviteOption);
    if (this.state.showInviteOption) {
      localStorage.setItem("hideInviteOnList", true);
      this.props.history.push("/invitationList");
    } else if (this.state.invitesCount <= 0 && this.state.isRegistrationOpen) {
      this.props.history.push("/invitationForm");
    } else {
      this.props.history.push("/invitationList");
    }
  };

  getDialogComponent = () => {
    const { imageList } = this.state;
    return (
      <div>
        <ImageSlider files={this.state.imageList} />
      </div>
    );
  };
  getVideoDialogComponent = () => {
    const { videoFiles } = this.state;
    return (
      <div>
        <ReactPlayer
          width="100%"
          height={window.innerWidth < 1400 ? "400px" : "600px"}
          style={{ background: "#000" }}
          config={{
            file: {
              attributes: {
                autoPlay: true,
              },
              forceVideo: true,
            },
          }}
          controls
          url={`https://${videoFiles?.videoStreamURL}`}
        />
      </div>
    );
  };
  goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  getUpdateForm = (e) => {
    const { eventId, runnerId, categoryId, modifyValues } = this.state;
    let requestParam = {
      eventId: eventId,
      runnerId: runnerId,
    };
    if (modifyValues?.firstName) {
      requestParam.firstName = modifyValues?.firstName;
    }
    // if (lastName) {
    requestParam.lastName = modifyValues?.lastName
      ? modifyValues?.lastName
      : "";
    // }
    if (modifyValues?.email) {
      requestParam.email = modifyValues?.email;
    }
    if (categoryId) {
      requestParam.categoryId = categoryId;
    }
    if (this.state?.modifyValues?.showRunnerGroup) {
      if (!this.state?.modifyValues?.otherGroup) {
        if (this.state?.modifyValues?.groupDetails) {
          requestParam.groupDetails = {
            name: this.state.modifyValues?.selectedRunnerGroup?.name,
            id: this.state.modifyValues?.selectedRunnerGroup?.id,
            city: this.state.modifyValues?.selectedRunnerGroup?.city,
            groupVisibility:
              this.state.modifyValues?.selectedRunnerGroup?.groupVisibility,
          };
        }
      } else if (
        this.state.modifyValues?.runnerGroup ||
        this.state.modifyValues?.runnerGroupCity
      ) {
        requestParam.groupDetails = {
          name: this.state.modifyValues?.runnerGroup,
          city: this.state.modifyValues?.runnerGroupCity,
          groupVisibility: false,
        };
      }
    }
    if (!this.state?.modifyValues?.otherCorporate) {
      requestParam.corporateDetails = {
        id: this.state.modifyValues?.selectedCorporate?.id,
        name: this.state.modifyValues?.selectedCorporate?.label,
      };
    } else if (this.state.modifyValues?.corporateName) {
      requestParam.corporateDetails = {
        name: this.state.modifyValues?.corporateName,
      };
    }

    if (this.state?.showAgeGroup && this.state?.selectedAgeGroup == null) {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select age group",
      });
      this.goToTop();
      return;
    } else if (
      this.state.modifyValues?.showAgeGroup &&
      this.state.modifyValues?.selectedAgeGroup
    ) {
      requestParam.ageGroupId = this.state.modifyValues?.selectedAgeGroup?.id;
    }
    let submitFlag = true;
    let fields = [];
    if (
      this.state.additionalDetails &&
      this.state.additionalDetails.length > 0
    ) {
      this.state.additionalDetails.forEach((element) => {
        if (submitFlag) {
          if (element.groupName) {
            for (let i = 0; i < element.field.length; i++) {
              if (
                element.field[i].requiredField &&
                !element.field[i].hidden &&
                (element.field[i].fieldValue == "" ||
                  element.field[i].fieldValue == undefined)
              ) {
                element.field[i].isError = true;
                Snackbar.show({
                  variant: "error",
                  isAutoHide: true,
                  preventDuplicate: true,
                  message: element.field[i].displayName + " is Required",
                });
                submitFlag = false;

                let newData = [...this.state.additionalDetails];
                let index = newData.findIndex(function (obj) {
                  if (obj.field && obj.field.length > 0)
                    return (
                      obj.field[i].customFieldId ==
                      element.field[i].customFieldId
                    );
                });
                newData[index].isError = element.field[i].isError
                  ? true
                  : false;
                this.setState({ additionalDetails: newData });
                // this.setState({ data: [...this.state.data, element] });
                this.goToTop();
                submitFlag = false;
                return;
              } else {
                let obj = {};
                element.field[i].isError = false;

                if (
                  element.field[i].fieldType.name == "SINGLE_SELECT" &&
                  element.field[i].fieldValue
                ) {
                  obj.fieldId = element.field[i].customFieldId;
                  obj.fieldOptionId = element?.field[i]?.fieldOptionId;
                  obj.fieldValue = element.field[i]?.fieldValue || null;

                  fields.push(obj);
                } else if (element.field[i].fieldType.name == "MULTI_SELECT") {
                  if (
                    element.field[i]?.fieldValue &&
                    element.field[i]?.fieldValue.length > 0
                  ) {
                    element.field[i].fieldValue.forEach((e) => {
                      let newObj = {};
                      newObj.fieldId = element.field[i].customFieldId;
                      newObj.fieldOptionId = e?.id;
                      newObj.fieldValue = e?.optionValue || null;

                      fields.push(newObj);
                    });
                  }
                } else if (element.field[i].fieldType.name == "DATE") {
                  obj.fieldId = element.customFieldId;
                  element.fieldValue
                    ? (obj.dateValue = moment(element?.fieldValue).format(
                        "DD-MM-YYYY"
                      ))
                    : (obj.dateValue = null);
                  fields.push(obj);
                } else if (element.field[i]?.fieldValue) {
                  obj.fieldId = element.field[i].customFieldId;
                  obj.fieldOptionId = element?.field[i]?.fieldOptionId;
                  obj.fieldValue = element.field[i]?.fieldValue || "";

                  fields.push(obj);
                }

                if (element.field.length - 1 > i) {
                  submitFlag = false;
                } else {
                  submitFlag = true;
                  return;
                }
              }
            }
          } else {
            if (
              element.requiredField &&
              !element.hidden &&
              (element.fieldValue == "" || element.fieldValue == undefined)
            ) {
              element.isError = true;
              Snackbar.show({
                variant: "error",
                isAutoHide: true,
                preventDuplicate: true,
                message: element.displayName + " is Required",
              });
              submitFlag = false;
              this.setState({ showForm: true });
              let newData = [...this.state.additionalDetails];
              let index = newData.findIndex(
                (obj) => obj.customFieldId == element.customFieldId
              );
              newData[index].isError = element.isError ? true : false;
              this.setState({ additionalDetails: newData });
              // this.setState({ data: [...this.state.data, element] });
              this.goToTop();
              return;
            } else {
              let obj = {};
              element.isError = false;
              if (
                element.fieldType.name == "SINGLE_SELECT" &&
                element.fieldValue
              ) {
                obj.fieldId = element.customFieldId;
                obj.fieldOptionId = element?.fieldOptionId;

                element.fieldValue
                  ? (obj.fieldValue = element?.fieldValue)
                  : (obj.fieldValue = null);
                fields.push(obj);
              } else if (element.fieldType.name == "MULTI_SELECT") {
                if (element.fieldValue && element.fieldValue.length > 0) {
                  for (let i = 0; i < element?.fieldValue?.length; i++) {
                    let newobj = {};
                    newobj.fieldId = element.customFieldId;
                    newobj.fieldOptionId = element?.fieldValue[i]?.id;
                    element?.fieldValue[i] && element?.fieldValue[i].optionValue
                      ? (newobj.fieldValue =
                          element?.fieldValue[i]?.optionValue)
                      : (newobj.fieldValue = null);
                    fields.push(newobj);
                  }
                }
              } else if (element.fieldType.name == "DATE") {
                obj.fieldId = element.customFieldId;
                element.fieldValue
                  ? (obj.dateValue = moment(element?.fieldValue).format(
                      "DD-MM-YYYY"
                    ))
                  : (obj.dateValue = null);
                fields.push(obj);
              } else {
                obj.fieldId = element.customFieldId;

                element.fieldValue
                  ? (obj.fieldValue = element?.fieldValue)
                  : (obj.fieldValue = "");
                fields.push(obj);
              }
              submitFlag = true;
              this.setState({ showForm: false });
              this.closeWindow();
              return;
            }
          }
        }
      });
      if (submitFlag) {
        let fieldValues = {
          runnerId: this.state.runnerId,
          eventId: this.state.eventId,
        };
        fieldValues.fields = fields;
        requestParam.fieldValues = fieldValues;
      }
    }

    if (submitFlag) {
      OrganizerUpdateForm(requestParam).then((res) => {
        if (res.data.success.code === "200") {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: res.data.success.verbose,
          });
          // this.closeWindow()
          this.setState({ showForm: false });
          this.closeWindow();
          this.pageRefresh();
        }
        if (res.data.success.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res.data.success.verbose,
          });
        }
        if (res.data.success.code === "404") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res.data.success.verbose,
          });
        }
      });
    }
  };
  showDetails = (data) => {
    let requestParams = {
      eventId: data.eventId,
      runnerId: data.runnerId,
    };
    getDetailsScanned(data.eventId, data.runnerId)
      .then((res) => {
        if (res.data) {
          this.setState({
            detailsScanned: res.data,
            participantName: res.data.participantName,
            participantGender: res.data.gender,
            participantContact: res.data.contactNo,
            participantEmail: res.data.email,
            participantProfileImage: res.data.profileImage,
            participantActivityType: res.data.activityType,
            participantCategory: res.data.category,
            fields: res.data?.fields?.customSectionLabel,
            customFieldsValues: res.data?.fields,
          });
        } else {
          this.setState({
            detailsScanned: "",
            participantName: "",
            participantGender: "",
            participantContact: "",
            participantEmail: "",
            participantActivityType: "",
            participantCategory: "",
            fields: "",
            customFieldsValues: "",
          });
        }
      })
      .catch((error) => {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: JSON.stringify(error), //Capture API error
        });
      });
  };
  handleScan = (data) => {
    const { selectedScanPoint } = this.state;
    let runnerData = ApplicationUtil.getRunnerDetails();

    let eventId = ApplicationUtil.getRunnersEventID();
    let runnerId = runnerData?.runner?.id;

    navigator.geolocation.getCurrentPosition((position) => {
      let formatDate = moment(position.timestamp).format("YYYY-MM-DD HH:mm:ss");

      let requestParams = {
        eventId,
        runnerId: data.runnerId,
        timestamp: formatDate,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        name: data?.name,
        scanPointIdentifier: selectedScanPoint?.timingMatIdentifier,
        // bibNumber:data?.bibNumber
      };

      postBarScannerData(runnerData?.token, requestParams)
        .then((response) => {
          if (response?.data === "SUCCESS") {
            this.setState({
              qrScanned: true,
              bibNumber: "",
              displayBibField: false,
              displayQRScanner: true,
            });
            // navigation.goBack()
            getIndividualDetails(eventId, runnerId, selectedScanPoint?.id).then(
              (listData) => {
                this.setState({
                  scanDetailsList: listData?.data?.locationTrackRecords,
                });
              }
            );
          }
        })
        .catch((error) => {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response?.data, //Capture API error
          });
        });
    });
  };

  onNewScanResult = (decodedText, decodedResult) => {
    const { qrScanned, scanCount } = this.state;
    const { eventDetails } = this.state;
    const { event, registration } = eventDetails;
    const isDetailsQr = event?.showDetailsOnQRScan;
    let scannedData = JSON.parse(decodedText);
    if (this.scanProcessed) {
      return;
    }
    this.scanProcessed = true;
    if (scannedData?.name) {
      if (isDetailsQr) {
        this.showDetails(scannedData);
        this.handleScan(scannedData);
        this.setState({
          openScannerDialog: false,
          openParticipantDialog: true,
          participantDetail: true,
        });
      } else {
        this.setState({
          openScannerDialog: false,
          openParticipantDialog: false,
          participantDetail: false,
        });
        this.handleScan(scannedData);
      }
    }
    setTimeout(() => {
      this.scanProcessed = false;
    }, 1000);
  };

  getScannerDialog = () => {
    const { qrScanned, displayQRScanner, displayBibField, bibNumber } =
      this.state;

    return (
      <>
        <QrScanner
          onDecode={this.onNewScanResult}
          onError={(error) => console.log(error?.message)}
          constraints={{
            facingMode: "environment",
          }}
        />
      </>
    );
  };

  getParticipantDialog = () => {
    const details = {
      name: this.state.participantName,
      gender: this.state.participantGender,
      contact: this.state.participantContact,
      email: this.state.participantEmail,
      profileImage: this.state.participantProfileImage,
      activity: this.state.participantActivityType,
      category: this.state.participantCategory,
      additionalFields: this.state.fields,
      customFieldsValues: this.state.customFieldsValues,
    };
    return <ParticipantDetailsScanned details={details} />;
  };
  updateAdditionalFields = (selectedValues) => {
    this.setState({ additionalDetails: selectedValues });
  };

  getFormComponent = () => {
    const { classes } = this.props;
    let { modifyValues } = this.state;

    return (
      <div>
        <ModifyForm
          modifyValues={modifyValues}
          isGroup
          isClearable
          updatedDetailsData={this.updateAdditionalFields}
          updateChildStates={(modifyValues) => {
            this.setState({ modifyValues: modifyValues });
          }}
        />
        <div style={{ width: "30%", margin: "auto" }}>
          <SubmitCancelBtn
            label={"Update"}
            type="submit"
            onClick={(e) => this.getUpdateForm(e)}
          />
        </div>
      </div>
    );
  };
  closeWindow = () => {
    this.setState({
      showDialog: false,
      showForm: false,
      openScannerDialog: false,
      openParticipantDialog: false,
      participantDetail: false,
      buyMerchandiseDialog: false,
      showVideoDialog: false,
      displayBibField: false,
      displayQRScanner: true,
    });
  };

  handleExpandClick = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };
  navigateToResults = () => {
    const { eventDetails } = this.state;
    const { event, registration } = eventDetails;
    const { history } = this.props;
    this.props.updateUserSelectedEvent(event);
    localStorage.setItem("navigatedFromEventDetails", true);
    localStorage.setItem(
      "defaultRunnerActivityType",
      JSON.stringify(registration?.eventRunCategories[0]?.activityType)
    );
    localStorage.setItem(
      "defaultRunnerCategoryName",
      JSON.stringify(registration?.eventRunCategories[0])
    );
    history.push("/results");
  };
  navigateToWellnessPointsDetails = () => {
    const { eventDetails } = this.state;
    const { event, registration } = eventDetails;
    const { history } = this.props;
    this.props.updateUserSelectedEvent(event);
    history.push("/wellnessDetails");
  };
  navigateToSummary = (type) => {
    const { history } = this.props;
    const { event, registration } = this.state.eventDetails;
    event.goodiesType = type;
    this.props.updateUserSelectedEvent(event);
    history.push("/summary");
  };
  navigateToReferral = () => {
    const { history } = this.props;
    history.push("/refer");
  };
  navigateToFeedback = () => {
    const { history } = this.props;
    history.push("/feedback");
  };
  navigateToTrainingPlan = () => {
    const { history } = this.props;
    history.push("/training");
  };
  handlePayment = (params) => {
    const { eventDetails, runnerId, buyMerchandiseDialog } = this.state;
    let categoryId = eventDetails?.registration?.eventRunCategories[0]?.id;
    let amount =
      eventDetails?.registration?.eventRunCategories[0]?.registrationFee;
    let contactNumber = eventDetails?.registration?.runner?.contactNumber;
    let runners = [
      {
        runnerId: runnerId,
        regFee: amount,
        otherCharges: 0,
      },
    ];

    let requestParams = {
      categoryId: categoryId,
      amount: amount,
      runners: runners,
    };
    this.closeWindow();
    getorderDetails(params ? params : requestParams).then((res) => {
      const { data } = res;
      this.setState({
        orderId: data.orderId,
        amount: data.amount,
      });
      data.contact =
        contactNumber !== "" ? contactNumber : data.participantPhone;
      //   this.setState({ orderId: data.orderId });
      ApplicationUtil.paymentGatewayHandler(
        data,
        this.captureDetails,
        this.handlePaymentFailure
      );
    });
  };

  captureDetails = (requestCaptureParams) => {
    let runnerData = ApplicationUtil.getRunnerDetails();

    const {
      eventDetails,
      runnerId,
      buyMerchandiseDialog,
      isBuyMerchandise,
      eventId,
      eventItemsFormData: {
        eventItems,
        selectedTshirtSize,
        contactNumber,
        address,
        city,
        pincode,
        state,
        country,
      },
    } = this.state;
    if (buyMerchandiseDialog) {
      requestCaptureParams.runnerId = runnerId;
    } else {
      requestCaptureParams.runnerIds = [runnerId];
    }

    getCaptureDetails(requestCaptureParams)
      .then((res) => {
        Snackbar.show({
          variant: "success",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Payment Successful", //Capture API error
        });
        if (isBuyMerchandise) {
          let categoryId =
            eventDetails?.registration?.eventRunCategories[0]?.id;

          let items = eventItems
            .filter((x) => x.itemQuantity > 0)
            .map((x) => {
              let obj = {
                id: x.id,
                itemName: x.itemName,
                maxQuantity: x.maxQuantity,
                quantity: x.itemQuantity,
                totalItemPrice: x.rowTotal,
                pricePerUnit: x.pricePerUnit,
                readOnly: x.readOnly,
              };

              if (x?.sizeMale || x?.sizeFemale) {
                if (runnerData?.gender === "FEMALE") {
                  obj.sizeFemale = selectedTshirtSize?.value;
                } else {
                  obj.sizeMale = selectedTshirtSize?.value;
                }
              }

              return obj;
            });
          let customeParams = {
            // userId: runnerData?.id,
            eventId: eventId,
            runnerId,
            categoryId: categoryId,
            firstName: runnerData?.firstName,
            email: runnerData?.email,
            items,
            shippingAddress: address,
            city,
            contactNumber,
            state,
            pincode,
            country: country.label,
            orderId: requestCaptureParams?.orderId,
            paymentMode: "ONLINE",
            paymentStatus: "SUCCESS",
            gatewayResponse: requestCaptureParams,

            //fields for address details
          };

          OrganizerUpdateForm(customeParams).then((registerResponse) => {
            this.setState({ isBuyMerchandise: false });
            if (registerResponse.data?.success?.code === "200") {
              Snackbar.show({
                variant: "success",
                isAutoHide: true,
                preventDuplicate: true,
                message: registerResponse.data?.success?.verbose,
              });
              // this.closeWindow()
              this.closeWindow();
              this.pageRefresh();
            }
            if (registerResponse.data?.success?.code === "409") {
              Snackbar.show({
                variant: "error",
                isAutoHide: true,
                preventDuplicate: true,
                message: registerResponse.data?.success?.verbose,
              });
              this.closeWindow();
            }
            if (registerResponse.data?.success?.code === "404") {
              Snackbar.show({
                variant: "error",
                isAutoHide: true,
                preventDuplicate: true,
                message: registerResponse.data?.success?.verbose,
              });
              this.closeWindow();
            }
            if (registerResponse.data?.success?.code === "500") {
              Snackbar.show({
                variant: "error",
                isAutoHide: true,
                preventDuplicate: true,
                message: registerResponse.data?.success?.verbose,
              });
              this.closeWindow();
            }
          });
        } else {
          this.pageRefresh();
        }

        // this.setState({
        //   transactionID: requestCaptureParams.razorpay_payment_id,
        //   transactionStatus: "Success",
        // });
      })
      .catch((error) => {
        this.setState({
          transactionStatus: "Failed",
        });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Failed to process the payment.", //Capture API error
        });
      });
  };
  shareGoodie = (type) => {
    const { eventDetails } = this.state;
    let runnerData = ApplicationUtil.getRunnerDetails();

    let eventId = ApplicationUtil.getRunnersEventID();
    let runnerId = runnerData?.runner?.id;
    getGoodiesImage(runnerId, eventId, type).then((res) => {
      let cardImage = res.data?.imageData
        ? `data:image/png;base64,${res.data?.imageData}`
        : null;
      if (cardImage) {
        const shareData = {
          imageSrc: cardImage,
          title: eventDetails?.event?.name,
        };

        shareData.text = `For more such exciting events and challenges, please visit ${eventDetails?.event?.eventLink} #necessarydevil #${eventDetails?.event?.name}`;
        shareData.url = eventDetails?.event?.eventLink;
        ApplicationUtil.navigatorShare(shareData);
        getSummaryImpressions(eventId, runnerId).then((res) => {});
      } else {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Failed to get goodie",
        });
      }
    });
  };
  handlePaymentFailure = (response) => {
    Snackbar.show({
      variant: "error",
      isAutoHide: true,
      preventDuplicate: true,
      message: `Failed to process the payment please note the transactionID
          ${response.error.metadata.payment_id}.`, //Capture API error
    });
    // this.pageRefresh();
  };
  componentWillUnmount() {
    window.removeEventListener(
      "captureCameraImage",
      this.handleCapturedCameraChanges
    );
    localStorage.removeItem("isFromLogin");
  }
  modifyHandleSelect = (
    runnerId,
    firstName,
    lastName,
    email,
    runCategoryData,
    modifyValues
  ) => {
    const { activityTypeOptions, modifyCategoryOptions } = this.state;
    let rowCat = {
      id: runCategoryData?.eventRunCategories?.id,
      type: runCategoryData?.eventRunCategories?.activityType?.type,
      label: runCategoryData?.eventRunCategories?.categoryName,
      value: runCategoryData?.eventRunCategories?.categoryName,
    };
    let rowAct = {};

    activityTypeOptions.map((e) => {
      if (
        runCategoryData?.eventRunCategories?.eventSupportedActivityType
          ?.activityType?.type === e.type &&
        e.eventSupportedActivityTypeId ==
          runCategoryData?.eventRunCategories?.eventSupportedActivityType?.id
      ) {
        rowAct = e;
      }
    });
    this.setState({
      showForm: true,
      runnerId: runnerId,
      firstName: modifyValues?.firstName,
      lastName: modifyValues?.lastName,
      email: modifyValues?.email,
      // activityType: rowAct,
      // categoryType: rowCat, //categoryTypeOptions[0],
      categoryId: modifyCategoryOptions[0]?.id,
      activityTypeOptions: activityTypeOptions,
      completeEventCategories: modifyCategoryOptions,
    });
  };

  getMerchandiseComponent = () => (
    <div className={this.props.classes.merchandiseContainer}>
      <EventItems
        data={this.state.eventItemsFormData}
        updateData={(data) => this.setState({ eventItemsFormData: data })}
      />
    </div>
  );
  handleOnBuyMerchandiseClick = async () => {
    let runnerData = ApplicationUtil.getRunnerDetails();

    const {
      eventId,
      runnerId,
      eventDetails: { registration },
      eventItemsFormData,
      eventItemsFormData: {
        eventItems,
        selectedTshirtSize,
        selectedTshirtSizeError,
        contactNumber,
        contactNumberError,
        address,
        addressError,
        city,
        cityError,
        pincode,
        pincodeError,
        state,
        stateError,
        country,
        countryError,
      },
    } = this.state;
    let isItemSelected = eventItems?.some((x) => x?.itemQuantity > 0);
    let wearableItem = eventItems.filter(
      (x) => (x?.sizeMale || x?.sizeFemale) && x?.itemQuantity > 0
    );

    let isWearableItemSelected = wearableItem.find(
      (x) => !x.selectedSize?.value || x.selectedSizeError
    );
    if (!isItemSelected) {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: `Please select item`,
      });

      return;
    } else if (isWearableItemSelected) {
      // let updatedData = { ...eventItemsFormData, selectedTshirtSizeError: true }
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: `Please select ${isWearableItemSelected?.itemName} size`,
      });
      let arr = eventItems?.map((x) => {
        if (x.id === isWearableItemSelected?.id) {
          x.selectedSizeError = true;
        }
        return x;
      });
      this.setState({
        eventItemsFormData: { ...eventItemsFormData, eventItems: arr },
      });
      // this.setState({ eventItemsFormData: updatedData })
      // this.closeWindow()

      return;
    } else if (!contactNumber || contactNumberError) {
      let updatedData = { ...eventItemsFormData, contactNumberError: true };
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please provide contact number",
      });
      this.setState({ eventItemsFormData: updatedData });
      // this.closeWindow()

      return;
    } else if (!address || addressError) {
      let updatedData = { ...eventItemsFormData, addressError: true };
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please provide address",
      });
      this.setState({ eventItemsFormData: updatedData });
      // this.closeWindow()

      return;
    } else if (!city || cityError) {
      let updatedData = { ...eventItemsFormData, cityError: true };
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please provide city",
      });
      this.setState({ eventItemsFormData: updatedData });
      // this.closeWindow()

      return;
    } else if (!pincode || pincodeError) {
      let updatedData = { ...eventItemsFormData, pincodeError: true };
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please provide pincode",
      });
      this.setState({ eventItemsFormData: updatedData });
      // this.closeWindow()

      return;
    } else if (!state || stateError) {
      let updatedData = { ...eventItemsFormData, stateError: true };
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please provide state",
      });
      this.setState({ eventItemsFormData: updatedData });
      // this.closeWindow()

      return;
    } else if (!country.label || countryError) {
      let updatedData = { ...eventItemsFormData, countryError: true };
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please provide country",
      });
      this.setState({ eventItemsFormData: updatedData });
      // this.closeWindow()

      return;
    } else {
      let totalAmount = 0;
      let items = eventItems
        .filter((x) => x.itemQuantity > 0)
        .map((x) => {
          let obj = {
            id: x.id,
            itemName: x.itemName,
            maxQuantity: x.maxQuantity,
            quantity: x.itemQuantity,
            totalItemPrice: x.rowTotal,
            pricePerUnit: x.pricePerUnit,
            readOnly: x.readOnly,
          };

          totalAmount += x?.totalRowPrice;
          if (x?.sizeMale || x?.sizeFemale) {
            if (runnerData?.gender === "FEMALE") {
              obj.sizeFemale = x?.selectedSize?.value;
            } else {
              obj.sizeMale = x?.selectedSize?.value;
            }
          }

          return obj;
        });

      if (totalAmount > 0) {
        let requestParams = {
          amount: totalAmount,
          currency: "INR",
          eventId,
          runnerId,
          categoryId: registration?.eventRunCategories[0]?.id,
          items,
        };
        this.handlePayment(requestParams);
      } else {
        let customeParams = {
          // userId: runnerData?.id,
          eventId: eventId,
          runnerId,
          categoryId: registration?.eventRunCategories[0]?.id,
          firstName: runnerData?.firstName,
          email: runnerData?.email,
          items,
          shippingAddress: address,
          city,
          contactNumber,
          state,
          pincode,
          country: country.label,
          // orderId:data?.orderId,
          // paymentMode:'ONLINE',
          // paymentStatus:'SUCCESS',
          // gatewayResponse:response

          //fields for address details
        };

        OrganizerUpdateForm(customeParams).then((registerResponse) => {
          if (registerResponse.data?.success?.code === "200") {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: registerResponse.data?.success?.verbose,
            });
            // this.closeWindow()
            this.closeWindow();
            this.pageRefresh();
          }
          if (registerResponse.data?.success?.code === "409") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: registerResponse.data?.success?.verbose,
            });
          }
          if (registerResponse.data?.success?.code === "404") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: registerResponse.data?.success?.verbose,
            });
          }
        });
      }
    }
  };

  onHandleVideoDelete = () => {
    const { videoFiles } = this.state;

    deletePostRunVideo({
      fileName: videoFiles?.videoFileName,
    }).then(() => {
      this.setState({ videoFiles: null, showVideoDialog: false });
    });
  };

  handleScanPointChange = (value) => {
    let runnerData = ApplicationUtil.getRunnerDetails();
    let eventId = ApplicationUtil.getRunnersEventID();

    postSelectedScanPoint(eventId, runnerData?.runnerId, value?.id)
      .then((res) => {
        return getIndividualDetails(eventId, runnerData?.runnerId, value?.id);
      })
      .then((listData) => {
        this.setState({
          scanDetailsList: listData?.data?.locationTrackRecords,
        });
      });
    this.setState({
      selectedScanPoint: value,
      seletedScanPointError: false,
    });
  };

  getEventAndActivityText = (event) => {
    let localStartDate = event.localStartDate;
    let localEndDate = event.localEndDate;
    var utc = new Date(event?.localStartDate + " " + event?.eventTime);
    var offset = utc.getTimezoneOffset();
    var local = new Date(utc.getTime() + offset * 60000);
    let startTime = moment(local).subtract(1, "hour").format("HH:mm:ss");
    localStartDate = localStartDate + " " + startTime;

    if (localEndDate && localStartDate) {
      if (
        moment().isBetween(
          moment(localStartDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
          moment(localEndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        )
      ) {
        return "No Activity Available";
      }

      if (moment().isBefore(moment(localStartDate))) {
        return "Event Not Started";
      }

      if (
        moment().isAfter(
          moment(localEndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        )
      ) {
        return "Event Ended";
      }
    }
  };
  render() {
    const { classes } = this.props;
    let {
      // a
      dataX,
      dataY,
      anchorEl,
      allowMultipeActiviy,
      allowPhotoDelete,
      // allowReferral,
      acknowledgementID,
      // c

      challengeType,

      challengeGoal,

      customFieldsExpanded,
      // d

      details,
      displayColumns,
      // e
      eventDetails,
      eventDate,
      eventEnd,

      expanded,

      // enableTraining,
      // g
      buyMerchandiseDialog,

      goodiesList,
      // h
      // hideFeebackMessages,

      // r

      // i
      imageId,
      isUploadAndAddPhotoAvaialable,

      imageList,

      isGoodiesAvailable,
      // u
      userName,
      userMessages,
      // m

      modifyValues,
      // o
      openScannerDialog,
      // p
      openParticipantDialog,

      // q

      // s

      sendInvite,
      showRunnerGroup,

      showAgeGroup,
      showCorporateOnReg,

      showSecondaryActivity,
      secondaryActivityList,
      shippingAddress,
      showDialog,
      selfChallenge,
      showAddButton,
      showDeleteButton,
      showNextDayActivityPlan,
      showVideoDialog,
      scanPointList,
      // t
      TShirtSize,

      timeValidation,
      type,
      totalTarget,

      teamValues,
      individualValues,
      runnerValues,
      selectedScanPoint,
    } = this.state;
    const { event, registration } = eventDetails;

    this.state.eventDetailsArr = [
      {
        id: 1,
        content: (
          <Team
            navigateToWellnessPointsDetails={
              this.navigateToWellnessPointsDetails
            }
            teamValues={teamValues}
          />
        ),
      },
      {
        id: 2,
        content: (
          <Individual
            individualValues={individualValues}
            navigateToWellnessPointsDetails={
              this.navigateToWellnessPointsDetails
            }
            dateHandleChange={this.dateHandleChange}
            dataX={dataX}
            dataY={dataY}
            getIndex={this.getIndex}
          />
        ),
      },
      {
        id: 3,
        content: (
          <Individual
            individualValues={individualValues}
            navigateToWellnessPointsDetails={
              this.navigateToWellnessPointsDetails
            }
            dateHandleChange={this.dateHandleChange}
            dataX={dataX}
            dataY={dataY}
            getIndex={this.getIndex}
          />
        ),
      },
      {
        id: 4,
        content: (
          <RunnerGroup
            runnerValues={runnerValues}
            navigateToWellnessPointsDetails={
              this.navigateToWellnessPointsDetails
            }
          />
        ),
      },
    ];

    const visiblesCarouselDetails = this.state.eventDetailsArr?.filter(
      (slide) => {
       
        
        return (
          (slide.id == 1 &&
            (teamValues?.challengeType === "TEAM_RELAY" ||
              teamValues?.challengeType === "BOTH" ||
              teamValues?.challengeType === "TEAM") &&
            teamValues?.teamGraphRunnerActivities?.length > 0 &&
            teamValues?.graphToShow === "MULTIPLE" &&
            teamValues?.isTeamMember &&
            !this.state.hideTeamGraph) ||
          (slide.id == 2 &&
            (individualValues?.challengeType == "BOTH" ||
              individualValues?.challengeType == "TEAM_RELAY") &&
            individualValues?.graphToShow == "MULTIPLE" &&
            individualValues?.individualGraphRunnerActivities?.length > 0 &&
            individualValues?.isTeamMember &&
            !this.state.hideTeamGraph) ||
          (slide.id == 3 &&
            individualValues &&
            individualValues?.graphToShow == "INDIVIDUAL" &&
            individualValues?.individualGraphRunnerActivities?.length > 0 
            //  (individualValues.challengeType=="TEAM" ||   individualValues.challengeType=="INDIVIDUAL" || individualValues.challengeType=="RELAY")  &&
            ) ||
          (slide.id == 4 &&
            runnerValues?.runnerRegistered &&
            runnerValues?.showRunnerGroupGraph &&
            runnerValues?.runnerGraphRunnerActivities?.length > 0 &&
            (runnerValues?.totalRunnerDistance > 0 ||
              runnerValues?.totalRunnerSteps > 0 ||
              runnerValues?.totalRunnerWellnessPoints > 0))
        );
      }
    );


    

    return (
      <React.Fragment>
        <AppToolBar>
          <Header
            {...this.props}
            label={event?.name ? event?.name : Properties.Label_EventDetails}
            fromDate={eventDate}
            toDate={eventDate === eventEnd ? false : eventEnd}
            reloadDetails={() => {
              this.setState(initialState);
              this.pageRefresh();
            }}
          />
        </AppToolBar>

        <div className={classes.mainPanel}>
          {/* name & refresh */}
          <RunnerSideBreadcrumbs />

          <Grid container style={{ alignItems: "flex-start" }}>
            <Grid item xs={6}>
              <div className={classes.actionSection}>
                {this.state.hideFeebackMessages && (
                  <ActionBtn
                    Icon={Forum}
                    label={"View feedback"}
                    onClick={this.navigateToFeedback}
                    classes={classes}
                  />
                )}
                {event?.enableTraining && (
                  <ActionBtn
                    Icon={FitnessCenter}
                    label={"Training"}
                    onClick={this.navigateToTrainingPlan}
                    classes={classes}
                  />
                )}
              </div>
              {!event?.showQRScanner && (
                <h3
                  style={{
                    color: "#727272",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  {userName}
                </h3>
              )}
            </Grid>
            <Grid item xs={6} className={classes.downloadRefresh}>
              {event?.allowReferral && (
                <div className={classes.download}>
                  <ActionBtn
                    Icon={InviteIcon}
                    label={"Refer"}
                    onClick={this.navigateToReferral}
                    classes={classes}
                  />
                </div>
              )}

              {event?.summaryGenerated && (
                <div className={classes.resultContainer}>
                  <ActionBtn
                    Icon={LeaderboardIcon}
                    label={event?.showQRScanner ? "Track" : "Results"}
                    onClick={this.navigateToResults}
                    classes={classes}
                  />
                </div>
              )}

              {event?.allowEditInfo && (
                <div className={classes.download}>
                  <ActionBtn
                    Icon={EditIcon}
                    label={"Edit"}
                    onClick={() => {
                      this.modifyHandleSelect(
                        eventDetails?.registration?.runner.id,
                        modifyValues?.firstName,
                        modifyValues?.lastName,
                        modifyValues?.email,
                        // eventDetails?.registration?.runner
                        //   .selectedRunnerGroup,
                        eventDetails?.registration?.runner.selectedAgeGroup,

                        eventDetails?.registration
                      );
                      this.setState({
                        runnerData: eventDetails?.registration?.runner,
                        rowDetails: eventDetails?.registration,
                      });
                    }}
                    classes={classes}
                  />
                </div>
              )}
              {isGoodiesAvailable &&
                !(
                  registration?.volunteer &&
                  event?.eventSettings?.allowVolunteerParticipation == "false"
                ) && (
                  <div className={classes.download}>
                    <ActionBtn
                      Icon={ShareIcon}
                      label={"Goodies"}
                      onClick={this.handleClick}
                      classes={classes}
                    />
                  </div>
                )}

              <div className={classes.download}>
                <div className={classes.textButton}>
                  <RefreshIcon
                    onClick={this.pageRefresh}
                    style={{ color: "#2883B5", cursor: "pointer" }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <div
            className={classes.firstBlock}
            style={{
              justifyContent: event?.showQRScanner ? "end" : "space-between",
            }}
          >
            {/* items block  */}
            {!event?.showQRScanner && (
              <div className={classes.itemsBlock}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    {/* category name */}
                    {event?.showCategoryOnRegistration === true && (
                      <div className={classes.item}>
                        <span className={classes.itemTitle}>
                          {challengeGoal === "TOTAL_TARGET"
                            ? "Total Target"
                            : Properties.Category_label}
                        </span>
                        <br />
                        {selfChallenge ? (
                          <div>
                            {totalTarget > 0 && (
                              <div>
                                <span className={classes.secondaryLabel}>
                                  {selfChallenge === "STEPS"
                                    ? Properties.TotalTargetSteps
                                    : Properties.TotalTargetDistance}
                                </span>
                                <span className={classes.itemValue}>
                                  {selfChallenge === "STEPS"
                                    ? totalTarget
                                    : totalTarget / 1000 + " Km"}
                                </span>
                              </div>
                            )}
                          </div>
                        ) : (
                          event && (
                            <div>
                              {secondaryActivityList && (
                                <span className={classes.secondaryLabel}>
                                  {Properties.Label_PrimaryActivity}:{" "}
                                </span>
                              )}
                              <span className={classes.itemValue}>
                                {eventDetails?.registration
                                  ?.eventRunCategories[0]?.activityType
                                  ?.displayName
                                  ? eventDetails?.registration
                                      ?.eventRunCategories[0]?.activityType
                                      ?.displayName
                                  : eventDetails?.registration
                                      ?.eventRunCategories[0]?.activityType
                                      ?.type}
                                {"-"}
                                {
                                  eventDetails?.registration
                                    ?.eventRunCategories[0]?.categoryName
                                }
                              </span>
                              {showSecondaryActivity && (
                                <div>
                                  <span className={classes.secondaryLabel}>
                                    {Properties.Label_SecondaryActivity}:{" "}
                                  </span>
                                  <span className={classes.itemValue}>
                                    {secondaryActivityList}
                                  </span>
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    )}
                    {/* bib */}
                    {registration && (
                      <div className={classes.item}>
                        <span className={classes.itemTitle}>
                          {Properties.BIB_label}
                        </span>
                        <br />
                        <span className={classes.itemValue}>
                          {registration.bibNumber}
                        </span>
                      </div>
                    )}
                    {registration?.ageGroup && showAgeGroup && (
                      <div className={classes.item}>
                        <span className={classes.itemTitle}>
                          {Properties.age_Group}
                        </span>
                        <br />
                        <span className={classes.itemValue}>
                          {registration?.ageGroup}
                        </span>
                      </div>
                    )}
                    {/* T-shirt */}
                    {TShirtSize?.length > 0 && window.innerWidth > 600 && (
                      <>
                        {TShirtSize.map((x) => (
                          <div className={classes.item}>
                            <span className={classes.itemTitle}>{x.label}</span>
                            <br />
                            <span className={classes.itemValue}>{x.value}</span>
                          </div>
                        ))}
                      </>
                    )}
                    {/* Shipping Address */}
                    {registration &&
                      shippingAddress &&
                      window.innerWidth > 600 && (
                        <div className={classes.item}>
                          <span className={classes.itemTitle}>
                            {Properties.address_label}
                          </span>
                          <br />
                          <span className={classes.itemValue}>
                            {shippingAddress}
                          </span>
                        </div>
                      )}
                    {event?.eventItems?.length > 0 &&
                      !registration.items &&
                      event?.buyMerchAfterReg && (
                        <div className={classes.purchaseLinkContainer}>
                          <p
                            className={classes.purchaseLink}
                            onClick={() =>
                              this.setState({
                                buyMerchandiseDialog: true,
                                isBuyMerchandise: true,
                              })
                            }
                          >
                            Buy Merchandise
                          </p>
                        </div>
                      )}
                    {registration?.fields?.customFields?.length > 0 &&
                      window.innerWidth > 600 && (
                        <>
                          <Collapse
                            in={customFieldsExpanded}
                            timeout="auto"
                            unmountOnExit
                          >
                            {registration?.fields?.customFields.map((field) => (
                              <div className={classes.item}>
                                <span className={classes.itemTitle}>
                                  {field?.displayName}
                                </span>
                                <br />
                                <span className={classes.itemValue}>
                                  {field?.fieldType?.name === "MULTI_SELECT" &&
                                  field?.fieldValue &&
                                  field?.fieldValue?.length > 0
                                    ? field?.fieldValue?.map((x, i) =>
                                        field?.fieldValue?.length - 1 === i
                                          ? x.optionValue
                                          : `${x.optionValue}, `
                                      )
                                    : field?.fieldValue}
                                </span>
                              </div>
                            ))}
                          </Collapse>
                          <Typography
                            className={clsx(classes.expand, {
                              [classes.expandOpen]: customFieldsExpanded,
                            })}
                            style={{ textAlign: "left" }}
                            onClick={() =>
                              this.setState({
                                customFieldsExpanded: !customFieldsExpanded,
                              })
                            }
                            aria-expanded={customFieldsExpanded}
                          >
                            {customFieldsExpanded ? (
                              <span>
                                {
                                  Properties.Label_EventDetails_EventInfo_Read_Less
                                }
                              </span>
                            ) : (
                              <span>
                                {Properties.Label_EventDetails_EventInfo_Read}
                              </span>
                            )}
                          </Typography>
                        </>
                      )}
                  </Grid>
                  {(showRunnerGroup ||
                    acknowledgementID ||
                    (registration && shippingAddress) ||
                    TShirtSize?.length > 0 ||
                    registration?.fields?.customFields?.length > 0) &&
                    (window.innerWidth > 600 ? (
                      // (false ? (
                      <Grid item xs={12} sm={6}>
                        {/* Runner Group */}
                        {registration?.runner?.runnerGroup &&
                          showRunnerGroup && (
                            <div className={classes.item}>
                              <span className={classes.itemTitle}>
                                {Properties.Running_Group_label}
                              </span>
                              <br />
                              <span className={classes.itemValue}>
                                {registration.runner.runnerGroup != ""
                                  ? registration.runner.runnerGroup
                                  : (registration.runner.runnerGroup = "NA")}
                              </span>
                            </div>
                          )}
                        {registration?.corporateGroup && showCorporateOnReg && (
                          <div className={classes.item}>
                            <span className={classes.itemTitle}>
                              {Properties.corporate_Group}
                            </span>
                            <br />
                            <span className={classes.itemValue}>
                              {registration.corporateGroup}
                            </span>
                          </div>
                        )}
                        {/* Acknowledge */}
                        {registration && acknowledgementID && (
                          <div className={classes.item}>
                            <span className={classes.itemTitle}>
                              {Properties.Label_AcknowledgementID}
                            </span>
                            <br />
                            <span className={classes.itemValue}>
                              {acknowledgementID}
                            </span>
                          </div>
                        )}
                      </Grid>
                    ) : (
                      <>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                          <Grid item xs={12} sm={6}>
                            {TShirtSize?.length > 0 && (
                              <>
                                {TShirtSize.map((x) => (
                                  <div className={classes.item}>
                                    <span className={classes.itemTitle}>
                                      {x.label}
                                    </span>
                                    <br />
                                    <span className={classes.itemValue}>
                                      {x.value}
                                    </span>
                                  </div>
                                ))}
                              </>
                            )}
                            {registration && shippingAddress && (
                              <div className={classes.item}>
                                <span className={classes.itemTitle}>
                                  {Properties.address_label}
                                </span>
                                <br />
                                <span className={classes.itemValue}>
                                  {shippingAddress}
                                </span>
                              </div>
                            )}
                            {/* Runner Group */}
                            {registration?.runner?.runnerGroup &&
                              showRunnerGroup && (
                                <div className={classes.item}>
                                  <span className={classes.itemTitle}>
                                    {Properties.Running_Group_label}
                                  </span>
                                  <br />
                                  <span className={classes.itemValue}>
                                    {registration.runner.runnerGroup != ""
                                      ? registration.runner.runnerGroup
                                      : (registration.runner.runnerGroup =
                                          "NA")}
                                  </span>
                                </div>
                              )}
                            {registration?.corporateGroup &&
                              showCorporateOnReg && (
                                <div className={classes.item}>
                                  <span className={classes.itemTitle}>
                                    {Properties.corporate_Group}
                                  </span>
                                  <br />
                                  <span className={classes.itemValue}>
                                    {registration?.corporateGroup}
                                  </span>
                                </div>
                              )}
                            {/* Acknowledge */}
                            {registration && acknowledgementID && (
                              <div className={classes.item}>
                                <span className={classes.itemTitle}>
                                  {Properties.Label_AcknowledgementID}
                                </span>
                                <br />
                                <span className={classes.itemValue}>
                                  {acknowledgementID}
                                </span>
                              </div>
                            )}
                            {registration?.fields?.customFields?.length > 0 && (
                              <>
                                {registration?.fields?.customFields.map(
                                  (field) => (
                                    <div className={classes.item}>
                                      <span className={classes.itemTitle}>
                                        {field?.displayName}
                                      </span>
                                      <br />
                                      <span className={classes.itemValue}>
                                        {field?.fieldType?.name ===
                                        "MULTI_SELECT"
                                          ? field?.fieldValue?.map((x, i) =>
                                              field?.fieldValue?.length - 1 ===
                                              i
                                                ? x.optionValue
                                                : `${x.optionValue}, `
                                            )
                                          : field?.fieldValue}
                                      </span>
                                    </div>
                                  )
                                )}
                              </>
                            )}
                          </Grid>
                        </Collapse>
                        <Typography
                          className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                          })}
                          onClick={this.handleExpandClick}
                          aria-expanded={expanded}
                        >
                          {expanded ? (
                            <span>
                              {
                                Properties.Label_EventDetails_EventInfo_Read_Less
                              }
                            </span>
                          ) : (
                            <span>
                              {Properties.Label_EventDetails_EventInfo_Read}
                            </span>
                          )}
                        </Typography>
                      </>
                    ))}
                </Grid>
              </div>
            )}
            {/* profile image */}
            <div
              className={classes.mediaContainer}
              style={{
                flex: event?.showQRScanner ? 1 : 0.2,
                marginTop: "20px",
              }}
            >
              {event?.allowVideoUpload && (
                <div
                  className={classes.mediaItem}
                  style={{
                    display: event?.showQRScanner ? "flex" : "block",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {this.state?.videoFiles ? (
                    <div
                      className={classes.mediaThumbnail}
                      style={{ width: "150px" }}
                    >
                      <span className={classes.itemTitle}>
                        {Properties.Label_PostActivityVideo}
                      </span>
                      <div className={classes.picture}>
                        <div className={classes.profileImgContainer}>
                          <img
                            className={classes.profileImg}
                            src={
                              "https://images.indianexpress.com/2022/05/Ranveer-1200.jpg"
                            }
                            alt={"pic"}
                          />
                        </div>
                        <div
                          style={{
                            backgroundColor: "#09456C",
                            borderRadius: "50%",
                            position: "absolute",
                            left: "30%",
                            top: "30%",
                          }}
                        >
                          <IconButton style={{ backgroundColor: "#09456C" }}>
                            <PlayCircleFilledIcon
                              onClick={() =>
                                this.setState({ showVideoDialog: true })
                              }
                              style={{ fontSize: "25px", color: "#fff" }}
                            />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <Button
                        color="primary"
                        fullWidth={false}
                        variant="contained"
                        className={classes.root}
                        style={{
                          width: "150px",
                          alignContent: "center",
                          padding: "10px",
                        }}
                      >
                        <label htmlFor="video-button-file">
                          <div style={{ cursor: "pointer" }}>
                            {Properties.Add_video_label}
                          </div>
                        </label>
                        <Grid item xs style={{ display: "none" }}>
                          <input
                            className={classes.input}
                            id="video-button-file"
                            type="file"
                            accept="video/*"
                            onChange={(e) => this.uploadVideo(e)}
                          />
                        </Grid>
                      </Button>
                      <p
                        style={{
                          fontSize: "11px",
                          color: "red",
                          margin: 0,
                          padding: 0,
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          textAlign: "center",
                        }}
                      >
                        Max video size allowed 15 MB
                      </p>
                    </>
                  )}
                </div>
              )}
              <div
                className={classes.mediaItem}
                style={{
                  display: event?.showQRScanner ? "flex" : "block",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {imageList?.length > 0 ? (
                  <div className={classes.mediaThumbnail}>
                    <span className={classes.itemTitle}>
                      {Properties.Label_PostRunPictures}
                    </span>
                    <div className={classes.picture}>
                      {/* <div className={classes.imgTitle}> */}
                      <div className={classes.profileImgContainer}>
                        <img
                          className={classes.profileImg}
                          src={
                            imageList
                              ? "data:image/png;base64," +
                                imageList[0]?.imageData
                              : null
                          }
                          alt={"pic"}
                          onClick={() => {
                            this.setState({ showDialog: true });
                          }}
                        />
                      </div>
                      {/* </div> */}
                      {imageList?.length > 1 ? (
                        <p
                          style={{ borderRadius: "50%" }}
                          onClick={() => {
                            this.setState({ showDialog: true });
                          }}
                        >
                          +{imageList?.length - 1}
                        </p>
                      ) : (
                        showAddButton && (
                          <p
                            style={{ borderRadius: "10px" }}
                            onClick={() => {
                              this.setState({ showDialog: true });
                            }}
                          >
                            <AddCircleIcon
                              style={{
                                color: "#fff",
                                fontSize: "15px",
                              }}
                            />
                            <span>Add</span>
                          </p>
                        )
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    {registration?.paymentStatus === "PENDING" ? (
                      // <SubmitCancelBtn
                      //   type="submit"
                      //   label="PAY NOW"
                      //   onClick={() => this.handlePayment()}
                      //   />
                      <Button
                        color="primary"
                        fullWidth={false}
                        variant="contained"
                        className={classes.root}
                        style={{
                          width: "150px",
                          alignContent: "center",
                          padding: "10px",
                        }}
                        onClick={(e) => {
                          this.handlePayment();
                        }}
                      >
                        <div style={{ cursor: "pointer" }}>PAY NOW</div>
                      </Button>
                    ) : (
                      showAddButton &&
                      !(
                        registration?.volunteer &&
                        event?.eventSettings?.allowVolunteerParticipation ==
                          "false"
                      ) && (
                        <Button
                          color="primary"
                          fullWidth={false}
                          variant="contained"
                          className={classes.root}
                          style={{
                            width: "150px",
                            alignContent: "center",
                            padding: "10px",
                          }}
                          onClick={(e) => {
                            if (imageId === "") {
                              // this.setState({ imageId: id });
                            }
                          }}
                        >
                          <label htmlFor="contained-button-file">
                            <div style={{ cursor: "pointer" }}>
                              {Properties.Add_photo_label}
                            </div>
                          </label>
                          <Grid item xs style={{ display: "none" }}>
                            <input
                              multiple
                              className={classes.input}
                              id="contained-button-file"
                              type="file"
                              accept="image/*"
                              onChange={(e) => this.multipleUpload(e)}
                            />
                          </Grid>
                        </Button>
                      )
                    )}
                  </div>
                )}
                {event?.showQRScanner && (
                  <div className={classes.qrScan}>
                    <SubmitCancelBtn
                      style={{ width: "150px", marginRight: "10px" }}
                      type="submit"
                      startIcon={<CropFreeIcon />}
                      label={"Scan QR"}
                      onClick={() => {
                        if (selectedScanPoint) {
                          this.setState({
                            openScannerDialog: true,
                            qrScanned: false,
                          });
                        } else {
                          this.setState({ seletedScanPointError: true });
                          Snackbar.show({
                            variant: "error",
                            isAutoHide: true,
                            preventDuplicate: true,
                            message: `Please select scan point`,
                          });
                        }
                      }}
                    />

                    {scanPointList && scanPointList.length > 1 && (
                      <div className={classes.scanPointsSelect}>
                        <MultiSelect
                          value={selectedScanPoint}
                          isSearchable={false}
                          isMenuMaxHeight
                          error={this.state?.seletedScanPointError}
                          options={this.state?.scanPointList}
                          onChange={(value) =>
                            this.handleScanPointChange(value)
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* ---- invite button */}
          {sendInvite && (
            <Grid
              style={{
                width: "100%",
                // height: "75px",
                marginTop: "10px",
                justifyContent: "center",
                marginBottom: "20px",
              }}
              container
              spacing={1}
            >
              <SubmitCancelBtn
                style={{ width: "auto" }}
                onClick={() => this.naviagteToInvite()}
                label={"Invite People"}
                endIcon={<InviteIcon />} // {<PersonAddIcon />}
                type="submit"
              />
            </Grid>
          )}

          {registration?.paymentStatus === "SUCCESS" && (
            <>
              <Carousel
                className={classes.thumbswrapper}
                showStatus={false}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeable={visiblesCarouselDetails?.length > 1}
                showArrows={visiblesCarouselDetails?.length > 1}
                showIndicators={visiblesCarouselDetails?.length > 1}
                swipeScrollTolerance={50}
                //  infiniteLoop={true}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <button
                      type="button"
                      onClick={onClickHandler}
                      className={classes.arrowStyles}
                      title={label}
                      style={{ left: 15 }}
                    >
                      <ArrowBackIosIcon
                        style={{ color: "#3a6ea5" }}
                        className={classes.leftArrow}
                      />
                    </button>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <button
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      className={classes.arrowStyles}
                      style={{ right: 15 }}
                    >
                      <ArrowForwardIosIcon style={{ color: "#3a6ea5" }} />
                    </button>
                  )
                }
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  const defStyle = {
                    color: "lightgrey",
                    cursor: "pointer",
                    margin: 0,
                  };
                  const style = isSelected
                    ? { ...defStyle, color: "#3a6ea5" }
                    : { ...defStyle };
                  return (
                    <span
                      style={style}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      value={index}
                      key={index}
                      role="button"
                      tabIndex={0}
                      aria-label={`${label} ${index + 1}`}
                    >
                      <FiberManualRecordIcon style={{ fontSize: "20px" }} />
                    </span>
                  );
                }}
              >
                {visiblesCarouselDetails.map((slide) => {
               
                  return <div key={slide.id}>{slide.content}</div>
  })}
              </Carousel>

              {event?.showQRScanner && (
                <>
                  {selectedScanPoint ? (
                    this.state?.scanDetailsList?.length > 0 ? (
                      <ListView
                        data={this.state?.scanDetailsList || []}
                        isAllParticipants={true}
                        eventDetailView
                        scanPointList={scanPointList}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          marginTop: "20px",
                          // minHeight: "200px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: 600,
                            marginTop: "10px",
                            color: "gray",
                          }}
                        >
                          {scanPointList &&
                            scanPointList.length > 1 &&
                            `No participants scanned at ${selectedScanPoint?.timingMatIdentifier}`}
                        </span>
                      </div>
                    )
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        marginTop: "20px",
                        minHeight: "200px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: 600,
                          marginTop: "10px",
                          color: "gray",
                        }}
                      >
                        {`Please select any scan point`}
                      </span>
                    </div>
                  )}
                </>
              )}

              <>
                {details?.length > 0 ? (
                  <Grid
                    style={{
                      display: "grid",
                    }}
                  >
                    <ActivityView
                      timeValidation={timeValidation}
                      data={details}
                      userMessages={userMessages}
                      displayColumns={displayColumns}
                      showNextDayActivityPlan={showNextDayActivityPlan}
                      isUploadAndAddPhotoAvaialable={
                        isUploadAndAddPhotoAvaialable
                      }
                      challengeType={challengeType}
                      eventType={type}
                      multipleActivityUpload={allowMultipeActiviy}
                      pageRefresh={() => {
                        this.setState(initialState);
                        this.pageRefresh();
                      }}
                      allowPhotoDelete={allowPhotoDelete}
                      enableTraining={event?.enableTraining}
                    />
                  </Grid>
                ) : (
                  <>
                    {registration?.volunteer &&
                    event?.eventSettings?.allowVolunteerParticipation ==
                      "false" ? null : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <NoActivityAvailable
                          style={{
                            color: "#696969",
                            width: "100px",
                            height: "100%",
                            marginLeft: "30px",
                          }}
                        />
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: 600,
                            marginTop: "10px",
                            color: "gray",
                          }}
                        >
                          {this.getEventAndActivityText(event)}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </>
            </>
          )}

          {/* drop down menu */}
          {isGoodiesAvailable && (
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
              // className="header__user--menu"
              style={{ marginTop: "50px" }}
            >
              {goodiesList?.map((list) => (
                <MenuItem className={classes.goodieMenu}>
                  <span onClick={(e) => this.navigateToSummary(list.label)}>
                    {list.label}
                  </span>
                  <div className={classes.shareIconContainer}>
                    <ShareIcon
                      onClick={(e) => this.shareGoodie(list.label)}
                      className={classes.shareIcon}
                      htmlColor={"#F79824"}
                    />
                  </div>
                </MenuItem>
              ))}
            </Menu>
          )}
          {/* slider dialog */}

          <DialogBox
            fullWidth
            deletePhoto={
              showDeleteButton ? (imageList != 0 ? true : false) : false //0 ? true : false
            }
            onDeletePhoto={this.deletePhoto}
            addPhoto={showAddButton}
            onAddPhoto={this.multipleUpload}
            open={showDialog}
            message={this.getDialogComponent()}
            onClick={() => this.closeWindow()}
            closeDisloag={() => this.closeWindow()}
          />
          <DialogBox
            fullWidth
            maxWidth={"md"}
            // header={"Hold QR Code Steady and Clear to Scan"}
            open={openScannerDialog}
            message={this.getScannerDialog()}
            onClick={() => this.closeWindow()}
            closeDisloag={() => this.closeWindow()}
          />
          <DialogBox
            fullWidth
            maxWidth={"sm"}
            header={"Participant Details"}
            open={openParticipantDialog}
            message={this.getParticipantDialog()}
            onClick={() => this.closeWindow()}
            closeDisloag={() => this.closeWindow()}
          />
          <DialogBox
            fullWidth
            maxWidth={"md"}
            open={this.state.showForm}
            header={"Update Registration"}
            message={this.getFormComponent()}
            onClick={() => this.closeWindow()}
            closeDisloag={() => this.closeWindow()}
          />
          <DialogBox
            fullWidth
            singleButton
            label={"Buy"}
            maxWidth={"md"}
            onSingleButtonClick={this.handleOnBuyMerchandiseClick}
            open={buyMerchandiseDialog}
            header={"Buy Merchandise"}
            message={this.getMerchandiseComponent()}
            onClick={() => this.closeWindow()}
            closeDisloag={() => this.closeWindow()}
          />
          <DialogBox
            fullWidth
            maxWidth={"md"}
            header={`${registration?.bibNumber} - ${registration?.runner?.fullName}`}
            deletePhoto={true}
            onDeletePhoto={this.onHandleVideoDelete}
            open={showVideoDialog}
            message={this.getVideoDialogComponent()}
            onClick={() => this.closeWindow()}
            closeDisloag={() => this.closeWindow()}
          />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userSelectedEvent: state.user.userSelectedEvent,
    updateUserPreferences: state.user.updateUserPreferences,
  };
}

const ActionBtn = ({ Icon, label, onClick, classes }) => (
  <>
    <div className={classes.actionBtn}>
      <SubmitCancelBtn
        style={{ width: "auto" }}
        type="submit"
        startIcon={<Icon />}
        label={label}
        onClick={onClick}
      />
    </div>
    <div className={classes.actionIcon}>
      <IconButton style={{ backgroundColor: "#09456C" }}>
        <Icon onClick={onClick} style={{ fontSize: "25px", color: "#fff" }} />
      </IconButton>
    </div>
  </>
);
export default compose(
  withStyles(
    styles,

    { withTheme: true }
  ),
  withRouter,
  connect(mapStateToProps, {
    updateUserSelectedEvent,
    updateUserPreferences,
  })
)(EventDetails);
