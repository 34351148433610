import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AppToolBar from "../../../common/AppToolBar";
import Header from "../../../common/Header";
import {
  getChallengeDetailsWithOrgkey,
  openChallengeDetails,
  openChallengeDetailsWithDistKey,
  openEventChallenges,
  openEventChallengeUserCheck,
} from "../../../../apis/challengeService";
import { isMobile } from "react-device-detect";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import ApplicationUtil from "../../../../utils/ApplicationUtil";

const styles = (theme) => ({
  mainBody: {
    marginTop: "68px",
  },
  upperBody: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  leftBody: {
    flex: 0.5,
    margin: "10px",
    position: "sticky",
    top: 0,
    bobttom: 0,
  },
  rightBody: {
    flex: 0.5,
    margin: "10px",
    // height: "90vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  media: {
    width: "60%",
    maxWidth: "60%",
    minWidth: "100%",
    margin: "auto",
    objectFit: "content",
    borderRadius: "8px",
    boxShadow: "inset 0px 0px 19px 0px rgba(0,0,0,0.75)",
  },
  cardmedia: {
    margin: "0 auto",
    display: "flex",
    position: "relative",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  customButton: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 38,
    padding: "0 30px",
    marginRight: "20px",
  },
  cardmediaBlock: {
    margin: "0 auto",
    display: "flex",
    position: "relative",
    justifyContent: "center",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  mediaBlock: {
    height: "250px",
    maxHeight: "250px",
    minHeight: "250px",
    width: "100%",
    minWidth: "100%",
    maxWidth: "100%",
    margin: "0 auto",
    objectFit: "content",
    borderRadius: "8px",
    boxShadow: "inset 0px 0px 19px 0px rgba(0,0,0,0.75)",
  },
  emailDialog: {
    padding: "8px 24px",
  },
});
class ChallengeDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      responseData: null,
    };
  }
  componentDidMount() {
    const { history } = this.props;
    let challengeEventId;
    // distkey logic for now is hardcoded, might need make changes further
    let url = window.location.href;
    let distKey = url.split("distKey=")[1];

    // details with orgKey
    let orgKey = localStorage.getItem("orgKey")
      ? localStorage.getItem("orgKey")
      : null;
    let orgId = null;
    let generateOTP = false;

    if (distKey) {
      openChallengeDetailsWithDistKey(distKey).then((res) => {
        localStorage.setItem("challengeId", res.data?.id);
        this.setState({
          responseData: res?.data,
        });
      });
    } else if (orgKey !== null) {
      getChallengeDetailsWithOrgkey(orgKey).then((res) => {
        if (res?.data) {
          orgId = res.data.id;
          if (challengeEventId) {
            if (orgId) {
              openChallengeDetails(orgId).then((res) => {
                this.setState({
                  responseData: res?.data,
                });
              });
            }
          } else {
            history.push("/completeEventList");
          }
        }
      });
    } else {
      openChallengeDetails().then((res) => {
        this.setState({
          responseData: res?.data,
        });
      });
    }
    let runnerData = ApplicationUtil.getRunnerDetails();

    let userEmail = runnerData?.email;
    let userMobile = runnerData?.contactNumber;
    openEventChallengeUserCheck(generateOTP, userEmail, userMobile).then(
      (res) => {
        this.setState({
          trial: res?.data?.trialApplicable,
        });
      }
    );
  }

  render() {
    const { classes, history } = this.props;
    const { responseData, trial } = this.state;
    return (
      <div>
      
        <AppToolBar
          backNavigate={() => {
            ApplicationUtil.isuserLoggedIn("runner")
              ? this.props.history.push("/eventlist")
              : history.push("/completeEventList");
          }}
          showBack={true}
        >
          <Header {...this.props} label={"Challenge Details"} />
          <div style={{ width: 35 }} />
        </AppToolBar>
        <div className={classes.mainBody}>
          <div className={classes.upperBody}>
            <div className={classes.leftBody}>
              <Card>
                {/* desk */}
                <CardMedia className={classes.cardmedia}>
                  <img
                    className={classes.media}
                    src={responseData?.bannerImagePath}
                    title="run"
                  />
                </CardMedia>
              </Card>
            </div>
            <div className={classes.rightBody}>
              <Card variant="outlined">
                <CardHeader
                  title={responseData?.challengeParamDisplayName}
                  subheader={ReactHtmlParser(responseData?.introduction)}
                />
                {/* mobile */}
                <CardMedia className={classes.cardmediaBlock}>
                  <img
                    className={classes.mediaBlock}
                    src={responseData?.bannerImagePath}
                    title="run"
                  />
                </CardMedia>
                <CardContent>
                  <Typography variant="body2" color="textPrimary">
                    <span
                      style={{
                        color: "#2883B5",
                        fontWeight: 600,
                        fontSize: "20px",
                      }}
                    >
                      How it works:
                    </span>
                    <p>{ReactHtmlParser(responseData?.description)}</p>
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </div>
          {responseData?.isEventClosed === false && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px 0px",
              }}
            >
              {(!ApplicationUtil.isuserLoggedIn("runner") || trial) &&
                responseData?.trialDays !== 0 && (
                  <Button
                    className={classes.customButton}
                    onClick={() => {
                      localStorage.setItem("freeRegister", true);
                      history.push("/challengeRegistration");
                    }}
                  >
                    {window.innerWidth < "500"
                      ? "FREE TRIAL"
                      : "START YOUR FREE TRIAL NOW"}
                  </Button>
                )}
              <div
              // style={{ margin: "20px" }}
              >
                <SubmitCancelBtn
                  label="REGISTER NOW"
                  type="submit"
                  onClick={() => {
                    localStorage.removeItem("freeRegister");
                    history.push("/challengeRegistration");
                  }}
                  style={{
                    width: window.innerWidth > "500" ? "250px" : null,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ChallengeDetails);
