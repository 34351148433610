import React, { Component } from "react";
import { connect } from "react-redux";
import { updateUserDetails } from "../../../redux/user";
import { updateSupportUserDetails } from "../../../redux/supportUser";
import AppToolBar from "../../common/AppToolBar";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Cropper from "react-easy-crop";
import Properties from "./../../../properties/Properties";
import SubmitCancelBtn from "../../common/SubmitCancelBtn";
import getCroppedImg from "./../../common/cropImage";
import { Grid } from "@material-ui/core";

import ApplicationUtil from "../../../utils/ApplicationUtil";

import { updateProfilePicture } from "../../../apis/editProfile";
import Header from "../../common/Header";
import getUserDetails from "../../../apis/userService";
import { withRouter } from "react-router-dom";
const styles = (theme) => ({
  //   imgContainer: {
  //     position: "relative",
  //     flex: 1,
  //     padding: 16,
  //     // display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //     height: "250px",
  //   },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  slider: {
    padding: "22px 0px",
  },
});

class EditProfilePictureContainer extends Component {
  state = {
    imageSrc: null,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 4 / 3,
    open: false,
    dailogMsg: "",
    croppedImg: null,
    croppedAreaPixels: null,
  };

  componentDidMount() {
    document.title = Properties.Page_Title_Event_UploadRun;
    this.setState({ userName: localStorage.getItem("userName") });

    if (!ApplicationUtil.isuserLoggedIn(this.props.location?.state?.view)) {
      this.props.location?.state?.view === "organizer"
        ? this.props.history("/organizer")
        : this.props.history.push("/");
    } else if (!this.state.imageSrc && !this.props.location.imgSrc) {
      // Snackbar.show({
      //   variant: "error",
      //   isAutoHide: true,
      //   preventDuplicate: true,
      //   message: "Please select image first",
      // });
      this.props.history.push("/profile");
    } else {
      this.setState({
        imageSrc: this.props.location.imgSrc,
      });
    }
  }

  closeWindow = () => {
    this.props.history.push({
      pathname: "/profile",
      state: {
        editProfile: this.props.location?.state?.view ? false : true,
        view: this.props.location?.state?.view
          ? this.props.location?.state?.view
          : "",
      },
    });
  };
  updatePicture = () => {
    const newBlob = new Blob(this.state.croppedImg, { type: "image/jpeg" });
    let file = new File(newBlob, "img.jpeg", { type: "image/jpeg" });
    return file;
    // let key = "profileId_" + parseInt(Math.random() * 1000000);
    // file.key = key;
    // file.id = key;
    // file.src = this.state.croppedImg;
    // file.name = "ProfilePic" + key;
    // file.type = "image/jpeg";
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    this.setState({ croppedAreaPixels: croppedAreaPixels });
  };
  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };
  cropImage = async () => {
    let supportData = ApplicationUtil.getSupportDetails();
    let supportUserId = supportData?.id;
    let supportOrganizer_token = supportData?.token;

    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerUserId = runnerData?.id;
    let runner_token = runnerData?.token;

    let token =
      this.props.location?.state?.view === "organizer"
        ? supportOrganizer_token
        : runner_token;
    let userId =
      this.props.location?.state?.view === "organizer"
        ? supportUserId
        : runnerUserId;
    let img = await getCroppedImg(
      this.state.imageSrc,
      this.state.croppedAreaPixels
    );

    this.setState({ croppedImg: img });

    fetch(img)
      .then((res) => res.blob())
      .then((blob) => {
        let file = new File([blob], "profile", {
          type: "image/jpeg",
        });
        let key = "profileId_" + parseInt(Math.random() * 1000000);
        file.key = key;
        file.id = key;
        let customParams = {};
        customParams.file = file;
        updateProfilePicture(customParams, token, userId).then((res) => {
          getUserDetails(token, userId).then((res) => {
            let profileData =
              this.props.location?.state?.view === "organizer"
                ? ApplicationUtil.getSupportDetails()
                : ApplicationUtil.getRunnerDetails();
            let newData = res.data.user;
            profileData = { ...profileData, ...newData };

            profileData.profilePicture = res.data?.profilePicture?.imageData;
            if (this.props.location?.state?.view === "organizer") {
              this.props.updateSupportUserDetails(profileData);
            } else {
              this.props.updateUserDetails(profileData);
            }
          });
          this.closeWindow();
        });
      });
  };

  changeToEditProfile = () => {
    this.props.history.push({
      pathname: "/editProfile",
      state: {
        editProfile: this.props.location?.state?.view ? false : true,
        view: this.props.location?.state?.view
          ? this.props.location?.state?.view
          : "",
      },
    });
  };
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <AppToolBar
          backNavigate={() => this.props.history.goBack()}
          showBack={true}
        >
          <Header signup label={"Edit Picture"} />
          <div style={{ width: 35 }} />
        </AppToolBar>
        <div
          className={classes.mainPanel}
          style={{
            marginTop: "2%",
          }}
        >
          <Grid
            style={{ width: "100%", margin: "0px", justifyContent: "center" }}
            container
            spacing={1}
          >
            {this.state.imageSrc && (
              <React.Fragment>
                <div style={{ maxHeight: "500px" }}>
                  <Cropper
                    image={this.state.imageSrc}
                    crop={this.state.crop}
                    zoom={this.state.zoom}
                    aspect={this.state.aspect}
                    onCropChange={this.onCropChange}
                    onCropComplete={this.onCropComplete}
                    onZoomChange={this.onZoomChange}
                  />

                  {/* <div className="controls">
                  <Slider
                    value={this.state.zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => this.onZoomChange(zoom)}
                    classes={{ container: "slider" }}
                  />
                </div> */}
                </div>
                <div style={{ position: "absolute", bottom: "0px" }}>
                  <Grid
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      margin: "0px",
                      textAlign: "center",
                      marginTop: "4%",
                    }}
                    container
                    spacing={1}
                  >
                    <Grid item xs={6}>
                      <SubmitCancelBtn
                        style={{ width: "auto" }}
                        onClick={() => this.props.history.goBack()}
                        type="submit"
                        label="Cancel"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SubmitCancelBtn
                        style={{ width: "auto" }}
                        onClick={() => this.cropImage()}
                        type="submit"
                        label="Save"
                      />
                    </Grid>
                  </Grid>
                </div>
              </React.Fragment>
            )}
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    supportUser: state.supportUser,
  };
}
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateUserDetails,
    updateSupportUserDetails,
  })
)(EditProfilePictureContainer);
