import { withStyles } from "@material-ui/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { OutlinedInput, TextField } from "@material-ui/core";
import MultiSelect from "../../../common/MultiSelect";
import ApplicationUtil from "../../../../utils/ApplicationUtil";

function NumericalQue({
  classes,
  handleChange,
  quizResponse,
  response,
  question,
  handleUnitSelect,
  selectedUnit,
}) {
  return (
    <div className={classes.container}>
      <div className={classes.inputWithText}>
        {selectedUnit == "Glass" && (
          <OutlinedInput
            type={"number"}
            onFocus={(e) =>
              //Vishal -  Defect_Id_669
              ApplicationUtil.disabledIncreaseDecreaseTextFieldCountOnScroll(e)
            }
            disabled={ApplicationUtil.handleFieldDisable(question, response)}
            style={{
              background: ApplicationUtil.handleFieldDisable(question, response)
                ? "hsl(0, 0%, 95%)"
                : "#ffffff",
            }}
            className={classes.inputBox}
            defaultValue={Number(quizResponse)}
            value={Number(quizResponse)}
            onChange={handleChange}
            inputProps={{ min: 0 }}
          />
        )}

        {selectedUnit == "Liters" && (
          //Ankit - Defect_id_859 for liters upto two decimal digit
          //Ankit - Defect_id_863 using backspace it was not getting cleared.
          <OutlinedInput
            id="outlined-number"
            type="number"
            onFocus={(e) =>
              ApplicationUtil.disabledIncreaseDecreaseTextFieldCountOnScroll(e)
            }
            disabled={ApplicationUtil.handleFieldDisable(question, response)}
            className={classes.inputBox}
            style={{
              background: ApplicationUtil.handleFieldDisable(question, response)
                ? "hsl(0, 0%, 95%)"
                : "#ffffff",
            }}
            defaultValue={Number(quizResponse)}
            value={Number(quizResponse)}
            onChange={handleChange}
            inputProps={{ min: 0, step: 0.5 }}
          />
        )}
      </div>
      {question?.showUnit && (
        <div className={classes.dropDown}>
          <MultiSelect
            isReadOnly={ApplicationUtil.handleFieldDisable(question, response)}
            value={{ label: selectedUnit, value: selectedUnit }}
            options={ApplicationUtil.formatLabelValuesFromArray(
              question?.units
            )}
            onChange={handleUnitSelect}
          />
        </div>
      )}
    </div>
  );
}

const styles = (theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  inputBox: {
    height: "38px",
    marginTop: "10px",
    marginBottom: "10px",
    border: `1px solid #08456C`,
  },
  inputWithText: {
    display: "flex",
    flexDirection: "column",
    "& .MuiFormHelperText-root": {
      marginTop: "0px !important",
      marginBottom: "10px",
    },
  },
  hideDisplay: {
    visibility: "hidden",
    marginBottom: "8px",
    [theme.breakpoints.down("sm")]: {
      // display: "none"
    },
  },
  dropDown: {
    width: "120px",
  },
});
export default compose(withStyles(styles), withRouter)(NumericalQue);
