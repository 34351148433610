import React from "react";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import compose from "recompose/compose";

import Properties from "../../properties/Properties";
import {
  SortSVGIcon,
  SortUpDownSVGIcon,
  SortUpBlueIcon,
  SortDownBlueIcon,
  SortDownGreyIcon
} from "../common/SvgIcon";
import { withRouter } from "react-router-dom";

const styles = theme => ({
  drawerPaper: theme.drawer.drawerRight,

  listStyle: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#222222",
    paddingLeft: "10px"
  },
  listnameStyle: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#222222",
    padding: "10px",
    background: "#D0DFFF",
    backgroundClip: "ontent-box"
  },
  scrollabelDiv: {
    height: "100%",
    overflow: "scroll"
  },
  buttonDiv: {
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "10px"
  },
  listSortIcon: {
    height: 27,
    width: 27
  },
  headerTitle: {
    borderBottom: "solid 1px #ccc",
    height: "60px"
  },
  applyBtn: {
    width: "80%",
    background: "#296dfa",
    textTransform: "none",
    height: "38px"
  }
});

class SortingDrawer extends React.Component {
  state = { selectedSort: null };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isOpen, selectedSortColumn } = nextProps;

    if (isOpen) {
      if (selectedSortColumn) {
        this.setState({ selectedSort: selectedSortColumn });
      }
    }
  }

  render() {
    const {
      classes,
      isOpen,
      onClose,
      sortingLabel,
      columnList,
      handleApplySort
    } = this.props;

    const { selectedSort } = this.state;

    return (
      <Drawer
        anchor="right"
        variant="temporary"
        className="Drawer"
        open={isOpen}
        classes={{
          paper: classes.drawerPaper
        }}
        onClose={() => {
          onClose();
        }}
      >
        <ListItem className={classes.headerTitle}>
          <SortSVGIcon
            style={{
              color: "#F79824",
              width: "26px",
              height: "20px"
            }}
          />
          <ListItemText
            classes={{ primary: classes.listStyle }}
            style={{ paddingLeft: "0px" }}
          >
            <b>{sortingLabel}</b>
          </ListItemText>
          <SortUpDownSVGIcon
            style={{
              color: "#F79824",
              width: "26px",
              float: "right",
              height: "26px"
            }}
          />
        </ListItem>
        <div className={classes.scrollabelDiv}>
          <List>
            {columnList.map((item, i) => (
              <ListItem
                key={i}
                style={{ borderBottom: "solid 1px #ccc" }}
                onClick={e => this.handleColumnSort(item)}
              >
                {this.getListItem(item)}
              </ListItem>
            ))}
          </List>
        </div>
        <div className={classes.buttonDiv}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onClose();

              handleApplySort({
                sortDirection: selectedSort.sortDirection,
                sortColumn: selectedSort.sortColumn,
                columnName: selectedSort.columnName
              });
            }}
            className={classes.applyBtn}
          >
            {Properties.Aapply_btnLabel}
          </Button>
        </div>
      </Drawer>
    );
  }

  handleColumnSort = item => {
    const { selectedSort } = this.state;
    item.sortColumn = item.dataIndex;

    if (
      selectedSort &&
      selectedSort.sortColumn === item.sortColumn &&
      selectedSort.sortDirection === "ASC"
    ) {
      item.sortDirection = "DESC";
      this.setState({ selectedSort: item });
    } else if (
      selectedSort &&
      selectedSort.sortColumn === item.sortColumn &&
      selectedSort.sortDirection === "DESC"
    ) {
      item.sortDirection = "ASC";
      this.setState({ selectedSort: item });
    } else {
      item.sortDirection = "ASC";
      this.setState({ selectedSort: item });
    }
  };

  getListItem = item => {
    const { classes } = this.props;
    const { selectedSort } = this.state;

    if (selectedSort && selectedSort.sortColumn === item.dataIndex) {
      return (
        <React.Fragment>
          <ListItemText classes={{ primary: classes.listnameStyle }}>
            <b>{item.columnName}</b>
          </ListItemText>
          {selectedSort.sortDirection === "ASC" && (
            <SortUpBlueIcon className={classes.listSortIcon} />
          )}
          {selectedSort.sortDirection === "DESC" && (
            <SortDownBlueIcon className={classes.listSortIcon} />
          )}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <ListItemText classes={{ primary: classes.listStyle }}>
            <b>{item.columnName}</b>
          </ListItemText>
          <SortDownGreyIcon className={classes.listSortIcon} />
        </React.Fragment>
      );
    }
  };
}

export default compose(withStyles(styles, { withTheme: true }),withRouter)(SortingDrawer);
