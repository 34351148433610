import React from "react";
import { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { ArrowDropDownCircleOutlined, CheckBox } from "@material-ui/icons";
import Properties from "../../../../../properties/Properties";
import Snackbar from "../../../../common/Snackbar";
import DialogBox from "../../../../common/DialogBox";
import { deleteProgramChallengeParticipants } from "../../../../../apis/eventService";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";




function OrganizerProgramChallengeParticipantList({
  classes,
  data,
  setIsProgramChallenge,
  history,
  isPastChallenge,
  isOpenSubscriptionProgram
}) {
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkboxArr, setCheckboxArr] = useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState("");
  const [anchorEl, setAnchorEl] = useState("");
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [participantsId, setParticipantsId] = useState([]);

  const closeWindow = () => {
    setShowCancelDialog(false);
    setAnchorEl(null);
    setParticipantsId(null);
  };

  const handleActivityDelete = () => {
    let participantsIds = [];
    if (menuAnchorEl) {
      checkboxArr.forEach((e) => {
        if (e.check) {
          participantsIds.push(e.id);
        } else {
          return;
        }
      });
    } else {
      participantsIds = [participantsId];
    }
    
    let getSupportedEventDetails = ApplicationUtil.getSupportsEventsDetails();
    let challengeId = getSupportedEventDetails?.id;
    deleteProgramChallengeParticipants(challengeId, participantsIds)
      .then((res) => {
        setIsProgramChallenge(true);
        closeWindow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isSelected = (id) => {
    let i = checkboxArr.findIndex((x) => x.id === id);
    if (i !== -1) {
      return checkboxArr[i].check;
    }
    return false;
  };

  const handleSelectAll = (e) => {
    let val = e.target.checked;
    let arr = val
      ? data?.map((participant) => ({
          id: participant?.runnerId,
          check: true,
        }))
      : [];
    setCheckboxArr(arr);
    setIsAllChecked(val);
  };

  const handleSelect = (e, row) => {
    let arr = [...checkboxArr];
    let i = arr.findIndex((x) => x.id === row?.runnerId);
    if (i !== -1) {
      arr[i].id = row?.runnerId;
      arr[i].check = e.target.checked;
      setCheckboxArr(arr);
    } else {
      setCheckboxArr([
        ...checkboxArr,
        {
          check: e.target.checked,
          id: row.runnerId,
        },
      ]);
    }
    setIsAllChecked(false);
  };

  const getDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.DeleteActivityWarning}</span>
        </Typography>
      </div>
    );
  };

  const navigateToProgramChallengeParticipntDetails = (
    id,
    programChallengeId
  ) => {

    
    
    localStorage.setItem("supportRunnerId", id);
    localStorage.setItem("programChallengeId", programChallengeId);
    history.push("list/details");
  };

  return (
    <div className={classes.main}>
      <FormControl className={classes.formControlAction}>
        <div className={classes.actions}>
          <span>Actions</span>
          <span>
            <ArrowDropDownCircleOutlined
              className={classes.editIcon}
              onClick={(event) => {
                setMenuAnchorEl(event.currentTarget);
              }}
            />
          </span>
        </div>
      </FormControl>

      <TableContainer component={Paper} hover>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {!isPastChallenge && (
                <>
                  <TableCell>
                    <Checkbox
                      checked={isAllChecked}
                      onChange={handleSelectAll}
                      color="primary"
                    />
                  </TableCell>

                  <TableCell
                    component="th"
                    style={{ width: "300px", "max-width": "120px" }}
                  ></TableCell>
                </>
              )}
              <TableCell component="th" align="center">
                Name
              </TableCell>
              {isOpenSubscriptionProgram && <TableCell component="th" align="center">
                Challenge Type
              </TableCell>}
              <TableCell component="th">Sustain</TableCell>
              <TableCell component="th">Not Sustain</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.map((row, index) => { 
            
                
                return (
                  <TableRow
                    className={classes.tableRow}
                    key={index}
                    hover
                    component={Paper}
                  >
                    {!isPastChallenge && (
                      <>
                        <TableCell
                          component="td"
                          align="center"
                          style={{ height: "50px" }}
                        >
                          <Checkbox
                            checked={
                              isAllChecked
                                ? isAllChecked
                                : isSelected(row?.runnerId)
                            }
                            onChange={(e) => handleSelect(e, row)}
                            color="primary"
                          />
                        </TableCell>

                        <TableCell component="td" align="center">
                          <IconButton>
                            <ArrowDropDownCircleOutlined
                              className={classes.editIcon}
                              onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setParticipantsId(row?.runnerId);
                              }}
                            />
                          </IconButton>
                        </TableCell>
                      </>
                    )}

                    <TableCell
                      component="td"
                      align="left"
                      onClick={() => {
                        navigateToProgramChallengeParticipntDetails(
                          row?.runnerId,
                          row?.programChallengeId
                        );
                      }}
                    >
                      {`${row?.runner?.firstName} ${row?.runner?.lastName}`}
                    </TableCell>

                    {isOpenSubscriptionProgram&&  <TableCell
                      component="td"
                      align="center"
                     
                    >
                    {ApplicationUtil.capitalizeFirstLetter(row?.programChallengeType?.replace(/_/g, " "))}
                    </TableCell>}

                    <TableCell
                      component="td"
                      align="center"
                      onClick={() => {
                        navigateToProgramChallengeParticipntDetails(
                          row?.runnerId,
                          row?.programChallengeId
                        );
                      }}
                    >
                      {row?.totalPositiveResponse}
                    </TableCell>
                    <TableCell
                      component="td"
                      align="center"
                      onClick={() => {
                        navigateToProgramChallengeParticipntDetails(
                          row?.runnerId,
                          row?.programChallengeId
                        );
                      }}
                    >
                      {row?.totalNegativeResponse}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* global menu */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{ marginTop: "45px", position: "absolute", zIndex: 0 }}
      >
        <MenuItem
          onClick={() => {
            let participantsIds = [];
            checkboxArr.forEach((e) => {
              if (e.check) {
                participantsIds.push(e.runnerId);
              } else {
                return;
              }
            });
            if (participantsIds.length > 0) {
              setShowCancelDialog(true);
            } else {
              Snackbar.show({
                variant: "error",
                isAutoHide: true,
                preventDuplicate: true,
                message: Properties.SelectActivityToDeleteLabel,
              });
            }
          }}
        >
          {Properties.DeleteActivityLabel}
        </MenuItem>
      </Menu>

      {/* row wise menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        // style={{ marginTop: "45px", position: "absolute", zIndex: 0 }}
      >
        <MenuItem
          onClick={() => {
            setShowCancelDialog(true);
          }}
        >
          {Properties.DeleteActivityLabel}
        </MenuItem>
      </Menu>

      {/* delete activity warning */}
      <DialogBox
        signup={true}
        open={showCancelDialog}
        message={getDialogMsg()}
        // header={dialogHeader}
        label={"No"}
        isSmall={true}
        onClick={() => {
          setShowCancelDialog(false);
        }}
        labelTwo={"Yes"}
        onClickTwo={() => {
          handleActivityDelete();
        }}
        closeDialog={() => {
          setShowCancelDialog(false);
        }}
      />
    </div>
  );
}
const styles = (theme) => ({
  main: {
    padding: "10px",
    margin: "10px",
  },
  editIcon: {
    color: "#09456C",
    cursor: "pointer",
    marginLeft: "1px",
  },
  formControlAction: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  actions: {
    display: "flex",
    alignItems: "center",
  },
  hideArrowDropdown: {
    visibility: "hidden",
  },
  tableHead: {
    backgroundColor: "#ced4da",
    "& th": {
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
    },
  },
  tableRow: {
    "& td": {
      fontWeight: "bold",
      fontFamily: "Rubik, sans-serif",
      cursor: "pointer",
    },
  },
  collapseItem: {
    // padding:10,
    display: "flex",
    alignItems: "center",
  },
  titleCss: {
    // color: "rgb(114, 114, 114)",
    color: "rgba(0, 0, 0, 0.87);",
    // color:'black',
    fontSize: "16px",
    fontWeight: 600,
  },
  infoCss: {
    color: "rgb(9, 69, 108)",
    fontSize: "20px",
    padding: "0px 0px 0px 5px",
    fontWeight: "bolder",
  },
});

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(OrganizerProgramChallengeParticipantList);
