import { Card, CardContent, Paper, Typography } from "@material-ui/core";
import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Properties from "../../../../../properties/Properties";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
const styles = (theme) => ({
  eventSponsors: {
    width: "100%",
    maxWidth: "850px",
    margin: "auto",
    marginBottom: "10px",
  },
  sponsorsTitle: {
    display: "flex",
    // flexDirection: "column"/,
    alignItems: "center",
    marginTop: "10px",
  },

  logo: {
    width: "70px",
  },
});

class EventSponsors extends Component {
  render() {
    const { data, classes, mobile } = this.props;

    return (
      <Card className={classes.eventSponsors}>
        <CardContent>
          <Typography variant="body2" gutterBottom>
            <span
              style={{
                color: "#2883B5",
                fontSize: "23px",
                fontWeight: "bold",
              }}
            >
              {Properties.Label_EventDetails_Sponsor}
            </span>
          </Typography>
          <Grid container>
            {data?.eventSponsors?.map((el) => (
              <Grid item xs={12} sm={4}>
                <div className={classes.sponsorsTitle}>
                  {el.sponsorLogo && (
                    <img
                      src={el.sponsorLogo}
                      alt={el.sponsorName}
                      className={classes.logo}
                    />
                  )}

                  <b style={{ fontSize: "20px", marginLeft: "10px" }}>
                    {el.sponsorName}
                  </b>
                </div>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }),withRouter)(EventSponsors);
