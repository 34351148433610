import React from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EjectRounded from "@material-ui/icons/EjectRounded";
import ImageDialogBox from "../../../common/ImageDialogBox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Grid } from "@material-ui/core";
import {
  resendEmails,
  downloadBIBFile,
  downloadCertificateFile,
  downloadRunnerCollageFile,
  downloadProgramCertificateFile,
  resendProgramCertificateEmail,
} from "./../../../../apis/eventService";
import { ArrowDropDownCircleOutlined } from "@material-ui/icons";
import Properties from "../../../../properties/Properties";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import { withRouter } from "react-router-dom";
import Snackbar from "../../../common/Snackbar";

const styles = (theme) => ({
  textTransformCss: {
    textTransform: "none",
    fontSize: "larger",
    fontWeight: "bold",
  },
  displayTitle: {
    color: "rgb(114, 114, 114)",
    fontSize: window.innerWidth > 600 ? "20px" : "16px",
  },
  displayInfo: {
    color: "rgb(40, 131, 181)",
    fontSize: window.innerWidth > 600 ? "20px" : "16px",
  },
  bottomMargin: {
    marginBottom: "15px",
  },
  thumbnailCss: { height: "180px", position: "fixed" },
  imgCss: {
    border: "1px solid #ddd",
    borderRadius: "4px",
    padding: "5px",
    width: "150px",
    height: "120px",
  },
  viewCss: {
    // color: "#3a6ea5",
    right: "2%",
    top: "2px",
    position: "absolute",
  },
  mainCss: {
    // fontFamily: "Rubik, sans-serif",
  },
});
class ThumbnailViewer extends React.Component {
  state = {
    anchorEl: null,
    dialogTitle: "",
    showImage: false,
  };
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  closeWindow = () => {
    this.setState({ showDialog: false, showImage: false });
  };
  handleResend = () => {
    const { title } = this.props;
    if (this.props.isProgram) {
      let runnerId = localStorage.getItem("supportRunnerId");
      let programDetails = ApplicationUtil.getSupportsProgramDetails();
      let programId = programDetails?.id;
      if (title === "Certificate")
        resendProgramCertificateEmail({
          programId,
          runnerId,
          emailTypeId: "CERTIFICATE_EMAIL",
        }).then((res) => this.handleClose());
    } else {
      if (title == "BIB") resendEmails("BIB_EMAIL");
      else if (title == "Certificate") resendEmails("CERTIFICATE_EMAIL");
      else if (title == "Collage") resendEmails("CERTIFICATE_EMAIL");
    }
  };
  handleDownload = () => {
    let runnerId = localStorage.getItem("supportRunnerId");
    const { title } = this.props;

    if (this.props.isProgram) {
      //for program certificate part
      let programDetails = ApplicationUtil.getSupportsProgramDetails();
      let programId = programDetails?.id;
      if (title === "Certificate")
        downloadProgramCertificateFile({ programId, runnerId }).then((res) => {
          if (res?.status === 200) {
            this.handleClose();
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Certificate Download successfully !!",
            });
          }
        });
    } else {
      //for excluding proram certificate
      let eventId = ApplicationUtil.getSupportsEventID();
      if (title == "BIB") downloadBIBFile(runnerId, eventId);
      else if (title == "Certificate")
        downloadCertificateFile(runnerId, eventId);
      else if (title == "Collage") downloadRunnerCollageFile(runnerId, eventId);
    }
  };
  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;

    const { src, title, onClick } = this.props;
    return (
      <Grid className={classes.bottomMargin} xs={3}>
        <div className={classes.thumbnailCss}>
          <img
            src={`data:image/png;base64,${src}`} //{src} //{`data:image/png;base64,${img}`}
            className={classes.imgCss}
            onClick={onClick}
            alt="img"
            // onClick={(e) =>
            //   this.setState({
            //     dialogTitle: { title },
            //     showImage: true,
            //     imagePath: `data:image/png;base64,${src}`,
            //   })
            // }
          />
          <div className={classes.displayTitle}>
            {" "}
            <span style={{ position: "absolute" }}>{title}</span>
            <span style={{ display: "flex", justifyContent: "flex-end" }}>
              <ArrowDropDownCircleOutlined onClick={this.handleClick} />
            </span>
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            className="header__user--menu"
          >
            <MenuItem onClick={this.handleDownload}>
              {Properties.DownloadLabel}
            </MenuItem>
            <MenuItem onClick={this.handleResend}>
              {Properties.ResendLabel}
            </MenuItem>
          </Menu>
          <ImageDialogBox
            title={this.state.dialogTitle}
            open={this.state.showImage}
            imagePath={this.state.imagePath}
            closeDisloag={() => this.closeWindow()}
          />
        </div>
      </Grid>
    );
  }
}

// export  ThumbnailViewer;
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ThumbnailViewer);
