import React, { useEffect, useState } from "react";
import CreateEmailReminederContent from "./CreateEmailReminederContent";
import ListingOfEmailContent from "./ListingOfEmailContent";
import { getEmailReminderContents } from "../../../../../../../../apis/programService";
import ApplicationUtil from "../../../../../../../../utils/ApplicationUtil";

const EmailContent = ({emailContentRef}) => {

  const [listOfContents, setListOfContents] = useState({
    contentList: [],
    editRecord:null
  });

  /**
   * Current Challenge Data
   */
  const currentChallenge = ApplicationUtil?.getProgramUnderCurrentChallenge();
 useEffect(() => {
  InitialLoad()
  }, []);

  const InitialLoad = ()=>{
    if (currentChallenge) {
      getEmailReminderContents(currentChallenge?.programChallengeType).then(
        (res) => {
         
          setListOfContents((prev) => ({
            ...prev,
            contentList: res?.data || [],
          }));
        }
      );
    }
  }


  let {contentList  , editRecord} = listOfContents;

  return (
    <div>
      <CreateEmailReminederContent InitialLoad={InitialLoad} editRecord={editRecord} setListOfContents={setListOfContents} />
      <div style={{ height: "1px", backgroundColor: "#9cb5c4" }}></div>
      <ListingOfEmailContent list={contentList} parentTopScrollView={emailContentRef} setListOfContents={setListOfContents} />
    </div>
  );
};

export default EmailContent;
