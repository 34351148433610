import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Properties from "./../../../properties/Properties";
import AppToolBar from "../../common/AppToolBar";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import Grid from "@material-ui/core/Grid";
import { CustomTextField } from "../../common/CustomTextField";
import { validateEmail, validatePassword } from "./../../../utils/Validator";
import SubmitCancelBtn from "../../common/SubmitCancelBtn";
import { resetPassword } from "../../../apis/passwordService";
import Header from "./../../common/Header";
import OrganizerCommonHeader from "../support/organizer/OrganizerCommonHeader";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  mainPanel: {
    paddingRight: theme.rightSpacing,
    paddingLeft: theme.leftSpacing,
    marginTop: "60px",
  },
});

class ResetpassContainer extends Component {
  state = {
    passwordError: false,
    confirmError: false,
    token: "",
  };

  componentDidMount() {
    document.title = Properties.Page_Title_ResetPassword;
    let search = this.props.location.search;
    let params = new URLSearchParams(search);

    let urlId = params.get("token");
    localStorage.setItem("token", urlId);

    this.setState({ token: urlId });
  }

  validateFields = () => {
    const {
      password = "",
      confirmPassword = "",
      passwordError = "",
      confirmError = "",
      token = "",
    } = this.state;

    if (password == "" || passwordError == true) {
      this.setState({ passwordError: true });
    } else if (
      confirmPassword == "" ||
      confirmPassword != password ||
      confirmError == true
    ) {
      this.setState({ confirmError: true });
    } else {
      this.saveFields();
    }
  };

  saveFields = () => {
    const { password, token } = this.state;
    let params = {
      token: token,
      newPassword: password,
    };

    //api
    resetPassword(params).then((res) => {
      this.props.history.push("/organizer");
    });

    //localStorage.setItem("data", JSON.stringify(data));
  };

  validatePasswordField = (key, value) => {
    let isValid = validatePassword(value);
    if (key == "password") {
      if (isValid) {
        this.setState({ password: value, passwordError: false });
      } else {
        this.setState({ passwordError: true, password: value });
      }
    } else {
      if (isValid && value == this.state.password) {
        this.setState({ confirmPassword: value, confirmError: false });
      } else {
        this.setState({ confirmError: true });
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { passwordError, confirmError } = this.state;

    return (
      <React.Fragment>
        <AppToolBar showBack={false}>
          {/* <Header signup label={Properties.Label_Resetpass} />
          <div style={{ width: 35 }} /> */}
          <Header signup label={Properties.Label_Resetpass} />
        </AppToolBar>
        <div className={classes.mainPanel}>
          <Grid
            style={{
              width: "100%",
              //   height: "75px",
              margin: "0px",
              padding: "10px",
            }}
            container
            spacing={1}
          >
            <Typography variant="body2" gutterBottom>
              <span
                style={{ fontSize: "16px", padding: "5px", color: "#727272" }}
              >
                {Properties.ResetPass_Message}
              </span>
            </Typography>
          </Grid>
          <Grid
            style={{
              width: "100%",
              height: "75px",
              maxHeight: "75px",
              margin: "0px",
              padding: "10px",
            }}
            container
            spacing={1}
          >
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>
                  {Properties.Label_NewPassword}
                </span>
                <span style={{ color: "red" }}>*</span>
              </Typography>{" "}
              <CustomTextField
                style={
                  passwordError
                    ? {
                        border: "1px solid red",
                        height: "37px",
                        minHeight: "37px",
                        maxHeight: "37px",
                        width: "100%",
                      }
                    : {
                        border: "1px solid #08456C",
                        height: "37px",
                        width: "100%",
                        minHeight: "37px",
                        maxHeight: "37px",
                      }
                }
                type={"password"}
                keyToSet={"password"}
                handleChange={(prop) => (event) => {
                  this.validatePasswordField("password", event.target.value);
                }}
              />
              <Typography
                style={{ textAlign: "right" }}
                variant="body2"
                gutterBottom
              >
                <span style={{ color: "#2883B5", fontSize: "13px" }}>
                  {Properties.Label_passwordvalidationText}
                </span>
              </Typography>
            </Grid>
          </Grid>

          <Grid
            style={{
              width: "100%",
              // height: "75px",
              margin: "0px",
              padding: "25px 10px",
            }}
            container
            spacing={1}
          >
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom>
                <span style={{ color: "#727272" }}>
                  {Properties.Label_ConfirmNewPassword}
                </span>
                <span style={{ color: "red" }}>*</span>
              </Typography>{" "}
              <CustomTextField
                style={
                  confirmError
                    ? {
                        border: "1px solid red",
                        height: "37px",
                        maxHeight: "37px",
                        minHeight: "37px",
                        width: "100%",
                      }
                    : {
                        border: "1px solid #08456C",
                        height: "37px",
                        maxHeight: "37px",
                        minHeight: "37px",
                        width: "100%",
                      }
                }
                type={"password"}
                keyToSet={"confirmPassword"}
                handleChange={(prop) => (event) => {
                  this.validatePasswordField(
                    "ConfirmPassword",
                    event.target.value
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid
            style={{
              width: "100%",
              //   height: "100%",
              marginTop: "10px",
              justifyContent: "center",
              margin: "0px",
              textAlign: "center",
            }}
            container
            spacing={1}
          >
            <Grid
              style={{
                marginTop: "5%",
                textAlign: "center",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <SubmitCancelBtn
                onClick={() => this.validateFields()}
                label={"Submit"}
                type="submit"
                style={{
                  width: "200px",
                  textAlign: "center",
                }}
              />
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

//export default ResetpassContainer;
export default compose(withStyles(styles, { withTheme: true }),withRouter)(
  ResetpassContainer
);
