import React, { useEffect, useState } from "react";
import { Grid, withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import ReactHtmlParser from "react-html-parser";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import { saveUserMatrics } from "../../../../apis/programService";

function ProductCard({ classes, item, productDetails, programChallengeId }) {
  const [expand, setExpand] = useState(false);
  const [requestedParams, setRequestedParams] = useState({
    programChallengeId: programChallengeId,
    runnerId: null,
    productDetailsId: null,
    view: 1,
    click: 0,
    buyNow: 0,
  });
  useEffect(() => {
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runner?.id;
    let productDetailsId = productDetails?.id;
    if (productDetailsId) {
      let params = {
        programChallengeId: programChallengeId,
        runnerId: runnerId,
        productDetailsId: productDetailsId,
        view: 1,
        click: 0,
        buyNow: 0,
      };
      setRequestedParams(params);
      saveUserMatrics(params);
    }
  }, [productDetails]);

  const handleExpandClick = () => {
    setExpand(!expand);
    if (!expand) {
      let updatedParam = {
        ...requestedParams,
        view: 0,
        click: 1,
        buyNow: 0,
      };
      setRequestedParams(updatedParam);
      saveUserMatrics(updatedParam);
    }
  };

  const handleBuyClick = () => {
    setExpand(false);

    let updatedParam = {
      ...requestedParams,
      view: 0,
      click: 0,
      buyNow: 1,
    };
    setRequestedParams(updatedParam);
    saveUserMatrics(updatedParam);
    window.open(productDetails?.vendorSiteLink, "_blank");
  };

  return (
    <div className={classes.mainContainer}>
      <Grid container spacing={2} className="container">
        <Grid item md={4}>
          <img src={item} alt="" width={"100%"} />
        </Grid>
        <Grid item md={8}>
          <div className="title_buyBtn_container">
            <div className="title">{productDetails?.productName}</div>
            <div className="buy_btn">
              <SubmitCancelBtn
                label="Buy"
                type="submit"
                onClick={handleBuyClick}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item md={12} className="productDescription">
        <div>
          <p className="description">
            {ReactHtmlParser(productDetails?.description)}
            {expand && (
              <span className="moreDesc">
                {ReactHtmlParser(productDetails?.advantages)}
              </span>
            )}
          </p>
        </div>
      </Grid>
      <div className="buy_btn_mobile">
        <SubmitCancelBtn
          style={{ fontWeight: "600", fontSize: "18px" }}
          label="Buy"
          type="submit"
          onClick={handleBuyClick}
        />
      </div>
      <div className="expand" onClick={handleExpandClick}>
        <span>Show {expand ? "less" : "more"}</span>{" "}
        {expand ? <ExpandLess /> : <ExpandMore />}
      </div>
    </div>
  );
}
const styles = (theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px",

    // border: "2px solid black",
    borderRadius: "10px",

    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    "& .productDescription": {
      padding: "0 10px",
    },
    "& .container": {
      padding: "10px",
    },
    "& .title_buyBtn_container": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      "& .title": {
        fontWeight: "600",
      },
      "& .buy_btn": {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
    "& .description": {
      margin: 0,
    },
    "& .expand": {
      padding: "2px",
      border: "1px solid #c8c8c8",
      borderRadius: "10px",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      borderTopRightRadius: "0",
      borderTopLeftRadius: "0",
      textAlign: "center",
      cursor: "pointer",
    },
    "& .moreDesc": {
      display: "block",
    },
    "& .buy_btn_mobile": {
      display: "none",
      justifyContent: "center",
      paddingBottom: "5px",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
      },
    },
  },
});
export default compose(withStyles(styles), withRouter)(ProductCard);
