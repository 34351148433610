import React, { Component, Fragment } from "react";
import AppToolBar from "../../common/AppToolBar";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  Divider,
  Typography,
  TextField,
  IconButton,
  TableContainer,
  TableCell,
  Box,
} from "@material-ui/core";
import Properties from "./../../../properties/Properties";
import MultiSelect from "./../../common/MultiSelect";
import Grid from "@material-ui/core/Grid";
import PublishIcon from "@material-ui/icons/Publish";
import { CustomTextField } from "../../common/CustomTextField";
import SubmitCancelBtn from "../../common/SubmitCancelBtn";
import logo from "../login/ndLogo.png";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import ApplicationUtil from "../../../utils/ApplicationUtil";
import { withRouter } from "react-router-dom";
import ListPageLoader from "../../common/ListPageLoader";
import {
  FilteilterSVGIcon,
  SortSVGIcon,
  ActiveFilterSVGIcon,
  FilterSVGIcon,
} from "../../common/SvgIcon";
import SortDrawer from "../../common/SortDrawer";
import FilterDrawer from "../../common/FilterDrawer";
import {
  getParticipantsList,
  getSupportList,
  updateActivity,
  downloadImage,
  getPostRunImage,
} from "../../../apis/eventService";
import SupportUtil from "../support/SupportUtil";

import ImageSlider from "../../common/ImageSlider";
import DialogBox from "../../common/DialogBox";
import { format } from "date-fns";
import Snackbar from "../../common/Snackbar";
const styles = (theme) => ({
  mainPanel: {
    paddingRight: theme.rightSpacing,
    paddingLeft: theme.leftSpacing,
  },
  head: {
    backgroundColor: "gray",
  },
  header: {
    color: theme.palette.common.white,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  boxAlignCss: {
    alignItems: "center",
    paddingRight: "0px",
  },
  tableBody: {
    backgroundColor: "#e0ebeb",
    // overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
});

class SupportListContainer extends Component {
  state = {
    userName: localStorage.getItem("supportUserName"),
    eventName: "",
    distanceError: false,
    sortingDrawer: false,
    filterDrawer: false,
    imageDialog: false,
    filterApplied: false,
    totalRecords: 0,
    appliedSort: { sortColumn: "runnerName", sortDirection: "ASC" },
    appliedFilter: { sortColumn: "PENDING" },
    mannualOptionList: [
      { label: "Yes", value: "YES" },
      { label: "No", value: "No" },
    ],
    mannualOption: { label: "Yes", value: "YES" },
    mannualOptionError: false,
    data: [],
    imageUrl: "",
    screenshots: [],
    participantList: [],
  };

  componentDidMount() {
    let eventId = localStorage.getItem("supportEventId");
    let runnerId = localStorage.getItem("supportRunnerId");
    getParticipantsList().then((res) => {
      res.data.map((r) => ((r.label = r.runner.name), (r.value = r.runner.id)));
      this.setState({ participantList: res.data });
    });

    let eventName = localStorage.getItem("supportEventName");
    if (eventName) {
      this.setState({ eventName: eventName });
    }
    document.title = Properties.Page_Title_Event_Support_list;
    if (!ApplicationUtil.isuserLoggedIn("support")) {
      this.props.history.push("/support");
    } else {
      let filterData = JSON.parse(localStorage.getItem("filterData"));
      //let sortData = JSON.parse(localStorage.getItem("sortData"));
      let customeParams;
      if (filterData && filterData.rec) {
        this.setState(
          {
            appliedFilter: filterData.rec
              ? filterData.rec
              : { sortColumn: "PENDING" },
            filterApplied: true,
          },
          () => {
            this.getList(true);
          }
        );
      } else {
        customeParams = {
          filterString: "PENDING",
          pageCount: 50,
          pageNumber: 0,
          sortKey: "runnerName",
          sortOrder: true,
        };
        getSupportList(customeParams, runnerId, eventId).then((res) => {
          this.setState({
            data: res.data.data,
            totalRecords: res.data.totalRecords,
          });
        });
      }
    }
  }

  getDialogComponent = () => {
    return (
      <div>
        <ImageSlider files={this.state.screenshots} />
      </div>
    );
  };

  closeWindow = () => {
    this.setState({ open: false });
  };

  getList = (isFristPage, page) => {
    let eventId = localStorage.getItem("supportEventId");
    let runnerId = localStorage.getItem("supportRunnerId");
    const { appliedFilter, appliedSort } = this.state;
    let customeParams = {
      filterString:
        appliedFilter.sortColumn == "BIB" ||
        appliedFilter.sortColumn == "PARTICIPANTS"
          ? "ALL"
          : appliedFilter.sortColumn,
      pageCount: 50,
      pageNumber: page ? page : 0,
      sortKey: appliedSort.sortColumn,
      sortOrder: appliedSort.sortDirection === "ASC" ? true : false,
    };
    if (appliedFilter.BIBValue) {
      customeParams.bib = appliedFilter.BIBValue;
    }
    if (appliedFilter.participantValue) {
      customeParams.participantIds = appliedFilter.participantValue;
    }
    getSupportList(customeParams, runnerId, eventId).then((res) => {
      let list = page ? this.state.data.concat(res.data.data) : res.data.data;
      this.setState({ data: list, totalRecords: res.data.totalRecords });
    });
  };

  handleMannualOptionChange = (row, selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        mannualOption: selectedValues,
        mannualOptionError: false,
      });
    } else {
      this.setState({
        mannualOption: selectedValues,
        mannualOptionError: true,
      });
    }
    row.selectedMannual = selectedValues;
  };

  removeFilter = () => {
    localStorage.removeItem("filterData");
    this.setState(
      {
        appliedFilter: { sortColumn: "PENDING" },
        filterDrawer: true,
        filterApplied: false,
      },
      () => {
        this.getList(true);
      }
    );
  };

  saveFields = (row) => {
    let requestParams = {};
    requestParams.activityId = row.id;
    // requestParams.id = row.id;
    if (row.distance && row.distance != "" && row.distance != undefined) {
      requestParams.distance = row.distance;
      requestParams.completionTime = row.completionTime;
      requestParams.activityUrl = row.activityUrl;
      requestParams.manual = row.selectedMannual
        ? row.selectedMannual.value == "No"
          ? false
          : true
        : row.manual
        ? true
        : false;
      requestParams.type = "Run";
      requestParams.approveRequest = true;
      updateActivity(requestParams, row.id).then((res) => {
        this.getList(true);
      });
    } else {
      row.distanceError = true;
      this.setState({ distanceError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please Enter distance",
      });
    }
  };
  handleSortDrawer = (flag) => {
    this.setState({ sortingDrawer: flag });
  };

  handleFilterDrawer = (flag) => {
    this.setState({ filterDrawer: flag });
  };

  viewImage = (id) => {
    let eventId = localStorage.getItem("supportEventId");
    downloadImage(id, eventId).then((res) => {
      this.setState({
        open: true,
        screenshots: this.getImageList(res),
      });
    });
  };

  viewPostRunPicture = (id) => {
    let eventId = localStorage.getItem("supportEventId");
    getPostRunImage(id, eventId).then((res) => {
      if (res.size === 0) {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "No Image Available",
        });
      } else {
        this.setState({
          open: true,
          screenshots: res,
        });
      }
    });
  };
  render() {
    const { classes } = this.props;

    const {
      distanceError,
      mannualOption,
      mannualOptionError,
      mannualOptionList,
      sortingDrawer,
      filterDrawer,
      eventName,
      participantList,
    } = this.state;
    return (
      <React.Fragment>
        <div style={{ width: "100%" }}>
          <Box display="flex" p={1} bgcolor="background.paper">
            <Box p={1} flexGrow={1}>
              <div style={{ width: "auto" }}>
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    height: "50px",
                    width: "50px",
                  }}
                />
              </div>
            </Box>

            <Box
              p={2}
              display="flex"
              classes={{
                root: classes.boxAlignCss,
              }}
              justifyContent="flex-end"
            >
              <span
                onClick={(e) => {
                  localStorage.clear();
                  ApplicationUtil.go("/support");
                }}
                style={{
                  color: "#F79824",
                  cursor: "pointer",
                }}
              >
                {this.state.userName}
              </span>
            </Box>
            {/* <Box p={2} display="flex" justifyContent="flex-end">
              <span
                style={{
                  color: "#2883B5"
                }}
              >
                <MenuIcon></MenuIcon>
              </span>
            </Box> */}
          </Box>
          <Divider style={{ height: "5px", backgroundColor: "#08456C" }} />
        </div>

        <div
          style={{
            height: "35px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ width: "90%" }}>
            <span
              style={{
                display: "flex",
                justifyContent: "flex-start",
                margin: "0 1%",
                cursor: "pointer",
                color: "blue",
              }}
              onClick={(e) => { localStorage.removeItem("filterData");ApplicationUtil.goBack() }}
            >
              {eventName}
            </span>
          </div>
          <div style={{ display: "flex" }}>
            <div>
              {!this.state.filterApplied && (
                <FilterSVGIcon
                  htmlColor={"#F79824"}
                  onClick={(e) => this.setState({ filterDrawer: true })}
                  style={{
                    height: "19px",
                    width: "19px",
                  }}
                />
              )}
              {this.state.filterApplied && (
                <ActiveFilterSVGIcon
                  htmlColor={"#F79824"}
                  onClick={(e) => this.removeFilter()}
                  style={{
                    height: "19px",
                    width: "19px",
                  }}
                />
              )}
            </div>
            <div style={{ marginLeft: "50%" }}>
              <SortSVGIcon
                onClick={(e) => this.setState({ sortingDrawer: true })}
                style={{
                  height: "19px",
                  width: "19px",
                  color: "#F79824",
                }}
              />
            </div>
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table aria-label="customized table" style={{ overflowX: "scroll" }}>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell
                  style={{ width: "10%", textAlign: "center" }}
                  className={classes.header}
                >
                  {Properties.Name_label}
                </TableCell>
                <TableCell
                  style={{ width: "5%", textAlign: "center" }}
                  className={classes.header}
                >
                  {Properties.BIB_label}
                </TableCell>
                <TableCell
                  style={{ width: "10%", textAlign: "center" }}
                  className={classes.header}
                >
                  {Properties.Category_label}
                </TableCell>

                <TableCell
                  style={{ width: "10%", textAlign: "center" }}
                  className={classes.header}
                >
                  {Properties.Distance_label}
                </TableCell>

                <TableCell
                  style={{ width: "10%", textAlign: "center" }}
                  className={classes.header}
                >
                  {Properties.CompletionTime_label}
                </TableCell>

                <TableCell
                  style={{ width: "10%", textAlign: "center" }}
                  className={classes.header}
                >
                  {"Activity Date"}
                </TableCell>
                 <TableCell
                  style={{ width: "6%", textAlign: "center" }}
                  className={classes.header}
                >
                  {"Type"}
                </TableCell>
                <TableCell
                  style={{ width: "8%", textAlign: "center" }}
                  className={classes.header}
                >
                  {Properties.Activity_label}
                </TableCell>

                <TableCell
                  style={{ width: "8%", textAlign: "center" }}
                  className={classes.header}
                >
                  {Properties.Screenshot_label}
                </TableCell>

                <TableCell
                  style={{ width: "10%", textAlign: "center" }}
                  className={classes.header}
                >
                  {Properties.Label_PostRunPicture}
                </TableCell>

                <TableCell
                  style={{ width: "10%", textAlign: "center" }}
                  className={classes.header}
                >
                  {Properties.Consent_label}
                </TableCell>

                <TableCell
                  style={{ width: "15%", textAlign: "center" }}
                  className={classes.header}
                >
                  {Properties.Mannual_label}
                </TableCell>

                <TableCell
                  style={{ width: "10%", textAlign: "center" }}
                ></TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>

        <ListPageLoader
          style={{ width: "100%" }}
          pageParams={{
            page: 0,
            start: 0,
            limit: 20,
            TotalRecords: this.state.totalRecords,
          }}
          totalLoadedLength={this.state.data ? this.state.data.length : 0}
          // divHeight={window.innerHeight - 190}

          loadNextData={(params) => {
            this.getList(false, params.page);
          }}
        >
          <TableContainer className={classes.tableBody} component={Paper}>
            <Table aria-label="customized table">
              {this.state.data && this.state.data.length > 0 ? (
                this.state.data.map((row, index) => (
                  <TableBody>
                    <TableRow key={index}>
                      <TableCell
                        onClick={(e) => {
                          SupportUtil.setUserRow(row);

                          localStorage.setItem("runnerName", row.runner.name);

                          localStorage.setItem(
                            "activityTypeId",
                            row.eventRunCategory.activityType.id
                          );
                          localStorage.setItem(
                            "activityList",
                            row.activityList
                          );
                          localStorage.setItem(
                            "type",
                            row.eventRunCategory.activityType.type == "RUN"
                              ? "RUN"
                              : "CYCLE"
                          );

                          let panelUrl = ApplicationUtil.getMatchUrl();
                          ApplicationUtil.go(panelUrl + "/form");
                        }}
                        style={{
                          width: "10%",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {row.runner.name}
                      </TableCell>
                      <TableCell style={{ width: "5%", textAlign: "center" }}>
                        {row.bibNumber}
                      </TableCell>
                      <TableCell style={{ width: "10%", textAlign: "center" }}>
                        {row.eventRunCategory.category}
                      </TableCell>
                      <TableCell style={{ width: "9%", textAlign: "center" }}>
                        <CustomTextField
                          style={
                            row.distanceError
                              ? {
                                  border: "1px solid red",
                                  height: "30px",
                                  maxHeight: "30%",
                                  minHeight: "30%",
                                  width: "100%",
                                }
                              : {
                                  border: "1px solid #08456C",
                                  height: "30px",
                                  maxHeight: "30%",
                                  minHeight: "30%",
                                  width: "100%",
                                }
                          }
                          defaultValue={row.distance}
                          type={"text"}
                          // defaultValue={row.distance}
                          keyToSet={"distance"}
                          handleChange={(prop) => (event) => {
                            row.distance = event.target.value;
                            row.distanceError = false;
                            this.setState({ distanceError: false });
                          }}
                        />
                      </TableCell>
                      <TableCell style={{ width: "10%", textAlign: "center" }}>
                        {row.completionTime}
                      </TableCell>
                      <TableCell style={{ width: "10%", textAlign: "center" }}>
                        {row.startDateLocal ? ApplicationUtil.changeDateFormat(row.startDateLocal):""}
                      </TableCell>
                      <TableCell style={{ width: "6%", textAlign: "center" }}>
                        {row.eventRunCategory.activityType.type}
                      </TableCell>

                      {row.activityUrl ? (
                        <TableCell
                          style={{ width: "8%", textAlign: "center" }}
                        >
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(row.activityUrl); //sample testing
                            }}
                            style={{ color: "#F79824", cursor: "pointer" }}
                          >
                            {Properties.Label_URL}
                          </span>
                        </TableCell>
                      ) : (
                        <TableCell
                          style={{ width: "8%", textAlign: "center" }}
                        ></TableCell>
                      )}

                      {/* {row.screenshots ? ( */}
                      {row.isActivityScreenshotsAvailable ? (
                        <TableCell
                          style={{ width: "8%", textAlign: "center" }}
                        >
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.viewImage(row.runner.id);
                              // this.setState({ imageDialog: true });
                            }}
                            style={{ color: "#F79824", cursor: "pointer" }}
                          >
                            {/* {row.screenshotFileName} */}
                            {Properties.Label_View}
                          </span>
                        </TableCell>
                      ) : (
                        <TableCell
                          style={{ width: "8%", textAlign: "center" }}
                        ></TableCell>
                      )}

                      {/* postRunPicture */}
                      {row.isActivityScreenshotsAvailable ? (
                        <TableCell
                          style={{ width: "10%", textAlign: "center" }}
                        >
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              this.viewPostRunPicture(row.runner.id);
                              // this.setState({ imageDialog: true });
                            }}
                            style={{ color: "#F79824", cursor: "pointer" }}
                          >
                            {/* {row.screenshotFileName} */}
                            {Properties.Label_View}
                          </span>
                        </TableCell>
                      ) : (
                        <TableCell
                          style={{ width: "10%", textAlign: "center" }}
                        ></TableCell>
                      )}

                      <TableCell
                        onClick={(e) => e.stopPropagation()}
                        style={{ width: "8%", textAlign: "center" }}
                      >
                        <MultiSelect
                          value={
                            row.runner.isAuthorized
                              ? { label: "Yes", value: "Yes" }
                              : { label: "No", value: "No" }
                          }
                          isDisabled={true}
                        />
                      </TableCell>
                      <TableCell
                        onClick={(e) => e.stopPropagation()}
                        style={{ width: "10%", textAlign: "center" }}
                      >
                        <MultiSelect
                          value={
                            row.selectedMannual
                              ? row.selectedMannual
                              : row.manual
                              ? { label: "Yes", value: "Yes" }
                              : { label: "No", value: "No" }
                          }
                          //not done
                          error={mannualOptionError}
                          options={this.state.mannualOptionList}
                          onChange={(e) =>
                            this.handleMannualOptionChange(row, e)
                          }
                        />
                      </TableCell>
                      {row.status == "PENDING" ? (
                        <TableCell
                          style={{ width: "10%", textAlign: "center" }}
                        >
                          <SubmitCancelBtn
                            onClick={() => this.saveFields(row)}
                            label={"Approve"}
                            type="submit"
                          />
                        </TableCell>
                      ) : (
                        <TableCell
                          style={{ width: "auto", textAlign: "center" }}
                        ></TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                ))
              ) : (
                <div style={{ padding: "15px 8px 15px 8px" }}>
                  {"No records to display"}
                </div>
              )}
            </Table>
          </TableContainer>
        </ListPageLoader>

        <SortDrawer
          columnList={ApplicationUtil.getSupportListFixColumns()}
          onClose={() => this.handleSortDrawer(false)}
          isOpen={sortingDrawer}
          sortingLabel={"Sort By"}
          handleApplySort={(rec) => {
            localStorage.setItem("sortData", JSON.stringify({ rec }));
            this.setState({ appliedSort: rec }, () => {
              this.getList(true);
            });
          }}
          selectedSortColumn={
            this.state.appliedSort
              ? this.state.appliedSort
              : {
                  sortColumn: "runnerName",
                  sortDirection: "ASC",
                }
          }
        />
        <FilterDrawer
          columnList={ApplicationUtil.getSupportFilterColumns()}
          participantList={participantList}
          onClose={() => this.handleFilterDrawer(false)}
          isOpen={filterDrawer}
          sortingLabel={"Sort By"}
          handleApplySort={(rec) => {
            localStorage.setItem("filterData", JSON.stringify({ rec }));
            this.setState({ appliedFilter: rec, filterApplied: true }, () => {
              this.getList(true);
            });
          }}
          selectedSortColumn={
            this.state.appliedFilter
              ? this.state.appliedFilter
              : {
                  sortColumn: "PENDING",
                }
          }
        />

        <DialogBox
          open={this.state.open}
          message={this.getDialogComponent()}
          onClick={() => this.closeWindow()}
          closeDisloag={() => this.closeWindow()}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(SupportListContainer);
