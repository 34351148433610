import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";

import { Doughnut, HorizontalBar } from "@reactchartjs/react-chart.js";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  summaryCharts: {
    // height:"100vh"
    marginBottom: "20px",
  },
  chartsGrid: {
    padding: "5px",
    // height: "100%",
  },
  doughnutChart: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    height: "100%",
    "& h1": {
      borderBottom: "2px solid gray",
    },
    "& .bigDoughnut": {
      width: "80%",
    },
    "& .smallDoughnuts": {
      display: "flex",
      // alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "space-between",
      marginBottom: "10px",

      // flexDirection: "column",
      "& > div": {
        // border: "1px solid black",
        width: "40%",
        display: "flex",
        flexDirection: "column",

        // margin: "auto",
      },
    },
  },
  resultsChart: {
    padding: "10px",
    height: "100%",
    "& h1": {
      borderBottom: "2px solid gray",
    },
  },
  resultsDoughnut: {
    marginTop: "20px",
    "& > div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      // justifyContent: "center",
      width: "80%",
      marginBottom: "10px",
      // margin: "auto",
    },
  },
  counts: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "space-around",
    padding: "10px",
    "& span:nth-child(2)": {
      //   marginLeft: "20px",
      flex: 0.6,
      textAlign: "left",
      fontSize: "40px",
    },
    "& span:nth-child(1)": {
      flex: 0.4,

      fontWeight: 600,
      fontSize: "18px",
    },
  },
});

//doughnut charts colors
let dynamicColors = function () {
  return (
    "hsl(" +
    360 * Math.random() +
    "," +
    (25 + 70 * Math.random()) +
    "%," +
    (65 + 10 * Math.random()) +
    "%)"
  );
};

class SummaryCharts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      participants: true,
      results: true,
      resData: this.props.data,
      activityDetails: [],
      activityChartData: {},
      categoriesChartData: [],
      activityStatChartData: {},
      dataServiceStatChartData: {},
      participantsTotalCounts: [],
      resultsTotalCounts: [],
      doughnutOptions: {
        legend: {
          display: true,
          position: "right",
          labels: {
            fontSize: 18,
          },
        },
        hover: {
          animationDuration: 0,
        },
        animation: {
          duration: 0,
          onComplete: function (item) {
            var chartInstance = item.chart,
              ctx = chartInstance.ctx;

            let sum = 0;
            let x, y;
            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                sum += dataset.data[index];
                x = bar._model.x;
                y = bar._model.y;
              });
              ctx.textAlign = "center";
              ctx.fillText("Total: " + sum, x, y);
            });
          },
        },
      },
      barOptions: {
        legend: {
          display: false,
        },
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
        scales: {
          yAxes: [
            {
              barPercentage: 0.45,
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
              },
            },
          ],
          xAxes: [
            {
              barPercentage: 0.45,
              ticks: {
                beginAtZero: true,
                userCallback: function (label, index, labels) {
                  // when the floored value is the same as the value we have a whole number
                  if (Math.floor(label) === label) {
                    return label;
                  }
                },
              },
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
              },
            },
          ],
        },
      },
    };
  }

  componentDidMount() {
    let getActivityDetails = [];
    let bigChartData = {};
    let smallChartsData = [];
    let eventId = ApplicationUtil.getSupportsEventID();
    let requestparam = "REGISTER_EVENT";
    let activityType = {};
    let categoriesArr = [];
    let registrationsArr = [];
    let totalRegistrationsCount = 0;
    let activityStatLabel = [];
    let activityStatData = [];
    let getActivityStatChartData = {};
    let statColor = [];

    // getEventDetails(requestparam, eventId).then((res) => {
    const data = this.state.resData;
    let activities = data.activities;
    let eventStatistics = data?.eventStatistics;
    let categoryWiseCount = eventStatistics?.categoryWiseCount;
    let activityStatistics = eventStatistics?.activityStatistics;
    let dataServiceActivityStatistics =
      eventStatistics?.dataServiceActivityStatistics;
    //mapping and getting particular activityType Id's details in object
    //and then pushing it into array
    for (let i = 0; i < activities?.length; i++) {
      for (let j = 0; j < categoryWiseCount?.length; j++) {
        if (
          categoryWiseCount[j].activityTypeId ===
          activities[i].eventSupportedActivityTypeId
        ) {
          totalRegistrationsCount += categoryWiseCount[j].totalRegistrations;
          categoriesArr.push(categoryWiseCount[j].displayName);
          registrationsArr.push(categoryWiseCount[j].totalRegistrations);
          activityType.id = categoryWiseCount[j].activityTypeId;
          activityType.type = activities[i].type;
          activityType.label = activities[i].displayName;
          activityType.ticketsSoldSum = totalRegistrationsCount;
          activityType.category = categoriesArr;
          activityType.tickets = registrationsArr;
          activityType.color = dynamicColors();
        }
      }
      getActivityDetails.push(activityType);
      totalRegistrationsCount = 0;
      categoriesArr = [];
      registrationsArr = [];
      activityType = {};
      for (let k = 0; k < activityStatistics?.length; k++) {
        if (
          activityStatistics[k].activityTypeId ===
          activities[i].eventSupportedActivityTypeId
        ) {
          activityStatLabel.push(activities[i].displayName);
          activityStatData.push(
            activityStatistics[k].totalRunnersWithActivities
          );
          statColor.push(dynamicColors());
        }
      }
    }

    if (getActivityDetails?.length > 0) {
      let labels = [];
      let data = [];
      let color = [];

      getActivityDetails.map((detail, index) => {
        if (detail) {
          if (
            detail.label !== undefined &&
            detail.ticketsSoldSum !== undefined
          ) {
            labels.push(detail.label);
            color.push(detail.color);

            data.push(detail.ticketsSoldSum);

            smallChartsData.push({
              labels: detail.category,
              datasets: [
                {
                  label: detail?.label,
                  data: detail?.tickets,

                  backgroundColor: [detail.color],
                  // borderColor: "#000",
                  hoverBackgroundColor: [detail.color],
                  hoverBorderWidth: 1,
                  borderWidth: 3,
                },
              ],
            });
          }
        }
      });

      if (labels.length > 0) {
        bigChartData.labels = labels;
        bigChartData.datasets = [
          {
            label: "Participants",
            data: data,
            backgroundColor: color,
            // borderColor: "#000",
            hoverBackgroundColor: color,
            hoverBorderWidth: 1,
            borderWidth: 3,
          },
        ];
      }
      this.setState({
        activityDetails: getActivityDetails,
        activityChartData: bigChartData,
        categoriesChartData: smallChartsData,
        participantDetails: labels.length > 0 ? true : false,
      });
    }
    if (activityStatLabel.length > 0 && activityStatData.length > 0) {
      let resultsAvailable = activityStatData.every((x) => x > 0);
      getActivityStatChartData.labels = activityStatLabel;
      getActivityStatChartData.datasets = [
        {
          label: "Participants",
          data: activityStatData,

          backgroundColor: statColor,
          // borderColor: "#000",
          hoverBackgroundColor: statColor,
          hoverBorderWidth: 1,
          borderWidth: 3,
        },
      ];
      this.setState({
        activityStatChartData: getActivityStatChartData,
        resultsAvailable: resultsAvailable,
      });
    }

    if (dataServiceActivityStatistics) {
      let dataServiceStatLabel = [];
      let dataServiceStatData = [];
      let getDataServiceStatChartData = {};

      let color = [];
      let results = dataServiceActivityStatistics.some(
        (x) => x.totalRunnersHaveActivities > 0
      );
      dataServiceActivityStatistics.map((stat) => {
        color.push(dynamicColors());

        dataServiceStatLabel.push(stat.serviceName);
        dataServiceStatData.push(stat.totalRunnersHaveActivities); // totalConsent
      });

      if (dataServiceStatData.length > 0) {
        getDataServiceStatChartData.labels = dataServiceStatLabel;
        getDataServiceStatChartData.datasets = [
          {
            label: "Participants",
            data: dataServiceStatData,

            backgroundColor: color,
            hoverBackgroundColor: color,
            hoverBorderWidth: 1,
            borderWidth: 3,
          },
        ];
      }

      this.setState({
        dataServiceStatChartData: getDataServiceStatChartData,
        resultConsentDetails: results,
      });
    }
    this.setState({
      participantsTotalCounts: [
        {
          title: "BIB Sent",
          count: eventStatistics?.totalBibSent,
        },
        {
          title: "Online Registrations",
          count: eventStatistics?.totalOnlineRegistrations,
        },
        {
          title: "Offline Registrations",
          count: eventStatistics?.totalOfflineRegistrations,
        },
      ],
      resultsTotalCounts: [
        {
          title: "Certificate Generated",
          count: eventStatistics?.totalCertificateSent,
        },
        {
          title: "Collage Generated",
          count: eventStatistics?.totalCollageSent,
        },
        {
          title: "Video Generated",
          count: eventStatistics?.totalVideoLinkSent,
        },
      ],
    });
    // });
  }
  render() {
    const { classes } = this.props;
    const {
      participants,
      activityChartData,
      participantDetails,
      activityDetails,
      results,
      categoriesChartData,
      activityStatChartData,
      resultsAvailable,
      dataServiceStatChartData,
      resultConsentDetails,
      doughnutOptions,
      barOptions,
      participantsTotalCounts,
      resultsTotalCounts,
    } = this.state;

    return (
      <Grid container className={classes.summaryCharts}>
        {participants && (
          <Grid item xs={6} className={classes.chartsGrid}>
            <Paper variant="outlined" className={classes.doughnutChart}>
              <h1>{"Participants"}</h1>
              <div className="bigDoughnut">
                <h2>Registration Details</h2>

                {activityChartData && doughnutOptions && participantDetails ? (
                  <Doughnut
                    data={activityChartData}
                    options={doughnutOptions}
                  />
                ) : (
                  <div>No participants for event.</div>
                )}
              </div>
              <div className="smallDoughnuts">
                {categoriesChartData?.map((category) => (
                  <div>
                    <h2>{category?.datasets[0]?.label}</h2>
                    <HorizontalBar data={category} options={barOptions} />
                  </div>
                ))}
              </div>
              <div className={classes.participantsCounts}>
                {participantsTotalCounts.map((count, index) => (
                  <div className={classes.counts}>
                    <span>
                      {count.title}
                      {" :"}
                    </span>
                    <span>{count.count}</span>
                  </div>
                ))}
              </div>
            </Paper>
          </Grid>
        )}
        {results && (
          <Grid item xs={6} className={classes.chartsGrid}>
            <Paper variant="outlined" className={classes.resultsChart}>
              <h1>Results</h1>
              <div className={classes.resultsDoughnut}>
                <div className="resultsDoughnut__activity">
                  <h2>Activities Received by Participants</h2>

                  {activityStatChartData && resultsAvailable ? (
                    <Doughnut
                      data={activityStatChartData}
                      options={doughnutOptions}
                    />
                  ) : (
                    <div>No activities for event.</div>
                  )}
                </div>
                <div className="resultsDoughnut__dataService">
                  <h2>Consent</h2>
                  {dataServiceStatChartData && resultConsentDetails ? (
                    <Doughnut
                      data={dataServiceStatChartData}
                      options={doughnutOptions}
                    />
                  ) : (
                    <div>No consent available for event.</div>
                  )}
                </div>
              </div>
              <div className={classes.resultsCounts}>
                {resultsTotalCounts.map((count, index) => (
                  <div className={classes.counts}>
                    <span>
                      {count.title}
                      {" :"}
                    </span>
                    <span>{count.count}</span>
                  </div>
                ))}
              </div>
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(SummaryCharts);
