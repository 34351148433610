import React, { Component } from "react";
import {
  Paper,
  Tab,
  Tabs,
  Grid,
  Typography,
  TableRow,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
} from "@material-ui/core";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  resendEmails,
  displayBIBFile,
  getParticipantsDetails,
  getTrainingPlan,
  getProgramChallengeParticipantDetails,
} from "../../../../apis/eventService";
import Properties from "../../../../properties/Properties";
import ImageDialogBox from "../../../common/ImageDialogBox";
import ApplicationUtil from "./../../../../utils/ApplicationUtil";
import ThumbnailViewer from "./ThumbnailViewer";
import { isMobile } from "react-device-detect";
import Clamp from "react-multiline-clamp";

const styles = (theme) => ({
  mainBody: {
    marginTop: "0px",
  },
  textTransformCss: {
    textTransform: "none",
    fontSize: "22px",
    fontWeight: "bold",
  },
  displayTitle: {
    color: "rgb(114, 114, 114)",
    fontSize: isMobile ? "16px" : "20px",
  },
  displayInfo: {
    color: "rgb(40, 131, 181)",
    fontSize: isMobile ? "20px" : "24px",
  },
  runnerNameCss: {
    color: "rgb(40, 131, 181)",
    fontWeight: "600",
    fontSize: "34px",
  },
  bottomMargin: {
    marginBottom: "15px",
  },
  tableHead: {
    backgroundColor: "#ced4da",
    "& th": {
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
    },
  },
  tableRow: {
    cursor: "pointer",
    "& td": {
      paddingLeft: "16px",
    },
  },
  dateCss: {
    fontWeight: 600,
  },
});
class ParticipantRegistrationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerLinkSelected: localStorage.getItem("headerLinkSelected"),
      isProgramChallenge: !!localStorage.getItem("isProgramChallenge"),
      userName: localStorage.getItem("supportUserName"),
      programChallengeParticipantData: null,
      ActiveTabIndex: 0,
      activeTabTitle: Properties.PersonalDataLabel,
      enableTraining: false,
    };
  }
  componentDidMount() {
    document.title = Properties.Label_ParticipantDetails;
    if (
      this.state.headerLinkSelected === "Program" &&
      this.state.isProgramChallenge
    ) {
      // vishal - to fetching the program challenge participant details
      let _supportRunnerId = localStorage.getItem("supportRunnerId");
      getProgramChallengeParticipantDetails().then((res) => {
        let _data = res?.data?.programChallengeParticipantDTOs;
        if (_data) {
          let _details = _data?.find(
            (obj) => obj?.runnerId == _supportRunnerId
          );
          this.setState({
            programChallengeParticipantData: _details ?? null,
          });
        }
      });
    } else {
      //prev code in the else block
      if (!ApplicationUtil.isuserLoggedIn("organizer")) {
        this.props.history.push("/organizer");
      } else {
        this.loadDetails().then((res) => {
          this.setState({
            data: res.participantData,
            enableTraining: res.participantData?.event?.enableTraining,
            trainingData: res?.trainingData,
          });

          if (res.participantData?.bibGenerated) {
            this.downloadBIB();
          }
        });
      }
    }
  }

  loadDetails = async () => {
    let runnerId = localStorage.getItem("supportRunnerId");
    let eventId = ApplicationUtil.getSupportsEventID();
    let token = ApplicationUtil.getSupportToken();
    let trainingData = null;
    let participantData = await getParticipantsDetails(runnerId, eventId).then(
      (res) => {
        return res.data;
      }
    );
    if (participantData.event?.enableTraining) {
      trainingData = await getTrainingPlan(token, eventId, runnerId).then(
        (trainingData) => {
          return trainingData.data;
        }
      );
    }

    return { participantData: participantData, trainingData: trainingData };
  };
  sendWelcomeEmail() {
    resendEmails("WELCOME_EMAIL");
  }
  sendConsentEmail() {
    resendEmails("CONSENT_EMAIL");
  }
  sendBIBEmail() {
    resendEmails("BIB_EMAIL");
  }
  downloadBIB = () => {
    let runnerId = localStorage.getItem("supportRunnerId");
    displayBIBFile(runnerId).then((res) => {
      this.setState({ bibFile: res.imageData });
    });
  };

  showPersonalData = () => {
    const { classes } = this.props;
    const { data } = this.state;

    return (
      <>
        <Grid container>
          <Grid item xs={6} className={classes.bottomMargin}>
            <Typography className={classes.displayTitle}>
              {Properties.Name_label}
            </Typography>
            <Typography className={classes.displayInfo}>
              {data.runner.name}
            </Typography>
          </Grid>
          {data.runner.age > 0 && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_age}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.runner.age}
              </Typography>
            </Grid>
          )}
          {data.runner.gender && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_Gender}
              </Typography>
              <Typography className={classes.displayInfo}>
                {ApplicationUtil.getCamelCasing(data.runner.gender)}
              </Typography>
            </Grid>
          )}
          {data.runner.email && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_Email}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.runner.email}
              </Typography>
            </Grid>
          )}
          {data.runner.city && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_City}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.runner.city}
              </Typography>
            </Grid>
          )}
          {data.runner.runnerAddress && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.address_label}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.runner.runnerAddress}
              </Typography>
            </Grid>
          )}
          {data.runner.country && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_Country}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.runner.country}
              </Typography>
            </Grid>
          )}
          {data.bibNumber && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.BIB_label}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.bibNumber}
              </Typography>
            </Grid>
          )}
          {data.runner.contactNumber && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_ContatNumber}
              </Typography>
              <Typography className={classes.displayInfo}>
                {}
                {data.runner.countryCode
                  ? "+91"
                  : "" + data.runner.countryCode
                  ? data.runner.countryCode
                  : ""}{" "}
                {data.runner.contactNumber}
              </Typography>
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  showTeamData = () => {
    const { classes } = this.props;
    const { data } = this.state;

    return (
      <>
        <Grid container>
          {data.teamDetailsDTO.teamName && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.TeamNameLabel}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.teamDetailsDTO.teamName}
              </Typography>
            </Grid>
          )}
          {data.teamDetailsDTO.teamLeader && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.TeamLeaderName}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.teamDetailsDTO.teamLeader}
              </Typography>
            </Grid>
          )}
          {data.teamDetailsDTO.teamMemberActivities && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.TeamMembersLabel}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.teamDetailsDTO.teamMemberActivities &&
                  data.teamDetailsDTO.teamMemberActivities.map((ele) => (
                    <div>{ele.firstName + " " + ele.lastName}</div>
                  ))}
              </Typography>
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  showEventRegistrationData = () => {
    const { classes } = this.props;
    const { data } = this.state;
    const details = data.eventRunCategories[0];
    let additionalDetails = null;

    if (data.fields && data.fields.customFields) {
      additionalDetails = data.fields.customFields;
    }
    return (
      <>
        <Grid container>
          <Grid item xs={6} className={classes.bottomMargin}>
            <Typography className={classes.displayTitle}>
              {Properties.RegistrationTypeLabel}
            </Typography>
            <Typography className={classes.displayInfo}>
              {data.imported ? Properties.OfflineLabel : Properties.OnlineLabel}
            </Typography>
          </Grid>
          {data.registrationDate && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.RegistrationDateLabel}
              </Typography>
              <Typography className={classes.displayInfo}>
                {ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                  data.registrationDate
                )}
              </Typography>
            </Grid>
          )}
          {details.activityType && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.ActivityType_label}
              </Typography>
              <Typography className={classes.displayInfo}>
                {details.activityType.displayName}
              </Typography>
            </Grid>
          )}
          {details.categoryName && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Category_label}
              </Typography>
              <Typography className={classes.displayInfo}>
                {details.categoryName}
              </Typography>
            </Grid>
          )}
          {additionalDetails && additionalDetails.length > 0 && (
            <Grid item xs={6} className={classes.bottomMargin}>
              {additionalDetails &&
                additionalDetails.length > 0 &&
                additionalDetails.map(
                  (ele) =>
                    ele.fieldValue && (
                      <>
                        <Typography className={classes.displayTitle}>
                          {ele.displayName}
                        </Typography>
                        <Typography className={classes.displayInfo}>
                          {ele.fieldValue}
                          {/* <div>{ele.firstName + " " + ele.lastName}</div> */}
                        </Typography>
                      </>
                    )
                )}
            </Grid>
          )}
          {data.runnerConsents && (
            // &&
            // data.runnerConsents
            // .findIndex((x) => x.status === "ACTIVE") !==-1
            <Grid item xs={6}>
              <Typography className={classes.displayTitle}>
                {Properties.Consent_label}
              </Typography>

              {data.runnerConsents.map((consent) => (
                <>
                  {/* {consent.status ? ( */}
                  <Typography className={classes.displayInfo}>
                    {consent.name}
                    {consent.status && consent.status === "ACTIVE" ? (
                      <>{Properties.ActiveLabel}</>
                    ) : (
                      <></>
                    )}
                  </Typography>
                  {/* ) : (
                    <></>
                  )} */}
                </>
              ))}
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  showEventItemData = () => {
    const { classes } = this.props;
    const { data } = this.state;
    return (
      <>
        <Grid>
          {data?.items && (
            <Grid style={{ margin: "8px 0px" }} container xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow className={classes.tableHead}>
                      <TableCell className={classes.tableCell} component="th">
                        Item Name
                      </TableCell>
                      <TableCell className={classes.tableCell} component="th">
                        Quantity
                      </TableCell>
                      <TableCell className={classes.tableCell} component="th">
                        Size
                      </TableCell>{" "}
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
              {data?.items &&
                data?.items.map((row, index) => (
                  <Table aria-label="customized table">
                    <TableBody style={{ paddingLeft: "16px" }}>
                      <TableRow
                        key={index}
                        style={{
                          marginBottom: "100px",
                        }}
                      >
                        <TableCell
                          component="td"
                          className={classes.tableCell}
                          align="left"
                        >
                          {row.itemName}
                        </TableCell>
                        <TableCell
                          component="td"
                          className={classes.tableCell}
                          align="center"
                        >
                          <span>{row.quantity ? row.quantity : 0}</span>
                        </TableCell>
                        <TableCell
                          component="td"
                          className={classes.tableCell}
                          align="center"
                        >
                          <span>{row.size ? row.size : "-"}</span>
                        </TableCell>{" "}
                      </TableRow>
                    </TableBody>
                  </Table>
                ))}
            </Grid>
          )}
          <Grid container>
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.shipping_Address_Label}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data.shippingAddress ? data.shippingAddress : "---"}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.itemAmountLabel}
              </Typography>
              <Typography className={classes.displayInfo}>
                {data?.paymentDetails &&
                data?.paymentDetails?.totalItemPrice === 0
                  ? "-"
                  : data?.paymentDetails
                  ? data?.paymentDetails?.totalItemPrice
                  : "---"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  showPaymentData = () => {
    const { classes } = this.props;
    const { data } = this.state;

    return (
      <>
        {data.paymentDetails && (
          <Grid container>
            {data.paymentDetails.totalAmount > 0 && (
              <Grid item xs={6} className={classes.bottomMargin}>
                <Typography className={classes.displayTitle}>
                  {Properties.Total_Amount}
                </Typography>
                <Typography className={classes.displayInfo}>
                  {data.paymentDetails.totalAmount}
                </Typography>
              </Grid>
            )}

            {data.paymentDetails.paymentStatus && (
              <Grid item xs={6} className={classes.bottomMargin}>
                <Typography className={classes.displayTitle}>
                  {Properties.paymentStatusLabel}
                </Typography>
                <Typography className={classes.displayInfo}>
                  {data.paymentDetails.paymentStatus}
                </Typography>
              </Grid>
            )}

            {data.paymentDetails.orderDetails &&
              data.paymentDetails.orderDetails.paymentId && (
                <Grid item xs={6} className={classes.bottomMargin}>
                  <Typography className={classes.displayTitle}>
                    {Properties.paymentIdLabel}
                  </Typography>
                  <Typography className={classes.displayInfo}>
                    {data.paymentDetails.orderDetails.paymentId}
                  </Typography>
                </Grid>
              )}
          </Grid>
        )}
      </>
    );
  };

  showTrainingPlanData = () => {
    const { classes } = this.props;
    const { trainingData } = this.state;

    return (
      <Grid container style={{ margin: "10px 0px" }}>
        {trainingData?.length > 0 ? (
          <Table aria-label="customized table">
            <TableHead>
              <TableRow className={classes.tableHead}>
                <TableCell component="th">Date</TableCell>
                <TableCell component="th">Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trainingData &&
                trainingData.map((row, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell
                      component="td"
                      style={{
                        width: "20%",
                        // textAlign: "center"
                      }}
                    >
                      {row.challengeDate}
                    </TableCell>
                    <TableCell component="td">
                      <Clamp
                        texts={{ showMore: "Moreeee", showLess: "Menos" }}
                        lines={3}
                        maxLines={1000}
                        withToggle
                        onShowMore={(show) => console.log(show)}
                      >
                        {ApplicationUtil.formatDesc(
                          row.description,
                          row.videoRecordLink
                        )}
                      </Clamp>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <div>No Training Plan Available</div>
        )}
      </Grid>
    );
  };

  showAttachmentData = () => {
    const { classes } = this.props;
    const { data, bibFile } = this.state;

    return (
      <>
        <Grid container>
          {data.bibGenerated && (
            <Grid container spacing={3} style={{ margin: "25px 0px" }}>
              {" "}
              <ThumbnailViewer
                title={"BIB"}
                src={bibFile}
                onClick={(e) => {
                  this.setState({
                    dialogTitle: "BIB",
                    showImage: true,
                    imagePath: `data:image/png;base64,${bibFile}`,
                  });
                }}
              />
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  /**====================================================================
   *    Vishal  -   Program Challenge Participant User details
   * ====================================================================
   */

  // render the program challenge participant data
  showProgramChallengePartPersonalDetails = () => {
    const { classes } = this.props;
    const { programChallengeParticipantData } = this.state;

    return (
      <>
        <Grid container>
          <Grid item xs={6} className={classes.bottomMargin}>
            <Typography className={classes.displayTitle}>
              {Properties.Name_label}
            </Typography>
            <Typography className={classes.displayInfo}>
              {programChallengeParticipantData?.runner?.fullName}
            </Typography>
          </Grid>
          {programChallengeParticipantData?.runner?.age > 0 && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_age}
              </Typography>
              <Typography className={classes.displayInfo}>
                {programChallengeParticipantData?.runner?.age}
              </Typography>
            </Grid>
          )}
          {programChallengeParticipantData?.runner?.gender && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_Gender}
              </Typography>
              <Typography className={classes.displayInfo}>
                {ApplicationUtil.getCamelCasing(
                  programChallengeParticipantData?.runner?.gender
                )}
              </Typography>
            </Grid>
          )}
          {programChallengeParticipantData?.runner?.email && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_Email}
              </Typography>
              <Typography className={classes.displayInfo}>
                {programChallengeParticipantData?.runner?.email}
              </Typography>
            </Grid>
          )}
          {programChallengeParticipantData?.runner?.city && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_City}
              </Typography>
              <Typography className={classes.displayInfo}>
                {programChallengeParticipantData?.runner?.city}
              </Typography>
            </Grid>
          )}
          {programChallengeParticipantData?.runner?.address && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.address_label}
              </Typography>
              <Typography className={classes.displayInfo}>
                {programChallengeParticipantData?.runner?.address}
              </Typography>
            </Grid>
          )}
          {programChallengeParticipantData?.runner?.country && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_Country}
              </Typography>
              <Typography className={classes.displayInfo}>
                {programChallengeParticipantData?.runner?.country}
              </Typography>
            </Grid>
          )}
          {!!this.state.programChallengeParticipantData?.bib_number && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.BIB_label}
              </Typography>
              <Typography className={classes.displayInfo}>
                {this.state.programChallengeParticipantData?.bib_number}
              </Typography>
            </Grid>
          )}
          {programChallengeParticipantData?.runner?.contactNumber && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.Label_ContatNumber}
              </Typography>
              <Typography className={classes.displayInfo}>
                {}
                {programChallengeParticipantData?.runner?.countryCode ?? "+91"}
                {programChallengeParticipantData?.runner?.contactNumber}
              </Typography>
            </Grid>
          )}
        </Grid>
      </>
    );
  };
  // render the program Challenge reg details
  showProgramChallengeRegistrationData = () => {
    const { classes } = this.props;
    const { programChallengeParticipantData } = this.state;
    return (
      <>
        <Grid container>
          {programChallengeParticipantData?.registrationDate && (
            <Grid item xs={6} className={classes.bottomMargin}>
              <Typography className={classes.displayTitle}>
                {Properties.RegistrationDateLabel}
              </Typography>
              <Typography className={classes.displayInfo}>
                {ApplicationUtil.changeDateFormatDateMMDDWithTimeDDMM(
                  programChallengeParticipantData?.registrationDate
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  handleTabChange = (event, value) => {
    this.setState({
      ActiveTabIndex: value,
      activeTabTitle: event.target.innerHTML,
    });
  };
  closeWindow = () => {
    this.setState({ showDialog: false, showImage: false });
  };
  render() {
    const { classes } = this.props;
    const {
      data,
      isProgramChallenge,
      headerLinkSelected,
      programChallengeParticipantData,
      value,
      ActiveTabIndex,
      activeTabTitle,
      enableTraining,
    } = this.state;
    return (
      <div className={classes.mainBody}>
        <>
          {headerLinkSelected === "Program" && isProgramChallenge ? (
            <Grid style={{ width: "95%", margin: "auto", marginTop: "10px" }}>
              <Grid>
                <Typography className={classes.runnerNameCss}>
                  {programChallengeParticipantData?.runner?.fullName}
                </Typography>
                {!!programChallengeParticipantData?.bib_number && (
                  <Grid item xs={6}>
                    <Typography className={classes.displayTitle}>
                      {programChallengeParticipantData?.bib_number}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Tabs
                value={this.state.ActiveTabIndex}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handleTabChange}
                variant={isMobile ? "scrollable" : "fullWidth"}
                scrollButtons={isMobile ? "on" : "off"}
              >
                <Tab
                  label={Properties.PersonalDataLabel}
                  className={classes.textTransformCss}
                />
                <Tab
                  label={Properties.RegistrationDataLabel}
                  className={classes.textTransformCss}
                />
              </Tabs>
              <div className={classes.root}>
                {activeTabTitle == Properties.PersonalDataLabel &&
                  this.showProgramChallengePartPersonalDetails()}
                {activeTabTitle == Properties.RegistrationDataLabel &&
                  this.showProgramChallengeRegistrationData()}
              </div>
            </Grid>
          ) : (
            <>
              {data && data?.runner && (
                <Grid
                  style={{ width: "95%", margin: "auto", marginTop: "10px" }}
                >
                  <Grid>
                    <Typography className={classes.runnerNameCss}>
                      {data.runner.name}
                    </Typography>
                    {data.bibNumber && (
                      <Grid item xs={6}>
                        <Typography className={classes.displayTitle}>
                          {data.bibNumber}
                        </Typography>
                      </Grid>
                    )}
                    {data.eventRunCategories[0] && (
                      <Grid item xs={6} className={classes.bottomMargin}>
                        <Typography className={classes.displayTitle}>
                          {data.eventRunCategories[0].activityType.displayName +
                            "-" +
                            data.eventRunCategories[0].categoryName}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>

                  <Tabs
                    value={this.state.ActiveTabIndex}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleTabChange}
                    variant={isMobile ? "scrollable" : "fullWidth"}
                    scrollButtons={isMobile ? "on" : "off"}
                  >
                    <Tab
                      label={Properties.PersonalDataLabel}
                      className={classes.textTransformCss}
                    />
                    {data.teamDetailsDTO && (
                      <Tab
                        label={Properties.TeamDataLabel}
                        className={classes.textTransformCss}
                      />
                    )}
                    {data && data.eventRunCategories[0] && (
                      <Tab
                        label={Properties.RegistrationDataLabel}
                        className={classes.textTransformCss}
                      />
                    )}

                    {data?.items && (
                      <Tab
                        label={Properties.EventItemDataLabel}
                        className={classes.textTransformCss}
                      />
                    )}

                    {data.paymentDetails && (
                      <Tab
                        label={Properties.PaymentInformationLabel}
                        className={classes.textTransformCss}
                      />
                    )}
                    {enableTraining && (
                      <Tab
                        label={Properties.Label_Training}
                        className={classes.textTransformCss}
                      />
                    )}
                    {data.bibGenerated && (
                      <Tab
                        label={Properties.AttachmentsDataLabel}
                        className={classes.textTransformCss}
                      />
                    )}
                  </Tabs>
                  <div className={classes.root}>
                    {activeTabTitle == Properties.PersonalDataLabel &&
                      this.showPersonalData()}
                    {activeTabTitle == Properties.RegistrationDataLabel &&
                      this.showEventRegistrationData()}
                    {activeTabTitle == Properties.TeamDataLabel &&
                      this.showTeamData()}
                    {activeTabTitle == Properties.EventItemDataLabel &&
                      this.showEventItemData()}
                    {activeTabTitle == Properties.PaymentInformationLabel &&
                      this.showPaymentData()}
                    {enableTraining &&
                      activeTabTitle == Properties.Label_Training &&
                      this.showTrainingPlanData()}
                    {activeTabTitle == Properties.AttachmentsDataLabel &&
                      this.showAttachmentData()}
                  </div>
                </Grid>
              )}
            </>
          )}
        </>
        <ImageDialogBox
          title={this.state.dialogTitle}
          open={this.state.showImage}
          imagePath={this.state.imagePath}
          closeDisloag={() => this.closeWindow()}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(ParticipantRegistrationDetails);
