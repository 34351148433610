import React from "react";
import Properties from "../../../../properties/Properties";
import AppToolBar from "../../../common/AppToolBar";
import Header from "./../../../common/Header";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import { withRouter } from "react-router-dom";
import { Grid, Paper, Typography } from "@material-ui/core";
import { getRewardPoints } from "../../../../apis/eventService";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import RunnerSideBreadcrumbs from "../../../common/RunnerSideBreadcrumbs";
const styles = (theme) => ({
  header: {
    display: "flex",
    justifyContent: "flex-start",
    // marginTop: "75px",
    padding: "10px",
    marginLeft: "3%",
  },
  tableBlock: {
    display: "flex",
    //   marginTop: "5px",
    justifyContent: "center",
  },

  tablebody: {
    width: "80vw",
    padding: "10px",
    //   marginTop: "30px",
    [theme.breakpoints.down("xs")]: {
      margin: "0 10px 0 10px",
      width: "90vw",
    },
  },

  outerColumn: {
    padding: "10px",
    borderTop: "2px solid grey",

    borderRight: "2px solid grey",
    borderBottom: "2px solid grey",
    borderLeft: "2px solid grey",
    textAlign: "center",
  },
  rowStyling: {
    fontWeight: "bold",
  },

  hrLine: {
    marginTop: "25px",
    border: "2px solid #b0b6bd",
  },
});
class WellnessPoints extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wellnessPoints: null,
      activityPoints: null,
      habitPoints: null,
      bonusPoints: null,
      wellnessPointDescription: null,
    };
  }
  componentDidMount() {
    let eventId = ApplicationUtil.getRunnersEventID();
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runner?.id;
    getRewardPoints(eventId, runnerId).then((res) => {
      if (res?.data) {
        this.setState({
          wellnessPoints: res.data.totalRewardPoint,
          activityPoints: res.data.totalActivityRewardPts,
          habitPoints: res.data.totalHabitRewardPts,
          bonusPoints: res.data.totalBonusRewardPts,
          wellnessPointDescription: res.data.wellnessPtComputeDesc,
        });
      } else {
        this.setState({
          wellnessPoints: "",
          activityPoints: "",
          habitPoints: "",
          bonusPoints: "",
          wellnessPointDescription: "",
        });
      }
    });
  }
  render() {
    const { classes } = this.props;
    const {
      activityPoints,
      habitPoints,
      bonusPoints,
      wellnessPoints,
      wellnessPointDescription,
    } = this.state;

    return (
      <React.Fragment>
        <AppToolBar>
          <Header
            //   {...this.props}
            label={Properties.Label_WellnessPoints}
            //   fromDate={eventDate}
            //   toDate={eventDate === eventEnd ? false : eventEnd}
          />
        </AppToolBar>
        <div style={{ marginTop: "60px" }}>
          <RunnerSideBreadcrumbs />
          <h1 className={classes.header}>
            My {Properties.Label_WellnessPoints} :{" "}
            {wellnessPoints ? wellnessPoints : "0"}
          </h1>

          <Grid
            container
            style={{
              width: "80vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "4%",
            }}
          >
            <Grid container direction="column" item xs={4} md={4} lg={4}>
              <Grid item className={classes.outerColumn}>
                <Typography className={classes.rowStyling}>
                  {Properties.Label_ActivityPoints}
                </Typography>
              </Grid>
              <Grid item className={classes.outerColumn}>
                <Typography className={classes.rowStyling}>
                  {activityPoints ? activityPoints : "0"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="column" item xs={4} md={4} lg={4}>
              <Grid item align="center" className={classes.outerColumn}>
                <Typography className={classes.rowStyling}>
                  {Properties.Label_HabitPoints}
                </Typography>
              </Grid>
              <Grid item align="center" className={classes.outerColumn}>
                <Typography className={classes.rowStyling}>
                  {habitPoints ? habitPoints : "0"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="column" item xs={4} md={4} lg={4}>
              <Grid item className={classes.outerColumn}>
                <Typography className={classes.rowStyling}>
                  {Properties.Label_BonusPoints}
                </Typography>
              </Grid>
              <Grid item className={classes.outerColumn}>
                <Typography className={classes.rowStyling}>
                  {bonusPoints ? bonusPoints : "0"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <hr className={classes.hrLine} />
        <div className={classes.description}>
          <span> {ReactHtmlParser(wellnessPointDescription)}</span>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(WellnessPoints);
