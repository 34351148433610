import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  withStyles,
  OutlinedInput,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import ApplicationUtil from "../../../../utils/ApplicationUtil";

function MultiSelectQue({
  classes,
  history,
  options,
  selectedValues,
  response,
  handleMultiSelectChange,
  question,
  setSelectedValues,
  otherValue,
  setOtherValue,
}) {
  const [disabledOptions, setDisabledOptions] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleOtherInputChange = (event) => {
    const newValue = event.target.value;
    setOtherValue(newValue);

    const updatedSelectedValues = selectedValues.map((v) =>
      v.startsWith("Any other $$ ") ? `Any other $$ ${newValue}` : v
    );

    // update the any other - newvalue
    if (!updatedSelectedValues.some((v) => v.startsWith("Any other $$ "))) {
      updatedSelectedValues.push(`Any other $$ ${newValue}`);
    }

    setSelectedValues(updatedSelectedValues);
  };

  // Effect to reset `otherValue` when "Any other" is deselected
  useEffect(() => {
    if (!selectedValues.some((v) => v.startsWith("Any other $$ "))) {
      setOtherValue("");
    }

    if (selectedValues.includes("None of these")) {
      let makeOtherDisable = options?.filter(
        (option) => option !== "None of these"
      );
      setDisabledOptions(makeOtherDisable);
    } else {
      setDisabledOptions([]);
    }
  }, [selectedValues, options]);

  const isDisabled = (item) => {
    return (
      disabledOptions.includes(item) ||
      ApplicationUtil.handleFieldDisable(question, response)
    );
  };

  const checkedValue = (item) => {
    //Uncheck other values if 'None of these' is selected
    if (selectedValues.includes("None of these")) {
      return item === "None of these";
    }
    return selectedValues.includes(item);
  };

  /**
   * _checkToShowTooltip - Will check the showing the tooltip on hover
   */
  const _checkToShowTooltip = () => {
    if (ApplicationUtil.handleFieldDisable(question, response) && otherValue) {
      setShowTooltip(true);
    }
  };

  return (
    <FormControl>
      <FormGroup>
        {options?.map((item) => (
          <FormControlLabel
            key={item}
            defaultChecked={selectedValues?.includes(item)}
            control={<Checkbox color="#09456C" />}
            label={item}
            disabled={isDisabled(item)}
            checked={checkedValue(item)}
            value={item}
            onChange={() => handleMultiSelectChange(item)}
          />
        ))}

        {selectedValues.includes("Any other") && (
          <Tooltip open={showTooltip} title={otherValue} placement="top">
            <div>
              <OutlinedInput
                className={classes.inputBox}
                value={otherValue}
                disabled={ApplicationUtil.handleFieldDisable(
                  question,
                  response
                )}
                style={{
                  background: ApplicationUtil.handleFieldDisable(
                    question,
                    response
                  )
                    ? "hsl(0, 0%, 95%)"
                    : "#ffffff",
                }}
                onChange={handleOtherInputChange}
                placeholder="Enter a value"
                onMouseEnter={_checkToShowTooltip}
                onMouseLeave={() => {
                  if (showTooltip) {
                    setShowTooltip(false);
                  }
                }}
              />{" "}
            </div>
          </Tooltip>
        )}
      </FormGroup>
    </FormControl>
  );
}

const styles = (theme) => ({
  inputBox: {
    height: "38px",
    marginTop: "10px",
    marginBottom: "10px",
    border: `1px solid #08456C`,
  },
});
export default compose(withStyles(styles), withRouter)(MultiSelectQue);
