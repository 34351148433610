/*global google*/

import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import GoogleMap from "../../../../../common/GoogleMap/GoogleMap";
import MenuIcon from "@material-ui/icons/Menu";
import StarIcon from "../../../../../../images/star.gif";
import StartIcon from "../../../../../../images/start.jpg";
import markerIcon from "../../../../../../images/marker.gif";
import markerImgIcon from "../../../../../../images/marker.jpg";

import "./RelayMap.css";
import { withRouter } from "react-router-dom";
const styles = (theme) => ({});
class RelayMap extends Component {
  state = {
    FullScreen: true,
  };

  apiIsLoaded = (map, maps) => {
    const { flightPlanCoordinates, features } = this.props;

    const iconBase =
      "https://developers.google.com/maps/documentation/javascript/examples/full/images/";
    const icons = {
      parking: {
        icon: iconBase + "parking_lot_maps.png",
      },
      library: {
        icon: iconBase + "library_maps.png",
      },
      info: {
        icon: iconBase + "info-i_maps.png",
      },
    };

    const flightPath = new google.maps.Polyline({
      path: flightPlanCoordinates,

      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });

    const firstPoint = {
      lat: flightPlanCoordinates[0].lat,
      lng: flightPlanCoordinates[0].lng,
    };

    const image = {
      url: StarIcon,
      // anchor: new google.maps.Point(14, 25),
    };
    let marker = new google.maps.Marker({
      position: firstPoint,
      icon: StartIcon, //image,
      map: map,
      title: "start",
      size: 1,
    });
    // marker = new google.maps.Marker({
    //   position: lastPoint,
    //   icon: image, //StarIcon, // iconBase + "info-i_maps.png", //
    //   map: map,
    //   title: "finish",
    //   size: 1,
    // });
    let allPoints = features;
    var infoWindow = new google.maps.InfoWindow();
    for (var i = 0; i < allPoints.length; i++) {
      let totalDistance = 0;
      let participants = [];

      var latLng = {
        //new google.maps.LatLng(
        lat: allPoints[i]?.coOrdinatePoint?.lat,
        lng: allPoints[i]?.coOrdinatePoint?.lng,
      };
      // );
      var data = null;
      let feat = allPoints[i]?.longDiscription
        ? allPoints[i]?.longDiscription
        : null;
      if (feat) {
        Object.keys(feat).forEach((key, value) => {
          if (feat[key].key === "Total Distance") {
            totalDistance = feat[key].value;
          }
          if (feat[key].key === "Participants") {
            // participants
            let parts = feat[key].value.split(",");
            if (parts && parts.length > 0) {
              parts.forEach((element) => {
                participants.push(element);
              });
            }
          }
        });
        data = {
          totalDistance: totalDistance,
          participants: participants,
        };
      }
      const image = {
        url: markerIcon,
        anchor: new google.maps.Point(14, 25),
      };
      marker = new google.maps.Marker({
        position: latLng, //{ lat: allPoints[i].lat, lng: allPoints[i].lng },
        icon: i === allPoints.length - 1 ? image : markerImgIcon,
        map: map,
        size: 1,
      });
      (function (marker, data) {
        google.maps.event.addListener(marker, "click", function (e) {
          infoWindow.setContent(
            "<div style='max-height:160px'>" +
              "<div style = 'margin-bottom:4px;  font-size: 16px; font-weight: 500; color: black; '>" +
              "Distance Covered" +
              "<div style=' font-size: 14px;font-weight: 500;color: #727272;'>" +
              parseFloat(Number(data?.totalDistance) / 1000).toFixed(2) +
              " Km" +
              "</div>" +
              "</div>" +
              "<div>" +
              ` <div style = 'font-size: 16px; font-weight: 500; color: black;'>Participants</div>
            <div style='font-size: 14px;font-weight: 500;color: #727272; '>
            ${
              data?.participants &&
              data.participants.map((e) => `<div>${e}</div>`).join("")
            }
            </div>
            ` +
              "<div >" +
              "</div>"
          );
          infoWindow.open(map, marker);
        });
        // google.maps.event.addListener(marker, "mouseout", function (e) {
        //   infoWindow.close(map, marker);
        // });
      })(marker, data);
    }

    flightPath.setMap(map);
  };

  toggleMenu = () => {
    return (
      <div
        style={{
          color: this.state.FullScreen ? "white" : "black",
          cursor: "pointer",
        }}
        onClick={() => {
          this.setState({ FullScreen: !this.state.FullScreen });
        }}
      >
        <MenuIcon />
      </div>
    );
  };
  render() {
    const { FullScreen } = this.state;
    const { flightPlanCoordinates, defaultCenter, center, features } =
      this.props;
    return (
      <Grid container>
        {/* {FullScreen ? ( */}
        <Grid
          item
          style={{ width: "100%", position: "relative", margin: "0px 4px" }}
        >
          {/* <div
            style={{
              position: "absolute",
              top: 10,
              left: 10,
              zIndex: 5,
            }}
          >
            {this.toggleMenu()}
          </div> */}
          <div>
            <GoogleMap
              defaultCenter={features?.[features?.length - 1]?.coOrdinatePoint} //{defaultCenter}
              center={features?.[features?.length - 1]?.coOrdinatePoint} //{center}
              apiIsLoaded={this.apiIsLoaded}
              markers={features}
              handleItemClick={(item) => {}}
              flightPlanCoordinates={flightPlanCoordinates}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}
export default compose(withStyles(styles, { withTheme: true }),withRouter)(RelayMap);

