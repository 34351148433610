import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@material-ui/core";
import EmojiEventsOutlinedIcon from "@material-ui/icons/EmojiEventsOutlined";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  rank: {
    width: "100%",
    marginTop: "10px",

    "& .rank_heading": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "60px",
    },
    "& .rank_heading h2": {
      fontWeight: 600,
      color: "rgb(40, 131, 181)",
    },
    "& .rank_icon": {
      fontSize: "30px !important",
      marginRight: "10px",
      color: "rgb(40, 131, 181)",
    },
    "& .rank_table": {
      overflowX: "auto",
      "& span": {
        fontSize: "18px",
      },
    },
  },
});

class Rank extends Component {
  render() {
    const { classes, gender, ageWise, overall, genderWise } = this.props;
    return (
      <Paper className={classes.rank} square elevation={3}>
        <Paper className="rank_heading" variant="outlined">
          <EmojiEventsOutlinedIcon className="rank_icon" />
          <h2>Rank</h2>
        </Paper>
        <Paper className="rank_table">
          <Table>
            <TableHead>
              <TableRow>
                {overall && (
                  <TableCell align="center">
                    <span>
                      <b>Overall Rank</b>
                    </span>
                  </TableCell>
                )}
                {genderWise && (
                  <TableCell align="center">
                    <span>
                      <b>Gender Rank</b>
                    </span>
                  </TableCell>
                )}
                {/* {ageWise && (
                  <TableCell align="center">
                    <span>Age Category Rank</span>
                  </TableCell>
                )} */}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {overall && (
                  <TableCell align="center">
                    <span>{overall}</span>
                  </TableCell>
                )}
                {genderWise && (
                  <TableCell align="center">
                    <span>{genderWise}</span>
                  </TableCell>
                )}
                {/* {ageWise && (
                  <TableCell align="center">
                    <span>{ageWise}</span>
                  </TableCell>
                )} */}
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Paper>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }),withRouter)(Rank);
