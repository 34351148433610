import React, { Component } from "react";
import Chart from "chart.js";
import Properties from "../../properties/Properties";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
const styles = (theme) => ({
  outerDiv: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "flex-start",
  },
  },
  legendStyling1: {
    width: "95%",
    fontSize: "10px",
    color: "grey",
    letterSpacing: 0,
  },
});
export class HorizontalLineGraph extends Component {
  chartRef = React.createRef();
  chartRef1 = React.createRef();
  state = {
    data: this.props.graphData,
    graphXAxis: this.props.graphXAxis,
    graphYAxis: this.props.graphYAxis,
  };

  componentDidMount() {
    this.makeChart();
  }

  componentDidUpdate(previousProps) {
    if (this.props.graphData !== previousProps.graphData) {
      this.setState({
        graphData: this.props.data,
      });
    }

    if (this.props.graphXAxis !== previousProps.graphXAxis) {
      this.setState({
        graphXAxis: this.props.graphXAxis,
      });
    }
    if (this.props.graphYAxis !== previousProps.graphYAxis) {
      this.setState({
        graphYAxis: this.props.graphYAxis,
      });
    }

    this.makeChart();
  }

  makeChart = () => {
    const {
      graphData,
      graphXAxis,
      graphYAxis,
      teamSteps,
      teamWellness,
      teamDistance,
    } = this.props;
    const myChartRef = this.chartRef.current.getContext("2d");
 const myChartRef1 = this?.chartRef1?.current?.getContext("2d");

    if (
      window.newHorizontalLineGraph != null &&
      (teamSteps > 0 || teamWellness > 0 || teamDistance > 0) &&
      graphData > 0
    )
      window.newHorizontalLineGraph.destroy();
    let labelName = "";

    let dataX = [],
      dataY2 = [],
      completionTime = [],
      legendLabel = "",
      totalDistance = [],
      totalWellnessPoint = [],
      tooltip1 = [],
      tooltip2 = [];

    // dataX = ["shraddha", "namrata", "swapnil", "kiran", "harshada"];
    dataX = graphData.map(function (e) {
      let d = graphYAxis ? e[graphYAxis] : e.runnerName;
      return d; //names of each runner in the team
    });
    if (graphXAxis === "wellnessPoint") {
      legendLabel =
        graphXAxis?.charAt(0).toUpperCase() +
        graphXAxis?.slice(1, 8) +
        " " +
        graphXAxis?.slice(8) +
        "s";
    } else if (graphXAxis === "distance") {
      legendLabel = Properties.Distance_label + " (Km)";
    } else if (graphXAxis === "steps") {
      legendLabel = "Steps";
    } else {
      legendLabel = graphXAxis?.charAt(0).toUpperCase() + graphXAxis?.slice(1);
    }
    if (graphXAxis) {
      if (graphXAxis === "distance") {
        dataY2 = graphData.map(function (e) {
          let dis = e.distance;
          if (dis % 1000 === 0) {
           return   parseFloat(dis / 1000).toFixed(0);
          } else {
           return   parseFloat(dis / 1000).toFixed(2);
         }
        });
      } else if (graphXAxis === "wellnessPoint") {
        dataY2 = graphData.map(function (e) {
          return e.wellnessPoints;
        });
      } else {
        dataY2 = graphData.map(function (e) {
          return e[graphXAxis];
        });
      }
    } else {
      dataY2 = graphData.map(function (e) {
        return parseFloat(e.distance / 1000).toFixed(2);
      });
    }

    completionTime = graphData.map(function (e) {
      return e.completionTime;
    });
    totalWellnessPoint = graphData.map(function (e) {
      return e.wellnessPoints;
    });

    tooltip1 = graphData.map(function (e) {
      return e[graphXAxis];
    });

    totalDistance = graphData.map(function (e) {
      return parseFloat(e.distance / 1000).toFixed(2);
    });

    window.newHorizontalLineGraph = new Chart(myChartRef, {
      type: "horizontalBar",
      data: {
        labels: dataX,
        datasets: [
          {
            label: legendLabel,
            data: dataY2,
            backgroundColor: "#a52a2a",
          },
        ],
      },
      //-------------------x-------------
      // defaultOptions: {
      //   global: {
      //     fontSize: "18px",
      //   },
      // },
      //------------------x---------------

      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            right:
              graphData.length > 7 && graphXAxis === "distance"
                ? 25
                : graphData.length > 7 && graphXAxis === "steps"
                ? 35
                : "", //fix for cutoff issue
            top: graphData.length <= 7 && 20,
          },

          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        // -----------x-----------------
        // plugins: {
        //   beforeInit: (chart, options) => {
        //     chart.legend.afterFit = () => {
        //       //if (chart.legend.margins) {
        //       //chart.legend.options.labels.padding = 40;
        //       chart.height = chart.height + 140;
        //       //}
        //     };
        //   },
        // },
        //-------------------x-----------------
        scales: {
          yAxes: [
            {
              barPercentage:
                graphData.length > 2 ? 0.2 : graphData.length <= 2 ? 0.1 : 0.5,
          //  labelMaxWidth: 20,
              ticks: {
                beginAtZero: true,
                fontSize: 10,
                autoSkip: false,
              },
            },
          ],
          xAxes: [
            {
              beginAtZero: true,
             barPercentage: 0.4,
             grid: {
                display: graphData.length <= 7 ? true : false,
              drawBorder: false,
            },
              //categoryPercentage: 2,
              ticks: {
                beginAtZero: graphData.length <= 7 && true,
                userCallback: function (label, index, labels) {
                  // when the floored value is the same as the value we have a whole number
                  if (Math.floor(label) === label && graphData.length <= 7) {
                    return label;
                  }
                },
              },

              gridLines: {
                display: graphData.length <= 7 && true,
                drawBorder: graphData.length <= 7 && true,
              },
            },
          ],
        },

        tooltips: {
          enabled: true,
          responsive: true,
          callbacks: {
            label: function (tooltipItem) {
              var distance =
                Properties.Distance_label +
                ": " +
                totalDistance[tooltipItem.index] +
                " km";
                var wellnessPoint =
                Properties.Label_WellnessPoints +
                ": " +
                totalWellnessPoint[tooltipItem.index];
              var time =
                Properties.CompletionTime_label +
                ": " +
                completionTime[tooltipItem.index];

              var newTooltip =
                graphXAxis !== undefined
                  ? graphXAxis === "distance"
                    ? distance
                    : graphXAxis === "wellnessPoint"
                    ? wellnessPoint
                    : graphXAxis?.charAt(0).toUpperCase() +
                      graphXAxis?.slice(1) +
                      ":" +
                      tooltip1[tooltipItem.index]
                  : "";

              if (newTooltip) return [newTooltip];
              else if (distance && time && wellnessPoint)
                return [distance, time, wellnessPoint];
              else return null;
            },
          },
        },

        //events:['hover'],
        //onHover:false,
        legend: {
          display: graphData.length <= 7 ? true : false,
          position: "bottom",
          align: "end",
          padding: 5,

          labels: {
            fontSize: 10,
           boxWidth: 12,
          },
        },
        responsive: true,
        zoom: {
          enabled: false,
        },
        hover: {
          animationDuration: 0,
          // mode: "null",
          //  intersect:false
        },
        animation: {
          duration: 0,
          onComplete: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;

            var chart = chartInstance.controller.chart;
            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
              //  ctx.textAlign = 'center';

                ctx.textBaseline = "middle";
                var data = dataset.data[index];
                var xOffset = bar._model.x;
                var yOffset = bar._model.y;
                ctx.fillText(data, xOffset, yOffset);
              });
            });
          },
        },
      },
    });
    if (graphData.length > 7) {
      window.newHorizontalLineGraph1 = new Chart(myChartRef1, {
        type: "horizontalBar",
        data: {
          labels: [],
          datasets: [
            {
              label: legendLabel,
              data: dataY2,
              backgroundColor: "#a52a2a",
            },
          ],
        },
        //-------------------x-------------
        // defaultOptions: {
        //   global: {
        //     fontSize: "18px",
        //   },
        // },
        //------------------x---------------

        options: {
          maintainAspectRatio: false,
          layout: {
            padding: {
              right: 47,
              top: graphData.length > 7 ? 0 : 5, //fix for cutoff issue
            },
          },

          scales: {
            yAxes: [
              {
             barPercentage: 0,
                afterFit: (context) => {
                  context.width += 92;
                },
    gridLines: {
      display: false,
    },
                grid: {
                  drawTicks: false,
    },
                ticks: {
                  beginAtZero: true,
                  fontSize: 0,
                },
              },
            ],
            xAxes: [
              {
                type: "linear",
               barPercentage: 5,
               gridLines: {
                display: false,
              },
                afterFit: (context) => {
                  context.height += 10;
                },
                //categoryPercentage: 2,
                ticks: {
                  beginAtZero: true,
                fontSize:15
                },
              },
            ],
          },

          //events:['hover'],
          //onHover:false,
          legend: {
            display: false,

            labels: {
              // fontSize: 10, //12,
              //fontWeight: "20px",
              boxWidth: 0,
            },
          },
          responsive: true,
          zoom: {
            enabled: false,
          },
        },
      });
    }
  };
  render() {
    const { graphData, classes, graphXAxis, graphYAxis } = this.props;
    let legendLabel = "";
    if (graphXAxis === "wellnessPoint") {
      legendLabel =
        graphXAxis?.charAt(0).toUpperCase() +
        graphXAxis?.slice(1, 8) +
        " " +
        graphXAxis?.slice(8) +
        "s";
    } else if (graphXAxis === "distance") {
      legendLabel = Properties.Distance_label + " (Km)";
    } else if (graphXAxis === "steps") {
      legendLabel = "Steps";
    } else {
      legendLabel = graphXAxis?.charAt(0).toUpperCase() + graphXAxis?.slice(1);
    }

    // legendLabel = Properties.Distance_label + " (Km)";
    return (
      <>
        {graphData.length <= 7 ? (
          <div
            style={{
              width: "100%",
              height: "300px",
              overflowY: graphData.length > 10 ? "scroll" : "",
            }}
          >
            <div
              style={{
                height: graphData.length > 10 ? "600px" : "300px",
                width: "100%",
                position: "relative",
              }}
            >
          {window.innerWidth > 480 ? (
            <canvas
              id="myChart"
              ref={this.chartRef}
                  style={
                    {
                // resize:"both"
                    }
                  }
                />
          ) : (
            <canvas
              id="myChart"
              ref={this.chartRef}
              style={{
                height: "300px",
                position: "relative",
                overflow: "hidden",
                // resize:"both"
                  }}
                />
          )}
        </div>
          </div>
        ) : (
          <>
            <div className={classes?.outerDiv}>
              <div
                style={{
                  width: "100%",
                }}
        >
          <div
            style={{
                    height: "500px",
                    width: "100%",
                    maxHeight: "250px",
                    overflowY: graphData.length > 7 ? "scroll" : "",
            }}
          >
                  <div
                    style={{
                      height:
                        graphData.length > 30
                          ? "100vh"
                          : graphData.length > 20
                          ? "500px"
                          : graphData.length > 7
                          ? "350px"
                          : "300px",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <canvas
                      id="mychart"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                      ref={this.chartRef}
                    ></canvas>{" "}
          </div>
                </div>
                <div
                  style={{
                    height: graphData?.length > 7 ? "50px" : "100px",
                    width: "100%",
                  }}
                >
                  <canvas
                    id="mychart1"
                    ref={this.chartRef1}
                    style={{ width: "100%" }}
                  ></canvas>{" "}
                </div>
              </div>
            </div>
            <div
              className={classes?.legendStyling1}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  backgroundColor: "#A52A2A",
                  border: "none",
                  width: "15px",
                  height: "10px",
                }}
              >
                {" "}
              </button>
              <span
                style={{
                  marginLeft: "5px",
                }}
              >
                {legendLabel}{" "}
              </span>
            </div>
          </>
        )}
      </>
    );
  }
}
export default compose(
  withStyles(
    styles,

    { withTheme: true }
  )
)(HorizontalLineGraph);
