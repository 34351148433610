import React, { Fragment } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import AppToolBar from "../../common/AppToolBar";
import { Typography, Grid, Divider, Box } from "@material-ui/core";
import Snackbar from "../../common/Snackbar";
import { CustomTextField } from "../../common/CustomTextField";
import MultiSelect from "./../../common/MultiSelect";
import { Component } from "react";
import Properties from "../../../properties/Properties";
import {
  getPostRegistrationFields,
  savePostRegistrationFields,
} from "./../../../apis/eventService";
import CommonMenu from "./../../common/SimpleMenu";
import SubmitCancelBtn from "../../common/SubmitCancelBtn";
import ApplicationUtil from "../../../utils/ApplicationUtil";
import { format, isAfter, isBefore } from "date-fns";
import { withRouter } from "react-router-dom";
const { zonedTimeToUtc, utcToZonedTime } = require("date-fns-tz");

const styles = (theme) => ({
  mainPanel: {
    paddingRight: theme.rightSpacing,
    paddingLeft: theme.leftSpacing,
  },
  boxCss: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    flexGrow: 1,
    marginLeft: "6.7%",
    marginRight: "6.7%",
    boxShadow: "none !important",
  },

  boxPaddingCss: {
    paddingRight: "0px !important",
  },
});

class PostRegistrationContainer extends Component {
  state = {
    anchorEl: null,
    userName: "",
    data: [],
  };
  componentDidMount() {
    this.setState({ userName: localStorage.getItem("userName") });
    let eventId = ApplicationUtil.getRunnersEventID();
    getPostRegistrationFields(eventId).then((res) => {
      let response = res.data;
      response.sort((a, b) => {
        return a.displayOrder - b.displayOrder;
      });

      let d = response;
      d = d.filter(function (ele) {
        if (ele.formName == "SIGNUP") return true;
      });
      this.setState({ data: d });
    });
  }

  goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  ValidateFields = () => {
    const { data } = this.state;
    let runnerId = localStorage.getItem("runnerId");
    let eventId = ApplicationUtil.getRunnersEventID();
    let customParams = {};
    let submitFlag = true;
    let fields = [];
    data.forEach((element) => {
      if (submitFlag) {
        if (
          element.requiredField &&
          (element.fieldValue == "" || element.fieldValue == undefined)
        ) {
          element.isError = true;
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: element.displayName + " is Required",
          });
          submitFlag = false;
          this.goToTop();
        } else {
          let obj = {};
          if (element.fieldType.name == "SINGLE_SELECT") {
            obj.fieldId = element.customFieldId;
            obj.fieldValue = element.fieldValue.optionValue;
          } else {
            obj.fieldId = element.customFieldId;
            obj.fieldValue = element.fieldValue;
          }
          fields.push(obj);
          submitFlag = true;
          return;
        }
      }
    });
    if (submitFlag) {
      customParams.fields = fields;
      // customParams.runnerId = runnerId;
      // customParams.eventId = eventId;
      savePostRegistrationFields(customParams).then((res) => {
        this.props.history.push("/eventRegistration");
      });
    }
  };

  getHeaderTitleWithIcon = () => {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div style={{ width: "100%", backgroundColor: "#2C78FF" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
          p={1}
          bgcolor="#2C78FF"
        >
          <Box
            p={1}
            classes={{
              root: classes.boxCss,
            }}
            flexGrow={1}
          >
            <div style={{ width: "auto", color: "#ffffff" }}>
              {Properties.Label_EventsList}
            </div>
          </Box>
          <Box
            p={2}
            display="flex"
            justifyContent="flex-end"
            classes={{
              root: classes.boxPaddingCss,
            }}
          >
            <span
              onClick={this.handleClick}
              style={{
                color: "#FFFFFF",
                cursor: "pointer",
              }}
            >
              <CommonMenu
                userName={this.state.userName}
                anchorEl={anchorEl}
                open={anchorEl}
                {...this.props}
              />
            </span>
          </Box>
        </Box>
      </div>
    );
  };

  handleSelectChange = (selectedValues, row) => {
    var arr = [];

    if (selectedValues != null) {
      arr.push(selectedValues.value);
      row.fieldValue = selectedValues;
    } else {
      row.fieldValue = selectedValues;
      row.isError = true;
    }
  };

  renderRequiredField = (inputField, row, index) => {
    if (inputField == "TEXT") {
      return (
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span style={{ color: "#727272" }}>{row.displayName}</span>
            {row.requiredField && <span style={{ color: "red" }}>*</span>}
          </Typography>
          <CustomTextField
            style={
              row.isError
                ? {
                    border: "1px solid red",
                    height: "45%",
                    maxHeight: "45%",
                    minHeight: "45%",
                  }
                : {
                    border: "1px solid #08456C",
                    height: "45%",
                    maxHeight: "45%",
                    minHeight: "45%",
                  }
            }
            type={"text"}
            error={row.fieldError}
            keyToSet={row.displayName}
            defaultValue={row.fieldValue}
            handleChange={(prop) => (event) => {
              //row.fieldValue = "dsvds";
              row.fieldValue = event.target.value;
              row.fieldError = false;
            }}
          />
        </Grid>
      );
    } else if (inputField == "SINGLE_SELECT") {
      let optionsList = row.fieldOptions;
      optionsList.map((r) => ((r.label = r.optionValue), (r.value = r.id)));
      let val = null;

      optionsList.forEach((element) => {
        if (element.label == row.fieldValue) {
          val = element;
        }
      });

      return (
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            <span style={{ color: "#727272" }}>{row.displayName}</span>
            {row.requiredField && <span style={{ color: "red" }}>*</span>}
          </Typography>
          {/* <Grid item xs={5}> */}
          <MultiSelect
            //value={val}
            error={row.fieldError}
            options={optionsList}
            onChange={(e) => {
              this.handleSelectChange(e, row);
            }} //{this.handleSelectChange(e, i)}
          />
          {/* </Grid> */}
        </Grid>
      );
    }
  };

  getHeaderTitleWithIcon = () => {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div style={{ width: "100%", backgroundColor: "#2C78FF" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
          p={1}
          bgcolor="#2C78FF"
        >
          <Box
            p={1}
            classes={{
              root: classes.boxCss,
            }}
            flexGrow={1}
          >
            <div style={{ width: "auto", color: "#ffffff" }}>
              {Properties.additional_Details_Label}
            </div>
          </Box>
          <Box
            p={2}
            display="flex"
            justifyContent="flex-end"
            classes={{
              root: classes.boxPaddingCss,
            }}
          >
            <span
              onClick={this.handleClick}
              style={{
                color: "#FFFFFF",
                cursor: "pointer",
              }}
            >
              <CommonMenu
                userName={this.state.userName}
                anchorEl={anchorEl}
                open={anchorEl}
                {...this.props}
              />
            </span>
          </Box>
        </Box>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const { data } = this.state;
    return (
      <React.Fragment>
        <AppToolBar
          backNavigate={() => this.props.history.push("/eventlist")}
          showBack={true}
        >
          {this.getHeaderTitleWithIcon()}
          <div style={{ width: 35 }} />
        </AppToolBar>
        <div
          className={classes.mainPanel}
          style={{
            marginTop: "2%",
          }}
        >
          {data.map((row, index) => (
            <Grid
              style={{ width: "100%", margin: "0px" }}
              container
              spacing={1}
            >
              {this.renderRequiredField(row.fieldType.name, row, index)}
            </Grid>
          ))}

          <Grid
            style={{
              width: "100%",
              // height: "75px",
              marginTop: "10px",
              //display: "flex",
              justifyContent: "center",
              margin: "0px",
              textAlign: "center",
            }}
            container
            spacing={1}
          >
            <Grid style={{ marginTop: "5%" }} item xs={5}>
              <SubmitCancelBtn
                onClick={() => this.ValidateFields()}
                label={"Next"}
                type="submit"
              />
            </Grid>
          </Grid>
          {/* <div>{this.renderRequiredField("dropdown")}</div>
            <div>{this.renderRequiredField("")}</div> */}
        </div>
      </React.Fragment>
    );
  }
}

//export default PostRegistrationContainer;
export default compose(withStyles(styles, { withTheme: true }),withRouter)(
  PostRegistrationContainer
);
