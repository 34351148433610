/*
    @variant: Not required, default is info message. (error, success, warning,  info)
    @message: Required, 'display text message'
    @buttons: Not required, default is cross close button, OR Array contains objects of a button, 
            : @text and @handler
    @isAutoHide: Not required, default is true, if we dont want to hide automatic then set as false.
    @preventDuplicate: prevent Duplicates
*/

import React from "react";
import { withSnackbar } from "notistack";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "@material-ui/core";

const styles = (theme) => ({
  btns: {
    fontSize: "1em",
    textTransform: "uppercase",
    color: "#fff",
    fontWeight: "bold",
    minWidth: 50,
    background: "transparent",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  closeBtnIcon: {
    fontSize: 20,
    color: "#fff",
  },
});

class Snackbar extends React.Component {
  constructor(props) {
    super(props);
    Snackbar.snackbarObjectArr = [];
  }

  render() {
    Snackbar.Props = this.props;
    return <div id="snackbar-popUp-modal" />;
  }

  static show = (obj) => {
    let { variant, message, buttons, isAutoHide, preventDuplicate } = obj;
    let { theme } = Snackbar.Props;
    let popupPosition = {
      vertical: "top",
      horizontal: "center",
    };
    let popupStyle = {
      backgroundColor: theme.snackbar[variant]
        ? theme.snackbar[variant]
        : theme.snackbar["info"],
      borderRadius: 4,
      //width: "87%", removed as per QA-4067
      flexGrow: "unset",
    };

    if (isAutoHide === false && isAutoHide !== undefined) {
      Snackbar.handlePopupModal(true);
    }

    if (
      Snackbar.snackbarObjectArr.indexOf(message) !== -1 &&
      preventDuplicate === true
    ) {
      return;
    }
    Snackbar.snackbarObjectArr.push(message);
    //let messageComp = <span dangerouslySetInnerHTML={{ __html: message }} />; // QA-5874, allow to show html encoded message string.
    let messageComp = message;
    let popup = Snackbar.Props.enqueueSnackbar(messageComp, {
      variant: variant,
      autoHideDuration: isAutoHide === false ? null : 3000,
      anchorOrigin: popupPosition,
      preventDuplicate: true,
      ContentProps: {
        style: popupStyle,
      },
      onClose: () => Snackbar.clearStack(message), // clear stack before close,
      action: getButtons(buttons),
    });

    function getButtons(buttonArray) {
      const { classes } = Snackbar.Props;
      let buttons = [];

      if (
        !buttonArray ||
        (buttonArray !== undefined && buttonArray.length === 0)
      ) {
        buttons.push(
          <Button
            key={0}
            variant="contained"
            size="small"
            className={classes.btns}
            onClick={() => {
              Snackbar.hide(popup, message);
            }}
          >
            <CloseIcon className={classes.closeBtnIcon} />
          </Button>
        );
      } else {
        buttonArray.forEach((button, i) => {
          buttons.push(
            <Button
              key={i}
              variant="contained"
              size="small"
              className={classes.btns}
              onClick={() => {
                if (button.handler) {
                  button.handler();
                }
                Snackbar.hide(popup, message);
              }}
            >
              {button.text}
            </Button>
          );
        });
      }

      return buttons;
    }
  };

  static hide = (popup, message) => {
    Snackbar.clearStack(message); // clear stack before close

    Snackbar.Props.closeSnackbar(popup);
    Snackbar.handlePopupModal(false);
  };

  static clearStack = (message) => {
    const index = Snackbar.snackbarObjectArr.indexOf(message);
    if (index > -1) {
      Snackbar.snackbarObjectArr.splice(index, 1);
    }
  };

  static handlePopupModal = (modal) => {
    let element = document.getElementById("snackbar-popUp-modal");

    if (modal) {
      element.setAttribute(
        "style",
        "position:fixed; height:100%; width:100%; background:#000; z-index:1111; opacity:0.4;"
      );
    } else {
      element.setAttribute("style", "");
    }
  };
}

export default withStyles(styles, { withTheme: true })(withSnackbar(Snackbar));
