import React from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@material-ui/core";
import TimerIcon from "@material-ui/icons/Timer";
import { withRouter } from "react-router-dom";
import Properties from "../../../../../../properties/Properties";

const styles = (theme) => ({
  timing: {
    width: "100%",

    "& span": {
      fontSize: "18px",
    },
    "& .timing_heading": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "60px",
    },
    "& .timing_heading h2": {
      fontWeight: 600,
      color: "rgb(40, 131, 181)",
    },
    "& .time_icon": {
      fontSize: "30px !important",
      marginRight: "10px",
      color: "rgb(40, 131, 181)",
    },
  },
});

function ResponseResultTable({ classes, data }) {
  return (
    <Paper className={classes.timing} square elevation={3}>
      <Paper className="timing_heading" variant="outlined">
        <TimerIcon className="time_icon" />
        <h2>{"Result"}</h2>
      </Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              style={{
                width: "auto",
              }}
            >
              <span>
                <b>{Properties.Total_Points_Label}</b>
              </span>
            </TableCell>

            <TableCell component="th" align="center">
              <span>
                <b>{Properties.Total_Sustained_Label}</b>
              </span>
            </TableCell>
            <TableCell component="th" align="center">
              <span>
                <b>{Properties.Total_Not_Sustained_Label}</b>
              </span>
            </TableCell>
            {/* <TableCell component="th" align="center">
              <span>
                <b>{Properties.Total_Not_Responded_Label}</b>
              </span>
            </TableCell> */}
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell align="center">{data?.totalPoints ? data?.totalPoints : "-"}</TableCell>

            <TableCell align="center">
              <span>{data.sustainPoints ? data.sustainPoints : "-"}</span>
            </TableCell>
            <TableCell align="center">
              <span>
                {data?.notSustainPoints ? data?.notSustainPoints : "-"}
              </span>
            </TableCell>
            {/* <TableCell align="center">
              <span>
                {data?.notRespondedPoints ? data?.notRespondedPoints : "-"}
              </span>
            </TableCell> */}
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ResponseResultTable);
