import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateSupportDTODetails,
  updateSupportSelectedEvent,
  updateAtheleteSetFilters,
  updateActivitySetFilters,
  updateParticipantSetFilters,
  updateSupportSelectedProgram,
  IsCurrentDraftChallengeData,
} from "../../../../redux/supportUser";
import {
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Grid,
  Typography,
} from "@material-ui/core";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import "./../../../../styles/common.css";
import {
  createEvent,
  deleteEvent,
  downloadCSVFormat,
  getOrganizerEventList,
  getOrganizerProgramList,
  publishEvent,
  uploadEventCSVFile,
} from "../../../../apis/eventService";
import EventListTiles from "./../../../common/EventListTiles";
import ListPageLoader from "./../../../common/ListPageLoader";
import { withRouter } from "react-router-dom";
import OrganizerCommonHeader from "./OrganizerCommonHeader";
import { EmptyEvents } from "../../../common/SvgIcon";
import Properties from "../../../../properties/Properties";
import Snackbar from "../../../common/Snackbar";
import DialogBox from "../../../common/DialogBox";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import SearchIcon from "@material-ui/icons/Search";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import { openEventChallenges } from "../../../../apis/challengeService";
import ChallengeDetailsTile from "../../registration/challenges/ChallengeDetailsTile";
import { FormatIndentIncreaseSharp } from "@material-ui/icons";
const styles = (theme) => ({
  importContainerCss: {
    margin: "10px",
  },
  selectedTab: {
    backgroundColor: "#4791db",
    color: "#fff",
    borderRadius: "10px",
    textTransform: "none",
    minWidth: 72,
    // marginRight: theme.spacing(4),
    // fontWeight: theme.typography.fontWeightRegular,
    fontWeight: 600,
  },
  tab: {
    // color: "#fff",
    textTransform: "none",
    minWidth: 72,
    // fontWeight: theme.typography.fontWeightRegular,
    // marginRight: theme.spacing(4),
    fontWeight: 600,
  },
  main: {
    width: "95%",
    margin: "auto",
    marginTop: "70px",
  },
  searchFilter__row: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(6),
    marginBottom: "20px",
    "& .search__select": {
      display: "flex",
      alignItems: "flex-end",
    },
    "& .search ": {
      display: "flex",
      alignItems: "center",
      border: "1px solid #bfc0c0",
      borderRadius: "5px",
      height: "35px",
      "& > input": {
        height: "30px",
        outline: "none",
        border: "none",
        fontSize: "16px",
        paddingLeft: "10px",
        borderRight: "2px solid #bfc0c0",
      },
      "& .search__icon": {
        padding: "5px",
        cursor: "pointer",
      },
    },
    "& .filters": {
      display: "flex",
      alignItems: "flex-end",
      fontFamily: "Rubik,sans-serif",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    fontFamily: "Rubik, sans-serif",
  },

  emptyEvents_main_div: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    // display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "80px",
  },

  emptyEvents_child_div1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginLeft: "50px",
  },

  emptyEvents_child_div2: {
    fontSize: "18px",
    fontWeight: 600,
    color: "gray",
  },
});

class OrganizerEventList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      userName: localStorage.getItem("supportUserName"),
      value: 0,
      data: null,
      totalRecords: 0,
      eventId: null,
      eventLinkData: null,
      programType: "EVENT",

      // eventTab: "CURRENT",
    };
  }

  componentDidMount() {
    this.loadDetails();
  }
  loadDetails = (isPastEvent) => {
    localStorage.removeItem("ActiveTab");
    localStorage.removeItem("pastEvent");
    localStorage.removeItem("isChallenge");
    localStorage.removeItem("isTraining");
    localStorage.removeItem("isChallengeEvent");
    localStorage.removeItem("isCreateOrDraft");
    localStorage.removeItem("supportOpenChallengeId");
    localStorage.removeItem("isChallengePastDate");
    localStorage.removeItem("linkType");
    localStorage.removeItem("values");
    this.props.updateSupportSelectedEvent(null);
    this.props.updateSupportSelectedProgram(null);
    this.props.updateAtheleteSetFilters(null);
    this.props.updateActivitySetFilters(null);
    this.props.updateParticipantSetFilters(null);
    this.props.IsCurrentDraftChallengeData(null);
    const supportUser = ApplicationUtil.getSupportDetails();
    let selectedLink = localStorage.getItem("headerLinkSelected");

    this.setState({
      programType:
        selectedLink === "Challenges"
          ? "CHALLENGE"
          : selectedLink === "Training"
          ? "TRAINING"
          : selectedLink === "Program"
          ? "PROGRAM"
          : "EVENT",
    });
    if (selectedLink === "Challenges") {
      localStorage.setItem("isChallengeEvent", true);
    } else if (selectedLink === "Training") {
      localStorage.setItem("isTraining", true);
    }
    if (selectedLink === "Program") {
      let customParams = {
        pageCount: 8,
        pageNumber: 0,
        sortKey: "event_date",
        sortOrder: false,
        eventState: isPastEvent ? "PAST" : "CURRENT",
      };
      getOrganizerProgramList(customParams).then((res) => {
        let createEvent = res?.data?.canCreateEvent;
        let modifyEvent = res.data?.permissions?.find(
          (x) => x.key === "canModifyEvent"
        );
        let deleteEvent = res.data?.permissions?.find(
          (x) => x.key === "canDeleteEvent"
        );
        let modifyProgram = res?.data?.permissions?.find(
          (x) => x.key === "canModifyProgram"
        );
        let list = res.data?.programDto?.map((x) => {
          x.image = x.imageDto;
          return x;
        });
        if (supportUser?.role !== "SUPPORT") {
          this.props.updateSupportDTODetails(
            list.length > 0
              ? list[0]?.organizers
                ? list[0]?.organizers[0]
                : {}
              : {}
          );
        }
        this.setState({
          data: list ? list : null,
          totalRecords: res.data.totalRecords,
          canCreateEvent: createEvent,
          canModifyEvent: modifyEvent
            ? modifyEvent.value === "true"
              ? true
              : false
            : false,
          canModifyProgram: modifyProgram
            ? modifyProgram.value === "true"
              ? true
              : false
            : false,
          showMenu: modifyEvent
            ? modifyEvent.value === "true"
              ? true
              : false
            : false,
          canDeleteEvent: deleteEvent
            ? deleteEvent.value === "true"
              ? true
              : false
            : false,
          isProgram: true,
        });
      });
    } else {
      let customParams = {
        pageCount: 8,
        pageNumber: 0,
        sortKey: "event_date",
        sortOrder: false,
        eventState: isPastEvent ? "PAST" : "CURRENT",
        programType:
          selectedLink === "Challenges"
            ? "CHALLENGE"
            : selectedLink === "Training"
            ? "TRAINING"
            : "EVENT",
      };

      getOrganizerEventList(customParams).then((res) => {
        let allPermissions = res?.data?.permissions;
        let setPermissions = {};
        allPermissions.forEach((element) => {
          setPermissions[element?.key] =
            element?.value === "true" ? true : false;
        });
        this.props.updateSupportSelectedEvent(null);
        this.props.updateSupportSelectedProgram(null);

        // this.props.updateAtheleteSetFilters(null);
        // this.props.updateActivitySetFilters(null);
        // this.props.updateParticipantSetFilters(null);
        localStorage.setItem(
          "supportPermissions",
          JSON.stringify(setPermissions)
        );
        let createEvent = false;
        if (setPermissions.canCreateEvent) {
          createEvent = res?.data?.canCreateEvent;
        }
        localStorage.setItem(
          "organizerDetailsImage",
          res?.data?.organizerDTO?.image?.imageData
        );
        localStorage.setItem(
          "organizerDetailsName",
          res?.data?.organizerDTO?.name
        );
        if (res.data.organizerDTO) {
          // supportData.organizerDTO = res.data.organizerDTO;
          this.props.updateSupportDTODetails(res.data.organizerDTO);
        }

        this.setState({
          data: res.data?.data ? res.data.data : null,
          totalRecords: res.data.totalRecords,
          canCreateEvent: createEvent,
          canModifyEvent: setPermissions.canModifyEvent,
          canModifyProgram: setPermissions.canModifyProgram,
          showMenu: setPermissions.canModifyEvent,
          canDeleteEvent: setPermissions.canDeleteEvent,
          canSeeChallenge:
            setPermissions.canSeeChallenge === undefined
              ? true
              : setPermissions.canSeeChallenge,
          isProgram: false,

          // ? setPermissions.canSeeChallenge
          // : true,
        });
      });
    }

    let organizer = JSON.parse(localStorage.getItem("organizer"));

    if (
      selectedLink === "Challenges" ||
      this.state.programType === "CHALLENGE"
    ) {
      openEventChallenges(
        organizer?.id ? organizer?.id : null,
        isPastEvent ? "PAST" : "CURRENT"
      ).then((res) => {
        if (res?.data) {
          this.setState({
            challengesList: res.data,
          });
        } else {
          this.setState({
            challengesList: [],
          });
        }
      });
    }
  };
  getList = (isFristPage, page) => {
    const { value, canSeeChallenge, programType } = this.state;
    let eventTab;

    if (value === 0) {
      eventTab = "CURRENT";
    } else if (value === 1) {
      eventTab = "PAST";
    } else if (value === 2) {
      eventTab = "DRAFT";
    }
    if (programType === "PROGRAM") {
      let customParams = {
        pageCount: 8,
        pageNumber: page ? page : 0,
        sortKey: "event_date",
        sortOrder: false,
        eventState: eventTab,
      };
      getOrganizerProgramList(customParams).then((res) => {
        let createEvent = res?.data?.canCreateEvent;
        let modifyEvent = res.data?.permissions.find(
          (x) => x.key === "canModifyEvent"
        );
        let deleteEvent = res.data?.permissions.find(
          (x) => x.key === "canDeleteEvent"
        );
        let modifyProgram = res.data?.permissions.find(
          (x) => x.key === "canModifyProgram"
        );

        let list =
          res.data?.programDto && res.data?.programDto?.length > 0
            ? res.data?.programDto?.map((x) => {
                x.image = x.imageDto;
                return x;
              })
            : [];

        //[ankit] Defect_id_661 for loading nextData and concat with previous.
        // let newdata = page
        // ? this.state.data?.concat(res?.data?.programDto)
        // : res?.data?.programDto;

        //Vishal - defect id 799 - program banner not displaying - in list- added image property to newly recieved obj and concat
        let newdata = page ? this.state.data?.concat(list) : list;

        this.setState({
          data: newdata ? newdata : [],
          totalRecords: res.data.totalRecords,
          canCreateEvent: createEvent,
          canModifyEvent: modifyEvent
            ? modifyEvent.value === "true"
              ? true
              : false
            : false,
          canModifyProgram: modifyProgram
            ? modifyProgram.value === "true"
              ? true
              : false
            : false,
          showMenu: modifyEvent
            ? modifyEvent.value === "true"
              ? true
              : false
            : false,
          canDeleteEvent: deleteEvent
            ? deleteEvent.value === "true"
              ? true
              : false
            : false,
          isProgram: true,
        });
      });
    } else {
      let customeParams = {
        pageCount: 8,
        pageNumber: page ? page : 0,
        sortKey: "event_date",
        sortOrder: false,
        eventState: eventTab,
        programType: this.state.programType,
      };

      // events list
      getOrganizerEventList(customeParams).then((res) => {
        let newdata = page
          ? this.state.data?.concat(res.data.data)
          : res.data.data;

        let setPermissions = JSON.parse(
          localStorage.getItem("supportPermissions")
        );
        let createEvent = false;
        if (setPermissions.canCreateEvent) {
          createEvent = res?.data?.canCreateEvent;
        }

        this.setState({
          data: newdata,
          totalRecords: res.data.totalRecords,
          canCreateEvent: createEvent,
          canModifyEvent: setPermissions.canModifyEvent,
          canModifyProgram: setPermissions.canModifyProgram,
          showMenu: setPermissions.canModifyEvent,
          canDeleteEvent: setPermissions.canDeleteEvent,
          canSeeChallenge:
            setPermissions.canSeeChallenge === undefined
              ? true
              : setPermissions.canSeeChallenge,
          isProgram: false,
        });
      });
    }
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleChange = (event, newValue) => {
    const { canModifyEvent, programType } = this.state;
    let showMenu = true;
    if (newValue === 0) {
      showMenu = canModifyEvent;
    } else if (newValue === 1) {
      //past
      showMenu = false;
    } else {
      showMenu = true;
    }
    this.setState({
      showMenu: showMenu,
      value: newValue,
      resetParams: newValue, //fix for reseting page since it was carrying between states
    });
    let eventTab = "CURRENT";
    if (newValue === 0) {
      eventTab = "CURRENT";
      localStorage.removeItem("pastEvent");
    } else if (newValue === 1) {
      eventTab = "PAST";
      localStorage.setItem("pastEvent", true);
    } else if (newValue === 2) {
      eventTab = "DRAFT";
      localStorage.removeItem("pastEvent");
    }

    if (programType === "PROGRAM") {
      let customParams = {
        pageCount: 8,
        pageNumber: 0,
        sortKey: "event_date",
        sortOrder: false,
        eventState: eventTab,
      };
      getOrganizerProgramList(customParams).then((res) => {
        if (Object.keys(res?.data).length !== 0) {
          let createEvent = res?.data?.canCreateEvent;
          let modifyEvent = res.data?.permissions?.find(
            (x) => x.key === "canModifyEvent"
          );
          let deleteEvent = res.data?.permissions?.find(
            (x) => x.key === "canDeleteEvent"
          );
          let modifyProgram = res.data?.permissions?.find(
            (x) => x.key === "canModifyProgram"
          );
          let challengeEvent = res.data?.permissions?.find(
            (x) => x.key === "canSeeChallenge"
          );
          let list = res.data?.programDto?.map((x) => {
            x.image = x.imageDto;
            return x;
          });

          this.setState({
            data: list ? list : null,
            totalRecords: res.data.totalRecords,
            canCreateEvent: createEvent,
            canModifyEvent: modifyEvent
              ? modifyEvent.value === "true"
                ? true
                : false
              : false,
            canModifyProgram: modifyProgram
              ? modifyProgram.value === "true"
                ? true
                : false
              : false,
            showMenu: modifyEvent
              ? modifyEvent.value === "true"
                ? true
                : false
              : false,
            canDeleteEvent: deleteEvent
              ? deleteEvent.value === "true"
                ? true
                : false
              : false,
            canSeeChallenge: challengeEvent
              ? challengeEvent.value === "true"
                ? true
                : false
              : true,
            isProgram: programType === "PROGRAM",
          });
        }
      });
    } else {
      let customeParams = {
        pageCount: 8,
        pageNumber: 0,
        sortKey: "event_date",
        sortOrder: false,
        eventState: eventTab,
        programType: programType,
      };
      getOrganizerEventList(customeParams).then((res) => {
        let setPermissions = JSON.parse(
          localStorage.getItem("supportPermissions")
        );

        let createEvent = false;
        if (setPermissions.canCreateEvent) {
          createEvent = res?.data?.canCreateEvent;
        }

        this.setState({
          data: res.data?.data ? res.data.data : null,
          totalRecords: res.data.totalRecords,
          canCreateEvent: createEvent,
          canModifyEvent: setPermissions.canModifyEvent,
          canModifyProgram: setPermissions.canModifyProgram,
          showMenu: setPermissions.canModifyEvent,
          canDeleteEvent: setPermissions.canDeleteEvent,
          canSeeChallenge:
            setPermissions.canSeeChallenge === undefined
              ? true
              : setPermissions.canSeeChallenge,
          isProgram: false,
        });
      });
    }
    let organizer = JSON.parse(localStorage.getItem("organizer"));

    if (eventTab !== "DRAFT") {
      if (this.state.programType === "CHALLENGE") {
        openEventChallenges(
          organizer?.id ? organizer?.id : null,
          eventTab
        ).then((res) => {
          if (res?.data) {
            this.setState({
              challengesList: res.data,
            });
          } else {
            this.setState({
              challengesList: null,
            });
          }
        });
      }
    } else {
      this.setState({
        challengesList: [],
      });
    }
  };

  handleNavigateToChallengeList = () => {
    this.props.history.push("/organizer/challenges");
  };
  handleCreateEvent = () => {
    localStorage.setItem("sidebarSelected", "Setup");
    localStorage.setItem("isCreateOrDraft", true);
    localStorage.setItem("editView", false);
    localStorage.setItem("selectedProgramCreateOrEdit", false);
    localStorage.setItem("isChallengeResponse", false);
    this.props.updateSupportSelectedEvent(null);
    this.props.history.push("/organizer/list");
  };

  handleCreateEventAndProgram = () => {
    localStorage.setItem("sidebarSelected", "Setup");
    localStorage.setItem("isCreateOrDraft", true);
    localStorage.setItem("editView", false);
    localStorage.setItem("isChallengeResponse", false);

    // This is used for the manage Breadcrumbs (usage - create program & calender view event edit button && on draft program click + menu modify program)
    if (this.state.programType === "PROGRAM") {
      localStorage.setItem("selectedProgramCreateOrEdit", true);
      localStorage.removeItem("IsOpenSubscriptionProgram");
    } else {
      localStorage.setItem("selectedProgramCreateOrEdit", false);
    }

    this.props.updateSupportSelectedEvent(null);
    this.props.history.push("/organizer/list");
  };

  handleUpdateClick = (row) => {
    this.props.updateSupportSelectedEvent(row);
    localStorage.setItem("sidebarSelected", "Setup");
    localStorage.setItem("editView", true);
    if (this.state.value === 2) {
      localStorage.setItem("isCreateOrDraft", true);
    } else {
      localStorage.removeItem("isCreateOrDraft");
    }

    this.props.history.push("/organizer/list");
  };

  handlePublishClick = (row) => {
    this.props.updateSupportSelectedEvent(row);

    publishEvent(row.id).then((res) => {
      if (res.data.success.code === "200") {
        Snackbar.show({
          variant: "success",
          message: res.data.success.verbose,
        });
        this.setState({ openDialog: false, value: 0 });
        this.loadDetails();
      } else if (res.data.success.code === "400") {
        let msg = res.data.success.verbose;
        //msg = "hello \n hi \n dcdsc \n";
        msg = msg.split("\n");
        let showMsg = "";

        showMsg = msg.map((e) => <ul>{e}</ul>);

        this.setState({
          openDialog: true,
          dialogMsg: showMsg,
        });
      }
    });
  };

  //api call for delete draft
  deleteDraft = () => {
    deleteEvent(this.state.eventId).then((res) => {
      this.closeDeleteWindow();
      // this.loadDetails();
      let PT = localStorage.getItem("linkType");
      let customParams = {
        pageCount: 8,
        pageNumber: 0,
        sortKey: "event_date",
        sortOrder: false,
        eventState: "DRAFT",
        programType: this.state.programType,
      };

      getOrganizerEventList(customParams).then((res) => {
        this.setState({
          data: res.data?.data ? res.data.data : null,
          totalRecords: res.data.totalRecords,
        });
      });
    });
  };

  handleDeleteClick = (id) => {
    this.setState({ showDeleteDialog: true, eventId: id });
  };

  getDeleteDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>
            Your event draft will get discarded, Do you still want to continue?{" "}
          </span>
        </Typography>
      </div>
    );
  };

  closeDeleteWindow = () => {
    this.setState({
      showDeleteDialog: false,
    });
  };

  closeDialogBox = () => {
    this.setState({
      open: false,
      openDialog: false,
      value: 0,
    });
    this.loadDetails();
  };

  // import csv

  multipleUpload = (e) => {
    if (e.target.id == "csv-file-input") {
      let file = e.target.files[0];
      if (file.name.split(".").pop() !== "csv") {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "only csv file is allowed",
        });
      } else {
        this.setState({
          importFile: file,
        });
      }
    }
  };
  handleImportClick = (id) => {
    this.setState({ eventId: id, openImportDialog: true });
  };
  handleCSVFormatDownload = () => {
    downloadCSVFormat().then((res) => {});
  };
  getImportComponent = () => {
    const { classes } = this.props;

    return (
      <div className={classes.importContainerCss}>
        <div>
          {/* <Input value={this.state.filename} />
          <label htmlFor="csv-file-input">
            <AttachmentIcon
              style={{
                color: "#296DFA",
              }}
            />
          </label> */}
          <input
            className={classes.input}
            id="csv-file-input"
            type="file"
            accept=".csv"
            // hidden
            onClick={this.clearImage}
            onChange={this.multipleUpload}
          />
          {/* {this.state.importFile && ( */}
          <SubmitCancelBtn
            style={{ width: "130px" }}
            label="Import"
            type="submit"
            onClick={() => {
              this.handleImportFileUpload();
            }}
            isDisabled={!this.state.importFile}
          />
          {/* // )} */}
        </div>
        <div>
          <a
            style={{ fontSize: "14px" }}
            onClick={() => this.handleCSVFormatDownload()}
          >
            Download CSV File Format
          </a>
        </div>
      </div>
    );
  };

  handleImportFileUpload = () => {
    let file = this.state.importFile;
    let eventId = this.state.eventId;
    uploadEventCSVFile(file, eventId).then((res) => {
      this.setState({
        openImportDialog: false,
      });
    });
  };

  handleImportClose = () => {
    this.setState({
      openImportDialog: false,
    });
  };
  searchHandleClick = () => {
    const { searchValue, eventTab } = this.state;
    let customeParams = {
      pageCount: 8,
      pageNumber: 0,
      sortKey: "event_date",
      sortOrder: false,
      eventState: "PAST",
      eventName: searchValue,
      programType: this.state.programType,
    };

    if (customeParams.programType === "PROGRAM") {
      getOrganizerProgramList(customeParams).then((res) => {
        let list = res.data?.programDto?.map((x) => {
          x.image = x.imageDto;
          return x;
        });
        this.setState({
          data: list,
        });
      });
    } else {
      getOrganizerEventList(customeParams).then((res) => {
        let newdata = res.data?.data;
        this.setState({
          data: newdata,
        });
      });
    }
  };
  searchHandleChange = (event) => {
    this.setState({ searchValue: event.target.value });
    let isPastEvent = true;
    if (!event.target.value) {
      this.loadDetails(isPastEvent);
    }
  };

  showEvents = (PT) => {
    localStorage.removeItem("isTraining");
    localStorage.removeItem("isChallengeEvent");
    this.setState({
      programType: PT,
      value: 0,
    });

    if (PT === "PROGRAM") {
      let customParams = {
        pageCount: 8,
        pageNumber: 0,
        sortKey: "event_date",
        sortOrder: false,
        eventState: "CURRENT",
      };
      getOrganizerProgramList(customParams).then((res) => {
        let setPermissions = JSON.parse(
          localStorage.getItem("supportPermissions")
        );

        let createEvent = false;
        if (setPermissions?.canCreateEvent) {
          createEvent = res?.data?.canCreateEvent;
        }

        let list = res.data?.programDto?.map((x) => {
          x.image = x.imageDto;
          return x;
        });
        this.setState({
          data: list ? list : null,
          totalRecords: res.data.totalRecords,
          canCreateEvent: createEvent,
          canModifyEvent: setPermissions?.canModifyEvent,
          canModifyProgram: setPermissions?.canModifyProgram,
          showMenu: setPermissions?.canModifyEvent,
          canDeleteEvent: setPermissions?.canDeleteEvent,
          canSeeChallenge:
            setPermissions?.canSeeChallenge === undefined
              ? true
              : setPermissions?.canSeeChallenge,
          isProgram: PT === "PROGRAM",
        });
      });
    } else {
      let customParams = {
        pageCount: 8,
        pageNumber: 0,
        sortKey: "event_date",
        sortOrder: false,
        eventState: "CURRENT",
        programType: PT,
        // programType: PT
      };

      getOrganizerEventList(customParams).then((res) => {
        let setPermissions = JSON.parse(
          localStorage.getItem("supportPermissions")
        );

        let createEvent = false;
        if (setPermissions.canCreateEvent) {
          createEvent = res?.data?.canCreateEvent;
        }

        this.setState({
          data: res.data?.data ? res.data.data : null,
          totalRecords: res.data.totalRecords,
          canCreateEvent: createEvent,
          canModifyEvent: setPermissions.canModifyEvent,
          canModifyProgram: setPermissions.canModifyProgram,
          showMenu: setPermissions.canModifyEvent,
          canDeleteEvent: setPermissions.canDeleteEvent,
          canSeeChallenge:
            setPermissions.canSeeChallenge === undefined
              ? true
              : setPermissions.canSeeChallenge,
          isProgram: false,
        });
      });

      let organizer = JSON.parse(localStorage.getItem("organizer"));
      if (PT === "CHALLENGE") {
        openEventChallenges(
          organizer?.id ? organizer?.id : null,
          "CURRENT"
        ).then((res) => {
          if (res?.data) {
            this.setState({
              challengesList: res.data,
            });
          } else {
            this.setState({
              challengesList: [],
            });
          }
        });
        localStorage.setItem("isChallengeEvent", true);
        localStorage.removeItem("isTraining");
      }
      if (PT === "TRAINING") {
        localStorage.setItem("isTraining", true);
        localStorage.removeItem("isChallengeEvent");
      }
    }
  };

  render() {
    const {
      anchorEl,
      value,
      canCreateEvent,
      canModifyEvent,
      canModifyProgram,
      canSeeChallenge,
      searchValue,
      challengesList,
      programType,
      isProgram,
    } = this.state;
    const open = Boolean(anchorEl);
    const { classes, history } = this.props;

    return (
      <>
        <OrganizerCommonHeader
          title={"Events"}
          logo
          showEventsHandler={this.showEvents}
          homeScreen
        />

        <div className={classes.main}>
          {canModifyProgram && canModifyEvent && (
            <div
              style={{
                padding: "0px 0px 5px 0px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {/* {canSeeChallenge && (
            <a
              style={{
                fontWeight: "600",
                fontSize: "18px",
                textDecoration: "underline",
                marginRight: "15px",
              }}
              onClick={this.handleNavigateToChallengeList}
            >
              Challenges
            </a>
          )} */}

              <a
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                  textDecoration: "underline",
                }}
                onClick={() => this.handleCreateEventAndProgram()}
              >
                + Create{" "}
                {this.state.programType === "PROGRAM" ? "Program" : "Event"}
              </a>
            </div>
          )}

          <Tabs
            variant="fullWidth"
            value={value}
            onChange={this.handleChange}
            centered
          >
            <Tab
              value={0}
              fullWidth
              className={value === 0 ? classes.selectedTab : classes.tab}
              label="Current"
            />
            <Tab
              value={1}
              fullWidth
              className={value === 1 ? classes.selectedTab : classes.tab}
              label="Past"
            />
            {(canCreateEvent || canModifyEvent) && (
              <Tab
                value={2}
                fullWidth
                className={value === 2 ? classes.selectedTab : classes.tab}
                label="Drafts"
              />
            )}
          </Tabs>

          <Grid
            style={{
              margin: "10px 5%",
              // overflow: "auto",
            }}
            // justifyContent="center"
            // className={classes.mainContainer}
            key={this.state.resetParams}
          >
            {value === 1 && (
              <div className={classes.searchFilter__row}>
                <div className="search__select">
                  <form
                    className="search"
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.searchHandleClick();
                    }}
                  >
                    <input
                      type="search"
                      placeholder="Search..."
                      value={searchValue}
                      onChange={this.searchHandleChange}
                    />
                    <SearchIcon
                      className="search__icon"
                      onClick={this.searchHandleClick}
                    />
                  </form>
                </div>
              </div>
            )}

            {programType === "CHALLENGE" && (
              <Grid container spacing={4} style={{ marginBottom: "6px" }}>
                {challengesList?.map((data, index) => (
                  <ChallengeDetailsTile
                    row={data}
                    startDate={ApplicationUtil.FormatDateToUTC(
                      data?.eventDate,
                      data?.eventTime
                    )}
                    endDate={ApplicationUtil.changeDateFormat(
                      data?.localEndDate
                    )}
                    key={index}
                    displayRegistration
                    onClick={() => {
                      this.props.history.push("/organizer/list");
                      localStorage.setItem("supportOpenChallengeId", data?.id);
                      localStorage.setItem("supportEventName", data?.name);
                      let side = "Participants";
                      localStorage.setItem("sidebarSelected", side);
                      let isChallenge = true;
                      localStorage.setItem("isChallenge", isChallenge);
                      localStorage.setItem(
                        "supportOpenChallengeType",
                        data?.activityType
                      );
                      localStorage.removeItem("isCreateOrDraft");
                      // challenge
                      localStorage.setItem("IsOpenSubscriptionProgram", false);
                    }}
                    // onResultsClick={() => {
                    //   this.props.history.push("/organizer/challenges/list");
                    //   localStorage.setItem("supportOpenChallengeId", data?.id);
                    //   localStorage.setItem("supportEventName", data?.name);
                    //   let side = "Results";
                    //   localStorage.setItem("sidebarSelected", side);
                    //   let isChallenge = true;
                    //   localStorage.setItem("isChallenge", isChallenge);
                    //   localStorage.setItem(
                    //     "supportOpenChallengeType",
                    //     data?.activityType
                    //   );
                    //   localStorage.removeItem("isCreateOrDraft");
                    // }}
                  />
                ))}
              </Grid>
            )}
            <ListPageLoader
              pageParams={{
                page: 0,
                start: 0,
                limit: 8,
                TotalRecords: this.state.totalRecords,
              }}
              totalLoadedLength={this.state.data ? this.state.data?.length : 0}
              loadNextData={(params) => {
                this.getList(false, params.page);
              }}
            >
              <Grid
                style={{
                  padding: "5px",
                }}
                container
                spacing={4}
              >
                {/* <Table aria-label="customized table"> */}
                {this.state.data && this.state.data?.length > 0 ? (
                  this.state.data.map((row, index) => (
                    <>
                      {row && (
                        <EventListTiles
                          key={index}
                          {...this.props}
                          row={row}
                          index={index}
                          // navigate={`/organizer/list`}
                          displayRegistration={value === 2 ? false : true}
                          hideResultsForAdmin={true}
                          isProgram={
                            isProgram || this.props.programType === "PROGRAM"
                          }
                          onClick={() => {
                            if (row?.isOpenSubscriptionProgram === true) {
                              localStorage.removeItem("isProgramChallenge");
                              this.props.history.push("/organizer/list");

                              this.props.updateSupportSelectedProgram(row);
                              this.props.IsCurrentDraftChallengeData(row);
                              this.props.updateSupportSelectedEvent(row);
                              // localStorage.setItem("supportOpenChallengeId", row?.id);
                              // localStorage.setItem("supportEventName", row?.name);
                              let side = "Participants";

                              localStorage.setItem("sidebarSelected", side);

                              localStorage.setItem(
                                "IsOpenSubscriptionProgram",
                                row?.isOpenSubscriptionProgram
                              );
                              localStorage.setItem(
                                "supportOpenChallengeType",
                                row?.activityType
                              );
                              localStorage.removeItem("isCreateOrDraft");
                            } else {
                              localStorage.removeItem(
                                "IsOpenSubscriptionProgram"
                              );
                              this.props.IsCurrentDraftChallengeData(null);
                              if (isProgram) {
                                this.props.updateSupportSelectedProgram(row);
                              } else {
                                this.props.updateSupportSelectedProgram(null);
                                this.props.updateSupportSelectedEvent(row);

                                localStorage.setItem(
                                  "challengeType",
                                  row?.challengeType
                                );
                              }

                              if (this.state?.programType === "PROGRAM") {
                                localStorage.setItem(
                                  "selectedProgramCreateOrEdit",
                                  true
                                );
                              } else {
                                localStorage.setItem(
                                  "selectedProgramCreateOrEdit",
                                  false
                                );
                              }
                              localStorage.setItem("editView", true);
                              let side = "";
                              if (value === 0) side = "Participants";
                              if (value === 1)
                                side =
                                  row?.type == "CHALLENGE"
                                    ? "Activities"
                                    : "Results";
                              if (value === 2) side = "Setup";
                              localStorage.setItem("sidebarSelected", side);
                              if (value === 2) {
                                localStorage.setItem("isCreateOrDraft", true);
                                this.props.history.push("/organizer/list");
                                return;
                              } else {
                                localStorage.removeItem("isCreateOrDraft");
                              }

                              this.props.history.push(
                                isProgram
                                  ? "/organizer/calendar"
                                  : "/organizer/list"
                              );
                            }
                          }}
                          onUpdateClick={() => this.handleUpdateClick(row)}
                          onPublishClick={() => this.handlePublishClick(row)}
                          onDeleteClick={() => this.handleDeleteClick(row.id)}
                          showMenu={this.state.showMenu}
                          showPublish={value === 2 ? true : false}
                          onCreateEventClick={() => {
                            this.props.updateSupportSelectedProgram(row);
                            this.handleCreateEvent();
                          }}
                          onProgramCreateChallengeChallenge={() => {
                            this.props.updateSupportSelectedProgram(row);
                          }}
                          onOpenModifiedProgram={() => {
                            this.props.updateSupportSelectedProgram(row);
                          }}
                          onCreateSessionClick={() => {
                            this.props.updateSupportSelectedProgram(row);

                            history.push("/organizer/calendar/createSession");
                          }}
                          showDelete={
                            value === 2 ? this.state.canDeleteEvent : false
                          }
                          showModify={canModifyEvent}
                          showImport={true}
                          handleImportClick={() =>
                            this.handleImportClick(row.id)
                          }
                        />
                      )}
                    </>
                  ))
                ) : (
                  <>
                    <div className={classes.emptyEvents_main_div}>
                      <div className={classes.emptyEvents_child_div1}>
                        <EmptyEvents />
                      </div>
                      <div className={classes.emptyEvents_child_div2}>
                        <p>
                          No {this.state.programType?.toLowerCase()} Available
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {/* </Table> */}
              </Grid>
            </ListPageLoader>
          </Grid>
        </div>
        {/* </Paper> */}
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          style={{ top: "7%" }}
        >
          {/* <MenuItem onClick={this.handleClose}>Profile</MenuItem> */}
          <MenuItem>Change Password</MenuItem>
          <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
        </Menu>

        {/* publish error response */}
        <DialogBox
          signup={true}
          open={this.state.openDialog}
          message={this.state.dialogMsg}
          header={this.state.dialogHeader}
          onClick={() => {
            this.closeDialogBox();
          }}
          fullWidth
          // maxWidth={"xs"}
          closeDisloag={() => this.closeDialogBox()}
        />

        {/* cancel dialog */}
        <DialogBox
          signup={true}
          open={this.state.showDeleteDialog}
          message={this.getDeleteDialogMsg()}
          header={this.state.dialogHeader}
          label={"No"}
          onClick={() => {
            this.closeDeleteWindow();
          }}
          labelTwo={"Yes"}
          onClickTwo={() => {
            this.deleteDraft();
          }}
          closeDisloag={() => this.closeDeleteWindow()}
        />

        {/* import dialog */}
        <DialogBox
          signup={false}
          open={this.state.openImportDialog}
          message={this.getImportComponent()}
          header={"Import "}
          onClick={() => {
            this.handleImportClose();
          }}
          closeDisloag={() => this.handleImportClose()}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    supportDTO: state.supportUser.supportDTO,
    supportSelectedEvent: state.supportUser.supportSelectedEvent,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateSupportDTODetails,
    updateSupportSelectedEvent,
    updateAtheleteSetFilters,
    updateActivitySetFilters,
    updateParticipantSetFilters,
    updateSupportSelectedProgram,
    IsCurrentDraftChallengeData,
  }),
  withRouter
)(OrganizerEventList);
