import React from "react";
import { updateUserDetails } from "../../../../redux/user";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid, Paper, Tooltip, Button } from "@material-ui/core";
import {
  getCaptureDetails,
  getorderDetails,
  getCategoryList,
  signupVerifyOTP,
  deleteEventParticipants,
} from "../../../../apis/eventService";
import Properties from "../../../../properties/Properties";
import AppToolBar from "../../../common/AppToolBar";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import Header from "../../../common/Header";
import Snackbar from "../../../common/Snackbar";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { isMobile } from "react-device-detect";

import MultiSelect from "./../../../common/MultiSelect";
import { CustomTextField } from "../../../common/CustomTextField";
import { IconCalendarBlue, RuppeeIcon } from "../../../common/SvgIcon";
import { isBrowser } from "react-device-detect";
import ApplicationConstants from "../../../../constants/ApplicationConstants";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import { validateEmail } from "../../../../utils/Validator";
import {
  challengeRegister,
  getChallengeCustomFee,
  getChallengeDetailsWithOrgkey,
  openChallengeDetails,
  openEventChallengeUserCheck,
} from "../../../../apis/challengeService";
import OtpInput from "react-otp-input";
import { withRouter } from "react-router-dom";
import DialogBox from "../../../common/DialogBox";
import HelpIcon from "@material-ui/icons/Help";
import { connect } from "react-redux";
import {
  updateUserPreferences,
  updateUserSelectedEvent,
} from "../../../../redux/user";
import { verifyOTP } from "../../../../apis/loginService";
var moment = require("moment");
var momentTimeZone = require("moment-timezone");

const styles = (theme) => ({
  btnCss: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#296DFA",
    },
    maxWidth: "300px",
    width: "100%",
    height: "38px",
    borderRadius: "16px",
    backgroundColor: "#09456C",
    color: "#fff",
    fontWeight: "bold",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // height: "100vh",
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 700,
      marginLeft: "auto",
      marginRight: "auto",
    },
    marginTop: "68px",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  centerCss: {
    textAlign: "center",
  },
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    width: "100%",
    borderRadius: "5px",
    // border: "1px solid #08456C",
  },
  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },
  innerGridCss: {
    padding: "4px 0px",
  },
  dropdownCss: {
    width: isBrowser ? "360px" : "167.5px",
  },
  labelCss: {
    color: "#727272",
  },
  mandatoryCss: { color: "red" },
  helpContainerCss: {
    display: "inline-flex",
  },
  helpCss: {
    display: "flex",
    color: "#3a6ea5",
    marginLeft: "2px",
  },
  fieldCss: {
    border: "1px solid #08456C",
    height: "37px",
    width: "100%",
    borderRadius: "5px",
  },
  fieldErrorCss: {
    border: "1px solid red",
    height: "37px",
    width: "100%",
  },
  fieldsContainer: {
    marginLeft: "20px",
    marginRight: "20px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px",
    },
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  highlightedText: {
    color: "#43aa8b",
    fontSize: "40px",
    fontWeight: 700,
  },
  highlightedPrice: {
    color: "#335c67",
    fontSize: "40px",
    fontWeight: 700,
  },
  footer: {
    width: "100%",
    height: "100px",
    position: "sticky",
    bottom: 0,
    backgroundColor: "#dee2e6",
  },
  footerPaper: {
    height: "100px",
    backgroundColor: "#dee2e6",
    display: "flex",
    justifyContent: "center",
  },
});

class ChallengeRegistration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      firstNameError: false,
      lastName: "",
      lastNameError: false,
      email: "",
      emailError: false,
      otp: "",
      contactNumber: "",
      contactNumberError: false,
      selectedChallengeDuration:
        ApplicationConstants.challengeDurationOptionsList[0],
      challengeDurationError: false,
      challengeDurationOptions:
        ApplicationConstants.challengeDurationOptionsList,
      totalTargetDistance: null,
      totalTargetDistanceError: false,
      selectedDailyMinDistance: null,
      dailyMinDistanceError: false,
      DailyMinDistanceOptions: [],
      userTotalDistance: "",
      userTotalDistanceError: false,
      challengeStartDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      challengeStartDateError: false,
      countryCodeList: ApplicationConstants.CountryCodes,
      countryList: ApplicationConstants.countries,
      countryCode: { label: "+91(India)", value: "91" },
      paidItem: false,
      showOtpForm: false,
      showEmailRegistration: true,
      showRegisterForm: false,
      otherDuration: "",
      otherDurationError: false,
      termsCheckboxSelected: false,
      existingUser: "INACTIVE",
      trialApplicable: false,
    };
  }

  componentDidMount() {
    let { DailyMinDistanceOptions } = this.state;
    const { history } = this.props;
    let challengeEventId = localStorage.getItem("challengeId");
    if (challengeEventId) {            
      this.addChallengeDetails();

      if (ApplicationUtil.isuserLoggedIn("runner")) {
        const user = this.props?.userState?.user;

        let userEmail = user?.email;
        let userMobile = user?.contactNumber;
        let userDetails = user;
        let generateOTP = false;
        openEventChallengeUserCheck(generateOTP, userEmail, userMobile)
          .then((response) => {
            this.setState({
              trialApplicable: response?.data?.trialApplicable,
            });
          })
        //   .then(() => {
        //  addChallengeDetails()
        //   });


        let orgKey = localStorage.getItem("orgKey");
        let orgId = null;
        let organizer = null;
        if (orgKey !== undefined || orgKey !== null) {
          getChallengeDetailsWithOrgkey(orgKey).then((res) => {
            if (res?.data) {
              organizer = {
                id: res?.data?.id,
                name: res?.data?.name,
              };
              this.setState({
                organizer: organizer,
              });
            }
          });
        }

        this.setState({
          existingUser: userDetails?.status,
          showEmailRegistration: false,
          showRegisterForm: true,
          firstName: userDetails?.firstName,
          lastName: userDetails?.lastName,
          email: userDetails?.email,
          contactNumber: userDetails?.contactNumber,
          zipcode: userDetails?.pincode,
          trialApplicable: userDetails?.trialApplicable,
        });
        // }
      }
    } else {
      history.push("/completeEventList");
    }
  }
  handleCheckBoxChange = (event) => {
    this.setState({ termsCheckboxSelected: event.target.checked });
  };

  addChallengeDetails = () => {
    let organizer = JSON.parse(localStorage.getItem("organizer"));
    let challengeDetails = {}; //localStorage.getItem("");

    openChallengeDetails(organizer?.id ? organizer?.id : null).then((res) => {
      let data = res?.data;
      let activityType = data?.activityType;
      challengeDetails = {
        type:
          activityType === "RUN"
            ? 1
            : activityType === "CYCLE"
            ? 2
            : activityType === "DUATHLON"
            ? 3
            : activityType === "STEPS"
            ? 4
            : 1, //RUN
      };

      let isFreeRegister = localStorage.getItem("freeRegister");
      let freeWeeks = data?.trialDays / 7;
      // let weekIndex =
      //   ApplicationConstants.challengeDurationOptionsList.findIndex(
      //     (x) => x.id === freeWeeks
      //   );
      // if (isFreeRegister === "true") {
      //   this.setState({
      //     selectedChallengeDuration:
      //       ApplicationConstants.challengeDurationOptionsList[weekIndex],
      //   });
      // }

      if (res.data.openChallengeDuration && res.data.openChallengeDuration) {
        let durations = [];
        let temp = [
          {
            id: 1,
            displayName: "1 Week Challenge",
            subscriptionFee: 49,
            duration: 7,
            visibility: false,
          },
          {
            id: 2,
            displayName: "2 Week Challenge",
            subscriptionFee: 99,
            duration: 14,
            visibility: true,
          },
        ];

        let isFreeRegister = localStorage.getItem("freeRegister");
        if (isFreeRegister) {
          res.data.openChallengeDuration.map((r) => {
            // temp.map((r) => {
            if (r.visibility || r.duration === res.data?.trialDays) {
              r.label = r?.displayName;
              r.value = r?.duration;
              r.id = r?.id;
              r.subscriptionFee = r?.subscriptionFee;
              durations.push(r);
            } else {
              return;
            }
          });

          let notFound = res.data?.openChallengeDuration.findIndex(
            (x) => x.duration === data?.trialDays
          ); // temp

          if (
            notFound === -1 ||
            res.data.openChallengeDuration[notFound] === undefined
          ) {
            let newData = {
              id: "extra",
              label: `${freeWeeks} Week Challenge`,
              displayName: `${freeWeeks} Week Challenge`,
              value: res.data.trialDays,
              subscriptionFee: 0,
            };
            durations.unshift(newData);
          }
        } else {
          res.data.openChallengeDuration.map((r) => {
            if (r.visibility) {
              r.label = r?.displayName;
              r.value = r?.duration;
              r.id = r?.id;
              r.subscriptionFee = r?.subscriptionFee;
              durations.push(r);
            } else {
              return;
            }
          });
        }

        //show custom days as duaration
        if (res.data && res.data.customDate) {
          let newData = {
            id: "CUSTOM",
            label: "Custom days",
            value: "CUSTOM",
          };
          durations.push(newData);
        }
        this.setState(
          {
            trialDays: data?.trialDays,
            trialDaysCount: freeWeeks,
            challengeDurationOptions: durations, //res?.data?.openChallengeDuration,
            // selectedChallengeDuration:
          },
          () => {
            let weekIndex = res.data?.openChallengeDuration.findIndex(
              (x) => x.duration === data?.trialDays
            );
            let isFreeRegister = localStorage.getItem("freeRegister");
            if (isFreeRegister === "true") {
              this.handleChallengeDurationChange(
                weekIndex !== -1 ? durations[weekIndex] : durations[0]
              );
            } else {
              this.handleChallengeDurationChange(durations[0]);
            }
          }
        );
      }

      ApplicationUtil.ReactNative(
        `{"title":"${data?.name ? data?.name : "Register"}"}`
      );
      // res.data.challengeStartDate = "2022-01-13";
      let d1 = moment(res.data?.challengeStartDate).format("LL");
      let d2 = moment().format("LL");
      let challengeStarted = moment(d2).isAfter(d1);

      this.setState(
        {
          // displayPrice: data?.subscriptionFee,
          challengeName: data?.name,
          challengeDetails: challengeDetails,
          minReadOnly: data?.minReadOnly,
          minChallengeValue: data?.minChallengeValue,
          targetReadOnly: data?.targetReadOnly,
          startDateSetting: data?.startDateSetting,
          challengeDurationSetting: data?.challengeDurationSetting,
          totalTargetDistance:
            challengeDetails?.type == 1 ||
            challengeDetails?.type == 2 ||
            challengeDetails?.type == 3
              ? (data?.targetValue / 1000).toFixed(2)
              : data?.targetValue,
          challengeStartDate:
            res.data?.challengeStartDate && !challengeStarted
              ? res.data?.challengeStartDate
              : this.state.challengeStartDate,
          challengeRegistrationStartDate:
            res.data?.challengeStartDate && !challengeStarted
              ? res.data?.challengeStartDate
              : this.state.challengeStartDate,
          registrationEndDate: res.data?.registrationEndDate
            ? ApplicationUtil.changeDateFormatDateDDMM(
                res.data?.registrationEndDate
              )
            : null,
          targetValueSetting: res.data?.targetValueSetting,
          minFieldHelpText: res.data?.minFieldHelpText,
          minFieldDisplayText: res.data?.minFieldDisplayText,
        },
        () => {
          let response = [];
          getCategoryList(challengeDetails.type).then((categoryRes) => {
            categoryRes.data.forEach((ele) => {
              let a = {};
              a.title = ele.category;
              a.id = ele.category;
              a.label = ele.categoryDisplayName;
              a.value = ele.category;
              a.categoryDisplayName = ele.categoryDisplayName;
              a.categoryFee = ele.defaultFee;
              a.categoryMaxTicketCount = ele.defaultMaxTicket;
              a.actualDistance = ele.actualDistance;
              response.push(a);
            });
            let DailyMinDistanceOptions = response;

            let newdata = { label: "Other", value: "OTHER" };
            DailyMinDistanceOptions.push(newdata);

            let selectedDailyMinDistance = null;

            DailyMinDistanceOptions.map((e) => {
              if (e.actualDistance === this.state.minChallengeValue) {
                selectedDailyMinDistance = e;
              }
            });

            this.setState({
              selectedDailyMinDistance: selectedDailyMinDistance,
              DailyMinDistanceOptions: DailyMinDistanceOptions,
            });
          });
        }
      );
    });
  };
  validateFields = () => {
    const {
      existingUser,
      firstName,
      firstNameError,
      lastName,
      lastNameError,
      email,
      emailError,
      contactNumber,
      contactNumberError,
      selectedChallengeDuration,
      challengeDurationError,
      totalTargetDistance,
      totalTargetDistanceError,
      selectedDailyMinDistance,
      dailyMinDistanceError,
      showOtherDuration,
      otherDuration,
      otherDurationError,
      userTotalDistance,
      challengeStartDate,
      challengeStartDateError,
      challengeRegistrationStartDate,
      registrationEndDate,
      zipcode,
      zipcodeError,
      startDateSetting,
      challengeDurationSetting,
    } = this.state;

    let date = moment(challengeStartDate).format("LL");
    let start = moment(challengeRegistrationStartDate).format("LL");
    let end = moment(
      ApplicationUtil.changeDateFormatDate(registrationEndDate)
    ).format("LL");

    if (
      existingUser === "INACTIVE" &&
      (firstName === undefined || firstName === "" || firstNameError === true)
    ) {
      this.setState({ firstNameError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter first name",
      });
    } else if (
      existingUser === "INACTIVE" &&
      (lastName === undefined || lastName === "" || lastNameError === true)
    ) {
      this.setState({ lastNameError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter last name.",
      });
    } else if (
      existingUser === "INACTIVE" &&
      (email === undefined || email === "" || emailError === true)
    ) {
      this.setState({ emailError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter valid email address.",
      });
    } else if (
      existingUser === "INACTIVE" &&
      (contactNumber === undefined ||
        contactNumber === "" ||
        contactNumberError === true)
    ) {
      this.setState({ contactNumberError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter contact number.",
      });
    } else if (
      existingUser === "INACTIVE" &&
      (zipcode === undefined || zipcode === "" || zipcodeError === true)
    ) {
      this.setState({ zipcodeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter zipcode.",
      });
    } else if (
      startDateSetting === "MANDATORY" &&
      (challengeStartDate === null || challengeStartDate === "")
    ) {
      this.setState({ challengeStartDateError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter challenge start date.",
      });
    } else if (
      registrationEndDate &&
      startDateSetting === "MANDATORY" &&
      (moment(date).isAfter(end) || moment(date).isBefore(start))
    ) {
      // moment(
      //   // "2022-02-21"
      //   moment(challengeStartDate).format("YYYY-MM-DD")
      // ).isBetween(
      //   moment(challengeRegistrationStartDate).format("YYYY-MM-DD"),
      //   moment(registrationEndDate).format("YYYY-MM-DD"),
      //   []
      // )
      // moment().format("YYYY-MM-DD")
      // moment('2022-01-02').isBetween('2021-12-19', date)
      this.setState({ challengeStartDateError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: `Please enter challenge start date between ${moment(
          challengeRegistrationStartDate
        ).format("DD-MM-YYYY")} and ${
          registrationEndDate
          // .format("DD-MM-YYYY")
        }.`,
      });
    } else if (
      challengeDurationSetting === "MANDATORY" &&
      (selectedChallengeDuration?.value === "" ||
        challengeDurationError === true ||
        selectedChallengeDuration === null ||
        selectedChallengeDuration === "")
    ) {
      this.setState({ challengeDurationError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please select challenge duration.",
      });
    } else if (
      (showOtherDuration && otherDuration === "") ||
      otherDurationError === true ||
      otherDuration === null
    ) {
      this.setState({ otherDurationError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message:
          otherDuration !== "" && otherDuration > 7 && otherDuration < 365
            ? "Please enter challenge duration."
            : "Please enter custom days between 8 to 365 days.",
      });
    } else if (
      (totalTargetDistance === "" ||
        totalTargetDistanceError === true ||
        totalTargetDistance === null) &&
      (selectedDailyMinDistance?.value === "" ||
        selectedDailyMinDistance === null)
    ) {
      this.setState({
        totalTargetDistanceError: true,
        dailyMinDistanceError: true,
      });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter Daily minimum distance OR  Total target value.",
      });
    } else {
      this.registerForChallenge();
    }
  };

  saveFields = () => {
    //Register flow
    // 1. Register will be done and after its successfull registration
    // 2. call the payment method
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runner?.id;

    //calling payment gateway
    const { categoryId, amount } = this.state;
    // if (paidItem) {
    let runners = [
      {
        runnerId: runnerId,
        regFee: amount,
        otherCharges: 0,
      },
    ];

    let requestParams = {
      categoryId: categoryId,
      amount: amount,
      runners: runners,
    };

    getorderDetails(requestParams).then((res) => {
      const { data } = res;
      this.setState({
        orderId: data.orderId,
        amount: res.data.amount,
      });

      data.contact =
        this.state.contactNumber != ""
          ? this.state.contactNumber
          : data.participantPhone;
      //   this.setState({ orderId: data.orderId });
      ApplicationUtil.paymentGatewayHandler(
        data,
        this.captureDetails,
        this.handlePaymentFailure,
        this.dismissHandler
      );
    });
    // } else {
    //   this.registerForChallenge();
    // }
  };

  //registerChallenege
  registerForChallenge = () => {
    const { history } = this.props;
    const {
      firstName,
      lastName,
      email,
      contactNumber,
      challengeStartDate,
      selectedChallengeDuration,
      showOtherDuration,
      otherDuration,
      trialDaysCount,
      totalTargetDistance,
      selectedDailyMinDistance,
      paidItem,
      zipcode,
      countryCodeList,
      countryList,
      countryCode,
      organizer,
      displayPrice,
      trialApplicable,
      challengeDetails,
    } = this.state;
    let d = moment(challengeStartDate).format("DD-MM-YYYY") + " " + "00:00:01";

    let eventId = localStorage.getItem("challengeId");

    let freeWeeks = this.state.trialDays / 7;
    let isFreeRegister = localStorage.getItem("freeRegister");
    let countryIndex = countryList.findIndex((x) =>
      countryCode.label.includes(x.label)
    );
    let nationCode = `${countryCode.value}`;
    let country = countryList[countryIndex].value;
    const timeZone = momentTimeZone.tz.guess();

    let fee = 0;
    if (
      (trialApplicable &&
        Number(selectedChallengeDuration?.value) === trialDaysCount) ||
      displayPrice == 0
    ) {
      fee = 0;
    } else {
      fee = displayPrice;
    }

    let customeParams = {
      openEventRequest: {
        organizer: organizer ? organizer : null,
        timeZone: timeZone,
        firstName: firstName,
        lastName: lastName,
        email: email,
        contactNumber: contactNumber,
        zipcode: zipcode,
        country: country,
        countryCode: nationCode,
        challengeStartDate: d,
        challengeDuration: showOtherDuration
          ? otherDuration
          : selectedChallengeDuration?.value,
        totalTarget:
          challengeDetails?.type == 1 ||
          challengeDetails?.type == 2 ||
          challengeDetails?.type == 3
            ? ApplicationUtil.getFormattedKM(totalTargetDistance) * 1000
            : totalTargetDistance,
        dailyMinimumTarget: selectedDailyMinDistance?.actualDistance,
        trialEvent: isFreeRegister === "true" ? true : false,
        subscriptionFee: fee,
        tnc: this.state.termsCheckboxSelected,
      },
      // userAgent: {},
      // openEvent: { id: 1, name: "1st" }, //need challenge details here
    };
    challengeRegister(customeParams, eventId).then((res) => {
      if (res.data?.error?.code === "200") {
        let str = res.data?.error?.verbose.split(",");
        let eventId = str[1];

        this.props.updateUserSelectedEvent({ id: eventId });
        this.setState({
          eventId: eventId,
        });
        history.push("/eventDetails");
      } else {
        let paymentStatus = res?.data?.paymentStatus;
        this.props.updateUserSelectedEvent(res.data);

        this.props.updateUserSelectedEvent({ id: res.data.eventId });
        this.setState({
          categoryId: res?.data?.eventRunCategory?.id,
          amount: this.state.displayPrice,
          // amount: res?.data?.eventRunCategory?.registrationFee,
          // displayPrice: res?.data?.eventRunCategory?.registrationFee,
          eventId: res?.data?.eventId,
          paymentStatus: res?.data?.paymentStatus,
        });

        if (paymentStatus === "PENDING") {
          this.saveFields();
        } else {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Challenge registration successful.",
          });
          history.push("/eventDetails");
        }
      }
    });
  };
  captureDetails = (requestCaptureParams) => {
    const { history } = this.props;
    let runnerData = ApplicationUtil.getRunnerDetails();
    let runnerId = runnerData?.runner?.id;
    requestCaptureParams.runnerIds = [runnerId];
    getCaptureDetails(requestCaptureParams)
      .then((res) => {
        Snackbar.show({
          variant: "success",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Challenge registration successful.", //Capture API error
        });
        history.push("/eventDetails");

        // this.setState({
        //   transactionID: requestCaptureParams.razorpay_payment_id,
        //   transactionStatus: "Success",
        // });
      })
      .catch((error) => {
        this.setState({
          transactionStatus: "Failed",
        });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Failed to process the payment.",
        });
      });
  };

  handlePaymentFailure = (response) => {
    Snackbar.show({
      variant: "error",
      isAutoHide: true,
      preventDuplicate: true,
      message: `Failed to process the payment please note the transactionID
          ${response.error.metadata.payment_id}.`,
    });

    // alert(response.error.code);
    // alert(response.error.description);
    // alert(response.error.source);
    // alert(response.error.step);
    // alert(response.error.reason);
    // alert(response.error.metadata.order_id);
    // alert(response.error.metadata.payment_id);
  };

  dismissHandler = () => {
    this.handleDeleteRegistration();
    //     this.setState({
    //       openDialog: true,
    //       dialogMsg: "",
    //       dialogMsg: `By 'cancel' your registration will get cancelled. Do you wish to cancel registration or register and pay later?
    // Note: Registration will not be confirmed unless payment is successful.`,
    //     });
  };

  handleDeleteRegistration = () => {
    let runnerData = ApplicationUtil.getRunnerDetails();

    let runnerId = runnerData?.runner?.id;
    deleteEventParticipants(runnerId, false).then((res) => {
      this.setState({ openDialog: false });
      this.props.history.push("/eventlist");
    });
  };

  closeWindow = () => {
    this.setState({ openDialog: false });
    this.props.history.push("/eventlist");
  };
  validateEmailId = (value) => {
    let isValidEmail = validateEmail(value);

    if (isValidEmail) {
      //RegistrationUtil.setUserEmail(value);
      localStorage.setItem("userEMail", value);
      this.setState({ email: value, emailError: false });
    } else {
      this.setState({ emailError: true, email: value });
    }
  };
  validateDurationField = (value) => {
    if (this.state.otherDuration) {
      if (value > 7 && value < 365) {
        this.setState({
          otherDuration: value,
          otherDurationError: false,
        });
      } else {
        this.setState({ otherDuration: value, otherDurationError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please enter value between 8 to 365.",
        });
        return;
      }
    }
  };

  handleDurationFieldChange = (event) => {
    let eventId = localStorage.getItem("challengeId");
    this.setState({
      otherDuration: event.target.value,
      displayPrice: this.state.displayPrice, //show prev
      otherDurationError: event.target.value > 365 ? true : false,
    });
    let requestparam = { challengeDuration: event.target.value };
    if (this.state.organizer?.id) {
      requestparam = {
        organizerId: this.state.organizer?.id ? this.state.organizer?.id : null,
        challengeDuration: event.target.value,
      };
    }
    if (event.target.value > 0) {
      getChallengeCustomFee(eventId, requestparam).then((res) => {
        this.setState({
          displayPrice: res.data?.subscriptionFee
            ? res.data?.subscriptionFee
            : this.state.displayPrice,
        });
      });
    }
  };
  handleChallengeDurationChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      let showOtherDuration = false,
        price = 0;

      if (selectedValues.value === "CUSTOM") {
        showOtherDuration = true;
      } else {
        showOtherDuration = false;
      }

      let isFreeRegister = localStorage.getItem("freeRegister");
      if (this.state.trialApplicable && isFreeRegister === "true") {
        if (selectedValues.duration == this.state.trialDays) {
          price = 0;
        } else {
          price = selectedValues.subscriptionFee;
        }
      } else {
        price = selectedValues.subscriptionFee;
      }
      this.setState({
        showOtherDuration: showOtherDuration,
        selectedChallengeDuration: selectedValues,
        challengeDurationError: false,
        displayPrice: showOtherDuration ? this.state.displayPrice : price,
      });
    } else {
      this.setState({
        selectedChallengeDuration: selectedValues,
        challengeDurationError: true,
        showOtherDuration: false,
        otherDurationError: false,
      });
    }
  };

  handleDailyMinDistanceChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      this.setState({
        selectedDailyMinDistance: selectedValues,
        dailyMinDistanceError: false,
        totalTargetDistanceError: false,
      });
    } else {
      this.setState({
        selectedDailyMinDistance: selectedValues,
        dailyMinDistanceError: true,
      });
    }
  };
  goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  checkAlreadyRegisteredEmail = () => {
    const { email, emailError } = this.state;

    if (email === "" || emailError === true) {
      this.setState({ emailError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter valid email address.",
      });
      this.goToTop();
      return;
    } else {
      let generateOTP = true;
      openEventChallengeUserCheck(generateOTP, email).then((res) => {
        let userDetails = res.data; //JSON.stringify(res?.data);
        userDetails.runner = {
          id: res.data.runnerId,
        };
        this.props.updateUserDetails(userDetails);
        localStorage.setItem("trialApplicable", res?.data?.trialApplicable);

        this.setState({
          existingUser: userDetails?.status,
          firstName: userDetails?.firstName,
          lastName: userDetails?.lastName,
          email: userDetails?.email,
          contactNumber: userDetails?.contactNumber,
          zipcode: userDetails?.pincode,
          trialApplicable: userDetails?.trialApplicable,
        });
      });
      this.setState({
        // showEmailRegistration: false,
        showOTPForm: true,
      });
    }
  };
  handleChangeOTP = (otp) => this.setState({ otp });
  handleResendOTP = () => {
    let generateOTP = true;
    openEventChallengeUserCheck(generateOTP, this.state.email).then((res) => {
      let userDetails = res.data; //JSON.stringify(res?.data);
      userDetails.runner = {
        id: res.data.runnerId,
      };
      this.props.updateUserDetails(userDetails);
      this.setState({
        existingUser: res.data?.status ? res.data?.status : userDetails?.status,
      });
    });
  };
  handleOTPSubmit = () => {
    let requestParams = {
      userName: this.state.email,
      otp: this.state.otp,
    };
    //api for verifying OTP
    verifyOTP(requestParams).then((res) => {
      let data=res.data;
      if(res.data?.runner){
        this.props.updateUserDetails(res.data);

      }else{
        data.runner={
          id:res.data.runnerId
        }
        this.props.updateUserDetails(res.data);

      }
      this.setState(
        {
          showEmailRegistration: false,
          showRegisterForm: true,
          showOTPForm: false,
          showSendOTPFields: false,
          otpVerified: true,
          // existingUser: "ACTIVE",
        }
       
      );
    });
  };
  handleCancelFields = () => {
    if (this.state.existingUser === "INACTIVE") {
      this.setState({
        firstName: "",
        lastName: "",
      });
    }
    this.setState({
      contactNumber: "",
      zipcode: "",
      selectedChallengeDuration: "",
      otherDuration: "",
      otherDurationError: false,
      // selectedDailyMinDistance: "",
      totalTargetDistance: "",
      challengeStartDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    });
  };
  render() {
    const { classes } = this.props;
    const {
      firstName,
      firstNameError,
      lastName,
      lastNameError,
      email,
      emailError,
      countryCodeList,
      countryCode,
      contactNumber,
      contactNumberError,
      selectedChallengeDuration,
      challengeDurationError,
      challengeDurationOptions,
      showOtherDuration,
      otherDuration,
      otherDurationError,
      challengeStartDate,
      challengeStartDateError,
      challengeRegistrationStartDate,
      registrationEndDate,
      DailyMinDistanceOptions,
      dailyMinDistanceError,
      selectedDailyMinDistance,
      totalTargetDistance,
      totalTargetDistanceError,

      zipcode,
      zipcodeError,
      displayPrice,
      paymentStatus,
      trialApplicable,
      trialDaysCount,
      challengeName,
      challengeDetails,
      minFieldDisplayText,
      minFieldHelpText,
      minReadOnly,
      targetReadOnly,
      startDateSetting,
      challengeDurationSetting,
      targetValueSetting,
    } = this.state;
    return (
      <React.Fragment>
        <AppToolBar
          backNavigate={() => this.props.history.push("/challenge")}
          showBack={true}
        >
          <Header
            {...this.props}
            label={challengeName ? challengeName +" Registration" : "Register"}
          />
        </AppToolBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="space-between"
              alignItems="flex-end"
            >
              {this.state.showEmailRegistration && (
                <>
                  <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                      <span className={classes.labelCss}>
                        {Properties.Label_Email}
                      </span>
                      <span className={classes.mandatoryCss}>*</span>
                    </Typography>
                    <CustomTextField
                      style={
                        emailError
                          ? {
                              border: "1px solid red",
                              height: "45px",
                              width: "100%",
                            }
                          : {
                              border: "1px solid #08456C",
                              height: "45px",
                              width: "100%",
                            }
                      }
                      type={"email"}
                      keyToSet={"email"}
                      value={this.state.email}
                      handleChange={(prop) => (event) => {
                        this.validateEmailId(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      color="primary"
                      fullWidth={false}
                      variant="contained"
                      className={classes.btnCss}
                      style={{
                        width: window.innerWidth < "500" ? "auto" : "150px",
                        alignContent: "center",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                      onClick={this.checkAlreadyRegisteredEmail}
                      disabled={this.state.showOTPForm}
                    >
                      Send OTP
                    </Button>
                    {/* <SubmitCancelBtn
                      onClick={this.checkAlreadyRegisteredEmail}
                      label={"Send OTP"}
                      type="submit"
                      isDisabled={this.state.showOTPForm}
                    /> */}
                  </Grid>
                </>
              )}
              {this.state.showOTPForm && (
                <>
                  <Grid item xs={12}>
                    <OtpInput
                      value={this.state.otp}
                      onChange={this.handleChangeOTP}
                      numInputs={6}
                      isInputNum={true}
                      inputStyle={{
                        border: 0,
                        borderBottom: "1px solid black",
                        marginRight: "10px",
                      }}
                      containerStyle={{ justifyContent: "center" }}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.centerCss}>
                    <button
                      style={{
                        fontSize: "11px",
                        height: "20px",
                      }}
                      onClick={this.handleResendOTP}
                    >
                      Resend OTP
                    </button>
                  </Grid>
                  <Grid item xs={12} className={classes.centerCss}>
                    <SubmitCancelBtn
                      onClick={this.handleOTPSubmit}
                      label={"Submit"}
                      type="submit"
                      style={{ width: "auto" }}
                    />
                  </Grid>
                </>
              )}
              {this.state.showRegisterForm && (
                <>
                  {this.state.existingUser === "INACTIVE" && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2" gutterBottom>
                          <span className={classes.labelCss}>First Name</span>
                          <span className={classes.mandatoryCss}>*</span>
                        </Typography>

                        <CustomTextField
                          className={
                            firstNameError
                              ? classes.fieldErrorCss
                              : classes.fieldCss
                          }
                          type={"text"}
                          value={firstName}
                          error={firstNameError}
                          keyToSet={"firstName"}
                          handleChange={(prop) => (event) => {
                            this.setState({
                              firstName: event.target.value,
                              firstNameError: false,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2" gutterBottom>
                          <span className={classes.labelCss}>Last Name</span>
                          <span className={classes.mandatoryCss}>*</span>
                        </Typography>

                        <CustomTextField
                          className={
                            lastNameError
                              ? classes.fieldErrorCss
                              : classes.fieldCss
                          }
                          type={"text"}
                          value={lastName}
                          error={lastNameError}
                          keyToSet={"lastName"}
                          handleChange={(prop) => (event) => {
                            this.setState({
                              lastName: event.target.value,
                              lastNameError: false,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom>
                          <span className={classes.labelCss}>Email</span>
                          <span className={classes.mandatoryCss}>*</span>
                        </Typography>

                        <CustomTextField
                          className={
                            emailError
                              ? classes.fieldErrorCss
                              : classes.fieldCss
                          }
                          style={{ backgroundColor: "#D1D5DB" }}
                          type={"text"}
                          value={email}
                          error={emailError}
                          keyToSet={"email"}
                          handleChange={(prop) => (event) => {
                            this.validateEmailId(event.target.value);
                          }}
                          readOnly={true}
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        spacing={2}
                        xs={12}
                        sm={6}
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                      >
                        <Grid item xs={6}>
                          <Typography variant="body2" gutterBottom>
                            <span className={classes.labelCss}>
                              Contact Number
                            </span>
                            <span className={classes.mandatoryCss}>*</span>
                          </Typography>
                          <MultiSelect
                            value={countryCode}
                            options={countryCodeList}
                            onChange={this.handleCodeChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CustomTextField
                            className={
                              contactNumberError
                                ? classes.fieldErrorCss
                                : classes.fieldCss
                            }
                            type={"number"}
                            isNumber={true}
                            error={contactNumberError}
                            keyToSet={"ContactNumber"}
                            value={contactNumber}
                            handleChange={(prop) => (event) => {
                              this.setState({
                                contactNumber: event.target.value,
                                contactNumberError:
                                  event.target.value.length !== 10
                                    ? true
                                    : false,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2" gutterBottom>
                          <span className={classes.labelCss}>Zip code</span>
                          <span className={classes.mandatoryCss}>*</span>
                        </Typography>
                        <CustomTextField
                          className={
                            zipcodeError
                              ? classes.fieldErrorCss
                              : classes.fieldCss
                          }
                          type={"number"}
                          isNumber={true}
                          value={zipcode}
                          error={zipcodeError}
                          keyToSet={"zipcode"}
                          handleChange={(prop) => (event) => {
                            this.setState({
                              zipcode: event.target.value,
                              zipcodeError: false,
                            });
                          }}
                        />
                      </Grid>
                    </>
                  )}

                  {challengeDurationSetting !== "HIDE" && (
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body2"
                        gutterBottom
                        className={classes.helpContainerCss}
                      >
                        <span className={classes.labelCss}>
                          Challenge Duration
                        </span>
                        {challengeDurationSetting === "MANDATORY" && (
                          <span className={classes.mandatoryCss}>*</span>
                        )}{" "}
                        <span className={classes.helpCss}>
                          <Tooltip
                            title={Properties.ChallengeDurationHelpText}
                            leaveTouchDelay={10000}
                            enterTouchDelay={50}
                          >
                            <div>
                              <HelpIcon fontSize="small" />
                            </div>
                          </Tooltip>
                        </span>
                      </Typography>

                      {showOtherDuration ? (
                        <Grid container spacing={2}>
                          <Grid xs={8} item>
                            <MultiSelect
                              value={selectedChallengeDuration}
                              error={challengeDurationError}
                              options={challengeDurationOptions}
                              onChange={this.handleChallengeDurationChange}
                            />
                          </Grid>
                          <Grid xs={4} item>
                            <CustomTextField
                              style={
                                otherDurationError
                                  ? {
                                      border: "1px solid red",
                                      height: "37px",
                                      width: "100%",
                                    }
                                  : {
                                      border: "1px solid #08456C",
                                      height: "37px",
                                      width: "100%",
                                    }
                              }
                              type={"number"}
                              value={otherDuration}
                              error={otherDurationError}
                              keyToSet={"otherDuration"}
                              handleMouseOut={(prop) => (event) => {
                                this.validateDurationField(event.target.value);
                              }}
                              handleChange={(prop) => (event) => {
                                this.handleDurationFieldChange(event);
                              }}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <MultiSelect
                          value={selectedChallengeDuration}
                          error={challengeDurationError}
                          options={challengeDurationOptions}
                          onChange={this.handleChallengeDurationChange}
                        />
                      )}
                    </Grid>
                  )}

                  {startDateSetting !== "HIDE" && (
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body2"
                        gutterBottom
                        className={classes.helpContainerCss}
                      >
                        <span className={classes.labelCss}>
                          Challenge Start Date
                        </span>
                        {startDateSetting === "MANDATORY" && (
                          <span className={classes.mandatoryCss}>*</span>
                        )}
                        <span className={classes.helpCss}>
                          <Tooltip
                            title={Properties.ChallengeStartDateHelpText}
                            leaveTouchDelay={10000}
                            enterTouchDelay={50}
                          >
                            <div>
                              <HelpIcon fontSize="small" />
                            </div>
                          </Tooltip>
                        </span>
                      </Typography>

                      <KeyboardDatePicker
                        className={classes.fieldCss}
                        autoOk //={false}
                        clearable
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        value={challengeStartDate}
                        minDate={challengeRegistrationStartDate}
                        minDateMessage={`Challenge Date cannot be before ${moment(
                          challengeRegistrationStartDate
                        ).format("DD-MM-YYYY")}`}
                        maxDate={
                          registrationEndDate
                            ? // registrationEndDate
                              // moment(registrationEndDate).format("YYYY-MM-DD")
                              ApplicationUtil.changeDateFormatDate(
                                registrationEndDate
                              )
                            : undefined
                        }
                        // {registrationEndDate} //{"2022-01-24"} //
                        maxDateMessage={
                          registrationEndDate
                            ? `Challenge Date cannot be after ${
                                //moment(
                                registrationEndDate
                                //).format("DD-MM-YYYY")
                              }`
                            : null
                        }
                        InputProps={{
                          classes: {
                            root: challengeStartDateError
                              ? classes.datePickerError
                              : classes.datePicker,
                          },
                          readOnly:
                            startDateSetting === "READ_ONLY" ? true : false,
                        }}
                        keyboardIcon={
                          <IconCalendarBlue
                            className={classes.calendarIconCss}
                          />
                        }
                        readOnly={
                          startDateSetting === "READ_ONLY" ? true : false
                        }
                        onChange={(date) => {
                          this.setState({
                            challengeStartDate: date,
                            challengeStartDateError: false,
                          });
                        }}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    // spacing={2}
                    xs={12}
                    direction="row"
                    justify="space-between"
                    alignItems="flex-end"
                  >
                    <Grid item xs={12} sm={5}>
                      <Typography
                        variant="body2"
                        gutterBottom
                        className={classes.helpContainerCss}
                      >
                        {/* {challengeDetails?.type == 1 ||
                        challengeDetails?.type == 2 ||
                        challengeDetails?.type == 3 ? (
                          <span className={classes.labelCss}>
                            Daily Minimum Distance
                          </span>
                        ) : (
                          <span className={classes.labelCss}>
                            Daily Minimum Steps
                          </span>
                        )} */}
                        <span className={classes.labelCss}>
                          {minFieldDisplayText}
                        </span>
                        <span className={classes.helpCss}>
                          <Tooltip
                            title={minFieldHelpText}
                            leaveTouchDelay={10000}
                            enterTouchDelay={50}
                          >
                            <div>
                              <HelpIcon fontSize="small" />
                            </div>
                          </Tooltip>
                        </span>
                      </Typography>

                      <MultiSelect
                        value={selectedDailyMinDistance}
                        error={dailyMinDistanceError}
                        options={DailyMinDistanceOptions}
                        onChange={this.handleDailyMinDistanceChange}
                        isReadOnly={minReadOnly}
                      />
                    </Grid>
                    {targetValueSetting !== "HIDE" && (
                      <>
                        <Grid item xs={12} sm={2}>
                          <Typography
                            variant="body2"
                            gutterBottom
                            className={classes.centerCss}
                            style={{ marginTop: isMobile ? "4px" : "0px" }}
                          >
                            <span className={classes.labelCss}>OR</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <Typography
                            variant="body2"
                            gutterBottom
                            className={classes.helpContainerCss}
                          >
                            {challengeDetails?.type == 1 ||
                            challengeDetails?.type == 2 ||
                            challengeDetails?.type == 3 ? (
                              <span className={classes.labelCss}>
                                Total Target distance (KM)
                              </span>
                            ) : (
                              <span className={classes.labelCss}>
                                Total Target Steps
                              </span>
                            )}
                            {targetValueSetting === "MANDATORY" && (
                              <span className={classes.mandatoryCss}>*</span>
                            )}
                            <span className={classes.helpCss}>
                              <Tooltip
                                title={Properties.TotalTargetHelpText}
                                leaveTouchDelay={10000}
                                enterTouchDelay={50}
                              >
                                <div>
                                  <HelpIcon fontSize="small" />
                                </div>
                              </Tooltip>
                            </span>
                          </Typography>

                          <CustomTextField
                            className={
                              totalTargetDistanceError
                                ? classes.fieldErrorCss
                                : classes.fieldCss
                            }
                            type={"number"}
                            isNumber={true}
                            value={totalTargetDistance}
                            error={totalTargetDistanceError}
                            keyToSet={"totalTargetDistance"}
                            handleChange={(prop) => (event) => {
                              this.setState({
                                totalTargetDistance: event.target.value,
                                totalTargetDistanceError: false,
                                dailyMinDistanceError: false,
                              });
                            }}
                            readOnly={targetReadOnly}
                            allowDecimal={
                              challengeDetails?.type == 1 ||
                              challengeDetails?.type == 2 ||
                              challengeDetails?.type == 3
                                ? true
                                : false
                            }
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid
                    style={{
                      // marginLeft: "6.7%",
                      height: "50px",
                      // borderTop: "1px solid rgb(13 108 169)",
                    }}
                    item
                    xs={12}
                  >
                    {/* <Grid item xs={12}> */}
                    <span style={{ fontSize: "16px" }}>
                      <input
                        type="checkbox"
                        onChange={this.handleCheckBoxChange}
                        value={this.state.termsCheckboxSelected}
                        required
                      />
                      I agree to the
                      <a
                        href="https://necessarydevil.com/terms"
                        target="_blank"
                      >
                        {" "}
                        Terms and Conditions.
                      </a>{" "}
                    </span>
                    {/* </Grid> */}
                  </Grid>

                  {/* <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom>
                      <span className={classes.highlightedText}>Free</span>
                    </Typography>
                  </Grid> */}
                  <Grid item xs={6} className={classes.centerCss}>
                    <SubmitCancelBtn
                      onClick={(e) => this.handleCancelFields()}
                      label={"Cancel"}
                      type="submit"
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.centerCss}>
                    <SubmitCancelBtn
                      onClick={(e) => this.validateFields()}
                      label={"Register"}
                      type="submit"
                      isDisabled={!this.state.termsCheckboxSelected}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        </main>
        {this.state.showRegisterForm && (
          <footer className={classes.footer}>
            <Paper className={classes.footerPaper}>
              {(trialApplicable &&
                Number(selectedChallengeDuration?.value) === trialDaysCount) ||
              displayPrice == 0 ? (
                <span
                  className={classes.highlightedText}
                  style={{ marginTop: "20px" }}
                >
                  Free
                </span>
              ) : (
                <span className={classes.highlightedPrice}>
                  {/* <span>
                    <RuppeeIcon style={{ fontSize: "25px" }} />
                  </span>
                  <span>{displayPrice}/-</span>
                  <br />
                  <span style={{ fontSize: "18px" }}>
                    {Properties.Label_EventDetails_Bookings_Inclu}
                  </span> */}
                </span>
              )}
            </Paper>
          </footer>
        )}

        <DialogBox
          signup={true}
          open={this.state.openDialog}
          message={this.state.dialogMsg}
          header={this.state.dialogHeader}
          onClick={() => {
            this.handleDeleteRegistration(this.state.selectedId);
          }}
          label={"Cancel"} //delete registration
          onClickTwo={() => {
            this.closeWindow();
          }} //
          isInvitationScreen={true}
          labelTwo={"Pay Later"}
          closeDisloag={() => this.closeWindow()}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userState: state.user,

    userSelectedEvent: state.user.userSelectedEvent,
    userPreferences: state.user.userPreferences,
  };
}
export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    updateUserDetails,
    updateUserSelectedEvent,
  }),
  withRouter
)(ChallengeRegistration);
