import React from 'react'
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import NameAndBib from '../NameAndBib';
import ResponseResultTable from './ResponseResultTable';

function ChallengeResponseResult({classes, data}) {

  return (
    <div className={classes.results}>
        <div className={classes.results_block1}>
          <NameAndBib
            firstName={data?.firstName}
            lastName={data?.lastName}
            bib={data?.bibNumber}
            profileImage={data?.profileLink ? data.profileLink : ""}
          />
          <div className={classes.results_block1_responseStatus}>
            {data && (
              <ResponseResultTable data ={data}/>
            )}
          </div>
        </div>
      </div>
  )
}

const styles = (theme) => ({
    results: {
      margin: "20px 0px",
    },
    results_block1: {
      width: "90%",
      margin: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row",
      flexWrap: "wrap",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    results_block1_responseStatus: {
      width: "60%",
      [theme.breakpoints.down("sm")]: {
        width: "95%",
        margin: "auto",
        marginTop: "20px",
      },
    },
  });

  export default compose(
    withRouter,
    withStyles(styles, { withTheme: true })
  )(ChallengeResponseResult);
  