import {
  Card,
  CardActionArea,
  CardActions,
  CardHeader,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import MultiSelect from "../../../../../../common/MultiSelect";
import Properties from "../../../../../../../properties/Properties";
import { compose } from "recompose";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import InlineDivider from "../../../../../../common/InlineDivider";
import ApplicationUtil from "../../../../../../../utils/ApplicationUtil";
import SubmitCancelBtn from "../../../../../../common/SubmitCancelBtn";
import { CustomTextField } from "../../../../../../common/CustomTextField";
import { FileUploader } from "../../../../../../common/FileUploader";

const StyledCardMedia = withStyles({
  img: {
    objectFit: "unset",
  },
})(CardMedia);

const CertificatesView = ({ classes }) => {
  const [certificateState, setCertificateState] = useState({
    deliverableItemList: [{ label: "CERTIFICATE", value: "CERTIFICATE" }],
    selectedDeliverableItem: null,
    certificateSign_1: {
      keyName: "CER_SIGN_1",
      imageURL: null,
      label: "Signature Image ",
      width: "95",
      height: "42", //w*h
      exactSize: true,
    },
    certificateSign_2: {
      keyName: "CER_SIGN_2",
      imageURL: null,
      label: "Signature Image",
      width: "95",
      height: "42",
      exactSize: true,
    },
    certificateLogo: {
      keyName: "CER_ORG_LOGO_1",
      imageURL: null,
      label: "Event Logo",
      width: "100 ",
      height: "80", //w*h
      exactSize: true,
    },
    certSigningAuthority1Name: "",
    certSigningAuthority2Name: "",
    certSigningAuthority1Designation: "",
    certSigningAuthority2Designation: "",
    certificateBackgrounds: [],
  });

  let {
    certificateLogo,
    deliverableItemList,
    selectedDeliverableItem,
    certificateSign_1,
    certificateSign_2,
    certSigningAuthority1Name,
    certSigningAuthority2Name,
    certSigningAuthority1Designation,
    certSigningAuthority2Designation,
    certificateBackgrounds,
  } = certificateState;

  const handleDeliverableItemChange = (e) => {
    setCertificateState((prev) => ({ ...prev, selectedDeliverableItem: e }));
  };
  return (
    <div style={{ padding: "20px 0" }}>
      <Grid container spacing={1} style={{ marginBottom: "30px" }}>
        <Grid
          item
          // xs={6}
        >
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>
              {Properties.DeliverableItemLabel}
            </span>
          </Typography>
          <div className={classes.parentDivCss}>
            <div
              style={{
                width: "360px", // { selectedDeliverableItem.length < 2 && "360px"},
                maxHeight: "60px",
                height: "40px",
                minHeight: "40px",
              }}
              className={classes.childDivCss}
            >
              <MultiSelect
                isMulti={true}
                value={selectedDeliverableItem}
                // error={deliverableItemError}
                options={deliverableItemList}
                onChange={(e) => {
                  handleDeliverableItemChange(e);
                }}
                // isReadOnly={isPastEvent}
              />
            </div>
          </div>
        </Grid>
      </Grid>

      {selectedDeliverableItem?.length &&
        selectedDeliverableItem?.map((row, index) => {
          return (
            <div key={index + 1}>
              <InlineDivider
                style={{ height: "5px", backgroundColor: "#08456C" }}
              >
                {ApplicationUtil.getCamelCasing(row.value)}{" "}
              </InlineDivider>

              {row.label == "CERTIFICATE" && (
                <div>
                  {/* sign1 */}
                  <Grid
                    container
                    style={{
                      margin: "10px 5px",
                    }}
                  >
                    <Grid item xs={4} className={classes.innerGridCss}>
                      <Typography variant="body2" gutterBottom>
                        <span className={classes.labelCss}>
                          {Properties.signAuthorityNameLabel}
                        </span>
                        <span className={classes.mandatoryCss}>*</span>
                      </Typography>
                      <CustomTextField
                        type={"text"}
                        value={certSigningAuthority1Name}
                        className={
                          // certSigningAuthority1_authorityNameError
                          //   ? classes.fieldErrorCss
                          //   :
                          classes.fieldCss
                        }
                        handleChange={(prop) => (e) => {
                          setCertificateState((prev) => ({
                            ...prev,
                            certSigningAuthority1Name: e.target.value,
                            certSigningAuthority1_authorityNameError: false,
                          }));
                        }}
                        // readOnly={isPastEvent}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.innerGridCss}>
                      <Typography variant="body2" gutterBottom>
                        <span className={classes.labelCss}>
                          {Properties.signAuthorityDesignationLabel}
                        </span>
                        <span className={classes.mandatoryCss}>*</span>
                      </Typography>
                      <CustomTextField
                        type={"text"}
                        value={certSigningAuthority1Designation}
                        className={
                          // certSigningAuthority1_authorityDesignationError
                          //   ? classes.fieldErrorCss
                          //   :
                          classes.fieldCss
                        }
                        handleChange={(prop) => (e) => {
                          setCertificateState((prev) => ({
                            ...prev,
                            certSigningAuthority1Designation: e.target.value,
                            certSigningAuthority1_authorityDesignationError: false,
                          }));
                        }}
                        // readOnly={isPastEvent}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.typographyCss}>
                        {" "}
                        <span className={classes.labelCss}>
                          Signature Image
                        </span>
                        <span className={classes.mandatoryCss}>*</span>
                      </Typography>{" "}
                      {certificateSign_1.imageURL ? (
                        <Grid>
                          <div className={classes.smallImgContainer}>
                            {/* {!isPastEvent && (
                              <div className={classes.clearImgContainer}>
                                <ClearIcon
                                  style={{ color: "red", fontSize: "20px" }}
                                  onClick={() =>
                                    this.handleImageRemove("CER_SIGN_1")
                                  }
                                />
                              </div>
                            )}{" "} */}
                            <div className={classes.smallProfileImgContainer}>
                              <img
                                className={classes.signImgCss}
                                src={certificateSign_1.imageURL}
                                alt={certificateSign_1.label}
                                onClick={() => {
                                  // this.setState({
                                  //   dialogTitle: "Signature",
                                  //   showImage: true,
                                  //   imagePath: certificateSign_1.imageURL,
                                  //   dialogTitle: certificateSign_1.label,
                                  // });
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        <Grid>
                          <FileUploader
                            selectedRow={certificateSign_1}
                            maxFiles={1}
                            multiple={false}
                            filesUploaded={
                              (e) => this.handleUploadedFiles(e) //(e, data);
                            }
                            isSmall={true}
                            // isReadOnly={isPastEvent}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {/* sign2 */}
                  <Grid
                    container
                    style={{
                      margin: "10px 5px",
                    }}
                  >
                    <Grid item xs={4} className={classes.innerGridCss}>
                      <Typography variant="body2" gutterBottom>
                        <span className={classes.labelCss}>
                          {Properties.signAuthorityNameLabel}
                        </span>
                        {/* <span className={classes.mandatoryCss}>*</span> */}
                      </Typography>
                      <CustomTextField
                        type={"text"}
                        value={certSigningAuthority2Name}
                        className={
                          // certSigningAuthority2_authorityNameError
                          //   ? classes.fieldErrorCss
                          //   :
                          classes.fieldCss
                        }
                        handleChange={(prop) => (e) => {
                          setCertificateState((prev) => ({
                            ...prev,
                            certSigningAuthority2Name: e.target.value,
                            certSigningAuthority2_authorityNameError: false,
                          }));
                        }}
                        // readOnly={isPastEvent}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.innerGridCss}>
                      <Typography variant="body2" gutterBottom>
                        <span className={classes.labelCss}>
                          {Properties.signAuthorityDesignationLabel}
                        </span>
                        {/* <span className={classes.mandatoryCss}>*</span> */}
                      </Typography>
                      <CustomTextField
                        type={"text"}
                        value={certSigningAuthority2Designation}
                        className={
                          // certSigningAuthority2_authorityDesignationError
                          //   ? classes.fieldErrorCss
                          //   :
                          classes.fieldCss
                        }
                        handleChange={(prop) => (e) => {
                          setCertificateState((prev) => ({
                            ...prev,
                            certSigningAuthority2Designation: e.target.value,
                            certSigningAuthority2_authorityDesignationError: false,
                          }));
                        }}
                        // readOnly={isPastEvent}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.typographyCss}>
                        {" "}
                        <span className={classes.labelCss}>
                          Signature Image
                        </span>
                        {/* <span className={classes.mandatoryCss}>*</span> */}
                      </Typography>{" "}
                      {certificateSign_2.imageURL ? (
                        <Grid>
                          <div className={classes.smallImgContainer}>
                            {/* {!isPastEvent && (
                              <div className={classes.clearImgContainer}>
                                <ClearIcon
                                  style={{ color: "red" }}
                                  onClick={() =>
                                    this.handleImageRemove("CER_SIGN_2")
                                  }
                                />
                              </div>
                            )} */}
                            <div className={classes.smallProfileImgContainer}>
                              <img
                                className={classes.signImgCss}
                                src={certificateSign_2.imageURL}
                                alt={certificateSign_2.label}
                                onClick={() => {
                                  // this.setState({
                                  //   dialogTitle: "Signature",
                                  //   showImage: true,
                                  //   imagePath: certificateSign_2.imageURL,
                                  //   dialogTitle: certificateSign_2.label,
                                  // });
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        <Grid>
                          <FileUploader
                            selectedRow={certificateSign_2}
                            maxFiles={1}
                            multiple={false}
                            filesUploaded={
                              (e) => this.handleUploadedFiles(e) //(e, data);
                            }
                            isSmall={true}
                            // isReadOnly={isPastEvent}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {/* logo */}
                  <Grid
                    container
                    style={{
                      margin: "10px 5px",
                    }}
                  >
                    <Grid item xs={6} className={classes.fileGridCss}>
                      <Typography className={classes.typographyCss}>
                        <span className={classes.labelCss}>
                          Certificate Logo
                        </span>
                        <span className={classes.mandatoryCss}>*</span>
                      </Typography>
                      {certificateLogo?.imageURL ? (
                        <Grid>
                          <div className={classes.imgContainer}>
                            {/* {!isPastEvent && (
                              <div className={classes.clearImgContainer}>
                                <ClearIcon
                                  style={{ color: "red" }}
                                  onClick={() =>
                                    this.handleImageRemove("CER_ORG_LOGO_1")
                                  }
                                />
                              </div>
                            )} */}
                            <div className={classes.profileImgContainer}>
                              <img
                                className={classes.profileImg}
                                src={certificateLogo?.imageURL}
                                alt={certificateLogo?.label}
                                onClick={() => {
                                  // this.setState({
                                  //   dialogTitle: "Logo",
                                  //   showImage: true,
                                  //   imagePath: certificateLogo?.imageURL,
                                  //   dialogTitle: certificateLogo?.label,
                                  // });
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        <Grid>
                          <FileUploader
                            selectedRow={certificateLogo}
                            maxFiles={1}
                            multiple={false}
                            filesUploaded={
                              (e) => this.handleUploadedFiles(e) //(e, data);
                            }
                            // isReadOnly={isPastEvent}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {/* activity specific */}

                  <div
                    style={{
                      margin: "10px 0px",
                      padding: "10px",
                      border: "1px solid gray",
                    }}
                    // key={activityIndex}
                  >
                    <Typography variant="body2" gutterBottom>
                      <span
                        style={{
                          fontSize: "22px",
                          fontWeight: "600",
                          color: "#727272",
                        }}
                      ></span>
                    </Typography>

                    <Grid container>
                      {/* templates */}

                      <Grid
                        item
                        xs={4}
                        // key={templateIndex}
                        spacing={3}
                        style={{ padding: "0px 4px" }}
                      >
                        <Card className={classes.templateCardCss}>
                          <CardHeader
                            subheader={
                              // ApplicationUtil.getCamelCasing(
                              // template?.templateName
                              // )
                              "Hello"
                            }
                            className={classes.labelCss}
                            style={{ marginBottom: "4px" }}
                          />
                          <CardActionArea>
                            <StyledCardMedia
                              component="img"
                              // alt={template?.templateName}
                              height="200"
                              image={"data:image/png;base64,"}
                              onClick={() => {
                                // this.setState({
                                //   templateDialogTitle:
                                //     template?.templateName,
                                //   showTemplate: true,
                                //   templateMessage: ReactHtmlParser(
                                //     template?.templateHtmlContent
                                //   ),
                                // });
                              }}
                            />
                          </CardActionArea>
                          <CardActions>
                            <FormControl error={true} required>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e) => {
                                      // this.handleTemplateSelection(
                                      //   e,
                                      //   activity,
                                      //   template?.id,
                                      //   template?.emailInlineImages
                                      // );
                                    }}
                                    color="primary"
                                    // value={
                                    //   template?.id == activity?.selectedTemplate
                                    //     ? true
                                    //     : false
                                    // }
                                    // checked={
                                    //   template?.id == activity?.selectedTemplate
                                    //     ? true
                                    //     : false
                                    // }
                                  />
                                }
                              />
                            </FormControl>
                          </CardActions>
                        </Card>
                      </Grid>

                      {/* uploads */}
                      <Grid container style={{ padding: "8px 0px" }}>
                        <Grid item xs={6} className={classes.fileGridCss}>
                          <Typography variant="body2" gutterBottom>
                            <span
                              className={classes.labelCss}
                              style={{ fontSize: "20px" }}
                            >
                              Custom Theme
                            </span>
                          </Typography>

                          <Grid>
                            <FileUploader
                              selectedRow={certificateSign_1}
                              maxFiles={1}
                              multiple={false}
                              filesUploaded={(e) => {
                                // this.handleUploadedFiles(e);
                                console.log(e);
                                // let newActivityType = activityType;
                                // if (
                                //   activity?.certificateBackground
                                //     ?.keyName === "CER_BACKGROUND"
                                // ) {
                                //   let item = newActivityType.findIndex(
                                //     (obj) => obj?.type === "RUN"
                                //   );
                                //   newActivityType[
                                //     item
                                //   ].customeTheme = true;
                                // } else if (
                                //   activity?.certificateBackground
                                //     ?.keyName === "CYCLING_CER_BGD"
                                // ) {
                                //   let item = newActivityType.findIndex(
                                //     (obj) => obj?.type === "CYCLE"
                                //   );
                                //   newActivityType[
                                //     item
                                //   ].customeTheme = true;
                                // } else if (
                                //   activity?.certificateBackground
                                //     ?.keyName === "STEP_CER_BGD"
                                // ) {
                                //   let item = newActivityType.findIndex(
                                //     (obj) => obj?.type === "STEPS"
                                //   );
                                //   newActivityType[
                                //     item
                                //   ].customeTheme = true;
                                // }
                              }}
                              // isReadOnly={isPastEvent}
                            />
                          </Grid>
                        </Grid>

                        {/* flags */}
                        <Grid
                          item
                          xs={6}
                          container
                          style={{ padding: "5px 0px" }}
                        >
                          <span
                            className={classes.labelCss}
                            style={{ fontSize: "20px" }}
                          >
                            Settings
                          </span>
                          <div
                            style={{
                              // marginTop: "-50px",
                              margin: "20px 0px 0px -60px",
                              display: "grid",
                              gridTemplateColumns: "140px 140px ",
                              gridTemplateRows: "40px 40px",
                            }}
                          >
                            {/* {activity?.flagInputs?.map(
                              (flag, flagIndex) =>
                                flag?.show && (
                                  <div
                                    item
                                    xs={6}
                                    key={flagIndex}
                                    // className={classes.innerGridCss}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          onChange={(e) =>
                                            this.handleFlagChange(
                                              e,
                                              activity,
                                              flag
                                            )
                                          }
                                          color="primary"
                                          value={flag?.value}
                                          checked={flag?.value}
                                        />
                                      }
                                    />
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        color: "#727272",
                                      }}
                                    >
                                      {flag?.label}
                                    </span>
                                  </div>
                                )
                            )} */}
                          </div>
                        </Grid>

                        {/* flags */}
                        {/* <Grid
                              item
                              xs={6}
                              container
                              style={{ padding: "5px 0px" }}
                            >
                              <span className={classes.labelCss}>Settings</span>
                              <Grid
                                container
                                // style={{ marginTop: "-90px" }}
                              >
                                {activity?.flagInputs?.map(
                                  (flag, flagIndex) =>
                                    flag &&
                                    flag.show && (
                                      <Grid
                                        item
                                        xs={6}
                                        key={flagIndex}
                                        // className={classes.innerGridCss}
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              onChange={(e) =>
                                                this.handleFlagChange(
                                                  e,
                                                  activity,
                                                  flag
                                                )
                                              }
                                              color="primary"
                                              value={flag?.value}
                                              checked={flag?.value}
                                            />
                                          }
                                        />
                                        <span className={classes.labelCss}>
                                          {flag?.label}
                                        </span>
                                      </Grid>
                                    )
                                )}
                              </Grid>
                            </Grid>
                           */}
                      </Grid>

                      <Grid
                        container
                        style={{
                          margin: "10px 5px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Grid
                          item
                          xs={4}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <SubmitCancelBtn
                            // onClick={(e) => this.validateFields(activity)}
                            label={"Preview"}
                            type="submit"
                            style={{ width: "200px" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}

              <Grid
                container
                style={{
                  margin: "10px 5px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <SubmitCancelBtn
                    // onClick={(e) => this.saveAllDetails(row)}
                    label={"Save All"}
                    type="submit"
                    style={{ width: "200px" }}
                    // isDisabled={!this.state.showSaveAll}
                  />
                </Grid>
              </Grid>
            </div>
          );
        })}
    </div>
  );
};

const styles = (theme) => ({
  typographyCss: {
    paddingBottom: "4px",
    fontSize: "18px",
    color: "#727272",
  },
  fileGridCss: { padding: "5px 0px" },
  actionCss: {
    color: "#F79824",
  },
  imgContainer: {
    position: "relative",
    width: "200px",
    textAlign: "center",
    padding: "40px 20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  smallImgContainer: {
    position: "relative",
    width: "200px",
    height: "120px",
    textAlign: "center",
    padding: "10px 5px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  clearImgContainer: {
    position: "absolute",
    top: "1px",
    right: "1px",
  },
  labelCss: {
    color: "#727272",
    fontSize: "18px",
    textTransform: "capitalize",
  },
  mandatoryCss: { color: "red" },

  fieldCss: {
    border: "1px solid #08456C",
    height: "40px", //"45%",
    maxHeight: "40px",
    minHeight: "40px",
    // width: isBrowser ? "360px" : "160px",
  },
  fieldErrorCss: {
    border: "1px solid red",
    height: "40px",
    maxHeight: "40px",
    minHeight: "40px",
    // width: isBrowser ? "360px" : "160px",
  },
  profileImgContainer: {
    // width: "120px",
    height: "120px",
  },
  smallProfileImgContainer: {
    // width: "120px",
    height: "60px",
  },

  profileImg: {
    width: "200px",
    height: "120px",
    objectFit: "contain",
    margin: "auto",
    cursor: "pointer",
  },
  signImgCss: {
    width: "100px",
    height: "50px",
    objectFit: "contain",
    margin: "auto",
    cursor: "pointer",
    paddingTop: "10px",
  },

  parentDivCss: {
    height: "40px",
  },
  childDivCss: {
    position: "absolute",
    // zIndex: "1",
  },
  checkboxDivCss: {
    border: "1px solid red",
    height: "20px",
    width: "20px",
  },
  checkboxCss: {
    height: "20px",
    width: "20px",
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(CertificatesView);
