import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import NameAndBib from "./NameAndBib";
import Timing from "./Timing";
import Rank from "./Rank";
import RaceDetails from "./RaceDetails";
import SubmitCancelBtn from "./../../../../common/SubmitCancelBtn";
import GetAppIcon from "@material-ui/icons/GetApp";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import { downloadViewResultsCertificateFile } from "./../../../../../apis/eventService";
import Properties from "../../../../../properties/Properties";
import PlayVideo from "./PlayVideo";
import { withRouter } from "react-router-dom";
const styles = (theme) => ({
  results: {
    margin: "20px 0px",
  },
  results_block1: {
    width: "90%",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  results_block1_timeRank: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      margin: "auto",
      marginTop: "20px",
    },
  },
  results_block2: {
    width: "90%",
    margin: "auto",
    marginTop: "10px",
  },
  results_btns: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
});

class Results extends Component {
  constructor(props) {
    super(props);

    this.state = {
      raceDetails: null,
    };
  }

  downloadCertificate = () => {
    const { data } = this.props;
    let requestParams = {
      eventId: data.eventId,
      runnerId: data.runnerId,
    };
    downloadViewResultsCertificateFile(requestParams);
  };

  render() {
    const { classes, data, type, isProgram } = this.props;
    return (
      <div className={classes.results}>
        <div className={classes.results_block1}>
          <NameAndBib
            firstName={data?.firstName}
            lastName={data?.lastName}
            bib={data?.bibNumber}
            profileImage={data?.profileLink ? data.profileLink : ""}
          />

          <div className={classes.results_block1_timeRank}>
            {data && <Timing data={data} />}

            {!isProgram &&
              (data?.genderWiseRank ||
                data?.overAllRank ||
                data?.ageWiseRank) && (
                <Rank
                  overall={data?.overAllRank}
                  ageWise={data?.ageWiseRank}
                  genderWise={data?.genderWiseRank}
                  gender={data.gender}
                />
              )}
          </div>
        </div>
        {type !== "CHALLENGE" && (
          <div className={classes.results_block2}>
            {data?.activityDetails &&
              (data.activityDetails.some((x) => x.activitySplits) ? (
                <RaceDetails
                  details={data.activityDetails}
                  activityType={data.activityType}
                />
              ) : (
                <></>
              ))}
          </div>
        )}
        {/* <div className={classes.results_btns}>
          <span
            style={{
              padding: "10px",
            }}
          >
            {data?.certificateStatus === "AVAILABLE" && (
              <SubmitCancelBtn
                type="submit"
                label={Properties.DownloadCertificate_label}
                onClick={() => this.downloadCertificate()}
                endIcon={<GetAppIcon />}
              />
            )}
          </span>

          <span
            style={{
              padding: "10px",
            }}
          >
            {data?.videoStreamUrl && <PlayVideo url={data.videoStreamUrl} />}
          </span>
        </div> */}
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(Results);
