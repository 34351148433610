import React from "react";
import SubmitCancelBtn from "./SubmitCancelBtn";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { FilterSVGIcon, IconCalendarBlue } from "./SvgIcon";
import {
  Typography,
  Box,
  Drawer,
  Accordion,
  AccordionSummary,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import AccordionFilter from "./AccordionFilter";
import { CheckBox } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const styles = (theme) => ({
  drawer: {
    padding: theme.spacing(2),
    width: "250px",
    role: "presentation",
    overflowX: "scroll",
    height: "100vh",
    position: "relative",
  },
  accordionStyling: {
    margin: "0",
    padding: "0",
    width: "100%",
  },
  accordionSummaryStyling: {
    padding: "0",
    margin: "0px 0px 0px 5px",
    fontWeight: "bold",
  },
  filterLabelStyling: {
    margin: "0",
    padding: 0,
    display: "flex",
    fontWeight: "bold",
    fontSize: "1.5rem",
  },
  filterStyling: {
    margin: "0 0 0 5px",
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filtericonStyling: {
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "5px 10px 15px 0px",
    color: "#09456C",
  },
  applyBtn: {
    position: "fixed",
    lineHeight: "45px",
    bottom: "3%",
    right: "2%",
    backgroundColor: "white",
    width: "200px",
  },
});

function DrawerFilter(props) {
  const { classes } = props;
  const data = props.filterData;
  const [updatedData, setUpdatedData] = useState(null);
  const handleChange = (value, id) => {
    
    if (!!value) {
      const updatedDetails = data?.map((x) => {
        if (x.id === id) {
          x.selectedValue = value;
        }
        return x;
      });
      // const dataItem = data.find((item) => item.id === id);
      // dataItem.selectedValue = value;
      setUpdatedData(updatedDetails);
      //  console.log("data.selectedValue",data.selectedValue)
      // props.onChange(data);
    }
  };

  return (
    <Drawer anchor="right" open={props.open} onClose={props.onClose}>
      <Box className={classes.drawer}>
        <Typography>
          <span className={classes.filterLabelStyling}>
            <span>
              <FilterSVGIcon className={classes.filtericonStyling} />
            </span>
            <span>Filters</span>
          </span>

          <>
            {data?.map((x) => (
              <Accordion>
                <AccordionSummary
                  aria-aria-controls="panel1-content"
                  expandIcon={<KeyboardArrowDownIcon />}
                  className={classes.accordionSummaryStyling}
                >
                  {x.label}
                </AccordionSummary>

                {x.btnType === "RADIO" ? (
                  <RadioGroup
                    name={`${x.label}-radio-buttons-group`}
                    value={x.selectedValue || x.items[0]?.value}
                    onChange={(event) => handleChange(event.target.value, x.id)}
                  >
                    {x.items.map((item) => (
                      <FormControlLabel
                        style={{
                          marginLeft: "5px",
                          marginRight: "5px",
                          justifyContent: "space-between",
                        }}
                        key={item.id}
                        value={item.value}
                        control={<Radio color="primary" />}
                        labelPlacement="start"
                        label={item.label}
                      />

                      // <AccordionFilter
                      //   id={item.id}
                      //   val={item.value}
                      //   label={item.label}
                      //   data={data}
                      //   onChange={handleChange}
                      //   selectedValue={data.selectedValue}
                      // />
                    ))}
                  </RadioGroup>
                ) : (
                  <div>
                    {x?.items?.map((item) => (
                      <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                        <span>{item.label}</span>
                        <CheckBox />
                      </div>
                    ))}
                  </div>
                )}
              </Accordion>
            ))}
          </>
        </Typography>

        <div style={{ lineHeight: "5" }}>
          <SubmitCancelBtn
            label={"Apply"}
            type="submit"
            onClick={() => {
              props.onApplyFilter(updatedData);
              props.onClose();
            }}
          />
          <button type="submit" style={{ display: "none" }}></button>
        </div>
      </Box>{" "}
    </Drawer>
  );
}
function mapStateToProps(state) {
  return {
    participantFilters: state.supportUser.filters.participantListFilters,
  };
}
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps)
)(DrawerFilter);
