import React, { useState } from "react";
import Properties from "../../../../../properties/Properties";
import DialogBox from "../../../../common/DialogBox";
import {
  Badge,
  Box,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { ArrowDropDownCircleOutlined, Forum } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { deleteChallengeActivity } from "../../../../../apis/challengeService";
import Snackbar from "../../../../common/Snackbar";
import SubmitCancelBtn from "../../../../common/SubmitCancelBtn";
import { addActivityFeedback } from "../../../../../apis/eventService";
import ApplicationUtil from "../../../../../utils/ApplicationUtil";

function UserBasedActivityList({ classes, data, setIsProgramChallenge }) {
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkboxArr, setCheckboxArr] = useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState("");
  const [anchorEl, setAnchorEl] = useState("");
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [challengeResponseId, setChallengeResponseId] = useState([]);
  const [details, setDetails] = useState({
    openFeedbackForm: false,
    activityId: null,
    activityDetails: null,
    showGlobalComment: false,
    programChallengeId: null,
  });
  
  const closeWindow = () => {
    setShowCancelDialog(false);
    setAnchorEl(null);
    setChallengeResponseId(null);
  };

  const handleActivityDelete = () => {
    let challengeResponseIds = [];
    if (menuAnchorEl) {
      checkboxArr.forEach((e) => {
        if (e.check) {
          challengeResponseIds.push(e.id);
        } else {
          return;
        }
      });
    } else {
      challengeResponseIds = [challengeResponseId];
    }

    deleteChallengeActivity(challengeResponseIds)
      .then((res) => {
        setIsProgramChallenge(true);
        closeWindow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isSelected = (id) => {
    let i = checkboxArr.findIndex((x) => x.id === id);
    if (i !== -1) {
      return checkboxArr[i].check;
    }
    return false;
  };

  const handleSelectAll = (e) => {
    let val = e.target.checked;
    let arr = val
      ? data?.map((challenge) => ({
          id: challenge?.challengeResponseId,
          check: true,
        }))
      : [];

    setCheckboxArr(arr);
    setIsAllChecked(val);
  };

  const handleSelect = (e, row) => {
    let arr = [...checkboxArr];
    let i = arr.findIndex((x) => x.id === row?.challengeResponseId);
    if (i !== -1) {
      arr[i].id = row?.challengeResponseId;
      arr[i].check = e.target.checked;
      setCheckboxArr(arr);
    } else {
      setCheckboxArr([
        ...checkboxArr,
        {
          check: e.target.checked,
          id: row.challengeResponseId,
        },
      ]);
    }
    setIsAllChecked(false);
  };
  const getDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.DeleteActivityWarning}</span>
        </Typography>
      </div>
    );
  };

  const formatTimeStamp = (date) => {
    let splitDate = date.split(" ");
    let formatDate = ApplicationUtil.changeDateFormatDate(splitDate[0]);
    let today = moment().format("YYYY-MM-DD");
    let isToday = moment(formatDate).isSame(today);
    let value = "";
    let time = splitDate[1].split(":");
    if (isToday) {
      value = `${time[0]}:${time[1]}`;
    } else {
      value = splitDate[0];
    }
    return value;
  };

  const getFeedbackForm = () => {
    const {
      comment,
      showGlobalComment,
      activityDetails,
      activityId,
      programChallengeId,
    } = details;

    return (
      <div className={classes.approvalForm}>
        <div
          style={{
            fontWeight: 600,
          }}
        >
          <Typography variant="body2" gutterBottom>
            <span className={classes.labelCss}>Comment</span>
            <span className={classes.mandatoryCss}>*</span>
          </Typography>
          <div className={classes.textContainer}>
            <TextareaAutosize
              value={comment}
              aria-label="feedback"
              minRows={5}
              style={{ width: 600 }}
              onChange={(e) => {
                setDetails((prev) => ({ ...prev, comment: e.target.value }));
              }}
            />

            <SubmitCancelBtn
              style={{ width: "auto", marginLeft: "16px" }}
              type="submit"
              label={"Add"}
              onClick={() => addComment()}
            />
          </div>
          <div style={{ paddingTop: "10px" }}>
            {activityDetails &&
              activityDetails?.messages?.map((ele) => (
                <>
                  <div key={ele.id}>
                    {ele?.messageDate && (
                      <span>
                        {formatTimeStamp(ele.messageDate)}
                      </span>
                    )}
                    <span style={{ fontWeight: 300, marginLeft: "10px" }}>
                      {ele?.message}
                    </span>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    );
  };
  const addComment = () => {
    const {
      comment,
      showGlobalComment,
      activityDetails,
      activityId,
      programChallengeId,
    } = details;

    let requestParams = {
      activityId: activityId,
      message: comment,
      programChallengeId: programChallengeId,
      recipientId: activityDetails?.runnerId,
      recipientType: "CHALLENGE_ACTIVITY",
      objectId: activityId,
      messageType: "ACTIVITY",
    };

    if (comment?.trim() === "" || comment === undefined || comment === null) {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please add a comment",
      });
    } else {
      addActivityFeedback(requestParams).then((res) => {
        setIsProgramChallenge(true);
        closeWindow();
      });

      setDetails({
        openFeedbackForm: false,
        activityId: null,
        activityDetails: false,
        comment: null,
        showGlobalComment: false,
        programChallengeId: null,
      });
    }
  };

  return (
    <div className={classes.main}>
      <FormControl className={classes.formControlAction}>
        <div className={classes.actions}>
          <span>Actions</span>
          <span>
            <ArrowDropDownCircleOutlined
              className={classes.editIcon}
              onClick={(event) => {
                setMenuAnchorEl(event.currentTarget);
              }}
            />
          </span>
        </div>
      </FormControl>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell component="th">
                <Checkbox
                  checked={isAllChecked}
                  onChange={handleSelectAll}
                  color="primary"
                />
                <IconButton className={classes.hideArrowDropdown}>
                  <ArrowDropDownCircleOutlined className={classes.editIcon} />
                </IconButton>
              </TableCell>
              <TableCell component="th">View Date</TableCell>
              <TableCell component="th">Parent Question Response</TableCell>
              <TableCell component="th">Child Question Response</TableCell>
              <TableCell component="th">Status</TableCell>
              <TableCell component="th">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <React.Fragment key={row?.challengeResponseId}>
                <TableRow className={classes.tableRow} hover>
                  <TableCell
                    component="td"
                    align="center"
                    style={{ height: "50px" }}
                  >
                    <Checkbox
                      checked={
                        isAllChecked
                          ? isAllChecked
                          : isSelected(row?.challengeResponseId)
                      }
                      onChange={(e) => handleSelect(e, row)}
                      color="primary"
                    />
                    <IconButton>
                      <ArrowDropDownCircleOutlined
                        className={classes.editIcon}
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setChallengeResponseId(row?.challengeResponseId);
                        }}
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell component="td" align="left">
                    {moment(
                      row?.questionnaireDto?.viewDate,
                      "DD-MM-YYYY"
                    ).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell component="td" align="left">
                    {row?.questionnaireDto?.response}
                  </TableCell>
                  <TableCell component="td" align="left">
                    {row?.questionnaireDto?.childQuestionnaireDto?.response ||
                      "No Response"}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {row?.questionnaireDto?.responseStatus === "Positive" ? (
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/8/8b/Eo_circle_green_white_checkmark.svg"
                        style={{ width: "30px", height: "30px" }}
                      />
                    ) : (
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/2/28/White_X_in_red_background.svg"
                        style={{ width: "30px", height: "30px" }}
                      />
                    )}
                  </TableCell>
                  <TableCell component="td" align="right">
                    <IconButton
                      onClick={() => {
                        //  setDetails((prev )=>({...prev , openFeedbackForm : true }))
                        setDetails({
                          openFeedbackForm: true,
                          activityId: row?.challengeResponseId,
                          activityDetails: row,
                          showGlobalComment: false,
                          programChallengeId: row?.programChallengeId,
                        });
                      }}
                    >
                      {row?.messages ? (
                        <Badge
                          badgeContent={row?.messages?.length}
                          color="primary"
                        >
                          <Forum
                            style={{
                              color: "#F79824",
                            }}
                          />
                        </Badge>
                      ) : (
                        <Forum
                          style={{
                            color: "#F79824",
                          }}
                        />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add feedback form*/}
      <DialogBox
        fullWidth
        maxWidth={"md"}
        header={"Add Feedback"}
        open={details.openFeedbackForm}
        message={getFeedbackForm()}
        onClick={() => {
          setDetails((prev) => ({ ...prev, openFeedbackForm: false }));
        }}
        closeDisloag={() => {
          setDetails((prev) => ({ ...prev, openFeedbackForm: false }));
        }}
      />

      {/* global menu */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{ marginTop: "45px", position: "absolute", zIndex: 0 }}
      >
        <MenuItem
          onClick={() => {
            let challengeIds = [];
            checkboxArr.forEach((e) => {
              if (e.check) {
                challengeIds.push(e.challengeResponseId);
              } else {
                return;
              }
            });
            if (challengeIds.length > 0) {
              setShowCancelDialog(true);
            } else {
              Snackbar.show({
                variant: "error",
                isAutoHide: true,
                preventDuplicate: true,
                message: Properties.SelectActivityToDeleteLabel,
              });
            }
          }}
        >
          {Properties.DeleteActivityLabel}
        </MenuItem>
      </Menu>
      {/* row wise menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        // style={{ marginTop: "45px", position: "absolute", zIndex: 0 }}
      >
        <MenuItem
          onClick={() => {
            setShowCancelDialog(true);
          }}
        >
          {Properties.DeleteActivityLabel}
        </MenuItem>
      </Menu>
      {/* delete activity warning */}
      <DialogBox
        signup={true}
        open={showCancelDialog}
        message={getDialogMsg()}
        // header={dialogHeader}
        label={"No"}
        isSmall={true}
        onClick={() => {
          setShowCancelDialog(false);
        }}
        labelTwo={"Yes"}
        onClickTwo={() => {
          handleActivityDelete();
        }}
        closeDialog={() => {
          setShowCancelDialog(false);
        }}
      />
    </div>
  );
}
const styles = (theme) => ({
  main: {
    padding: "10px",
    margin: "10px",
  },
  editIcon: {
    color: "#09456C",
    cursor: "pointer",
    marginLeft: "1px",
  },
  formControlAction: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  actions: {
    display: "flex",
    alignItems: "center",
  },
  hideArrowDropdown: {
    visibility: "hidden",
  },
  tableHead: {
    backgroundColor: "#ced4da",
    "& th": {
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
    },
  },
  tableRow: {
    "& td": {
      fontWeight: "bold",
      fontFamily: "Rubik, sans-serif",
    },
    "& > *": {
      borderBottom: "unset",
    },
  },
  collapseItem: {
    // padding:10,
    display: "flex",
    alignItems: "center",
  },
  titleCss: {
    // color: "rgb(114, 114, 114)",
    color: "rgba(0, 0, 0, 0.87);",
    // color:'black',
    fontSize: "16px",
    fontWeight: 600,
  },
  infoCss: {
    color: "rgb(9, 69, 108)",
    fontSize: "20px",
    padding: "0px 0px 0px 5px",
    fontWeight: "bolder",
  },
  approvalForm: {
    padding: "8px 24px",
  },
  mandatoryCss: { color: "red" },
  textContainer: {
    display: "flex",
    alignItems: "center",
  },
});

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(UserBasedActivityList);
