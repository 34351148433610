import React, { useEffect, useState } from "react";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Tabs } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import Properties from "../../../../../properties/Properties";
import OrganizerJobHistoryTab from "./OrganizerJobHistoryTab";
import OrganizerSyncTab from "./OrganizerSyncTab";

function OrganizerActivitySyncJob({ classes }) {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const tabIndex = localStorage.getItem("activeTab");
    if (tabIndex !== null) {
      setActiveTab(parseInt(tabIndex));
    }
  }, []);

  const handleTabChange = (event, tab) => {
    setActiveTab(tab);
    localStorage.setItem("activeTab", tab);
  };

  return (
    <div>
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        variant={isMobile ? "scrollable" : "fullWidth"}
        scrollButtons={isMobile ? "on" : "off"}
      >
        <Tab
          value={0}
          label={Properties.JobSyncLabel}
          className={classes.textTransformCss}
        />
        <Tab
          value={1}
          label={Properties.JobHistoryLabel}
          className={classes.textTransformCss}
        />
      </Tabs>
      {activeTab === 0 && <OrganizerSyncTab />}

      {activeTab === 1 && <OrganizerJobHistoryTab />}
    </div>
  );
}

const styles = (theme) => ({
  textTransformCss: {
    textTransform: "none",
    fontSize: "22px",
    fontWeight: "bold",
  },
});

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(OrganizerActivitySyncJob);
