import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Card, Grid } from "@material-ui/core";
import { RuppeeIcon } from "../../../common/SvgIcon";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import group from "./../../../../images/Group.png";

const styles = (theme) => ({
  eventBlock: {
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    // width: "30%",
    padding: "10px",
    width: "100%",
    cursor: "pointer",
  },
  rowContainer: {
    paddingBottom: "10px",
    borderRadius: "10px",
    cursor: "pointer",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  title: {
    width: "80%",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#2883B5",
  },
  desc: {
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: "700",
    color: " #424242",
  },
  imageCont: {
    position: "relative",
    margin: "0 auto",
    justifyContent: "center",
    display: "flex",
    position: "relative",
  },
  reviewImgSmall: {
    width: "60%",
    maxWidth: "60%",
    minWidth: "100%",
  },
});

class ChallengesDetailsTile extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let organizer = JSON.parse(localStorage.getItem("organizer"));
  }
  render() {
    const {
      classes,
      history,
      row,
      startDate,
      endDate,
      displayRegistration,
      onClick,
      onResultsClick,
    } = this.props;

    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={4}
        xl={3}
        style={{ marginBottom: "8px" }}
        key={row?.id}
      >
        <Card className={classes.rowContainer}>
          {row?.bannerImagePath ? (
            <div className={classes.imageCont} onClick={onClick}>
              <img
                src={row?.bannerImagePath}
                className={classes.reviewImgSmall}
                alt="rev-itm-img"
              />
            </div>
          ) : (
            <div className={classes.imageCont} onClick={onClick}>
              <img
                src={group}
                className={classes.reviewImgSmall}
                alt="rev-itm-img"
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px 10px",
              alignItems: "center",
            }}
          >
            <div className={classes.textContainer} onClick={onClick}>
              <span className={classes.title}>{row?.name}</span>
              <br />
              {startDate && (
                <p className={classes.desc}>
                  {startDate}
                  {" to "}
                  {endDate}
                </p>
              )}
            </div>
            {displayRegistration && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {onResultsClick && (
                  <div
                    style={{
                      borderRadius: "25px",
                      border: "1px solid lightgray",
                      maxHeight: "20px",
                      padding: "4px",
                      cursor: "default",
                      minWidth: "75px",
                    }}
                    onClick={() => {
                      if (onResultsClick) {
                        onResultsClick();
                      }
                    }}
                  >
                    <span
                      style={{
                        borderRadius: "50%",
                        display: "inline-block",
                        backgroundColor: "red",
                        height: "10px",
                        width: "10px",
                      }}
                    ></span>{" "}
                    <span>Results</span>
                  </div>
                )} 
              </div>
            )}
          </div>
        </Card>
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ChallengesDetailsTile);
