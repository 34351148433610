import React, { Component } from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import httpService from "../../../../../../apis/httpService";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";

const styles = (theme) => ({
  avatar2: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    border: "1px solid #0c0b0b63",
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(13),
      height: theme.spacing(13),
    },
  },
  avatar1: {
    width: theme.spacing(22),
    height: theme.spacing(22),
    border: "1px solid #0c0b0b63",
    zIndex: 0,
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  avatar3: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    border: "1px solid #0c0b0b63",
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(13),
      height: theme.spacing(13),
    },
  },
  runnerName: {
    width: "150px",
    textAlign: "center",
    wordBreak: "break-word",
    fontWeight: "bold",
    fontWeight: "400",
    fontSize: "20px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "100px",
    },
  },
  avatarLetter: {
    fontSize: "100px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "60px",
    },
  },
  rank: {
    fontSize: "20px",
    fontWeight: "600",
  },
  winner1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
  },
  winner2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
    position: "absolute",
    right: "150px",
    top: "70px",
    [theme.breakpoints.down("sm")]: {
      right: "100px",
    },
  },
  winner3: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
    position: "absolute",
    left: "150px",
    top: "70px",
    [theme.breakpoints.down("sm")]: {
      left: "100px",
    },
  },
  container: {
    display: "flex",
    position: "relative",
    height: "280px",
    marginBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px",
    },
  },
});
function WinnerBoardIndividualList({
  challengeParameter,
  winners,
  classes,
  time,
  distance,
  rank,
  challangeType,
}) {
  let winner1 = winners[0];
  let winner2 = winners[1];
  let winner3 = winners[2];
  let rank1 = winner1?.rank;
  let rank2 = winner2?.rank;
  let rank3 = winner3?.rank;
  const API_URL = httpService.API_URL();
  return (
    <>
      <div className={classes.container}>
        {rank2 && winner2 && (
          <div className={classes.winner2}>
            {rank && <span className={classes.rank}>2nd</span>}
            <Avatar
              src={
                winner2?.profileLink
                  ? winner2?.profileLink.includes("https")
                    ? `${winner2?.profileLink}`
                    : `${API_URL}${winner2?.profileLink}`
                  : ""
              }
              className={classes.avatar2}
            >
              <span className={classes.avatarLetter}>
                <b>{winner2?.firstName[0].toUpperCase()}</b>
              </span>
            </Avatar>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 10px",
              }}
            >
              <span className={classes.runnerName}>
                <b>{`${winner2?.firstName} ${winner2?.lastName}`} </b>
              </span>
              <span>
                {(challengeParameter === "DISTANCE" || distance) && (
                  <b>
                    {parseFloat(winner2?.totalActivityDistance / 1000).toFixed(
                      2
                    ) + " Km"}
                  </b>
                )}
                {challengeParameter === "STEPS" &&
                  challangeType !== "STAIR_STEPPER" && (
                    <b>
                      {ApplicationUtil.formatNumberWithCommas(
                        winner2?.totalSteps
                      )}
                    </b>
                  )}

                {challengeParameter === "STEPS" &&
                  challangeType === "STAIR_STEPPER" && (
                    <b>
                      {ApplicationUtil.formatNumberWithCommas(
                        winner2?.completionTime
                      )}
                    </b>
                  )}

                {challengeParameter === "WELLNESS_POINT" && (
                  <b>{winner2?.totalWellnessPoint}</b>
                )}
                {challengeParameter === "CALORIE" && (
                  <b>{winner2?.totalCalories}</b>
                )}
                {challengeParameter === "ELEVATION" && (
                  <b>{winner2?.totalElevationGain}</b>
                )}
                {(challengeParameter === "TIME" ||
                  challengeParameter === undefined ||
                  time) && <b>{winner2?.completionTime}</b>}
              </span>
            </div>
          </div>
        )}
        {/* winner 1 */}
        {rank1 && (
          <div className={classes.winner1}>
            {rank && <span className={classes.rank}>1st</span>}
            <Avatar
              src={
                winner1?.profileLink
                  ? winner1?.profileLink.includes("https")
                    ? `${winner1?.profileLink}`
                    : `${API_URL}${winner1?.profileLink}`
                  : ""
              }
              className={classes.avatar1}
            >
              {" "}
              <span className={classes.avatarLetter}>
                <b>{winner1?.firstName[0].toUpperCase()}</b>
              </span>
            </Avatar>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 10px",
              }}
            >
              <span className={classes.runnerName}>
                <b>{`${winner1?.firstName} ${winner1?.lastName}`}</b>
              </span>
              <span>
                {(challengeParameter === "DISTANCE" || distance) && (
                  <b>
                    {parseFloat(winner1?.totalActivityDistance / 1000).toFixed(
                      2
                    ) + " Km"}
                  </b>
                )}

                {challengeParameter === "STEPS" &&
                  challangeType !== "STAIR_STEPPER" && (
                    <b>
                      {ApplicationUtil.formatNumberWithCommas(
                        winner1?.totalSteps
                      )}
                    </b>
                  )}

                {challengeParameter === "STEPS" &&
                  challangeType === "STAIR_STEPPER" && (
                    <b>
                      {ApplicationUtil.formatNumberWithCommas(
                        winner1?.completionTime
                      )}
                    </b>
                  )}

                {challengeParameter === "WELLNESS_POINT" && (
                  <b>{winner1?.totalWellnessPoint}</b>
                )}
                {challengeParameter === "CALORIE" && (
                  <b>{winner1?.totalCalories}</b>
                )}
                {challengeParameter === "ELEVATION" && (
                  <b>{winner1?.totalElevationGain}</b>
                )}
                {(challengeParameter === "TIME" ||
                  challengeParameter === undefined ||
                  time) && <b>{winner1?.completionTime}</b>}
              </span>
            </div>
          </div>
        )}
        {/* winner 3 */}
        {rank3 && winner3 && (
          <div className={classes.winner3}>
            {rank && <span className={classes.rank}>3rd</span>}
            <Avatar
              src={
                winner3?.profileLink
                  ? winner3?.profileLink.includes("https")
                    ? `${winner3?.profileLink}`
                    : `${API_URL}${winner3?.profileLink}`
                  : ""
              }
              className={classes.avatar3}
            >
              {" "}
              <span className={classes.avatarLetter}>
                <b>
                  {winner3?.firstName
                    ? winner3?.firstName[0].toUpperCase()
                    : ""}
                </b>
              </span>
            </Avatar>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 10px",
              }}
            >
              <span className={classes.runnerName}>
                <b>{`${winner3?.firstName} ${winner3?.lastName}`}</b>
              </span>
              <span>
                {(challengeParameter === "DISTANCE" || distance) && (
                  <b>
                    {parseFloat(winner3?.totalActivityDistance / 1000).toFixed(
                      2
                    ) + " Km"}
                  </b>
                )}
                {challengeParameter === "STEPS" &&
                  challangeType !== "STAIR_STEPPER" && (
                    <b>
                      {ApplicationUtil.formatNumberWithCommas(
                        winner3?.totalSteps
                      )}
                    </b>
                  )}

                {challengeParameter === "STEPS" &&
                  challangeType === "STAIR_STEPPER" && (
                    <b>
                      {ApplicationUtil.formatNumberWithCommas(
                        winner3?.completionTime
                      )}
                    </b>
                  )}
                {challengeParameter === "WELLNESS_POINT" && (
                  <b>{winner3?.totalWellnessPoint}</b>
                )}
                {challengeParameter === "CALORIE" && (
                  <b>{winner3?.totalCalories}</b>
                )}
                {challengeParameter === "ELEVATION" && (
                  <b>{winner3?.totalElevationGain}</b>
                )}
                {(challengeParameter === "TIME" ||
                  challengeParameter === undefined ||
                  time) && <b>{winner3?.completionTime}</b>}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(WinnerBoardIndividualList);
