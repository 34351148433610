import http from "./httpService";
import { loading } from "../components/common/Spinner";
import Snackbar from "../components/common/Snackbar";
import Properties from "../properties/Properties";
import ApplicationUtil from "../utils/ApplicationUtil";

const API_URL_FILE = http.API_URL();

export default function uploadRunActivity(requestParams) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/runner/activity/upload";
  var formData = new FormData();

  formData.set("uploadRequest", JSON.stringify(requestParams.uploadRequest));
  if (requestParams.file && requestParams.file.length>0){
  requestParams.file.forEach((element) => {
    formData.append("files", element);
  });
}
  if (requestParams.postRunImages){
  formData.append("postRunImages", requestParams.postRunImages);
  }
  // formData.append("files",requestParams.file);

  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        loading(false);
        if (res.status === 200) {
          if (res?.data?.success?.code === "409") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Data Already Present.",
            });
            resolve(res);
          } else if (res?.data?.success?.code === "400") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.success?.verbose
                ? res?.data?.success?.verbose
                : Properties.Upload_Activity_Validation_label,
            });
            reject(res);
          } else if (res?.data?.success?.code === "500") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.success?.verbose
                ? res.data.success.verbose?.split("\n")[0]
                : Properties.Something_went_wrong,
            });
            reject(res);
          } else if (res?.data?.success?.code === "503") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.success?.verbose,
            });
            reject(res);
          } else if (res?.data?.success?.code === "600") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Upload_Activity_Invalid_Activity_Date,
            });
            reject(res);
          } else if (res?.data?.success?.code === "500") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Upload_Activity_Failed,
            });
            reject(res);
          } else {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Data uploaded successfully",
            });
            resolve(res);
          }
        } else {
          console.log("API failed");
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }

        reject(error);
      });
  });
}

export function uploadMultipleActivity(requestParams, files) {
  loading(true);
  let token = localStorage.getItem("token");

  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/runner/multiActivity/upload";

  // var formData = new FormData();

  // requestParams.forEach((ele) => {
  // formData.append("uploadRequest", JSON.stringify(requestParams));
  // });
  // JSON.stringify(requestParams)
  // files.forEach((element) => {
  //   formData.append("files", element);
  // });
  // formData.append("postRunImages", requestParams.postRunImages);

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        loading(false);
        if (res.status === 200) {
          if (res?.data?.success?.code === "409") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Data Already Present.",
            });
            resolve(res);
          } else if (res?.data?.success?.code === "400") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.success?.verbose
                ? res?.data?.success?.verbose
                : Properties.Upload_Activity_Validation_label,
            });
            reject(res);
          } else if (res?.data?.success?.code === "503") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.success?.verbose,
            });
            reject(res);
          } else if (res?.data?.success?.code === "600") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Upload_Activity_Invalid_Activity_Date,
            });
            reject(res);
          } else if (res?.data?.success?.code === "500") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Upload_Activity_Failed,
            });
            reject(res);
          } else {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Data uploaded successfully",
            });
            resolve(res);
          }
        } else {
          console.log("API failed");
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error?.response?.data
                ? error?.response?.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
