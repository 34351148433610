import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import SubmitCancelBtn from "../../../common/SubmitCancelBtn";
import { Button, Grid, Tooltip } from "@material-ui/core";
import Properties from "../../../../properties/Properties";
import { AddCircle } from "@material-ui/icons";
import DialogBox from "../../../common/DialogBox";
import Snackbar from "../../../common/Snackbar";
import { loading } from "../../../common/Spinner";
import ImageSlider from "../../../common/ImageSlider";
import ShareIcon from "@material-ui/icons/Share";
import EditIcon from "@material-ui/icons/Edit";
import { uploadModifyAndDeleteImage } from "../../../../apis/programService";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import moment from "moment";

function ImageUploadComponent({
  classes,
  challengeId,
  viewDate,
  initialLoad,
  userUploadedImg,
  setUserUploadedImg,
  runnerDetails,
  perDayViewData,

  ResponseSubmissionEndDate,
}) {
  const [showDialog, setShowDialog] = useState(false);
  const [showAddButton, setShowButton] = useState(true);
  const [isImagePresent, setIsImagePresent] = useState(true);
  const [isChallengePerformed, setIsChallengePerformed] = useState(false);

  const [stateImage, setStateImage] = useState();

  useEffect(() => {
    const startTime = moment(viewDate, "YYYY-MM-DD")
      .startOf("day")
      .add(1, "second");
    const endTime = startTime.clone().add(1, "day").endOf("day");
    const now = moment();

    const isEnabled = now.isBetween(startTime, endTime, null, "[]");

    //enable button before submissionDate
    const isEnableBeforeSubmissionEndDate = moment().isSameOrBefore(
      ResponseSubmissionEndDate
    );

    setShowButton(isEnableBeforeSubmissionEndDate);

    const checkResponsePresent = perDayViewData.response ? true : false;
    setIsChallengePerformed(checkResponsePresent);
  }, [viewDate, perDayViewData]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      let resquestedParam = {
        file: file,
        viewDate: viewDate,
        runnerId: runnerDetails?.runner?.id,
        id: challengeId,
      };
      // const newImageUrl = URL.createObjectURL(file); // Create a new object URL
      // setStateImage(newImageUrl);
      uploadChangeAndDelete(resquestedParam);
    } else {
      loading(false);
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Only JPEG or PNG image types are allowed",
      });
    }
    e.target.value = null; // Reset file input
  };

  const deletePhoto = () => {
    let requestedParam = {
      viewDate: viewDate,
      runnerId: runnerDetails?.runner?.id,
      id: challengeId,
    };
    uploadChangeAndDelete(requestedParam);
  };

  const uploadChangeAndDelete = (resquestedParam) => {
    uploadModifyAndDeleteImage(resquestedParam).then((res) => {
      if (res && res.status == 200) {
        initialLoad(challengeId, viewDate);
        if (res.data?.imageURL) {
          // setUserUploadedImg(res.data?.imageURL);
        } else {
          setUserUploadedImg(Properties.no_image_uploaded_url);
        }
       
      }
    });
  };

  const getDialogComponent = () => {
    return (
      <div>
        <img
          key={moment()}
          className={classes.uploadedImg}
          src={
            userUploadedImg ? `${userUploadedImg}?rnd=${Math.random()}` : Properties.no_image_uploaded_url
          }
          alt={"pic"}
        />
      </div>
    );
  };
  const shareImage = async () => {
    if (userUploadedImg) {
      try {
        const blob = await fetch(userUploadedImg).then((r) => r.blob());
        const file = new File([blob], "user_challenge_photo.jpeg", {
          type: blob.type,
          lastModified: new Date().getTime(),
        });

        // Prepare share data
        const shareData = {
          files: [file],
          title: "Check this out!",
          //   url: "https://example.com",
          text: "Join the challenge now!",
        };
        await navigator.share(shareData);
      } catch (err) {
        console.error("Error sharing the image:", err);
      }
    } else {
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Upload image to share",
      });
    }
  };

  const closeWindow = () => {
    setShowDialog(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        {perDayViewData?.programChallengeType === "ZERO_SUGAR"
          ? Properties.zero_sugar_image_info
          : perDayViewData?.programChallengeType === "HYDRATION"
          ? Properties.hydration_image_info
          : ""}
        <p style={{ marginBottom: "5px" }}>
          Don’t forget to tag us on social media.
        </p>
        {perDayViewData?.programChallengeType === "ZERO_SUGAR" && (
          <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
            <span>#ZeroSugarChallenge</span>
            <span>#ZSC</span>
            <span>#HealthyLifestyle</span>
          </div>
        )}
      </div>

      <div className={classes.imageContainer}>
        {isImagePresent && (
          <div className={classes.mediaThumbnail}>
            <span className={classes.itemTitle}>Post Activity Picture</span>
            <div className={classes.picture}>
              {/* <div className={classes.imgTitle}> */}
              <div className={classes.profileImgContainer}>
                <img
                  key={userUploadedImg}
                  className={classes.profileImg}
                  src={
                    userUploadedImg
                      ? `${userUploadedImg}?rnd=${Math.random()}`
                      : Properties.no_image_uploaded_url
                  }
                  alt={"pic"}
                  onClick={() => {
                    setShowDialog(userUploadedImg ? true : false);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className={classes.buttonAlignment}>
          <Tooltip
            enterTouchDelay={0}
            title={
              !isChallengePerformed
                ? "Let's submit the response to questions before uploading photo"
                : ""
            }
            placement="top"
          >
            <Button
              color="primary"
              fullWidth={false}
              variant="contained"
              disabled={!showAddButton || !isChallengePerformed}
              className={
                !showAddButton || !isChallengePerformed
                  ? classes.disabledBtn
                  : classes.uploadImageBtn
              }
              component="label"
            >
              <label htmlFor="contained-button-file">
                <div
                  className={classes.editButtonText}
                  style={{
                    cursor:
                      !showAddButton || !isChallengePerformed
                        ? "not-allowed"
                        : "pointer",
                    textTransform: "capitalize",
                    lineHeight: "normal",
                  }}
                >
                  {userUploadedImg ? (
                    <div
                      className={classes.editButtonText}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      Change Photo
                      <EditIcon
                        fontSize="small"
                        style={{
                          // color: "#F79824",
                          fontSize: "19px",
                          cursor:
                            !showAddButton || !isChallengePerformed
                              ? "not-allowed"
                              : "pointer",
                          // marginLeft: "8px",
                        }}
                      ></EditIcon>
                    </div>
                  ) : (
                    "Add Photo"
                  )}
                </div>
              </label>
              {showAddButton && isChallengePerformed && (
                <Grid item xs style={{ display: "none" }}>
                  <input
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                    // accept="image/*"
                    onChange={(e) => handleImageUpload(e)}
                  />
                </Grid>
              )}
            </Button>
          </Tooltip>

          <Tooltip
            enterTouchDelay={0}
            title={userUploadedImg === null ? "Upload Image to share" : ""}
            placement="top"
          >
            <Button
              color="primary"
              variant="contained"
              className={`${
                userUploadedImg === null
                  ? classes.disabledBtn
                  : classes.uploadImageBtn
              } ${classes.editButtonText}`}
              disabled={userUploadedImg === null}
              onClick={shareImage}
            >
              Share
              <ShareIcon
                fontSize="small"
                style={{ marginLeft: "8px", fontSize: "19px" }}
              />
            </Button>
          </Tooltip>
        </div>

        <DialogBox
          fullWidth
          deleteFalse={true}
          addPhoto={showAddButton}
          onAddPhoto={handleImageUpload}
          open={showDialog}
          message={getDialogComponent()}
          deletePhoto={true}
          onDeletePhoto={deletePhoto}
          onClick={() => closeWindow()}
          isImagePresent
          closeDisloag={() => closeWindow()}
        />
      </div>
    </div>
  );
}

const styles = (theme) => ({
  container: {
    display: "flex",
    borderRadius: "10px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    background: "linear-gradient(to right, #ebf1f6, #759ac0)",
    padding: "10px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      // justifyContent: "space-between",
      flexDirection: "column",
    },
  },
  imageContainer: {
    backgroundColor: "#fff",
    padding: "10px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    // alignItems:"center"
  },
  textContainer: {
    width: "30%",
    fontSize: "16px",
    fontWeight: "bold",
    // color: "#2883B5",
    padding: "10px",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      width: "96%",
    },
  },
  buttonAlignment: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
  },
  uploadImageBtn: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#296DFA",
    },
    width: "150px",
    alignContent: "center",
    padding: "6px 16px",
    maxWidth: "300px",
    height: "38px",
    borderRadius: "10px",
    backgroundColor: "#09456C",
    color: "#fff",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  disabledBtn: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#296DFA",
    },
    width: "150px",
    alignContent: "center",
    padding: "6px 16px",
    maxWidth: "300px",
    height: "38px",
    borderRadius: "10px",
    backgroundColor: "#09456C",
    color: "#fff",
    fontWeight: "bold",
    cursor: "not-allowed !important",
    pointerEvents: "inherit !important",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  mediaThumbnail: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  itemTitle: {
    color: "#727272",
    fontWeight: "600",
    fontSize: "16px",
  },
  profileImgContainer: {
    width: "120px",
    height: "120px",
    // border: "2px solid blue",
    boxShadow: "0px 0px 2px 3px rgba(0,0,0,0.3)",
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    marginRight: "5px",
    marginBottom: "10px",
  },
  profileImg: {
    // maxWidth: "80px",
    // minWidth: "60px",
    width: "100px",
    height: "100px",
    objectFit: "contain",
    margin: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    cursor: "pointer",
  },
  imgTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  uploadedImg: {
    width: "95%",
    height: "100%",
    margin: "auto",
    overflow: "hidden",
    display: "block",
    objectFit: "contain",
  },
  editButtonText: {
    fontSize: "12px !important",
    [theme.breakpoints.down("sm")]: {
      // justifyContent: "space-between",
      fontSize: "12px !important",
    },
  },
});

export default compose(withStyles(styles), withRouter)(ImageUploadComponent);
