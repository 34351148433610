import React, { Component } from "react";
import { Typography, FormControl } from "@material-ui/core";
import { FilterSVGIcon } from "./SvgIcon";
import compose from "recompose/compose";
import { withStyles ,makeStyles} from "@material-ui/core/styles";
const styles = (theme) => ({
filter:{
cursor :'pointer',
width:'25px',
height:'25px',
display:'flex',
justifyContent:'flex-end',
alignItems:'flex-end',
margin  :'20% 20% 20% 150%',
color:'#09456C'
},
});
 function FilterIcon(props){
  const { classes  } =props;
  return(
    <FormControl className={classes.formControl}>
         <FilterSVGIcon  onClick={()=>{props.filter();props.open();}} className={classes.filter}/>
    </FormControl>
  )
 }
export default compose(
  withStyles(styles, { withTheme: true }))(FilterIcon)