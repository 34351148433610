import { Box, FormControl, NativeSelect } from "@material-ui/core";
import { Bar } from "@reactchartjs/react-chart.js";
import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/styles";
import moment from "moment";
import ApplicationUtil from "../../../utils/ApplicationUtil";

const StepperBarGraph = ({
  isProgram = true,
  classes,
  setSelectedWeek,
  isOrganizer,
  selectWeek,
  stepperGraphData: stepperGraphData,
  stairStepperValueChange,
  selectedWeekData,
}) => {
  const [graphData, setGraphData] = useState([]);

  let weekWise = stepperGraphData.currentWeek
    ? stepperGraphData.currentWeek + 1
    : 1;

  const data = {
    labels: selectWeek
      ? graphData?.map((item) => {
          const isRestDay = item?.activeTime === 0 && item?.targetTime === 0;
          return (
            moment(item.startDateLocal, "DD-MM-YYYY HH:mm:ss").format("DD-MM") +
            `${isRestDay ? "" : ""}`
          );
        })
      : stepperGraphData[`week${weekWise}`]?.map((item) => {
          const isRestDay = item?.activeTime === 0 && item?.targetTime === 0;

          return (
            moment(item.startDateLocal, "DD-MM-YYYY HH:mm:ss").format("DD-MM") +
            `${isRestDay ? "" : ""}`
          );
        }),
    datasets: [
      {
        label: "Target(min)",
        backgroundColor: "#9396f3",
        borderColor: "#9396f3",

        data: selectWeek
          ? graphData?.map((record) => record?.targetTime)
          : stepperGraphData[`week${weekWise}`]?.map(
              (record) => record?.targetTime
            ),
        barPercentage: 1,
        categoryPercentage:
          document.documentElement.clientWidth < 600 ? 0.85 : 0.4,
        group: 1,
      },

      {
        label: "Achieved(min)",
        backgroundColor: "#fe7235",
        borderColor: "#fe7235",
        data: selectWeek
          ? graphData?.map((record) => record.activeTime)
          : stepperGraphData[`week${weekWise}`]?.map(
              (record) => record.activeTime
            ),
        barPercentage: 1,
        categoryPercentage:
          document.documentElement.clientWidth < 600 ? 0.85 : 0.4,
        group: 1,
      },
    ],
  };

  let options = {
    layout: {
      padding: {
        top: 20, //fix for cutoff issue
      },
    },
    maintainAspectRatio: false,
    tooltips: {
      enabled: true,
      filter: function (tooltipItem, data) {
        return tooltipItem.datasetIndex === 1; // Show tooltip only for index 1
      },
      callbacks: {
        label: function (tooltipItem) {
          let currentBar = selectWeek
            ? graphData.filter((item) => {
                return (
                  moment(item.startDateLocal, "DD-MM-YYYY HH:mm:ss").format(
                    "DD-MM"
                  ) === tooltipItem?.label
                );
              })
            : stepperGraphData[`week${weekWise}`].filter((item) => {
                return (
                  moment(item.startDateLocal, "DD-MM-YYYY HH:mm:ss").format(
                    "DD-MM"
                  ) === tooltipItem?.label
                );
              });

          if (!isOrganizer) {
            return [
              ` Total Steps : ${currentBar?.[0]?.steps || 0} `,
              // ` ${
              //   tooltipItem?.datasetIndex === 1 ? "Achieved" : "Target"
              // } (min) : ${tooltipItem?.value}`,
            ];
          } else {
            return [
              ` Active Participants : ${
                currentBar?.[0]?.activeParticipants || 0
              } `,
              // ` ${
              //   tooltipItem?.datasetIndex === 1 ? "Achieved" : "Target"
              // } (min) : ${tooltipItem?.value}`,
            ];
          }
        },
      },
    },
    datalabels: {
      align: "top",
      anchor: "end",
      display: "auto",
    },

    legend: {
      position: "bottom",
      align: "end",
      onClick: function (event, legendItem) {},
    },

    scales: {
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            min: 0,

            // fontStyle:"bold",
            fontSize: isProgram
              ? document.documentElement.clientWidth < 1200
                ? 12
                : 15
              : 10,
            fontWeight: "700",
            fontColor: "black",
            // stepSize: 10,
          },
          // scaleLabel: {
          //   display: true,
          //   labelString: "Percent (%)",
          // },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            fontWeight: "700",
            fontColor: "black",

            fontSize: 12,
          },
        },
      ],
    },
    hover: {
      animationDuration: 0,
      // mode: "null",
      //  intersect:false
    },
    animation: {
      duration: 0,
      onComplete: function (tooltipItem) {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;

        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          if (
            chartInstance.data.datasets[i] &&
            chartInstance.data.datasets[i + 1]
          ) {
            meta.data.forEach(function (bar, index) {
              let targetMin = dataset.data[index];
              var achievedMin = chartInstance.data.datasets[i + 1].data[index];
              if (targetMin === 0 && achievedMin === 0) {
                ctx.font = "15px !important";
                ctx.textAlign = "center";
                ctx.save();
                ctx.translate(bar._model.x, bar._model.y);
                ctx.rotate(-Math.PI / 2);
                ctx.scale(2, 2);
                ctx.fillText("Rest day", 50, 0);
                ctx.restore();
              } else {
                var data = dataset.data[index];
                var height = chartInstance.controller.boxes[0].bottom;
                ctx.font = "15px !important";
                ctx.textAlign = "center";
                if (data) {
                  ctx.fillText(
                    ApplicationUtil.formatNumberWithCommas(data || 0),
                    bar._model.x,
                    bar._model.y - 5
                  );
                }
              }
            });
          } else {
            meta.data.forEach(function (bar, index) {
              var data = dataset.data[index];
              var height = chartInstance.controller.boxes[0].bottom;
              ctx.font = "15px !important";
              ctx.textAlign = "center";
              if (data) {
                ctx.fillText(
                  ApplicationUtil.formatNumberWithCommas(data || 0),
                  bar._model.x,
                  bar._model.y - 5
                );
              }
            });
          }
        });
      },
    },
  };

  useEffect(() => {
    if (selectWeek) {
      setGraphData(stepperGraphData?.[`week${selectWeek}`]);
    }
  }, [selectWeek]);

  return (
    <Box className={classes.graph_container}>
      <Box sx={{ width: "90%", margin: "auto" }}>
        <Box className={classes.selectContainer}>
          {!isOrganizer && <span></span>}
          <span>
            {stepperGraphData?.weekRange?.length > 0 && (
              <FormControl className={classes.dropdownStyling}>
                <NativeSelect
                  id="myDropdown"
                  onChange={stairStepperValueChange}
                  defaultValue={weekWise - 1}
                >
                  {stepperGraphData?.weekRange
                    ?.filter((item) => {
                      return item !== "Invalid date-Invalid date";
                    })
                    .map((value, index) => (
                      <option value={index} key={index} index={index}>
                        {value}
                      </option>
                    ))}
                </NativeSelect>
              </FormControl>
            )}
          </span>
        </Box>

        <Box
          sx={{
            width: isOrganizer ? "100%" : { sm: "100%", md: "60%" },
            margin: "auto",
          }}
        >
          <Bar data={data} options={options} height={300} width={"100%"} />
        </Box>
      </Box>
      {/* </Container> */}
    </Box>
  );
};

const styles = (theme) => ({
  graph_container: {
    width: "100%",
  },
  graphWrapper: {
    width: "100% ",
  },
  headerContainer: {
    paddingBottom: 15,
  },
  title: {
    color: "#202260",
    fontWeight: "bold",
    fontFamily: "karla, sans serif",
    fontSize: "35px",
    letterSpacing: "-1px",
  },
  desc: {
    color: "black",
    margin: "0",
    fontSize: "26px",
    fontWeight: "bolder",
  },
  selectContainer: {
    padding: "10px",
    marginBottom: "5px",
    // backgroundColor:"gray",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
  },
});

export default compose(withStyles(styles, { withTheme: true }))(
  StepperBarGraph
);
