import React, { Component } from "react";
import compose from "recompose/compose";
import { Switch, Route, withRouter } from "react-router-dom";
import ApplicationUtil from "./../../../../utils/ApplicationUtil";
import SupportLoginScreen from "./../SupportLoginScreen";
import OrganizerEventList from "./OrganizerEventList";
import OrganizerParticipantsList from "./OrganizerParticipantsList";
import OrganizerParticipantsDetails from "./OrganizerParticipantsDetails";
import OrganizerProgramSessionForm from "./OrganizerProgramSessionForm";

import ParticipantExecutationDetails from "./ParticipantExecutationDetails";
import ChallengeRegistrationList from "./Challenge/ChallengeRegistrationList";
import OpenChallengesList from "./Challenge/OpenChallengesList";
import CalenderHomeView from "../../calenderHome/CalenderHomeView";
import OrganizerCalendarView from "./OrganizerCalendarView/OrganizerCalendarView";
import OrganizerProgramParticipantsList from "./OrganizerProgram/OrganizerProgramParticipantsList";
import ProgramDashboard from "../../program/ProgramDashboard";
import OrganizerProgramChallenge from "./OrganizerProgram/OrganizerProgramCreateChallenge";
import OrganizerProgramCreateChallenge from "./OrganizerProgram/OrganizerProgramCreateChallenge";
import OrganizerProgramParticipantInfo from "./Program/OrganizerProgramParticipantInfo";

class SupportPanel extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { match } = this.props;
    ApplicationUtil.saveMatchUrl(match.url);
    return (
      <Switch>
        <Route
          strict
          path={match.url + "/form"}
          render={() => this.getSupportListFormPanel()}
        />
        <Route
          strict
          exact
          path={match.url}
          // component={SupportLoginScreen}
          render={() => <SupportLoginScreen role="organizer" />}
        />
        <Route
          strict
          exact
          path={match.url + "/list"}
          render={() => this.getSupportListPanel()}
        />

        <Route
          strict
          exact
          path={match.url + "/list/details"}
          render={() => <OrganizerParticipantsDetails />}
        />
        <Route
          strict
          exact
          path={match.url + "/calendar"}
          render={() => this.getProgramCalendarView()}
        />
        <Route
          strict
          exact
          path={match.url + "/calendar/list"}
          render={() => this.getSupportListPanel()}
        />

        <Route
          strict
          exact
          path={match.url + "/createChallenge"}
          render={() => this.getCreateChallengeInProgram()}
        />
        <Route
          strict
          exact
          path={match.url + "/calendar/list/details"}
          render={() => <OrganizerParticipantsDetails />}
        />
        <Route
          strict
          exact
          path={match.url + "/calendar/createSession"}
          render={() => this.getProgramSessionFormView()}
        />
        <Route
          strict
          exact
          path={match.url + "/calendar/participants"}
          render={() => this.getProgramParticipantsList()}
        />
        <Route
          strict
          exact
          path={match.url + "/calendar/participants/info"}
          render={() => this.getParticipantInfo()}
        />
      
        <Route
          strict
          exact
          path={match.url + "/participants/info"}
          render={() => this.getParticipantInfo()}
        />
        {/* <Route
          strict
          exact
          path={match.url + "/eventlist"}
          component={SupportEventList}
        /> */}
        <Route
          strict
          exact
          path={match.url + "/eventlist"}
          render={() => this.getSupportEventListPanel()}
        />
        <Route
          strict
          exact
          path={match.url + "/challenges"}
          render={() => this.getSupportChallengesListPanel()}
        />
        <Route
          strict
          exact
          path={match.url + "/challenges/list"}
          render={() => this.getSupportListPanel()}
        />
        <Route
          strict
          exact
          path={match.url + "/challenges/list/details"}
          render={() => <OrganizerParticipantsDetails />}
        />
        <Route
          strict
          exact
          path={match.url + "/calendar/programDashboard"}
          render={() => this.getProgramDashboard()}
        />
      </Switch>
    );
  }

  getCreateChallengeInProgram = () => {
    return <OrganizerProgramCreateChallenge />;
  };
  getSupportListFormPanel = () => {
    return <h1>Form</h1>;
  };

  getSupportListPanel = () => {
    // return <h1>Participant List</h1>;
    return <OrganizerParticipantsList />;
  };

  getSupportEventListPanel = () => {
    // return <h1>Event list</h1>;
    return <OrganizerEventList />;
  };

  getSupportChallengesListPanel = () => {
    return <OpenChallengesList />;
  };

  getChallengeRegistrationList = () => {
    return <ChallengeRegistrationList />;
  };
  getProgramCalendarView = () => {
    return <OrganizerCalendarView />;
  };
  getProgramParticipantsList = () => {
    return <OrganizerProgramParticipantsList />;
  };
  getParticipantInfo = () => {
    return <OrganizerProgramParticipantInfo />;
  };
  getProgramDashboard = () => {
    return <ProgramDashboard isOrganizer={true} />;
  };
  getProgramSessionFormView = () => {
    return <OrganizerProgramSessionForm />;
  };
}

export default compose(withRouter)(SupportPanel);
