import React from "react";
import "../../styles/common.css";


class TripleToggleSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switchPosition: "left",
      animation: null
    };
  }

  getSwitchAnimation = (value) => {
    
    const { switchPosition } = this.state;
    const { labels } = this.props;

    let animation = null;
    if (value === "center" && switchPosition === "left") {
      animation = "left-to-center";
    } else if (value === "right" && switchPosition === "center") {
      animation = "center-to-right";
    } else if (value === "center" && switchPosition === "right") {
      animation = "right-to-center";
    } else if (value === "left" && switchPosition === "center") {
      animation = "center-to-left";
    } else if (value === "right" && switchPosition === "left") {
      animation = "left-to-right";
    } else if (value === "left" && switchPosition === "right") {
      animation = "right-to-left";
    }
    let selectedValue = value === "left" ? labels.left.value : value === "center" ? labels.center.value : labels.right.value
    this.props.onChange(selectedValue);
    this.setState({ switchPosition: value, animation });
  };

  render() {
    const { labels, animateFrom, changePosition } = this.props;
const {animation, switchPosition} = this.state
let animate = animateFrom ? animateFrom : animation
let position = changePosition ? changePosition : switchPosition
    return (
      <div className="tripple-toggle-container">
        <div
          className={`switch ${animate} ${position}-position`}
        ></div>
        <input
          // defaultChecked
          onChange={(e) => this.getSwitchAnimation(e.target.value)}
          name="map-switch"
          id="left"
          type="radio"
        //   value={labels.left.value}
        value = {'left'}
          
        />
        <label
          className={`left-label ${
            position === "left" && "black-font"
          }`}
          htmlFor="left"
        >
          <h4>{labels.left.title}</h4>
        </label>

        <input
          onChange={(e) => this.getSwitchAnimation(e.target.value)}
          name="map-switch"
          id="center"
          type="radio"
        //   value={labels.center.value}
        value={'center'}
        />
        <label
          className={`center-label ${
            position === "center" && "black-font"
          }`}
          htmlFor="center"
        >
          <h4>{labels.center.title}</h4>
        </label>

        <input
          onChange={(e) => this.getSwitchAnimation(e.target.value)}
          name="map-switch"
          id="right"
          type="radio"
        //   value={labels.right.value}
        value={'right'}
        />
        <label
          className={`right-label ${
            position === "right" && "black-font"
          }`}
          htmlFor="right"
        >
          <h4>{labels.right.title}</h4>
        </label>
      </div>
    );
  }
}



export default TripleToggleSwitch;
