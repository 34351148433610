import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

class Spinner extends React.Component {
  constructor() {
    super();
    this.SpinnerEle = React.createRef();
  }

  render() {
    Spinner.SpinnerEle = this.SpinnerEle;

    return (
      <div
        ref={this.SpinnerEle}
        style={{
          top: 0,
          left: 0,
          position: "fixed",
          width: "100vw",
          height: "100vh",
          zIndex: "9999",
          display: "none",
          background: "#7169694a",
        }}
      >
        <CircularProgress
          size={40}
          style={{
            top: "calc(50% - 27px)",
            left: "calc(50% - 27px)",
            position: "absolute",
            background: "#fff",
            borderRadius: 30,
            padding: 7,
            boxShadow:
              "0 2px 5px 0 rgba(0, 0, 0, 0.44), 0 2px 7px 0 rgba(0, 0, 0, 0.44)",
          }}
        />
      </div>
    );
  }
}

export default Spinner;

export function loading(flag) {
  const e = Spinner.SpinnerEle.current;
  if (e) {
    if (flag) {
      e.style.display = "block";
      document.body.style.overflow = "hidden";
    } else {
      e.style.display = "none";
      document.body.style.overflow = "";
    }
  }
}
