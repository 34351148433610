import {
  Box,
  FormControl,
  IconButton,
  NativeSelect,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Bar } from "@reactchartjs/react-chart.js";
import moment from "moment";
import React, { useState } from "react";
import {
  IoArrowBackCircleOutline,
  IoArrowForwardCircleOutline,
} from "react-icons/io5";
import { compose } from "recompose";

function ThreeBarVerticalGraph({
  classes,
  graphData,
  weekRange,
  // handleChangePrevious,
  // handleChangeNext,
}) {
  const [state, setState] = useState(() => {
    const dateSorted = graphData?.sort((a, b) => {
      const dateA = moment(a.localDate, "DD-MM-YYYY HH:mm:ss");
      const dateB = moment(b.localDate, "DD-MM-YYYY HH:mm:ss");
      return dateA - dateB;
    });
    let weekWise = [];
    for (let i = 0; i < dateSorted?.length; i += 7) {
      weekWise.push(dateSorted?.slice(i, i + 7));
    }

    function formattedDate(date) {
      return moment(date, "YYYY-MM-DD").format("Do MMM");
    }

    let currentDate = moment().format("DD-MM-YYYY");
    let graphValues = {
      weekRange: [],
      currentWeek: 0,
    };

    for (let i = 0; i < weekWise?.length; i++) {
      let weekData =
        weekWise[i]?.length > 3
          ? weekWise[i]
          : weekWise[i - 1]?.concat(weekWise[i]) || [];

      let startDate = weekData?.length > 0 ? weekData[0]?.localDate : null;
      let endDate =
        weekData?.length > 0 ? weekData[weekData?.length - 1]?.localDate : null;
      if (weekData !== weekWise[i]) {
        graphValues.weekRange[
          graphValues.weekRange.length - 1
        ] = `${formattedDate(startDate)}-${formattedDate(endDate)}`;

        graphValues[`week${i}`] = weekData;
      } else {
        graphValues.weekRange.push(
          startDate && endDate
            ? `${formattedDate(startDate)}-${formattedDate(endDate)}`
            : null
        );
        graphValues[`week${i + 1}`] = weekData;

        let calculatedDates = graphValues[`week${i + 1}`].filter(
          (item, index, array) => {
            if (
              moment(item.startDateLocal, "DD-MM-YYYY HH:mm:ss")
                .format("DD-MM-YYYY")
                .includes(currentDate)
            ) {
              graphValues.currentWeek = i;

              return `week${i}`;
            }
          }
        );
      }
    }

    return graphValues;
  });

  const handleChangePrevious = () => {
    if (state.currentWeek > 0) {
      setState((prev) => ({ ...prev, currentWeek: state.currentWeek - 1 }));
    }
  };

  const handleChangeNext = () => {
    if (state.currentWeek + 1 < state.weekRange.length) {
      setState((prev) => ({ ...prev, currentWeek: state.currentWeek + 1 }));
    }
  };

  console.log(state.weekRange[state?.currentWeek]);

  return (
    <Box className={classes.graph_container}>
      <Box
        sx={{
          width: "90%",
          margin: "auto",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className={classes.arrowContainer}>
          <Tooltip title="Previous Week">
            <IconButton
              className={state?.currentWeek === 0 && classes.disabled}
              onClick={() => handleChangePrevious()}
            >
              <IoArrowBackCircleOutline />
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.barChart}>
          <Box className={classes.selectContainer}>
            {/* <FormControl className={classes.dropdownStyling}>
              <NativeSelect
                id="myDropdown"
                
              >
                {state?.weekRange?.map((dateRange, index) => {
                  return (
                    <option key={dateRange} value={"index"}>
                      {dateRange}
                    </option>
                  );
                })}
                
              </NativeSelect>
            </FormControl> */}
            <Typography className={classes.scoreTitle}>
              {state.weekRange[state?.currentWeek]}
            </Typography>
          </Box>

          <Box
            sx={{
              // width: isOrganizer ? "100%" : { sm: "100%", md: "60%" },
              width: "100%",
              margin: "auto",
              height: "300px",
            }}
          >
            <Bar
              data={{
                labels: state?.[`week${state?.currentWeek + 1}`]?.map((item) =>
                  moment(item.localDate, "YYYY-MM-DD").format("DD-MM")
                ),
                datasets: [
                  {
                    label: "Sustain",
                    backgroundColor: "#77B64B",
                    data: state?.[`week${state?.currentWeek + 1}`]?.map(
                      (item) => item.positiveCount
                    ),
                  },
                  {
                    label: "Not Sustain",
                    backgroundColor: "#8487db",
                    data: state?.[`week${state?.currentWeek + 1}`]?.map(
                      (item) => item.negetiveCount
                    ),
                  },
                  {
                    label: "Not Attempted",
                    backgroundColor: "#FE7235",
                    data: state?.[`week${state?.currentWeek + 1}`]?.map(
                      (item) => item.notResponded
                    ),
                  },
                ],
              }}
              options={options}
            />
          </Box>
        </div>
        <div className={classes.arrowContainer}>
          <Tooltip title="Next Week">
            <IconButton
              onClick={() => {
                handleChangeNext();
              }}
              className={
                state?.currentWeek + 1 === state.weekRange?.length &&
                classes.disabled
              }
              // disabled={selectWeek === stepperGraphData.weekRange.length}
            >
              <IoArrowForwardCircleOutline
              // className={
              //   // selectWeek === stepperGraphData.weekRange.length
              //   //   ? classes.disabledArrow
              //   //   : classes.rightArrow
              // }
              // onClick={() => {
              //   nextClickHandler();
              // }}
              />
            </IconButton>
          </Tooltip>
        </div>
      </Box>
      {/* </Container> */}
    </Box>
  );
}
const styles = (theme) => ({
  graph_container: {
    width: "100%",
  },
  graphWrapper: {
    width: "100% ",
  },
  barChart: {
    width: "86%",
  },
  arrowContainer: {
    width: "7%",
  },
  headerContainer: {
    paddingBottom: 15,
  },
  title: {
    color: "#202260",
    fontWeight: "bold",
    fontFamily: "karla, sans serif",
    fontSize: "35px",
    letterSpacing: "-1px",
  },
  desc: {
    color: "black",
    margin: "0",
    fontSize: "26px",
    fontWeight: "bolder",
  },
  scoreTitle: {
    marginTop: "5px",
    fontWeight: "600",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  selectContainer: {
    padding: "10px",
    marginBottom: "5px",
    display: "flex",
    // justifyContent: "end",
    alignItems: "center",
    width: "90%",
  },
  disabled: {
    color: "#8080807a",
    cursor: "not-allowed",
  },
});

export default compose(withStyles(styles, { withTheme: true }))(
  ThreeBarVerticalGraph
);
const options = {
  maintainAspectRatio: false,

  layout: {
    padding: {
      top: 20, //fix for cutoff issue
    },
  },
  tooltips: {
    enabled: false,
  },
  datalabels: {
    align: "top",
    anchor: "end",
    display: "auto",
  },

  legend: {
    position: "bottom",
    align: "end",
    onClick: function (event, legendItem) {},
  },

  scales: {
    yAxes: [
      {
        gridLines: {
          drawOnChartArea: false,
        },
        ticks: {
          min: 0,
          fontWeight: "700",
          fontColor: "black",
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          drawOnChartArea: false,
        },
        ticks: {
          fontWeight: "700",
          fontColor: "black",

          fontSize: 12,
        },
      },
    ],
  },
  hover: {
    animationDuration: 0,
  },
  animation: {
    duration: 0,
    onComplete: function (tooltipItem) {
      var chartInstance = this.chart,
        ctx = chartInstance.ctx;
      this.data.datasets.forEach(function (dataset, i) {
        var meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach(function (bar, index) {
          var data = dataset.data[index];
          var height = chartInstance.controller.boxes[0].bottom;
          ctx.font = "15px !important";
          ctx.textAlign = "center";
          if (data) {
            ctx.fillText(data || 0, bar._model.x, bar._model.y - 5);
          }
        });
      });
    },
  },
};
