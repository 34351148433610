import React, { Fragment } from "react";
import { connect } from "react-redux";
import { updateDrawersEventList, updateUserDetails } from "../../../redux/user";

import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Divider,
  Box,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import AppToolBar from "../../common/AppToolBar";
import Properties from "../../../properties/Properties";
import MultiSelect from "../../common/MultiSelect";
import SubmitCancelBtn from "../../common/SubmitCancelBtn";
import DialogBox from "../../common/DialogBox";
import { TableRow, Table, TableCell, TableBody } from "@material-ui/core";
import {
  getEventDetails,
  registerEventCategory,
  getEventActivityDetails,
  getorderDetails,
  getCaptureDetails,
  getSecondaryActivity,
  getRegisteredEventDetails,
  getEventList,
  getDiscountCoupon,
} from "../../../apis/eventService";
import getRunnerGroupList from "../../../apis/runnerGroupService";

import Snackbar from "../../common/Snackbar";
import ApplicationUtil from "../../../utils/ApplicationUtil";
import ApplicationConstants from "./../../../constants/ApplicationConstants";
import { CustomTextField } from "../../common/CustomTextField";
import { validateEmpCode } from "../../../utils/Validator";
import Header from "../../common/Header";
import InfoIcon from "@material-ui/icons/Info";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { IconCalendarBlue } from "../../common/SvgIcon";
import { isBrowser } from "react-device-detect";
import Checkbox from "@material-ui/core/Checkbox";
import { blue, green } from "@material-ui/core/colors";
import { countryList } from "../../../constants/CountryList";
import { withRouter } from "react-router-dom";
import CustomFields from "../../common/CustomFields";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import EventItems from "../../common/EventItems";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RunnerSideBreadcrumbs from "../../common/RunnerSideBreadcrumbs";
var moment = require("moment");
const styles = (theme) => ({
  mainPanel: {
    marginTop: "60px",
    // overflowX: "hidden",
  },

  customInputCss: {
    border: "1px solid #08456C",
    height: "38px", //"55%",
    maxHeight: "38px", //"55%",
    minHeight: "38px", //"55%",
    width: window.innerWidth >= 600 ? "360px" : "220px",
    minWidth: window.innerWidth >= 600 ? "360px" : "220px",
    maxWidth: window.innerWidth >= 600 ? "360px" : "220px",
  },
  merchandiseTitle: {
    color: "black",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "2px 0px",
  },
  eventItemsStyling: {
    marginLeft: "6.7%",
    marginRight: "6.7%",
    marginTop: "1%",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "6.7%",
      marginRight: "6.7%",
      marginTop: "3%",
      marginBottom: "3%",
    },
  },
  customInputErrorCss: {
    border: "1px solid red",
    height: "38px", //"55%",
    maxHeight: "38px", //"55%",
    minHeight: "38px", //"55%",
    width: window.innerWidth >= 600 ? "360px" : "220px",
    minWidth: window.innerWidth >= 600 ? "360px" : "220px",
    maxWidth: window.innerWidth >= 600 ? "360px" : "220px",
  },
  datePicker: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid #08456C",
    width: window.innerWidth >= 600 ? "360px" : "220px",
    minWidth: window.innerWidth >= 600 ? "360px" : "220px",
    maxWidth: window.innerWidth >= 600 ? "360px" : "220px",
  },
  datePickerError: {
    height: 38,
    paddingRight: 0,
    fontSize: 15,
    border: "1px solid red",
    width: window.innerWidth >= 600 ? "360px" : "220px",
    minWidth: window.innerWidth >= 600 ? "360px" : "220px",
    maxWidth: window.innerWidth >= 600 ? "360px" : "220px",
  },
  calendarIconCss: {
    height: 22,
    width: 22,
  },
  dropdownCss: {
    width: window.innerWidth >= 600 ? "360px" : "220px",
    minWidth: window.innerWidth >= 600 ? "360px" : "220px",
    maxWidth: window.innerWidth >= 600 ? "360px" : "220px",
  },
  helpCss: {
    display: "flex",
    color: "#3a6ea5",
  },
  helpContainerCss: {
    display: "inline-flex",
  },
  incrementDistanceBlock: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  incrementDistanceMenu: {
    marginLeft: "10px",
    width: "100px",
  },
  checkBox: {
    "&.Mui-checked": {
      color: "#296dfa",
    },
  },
  customFieldTopBar: {
    marginLeft: "5%",
    width: "87%",
  },
  customFieldContainer: {
    marginLeft: "7.3%",
    marginTop: "10px",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  note: {
    fontSize: "18px",
    color: "red",
    // fontWeight:'bold'
  },
  couponAccordion: {
    margin: "2% 6.7% 1% 6.9% !important",
    border: "none",
    width: "30%",
    borderRadius: "10px",

    "& .Mui-expanded": {
      minHeight: "30px",
      height: "30px",
    },
    "& .MuiAccordionDetails-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "&::before": {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
});

class RegisterEvent extends React.Component {
  state = {
    open: false,
    eventTotalList: [],
    eventCategoryList: [],
    eventTypeList: [],
    newEventTypeList: [],
    eventKm: 5,
    userMail: "",
    eventCategory: "",
    eventCategoryError: false,
    eventTypeError: false,
    eventDate: "",
    eventTime: "",
    eventName: "",
    eventCategoryRegistrationFee: "",
    changeActivity: false,
    changeActivityMsg: "",
    dialogHeader: "Registration Successful",
    dailogMsg: "",
    eventType: null,
    signupCondition: false,
    showInviteCondition: false,
    registrationFee: 0,
    grandTotal: 0,
    shirtSize: null,
    selectedSize: null,
    selectedSizeError: false,
    streakType: false,
    days: null,
    hour: 0,
    hourError: "",
    minute: 0,
    minuteError: "",
    second: 0,
    secondError: "",
    showAgeGroup: false,
    staticDataList: null,
    selectedStaticData: "",
    activityType: "",
    showPersonalBest: true,
    computeAdvisedPace: true,
    eventScope: "",
    city: "",
    cityError: false,
    country: { label: "India", value: "India" },
    countryError: false,
    address: "",
    addressError: false,
    pincode: "",
    pincodeError: false,
    state: "",
    stateError: false,
    countryCodeList: countryList,
    countryList: ApplicationConstants.countries,
    contactNumber: "",
    contactNumberError: false,
    contactCode: countryList.find((x) => x.value === " 91"),
    termsCheckboxSelected: false,
    orderId: null,
    showAddressFields: false,
    addressList: [
      { label: "Bangalore Office", value: " Bangalore Office" },
      { label: "Goa Office", value: "Goa Office" },
      { label: "Hyderabad Office", value: "Hyderabad Office" },
      { label: "HJ Pune Office", value: "HJ Pune Office" },
      { label: "PT Pune Office", value: "PT Pune Office" },
      { label: "Nagpur office", value: "Nagpur office" },
      { label: "Other", value: "Other" },
    ],
    selectedAddress: { label: "Other", value: "Other" },
    selectedAddressError: false,
    showOtherAddressFields: false,
    transactionID: "",
    transactionStatus: "",
    showChartOpen: false,
    employeeCode: "",
    employeeCodeError: false,
    showContactField: false,
    registerOnFailure: false,
    registrationEnded: null,
    registrationOpen: null,
    customSectionLabel: null,
    description: [],
    ticketSoldOut: false,
    shippingRequired: false,
    displayIncrementDistance: false,
    incrementDistance: false,
    participationType: { label: "Physical", value: "REGULAR_PHYSICAL" },
    participationTypeError: false,
    participationTypeList: ApplicationConstants.participationTypeList,
    showParticipationType: false,
    hideCustomSectionLabel: false,
    height: null,
    weight: null,
    heightError: false,
    weightError: false,
    corporateName: null,
  };

  componentDidMount() {
    document.title = Properties.Page_Title_Event_Registration;
    let runnerData = ApplicationUtil.getRunnerDetails();

    this.setState({
      userMail: runnerData?.email,
      height: runnerData?.height,
      weight: runnerData?.weight,
    });
    // store
    let loginData = runnerData;
    if (loginData?.city) this.setState({ city: loginData.city });
    if (loginData?.contactNumber) {
      this.setState({
        contactNumber: loginData.contactNumber,
      });
      this.state.countryCodeList.map((c) => {
        if (c.value == loginData.countryCode) this.setState({ contactCode: c });
      });
    }
    if (loginData?.state)
      this.setState({
        state: loginData.state,
      });
    if (loginData?.pincode)
      this.setState({
        pincode: loginData.pincode,
      });
    if (loginData?.country) {
      this.state.countryList.map((c) => {
        if (c.value == loginData.country) this.setState({ country: c });
      });
    }

    let runTime;

    if (loginData?.runPbTime) {
      runTime = loginData.runPbTime != undefined && loginData.runPbTime != 0;
    }
    if (
      (loginData?.cyclePbTime != undefined && loginData?.cyclePbTime != 0) ||
      runTime
    ) {
      this.setState({ showPersonalBest: false });
    }

    let staticRunList = [
      { label: 5, value: "5K" },
      { label: 10, value: "10K" },
      { label: 21.1, value: "21.1K" },
      { label: 42.2, value: "42.2K" },
    ];
    this.setState({
      signupCondition: localStorage.getItem("fromSignup"),
      staticDataList: staticRunList,
    });

    let eventId = ApplicationUtil.getRunnersEventID();
    let runnerId = runnerData?.runner?.id;

    getRegisteredEventDetails(runnerId, eventId).then((result) => {
      if (
        result.data?.registration === undefined ||
        result.data?.registration === null
      ) {
        let requestparam = { requestView: "REGISTER_EVENT", runnerId };
        getEventActivityDetails(requestparam, eventId).then((response) => {
          response.data.map(
            (r) => (
              (r.label = r.displayName),
              (r.value = r.type),
              (r.id = r.id),
              (r.eventSupportedActivityTypeId =
                r?.eventSupportedActivityTypeId),
              (r.participantType = r.participantType)
            )
          );

          this.setState({
            allEventTypeList: response?.data,
            eventTypeList: response?.data,
            eventType: response?.data[0],
          });
        });
        getEventDetails(requestparam?.requestView, eventId).then((res) => {
          res.data.eventRunCategories.map(
            (r) => (
              (r.label = r.categoryName),
              (r.value = r.id),
              (r.category = r.category),
              (r.eventSupportedActivityType = r?.eventSupportedActivityType)
            )
          );

          let incrementDistanceArr = [];
          res.data.displayIncrementDistance &&
            res.data.eventIncrementDistanceList.map((x) => {
              let obj = {
                label: x.displayName,
                value: x.incrementDistance,
                id: x.id,
                eventRunCategoryId: x.eventRunCategoryId,
              };

              incrementDistanceArr.push(obj);
            });

          this.setState({
            displayIncrementDistance: res.data.displayIncrementDistance,
            incrementDistanceValue: incrementDistanceArr[0],
            incrementDistanceList: incrementDistanceArr,
          });
          //multiple activity change
          if (res.data.eventRunCategories) {
            let catList = [];
            res.data.eventRunCategories.forEach((element) => {
              if (element?.eventSupportedActivityType?.id) {
                if (
                  res.data.eventParticipantType === "ALL" &&
                  res.data.showParticipationType &&
                  this.state?.eventType?.eventSupportedActivityTypeId &&
                  this.state?.eventType?.eventSupportedActivityTypeId ===
                    element?.eventSupportedActivityType?.id
                ) {
                  if (!element.hidden) {
                    catList.push(element);
                  } else {
                    return;
                  }
                } else if (
                  this.state?.eventType?.eventSupportedActivityTypeId &&
                  this.state?.eventType?.eventSupportedActivityTypeId ===
                    element?.eventSupportedActivityType?.id
                ) {
                  if (!element.hidden) {
                    catList.push(element);
                  } else {
                    return;
                  }
                }
              } else {
                if (
                  this.state?.eventType?.type === element?.activityType?.type
                ) {
                  catList.push(element);
                }
              }
            });

            if (
              res.data.eventParticipantType === "ALL" &&
              res.data.showParticipationType
            ) {
              let newEventsTypeList = [];
              this.state.allEventTypeList.forEach((element) => {
                if (
                  element.participantType === this.state.participationType.value
                ) {
                  newEventsTypeList.push(element);
                }
              });
              this.setState({
                eventType: newEventsTypeList[0],
                eventTypeList: newEventsTypeList,
                showParticipationType: res.data.showParticipationType,
                eventParticipantType: res.data.eventParticipantType,
              });
            }

            this.setState(
              {
                showParticipationType: res.data.showParticipationType,
                eventParticipantType: res.data.eventParticipantType
                  ? res.data.eventParticipantType
                  : "REGULAR",
                isPhysical: ApplicationUtil.checkPhysicalEvent(
                  res.data.eventParticipantType,
                  res.data.showParticipationType
                ),
                // res.data.eventParticipantType === "PHYSICAL"
                //   ? true
                //   : res.data.eventParticipantType === "VIRTUAL"
                //   ? false
                //   : false,
                eventCategoryList: catList,
                eventCategory: catList[0],
                completeEventCategories: res.data.eventRunCategories,

                showActivityTypeOnRegistration:
                  res.data?.showActivityTypeOnRegistration,
                showCategoryOnRegistration:
                  res.data?.showCategoryOnRegistration,
                showBriefTextOnRegistration:
                  res.data?.showBriefTextOnRegistration,
                briefRegistrationText: res?.data?.briefRegistrationText,
                hideCustomSectionLabel: false, //res.data?.hideCustomSectionLabel,
              },
              () => {
                this.handleCategoryChange(catList[0]);
                this.handleTypeChange(this.state.eventType);
                // this.handleParticipationTypeChange(this.state.participationType);
              }
            );
          }

          //description
          let descArr = [];
          res.data.eventRunCategories.map((desc) => {
            if (desc?.description) {
              descArr.push({
                label: desc?.activityType?.type,
                desc: desc?.description,
                category: desc?.categoryName,
                maxTickets: desc?.maxTickets,
                ticketsSold: desc?.ticketsSold,
                ticketSoldOut: desc?.ticketSoldOut,
              });
            }
          });
          if (descArr) {
            this.setState({
              description: descArr,
            });
          }
          if (res.data?.wellnessPointLookupList) {
            let height = runnerData?.height; //converting height into cm
            let weight = runnerData?.weight;
            let bmi;
            if (height && height > 50 && weight && weight > 0) {
              bmi = Number(weight / ((height / 100) * (height / 100))).toFixed(
                2
              );
            }
            let findWellnessPoints = res.data?.wellnessPointLookupList.find(
              (x) => {
                let range1 = x?.bmiRange1 ? x.bmiRange1 : 0;
                return bmi <= x.bmiRange2;
              }
            );
            this.setState({
              wellnessPointLookupList: res.data?.wellnessPointLookupList,
              calculatedBmi: bmi,
              calculatedSuggestedPoints:
                height && weight ? findWellnessPoints?.wellnessPoint : "",
              calculatedSuggestedSteps:
                height && weight ? findWellnessPoints?.steps : "",
              calculatedSuggestedDistance:
                height && weight ? findWellnessPoints?.distance : "",
            });
          }
          //eventState
          this.setState({ eventState: res?.data?.eventState });
          let selectedType = "";
          //values for Activity type from event/id?requestView="INVITATION"
          // if (res.data && res.data.activities) {
          //   res.data.activities.map(
          //     (r) => ((r.label = r.displayName), (r.value = r.type), (r.id = r.id))
          //   );
          //   // this.setState({ eventType: res.data.activities[0] });
          //   this.setState({
          //     newEventTypeList: res.data.activities,
          //     eventType: res.data.activities[0],
          //   });
          //   selectedType = res.data?.activities[0]?.type;
          // }
          //
          let [hours, minutes, seconds] = res.data.eventTime.split(":");
          let exactDate = ApplicationUtil.FormatDateToUTC(
            res.data.eventDate,
            res.data.eventTime
          );
          this.setState({
            showInviteOption:
              res.data.sendInvitation && res.data.isRegistrationOpen,
          });
          localStorage.setItem("showInviteOption", this.state.showInviteOption);

          let date = res.data.registrationEndDate;
          if (date != "undefined") {
            let showRegistrationOpen = res.data.isRegistrationOpen; //isAfter(utcDate, finalEventDate);
            this.setState({
              registrationEnded: !showRegistrationOpen,
              registrationOpen: showRegistrationOpen,
            });
          }
          if (res.data) {
            this.setState({
              displayIncrementDistance: res.data.displayIncrementDistance,
              registerOnFailure: res.data.registerOnFailure,
              showInviteCondition: res.data.sendInvitation,
              showHtWtOnSignup: res.data.showHtWtOnSignup,
              challengeParams: res.data.activities.map(
                (activity) => activity.challengeParams
              ),
              showSuggestionOnRegisterEvent:
                res.data.showSuggestionOnRegisterEvent,
              wellnessPointDesc: res.data.wellnessPointDesc,
              hasDiscountCoupon: res.data?.hasDiscountCoupon,
            });
          }

          if (res?.data?.eventItems?.length > 0) {
            let eventItems = res.data?.eventItems;
            let eventId = res.data?.id;
            let eventItemsArr = [];
            let singleEventItem = [];
            let item = eventItems.find((x) =>
              x?.itemName?.toLowerCase().includes("t-shirt")
            );
            for (let i = 0; i < eventItems.length; i++) {
              let obj = {
                ...eventItems[i],
                itemQuantity:
                  eventItems[i]?.type === "IMPLICITE"
                    ? eventItems[i]?.maxQuantity
                    : 0,
                totalRowPrice: 0,
                hideItem:
                  (eventId == 1879 ||
                    eventId == 508 ||
                    eventId == 507 ||
                    eventId === 513) &&
                  item &&
                  eventItems[i]?.itemName?.toLowerCase().includes("medal")
                    ? true
                    : false,
              };
              if (eventItems[i]?.sizeMale || eventItems[i]?.sizeFemale) {
                obj.sizeArray =
                  runnerData?.gender === "FEMALE"
                    ? eventItems[i]?.sizeFemale
                        .split(",")
                        .map((x) => ({ label: x, value: x }))
                    : eventItems[i]?.sizeMale
                        .split(",")
                        .map((x) => ({ label: x, value: x }));
                obj.selectedSizeError = false;
              }
              eventItemsArr.push(obj);
            }

            let eventItemsData = {
              eventItems: eventItemsArr,
              showShippingDetails: eventItemsArr.some(
                (x) => x?.itemQuantity > 0
              ),
              countryCode: runnerData?.countryCode
                ? {
                    label: runnerData?.country,
                    value: ` ${runnerData?.countryCode}`,
                  }
                : countryList.find((x) => x.value === " 91"),
              countryCodeList: countryList,
              contactNumber: runnerData?.contactNumber,
              contactNumberError: false,
              // address: runnerData?.address,
              addressError: false,
              city: runnerData?.city,
              cityError: false,
              pincode: runnerData?.pincode,
              pincodeError: false,
              state: runnerData?.state,
              stateError: false,
              country: runnerData?.country
                ? {
                    label: runnerData?.country,
                    value: countryList?.find((x) =>
                      x.label.includes(runnerData?.country)
                    )?.value,
                  }
                : countryList.find((x) => x.value === " 91"),
              countryError: false,
            };
            if (item) {
              if (item?.eventItemSizeChartImage?.imageData) {
                eventItemsData.tShirtSizeChartFile =
                  "data:image/png;base64," +
                  item?.eventItemSizeChartImage?.imageData;
              }
            }

            this.setState({
              eventItemsFormData: eventItemsData,
            });
          }
          let list = res.data.eventRunCategories;
          let runList = [],
            cycleList = [],
            duathlonList = [],
            stepsList = [];
          list.forEach((element) => {
            if (element.activityType.type === "RUN") {
              runList.push(element);
              return;
            }
            if (element.activityType.type === "CYCLE") {
              cycleList.push(element);
              return;
            }
            if (element.activityType.type === "DUATHLON") {
              duathlonList.push(element);
              return;
            }
            if (element.activityType.type === "STEPS") {
              stepsList.push(element);
              return;
            }
          });
          let newlist = [];
          if (selectedType == "RUN") newlist = runList;
          else if (selectedType == "CYCLE") newlist = cycleList;
          else if (selectedType == "DUATHLON") newlist = duathlonList;
          else if (selectedType == "STEPS") newlist = stepsList;

          // this.setState(
          //   {
          //     eventCategoryList: newlist,
          //   },
          //   () => {
          //     this.handleCategoryChange(this.state.eventCategoryList[0]);
          //   }
          // );

          let start = moment(
            moment(exactDate, "DD-MM-YYYY").format("YYYY-MM-DD")
          ); //res.data.eventDate; //+ " "+;

          if (
            res.data.type == "STREAK" ||
            res.data.type == "CHALLENGE" ||
            res.data.type == "TRAINING"
          ) {
            this.setState({
              streakType: true,
              days: res?.data?.eventStreakDetail?.totalDays,
            });
            localStorage.setItem("days", this.state.days);
          } else this.setState({ streakType: false });
          let end = moment(start)
            .add(this.state.days - 1, "day")
            .format("DD-MM-YYYY");

          //show secondary activity
          if (
            res?.data?.showSecondaryActivityType == "SHOW_ON_REG" ||
            res?.data?.showSecondaryActivityType == "SHOW_ON_REG_INVITEE"
          ) {
            getSecondaryActivity(eventId, this.state.eventType?.id).then(
              (response) => {
                let secondaryActivityList = response.data.secondaryActivityType;
                secondaryActivityList.map(
                  (r) => (
                    (r.label = r.displayName), (r.value = r.type), (r.id = r.id)
                  )
                );
                this.setState({
                  secondaryActivityList: secondaryActivityList,
                  showSecondaryActivity: true,
                });
              }
            );
          }

          if (res.data?.showRunnerGroup) {
            let runnerGroupData = res.data?.runnerGroupListDto?.data?.map(
              (x) => {
                if (x?.city) {
                  x.city = x.city.toLowerCase();
                }
                if (x?.groupVisibility) {
                  return x;
                }
              }
            );

            // logic for grouping runner groups based on city

            let runnerGroupWithCity = runnerGroupData
              .filter((x) => x?.city)
              .map((a) => {
                a.label = a?.name;
                a.value = `${a?.name} ${a?.city.toUpperCase()}`;
                return a;
              });
            let runnerGroupWithOther = runnerGroupData
              .filter((x) => !x?.city)
              .map((a) => {
                a.label = a?.name;
                a.value = a?.name;
                return a;
              });

            let cityList = ["Custom"];
            let city;
            runnerGroupWithCity.forEach((item) => {
              if (city !== item.city && !cityList.includes(item.city)) {
                cityList.push(item.city);
                city = item.city;
              }
            });

            if (runnerGroupWithOther?.length > 0) {
              cityList.push("Others");
            }
            // cityList.push('Custom')

            let list = [];
            cityList.forEach((item, index) => {
              let obj = {
                id: index + 1,
                // label : `${item.charAt(0).toUpperCase()}${item.substring(1)}`,
                label: item,
                options:
                  item === "Custom"
                    ? [{ label: "Other" }]
                    : item === "Others"
                    ? runnerGroupWithOther
                    : runnerGroupWithCity.filter((x) => x.city === item),
              };
              list.push(obj);
            });

            if (runnerData?.groupDetails) {
              let details = { ...runnerData?.groupDetails };
              details.label = details?.name;
              details.value = details?.city
                ? `${details?.name} ${details?.city.toUpperCase()}`
                : details?.name;

              this.setState({
                selectedRunnerGroup: details,
                groupDetails: details,
              });
            }

            this.setState({ runnerGroupList: list });
          }
          if (res.data?.showAgeGroup) {
            let ageGroupData = res.data?.ageGroupDTOList;
            if (ageGroupData?.length > 0) {
              ageGroupData.map((x) => {
                x.label = x?.groupName;
                x.value = x?.id;
                return x;
              });
              this.setState({ ageGroupList: ageGroupData });
            }
          }
          if (res.data?.showCorporateOnReg) {
            let corporateData = res.data?.corporateListDto?.data;
            corporateData.map((x) => {
              x.label = x?.displayName;
              x.value = x?.id;
              return x;
            });
            corporateData.unshift({ label: "Other" });
            if (runnerData?.corporateListDto) {
              let details = { ...runnerData?.corporateListDto };
              details.label = details?.displayName;
              details.value = details?.id;

              this.setState({ selectedCorporate: details });
            }
            this.setState({ corporateList: corporateData });
          }
          this.setState(
            {
              eventId: eventId,
              runnerId: runnerId,

              eventTotalList: res.data.eventRunCategories,
              // eventCategoryList: selectedList,
              // eventCategory: selectedList[0], // runList.length == 1 ? runList[0] : "",
              eventName: res.data.name,
              eventDesc: res.data?.description,
              eventDate: ApplicationUtil.changeDateFormatDate(
                res?.data?.localStartDate
              ),
              eventEnd: ApplicationUtil.changeDateFormatDate(
                res?.data?.localEndDate
              ),
              eventTime:
                hours > 12
                  ? hours.toString() + ":" + minutes.toString() + " PM"
                  : hours.toString() + ":" + minutes.toString() + " AM",
              eventId: res.data.id,
              computeAdvisedPace: res.data.computeAdvisedPace,
              shippingRequired: res.data?.shippingRequired,
              showRunnerGroup: res.data?.showRunnerGroup,
              showAgeGroup: res.data?.showAgeGroup,
              showCorporateOnReg: res.data?.showCorporateOnReg,
              supportedDomain: res.data?.supportedDomains,
            },
            () => {
              this.handleCategoryChange(this.state.eventCategoryList[0]);
            }
          );
          let count = 0;

          //additional details
          if (
            res.data.fields &&
            res.data.fields.customFields &&
            res.data.fields.customFields.length > 0
          ) {
            let d = res.data.fields.customFields;
            d.sort((a, b) => {
              return a.displayOrder - b.displayOrder;
            });

            d = d.filter(function (ele) {
              if (ele.formName == "REGISTER_EVENT") return true;
            });

            d.forEach((row) => {
              if (row.inputField == "SINGLE_SELECT") {
                let optionsList = row.fieldOptions;
                optionsList.map(
                  (r) => ((r.label = r.optionValue), (r.value = r.id))
                );
              }
            });

            // let newD = [];
            // d = d.filter(function (ele) {
            //   if (ele.fieldGroup) {
            //     newD.push(ele);
            //     return false;
            //   } else return true;
            // });

            // let arrCollect = [];

            // let i = 0;
            // for (let index = 0; index < groupFieldList.length; index++) {
            //   // arrCollect[index].groupName = groupList[index].groupName;
            //   newD.forEach((element) => {
            //     if (element.fieldGroup.id == groupList[index].id) {
            //       arrCollect[i] = element;
            //       i++;
            //     }
            //   });
            // }
            if (res.data.fields && res.data.fields.fieldGroups) {
              let groupFieldList = res.data.fields.fieldGroups;
              if (groupFieldList.length > 0) {
                let List = [];

                groupFieldList.forEach((element) => {
                  let list = [];
                  if (element.fieldId && element.fieldId.length > 0) {
                    for (let i = 0; i < element.fieldId.length; i++) {
                      // d.forEach((el) => {
                      for (let j = 0; j < d.length; j++) {
                        if (element.fieldId[i] == d[j].customFieldId) {
                          list.push(d[j]);
                          d.splice(j, 1);
                        }
                      }
                      // d.filter((item) => {
                      //   if (item.customFieldId == element.fieldId[i]) return true;
                      // });
                      // if (element.fieldId[i] == el.customFieldId) {
                      //   list.push(el);
                      //   d.splice(el, 1);
                      // }
                      // });
                    }
                    // List.push({
                    //   field: list,
                    //   groupName: element.fieldGroup.groupName,
                    // });
                    d.push({
                      field: list,
                      displayOrder: element.displayOrder,
                      groupName: element.fieldGroup.groupName,
                    });
                  }
                });

                // d.push(arrCollect);
                // let newList = [];
                // arrCollect.forEach((element) => {
                //   newList.push({
                //     groupName: element[groupName],
                //     list: element,
                //   });
                // });
                //this.setState({ additionalDetailsGroupedList: List });
              }
            }
            // d = { ...d, List };

            this.setState({
              additionalDetails: d.sort((a, b) =>
                a.displayOrder > b.displayOrder ? 1 : -1
              ),
            });
            if (res.data?.fields?.customSectionLabel) {
              this.setState({
                customSectionLabel: res.data?.fields?.customSectionLabel,
              });
            }
          }
        });
      } else {
        this.props.history.push("/eventDetails");
      }
    });
  }

  componentWillMount() {
    localStorage.removeItem("fromSignup");
  }
  handleSelectChange = (selectedValues, row) => {
    var arr = [];

    if (selectedValues != null) {
      arr.push(selectedValues.value);
      row.fieldValue = selectedValues;
      row.isError = false;
    } else {
      row.fieldValue = selectedValues;
      row.isError = false;
    }
    let newData = [...this.state.additionalDetails];
    let index = newData.findIndex(
      (obj) => obj.customFieldId == row.customFieldId
    );
    newData[index].isError = row.isError ? true : false;
    if (row.dependentField.length > 0) {
      row.dependentField.forEach((ele) => {
        for (let i = 0; i < newData.length; i++) {
          if (newData[i].customFieldId == ele.dependentFieldId) {
            if (row.fieldValue == "") newData[i].hidden = true;
            else newData[i].hidden = false;
          }
        }
      });
    }

    this.setState({ additionalDetails: newData });
  };

  handleMultiSelectChange = (selectedValues, row) => {
    var arr = [];

    if (selectedValues != null) {
      selectedValues.forEach((element) => {
        arr.push(element);
      });

      row.fieldValue = selectedValues;
      row.isError = false;
    } else {
      row.fieldValue = selectedValues;
      row.isError = true;
    }
    let newData = [...this.state.additionalDetails];
    let index = newData.findIndex(
      (obj) => obj.customFieldId == row.customFieldId
    );
    newData[index].isError = row.isError ? true : false;
    this.setState({ additionalDetails: newData });
  };
  handleGroupedSelectChange = (selectedValues, row, rowIndex) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      row.fieldValue = selectedValues;
      row.isError = false;
    } else {
      row.fieldValue = selectedValues;
      row.isError = false;
    }
    let newData = [...this.state.additionalDetails];
    if (newData[rowIndex].field) {
      let index = newData[rowIndex].field.findIndex(
        (obj) => obj.customFieldId == row.customFieldId
      );
      newData[rowIndex].field[index].isError = row.isError ? true : false;
    }
    if (row.dependentField.length > 0) {
      row.dependentField.forEach((ele) => {
        for (let i = 0; i < newData.length; i++) {
          for (
            let j = 0;
            newData[i].field && j < newData[i].field.length;
            j++
          ) {
            if (newData[i].field[j].customFieldId == ele.dependentFieldId) {
              if (row.fieldValue == "") newData[i].field[j].hidden = true;
              else newData[i].field[j].hidden = false;
            }
          }
          // if (
          //   newData[i].field.customFieldId == ele.dependentFieldId
          // ) {
          //   if (row.fieldValue == "") newData[i].hidden = true;
          //   else newData[i].hidden = false;
          // }
        }
      });
    }
    this.setState({ additionalDetails: newData });
  };

  handleGroupedMultiSelectChange = (selectedValues, row, rowIndex) => {
    var arr = [];
    if (selectedValues != null) {
      selectedValues.forEach((element) => {
        arr.push(element);
      });
      row.fieldValue = selectedValues;
      row.isError = false;
    } else {
      row.fieldValue = selectedValues;
      row.isError = false;
    }
    let newData = [...this.state.additionalDetails];
    if (newData[rowIndex].field) {
      let index = newData[rowIndex].field.findIndex(
        (obj) => obj.customFieldId == row.customFieldId
      );
      newData[rowIndex].field[index].isError = row.isError ? true : false;
    }
    if (row.dependentField.length > 0) {
      row.dependentField.forEach((ele) => {
        for (let i = 0; i < newData.length; i++) {
          for (
            let j = 0;
            newData[i].field && j < newData[i].field.length;
            j++
          ) {
            if (newData[i].field[j].customFieldId == ele.dependentFieldId) {
              if (row.fieldValue == "") newData[i].field[j].hidden = true;
              else newData[i].field[j].hidden = false;
            }
          }
          // if (
          //   newData[i].field.customFieldId == ele.dependentFieldId
          // ) {
          //   if (row.fieldValue == "") newData[i].hidden = true;
          //   else newData[i].hidden = false;
          // }
        }
      });
    }
    this.setState({ additionalDetails: newData });
  };

  renderRequiredField = (inputField, row, index) => {
    const { classes } = this.props;
    if (inputField === "TEXT") {
      return (
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          className={classes.customTextField}
        >
          <Typography variant="body2" gutterBottom>
            <span className={classes.customFieldTitle}>{row.displayName}</span>
            {row.requiredField && (
              <span className={classes.customFieldRequired}>*</span>
            )}
          </Typography>
          <CustomTextField
            className={
              row.isError ? classes.customInputErrorCss : classes.customInputCss
            }
            type={"text"}
            error={row.isError}
            keyToSet={row.displayName}
            defaultValue={row.fieldValue}
            handleChange={(prop) => (event) => {
              row.fieldValue = event.target.value;
              row.isError = false;
              let newData = [...this.state.additionalDetails];
              let index = newData.findIndex(
                (obj) => obj.customFieldId == row.customFieldId
              );
              newData[index].isError = row.isError ? true : false;
              if (row.dependentField.length > 0) {
                row.dependentField.forEach((ele) => {
                  for (let i = 0; i < newData.length; i++) {
                    if (newData[i].customFieldId == ele.dependentFieldId) {
                      if (row.fieldValue == "") newData[i].hidden = true;
                      else newData[i].hidden = false;
                    }
                  }
                });
              }

              this.setState({ additionalDetails: newData });
            }}
          />
        </Grid>
      );
    } else if (inputField === "SINGLE_SELECT") {
      let optionsList = row.fieldOptions;
      optionsList.map((r) => ((r.label = r.optionValue), (r.value = r.id)));
      let val = null;

      optionsList.forEach((element) => {
        if (element.label == row.fieldValue) {
          val = element;
        }
      });

      return (
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          className={classes.customTextField}
        >
          <Typography variant="body2" gutterBottom>
            <span className={classes.customFieldTitle}>{row.displayName}</span>
            {row.requiredField && (
              <span className={classes.customFieldRequired}>*</span>
            )}
          </Typography>
          <div className={classes.dropdownCss}>
            <MultiSelect
              //value={val}
              error={row.isError}
              options={optionsList}
              onChange={(e) => {
                this.handleSelectChange(e, row);
              }} //{this.handleSelectChange(e, i)}
            />
          </div>
        </Grid>
      );
    } else if (inputField === "MULTI_SELECT") {
      let optionsList = row.fieldOptions;
      optionsList.map((r) => ((r.label = r.optionValue), (r.value = r.id)));
      let val = null;

      optionsList.forEach((element) => {
        if (element.label == row.fieldValue) {
          val = element;
        }
      });

      return (
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          className={classes.customTextField}
        >
          <Typography variant="body2" gutterBottom>
            <span className={classes.customFieldTitle}>{row.displayName}</span>
            {row.requiredField && (
              <span className={classes.customFieldRequired}>*</span>
            )}
          </Typography>
          <div className={classes.dropdownCss}>
            <MultiSelect
              isMulti={true}
              value={row.fieldValue}
              error={row.isError}
              options={optionsList}
              onChange={(e) => {
                this.handleSelectChange(e, row);
              }}
            />
          </div>
        </Grid>
      );
    } else if (inputField === "DATE") {
      return (
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.customFieldTitle}>{row.displayName}</span>
            {row.requiredField && (
              <span className={classes.customFieldRequired}>*</span>
            )}
          </Typography>
          <div>
            <KeyboardDatePicker
              autoOk
              clearable
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              // disableFuture={true}
              style={{
                width: "360px",
                minWidth: "360px",
                maxWidth: "360px",
              }}
              defaultValue={row.fieldValue}
              value={row.fieldValue}
              error={row.isError}
              className={classes.fieldWidth}
              InputProps={{
                classes: {
                  root: row.isError
                    ? classes.datePickerError
                    : classes.datePicker,
                },
              }}
              keyboardIcon={
                <IconCalendarBlue className={classes.calendarIconCss} />
              }
              onChange={(date) => {
                row.fieldValue = date;
                row.isError = false;

                let newData = [...this.state.additionalDetails];
                let index = newData.findIndex(
                  (obj) => obj.customFieldId == row.customFieldId
                );
                newData[index].isError = row.isError ? true : false;
                if (row.dependentField.length > 0) {
                  row.dependentField.forEach((ele) => {
                    for (let i = 0; i < newData.length; i++) {
                      if (newData[i].customFieldId == ele.dependentFieldId) {
                        if (row.fieldValue == "") newData[i].hidden = true;
                        else newData[i].hidden = false;
                      }
                    }
                  });
                }
                this.setState({ additionalDetails: newData });
              }}
            />
          </div>
        </Grid>
      );
    }
  };

  renderRequiredGroupedField = (inputField, row, index, rowIndex) => {
    const { classes } = this.props;

    if (inputField == "TEXT") {
      return (
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          className={classes.customTextField}
        >
          <Typography variant="body2" gutterBottom>
            <span className={classes.customFieldTitle}>{row.displayName}</span>
            {row.requiredField && (
              <span className={classes.customFieldRequired}>*</span>
            )}
          </Typography>
          <CustomTextField
            className={
              row.isError ? classes.customInputErrorCss : classes.customInputCss
            }
            type={"text"}
            error={row.isError}
            keyToSet={row.displayName}
            defaultValue={row.fieldValue}
            handleChange={(prop) => (event) => {
              row.fieldValue = event.target.value;
              row.isError = false;
              //let newData = [...this.state.additionalDetailsGroupedList];
              let newData = [...this.state.additionalDetails];
              if (newData[rowIndex].field) {
                let index = newData[rowIndex].field.findIndex(
                  (obj) => obj.customFieldId == row.customFieldId
                );
                newData[rowIndex].field[index].isError = row.isError
                  ? true
                  : false;
              }
              if (row.dependentField.length > 0) {
                row.dependentField.forEach((ele) => {
                  for (let i = 0; i < newData.length; i++) {
                    for (
                      let j = 0;
                      newData[i].field && j < newData[i].field.length;
                      j++
                    ) {
                      if (
                        newData[i].field[j].customFieldId ==
                        ele.dependentFieldId
                      ) {
                        if (row.fieldValue == "")
                          newData[i].field[j].hidden = true;
                        else newData[i].field[j].hidden = false;
                      }
                    }
                    // if (
                    //   newData[i].field.customFieldId == ele.dependentFieldId
                    // ) {
                    //   if (row.fieldValue == "") newData[i].hidden = true;
                    //   else newData[i].hidden = false;
                    // }
                  }
                });
              }
              this.setState({ additionalDetails: newData });

              //dependent fields
            }}
          />
        </Grid>
      );
    } else if (inputField == "SINGLE_SELECT") {
      let optionsList = row.fieldOptions;
      optionsList.map((r) => ((r.label = r.optionValue), (r.value = r.id)));
      let val = null;

      optionsList.forEach((element) => {
        if (element.label == row.fieldValue) {
          val = element;
        }
      });

      return (
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          className={classes.customTextField}
        >
          <Typography variant="body2" gutterBottom>
            <span className={classes.customFieldTitle}>{row.displayName}</span>
            {row.requiredField && (
              <span className={classes.customFieldRequired}>*</span>
            )}
          </Typography>{" "}
          <div className={classes.dropdownCss}>
            <MultiSelect
              style={{}}
              //value={val}
              error={row.isError}
              options={optionsList}
              onChange={(e) => {
                this.handleGroupedSelectChange(e, row, rowIndex);
              }} //{this.handleSelectChange(e, i)}
            />
          </div>
        </Grid>
      );
    } else if (inputField === "MULTI_SELECT") {
      let optionsList = row.fieldOptions;
      optionsList.map((r) => ((r.label = r.optionValue), (r.value = r.id)));
      let val = null;

      optionsList.forEach((element) => {
        if (element.label == row.fieldValue) {
          val = element;
        }
      });

      return (
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          className={classes.customTextField}
        >
          <Typography variant="body2" gutterBottom>
            <span className={classes.customFieldTitle}>{row.displayName}</span>
            {row.requiredField && (
              <span className={classes.customFieldRequired}>*</span>
            )}
          </Typography>
          <div className={classes.dropdownCss}>
            <MultiSelect
              isMulti={true}
              value={row.fieldValue}
              error={row.isError}
              options={optionsList}
              onChange={(e) => {
                this.handleGroupedMultiSelectChange(e, row, rowIndex);
              }}
            />
          </div>
        </Grid>
      );
    } else if (inputField === "DATE") {
      return (
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
          <Typography variant="body2" gutterBottom>
            <span className={classes.customFieldTitle}>{row.displayName}</span>
            {row.requiredField && (
              <span className={classes.customFieldRequired}>*</span>
            )}
          </Typography>
          <div>
            <KeyboardDatePicker
              autoOk
              clearable
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              // disableFuture={true}
              defaultValue={row.fieldValue}
              value={row.fieldValue}
              error={row.isError}
              className={classes.fieldWidth}
              InputProps={{
                classes: {
                  root: row.isError
                    ? classes.datePickerError
                    : classes.datePicker,
                },
              }}
              keyboardIcon={
                <IconCalendarBlue className={classes.calendarIconCss} />
              }
              onChange={(date) => {
                row.fieldValue = date;
                row.isError = false;

                let newData = [...this.state.additionalDetails];
                let index = newData.findIndex(
                  (obj) => obj.customFieldId == row.customFieldId
                );
                newData[index].isError = row.isError ? true : false;
                if (row.dependentField.length > 0) {
                  row.dependentField.forEach((ele) => {
                    for (let i = 0; i < newData.length; i++) {
                      if (newData[i].customFieldId == ele.dependentFieldId) {
                        if (row.fieldValue == "") newData[i].hidden = true;
                        else newData[i].hidden = false;
                      }
                    }
                  });
                }
                this.setState({ additionalDetails: newData });
              }}
            />
          </div>
        </Grid>
      );
    }
  };
  viewChart = () => {
    this.setState({
      showChartOpen: true,
      dailogMsg: this.getShirtSizeChart(),
    });
  };
  hideChart = () => {
    this.setState({ showChartOpen: false });
  };
  getShirtSizeChart = () => {
    return (
      <div style={{ padding: "8px 24px" }}>
        <img
          src={this.state.eventItemsFormData?.tShirtSizeChartFile}
          alt="sizechart"
          width="100%"
        />
      </div>
    );
  };
  getOrderDetail() {
    let requestParams = {};
    requestParams.amount = this.state.grandTotal;
    requestParams.categoryId = this.state.eventCategory.value;
    if (this.state.eventItemsFormData?.eventItems) {
      let items = [];
      this.state.eventItemsFormData.eventItems.forEach((element) => {
        if (element?.itemQuantity > 0) {
          element.quantity = element.itemQuantity;
          element.size = this.state.selectedSize.value;
          items.push(element);
        }
      });
      requestParams.items = items;
    }
    getorderDetails(requestParams).then((res) => {
      return res.data;
    });
  }

  handleCountryChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      this.setState({
        country: selectedValues,
        countryError: false,
      });
    } else {
      this.setState({
        country: selectedValues,
        countryError: true,
      });
    }
  };

  handleAddressChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      this.setState({
        selectedAddress: selectedValues,
        selectedAddressError: false,
      });
      if (selectedValues.value == "Other")
        this.setState({ showOtherAddressFields: true });
      else this.setState({ showOtherAddressFields: false });
    } else {
      this.setState({
        selectedAddress: selectedValues,
        selectedAddressError: true,
      });
    }
  };

  calculateTotal = () => {
    let total = this.state.registrationFee;
    const { eventItemsFormData, isPhysical } = this.state;

    if (eventItemsFormData?.eventItems) {
      eventItemsFormData.eventItems.forEach((element) => {
        if (element.rowTotal) {
          total = total + element.rowTotal;
        }
      });
    }

    // if (eventItems.some((x) => x.itemQuantity > 0)) {
    //   this.setState({
    //     showAddressFields: isPhysical === true ? false : true, //hiding address dropdown
    //     showOtherAddressFields: isPhysical === true ? false : true, //for shiiping address
    //   });
    // } else {
    //   this.setState({
    //     showAddressFields: false, //hiding address dropdown
    //     showOtherAddressFields: false, //for shiiping address
    //   });
    // }

    // if (eventItems.length <= 0) {
    //   this.setState({
    //     showContactField: total > 0 ? true : false,
    //   });
    // }

    this.setState({
      grandTotal: total,
    });
  };

  SeperateEventTypes = (list, type) => {
    let runList = [],
      cycleList = [],
      duathlonList = [],
      stepsList = [];

    let staticRunList = [],
      staticCycleList = [];
    list.forEach((element) => {
      if (element.activityType.type === "RUN" && type === "RUN") {
        runList.push(element);
      }
      if (element.activityType.type === "CYCLE" && type === "CYCLE") {
        cycleList.push(element);
      }
      if (element.activityType.type === "DUATHLON" && type === "DUATHLON") {
        duathlonList.push(element);
      }
      if (element.activityType.type === "STEPS" && type === "STEPS") {
        stepsList.push(element);
      }
    });

    staticRunList = [
      { label: 5, value: "5K" },
      { label: 10, value: "10K" },
      { label: 21.1, value: "21.1K" },
      { label: 42.2, value: "42.2K" },
    ];
    staticCycleList = [
      { label: 10, value: "10K" },
      { label: 20, value: "20K" },
      { label: 30, value: "30K" },
      { label: 40, value: "40K" },
    ];
    let newlist = [];
    if (type == "RUN") newlist = runList;
    else if (type == "CYCLE") newlist = cycleList;
    else if (type == "DUATHLON") newlist = duathlonList;
    else if (type == "STEPS") newlist = stepsList;

    this.setState({
      // eventCategoryList: newlist,
      staticDataList: type === "RUN" ? staticRunList : staticCycleList,
    });
  };

  goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  paymentHandler = async () => {
    // e.preventDefault();
    const user = ApplicationUtil.getRunnerDetails();

    let requestParams = {};
    requestParams.runnerId = user?.runnerId;
    requestParams.amount = this.state.grandTotal;
    requestParams.categoryId = this.state.eventCategory.value;
    if (this.state.eventItemsFormData?.eventItems?.length > 0) {
      let items = [];
      this.state.eventItemsFormData.eventItems.forEach((element) => {
        if (element?.itemQuantity > 0) {
          element.quantity = element.itemQuantity;
          if (element?.sizeArray) {
            element.size = element?.selectedSize.value;
            let data = user;
            let gender = data.gender;
            if (gender == "MALE" || gender == "OTHER") {
              element.sizeMale = element?.selectedSize.value;
            }

            if (gender == "FEMALE") {
              element.sizeFemale = element?.selectedSize.value;
            }
          }
          items.push(element);
        }
      });
      requestParams.items = items;
    }

    getorderDetails(requestParams).then((res) => {
      const { data } = res;
      this.setState({ orderId: data.orderId });
      data.contact =
        this.state.contactNumber != ""
          ? this.state.contactNumber
          : data.participantPhone;
      ApplicationUtil.paymentGatewayHandler(
        data,
        this.captureDetails,
        this.handlePaymentFailure
      );
    });
  };
  captureDetails = (requestCaptureParams) => {
    let runnerData = ApplicationUtil.getRunnerDetails();
    requestCaptureParams.runnerId = runnerData?.runner?.id;
    let submitFlag = true;

    getCaptureDetails(requestCaptureParams)
      .then((res) => {
        let userId = runnerData?.id;
        let completionTime =
          this.state.hour.value +
          ":" +
          this.state.minute.value +
          ":" +
          this.state.second.value;
        let isUndefined = completionTime.includes("undefined");
        this.setState({ transactionID: requestCaptureParams.paymentId });
        this.setState({ transactionStatus: "Success" });
        let customeParams = {
          userId: parseInt(userId),
          eventId: this.state.eventId,
          categoryId: this.state.eventCategory.value,

          //fields for address details
        };
        if (this.state.showSecondaryActivity) {
          let secondaryActivityTypes = "";
          if (this.state?.selectedSecondaryActivity) {
            this.state.selectedSecondaryActivity.forEach((i) => {
              secondaryActivityTypes = secondaryActivityTypes?.concat(
                i.id + ","
              );
            });
            secondaryActivityTypes = secondaryActivityTypes.replace(
              /,\s*$/,
              ""
            );
            customeParams.secondaryActivityTypes = secondaryActivityTypes;
          }
          // else {
          //   Snackbar.show({
          //     variant: "error",
          //     isAutoHide: true,
          //     preventDuplicate: true,
          //     message: "Please select secondary Activity.",
          //   });
          //   this.goToTop();
          //   return;
          // }
        }
        if (this.state?.showAgeGroup && !this.state?.selectedAgeGroup) {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Please select age group",
          });
          this.goToTop();
          return;
        }

        if (this.state.eventItemsFormData) {
          let eventItemsDetails = this.state.eventItemsFormData;
          let {
            contactNumber,
            contactNumberError,
            address,
            addressError,
            city,
            cityError,
            pincode,
            pincodeError,
            state,
            stateError,
            countryCode,
            country,
            countryError,
          } = eventItemsDetails;
          // if (!this.state.isPhysical) {
          customeParams.countryCode = countryCode.value;
          customeParams.contactNumber = contactNumber;

          // if (this.state.selectedAddress.value == "Other") {
          customeParams.city = city;
          customeParams.pincode = pincode;
          customeParams.state = state;
          customeParams.country = country.label;
          customeParams.address = address;
          // } else {
          //   customeParams.companyLocation =
          //     this.state.selectedAddress.value;
          // }
          // }
        }

        if (this.state.selectedStaticData != "" && isUndefined) {
        } else {
          if (this.state.eventType.value == "RUN" && !isUndefined) {
            let statData = {};
            statData.runPbDistance = this.state.selectedStaticData.value;
            statData.runPbTime = completionTime;
            customeParams.runnerStats = statData;
          }
          if (this.state.eventType.value == "CYCLE" && !isUndefined) {
            let statData = {};
            statData.cyclePbDistance = this.state.selectedStaticData.value;
            statData.cyclePbTime = completionTime;
            customeParams.runnerStats = statData;
          }

          let data = [];

          if (this.state.eventItemsFormData?.eventItems) {
            this.state.eventItemsFormData.eventItems.forEach((element) => {
              if (element.itemQuantity && element.itemQuantity > 0) {
                let object = {};
                object.id = element.id;
                object.itemName = element.itemName;
                object.pricePerUnit = element.pricePerUnit;
                object.quantity = element.itemQuantity;
                if (element?.sizeArray) {
                  let data = runnerData;
                  let gender = data.gender;
                  if (gender == "MALE" || gender == "OTHER") {
                    object.sizeMale = element?.selectedSize.value;
                  }

                  if (gender == "FEMALE") {
                    object.sizeFemale = element?.selectedSize.value;
                  }
                  // object.size = this.state.selectedSize.value;
                }
                data.push(object);
              }
            });
          }
          if (this.state.showRunnerGroup) {
            if (!this.state.otherGroup) {
              //runnerGroup.map(r => ((r.id = r.id), (r.name = r.name)));
              if (this.state.groupDetails) {
                let details = this.state.groupDetails;
                customeParams.groupDetails = {
                  name: details?.name,
                  id: details?.id,
                  city: details?.city,
                  groupVisibility: details?.groupVisibility,
                };
                customeParams.runnerGroup = details?.name;
                customeParams.runnerGroupCity = details?.city;
              }
            } else if (this.state.runnerGroup || this.state.runnerGroupCity) {
              customeParams.groupDetails = {
                name: this.state.runnerGroup,
                city: this.state.runnerGroupCity,
                groupVisibility: false,
              };
              customeParams.runnerGroup = this.state.runnerGroup;
              customeParams.runnerGroupCity = this.state.runnerGroupCity;
            }
          }
          if (this.state?.showCorporateOnReg && this.state?.selectedCorporate) {
            if (!this.state?.otherCorporate) {
              customeParams.corporateDetails = {
                id: this.state.selectedCorporate?.id,
                name: this.state.selectedCorporate?.label,
              };
            } else if (this.state.corporateName) {
              customeParams.corporateDetails = {
                name: this.state.corporateName,
              };
            }
          }

          if (this.state.showAgeGroup && this.state?.selectedAgeGroup) {
            customeParams.ageGroupId = this.state?.selectedAgeGroup?.id;
          }
          customeParams.items = data;
          customeParams.isTermsNcondition = this.state.termsCheckboxSelected;
          customeParams.employeeCode = this.state.employeeCode;
          customeParams.orderId = requestCaptureParams?.orderId;
          customeParams.paymentStatus = "SUCCESS";
          customeParams.gatewayResponse = requestCaptureParams;

          let fields = [];

          // additional details check
          if (
            this.state.additionalDetails &&
            this.state.additionalDetails.length > 0
          ) {
            this.state.additionalDetails.forEach((element) => {
              if (submitFlag) {
                if (element.groupName) {
                  for (let i = 0; i < element.field.length; i++) {
                    if (
                      element.field[i].requiredField &&
                      !element.field[i].hidden &&
                      (element.field[i].fieldValue == "" ||
                        element.field[i].fieldValue == undefined)
                    ) {
                      element.field[i].isError = true;
                      Snackbar.show({
                        variant: "error",
                        isAutoHide: true,
                        preventDuplicate: true,
                        message: element.field[i].displayName + " is Required",
                      });
                      submitFlag = false;

                      let newData = [...this.state.additionalDetails];
                      let index = newData.findIndex(function (obj) {
                        if (obj.field && obj.field.length > 0)
                          return (
                            obj.field[i].customFieldId ==
                            element.field[i].customFieldId
                          );
                      });
                      newData[index].isError = element.field[i].isError
                        ? true
                        : false;
                      this.setState({ additionalDetails: newData });
                      // this.setState({ data: [...this.state.data, element] });
                      this.goToTop();
                      submitFlag = false;
                      return;
                    } else {
                      let obj = {};
                      element.field[i].isError = false;
                      if (
                        element.field[i].fieldType.name == "SINGLE_SELECT" &&
                        element.field[i].fieldValue
                      ) {
                        obj.fieldId = element.field[i].customFieldId;
                        obj.fieldOptionId = element?.field[i]?.fieldOptionId;
                        element.field[i].fieldValue &&
                        element.field[i].fieldValue.optionValue
                          ? (obj.fieldValue =
                              element.field[i]?.fieldValue?.optionValue)
                          : (obj.fieldValue = null);
                        fields.push(obj);
                      } else if (
                        element.field[i].fieldType.name == "MULTI_SELECT"
                      ) {
                        if (
                          element.fieldValue &&
                          element.fieldValue.length > 0
                        ) {
                          element.fieldValue.forEach((e) => {
                            let newObj = {};
                            newObj.fieldId = element.customFieldId;
                            newObj.fieldOptionId = e?.id;
                            e && e.optionValue
                              ? (newObj.fieldValue = e?.optionValue)
                              : (newObj.fieldValue = null);
                            fields.push(newObj);
                          });
                        }
                      } else if (element.field[i].fieldType.name == "DATE") {
                        obj.fieldId = element.customFieldId;
                        element.fieldValue
                          ? (obj.dateValue = moment(element?.fieldValue).format(
                              "DD-MM-YYYY"
                            ))
                          : (obj.dateValue = null);
                        fields.push(obj);
                      } else if (element.fieldValue) {
                        obj.fieldId = element.field[i].customFieldId;
                        obj.fieldOptionId = element?.field[i]?.fieldOptionId;
                        element.fieldValue
                          ? (obj.fieldValue = element.field[i]?.fieldValue)
                          : (obj.fieldValue = "");
                        fields.push(obj);
                      }

                      if (element.field.length - 1 > i) {
                        submitFlag = false;
                      } else {
                        submitFlag = true;
                        return;
                      }
                    }
                  }
                } else {
                  if (
                    element.requiredField &&
                    !element.hidden &&
                    (element.fieldValue == "" ||
                      element.fieldValue == undefined)
                  ) {
                    element.isError = true;
                    Snackbar.show({
                      variant: "error",
                      isAutoHide: true,
                      preventDuplicate: true,
                      message: element.displayName + " is Required",
                    });
                    submitFlag = false;
                    let newData = [...this.state.additionalDetails];
                    let index = newData.findIndex(
                      (obj) => obj.customFieldId == element.customFieldId
                    );
                    newData[index].isError = element.isError ? true : false;
                    this.setState({ additionalDetails: newData });
                    // this.setState({ data: [...this.state.data, element] });
                    this.goToTop();
                  } else {
                    let obj = {};
                    element.isError = false;
                    if (
                      element.fieldType.name == "SINGLE_SELECT" &&
                      element.fieldValue
                    ) {
                      obj.fieldId = element.customFieldId;
                      obj.fieldOptionId = element?.fieldOptionId;
                      element.fieldValue
                        ? (obj.fieldValue = element?.fieldValue)
                        : (obj.fieldValue = null);
                      fields.push(obj);
                    } else if (element.fieldType.name == "MULTI_SELECT") {
                      if (element.fieldValue && element.fieldValue.length > 0) {
                        element.fieldValue.forEach((e) => {
                          let newObj = {};
                          newObj.fieldId = element.customFieldId;
                          newObj.fieldOptionId = e?.id;
                          e && e.optionValue
                            ? (newObj.fieldValue = e?.optionValue)
                            : (newObj.fieldValue = null);
                          fields.push(newObj);
                        });
                      }
                    } else if (element.fieldType.name == "DATE") {
                      obj.fieldId = element.customFieldId;
                      element.fieldValue
                        ? (obj.dateValue = moment(element?.fieldValue).format(
                            "DD-MM-YYYY"
                          ))
                        : (obj.dateValue = null);
                      fields.push(obj);
                    } else if (element.fieldValue) {
                      obj.fieldId = element.customFieldId;
                      obj.fieldOptionId = element?.fieldOptionId;
                      element.fieldValue
                        ? (obj.fieldValue = element?.fieldValue)
                        : (obj.fieldValue = "");
                      fields.push(obj);
                    }

                    submitFlag = true;
                    return;
                  }
                }
              }
            });
            if (submitFlag) {
              let fieldValues = {
                runnerId: this.state.runnerId,
                eventId: this.state.eventId,
              };
              fieldValues.fields = fields;
              customeParams.fieldValues = fieldValues;
            }
          }

          //additional grouped fields
          if (
            this.state.additionalDetailsGroupedList &&
            this.state.additionalDetailsGroupedList.length > 0
          ) {
            for (
              let i = 0;
              i < this.state.additionalDetailsGroupedList.length;
              i++
            ) {
              this.state.additionalDetailsGroupedList[i].field.forEach(
                (element) => {
                  if (submitFlag) {
                    if (
                      element.requiredField &&
                      !element.hidden &&
                      (element.fieldValue == "" ||
                        element.fieldValue == undefined)
                    ) {
                      element.isError = true;
                      Snackbar.show({
                        variant: "error",
                        isAutoHide: true,
                        preventDuplicate: true,
                        message: element.displayName + " is Required",
                      });
                      submitFlag = false;
                      let newData = [
                        ...this.state.additionalDetailsGroupedList,
                      ];

                      let index = newData[i].field.findIndex(
                        (obj) => obj.customFieldId == element.customFieldId
                      );
                      newData[index].isError = element.isError ? true : false;
                      this.setState({
                        additionalDetailsGroupedList: newData,
                      });
                      // this.setState({ data: [...this.state.data, element] });
                      this.goToTop();
                    } else {
                      let obj = {};
                      element.isError = false;

                      if (
                        element.fieldType.name == "SINGLE_SELECT" &&
                        element.fieldValue
                      ) {
                        obj.fieldId = element.customFieldId;
                        obj.fieldOptionId = element?.fieldOptionId;
                        element.fieldValue
                          ? (obj.fieldValue = element?.fieldValue)
                          : (obj.fieldValue = null);
                        fields.push(obj);
                      } else if (element.fieldType.name == "MULTI_SELECT") {
                        if (
                          element.fieldValue &&
                          element.fieldValue.length > 0
                        ) {
                          element.fieldValue.forEach((e) => {
                            let newObj = {};
                            newObj.fieldId = element.customFieldId;
                            newObj.fieldOptionId = e?.id;
                            e && e.optionValue
                              ? (newObj.fieldValue = e?.optionValue)
                              : (newObj.fieldValue = null);
                            fields.push(newObj);
                          });
                        }
                      } else if (element.fieldType.name == "DATE") {
                        obj.fieldId = element.customFieldId;
                        element.fieldValue
                          ? (obj.dateValue = moment(element?.fieldValue).format(
                              "DD-MM-YYYY"
                            ))
                          : (obj.dateValue = null);
                        fields.push(obj);
                      } else if (element.fieldValue) {
                        obj.fieldId = element.customFieldId;
                        obj.fieldOptionId = element?.fieldOptionId;
                        element.fieldValue
                          ? (obj.fieldValue = element?.fieldValue)
                          : (obj.fieldValue = "");
                        fields.push(obj);
                      }

                      submitFlag = true;
                      return;
                    }
                  }
                }
              );
            }
            if (submitFlag) {
              let fieldValues = {
                runnerId: this.state.runnerId,
                eventId: this.state.eventId,
              };
              fieldValues.fields = fields;
              customeParams.fieldValues = fieldValues;
            }
          }
          if (
            this.state.displayIncrementDistance &&
            this.state.incrementDistance
          ) {
            customeParams.incrementalDistanceId =
              this.state.incrementDistanceValue.id;
          }
          customeParams.paymentMode =
            this.state.grandTotal > 0
              ? "ONLINE"
              : this.state.grandTotal === 0
              ? "NOT_APPLICABLE"
              : "OFFLINE";

          registerEventCategory(customeParams).then((res) => {
            let code = res?.data?.success?.code;
            let msg = res.data.success.verbose;
            if (code === "409") {
              this.setState({
                changeActivity: msg,
                dialogHeader: "Already Registered",
                dailogMsg: this.getFailDailogComponent(),
              });
            } else if (code === "601") {
              this.setState({
                changeActivity: msg,
                dialogHeader: "Registrations Closed",
                dailogMsg: this.getRegistrationsClosedDailogComponent(),
              });
            } else if (code === "500") {
              this.setState({
                changeActivity: msg,
                dialogHeader: "Event Registration Failed",
                dailogMsg: this.getRegistrationFailDailogComponent(),
              });
            } else {
              // drawer events list
              this.updateEventsList();
              if (this.state.grandTotal === 0)
                this.setState({
                  dailogMsg: this.getSuccessDialogComponent(),
                });
              else
                this.setState({
                  dailogMsg: this.getSuccessDialogComponentWithTotal(),
                });
            }
            this.setState({
              open: true,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({ transactionStatus: "Failed" });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Failed to process the payment.", //Capture API error
        });
      });
  };
  handlePaymentFailure = (response) => {
    Snackbar.show({
      variant: "error",
      isAutoHide: true,
      preventDuplicate: true,
      message: `Failed to process the payment please note the transactionID
          ${response.error.metadata.payment_id}.`, //Capture API error
    });
    // this.pageRefresh();
  };
  updateEventsList = () => {
    let customParams = {
      pageCount: 10,
      pageNumber: 0,
      sortKey: "",
      sortOrder: true,
      eventState: "REGISTERED",
    };
    getEventList(customParams).then((response) => {
      this.props.updateDrawersEventList(response.data.data);
    });
  };
  validateEmailDomain = (value) => {
    let domainValid = false;
    let domain = value.split("@");
    domain = domain[1].toLowerCase();

    if (this.state.supportedDomain && this.state.supportedDomain.length > 0) {
      this.state.supportedDomain.map((e) => {
        if (e.validationDomain.toLowerCase() === domain) {
          domainValid = true;
        }
      });
      return domainValid;
    } else {
      return true;
    }
  };
  registerEvent = (e) => {
    const { supportedDomain } = this.state;
    let checkAddress = false;
    const userData = ApplicationUtil.getRunnerDetails();
    // let isItemQuantityLess = this.state.eventItems.some((x) => x?.itemQuantity > 0);
    // let checkContact = this.state.eventItems.some((x) => x?.itemQuantity > 0) || this.state.grandTotal > 0;
    // let isItemQuantityLess = false
    let checkContact = false;
    if (
      supportedDomain?.length > 0 &&
      (!userData?.email || !this.validateEmailDomain(userData?.email))
    ) {
      if (!userData?.email) {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Email is mandatory for this event",
        });
        this.props.history.push("/profile");
      } else {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Logged in email domain is not supported for this event",
        });
      }
      return;
    }

    if (this.state.eventItemsFormData?.eventItems) {
      this.state.eventItemsFormData.eventItems.forEach((element) => {
        // if (element.itemName?.toLowerCase().includes('t-shirt') && element.itemQuantity <= 0) {
        //   isItemQuantityLess = true;
        // }
        if (element.itemQuantity > 0 && this.state.grandTotal > 0) {
          checkContact = true;
          return;
        } else {
          checkContact = false;
          return;
        }
      });
    }

    if (
      (this.state.eventId == ApplicationConstants.CommonEventId &&
        this.state.employeeCode == "") ||
      this.state.employeeCodeError
    ) {
      this.setState({ employeeCodeError: true });
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter employee code.",
      });
      this.goToTop();
      return;
    }

    if (this.state.showHtWtOnSignup) {
      if (
        !this.state.height ||
        this.state.height < 50 ||
        this.state.heightError
      ) {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message:
            this.state.height < 50
              ? "Please provide height in cm"
              : "Please enter height",
        });
        this.goToTop();
        return;
      }
      if (!this.state.weight || this.state.weightError) {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please enter weight",
        });
        this.goToTop();
        return;
      }

      if (!this.state.calculatedBmi) {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please enter correct height in cm",
        });
        this.goToTop();
        return;
      }
    }

    if (this.state.eventCategory == "") {
      if (this.state.eventType == "") {
        this.setState({ eventTypeError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please select event type.",
        });
      } else {
        this.setState({ eventCategoryError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please select event category.",
        });
      }
    } else if (
      this.state.shippingRequired ||
      this.state.eventItemsFormData?.eventItems?.some((x) => x.itemQuantity > 0)
    ) {
      let eventItemsDetails = this.state.eventItemsFormData;
      let {
        eventItems,
        contactNumber,
        contactNumberError,
        address,
        addressError,
        city,
        cityError,
        pincode,
        pincodeError,
        state,
        stateError,
        countryCode,
        country,
        countryError,
      } = eventItemsDetails;
      let isItemSelected = eventItems?.some((x) => x?.itemQuantity > 0);
      let wearableItem = eventItems.filter(
        (x) => (x?.sizeMale || x?.sizeFemale) && x?.itemQuantity > 0
      );

      let isWearableItemSelected = wearableItem.find(
        (x) => !x.selectedSize?.value || x.selectedSizeError
      );

      // if (!isItemSelected) {
      //   Snackbar.show({
      //     variant: "error",
      //     isAutoHide: true,
      //     preventDuplicate: true,
      //     message: `Please select item`,
      //   });

      //   return
      // } else
      if (isWearableItemSelected) {
        // let updatedData = { ...eventItemsFormData, selectedTshirtSizeError: true }
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: `Please select ${isWearableItemSelected?.itemName} size`,
        });
        let arr = eventItems?.map((x) => {
          if (x.id === isWearableItemSelected?.id) {
            x.selectedSizeError = true;
          }
          return x;
        });
        this.setState({
          eventItemsFormData: { ...eventItemsDetails, eventItems: arr },
        });
        // this.setState({ eventItemsFormData: updatedData })
        // this.closeWindow()

        return;
      } else if (!contactNumber || contactNumberError) {
        let updatedData = { ...eventItemsDetails, contactNumberError: true };
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please provide contact number",
        });
        this.setState({ eventItemsFormData: updatedData });
        // this.closeWindow()

        return;
      } else if (!address || addressError) {
        let updatedData = { ...eventItemsDetails, addressError: true };
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please provide address",
        });
        this.setState({ eventItemsFormData: updatedData });
        // this.closeWindow()

        return;
      } else if (!city || cityError) {
        let updatedData = { ...eventItemsDetails, cityError: true };
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please provide city",
        });
        this.setState({ eventItemsFormData: updatedData });
        // this.closeWindow()

        return;
      } else if (!pincode || pincodeError) {
        let updatedData = { ...eventItemsDetails, pincodeError: true };
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please provide pincode",
        });
        this.setState({ eventItemsFormData: updatedData });
        // this.closeWindow()

        return;
      } else if (!state || stateError) {
        let updatedData = { ...eventItemsDetails, stateError: true };
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please provide state",
        });
        this.setState({ eventItemsFormData: updatedData });
        // this.closeWindow()

        return;
      } else if (!country || countryError) {
        let updatedData = { ...eventItemsDetails, countryError: true };
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please provide country",
        });
        this.setState({ eventItemsFormData: updatedData });
        // this.closeWindow()

        return;
      } else {
        let userId = userData?.id;
        let completionTime =
          this.state.hour.value +
          ":" +
          this.state.minute.value +
          ":" +
          this.state.second.value;
        let isUndefined = completionTime.includes("undefined");

        let customeParams = {
          userId: parseInt(userId),
          eventId: this.state.eventId,
          categoryId: this.state.eventCategory.value,
        };
        if (this.state.showSecondaryActivity) {
          let secondaryActivityTypes = "";
          if (this.state?.selectedSecondaryActivity) {
            this.state.selectedSecondaryActivity.forEach((i) => {
              secondaryActivityTypes = secondaryActivityTypes?.concat(
                i.id + ","
              );
            });
            secondaryActivityTypes = secondaryActivityTypes.replace(
              /,\s*$/,
              ""
            );
            customeParams.secondaryActivityTypes = secondaryActivityTypes;
          }
          //  else {
          //   Snackbar.show({
          //     variant: "error",
          //     isAutoHide: true,
          //     preventDuplicate: true,
          //     message: "Please select secondary Activity.",
          //   });
          //   this.goToTop();
          //   return;
          // }
        }

        if (this.state?.showAgeGroup && !this.state?.selectedAgeGroup) {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Please select age group",
          });
          this.goToTop();
          return;
        }
        // if (!this.state.isPhysical) {
        customeParams.countryCode = countryCode.value;
        customeParams.contactNumber = contactNumber;

        // if (this.state.selectedAddress.value == "Other") {
        customeParams.city = city;
        customeParams.pincode = pincode;
        customeParams.state = state;
        customeParams.country = country.label;
        customeParams.address = address;
        // } else {
        //   customeParams.companyLocation = this.state.selectedAddress.value;
        // }
        // }

        if (this.state.selectedStaticData != "" && isUndefined) {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,

            preventDuplicate: true,
            message: "Please enter valid time for HH, MM and SS fields.",
          });
        } else {
          if (this.state.eventType.value == "RUN" && !isUndefined) {
            let statData = {};
            statData.runPbDistance = this.state.selectedStaticData.value;
            statData.runPbTime = completionTime;
            customeParams.runnerStats = statData;
          }
          if (this.state.eventType.value == "CYCLE" && !isUndefined) {
            let statData = {};
            statData.cyclePbDistance = this.state.selectedStaticData.value;
            statData.cyclePbTime = completionTime;
            customeParams.runnerStats = statData;
          }
          let submitFlag = true;

          let fields = [];
          // additional details check

          if (
            this.state.additionalDetails &&
            this.state.additionalDetails.length > 0
          ) {
            this.state.additionalDetails.forEach((element) => {
              if (submitFlag) {
                if (element.groupName) {
                  for (let i = 0; i < element.field.length; i++) {
                    if (
                      element.field[i].requiredField &&
                      !element.field[i].hidden &&
                      (element.field[i]?.fieldValue == "" ||
                        element.field[i]?.fieldValue?.trim()?.length === 0 ||
                        element.field[i]?.fieldValue == undefined)
                    ) {
                      element.field[i].isError = true;
                      Snackbar.show({
                        variant: "error",
                        isAutoHide: true,
                        preventDuplicate: true,
                        message: element.field[i].displayName + " is Required",
                      });
                      submitFlag = false;

                      let newData = [...this.state.additionalDetails];
                      let index = newData.findIndex(function (obj) {
                        if (obj.field && obj.field.length > 0)
                          return (
                            obj.field[i].customFieldId ==
                            element.field[i].customFieldId
                          );
                      });
                      newData[index].isError = element.field[i].isError
                        ? true
                        : false;
                      this.setState({ additionalDetails: newData });
                      // this.setState({ data: [...this.state.data, element] });
                      this.goToTop();
                      submitFlag = false;
                      return;
                    } else {
                      let obj = {};
                      element.field[i].isError = false;
                      if (
                        element.field[i].fieldType.name == "SINGLE_SELECT" &&
                        element.field[i].fieldValue
                      ) {
                        obj.fieldId = element.field[i].customFieldId;
                        obj.fieldOptionId = element?.field[i]?.fieldOptionId;
                        element.field[i].fieldValue
                          ? (obj.fieldValue = element.field[i]?.fieldValue)
                          : (obj.fieldValue = null);
                        fields.push(obj);
                      } else if (
                        element.field[i].fieldType.name == "MULTI_SELECT"
                      ) {
                        if (
                          element.fieldValue &&
                          element.fieldValue.length > 0
                        ) {
                          element.fieldValue.forEach((e) => {
                            let newObj = {};
                            newObj.fieldId = element.customFieldId;
                            newObj.fieldOptionId = e?.id;
                            e && e.optionValue
                              ? (newObj.fieldValue = e?.optionValue)
                              : (newObj.fieldValue = null);
                            fields.push(newObj);
                          });
                        }
                      } else if (element.field[i].fieldType.name == "DATE") {
                        obj.fieldId = element.field[i].customFieldId;
                        element.fieldValue
                          ? (obj.dateValue = moment(
                              element.field[i].fieldValue
                            ).format("DD-MM-YYYY"))
                          : (obj.dateValue = null);
                        fields.push(obj);
                      } else if (element.fieldValue) {
                        obj.fieldId = element.field[i].customFieldId;
                        obj.fieldOptionId = element?.field[i]?.fieldOptionId;
                        element.fieldValue
                          ? (obj.fieldValue = element.field[i].fieldValue)
                          : (obj.fieldValue = "");
                        fields.push(obj);
                      }
                      if (element.field.length - 1 > i) {
                        submitFlag = false;
                      } else {
                        submitFlag = true;
                        return;
                      }
                    }
                  }
                } else {
                  if (
                    element.requiredField &&
                    !element.hidden &&
                    !element.fieldValue
                    // (element?.fieldValue == "" ||
                    //   element?.fieldValue?.trim()?.length == 0 ||
                    //   element?.fieldValue == undefined)
                  ) {
                    element.isError = true;
                    Snackbar.show({
                      variant: "error",
                      isAutoHide: true,
                      preventDuplicate: true,
                      message: element.displayName + " is Required",
                    });
                    submitFlag = false;
                    let newData = [...this.state.additionalDetails];
                    let index = newData.findIndex(
                      (obj) => obj.customFieldId == element.customFieldId
                    );
                    newData[index].isError = element.isError ? true : false;
                    this.setState({ additionalDetails: newData });
                    // this.setState({ data: [...this.state.data, element] });
                    this.goToTop();
                  } else {
                    let obj = {};
                    element.isError = false;

                    if (
                      element.fieldType.name == "SINGLE_SELECT" &&
                      element.fieldValue
                    ) {
                      obj.fieldId = element.customFieldId;
                      obj.fieldOptionId = element?.fieldOptionId;
                      element.fieldValue
                        ? (obj.fieldValue = element?.fieldValue)
                        : (obj.fieldValue = null);
                      fields.push(obj);
                    } else if (element.fieldType.name == "MULTI_SELECT") {
                      if (element.fieldValue && element.fieldValue.length > 0) {
                        element.fieldValue.forEach((e) => {
                          let newObj = {};
                          newObj.fieldId = element.customFieldId;
                          newObj.fieldOptionId = e?.id;
                          e && e.optionValue
                            ? (newObj.fieldValue = e?.optionValue)
                            : (newObj.fieldValue = null);
                          fields.push(newObj);
                        });
                      }
                    } else if (element.fieldType.name == "DATE") {
                      obj.fieldId = element.customFieldId;
                      element.fieldValue
                        ? (obj.dateValue = moment(element?.fieldValue).format(
                            "DD-MM-YYYY"
                          ))
                        : (obj.dateValue = null);
                      fields.push(obj);
                    } else if (element.fieldValue) {
                      obj.fieldId = element.customFieldId;
                      obj.fieldOptionId = element?.fieldOptionId;
                      element.fieldValue
                        ? (obj.fieldValue = element?.fieldValue)
                        : (obj.fieldValue = "");
                      fields.push(obj);
                    }

                    submitFlag = true;
                    return;
                  }
                }
              }
            });

            if (submitFlag) {
              let fieldValues = {
                runnerId: this.state.runnerId,
                eventId: this.state.eventId,
              };
              fieldValues.fields = fields;
              customeParams.fieldValues = fieldValues;
            }
          }

          //additional grouped fields
          if (
            this.state.additionalDetailsGroupedList &&
            this.state.additionalDetailsGroupedList.length > 0
          ) {
            for (
              let i = 0;
              i < this.state.additionalDetailsGroupedList.length;
              i++
            ) {
              this.state.additionalDetailsGroupedList[i].field.forEach(
                (element) => {
                  if (submitFlag) {
                    if (
                      element.requiredField &&
                      !element.hidden &&
                      (element?.fieldValue == "" ||
                        element?.fieldValue?.trim()?.length === 0 ||
                        element?.fieldValue == undefined)
                    ) {
                      element.isError = true;
                      Snackbar.show({
                        variant: "error",
                        isAutoHide: true,
                        preventDuplicate: true,
                        message: element.displayName + " is Required",
                      });
                      submitFlag = false;
                      let newData = [
                        ...this.state.additionalDetailsGroupedList,
                      ];

                      let index = newData[i].field.findIndex(
                        (obj) => obj.customFieldId == element.customFieldId
                      );
                      newData[index].isError = element.isError ? true : false;
                      this.setState({
                        additionalDetailsGroupedList: newData,
                      });
                      // this.setState({ data: [...this.state.data, element] });
                      this.goToTop();
                    } else {
                      let obj = {};
                      element.isError = false;
                      if (
                        element.fieldType.name == "SINGLE_SELECT" &&
                        element.fieldValue
                      ) {
                        obj.fieldId = element.customFieldId;
                        obj.fieldOptionId = element?.fieldValue?.id;
                        element.fieldValue
                          ? (obj.fieldValue = element?.fieldValue)
                          : (obj.fieldValue = null);
                        fields.push(obj);
                      } else if (element.fieldType.name == "MULTI_SELECT") {
                        if (
                          element.fieldValue &&
                          element.fieldValue.length > 0
                        ) {
                          element.fieldValue.forEach((e) => {
                            let newObj = {};
                            newObj.fieldId = element.customFieldId;
                            newObj.fieldOptionId = e?.id;
                            e && e.optionValue
                              ? (newObj.fieldValue = e?.optionValue)
                              : (newObj.fieldValue = null);
                            fields.push(newObj);
                          });
                        }
                      } else if (element.fieldType.name == "DATE") {
                        obj.fieldId = element.customFieldId;
                        element.fieldValue
                          ? (obj.dateValue = moment(element?.fieldValue).format(
                              "DD-MM-YYYY"
                            ))
                          : (obj.dateValue = null);
                        fields.push(obj);
                      } else if (element.fieldValue) {
                        obj.fieldId = element.customFieldId;
                        obj.fieldOptionId = element?.fieldValue?.id;
                        element.fieldValue
                          ? (obj.fieldValue = element?.fieldValue)
                          : (obj.fieldValue = "");
                        fields.push(obj);
                      }
                      submitFlag = true;
                      return;
                    }
                  }
                }
              );
              if (submitFlag) {
                let fieldValues = {
                  runnerId: this.state.runnerId,
                  eventId: this.state.eventId,
                };

                fieldValues.fields = fields;
                customeParams.fieldValues = fieldValues;
              }
            }

            if (submitFlag) {
              let fieldValues = {
                runnerId: this.state.runnerId,
                eventId: this.state.eventId,
              };
              fieldValues.fields = fields;
              customeParams.fieldValues = fieldValues;
            }
          }

          if (submitFlag) {
            let data = [];
            if (this.state.eventItemsFormData.eventItems) {
              this.state.eventItemsFormData.eventItems.forEach((element) => {
                if (element.itemQuantity && element.itemQuantity > 0) {
                  let object = {};
                  object.id = element.id;
                  object.itemName = element.itemName;
                  object.pricePerUnit = element.pricePerUnit;
                  object.quantity = element.itemQuantity;
                  if (element.sizeArray) {
                    let data = userData;
                    let gender = data.gender;
                    if (gender == "MALE" || gender == "OTHER") {
                      object.sizeMale = element.selectedSize?.value;
                    }

                    if (gender == "FEMALE") {
                      object.sizeFemale = element.selectedSize?.value;
                    }
                  }
                  data.push(object);
                }
              });
            }
            if (
              this.state.displayIncrementDistance &&
              this.state.incrementDistance
            ) {
              customeParams.incrementalDistanceId =
                this.state.incrementDistanceValue.id;
            }

            if (this.state.showSuggestionOnRegisterEvent) {
              customeParams.height = this.state.height;
              customeParams.weight = this.state.weight;
              customeParams.bmi = Number(this.state.calculatedBmi);
              let profileData = ApplicationUtil.getRunnerDetails();

              this.props.updateUserDetails({
                ...profileData,
                height: Number(this.state.height),
                weight: Number(this.state.weight),
              });

              {
                this.state.challengeParams?.includes("WELLNESS_POINT") &&
                  (customeParams.totalTarget =
                    this.state.calculatedSuggestedPoints);
              }
              {
                this.state.challengeParams?.includes("DISTANCE") &&
                  (customeParams.totalTarget =
                    this.state.calculatedSuggestedDistance);
              }
              {
                this.state.challengeParams?.includes("STEPS") &&
                  (customeParams.totalTarget =
                    this.state.calculatedSuggestedSteps);
              }
            }
            if (this.state.showRunnerGroup) {
              if (!this.state.otherGroup) {
                //runnerGroup.map(r => ((r.id = r.id), (r.name = r.name)));
                if (this.state.groupDetails) {
                  let details = this.state.groupDetails;
                  customeParams.groupDetails = {
                    name: details?.name,
                    id: details?.id,
                    city: details?.city,
                    groupVisibility: details?.groupVisibility,
                  };
                  customeParams.runnerGroup = details?.name;
                  customeParams.runnerGroupCity = details?.city;
                }
              } else if (this.state.runnerGroup || this.state.runnerGroupCity) {
                customeParams.groupDetails = {
                  name: this.state.runnerGroup,
                  city: this.state.runnerGroupCity,
                  groupVisibility: false,
                };
                customeParams.runnerGroup = this.state.runnerGroup;
                customeParams.runnerGroupCity = this.state.runnerGroupCity;
              }
            }
            if (
              this.state?.showCorporateOnReg &&
              this.state?.selectedCorporate
            ) {
              if (!this.state?.otherCorporate) {
                customeParams.corporateDetails = {
                  id: this.state.selectedCorporate?.id,
                  name: this.state.selectedCorporate?.label,
                };
              } else if (this.state.corporateName) {
                customeParams.corporateDetails = {
                  name: this.state.corporateName,
                };
              }
            }
            if (this.state.showAgeGroup && this.state?.selectedAgeGroup) {
              customeParams.ageGroupId = this.state?.selectedAgeGroup?.id;
            }
            customeParams.items = data;
            customeParams.isTermsNcondition = this.state.termsCheckboxSelected;
            customeParams.employeeCode = this.state.employeeCode;
            customeParams.paymentStatus = "SUCCESS";
            customeParams.paymentMode =
              this.state.grandTotal > 0
                ? "ONLINE"
                : this.state.grandTotal === 0
                ? "NOT_APPLICABLE"
                : "OFFLINE";

            //passing regKey
            let key = localStorage.getItem("key");
            if (key != "undefined") customeParams.regKey = key;

            if (this.state.grandTotal > 0) {
              this.paymentHandler(e);
            } else {
              registerEventCategory(customeParams).then((res) => {
                if (res.data.success && res.data.success.code === "409") {
                  this.setState({ changeActivity: res.data.success.verbose });
                  this.setState({ dialogHeader: "Already Registered" });
                  this.setState({ dailogMsg: this.getFailDailogComponent() });
                } else if (
                  res.data.success &&
                  res.data.success.code === "601"
                ) {
                  this.setState({
                    changeActivity: res.data.success.verbose,
                  });
                  this.setState({ dialogHeader: "Registrations Closed" });
                  this.setState({
                    dailogMsg: this.getRegistrationsClosedDailogComponent(),
                  });
                } else if (res.data?.success?.code === "500") {
                  this.setState({
                    changeActivity: res.data.success.verbose,
                    dialogHeader: "Event Registration Failed",
                    dailogMsg: this.getRegistrationFailDailogComponent(),
                  });
                } else {
                  this.updateEventsList();
                  if (this.state.grandTotal == 0)
                    this.setState({
                      dailogMsg: this.getSuccessDialogComponent(),
                    });
                  else
                    this.setState({
                      dailogMsg: this.getSuccessDialogComponentWithTotal(),
                    });
                }
                this.setState({ open: true });
              });
            }
          }
        }
      }
    } else {
      let submitFlag = true;
      let userId = userData?.id;
      let customeParams = {
        userId: parseInt(userId),
        eventId: this.state.eventId,
        categoryId: this.state.eventCategory.value,
      };

      if (this.state.showSecondaryActivity) {
        let secondaryActivityTypes = "";
        if (this.state?.selectedSecondaryActivity) {
          this.state.selectedSecondaryActivity.forEach((i) => {
            secondaryActivityTypes = secondaryActivityTypes?.concat(i.id + ",");
          });
          secondaryActivityTypes = secondaryActivityTypes.replace(/,\s*$/, "");
          customeParams.secondaryActivityTypes = secondaryActivityTypes;
        }
        //  else {
        //   Snackbar.show({
        //     variant: "error",
        //     isAutoHide: true,
        //     preventDuplicate: true,
        //     message: "Please select secondary Activity.",
        //   });
        //   this.goToTop();
        //   return;
        // }
      }

      if (this.state?.showAgeGroup && !this.state?.selectedAgeGroup) {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please select age group",
        });
        this.goToTop();
        return;
      }
      customeParams.isTermsNcondition = this.state.termsCheckboxSelected;

      let fields = [];
      // additional details check
      if (
        this.state.additionalDetails &&
        this.state.additionalDetails.length > 0
      ) {
        this.state.additionalDetails.forEach((element) => {
          if (submitFlag) {
            if (element.groupName) {
              for (let i = 0; i < element.field.length; i++) {
                if (
                  (element.field[i].fieldType.name === "SINGLE_SELECT" ||
                    element.field[i].fieldType.name === "MULTI_SELECT") &&
                  element.field[i].requiredField &&
                  !element.field[i].hidden &&
                  (element?.field[i]?.fieldValue == "" ||
                    element?.field[i]?.fieldValue == undefined)
                ) {
                  element.field[i].isError = true;
                  Snackbar.show({
                    variant: "error",
                    isAutoHide: true,
                    preventDuplicate: true,
                    message: element.field[i].displayName + " is Required",
                  });
                  submitFlag = false;

                  let newData = [...this.state.additionalDetails];
                  let index = newData.findIndex(function (obj) {
                    if (obj.field && obj.field.length > 0)
                      return (
                        obj.field[i].customFieldId ==
                        element.field[i].customFieldId
                      );
                  });
                  newData[index].isError = element.field[i].isError
                    ? true
                    : false;
                  this.setState({ additionalDetails: newData });
                  // this.setState({ data: [...this.state.data, element] });
                  this.goToTop();
                  submitFlag = false;
                  return;
                } else if (
                  (element.field[i].fieldType.name === "TEXT" ||
                    element.field[i].fieldType.name === "DATE" ||
                    element.field[i].fieldType.name === "NUMBER") &&
                  element.field[i].requiredField &&
                  !element.field[i].hidden &&
                  (element?.field[i]?.fieldValue == "" ||
                    element?.field[i]?.fieldValue == undefined ||
                    element?.field[i]?.fieldValue.trim()?.length === 0)
                ) {
                  element.field[i].isError = true;
                  Snackbar.show({
                    variant: "error",
                    isAutoHide: true,
                    preventDuplicate: true,
                    message: element.field[i].displayName + " is Required",
                  });
                  submitFlag = false;

                  let newData = [...this.state.additionalDetails];
                  let index = newData.findIndex(function (obj) {
                    if (obj.field && obj.field.length > 0)
                      return (
                        obj.field[i].customFieldId ==
                        element.field[i].customFieldId
                      );
                  });
                  newData[index].isError = element.field[i].isError
                    ? true
                    : false;
                  this.setState({ additionalDetails: newData });
                  // this.setState({ data: [...this.state.data, element] });
                  this.goToTop();
                  submitFlag = false;
                  return;
                } else {
                  let obj = {};

                  element.field[i].isError = false;
                  if (
                    element.field[i].fieldType.name == "SINGLE_SELECT" &&
                    element.field[i]?.fieldValue
                  ) {
                    obj.fieldId = element?.field[i]?.customFieldId;
                    obj.fieldOptionId = element?.field[i]?.fieldOptionId;
                    element.field[i] && element.field[i]?.fieldValue
                      ? (obj.fieldValue = element.field[i]?.fieldValue)
                      : (obj.fieldValue = null);
                    fields.push(obj);
                  } else if (
                    element.field[i].fieldType.name == "MULTI_SELECT"
                  ) {
                    if (
                      element.field[i]?.fieldValue &&
                      element.field[i]?.fieldValue?.length > 0
                    ) {
                      element.field[i].fieldValue.forEach((e) => {
                        let newObj = {};

                        newObj.fieldId = element?.field[i]?.customFieldId;
                        newObj.fieldOptionId = e?.id;
                        e && e?.optionValue
                          ? (newObj.fieldValue = e?.optionValue)
                          : (newObj.fieldValue = null);
                        fields.push(newObj);
                      });
                    }
                  } else if (element?.field[i]?.fieldType?.name == "DATE") {
                    obj.fieldId = element?.customFieldId;
                    element.fieldValue
                      ? (obj.dateValue = moment(
                          element?.field[i]?.fieldValue
                        ).format("DD-MM-YYYY"))
                      : (obj.dateValue = null);
                    fields.push(obj);
                  } else if (element.field[i]?.fieldValue) {
                    obj.fieldId = element?.field[i]?.customFieldId;
                    obj.fieldOptionId = element?.field[i]?.fieldOptionId;
                    element?.field[i]?.fieldValue
                      ? (obj.fieldValue = element?.field[i]?.fieldValue)
                      : (obj.fieldValue = "");
                    fields.push(obj);
                  }

                  if (element.field.length - 1 > i) {
                    submitFlag = false;
                  } else {
                    submitFlag = true;
                    return;
                  }
                }
              }
            } else {
              if (
                (element.fieldType.name === "SINGLE_SELECT" ||
                  element.fieldType.name === "MULTI_SELECT") &&
                element.requiredField &&
                !element?.hidden &&
                (element?.fieldValue === "" ||
                  element?.fieldValue === undefined)
              ) {
                element.isError = true;
                Snackbar.show({
                  variant: "error",
                  isAutoHide: true,
                  preventDuplicate: true,
                  message: element.displayName + " is Required",
                });
                submitFlag = false;
                let newData = [...this.state.additionalDetails];
                let index = newData.findIndex(
                  (obj) => obj.customFieldId == element.customFieldId
                );
                newData[index].isError = element.isError ? true : false;
                this.setState({ additionalDetails: newData });
                // this.setState({ data: [...this.state.data, element] });
                this.goToTop();
              } else if (
                (element.fieldType.name === "TEXT" ||
                  element.fieldType.name === "DATE" ||
                  element.fieldType.name === "NUMBER") &&
                element.requiredField &&
                !element?.hidden &&
                (element?.fieldValue === "" ||
                  element?.fieldValue === undefined ||
                  element?.fieldValue?.trim()?.length === 0)
              ) {
                element.isError = true;
                Snackbar.show({
                  variant: "error",
                  isAutoHide: true,
                  preventDuplicate: true,
                  message: element.displayName + " is Required",
                });
                submitFlag = false;
                let newData = [...this.state.additionalDetails];
                let index = newData.findIndex(
                  (obj) => obj.customFieldId == element.customFieldId
                );
                newData[index].isError = element.isError ? true : false;
                this.setState({ additionalDetails: newData });
                // this.setState({ data: [...this.state.data, element] });
                this.goToTop();
              } else {
                let obj = {};
                element.isError = false;
                if (
                  element.fieldType?.name == "SINGLE_SELECT" &&
                  element.fieldValue
                ) {
                  obj.fieldId = element?.customFieldId;
                  obj.fieldOptionId = element?.fieldOptionId;
                  element.fieldValue
                    ? (obj.fieldValue = element?.fieldValue)
                    : (obj.fieldValue = null);
                  fields.push(obj);
                } else if (element.fieldType?.name == "MULTI_SELECT") {
                  if (element.fieldValue && element?.fieldValue?.length > 0) {
                    element.fieldValue.forEach((e) => {
                      let newObj = {};
                      newObj.fieldId = element?.customFieldId;
                      newObj.fieldOptionId = e?.id;
                      e && e.optionValue
                        ? (newObj.fieldValue = e?.optionValue)
                        : (newObj.fieldValue = null);
                      fields.push(newObj);
                    });
                  }
                } else if (element.fieldType.name == "DATE") {
                  obj.fieldId = element.customFieldId;
                  element.fieldValue
                    ? (obj.dateValue = moment(element?.fieldValue).format(
                        "DD-MM-YYYY"
                      ))
                    : (obj.dateValue = null);
                  fields.push(obj);
                } else if (element.fieldValue) {
                  obj.fieldId = element.customFieldId;
                  obj.fieldOptionId = element?.fieldOptionId;
                  element?.fieldValue
                    ? (obj.fieldValue = element?.fieldValue)
                    : (obj.fieldValue = "");
                  fields.push(obj);
                }

                submitFlag = true;
                return;
              }
            }
          }
        });

        if (submitFlag) {
          let fieldValues = {
            runnerId: this.state.runnerId,
            eventId: this.state.eventId,
          };
          fieldValues.fields = fields;
          customeParams.fieldValues = fieldValues;
        }
      }

      //additional grouped fields
      if (
        this.state.additionalDetailsGroupedList &&
        this.state.additionalDetailsGroupedList.length > 0
      ) {
        for (
          let i = 0;
          i < this.state.additionalDetailsGroupedList.length;
          i++
        ) {
          this.state.additionalDetailsGroupedList[i].field.forEach(
            (element) => {
              if (submitFlag) {
                if (
                  (element.field[i].fieldType.name === "TEXT" ||
                    element.field[i].fieldType.name === "DATE" ||
                    element.field[i].fieldType.name === "NUMBER") &&
                  element?.requiredField &&
                  !element?.hidden &&
                  (element?.fieldValue == "" ||
                    element?.fieldValue == undefined ||
                    element?.fieldValue?.trim()?.length === 0)
                ) {
                  element.isError = true;
                  Snackbar.show({
                    variant: "error",
                    isAutoHide: true,
                    preventDuplicate: true,
                    message: element.displayName + " is Required",
                  });
                  submitFlag = false;
                  let newData = [...this.state.additionalDetailsGroupedList];

                  let index = newData[i].field.findIndex(
                    (obj) => obj.customFieldId == element.customFieldId
                  );
                  newData[index].isError = element.isError ? true : false;
                  this.setState({
                    additionalDetailsGroupedList: newData,
                  });
                  // this.setState({ data: [...this.state.data, element] });
                  this.goToTop();
                } else if (
                  (element.field[i].fieldType.name === "SINGLE_SELECT" ||
                    element.field[i].fieldType.name === "MULTI_SELECT") &&
                  element?.requiredField &&
                  !element?.hidden &&
                  (element?.fieldValue == "" ||
                    element?.fieldValue == undefined)
                ) {
                  element.isError = true;
                  Snackbar.show({
                    variant: "error",
                    isAutoHide: true,
                    preventDuplicate: true,
                    message: element.displayName + " is Required",
                  });
                  submitFlag = false;
                  let newData = [...this.state.additionalDetailsGroupedList];

                  let index = newData[i].field.findIndex(
                    (obj) => obj.customFieldId == element.customFieldId
                  );
                  newData[index].isError = element.isError ? true : false;
                  this.setState({
                    additionalDetailsGroupedList: newData,
                  });
                  // this.setState({ data: [...this.state.data, element] });
                  this.goToTop();
                } else {
                  let obj = {};
                  element.isError = false;

                  if (
                    element?.fieldType?.name == "SINGLE_SELECT" &&
                    element.fieldValue
                  ) {
                    obj.fieldId = element?.customFieldId;
                    obj.fieldOptionId = element?.fieldValue?.id;
                    element.fieldValue
                      ? (obj.fieldValue = element?.fieldValue)
                      : (obj.fieldValue = null);
                    fields.push(obj);
                  } else if (element.fieldType?.name == "MULTI_SELECT") {
                    if (element.fieldValue && element?.fieldValue?.length > 0) {
                      element.fieldValue.forEach((e) => {
                        let newObj = {};
                        newObj.fieldId = element?.customFieldId;
                        newObj.fieldOptionId = e?.id;
                        e && e.optionValue
                          ? (newObj.fieldValue = e?.optionValue)
                          : (newObj.fieldValue = null);
                        fields.push(newObj);
                      });
                    }
                  } else if (element.fieldType.name == "DATE") {
                    obj.fieldId = element?.customFieldId;
                    element.fieldValue
                      ? (obj.dateValue = moment(element?.fieldValue).format(
                          "DD-MM-YYYY"
                        ))
                      : (obj.dateValue = null);
                    fields.push(obj);
                  } else if (element.fieldValue) {
                    obj.fieldId = element?.customFieldId;
                    obj.fieldOptionId = element?.fieldOptionId;
                    element.fieldValue
                      ? (obj.fieldValue = element?.fieldValue)
                      : (obj.fieldValue = "");
                    fields.push(obj);
                  }

                  submitFlag = true;
                  return;
                }
              }
            }
          );
          if (submitFlag) {
            let fieldValues = {
              runnerId: this.state.runnerId,
              eventId: this.state.eventId,
            };
            fieldValues.fields = fields;
            customeParams.fieldValues = fieldValues;
          }
        }
      }
      //condition for contact field if fees is present
      if (
        (this.state.contactNumber == "" && checkContact) ||
        (!ApplicationUtil.validateContactNumber(this.state.contactNumber) &&
          // this.state.contactNumber.length < 10
          checkContact)
      ) {
        this.setState({ contactNumberError: true });
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Please enter valid contact number.",
        });
        this.goToTop();
        return;
      }
      if (submitFlag) {
        let data = [];
        if (this.state.eventItemsFormData?.eventItems) {
          this.state.eventItemsFormData.eventItems.forEach((element) => {
            if (element.itemQuantity && element.itemQuantity > 0) {
              let object = {};
              object.id = element.id;
              object.itemName = element.itemName;
              object.pricePerUnit = element.pricePerUnit;
              object.quantity = element.itemQuantity;
              if (element?.sizeArray) {
                let data = ApplicationUtil.getRunnerDetails();
                let gender = data.gender;
                if (gender == "MALE" || gender == "OTHER") {
                  object.sizeMale = element?.selectedSize.value;
                }

                if (gender == "FEMALE") {
                  object.sizeFemale = element?.selectedSize.value;
                }
                //object.size = this.state.selectedSize.value;
              }
              data.push(object);
            }
          });
        }
        if (
          this.state.displayIncrementDistance &&
          this.state.incrementDistance
        ) {
          customeParams.incrementalDistanceId =
            this.state.incrementDistanceValue.id;
        }
        if (this.state.showSuggestionOnRegisterEvent) {
          customeParams.height = this.state.height;
          customeParams.weight = this.state.weight;
          customeParams.bmi = Number(this.state.calculatedBmi);
          let profileData = ApplicationUtil.getRunnerDetails();
          this.props.updateUserDetails({
            ...profileData,
            height: Number(this.state.height),
            weight: Number(this.state.weight),
          });
          {
            this.state.challengeParams?.includes("WELLNESS_POINT") &&
              (customeParams.totalTarget =
                this.state.calculatedSuggestedPoints);
          }
          {
            this.state.challengeParams?.includes("DISTANCE") &&
              (customeParams.totalTarget =
                this.state.calculatedSuggestedDistance);
          }
          {
            this.state.challengeParams?.includes("STEPS") &&
              (customeParams.totalTarget = this.state.calculatedSuggestedSteps);
          }
        }
        if (this.state.showRunnerGroup) {
          if (!this.state.otherGroup) {
            //runnerGroup.map(r => ((r.id = r.id), (r.name = r.name)));
            if (this.state.groupDetails) {
              let details = this.state.groupDetails;
              customeParams.groupDetails = {
                name: details?.name,
                id: details?.id,
                city: details?.city,
                groupVisibility: details?.groupVisibility,
              };
              customeParams.runnerGroup = details?.name;
              customeParams.runnerGroupCity = details?.city;
            }
          } else if (this.state.runnerGroup || this.state.runnerGroupCity) {
            customeParams.groupDetails = {
              name: this.state.runnerGroup,
              city: this.state.runnerGroupCity,
              groupVisibility: false,
            };
            customeParams.runnerGroup = this.state.runnerGroup;
            customeParams.runnerGroupCity = this.state.runnerGroupCity;
          }
        }
        if (this.state?.showCorporateOnReg && this.state?.selectedCorporate) {
          if (!this.state?.otherCorporate) {
            customeParams.corporateDetails = {
              id: this.state.selectedCorporate?.id,
              name: this.state.selectedCorporate?.label,
            };
          } else if (this.state.corporateName) {
            customeParams.corporateDetails = { name: this.state.corporateName };
          }
        }
        if (this.state.showAgeGroup && this.state?.selectedAgeGroup) {
          customeParams.ageGroupId = this.state?.selectedAgeGroup?.id;
        }
        customeParams.items = data;
        customeParams.employeeCode = this.state.employeeCode;
        customeParams.paymentStatus = "SUCCESS";
        customeParams.paymentMode =
          this.state.grandTotal > 0
            ? "ONLINE"
            : this.state.grandTotal === 0
            ? "NOT_APPLICABLE"
            : "OFFLINE";
        let key = localStorage.getItem("key");
        if (key != undefined) customeParams.regKey = key;
        if (this.state.grandTotal > 0) {
          this.paymentHandler(e);
        } else {
          registerEventCategory(customeParams).then((res) => {
            if (res.data.success && res.data.success.code === "409") {
              this.setState({ changeActivity: res.data.success.verbose });
              this.setState({ dialogHeader: "Already Registered" });
              this.setState({ dailogMsg: this.getFailDailogComponent() });
            } else if (res.data.success && res.data.success.code === "601") {
              this.setState({
                changeActivity: res.data.success.verbose,
              });
              this.setState({ dialogHeader: "Registrations Closed" });
              this.setState({
                dailogMsg: this.getRegistrationsClosedDailogComponent(),
              });
            } else if (res.data?.success?.code === "500") {
              this.setState({
                changeActivity: res.data.success.verbose,
                dialogHeader: "Event Registration Failed",
                dailogMsg: this.getRegistrationFailDailogComponent(),
              });
            } else {
              this.updateEventsList();
              if (this.state.grandTotal == 0)
                this.setState({ dailogMsg: this.getSuccessDialogComponent() });
              else
                this.setState({
                  dailogMsg: this.getSuccessDialogComponentWithTotal(),
                });
            }
            this.setState({ open: true });
          });
        }
      }
    }
  };

  handleCategoryChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      let { eventItems1, eventItems } = this.state;
      this.setState(
        {
          eventCategory: selectedValues,
          eventCategoryError: false,
          registrationFee: selectedValues.registrationFee,
          grandTotal: this.state.grandTotal + selectedValues.registrationFee,
          isPhysical: ApplicationUtil.checkPhysicalEvent(
            selectedValues?.participantType,
            this.state.showParticipationType
          ),
        },
        () => {
          this.calculateTotal();
          //  this.setState({eventItems: eventItems1})
        }
      );
    } else {
      this.setState({
        eventCategory: selectedValues,
        eventCategoryError: true,
      });
    }
  };
  handleParticipationTypeChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      let newEventsTypeList = [];
      this.state.allEventTypeList.forEach((element) => {
        if (element.participantType === selectedValues.value) {
          newEventsTypeList.push(element);
        }
      });

      this.setState(
        {
          eventType: newEventsTypeList[0],
          eventTypeList: newEventsTypeList,
          participationType: selectedValues,
          participationTypeError: false,
          isPhysical: ApplicationUtil.checkPhysicalEvent(
            selectedValues?.value,
            this.state.showParticipationType
          ),
          // selectedValues?.value === "REGULAR"
          //   ? false
          //   : this.state.showParticipationType &&
          //     selectedValues?.value === "REGULAR_PHYSICAL"
          //   ? true
          //   :
          //   this.state.eventParticipantType === "ALL"
          //   ? false
          //   : true,
        },
        () => {
          this.handleTypeChange(this.state.eventType);
        }
      );
    } else {
      this.setState({
        participationType: selectedValues,
        participationTypeError: true,
      });
    }
  };

  handleTypeChange = (selectedValues) => {
    let eventId = ApplicationUtil.getRunnersEventID();

    const {
      eventType,
      completeEventCategories,
      participationType,
      eventParticipantType,
      showParticipationType,
    } = this.state;

    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      let catList = [];
      completeEventCategories.forEach((element) => {
        //having supportedActivityType
        if (element?.eventSupportedActivityType?.id) {
          if (
            eventParticipantType === "ALL" &&
            showParticipationType &&
            selectedValues.eventSupportedActivityTypeId &&
            selectedValues.eventSupportedActivityTypeId ===
              element.eventSupportedActivityType.id &&
            element.participantType === participationType?.value
          ) {
            if (!element.hidden) {
              catList.push(element);
            } else {
              return;
            }
          } else if (
            // eventParticipantType !== "ALL" &&
            !showParticipationType &&
            selectedValues.eventSupportedActivityTypeId &&
            selectedValues.eventSupportedActivityTypeId ===
              element.eventSupportedActivityType.id
          ) {
            if (!element.hidden) {
              catList.push(element);
            } else {
              return;
            }
          }
        } else {
          //not having supportedActivityType
          if (this.state.eventType.type === element.activityType.type) {
            catList.push(element);
          }
        }
      });

      this.setState(
        {
          activityType: selectedValues.label,
          eventType: selectedValues,
          eventTypeError: false,
          eventCategoryList: catList,
          eventCategory: catList[0] ? catList[0] : "",
        },
        () => {
          this.handleCategoryChange(this.state.eventCategory);
        }
      );
      // this.SeperateEventTypes(this.state.eventTotalList, selectedValues.value);

      //secondary activity
      if (this.state.showSecondaryActivity) {
        getSecondaryActivity(eventId, selectedValues.id).then((response) => {
          let secondaryActivityList = response.data.secondaryActivityType;
          secondaryActivityList.map(
            (r) => (
              (r.label = r.displayName), (r.value = r.type), (r.id = r.id)
            )
          );
          this.setState({
            secondaryActivityList: secondaryActivityList,
          });
        });
      }
    } else {
      this.setState({
        eventType: selectedValues,
        eventTypeError: true,
      });
    }
  };

  // secondary
  handleSecondaryActivityChange = (selectedValues) => {
    var arr = [];

    if (selectedValues != null) {
      selectedValues.forEach((element) => {
        arr.push(element);
      });

      this.setState({
        selectedSecondaryActivity: arr,
        // activityTypeError: false,
      });
    } else {
      this.setState({
        selectedSecondaryActivity: arr,
        // activityTypeError: true,
      });
    }
  };
  handleRunnerGroupChange = (selectedValues) => {
    this.setState({
      selectedRunnerGroup: selectedValues,
      groupDetails: selectedValues,
    });

    if (selectedValues?.label == "Other") this.setState({ otherGroup: true });
    else this.setState({ otherGroup: false });
  };
  handleCorporateChange = (selectedValues) => {
    this.setState({ selectedCorporate: selectedValues });
    if (selectedValues?.label === "Other") {
      this.setState({ otherCorporate: true });
    } else {
      this.setState({ otherCorporate: false });
    }
  };
  handleAgeGroupChange = (selectedValues) => {
    this.setState({ selectedAgeGroup: selectedValues });
  };
  handleStaticDataChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        selectedStaticData: selectedValues,
      });
    }
  };
  handleHourChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        hour: selectedValues,
        hourError: false,
      });
    } else {
      this.setState({
        hour: selectedValues,
        hourError: true,
      });
    }
  };
  handleMinuteChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        minute: selectedValues,
        minuteError: false,
      });
    } else {
      this.setState({
        minute: selectedValues,
        minuteError: true,
      });
    }
  };
  handleSecondChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);

      this.setState({
        second: selectedValues,
        secondError: false,
      });
    } else {
      this.setState({
        second: selectedValues,
        secondError: true,
      });
    }
  };

  handleCodeChange = (selectedValues) => {
    var arr = [];
    if (selectedValues != null) {
      arr.push(selectedValues.value);
      this.setState({
        contactCode: selectedValues,
      });
    } else {
      this.setState({
        contactCode: selectedValues,
      });
    }
  };

  handleCheckBoxChange = (event) => {
    this.setState({ termsCheckboxSelected: event.target.checked });
  };

  closeWindow = () => {
    this.setState({ open: false });
  };

  getTimeInvalidDialogComponent = () => {
    return (
      <div>
        <Typography style={{ display: "grid" }}>
          <div style={{ paddingTop: "10px" }}>
            <span style={{ color: "#D85656" }}>
              <div>Registartions are closed for Freedom2Fitness Event</div>
            </span>
          </div>
        </Typography>
      </div>
    );
  };

  getFailDailogComponent = () => {
    return (
      <div style={{ padding: "8px 24px" }}>
        <Typography style={{ display: "grid" }}>
          <div style={{ paddingTop: "10px" }}>
            <span style={{ color: "#D85656" }}>
              <div>
                You have already registered for this event. Please check
                registered email.
              </div>
            </span>
          </div>
        </Typography>
      </div>
    );
  };
  getRegistrationFailDailogComponent = () => {
    return (
      <div style={{ padding: "8px 24px" }}>
        <Typography style={{ display: "grid" }}>
          <div style={{ paddingTop: "10px" }}>
            <span style={{ color: "#D85656" }}>
              <div>Event Registration failed.</div>
            </span>
          </div>
        </Typography>
      </div>
    );
  };
  getRegistrationsClosedDailogComponent = () => {
    return (
      <div style={{ padding: "8px 24px" }}>
        <Typography style={{ display: "grid" }}>
          <div style={{ paddingTop: "10px" }}>
            <span style={{ color: "#D85656" }}>
              <div>Registrations for this event are over.</div>
            </span>
          </div>
        </Typography>
      </div>
    );
  };
  getSuccessDialogComponent = () => {
    const { eventName, eventType, eventCategory, showCategoryOnRegistration } =
      this.state;

    return (
      <div style={{ padding: "8px 24px" }}>
        <Typography style={{ display: "grid" }}>
          {showCategoryOnRegistration ? (
            <span>
              Thank You for registering the event, <b>{eventName}</b>
              {!(
                eventType.type === "ANY" && eventCategory.category === "ANY"
              ) && (
                <>
                  {". "}
                  for <b>{eventCategory.categoryName}</b> category.
                </>
              )}
            </span>
          ) : (
            <span>
              Thank You for registering to <b>{eventName}</b>
              {/* {!(
              eventType.type === "ANY" && eventCategory.category === "ANY"
            ) && (
              <>
                {". "}
                for <b>{eventCategory.categoryName}</b> category.
              </>
            )} */}
            </span>
          )}
        </Typography>
      </div>
    );
  };

  getSuccessDialogComponentWithTotal = () => {
    const { eventName, eventType, eventCategory, showCategoryOnRegistration } =
      this.state;

    return (
      <div style={{ padding: "8px 24px" }}>
        <Typography style={{ display: "grid", fontSize: "16px !important" }}>
          {showCategoryOnRegistration ? (
            <span>
              Thank You for registering the event, <b>{this.state.eventName}</b>
              {!(
                eventType.type === "ANY" && eventCategory.category === "ANY"
              ) && (
                <>
                  {". "}
                  for{" "}
                  {this.state.grandTotal > 0 && (
                    <Fragment>
                      <b>{this.state.eventCategory.categoryName}</b> category.
                    </Fragment>
                  )}
                  {this.state.grandTotal <= 0 && (
                    <Fragment>
                      <b>{this.state.eventCategory.categoryName}</b> category.
                    </Fragment>
                  )}
                </>
              )}
            </span>
          ) : (
            <span>
              Thank You for registering to
              {/* the event,{" "} */}
              <b>{this.state.eventName}</b>
            </span>
          )}
          {this.state.grandTotal > 0 && (
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Acknowledgement ID</div>
                <div
                  style={{
                    display: "flex",
                    wordBreak: "break-all",
                  }}
                >
                  {" "}
                  {this.state.transactionID}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Acknowledgement Status</div>
                <div
                  style={{
                    display: "flex",
                    wordBreak: "break-all",
                  }}
                >
                  {this.state.transactionStatus}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Date</div>
                <div
                  style={{
                    display: "flex",
                    wordBreak: "break-all",
                  }}
                >
                  {moment(new Date()).format("DD-MM-YYYY HH:mm:ss")}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <div style={{ fontWeight: "bold" }}>
                  {Properties.Total_Amount}
                </div>
                <div
                  style={{
                    display: "flex",
                    wordBreak: "break-all",
                  }}
                >
                  {" "}
                  {this.state.grandTotal}
                </div>
              </div>
            </div>
          )}
        </Typography>
      </div>
    );
  };

  navigate = () => {
    //QA
    //window.location.assign('/signup')

    //prod
    window.location.assign("https://necessarydevil.com");

    //this.props.history.push("/");
  };

  navigate2 = () => {
    let runnerData = ApplicationUtil.getRunnerDetails();
    if (runnerData?.isAuthorized) {
      this.props.history.push("/eventDetails");
    } else {
      this.props.history.push("/consent");
    }
  };

  showInvite = () => {
    this.props.history.push("/invitationForm");
  };
  noShowInvite = () => {
    return null;
  };
  handleIncrementDistanceChange = (value) => {
    const { incrementDistanceValue, incrementDistanceList } = this.state;
    this.setState({ incrementDistanceValue: value });
  };
  onApplyCoupon = (totalCharges) => {
    const { couponCode, grandTotal } = this.state;
    getDiscountCoupon(couponCode).then((res) => {
      if (res.data?.discountApplicable) {
        let discount = res.data?.discountPercentage;
        let price = totalCharges ? totalCharges : grandTotal;
        let finalPrice = 0;
        if (price > 0) {
          finalPrice = (price - price * (discount / 100)).toFixed(2);
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: `Hurray!!! You saved ${(price - finalPrice).toFixed(2)}`,
          });
          this.setState({ grandTotal: finalPrice, couponApplied: true });
        }
      } else {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: `Coupon not found`,
        });
      }
    });
  };
  render() {
    const { classes } = this.props;
    const {
      eventCategoryError,
      eventCategoryList,
      eventTypeList,
      newEventTypeList,
      eventName,
      eventTypeError,
      eventType,
      selectedSecondaryActivity,
      secondaryActivityList,
      showSecondaryActivity,
      eventCategory,
      registrationFee,
      selectedSizeError,
      grandTotal,
      signupCondition,
      streakType,
      hourError,
      couponCode,
      minuteError,
      secondError,
      staticDataList,
      selectedStaticData,
      activityType,
      showPersonalBest,
      description,
      ticketSoldOut,
      eventDate,
      eventEnd,
      eventState,
      eventDesc,
      eventItems,
      displayIncrementDistance,
      participationType,
      participationTypeError,
      participationTypeList,
      eventParticipantType,
      showParticipationType,
      briefRegistrationText,
      showBriefTextOnRegistration,
      showCategoryOnRegistration,
      showActivityTypeOnRegistration,
      hideCustomSectionLabel,
      customSectionLabel,
      additionalDetails,
      height,
      weight,
      heightError,
      weightError,
      showHtWtOnSignup,
      showSuggestionOnRegisterEvent,
      calculatedBmi,
      calculatedSuggestedPoints,
      calculatedSuggestedDistance,
      calculatedSuggestedSteps,
      wellnessPointDesc,
      challengeParams,
      hasDiscountCoupon,
      couponApplied,
    } = this.state;

    return (
      <React.Fragment>
        <AppToolBar
          backNavigate={() => this.props.history.push("/eventlist")}
          showBack={true}
        >
          {/* <Header {...this.props} label={Properties.Label_Event} /> */}
          {eventDate === eventEnd ? (
            <Header
              {...this.props}
              label={eventName ? eventName : Properties.Label_EventDetails}
              fromDate={eventDate}
              title={eventName}
              description={eventDesc}
            />
          ) : (
            <Header
              {...this.props}
              label={eventName ? eventName : Properties.Label_EventDetails}
              fromDate={eventDate}
              toDate={eventEnd}
              title={eventName}
              description={eventDesc}
            />
          )}
          <div style={{ width: 35 }} />
        </AppToolBar>
        <div className={classes.mainPanel}>
          <div style={{ marginLeft: "6.7%" }}>
            <RunnerSideBreadcrumbs />
          </div>
          <Grid
            style={{
              width: "100%",
              // height: "75px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
            container
            spacing={1}
          >
            <Grid
              item
              style={{
                marginLeft: "6.7%",
                // marginTop: "3%"
              }}
            >
              <Typography variant="body2" gutterBottom>
                <span
                  style={{
                    color: "#2883B5",
                    fontSize: "23px",
                    fontWeight: "bold",
                  }}
                >
                  {"Register"}
                </span>
              </Typography>
            </Grid>
          </Grid>

          <Divider
            style={{
              height: "1px",
              backgroundColor: " rgb(13, 108, 169)",
              color: "#3a6ea5",
              // border:'1px solid #08456C',
              marginLeft: "6%",
              marginRight: "9%",
            }}
          />

          {this.state.registrationEnded && (
            <>
              <Grid
                style={{
                  width: "100%",
                  margin: "0px",
                  marginTop: "10px",
                }}
                container
                spacing={1}
              >
                <Grid
                  style={{
                    marginLeft: "6.7%",
                    marginRight: "6.7%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  item
                  xs={10}
                >
                  <span
                    style={{
                      color: "red",
                      fontSize: "22px",
                      fontWeight: "bold",
                    }}
                  >
                    {Properties.RegistrationsClosed_message}
                  </span>
                </Grid>
              </Grid>
            </>
          )}
          {eventState === "DRAFT" && (
            <>
              <Grid
                style={{
                  width: "100%",
                  margin: "0px",
                  marginTop: "10px",
                }}
                container
                spacing={1}
              >
                <Grid
                  style={{
                    marginLeft: "6.7%",
                    marginRight: "6.7%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  item
                  xs={10}
                >
                  <span
                    style={{
                      color: "red",
                      fontSize: "22px",
                      fontWeight: "bold",
                    }}
                  >
                    Registration not open
                  </span>
                </Grid>
              </Grid>
            </>
          )}
          {eventState !== "DRAFT" && this.state.registrationOpen && (
            <Grid>
              <Grid
                style={{
                  width: "100%",
                  margin: "0px",
                }}
                container
                spacing={1}
              >
                {this.state.eventId == ApplicationConstants.CommonEventId && (
                  <Grid
                    style={{
                      marginLeft: "6.7%",
                      marginTop: "20px",
                      height: "50px",
                    }}
                    item
                    xs={10}
                  >
                    <Typography variant="body2" gutterBottom>
                      <span style={{ color: "#727272" }}>
                        {"Employee Code"}
                      </span>
                      <span style={{ color: "red" }}>{"*  "}</span>

                      <span style={{ color: "black", fontSize: "12px" }}>
                        {"(6 digits Code)"}
                      </span>
                    </Typography>

                    <CustomTextField
                      style={
                        this.state.employeeCodeError
                          ? {
                              border: "1px solid red",
                              height: "90%",
                              maxHeight: "90%",
                              width: "100%",
                              minHeight: "90%",
                            }
                          : {
                              border: "1px solid #08456C",
                              height: "90%",
                              maxHeight: "90%",
                              width: "100%",
                              minHeight: "90%",
                            }
                      }
                      type={"text"}
                      keyToSet={"employeeCode"}
                      value={this.state.employeeCode}
                      handleChange={(prop) => (event) => {
                        let isCodeMatch = validateEmpCode(event.target.value);
                        this.setState({
                          employeeCode: event.target.value,
                          employeeCodeError: !isCodeMatch ? true : false,
                        });
                      }}
                    />
                  </Grid>
                )}

                {showActivityTypeOnRegistration && (
                  <>
                    {eventParticipantType === "ALL" &&
                      showParticipationType && (
                        <Grid
                          style={{
                            marginLeft: "6.7%",
                            marginRight: "6.7%",
                            marginTop: "20px",
                          }}
                          item
                          xs={10}
                          lg={6}
                        >
                          <Typography variant="body2" gutterBottom>
                            <span style={{ color: "#727272" }}>
                              {Properties.ParticipationLabel}
                            </span>
                            <span style={{ color: "red" }}>*</span>
                          </Typography>

                          <MultiSelect
                            value={participationType}
                            error={participationTypeError}
                            options={participationTypeList}
                            onChange={this.handleParticipationTypeChange}
                          />
                        </Grid>
                      )}

                    <Grid
                      style={{
                        marginLeft: "6.7%",
                        marginRight: "6.7%",
                      }}
                      item
                      xs={10}
                      lg={6}
                    >
                      <Typography variant="body2" gutterBottom>
                        <span style={{ color: "#727272" }}>
                          {Properties.Activity_type_label}
                        </span>
                        <span style={{ color: "red" }}>*</span>
                      </Typography>

                      <MultiSelect
                        value={eventType}
                        error={eventTypeError}
                        options={
                          newEventTypeList.length > 0
                            ? newEventTypeList
                            : eventTypeList
                        }
                        onChange={this.handleTypeChange}
                      />
                    </Grid>

                    {/* secondary activity */}
                    {showSecondaryActivity && (
                      <Grid
                        style={{
                          marginLeft: "6.7%",
                        }}
                        item
                        xs={10}
                        lg={6}
                      >
                        <Typography
                          variant="body2"
                          gutterBottom
                          className={classes.helpContainerCss}
                        >
                          <span style={{ color: "#727272" }}>
                            {Properties.Secondary_Activity_type_label}
                          </span>
                          <span className={classes.helpCss}>
                            <Tooltip
                              title={Properties.SecondaryActivityInfoText}
                            >
                              <InfoIcon fontSize="small" />
                            </Tooltip>
                          </span>
                        </Typography>

                        <div className={classes.dropdownCss}>
                          <MultiSelect
                            isMulti={true}
                            value={selectedSecondaryActivity || ""}
                            // error={activityTypeError}
                            options={secondaryActivityList}
                            onChange={(e) => {
                              this.handleSecondaryActivityChange(e);
                            }}
                          />
                        </div>
                      </Grid>
                    )}
                  </>
                )}
                {showSuggestionOnRegisterEvent &&
                  challengeParams?.includes("WELLNESS_POINT") && (
                    <Grid
                      style={{
                        marginLeft: "6.7%",
                        marginRight: "6.7%",
                      }}
                      item
                      xs={10}
                      lg={6}
                    >
                      <Typography variant="body2" gutterBottom>
                        <span style={{ color: "#727272" }}>
                          {Properties.SuggestedWellnessPoints}
                        </span>
                      </Typography>

                      <CustomTextField
                        style={{
                          border: "1px solid #08456C",
                          height: "38px", //"55%",
                          maxHeight: "38px", //"55%",
                          minHeight: "38px",
                          marginLeft: "2px",
                        }}
                        type={"number"}
                        isNumber={true}
                        value={calculatedSuggestedPoints}
                        readOnly
                        keyToSet={"suggestedpoints"}
                        handleChange={(prop) => (event) => {}}
                      />

                      <span> / daily</span>
                    </Grid>
                  )}
                {showSuggestionOnRegisterEvent &&
                  challengeParams?.includes("DISTANCE") && (
                    <Grid
                      style={{
                        marginLeft: "6.7%",
                        marginRight: "6.7%",
                      }}
                      item
                      xs={10}
                      lg={6}
                    >
                      <Typography variant="body2" gutterBottom>
                        <span style={{ color: "#727272" }}>
                          {Properties.SuggestedDistance}
                        </span>
                      </Typography>

                      <CustomTextField
                        style={{
                          border: "1px solid #08456C",
                          height: "38px", //"55%",
                          maxHeight: "38px", //"55%",
                          minHeight: "38px",
                          marginLeft: "2px",
                        }}
                        type={"number"}
                        isNumber={true}
                        value={calculatedSuggestedDistance}
                        readOnly
                        keyToSet={"suggestedpoints"}
                        handleChange={(prop) => (event) => {}}
                      />

                      <span> / daily</span>
                    </Grid>
                  )}
                {showSuggestionOnRegisterEvent &&
                  challengeParams?.includes("STEPS") && (
                    <Grid
                      style={{
                        marginLeft: "6.7%",
                        marginRight: "6.7%",
                      }}
                      item
                      xs={10}
                      lg={6}
                    >
                      <Typography variant="body2" gutterBottom>
                        <span style={{ color: "#727272" }}>
                          {Properties.SuggestedSteps}
                        </span>
                      </Typography>

                      <CustomTextField
                        style={{
                          border: "1px solid #08456C",
                          height: "38px", //"55%",
                          maxHeight: "38px", //"55%",
                          minHeight: "38px",
                          marginLeft: "2px",
                        }}
                        type={"number"}
                        isNumber={true}
                        value={calculatedSuggestedSteps}
                        readOnly
                        keyToSet={"suggestedpoints"}
                        handleChange={(prop) => (event) => {}}
                      />

                      <span> / daily</span>
                    </Grid>
                  )}

                {showCategoryOnRegistration && (
                  <>
                    <Grid
                      style={{
                        margin: "0% 6.7%",
                        //marginTop: "30px",
                        //height: "50px",
                      }}
                      item
                      xs={10}
                    >
                      {eventType !== "" && (
                        <React.Fragment>
                          <Typography variant="body2" gutterBottom>
                            <span style={{ color: "#727272" }}>
                              {Properties.Label_EventCategory}
                            </span>
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              <MultiSelect
                                value={eventCategory}
                                error={eventCategoryError}
                                options={eventCategoryList}
                                onChange={this.handleCategoryChange}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={3}
                              spacing={5}
                              style={{ margin: "auto" }}
                            >
                              {this.state.registrationFee > 0 && (
                                <span
                                  style={{
                                    display: "flex",
                                    //padding: "2px 20px",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    color: "#09456C",
                                    marginLeft: "15px",
                                  }}
                                >
                                  {"Fee "}&#8377;
                                  {" " + this.state.registrationFee}
                                </span>
                              )}
                            </Grid>

                            <Grid item xs={6} sm={3} style={{ margin: "auto" }}>
                              {this.state.grandTotal > 0 && (
                                <span
                                  style={{
                                    display: "flex",
                                    //padding: "2px 20px",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    color: "#09456C",
                                  }}
                                >
                                  Total &#8377; {this.state.grandTotal}
                                </span>
                              )}
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                    {/* increment distance field*/}

                    {displayIncrementDistance && (
                      <Grid
                        item
                        style={{
                          marginLeft: "6%",
                        }}
                        xs={10}
                      >
                        <div className={classes.incrementDistanceBlock}>
                          <Checkbox
                            // color="primary"
                            className={classes.checkBox}
                            onChange={(e) => {
                              const { eventCategory, incrementDistanceList } =
                                this.state;
                              let id = eventCategory.id;
                              let filterListArr = incrementDistanceList.filter(
                                (x) => x.eventRunCategoryId === id
                              );

                              this.setState({
                                incrementDistance: e.target.checked,
                                incrementDistanceValue: filterListArr[0],
                                incrementDistanceList: filterListArr,
                              });
                            }}
                          />
                          <Typography variant="body2" gutterBottom>
                            <span style={{ color: "#727272" }}>
                              {Properties.Label_IncrementDistance}
                            </span>
                          </Typography>

                          <div className={classes.incrementDistanceMenu}>
                            <MultiSelect
                              value={this.state.incrementDistanceValue}
                              options={this.state.incrementDistanceList}
                              onChange={this.handleIncrementDistanceChange}
                              isReadOnly={!this.state.incrementDistance}
                            />
                          </div>
                        </div>
                      </Grid>
                    )}
                  </>
                )}

                {showBriefTextOnRegistration &&
                  briefRegistrationText !== "" && (
                    <Grid
                      style={{
                        marginLeft: "6.7%",
                        marginRight: "6.7%",
                      }}
                      item
                      xs={10}
                      lg={6}
                    >
                      <Typography variant="body2" gutterBottom>
                        <span
                          style={{
                            color: "#727272",
                            fontWeight: 600,
                            fontSize: "20px",
                          }}
                        >
                          {briefRegistrationText}
                        </span>
                      </Typography>
                    </Grid>
                  )}
                {description && (
                  <>
                    {description.map((desc) =>
                      desc.label === eventType.value &&
                      desc.category === eventCategory.categoryName ? (
                        <Grid
                          container
                          style={{
                            marginLeft: "7%",
                            marginTop: "20px",
                            marginBottom: "10px",
                          }}
                          xs={10}
                        >
                          <Typography variant="body2" gutterBottom>
                            <span>
                              <b style={{ color: "rgb(40, 131, 181)" }}>
                                {desc.label}
                              </b>
                            </span>
                            <br />
                            {desc?.ticketSoldOut ? (
                              <span style={{ color: "#ff0000" }}>
                                {"SOLD OUT"}
                              </span>
                            ) : desc?.ticketSoldOut === undefined ? (
                              <span>{desc.desc}</span>
                            ) : (
                              <span>{desc.desc}</span>
                            )}
                          </Typography>
                        </Grid>
                      ) : (
                        <></>
                      )
                    )}
                  </>
                )}

                {this.state.computeAdvisedPace ? (
                  <Grid
                    style={{
                      marginLeft: "6.7%",
                      marginTop: "45px",
                      height: "120px",
                    }}
                    item
                    xs={10}
                  >
                    {eventType != "" && (
                      <React.Fragment>
                        <div>
                          <Typography variant="body2" gutterBottom>
                            <span style={{ color: "#727272" }}>
                              Please enter personal best
                            </span>
                          </Typography>
                        </div>
                        <Typography
                          style={{ marginTop: "10px" }}
                          variant="body2"
                          gutterBottom
                        >
                          <span style={{ color: "#727272" }}>Distance</span>
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          style={{ paddingBottom: "5px" }}
                        >
                          <Grid item xs={4}>
                            <MultiSelect
                              value={selectedStaticData}
                              options={staticDataList}
                              onChange={this.handleStaticDataChange}
                            />
                          </Grid>
                        </Grid>

                        {this.state.selectedStaticData != "" && (
                          <React.Fragment>
                            <Typography
                              style={{ marginTop: "10px" }}
                              variant="body2"
                              gutterBottom
                            >
                              <span style={{ color: "#727272" }}>Time</span>
                            </Typography>
                            <Grid
                              container
                              spacing={2}
                              style={{ paddingBottom: "7px" }}
                            >
                              <Grid item xs={4}>
                                <MultiSelect
                                  error={hourError}
                                  options={ApplicationConstants.Hours}
                                  onChange={this.handleHourChange}
                                  classes={{ icon: classes.icon }}
                                />
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    color: "#2883B5",
                                  }}
                                >
                                  HH
                                </span>
                              </Grid>
                              <Grid item xs={4}>
                                <MultiSelect
                                  error={minuteError}
                                  options={ApplicationConstants.valueArray}
                                  onChange={this.handleMinuteChange}
                                  classes={{ icon: classes.icon }}
                                />{" "}
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    color: "#2883B5",
                                  }}
                                >
                                  MM
                                </span>
                              </Grid>
                              <Grid item xs={4}>
                                <MultiSelect
                                  error={secondError}
                                  options={ApplicationConstants.valueArray}
                                  onChange={this.handleSecondChange}
                                  classes={{ icon: classes.icon }}
                                />{" "}
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    color: "#2883B5",
                                  }}
                                >
                                  SS
                                </span>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </Grid>
                ) : (
                  <></>
                )}

                {(this.state.eventId === 507 ||
                  this.state.eventId === 515 ||
                  this.state.eventId === 508) && (
                  <Grid
                    style={{ width: "100%", margin: "0% 6.7%" }}
                    container
                    spacing={1}
                  >
                    <Typography variant="body2" gutterBottom>
                      <span className={classes.note}>
                        Note: If your company/group is not on the list, please
                        select Others and send a request to create a
                        company/group. It will be added within 24 Hours.
                      </span>
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{ paddingTop: "10px" }}
                    >
                      <span className={classes.note}>
                        Disclaimer : We do not take responsibility for
                        unofficial (mis) representation of institutions of
                        public or private nature
                      </span>
                    </Typography>
                  </Grid>
                )}
                {this.state.showRunnerGroup && (
                  <Grid
                    style={{ width: "100%", margin: "0% 6.7%" }}
                    container
                    spacing={1}
                  >
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body2" gutterBottom>
                        <span style={{ color: "#727272" }}>
                          {Properties.Label_FitnessGroup}
                        </span>
                      </Typography>{" "}
                      <div
                        style={{
                          width: isBrowser ? "360px" : "100%",
                        }}
                      >
                        <MultiSelect
                          isGroup
                          groupedOptions={this.state.runnerGroupList}
                          isClearable
                          formatOptionLabel={(option, { context }) => {
                            return context === "value"
                              ? option?.city
                                ? `${
                                    option.label
                                  } (${option.city.toUpperCase()})`
                                : option.label
                              : option.label;
                          }}
                          // options={this.state.runnerGroupList}
                          value={this.state.selectedRunnerGroup}
                          onChange={this.handleRunnerGroupChange}
                        />
                      </div>
                    </Grid>
                    {this.state.otherGroup && (
                      <Grid container spacing={1} style={{ margin: "0px" }}>
                        <Grid item xs={6}>
                          <Typography variant="body2" gutterBottom>
                            <span style={{ color: "#727272" }}>
                              {"Group Name"}
                            </span>
                          </Typography>
                          <CustomTextField
                            style={{
                              border: "1px solid #08456C",
                              height: "48%",
                              maxHeight: "48%",
                              minHeight: "48%",
                              // width:'100%'
                              // marginTop: "21px",
                              width: isBrowser ? "360px" : "160px",
                            }}
                            type={"text"}
                            keyToSet={"runnerGroup"}
                            value={this.state.runnerGroup}
                            handleChange={(prop) => (event) => {
                              this.setState({
                                runnerGroup: event.target.value,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" gutterBottom>
                            <span style={{ color: "#727272" }}>
                              {Properties.Label_City}
                            </span>
                          </Typography>
                          <CustomTextField
                            style={{
                              border: "1px solid #08456C",
                              height: "48%",
                              maxHeight: "48%",
                              minHeight: "48%",
                              // marginTop: "21px",
                              width: isBrowser ? "360px" : "160px",
                            }}
                            type={"text"}
                            keyToSet={"runnerGroup"}
                            value={this.state.runnerGroupCity}
                            handleChange={(prop) => (event) => {
                              this.setState({
                                runnerGroupCity: event.target.value,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}
                {this.state?.showCorporateOnReg && (
                  <Grid
                    style={{ width: "100%", margin: "0% 6.7%" }}
                    container
                    spacing={1}
                  >
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body2" gutterBottom>
                        <span style={{ color: "#727272" }}>
                          {Properties.Label_Corporate}
                        </span>
                      </Typography>{" "}
                      <div
                        style={{
                          width: isBrowser ? "360px" : "100%",
                        }}
                      >
                        <MultiSelect
                          isClearable
                          options={this.state.corporateList}
                          value={this.state.selectedCorporate}
                          onChange={this.handleCorporateChange}
                        />
                      </div>
                    </Grid>
                    {this.state?.otherCorporate && (
                      <Grid
                        container
                        spacing={1}
                        xs={12}
                        style={{ margin: "0%" }}
                      >
                        <Grid item xs={6}>
                          <Typography variant="body2" gutterBottom>
                            <span style={{ color: "#727272" }}>{"Other"}</span>
                          </Typography>
                          <CustomTextField
                            style={{
                              border: "1px solid #08456C",
                              height: "48%",
                              maxHeight: "48%",
                              minHeight: "48%",
                              // width:'100%'
                              // marginTop: "21px",
                              width: isBrowser ? "360px" : "160px",
                            }}
                            type={"text"}
                            keyToSet={"corporate"}
                            value={this.state.corporateName}
                            handleChange={(prop) => (event) => {
                              this.setState({
                                corporateName: event.target.value,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}
                {this.state.showAgeGroup && (
                  <Grid
                    style={{ width: "100%", margin: "0% 6.7%" }}
                    container
                    spacing={1}
                  >
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body2" gutterBottom>
                        <span style={{ color: "#727272" }}>
                          {Properties.Label_AgeGroup}
                        </span>
                        <span style={{ color: "red" }}>*</span>
                      </Typography>{" "}
                      <div
                        style={{
                          width: isBrowser ? "360px" : "100%",
                        }}
                      >
                        <MultiSelect
                          options={this.state.ageGroupList}
                          value={this.state.selectedAgeGroup}
                          onChange={this.handleAgeGroupChange}
                        />
                      </div>
                    </Grid>
                  </Grid>
                )}

                {/* Additional details */}
                {additionalDetails?.length > 0 && (
                  <div className={classes.customFieldContainer}>
                    <CustomFields
                      detailsData={additionalDetails}
                      hideCustomSectionLabel={hideCustomSectionLabel}
                      customSectionLabel={customSectionLabel}
                      updateDetailsData={(updatedData) =>
                        this.setState({ additionalDetails: updatedData })
                      }
                    />
                  </div>
                )}

                {/* Height weight & BMI */}

                {showHtWtOnSignup && (
                  <Grid
                    style={{
                      marginLeft: "6.7%",
                      marginRight: "6.7%",
                      marginTop: "5%",
                      borderTop: "1px solid rgb(13 108 169)",
                      paddingTop: "10px",
                      //height: "50px",
                    }}
                    container
                    spacing={1}
                  >
                    <Grid
                      item
                      xs={12}
                      // lg={6}
                    >
                      <Typography variant="body2" gutterBottom>
                        <span style={{ color: "#727272" }}>
                          {Properties.Height_label} {Properties.Label_unit_cm}
                        </span>
                        <span style={{ color: "red" }}>*</span>
                      </Typography>

                      <CustomTextField
                        style={{
                          border: heightError
                            ? "1px solid red"
                            : "1px solid #08456C",
                          height: "38px", //"55%",
                          maxHeight: "38px", //"55%",
                          minHeight: "38px",
                          marginLeft: "2px",
                        }}
                        type={"number"}
                        isNumber={true}
                        value={height}
                        error={heightError}
                        keyToSet={"height"}
                        handleChange={(prop) => (event) => {
                          // let heightValue = /^[-+]?[0-9]+\.[0-9]+$/.test(event.target.value) ? '' : event.target.value
                          let heightValue = event.target.value;
                          let bmi;

                          if (heightValue > 50 && weight && weight > 0) {
                            bmi = Number(
                              weight /
                                ((heightValue / 100) * (heightValue / 100))
                            ).toFixed(2);
                          }
                          let findWellnessPoints =
                            this.state.wellnessPointLookupList.find((x) => {
                              let range1 = x?.bmiRange1 ? x.bmiRange1 : 0;
                              return bmi <= x.bmiRange2;
                            });
                          this.setState({
                            height: heightValue,
                            // heightError: /^[-+]?[0-9]+\.[0-9]+$/.test(event.target.value) || !event.target.value  ? true : false,
                            heightError: event.target.value > 50 ? false : true,
                            calculatedBmi: bmi,

                            calculatedSuggestedPoints:
                              height && weight
                                ? findWellnessPoints?.wellnessPoint
                                : "",
                            calculatedSuggestedDistance:
                              height && weight
                                ? findWellnessPoints?.distance
                                : "",
                            calculatedSuggestedSteps:
                              height && weight ? findWellnessPoints?.steps : "",
                          });
                        }}
                      />
                      {heightError && (
                        <Typography variant="body2" gutterBottom>
                          <span style={{ color: "red" }}>
                            Height should be in cm
                          </span>
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" gutterBottom>
                        <span style={{ color: "#727272" }}>
                          {Properties.Weight_label} {Properties.Label_Unit_kg}
                        </span>
                        <span style={{ color: "red" }}>*</span>
                      </Typography>

                      <CustomTextField
                        style={{
                          border: weightError
                            ? "1px solid red"
                            : "1px solid #08456C",
                          height: "38px", //"55%",
                          maxHeight: "38px", //"55%",
                          minHeight: "38px",
                          marginLeft: "2px",
                        }}
                        type={"number"}
                        isNumber={true}
                        value={weight}
                        error={this.state.contactNumberError}
                        keyToSet={"weight"}
                        handleChange={(prop) => (event) => {
                          let bmi;
                          let weightValue = event.target.value;
                          if (
                            height &&
                            height > 50 &&
                            weightValue &&
                            weightValue > 0
                          ) {
                            bmi = Number(
                              weightValue / ((height / 100) * (height / 100))
                            ).toFixed(2);
                          }
                          let findWellnessPoints =
                            this.state.wellnessPointLookupList.find((x) => {
                              let range1 = x?.bmiRange1 ? x.bmiRange1 : 0;
                              return bmi <= x.bmiRange2;
                            });
                          this.setState({
                            weight: event.target.value,
                            weightError: event.target.value ? false : true,
                            calculatedBmi: bmi,
                            calculatedSuggestedPoints:
                              height && weight
                                ? findWellnessPoints?.wellnessPoint
                                : "",
                            calculatedSuggestedDistance:
                              height && weight
                                ? findWellnessPoints?.distance
                                : "",
                            calculatedSuggestedSteps:
                              height && weight ? findWellnessPoints?.steps : "",
                          });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ width: "100%", textAlign: "center" }}
                      // lg={6}
                    >
                      <Typography variant="body2" gutterBottom>
                        <span
                          style={{
                            color: "#727272",
                            fontSize: "20px",
                            fontWeight: "600",
                          }}
                        >
                          {"Calculated BMI:"}
                        </span>
                        <span
                          style={{
                            color: "#727272",
                            fontSize: "20px",
                            marginLeft: "10px",
                            fontWeight: "600",
                          }}
                        >
                          {calculatedBmi}
                        </span>
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{ width: "100%" }}
                      // lg={6}
                    >
                      <Typography variant="body2" gutterBottom>
                        <span
                          style={{
                            color: "#727272",
                            fontSize: "20px",
                            fontWeight: "600",
                          }}
                        >
                          {ReactHtmlParser(wellnessPointDesc)}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {this.state?.eventItemsFormData && (
                  <Grid
                    className={classes.eventItemsStyling}
                    item
                    xs={10}
                    container
                    spacing={1}
                  >
                    <Typography variant="body2" gutterBottom>
                      <span className={classes.merchandiseTitle}>
                        Merchandise
                      </span>
                    </Typography>
                    <div
                      style={{
                        borderTop: "1px solid rgb(13 108 169)",
                        width: "100%",
                      }}
                    ></div>

                    <div
                      style={{ width: "100%" }}
                      className={classes.merchandiseContainer}
                    >
                      <EventItems
                        data={this.state.eventItemsFormData}
                        updateData={(data) => {
                          let total = 0;
                          if (this.state.registrationFee)
                            total = total + this.state.registrationFee;
                          if (data?.eventItems) {
                            data.eventItems.map((x) => {
                              total = x?.rowTotal ? total + x?.rowTotal : total;
                            });
                          }
                          if (hasDiscountCoupon && total > 0) {
                            this.setState({
                              eventItemsFormData: data,
                            });
                            this.onApplyCoupon(total);
                          } else {
                            this.setState({
                              eventItemsFormData: data,
                              grandTotal: total,
                            });
                          }
                        }}
                        openSizeChart={this.viewChart}
                      />
                    </div>
                  </Grid>
                )}

                {this.state.shippingRequired && (
                  <Grid
                    style={{
                      width: "100%",
                      margin: "0% 6.7%",
                      height: "75px",
                    }}
                    container
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body2" gutterBottom>
                        <span style={{ color: "#727272" }}>
                          {Properties.Label_ContatNumber}
                        </span>
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <Grid item style={{ display: "flex" }}>
                        <Grid item xs={5}>
                          <MultiSelect
                            value={this.state.contactCode}
                            options={this.state.countryCodeList}
                            onChange={this.handleCodeChange}
                          />
                        </Grid>
                        <Grid item xs={7}>
                          <CustomTextField
                            style={
                              this.state.contactNumberError
                                ? {
                                    border: "1px solid red",
                                    height: "63%",
                                    maxHeight: "63%",
                                    minHeight: "63%",
                                    marginLeft: "2px",
                                  }
                                : {
                                    border: "1px solid #08456C",
                                    height: "63%",
                                    maxHeight: "63%",
                                    minHeight: "63%",
                                    marginLeft: "2px",
                                  }
                            }
                            type={"number"}
                            isNumber={true}
                            value={this.state.contactNumber}
                            error={this.state.contactNumberError}
                            keyToSet={"contactNumber"}
                            handleChange={(prop) => (event) => {
                              this.setState({
                                contactNumber: event.target.value,
                                contactNumberError:
                                  ApplicationUtil.validateContactNumber(
                                    event.target.value
                                  )
                                    ? false
                                    : true,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {this.state.shippingRequired && (
                  <Grid item xs={12} style={{ margin: "0% 6.7% 1% 6.7%" }}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        height: "70px",
                        marginTop: "1%",
                        marginBottom: "0%",
                      }}
                    >
                      <Typography variant="body2" gutterBottom>
                        <span
                          style={{
                            color: "#727272",
                            marginBottom: "4px",
                            marginTop: "5px",
                          }}
                        >
                          {Properties.shipping_Address_Label}
                        </span>
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <CustomTextField
                        style={
                          this.state.addressError
                            ? {
                                border: "1px solid red",
                                height: "40px",
                                maxHeight: "40px",
                                minHeight: "40px",
                                width: "100%",
                              }
                            : {
                                border: "1px solid #08456C",

                                height: "40px",
                                maxHeight: "40px",
                                minHeight: "40px",
                                width: "100%",
                              }
                        }
                        type={"text"}
                        error={true}
                        keyToSet={"city"}
                        readOnly={this.state.isReadOnly}
                        value={this.state.address}
                        handleChange={(prop) => (event) => {
                          this.setState({
                            address: event.target.value,
                            addressError: false,
                          });
                        }}
                      />
                    </Grid>
                    <Grid
                      style={{
                        marginTop: "1%",
                        marginBottom: "0%",
                        height: "75px",
                      }}
                      container
                      spacing={1}
                    >
                      <Grid item xs={6}>
                        <Typography variant="body2" gutterBottom>
                          <span style={{ color: "#727272" }}>
                            {Properties.Label_City}
                          </span>
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <CustomTextField
                          style={
                            this.state.cityError
                              ? {
                                  border: "1px solid red",
                                  height: "45%",
                                  maxHeight: "45%",
                                  minHeight: "45%",
                                }
                              : {
                                  border: "1px solid #08456C",
                                  height: "45%",
                                  maxHeight: "45%",
                                  minHeight: "45%",
                                }
                          }
                          type={"text"}
                          error={true}
                          keyToSet={"city"}
                          readOnly={this.state.isReadOnly}
                          value={this.state.city}
                          handleChange={(prop) => (event) => {
                            this.setState({
                              city: event.target.value,
                              cityError: false,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" gutterBottom>
                          <span style={{ color: "#727272" }}>
                            {Properties.Label_Postal}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        </Typography>
                        <CustomTextField
                          style={
                            this.state.pincodeError
                              ? {
                                  border: "1px solid red",
                                  height: "45%",
                                  maxHeight: "45%",
                                  minHeight: "45%",
                                }
                              : {
                                  border: "1px solid #08456C",
                                  height: "45%",
                                  maxHeight: "45%",
                                  minHeight: "45%",
                                }
                          }
                          type={"text"}
                          keyToSet={"postaclCode"}
                          readOnly={this.state.isReadOnly}
                          error={this.state.pincodeError}
                          value={this.state.pincode}
                          handleChange={(prop) => (event) => {
                            this.setState({
                              pincode: event.target.value,
                              pincodeError: false,
                            });
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ height: "75px" }}>
                      <Typography variant="body2" gutterBottom>
                        <span style={{ color: "#727272" }}>
                          {Properties.Label_State}
                        </span>
                        <span style={{ color: "red" }}>*</span>
                      </Typography>{" "}
                      <CustomTextField
                        style={
                          this.state.stateError
                            ? {
                                border: "1px solid red",
                                height: "40px",
                                maxHeight: "40px",
                                minHeight: "40px",
                                width: "100%",
                              }
                            : {
                                border: "1px solid #08456C",
                                height: "40px",
                                maxHeight: "40px",
                                minHeight: "40px",
                                width: "100%",
                              }
                        }
                        type={"text"}
                        keyToSet={"state"}
                        value={this.state.state}
                        error={this.state.stateError}
                        handleChange={(prop) => (event) => {
                          this.setState({
                            state: event.target.value,
                            stateError: false,
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="body2" gutterBottom>
                        <span style={{ color: "#727272" }}>
                          {Properties.Label_Country}
                        </span>
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <MultiSelect
                        value={this.state.country}
                        error={this.state.countryError}
                        options={this.state.countryList}
                        onChange={this.handleCountryChange}
                      />
                    </Grid>
                  </Grid>
                )}

                {hasDiscountCoupon && (
                  <Accordion className={classes.couponAccordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <LocalOfferIcon
                        style={{ color: "blue", fontSize: "20px" }}
                      />
                      <Typography
                        style={{
                          color: "blue",
                          marginLeft: "10px",
                          fontSize: "16px",
                        }}
                      >
                        Apply Discount Code
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "75px",
                        justifyContent: "space-between",
                      }}
                    >
                      <CustomTextField
                        style={{
                          border: "1px solid #08456C",
                          height: "63%",
                          maxHeight: "55%",
                          minHeight: "55%",
                          marginRight: "10px",
                          width: "100%",
                          flex: 1,
                          fontSize: "16px",
                        }}
                        type={"text"}
                        isNumber={true}
                        value={couponCode}
                        // error={}
                        keyToSet={"couponCode"}
                        handleChange={(prop) => (event) => {
                          this.setState({
                            couponCode: event.target.value,
                          });
                        }}
                      />

                      <Typography
                        style={{
                          cursor: "pointer",
                          color: couponApplied ? "gray" : "#08456C",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                        onClick={couponApplied ? () => {} : this.onApplyCoupon}
                      >
                        Apply
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}

                {/* checkbox for terms & conditions */}
                <Grid
                  style={{
                    marginTop: "10px",
                    marginLeft: "6.7%",
                    height: "50px",
                  }}
                  item
                  xs={10}
                >
                  <div
                    style={{
                      borderTop: "1px solid rgb(13 108 169)",
                      width: "100%",
                      marginBottom: "2px",
                    }}
                  ></div>
                  {/* <Grid item xs={12}> */}
                  <span style={{ fontSize: "16px" }}>
                    <input
                      type="checkbox"
                      onChange={this.handleCheckBoxChange}
                      value={this.state.termsCheckboxSelected}
                      required
                    />
                    I agree to the
                    <a href="https://necessarydevil.com/terms" target="_blank">
                      {" "}
                      Terms and Conditions.
                    </a>{" "}
                  </span>
                  {/* </Grid> */}
                </Grid>
              </Grid>

              <Grid
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  margin: "0px",
                  textAlign: "center",
                }}
                container
                spacing={1}
              >
                <Grid style={{ margin: "25px" }} item xs={5}>
                  <SubmitCancelBtn
                    onClick={(e) => this.registerEvent(e)}
                    label={Properties.Label_Register}
                    type="submit"
                    isDisabled={
                      eventCategory?.ticketSoldOut
                        ? true
                        : !this.state.termsCheckboxSelected
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {this.state.showInviteCondition && this.state.registrationOpen ? (
            <DialogBox
              signup={true}
              open={this.state.open}
              message={this.state.dailogMsg}
              header={this.state.dialogHeader}
              onClick={() => {
                // this.state.signupCondition ? this.navigate() : this.navigate2();
                this.navigate2();
              }}
              // for payemnt
              // onClick={() => {
              //   this.state.signupCondition
              //     ? this.navigate()
              //     : this.paymentHandler(); //  this.navigate2();
              // }}
              onClickTwo={() => {
                this.showInvite();
              }}
              labelTwo={"Invite People"}
              closeDisloag={null} //{() => this.closeWindow()}
            />
          ) : (
            <DialogBox
              signup={true}
              open={this.state.open}
              message={this.state.dailogMsg}
              header={this.state.dialogHeader}
              onClick={() => {
                this.state.signupCondition ? this.navigate() : this.navigate2();
              }}
              closeDisloag={() => this.setState({ open: false })}
            />
          )}

          <DialogBox
            signup={true}
            open={this.state.showChartOpen}
            message={this.state.dailogMsg}
            header="T-Shirt Size Chart"
            onClick={() => this.hideChart()}
            closeDisloag={() => {
              this.hideChart();
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateDrawersEventList,
    updateUserDetails,
  })
)(RegisterEvent);
