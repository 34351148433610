import React, {useState, useEffect} from 'react'
import AppToolBar from '../common/AppToolBar'
import Header from '../common/Header'
import { getEventDetailsWithDistkey, getMapData } from '../../apis/eventService'
import moment from 'moment'
import RelayLeaderboard from './registration/events/viewResults/Relay/RelayLeaderboard'

function LiveMap() {
    const [relayMapData, setRelayMapData] = useState(null)

    useEffect(()=>{
        let distKey = "b+AwHrotYWQ8XOTmdAYbyw=="
        getEventDetailsWithDistkey(distKey)
        .then((res) => {
          if (res.status === 200) {
            // document.title = `Results - ${res?.data?.name}`;
            document.title = res?.data?.name;
            let eventData = res.data;
            eventData.distKey = distKey;

    

        

            if (res.data?.mapGenerated) {
              
              if (res.data?.mapToShow === "RELAY") {
                let isLive = moment().isAfter(eventData?.eventLocalEndDate);
                let eventDateTime = moment(
                  eventData?.eventDate + " " + eventData?.eventTime
                );
                let current = moment();
                let timeRemained = eventDateTime.diff(current);
                // if(!search?.includes('#participants'))
                getMapData(eventData?.id).then((relayRes) => {
                    setRelayMapData(
                    {
                      live: isLive,
                      flightPlanCoordinates: relayRes?.data
                        ?.coOrdinatePointsJson
                        ? JSON.parse(relayRes?.data?.coOrdinatePointsJson)
                        : null,
                      center: relayRes?.data?.centerPoint?.coOrdinatePoint
                        ? relayRes?.data?.centerPoint?.coOrdinatePoint
                        : null,
                      defaultCenter:
                        relayRes?.data?.centerPoint?.coOrdinatePoint,
                      features: relayRes?.data?.markerPointDTOs
                        ? relayRes?.data?.markerPointDTOs
                        : null,
                      timeRemained: timeRemained,
                    },
                  )
                });
               
              }
            }
          }
        })
        .catch((error) => {
          if (error) {
            // history.push(`/event?distKey=${distKey}`);
          }
        });
    },[])

  return (
    <>
        <AppToolBar
            //   backNavigate={() => this.props.history.push("/eventDetails")} //goBack()} //
            //   showBack={true}
            >
              <Header
              hideDrawer
                // {...this.props}
                label={`Ashish Kasodekar's Live Tracker`}
                // fromDate={eventDate}
                // toDate={eventDate === eventEnd ? false : eventEnd}
              />
            </AppToolBar>
            <div style={{marginTop:'70px'}}>
           {relayMapData &&  <RelayLeaderboard data={relayMapData} />}
            </div>
    </>
  )
}

export default LiveMap