/*
 should have 2 div separately, 
First one for center items 
Second one for last, ie. right alight items.
*/

import React from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { Divider, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  btnSize: {
    width: "23px",
    height: "23px",
  },
  children: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      textAlign: "center",
    },
  },
});

class InlineDivider extends React.Component {
  render() {
    const { classes, children, style, childrenStyle } = this.props;
    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={3} sm={5}>
          <Divider style={style} />
        </Grid>
        <Grid
          item
          xs={6}
          sm={2}
          style={{
            display: "flex",
            justifyContent: "center",
            color: "#08456C",
          }}
        >
          <span
            className={classes?.children}
            style={
              childrenStyle
                ? childrenStyle
                : { fontSize: "24px", fontWeight: "600" }
            }
          >
            {children}
          </span>
        </Grid>
        <Grid item xs={3} sm={5}>
          <Divider style={style} />
        </Grid>
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(InlineDivider);
