import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

class ListPageLoader extends React.Component {
  pageParams = "";

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.pageParams.TotalRecords = nextProps.pageParams.TotalRecords;
    this.pageParams.pageNumberLoad = nextProps.pageParams.pageNumberLoad;
  }
  componentDidMount() {
    this.resetParams();
  }
  componentDidUpdate() {
    // console.log('loader did update',this.props.resetLoader)
    if (this.props.resetLoader) {
      this.resetParams();
      // console.log('params check', this.pageParams)
    }
  }
  render() {
    const { children, totalLoadedLength, divHeight } = this.props;

    return (
      <InfiniteScroll
        ref={(e) => (this.InfiniteScroll = e)}
        dataLength={totalLoadedLength}
        next={() => this.calculateParams()}
        hasMore={true}
        height={divHeight}
        style={{ overflow: "hidden" }}
        reset={() => this.resetParams()}
      >
        {children}
      </InfiniteScroll>
    );
  }

  resetParams = () => {
    
    this.pageParams = this.props.pageParams;
    if (this.InfiniteScroll) {
      this.InfiniteScroll.el.scrollTo(0, 0);
    }
  };
  calculateParams = () => {
    const { loadNextData } = this.props;
    let { TotalRecords, limit } = this.pageParams;
    let params = this.pageParams;
    if (this.pageParams.pageNumberLoad === 0) {
      params.page = 0;
    }
    if (params.page + 1 <= Math.ceil(TotalRecords / limit)) {
      params.page = params.page + 1;
      params.start = params.page * params.limit - params.limit;
      this.pageParams = params;
      loadNextData(params);
    }
  };
}

export default ListPageLoader;
